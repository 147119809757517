export const data = {
  mdsDat: {
    x: [
      -0.3096060204531974,
      0.21190286285390883,
      0.2549761140321437,
      -0.14488729531035516,
      -0.012385661122499399,
    ],
    y: [
      -0.074754442408441,
      -0.24896533136181095,
      0.07633180504230379,
      -0.0997219214868983,
      0.347109890214846,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      16.666891247998024,
      23.016272225273422,
      15.759923543005486,
      21.429885297747724,
      23.127027685975346,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      14.0,
      17.0,
      9.0,
      12.0,
      7.0,
      6.0,
      6.0,
      11.0,
      6.0,
      6.0,
      1.0000134748798815,
      1.0000134748798815,
      1.0000134748798815,
      1.0000134748798815,
      1.0000134748798815,
      4.000053899519526,
      1.0000134748798815,
      3.0000404246396446,
      3.0000404246396446,
      1.0000134748798815,
      4.000053899519526,
      4.000053899519526,
      3.0000404246396446,
      3.0000404246396446,
      3.0000404246396446,
      2.000026949759763,
      2.000026949759763,
      2.000026949759763,
      2.000026949759763,
      8.000107799039052,
      7.00009432415917,
      7.00009432415917,
      4.000053899519526,
      3.0000404246396446,
      3.0000404246396446,
      6.000104069760933,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      6.000104069760933,
      6.000104069760933,
      3.0000520348804667,
      5.000086724800778,
      6.000104069760933,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      5.000086724800778,
      5.000086724800778,
      2.0000363402778185,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      2.0000363402778185,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      6.000109020833456,
      4.000072680555637,
      3.000054510416728,
      1.0000613138948937,
      1.0000613138948937,
      3.000183941684681,
      1.0000613138948937,
      1.0000613138948937,
      1.0000613138948937,
      1.0000613138948937,
      2.0001226277897874,
      1.0000613138948937,
      2.0001226277897874,
      6.000367883369362,
      4.000245255579575,
      4.000245255579575,
      4.000245255579575,
      3.000183941684681,
      3.000183941684681,
      3.000183941684681,
      3.000183941684681,
      3.000183941684681,
      12.000735766738725,
      10.000613138948937,
      12.000735766738725,
      4.000245255579575,
      3.000183941684681,
      3.000183941684681,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      6.999326908490774,
      5.999423064420663,
      5.999423064420663,
      4.999519220350552,
      4.999519220350552,
      3.999615376280442,
      3.999615376280442,
      3.999615376280442,
      3.999615376280442,
      3.999615376280442,
      6.999326908490774,
      4.999519220350552,
      3.999615376280442,
    ],
    Term: [
      "pm",
      "modi",
      "rally",
      "pm_modi",
      "unrelated",
      "ajmal",
      "covid",
      "show",
      "shah",
      "amit",
      "kubera",
      "ground",
      "recently",
      "art",
      "asset",
      "west_bengal",
      "grassroot",
      "unrelated_photo",
      "left_front",
      "unrelated_photos",
      "bengal",
      "west",
      "tweet",
      "leave",
      "kolkata",
      "tornado",
      "peddle",
      "account",
      "bhainsa",
      "rally",
      "unrelated",
      "bjp",
      "modi",
      "violence",
      "pass",
      "amit_shah",
      "scam",
      "foot",
      "bsp",
      "kerala",
      "banerjee",
      "amit",
      "shah",
      "mamata_banerjee",
      "owaisi",
      "give",
      "leg",
      "mamata",
      "police",
      "election",
      "bjp",
      "restore",
      "arrest",
      "sector",
      "number",
      "leading",
      "singe",
      "tea",
      "poster",
      "trinamul",
      "status",
      "ganguly",
      "manmohan",
      "statistic",
      "manmohan_singh",
      "statue",
      "sourav_ganguly",
      "sourav",
      "government",
      "catch",
      "liberty",
      "incorrectly",
      "tenure",
      "ram",
      "endorse",
      "show",
      "woman",
      "singh",
      "find",
      "meeting",
      "office",
      "adityanath",
      "truck",
      "uk",
      "rashtra",
      "quick",
      "graphic",
      "hoard",
      "covid",
      "thank",
      "gandhi",
      "vaccine",
      "queen",
      "rahul_gandhi",
      "vaccines",
      "billboard",
      "rahul",
      "pm",
      "pm_modi",
      "modi",
      "morph",
      "show",
      "make",
      "portray",
      "sena",
      "mithun",
      "jogi",
      "debut",
      "rudranil",
      "assam",
      "page",
      "palestinian",
      "yet",
      "ajmal",
      "badruddin_ajmal",
      "badruddin",
      "haryana",
      "islamic",
      "khattar",
      "hooda",
      "say",
      "farmer",
      "israel",
      "cm",
      "singh",
      "leader",
    ],
    Total: [
      14.0,
      17.0,
      9.0,
      12.0,
      7.0,
      6.0,
      6.0,
      11.0,
      6.0,
      6.0,
      1.0000134748798815,
      1.0000134748798815,
      1.0000134748798815,
      1.0000134748798815,
      1.0000134748798815,
      4.000053899519526,
      1.0000134748798815,
      3.0000404246396446,
      3.0000404246396446,
      1.0000134748798815,
      4.000053899519526,
      4.000053899519526,
      3.0000404246396446,
      3.0000404246396446,
      3.0000404246396446,
      2.000026949759763,
      2.000026949759763,
      2.000026949759763,
      2.000026949759763,
      9.000169112933946,
      7.999998168229281,
      15.000321846888646,
      17.000807836397158,
      4.000101738534538,
      4.999962438848664,
      6.000104069760933,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      6.000104069760933,
      6.000104069760933,
      3.0000520348804667,
      5.000086724800778,
      6.000104069760933,
      3.0000520348804667,
      3.0000520348804667,
      3.0000520348804667,
      8.000179683714462,
      15.000321846888646,
      2.0000363402778185,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      1.0000181701389093,
      2.0000363402778185,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      3.000054510416728,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      2.0000363402778185,
      11.00032765243845,
      6.000107370475948,
      7.99957373076728,
      1.0000613138948937,
      1.0000613138948937,
      3.000183941684681,
      1.0000613138948937,
      1.0000613138948937,
      1.0000613138948937,
      1.0000613138948937,
      2.0001226277897874,
      1.0000613138948937,
      2.0001226277897874,
      6.000367883369362,
      4.000245255579575,
      4.000245255579575,
      4.000245255579575,
      3.000183941684681,
      3.000183941684681,
      3.000183941684681,
      3.000183941684681,
      3.000183941684681,
      14.000762716498487,
      12.0006400887087,
      17.000807836397158,
      8.000088458818862,
      11.00032765243845,
      4.999991629824902,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      0.9999038440701105,
      6.999326908490774,
      5.999423064420663,
      5.999423064420663,
      4.999519220350552,
      4.999519220350552,
      3.999615376280442,
      3.999615376280442,
      3.999615376280442,
      3.999615376280442,
      3.999615376280442,
      7.99934425345093,
      7.99957373076728,
      7.999728725055802,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.7917,
      1.674,
      1.6582,
      1.0296,
      0.3448,
      1.5041,
      1.2809,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      1.469,
      0.999,
      0.3704,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.8477,
      1.2416,
      1.4422,
      0.8669,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.5404,
      1.3862,
      1.3581,
      1.1921,
      0.8473,
      0.2411,
      1.0296,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.4642,
      1.3306,
      0.9941,
      0.771,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.9904,
      -4.9904,
      -4.9904,
      -4.9904,
      -4.9904,
      -3.6041,
      -4.9904,
      -3.8918,
      -3.8918,
      -4.9904,
      -3.6041,
      -3.6041,
      -3.8918,
      -3.8918,
      -3.8918,
      -4.2973,
      -4.2973,
      -4.2973,
      -4.2973,
      -2.911,
      -3.0445,
      -3.0445,
      -3.6041,
      -3.8918,
      -3.8918,
      -3.5214,
      -4.2146,
      -4.2146,
      -4.2146,
      -4.2146,
      -4.2146,
      -3.5214,
      -3.5214,
      -4.2146,
      -3.7038,
      -3.5214,
      -4.2146,
      -4.2146,
      -4.2146,
      -3.7038,
      -3.7038,
      -4.2413,
      -4.9345,
      -4.9345,
      -4.9345,
      -4.9345,
      -4.9345,
      -4.9345,
      -4.9345,
      -4.9345,
      -4.2413,
      -3.8359,
      -3.8359,
      -3.8359,
      -3.8359,
      -3.8359,
      -3.8359,
      -3.8359,
      -3.8359,
      -4.2413,
      -4.2413,
      -4.2413,
      -4.2413,
      -4.2413,
      -4.2413,
      -3.1427,
      -3.5482,
      -3.8359,
      -5.2417,
      -5.2417,
      -4.1431,
      -5.2417,
      -5.2417,
      -5.2417,
      -5.2417,
      -4.5486,
      -5.2417,
      -4.5486,
      -3.45,
      -3.8555,
      -3.8555,
      -3.8555,
      -4.1431,
      -4.1431,
      -4.1431,
      -4.1431,
      -4.1431,
      -2.7568,
      -2.9392,
      -2.7568,
      -3.8555,
      -4.1431,
      -4.1431,
      -5.3181,
      -5.3181,
      -5.3181,
      -5.3181,
      -5.3181,
      -5.3181,
      -5.3181,
      -5.3181,
      -5.3181,
      -5.3181,
      -3.3722,
      -3.5264,
      -3.5264,
      -3.7087,
      -3.7087,
      -3.9318,
      -3.9318,
      -3.9318,
      -3.9318,
      -3.9318,
      -3.3722,
      -3.7087,
      -3.9318,
    ],
  },
  "token.table": {
    Topic: [
      1,
      4,
      5,
      2,
      2,
      3,
      1,
      5,
      1,
      5,
      5,
      2,
      1,
      1,
      4,
      1,
      2,
      3,
      4,
      2,
      3,
      2,
      5,
      4,
      5,
      1,
      2,
      3,
      4,
      3,
      5,
      4,
      2,
      4,
      3,
      2,
      3,
      4,
      1,
      1,
      5,
      4,
      5,
      3,
      5,
      5,
      5,
      2,
      5,
      1,
      1,
      2,
      4,
      5,
      3,
      1,
      1,
      2,
      3,
      4,
      5,
      2,
      2,
      3,
      3,
      4,
      5,
      1,
      3,
      4,
      2,
      3,
      4,
      5,
      3,
      4,
      2,
      5,
      5,
      1,
      3,
      5,
      1,
      1,
      4,
      1,
      4,
      2,
      5,
      3,
      4,
      4,
      4,
      4,
      1,
      4,
      3,
      4,
      1,
      3,
      5,
      5,
      2,
      3,
      5,
      2,
      2,
      3,
      4,
      3,
      3,
      5,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      4,
      1,
      3,
      4,
      1,
      4,
      1,
      5,
      1,
      1,
      4,
      4,
      1,
      4,
      1,
      1,
      2,
      3,
      5,
    ],
    Freq: [
      0.9999865253016885,
      0.9999386898642695,
      1.0000961651767415,
      0.9999826553406869,
      0.9999826553406869,
      0.9999818301912387,
      0.9999865253016885,
      1.0000961651767415,
      0.9999865253016885,
      1.0000961651767415,
      1.0000961651767415,
      0.9999826553406869,
      0.9999865253016885,
      0.9999865253016885,
      0.9999386898642695,
      0.46665665386719246,
      0.3333261813337089,
      0.06666523626674178,
      0.13333047253348357,
      0.9999826553406869,
      0.9999818301912387,
      0.1250102468797487,
      0.8750717281582411,
      0.9999386898642695,
      1.0000961651767415,
      0.12499719250501917,
      0.6249859625250959,
      0.12499719250501917,
      0.12499719250501917,
      0.9999818301912387,
      1.0000961651767415,
      0.9999386898642695,
      0.9999826553406869,
      0.9999386898642695,
      0.9999818301912387,
      0.9999826553406869,
      0.9999818301912387,
      0.9999386898642695,
      0.9999865253016885,
      0.9999865253016885,
      1.0000961651767415,
      0.9999386898642695,
      1.0000961651767415,
      0.9999818301912387,
      1.0000961651767415,
      1.0000961651767415,
      1.0000961651767415,
      0.9999826553406869,
      1.0000961651767415,
      0.9999865253016884,
      0.9999865253016885,
      0.37501271644421585,
      0.12500423881473863,
      0.5000169552589545,
      0.9999818301912387,
      0.9999865253016884,
      0.9999865253016884,
      0.9999826553406869,
      0.9999818301912387,
      0.6000010044226932,
      0.4000006696151288,
      0.9999826553406869,
      0.9999826553406869,
      0.9999818301912387,
      0.9999818301912387,
      0.9999386898642695,
      1.0000961651767415,
      0.23528293705175407,
      0.05882073426293852,
      0.7058488111552622,
      0.12499861784623824,
      0.12499861784623824,
      0.49999447138495295,
      0.24999723569247648,
      0.9999818301912387,
      0.9999386898642695,
      0.9999826553406868,
      1.0000961651767415,
      1.0000961651767415,
      0.6000045073720207,
      0.20000150245734025,
      0.20000150245734025,
      0.9999865253016885,
      0.14284936045971278,
      0.8570961627582767,
      0.1666577770198927,
      0.8332888850994635,
      0.9999826553406869,
      1.0000961651767415,
      0.9999818301912387,
      0.9999386898642695,
      0.9999386898642695,
      0.9999386898642695,
      0.9999386898642695,
      0.888872186690734,
      0.11110902333634175,
      0.9999818301912387,
      0.9999386898642695,
      0.9999865253016885,
      0.9999818301912387,
      1.0000961651767415,
      1.0000961651767415,
      0.9999826553406869,
      0.9999818301912387,
      1.0000961651767415,
      0.9999826553406869,
      0.1818127662367092,
      0.5454382987101276,
      0.2727191493550638,
      0.9999818301912387,
      0.3750199824350209,
      0.6250333040583681,
      0.9999818301912387,
      0.9999818301912387,
      0.9999818301912387,
      0.9999818301912387,
      0.9999818301912387,
      0.9999818301912387,
      0.9999818301912387,
      0.9999386898642695,
      0.9999865253016885,
      0.9999818301912387,
      0.9999386898642695,
      0.9999865253016884,
      0.9999386898642695,
      0.8750002003499683,
      0.12500002862142404,
      0.9999865253016884,
      0.9999865253016885,
      0.9999386898642695,
      0.9999386898642695,
      0.7499809245099521,
      0.24999364150331738,
      0.9999865253016885,
      0.9999865253016885,
      0.3333273684136345,
      0.666654736827269,
      1.0000961651767415,
    ],
    Term: [
      "account",
      "adityanath",
      "ajmal",
      "amit",
      "amit_shah",
      "arrest",
      "art",
      "assam",
      "asset",
      "badruddin",
      "badruddin_ajmal",
      "banerjee",
      "bengal",
      "bhainsa",
      "billboard",
      "bjp",
      "bjp",
      "bjp",
      "bjp",
      "bsp",
      "catch",
      "cm",
      "cm",
      "covid",
      "debut",
      "election",
      "election",
      "election",
      "election",
      "endorse",
      "farmer",
      "find",
      "foot",
      "gandhi",
      "ganguly",
      "give",
      "government",
      "graphic",
      "grassroot",
      "ground",
      "haryana",
      "hoard",
      "hooda",
      "incorrectly",
      "islamic",
      "israel",
      "jogi",
      "kerala",
      "khattar",
      "kolkata",
      "kubera",
      "leader",
      "leader",
      "leader",
      "leading",
      "leave",
      "left_front",
      "leg",
      "liberty",
      "make",
      "make",
      "mamata",
      "mamata_banerjee",
      "manmohan",
      "manmohan_singh",
      "meeting",
      "mithun",
      "modi",
      "modi",
      "modi",
      "morph",
      "morph",
      "morph",
      "morph",
      "number",
      "office",
      "owaisi",
      "page",
      "palestinian",
      "pass",
      "pass",
      "pass",
      "peddle",
      "pm",
      "pm",
      "pm_modi",
      "pm_modi",
      "police",
      "portray",
      "poster",
      "queen",
      "quick",
      "rahul",
      "rahul_gandhi",
      "rally",
      "rally",
      "ram",
      "rashtra",
      "recently",
      "restore",
      "rudranil",
      "say",
      "scam",
      "sector",
      "sena",
      "shah",
      "show",
      "show",
      "show",
      "singe",
      "singh",
      "singh",
      "sourav",
      "sourav_ganguly",
      "statistic",
      "statue",
      "status",
      "tea",
      "tenure",
      "thank",
      "tornado",
      "trinamul",
      "truck",
      "tweet",
      "uk",
      "unrelated",
      "unrelated",
      "unrelated_photo",
      "unrelated_photos",
      "vaccine",
      "vaccines",
      "violence",
      "violence",
      "west",
      "west_bengal",
      "woman",
      "woman",
      "yet",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/west-bengal-election-bjp-brigade-rally-fake-news-fact-check-west-bengal-assembly-poll-12244",
        headline: "2019 Image Of Left Front Rally Now Revived By BJP Handles",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/west-bengal-election-2021-bjp-rally-mamata-banerjee-narendra-modi-tmc-government-fake-news-fact-check-12246",
        headline:
          "\u092a\u0936\u094d\u091a\u093f\u092e \u092c\u0902\u0917\u093e\u0932 \u092e\u0947\u0902 \u092c\u0940\u091c\u0947\u092a\u0940 \u0930\u0948\u0932\u0940 \u0915\u0947 \u0926\u094c\u0930\u093e\u0928 \u0928\u0939\u0940\u0902 \u0906\u092f\u093e \u0939\u0948 \u092f\u0939 \u092c\u0935\u0902\u0921\u0930",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/west-bengal-election-bjp-brigade-rally-fake-news-fact-check-west-bengal-assembly-poll-12247",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0 \u09ac\u09be\u09ae\u09ab\u09cd\u09b0\u09a8\u09cd\u099f\u09c7\u09b0 \u09ac\u09cd\u09b0\u09bf\u0997\u09c7\u09a1 \u099c\u09a8\u09b8\u09ad\u09be\u09b0 \u099b\u09ac\u09bf \u098f\u09ac\u09be\u09b0 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u0985\u09cd\u09af\u09be\u0995\u09be\u0989\u09a8\u09cd\u099f",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-from-2018-journalists-protest-passed-off-as-recent-anti-bjp-rally-in-kolkata-675224",
        headline:
          "Fact Check: Photo from 2018 journalists' protest passed off as recent anti-BJP rally in Kolkata",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/bhainsa-telangana-violence-hindu-muslim-delhi-riots-old-photos-fact-check-12336",
        headline: "Old, Unrelated Images Peddled As Bhainsa Violence",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-edited-photo-no-vote-to-bjp-rally-kolkatta-old-journalist-protest-against-tmc-west-bengal-elections-12267",
        headline:
          "Edited Photo From Protest Against TMC Peddled As Anti-BJP Rally",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/old-photos-delhi-riots-bhainsa-violence-telangana-communal-clashes-2021-fake-news-12338",
        headline:
          "\u092a\u0941\u0930\u093e\u0928\u0940, \u0905\u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u092d\u0948\u0902\u0938\u093e \u0939\u093f\u0902\u0938\u093e \u0915\u0947 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-will-invest-6000-crore-if-bjp-comes-power-in-west-bengal-said-ratan-tata-fake-quote-west-bengal-2021-assembly-poll-12252",
        headline:
          "\u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u09ac\u09bf\u09a8\u09bf\u09df\u09cb\u0997 \u09a8\u09bf\u09df\u09c7 \u09b0\u09a4\u09a8 \u099f\u09be\u099f\u09be\u09b0 \u09ad\u09c1\u09df\u09cb \u09ac\u0995\u09cd\u09a4\u09ac\u09cd\u09af \u09ad\u09cb\u099f \u0986\u09ac\u09b9\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-edited-photo-no-vote-to-bjp-rally-kolkata-old-journalist-protest-against-tmc-west-bengal-elections-12328",
        headline:
          "\u09a4\u09c3\u09a3\u09ae\u09c2\u09b2\u09c7\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u09ac\u09bf\u099c\u09c7\u09aa\u09bf-\u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u09ae\u09bf\u099b\u09bf\u09b2 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-art-installation-passed-off-as-annual-book-donation-festival-in-toronto-675274",
        headline:
          "Fact Check: Photo of art installation passed off as annual book donation festival in Toronto",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/11/fact-check-old-image-of-left-rally-linked-to-pm-modis-recent-rally-in-kolkatas-brigade-ground/",
        headline:
          "Fact Check: Old Image Of Left Rally Linked To PM Modi\u2019s Recent Rally In Kolkata\u2019s Brigade Ground",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/14/fact-check-old-pictures-of-violence-falsely-linked-to-recent-clashes-in-telanganas-bhainsa/",
        headline:
          "Fact Check: Old Pictures Of Violence Falsely Linked To Recent Clashes In Telangana\u2019s Bhainsa",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-video-clips-falsely-shared-as-medicine-jihad-against-the-hindus-in-india/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c15\u0c4d\u0c32\u0c3f\u0c2a\u0c4d\u0c2a\u0c41\u0c32\u0c28\u0c41 \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u2018\u0c2e\u0c46\u0c21\u0c3f\u0c38\u0c3f\u0c28\u0c4d-\u0c1c\u0c3f\u0c39\u0c3e\u0c26\u0c4d\u2019 \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-unrelated-photos-shared-as-visuals-of-modis-recent-election-rally-in-west-bengal/",
        headline:
          " Old unrelated photos shared as visuals of PM Modi\u2019s recent election rally in West Bengal",
      },
      {
        url:
          "https://factly.in/telugu-old-unrelated-photos-shared-as-visuals-of-pm-modis-recent-election-rally-in-west-bengal/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c28\u0c30\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c4b\u0c26\u0c40 \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c15\u0c4b\u0c32\u0c4d\u200c\u0c15\u0c24\u0c3e\u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c38\u0c2d \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/unrelated-photos-falsely-shared-as-mysterious-tunnels-in-dwaraka/",
        headline:
          " Unrelated photos falsely shared as mysterious tunnels in Dwaraka",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-photos-falsely-shared-as-wealth-left-behind-by-the-dead-kuwait-billionaire-nasser-al-kharafi/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c15\u0c41\u0c35\u0c48\u0c1f\u0c4d \u0c15\u0c41\u0c2c\u0c47\u0c30\u0c41\u0c21\u0c41 \u0c28\u0c3e\u0c1c\u0c30\u0c4d \u0c05\u0c32\u0c4d-\u0c16\u0c30\u0c3e\u0c2b\u0c3f \u0c2e\u0c30\u0c23\u0c3f\u0c02\u0c1a\u0c15 \u0c2e\u0c41\u0c02\u0c26\u0c41 \u0c38\u0c02\u0c2a\u0c3e\u0c26\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c06\u0c38\u0c4d\u0c24\u0c41\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-photos-are-being-linked-to-simlipal-forest-fire-in-odisha/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c12\u0c21\u0c3f\u0c36\u0c3e \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c02\u0c32\u0c4b\u0c28\u0c3f \u0c38\u0c3f\u0c2e\u0c4d\u0c32\u0c3f\u0c2a\u0c3e\u0c32\u0c4d \u0c28\u0c47\u0c37\u0c28\u0c32\u0c4d \u0c2a\u0c3e\u0c30\u0c4d\u0c15\u0c4d \u0c2e\u0c02\u0c1f\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-fake-tweet-of-sara-ali-khan-about-muslim-women-goes-viral-on-social-media/",
        headline:
          "Fact Check: \u0938\u093e\u0930\u093e \u0905\u0932\u0940 \u0916\u093e\u0928 \u0915\u093e \u091f\u094d\u0935\u093f\u091f\u0930 \u092a\u0930 \u0928\u0939\u0940\u0902 \u0939\u0948 \u0905\u0915\u093e\u0909\u0902\u091f, \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u091f\u094d\u0935\u0940\u091f \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-fake-tweet-in-the-name-of-former-union-minister-yashwant-sinha-went-viral/",
        headline:
          "Fact Check : \u092a\u0942\u0930\u094d\u0935 \u0915\u0947\u0902\u0926\u094d\u0930\u0940\u092f \u092e\u0902\u0924\u094d\u0930\u0940 \u092f\u0936\u0935\u0902\u0924 \u0938\u093f\u0928\u094d\u0939\u093e \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0906 \u092b\u0947\u0915 \u091f\u094d\u0935\u0940\u091f",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-bjp-has-not-launched-its-political-entity-in-sri-lanka-viral-claim-is-fake/",
        headline:
          "Fact check: BJP has not launched its political entity in Sri Lanka, viral claim is fake",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-video-of-tornado-from-kenya-falsely-passed-of-as-from-west-bengal/",
        headline:
          "Fact Check: Video of Tornado From Kenya Falsely Passed of As From West Bengal",
      },
    ],
    "2": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/mamata-banerjee-west-bengal-old-image-soumitra-chatterjee-funeral-bandaged-foot-west-bengal-assembly-elections-2021-nandigram-12302",
        headline:
          "Old Image Of CM Mamata Banerjee With Bandaged Foot Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/old-image-peddled-as-mamata-banerjees-injured-leg-12312",
        headline: "Old Image Peddled As Mamata Banerjee's Injured Leg",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/amit-shah-asaduddin-owaisi-edited-image-west-bengal-assembly-elections-2021-akbaruddin-owaisi-12318",
        headline:
          "Edited Image Of Amit Shah And Owaisi Shared Ahead Of Assembly Elections",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/hariyana-trust-vote-manohar-lal-khattar-bhupinder-singh-hooda-kisan-andolan-old-picture-12314",
        headline: "           ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/fact-check-west-bengal-vote-2021-bjp-leader-welcomed-by-shoe-garland-during-poll-campaign-madhya-pradesh-old-video-12250",
        headline:
          "\u09ae\u09a7\u09cd\u09af\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09a8\u09c7\u09a4\u09be\u0995\u09c7 \u099c\u09c1\u09a4\u09cb\u09b0 \u09ae\u09be\u09b2\u09be \u09a6\u09c7\u0993\u09df\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09a6\u09c3\u09b6\u09cd\u09af \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/mamata-banerjee-west-bengal-old-image-soumitra-chatterjee-funeral-bandaged-foot-west-bengal-assembly-elections-2021-nandigram-12305",
        headline:
          "\u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09aa\u09be\u09df\u09c7 \u09ac\u09cd\u09af\u09be\u09a8\u09cd\u09a1\u09c7\u099c \u09ac\u09be\u0981\u09a7\u09be \u099b\u09ac\u09bf\u099f\u09bf \u09a8\u09a8\u09cd\u09a6\u09c0\u0997\u09cd\u09b0\u09be\u09ae \u0998\u099f\u09a8\u09be\u09b0 \u0986\u0997\u09c7\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/mamata-banerjee-old-image-injured-leg-viral-image-fake-news-12322",
        headline:
          "\u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u099c\u0996\u09ae\u09bf \u09aa\u09be \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09a8\u09cb \u09b9\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-nios-not-introducing-gita-ramayana-in-madrasas-675313",
        headline: "Fact Check: NIOS not introducing Gita, Ramayana in madrasas",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-beef-promise-returns-to-haunt-kerala-bjp-leader-675256",
        headline:
          "Fact check: Old beef promise returns to haunt Kerala BJP leader",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-miscreants-replace-arvind-kumar-with-amit-shah-in-owaisi-picture-675335",
        headline:
          "Fact check: Miscreants replace Arvind Kumar with Amit Shah in Owaisi picture",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-for-the-record-man-pleading-mercy-is-not-bsp-member-675387",
        headline:
          "FACT CHECK: For the record, man pleading mercy is not BSP member",
      },
      {
        url:
          "https://digiteye.in/bjp-leaders-old-beef-remark-resurfaces-ahead-of-kerala-assembly-elections-fact-check/",
        headline:
          "BJP Leaders old beef remark resurfaces ahead of Kerala Assembly elections; Fact Check",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/09/fact-check-this-video-does-not-show-former-bsp-mp-surrendering-in-front-of-police/",
        headline:
          "Fact Check: This Video Does NOT Show Former BSP MP Surrendering In Front Of Police",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/10/fact-check-artwork-by-a-3d-artist-falsely-shared-as-a-fountain-resembling-a-womans-head-in-japan/",
        headline:
          "Fact Check: Artwork By A 3D Artist Falsely Shared As A Fountain Resembling A Woman\u2019s Head In Japan",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/13/fact-check-old-picture-of-amit-shah-shared-with-false-context/",
        headline:
          "Fact Check: Old Picture Of Amit Shah Shared With False Context",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/13/fact-check-amit-shah-did-not-meet-aimim-chief-asaduddin-owaisi-picture-is-morphed/",
        headline:
          "Fact Check: Amit Shah Did Not Meet AIMIM Chief Asaduddin Owaisi; Picture Is Morphed",
      },
      {
        url:
          "https://factly.in/telugu-old-video-shared-as-bjp-leaders-in-kerala-are-protesting-against-hike-in-petrol-prices/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42, \u0c2a\u0c46\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c27\u0c30\u0c32\u0c15\u0c41 \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c15\u0c47\u0c30\u0c33 \u0c32\u0c4b \u0c24\u0c3e\u0c1c\u0c3e\u0c17\u0c3e \u0c27\u0c30\u0c4d\u0c28\u0c3e \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 BJP \u0c28\u0c47\u0c24\u0c32\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/2019-lok-sabha-election-schedule-is-falsely-shared-as-that-of-upcoming-panchayat-election-in-uttar-pradesh/",
        headline:
          " 2019 Lok Sabha election schedule is falsely shared as that of the upcoming Panchayat election in Uttar Pradesh",
      },
      {
        url:
          "https://factly.in/telugu-andhrajyothy-and-velugu-did-not-publish-articles-about-bandi-sanjay-assets-600-crores/",
        headline:
          " \u2018\u0c2c\u0c02\u0c21\u0c3f \u0c38\u0c02\u0c1c\u0c2f\u0c4d \u0c06\u0c38\u0c4d\u0c24\u0c41\u0c32\u0c41 600 \u0c15\u0c4b\u0c1f\u0c4d\u0c32\u0c41\u2019 \u0c05\u0c28\u0c3f \u2018\u0c35\u0c46\u0c32\u0c41\u0c17\u0c41\u2019 \u0c2e\u0c30\u0c3f\u0c2f\u0c41 \u2018\u0c06\u0c02\u0c27\u0c4d\u0c30\u0c1c\u0c4d\u0c2f\u0c4b\u0c24\u0c3f\u2019 \u0c06\u0c30\u0c4d\u0c1f\u0c3f\u0c15\u0c32\u0c4d\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c1a\u0c41\u0c30\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/the-person-falling-at-the-feet-of-the-police-and-surrendering-in-this-video-is-not-a-former-bsp-mp/",
        headline:
          " The person falling at the feet of the police and surrendering in this video is not a former BSP MP",
      },
      {
        url:
          "https://factly.in/telugu-tv9-did-not-telecast-any-news-about-bandi-sanjay-granite-scam/",
        headline:
          " \u2018\u0c2c\u0c02\u0c21\u0c3f \u0c38\u0c02\u0c1c\u0c2f\u0c4d \u0c17\u0c4d\u0c30\u0c3e\u0c28\u0c48\u0c1f\u0c4d \u0c38\u0c4d\u0c15\u0c3e\u0c02\u2019 \u0c05\u0c02\u0c1f\u0c42 \u0c1f\u0c40\u0c35\u0c409 \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c35\u0c3e\u0c30\u0c4d\u0c24\u0c28\u0c41 \u0c1f\u0c46\u0c32\u0c3f\u0c15\u0c3e\u0c38\u0c4d\u0c1f\u0c4d \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-free-ride-scheme-not-started-by-indore-police-message-is-fake/",
        headline:
          "Fact Check : \u0907\u0902\u0926\u094c\u0930 \u092a\u0941\u0932\u093f\u0938 \u0915\u0940 \u0913\u0930 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0936\u0941\u0930\u0942 \u0915\u0940 \u0917\u0908 \u092b\u094d\u0930\u0940 \u0938\u0935\u093e\u0930\u0940 \u092f\u094b\u091c\u0928\u093e, \u092b\u0947\u0915 \u0939\u0948 \u092e\u0948\u0938\u0947\u091c",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-an-edited-image-of-amit-shah-and-owaisi-brother-is-being-shared-with-false-context-before-bengal-assembly-elections/",
        headline:
          "Fact Check: \u092c\u0902\u0917\u093e\u0932 \u091a\u0941\u0928\u093e\u0935 \u0915\u094b \u0932\u0947\u0915\u0930 \u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0914\u0930 \u0913\u0935\u0948\u0938\u0940 \u092c\u0902\u0927\u0941\u0913\u0902 \u0915\u0940 \u092e\u0941\u0932\u093e\u0915\u093e\u0924 \u0915\u0940 \u090f\u0921\u093f\u091f\u0947\u0921 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u094b \u0930\u0939\u0940 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-ex-election-commissioner-did-not-give-this-viral-statement-on-evm-hacking-the-post-is-fake/",
        headline:
          "Fact Check : \u0908\u0935\u0940\u090f\u092e \u0939\u0948\u0915\u093f\u0902\u0917 \u092a\u0930 \u092a\u0942\u0930\u094d\u0935 \u091a\u0941\u0928\u093e\u0935 \u0906\u092f\u0941\u0915\u094d\u200d\u0924 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e \u092f\u0939 \u0935\u093e\u092f\u0930\u0932 \u092c\u092f\u093e\u0928, \u092a\u094b\u0938\u094d\u200d\u091f \u092b\u0930\u094d\u091c\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-company-is-not-giving-free-fridge-full-of-goodies-viral-post-is-fake/",
        headline:
          "Fact Check: \u092f\u0939 \u0915\u0902\u092a\u0928\u0940 100\u0935\u0940\u0902 \u0935\u0930\u094d\u0937\u0917\u093e\u0902\u0920 \u0915\u0947 \u092e\u094c\u0915\u0947 \u092a\u0930 \u0928\u0939\u0940\u0902 \u0926\u0947 \u0930\u0939\u0940 \u092e\u0941\u092b\u094d\u0924 \u092e\u093f\u0928\u0940 \u092b\u094d\u0930\u093f\u091c, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0938\u094d\u0915\u0948\u092e \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-old-photograph-of-mamata-banerjee-is-being-circulated-as-the-latest-one-after-an-attack-on-her/",
        headline:
          "Fact Check : \u092e\u092e\u0924\u093e \u092c\u0928\u0930\u094d\u091c\u0940 \u0915\u0947 \u092a\u0948\u0930 \u092e\u0947\u0902 \u0932\u0917\u0940 \u091a\u094b\u091f \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u200d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-old-picture-of-hardik-patel-getting-his-head-shaved-going-viral-with-fake-claim/",
        headline:
          "Fact Check: Old Picture of Hardik Patel Getting His Head Shaved Going Viral With Fake Claim",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-no-this-company-isnt-giving-away-mini-fridges-for-its-100th-anniversary-viral-post-is-a-scam/",
        headline:
          "Fact Check: No, This Company Isn\u2019t Giving Away Mini Fridges for its 100th Anniversary; Viral Post Is A Scam",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-ex-election-commissioner-did-not-give-this-statement-on-evm-hacking-viral-post-is-fake/?itm_source=homepage&itm_medium=dktp_s3&itm_campaign=editorpick",
        headline:
          "Fact Check: Ex-Election Commissioner Did Not Give This Statement On EVM Hacking, Viral Post Is Fake",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-adidas-is-not-giving-away-shoes-as-womens-day-gift-viral-claim-is-fake/",
        headline:
          "Fact Check: Adidas is not giving away shoes as Women\u2019s Day gift, viral claim is fake",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/no-this-video-does-not-show-killings-caught-on-camera-12245",
        headline: "No, This Video Does Not Show Killings Caught On Camera",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/congress-shares-photos-from-taiwan-tea-garden-as-assam-12248",
        headline:
          "\u0995\u0982\u0997\u09cd\u09b0\u09c7\u09b8 \u09a4\u09be\u0987\u0993\u09df\u09be\u09a8\u09c7\u09b0 \u099a\u09be \u09ac\u09be\u0997\u09bf\u099a\u09be\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u0985\u09b8\u09ae\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/sourav-ganguly-fake-posters-edited-posters-viral-image-west-bengal-assembly-elections-2021-bjp-tmc-cpim-12280",
        headline: "Fake Edited Posters Welcome Sourav Ganguly Into Politics",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2019s-news-of-11-bjp-workers-arrested-by-police-from-brothel-revived-narendra-modi-brigade-rally-west-bengal-assembly-election-12269",
        headline:
          "\u09aa\u09a4\u09bf\u09a4\u09be\u09b2\u09df\u09c7 \u09af\u09be\u0993\u09df\u09be \u09e7\u09e7 \u099c\u09a8 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u0995\u09b0\u09cd\u09ae\u09c0\u09b0 \u0997\u09cd\u09b0\u09c7\u09ab\u09a4\u09be\u09b0\u09c7\u09b0 \u098f\u0987 \u0996\u09ac\u09b0\u099f\u09bf \u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-morphed-photo-show-sourav-ganguly-endorsing-trinamul-congress-675273",
        headline:
          "Fact Check: Morphed photo show Sourav Ganguly endorsing Trinamul Congress",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-v6-exit-poll-survey-of-mlc-elections-showing-trs-leading-is-fake-675487",
        headline:
          "Fact Check: V6 exit poll survey of MLC elections showing TRS leading is fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/09/fact-check-no-the-us-has-not-restored-preferential-trade-status-for-india-under-gsp/",
        headline:
          "Fact Check: No, The US Has NOT Restored Preferential Trade Status For India Under GSP",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/09/fact-check-sourav-ganguly-has-not-endorsed-bjp-viral-picture-is-morphed/",
        headline:
          "Fact Check: Sourav Ganguly Has NOT Endorsed BJP; Viral Picture Is Morphed",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/10/fact-check-does-this-picture-show-the-woman-who-posed-for-statue-of-liberty-heres-the-truth/",
        headline:
          "Fact Check: Does This Picture Show The Woman Who Posed For Statue of Liberty? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/10/fact-check-video-of-a-film-shoot-passed-off-as-murder-caught-on-camera-in-mumbai/",
        headline:
          "Fact Check: Video Of A Film Shoot Passed Off As Murder Caught On Camera In Mumbai",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/11/fact-check-does-this-video-show-women-performing-ram-bhajans-in-dubai-mosque-heres-the-truth/",
        headline:
          "Fact Check: Does This Video Show Women Performing \u2018Ram Bhajans\u2019 in Dubai Mosque? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/telugu-this-lord-vishnu-statue-was-not-unearthed-during-granite-excavations/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c02 \u0c17\u0c4d\u0c30\u0c3e\u0c28\u0c48\u0c1f\u0c4d \u0c24\u0c35\u0c4d\u0c35\u0c15\u0c3e\u0c32\u0c32\u0c4b \u0c2c\u0c2f\u0c1f\u0c2a\u0c21\u0c3f\u0c28\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-a-state-specific-blood-on-call-104-service-in-maharashtra-is-being-shared-as-a-pan-indian-service/",
        headline:
          " 104 \u0c39\u0c46\u0c32\u0c4d\u0c2a\u0c4d \u0c32\u0c48\u0c28\u0c4d \u0c28\u0c46\u0c02\u0c2c\u0c30\u0c4d \u0c24\u0c4b \u2018\u0c2c\u0c4d\u0c32\u0c21\u0c4d \u0c06\u0c28\u0c4d \u0c15\u0c3e\u0c32\u0c4d\u2019 \u0c05\u0c28\u0c47 \u0c38\u0c47\u0c35\u0c32\u0c28\u0c3f \u0c2e\u0c39\u0c3e\u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c05\u0c2e\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c4b\u0c02\u0c26\u0c3f.",
      },
      {
        url:
          "https://factly.in/telugu-this-video-showing-christian-prayers-on-tirumala-hill-is-not-during-ysrcp-government/",
        headline:
          " \u2018\u0c24\u0c3f\u0c30\u0c41\u0c2e\u0c32\u0c32\u0c4b \u0c05\u0c28\u0c4d\u0c2f\u0c2e\u0c24 \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02\u2019 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c2f\u0c2c\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c35\u0c48\u0c38\u0c40\u0c2a\u0c40 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35 \u0c38\u0c2e\u0c2f\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-does-not-show-muslim-women-singing-ram-bhajan-at-a-masjid-in-dubai/",
        headline:
          " This video does not show Muslim women singing Ram bhajan at a Masjid in Dubai",
      },
      {
        url:
          "https://factly.in/telugu-convenient-data-is-selected-to-claim-india-performed-better-under-manmohan-singh-than-modi/",
        headline:
          " \u0c2e\u0c28\u0c4d\u0c2e\u0c4b\u0c39\u0c28\u0c4d \u0c38\u0c3f\u0c02\u0c17\u0c4d \u0c39\u0c2f\u0c3e\u0c02\u0c32\u0c4b \u0c05\u0c28\u0c41\u0c15\u0c42\u0c32\u0c02\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28 \u0c17\u0c23\u0c3e\u0c02\u0c15\u0c3e\u0c32\u0c28\u0c41 , \u0c2e\u0c4b\u0c26\u0c40 \u0c39\u0c2f\u0c3e\u0c02\u0c32\u0c4b \u0c05\u0c02\u0c24\u0c17\u0c3e \u0c05\u0c28\u0c41\u0c15\u0c42\u0c32\u0c02\u0c17\u0c3e \u0c32\u0c47\u0c28\u0c3f \u0c17\u0c23\u0c3e\u0c02\u0c15\u0c3e\u0c32\u0c24\u0c4b \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4b\u0c32\u0c41\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-disinvestments-in-psus-did-not-start-under-manmohan-singh-government/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35 \u0c30\u0c02\u0c17 \u0c38\u0c02\u0c38\u0c4d\u0c25\u0c32\u0c4d\u0c32\u0c4b \u0c35\u0c3e\u0c1f\u0c3e\u0c32\u0c41 \u0c35\u0c3f\u0c15\u0c4d\u0c30\u0c2f\u0c3f\u0c02\u0c1a\u0c21\u0c02 \u0c2e\u0c4a\u0c26\u0c32\u0c41\u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c02\u0c26\u0c3f \u0c2e\u0c28\u0c4d\u0c2e\u0c4b\u0c39\u0c28\u0c4d \u0c38\u0c3f\u0c02\u0c17\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-dalits-converted-into-christianity-or-islam-are-ineligible-for-reservation-under-scheduled-caste/",
        headline:
          " \u0c39\u0c3f\u0c02\u0c26\u0c42, \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41, \u0c2e\u0c30\u0c3f\u0c2f\u0c41 \u0c2c\u0c4c\u0c26\u0c4d\u0c27 \u0c2e\u0c24\u0c02 \u0c15\u0c3e\u0c15\u0c41\u0c02\u0c21\u0c3e \u0c07\u0c24\u0c30 \u0c2e\u0c24\u0c3e\u0c32 \u0c35\u0c3e\u0c30\u0c3f\u0c15\u0c3f \u0c37\u0c46\u0c21\u0c4d\u0c2f\u0c42\u0c32\u0c4d\u0c21\u0c4d \u0c15\u0c41\u0c32\u0c02 \u0c05\u0c30\u0c4d\u0c39\u0c24 \u0c09\u0c02\u0c21\u0c26\u0c28\u0c3f \u2018Constitution (Scheduled Castes) Order,1950\u2019 \u0c32\u0c4b\u0c28\u0c47 \u0c09\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/only-those-statistics-in-favour-of-manmohan-singhs-tenure-are-being-compared-with-those-not-so-in-favour-of-modis-tenure/",
        headline:
          " Only those statistics favouring Manmohan Singh\u2019s tenure are being compared with those not favouring Modi\u2019s tenure",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-us-has-not-restored-indias-gsp-status-yet-viral-claim-is-fake/",
        headline:
          "Fact Check: \u0905\u092e\u0947\u0930\u093f\u0915\u093e \u0928\u0947 \u0905\u092c\u0924\u0915 \u092d\u093e\u0930\u0924 \u0915\u093e GSP \u0938\u094d\u091f\u0947\u091f\u0938 \u092c\u0939\u093e\u0932 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e, \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u092b\u0930\u094d\u091c\u0940 \u0926\u093e\u0935\u093e",
      },
      {
        url:
          "https://www.vishvasnews.com/english/world/fact-check-image-of-woman-being-shared-is-not-related-to-statute-of-liberty-viral-post-is-fake/",
        headline:
          "Fact Check: Image of Woman Being Shared Is Not Related To Statue of Liberty, Viral Post is Fake",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-narendra-modi-letter-yogi-adityanath-uttar-pradesh-hindu-rashtra-fake-letter-factcheck-12242",
        headline:
          "Fake Letter Claims PM Wrote To Yogi Adityanath About A Hindu Rashtra",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-morphed-image-shows-mukesh-ambanis-photo-hanging-on-pms-office-walls-675223",
        headline:
          "Fact Check: Morphed image shows Mukesh Ambani's photo hanging on PM's office walls",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/coronavirus-outbreak/tarun-kothari-biswaroop-chaudhary-covid-19-vaccine-hesitancy-misinformation-12264",
        headline: "COVID-19 Vaccine: How Anti-Vaxxers Are Spreading Panic",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rahul-gandhi-reading-kannada-newspaper-national-herald-commemorative-edition-in-bengaluru-12319",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915: \u0905\u0959\u092c\u093e\u0930 \u092a\u095d\u0924\u0947 \u0939\u0941\u090f \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0902\u0927\u0940 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094d\u092f\u094b\u0902 \u0935\u093e\u092f\u0930\u0932 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-west-bengal-assembly-elections-2021-fake-graphic-abp-ananda-reports-bjp-workers-alleged-for-not-receiving-cash-to-attend-brigade-rally-12325",
        headline:
          "\u09ac\u09bf\u0997\u09cd\u09b0\u09c7\u09a1 \u09af\u09be\u0993\u09df\u09be \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u0995\u09b0\u09cd\u09ae\u09c0\u09b0\u09be \u09ae\u099c\u09c1\u09b0\u09bf \u09aa\u09be\u09df\u09a8\u09bf \u098f\u09ac\u09bf\u09aa\u09bf \u0986\u09a8\u09a8\u09cd\u09a6\u09c7\u09b0 \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-edited-of-billboard-shows-queen-elizabeth-thanking-modi-for-covid-19-vaccines-675461",
        headline:
          "Fact Check: Edited of billboard shows Queen Elizabeth thanking Modi for COVID-19 vaccines",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-in-the-election-season-fake-modi-letter-stages-a-comeback-675243",
        headline:
          "FACT CHECK: In the election season, fake Modi letter stages a comeback",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-kejriwal-makes-it-us-hoardingvia-photoshop-675391",
        headline:
          "Fact check: Kejriwal makes it to US hoarding\u2026.via photoshop",
      },
      {
        url:
          "https://digiteye.in/top-4-covid-19-vaccines-are-chinese-made-claims-viral-post-fact-check/",
        headline:
          "Top 4 Covid-19 vaccines are Chinese-made, claims viral post; Fact Check",
      },
      {
        url:
          "https://digiteye.in/queens-billboard-thanking-pm-modi-for-covid-vaccine-goes-viral-fact-check/",
        headline:
          "Queens billboard thanking PM Modi for Covid Vaccine goes viral; Fact Check",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/08/fact-check-man-praising-pm-modi-in-old-video-falsely-claimed-to-be-cong-mla/",
        headline:
          "Fact Check: Man Praising PM Modi In Old Video Falsely Claimed To Be Cong MLA",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/08/fact-check-pm-modi-was-not-spotted-with-tainted-bjp-leader-pamela-goswami-photo-is-edited/",
        headline:
          "Fact Check: PM Modi Was Not Spotted With Tainted BJP Leader Pamela Goswami; Photo Is Edited",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/10/fact-check-this-photo-in-pm-modis-office-is-morphed-see-the-original-picture-here/",
        headline:
          "Fact Check: This Photo In PM Modi\u2019s Office Is Morphed; See The Original Picture Here",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/14/fact-check-did-the-queen-thank-pm-modi-for-covid-19-vaccines-heres-the-truth/",
        headline:
          "Fact Check: Did The Queen Thank PM Modi For Covid-19 Vaccines? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/14/fact-check-find-the-truth-about-this-hoarding-featuring-pm-modi/",
        headline:
          "Fact Check: Find The Truth About This Hoarding Featuring PM Modi",
      },
      {
        url:
          "https://factly.in/telugu-a-man-named-dhruv-patel-did-not-make-any-chemical-to-kill-people-who-eat-beef/",
        headline:
          " \u0c2a\u0c36\u0c41\u0c35\u0c41 \u0c2e\u0c3e\u0c02\u0c38\u0c02 \u0c24\u0c3f\u0c02\u0c1f\u0c47 \u0c2e\u0c28\u0c41\u0c37\u0c41\u0c32\u0c41 \u0c1a\u0c28\u0c3f\u0c2a\u0c4b\u0c2f\u0c47\u0c32\u0c3e \u0c30\u0c38\u0c3e\u0c2f\u0c28\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c27\u0c43\u0c35\u0c4d \u0c2a\u0c1f\u0c47\u0c32\u0c4d \u0c05\u0c28\u0c47 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c0e\u0c15\u0c4d\u0c15\u0c21\u0c3e \u0c38\u0c2e\u0c3e\u0c1a\u0c3e\u0c30\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/morphed-image-falsely-shared-as-mukesh-ambani-picture-inside-pm-modi-office/",
        headline:
          " Morphed image falsely shared as Mukesh Ambani\u2019s picture inside PM Modi\u2019s office",
      },
      {
        url:
          "https://factly.in/rahul-gandhi-mentions-various-sources-not-just-islam-from-where-mahatma-gandhi-picked-up-the-idea-of-non-violence/",
        headline:
          " Rahul Gandhi mentions various sources, not just Islam, from where Mahatma Gandhi picked up the idea of non-violence",
      },
      {
        url:
          "https://factly.in/morphed-image-falsely-shared-as-a-truck-carrying-an-anti-congress-message-on-its-signboard/",
        headline:
          " Morphed image shared as a truck carrying anti-Congress message on its signboard",
      },
      {
        url:
          "https://factly.in/pm-modi-is-not-the-first-politician-to-receive-covid-19-vaccination-in-india/",
        headline:
          " PM Modi is not the first politician to receive COVID-19 vaccination in India",
      },
      {
        url:
          "https://factly.in/edited-image-shared-as-a-billboard-showing-the-queen-thanking-pm-modi-for-sending-vaccines-to-the-uk/",
        headline:
          " Edited image shared as a billboard showing the Queen thanking PM Modi for sending vaccines to the UK",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-rahul-gandhi-was-reading-an-english-newspaper-not-a-kannada-one/",
        headline:
          "Quick Fact Check : \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0902\u0927\u0940 \u0915\u0928\u094d\u200d\u0928\u095c \u0915\u093e \u0928\u0939\u0940\u0902, \u0905\u0902\u0917\u094d\u0930\u0947\u091c\u0940 \u0915\u093e \u0905\u0916\u092c\u093e\u0930 \u092a\u0922\u093c \u0930\u0939\u0947 \u0925\u0947",
      },
      {
        url:
          "https://www.vishvasnews.com/world/fact-check-canada-protest-video-viral-with-fake-claim-indian-canadians-marched-with-flag-after-covid-vaccine/",
        headline:
          "Fact Check: \u0915\u0928\u093e\u0921\u093e \u092e\u0947\u0902 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u092d\u093e\u0930\u0924 \u0938\u0947 \u0935\u0948\u0915\u094d\u0938\u0940\u0928 \u092e\u093f\u0932\u0928\u0947 \u0915\u0940 \u0959\u0941\u0936\u0940 \u092e\u0947\u0902 \u0928\u093f\u0915\u093e\u0932\u0940 \u0917\u092f\u0940 \u0930\u0948\u0932\u0940 \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/english/health/quick-fact-check-fake-post-claiming-who-listed-out-7-brain-damaging-habits-resurfaces/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Quick Fact Check: Fake Post Claiming WHO Listed Out 7 Brain Damaging Habits Resurfaces",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-edited-picture-of-pm-modi-and-sachin-tendulkars-meeting-going-viral/",
        headline:
          "Fact Check: Edited Picture of PM Modi and Sachin Tendulkar\u2019s Meeting Going Viral",
      },
    ],
    "5": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bhagat-singh-last-sister-prakash-kaur-death-rumour-viral-toronto-canada-2014-news-12241",
        headline:
          "\u0915\u094d\u092f\u093e \u092d\u0917\u0924 \u0938\u093f\u0902\u0939 \u0915\u0940 \u0905\u0902\u0924\u093f\u092e \u091c\u0940\u0935\u093f\u0924 \u092c\u0939\u0928 \u0915\u093e \u0939\u093e\u0932 \u092e\u0947\u0902 \u0926\u0947\u0939\u093e\u0902\u0924 \u0939\u0941\u0906 \u0939\u0948?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-indian-armys-sena-jal-yet-to-make-commercial-debut-675200",
        headline:
          "Fact check: Indian army's 'Sena Jal' yet to make commercial debut",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-old-photo-manohar-lal-khattar-bhupinder-singh-hooda-no-confidence-motion-haryana-assembly-farmers-protest-congress-bjp-12313",
        headline:
          "Haryana Trust Vote: Old Photo Of CM Khattar And Hooda Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-122-muslim-men-spend-20-years-in-prison-for-crime-never-committed-gujarat-high-court-judiciary-fake-news-12258",
        headline:
          "Claim About 122 Muslims Acquitted After 20 Years In Prison Is Misleading",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-rajanth-singh-old-clip-cropped-clip-farmers-protest-farm-bills-bjp-12297",
        headline:
          "2015 Clip Of Rajnath Singh Passed Off As Support To Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/no,-badruddin-ajmal-did-not-say-india-will-become-an-islamic-state-12281",
        headline:
          "No, Badruddin Ajmal Did Not Say India Will Become An Islamic State",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/aiudf-badruddin-ajmal-islamic-nation-mughal-viral-video-congress-bjp-assam-election-12284",
        headline:
          "\u0915\u094d\u092f\u093e \u092c\u0926\u0930\u0941\u0926\u094d\u0926\u0940\u0928 \u0905\u091c\u092e\u0932 \u0928\u0947 \u092d\u093e\u0930\u0924 \u0915\u094b \u0907\u0938\u094d\u0932\u093e\u092e\u093f\u0915 \u0930\u093e\u0937\u094d\u091f\u094d\u0930 \u092c\u0928\u093e\u0928\u0947 \u0915\u0940 \u092c\u093e\u0924 \u0915\u0939\u0940?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/sourav-ganguly-swagtam-dada-poster-bjp-tmc-west-bengal-election-12268",
        headline: "   ' '         ?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/west-bengal-assembly-vote-2021-fake-post-of-actor-jisshu-sengupta-with-actor-turned-bjp-politician-rudranil-ghosh-and-mithun-chakraborty-viral-cobra-chameleon-12323",
        headline:
          "\u09af\u09bf\u09b6\u09c1 \u09b8\u09c7\u09a8\u0997\u09c1\u09aa\u09cd\u09a4\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09ae\u09bf\u09a0\u09c1\u09a8 \u0993 \u09b0\u09c1\u09a6\u09cd\u09b0\u09a8\u09c0\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf\u09b0 \u09ad\u09c1\u09df\u09cb \u09ab\u09c7\u09b8\u09ac\u09c1\u0995 \u09aa\u09cb\u09b8\u09cd\u099f \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-122-muslim-men-spend-20-years-in-prison-for-crime-never-committed-gujarat-high-court-judiciary-fake-news-12266",
        headline:
          "\u09e8\u09e6 \u09ac\u099b\u09b0 \u099c\u09c7\u09b2 \u0996\u09c7\u099f\u09c7 \u09e7\u09e8\u09e8 \u099c\u09a8 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u09a8\u09bf\u09b0\u09cd\u09a6\u09cb\u09b7\u2014\u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-narendra-modi-letter-yogi-adityanath-uttar-pradesh-hindu-rashtra-fake-letter-factcheck-12279",
        headline:
          "\u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0 \u09a8\u09bf\u09df\u09c7 \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u0995\u09c7 \u09b2\u09c7\u0996\u09be \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0\u09b0 \u099a\u09bf\u09a0\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-aiudf-badruddin-ajmal-assam-islamic-state-india-doctored-cropped-old-video-assam-election-2021-12282",
        headline:
          "\u09a8\u09be, \u09ac\u09a6\u09b0\u09c1\u09a6\u09cd\u09a6\u09bf\u09a8 \u0986\u099c\u09ae\u09b2 \u09ac\u09b2\u09c7\u09a8\u09a8\u09bf \u09ad\u09be\u09b0\u09a4 \u098f\u0995\u099f\u09bf \u0987\u09b8\u09b2\u09be\u09ae\u09bf\u0995 \u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0 \u09b9\u09ac\u09c7",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2019-photo-of-haryana-cm-with-cong-leader-falsely-linked-to-recent-trust-vote-675460",
        headline:
          "Fact Check: 2019 photo of Haryana CM with Cong leader falsely linked to recent trust vote",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-rajnath-singh-did-not-come-out-in-support-of-protesting-farmers-675436",
        headline:
          "Fact Check: No, Rajnath Singh did not come out in support of protesting farmers",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/09/fact-check-inspired-by-ongoing-farmers-protest-in-india-did-farmers-in-spain-protest-as-well/",
        headline:
          "Fact Check: Inspired By Ongoing Farmers\u2019 Protest In India, Did Farmers In Spain Protest As Well?",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/11/fact-check-morphed-picture-of-girl-holding-placard-shared-with-false-claim/",
        headline:
          "Fact Check: Morphed Picture Of Girl Holding Placard Shared With False Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/11/fact-check-ahead-of-upcoming-assam-polls-edited-video-of-aiudf-chief-badruddin-ajmal-viral-with-false-claim/",
        headline:
          "Fact Check: Ahead Of Upcoming Assam Polls, Edited Video Of AIUDF Chief Badruddin Ajmal Viral With False Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/14/fact-check-this-photo-of-haryana-cm-ml-khattar-with-former-cm-cong-leader-bs-hooda-is-old/",
        headline:
          "Fact Check: This Photo Of Haryana CM ML Khattar With Former CM & Cong Leader BS Hooda Is Old",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/14/fact-check-this-letter-is-not-issued-by-home-ministry-heres-the-truth/",
        headline:
          "Fact Check: This Letter Attributed To Home Ministry Is Fake; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/all-the-first-five-education-ministers-of-india-are-not-from-the-same-community/",
        headline:
          " All the first five education ministers of India are not from the Muslim community",
      },
      {
        url:
          "https://factly.in/telugu-the-state-of-israel-does-not-have-a-written-constitution/",
        headline:
          " \u0c07\u0c1c\u0c4d\u0c30\u0c3e\u0c2f\u0c46\u0c32\u0c4d \u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c12\u0c15 \u0c35\u0c4d\u0c30\u0c3e\u0c24\u0c2a\u0c42\u0c30\u0c4d\u0c35\u0c15 \u0c30\u0c3e\u0c1c\u0c4d\u0c2f\u0c3e\u0c02\u0c17\u0c02 \u0c32\u0c47\u0c26\u0c41, \u0c15\u0c3e\u0c2c\u0c1f\u0c4d\u0c1f\u0c3f \u0c07\u0c1c\u0c4d\u0c30\u0c3e\u0c2f\u0c46\u0c32\u0c4d \u0c30\u0c3e\u0c1c\u0c4d\u0c2f\u0c3e\u0c02\u0c17\u0c02\u0c32\u0c4b \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c2a\u0c4a\u0c17\u0c41\u0c21\u0c41\u0c24\u0c42 \u0c30\u0c3e\u0c38\u0c3e\u0c30\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c26\u0c28 \u0c24\u0c2a\u0c4d\u0c2a\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-killing-of-a-hindu-family-in-pakistan-is-shared-with-a-false-communal-narrative/",
        headline:
          " \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c08 \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c15\u0c41\u0c1f\u0c41\u0c02\u0c2c\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c1a\u0c02\u0c2a\u0c3f\u0c02\u0c26\u0c3f \u0c05\u0c15\u0c4d\u0c15\u0c21\u0c3f \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/israel-has-no-written-constitution-to-write-great-words-about-india-on-its-first-page/",
        headline:
          " Israel has no written constitution to mention India on its first page",
      },
      {
        url:
          "https://factly.in/old-unrelated-video-portrayed-as-israel-army-attack-on-palestine-mosque/",
        headline:
          " Old unrelated video portrayed as Israel army\u2019s attack on a Palestinian Mosque",
      },
      {
        url:
          "https://factly.in/edited-video-falsely-shared-as-badruddin-ajmal-saying-india-will-become-an-islamic-nation/",
        headline:
          " Edited video falsely shared as Badruddin Ajmal saying India will become an Islamic nation",
      },
      {
        url:
          "https://factly.in/telugu-edited-video-falsely-shared-as-badruddin-ajmal-saying-india-will-become-an-islamic-nation/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c02 \u0c07\u0c38\u0c4d\u0c32\u0c3e\u0c2e\u0c3f\u0c15\u0c4d \u0c30\u0c3e\u0c1c\u0c4d\u0c2f\u0c02\u0c17\u0c3e \u0c2e\u0c3e\u0c30\u0c2c\u0c4b\u0c24\u0c41\u0c02\u0c26\u0c28\u0c3f AIDUF \u0c28\u0c47\u0c24 \u0c2c\u0c26\u0c4d\u0c30\u0c41\u0c26\u0c4d\u0c26\u0c40\u0c28\u0c4d \u0c05\u0c1c\u0c4d\u0c2e\u0c32\u0c4d \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c05\u0c28\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-old-picture-of-haryana-cm-khattar-and-ex-cm-bhupendra-hooda-viral-as-recent/",
        headline:
          "Fact Check: \u0939\u0930\u093f\u092f\u093e\u0923\u093e \u0915\u0947 \u092e\u0941\u0916\u094d\u092f\u092e\u0902\u0924\u094d\u0930\u0940 \u092e\u0928\u094b\u0939\u0930 \u0932\u093e\u0932 \u0916\u091f\u094d\u091f\u0930 \u0914\u0930 \u092a\u0942\u0930\u094d\u0935 \u0938\u0940\u090f\u092e \u092d\u0942\u092a\u0947\u0902\u0926\u094d\u0930 \u0938\u093f\u0902\u0939 \u0939\u0941\u0921\u094d\u0921\u093e \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0939\u093e\u0932 \u0915\u093e \u092c\u0924\u093e \u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-viral-clip-of-aiudfs-maulana-badaruddin-ajmal-is-edited/",
        headline:
          "Fact Check: AIUDF \u0928\u0947\u0924\u093e \u092c\u0926\u0930\u0941\u0926\u094d\u0926\u0940\u0928 \u0905\u091c\u092e\u0932 \u0915\u0940 \u092f\u0939 \u0935\u093e\u092f\u0930\u0932 \u0915\u094d\u0932\u093f\u092a \u090f\u0921\u093f\u091f\u0947\u0921 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-old-picture-of-haryana-cm-ml-khattar-and-ex-cm-bhupendra-hooda-viral-as-recent/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check: Old picture of Haryana CM ML Khattar and ex-CM Bhupendra Hooda viral as recent",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-viral-clip-of-aiudfs-maulana-badaruddin-ajmal-is-morphed/",
        headline:
          "Fact Check: Viral clip of AIUDF\u2019s Maulana Badaruddin Ajmal is morphed",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-old-video-of-rajnath-singh-going-viral-as-recent-with-fake-claim/",
        headline:
          "Fact Check: Old Video of Rajnath Singh Going Viral As Recent With Fake Claim",
      },
    ],
  },
  numpy_seed: 201,
  number_of_articles: 129,
}
