export const data = {
  mdsDat: {
    x: [
      -0.1736224489379663,
      -0.03995486890156195,
      0.25553143252580984,
      0.022279986846204827,
      0.24482560870620526,
      -0.30905971023869183,
    ],
    y: [
      0.38160682006959096,
      -0.21108370663415815,
      -0.05513016312114949,
      0.011843415103593415,
      0.05835444973481537,
      -0.18559081515269182,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      14.612024290044342,
      12.405190156705864,
      23.592547478572072,
      15.754478083030845,
      18.796601013306987,
      14.839158978339889,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      40.0,
      9.0,
      13.0,
      9.0,
      38.0,
      9.0,
      9.0,
      8.0,
      7.0,
      7.0,
      4.000041649399639,
      2.0000208246998197,
      1.0000104123499098,
      4.000041649399639,
      1.0000104123499098,
      2.0000208246998197,
      1.0000104123499098,
      2.0000208246998197,
      2.0000208246998197,
      2.0000208246998197,
      9.000093711149189,
      8.000083298799279,
      7.000072886449369,
      6.000062474099459,
      5.00005206174955,
      5.00005206174955,
      4.000041649399639,
      4.000041649399639,
      4.000041649399639,
      4.000041649399639,
      4.000041649399639,
      4.000041649399639,
      1.0000257586448777,
      3.0000772759346335,
      1.0000257586448777,
      2.0000515172897555,
      2.0000515172897555,
      1.0000257586448777,
      1.0000257586448777,
      3.0000772759346335,
      1.0000257586448777,
      5.0001287932243885,
      9.0002318278039,
      5.0001287932243885,
      5.0001287932243885,
      5.0001287932243885,
      4.000103034579511,
      4.000103034579511,
      4.000103034579511,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      2.000039186247981,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      2.000039186247981,
      13.00025471061188,
      7.000137151867935,
      6.000117558743945,
      6.000117558743945,
      6.000117558743945,
      4.000078372495962,
      4.000078372495962,
      4.000078372495962,
      4.000078372495962,
      4.000078372495962,
      8.000156744991925,
      21.000411455603807,
      19.00037226935582,
      8.000156744991925,
      7.000468087329359,
      7.000468087329359,
      5.0003343480924,
      5.0003343480924,
      9.000601826566319,
      9.000601826566319,
      4.00026747847392,
      2.00013373923696,
      2.00013373923696,
      1.00006686961848,
      6.0004012177108805,
      4.00026747847392,
      4.00026747847392,
      4.00026747847392,
      4.00026747847392,
      4.00026747847392,
      6.99964113847764,
      2.9998462022047025,
      3.999794936272937,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      7.999589872545874,
      5.999692404409405,
      5.999692404409405,
      5.999692404409405,
      5.999692404409405,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      19.998974681364682,
      13.99928227695528,
      3.999794936272937,
      0.9999310203865958,
      1.9998620407731915,
      1.9998620407731915,
      1.9998620407731915,
      0.9999310203865958,
      0.9999310203865958,
      1.9998620407731915,
      0.9999310203865958,
      0.9999310203865958,
      0.9999310203865958,
      6.9995171427061695,
      6.9995171427061695,
      6.9995171427061695,
      5.999586122319575,
      5.999586122319575,
      5.999586122319575,
      3.999724081546383,
      2.9997930611597874,
      2.9997930611597874,
      2.9997930611597874,
      2.9997930611597874,
      2.9997930611597874,
      4.999655101932978,
      3.999724081546383,
      3.999724081546383,
      3.999724081546383,
    ],
    Term: [
      "farmer",
      "capitol",
      "vaccine",
      "badaun",
      "protest",
      "jnu",
      "pride",
      "murder",
      "pilot",
      "kumbh",
      "pakistani_pilot",
      "debunk",
      "hindustan",
      "rahul_gandhi",
      "pilot_urinated",
      "badaun_gangrape",
      "qasem",
      "clear",
      "plane",
      "daughter",
      "badaun",
      "murder",
      "pilot",
      "case",
      "dawn",
      "unnao",
      "rahul",
      "unnao_badaun",
      "gandhi",
      "rape",
      "gangrape",
      "pakistani",
      "measure",
      "bhawan_palace",
      "drive",
      "verification",
      "vandalize",
      "save",
      "parody",
      "palace",
      "mistake",
      "capitol_hill",
      "capitol",
      "praise",
      "hill",
      "time",
      "adityanath",
      "yogi",
      "yogi_adityanath",
      "batman",
      "management",
      "umaid",
      "siege",
      "jodhpur",
      "time_magazine",
      "show_umaid",
      "army",
      "warn",
      "africans",
      "protest_delhi",
      "cost",
      "lawyer",
      "freedom",
      "microchip",
      "farooqi",
      "placard",
      "vaccine",
      "cover",
      "national",
      "geographic",
      "national_geographic",
      "related_farmer",
      "singhu_border",
      "border",
      "ex",
      "free",
      "covid_vaccine",
      "farmer",
      "protest",
      "covid",
      "hindu",
      "culture",
      "hindu_culture",
      "kolkata_pride",
      "jnu",
      "pride",
      "morgan",
      "warehouse",
      "tier",
      "mock",
      "kolkata",
      "economy",
      "walk",
      "anti",
      "stanley",
      "morgan_stanley",
      "tractor",
      "tour",
      "boris_johnson",
      "place",
      "thug",
      "take",
      "double",
      "political",
      "jalore",
      "vaccinate",
      "kumbh",
      "kumbh_mela",
      "mela",
      "stunt",
      "movement",
      "johnson",
      "tractor_stunt",
      "cancel",
      "cnn",
      "boris",
      "kisan",
      "farmer",
      "protest",
      "screenshot",
      "gulbarga",
      "bandh",
      "jan",
      "temple",
      "purportedly",
      "communal",
      "vijay",
      "narendra",
      "vizag",
      "passport",
      "asaduddin",
      "owaisi",
      "asaduddin_owaisi",
      "amit",
      "amit_shah",
      "shah",
      "greeting",
      "indian_soldier",
      "namaz",
      "offer",
      "kejriwal",
      "war",
      "morph",
      "bjp",
      "show",
      "indian",
    ],
    Total: [
      40.0,
      9.0,
      13.0,
      9.0,
      38.0,
      9.0,
      9.0,
      8.0,
      7.0,
      7.0,
      4.000041649399639,
      2.0000208246998197,
      1.0000104123499098,
      4.000041649399639,
      1.0000104123499098,
      2.0000208246998197,
      1.0000104123499098,
      2.0000208246998197,
      2.0000208246998197,
      2.0000208246998197,
      9.000093711149189,
      8.000083298799279,
      7.000072886449369,
      6.000062474099459,
      5.00005206174955,
      5.00005206174955,
      4.000041649399639,
      4.000041649399639,
      4.000041649399639,
      4.000041649399639,
      4.000041649399639,
      5.00006124252363,
      1.0000257586448777,
      3.0000772759346335,
      1.0000257586448777,
      2.0000515172897555,
      2.0000515172897555,
      1.0000257586448777,
      1.0000257586448777,
      3.0000772759346335,
      1.0000257586448777,
      5.0001287932243885,
      9.0002318278039,
      5.0001287932243885,
      5.0001287932243885,
      5.0001287932243885,
      4.000103034579511,
      4.000103034579511,
      4.000103034579511,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      3.0000772759346335,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      2.000039186247981,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      1.0000195931239906,
      2.000039186247981,
      13.00025471061188,
      7.000137151867935,
      6.000117558743945,
      6.000117558743945,
      6.000117558743945,
      4.000078372495962,
      4.000078372495962,
      4.000078372495962,
      4.000078372495962,
      4.000078372495962,
      9.000105479060158,
      40.99938613696849,
      38.99997354207478,
      12.000105730418149,
      7.000468087329359,
      7.000468087329359,
      5.0003343480924,
      5.0003343480924,
      9.000601826566319,
      9.000601826566319,
      4.00026747847392,
      2.00013373923696,
      2.00013373923696,
      1.00006686961848,
      6.0004012177108805,
      4.00026747847392,
      4.00026747847392,
      4.00026747847392,
      4.00026747847392,
      4.00026747847392,
      6.99964113847764,
      2.9998462022047025,
      3.999794936272937,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      0.9999487340682343,
      7.999589872545874,
      5.999692404409405,
      5.999692404409405,
      5.999692404409405,
      5.999692404409405,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      3.999794936272937,
      40.99938613696849,
      38.99997354207478,
      6.999826173322667,
      0.9999310203865958,
      1.9998620407731915,
      1.9998620407731915,
      1.9998620407731915,
      0.9999310203865958,
      0.9999310203865958,
      1.9998620407731915,
      0.9999310203865958,
      0.9999310203865958,
      0.9999310203865958,
      6.9995171427061695,
      6.9995171427061695,
      6.9995171427061695,
      5.999586122319575,
      5.999586122319575,
      5.999586122319575,
      3.999724081546383,
      2.9997930611597874,
      2.9997930611597874,
      2.9997930611597874,
      2.9997930611597874,
      2.9997930611597874,
      6.999614248351122,
      4.999672815614617,
      6.999801357481017,
      7.999897007031324,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.9233,
      1.7002,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      2.0871,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.4442,
      1.3265,
      0.7752,
      0.7251,
      1.0388,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.848,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      1.6715,
      0.9536,
      0.6469,
      1.1119,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.9079,
      1.5714,
      1.6848,
      1.3482,
      1.2147,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.8712,
      -4.5643,
      -5.2575,
      -3.8712,
      -5.2575,
      -4.5643,
      -5.2575,
      -4.5643,
      -4.5643,
      -4.5643,
      -3.0603,
      -3.1781,
      -3.3116,
      -3.4657,
      -3.6481,
      -3.6481,
      -3.8712,
      -3.8712,
      -3.8712,
      -3.8712,
      -3.8712,
      -3.8712,
      -5.0938,
      -3.9951,
      -5.0938,
      -4.4006,
      -4.4006,
      -5.0938,
      -5.0938,
      -3.9951,
      -5.0938,
      -3.4843,
      -2.8965,
      -3.4843,
      -3.4843,
      -3.4843,
      -3.7075,
      -3.7075,
      -3.7075,
      -3.9951,
      -3.9951,
      -3.9951,
      -3.9951,
      -3.9951,
      -3.9951,
      -3.9951,
      -5.7366,
      -5.7366,
      -5.7366,
      -5.0434,
      -5.7366,
      -5.7366,
      -5.7366,
      -5.7366,
      -5.7366,
      -5.0434,
      -3.1716,
      -3.7907,
      -3.9448,
      -3.9448,
      -3.9448,
      -4.3503,
      -4.3503,
      -4.3503,
      -4.3503,
      -4.3503,
      -3.6571,
      -2.692,
      -2.7921,
      -3.6571,
      -3.3868,
      -3.3868,
      -3.7233,
      -3.7233,
      -3.1355,
      -3.1355,
      -3.9464,
      -4.6396,
      -4.6396,
      -5.3327,
      -3.541,
      -3.9464,
      -3.9464,
      -3.9464,
      -3.9464,
      -3.9464,
      -3.5635,
      -4.4108,
      -4.1231,
      -5.5094,
      -5.5094,
      -5.5094,
      -5.5094,
      -5.5094,
      -5.5094,
      -5.5094,
      -3.4299,
      -3.7176,
      -3.7176,
      -3.7176,
      -3.7176,
      -4.1231,
      -4.1231,
      -4.1231,
      -4.1231,
      -4.1231,
      -4.1231,
      -2.5137,
      -2.8703,
      -4.1231,
      -5.273,
      -4.5799,
      -4.5799,
      -4.5799,
      -5.273,
      -5.273,
      -4.5799,
      -5.273,
      -5.273,
      -5.273,
      -3.3271,
      -3.3271,
      -3.3271,
      -3.4812,
      -3.4812,
      -3.4812,
      -3.8867,
      -4.1744,
      -4.1744,
      -4.1744,
      -4.1744,
      -4.1744,
      -3.6636,
      -3.8867,
      -3.8867,
      -3.8867,
    ],
  },
  "token.table": {
    Topic: [
      2,
      3,
      6,
      6,
      4,
      3,
      6,
      6,
      1,
      1,
      6,
      2,
      2,
      5,
      6,
      3,
      5,
      5,
      5,
      2,
      2,
      1,
      1,
      5,
      6,
      3,
      3,
      2,
      3,
      5,
      3,
      5,
      4,
      1,
      1,
      1,
      5,
      2,
      4,
      3,
      3,
      5,
      3,
      3,
      3,
      1,
      1,
      3,
      6,
      6,
      2,
      4,
      4,
      1,
      3,
      4,
      6,
      6,
      5,
      6,
      4,
      2,
      5,
      6,
      5,
      4,
      4,
      5,
      5,
      3,
      2,
      2,
      5,
      3,
      2,
      4,
      4,
      4,
      1,
      5,
      6,
      5,
      1,
      6,
      6,
      3,
      3,
      6,
      6,
      1,
      3,
      1,
      2,
      2,
      6,
      1,
      1,
      3,
      5,
      1,
      5,
      2,
      4,
      2,
      3,
      4,
      5,
      3,
      6,
      1,
      1,
      1,
      1,
      3,
      2,
      1,
      5,
      6,
      2,
      6,
      2,
      2,
      3,
      4,
      5,
      5,
      6,
      5,
      4,
      2,
      2,
      5,
      5,
      5,
      2,
      1,
      1,
      5,
      3,
      2,
      2,
      6,
      6,
      4,
      6,
      4,
      3,
      2,
      2,
    ],
    Freq: [
      0.999974242018613,
      0.9999804072598925,
      1.0000689843719195,
      1.0000689843719195,
      0.9999331348527669,
      0.9999804072598925,
      1.0000689843719197,
      1.0000689843719197,
      0.999989587758506,
      0.9999895877585061,
      1.0000689843719195,
      0.9999742420186128,
      0.9999742420186128,
      0.20001308823186834,
      0.8000523529274733,
      0.9999804072598925,
      1.0000512685600962,
      1.0000512685600962,
      1.0000512685600962,
      0.999974242018613,
      0.999974242018613,
      0.9999895877585061,
      0.9999895877585061,
      1.0000512685600962,
      1.0000689843719195,
      0.9999804072598925,
      0.9999804072598923,
      0.16666519820157527,
      0.6666607928063011,
      0.16666519820157527,
      0.8888784713260277,
      0.11110980891575346,
      0.9999331348527669,
      0.9999895877585061,
      0.999989587758506,
      0.9999895877585061,
      1.0000512685600962,
      0.999974242018613,
      0.9999331348527669,
      0.9999804072598925,
      0.5122027907892172,
      0.4878121817040163,
      0.9999804072598925,
      0.9999804072598925,
      0.9999804072598925,
      0.9999895877585061,
      0.9999895877585061,
      0.9999804072598922,
      1.0000689843719195,
      1.0000689843719195,
      0.999974242018613,
      0.9999331348527669,
      0.9999331348527669,
      0.9999895877585061,
      0.25000321857170743,
      0.25000321857170743,
      0.5000064371434149,
      1.0000689843719195,
      1.0000512685600962,
      1.0000689843719195,
      0.999933134852767,
      0.9999742420186128,
      1.0000512685600962,
      1.0000689843719195,
      1.0000512685600962,
      0.9999331348527668,
      0.9999331348527669,
      1.0000512685600962,
      1.0000512685600964,
      0.9999804072598925,
      0.9999742420186128,
      0.999974242018613,
      1.0000512685600964,
      0.9999804072598925,
      0.999974242018613,
      0.9999331348527669,
      0.9999331348527669,
      0.9999331348527669,
      0.1428650157736288,
      0.1428650157736288,
      0.714325078868144,
      1.0000512685600964,
      0.9999895877585061,
      1.0000689843719195,
      1.0000689843719195,
      0.9999804072598922,
      0.9999804072598922,
      1.0000689843719195,
      1.0000689843719197,
      0.7999902013162384,
      0.1999975503290596,
      0.9999895877585061,
      0.9999742420186128,
      0.999974242018613,
      1.0000689843719195,
      0.999989587758506,
      0.9999895877585061,
      0.9999804072598925,
      1.0000512685600962,
      0.9999895877585061,
      1.0000512685600962,
      0.999974242018613,
      0.999933134852767,
      0.051282086072246116,
      0.4871798176863381,
      0.10256417214449223,
      0.35897460250572283,
      0.9999804072598925,
      1.0000689843719195,
      0.9999895877585061,
      0.9999895877585061,
      0.9999895877585061,
      0.9999895877585061,
      0.9999804072598925,
      0.999974242018613,
      0.42858207128534515,
      0.5714427617137935,
      1.0000689843719195,
      0.42858359070343605,
      0.5714447876045814,
      0.9999742420186128,
      0.9999742420186128,
      0.9999804072598925,
      0.9999331348527669,
      1.0000512685600964,
      1.0000512685600962,
      1.0000689843719195,
      1.0000512685600962,
      0.9999331348527669,
      0.999974242018613,
      0.9999742420186128,
      1.0000512685600964,
      1.0000512685600962,
      1.0000512685600962,
      0.9999742420186128,
      0.999989587758506,
      0.9999895877585061,
      1.0000512685600962,
      0.9999804072598922,
      0.999974242018613,
      0.999974242018613,
      1.0000689843719195,
      1.0000689843719195,
      0.9999331348527669,
      1.0000689843719195,
      0.9999331348527669,
      0.9999804072598925,
      0.999974242018613,
      0.999974242018613,
    ],
    Term: [
      "adityanath",
      "africans",
      "amit",
      "amit_shah",
      "anti",
      "army",
      "asaduddin",
      "asaduddin_owaisi",
      "badaun",
      "badaun_gangrape",
      "bandh",
      "batman",
      "bhawan_palace",
      "bjp",
      "bjp",
      "border",
      "boris",
      "boris_johnson",
      "cancel",
      "capitol",
      "capitol_hill",
      "case",
      "clear",
      "cnn",
      "communal",
      "cost",
      "cover",
      "covid",
      "covid",
      "covid",
      "covid_vaccine",
      "covid_vaccine",
      "culture",
      "daughter",
      "dawn",
      "debunk",
      "double",
      "drive",
      "economy",
      "ex",
      "farmer",
      "farmer",
      "farooqi",
      "free",
      "freedom",
      "gandhi",
      "gangrape",
      "geographic",
      "greeting",
      "gulbarga",
      "hill",
      "hindu",
      "hindu_culture",
      "hindustan",
      "indian",
      "indian",
      "indian",
      "indian_soldier",
      "jalore",
      "jan",
      "jnu",
      "jodhpur",
      "johnson",
      "kejriwal",
      "kisan",
      "kolkata",
      "kolkata_pride",
      "kumbh",
      "kumbh_mela",
      "lawyer",
      "management",
      "measure",
      "mela",
      "microchip",
      "mistake",
      "mock",
      "morgan",
      "morgan_stanley",
      "morph",
      "morph",
      "morph",
      "movement",
      "murder",
      "namaz",
      "narendra",
      "national",
      "national_geographic",
      "offer",
      "owaisi",
      "pakistani",
      "pakistani",
      "pakistani_pilot",
      "palace",
      "parody",
      "passport",
      "pilot",
      "pilot_urinated",
      "placard",
      "place",
      "plane",
      "political",
      "praise",
      "pride",
      "protest",
      "protest",
      "protest",
      "protest",
      "protest_delhi",
      "purportedly",
      "qasem",
      "rahul",
      "rahul_gandhi",
      "rape",
      "related_farmer",
      "save",
      "screenshot",
      "screenshot",
      "shah",
      "show",
      "show",
      "show_umaid",
      "siege",
      "singhu_border",
      "stanley",
      "stunt",
      "take",
      "temple",
      "thug",
      "tier",
      "time",
      "time_magazine",
      "tour",
      "tractor",
      "tractor_stunt",
      "umaid",
      "unnao",
      "unnao_badaun",
      "vaccinate",
      "vaccine",
      "vandalize",
      "verification",
      "vijay",
      "vizag",
      "walk",
      "war",
      "warehouse",
      "warn",
      "yogi",
      "yogi_adityanath",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/two-year-old-image-rape-victim-shared-as-50-year-old-woman-gangraped-and-murdered-in-ups-badaun-false/",
        headline:
          "Old photo of 2018 UP murder victim shared as Badaun rape victim",
      },
      {
        url:
          "https://www.altnews.in/hindi/two-year-old-image-rape-victim-shared-as-50-year-old-woman-gangraped-and-murdered-in-ups-badaun-false/",
        headline:
          "UP \u092e\u0947\u0902 2 \u0938\u093e\u0932 \u092a\u0939\u0932\u0947 \u0932\u095c\u0915\u0940 \u0915\u0940 \u0939\u0924\u094d\u092f\u093e \u0939\u0941\u0908 \u0925\u0940, \u0909\u0938\u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092c\u0926\u093e\u092f\u0942\u0902 \u0915\u0940 \u0918\u091f\u0928\u093e \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0936\u0947\u092f\u0930 \u0915\u0940 \u0917\u092f\u0940",
      },
      {
        url:
          "https://www.altnews.in/fact-check-did-pakistans-dawn-report-about-a-pilot-urinated-in-f-16/",
        headline:
          "Morphed Dawn report claims pilot urinated in Pak fighter jet after calling from Allah",
      },
      {
        url:
          "https://factly.in/telugu-an-old-video-of-rahul-gandhi-visiting-a-dargah-in-up-is-being-shared-with-a-false-narrative/",
        headline:
          " 2016 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u2018\u0c39\u0c3f\u0c02\u0c26\u0c42\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c16\u0c24\u0c02 \u0c15\u0c3e\u0c35\u0c32\u0c3f\u2019 \u0c05\u0c28\u0c3f \u0c30\u0c3e\u0c39\u0c41\u0c32\u0c4d \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c2e\u0c41\u0c02\u0c26\u0c41 \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32\u0c41 \u0c36\u0c2a\u0c25\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/morphed-news-report-shared-as-pakistan-pilot-caught-pissing-in-the-cockpit-of-paf-f-16-jet/",
        headline:
          " Morphed news report shared as Pakistan pilot caught pissing in the cockpit of PAF F-16 jet",
      },
      {
        url:
          "https://factly.in/kazakhstan-airsoft-gamer-falsely-portrayed-as-the-american-military-officer-who-killed-qasem-soleimani/",
        headline:
          " Kazakhstan Airsoft gamer falsely portrayed as the American military officer who killed Qasem Soleimani",
      },
      {
        url:
          "https://factly.in/telugu-kazakhstan-airsoft-gamer-falsely-portrayed-as-the-american-military-officer-who-killed-qasem-soleimani/",
        headline:
          " \u0c16\u0c1c\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c17\u0c47\u0c2e\u0c30\u0c4d \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c07\u0c30\u0c3e\u0c28\u0c4d \u0c15\u0c2e\u0c3e\u0c02\u0c21\u0c30\u0c4d \u0c16\u0c3e\u0c38\u0c3f\u0c2e\u0c4d \u0c38\u0c41\u0c32\u0c47\u0c2e\u0c3e\u0c28\u0c3f \u0c28\u0c3f \u0c39\u0c24\u0c2e\u0c3e\u0c30\u0c4d\u0c1a\u0c3f\u0c28 \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c28\u0c4d \u0c32\u0c47\u0c21\u0c3f \u0c06\u0c2b\u0c40\u0c38\u0c30\u0c4d \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/photo-of-a-2018-murder-case-in-unnau-falsely-linked-to-the-budaun-rape-case/",
        headline:
          " Photo of a 2018 murder case in Unnao is falsely linked to the Badaun rape case",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/09/fact-check-dont-fall-for-fake-posts-stating-speaker-om-birlas-daughter-cleared-upsc-exam-without-appearing-for-it/",
        headline:
          "Fact Check: Don\u2019t Fall For Fake Posts Stating Speaker Om Birla\u2019s Daughter Cleared UPSC Exam Without Appearing For It",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-pakistan-dawn-pakistani-pilot-f-16-pissing-photoshopped-11385",
        headline:
          "Dawn Screenshot Claiming Pakistani Pilot Urinated In F-16 Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/badaun-gang-rape-uttar-pradesh-50-year-old-woman-raped-by-three-men-11447",
        headline:
          "2018 Image From Unnao Viral As Badaun Gangrape And Murder Case",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-rahul-gandhi-married-secret-family-postcard-news-mahesh-hegde-wikileaks-cable-colombian-woman-two-children-niyak-mainak-11475",
        headline: "Debunked Claim Of Rahul Gandhi's 'Secret Family' Revived",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/old-farmer-dies-at-protest-site-farmer-kisan-andolan-singhu-border-11405",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u091a\u0915\u094d\u0915\u0930 \u0916\u093e\u0915\u0930 \u0917\u093f\u0930\u0924\u0947 \u0935\u0943\u0926\u094d\u0927 \u0915\u0940 \u092e\u0943\u0924\u094d\u092f\u0941 \u0928\u0939\u0940\u0902 \u0939\u0941\u0908 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/viral-photo-claims-badaun-gang-rape-uttar-pradesh-50-year-old-woman-raped-by-hindu-pujari-11457",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u092f\u0939 \u095e\u094b\u091f\u094b \u092c\u0926\u093e\u092f\u0942\u0902 \u0917\u0948\u0902\u0917\u0930\u0947\u092a \u0914\u0930 \u0939\u0924\u094d\u092f\u093e \u0915\u0947 \u092e\u093e\u092e\u0932\u0947 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news-fact-check-pakistan-dawn-pakistani-pilot-f-16-pissing-photoshopped-11395",
        headline:
          "Fake News: Dawn-\u098f \u0996\u09ac\u09b0 \u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09bf \u09ac\u09bf\u09ae\u09be\u09a8 \u099a\u09be\u09b2\u0995 F-16 \u09ac\u09bf\u09ae\u09be\u09a8\u09c7 \u09aa\u09cd\u09b0\u09b8\u09be\u09ac \u0995\u09b0\u09c7\u099b\u09c7\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/2018-image-from-unnao-viral-as-badaun-gangrape-and-murder-case-11471",
        headline:
          "Unnao \u098f\u09b0 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 Badaun \u0997\u09a3\u09a7\u09b0\u09cd\u09b7\u09a3 \u0993 \u09b9\u09a4\u09cd\u09af\u09be\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/09/fact-check-dont-fall-for-fake-posts-stating-speaker-om-birlas-daughter-cleared-upsc-exam-without-appearing-for-it/",
        headline:
          "Fact Check: Don\u2019t Fall For Fake Posts Stating Speaker Om Birla\u2019s Daughter Cleared UPSC Exam Without Appearing For It",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rajnath-singh-bjp-old-video-st-sc-act-fake-news-11491",
        headline:
          "\u0930\u093e\u091c\u0928\u093e\u0925 \u0938\u093f\u0902\u0939 \u0915\u093e \u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092b\u093f\u0930 \u0939\u0941\u0906 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/old-farmer-dies-at-protest-site-farmer-kisan-andolan-singhu-border-11405",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u091a\u0915\u094d\u0915\u0930 \u0916\u093e\u0915\u0930 \u0917\u093f\u0930\u0924\u0947 \u0935\u0943\u0926\u094d\u0927 \u0915\u0940 \u092e\u0943\u0924\u094d\u092f\u0941 \u0928\u0939\u0940\u0902 \u0939\u0941\u0908 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-photo-claims-badaun-gang-rape-uttar-pradesh-50-year-old-woman-raped-by-hindu-pujari-11457",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u092f\u0939 \u095e\u094b\u091f\u094b \u092c\u0926\u093e\u092f\u0942\u0902 \u0917\u0948\u0902\u0917\u0930\u0947\u092a \u0914\u0930 \u0939\u0924\u094d\u092f\u093e \u0915\u0947 \u092e\u093e\u092e\u0932\u0947 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-pakistan-dawn-pakistani-pilot-f-16-pissing-photoshopped-11385",
        headline:
          "Dawn Screenshot Claiming Pakistani Pilot Urinated In F-16 Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-rahul-gandhi-married-secret-family-postcard-news-mahesh-hegde-wikileaks-cable-colombian-woman-two-children-niyak-mainak-11475",
        headline: "Debunked Claim Of Rahul Gandhi's 'Secret Family' Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/badaun-gang-rape-uttar-pradesh-50-year-old-woman-raped-by-three-men-11447",
        headline:
          "2018 Image From Unnao Viral As Badaun Gangrape And Murder Case",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2018-image-from-unnao-viral-as-badaun-gangrape-and-murder-case-11471",
        headline:
          "Unnao \u098f\u09b0 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 Badaun \u0997\u09a3\u09a7\u09b0\u09cd\u09b7\u09a3 \u0993 \u09b9\u09a4\u09cd\u09af\u09be\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-pakistan-dawn-pakistani-pilot-f-16-pissing-photoshopped-11395",
        headline:
          "Fake News: Dawn-\u098f \u0996\u09ac\u09b0 \u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09bf \u09ac\u09bf\u09ae\u09be\u09a8 \u099a\u09be\u09b2\u0995 F-16 \u09ac\u09bf\u09ae\u09be\u09a8\u09c7 \u09aa\u09cd\u09b0\u09b8\u09be\u09ac \u0995\u09b0\u09c7\u099b\u09c7\u09a8",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/pilot-urinating-in-the-cockpit-672510",
        headline:
          "Fact Check: Screenshot of 'news' about a pilot urinating in the cockpit is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/viral-video-claiming-rahul-gandhi-owns-historic-buildings-in-italy-is-false-672663",
        headline:
          "Fact Check: Viral video claiming Rahul Gandhi owns historic buildings in Italy is false",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/3d-projection-on-istanbul-galata-tower-shared-as-light-show-on-umaid-bhawan-palace-in-jodhpur/",
        headline:
          " 3D Projection on Istanbul Galata Tower shared as light show on Umaid Bhawan Palace in Jodhpur",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-such-labour-policy-scheme-launched-by-the-central-government/",
        headline:
          " \u0c32\u0c47\u0c2c\u0c30\u0c4d \u0c2a\u0c3e\u0c32\u0c38\u0c40 \u0c2a\u0c47\u0c30\u0c41\u0c24\u0c4b \u0c15\u0c3e\u0c30\u0c4d\u0c2e\u0c3f\u0c15\u0c41\u0c32 \u0c15\u0c4b\u0c38\u0c02 \u0c07\u0c32\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c2a\u0c25\u0c15\u0c02 \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c2a\u0c4d\u0c30\u0c35\u0c47\u0c36 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-story-carried-in-time-magazine-about-covid-19-management-by-up-is-a-sponsored-post/",
        headline:
          " \u0c09\u0c24\u0c4d\u0c24\u0c30 \u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c30\u0c4b\u0c28 \u0c28\u0c3f\u0c2f\u0c02\u0c24\u0c4d\u0c30\u0c23 \u0c1a\u0c30\u0c4d\u0c2f\u0c32\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c36\u0c02\u0c38\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u2018TIME\u2019 \u0c2e\u0c4d\u0c2f\u0c3e\u0c17\u0c1c\u0c48\u0c28\u0c4d\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c1a\u0c41\u0c30\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c12\u0c15 \u0c2a\u0c4d\u0c30\u0c15\u0c1f\u0c28",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-man-saffron-flag-capitol-hill-united-states-dc-siege-pro-tump-supporters-hindutva-members-11452",
        headline:
          "Photo Of Man With US, Saffron Flags Falsely Linked To Capitol Siege",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/jodhpur-umaid-bhawan-palace-video-istanbul-galata-tower-11388",
        headline:
          "\u0915\u094d\u092f\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u091c\u094b\u0927\u092a\u0941\u0930 \u0915\u0947 \u0909\u092e\u094d\u092e\u0947\u0926 \u092d\u0935\u0928 \u092a\u0948\u0932\u0947\u0938 \u0915\u094b \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/time-magazine-praise-yogi-adityanath-covid-19-patrika-zee-news-abp-news-tv9-bharatvarsh-paid-content-11421",
        headline:
          "\u0915\u094d\u092f\u093e TIME \u092e\u0948\u0917\u095b\u0940\u0928 \u0928\u0947 \u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u0947 \u0915\u094b\u0935\u093f\u0921-19 \u092a\u094d\u0930\u092c\u0902\u0927\u0928 \u0915\u0940 \u092a\u094d\u0930\u0936\u0902\u0938\u093e \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/balakot-strike-news-ani-ndtv-the-quint-hindustan-times-fake-news-zafar-hilaly-11489",
        headline:
          "\u090f.\u090f\u0928.\u0906\u0908, \u0905\u0928\u094d\u092f \u092e\u0940\u0921\u093f\u092f\u093e \u0938\u0902\u0938\u094d\u0925\u093e\u0928\u094b\u0902 \u0928\u0947 \u092c\u093e\u0932\u093e\u0915\u094b\u091f \u092a\u0930 \u0915\u0940 \u0917\u0932\u0924 \u0930\u093f\u092a\u094b\u0930\u094d\u091f\u093f\u0902\u0917",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-graphic-claiming-tmc-member-carried-lactern-of-lancy-pelosi-chamber-in-us-capitol-11469",
        headline:
          "\u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987: Capitol Hill-\u098f\u09b0 \u09ae\u09be\u09b0\u09cd\u0995\u09bf\u09a8 \u09b8\u0982\u09b8\u09a6 \u09ad\u09ac\u09a8\u09c7 \u09ad\u09be\u0999\u099a\u09c1\u09b0 \u0995\u09b0\u099b\u09c7 TMC \u0995\u09b0\u09cd\u09ae\u09c0?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-after-capitol-hill-siege-by-trump-supporters-merkel-s-parody-tweet-goes-viral-1756809-2021-01-07",
        headline:
          "Fact Check: After Capitol Hill siege by Trump supporters, Merkel\u2019s parody tweet goes viral",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-mistake-artwork-for-cloud-pattern-over-mumbai-1755764-2021-01-04",
        headline:
          "Fact Check: Netizens mistake artwork for cloud pattern over Mumbai",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/this-batman-wanted-to-save-city-but-video-not-capitol-hill-1756915-2021-01-07",
        headline:
          "Fact Check: This Batman wanted to save the city, but the video is not from Capitol Hill",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-know-truth-behind-google-self-driving-bicycle-1757445-2021-01-09",
        headline:
          "Fact Check: Know the truth behind Google's 'self-driving' bicycle",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/jodhpur-umaid-bhawan-palace-video-istanbul-galata-tower-11388",
        headline:
          "\u0915\u094d\u092f\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u091c\u094b\u0927\u092a\u0941\u0930 \u0915\u0947 \u0909\u092e\u094d\u092e\u0947\u0926 \u092d\u0935\u0928 \u092a\u0948\u0932\u0947\u0938 \u0915\u094b \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/time-magazine-praise-yogi-adityanath-covid-19-patrika-zee-news-abp-news-tv9-bharatvarsh-paid-content-11421",
        headline:
          "\u0915\u094d\u092f\u093e TIME \u092e\u0948\u0917\u095b\u0940\u0928 \u0928\u0947 \u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u0947 \u0915\u094b\u0935\u093f\u0921-19 \u092a\u094d\u0930\u092c\u0902\u0927\u0928 \u0915\u0940 \u092a\u094d\u0930\u0936\u0902\u0938\u093e \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/balakot-strike-news-ani-ndtv-the-quint-hindustan-times-fake-news-zafar-hilaly-11489",
        headline:
          "\u090f.\u090f\u0928.\u0906\u0908, \u0905\u0928\u094d\u092f \u092e\u0940\u0921\u093f\u092f\u093e \u0938\u0902\u0938\u094d\u0925\u093e\u0928\u094b\u0902 \u0928\u0947 \u092c\u093e\u0932\u093e\u0915\u094b\u091f \u092a\u0930 \u0915\u0940 \u0917\u0932\u0924 \u0930\u093f\u092a\u094b\u0930\u094d\u091f\u093f\u0902\u0917",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/batman-us-capitol-protests-donald-trump-11444",
        headline: "Batman At US Capitol Protest? No, This Is An Old Video",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/time-magazine-up-government-praise-covid-yogi-adityanath-article-zee-news-abp-news-patrika-tv9bharatvarsh-news18-up-11416",
        headline:
          "Did TIME Praise Yogi Adityanath's COVID-19 Management? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-man-saffron-flag-capitol-hill-united-states-dc-siege-pro-tump-supporters-hindutva-members-11452",
        headline:
          "Photo Of Man With US, Saffron Flags Falsely Linked To Capitol Siege",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/time-magazine-up-government-praise-covid-yogi-adityanath-article-zee-news-abp-news-patrika-tv9bharatvarsh-news18-up-11450",
        headline:
          "Fact Check: Covid \u09ae\u09cb\u0995\u09be\u09ac\u09bf\u09b2\u09be \u09a8\u09bf\u09df\u09c7 Yogi Adityanath \u098f\u09b0 \u09aa\u09cd\u09b0\u09b6\u0982\u09b8\u09be\u09df Time Mag?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/viral-batman-us-capitol-hill-protests-donald-trump-11484",
        headline:
          "\u09ae\u09be\u09b0\u09cd\u0995\u09bf\u09a8 \u09af\u09c1\u0995\u09cd\u09a4\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09c7\u09b0 'Capitol Hill' \u09ac\u09bf\u0995\u09cd\u09b7\u09c7\u09be\u09ad\u09c7 \u0995\u09bf Batman \u099b\u09bf\u09b2?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-graphic-claiming-tmc-member-carried-lactern-of-lancy-pelosi-chamber-in-us-capitol-11469",
        headline:
          "\u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987: Capitol Hill-\u098f\u09b0 \u09ae\u09be\u09b0\u09cd\u0995\u09bf\u09a8 \u09b8\u0982\u09b8\u09a6 \u09ad\u09ac\u09a8\u09c7 \u09ad\u09be\u0999\u099a\u09c1\u09b0 \u0995\u09b0\u099b\u09c7 TMC \u0995\u09b0\u09cd\u09ae\u09c0?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-man-who-waved-tricolor-at-capitol-is-not-modi-bakht-but-affiliate-of-shashi-tharoor-672674",
        headline: "Fact Check: Man who waved tricolor at Capitol is from Kochi",
      },
    ],
    "3": [
      {
        url:
          "https://factly.in/this-national-geographic-magazine-cover-on-recent-indian-farmers-protest-is-fake/",
        headline:
          " This National Geographic magazine cover on recent Indian farmers\u2019 protest is fake",
      },
      {
        url:
          "https://factly.in/covax-agreement-between-190-countries-is-misconstrued-as-that-for-vaccine-being-developed-in-india/",
        headline:
          " COVAX agreement between 190 countries is misconstrued as that for the vaccine being developed in India",
      },
      {
        url:
          "https://factly.in/not-only-india-even-uk-us-is-providing-free-vaccine-to-their-citizens/",
        headline:
          " Not only India, even UK & US is providing free vaccine to their citizens",
      },
      {
        url:
          "https://factly.in/telugu-not-only-india-even-uk-us-is-providing-free-vaccine-to-their-citizens/",
        headline:
          " \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c02 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c15\u0c3e\u0c26\u0c41, \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e, \u0c2c\u0c4d\u0c30\u0c3f\u0c1f\u0c28\u0c4d \u0c15\u0c42\u0c21\u0c3e \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c15\u0c3f \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d\u0c28\u0c3f \u0c09\u0c1a\u0c3f\u0c24\u0c02\u0c17\u0c3e \u0c05\u0c02\u0c26\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://factly.in/not-just-five-but-many-more-countries-are-developing-and-manufacturing-various-covid-19-vaccines/",
        headline:
          " Not just five, many countries are developing & manufacturing various COVID-19 vaccines",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/06/fact-check-this-national-geographic-cover-with-farmers-picture-is-fake-heres-the-truth/",
        headline:
          "Fact Check: This National Geographic Cover With Farmer\u2019s Picture Is Fake; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/06/fact-check-this-video-of-tower-on-fire-is-old-and-not-related-to-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Tower On Fire Is OLD and NOT Related To Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/08/fact-check-this-picture-is-not-related-to-farmers-protest-at-delhis-singhu-border-heres-the-truth/",
        headline:
          "Fact Check: This Picture Is NOT Related To Farmers\u2019 Protest At Delhi\u2019s Singhu Border; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/old-video-of-mobile-tower-blaze-linked-to-farmers-protest-11386",
        headline: "Old Video Of Mobile Tower Blaze Linked To Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/covid-19-coronavirus-vaccine-muslim-maulana-vaccine-conspiracey-theory-11400",
        headline:
          "Video Of Pak Cleric Spreading Misinformation On COVID Vaccine Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/elderly-sikh-farmer-collapsing-at-protest-site-in-viral-video-is-alive-11401",
        headline:
          "Elderly Sikh Farmer Collapsing At Protest Site In Viral Video is Alive",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/munawar-faruqui-standup-comedian-indore-madhya-pradesh-police-custody-11404",
        headline:
          "Man Being Thrashed In Viral Video Is Not Stand-up Comic Munawar Faruqui",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/cowin-app-vaccine-covid19-health-ministry-coronavirus-11437",
        headline: "Copycat Cowin Apps Confuse Vaccine Hopefuls",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check-fake-news-balakot-zafar-hilaly-ani-india-today-surgical-strike-300-deaths-pakistan-narendra-modi-11487",
        headline:
          "ANI, News Outlets Falsely Claim Ex Pak Diplomat Admitted To Balakot Deaths",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/fact-check-is-this-an-aerial-shot-of-farmers-protest-ground-at-singhu-border-1757255-2021-01-08",
        headline:
          "Fact Check: Is this an aerial shot of farmers protest ground at Singhu border?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-farmer-seen-collapsing-on-video-at-singhu-border-has-not-passed-away-1755846-2021-01-04",
        headline:
          "Fact Check: Farmer seen collapsing on video at Singhu Border has not passed away",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-placard-seeking-release-of-kashmiri-separatists-is-not-from-farmers-protest-1756164-2021-01-05",
        headline:
          "Fact Check: Placard seeking release of Kashmiri separatists is not from farmers' protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-fake-national-geographic-magazine-cover-featuring-farmers-protest-goes-viral-1756171-2021-01-05",
        headline:
          "Fact Check: Fake 'National Geographic' magazine cover featuring farmers' protest goes viral",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-ex-army-man-s-video-in-support-of-farmers-protest-shared-with-misleading-claims-1756947-2021-01-08",
        headline:
          "Fact Check: Ex-Army man's video in support of farmers' protest shared with misleading claims",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/is-this-farmers-protest-supporter-a-phony-ex-soldier-1757529-2021-01-10",
        headline: "Is this farmers-protest supporter a phony ex-soldier?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-obama-never-warned-africans-against-covid-19-vaccines-from-us-europe-1757718-2021-01-10",
        headline:
          "Fact Check: Obama never warned Africans against Covid-19 vaccines from US, Europe",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/06/fact-check-this-national-geographic-cover-with-farmers-picture-is-fake-heres-the-truth/",
        headline:
          "Fact Check: This National Geographic Cover With Farmer\u2019s Picture Is Fake; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/06/fact-check-this-video-of-tower-on-fire-is-old-and-not-related-to-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Tower On Fire Is OLD and NOT Related To Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/08/fact-check-this-picture-is-not-related-to-farmers-protest-at-delhis-singhu-border-heres-the-truth/",
        headline:
          "Fact Check: This Picture Is NOT Related To Farmers\u2019 Protest At Delhi\u2019s Singhu Border; Here\u2019s The Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/stand-up-comedian-munawar-faruqui-beaten-in-indore-madhya-pradesh-11407",
        headline:
          "\u0915\u094d\u092f\u093e \u0935\u0915\u0940\u0932\u094b\u0902 \u0928\u0947 \u0915\u094b\u0930\u094d\u091f \u0915\u0947 \u092c\u093e\u0939\u0930 \u0938\u094d\u091f\u0948\u0902\u0921\u0905\u092a \u0915\u0949\u092e\u093f\u0915 \u092e\u0941\u0928\u0935\u094d\u0935\u0930 \u095e\u093e\u0930\u0942\u0915\u0940 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-fake-news-balakot-zafar-hilaly-ani-india-today-surgical-strike-300-deaths-pakistan-narendra-modi-11487",
        headline:
          "ANI, News Outlets Falsely Claim Ex Pak Diplomat Admitted To Balakot Deaths",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/elderly-sikh-farmer-collapsing-at-protest-site-in-viral-video-is-alive-11401",
        headline:
          "Elderly Sikh Farmer Collapsing At Protest Site In Viral Video is Alive",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/cowin-app-vaccine-covid19-health-ministry-coronavirus-11437",
        headline: "Copycat Cowin Apps Confuse Vaccine Hopefuls",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/munawar-faruqui-standup-comedian-indore-madhya-pradesh-police-custody-11404",
        headline:
          "Man Being Thrashed In Viral Video Is Not Stand-up Comic Munawar Faruqui",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/old-video-of-mobile-tower-blaze-linked-to-farmers-protest-11386",
        headline: "Old Video Of Mobile Tower Blaze Linked To Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-fake-news-fake-national-geographic-cover-magazine-foreign-press-farmers-protests-11399",
        headline:
          "No, This Is Not A National Geographic Cover On Farmers' Protests",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/covid-19-vaccines-free-coronavirus-india-covxin-bharat-biotech-serum-institute-uk-us-harsh-vardhan-11451",
        headline: "Are COVID-19 Vaccines In India Free? Not Really",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/factcheck-fake-news-sikh-youth-placard-demanding-release-kashmiri-separatists-farmers-protests-photo-11420",
        headline:
          "Placard Supporting Kashmiri Separatists Falsely Linked To Farmers' March",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/covid-19-coronavirus-vaccine-muslim-maulana-vaccine-conspiracey-theory-11400",
        headline:
          "Video Of Pak Cleric Spreading Misinformation On COVID Vaccine Viral",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-fake-national-geographic-cover-magazine-foreign-press-farmers-protests-11440",
        headline:
          "Farmers Protest \u09a8\u09bf\u09df\u09c7 \u098f\u0987 \u09aa\u09cd\u09b0\u099a\u09cd\u099b\u09a6\u099f\u09bf National Geographic \u098f\u09b0 \u09a8\u09df",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-nat-geo-magazine-cover-featuring-farmers-protest-is-fake-672590",
        headline:
          "FACT CHECK: Viral Nat Geo magazine cover featuring farmers protest is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/image-from-pepsi-commercial-featuring-us-actress-kendell-jenner-672611",
        headline:
          "Fact Check: Image from Pepsi commercial featuring US actress Kendell Jenner shared with FALSE claim",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/nhs-message-seeking-bank-details-for-covid-vaccine-672503",
        headline:
          "Fact Check: Viral NHS message seeking bank details for Covid vaccine is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-from-2019-protest-to-set-pro-freedom-kashmir-leaders-free-falsely-linked-to-2020-farmers-protest-672730",
        headline:
          "Fact Check: Photo from 2019 protest to set pro-freedom Kashmir leaders free falsely linked to 2020 farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-art-installation-by-3d-artist-goes-viral-as-mickey-mouse-head-abandoned-by-disney-672568",
        headline:
          "Fact Check: Art installation by 3D artist goes viral as Mickey Mouse head abandoned by Disney",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-man-claiming-covid-vaccine-contains-microchip-is-from-pakistan-not-india-672693",
        headline:
          "FACT CHECK: Man claiming Covid vaccine contains microchip is from Pakistan, not India.",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-true-private-clinics-in-brazil-to-buy-5-million-doses-of-hyd-firms-covid-19-vaccine-covaxin-672542",
        headline:
          "Fact Check: True, private clinics in Brazil to buy 5 million doses of Hyd firm's COVID-19 vaccine Covaxin",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-maulvi-claiming-that-there-is-a-chip-in-corona-vaccine-is-from-pakistani-not-india/",
        headline:
          "Fact Check: \u0915\u094b\u0930\u094b\u0928\u093e \u0935\u0948\u0915\u094d\u0938\u0940\u0928 \u092e\u0947\u0902 \u091a\u093f\u092a \u0939\u094b\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u0915\u0930 \u0930\u0939\u0947 \u092e\u094c\u0932\u0935\u0940 \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u0939\u0948\u0902, \u092d\u093e\u0930\u0924\u0940\u092f \u0928\u0939\u0940\u0902",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/audio-clip-discrediting-caa-protestors-falsely-attributed-to-supreme-court-advocate-harish-salve/",
        headline:
          "Audio clip discrediting CAA protestors falsely attributed to Supreme Court advocate Harish Salve",
      },
      {
        url:
          "https://www.altnews.in/old-photo-from-pride-padare-from-kolkata-shared-as-jnu-protest-against-hindu-culture/",
        headline:
          "Old photo from Kolkata Pride March viral as JNU student \u2018mocking\u2019 Hindu culture",
      },
      {
        url:
          "https://www.altnews.in/fact-check-did-supreme-court-of-nepal-ban-loudspeaker-of-mosques/",
        headline:
          "No, Nepal Supreme Court has NOT banned loudspeakers in mosques",
      },
      {
        url:
          "https://factly.in/except-for-one-none-of-these-statements-on-the-indian-economy-were-made-by-morgan-stanley/",
        headline:
          " Except for one, none of these statements on the Indian economy were made by Morgan Stanley",
      },
      {
        url:
          "https://factly.in/telugu-except-for-one-none-of-these-statements-on-the-indian-economy-were-made-by-morgan-stanley/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24 \u0c06\u0c30\u0c4d\u0c27\u0c3f\u0c15 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c4d\u0c25 \u0c2a\u0c48 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c41\u0c32\u0c4b\u0c28\u0c3f \u0c12\u0c15\u0c4d\u0c15 \u0c35\u0c3e\u0c15\u0c4d\u0c2f\u0c02 \u0c24\u0c2a\u0c4d\u0c2a \u0c2e\u0c3f\u0c17\u0c3f\u0c24\u0c3e \u0c35\u0c4d\u0c2f\u0c3e\u0c16\u0c4d\u0c2f\u0c32\u0c28\u0c41 \u0c2e\u0c4b\u0c30\u0c4d\u0c17\u0c3e\u0c28\u0c4d \u0c38\u0c4d\u0c1f\u0c3e\u0c28\u0c4d\u0c32\u0c40 \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/pm-modi-hasnt-credited-first-metro-rail-services-in-the-country-to-atal-bihari-vajpayee/",
        headline:
          " PM Modi hasn\u2019t credited first metro rail service in the country to Atal Bihari Vajpayee",
      },
      {
        url:
          "https://factly.in/telugu-fci-has-been-storing-food-grains-in-private-companies-warehouses-for-many-years/",
        headline:
          " FCI \u0c2a\u0c4d\u0c30\u0c48\u0c35\u0c47\u0c1f\u0c41 \u0c38\u0c02\u0c38\u0c4d\u0c25\u0c32 \u0c17\u0c4b\u0c26\u0c3e\u0c2e\u0c41\u0c32\u0c32\u0c4b \u0c1a\u0c3e\u0c32\u0c3e \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c3e\u0c32 \u0c2e\u0c41\u0c02\u0c26\u0c41\u0c28\u0c41\u0c02\u0c21\u0c47 \u0c06\u0c39\u0c3e\u0c30 \u0c27\u0c3e\u0c28\u0c4d\u0c2f\u0c3e\u0c32\u0c41 \u0c28\u0c3f\u0c32\u0c4d\u0c35 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c4b\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/fci-has-been-storing-food-grains-in-private-companies-warehouses-for-many-years/",
        headline:
          " FCI has been storing food grains in private warehouses for many years",
      },
      {
        url:
          "https://factly.in/telugu-not-just-five-many-countries-are-developing-manufacturing-various-covid-19-vaccines/",
        headline:
          " \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c10\u0c26\u0c41 \u0c26\u0c47\u0c36\u0c3e\u0c32\u0c41 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c15\u0c3e\u0c26\u0c41, \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a\u0c02\u0c32\u0c4b \u0c1a\u0c3e\u0c32\u0c3e \u0c26\u0c47\u0c36\u0c3e\u0c32\u0c41 \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d-19 \u0c35\u0c4d\u0c2f\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d\u0c32\u0c28\u0c41 \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/09/fact-check-a-2019-picture-from-kolkata-pride-parade-shared-with-false-jnu-link/",
        headline:
          "Fact Check: A 2019 Picture From Kolkata Pride Parade Shared With False JNU Link",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/morgan-stanley-forbes-indian-economy-narendra-modi-world-bank-economic-growth-6-trillion-11398",
        headline:
          "Economy Under PM Modi: False Claims Linked To Morgan Stanley Goes Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/pride-parade-jnu-protest-kolkata-hindu-culture-anti-caa-protest-viral-image-11403",
        headline:
          "2019 Pride Walk Image Viral As JNU Protest Against Hindu Culture",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/harish-salve-did-not-record-audio-clip-slamming-anti-caa-protesters-11460",
        headline:
          "Harish Salve Did Not Record Audio Clip Slamming Anti CAA Protesters",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/jamia-millia-islamias-gunman-misidentified-as-shaheenbags-gunman-kapil-gurjar-11384",
        headline:
          "Jamia Millia Islamia-\u09b0 \u09ac\u09a8\u09cd\u09a6\u09c1\u0995\u09ac\u09be\u099c\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u099c\u09cb\u09a1\u09bc\u09be \u09b9\u09b2 \u09b9\u09b2 \u09b6\u09be\u09b9\u09bf\u09a8\u09ac\u09be\u0997\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/pride-parade-jnu-protest-kolkata-hindu-culture-anti-caa-protest-viral-image-11442",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 Kolkata Pride Walk \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 JNU \u09b8\u0982\u09b8\u09cd\u0995\u09c3\u09a4\u09bf \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/-ssc-notice-on-new-tier-0-level-exam-to-deal-with-server-woes-fake-1756925-2021-01-07",
        headline:
          "Fact Check: This 'SSC' notice on new 'Tier-0' level exam to deal with server woes is fake",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/picture-of-pride-parade-kolkata-goes-viral-jnu-protest-hindu-culture-1755869-2021-01-04",
        headline:
          "Fact Check: Picture of Pride parade from Kolkata goes viral as JNU protest against Hindu culture",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/09/fact-check-a-2019-picture-from-kolkata-pride-parade-shared-with-false-jnu-link/",
        headline:
          "Fact Check: A 2019 Picture From Kolkata Pride Parade Shared With False JNU Link",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/harish-salve-did-not-record-audio-clip-slamming-anti-caa-protesters-11460",
        headline:
          "Harish Salve Did Not Record Audio Clip Slamming Anti CAA Protesters",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/pride-parade-jnu-protest-kolkata-hindu-culture-anti-caa-protest-viral-image-11403",
        headline:
          "2019 Pride Walk Image Viral As JNU Protest Against Hindu Culture",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/morgan-stanley-forbes-indian-economy-narendra-modi-world-bank-economic-growth-6-trillion-11398",
        headline:
          "Economy Under PM Modi: False Claims Linked To Morgan Stanley Goes Viral",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/jamia-millia-islamias-gunman-misidentified-as-shaheenbags-gunman-kapil-gurjar-11384",
        headline:
          "Jamia Millia Islamia-\u09b0 \u09ac\u09a8\u09cd\u09a6\u09c1\u0995\u09ac\u09be\u099c\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u099c\u09cb\u09a1\u09bc\u09be \u09b9\u09b2 \u09b9\u09b2 \u09b6\u09be\u09b9\u09bf\u09a8\u09ac\u09be\u0997\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/pride-parade-jnu-protest-kolkata-hindu-culture-anti-caa-protest-viral-image-11442",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 Kolkata Pride Walk \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 JNU \u09b8\u0982\u09b8\u09cd\u0995\u09c3\u09a4\u09bf \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/jnu-protest-against-hindu-culture-672559",
        headline:
          "FACT CHECK: 2019 pride parade passed off as JNU protest against Hindu culture",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/ssc-notice-on-new-tier-0-level-exam-is-fake-672646",
        headline: "Fact Check: 'SSC' notice on new 'Tier-0' level exam is fake",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/2013-image-of-mahakumbh-shared-as-farmers-tent/",
        headline:
          "Photo from 2013 Kumbh Mela shared as tents installed for protesting farmers",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-attempted-car-hijack-at-night-is-from-odisha/",
        headline:
          " \u0c30\u0c39\u0c26\u0c3e\u0c30\u0c3f \u0c2e\u0c40\u0c26\u0c41\u0c17\u0c3e \u0c35\u0c46\u0c33\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c15\u0c3e\u0c30\u0c41\u0c2a\u0c48 \u0c26\u0c41\u0c02\u0c21\u0c17\u0c41\u0c32\u0c41 \u0c26\u0c3e\u0c21\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c18\u0c1f\u0c28 \u0c12\u0c21\u0c3f\u0c36\u0c3e\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-2013-kumbh-mela-photo-is-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          " 2013 \u0c15\u0c41\u0c02\u0c2d\u0c2e\u0c47\u0c33\u0c3e\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c42 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-gst-rate-on-a-mercedes-car-is-not-half-the-rate-on-a-tractor/",
        headline:
          " \u0c2e\u0c46\u0c30\u0c4d\u0c38\u0c3f\u0c21\u0c40\u0c38\u0c4d \u0c15\u0c3e\u0c30\u0c41 \u0c24\u0c4b \u0c2a\u0c4b\u0c32\u0c41\u0c38\u0c4d\u0c24\u0c47 \u0c1f\u0c4d\u0c30\u0c3e\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c2a\u0c48 \u0c1c\u0c40\u0c0e\u0c38\u0c4d\u200c\u0c1f\u0c40 \u0c30\u0c47\u0c1f\u0c41 \u0c30\u0c46\u0c02\u0c21\u0c3f\u0c02\u0c24\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/06/fact-check-video-of-clash-from-rajasthan-falsely-linked-to-protest-over-farm-laws-heres-the-truth/",
        headline:
          "Fact check: Video Of Clash From Rajasthan Falsely Linked To Protest Over Farm Laws; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/06/fact-check-this-tractor-stunt-video-is-not-related-to-the-upcoming-kisan-parade-heres-the-truth/",
        headline:
          "Fact Check: This Tractor Stunt Video Is NOT Related To The Upcoming \u2018Kisan Parade\u2019; Here\u2019s the Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/farmers-protest-reliance-jio-towers-signboards-vandalised-hindi-words-blackened-punjab-11490",
        headline:
          "2017 Pics Of Defaced NH Signboards In Punjab Viral With Farmers' Protest Twist",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/tractor-rally-kisan-parade-republic-day-2021-kisan-andolan-11438",
        headline:
          "\u091f\u094d\u0930\u0948\u0915\u094d\u091f\u0930 \u0938\u0947 \u0938\u094d\u091f\u0902\u091f \u0915\u0930\u0924\u0947 \u0907\u0938 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/kisan-andolan-allahabad-kumbh-mela-photo-viral-fake-news-11445",
        headline:
          "2013 \u0907\u0932\u093e\u0939\u093e\u092c\u093e\u0926 \u0915\u0941\u092e\u094d\u092d \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0915\u093f\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/boris-johnson-republic-day-chief-guest-cancelled-kisan-andolan-news-fake-information-11448",
        headline:
          "\u092c\u094b\u0930\u093f\u0938 \u091c\u0949\u0928\u0938\u0928 \u0928\u0947 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0947 \u0915\u093e\u0930\u0923 \u0926\u094c\u0930\u093e \u0930\u0926\u094d\u0926 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/covid-19-vaccine-penis-cnn-screenshot-fake-news-viral-11449",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u094b\u0935\u093f\u0921-19 \u091f\u0940\u0915\u0947 \u0932\u093f\u0902\u0917 \u092e\u0947\u0902 \u0932\u0917\u0947\u0902\u0917\u0947? \u0938\u0940.\u090f\u0928.\u090f\u0928 \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/munawar-faruqui-standup-comedian-indore-madhya-pradesh-police-custody-11427",
        headline:
          "\u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09ad\u09bf\u09a1\u09bf\u0993\u09b0 \u09a8\u09bf\u0997\u09c3\u09b9\u09c0\u09a4 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf \u0995\u09ae\u09c7\u09a1\u09bf\u09df\u09be\u09a8 Munawar Faruqui \u09a8\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/elderly-sikh-farmer-collapsing-at-singhu-protest-site-in-viral-video-is-alive-11458",
        headline:
          "Farmers Protest: \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09df \u09b2\u09c1\u099f\u09bf\u09df\u09c7 \u09aa\u09dc\u09be \u09ac\u09c3\u09a6\u09cd\u09a7 \u09b6\u09bf\u0996 \u0995\u09c3\u09b7\u0995 \u099c\u09c0\u09ac\u09bf\u09a4 \u0986\u099b\u09c7\u09a8",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-picture-of-2013-kumbh-mela-gathering-passed-off-as-farmers-protest-1756553-2021-01-06",
        headline:
          "Fact Check: Picture of 2013 Kumbh Mela gathering passed off as farmers' protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-visualisation-of-a-project-in-belgium-passed-off-as-swanky-ambedkar-library-in-us-1757515-2021-01-09",
        headline:
          "Fact Check: Visualisation of a project in Belgium passed off as swanky Ambedkar library in US",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/06/fact-check-video-of-clash-from-rajasthan-falsely-linked-to-protest-over-farm-laws-heres-the-truth/",
        headline:
          "Fact check: Video Of Clash From Rajasthan Falsely Linked To Protest Over Farm Laws; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/06/fact-check-this-tractor-stunt-video-is-not-related-to-the-upcoming-kisan-parade-heres-the-truth/",
        headline:
          "Fact Check: This Tractor Stunt Video Is NOT Related To The Upcoming \u2018Kisan Parade\u2019; Here\u2019s the Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/boris-johnson-republic-day-chief-guest-cancelled-kisan-andolan-news-fake-information-11448",
        headline:
          "\u092c\u094b\u0930\u093f\u0938 \u091c\u0949\u0928\u0938\u0928 \u0928\u0947 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0947 \u0915\u093e\u0930\u0923 \u0926\u094c\u0930\u093e \u0930\u0926\u094d\u0926 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/kisan-andolan-allahabad-kumbh-mela-photo-viral-fake-news-11445",
        headline:
          "2013 \u0907\u0932\u093e\u0939\u093e\u092c\u093e\u0926 \u0915\u0941\u092e\u094d\u092d \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0915\u093f\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/covid-19-vaccine-penis-cnn-screenshot-fake-news-viral-11449",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u094b\u0935\u093f\u0921-19 \u091f\u0940\u0915\u0947 \u0932\u093f\u0902\u0917 \u092e\u0947\u0902 \u0932\u0917\u0947\u0902\u0917\u0947? \u0938\u0940.\u090f\u0928.\u090f\u0928 \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/a-photo-of-line-shah-baba-dargah-prayagraj-junction-viral-as-old-delhi-railway-station-mosque-11482",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915 : \u0930\u0947\u0932\u0935\u0947 \u0938\u094d\u091f\u0947\u0936\u0928 \u092a\u0930 \u092c\u0928\u0940 '\u092e\u0938\u094d\u091c\u093f\u0926' \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0939\u093e\u0902 \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/tractor-rally-kisan-parade-republic-day-2021-kisan-andolan-11438",
        headline:
          "\u091f\u094d\u0930\u0948\u0915\u094d\u091f\u0930 \u0938\u0947 \u0938\u094d\u091f\u0902\u091f \u0915\u0930\u0924\u0947 \u0907\u0938 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/farmers-protest-reliance-jio-towers-signboards-vandalised-hindi-words-blackened-punjab-11490",
        headline:
          "2017 Pics Of Defaced NH Signboards In Punjab Viral With Farmers' Protest Twist",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/farmers-protest-tractor-stunt-old-video-viral-republic-day-practice-11424",
        headline:
          "Old Tractor Stunt Video Shared As Farmers' Practice For Republic Day",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/viral-image-tents-farmers-protest-site-kumbh-mela-delhi-borders-11417",
        headline:
          "Image Of Tents At Kumbh Mela Peddled As Farmers' Protest In Delhi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-viral-news-doctors-covid-19-vaccine-injections-in-penis-cnn-news-11392",
        headline:
          "COVID-19 Vaccines To Be Injected In Penis? Morphed CNN Screenshot Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-united-kingdom-prime-minister-boris-johnson-india-visit-republic-day-canceled-farmers-protests-factcheck-11441",
        headline:
          "Boris Johnson Cancels India Visit Due To Farmers' Protest? A FactCheck",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/elderly-sikh-farmer-collapsing-at-singhu-protest-site-in-viral-video-is-alive-11458",
        headline:
          "Farmers Protest: \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09df \u09b2\u09c1\u099f\u09bf\u09df\u09c7 \u09aa\u09dc\u09be \u09ac\u09c3\u09a6\u09cd\u09a7 \u09b6\u09bf\u0996 \u0995\u09c3\u09b7\u0995 \u099c\u09c0\u09ac\u09bf\u09a4 \u0986\u099b\u09c7\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-viral-news-doctors-covid-19-vaccine-injections-in-penis-cnn-news-11393",
        headline:
          "\u09aa\u09c1\u09b0\u09c1\u09b7\u09c7\u09b0 \u099c\u09a8\u09a8\u09be\u0999\u09cd\u0997\u09c7 \u09a6\u09c7\u0993\u09df\u09be \u09b9\u09ac\u09c7 COVID-19 \u099f\u09bf\u0995\u09be? \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2 \u09ad\u09c1\u09df\u09cb CNN \u09b8\u09cd\u0995\u09cd\u09b0\u09bf\u09a8\u09b6\u099f",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-united-kingdom-prime-minister-boris-johnson-india-visit-republic-day-canceled-farmers-protests-factcheck-11483",
        headline:
          "Farmers Protest \u098f\u09b0 \u099c\u09a8\u09cd\u09af Boris Johnson \u098f\u09b0 \u09ad\u09be\u09b0\u09a4 \u09b8\u09ab\u09b0 \u09ac\u09be\u09a4\u09bf\u09b2?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/munawar-faruqui-standup-comedian-indore-madhya-pradesh-police-custody-11427",
        headline:
          "\u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09ad\u09bf\u09a1\u09bf\u0993\u09b0 \u09a8\u09bf\u0997\u09c3\u09b9\u09c0\u09a4 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf \u0995\u09ae\u09c7\u09a1\u09bf\u09df\u09be\u09a8 Munawar Faruqui \u09a8\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/viral-image-tents-farmers-protest-site-kumbh-mela-delhi-borders-11423",
        headline:
          "\u09e8\u09e6\u09e7\u09e9 \u09b8\u09be\u09b2\u09c7 Kumbh \u09ae\u09c7\u09b2\u09be\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 Singhu-\u09a4\u09c7 Farmers Protest-\u098f\u09b0 \u09a4\u09be\u0981\u09ac\u09c1 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/farmers-protest-tractor-stunt-old-video-viral-republic-day-practice-11488",
        headline:
          "\u0995\u09c3\u09b7\u0995\u09b0\u09be Republic Day-\u098f\u09b0 \u09ae\u09b9\u09dc\u09be \u09a6\u09bf\u099a\u09cd\u099b\u09c7 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb Tractor Stunt \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-of-haryana-bjp-mla-attack-672536",
        headline:
          "Fact Check: Video of political clash during panchayat elections in Jalore passed off as Haryana farmers attacking BJP MP",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-aerial-image-of-maha-kumbh-mela-passed-off-as-farmers-protest-at-delhi-672609",
        headline:
          "FACT CHECK: Aerial image of Maha Kumbh Mela passed off as farmers protest at Delhi",
      },
    ],
    "6": [
      {
        url:
          "https://www.altnews.in/old-fake-cheque-claiming-vijay-mallya-donated-rs-35-cr-to-bjp-before-leaving-india-revived-again-with-false-claim/",
        headline:
          "Morphed image viral as Vijay Mallya issued Rs 35 cr cheque to BJP before fleeing India",
      },
      {
        url:
          "https://factly.in/the-skeleton-in-this-picture-is-not-real-and-does-not-show-an-indian-soldier-from-the-1965-war/",
        headline:
          " The skeleton in this picture is not real and does not show an Indian soldier from the 1965 war",
      },
      {
        url:
          "https://factly.in/2016-photo-shared-as-aravind-kejriwal-offering-namaz-on-the-eve-of-new-year/",
        headline:
          " 2016 photo shared as Aravind Kejriwal offering Namaz on the eve of New Year",
      },
      {
        url:
          "https://factly.in/telugu-the-skeleton-in-this-picture-is-not-real-and-does-not-show-an-indian-soldier-from-the-1965-war/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c28\u0c3f\u0c1c\u0c2e\u0c48\u0c28 \u0c05\u0c38\u0c4d\u0c25\u0c3f\u0c2a\u0c02\u0c1c\u0c30\u0c02 \u0c15\u0c3e\u0c26\u0c41; \u0c26\u0c3e\u0c28\u0c3f\u0c24\u0c4b \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-no-evidence-yet-to-state-that-new-501-v2-coronavirus-strain-is-more-dangerous/",
        headline:
          " \u0c2e\u0c3f\u0c17\u0c3f\u0c24\u0c3e \u0c35\u0c3e\u0c1f\u0c3f\u0c15\u0c02\u0c1f\u0c47 \u0c15\u0c30\u0c4b\u0c28\u0c3e 501.V2 \u0c38\u0c4d\u0c1f\u0c4d\u0c30\u0c46\u0c2f\u0c3f\u0c28\u0c4d \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c15\u0c30\u0c02 \u0c05\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f\u0c35\u0c30\u0c15\u0c48\u0c24\u0c47 \u0c06\u0c27\u0c3e\u0c30\u0c3e\u0c32\u0c41 \u0c32\u0c47\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/morphed-photo-shared-as-narendra-modi-holding-population-control-bill-2021/",
        headline:
          " Morphed Photo shared as Narendra Modi Holding Population Control Bill 2021",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-photo-shared-as-the-design-of-nad-flyover-in-vizag/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c35\u0c48\u0c1c\u0c3e\u0c17\u0c4d \u0c32\u0c4b\u0c28\u0c3f NAD \u0c2b\u0c4d\u0c32\u0c48\u0c13\u0c35\u0c30\u0c4d \u0c2f\u0c4a\u0c15\u0c4d\u0c15 \u0c05\u0c38\u0c32\u0c41 \u0c21\u0c3f\u0c1c\u0c48\u0c28\u0c4d \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://digiteye.in/fact-check-old-image-of-a-caravan-of-trump-supporters-resurfaces-with-new-claim/",
        headline:
          "Fact Check: Old image of a caravan of Trump supporters resurfaces with new claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/06/fact-check-did-cm-kejriwal-offer-namaz-at-jama-masjid-on-jan-1-heres-the-truth/",
        headline:
          "Fact Check: Did CM Kejriwal Offer Namaz At Jama Masjid On Jan 1? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/06/fact-check-is-this-picture-of-an-indian-soldier-martyred-in-1965-war-heres-the-truth/",
        headline:
          "Fact Check: Is This Picture Of An Indian Soldier Martyred In 1965 War? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/photo-of-amit-shah-greeting-asaduddin-owaisi-is-morphed-11454",
        headline: "Photo Of Amit Shah Greeting Asaduddin Owaisi Is Morphed",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/hindu-temple-destroyed-in-pakistan-photos-fake-news-11406",
        headline:
          "\u092f\u0947 \u0924\u0938\u094d\u0935\u0940\u0930 \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u092e\u0947\u0902 \u092e\u0902\u0926\u093f\u0930 \u0924\u094b\u095c\u0947 \u091c\u093e\u0928\u0947 \u0915\u0947 \u092c\u093e\u0926 \u0939\u094b \u0930\u0939\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/viral-photo-claims-amit-shah-showing-gratitude-to-asaduddin-owaisi-ahead-of-west-bengal-election-2021-11479",
        headline:
          "\u0905\u0938\u0926\u0941\u0926\u094d\u0926\u0940\u0928 \u0913\u0935\u0948\u0938\u0940 \u0915\u094b \u092c\u0927\u093e\u0908 \u0926\u0947\u0924\u0947 \u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u090f\u0921\u093f\u091f\u0947\u0921 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/2013-jdu-bjp-clash-in-patna-shared-as-tmc-bjp-clash-in-coochbehar-11387",
        headline:
          "Mathabhanga-\u09df TMC-BJP \u09b8\u0982\u0998\u09b0\u09cd\u09b7 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09e8\u09e6\u09e7\u09e9 \u09b8\u09be\u09b2\u09c7 Bihar Bandh-\u098f\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-dilip-ghosh-mamata-banerjee-birthday-wish-11422",
        headline:
          "Mamata Banerjee-\u09b0 \u099c\u09a8\u09cd\u09ae\u09a6\u09bf\u09a8\u09c7\u09b0 \u099c\u09a8\u09cd\u09af Dilip Ghosh-\u098f\u09b0 \u09b6\u09c1\u09ad\u09c7\u099a\u09cd\u099b\u09be \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf \u09b8\u09b9",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/photo-of-amit-shah-greeting-asaduddin-owaisi-is-morphed-11459",
        headline:
          "AIMIM \u09aa\u09cd\u09b0\u09a7\u09be\u09a8 Asaduddin Owasi-\u0995\u09c7 Amit Shah-\u098f\u09b0 \u09b6\u09c1\u09ad\u09c7\u099a\u09cd\u099b\u09be \u099c\u09be\u09a8\u09be\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-truth-behind-mandir-banao-campaign-in-pakistan-1755667-2021-01-04",
        headline:
          "Fact Check: Truth behind \u2018Mandir Banao\u2019 campaign in Pakistan",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-centre-has-not-removed-the-nationality-column-from-indian-passports-1757293-2021-01-08",
        headline:
          "Fact Check: No, Centre has not removed the nationality column from Indian passports",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/06/fact-check-did-cm-kejriwal-offer-namaz-at-jama-masjid-on-jan-1-heres-the-truth/",
        headline:
          "Fact Check: Did CM Kejriwal Offer Namaz At Jama Masjid On Jan 1? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/06/fact-check-is-this-picture-of-an-indian-soldier-martyred-in-1965-war-heres-the-truth/",
        headline:
          "Fact Check: Is This Picture Of An Indian Soldier Martyred In 1965 War? Here\u2019s The Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-photo-claims-amit-shah-showing-gratitude-to-asaduddin-owaisi-ahead-of-west-bengal-election-2021-11479",
        headline:
          "\u0905\u0938\u0926\u0941\u0926\u094d\u0926\u0940\u0928 \u0913\u0935\u0948\u0938\u0940 \u0915\u094b \u092c\u0927\u093e\u0908 \u0926\u0947\u0924\u0947 \u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u090f\u0921\u093f\u091f\u0947\u0921 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/hindu-temple-destroyed-in-pakistan-photos-fake-news-11406",
        headline:
          "\u092f\u0947 \u0924\u0938\u094d\u0935\u0940\u0930 \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u092e\u0947\u0902 \u092e\u0902\u0926\u093f\u0930 \u0924\u094b\u095c\u0947 \u091c\u093e\u0928\u0947 \u0915\u0947 \u092c\u093e\u0926 \u0939\u094b \u0930\u0939\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u0940",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/photo-of-amit-shah-greeting-asaduddin-owaisi-is-morphed-11454",
        headline: "Photo Of Amit Shah Greeting Asaduddin Owaisi Is Morphed",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/ujjain-madhya-pradesh-stone-pelting-hindu-muslim-begambagh-mahakali-temple-viral-video-11402",
        headline:
          "Gulbarga-\u09a4\u09c7 Ram Navami \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09b0\u0999 \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/fact-check-asaduddin-owaisi-smriti-irani-image-in-textile-meeting-11425",
        headline:
          "West Bengal \u09ad\u09cb\u099f\u09c7\u09b0 \u0986\u0997\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 Asaduddin Owasi-Smriti Irani-\u09b0 \u09e8\u09e6\u09e7\u09ec'\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2013-jdu-bjp-clash-in-patna-shared-as-tmc-bjp-clash-in-coochbehar-11387",
        headline:
          "Mathabhanga-\u09df TMC-BJP \u09b8\u0982\u0998\u09b0\u09cd\u09b7 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09e8\u09e6\u09e7\u09e9 \u09b8\u09be\u09b2\u09c7 Bihar Bandh-\u098f\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/photo-of-amit-shah-greeting-asaduddin-owaisi-is-morphed-11459",
        headline:
          "AIMIM \u09aa\u09cd\u09b0\u09a7\u09be\u09a8 Asaduddin Owasi-\u0995\u09c7 Amit Shah-\u098f\u09b0 \u09b6\u09c1\u09ad\u09c7\u099a\u09cd\u099b\u09be \u099c\u09be\u09a8\u09be\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-dilip-ghosh-mamata-banerjee-birthday-wish-11422",
        headline:
          "Mamata Banerjee-\u09b0 \u099c\u09a8\u09cd\u09ae\u09a6\u09bf\u09a8\u09c7\u09b0 \u099c\u09a8\u09cd\u09af Dilip Ghosh-\u098f\u09b0 \u09b6\u09c1\u09ad\u09c7\u099a\u09cd\u099b\u09be \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf \u09b8\u09b9",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-rs-35-crore-cheque-purportedly-issued-by-vijay-mallya-to-bjp-is-morphed-672588",
        headline:
          "FACT CHECK: Viral image of Rs 35 crore cheque purportedly issued by Vijay Mallya to BJP is morphed",
      },
    ],
  },
  numpy_seed: 20,
  number_of_articles: 181,
}
