export const data = {
  mdsDat: {
    x: [
      -0.1936670152285885,
      0.2010743939777096,
      -0.2795410707571271,
      0.27213369200800613,
    ],
    y: [
      0.34450890017695207,
      0.043876151552515785,
      -0.29653395247113057,
      -0.0918510992583372,
    ],
    topics: [1, 2, 3, 4],
    cluster: [1, 1, 1, 1],
    Freq: [
      26.4203118668282,
      22.467343925469383,
      21.146421816957016,
      29.965922390745398,
    ],
  },
  tinfo: {
    Term: [
      "farmer",
      "protest",
      "deepika",
      "minister",
      "bjp",
      "vardhan",
      "woman",
      "deepika_padukone",
      "padukone",
      "harsh",
      "carl",
      "himalaya",
      "floodwater",
      "china",
      "rahul",
      "osama",
      "galore",
      "granddaughter",
      "demonstration",
      "farm",
      "bill",
      "hospital",
      "laden",
      "osama_bin",
      "bin",
      "daughter",
      "company",
      "laden_daughter",
      "marry",
      "late",
      "drug",
      "harsh",
      "rule",
      "ips",
      "alive",
      "persecute",
      "trinamool",
      "path",
      "attack",
      "demand",
      "mob",
      "vardhan",
      "bjp",
      "health_minister",
      "harsh_vardhan",
      "leader",
      "osd",
      "health",
      "union",
      "hathras_incident",
      "accuse",
      "incident",
      "minister",
      "hathras",
      "canal",
      "punjabi",
      "govt",
      "benz",
      "farmers_protest",
      "flood",
      "deepika_padukone",
      "cruise",
      "gold",
      "break",
      "deepika",
      "padukone",
      "greece",
      "corinth_canal",
      "shirt",
      "gujarat",
      "ship",
      "corinth",
      "farmer",
      "protest",
      "girl",
      "new",
      "yogi",
      "hathras_victim",
      "adityanath",
      "yogi_adityanath",
      "york",
      "avian_sarcoma",
      "avian",
      "sarcoma",
      "woman",
      "amit",
      "make",
      "amit_shah",
      "shah",
      "victim",
      "say",
      "hathras",
    ],
    Freq: [
      10.0,
      9.0,
      6.0,
      8.0,
      6.0,
      6.0,
      7.0,
      5.0,
      5.0,
      5.0,
      0.9995684656283336,
      1.9991369312566671,
      0.9995684656283336,
      0.9995684656283336,
      0.9995684656283336,
      3.9982738625133343,
      0.9995684656283336,
      0.9995684656283336,
      0.9995684656283336,
      0.9995684656283336,
      4.997842328141667,
      3.9982738625133343,
      3.9982738625133343,
      3.9982738625133343,
      3.9982738625133343,
      3.9982738625133343,
      2.9987053968850006,
      2.9987053968850006,
      2.9987053968850006,
      2.9987053968850006,
      2.9987053968850006,
      5.000085363805441,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      2.0000341455221764,
      1.0000170727610882,
      2.0000341455221764,
      6.00010243656653,
      6.00010243656653,
      4.000068291044353,
      4.000068291044353,
      4.000068291044353,
      4.000068291044353,
      4.000068291044353,
      3.000051218283265,
      3.000051218283265,
      3.000051218283265,
      3.000051218283265,
      7.000119509327617,
      5.000085363805441,
      4.000198127041035,
      1.0000495317602587,
      1.0000495317602587,
      2.0000990635205174,
      2.0000990635205174,
      1.0000495317602587,
      5.000247658801294,
      3.000148595280776,
      1.0000495317602587,
      1.0000495317602587,
      6.000297190561552,
      5.000247658801294,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      9.000445785842329,
      8.00039625408207,
      3.000998992367296,
      3.000998992367296,
      5.001664987278827,
      6.001997984734592,
      5.001664987278827,
      5.001664987278827,
      3.000998992367296,
      3.000998992367296,
      3.000998992367296,
      3.000998992367296,
      7.002330982190358,
      4.001331989823061,
      4.001331989823061,
      4.001331989823061,
      4.001331989823061,
      6.001997984734592,
      5.001664987278827,
      5.001664987278827,
    ],
    Total: [
      10.0,
      9.0,
      6.0,
      8.0,
      6.0,
      6.0,
      7.0,
      5.0,
      5.0,
      5.0,
      0.9995684656283336,
      1.9991369312566671,
      0.9995684656283336,
      0.9995684656283336,
      0.9995684656283336,
      3.9982738625133343,
      0.9995684656283336,
      0.9995684656283336,
      0.9995684656283336,
      0.9995684656283336,
      4.997842328141667,
      3.9982738625133343,
      3.9982738625133343,
      3.9982738625133343,
      3.9982738625133343,
      3.9982738625133343,
      2.9987053968850006,
      2.9987053968850006,
      2.9987053968850006,
      2.9987053968850006,
      2.9987053968850006,
      5.000085363805441,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      1.0000170727610882,
      2.0000341455221764,
      1.0000170727610882,
      2.0000341455221764,
      6.00010243656653,
      6.00010243656653,
      4.000068291044353,
      4.000068291044353,
      4.000068291044353,
      4.000068291044353,
      4.000068291044353,
      3.000051218283265,
      3.000051218283265,
      3.000051218283265,
      3.000051218283265,
      8.000452506783382,
      10.001750351084269,
      4.000198127041035,
      1.0000495317602587,
      1.0000495317602587,
      2.0000990635205174,
      2.0000990635205174,
      1.0000495317602587,
      5.000247658801294,
      3.000148595280776,
      1.0000495317602587,
      1.0000495317602587,
      6.000297190561552,
      5.000247658801294,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      4.000198127041035,
      10.000462858603417,
      9.999981792471491,
      3.000998992367296,
      3.000998992367296,
      5.001664987278827,
      6.001997984734592,
      5.001664987278827,
      5.001664987278827,
      3.000998992367296,
      3.000998992367296,
      3.000998992367296,
      3.000998992367296,
      7.002330982190358,
      4.001331989823061,
      4.001331989823061,
      4.001331989823061,
      4.001331989823061,
      7.00201505749568,
      6.001682060039915,
      10.001750351084269,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.193,
      -4.4998,
      -5.193,
      -5.193,
      -5.193,
      -3.8067,
      -5.193,
      -5.193,
      -5.193,
      -5.193,
      -3.5835,
      -3.8067,
      -3.8067,
      -3.8067,
      -3.8067,
      -3.8067,
      -4.0943,
      -4.0943,
      -4.0943,
      -4.0943,
      -4.0943,
      -3.421,
      -5.0304,
      -5.0304,
      -5.0304,
      -5.0304,
      -5.0304,
      -5.0304,
      -4.3373,
      -5.0304,
      -4.3373,
      -3.2387,
      -3.2387,
      -3.6441,
      -3.6441,
      -3.6441,
      -3.6441,
      -3.6441,
      -3.9318,
      -3.9318,
      -3.9318,
      -3.9318,
      -3.0845,
      -3.421,
      -3.5835,
      -4.9698,
      -4.9698,
      -4.2767,
      -4.2767,
      -4.9698,
      -3.3604,
      -3.8712,
      -4.9698,
      -4.9698,
      -3.1781,
      -3.3604,
      -3.5835,
      -3.5835,
      -3.5835,
      -3.5835,
      -3.5835,
      -3.5835,
      -2.7726,
      -2.8904,
      -4.2195,
      -4.2195,
      -3.7087,
      -3.5264,
      -3.7087,
      -3.7087,
      -4.2195,
      -4.2195,
      -4.2195,
      -4.2195,
      -3.3722,
      -3.9318,
      -3.9318,
      -3.9318,
      -3.9318,
      -3.5264,
      -3.7087,
      -3.7087,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.331,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.4931,
      1.3595,
      0.7998,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.5537,
      1.4483,
      1.3306,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.2051,
      1.051,
      1.0228,
      0.5121,
    ],
  },
  "token.table": {
    Topic: [
      2,
      4,
      2,
      4,
      4,
      2,
      4,
      4,
      3,
      1,
      1,
      2,
      3,
      3,
      1,
      1,
      1,
      3,
      3,
      3,
      1,
      3,
      3,
      2,
      1,
      1,
      1,
      2,
      3,
      3,
      3,
      1,
      1,
      4,
      3,
      3,
      1,
      3,
      3,
      2,
      2,
      2,
      4,
      2,
      4,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      4,
      1,
      2,
      4,
      2,
      4,
      1,
      1,
      2,
      3,
      2,
      2,
      1,
      2,
      3,
      3,
      1,
      2,
      4,
      2,
      4,
      4,
      3,
      3,
      2,
      2,
      2,
      2,
      4,
      4,
      4,
      4,
      4,
    ],
    Freq: [
      0.9999829275303859,
      0.9996671133946273,
      0.999982927530386,
      0.9996671133946273,
      0.9996671133946273,
      0.999982927530386,
      0.9996671133946273,
      0.9996671133946273,
      0.9999504706930151,
      1.0004317206739763,
      1.0004317206739761,
      0.9999829275303859,
      0.9999504706930151,
      0.9999504706930151,
      1.0004317206739761,
      1.0004317206739761,
      1.0004317206739763,
      0.9999504706930151,
      0.9999504706930151,
      0.9999504706930151,
      1.0004317206739761,
      0.9999504706930151,
      0.9999504706930149,
      0.999982927530386,
      1.0004317206739761,
      1.0004317206739763,
      1.0004317206739761,
      0.09999537162819401,
      0.899958344653746,
      0.9999504706930151,
      0.9999504706930151,
      1.0004317206739761,
      1.0004317206739761,
      0.9996671133946273,
      0.9999504706930151,
      0.9999504706930151,
      1.0004317206739761,
      0.9999504706930151,
      0.9999504706930151,
      0.999982927530386,
      0.999982927530386,
      0.49991249776175034,
      0.49991249776175034,
      0.9999829275303859,
      0.9996671133946273,
      0.999982927530386,
      0.999982927530386,
      1.0004317206739761,
      1.0004317206739761,
      0.9999829275303859,
      0.999982927530386,
      1.0004317206739761,
      1.0004317206739763,
      1.0004317206739763,
      0.999982927530386,
      0.9996671133946273,
      1.0004317206739763,
      0.874950509869895,
      0.12499292998141356,
      0.999982927530386,
      0.9996671133946273,
      1.0004317206739761,
      1.0004317206739761,
      0.999982927530386,
      0.9999504706930149,
      0.999982927530386,
      0.999982927530386,
      0.1000001820756166,
      0.1000001820756166,
      0.8000014566049328,
      0.9999504706930151,
      1.0004317206739761,
      0.999982927530386,
      0.9996671133946273,
      0.1666199558717293,
      0.8330997793586464,
      0.9996671133946273,
      0.9999504706930151,
      0.9999504706930151,
      0.999982927530386,
      0.9999829275303859,
      0.9999829275303859,
      0.14281603106944146,
      0.8568961864166488,
      0.9996671133946272,
      0.9996671133946273,
      0.9996671133946273,
      0.9996671133946273,
    ],
    Term: [
      "accuse",
      "adityanath",
      "alive",
      "amit",
      "amit_shah",
      "attack",
      "avian",
      "avian_sarcoma",
      "benz",
      "bill",
      "bin",
      "bjp",
      "break",
      "canal",
      "carl",
      "china",
      "company",
      "corinth",
      "corinth_canal",
      "cruise",
      "daughter",
      "deepika",
      "deepika_padukone",
      "demand",
      "demonstration",
      "drug",
      "farm",
      "farmer",
      "farmer",
      "farmers_protest",
      "flood",
      "floodwater",
      "galore",
      "girl",
      "gold",
      "govt",
      "granddaughter",
      "greece",
      "gujarat",
      "harsh",
      "harsh_vardhan",
      "hathras",
      "hathras",
      "hathras_incident",
      "hathras_victim",
      "health",
      "health_minister",
      "himalaya",
      "hospital",
      "incident",
      "ips",
      "laden",
      "laden_daughter",
      "late",
      "leader",
      "make",
      "marry",
      "minister",
      "minister",
      "mob",
      "new",
      "osama",
      "osama_bin",
      "osd",
      "padukone",
      "path",
      "persecute",
      "protest",
      "protest",
      "protest",
      "punjabi",
      "rahul",
      "rule",
      "sarcoma",
      "say",
      "say",
      "shah",
      "ship",
      "shirt",
      "trinamool",
      "union",
      "vardhan",
      "victim",
      "victim",
      "woman",
      "yogi",
      "yogi_adityanath",
      "york",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://hindi.boomlive.in/fake-news/farmers-bill-farmers-protest-protest-farm-bills-farmers-farm-bills-kisan-9946",
        headline:
          "\u0915\u0943\u0937\u093f \u092c\u093f\u0932\u094d\u0938 2020: \u092a\u0941\u0930\u093e\u0928\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u094b\u0902 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-the-person-seen-in-the-video-is-not-the-owner-of-himalaya-drug-company-9948",
        headline:
          "\u0928\u0939\u0940\u0902, \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u093e \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0939\u093f\u092e\u093e\u0932\u092f \u0921\u094d\u0930\u0917 \u0915\u0902\u092a\u0928\u0940 \u0915\u093e \u092e\u093e\u0932\u093f\u0915 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/photo-of-bhojpuri-singer-peddled-as-osama-bin-ladens-son-in-law-9983",
        headline:
          "\u0915\u094d\u092f\u093e \u0913\u0938\u093e\u092e\u093e \u092c\u093f\u0928 \u0932\u093e\u0926\u0947\u0928 \u0915\u0940 \u092c\u0947\u091f\u0940 \u095b\u094b\u092f\u093e \u0928\u0947 \u090f\u0915 \u092d\u094b\u091c\u092a\u0941\u0930\u0940 \u0917\u093e\u092f\u0915 \u0938\u0947 \u0936\u093e\u0926\u0940 \u0915\u0930 \u0932\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-bhojpuri-singer-peddled-as-osama-bin-ladens-son-in-law-9961",
        headline:
          "\u09ad\u09cb\u099c\u09aa\u09c1\u09b0\u09bf \u0997\u09be\u09df\u0995\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u0993\u09b8\u09be\u09ae\u09be \u09ac\u09bf\u09a8 \u09b2\u09be\u09a6\u09c7\u09a8\u09c7\u09b0 \u099c\u09be\u09ae\u09be\u0987 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-the-man-in-this-viral-clip-is-not-the-owner-of-himalaya-drug-co-9963",
        headline:
          "\u09a8\u09be, \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u0995\u09cd\u09b2\u09bf\u09aa\u09c7\u09b0 \u098f\u0987 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf \u09b9\u09bf\u09ae\u09be\u09b2\u09af\u09bc \u09a1\u09cd\u09b0\u09be\u0997 \u0995\u09cb\u09ae\u09cd\u09aa\u09be\u09a8\u09bf\u09b0 \u09ae\u09be\u09b2\u09bf\u0995 \u09a8\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-is-not-a-photo-of-the-deceased-hathras-victim-9978",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u09b9\u09be\u09a5\u09b0\u09b8\u09c7 \u09aa\u09cd\u09b0\u09df\u09be\u09a4 \u09a8\u09bf\u09b0\u09cd\u09af\u09be\u09a4\u09bf\u09a4\u09be\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018s-video-of-kisan-long-march-in-maharashtra-shared-as-recent-10006",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09ae\u09b9\u09be\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09c7\u09b0 \u0995\u09c3\u09b7\u0995 \u09b2\u0982 \u09ae\u09be\u09b0\u09cd\u099a\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2 \u09b9\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-mgm-hospital-did-not-agree-to-handover-spb-body-is-fake-news/",
        headline:
          " MGM \u0c39\u0c3e\u0c38\u0c4d\u0c2a\u0c3f\u0c1f\u0c32\u0c4d \u0c0e\u0c38\u0c4d.\u0c2a\u0c3f. \u0c2c\u0c3e\u0c32 \u0c38\u0c41\u0c2c\u0c4d\u0c30\u0c39\u0c4d\u0c2e\u0c23\u0c4d\u0c2f\u0c02 \u0c2a\u0c3e\u0c30\u0c4d\u0c25\u0c3f\u0c35\u0c26\u0c47\u0c39\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c35\u0c3e\u0c30\u0c3f \u0c15\u0c41\u0c1f\u0c41\u0c02\u0c2d\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c05\u0c02\u0c26\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c4d\u0c24\u0c32\u0c4b \u0c28\u0c3f\u0c1c\u0c02 \u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-the-woman-singing-in-this-video-is-not-mohd-rafi-grand-daughter/",
        headline:
          " \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c39\u0c30\u0c3f\u0c28\u0c3e\u0c2e \u0c38\u0c02\u0c15\u0c40\u0c30\u0c4d\u0c24\u0c28 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2a\u0c4d\u0c30\u0c16\u0c4d\u0c2f\u0c3e\u0c24 \u0c2d\u0c15\u0c4d\u0c24\u0c3f \u0c17\u0c3e\u0c2f\u0c15\u0c41\u0c30\u0c3e\u0c32\u0c41 \u0c17\u0c40\u0c24\u0c3e\u0c02\u0c1c\u0c32\u0c3f \u0c30\u0c3e\u0c2f\u0c4d, \u0c2e\u0c39\u0c2e\u0c4d\u0c2e\u0c26\u0c4d \u0c30\u0c2b\u0c40 \u0c2e\u0c28\u0c35\u0c30\u0c3e\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/the-allegations-on-mgm-hospital-regarding-s-p-balasubrahmanyam-treatment-bills-are-false/",
        headline:
          " The allegations on MGM hospital regarding S.P. Balasubrahmanyam treatment bills are false",
      },
      {
        url:
          "https://factly.in/frdi-bill-2017-has-been-withdrawn-by-the-government-in-2018-itself/",
        headline:
          " FRDI Bill, 2017 has been withdrawn by the government in 2018 itself",
      },
      {
        url:
          "https://factly.in/image-of-pak-model-is-peddled-as-osama-bin-ladens-daughter-getting-married-to-a-hindu/",
        headline:
          " Image of Pak model is peddled as Osama bin Laden\u2019s daughter getting married to a Hindu.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-rumours-galore-over-who-settled-spb-s-hospital-bills-1726299-2020-09-28",
        headline:
          "Fact Check: Rumours galore over who settled SPB\u2019s hospital bills",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-do-not-believe-this-hoax-about-microchip-implant-in-coronavirus-vaccine-1726551-2020-09-29",
        headline:
          "Fact Check: Do not believe this hoax about microchip implant in coronavirus vaccine",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-these-are-not-indian-paratroopers-in-ladakh-but-an-old-video-from-spain/",
        headline:
          "Fact check: These are NOT Indian paratroopers in Ladakh, but an old video from Spain",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-this-video-camels-stranded-in-floodwater-is-not-from-rajasthan-heres-the-truth/",
        headline:
          "Fact Check: This video of camels stranded in floodwater is NOT from Rajasthan; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-2018-picture-of-kisan-march-shared-as-a-recent-protest-against-the-farm-bills/",
        headline:
          "Fact check : 2018 picture of Kisan March shared as a recent protest against the Farm Bills",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-no-the-government-is-not-providing-rs-11000-scholarship-to-students-due-to-covid-19/",
        headline:
          "Fact Check: No, the Government is NOT providing Rs 11,000 scholarship to students due to COVID-19",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-steam-from-a-pressure-cooker-does-not-help-in-covid-19-treatment/",
        headline:
          "FALSE: The claim that taking steam from a pressure cooker can kill Covid 19 virus",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-did-rahul-gandhi-cut-former-pm-manmohan-singhs-birthday-cake/",
        headline:
          "Fact Check: Did Rahul Gandhi cut former PM Manmohan Singh\u2019s birthday cake?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-the-man-in-this-video-is-not-the-owner-of-himalaya-drug-company/",
        headline:
          "Fact Check: The man in this video is not the owner of Himalaya Drug Company",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-old-video-of-carl-sanders-who-designed-rfid-microchip-falsely-linked-to-covid-19/",
        headline:
          "Fact Check: Old video of Carl Sanders who designed RFID microchip falsely linked to COVID-19",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-centre-has-not-given-rs-50000-aid-to-bpl-families-for-marriage-of-their-daughters/",
        headline:
          "Fact Check: Centre has NOT given Rs 50,000 aid to BPL families for marriage of their daughters",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-this-video-of-a-man-being-thrashed-is-not-from-china-find-the-truth-here/",
        headline:
          "Fact Check: This video of a man being thrashed is NOT from China; find the truth here",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-fake-news-about-late-sp-balasubrahmanyams-hospital-bills-goes-viral-heres-the-truth/",
        headline:
          "Fact Check: Fake news about late SP Balasubrahmanyam\u2019s hospital bills goes viral; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-did-centre-withdraw-the-dearness-allowance-cut-announcement-heres-the-truth/",
        headline:
          "Fact Check: Did Centre withdraw the Dearness Allowance cut announcement? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-is-osama-bin-ladens-daughter-zoya-getting-married-to-a-hindu-heres-the-truth/",
        headline:
          "Fact Check: Is Osama Bin Laden\u2019s daughter Zoya getting married to a Hindu? Here\u2019s the truth",
      },
    ],
    "2": [
      {
        url:
          "https://www.boomlive.in/fake-news/2016-video-falsely-shared-as-health-minister-harsh-vardhan-assaulted-9971",
        headline:
          "2016 Video Falsely Shared As Health Minister Harsh Vardhan Assaulted",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/hathras-incident-photo-of-up-bjp-leader-peddled-as-accuseds-father-10021",
        headline:
          "Hathras Incident: Photo Of UP BJP Leader Peddled As Accused's Father",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-sri-lankan-artist-viral-as-protest-against-hathras-incident-10027",
        headline:
          "Photo Of Sri Lankan Artist Viral As Protest Against Hathras Incident",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2012-photo-from-california-falsely-shared-as-images-of-atal-tunnel-10039",
        headline:
          "2012 Photo From California Falsely Shared As Image Of Atal Tunnel",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/man-being-beaten-up-in-video-is-not-bjp-mp-dr-harsh-vardhan-10004",
        headline:
          "\u0928\u0939\u0940\u0902, \u092d\u0940\u095c \u0928\u0947 \u0915\u0947\u0902\u0926\u094d\u0930\u0940\u092f \u092e\u0902\u0924\u094d\u0930\u0940 \u0939\u0930\u094d\u0937\u0935\u0930\u094d\u0927\u0928 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0928\u0939\u0940\u0902 \u0915\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/picture-of-a-bjp-neta-is-viral-with-claim-that-he-is-hathras-rape-accused-sandeep-thakurs-father-10023",
        headline:
          "\u0909\u0924\u094d\u0924\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u092c\u0940\u091c\u0947\u092a\u0940 \u0928\u0947\u0924\u093e \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u093e\u0925\u0930\u0938 \u0917\u0948\u0902\u0917\u0930\u0947\u092a \u0906\u0930\u094b\u092a\u0940 \u0915\u093e \u092a\u093f\u0924\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2016-video-falsely-shared-as-health-minister-harsh-vardhan-assaulted-10001",
        headline:
          "\u09b8\u09cd\u09ac\u09be\u09b8\u09cd\u09a5\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09b9\u09b0\u09cd\u09b7 \u09ac\u09b0\u09cd\u09a7\u09a8\u0995\u09c7 \u09a8\u09bf\u0997\u09c3\u09b9? \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09e8\u09e6\u09e7\u09ec'\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/hathras-incident-photo-of-up-bjp-leader-peddled-as-accuseds-father-10026",
        headline:
          "\u09b9\u09be\u09a5\u09b0\u09b8 \u0998\u099f\u09a8\u09be: \u0985\u09ad\u09bf\u09af\u09c1\u0995\u09cd\u09a4\u09c7\u09b0 \u09ac\u09be\u09ac\u09be \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u0987\u0989\u09aa\u09bf\u09b0 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09a8\u09c7\u09a4\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-street-play-revived-as-comment-by-tmc-mp-sajda-ahmed-10036",
        headline:
          "\u09a4\u09c3\u09a3\u09ae\u09c2\u09b2 \u0995\u0982\u0997\u09cd\u09b0\u09c7\u09b8 \u09b8\u09be\u0982\u09b8\u09a6 \u09b8\u09be\u099c\u09a6\u09be \u0986\u09b9\u09ae\u09c7\u09a6\u09c7\u09b0 \u0995\u09a5\u09be \u09ac\u09b2\u09c7 \u09aa\u09a5 \u09a8\u09be\u099f\u09bf\u0995\u09be\u09b0 \u0995\u09cd\u09b2\u09bf\u09aa \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://factly.in/old-video-of-ink-attack-on-kurukshetra-mp-is-being-falsely-shared-as-recent-attack-on-bjp-leader-by-farmers-against-the-farm-bills/",
        headline:
          " Old video of ink attack on Kurukshetra MP is being falsely shared as recent attack on BJP leader by farmers",
      },
      {
        url:
          "https://factly.in/osd-to-former-maharashtra-cm-devendra-fadnavis-is-being-referred-as-osd-to-uddhav-thackeray/",
        headline:
          " OSD to former Maharashtra CM Devendra Fadnavis is being referred as OSD to Uddhav Thackeray",
      },
      {
        url:
          "https://factly.in/presidents-rule-is-imposed-even-during-narendra-modi-as-prime-minister/",
        headline:
          " President\u2019s rule is imposed even during Narendra Modi as Prime Minister",
      },
      {
        url:
          "https://factly.in/assaulted-bjp-party-worker-in-west-bengal-is-being-portrayed-as-the-union-health-minister-harsh-vardhan/",
        headline:
          " Assaulted BJP party worker in West Bengal is being portrayed as the Union Health Minister Harsh Vardhan",
      },
      {
        url:
          "https://factly.in/telugu-the-girl-in-this-photo-is-the-victim-of-hathras-gang-rape/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c39\u0c24\u0c4d\u0c30\u0c3e\u0c38\u0c4d\u200c (\u0c09\u0c24\u0c4d\u0c24\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d) \u0c05\u0c24\u0c4d\u0c2f\u0c3e\u0c1a\u0c3e\u0c30 \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c30\u0c3e\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-the-man-assaulted-in-this-video-is-not-union-health-minister-harsha-vardhan/",
        headline:
          " \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c26\u0c3e\u0c21\u0c3f\u0c15\u0c3f \u0c17\u0c41\u0c30\u0c48\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c02\u0c24\u0c4d\u0c30\u0c3f \u0c39\u0c30\u0c4d\u0c37 \u0c35\u0c30\u0c4d\u0c27\u0c28\u0c4d \u0c15\u0c3e\u0c26\u0c41, \u0c2a\u0c36\u0c4d\u0c1a\u0c3f\u0c2e \u0c2c\u0c46\u0c02\u0c17\u0c3e\u0c32\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c12\u0c15 \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c3f \u0c15\u0c3e\u0c30\u0c4d\u0c2f\u0c15\u0c30\u0c4d\u0c24",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/28/fact-check-the-man-in-viral-video-talking-about-criminalization-of-politics-isnt-ips-officer-shailjakant-mishra/",
        headline:
          "Fact Check: The man in viral video talking about criminalization of politics isn\u2019t IPS officer Shailjakant Mishra",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-fadnavis-osd-being-described-as-uddhavs-osd-in-viral-post-where-he-cries-on-the-plight-of-covid-19-patients-in-maharashtra/",
        headline:
          "Fact Check: Fadnavis\u2019 OSD being described as Uddhav\u2019s OSD in viral post where he cries on the plight of COVID-19 patients in Maharashtra",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-the-man-being-assaulted-by-a-mob-in-the-video-is-not-health-minister-harsh-vardhan/",
        headline:
          "Fact Check: The man being assaulted by a mob in the video is NOT Health Minister Dr Harsh Vardhan",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fake-news/image-of-deepika-padukone-in-solidarity-with-farmers-protest-is-morphed-9944",
        headline:
          "Image Of Deepika Padukone In Solidarity With Farmers Protest Is Morphed",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/govt-official-breaks-down-over-maharashtras-covid-crisis-a-factcheck-9949",
        headline:
          "Govt Official Breaks Down Over Maharashtra's COVID Crisis? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/cruise-ship-crossing-corinth-canal-in-greece-viral-as-gujarat-9954",
        headline:
          "Cruise Ship Crossing Corinth Canal In Greece Viral As Gujarat",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/thats-not-ravish-kumar-in-this-viral-video-mocking-him-10002",
        headline: "That's Not Ravish Kumar In This Viral Video Mocking Him",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/deepika-padukone-photo-with-i-stand-with-farmers-t-shirt-is-morphed-9980",
        headline:
          "\u0926\u0940\u092a\u093f\u0915\u093e \u092a\u093e\u0926\u0941\u0915\u094b\u0923 \u0915\u0940 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0938\u0947 \u0939\u092e\u0926\u0930\u094d\u0926\u0940 \u091c\u0924\u093e\u0924\u0940 \u091f\u0940-\u0936\u0930\u094d\u091f \u092a\u0939\u0928\u0947 \u095e\u094b\u091f\u094b \u092e\u0949\u0930\u094d\u092b\u094d\u0921 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-from-greece-viral-as-cruise-ship-service-in-gujarat-9993",
        headline:
          "\u0917\u094d\u0930\u0940\u0938 \u0915\u0940 \u0915\u094b\u0930\u093f\u0902\u0925 \u0928\u0939\u0930 \u0938\u0947 \u0917\u0941\u095b\u0930\u0924\u093e \u0915\u094d\u0930\u0942\u095b \u0936\u093f\u092a \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0917\u0941\u091c\u0930\u093e\u0924 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/man-crawling-on-ground-viral-video-not-ndtv-ravish-kumar-but-fayaz-bukhari-10014",
        headline:
          "\u0907\u0938 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0930\u0935\u0940\u0936 \u0915\u0941\u092e\u093e\u0930 \u0928\u0939\u0940\u0902 \u095e\u092f\u093e\u095b \u092c\u0941\u0916\u093e\u0930\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-of-deepika-padukone-in-solidarity-with-farmers-protest-is-morphed-9950",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09b8\u09ae\u09b0\u09cd\u09a5\u09a8\u09c7 \u09a6\u09c0\u09aa\u09bf\u0995\u09be \u09aa\u09be\u09a1\u09bc\u09c1\u0995\u09cb\u09a8\u09c7\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4",
      },
      {
        url:
          "https://factly.in/telugu-mexico-flood-video-is-being-shared-as-video-of-musi-river-from-vikarabad/",
        headline:
          " \u0c2e\u0c46\u0c15\u0c4d\u0c38\u0c3f\u0c15\u0c4b \u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c35\u0c3f\u0c15\u0c3e\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c1c\u0c3f\u0c32\u0c4d\u0c32\u0c3e\u0c32\u0c4b \u0c2e\u0c42\u0c38\u0c40 \u0c28\u0c26\u0c3f \u0c35\u0c30\u0c26 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-the-song-in-this-video-was-not-sung-by-spb-from-icu/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c2a\u0c3e\u0c1f\u0c28\u0c3f ICU\u0c32\u0c4b \u0c28\u0c41\u0c02\u0c1a\u0c3f \u0c0e\u0c38\u0c4d.\u0c2a\u0c40.\u0c2c\u0c3e\u0c32\u0c38\u0c41\u0c2c\u0c4d\u0c30\u0c39\u0c4d\u0c2e\u0c23\u0c4d\u0c2f\u0c02 \u0c2a\u0c3e\u0c21\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/old-images-of-farmers-protest-are-being-shared-as-those-of-the-current-protest/",
        headline:
          " Old images of farmers protest are being shared as those of the current protest",
      },
      {
        url:
          "https://factly.in/morphed-photo-shared-as-a-pro-farmer-slogan-on-deepika-padukones-t-shirt/",
        headline:
          " Morphed photo shared as a pro-farmer slogan on Deepika Padukone\u2019s t-shirt",
      },
      {
        url:
          "https://factly.in/punjabi-actresss-video-expressing-solidarity-with-farmers-is-shared-as-farmers-protesting-in-benz-car/",
        headline:
          " Punjabi actress\u2019s video expressing solidarity with farmers is shared as farmers protesting in Benz car",
      },
      {
        url:
          "https://factly.in/telugu-morphed-photo-shared-as-a-pro-farmer-slogan-on-deepika-padukones-t-shirt/",
        headline:
          " \u0c26\u0c40\u0c2a\u0c3f\u0c15\u0c3e \u0c2a\u0c26\u0c41\u0c15\u0c4a\u0c28\u0c46 \u0c1f\u0c3f\u0c37\u0c30\u0c4d\u0c1f\u0c41 \u0c2a\u0c48 \u0c30\u0c48\u0c24\u0c41\u0c32\u0c15\u0c41 \u0c05\u0c02\u0c21\u0c17\u0c3e \u0c28\u0c3f\u0c32\u0c2c\u0c21\u0c24\u0c3e\u0c28\u0c41 \u0c05\u0c28\u0c47 \u0c38\u0c4d\u0c32\u0c4b\u0c17\u0c28\u0c4d \u0c2b\u0c4b\u0c1f\u0c4b\u0c37\u0c3e\u0c2a\u0c4d \u0c1a\u0c47\u0c2f\u0c2c\u0c21\u0c3f\u0c28\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/this-cruising-ship-video-is-from-the-corinth-canal-in-greece-not-from-gujarat/",
        headline:
          " This cruising ship video is from the Corinth Canal in Greece, not from Gujarat",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/madras-high-court-logo-hindi-fact-check-1726222-2020-09-28",
        headline:
          "Fact Check: Madras high court did not \u2018change\u2019 its logo to Hindi\u00a0",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/28/fact-check-did-deepika-wear-a-t-shirt-in-support-of-farmers-heres-the-truth/",
        headline:
          "Fact Check: Did Deepika wear a T-shirt in support of farmers? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/28/fact-check-kerala-gold-smuggling-protest-pictures-falsely-attributed-to-farmers-protest/",
        headline:
          "Fact Check: Kerala gold smuggling protest pictures falsely attributed to farmers\u2019 protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-old-video-of-ship-passing-through-corinth-canal-in-greece-passed-off-as-gujarat/",
        headline:
          "Fact Check: Old video of ship passing through Corinth Canal in Greece passed off as Gujarat",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-old-siren-video-being-falsely-shared-to-suggest-chinese-loudspeaker-impacted-eardrums-of-indian-soldiers/",
        headline:
          "Fact Check: Old siren video being falsely shared to suggest Chinese loudspeaker impacted eardrums of Indian soldiers",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-dont-fall-for-false-claims-that-farmers-riding-on-mercedes-benz-are-protesting-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t fall for false claims that farmers riding on Mercedes-Benz are protesting; here\u2019s the truth",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-is-not-a-photo-of-the-deceased-hathras-victim-9970",
        headline: "No, This Is Not A Photo Of The Deceased Hathras Victim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/viral-message-falsely-claims-amit-shah-is-in-ny-to-treat-avian-sarcoma-9981",
        headline:
          "Viral Message Falsely Claims Amit Shah Is In NY To Treat Avian Sarcoma",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-yogi-adityanath-did-not-say-our-job-to-save-cows-not-women-9999",
        headline:
          "No, Yogi Adityanath Did Not Say 'Our Job To Save Cows, Not Women'",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/false-social-media-claims-that-bank-accounts-to-be-shut-without-nrc-10000",
        headline:
          "False: Social Media Claims That Bank Accounts To Be Shut Without NRC",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-yogi-adityanath-watching-hathras-victims-cremation-is-fake-10012",
        headline:
          "Photo Of Yogi Adityanath Watching Hathras Victim's Cremation Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/puducherry-cm-pays-tribute-to-woman-misidentified-as-hathras-victim-10032",
        headline:
          "Puducherry CM Pays Tribute To Woman Misidentified As Hathras Victim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/up-cm-did-not-say-thakurs-make-mistakes-screenshot-is-edited-10035",
        headline:
          "UP CM Did Not Say 'Thakurs Make Mistakes', Screenshot Is Edited",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/unrelated-video-of-woman-being-felicitated-viral-with-hathras-twist-10041",
        headline:
          "Unrelated Video Of Woman Being Felicitated Viral With Hathras Twist",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/hathras-case-girl-unrelated-photo-viral-9972",
        headline:
          "\u091c\u0940 \u0928\u0939\u0940\u0902, \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u093e\u0925\u0930\u0938 \u092a\u0940\u095c\u093f\u0924\u093e \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photo-of-yogi-adityanath-watching-hathras-victims-cremation-is-fake-10016",
        headline:
          "\u0939\u093e\u0925\u0930\u0938 \u092a\u0940\u095c\u093f\u0924\u093e \u0915\u0947 \u0905\u0902\u0924\u093f\u092e \u0938\u0902\u0938\u094d\u0915\u093e\u0930 \u0915\u093e \u0932\u093e\u0907\u0935 \u092a\u094d\u0930\u0938\u093e\u0930\u0923 \u0926\u0947\u0916\u0924\u0947 \u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/yogi-adityanath-did-not-say-hamara-kaam-gaay-bachana-hai-ladki-nahi-10018",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0915\u0939\u093e '\u0939\u092e\u093e\u0930\u093e \u0915\u093e\u092e \u0917\u093e\u092f \u092c\u091a\u093e\u0928\u093e \u0939\u0948, \u0932\u095c\u0915\u0940 \u0928\u0939\u0940\u0902'",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/up-cm-did-not-say-thakurs-make-mistakes-screenshot-is-edited-10037",
        headline:
          '\u0928\u0939\u0940\u0902, \u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0915\u0939\u093e \u0915\u093f, "\u0920\u093e\u0915\u0941\u0930\u094b\u0902 \u0938\u0947 \u0917\u0932\u0924\u093f\u092f\u093e\u0902 \u0939\u094b \u091c\u093e\u0924\u0940 \u0939\u0948"',
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-claim-of-sonia-gandhi-among-worlds-richest-politicians-revived-9941",
        headline:
          "\u09ac\u09bf\u09b6\u09cd\u09ac\u09c7\u09b0 \u09a7\u09a8\u09c0 \u09b0\u09be\u099c\u09a8\u09c0\u09a4\u09bf\u0995\u09a6\u09c7\u09b0 \u09a4\u09be\u09b2\u09bf\u0995\u09be\u09df \u09b8\u09cb\u09a8\u09bf\u09df\u09be \u0997\u09be\u0981\u09a7\u09c0, \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-message-falsely-claims-amit-shah-is-in-ny-to-treat-avian-sarcoma-9994",
        headline:
          "\u0985\u09cd\u09af\u09be\u09ad\u09bf\u09af\u09bc\u09be\u09a8 \u09b8\u09be\u09b0\u0995\u09cb\u09ae\u09be \u09b8\u09be\u09b0\u09be\u09a4\u09c7 \u0985\u09ae\u09bf\u09a4 \u09b6\u09be\u09b9 \u09a8\u09bf\u0989\u0987\u09df\u09b0\u09cd\u0995\u09c7, \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09ac\u09be\u09b0\u09cd\u09a4\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-video-of-sonbhadra-shared-as-hathras-victims-family-meets-priyanka-gandhi-10022",
        headline:
          "\u09b8\u09cb\u09a8\u09ad\u09a6\u09cd\u09b0\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09b9\u09be\u09a5\u09b0\u09b8\u09c7 \u09a8\u09bf\u09b0\u09cd\u09af\u09be\u09a4\u09bf\u09a4\u09be\u09b0 \u09ac\u09be\u09dc\u09bf\u09a4\u09c7 \u09aa\u09cd\u09b0\u09bf\u09df\u0999\u09cd\u0995\u09be \u0997\u09be\u0981\u09a7\u09c0",
      },
      {
        url:
          "https://factly.in/this-abduction-video-of-a-woman-nothing-to-do-with-the-vikarabad-incident/",
        headline: " This abduction video of a woman is from Kolar, Karnataka",
      },
      {
        url:
          "https://factly.in/telugu-this-abduction-video-of-a-woman-nothing-to-do-with-the-vikarabad-incident/",
        headline:
          " \u0c15\u0c4b\u0c32\u0c3e\u0c30\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c15\u0c30\u0c4d\u0c23\u0c3e\u0c1f\u0c15  \u0c2f\u0c41\u0c35\u0c24\u0c3f \u0c15\u0c3f\u0c21\u0c4d\u0c28\u0c3e\u0c2a\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f, \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c2a\u0c30\u0c3f\u0c38\u0c30 \u0c2a\u0c4d\u0c30\u0c3e\u0c02\u0c24\u0c3e\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f \u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-of-a-woman-protesting-in-a-chain-type-dress-is-from-srilanka-not-india/",
        headline:
          " \u0c17\u0c4a\u0c32\u0c41\u0c38\u0c41\u0c32\u0c24\u0c4b \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c21\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c27\u0c30\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2e\u0c39\u0c3f\u0c33 \u0c2b\u0c4b\u0c1f\u0c4b \u0c36\u0c4d\u0c30\u0c40\u0c32\u0c02\u0c15\u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28\u0c26\u0c3f, \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/amit-shah-did-not-fly-to-new-york-to-treat-avian-sarcoma/",
        headline:
          " Amit Shah did not fly to New York for Avian Sarcoma treatment",
      },
      {
        url:
          "https://factly.in/image-of-chain-clad-woman-is-from-sri-lanka-not-india/",
        headline: " Image of chain clad Woman is from Sri Lanka, not India",
      },
      {
        url:
          "https://factly.in/these-screenshots-show-tweets-posted-from-fan-page-accounts-of-sonu-nigam/",
        headline:
          " These screenshots show tweets posted from fan page accounts of Sonu Nigam",
      },
      {
        url:
          "https://factly.in/telugu-who-chief-did-not-claim-india-supplied-medicines-to-150-countries-during-covid-19/",
        headline:
          " \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d \u0c28\u0c3f \u0c0e\u0c26\u0c41\u0c30\u0c4d\u0c15\u0c4b\u0c35\u0c21\u0c02\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a \u0c26\u0c47\u0c36\u0c3e\u0c32\u0c15\u0c41 \u0c2e\u0c4b\u0c26\u0c40 \u0c24\u0c46\u0c32\u0c3f\u0c2a\u0c3f\u0c28 \u0c38\u0c02\u0c18\u0c40\u0c2d\u0c3e\u0c35\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c15\u0c43\u0c24\u0c1c\u0c4d\u0c1e\u0c24\u0c32\u0c41 \u0c24\u0c46\u0c32\u0c41\u0c2a\u0c41\u0c24\u0c42 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 WHO \u0c1a\u0c40\u0c2b\u0c4d \u0c1f\u0c47\u0c21\u0c4d\u0c30\u0c4b\u0c38\u0c4d \u0c1f\u0c4d\u0c35\u0c40\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3e\u0c21\u0c41",
      },
      {
        url:
          "https://factly.in/the-punjab-policewoman-in-these-photos-has-not-been-raped-she-died-in-a-road-accident/",
        headline:
          " The Punjab policewoman in these photos has not been raped. She died in a road accident.",
      },
      {
        url:
          "https://factly.in/telugu-female-police-constable-in-punjab-was-not-raped-murdered-she-died-in-accident/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4d\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c2a\u0c02\u0c1c\u0c3e\u0c2c\u0c4d \u0c2e\u0c39\u0c3f\u0c33\u0c3e  \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c4d \u0c15\u0c3e\u0c28\u0c3f\u0c38\u0c4d\u0c1f\u0c47\u0c2c\u0c41\u0c32\u0c4d \u0c05\u0c24\u0c4d\u0c2f\u0c3e\u0c1a\u0c3e\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c17\u0c41\u0c30\u0c35\u0c32\u0c47\u0c26\u0c41; \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c02\u0c32\u0c4b \u0c2e\u0c30\u0c23\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/wrong-girl-goes-viral-on-social-media-as-hathras-victim-1726722-2020-09-29",
        headline:
          "Fact Check: Wrong girl goes viral on social media as Hathras victim\u00a0",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/29/fact-check-dont-fall-for-this-fabricated-television-grab-of-bihar-cm-nitish-kumar/",
        headline:
          "Fact Check: Don\u2019t fall for this fabricated television grab of Bihar CM Nitish Kumar",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-viral-video-of-leopard-in-a-resort-is-not-from-ranthambore-heres-the-truth/",
        headline:
          "Fact Check: Viral video of leopard in a resort is NOT from Ranthambore; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/30/fact-check-has-home-minister-amit-shah-been-flown-to-new-york-for-treatment-heres-the-truth/",
        headline:
          "Fact Check: Has Home Minister Amit Shah been flown to New York for treatment? Here\u2019s the truth",
      },
    ],
  },
  numpy_seed: 1,
  number_of_articles: 94,
}
