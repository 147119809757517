export const data = {
  mdsDat: {
    x: [
      0.15632984277425566,
      0.26864326439965147,
      -0.2062684040400668,
      -0.30982168727668413,
      0.09111698414284447,
    ],
    y: [
      -0.3516823213387812,
      0.06550532576218786,
      0.03853475113901238,
      -0.05677916906959498,
      0.3044214135071757,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      17.673444061573257,
      15.167106566788052,
      25.767801156823527,
      19.6138450502957,
      21.777803164519458,
    ],
  },
  tinfo: {
    Term: [
      "pranab",
      "mukherjee",
      "tweet",
      "rana",
      "death",
      "idol",
      "kali",
      "police",
      "man",
      "pranab_mukherjee",
      "suresh",
      "pradesh",
      "hit",
      "shah",
      "hike",
      "maldar",
      "interrogation",
      "fuel",
      "child",
      "cbi",
      "covid",
      "exam",
      "rhea",
      "chakraborty",
      "rhea_chakraborty",
      "amit",
      "report",
      "pakistan",
      "pak",
      "fall",
      "marriage",
      "go",
      "presidential",
      "peace",
      "gita",
      "express",
      "nominates",
      "bhagavad",
      "rahul",
      "mukherjee_death",
      "republican",
      "vice",
      "pranab",
      "tweet",
      "mukherjee",
      "rana",
      "pranab_mukherjee",
      "ayyub",
      "journalist",
      "rana_ayyub",
      "ayub",
      "tweet_pranab",
      "death",
      "afzal",
      "riot",
      "muslim_man",
      "love",
      "girl",
      "mishra",
      "love_jihad",
      "jihad",
      "kapil_mishra",
      "chennai",
      "manufacture",
      "man",
      "sweden",
      "norway",
      "sister",
      "cop",
      "muslim",
      "muslims",
      "flag",
      "burn",
      "capture",
      "maa",
      "arab",
      "message",
      "johnson",
      "baseless",
      "official",
      "ips",
      "restaurant",
      "abu",
      "idol",
      "kali",
      "police",
      "bengal",
      "angle",
      "west",
      "kali_idol",
      "west_bengal",
      "murshidabad",
      "israel",
      "murshidabad_police",
      "communal_angle",
      "communal",
      "burn",
      "group",
      "ban",
      "soldier",
      "galvan",
      "corpse",
      "minor",
      "decompose",
      "prasad",
      "log",
      "celebrate",
      "chinese",
      "wagah",
      "border",
      "galwan",
      "wagah_border",
      "grave",
      "hyderabad_shared",
      "times",
      "valley",
      "show",
      "indian",
      "flag",
      "year",
    ],
    Freq: [
      9.0,
      8.0,
      8.0,
      7.0,
      8.0,
      6.0,
      6.0,
      6.0,
      6.0,
      5.0,
      0.9999748672257899,
      1.9999497344515798,
      0.9999748672257899,
      3.9998994689031595,
      0.9999748672257899,
      0.9999748672257899,
      0.9999748672257899,
      0.9999748672257899,
      2.9999246016773697,
      0.9999748672257899,
      3.9998994689031595,
      3.9998994689031595,
      2.9999246016773697,
      2.9999246016773697,
      2.9999246016773697,
      2.9999246016773697,
      1.9999497344515798,
      1.9999497344515798,
      1.9999497344515798,
      3.9998994689031595,
      1.9999497344515798,
      3.9998994689031595,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      2.000233452492198,
      2.000233452492198,
      1.000116726246099,
      1.000116726246099,
      9.001050536214892,
      8.000933809968792,
      8.000933809968792,
      7.000817083722694,
      5.000583631230495,
      4.000466904984396,
      4.000466904984396,
      4.000466904984396,
      3.000350178738297,
      3.000350178738297,
      7.000817083722694,
      3.000350178738297,
      5.999565030407317,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      0.9999275050678863,
      6.999492535475204,
      4.999637525339431,
      3.999710020271545,
      3.999710020271545,
      3.999710020271545,
      7.99942004054309,
      5.999565030407317,
      3.999710020271545,
      3.999710020271545,
      1.0000780295993794,
      1.0000780295993794,
      1.0000780295993794,
      2.000156059198759,
      1.0000780295993794,
      1.0000780295993794,
      2.000156059198759,
      1.0000780295993794,
      2.000156059198759,
      2.000156059198759,
      6.000468177596277,
      6.000468177596277,
      6.000468177596277,
      5.000390147996898,
      5.000390147996898,
      4.000312118397518,
      4.000312118397518,
      4.000312118397518,
      3.0002340887981385,
      3.0002340887981385,
      3.0002340887981385,
      3.0002340887981385,
      5.000390147996898,
      5.000390147996898,
      1.999909253956394,
      0.999954626978197,
      3.999818507912788,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      4.999773134890985,
      3.999818507912788,
      3.999818507912788,
      3.999818507912788,
      3.999818507912788,
      2.999863880934591,
      2.999863880934591,
      1.999909253956394,
      1.999909253956394,
      4.999773134890985,
      4.999773134890985,
      3.999818507912788,
      2.999863880934591,
    ],
    Total: [
      9.0,
      8.0,
      8.0,
      7.0,
      8.0,
      6.0,
      6.0,
      6.0,
      6.0,
      5.0,
      0.9999748672257899,
      1.9999497344515798,
      0.9999748672257899,
      3.9998994689031595,
      0.9999748672257899,
      0.9999748672257899,
      0.9999748672257899,
      0.9999748672257899,
      2.9999246016773697,
      0.9999748672257899,
      3.9998994689031595,
      3.9998994689031595,
      2.9999246016773697,
      2.9999246016773697,
      2.9999246016773697,
      2.9999246016773697,
      1.9999497344515798,
      1.9999497344515798,
      1.9999497344515798,
      5.000016195149259,
      1.9999497344515798,
      7.999798710352918,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      1.000116726246099,
      2.000233452492198,
      2.000233452492198,
      1.000116726246099,
      1.000116726246099,
      9.001050536214892,
      8.000933809968792,
      8.000933809968792,
      7.000817083722694,
      5.000583631230495,
      4.000466904984396,
      4.000466904984396,
      4.000466904984396,
      3.000350178738297,
      3.000350178738297,
      8.00074458879058,
      3.000350178738297,
      5.999565030407317,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      2.9997825152036586,
      0.9999275050678863,
      6.999492535475204,
      4.999637525339431,
      3.999710020271545,
      3.999710020271545,
      3.999710020271545,
      8.99949807014247,
      6.999643060006696,
      7.999528528184333,
      9.000100168268443,
      1.0000780295993794,
      1.0000780295993794,
      1.0000780295993794,
      2.000156059198759,
      1.0000780295993794,
      1.0000780295993794,
      2.000156059198759,
      1.0000780295993794,
      2.000156059198759,
      2.000156059198759,
      6.000468177596277,
      6.000468177596277,
      6.000468177596277,
      5.000390147996898,
      5.000390147996898,
      4.000312118397518,
      4.000312118397518,
      4.000312118397518,
      3.0002340887981385,
      3.0002340887981385,
      3.0002340887981385,
      3.0002340887981385,
      8.00022002535846,
      9.000100168268443,
      1.999909253956394,
      0.999954626978197,
      3.999818507912788,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      0.999954626978197,
      4.999773134890985,
      3.999818507912788,
      3.999818507912788,
      3.999818507912788,
      3.999818507912788,
      2.999863880934591,
      2.999863880934591,
      1.999909253956394,
      1.999909253956394,
      5.999889861137084,
      7.000006587383183,
      7.999528528184333,
      3.9999419105339706,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.0434,
      -4.3503,
      -5.0434,
      -3.6571,
      -5.0434,
      -5.0434,
      -5.0434,
      -5.0434,
      -3.9448,
      -5.0434,
      -3.6571,
      -3.6571,
      -3.9448,
      -3.9448,
      -3.9448,
      -3.9448,
      -4.3503,
      -4.3503,
      -4.3503,
      -3.6571,
      -4.3503,
      -3.6571,
      -4.8903,
      -4.8903,
      -4.8903,
      -4.8903,
      -4.8903,
      -4.8903,
      -4.1972,
      -4.1972,
      -4.8903,
      -4.8903,
      -2.6931,
      -2.8109,
      -2.8109,
      -2.9444,
      -3.2809,
      -3.5041,
      -3.5041,
      -3.5041,
      -3.7917,
      -3.7917,
      -2.9444,
      -3.7917,
      -3.6288,
      -4.3219,
      -4.3219,
      -4.3219,
      -4.3219,
      -4.3219,
      -4.3219,
      -4.3219,
      -4.3219,
      -5.4205,
      -3.4746,
      -3.8111,
      -4.0342,
      -4.0342,
      -4.0342,
      -3.3411,
      -3.6288,
      -4.0342,
      -4.0342,
      -5.1475,
      -5.1475,
      -5.1475,
      -4.4543,
      -5.1475,
      -5.1475,
      -4.4543,
      -5.1475,
      -4.4543,
      -4.4543,
      -3.3557,
      -3.3557,
      -3.3557,
      -3.5381,
      -3.5381,
      -3.7612,
      -3.7612,
      -3.7612,
      -4.0489,
      -4.0489,
      -4.0489,
      -4.0489,
      -3.5381,
      -3.5381,
      -4.5591,
      -5.2523,
      -3.866,
      -5.2523,
      -5.2523,
      -5.2523,
      -5.2523,
      -5.2523,
      -5.2523,
      -5.2523,
      -3.6428,
      -3.866,
      -3.866,
      -3.866,
      -3.866,
      -4.1537,
      -4.1537,
      -4.5591,
      -4.5591,
      -3.6428,
      -3.6428,
      -3.866,
      -4.1537,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.7331,
      1.5099,
      1.7331,
      1.04,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.886,
      1.7525,
      1.886,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.356,
      1.2382,
      1.2019,
      0.6629,
      0.545,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.6289,
      1.159,
      1.0412,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.5243,
      1.3419,
      1.1878,
      0.8311,
      1.2366,
    ],
  },
  "token.table": {
    Topic: [
      4,
      2,
      1,
      4,
      4,
      2,
      2,
      5,
      4,
      4,
      2,
      5,
      3,
      4,
      4,
      1,
      5,
      1,
      3,
      1,
      5,
      1,
      3,
      4,
      4,
      3,
      5,
      1,
      2,
      3,
      5,
      1,
      2,
      1,
      2,
      3,
      5,
      1,
      5,
      5,
      3,
      2,
      1,
      2,
      3,
      5,
      5,
      1,
      1,
      5,
      4,
      2,
      5,
      1,
      4,
      4,
      3,
      4,
      2,
      4,
      4,
      3,
      5,
      3,
      3,
      4,
      1,
      3,
      3,
      1,
      4,
      5,
      3,
      2,
      2,
      4,
      4,
      3,
      4,
      3,
      3,
      4,
      2,
      3,
      4,
      1,
      1,
      2,
      4,
      1,
      2,
      2,
      5,
      2,
      2,
      2,
      2,
      1,
      2,
      4,
      1,
      1,
      3,
      1,
      2,
      5,
      3,
      5,
      1,
      3,
      5,
      2,
      2,
      5,
      2,
      5,
      5,
      4,
      4,
      4,
      5,
    ],
    Freq: [
      0.9999219764887639,
      0.9998832873773272,
      1.0000251334058823,
      0.9999219764887638,
      0.9999219764887639,
      0.9998832873773272,
      0.9998832873773272,
      1.0000453750806075,
      0.9999219764887639,
      0.9999219764887638,
      0.9998832873773272,
      1.0000453750806075,
      0.4444394979183407,
      0.5555493723979258,
      0.9999219764887639,
      1.0000251334058823,
      1.0000453750806075,
      1.0000251334058823,
      1.00007250018801,
      1.0000251334058823,
      1.0000453750806075,
      0.12499656219832449,
      0.24999312439664897,
      0.6249828109916225,
      0.9999219764887638,
      1.00007250018801,
      1.0000453750806075,
      1.0000251334058823,
      0.8749185681801829,
      0.12498836688288327,
      1.0000453750806075,
      1.0000251334058823,
      0.9998832873773272,
      0.7999974087845115,
      0.19999935219612788,
      0.5000294687251884,
      0.5000294687251884,
      1.0000251334058823,
      1.0000453750806075,
      1.0000453750806075,
      1.00007250018801,
      0.9998832873773272,
      0.5000125809194937,
      0.12500314522987344,
      0.3750094356896203,
      1.0000453750806075,
      1.0000453750806075,
      1.0000251334058823,
      1.0000251334058823,
      1.0000453750806075,
      0.9999219764887638,
      0.28571401684175574,
      0.7142850421043894,
      1.0000251334058823,
      0.9999219764887639,
      0.9999219764887638,
      1.00007250018801,
      0.9999219764887639,
      0.9998832873773272,
      0.9999219764887638,
      0.9999219764887639,
      1.00007250018801,
      1.0000453750806075,
      1.00007250018801,
      1.00007250018801,
      0.9999219764887639,
      1.0000251334058823,
      1.00007250018801,
      1.00007250018801,
      1.0000251334058823,
      0.9999219764887639,
      1.0000453750806075,
      1.00007250018801,
      0.9998832873773272,
      0.9998832873773272,
      0.9999219764887638,
      0.9999219764887638,
      0.8889384649730084,
      0.11111730812162605,
      1.00007250018801,
      0.8571865663096055,
      0.14286442771826757,
      0.9998832873773272,
      1.00007250018801,
      0.9999219764887639,
      1.0000251334058823,
      1.0000251334058823,
      0.9998832873773272,
      0.9999219764887638,
      1.0000251334058823,
      0.9998832873773271,
      0.9998832873773272,
      1.0000453750806075,
      0.9998832873773272,
      0.9998832873773272,
      0.9998832873773271,
      0.9998832873773272,
      1.0000251334058823,
      0.9998832873773272,
      0.9999219764887639,
      1.0000251334058823,
      1.0000251334058823,
      1.00007250018801,
      1.0000251334058823,
      0.1666697261356865,
      0.8333486306784326,
      1.00007250018801,
      1.0000453750806075,
      1.0000251334058823,
      1.00007250018801,
      1.0000453750806075,
      0.9998832873773272,
      0.9998832873773272,
      1.0000453750806075,
      0.9998832873773272,
      1.0000453750806075,
      1.0000453750806075,
      0.9999219764887639,
      0.9999219764887639,
      0.2500036306443524,
      0.7500108919330571,
    ],
    Term: [
      "abu",
      "afzal",
      "amit",
      "angle",
      "arab",
      "ayub",
      "ayyub",
      "ban",
      "baseless",
      "bengal",
      "bhagavad",
      "border",
      "burn",
      "burn",
      "capture",
      "cbi",
      "celebrate",
      "chakraborty",
      "chennai",
      "child",
      "chinese",
      "communal",
      "communal",
      "communal",
      "communal_angle",
      "cop",
      "corpse",
      "covid",
      "death",
      "death",
      "decompose",
      "exam",
      "express",
      "fall",
      "fall",
      "flag",
      "flag",
      "fuel",
      "galvan",
      "galwan",
      "girl",
      "gita",
      "go",
      "go",
      "go",
      "grave",
      "group",
      "hike",
      "hit",
      "hyderabad_shared",
      "idol",
      "indian",
      "indian",
      "interrogation",
      "ips",
      "israel",
      "jihad",
      "johnson",
      "journalist",
      "kali",
      "kali_idol",
      "kapil_mishra",
      "log",
      "love",
      "love_jihad",
      "maa",
      "maldar",
      "man",
      "manufacture",
      "marriage",
      "message",
      "minor",
      "mishra",
      "mukherjee",
      "mukherjee_death",
      "murshidabad",
      "murshidabad_police",
      "muslim",
      "muslim",
      "muslim_man",
      "muslims",
      "muslims",
      "nominates",
      "norway",
      "official",
      "pak",
      "pakistan",
      "peace",
      "police",
      "pradesh",
      "pranab",
      "pranab_mukherjee",
      "prasad",
      "presidential",
      "rahul",
      "rana",
      "rana_ayyub",
      "report",
      "republican",
      "restaurant",
      "rhea",
      "rhea_chakraborty",
      "riot",
      "shah",
      "show",
      "show",
      "sister",
      "soldier",
      "suresh",
      "sweden",
      "times",
      "tweet",
      "tweet_pranab",
      "valley",
      "vice",
      "wagah",
      "wagah_border",
      "west",
      "west_bengal",
      "year",
      "year",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-screenshot-claims-students-test-covid-19-positive-after-comedk-exams-9555",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09b8\u09cd\u0995\u09cd\u09b0\u09bf\u09a8\u09b6\u099f: \u0995\u09b0\u09cd\u09a8\u09be\u099f\u0995\u09c7 \u09b8\u09bf\u0993\u098f\u09ae\u0987\u09a1\u09bf\u0995\u09c7 \u09aa\u09b0\u09c0\u0995\u09cd\u09b7\u09be\u09b0 \u09aa\u09b0 \u099b\u09be\u09a4\u09cd\u09b0\u09b0\u09be \u0995\u09cb\u09ad\u09bf\u09a1 \u0986\u0995\u09cd\u09b0\u09be\u09a8\u09cd\u09a4",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/images-from-anti-pothole-campaign-in-west-bengal-viral-as-up-chattisgarh-9556",
        headline:
          "\u09ae\u09be\u09b2\u09a6\u09be\u09b0 \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09df \u0996\u09be\u09a8\u09be\u0996\u09a8\u09cd\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6 \u0993 \u099b\u09a4\u09cd\u09a4\u09bf\u09b6\u0997\u09a1\u09bc\u09c7\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/fake-screenshot-claims-students-test-covid-19-positive-after-comedk-exams-9559",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u0930\u094d\u0928\u093e\u091f\u0915 \u0930\u093e\u091c\u094d\u092f \u092a\u094d\u0930\u0935\u0947\u0936 \u092a\u0930\u0940\u0915\u094d\u0937\u093e \u092e\u0947\u0902 \u0936\u093e\u092e\u093f\u0932 5 \u0939\u095b\u093e\u0930 \u091b\u093e\u0924\u094d\u0930 \u0915\u094b\u0930\u094b\u0928\u093e \u0938\u0902\u0915\u094d\u0930\u092e\u093f\u0924 \u0939\u094b \u0917\u090f?",
      },
      {
        url:
          "https://factly.in/telugu-uttar-pradesh-government-did-not-pass-an-ordinance-to-cancel-license-of-schools-charging-above-20000-rupees/",
        headline:
          " \u0c09\u0c24\u0c4d\u0c24\u0c30 \u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b 12\u0c35 \u0c24\u200c\u0c30\u200c\u0c17\u200c\u0c24\u0c3f \u0c35\u200c\u0c30\u200c\u0c15\u0c41 \u0c38\u0c4d\u0c15\u0c42\u0c32\u0c4d \u0c2b\u0c40\u0c1c\u0c41 20 \u0c35\u0c47\u0c32 \u0c30\u0c42\u0c2a\u0c3e\u0c2f\u200c\u0c32\u0c41 \u0c26\u0c3e\u0c1f\u0c3f\u0c24\u0c47 \u0c35\u0c3f\u0c26\u0c4d\u0c2f\u0c3e \u0c38\u0c02\u0c38\u0c4d\u0c25 \u0c32\u0c48\u0c38\u0c46\u0c28\u0c4d\u0c38\u0c4d \u0c30\u200c\u0c26\u0c4d\u0c26\u0c41 \u0c05\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c4d\u0c24\u0c32\u0c4b \u0c28\u0c3f\u0c1c\u0c02 \u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/why-claims-of-us-canada-gdp-falling-more-than-india-are-misleading-9615",
        headline:
          "Why Claims Of US, Canada GDP Falling More Than India Are Misleading",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photoshopped-image-showing-amit-shah-with-naga-sadhus-viral-9592",
        headline:
          "\u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0915\u0940 \u095e\u094b\u091f\u094b\u0936\u0949\u092a \u0915\u0940 \u0939\u0941\u0908 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094d\u092f\u094b\u0902 \u0939\u094b \u0930\u0939\u0940 \u0939\u0948 \u0935\u093e\u092f\u0930\u0932?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-set-of-photos-showing-inundated-areas-viral-as-neet-and-jee-exam-centers-9604",
        headline:
          "\u0924\u0938\u094d\u0935\u0940\u0930\u094b\u0902 \u0915\u093e \u092f\u0939 \u0938\u0947\u091f \u0939\u093e\u0932\u093f\u092f\u093e \u0928\u0940\u091f \u0914\u0930 \u091c\u0947\u0908\u0908 \u090f\u0917\u094d\u091c\u093e\u092e \u0938\u0947\u0902\u091f\u0930\u094d\u0938 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-photo-doesnt-show-mother-teresa-at-the-age-of-18-9600",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u0985\u09b7\u09cd\u099f\u09be\u09a6\u09b6\u09c0 \u09ae\u09be\u09a6\u09be\u09b0 \u099f\u09c7\u09b0\u09bf\u099c\u09be\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/madonnas-misleading-images-with-fake-quote-on-hindu-religion-viral-9612",
        headline:
          "\u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf \u09b8\u09b9 \u09ae\u09cd\u09af\u09be\u09a1\u09cb\u09a8\u09be\u09b0 \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09a7\u09b0\u09cd\u09ae \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u09ad\u09c1\u09df\u09cb \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-sharukh-khan-did-not-donate-45-crores-to-pakistan/",
        headline:
          " \u0c2c\u0c3e\u0c32\u0c40\u0c35\u0c41\u0c21\u0c4d \u0c28\u0c1f\u0c41\u0c21\u0c41 \u0c37\u0c3e\u0c30\u0c41\u0c16\u0c4d \u0c16\u0c3e\u0c28\u0c4d \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c15\u0c3f 45 \u0c15\u0c4b\u0c1f\u0c4d\u0c32 \u0c38\u0c3e\u0c2f\u0c02 \u0c05\u0c02\u0c26\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/rajdeep-sardesai-was-not-heckled-by-a-mob-after-his-interview-with-rhea-chakraborty/",
        headline:
          " Rajdeep Sardesai was not heckled by a mob after his interview with Rhea Chakraborty",
      },
      {
        url:
          "https://factly.in/old-images-of-flood-hit-streets-falsely-shared-as-the-condition-of-jee-and-neet-examination-centres/",
        headline:
          " Old images of flood-hit streets falsely shared as the  condition of JEE and NEET examination centres",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-was-rhea-chakraborty-slapped-during-cbi-interrogation-heres-the-truth/",
        headline:
          "Fact Check: Was Rhea Chakraborty slapped during CBI interrogation? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-suresh-raina-did-not-test-positive-for-covid-19-dont-fall-for-fake-reports/",
        headline:
          "Fact Check: Suresh Raina did not test positive for COVID-19, don\u2019t fall for FAKE reports",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-this-wanted-banner-of-rhea-chakraborty-which-has-gone-viral-is-fake/",
        headline:
          "Fact Check: This \u2018WANTED\u2019 banner of Rhea Chakraborty which has gone viral is FAKE",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-did-amit-shah-visit-dwarkadhish-after-testing-negative-for-covid-19-heres-the-truth/",
        headline:
          "Fact Check: Did Amit Shah visit Dwarkadhish after testing negative for COVID-19? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-reports-of-no-govt-jobs-for-people-having-more-than-two-children-resurfaces-as-recent-one/",
        headline:
          "Fact Check: Reports of no govt jobs for people having more than two children resurfaces as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-dont-believe-this-morphed-picture-of-home-minister-amit-shah/",
        headline:
          "FACT CHECK: Don\u2019t believe this morphed picture of Home Minister Amit Shah",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-heres-the-truth-behind-this-road-sign-stating-fuel-price-hike/",
        headline:
          "Fact Check: Here\u2019s the truth behind this road sign stating fuel price hike",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/02/fact-check-old-video-from-pak-of-women-falling-into-a-gutter-being-shared-with-false-claim-that-it-is-from-delhi/",
        headline:
          "Fact Check: Old video from Pak of women falling into a gutter being shared with false claim that it is from Delhi",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-walk-down-the-aisle-of-communal-hatred-over-pak-child-marriage-1718007-2020-09-02",
        headline:
          "Fact Check: Netizens walk down the aisle of communal hatred over Pak child marriage",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-claiming-kidney-theft-in-kota-hospital-goes-viral-with-a-covid-twist-1718772-2020-09-04",
        headline:
          "Fact Check: Video claiming kidney theft in Kota hospital goes viral with a Covid twist",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/04/fact-check-this-picture-of-child-marriage-is-from-pakistan-not-india/",
        headline:
          "Fact Check: This picture of child marriage is from Pakistan, not India",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/04/fact-check-dont-fall-for-fake-claims-that-upsc-exam-includes-islamic-subject/",
        headline:
          "Fact Check: Don\u2019t fall for FAKE claims that UPSC Exam includes Islamic subject",
      },
    ],
    "2": [
      {
        url:
          "https://www.altnews.in/fake-tweet-of-ranaa-ayyub-about-afzal-guru-on-pranab-mukherjees-death-is-viral/",
        headline:
          "Journalist Rana Ayyub targeted with fake tweet in her name on Pranab Mukherjee\u2019s death",
      },
      {
        url:
          "https://www.altnews.in/hindi/fake-tweet-of-ranaa-ayyub-about-afzal-guru-on-pranab-mukherjees-death-is-viral/",
        headline:
          "\u092a\u094d\u0930\u0923\u0935 \u092e\u0941\u0916\u0930\u094d\u091c\u0940 \u0915\u0940 \u092e\u094c\u0924 \u092a\u0930 \u0905\u0938\u0902\u0935\u0947\u0926\u0928\u0936\u0940\u0932 \u091f\u094d\u0935\u0940\u091f \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0938\u0947 \u0930\u093e\u0923\u093e \u0905\u092f\u0942\u092c \u0915\u094b \u0928\u093f\u0936\u093e\u0928\u093e \u092c\u0928\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-rana-ayyub-did-not-tweet-about-afzal-guru-on-pranab-mukherjees-death-9577",
        headline:
          "No, Rana Ayyub Did Not Tweet About Afzal Guru On Pranab Mukherjee's Death",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-rana-ayyub-did-not-tweet-about-afzal-guru-on-pranab-mukherjees-death-9598",
        headline:
          "\u09a8\u09be, \u09aa\u09cd\u09b0\u09a3\u09ac \u09ae\u09c1\u0996\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09aa\u09cd\u09b0\u09df\u09be\u09a3\u09c7 \u09b0\u09be\u09a8\u09be \u0986\u09df\u09c1\u09ac \u0986\u09ab\u099c\u09b2 \u0997\u09c1\u09b0\u09c1\u0995\u09c7 \u09a8\u09bf\u09df\u09c7 \u099f\u09c1\u0987\u099f \u0995\u09b0\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://factly.in/telugu-indian-american-nikki-haley-was-not-nominated-as-the-republican-party-vice-president-candidate/",
        headline:
          " \u0c30\u0c3f\u0c2a\u0c2c\u0c4d\u0c32\u0c3f\u0c15\u0c4d \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40 \u0c35\u0c48\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c46\u0c38\u0c3f\u0c21\u0c46\u0c02\u0c1f\u0c4d \u0c05\u0c2d\u0c4d\u0c2f\u0c30\u0c4d\u0c27\u0c3f\u0c17\u0c3e \u0c21\u0c4b\u0c28\u0c3e\u0c32\u0c4d\u0c21\u0c4d \u0c1f\u0c4d\u0c30\u0c02\u0c2a\u0c4d \u0c2e\u0c48\u0c15\u0c4d \u0c2a\u0c46\u0c28\u0c4d\u0c38\u0c4d \u0c28\u0c3f \u0c28\u0c3e\u0c2e\u0c3f\u0c28\u0c47\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3e\u0c21\u0c41, \u0c28\u0c3f\u0c15\u0c4d\u0c15\u0c3f \u0c39\u0c47\u0c32\u0c40\u0c28\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-screenshot-showing-rana-ayyubs-tweet-about-pranab-mukherjees-death-is-digitally-created/",
        headline:
          " This screenshot showing Rana Ayyub\u2019s tweet about Pranab Mukherjee\u2019s death is digitally created",
      },
      {
        url:
          "https://factly.in/the-purported-excerpt-from-pranab-mukherjees-book-is-a-cooked-up-story/",
        headline:
          " The purported excerpt from Pranab Mukherjee\u2019s book is a cooked-up story",
      },
      {
        url:
          "https://factly.in/telugu-tweet-of-rana-ayyub-expressing-happiness-about-pranab-mukherjee-death-is-photoshopped/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c23\u0c2c\u0c4d \u0c2e\u0c41\u0c16\u0c30\u0c4d\u0c1c\u0c40 \u0c2e\u0c30\u0c23\u0c02\u0c2a\u0c48 \u0c39\u0c30\u0c4d\u0c37\u0c02 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c42 \u0c1c\u0c30\u0c4d\u0c28\u0c32\u0c3f\u0c38\u0c4d\u0c1f\u0c4d \u0c30\u0c3e\u0c28\u0c3e \u0c06\u0c2f\u0c41\u0c2c\u0c4d \u0c1f\u0c4d\u0c35\u0c40\u0c1f\u0c4d \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-quote-attributed-to-nitin-gadkari-praising-sonia-and-rahul-gandhi-is-false/",
        headline:
          "Fact Check: Quote attributed to Nitin Gadkari praising Sonia and Rahul Gandhi is false",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-does-new-zealands-pm-jacinda-ardern-praise-the-bhagavad-gita-in-this-video/",
        headline:
          "Fact Check: Does New Zealand\u2019s PM Jacinda Ardern praise the Bhagavad Gita in this video?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/01/fact-check-dont-fall-for-this-fake-picture-of-rahul-gandhi-sitting-next-to-a-donkey/",
        headline:
          "Fact Check: Don\u2019t fall for this fake picture of Rahul Gandhi sitting next to a donkey",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/02/fact-check-fake-tweet-about-former-president-pranab-mukherjee-attributed-to-indian-journalist-viral/",
        headline:
          "Fact Check: Fake tweet about Pranab Mukherjee attributed to Indian Journalist goes viral",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/02/fact-check-old-picture-of-indian-journalist-manipulated-to-create-false-narrative/",
        headline:
          "Fact Check: Old picture of Indian journalist manipulated to create false narrative",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-afzal-guru-at-peace-over-pranab-s-death-that-s-a-fake-rana-ayyub-tweet-1717555-2020-09-01",
        headline:
          "Fact Check: Afzal Guru at peace over Pranab\u2019s death? That\u2019s a fake Rana Ayyub tweet",
      },
    ],
    "3": [
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-old-picture-of-burnt-cars-falsely-linked-to-the-recent-norway-riots/",
        headline:
          "Fact Check: Old picture of burnt cars falsely linked to the recent Norway riots",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-picture-of-a-chicago-protest-shared-as-riots-in-norway/",
        headline:
          "Fact Check: Picture of a Chicago protest shared as riots in Norway",
      },
      {
        url:
          "https://www.altnews.in/old-image-from-2016-falsely-shared-as-bjp-leader-kapil-mishras-sister-married-a-muslim-man/",
        headline:
          "Old image from 2016 falsely shared as BJP leader Kapil Mishra\u2019s sister married a Muslim man",
      },
      {
        url:
          "https://www.altnews.in/old-incident-revived-with-false-claim-of-muslim-men-attacking-cop-in-chennai/",
        headline:
          "Old incident revived with false claim of Muslim men attacking cop in Chennai",
      },
      {
        url:
          "https://www.altnews.in/unrelated-images-shared-with-false-love-jihad-hindu-muslim-angle/",
        headline:
          "Photos of two unrelated cases viral to manufacture a false \u2018love Jihad\u2019 story",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-image-from-2016-falsely-shared-as-bjp-leader-kapil-mishras-sister-married-a-muslim-man/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915: \u0915\u092a\u093f\u0932 \u092e\u093f\u0936\u094d\u0930\u093e \u0915\u0940 \u092c\u0939\u0928 \u0928\u0947 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0936\u0916\u094d\u0938 \u0938\u0947 \u0936\u093e\u0926\u0940 \u0915\u0940?",
      },
      {
        url:
          "https://www.altnews.in/hindi/unrelated-images-shared-with-false-love-jihad-hindu-muslim-angle/",
        headline:
          "2 \u0905\u0932\u0917 \u0918\u091f\u0928\u093e\u0913\u0902 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u094b\u0902 \u0915\u0947 \u095b\u0930\u093f\u090f \u0932\u0935 \u091c\u093f\u0939\u093e\u0926 \u0914\u0930 \u0939\u093f\u0928\u094d\u0926\u0942 \u0932\u095c\u0915\u0940 \u0915\u0940 \u0939\u0924\u094d\u092f\u093e \u0915\u093e \u091d\u0942\u0920\u093e \u0926\u093e\u0935\u093e \u0915\u093f\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2017-attack-on-chennai-cop-peddled-with-false-communal-claim-9587",
        headline:
          "2017 Attack On Chennai Cop Peddled With False Communal Claim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photos-of-inter-faith-couple-peddled-with-false-murder-claim-9588",
        headline:
          "Photos Of Inter-Faith Couple Peddled With False Murder Claim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-muslim-teen-made-cop-for-a-day-peddled-with-communal-claim-9603",
        headline:
          "Photo Of Muslim Teen Made Cop For A Day Peddled With Communal Claim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2006-picture-of-muslims-burning-danish-flag-revived-with-fake-claim-9608",
        headline:
          "2006 Picture Of Muslims Burning Danish Flag Revived With Fake Claim",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/did-gunjan-saxenas-biopic-claim-she-was-a-shaurya-chakra-awardee-9573",
        headline:
          "\u0915\u094d\u092f\u093e \u0917\u0941\u0902\u091c\u0928 \u0938\u0915\u094d\u0938\u0947\u0928\u093e \u0915\u094b \u095e\u093f\u0932\u094d\u092e \u092e\u0947\u0902 \u0936\u094c\u0930\u094d\u092f \u091a\u0915\u094d\u0930 \u0935\u093f\u091c\u0947\u0924\u093e \u092c\u0924\u093e\u092f\u093e \u0917\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-dead-man-rising-from-bier-goes-viral-with-fake-claims-9586",
        headline:
          "\u092e\u094c\u0924 \u0915\u0947 \u092e\u0941\u0902\u0939 \u0938\u0947 \u0935\u093e\u092a\u0938 \u0932\u094c\u091f\u093e \u0907\u0902\u0938\u093e\u0928? \u091c\u0940 \u0928\u0939\u0940\u0902, \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u0947 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photo-of-muslim-teen-made-cop-for-a-day-peddled-with-communal-claim-9620",
        headline:
          "\u090f\u0915 \u0926\u093f\u0928 \u0915\u0947 \u0932\u093f\u090f DSP \u092c\u0928\u0940 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u092c\u091a\u094d\u091a\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u091c\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/the-man-in-the-viral-picture-is-not-bhupalpalli-district-judge-9634",
        headline:
          "\u0928\u0939\u0940\u0902, \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u0947 \u0936\u0959\u094d\u0938 \u092d\u0941\u092a\u0932\u092a\u0932\u094d\u0932\u0940 \u0915\u0947 \u095b\u093f\u0932\u093e \u0928\u094d\u092f\u093e\u092f\u093e\u0927\u0940\u0936 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2016-photo-of-inter-faith-couple-falsely-linked-to-kapil-mishras-sister-9571",
        headline:
          "\u09ad\u09bf\u09a8\u09cd\u09a8 \u09a7\u09b0\u09cd\u09ae\u09c7 \u09ac\u09bf\u09ac\u09be\u09b9\u09bf\u09a4 \u09a6\u09b8\u09cd\u09aa\u09a4\u09bf\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u0995\u09b0\u09c7 \u0995\u09aa\u09bf\u09b2 \u09ae\u09bf\u09b6\u09cd\u09b0\u09b0 \u09ac\u09cb\u09a8 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://factly.in/unrelated-old-images-of-injured-women-are-falsely-attributed-to-the-recent-riots-in-sweden/",
        headline:
          " Unrelated old images of injured women are falsely attributed to the recent riots in Sweden",
      },
      {
        url:
          "https://factly.in/telugu-these-women-in-the-photo-are-not-orphans-and-were-not-adopted-by-the-muslim-man/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c38\u0c3e\u0c17\u0c28\u0c02\u0c2a\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c05\u0c2e\u0c4d\u0c2e\u0c3e\u0c2f\u0c3f\u0c32\u0c41 \u0c06\u0c28\u0c3e\u0c27\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41, \u0c35\u0c3e\u0c33\u0c4d\u0c33\u0c28\u0c3f \u0c24\u0c28\u0c41 \u0c26\u0c24\u0c4d\u0c24\u0c24  \u0c24\u0c40\u0c38\u0c41\u0c15\u0c4b\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/2015-human-shield-photo-of-norwegian-muslims-is-falsely-linked-to-sweden/",
        headline:
          " 2015 Human shield photo of Norwegian Muslims is falsely linked to Sweden",
      },
      {
        url:
          "https://factly.in/telugu-2015-norway-picture-of-muslims-human-chain-is-shared-as-the-recent-one-from-sweden/",
        headline:
          " 2015\u0c32\u0c4b \u0c28\u0c3e\u0c30\u0c4d\u0c35\u0c47\u0c32\u0c4b \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32\u0c41 \u0c1a\u0c47\u0c2a\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c2e\u0c3e\u0c28\u0c35 \u0c39\u0c3e\u0c30\u0c02  \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c38\u0c4d\u0c35\u0c40\u0c21\u0c28\u0c4d \u0c05\u0c32\u0c4d\u0c32\u0c30\u0c4d\u0c32\u0c26\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/the-syrian-refugee-kid-alan-kurdi-was-not-murdered/",
        headline: " The Syrian refugee kid (Alan Kurdi) was not murdered",
      },
      {
        url:
          "https://factly.in/2006-photo-of-pakistani-muslims-burning-the-denmark-flag-is-shared-as-muslim-refugees-in-switzerland-burning-the-swiss-flag/",
        headline:
          " 2006 photo of Pakistani Muslims burning the Denmark flag is shared as Muslim refugees in Switzerland burning the Swiss flag",
      },
      {
        url:
          "https://factly.in/the-man-sitting-on-the-stairs-along-with-an-old-woman-in-this-photo-is-bhupalpally-district-collector-abdul-azeem/",
        headline:
          " The man sitting on the stairs along with an old woman in this photo is Bhupalpally District Collector Abdul Azeem.",
      },
      {
        url:
          "https://factly.in/telugu-india-gdp-contracted-greatest-in-april-june-2020-quarter-among-major-economies/",
        headline:
          " 2020 \u0c0f\u0c2a\u0c4d\u0c30\u0c3f\u0c32\u0c4d \u2013 \u0c1c\u0c42\u0c28\u0c4d \u0c24\u0c4d\u0c30\u0c48\u0c2e\u0c3e\u0c38\u0c3f\u0c15\u0c02\u0c32\u0c4b \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36 GDP \u0c2e\u0c3f\u0c17\u0c24\u0c3e \u0c06\u0c30\u0c4d\u0c27\u0c3f\u0c15\u0c02\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c27\u0c3e\u0c28\u0c2e\u0c48\u0c28 \u0c26\u0c47\u0c36\u0c3e\u0c32\u0c15\u0c02\u0c1f\u0c47 \u0c0e\u0c15\u0c4d\u0c15\u0c41\u0c35\u0c17\u0c3e \u0c15\u0c4d\u0c37\u0c40\u0c23\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-of-shivaji-is-not-the-one-in-london-museum/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c1b\u0c24\u0c4d\u0c30\u0c2a\u0c24\u0c3f \u0c36\u0c3f\u0c35\u0c3e\u0c1c\u0c40 \u0c1a\u0c3f\u0c24\u0c4d\u0c30\u0c2a\u0c20\u0c02 \u0c32\u0c02\u0c21\u0c28\u0c4d \u0c2e\u0c4d\u0c2f\u0c41\u0c1c\u0c3f\u0c2f\u0c02\u0c32\u0c4b \u0c2a\u0c4a\u0c02\u0c26\u0c41\u0c2a\u0c30\u0c3f\u0c1a\u0c3f\u0c28\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-heres-the-truth-behind-this-picture-of-ssrs-sister-shweta-singh-kirti-holding-a-placard/",
        headline:
          "Fact Check: Here\u2019s the truth behind this picture of SSR\u2019s sister Shweta Singh Kirti holding a placard",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-heres-the-truth-behind-this-picture-of-alia-bhatt-begging/",
        headline:
          "Fact Check: Here\u2019s the truth behind this picture of Alia Bhatt begging",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/03/fact-check-old-video-of-punjab-protest-against-article-370-shared-as-recent-one/",
        headline:
          "Fact Check: Old video of Punjab protest against Article 370 shared as recent one",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-scene-of-arson-at-black-lives-matter-protest-goes-viral-as-norway-riots-1717233-2020-09-01",
        headline:
          "Fact Check: Scene of arson at 'Black Lives Matter' protest goes viral as Norway riots",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/egypt-pipeline-fire-sweden-riots-fact-check-1717826-2020-09-02",
        headline:
          "Fact Check: Egypt pipeline fire goes viral as scenes from Sweden riots\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-this-chennai-cop-was-not-beaten-by-muslim-men-1717339-2020-09-01",
        headline:
          "Fact Check: No, this Chennai cop was not beaten by Muslim men",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-image-mob-attack-victim-painted-love-jihad-hues-1718616-2020-09-04",
        headline:
          "Fact Check: Old image of mob attack victim painted in Love-Jihad hues",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-did-muslims-in-switzerland-demand-removal-of-swiss-cross-from-the-flag-1717563-2020-09-01",
        headline:
          "Fact Check: Did Muslims in Switzerland demand removal of Swiss cross from the flag?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/04/fact-check-old-picture-human-chain-by-norwegian-muslims-shared-as-recent-one-from-sweden/",
        headline:
          "Fact Check: Old picture human chain by Norwegian Muslims shared as recent one from Sweden",
      },
    ],
    "4": [
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-did-uk-pm-boris-johnson-apologise-for-colonial-rule-in-india-heres-the-truth/",
        headline:
          "Fact Check: Did UK PM Boris Johnson apologise for colonial rule in India? Here\u2019s the truth",
      },
      {
        url:
          "https://www.altnews.in/images-from-west-bengal-where-a-man-sitting-in-pothole-wearing-helmet-shared-as-uttar-pradesh/",
        headline:
          "Amar Ujala publishes old photos from West Bengal as pothole-ridden road in Lucknow",
      },
      {
        url:
          "https://www.altnews.in/images-of-charred-idol-of-hindu-deity-kali-in-west-bengal-viral-with-baseless-communal-angle/",
        headline:
          "Images of charred idol of Hindu deity Kali in West Bengal viral with baseless communal angle",
      },
      {
        url:
          "https://www.altnews.in/explosion-in-uae-after-first-official-flight-lands-from-israel-no-false-claim-viral/",
        headline:
          "Explosion in UAE after first official flight lands from Israel? No, false claim viral",
      },
      {
        url:
          "https://www.altnews.in/hindi/images-of-charred-idol-of-hindu-deity-kali-in-west-bengal-viral-with-baseless-communal-angle/",
        headline:
          "\u0939\u093f\u0928\u094d\u0926\u0942 \u0926\u0947\u0935\u0940 \u0915\u093e\u0932\u0940 \u0915\u0940 \u091c\u0932\u0940 \u0939\u0941\u0908 \u092e\u0942\u0930\u094d\u0924\u093f \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0910\u0902\u0917\u0932 \u0915\u0947 \u0938\u093e\u0925 \u0915\u0940 \u0917\u092f\u0940 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-incident-revived-with-false-claim-of-muslim-men-attacking-cop-in-chennai/",
        headline:
          "3 \u0938\u093e\u0932 \u092a\u0939\u0932\u0947 \u0936\u0930\u093e\u092c\u093f\u092f\u094b\u0902 \u0928\u0947 \u092a\u0941\u0932\u093f\u0938 \u092a\u0930 \u0939\u092e\u0932\u093e \u0915\u093f\u092f\u093e \u0925\u093e, \u0905\u092c \u0909\u0938\u092e\u0947\u0902 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0910\u0902\u0917\u0932 \u091c\u094b\u095c\u0915\u0930 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/murshidabad-police-rule-out-communal-angle-in-burnt-kali-idol-incident-9590",
        headline:
          "Murshidabad Police Rule Out Communal Angle In Burnt Kali Idol Incident",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/blast-at-abu-dhabi-restaurants-falsely-linked-to-first-israel-uae-flight-9606",
        headline:
          "Blast At Abu Dhabi Restaurants Falsely Linked To First Israel-UAE Flight",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/fake-message-claims-thieves-are-posing-as-mha-officials-on-census-duty-9613",
        headline:
          "Fake Message Claims Thieves Are Posing As MHA Officials On Census Duty",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/murshidabad-police-rule-out-communal-angle-in-burnt-kali-idol-incident-9609",
        headline:
          "\u091c\u0932\u0940 \u0939\u0941\u0908 \u0915\u093e\u0932\u0940 \u0915\u0940 \u092e\u0942\u0930\u094d\u0924\u093f \u092e\u093e\u092e\u0932\u0947 \u092e\u0947\u0902 \u0938\u093e\u092e\u094d\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0915\u094b\u0923 \u0928\u0939\u0940\u0902 \u0939\u0948: \u092e\u0941\u0930\u094d\u0936\u093f\u0926\u093e\u092c\u093e\u0926 \u092a\u0941\u0932\u093f\u0938",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/blast-at-abu-dhabi-restaurants-falsely-linked-to-first-israel-uae-flight-9621",
        headline:
          "\u0905\u092c\u0942 \u0927\u093e\u092c\u0940 \u0930\u0947\u0938\u094d\u091f\u094d\u0930\u0949\u0928\u094d\u091f \u0927\u092e\u093e\u0915\u0947 \u0915\u094b \u095e\u0930\u094d\u095b\u0940 \u0924\u0930\u0939 \u0938\u0947 \u092a\u0939\u0932\u0940 \u0907\u095b\u0930\u093e\u0907\u0932-\u0905\u0930\u092c \u095e\u094d\u0932\u093e\u0907\u091f \u0938\u0947 \u091c\u094b\u095c\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/fake-message-claims-thieves-are-posing-as-mha-officials-on-census-duty-9629",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u092e\u0948\u0938\u0947\u091c \u0915\u093e \u0926\u093e\u0935\u093e - \u091c\u0928\u0917\u0923\u0928\u093e \u0905\u0927\u093f\u0915\u093e\u0930\u0940 \u092c\u0928\u0915\u0930 \u091a\u094b\u0930 \u0918\u0930\u094b\u0902 \u092e\u0947\u0902 \u0915\u0930\u0924\u0947 \u0939\u0948\u0902 \u0932\u0942\u091f\u092a\u093e\u091f",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/murshidabad-police-rule-out-communal-angle-in-burnt-kali-idol-incident-9595",
        headline:
          "\u09ae\u09c1\u09b0\u09cd\u09b6\u09bf\u09a6\u09be\u09ac\u09be\u09a6 \u09aa\u09c1\u09b2\u09bf\u09b6 \u099c\u09be\u09a8\u09be\u09b2, \u0995\u09be\u09b2\u09c0\u09ae\u09c2\u09b0\u09cd\u09a4\u09bf \u09aa\u09cb\u09a1\u09bc\u09be\u09b0 \u0998\u099f\u09a8\u09be\u09df \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995\u09a4\u09be \u09a8\u09c7\u0987",
      },
      {
        url:
          "https://factly.in/telugu-accidentally-burnt-kali-idol-in-west-bengal-shared-with-false-communal-narrative/",
        headline:
          " \u0c2a\u0c36\u0c4d\u0c1a\u0c3f\u0c2e \u0c2c\u0c46\u0c02\u0c17\u0c3e\u0c32\u0c4d \u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c35\u0c36\u0c3e\u0c24\u0c4d\u0c24\u0c41 \u0c15\u0c3e\u0c32\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c15\u0c3e\u0c33\u0c40\u0c2e\u0c3e\u0c24 \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32\u0c41 \u0c24\u0c17\u0c32\u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3e\u0c30\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/photos-of-accidentally-burnt-maa-kali-idol-in-west-bengal-is-shared-with-a-false-communal-narrative/",
        headline:
          " Photos of accidentally burnt \u2018Maa Kali\u2019 idol in West Bengal is shared with a false communal narrative",
      },
      {
        url:
          "https://factly.in/telugu-hyderabad-police-did-not-ask-people-to-avoid-drinking-cool-drinks-because-of-ebola/",
        headline:
          " \u2018\u0c15\u0c42\u0c32\u0c4d \u0c21\u0c4d\u0c30\u0c3f\u0c02\u0c15\u0c4d\u0c38\u0c4d \u0c0e\u0c2c\u0c4b\u0c32\u0c3e \u0c35\u0c48\u0c30\u0c38\u0c4d \u0c24\u0c4b \u0c15\u0c32\u0c41\u0c37\u0c3f\u0c24\u0c02 \u0c05\u0c2f\u0c4d\u0c2f\u0c3e\u0c2f\u0c3f. \u0c15\u0c4a\u0c26\u0c4d\u0c26\u0c3f \u0c30\u0c4b\u0c1c\u0c41\u0c32\u0c41 \u0c24\u0c3e\u0c17\u0c35\u0c26\u0c4d\u0c26\u0c41\u2019 \u0c05\u0c28\u0c3f \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-this-sculpture-is-not-from-nagvasuki-temple-in-prayagraj/",
        headline:
          "Fact Check: This sculpture is not from Nagvasuki Temple in Prayagraj",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-video-of-mock-drill-from-karnataka-shared-as-police-capturing-a-terrorist/",
        headline:
          "Fact check: Video of mock drill from Karnataka shared as police capturing a terrorist",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-the-truth-behind-this-viral-picture-of-pm-modi-with-president-trump-and-xi-jinping/",
        headline:
          "Fact Check: The truth behind this viral picture of PM Modi with President Trump and Xi Jinping",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-the-curious-case-of-a-desecrated-kali-idol-in-a-bengal-temple-1718010-2020-09-03",
        headline:
          " Fact Check: The curious case of a 'desecrated' Kali idol in a Bengal temple",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-did-burqa-replace-khakhi-for-an-ips-officer-in-maharashtra-1718392-2020-09-03",
        headline:
          "Fact Check: Did burqa replace Khaki for an IPS officer in Maharashtra?",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/india-today-group-times-now-air-old-images-of-pla-cemetery-as-graves-of-chinese-killed-in-galwan/",
        headline:
          "India Today Group, Times Now air old images of PLA cemetery as graves of Chinese killed in Galwan",
      },
      {
        url:
          "https://www.altnews.in/saddam-husseins-body-did-not-decay-after-12-years-fake-news/",
        headline:
          "Fact-check: Saddam Hussein\u2019s dead body did not decompose after 12 years of burial?",
      },
      {
        url:
          "https://www.altnews.in/hindi/india-today-group-times-now-air-old-images-of-pla-cemetery-as-graves-of-chinese-killed-in-galwan/",
        headline:
          "\u0907\u0902\u0921\u093f\u092f\u093e \u091f\u0941\u0921\u0947 \u0917\u094d\u0930\u0941\u092a \u0914\u0930 \u091f\u093e\u0907\u092e\u094d\u0938 \u0928\u093e\u0909 \u0928\u0947 \u092a\u0941\u0930\u093e\u0928\u0940 \u0915\u092c\u094d\u0930\u0947\u0902 \u0917\u0932\u0935\u093e\u0928 \u092e\u0947\u0902 \u092e\u093e\u0930\u0947 \u0917\u090f \u091a\u0940\u0928\u0940 \u0938\u0948\u0928\u093f\u0915\u094b\u0902 \u0915\u0940 \u092c\u0924\u093e\u0924\u0947 \u0939\u0941\u090f \u0926\u093f\u0916\u093e\u0908\u0902",
      },
      {
        url:
          "https://www.altnews.in/hindi/saddam-husseins-body-did-not-decay-after-12-years-fake-news/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915: 12 \u0938\u093e\u0932 \u092c\u093e\u0926 \u092d\u0940 \u0938\u0926\u094d\u0926\u093e\u092e \u0939\u0941\u0938\u0948\u0928 \u0915\u093e \u0936\u0935 \u0915\u092c\u094d\u0930 \u0938\u0947 \u092a\u0939\u0932\u0947 \u091c\u0948\u0938\u093e \u0939\u0940 \u0928\u093f\u0915\u0932\u093e?",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/aaj-tak-times-now-run-1962-war-memorial-video-as-proof-of-galwan-9584",
        headline:
          "Aaj Tak, Times Now Air Video Of 1962 War Memorial As 'Proof Of Galwan'",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-a-diving-stunt-in-water-logged-indore--shared-as-karachi-9585",
        headline:
          "Video Of A Diving Stunt In Water-Logged Indore Shared As Karachi",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/fake-kangana-ranaut-twitter-account-calls-for-ban-on-kapil-sharma-show-9633",
        headline:
          "Fake Kangana Ranaut Twitter Account Calls For Ban On Kapil Sharma Show",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-a-diving-stunt-in-water-logged-indore-shared-as-karachi-9596",
        headline:
          "\u0907\u0902\u0926\u094c\u0930 \u0938\u0947 \u092a\u093e\u0928\u0940 \u092e\u0947\u0902 \u091b\u0932\u093e\u0902\u0917 \u0932\u0917\u093e\u0928\u0947 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u0930\u093e\u091a\u0940 \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2011-video-of-tsunami-in-japan-passed-off-as-china-9572",
        headline:
          "\u09e8\u09e6\u09e7\u09e7 \u09b8\u09be\u09b2\u09c7 \u099c\u09be\u09aa\u09be\u09a8\u09c7 \u09b8\u09c1\u09a8\u09be\u09ae\u09bf\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u099a\u09bf\u09a8\u09c7\u09b0 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09a8\u09cb \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018-image-of-protest-against-rape-and-murder-of-teen-in-nepal-shared-as-india-9578",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09a4\u09c7 \u09a8\u09c7\u09aa\u09be\u09b2\u09c7 \u09a8\u09be\u09ac\u09be\u09b2\u09bf\u0995\u09be \u09a7\u09b0\u09cd\u09b7\u09a3 \u0993 \u0996\u09c1\u09a8\u09c7 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/aaj-tak-times-now-air-video-of-1962-war-memorial-as-proof-of-galwan-9611",
        headline:
          "'\u0997\u09be\u09b2\u0993\u09df\u09be\u09a8\u09c7\u09b0 \u09aa\u09cd\u09b0\u09ae\u09be\u09a3' \u09b9\u09bf\u09b8\u09c7\u09ac\u09c7 \u0986\u099c\u09a4\u0995, \u099f\u09be\u0987\u09ae\u09b8 \u09a8\u09be\u0993 \u09a6\u09c7\u0996\u09be\u09b2 \u09e7\u09ef\u09ec\u09e8'\u09b0 \u09af\u09c1\u09a6\u09cd\u09a7 \u09b8\u09cd\u09ae\u09c3\u09a4\u09bf\u09b8\u09cc\u09a7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/unrelated-pictures-peddled-with-false-claim-of-indian-army-occupying-chinese-territory-9624",
        headline:
          "\u099a\u09bf\u09a8\u09c7\u09b0 \u099c\u09ae\u09bf \u09a6\u0996\u09b2 \u0995\u09b0\u09b2 \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09b8\u09c7\u09a8\u09be, \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://factly.in/a-video-created-on-fake-website-huzler-is-falsely-shared-as-human-meat-found-inside-mc-donalds-factory/",
        headline:
          " A video created on fake website \u2018Huzlers\u2019 is falsely shared as \u2018Human meat found inside Mc Donald\u2019s  factory\u2019",
      },
      {
        url:
          "https://factly.in/old-video-falsely-shared-as-army-personnel-dancing-after-reclaiming-the-land-in-eastern-ladakh/",
        headline:
          " Old video falsely shared as army personnel dancing after reclaiming the land in Eastern Ladakh",
      },
      {
        url:
          "https://factly.in/telugu-old-video-shared-as-indian-soldiers-dancing-after-reclaiming-requin-from-china/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c1a\u0c48\u0c28\u0c3e \u0c06\u0c15\u0c4d\u0c30\u0c2e\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c30\u0c46\u0c15\u0c4d\u0c35\u0c3f\u0c28\u0c4d \u0c2a\u0c4d\u0c30\u0c3e\u0c02\u0c24\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c24\u0c3f\u0c30\u0c3f\u0c17\u0c3f \u0c38\u0c4d\u0c35\u0c3e\u0c27\u0c40\u0c28\u0c02 \u0c1a\u0c47\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c06\u0c28\u0c02\u0c26\u0c02\u0c32\u0c4b \u0c1a\u0c3f\u0c02\u0c26\u0c41\u0c32\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2d\u0c3e\u0c30\u0c24 \u0c38\u0c48\u0c28\u0c3f\u0c15\u0c41\u0c32\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-videos-being-reshared-in-the-light-of-the-latest-standoff-between-indian-and-chinese-soldiers/",
        headline:
          " Old videos being reshared in the light of the latest standoff between Indian and Chinese soldiers",
      },
      {
        url:
          "https://factly.in/unrelated-photos-falsely-shared-as-those-of-special-frontier-force-sff/",
        headline:
          " Unrelated photos falsely shared as those of \u2018Special Frontier Force (SFF)\u2019",
      },
      {
        url:
          "https://factly.in/old-flag-hoisting-video-from-hyderabad-falsely-shared-as-that-at-wagah-border/",
        headline:
          " Old flag hoisting video from Hyderabad falsely shared as that at Wagah border",
      },
      {
        url:
          "https://factly.in/telugu-old-flag-hoisting-video-from-hyderabad-falsely-shared-as-that-at-wagah-border/",
        headline:
          " \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c32\u0c4b \u0c0f\u0c30\u0c4d\u0c2a\u0c3e\u0c1f\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c1c\u0c46\u0c02\u0c21\u0c3e\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f, \u0c35\u0c3e\u0c18\u0c3e \u0c38\u0c30\u0c3f\u0c39\u0c26\u0c4d\u0c26\u0c41 \u0c15\u0c3f  \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/2019-video-of-indian-soldiers-celebrating-ganesh-chaturthi-near-shingo-valley-is-shared-as-the-celebrations-in-galwan-valley/",
        headline:
          " 2019 video of Indian soldiers celebrating Ganesh Chaturthi near Shingo valley is shared as the celebrations in Galwan Valley",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-picture-from-afghanistan-shared-as-kashmir-in-1947/",
        headline:
          "Fact Check: Picture from Afghanistan shared as Kashmir in the year 1947",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-iconic-mughal-gardens-renamed-as-rajendra-prasad-garden-heres-the-truth/",
        headline:
          "Fact Check: Iconic Mughal Gardens renamed as Rajendra Prasad Garden? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/31/fact-check-dont-believe-in-this-photo-showing-alcohol-smuggling-in-bihar/",
        headline:
          "Fact Check: Don\u2019t believe in this photo showing alcohol smuggling in Bihar",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/no-taiwan-did-not-shoot-down-a-chinese-su-35-fighter-jet-1718774-2020-09-04",
        headline:
          "Fact Check: No, Taiwan did not shoot down a Chinese Su-35 fighter jet\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-tricolour-at-wagah-border-is-not-the-largest-flag-flown-1718875-2020-09-05",
        headline:
          "Fact Check: This Tricolour at Wagah border is not the largest flag flown",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/03/fact-check-the-truth-behind-the-viral-picture-of-protest-by-indian-railway-employees/",
        headline:
          "Fact Check: The truth behind the viral picture of protest by Indian Railway employees",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/04/fact-check-old-video-of-flag-hoisting-in-hyderabad-shared-as-recent-one-from-attari-wagah-border/",
        headline:
          "Fact Check: Old video of flag hoisting in Hyderabad shared as recent one from Attari-Wagah border",
      },
    ],
  },
  numpy_seed: 0,
  number_of_articles: 120,
}
