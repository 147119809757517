export const data = {
  mdsDat: {
    x: [
      -0.2754312951307641,
      0.1582282119421673,
      0.180779975048454,
      -0.24743767503682276,
      -0.07653970700697206,
      0.26040049018393796,
    ],
    y: [
      0.27401419688917966,
      -0.15491622533909505,
      0.1590171558098673,
      -0.08331339924641758,
      -0.30079129036991664,
      0.10598956225638233,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      13.631040006005673,
      18.214146685663916,
      17.12982671355727,
      15.802208186118635,
      18.334333293087973,
      16.88844511556653,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      11.0,
      11.0,
      11.0,
      23.0,
      15.0,
      8.0,
      10.0,
      13.0,
      9.0,
      10.0,
      1.0000116960158145,
      2.000023392031629,
      1.0000116960158145,
      1.0000116960158145,
      1.0000116960158145,
      3.0000350880474436,
      1.0000116960158145,
      1.0000116960158145,
      1.0000116960158145,
      1.0000116960158145,
      4.000046784063258,
      4.000046784063258,
      4.000046784063258,
      3.0000350880474436,
      3.0000350880474436,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      3.0000350880474436,
      2.000023392031629,
      2.000023392031629,
      2.9999061461752423,
      2.9999061461752423,
      2.9999061461752423,
      2.9999061461752423,
      2.9999061461752423,
      4.999843576958738,
      4.999843576958738,
      4.999843576958738,
      2.9999061461752423,
      0.9999687153917475,
      8.999718438525727,
      3.99987486156699,
      3.99987486156699,
      3.99987486156699,
      3.99987486156699,
      3.99987486156699,
      6.999781007742232,
      7.99974972313398,
      4.999843576958738,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      3.000132326522319,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      5.000220544203866,
      5.000220544203866,
      4.000176435363093,
      4.000176435363093,
      4.000176435363093,
      4.000176435363093,
      3.000132326522319,
      3.000132326522319,
      3.000132326522319,
      4.000176435363093,
      3.000132326522319,
      1.0000023348314768,
      1.0000023348314768,
      1.0000023348314768,
      3.0000070044944307,
      1.0000023348314768,
      6.000014008988861,
      1.0000023348314768,
      1.0000023348314768,
      3.0000070044944307,
      1.0000023348314768,
      6.000014008988861,
      5.000011674157385,
      5.000011674157385,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      9.000021013483293,
      6.000014008988861,
      8.000018678651815,
      4.000009339325907,
      1.9998897763118328,
      0.9999448881559164,
      0.9999448881559164,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      0.9999448881559164,
      0.9999448881559164,
      1.9998897763118328,
      1.9998897763118328,
      4.999724440779582,
      3.9997795526236657,
      2.999834664467749,
      2.999834664467749,
      2.999834664467749,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      8.999503993403248,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      9.999448881559164,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      4.999724440779582,
      2.999834664467749,
      2.999834664467749,
      2.999834664467749,
      2.000074428686379,
      2.000074428686379,
      2.000074428686379,
      1.0000372143431895,
      2.000074428686379,
      4.000148857372758,
      2.000074428686379,
      11.000409357775084,
      1.0000372143431895,
      3.0001116430295687,
      11.000409357775084,
      11.000409357775084,
      4.000148857372758,
      4.000148857372758,
      3.0001116430295687,
      3.0001116430295687,
      3.0001116430295687,
      8.000297714745516,
      11.000409357775084,
      8.000297714745516,
      4.000148857372758,
    ],
    Term: [
      "anna_hazare",
      "hazare",
      "anna",
      "modi",
      "pm",
      "vajpayee",
      "protest",
      "pm_modi",
      "farmer",
      "vaccine",
      "receive",
      "change",
      "lechu",
      "clinic",
      "dark",
      "victim",
      "organise",
      "error",
      "vigneswara",
      "unity",
      "babul",
      "ujjain",
      "temple",
      "rally",
      "karnataka",
      "peasant",
      "fund",
      "grassroots",
      "burn",
      "ram",
      "spread",
      "attack",
      "join",
      "mp",
      "alive",
      "famous",
      "start",
      "famous_people",
      "believe",
      "first",
      "metro",
      "first_metro",
      "vajpayee_starte",
      "gujarat",
      "vajpayee",
      "atal",
      "atal_bihari",
      "people",
      "say",
      "bihari",
      "pm",
      "modi",
      "pm_modi",
      "rakab",
      "pray",
      "march",
      "statements",
      "remove",
      "begin_new",
      "anti",
      "carpet",
      "come",
      "ticket",
      "year",
      "morph",
      "amit",
      "amit_shah",
      "shah",
      "kejriwal",
      "begin",
      "new",
      "chain",
      "modi",
      "woman",
      "body",
      "declare",
      "shared",
      "linked_ongoing",
      "pakistan",
      "sony",
      "netizen",
      "dec",
      "deal",
      "cockpit",
      "microsoft",
      "farmers_protest",
      "acquire",
      "mobile_tower",
      "trump",
      "ongoing",
      "mobile",
      "fire",
      "sony_billion",
      "protest",
      "link",
      "farmer",
      "tower",
      "reduction",
      "internet",
      "ally",
      "take",
      "look",
      "cricketer",
      "register",
      "illusion",
      "employee",
      "break",
      "pfizer",
      "pfizer_covid",
      "jodhpur",
      "state",
      "rule",
      "faint",
      "campaign",
      "arrest",
      "aide",
      "russia",
      "vaccine",
      "govt",
      "yograj",
      "stunning",
      "covid",
      "economy",
      "company",
      "show",
      "singh",
      "light",
      "covid_vaccine",
      "top",
      "death",
      "actor",
      "narrative",
      "late",
      "lakshmanrao",
      "adani_group",
      "rajput",
      "anna",
      "rss",
      "inamdar",
      "anna_hazare",
      "hazare",
      "group",
      "rare",
      "pune",
      "junction",
      "pune_junction",
      "pm_modi",
      "modi",
      "pm",
      "adani",
    ],
    Total: [
      11.0,
      11.0,
      11.0,
      23.0,
      15.0,
      8.0,
      10.0,
      13.0,
      9.0,
      10.0,
      1.0000116960158145,
      2.000023392031629,
      1.0000116960158145,
      1.0000116960158145,
      1.0000116960158145,
      3.0000350880474436,
      1.0000116960158145,
      1.0000116960158145,
      1.0000116960158145,
      1.0000116960158145,
      4.000046784063258,
      4.000046784063258,
      4.000046784063258,
      3.0000350880474436,
      3.0000350880474436,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      2.000023392031629,
      4.00003742287892,
      2.000023392031629,
      3.0000257268631056,
      2.9999061461752423,
      2.9999061461752423,
      2.9999061461752423,
      2.9999061461752423,
      2.9999061461752423,
      4.999843576958738,
      4.999843576958738,
      4.999843576958738,
      2.9999061461752423,
      0.9999687153917475,
      8.999718438525727,
      3.99987486156699,
      3.99987486156699,
      3.99987486156699,
      3.99987486156699,
      3.99987486156699,
      15.000078722487748,
      23.000335516272155,
      13.000141291704253,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      3.000132326522319,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      2.0000882176815464,
      5.000220544203866,
      5.000220544203866,
      4.000176435363093,
      4.000176435363093,
      4.000176435363093,
      4.000176435363093,
      3.000132326522319,
      3.000132326522319,
      3.000132326522319,
      23.000335516272155,
      4.000144022538134,
      1.0000023348314768,
      1.0000023348314768,
      1.0000023348314768,
      3.0000070044944307,
      1.0000023348314768,
      6.000014008988861,
      1.0000023348314768,
      1.0000023348314768,
      3.0000070044944307,
      1.0000023348314768,
      6.000014008988861,
      5.000011674157385,
      5.000011674157385,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      3.0000070044944307,
      10.999958444266788,
      7.000025705004676,
      9.99995610943531,
      6.000032731357536,
      1.9998897763118328,
      0.9999448881559164,
      0.9999448881559164,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      0.9999448881559164,
      0.9999448881559164,
      1.9998897763118328,
      1.9998897763118328,
      4.999724440779582,
      3.9997795526236657,
      2.999834664467749,
      2.999834664467749,
      2.999834664467749,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      10.999441424186744,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      13.999474530024205,
      1.9998897763118328,
      1.9998897763118328,
      1.9998897763118328,
      7.999801204297437,
      3.9998463604835637,
      4.999772095251244,
      4.999922882149296,
      2.000074428686379,
      2.000074428686379,
      2.000074428686379,
      1.0000372143431895,
      2.000074428686379,
      4.000148857372758,
      2.000074428686379,
      11.000409357775084,
      1.0000372143431895,
      3.0001116430295687,
      11.000409357775084,
      11.000409357775084,
      4.000148857372758,
      4.000148857372758,
      3.0001116430295687,
      3.0001116430295687,
      3.0001116430295687,
      13.000141291704253,
      23.000335516272155,
      15.000078722487748,
      6.000237075054304,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.9928,
      1.7051,
      1.9928,
      1.5874,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      1.703,
      0.9408,
      0.6469,
      0.7474,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      1.7643,
      0.0152,
      1.4767,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.845,
      1.6444,
      1.6909,
      1.6219,
      1.4396,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.6964,
      1.4957,
      1.6964,
      1.6964,
      1.6964,
      1.3599,
      1.6964,
      1.6964,
      1.6964,
      1.2264,
      1.4087,
      1.1856,
      1.1855,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.7785,
      1.2931,
      1.041,
      1.15,
      1.3731,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.7274,
      -4.0342,
      -4.7274,
      -4.7274,
      -4.7274,
      -3.6288,
      -4.7274,
      -4.7274,
      -4.7274,
      -4.7274,
      -3.3411,
      -3.3411,
      -3.3411,
      -3.6288,
      -3.6288,
      -4.0342,
      -4.0342,
      -4.0342,
      -4.0342,
      -4.0342,
      -4.0342,
      -3.6288,
      -4.0342,
      -4.0342,
      -3.9187,
      -3.9187,
      -3.9187,
      -3.9187,
      -3.9187,
      -3.4078,
      -3.4078,
      -3.4078,
      -3.9187,
      -5.0173,
      -2.8201,
      -3.631,
      -3.631,
      -3.631,
      -3.631,
      -3.631,
      -3.0714,
      -2.9378,
      -3.4078,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -3.8572,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -3.3464,
      -3.3464,
      -3.5695,
      -3.5695,
      -3.5695,
      -3.5695,
      -3.8572,
      -3.8572,
      -3.8572,
      -3.5695,
      -3.8572,
      -4.8752,
      -4.8752,
      -4.8752,
      -3.7766,
      -4.8752,
      -3.0834,
      -4.8752,
      -4.8752,
      -3.7766,
      -4.8752,
      -3.0834,
      -3.2658,
      -3.2658,
      -3.7766,
      -3.7766,
      -3.7766,
      -3.7766,
      -3.7766,
      -3.7766,
      -2.678,
      -3.0834,
      -2.7958,
      -3.4889,
      -4.3307,
      -5.0239,
      -5.0239,
      -4.3307,
      -4.3307,
      -4.3307,
      -5.0239,
      -5.0239,
      -4.3307,
      -4.3307,
      -3.4144,
      -3.6376,
      -3.9253,
      -3.9253,
      -3.9253,
      -4.3307,
      -4.3307,
      -4.3307,
      -4.3307,
      -4.3307,
      -2.8267,
      -4.3307,
      -4.3307,
      -4.3307,
      -2.7213,
      -4.3307,
      -4.3307,
      -4.3307,
      -3.4144,
      -3.9253,
      -3.9253,
      -3.9253,
      -4.2485,
      -4.2485,
      -4.2485,
      -4.9416,
      -4.2485,
      -3.5553,
      -4.2485,
      -2.5437,
      -4.9416,
      -3.843,
      -2.5437,
      -2.5437,
      -3.5553,
      -3.5553,
      -3.843,
      -3.843,
      -3.843,
      -2.8622,
      -2.5437,
      -2.8622,
      -3.5553,
    ],
  },
  "token.table": {
    Topic: [
      4,
      6,
      3,
      6,
      6,
      5,
      2,
      5,
      3,
      3,
      6,
      6,
      3,
      5,
      2,
      2,
      1,
      4,
      1,
      3,
      3,
      2,
      2,
      4,
      5,
      1,
      5,
      3,
      3,
      1,
      1,
      4,
      3,
      5,
      2,
      3,
      5,
      2,
      5,
      5,
      1,
      4,
      6,
      4,
      4,
      5,
      5,
      1,
      5,
      2,
      2,
      2,
      4,
      4,
      4,
      2,
      2,
      1,
      5,
      1,
      6,
      2,
      6,
      5,
      6,
      5,
      5,
      1,
      6,
      1,
      3,
      6,
      6,
      1,
      1,
      5,
      1,
      4,
      4,
      5,
      3,
      2,
      4,
      4,
      4,
      2,
      3,
      6,
      3,
      1,
      4,
      6,
      4,
      3,
      4,
      1,
      4,
      1,
      2,
      5,
      5,
      2,
      6,
      2,
      6,
      3,
      2,
      4,
      6,
      6,
      6,
      3,
      1,
      1,
      6,
      1,
      5,
      5,
      3,
      6,
      5,
      5,
      2,
      3,
      4,
      5,
      4,
      5,
      6,
      4,
      4,
      1,
      2,
      5,
      3,
      5,
      5,
      1,
      3,
      3,
      5,
      1,
      4,
      4,
      1,
      1,
      2,
      5,
      2,
      2,
      1,
      1,
      1,
      3,
      3,
      5,
    ],
    Freq: [
      0.9999976651739745,
      0.9999627870416663,
      0.3333201630173753,
      0.6666403260347507,
      0.9999627870416663,
      1.0000551148815664,
      1.00003128558701,
      1.0000551148815664,
      0.9999558931047308,
      0.9999558931047308,
      0.9999627870416664,
      0.9999627870416664,
      0.9999558931047308,
      1.0000551148815664,
      1.00003128558701,
      1.00003128558701,
      0.749992983275849,
      0.24999766109194965,
      0.9999883041209806,
      0.999955893104731,
      0.999955893104731,
      1.00003128558701,
      1.00003128558701,
      0.9999976651739746,
      1.0000551148815664,
      0.9999883041209806,
      1.0000551148815664,
      0.9999558931047308,
      0.999955893104731,
      0.9999883041209806,
      0.9999883041209806,
      0.9999976651739746,
      0.9999558931047308,
      1.0000551148815664,
      0.14286250499693162,
      0.14286250499693162,
      0.7143125249846581,
      0.4000182332109875,
      0.6000273498164813,
      1.0000551148815664,
      0.9999883041209806,
      0.9999976651739745,
      0.9999627870416663,
      0.9999976651739746,
      0.9999976651739746,
      1.0000551148815664,
      1.0000551148815664,
      0.9999883041209806,
      1.0000551148815664,
      1.00003128558701,
      1.00003128558701,
      0.20000087781514658,
      0.8000035112605863,
      0.9999976651739745,
      0.9999976651739745,
      1.0000312855870097,
      1.0000312855870097,
      0.9999883041209806,
      1.0000551148815664,
      0.9999883041209806,
      0.9999627870416663,
      1.00003128558701,
      0.9999627870416664,
      1.0000551148815664,
      0.9999627870416662,
      1.0000551148815664,
      1.0000551148815664,
      0.9999883041209806,
      0.9999627870416662,
      0.9999883041209806,
      0.9999558931047308,
      0.9999627870416663,
      0.9999627870416663,
      0.9999883041209806,
      0.2500096028386211,
      0.7500288085158634,
      0.14285661826713705,
      0.8571397096028224,
      0.9999976651739745,
      1.0000551148815664,
      0.9999558931047308,
      1.0000312855870097,
      0.9999976651739745,
      0.9999976651739745,
      0.9999976651739745,
      0.3478210130604487,
      0.17391050653022436,
      0.478253892958117,
      0.9999558931047309,
      0.6666609496350037,
      0.33333047481750183,
      0.9999627870416663,
      0.9999976651739746,
      0.999955893104731,
      0.9999976651739745,
      0.9999883041209806,
      0.9999976651739746,
      0.9999883041209806,
      1.00003128558701,
      1.0000551148815664,
      1.0000551148815664,
      0.46666421753545684,
      0.5333305343262363,
      0.3846112044328808,
      0.6153779270926093,
      0.9999558931047308,
      0.18181886869240002,
      0.8181849091158001,
      0.9999627870416662,
      0.9999627870416662,
      0.9999627870416663,
      0.9999558931047308,
      0.9999883041209806,
      0.9999883041209806,
      0.9999627870416663,
      0.9999883041209806,
      1.0000551148815664,
      1.0000551148815664,
      0.9999558931047308,
      0.9999627870416663,
      1.0000551148815664,
      1.0000551148815664,
      1.00003128558701,
      0.9999558931047308,
      0.9999976651739746,
      1.0000551148815664,
      0.12500310626004144,
      0.6250155313002071,
      0.25000621252008287,
      0.9999976651739745,
      0.9999976651739745,
      0.9999883041209806,
      1.00003128558701,
      1.0000551148815664,
      0.9999558931047308,
      1.0000551148815664,
      1.0000551148815664,
      0.9999883041209806,
      0.9999558931047308,
      0.40000616952321244,
      0.6000092542848187,
      0.3333315149345011,
      0.6666630298690022,
      0.9999976651739745,
      0.9999883041209806,
      0.9999883041209806,
      0.18182741494510685,
      0.8182233672529808,
      1.00003128558701,
      1.00003128558701,
      0.9999883041209806,
      0.9999883041209806,
      0.2499909989154564,
      0.7499729967463692,
      0.9999558931047309,
      1.0000551148815664,
    ],
    Term: [
      "acquire",
      "actor",
      "adani",
      "adani",
      "adani_group",
      "aide",
      "alive",
      "ally",
      "amit",
      "amit_shah",
      "anna",
      "anna_hazare",
      "anti",
      "arrest",
      "atal",
      "atal_bihari",
      "attack",
      "attack",
      "babul",
      "begin",
      "begin_new",
      "believe",
      "bihari",
      "body",
      "break",
      "burn",
      "campaign",
      "carpet",
      "chain",
      "change",
      "clinic",
      "cockpit",
      "come",
      "company",
      "covid",
      "covid",
      "covid",
      "covid_vaccine",
      "covid_vaccine",
      "cricketer",
      "dark",
      "deal",
      "death",
      "dec",
      "declare",
      "economy",
      "employee",
      "error",
      "faint",
      "famous",
      "famous_people",
      "farmer",
      "farmer",
      "farmers_protest",
      "fire",
      "first",
      "first_metro",
      "fund",
      "govt",
      "grassroots",
      "group",
      "gujarat",
      "hazare",
      "illusion",
      "inamdar",
      "internet",
      "jodhpur",
      "join",
      "junction",
      "karnataka",
      "kejriwal",
      "lakshmanrao",
      "late",
      "lechu",
      "light",
      "light",
      "link",
      "link",
      "linked_ongoing",
      "look",
      "march",
      "metro",
      "microsoft",
      "mobile",
      "mobile_tower",
      "modi",
      "modi",
      "modi",
      "morph",
      "mp",
      "mp",
      "narrative",
      "netizen",
      "new",
      "ongoing",
      "organise",
      "pakistan",
      "peasant",
      "people",
      "pfizer",
      "pfizer_covid",
      "pm",
      "pm",
      "pm_modi",
      "pm_modi",
      "pray",
      "protest",
      "protest",
      "pune",
      "pune_junction",
      "rajput",
      "rakab",
      "rally",
      "ram",
      "rare",
      "receive",
      "reduction",
      "register",
      "remove",
      "rss",
      "rule",
      "russia",
      "say",
      "shah",
      "shared",
      "show",
      "singh",
      "singh",
      "singh",
      "sony",
      "sony_billion",
      "spread",
      "start",
      "state",
      "statements",
      "stunning",
      "take",
      "temple",
      "ticket",
      "top",
      "top",
      "tower",
      "tower",
      "trump",
      "ujjain",
      "unity",
      "vaccine",
      "vaccine",
      "vajpayee",
      "vajpayee_starte",
      "victim",
      "vigneswara",
      "woman",
      "woman",
      "year",
      "yograj",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://factly.in/old-video-from-karnataka-is-falsely-shared-linking-it-to-the-ujjain-stone-pelting-incident/",
        headline:
          " Old video from Karnataka is falsely shared linking it to the Ujjain stone pelting incident",
      },
      {
        url:
          "https://factly.in/telugu-both-the-accused-and-the-victim-in-the-knife-attack-incident-in-karnataka-are-from-same-religion/",
        headline:
          " \u0c15\u0c30\u0c4d\u0c23\u0c3e\u0c1f\u0c15\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c15\u0c24\u0c4d\u0c24\u0c3f \u0c26\u0c3e\u0c21\u0c3f \u0c18\u0c1f\u0c28\u0c32\u0c4b\u0c28\u0c3f \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c30\u0c3e\u0c32\u0c41, \u0c28\u0c3f\u0c02\u0c26\u0c3f\u0c24\u0c41\u0c21\u0c41 \u0c12\u0c15\u0c47 \u0c2e\u0c24\u0c38\u0c4d\u0c25\u0c41\u0c32\u0c41",
      },
      {
        url:
          "https://factly.in/video-of-machete-attack-on-a-woman-in-karnataka-is-falsely-shared-with-a-communal-spin/",
        headline:
          " Video of Machete attack on a woman in Karnataka is shared with a false communal spin",
      },
      {
        url:
          "https://digiteye.in/fact-check-has-nasa-confirmed-that-earth-will-go-dark-for-6-days-in-december/",
        headline:
          "Fact Check: Has NASA confirmed that Earth will go dark for 6 days in December?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-no-this-video-is-not-of-ram-temple-fund-rally-from-ujjain/",
        headline:
          "Fact Check: No, This Video Is Not Of \u2018Ram Temple Fund\u2019 Rally From Ujjain",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/mukesh-ambani-jio-telephone-tower-kisan-andolan-fake-news-11356",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u091c\u0932\u093e\u092f\u093e \u0917\u092f\u093e \u0939\u0948 \u092f\u0939 \u091f\u0947\u0932\u0940\u095e\u094b\u0928 \u091f\u0949\u0935\u0930?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/bjp-mp-babul-supriyo-joining-tmc-fake-abp-ananda-graphic-viral-11313",
        headline:
          "Fake Graphic: \u09ac\u09be\u09ac\u09c1\u09b2 \u09b8\u09c1\u09aa\u09cd\u09b0\u09bf\u09df\u09b0 \u09a8\u09be\u09ae\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09a4\u09c3\u09a3\u09ae\u09c2\u09b2\u09c7 \u09af\u09cb\u0997 \u09a6\u09c7\u0993\u09df\u09be\u09b0 \u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/morphed-abp-graphic-locket-chatterjee-bjp-mp-switching-to-tmc-fake-news-babul-supriyo-11330",
        headline:
          "Babul Supriyo-\u09b0 \u09aa\u09b0 \u09a6\u09b2 \u09ac\u09a6\u09b2\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0\u09c7\u09b0 \u09b6\u09bf\u0995\u09be\u09b0 \u09b8\u09be\u0982\u09b8\u09a6 Locket Chatterjee",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/indian-government-has-not-approved-pharmacists-to-run-clinics-in-country-1755320-2021-01-02",
        headline:
          "Fact Check: No, Indian government has not approved pharmacists to run clinics in the country",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-no-this-video-is-not-of-ram-temple-fund-rally-from-ujjain/",
        headline:
          "Fact Check: No, This Video Is Not Of \u2018Ram Temple Fund\u2019 Rally From Ujjain",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/mukesh-ambani-jio-telephone-tower-kisan-andolan-fake-news-11356",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u091c\u0932\u093e\u092f\u093e \u0917\u092f\u093e \u0939\u0948 \u092f\u0939 \u091f\u0947\u0932\u0940\u095e\u094b\u0928 \u091f\u0949\u0935\u0930?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/morphed-abp-graphic-locket-chatterjee-bjp-mp-switching-to-tmc-fake-news-babul-supriyo-11330",
        headline:
          "Babul Supriyo-\u09b0 \u09aa\u09b0 \u09a6\u09b2 \u09ac\u09a6\u09b2\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0\u09c7\u09b0 \u09b6\u09bf\u0995\u09be\u09b0 \u09b8\u09be\u0982\u09b8\u09a6 Locket Chatterjee",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/bjp-mp-babul-supriyo-joining-tmc-fake-abp-ananda-graphic-viral-11313",
        headline:
          "Fake Graphic: \u09ac\u09be\u09ac\u09c1\u09b2 \u09b8\u09c1\u09aa\u09cd\u09b0\u09bf\u09df\u09b0 \u09a8\u09be\u09ae\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09a4\u09c3\u09a3\u09ae\u09c2\u09b2\u09c7 \u09af\u09cb\u0997 \u09a6\u09c7\u0993\u09df\u09be\u09b0 \u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/tirumala-temple-lighting-represents-christian-cross-672291",
        headline:
          "Fact check: Lighting at Tirumala temple represents 'Purna Kumbham', not Christian Cross",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-hindus-did-not-organise-unity-procession-after-bajrang-dal-rally-attack-in-ujjain-672389",
        headline:
          "Fact Check: Hindus did not organise unity procession after Bajrang Dal rally attack in Ujjain",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/lord-subrahmanyeswara-swamys-idol-has-been-vandalized-in-vigneswara-temple-at-rajahmundry-672435",
        headline:
          "Fact check: True, Lord Subrahmanyeswara Swamy's idol has been vandalized in Vigneswara Temple at Rajahmundry",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/fact-check-fake-post-related-to-tezpur-viral/",
        headline:
          "\u09ab\u09c7\u0995\u09cd\u099f \u099a\u09c7\u0995\u0983 \u09a4\u09c7\u099c\u09aa\u09c1\u09f0\u09f0 \u09b2\u09c7\u099a\u09c1\u09df\u09c7 2015 \u099a\u09a8\u09a4\u09c7\u0987 \u09aa\u09cd\u09f0\u09be\u09aa\u09cd\u09a4 \u0995\u09f0\u09bf\u099b\u09bf\u09b2 \u099c\u09c0\u0986\u0987 \u099f\u09c7\u0997, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f \u09ad\u09cd\u09f0\u09ae\u09bf\u09a4|",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/telugu-pope-francis-did-not-say-that-he-does-not-believe-in-jesus-christ/",
        headline:
          " \u0c2f\u0c47\u0c38\u0c41\u0c15\u0c4d\u0c30\u0c40\u0c38\u0c4d\u0c24\u0c41 \u0c2a\u0c48 \u0c24\u0c28\u0c15\u0c41 \u0c28\u0c2e\u0c4d\u0c2e\u0c15\u0c02 \u0c32\u0c47\u0c26\u0c28\u0c3f, \u0c2e\u0c33\u0c4d\u0c33\u0c40 \u0c1c\u0c28\u0c4d\u0c2e\u0c02\u0c1f\u0c42 \u0c09\u0c02\u0c1f\u0c47 \u0c39\u0c3f\u0c02\u0c26\u0c41\u0c35\u0c41\u0c17\u0c3e \u0c2a\u0c41\u0c1f\u0c4d\u0c1f\u0c3e\u0c32\u0c28\u0c41\u0c02\u0c26\u0c28\u0c3f \u0c2a\u0c4a\u0c2a\u0c4d \u0c05\u0c28\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://digiteye.in/viral-list-of-black-money-holding-accounts-in-swiss-bank-is-fake/",
        headline:
          "Viral list of black money holding accounts in Swiss Bank is fake",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/29/fact-check-dont-believe-this-fake-cnn-graphic-about-side-effect-of-covid-19-vaccine-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t Believe This Fake CNN Graphic About Side Effect Of Covid-19 Vaccine; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-did-pm-modi-visit-a-church-on-christmas-heres-the-truth/",
        headline:
          "Fact Check: Did PM Modi Visit A Church On Christmas? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-no-pm-modi-did-not-say-that-vajpayee-started-indias-first-metro-heres-the-truth/",
        headline:
          "Fact Check: No, PM Modi Did Not Say That Vajpayee Started India\u2019s First Metro; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-is-this-former-pm-atal-bihari-vajpayees-picture-with-his-mother-heres-the-truth/",
        headline:
          "Fact Check: Is This Former PM Atal Bihari Vajpayee\u2019s Picture With His Mother? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/famous-people-who-fake-news-killed-in-2020-but-are-alive-11318",
        headline: "Famous People Who Fake News Killed In 2020 But Are Alive",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/atal-bihari-vajpayee-childhood-photo-ranu-shankar-muzaffarpur-bihar-fake-news-11333",
        headline:
          "\u0915\u094d\u092f\u093e \u092f\u0947 \u0905\u091f\u0932 \u092c\u093f\u0939\u093e\u0930\u0940 \u0935\u093e\u091c\u092a\u0947\u092f\u0940 \u0915\u0947 \u092c\u091a\u092a\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-graphic-post-claiming-sachin-tendulkar-kapil-dev-sunil-gavaskar-threat-to-return-their-awards-supporting-farmers-protest-11324",
        headline:
          "Farmers Protest: \u09b8\u099a\u09bf\u09a8, \u0995\u09aa\u09bf\u09b2 \u0993 \u09b8\u09c1\u09a8\u09c0\u09b2 \u09aa\u09c1\u09b0\u09b8\u09cd\u0995\u09be\u09b0 \u09ab\u09c7\u09b0\u09be\u09a8\u09cb\u09b0 \u09b9\u09c1\u09ae\u0995\u09bf \u09a6\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/narendra-modi-atal-bihari-vajpayee-indias-first-metro-ani-ndtv-fake-news-fact-check-11354",
        headline:
          "Fact Check: \u09ad\u09be\u09b0\u09a4\u09c7 \u09aa\u09cd\u09b0\u09a5\u09ae Metro \u099a\u09be\u09b2\u09c1\u09b0 \u099c\u09a8\u09cd\u09af Modi \u0995\u09bf Vajpayee-\u0995\u09c7 \u0995\u09c3\u09a4\u09bf\u09a4\u09cd\u09ac \u09a6\u09bf\u09b2\u09c7\u09a8?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/famous-people-who-fake-news-killed-in-2020-but-are-alive-11371",
        headline:
          "\u09e8\u09e6\u09e8\u09e6 \u09b8\u09be\u09b2\u09c7 \u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0\u09c7\u09b0 \u09b9\u09be\u09a4\u09c7 \u09ae\u09be\u09b0\u09be \u09aa\u09a1\u09bc\u09c7\u0993 \u099c\u09c0\u09ac\u09bf\u09a4 \u0986\u099b\u09c7\u09a8 \u09af\u09c7 \u0996\u09cd\u09af\u09be\u09a4\u09a8\u09be\u09ae\u09be \u09ae\u09be\u09a8\u09c1\u09b7\u09b0\u09be",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-pm-modi-never-said-vajpayee-started-the-first-metro-in-india-1754604-2020-12-30",
        headline:
          "Fact Check: PM Modi never said Vajpayee started the first Metro in India",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/29/fact-check-dont-believe-this-fake-cnn-graphic-about-side-effect-of-covid-19-vaccine-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t Believe This Fake CNN Graphic About Side Effect Of Covid-19 Vaccine; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-did-pm-modi-visit-a-church-on-christmas-heres-the-truth/",
        headline:
          "Fact Check: Did PM Modi Visit A Church On Christmas? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-no-pm-modi-did-not-say-that-vajpayee-started-indias-first-metro-heres-the-truth/",
        headline:
          "Fact Check: No, PM Modi Did Not Say That Vajpayee Started India\u2019s First Metro; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-is-this-former-pm-atal-bihari-vajpayees-picture-with-his-mother-heres-the-truth/",
        headline:
          "Fact Check: Is This Former PM Atal Bihari Vajpayee\u2019s Picture With His Mother? Here\u2019s The Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/atal-bihari-vajpayee-childhood-photo-ranu-shankar-muzaffarpur-bihar-fake-news-11333",
        headline:
          "\u0915\u094d\u092f\u093e \u092f\u0947 \u0905\u091f\u0932 \u092c\u093f\u0939\u093e\u0930\u0940 \u0935\u093e\u091c\u092a\u0947\u092f\u0940 \u0915\u0947 \u092c\u091a\u092a\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0948?",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/famous-people-who-fake-news-killed-in-2020-but-are-alive-11318",
        headline: "Famous People Who Fake News Killed In 2020 But Are Alive",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/narendra-modi-atal-bihari-vajpayee-indias-first-metro-ani-ndtv-fake-news-fact-check-11354",
        headline:
          "Fact Check: \u09ad\u09be\u09b0\u09a4\u09c7 \u09aa\u09cd\u09b0\u09a5\u09ae Metro \u099a\u09be\u09b2\u09c1\u09b0 \u099c\u09a8\u09cd\u09af Modi \u0995\u09bf Vajpayee-\u0995\u09c7 \u0995\u09c3\u09a4\u09bf\u09a4\u09cd\u09ac \u09a6\u09bf\u09b2\u09c7\u09a8?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fake-graphic-post-claiming-sachin-tendulkar-kapil-dev-sunil-gavaskar-threat-to-return-their-awards-supporting-farmers-protest-11324",
        headline:
          "Farmers Protest: \u09b8\u099a\u09bf\u09a8, \u0995\u09aa\u09bf\u09b2 \u0993 \u09b8\u09c1\u09a8\u09c0\u09b2 \u09aa\u09c1\u09b0\u09b8\u09cd\u0995\u09be\u09b0 \u09ab\u09c7\u09b0\u09be\u09a8\u09cb\u09b0 \u09b9\u09c1\u09ae\u0995\u09bf \u09a6\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-modi-sitting-with-some-people-are-bjp-gujarat-members-not-peons-672263",
        headline:
          "FACT CHECK: Modi sitting with some people are BJP Gujarat members, not peons",
      },
    ],
    "3": [
      {
        url:
          "http://newsmobile.in/articles/2020/12/28/fact-check-did-amit-shah-disrespect-tagore-during-his-recent-west-bengal-visit-heres-the-truth/",
        headline:
          "Fact Check: Did Amit Shah Disrespect Tagore During His Recent West Bengal Visit? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-dont-fall-for-this-picture-of-women-chained-to-a-man-its-morphed/",
        headline:
          "Fact Check: Don\u2019t Fall For This Picture of Women Chained to A Man; It\u2019s Morphed",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-picture-of-a-platform-ticket-with-adani-railway-printed-on-it-is-morphed/",
        headline:
          "Fact Check: Picture Of A Platform Ticket With \u2018Adani Railway\u2019 Printed On It Is Morphed",
      },
      {
        url:
          "https://www.boomlive.in/coronavirus-outbreak/covid-19-coronavirus-china-wuhan-bioweapon-lab-vaccine-lockdown-mask-narendra-modi-fake-news-fact-check-tablighi-jamaat-ayurveda-11367",
        headline: "The Year Of Covid-19: Top 5 Misinformation Trends",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/factcheck-arvind-kejriwal-mosque-namaz-new-year-2021-delhi-jama-masjid-11376",
        headline:
          "Did Arvind Kejriwal Pray At A Mosque To Begin New Year 2021? A FactCheck",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/image-of-dubai-palm-jumeirah-viral-as-lucknow-gomti-river-front-11331",
        headline:
          "\u0926\u0941\u092c\u0908 \u0915\u0947 \u092a\u093e\u092e \u091c\u0941\u092e\u0947\u0930\u093e\u0939 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0932\u0916\u0928\u090a \u0915\u0947 \u0917\u094b\u092e\u0924\u0940 \u0930\u093f\u0935\u0930 \u092b\u094d\u0930\u0902\u091f \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/amit-shah-narendra-modi-statement-ram-mandir-hindu-muslim-fake-11355",
        headline:
          "\u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0914\u0930 \u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u092c\u092f\u093e\u0928 \u0939\u094b \u0930\u0939\u0947 \u0939\u0948\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fact-check-narendra-modi-carpet-removed-rakabganj-gurudwara-visit-viral-image-11351",
        headline:
          "Fact Check: Modi \u0986\u09b8\u09be\u09b0 \u0986\u0997\u09c7 Rakab Ganj Gurudwara \u0995\u09bf \u0995\u09be\u09b0\u09aa\u09c7\u099f \u09b8\u09b0\u09bf\u09df\u09c7 \u09ab\u09c7\u09b2\u09c7?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/2019-images-of-anti-caa-protest-lead-by-wb-cm-mamata-banerjee-shared-as-rally-in-birbhum-11370",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u0995\u09b2\u0995\u09be\u09a4\u09be\u09b0 CAA \u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 Bolpur-\u098f\u09b0 \u09aa\u09a6\u09af\u09be\u09a4\u09cd\u09b0\u09be",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/kejriwal-did-not-begin-new-year-2021-by-offering-namaz-at-jama-masjid-1755152-2021-01-01",
        headline:
          "Fact Check: Kejriwal did not begin New Year 2021 by offering namaz at Jama Masjid\u00a0",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/28/fact-check-did-amit-shah-disrespect-tagore-during-his-recent-west-bengal-visit-heres-the-truth/",
        headline:
          "Fact Check: Did Amit Shah Disrespect Tagore During His Recent West Bengal Visit? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-dont-fall-for-this-picture-of-women-chained-to-a-man-its-morphed/",
        headline:
          "Fact Check: Don\u2019t Fall For This Picture of Women Chained to A Man; It\u2019s Morphed",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-picture-of-a-platform-ticket-with-adani-railway-printed-on-it-is-morphed/",
        headline:
          "Fact Check: Picture Of A Platform Ticket With \u2018Adani Railway\u2019 Printed On It Is Morphed",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2019-images-of-anti-caa-protest-lead-by-wb-cm-mamata-banerjee-shared-as-rally-in-birbhum-11370",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u0995\u09b2\u0995\u09be\u09a4\u09be\u09b0 CAA \u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 Bolpur-\u098f\u09b0 \u09aa\u09a6\u09af\u09be\u09a4\u09cd\u09b0\u09be",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/amit-shah-narendra-modi-statement-ram-mandir-hindu-muslim-fake-11355",
        headline:
          "\u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0914\u0930 \u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u092c\u092f\u093e\u0928 \u0939\u094b \u0930\u0939\u0947 \u0939\u0948\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/image-of-dubai-palm-jumeirah-viral-as-lucknow-gomti-river-front-11331",
        headline:
          "\u0926\u0941\u092c\u0908 \u0915\u0947 \u092a\u093e\u092e \u091c\u0941\u092e\u0947\u0930\u093e\u0939 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0932\u0916\u0928\u090a \u0915\u0947 \u0917\u094b\u092e\u0924\u0940 \u0930\u093f\u0935\u0930 \u092b\u094d\u0930\u0902\u091f \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/factcheck-arvind-kejriwal-mosque-namaz-new-year-2021-delhi-jama-masjid-11376",
        headline:
          "Did Arvind Kejriwal Pray At A Mosque To Begin New Year 2021? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/coronavirus-outbreak/covid-19-coronavirus-china-wuhan-bioweapon-lab-vaccine-lockdown-mask-narendra-modi-fake-news-fact-check-tablighi-jamaat-ayurveda-11367",
        headline: "The Year Of Covid-19: Top 5 Misinformation Trends",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fact-check-narendra-modi-carpet-removed-rakabganj-gurudwara-visit-viral-image-11351",
        headline:
          "Fact Check: Modi \u0986\u09b8\u09be\u09b0 \u0986\u0997\u09c7 Rakab Ganj Gurudwara \u0995\u09bf \u0995\u09be\u09b0\u09aa\u09c7\u099f \u09b8\u09b0\u09bf\u09df\u09c7 \u09ab\u09c7\u09b2\u09c7?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/aravind-kejriwal-offering-prayers-at-jama-masjid-672450",
        headline:
          "Fact Check: 2016 photo of Kejriwal passed off as CM offering prayers at Jama Masjid on 1 Jan 2021",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-picture-of-burqa-clad-women-in-chains-is-morphed-672261",
        headline:
          "Fact check: Picture of burqa-clad women in chains is morphed",
      },
    ],
    "4": [
      {
        url:
          "https://factly.in/2015-image-of-civil-activist-surat-singh-khalsa-falsely-linked-to-the-ongoing-farmers-protests/",
        headline:
          " 2015 image of civil activist Surat Singh Khalsa falsely linked to the ongoing farmers\u2019 protests",
      },
      {
        url:
          "https://factly.in/old-unidentified-dead-body-photo-falsely-linked-to-the-ongoing-farmers-protests/",
        headline:
          " Old unidentified dead body photo falsely linked to the ongoing farmers\u2019 protests",
      },
      {
        url:
          "https://factly.in/the-posts-claiming-microsoft-has-acquired-all-divisions-of-sony-are-false/",
        headline:
          " Posts claiming \u2018Microsoft has acquired all divisions of Sony\u2019 are false",
      },
      {
        url:
          "https://factly.in/old-fire-accident-on-a-mobile-tower-in-dehradun-falsely-linked-to-the-ongoing-farmers-protests/",
        headline:
          " Old Mobile tower fire accident in Dehradun falsely linked to the ongoing farmers\u2019 protests.",
      },
      {
        url:
          "https://factly.in/telugu-photo-from-2018-protests-linked-to-the-ongoing-farmers-protest/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b 2018\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c07\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-mobile-tower-fire-accident-in-dehradun-falsely-linked-to-the-ongoing-farmers-protests/",
        headline:
          " 2017 \u0c2e\u0c4a\u0c2c\u0c48\u0c32\u0c4d \u0c1f\u0c35\u0c30\u0c4d \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c02 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c41\u0c21\u0c41 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c3e\u0c2f \u0c1a\u0c1f\u0c4d\u0c1f\u0c3e\u0c32\u0c15\u0c41 \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c24\u0c4b \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://digiteye.in/fact-check-no-sony-and-its-playstation-never-acquired-by-microsoft-as-claimed-on-dec-28/",
        headline:
          "Fact Check: No, Sony and its PlayStation never acquired by Microsoft as claimed on \u2018Dec 28\u2019",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-did-microsoft-acquire-sony-in-a-130-billion-deal-heres-the-truth/",
        headline:
          "Fact Check: Did Microsoft Acquire Sony In A $130 Billion Deal? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-this-man-sporting-a-trump-cap-is-not-tennessee-christmas-bomber/",
        headline:
          "Fact Check: This Man Sporting A Trump Cap Is NOT Tennessee Christmas Bomber",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-a-jio-tower-set-on-fire-amid-farmers-protest-1754840-2020-12-31",
        headline:
          "Fact Check: This is not a Jio tower set on fire amid farmers\u2019 protest\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fake-news-pakistan-pilot-urinating-f-16-cockpit-shared-dawn-report-1755450-2021-01-03",
        headline:
          "Fact Check: Fake news of Pakistan pilot urinating in F-16 cockpit shared as Dawn report",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-did-microsoft-acquire-sony-in-a-130-billion-deal-heres-the-truth/",
        headline:
          "Fact Check: Did Microsoft Acquire Sony In A $130 Billion Deal? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-this-man-sporting-a-trump-cap-is-not-tennessee-christmas-bomber/",
        headline:
          "Fact Check: This Man Sporting A Trump Cap Is NOT Tennessee Christmas Bomber",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/microsoft-sony-acquisition-130-billion-fake-news-11336",
        headline:
          "Microsoft-Sony $130 Billion Deal Hoax: Prank Story Games Netizens",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2016-ink-attack-video-against-bjp-mp-falsely-linked-to-farmers-protest-672480",
        headline:
          "Fact Check: 2016 ink attack video against BJP MP falsely linked to farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/trumps-tweet-declaring-martial-law-672451",
        headline: "Fact Check: Trump's tweet declaring martial law is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/microsoft-acquiring-sony-viral-reports-672368",
        headline:
          "Fact check: Viral reports about Microsoft acquiring Sony is prank",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/2013-image-of-national-flag-being-stepped-on-shared-as-recent-protest-by-farmers-672310",
        headline:
          "Fact Check: 2013 image of national flag being stepped on shared as recent protest by farmers",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2017-video-of-mobile-tower-on-fire-in-dehradun-falsely-linked-to-farmers-protest-672424",
        headline:
          "Fact Check: 2017 video of mobile tower on fire in Dehradun falsely linked to farmers' protest",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/image-of-cnn-report-stating-vaccine-administered-covid-19-patients-eating-others-is-digitally-created/",
        headline:
          " Image of a CNN report stating that vaccine administered COVID-19 patients eating others is digitally created",
      },
      {
        url:
          "https://factly.in/telugu-no-pig-fat-ingredients-were-found-in-pfizer-and-moderna-covid-19-vaccine/",
        headline:
          " \u0c2b\u0c48\u0c1c\u0c30\u0c4d, \u0c2e\u0c3e\u0c21\u0c46\u0c30\u0c4d\u0c28\u0c3e \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d-19 \u0c1f\u0c40\u0c15\u0c3e\u0c32\u0c32\u0c4b \u0c2a\u0c02\u0c26\u0c3f \u0c15\u0c4a\u0c35\u0c4d\u0c35\u0c41 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-states-ruled-by-bjp-and-its-allies-appear-6-times-among-top-10-states-with-high-petrol-price/",
        headline:
          " \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c27\u0c30\u0c32\u0c41 \u0c05\u0c27\u0c3f\u0c15\u0c02\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28 \u0c2e\u0c4a\u0c26\u0c1f\u0c3f \u0c2a\u0c26\u0c3f \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c32\u0c4d\u0c32\u0c4b \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40, \u0c35\u0c3e\u0c1f\u0c3f \u0c2e\u0c3f\u0c24\u0c4d\u0c30 \u0c2a\u0c15\u0c4d\u0c37\u0c3e\u0c32\u0c41 \u0c2a\u0c3e\u0c32\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c35\u0c3f \u0c06\u0c30\u0c41 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c32\u0c41 \u0c09\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://digiteye.in/do-you-need-to-register-via-phone-call-to-get-a-covid-19-vaccine-fact-check/",
        headline:
          "Do you need to register via phone call to get a COVID-19 vaccine? Fact Check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/28/fact-check-no-this-viral-picture-is-not-of-pfizers-covid-19-vaccine-heres-the-truth/",
        headline:
          "Fact Check: No, This Viral Picture Is NOT Of Pfizer\u2019s COVID-19 Vaccine; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-us-nurse-who-fainted-after-taking-covid-vaccine-is-not-dead-heres-the-truth/",
        headline:
          "Fact Check: US Nurse Who Fainted After Taking COVID Vaccine Is NOT Dead; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-picture-of-former-cricketer-navjot-singh-sidhu-and-yograj-singh-viral-with-misleading-claim/",
        headline:
          "Fact Check: Picture Of Former Cricketer Navjot Singh Sidhu And Yograj Singh Viral With Misleading Claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-this-stunning-video-of-light-show-is-not-from-jodhpurs-umaid-palace-heres-the-truth/",
        headline:
          "Fact Check: This Stunning Video Of Light Show Is NOT From Jodhpur\u2019s Umaid Palace; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-video-from-russia-falsely-shared-as-that-of-bidens-campaign-aide-being-arrested/",
        headline:
          "Fact Check: Video From Russia Falsely Shared As That Of Biden\u2019s Campaign Aide Being Arrested",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/31/fact-check-claims-about-salary-reduction-of-govt-employees-are-misleading-heres-the-truth/",
        headline:
          "Fact Check: Claims About Salary Reduction Of Govt Employees Are Misleading; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-2020-year-ender-finance-economics-data-misinformation-banks-money-indian-economy-gdp-11358",
        headline: "Economy And Companies In 2020: Top 10 Fake Claims",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-image-covid-19-vaccine-pfizer-vaccine-coronavirus-vapourizer-vape-fake-news-fact-check-11340",
        headline:
          "\u09a8\u09be, Pfizer-\u098f\u09b0 Covid-19 \u09ad\u09cd\u09af\u09be\u0995\u09b8\u09bf\u09a8 \u098f\u09b0\u0995\u09ae \u09a6\u09c7\u0996\u09a4\u09c7 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/covid-19-rules-violated-at-mukesh-ambanis-party-a-factcheck-11377",
        headline:
          "Mukesh Ambani-\u09b0 \u09aa\u09be\u09b0\u09cd\u099f\u09bf\u09a4\u09c7 COVID 19 \u09a8\u09bf\u09df\u09ae \u09ad\u09be\u0999\u09be \u09b9\u09df\u09c7\u099b\u09c7? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af-\u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-kerala-has-not-banned-jio-internet-services-in-the-state-1754996-2021-01-01",
        headline:
          "Fact Check: No, Kerala has not banned Jio Internet services in the state",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/28/fact-check-no-this-viral-picture-is-not-of-pfizers-covid-19-vaccine-heres-the-truth/",
        headline:
          "Fact Check: No, This Viral Picture Is NOT Of Pfizer\u2019s COVID-19 Vaccine; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-us-nurse-who-fainted-after-taking-covid-vaccine-is-not-dead-heres-the-truth/",
        headline:
          "Fact Check: US Nurse Who Fainted After Taking COVID Vaccine Is NOT Dead; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-picture-of-former-cricketer-navjot-singh-sidhu-and-yograj-singh-viral-with-misleading-claim/",
        headline:
          "Fact Check: Picture Of Former Cricketer Navjot Singh Sidhu And Yograj Singh Viral With Misleading Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-this-stunning-video-of-light-show-is-not-from-jodhpurs-umaid-palace-heres-the-truth/",
        headline:
          "Fact Check: This Stunning Video Of Light Show Is NOT From Jodhpur\u2019s Umaid Palace; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-video-from-russia-falsely-shared-as-that-of-bidens-campaign-aide-being-arrested/",
        headline:
          "Fact Check: Video From Russia Falsely Shared As That Of Biden\u2019s Campaign Aide Being Arrested",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/31/fact-check-claims-about-salary-reduction-of-govt-employees-are-misleading-heres-the-truth/",
        headline:
          "Fact Check: Claims About Salary Reduction Of Govt Employees Are Misleading; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-2020-year-ender-finance-economics-data-misinformation-banks-money-indian-economy-gdp-11358",
        headline: "Economy And Companies In 2020: Top 10 Fake Claims",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/viral-image-covid-19-vaccine-pfizer-vaccine-coronavirus-vapourizer-vape-fake-news-fact-check-11340",
        headline:
          "\u09a8\u09be, Pfizer-\u098f\u09b0 Covid-19 \u09ad\u09cd\u09af\u09be\u0995\u09b8\u09bf\u09a8 \u098f\u09b0\u0995\u09ae \u09a6\u09c7\u0996\u09a4\u09c7 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/covid-19-rules-violated-at-mukesh-ambanis-party-a-factcheck-11377",
        headline:
          "Mukesh Ambani-\u09b0 \u09aa\u09be\u09b0\u09cd\u099f\u09bf\u09a4\u09c7 COVID 19 \u09a8\u09bf\u09df\u09ae \u09ad\u09be\u0999\u09be \u09b9\u09df\u09c7\u099b\u09c7? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af-\u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/video-of-lighting-illusion-is-from-turkey-not-jodhpur-672372",
        headline:
          "Fact check: Viral video of lighting illusion is from Turkey, not Jodhpur",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/fact-check-former-prime-minister-manmohan-singh-did-nowt-attends-joe-bowdens-swearing-in-ceremony-viral-post-lies/",
        headline:
          "\u09ab\u09c7\u0995\u09cd\u099f \u099a\u09c7\u0995\u0983 \u099c\u09cb \u09ac\u09be\u0988\u09a1\u09a8\u09f0 \u09b6\u09aa\u09a4 \u0997\u09cd\u09f0\u09b9\u09a3 \u09b8\u09ae\u09be\u09f0\u09cb\u09b9\u09a4 \u09aa\u09c2\u09f0\u09cd\u09ac \u09aa\u09cd\u09f0\u09a7\u09be\u09a8 \u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09a8\u09ae\u09cb\u09b9\u09a8 \u09b8\u09bf\u0982 \u09a8\u09be\u09af\u09be\u09df, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f \u09ae\u09bf\u099b\u09be|",
      },
    ],
    "6": [
      {
        url:
          "https://www.altnews.in/photo-of-narendra-modi-with-lakshman-rao-inamdar-shared-as-he-is-standing-with-anna-hazare/",
        headline:
          "PM Modi is NOT with Anna Hazare but late RSS leader Lakshmanrao Inamdar in this photo",
      },
      {
        url:
          "https://factly.in/the-man-in-this-photo-along-with-narendra-modi-is-laxmanrao-inamdar-not-anna-hazare/",
        headline:
          " The man seen in this photo with Narendra Modi is Laxmanrao Inamdar, not Anna Hazare",
      },
      {
        url:
          "https://digiteye.in/fact-check-did-archaeologists-find-a-shivlingam-inside-a-south-african-cave/",
        headline:
          "Fact Check: Did archaeologists find a Shivlingam inside a South African cave?",
      },
      {
        url:
          "https://digiteye.in/no-adani-group-doesnt-own-pune-junction-fact-check/",
        headline: "No, Adani group doesn\u2019t own Pune Junction; Fact Check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-video-of-accidental-firing-by-cop-shared-with-false-communal-narrative-heres-the-truth/",
        headline:
          "Fact Check: Video Of Accidental Firing By Cop Shared With False Communal Narrative; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/30/fact-check-is-this-anna-hazares-picture-with-pm-modi-heres-the-truth/",
        headline:
          "Fact Check: Is This Anna Hazare\u2019s Picture With PM Modi? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/fact-check-pm-modi-with-anna-hazare-lakshmanrao-inamdarrare-rss-photo-11322",
        headline: "Rare Photo Of PM Modi With Anna Hazare? A Fact Check",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/adani-group-pune-railway-station-ticket-price-fake-news-11320",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u0941\u0923\u0947 \u091c\u0902\u0915\u094d\u0936\u0928 \u0905\u0921\u093e\u0928\u0940 \u0917\u094d\u0930\u0941\u092a \u0915\u0947 \u0939\u093e\u0925\u094b\u0902 \u092e\u0947\u0902 \u091a\u0932\u093e \u0917\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-photo-pm-modi-with-anna-hazare-lakshmanrao-inamdar-rss-camp-11353",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915 : \u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u0947 \u0938\u093e\u0925 \u0905\u0928\u094d\u0928\u093e \u0939\u091c\u093c\u093e\u0930\u0947 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/death-hoax-sushant-singh-rajputs-father-k-k-singh-died-in-hospital-11338",
        headline:
          "Fake News: \u0985\u09ad\u09bf\u09a8\u09c7\u09a4\u09be Sushant Singh Rajput-\u098f\u09b0 \u09ac\u09be\u09ac\u09be\u09b0 \u09ae\u09c3\u09a4\u09cd\u09af\u09c1\u09b0 \u0996\u09ac\u09b0 \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-pm-modi-with-anna-hazare-lakshmanrao-inamdar-rare-rss-photo-11362",
        headline:
          "Fact Check: Anna Hazare-\u098f\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 Narendra Modi-\u09b0 \u098f\u0995 \u09a6\u09c1\u09b7\u09cd\u09aa\u09cd\u09b0\u09be\u09aa\u09cd\u09af \u099b\u09ac\u09bf?",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-video-of-accidental-firing-by-cop-shared-with-false-communal-narrative-heres-the-truth/",
        headline:
          "Fact Check: Video Of Accidental Firing By Cop Shared With False Communal Narrative; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/30/fact-check-is-this-anna-hazares-picture-with-pm-modi-heres-the-truth/",
        headline:
          "Fact Check: Is This Anna Hazare\u2019s Picture With PM Modi? Here\u2019s The Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/viral-photo-pm-modi-with-anna-hazare-lakshmanrao-inamdar-rss-camp-11353",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915 : \u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u0947 \u0938\u093e\u0925 \u0905\u0928\u094d\u0928\u093e \u0939\u091c\u093c\u093e\u0930\u0947 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/adani-group-pune-railway-station-ticket-price-fake-news-11320",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u0941\u0923\u0947 \u091c\u0902\u0915\u094d\u0936\u0928 \u0905\u0921\u093e\u0928\u0940 \u0917\u094d\u0930\u0941\u092a \u0915\u0947 \u0939\u093e\u0925\u094b\u0902 \u092e\u0947\u0902 \u091a\u0932\u093e \u0917\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/fact-check-pm-modi-with-anna-hazare-lakshmanrao-inamdarrare-rss-photo-11322",
        headline: "Rare Photo Of PM Modi With Anna Hazare? A Fact Check",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/famous-people-who-fake-news-killed-in-2020-but-are-alive-11371",
        headline: "           ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/death-hoax-sushant-singh-rajputs-father-k-k-singh-died-in-hospital-11338",
        headline:
          "Fake News: \u0985\u09ad\u09bf\u09a8\u09c7\u09a4\u09be Sushant Singh Rajput-\u098f\u09b0 \u09ac\u09be\u09ac\u09be\u09b0 \u09ae\u09c3\u09a4\u09cd\u09af\u09c1\u09b0 \u0996\u09ac\u09b0 \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-pm-modi-with-anna-hazare-lakshmanrao-inamdar-rare-rss-photo-11362",
        headline:
          "Fact Check: Anna Hazare-\u098f\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 Narendra Modi-\u09b0 \u098f\u0995 \u09a6\u09c1\u09b7\u09cd\u09aa\u09cd\u09b0\u09be\u09aa\u09cd\u09af \u099b\u09ac\u09bf?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/government-has-not-sold-railways-to-adani-group-672287",
        headline: "Fact Check: Government has not sold Railways to Adani Group",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/pm-modi-is-his-political-guru-lakshmanrao-inamdar-not-anna-hazare-672366",
        headline:
          "FACT CHECK: Man in viral picture with PM Modi is his political guru Lakshmanrao Inamdar, not Anna Hazare",
      },
    ],
  },
  numpy_seed: 31,
  number_of_articles: 124,
}
