export const data = {
  mdsDat: {
    x: [
      0.17586102839048925,
      -0.2956011629622642,
      -0.12154733249070367,
      0.20238706362548436,
      0.23545155705280077,
      -0.1965511536158066,
    ],
    y: [
      -0.0013423271635945343,
      -0.24175229076617616,
      0.4170685031684375,
      -0.08158598638935537,
      -0.051341416548399715,
      -0.04104648230091181,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      19.5092900573001,
      11.902566563344088,
      10.926953880441378,
      20.685272467575004,
      13.269192055952628,
      23.706724975386813,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      21.0,
      12.0,
      25.0,
      11.0,
      12.0,
      9.0,
      9.0,
      8.0,
      8.0,
      7.0,
      0.9998511154366301,
      0.9998511154366301,
      1.9997022308732602,
      2.9995533463098902,
      0.9998511154366301,
      0.9998511154366301,
      0.9998511154366301,
      2.9995533463098902,
      1.9997022308732602,
      0.9998511154366301,
      4.999255577183151,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      2.9995533463098902,
      2.9995533463098902,
      14.99776673154945,
      5.9991066926197805,
      5.9991066926197805,
      4.999255577183151,
      4.999255577183151,
      4.999255577183151,
      3.9994044617465203,
      1.0000107153629256,
      1.0000107153629256,
      1.0000107153629256,
      1.0000107153629256,
      2.0000214307258513,
      1.0000107153629256,
      1.0000107153629256,
      1.0000107153629256,
      2.0000214307258513,
      1.0000107153629256,
      4.0000428614517025,
      4.0000428614517025,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      2.0000214307258513,
      2.0000214307258513,
      2.0000214307258513,
      7.000075007540478,
      6.000064292177553,
      6.000064292177553,
      4.0000428614517025,
      3.0000321460887767,
      1.0000114042368224,
      3.0000342127104673,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      2.000022808473645,
      3.0000342127104673,
      3.0000342127104673,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      3.0000342127104673,
      2.000022808473645,
      3.0000342127104673,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      3.000340228197789,
      1.000113409399263,
      3.000340228197789,
      4.000453637597052,
      2.000226818798526,
      4.000453637597052,
      1.000113409399263,
      1.000113409399263,
      7.000793865794841,
      4.000453637597052,
      12.001360912791156,
      11.001247503391895,
      9.001020684593367,
      6.000680456395578,
      5.0005670469963155,
      4.000453637597052,
      4.000453637597052,
      16.001814550388207,
      7.000793865794841,
      7.000793865794841,
      5.0005670469963155,
      1.0000677836287826,
      1.0000677836287826,
      1.0000677836287826,
      1.0000677836287826,
      4.00027113451513,
      2.000135567257565,
      1.0000677836287826,
      2.000135567257565,
      1.0000677836287826,
      1.0000677836287826,
      5.000338918143913,
      5.000338918143913,
      5.000338918143913,
      4.00027113451513,
      4.00027113451513,
      3.000203350886348,
      3.000203350886348,
      2.000135567257565,
      2.000135567257565,
      2.000135567257565,
      2.000135567257565,
      9.000610052659043,
      2.000135567257565,
      2.000135567257565,
      4.00027113451513,
      2.999925074045862,
      1.9999500493639084,
      0.9999750246819542,
      1.9999500493639084,
      1.9999500493639084,
      0.9999750246819542,
      1.9999500493639084,
      0.9999750246819542,
      3.999900098727817,
      0.9999750246819542,
      6.999825172773678,
      5.999850148091724,
      4.999875123409771,
      3.999900098727817,
      3.999900098727817,
      3.999900098727817,
      3.999900098727817,
      3.999900098727817,
      2.999925074045862,
      2.999925074045862,
      2.999925074045862,
      2.999925074045862,
      5.999850148091724,
      6.999825172773678,
      5.999850148091724,
      5.999850148091724,
      3.999900098727817,
      3.999900098727817,
    ],
    Term: [
      "french",
      "product",
      "france",
      "boycott",
      "police",
      "yadav",
      "parliament",
      "tejashwi_yadav",
      "tejashwi",
      "discussion",
      "film",
      "ride",
      "lose",
      "pray",
      "unit",
      "pakistanis",
      "ludhiana",
      "tweet",
      "macron",
      "plane",
      "protest",
      "haryana",
      "priest",
      "violence",
      "link",
      "priest_haryana",
      "brazil",
      "assault",
      "muslim_man",
      "prayer",
      "france",
      "muslim",
      "man",
      "muslims",
      "election",
      "french",
      "woman",
      "hold",
      "hit",
      "suddenly",
      "newly",
      "placard",
      "injure",
      "personnel",
      "political",
      "actor",
      "stadium",
      "london",
      "award",
      "soldier",
      "bus",
      "receive",
      "rjd",
      "bsf",
      "youngest_politician",
      "accident",
      "sonu",
      "holding",
      "yadav",
      "tejashwi",
      "tejashwi_yadav",
      "young",
      "politician",
      "tirpude",
      "voting",
      "grow",
      "iit",
      "develop",
      "register",
      "allow",
      "grinder",
      "credit",
      "kanyashree",
      "pakistan",
      "denmark",
      "raise",
      "padmashila",
      "revoke",
      "california",
      "stone",
      "sub",
      "praise",
      "right",
      "inspector",
      "tamara",
      "himanta",
      "voter",
      "zindabad",
      "muslims",
      "fraud",
      "woman",
      "collection",
      "saudi",
      "morph",
      "chinese_product",
      "kuwaitis_boycotte",
      "insult",
      "show_discussion",
      "announce",
      "hoard",
      "discussion",
      "russia",
      "product",
      "boycott",
      "parliament",
      "french_parliament",
      "quran",
      "french_product",
      "kuwaitis",
      "french",
      "show",
      "france",
      "election",
      "stretcher",
      "behead",
      "biden",
      "mock",
      "cartoon",
      "rana",
      "vienna",
      "shaheen",
      "hawker",
      "teacher",
      "arnab_goswami",
      "goswami",
      "arnab",
      "abdo",
      "mumbai",
      "torture",
      "mumbai_police",
      "publicize",
      "pornhub",
      "sharli",
      "cnn",
      "police",
      "bagh",
      "shirley",
      "france",
      "kapil",
      "minister",
      "plot",
      "kashi",
      "context",
      "council",
      "sabha",
      "manipulation",
      "spread",
      "see",
      "ram",
      "ayodhya",
      "death",
      "dubbaka",
      "congress_leader",
      "congress",
      "evm",
      "trs",
      "kashmir",
      "remove",
      "list",
      "kumar",
      "construction",
      "temple",
      "bihar",
      "election",
      "leader",
      "distribute",
    ],
    Total: [
      21.0,
      12.0,
      25.0,
      11.0,
      12.0,
      9.0,
      9.0,
      8.0,
      8.0,
      7.0,
      0.9998511154366301,
      0.9998511154366301,
      1.9997022308732602,
      2.9995533463098902,
      0.9998511154366301,
      0.9998511154366301,
      0.9998511154366301,
      2.9995533463098902,
      1.9997022308732602,
      0.9998511154366301,
      4.999255577183151,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      3.9994044617465203,
      2.9995533463098902,
      2.9995533463098902,
      25.998831731859422,
      7.999056741983689,
      7.999149500930518,
      7.999289789893618,
      16.999740555899972,
      21.00107012757136,
      5.999427270220165,
      1.0000107153629256,
      1.0000107153629256,
      1.0000107153629256,
      1.0000107153629256,
      2.0000214307258513,
      1.0000107153629256,
      1.0000107153629256,
      1.0000107153629256,
      2.0000214307258513,
      1.0000107153629256,
      4.0000428614517025,
      4.0000428614517025,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      3.0000321460887767,
      2.0000214307258513,
      2.0000214307258513,
      2.0000214307258513,
      9.000025056904386,
      8.000014341541462,
      8.000014341541462,
      4.999893976888332,
      4.000145555488039,
      1.0000114042368224,
      3.0000342127104673,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      1.0000114042368224,
      2.000022808473645,
      3.0000342127104673,
      3.0000342127104673,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      2.000022808473645,
      3.9998853281470974,
      2.000022808473645,
      7.999289789893618,
      3.0001362178729076,
      5.999427270220165,
      2.000022808473645,
      3.000340228197789,
      1.000113409399263,
      3.000340228197789,
      4.000453637597052,
      2.000226818798526,
      4.000453637597052,
      1.000113409399263,
      1.000113409399263,
      7.000793865794841,
      4.000453637597052,
      12.001360912791156,
      11.001247503391895,
      9.001020684593367,
      6.000680456395578,
      5.0005670469963155,
      4.000453637597052,
      4.000453637597052,
      21.00107012757136,
      12.000947266045097,
      25.998831731859422,
      16.999740555899972,
      1.0000677836287826,
      1.0000677836287826,
      1.0000677836287826,
      1.0000677836287826,
      4.00027113451513,
      2.000135567257565,
      1.0000677836287826,
      2.000135567257565,
      1.0000677836287826,
      1.0000677836287826,
      5.000338918143913,
      5.000338918143913,
      5.000338918143913,
      4.00027113451513,
      4.00027113451513,
      3.000203350886348,
      3.000203350886348,
      2.000135567257565,
      2.000135567257565,
      2.000135567257565,
      2.000135567257565,
      12.000425692931566,
      2.000135567257565,
      2.000135567257565,
      25.998831731859422,
      2.999925074045862,
      1.9999500493639084,
      0.9999750246819542,
      1.9999500493639084,
      1.9999500493639084,
      0.9999750246819542,
      1.9999500493639084,
      0.9999750246819542,
      3.999900098727817,
      0.9999750246819542,
      6.999825172773678,
      5.999850148091724,
      4.999875123409771,
      3.999900098727817,
      3.999900098727817,
      3.999900098727817,
      3.999900098727817,
      3.999900098727817,
      2.999925074045862,
      2.999925074045862,
      2.999925074045862,
      2.999925074045862,
      6.99986086345465,
      8.999527403646939,
      13.999936195746926,
      16.999740555899972,
      8.000045654215857,
      4.999910814090742,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.6343,
      1.0841,
      1.3466,
      1.3466,
      1.1642,
      0.4104,
      0.199,
      1.2288,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      2.1284,
      1.8771,
      1.8407,
      1.8407,
      1.9053,
      1.8407,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      2.2139,
      1.9263,
      2.2139,
      1.2332,
      1.8084,
      1.1154,
      2.2139,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.5757,
      1.3039,
      1.0368,
      0.2637,
      0.3521,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      2.0197,
      1.7321,
      2.0197,
      2.0197,
      0.148,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.4394,
      1.2853,
      1.1881,
      0.5921,
      0.3979,
      0.7462,
      1.2163,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.2983,
      -5.2983,
      -4.6052,
      -4.1997,
      -5.2983,
      -5.2983,
      -5.2983,
      -4.1997,
      -4.6052,
      -5.2983,
      -3.6889,
      -3.912,
      -3.912,
      -3.912,
      -3.912,
      -3.912,
      -3.912,
      -3.912,
      -4.1997,
      -4.1997,
      -2.5903,
      -3.5066,
      -3.5066,
      -3.6889,
      -3.6889,
      -3.6889,
      -3.912,
      -4.804,
      -4.804,
      -4.804,
      -4.804,
      -4.1109,
      -4.804,
      -4.804,
      -4.804,
      -4.1109,
      -4.804,
      -3.4177,
      -3.4177,
      -3.7054,
      -3.7054,
      -3.7054,
      -3.7054,
      -3.7054,
      -3.7054,
      -4.1109,
      -4.1109,
      -4.1109,
      -2.8581,
      -3.0123,
      -3.0123,
      -3.4177,
      -3.7054,
      -4.7185,
      -3.6199,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.0254,
      -3.6199,
      -3.6199,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -3.6199,
      -4.0254,
      -3.6199,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.258,
      -5.3566,
      -4.258,
      -3.9703,
      -4.6634,
      -3.9703,
      -5.3566,
      -5.3566,
      -3.4107,
      -3.9703,
      -2.8717,
      -2.9587,
      -3.1594,
      -3.5648,
      -3.7471,
      -3.9703,
      -3.9703,
      -2.584,
      -3.4107,
      -3.4107,
      -3.7471,
      -4.9127,
      -4.9127,
      -4.9127,
      -4.9127,
      -3.5264,
      -4.2195,
      -4.9127,
      -4.2195,
      -4.9127,
      -4.9127,
      -3.3032,
      -3.3032,
      -3.3032,
      -3.5264,
      -3.5264,
      -3.814,
      -3.814,
      -4.2195,
      -4.2195,
      -4.2195,
      -4.2195,
      -2.7154,
      -4.2195,
      -4.2195,
      -3.5264,
      -4.3944,
      -4.7999,
      -5.4931,
      -4.7999,
      -4.7999,
      -5.4931,
      -4.7999,
      -5.4931,
      -4.1068,
      -5.4931,
      -3.5472,
      -3.7013,
      -3.8836,
      -4.1068,
      -4.1068,
      -4.1068,
      -4.1068,
      -4.1068,
      -4.3944,
      -4.3944,
      -4.3944,
      -4.3944,
      -3.7013,
      -3.5472,
      -3.7013,
      -3.7013,
      -4.1068,
      -4.1068,
    ],
  },
  "token.table": {
    Topic: [
      5,
      2,
      2,
      3,
      4,
      5,
      5,
      1,
      2,
      6,
      5,
      5,
      5,
      1,
      2,
      4,
      5,
      6,
      4,
      1,
      2,
      2,
      3,
      5,
      4,
      5,
      3,
      6,
      6,
      2,
      6,
      6,
      6,
      3,
      6,
      3,
      3,
      4,
      2,
      6,
      6,
      1,
      4,
      5,
      6,
      6,
      1,
      1,
      4,
      5,
      3,
      4,
      1,
      4,
      4,
      4,
      5,
      3,
      3,
      1,
      5,
      3,
      2,
      4,
      2,
      2,
      3,
      2,
      3,
      4,
      3,
      6,
      6,
      6,
      6,
      4,
      4,
      2,
      4,
      6,
      1,
      6,
      2,
      1,
      1,
      1,
      1,
      5,
      6,
      6,
      6,
      5,
      4,
      5,
      5,
      1,
      6,
      1,
      1,
      3,
      2,
      3,
      3,
      1,
      4,
      2,
      2,
      1,
      6,
      1,
      4,
      5,
      2,
      2,
      4,
      5,
      3,
      1,
      1,
      1,
      1,
      4,
      1,
      5,
      4,
      3,
      6,
      5,
      2,
      3,
      6,
      3,
      1,
      3,
      2,
      4,
      6,
      4,
      6,
      5,
      5,
      5,
      4,
      5,
      6,
      4,
      2,
      2,
      6,
      2,
      3,
      5,
      3,
      2,
      3,
      5,
      2,
      6,
      2,
      6,
      1,
      6,
      3,
      5,
      6,
      1,
      1,
      5,
      1,
      1,
      3,
      3,
      1,
      3,
      2,
      6,
      1,
      2,
      2,
      3,
    ],
    Freq: [
      0.9999322209655264,
      0.9999892847518922,
      0.9999892847518922,
      0.9999885958932327,
      0.9998866034609704,
      0.9999322209655263,
      0.9999322209655263,
      1.0001489067332838,
      0.9999892847518922,
      1.000024975941828,
      0.9999322209655264,
      0.9999322209655264,
      0.9999322209655264,
      0.14285779392391693,
      0.14285779392391693,
      0.14285779392391693,
      0.14285779392391693,
      0.4285733817717508,
      0.9998866034609702,
      1.0001489067332838,
      0.9999892847518922,
      0.9999892847518922,
      0.9999885958932327,
      0.9999322209655264,
      0.9998866034609704,
      0.9999322209655264,
      0.9999885958932327,
      1.0000249759418278,
      1.0000249759418278,
      0.14285998243491782,
      0.8571598946095069,
      1.0000249759418278,
      1.0000249759418278,
      0.9999885958932327,
      1.000024975941828,
      0.9999885958932327,
      0.9999885958932327,
      0.9998866034609704,
      0.20000356750000445,
      0.8000142700000178,
      1.0000249759418278,
      0.2941221357795774,
      0.2941221357795774,
      0.058824427155915474,
      0.35294656293549287,
      1.0000249759418278,
      1.0001489067332838,
      0.5769490011975706,
      0.269242867225533,
      0.15385306698601883,
      0.6666363974026477,
      0.33331819870132384,
      0.2380831057478221,
      0.7618659383930307,
      0.9998866034609704,
      0.9998866034609704,
      0.9999322209655263,
      0.9999885958932327,
      0.9999885958932327,
      1.0001489067332838,
      0.9999322209655264,
      0.9999885958932327,
      0.9999892847518922,
      0.9998866034609704,
      0.9999892847518922,
      0.9999892847518922,
      0.9999885958932327,
      0.9999892847518922,
      0.9999885958932327,
      0.9998866034609704,
      0.9999885958932327,
      1.000024975941828,
      1.0000249759418278,
      1.000024975941828,
      1.000024975941828,
      0.9998866034609704,
      0.9998866034609704,
      0.3749978599708444,
      0.12499928665694814,
      0.49999714662779254,
      1.0001489067332838,
      1.000024975941828,
      0.9999892847518922,
      1.0001489067332838,
      1.0001489067332838,
      1.0001489067332838,
      0.7500797427654075,
      0.12501329046090123,
      0.12501329046090123,
      1.0000249759418278,
      1.0000249759418278,
      0.9999322209655264,
      0.9998866034609704,
      0.9999322209655264,
      0.9999322209655263,
      0.7500884408668487,
      0.25002948028894956,
      1.0001489067332838,
      0.625055490090764,
      0.3750332940544584,
      0.9999892847518922,
      0.9999885958932327,
      0.9999885958932327,
      1.0001489067332838,
      0.9998866034609704,
      0.9999892847518922,
      0.9999892847518922,
      1.0001489067332838,
      1.0000249759418278,
      0.16666075447457088,
      0.08333037723728544,
      0.749973395135569,
      0.9999892847518922,
      0.749972709339069,
      0.249990903113023,
      0.9999322209655264,
      0.9999885958932327,
      1.0001489067332838,
      1.0001489067332838,
      1.0001489067332838,
      1.0001489067332838,
      0.9998866034609704,
      1.0001489067332838,
      0.9999322209655264,
      0.9998866034609702,
      0.9999885958932327,
      1.000024975941828,
      0.9999322209655264,
      0.9999892847518922,
      0.9999885958932327,
      1.000024975941828,
      0.9999885958932327,
      1.0001489067332838,
      0.9999885958932327,
      0.9999892847518922,
      0.9998866034609704,
      1.0000249759418278,
      0.9998866034609704,
      1.0000249759418278,
      0.9999322209655264,
      0.9999322209655264,
      0.9999322209655264,
      0.5832872893130248,
      0.2499802668484392,
      0.1666535112322928,
      0.9998866034609704,
      0.9999892847518922,
      0.9999892847518922,
      1.0000249759418278,
      0.9999892847518922,
      0.9999885958932327,
      0.9999322209655264,
      0.9999885958932327,
      0.9999892847518922,
      0.9999885958932327,
      0.9999322209655264,
      0.7499986554828982,
      0.24999955182763275,
      0.7499986554828982,
      0.24999955182763275,
      0.22223389188075884,
      0.7778186215826559,
      0.9999885958932327,
      0.9999322209655263,
      1.0000249759418278,
      1.0001489067332838,
      1.0001489067332838,
      0.9999322209655264,
      1.0001489067332838,
      0.2500071671962753,
      0.750021501588826,
      0.9999885958932327,
      0.6667303093838838,
      0.3333651546919419,
      0.7777756123723163,
      0.22222160353494752,
      0.20000424101439582,
      0.8000169640575833,
      0.9999892847518922,
      0.9999885958932327,
    ],
    Term: [
      "abdo",
      "accident",
      "actor",
      "allow",
      "announce",
      "arnab",
      "arnab_goswami",
      "assault",
      "award",
      "ayodhya",
      "bagh",
      "behead",
      "biden",
      "bihar",
      "bihar",
      "bihar",
      "bihar",
      "bihar",
      "boycott",
      "brazil",
      "bsf",
      "bus",
      "california",
      "cartoon",
      "chinese_product",
      "cnn",
      "collection",
      "congress",
      "congress_leader",
      "construction",
      "construction",
      "context",
      "council",
      "credit",
      "death",
      "denmark",
      "develop",
      "discussion",
      "distribute",
      "distribute",
      "dubbaka",
      "election",
      "election",
      "election",
      "election",
      "evm",
      "film",
      "france",
      "france",
      "france",
      "fraud",
      "fraud",
      "french",
      "french",
      "french_parliament",
      "french_product",
      "goswami",
      "grinder",
      "grow",
      "haryana",
      "hawker",
      "himanta",
      "hit",
      "hoard",
      "hold",
      "holding",
      "iit",
      "injure",
      "inspector",
      "insult",
      "kanyashree",
      "kapil",
      "kashi",
      "kashmir",
      "kumar",
      "kuwaitis",
      "kuwaitis_boycotte",
      "leader",
      "leader",
      "leader",
      "link",
      "list",
      "london",
      "lose",
      "ludhiana",
      "macron",
      "man",
      "man",
      "man",
      "manipulation",
      "minister",
      "mock",
      "morph",
      "mumbai",
      "mumbai_police",
      "muslim",
      "muslim",
      "muslim_man",
      "muslims",
      "muslims",
      "newly",
      "padmashila",
      "pakistan",
      "pakistanis",
      "parliament",
      "personnel",
      "placard",
      "plane",
      "plot",
      "police",
      "police",
      "police",
      "political",
      "politician",
      "politician",
      "pornhub",
      "praise",
      "pray",
      "prayer",
      "priest",
      "priest_haryana",
      "product",
      "protest",
      "publicize",
      "quran",
      "raise",
      "ram",
      "rana",
      "receive",
      "register",
      "remove",
      "revoke",
      "ride",
      "right",
      "rjd",
      "russia",
      "sabha",
      "saudi",
      "see",
      "shaheen",
      "sharli",
      "shirley",
      "show",
      "show",
      "show",
      "show_discussion",
      "soldier",
      "sonu",
      "spread",
      "stadium",
      "stone",
      "stretcher",
      "sub",
      "suddenly",
      "tamara",
      "teacher",
      "tejashwi",
      "tejashwi",
      "tejashwi_yadav",
      "tejashwi_yadav",
      "temple",
      "temple",
      "tirpude",
      "torture",
      "trs",
      "tweet",
      "unit",
      "vienna",
      "violence",
      "voter",
      "voter",
      "voting",
      "woman",
      "woman",
      "yadav",
      "yadav",
      "young",
      "young",
      "youngest_politician",
      "zindabad",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-video-of-french-muslims-praying-on-street-shared-as-recent-10574",
        headline:
          "\u09e8\u09e6\u09e7\u09ed \u09b8\u09be\u09b2\u09c7 \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7\u09b0 \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09df \u09a8\u09be\u09ae\u09be\u099c\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-c-voter-did-not-release-any-poll-survey-for-dubbaka-by-election/",
        headline:
          " \u0c26\u0c41\u0c2c\u0c4d\u0c2c\u0c3e\u0c15 \u0c09\u0c2a \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f C-Voter \u0c38\u0c02\u0c38\u0c4d\u0c25 \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c38\u0c30\u0c4d\u0c35\u0c47 \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2017-video-of-french-muslims-praying-on-street-shared-as-recent-10523",
        headline:
          "2017 Video Of French Muslims Praying On Street Shared As Recent",
      },
      {
        url:
          "https://factly.in/telugu-ludhiana-police-helpline-numbers-for-women-falsely-shared-as-those-by-ap-government/",
        headline:
          " \u0c32\u0c41\u0c27\u0c3f\u0c2f\u0c3e\u0c28\u0c3e\u0c32\u0c4b \u0c2e\u0c39\u0c3f\u0c33\u0c32 \u0c2d\u0c26\u0c4d\u0c30\u0c24 \u0c15\u0c4b\u0c38\u0c02 \u0c2a\u0c4d\u0c30\u0c35\u0c47\u0c36 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c28\u0c46\u0c02\u0c2c\u0c30\u0c4d\u0c32\u0c28\u0c41 \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u2018\u0c2b\u0c4d\u0c30\u0c40 \u0c30\u0c48\u0c21\u0c4d\u2019 \u0c38\u0c4d\u0c15\u0c40\u0c02\u0c15\u0c3f \u0c2e\u0c41\u0c21\u0c3f \u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-police-assault-on-muslim-woman-falsely-linked-to-france-1738071-2020-11-05",
        headline:
          "Fact Check: Video of police assault on Muslim woman falsely linked to France",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/02/fact-check-video-from-brazil-falsely-shared-as-french-men-attacking-a-muslim-man-in-france/",
        headline:
          "Fact Check: Video from Brazil falsely shared as French men attacking a Muslim man in France",
      },
      {
        url:
          "https://factly.in/telugu-film-promotion-video-falsely-shared-as-money-recovered-from-corrupt-politicians-in-brazil/",
        headline:
          " \u0c38\u0c3f\u0c28\u0c3f\u0c2e\u0c3e \u0c2a\u0c4d\u0c30\u0c2e\u0c4b\u0c37\u0c28\u0c4d \u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c26\u0c30\u0c4d\u0c36\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c28\u0c15\u0c3f\u0c32\u0c40 \u0c15\u0c30\u0c46\u0c28\u0c4d\u0c38\u0c40 \u0c28\u0c3f \u0c2c\u0c4d\u0c30\u0c46\u0c1c\u0c3f\u0c32\u0c4d \u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c05\u0c35\u0c3f\u0c28\u0c40\u0c24\u0c3f \u0c2a\u0c30\u0c41\u0c32\u0c41 \u0c26\u0c4b\u0c1a\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c38\u0c4a\u0c2e\u0c4d\u0c2e\u0c28\u0c3f  \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-fake-tweet-france-deporting-pakistanis-go-viral-imran-slams-macron-1737276-2020-11-02",
        headline:
          "Fact Check: Fake tweet on France deporting Pakistanis go viral after Imran slams Macron",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-beating-up-temple-priest-in-haryana-viral-as-gujarat-10541",
        headline:
          "\u0939\u0930\u093f\u092f\u093e\u0923\u093e \u092e\u0947\u0902 \u092a\u0941\u091c\u093e\u0930\u0940 \u0915\u0947 \u0938\u093e\u0925 \u092e\u093e\u0930\u092a\u0940\u091f \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0917\u0941\u091c\u0930\u093e\u0924 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-from-argentina-shared-as-ramayanas-jatayu-bird-found-in-kerala-10579",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0930\u093e\u092e\u093e\u092f\u0923 \u0915\u093e \u092a\u094c\u0930\u093e\u0923\u093f\u0915 \u092a\u0915\u094d\u0937\u0940 '\u091c\u091f\u093e\u092f\u0941' \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-cricket-bat-assault-on-temple-priest-in-haryana-viral-as-gujarat-10538",
        headline:
          "Video Of Cricket Bat Assault On Temple Priest In Haryana Viral As Gujarat",
      },
      {
        url:
          "https://factly.in/2017-video-from-canada-now-being-shared-as-muslim-woman-assaulted-by-french-police/",
        headline:
          " 2017 video from Canada now being shared as Muslim woman assaulted by French Police",
      },
      {
        url:
          "https://factly.in/telugu-100-subsidy-for-units-which-costs-upto-50000-rupees-is-applicable-to-various-groups-not-only-muslims/",
        headline:
          " \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c15\u0c47 \u0c15\u0c3e\u0c26\u0c41, \u0c2f\u0c42\u0c28\u0c3f\u0c1f\u0c4d \u0c16\u0c30\u0c4d\u0c1a\u0c41 50 \u0c35\u0c47\u0c32 \u0c30\u0c42\u0c2a\u0c3e\u0c2f\u0c32 \u0c26\u0c3e\u0c15\u0c3e 100 \u0c36\u0c3e\u0c24\u0c02 \u0c38\u0c2c\u0c4d\u0c38\u0c3f\u0c21\u0c40 \u0c35\u0c3f\u0c35\u0c3f\u0c27 \u0c35\u0c30\u0c4d\u0c17\u0c3e\u0c32\u0c15\u0c41 \u0c32\u0c2d\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c3f.",
      },
      {
        url:
          "https://factly.in/an-old-video-falsely-shared-as-the-attack-on-muslims-in-france-in-response-to-the-recent-incidents/",
        headline:
          " An old video falsely shared as the attack on Muslims in France in response to the recent incidents.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/02/fact-check-old-picture-of-anti-caa-protest-in-kolkata-falsely-linked-with-bihar-elections/",
        headline:
          "Fact Check: Old picture of anti-CAA protest in Kolkata falsely linked with Bihar elections",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/07/fact-check-did-donald-trump-tweet-about-leaving-if-he-loses-us-election-heres-the-truth/",
        headline:
          "Fact Check: Did Donald Trump tweet about leaving if he loses US election? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/06/fact-check-this-picture-of-refugees-fleeing-violence-is-not-a-recent-one-from-france-heres-the-truth/",
        headline:
          "Fact Check: This picture of refugees fleeing violence is NOT a recent one from France; here\u2019s the truth",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-from-brazil-viral-as-muslim-man-hits-in-self-defence-when-attacked-in-france/",
        headline:
          "\u0926\u093e\u0935\u093e \u0925\u093e \u0915\u093f \u092b\u093c\u094d\u0930\u093e\u0902\u0938 \u092e\u0947\u0902 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0928\u0947 \u0906\u0924\u094d\u092e\u0930\u0915\u094d\u0937\u093e \u092e\u0947\u0902 2 \u0932\u094b\u0917\u094b\u0902 \u0915\u094b \u092a\u0940\u091f\u093e, \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u094d\u0930\u093e\u095b\u0940\u0932 \u0915\u093e \u0928\u093f\u0915\u0932\u093e",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-pic-viral-as-erdogan-snub-to-macron-amid-turkey-france-tensions-1738386-2020-11-05",
        headline:
          "Fact Check: Old pic viral as Erdogan's 'snub' to Macron amid Turkey-France tensions",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/02/fact-check-old-picture-of-anti-caa-protest-in-kolkata-falsely-linked-with-bihar-elections/",
        headline:
          "Fact Check: Old picture of anti-CAA protest in Kolkata falsely linked with Bihar elections",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/02/fact-check-video-from-brazil-falsely-shared-as-french-men-attacking-a-muslim-man-in-france/",
        headline:
          "Fact Check: Video from Brazil falsely shared as French men attacking a Muslim man in France",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/06/fact-check-this-picture-of-refugees-fleeing-violence-is-not-a-recent-one-from-france-heres-the-truth/",
        headline:
          "Fact Check: This picture of refugees fleeing violence is NOT a recent one from France; here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/07/fact-check-did-donald-trump-tweet-about-leaving-if-he-loses-us-election-heres-the-truth/",
        headline:
          "Fact Check: Did Donald Trump tweet about leaving if he loses US election? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2017-protest-clip-viral-claiming-muslims-in-france-stopped-from-praying-10510",
        headline:
          "2017 Protest Clip Viral Claiming Muslims In France Stopped From Praying",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2017-video-of-french-muslims-praying-on-street-shared-as-recent-10523",
        headline:
          "2017 Video Of French Muslims Praying On Street Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-cricket-bat-assault-on-temple-priest-in-haryana-viral-as-gujarat-10538",
        headline:
          "Video Of Cricket Bat Assault On Temple Priest In Haryana Viral As Gujarat",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2017-protest-clip-viral-claiming-muslims-in-france-stopped-from-praying-10573",
        headline:
          "\u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae\u09a6\u09c7\u09b0 \u09a8\u09be\u09ae\u09be\u099c\u09c7 \u09ac\u09be\u09a7\u09be \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09e8\u09e6\u09e7\u09ed \u09b8\u09be\u09b2\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u09a6\u09b6\u09cd\u09af",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2017-video-of-french-muslims-praying-on-street-shared-as-recent-10574",
        headline:
          "\u09e8\u09e6\u09e7\u09ed \u09b8\u09be\u09b2\u09c7 \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7\u09b0 \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09df \u09a8\u09be\u09ae\u09be\u099c\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-of-man-shouting-allahu-akbar-in-flight-goes-viral-again-10559",
        headline:
          "\u0935\u093f\u092e\u093e\u0928 \u092e\u0947\u0902 '\u0905\u0932\u094d\u0932\u093e\u0939\u0941 \u0905\u0915\u092c\u0930' \u091a\u093f\u0932\u094d\u0932\u093e\u0924\u0947 \u092f\u0941\u0935\u0915 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092b\u093f\u0930 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/video-of-beating-up-temple-priest-in-haryana-viral-as-gujarat-10541",
        headline:
          "\u0939\u0930\u093f\u092f\u093e\u0923\u093e \u092e\u0947\u0902 \u092a\u0941\u091c\u093e\u0930\u0940 \u0915\u0947 \u0938\u093e\u0925 \u092e\u093e\u0930\u092a\u0940\u091f \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0917\u0941\u091c\u0930\u093e\u0924 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/video-from-argentina-shared-as-ramayanas-jatayu-bird-found-in-kerala-10579",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0930\u093e\u092e\u093e\u092f\u0923 \u0915\u093e \u092a\u094c\u0930\u093e\u0923\u093f\u0915 \u092a\u0915\u094d\u0937\u0940 '\u091c\u091f\u093e\u092f\u0941' \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-policeman-assaulting-woman-is-from-canada-not-france-666994",
        headline:
          "Fact check: Viral video of policeman assaulting woman is from Canada, not France",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-venezuela-protests-passed-off-as-stir-in-france-666993",
        headline:
          "Fact Check: Video of Venezuela protests passed off as stir in France",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/tejaswi-yadav-is-not-conferred-with-young-political-leader-award-in-london/",
        headline:
          " Tejaswi Yadav is not conferred with Young Political leader award in London",
      },
      {
        url:
          "https://digiteye.in/has-mukesh-ambani-undergone-a-liver-transplant-in-london-fact-check/",
        headline:
          "Has Mukesh Ambani undergone a liver transplant in London? Fact Check",
      },
      {
        url:
          "https://factly.in/a-russian-soldier-is-falsely-portrayed-as-injured-indian-soldier/",
        headline:
          " A Russian soldier is falsely portrayed as injured Indian soldier",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/06/fact-check-actor-sonu-sood-is-not-holding-placard-in-support-of-rjd-leader-tejashwi-yadav-heres-the-truth/",
        headline:
          "Fact Check: Actor Sonu Sood is NOT holding placard in support of RJD leader Tejashwi Yadav; here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/telugu-the-missing-persons-figures-in-the-state-of-telangana-is-not-abnormal/",
        headline:
          " \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c32\u0c4b \u0c15\u0c32\u0c15\u0c32\u0c02 \u0c30\u0c47\u0c2a\u0c47 \u0c05\u0c02\u0c24\u0c17\u0c3e \u0c2e\u0c3f\u0c38\u0c4d\u0c38\u0c3f\u0c02\u0c17\u0c4d \u0c15\u0c47\u0c38\u0c41\u0c32\u0c41 \u0c06\u0c15\u0c38\u0c4d\u0c2e\u0c3f\u0c15\u0c02\u0c17\u0c3e \u0c2a\u0c46\u0c30\u0c3f\u0c17\u0c3f\u0c2a\u0c4b\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/07/fact-check-has-tejashwi-yadav-received-youngest-politician-award-at-the-age-of-30-heres-the-truth/",
        headline:
          "Fact Check: Has Tejashwi Yadav received \u2018Youngest Politician Award\u2019 at the age of 30? Here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/telugu-photos-of-work-done-for-ladakh-football-stadium-is-linked-to-new-roads-being-constructed/",
        headline:
          " \u0c32\u0c46\u0c39\u0c4d \u0c38\u0c4d\u0c1f\u0c47\u0c21\u0c3f\u0c2f\u0c02 \u0c28\u0c3f\u0c30\u0c4d\u0c2e\u0c3e\u0c23 \u0c2a\u0c28\u0c41\u0c32 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c2d\u0c3e\u0c30\u0c24 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c32\u0c21\u0c16\u0c4d \u0c32\u0c4b \u0c15\u0c4a\u0c24\u0c4d\u0c24\u0c17\u0c3e \u0c28\u0c3f\u0c30\u0c4d\u0c2e\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-tejashwi-yadav-did-not-receive-any-youngest-politician-award-in-london-1738213-2020-11-05",
        headline:
          "Fact Check: Tejashwi Yadav did not receive any \u2018Youngest Politician\u2019 award in London",
      },
      {
        url:
          "https://factly.in/this-photo-of-people-holding-a-no-to-islamophobia-poster-is-from-london-uk-not-austria/",
        headline:
          " This photo of people holding a \u2018No to Islamophobia\u2019 poster is from London (UK), not Austria",
      },
      {
        url:
          "https://www.altnews.in/hindi/images-of-bus-accident-of-bsf-soldiers-going-bihar-polls-duty-shared-with-false-claim/",
        headline:
          "\u092c\u093f\u0939\u093e\u0930 \u092e\u0947\u0902 BSF \u091c\u0935\u093e\u0928\u094b\u0902 \u0938\u0947 \u092d\u0930\u0940 \u092c\u0938 \u092a\u0932\u091f\u0940 \u095b\u0930\u0942\u0930 \u0925\u0940 \u0932\u0947\u0915\u093f\u0928 9 \u091c\u0935\u093e\u0928\u094b\u0902 \u0915\u0947 \u0936\u0939\u0940\u0926 \u0939\u094b\u0928\u0947 \u0915\u0940 \u0959\u092c\u0930 \u095a\u0932\u0924",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/no-bsf-jawans-killed-bus-accident-bihar-1738633-2020-11-06",
        headline:
          "Fact Check: No BSF jawans were killed in this bus accident in Bihar",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/06/fact-check-actor-sonu-sood-is-not-holding-placard-in-support-of-rjd-leader-tejashwi-yadav-heres-the-truth/",
        headline:
          "Fact Check: Actor Sonu Sood is NOT holding placard in support of RJD leader Tejashwi Yadav; here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/07/fact-check-has-tejashwi-yadav-received-youngest-politician-award-at-the-age-of-30-heres-the-truth/",
        headline:
          "Fact Check: Has Tejashwi Yadav received \u2018Youngest Politician Award\u2019 at the age of 30? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/images-of-bus-accident-involving-bsf-personnel-viral-with-fake-claims-10578",
        headline:
          "Images Of Bus Accident Involving BSF Personnel Viral With Fake Claims",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check-video-of-rjds-tejashwi-yadav-distributing-money-to-flood-hit-people-shared-with-false-claims",
        headline:
          "Fact Check: Video of RJD's Tejashwi Yadav distributing money to flood-hit people shared with false claims",
      },
    ],
    "3": [
      {
        url:
          "https://factly.in/these-are-not-actual-statistics-related-to-registered-voters-in-american-states/",
        headline:
          " These are not the actual registered voter figures in American states for 2020",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/misleading-newspaper-graphic-falsely-claims-social-worker-tamara-grigsby-praised-kanyashree-scheme-10576",
        headline:
          "\u0995\u09a8\u09cd\u09af\u09be\u09b6\u09cd\u09b0\u09c0 \u09aa\u09cd\u09b0\u0995\u09b2\u09cd\u09aa\u09c7\u09b0 \u09aa\u09cd\u09b0\u09b6\u0982\u09b8\u09be\u09df \u09b8\u09ae\u09be\u099c\u0995\u09b0\u09cd\u09ae\u09c0 \u09a4\u09be\u09ae\u09be\u09b0\u09be \u0997\u09cd\u09b0\u09bf\u0997\u09b8\u09ac\u09bf? \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-denmark-has-not-passed-a-law-banning-muslims-from-voting-10577",
        headline:
          "\u0928\u0939\u0940\u0902, \u0921\u0947\u0928\u092e\u093e\u0930\u094d\u0915 \u0928\u0947 \u092e\u0941\u0938\u0932\u092e\u093e\u0928\u094b\u0902 \u0915\u0947 \u092e\u0924\u0926\u093e\u0928 \u0905\u0927\u093f\u0915\u093e\u0930 \u0930\u0926\u094d\u0926 \u0928\u0939\u0940\u0902 \u0915\u093f\u090f \u0939\u0948\u0902",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-collection-of-mail-in-ballots-viral-as-voter-fraud-in-california-10549",
        headline:
          "Video Of Collection Of Mail-in Ballots Viral As Voter Fraud In California",
      },
      {
        url:
          "https://digiteye.in/has-rbi-allowed-banks-to-collect-charges-for-cash-credit-current-and-overdraft-accounts-fact-check/",
        headline:
          "Has RBI allowed banks to collect charges for Cash Credit, Current, and Overdraft Accounts? Fact Check",
      },
      {
        url:
          "https://digiteye.in/pakistan-national-assembly-members-did-not-chant-modi-modi-fact-check/",
        headline:
          "Pakistan National Assembly members did not chant \u2018Modi, Modi\u2019; Fact Check",
      },
      {
        url:
          "https://www.altnews.in/hindi/woman-labourer-carrying-stone-grinder-on-her-head-is-not-psi-padmashila-tirpude/",
        headline:
          "\u0938\u092c-\u0907\u0902\u0938\u094d\u092a\u0947\u0915\u094d\u091f\u0930 \u092a\u0926\u094d\u092e\u0936\u093f\u0932\u093e \u0924\u093f\u0930\u092a\u0941\u0921\u0947 \u0928\u0939\u0940\u0902 \u0925\u0940\u0902 \u092e\u095b\u0926\u0942\u0930, \u0938\u093f\u0930 \u092a\u0930 \u0938\u093f\u0932\u092c\u091f\u094d\u091f\u093e \u0930\u0916\u0947 \u0915\u093f\u0938\u0940 \u0914\u0930 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/woman-labourer-carrying-stone-grinder-on-her-head-is-not-psi-padmashila-tirpude/",
        headline:
          "Woman labourer carrying stone grinder on her head is not PSI Padmashila Tirpude",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-denmark-has-not-passed-a-law-banning-muslims-from-voting-10563",
        headline:
          "No, Denmark Has Not Passed A Law Banning Muslims From Voting",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/himanta-biswa-sarma-news18-times-now-falsely-claim-pakistan-zindabad-slogans-raised-in-assam-10540",
        headline:
          "Himanta Biswa Sarma, News18, Times Now Falsely Claim 'Pakistan Zindabad' Slogans Raised In Assam",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-collection-of-mail-in-ballots-viral-as-voter-fraud-in-california-10549",
        headline:
          "Video Of Collection Of Mail-in Ballots Viral As Voter Fraud In California",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/misleading-newspaper-graphic-falsely-claims-social-worker-tamara-grigsby-praised-kanyashree-scheme-10576",
        headline:
          "\u0995\u09a8\u09cd\u09af\u09be\u09b6\u09cd\u09b0\u09c0 \u09aa\u09cd\u09b0\u0995\u09b2\u09cd\u09aa\u09c7\u09b0 \u09aa\u09cd\u09b0\u09b6\u0982\u09b8\u09be\u09df \u09b8\u09ae\u09be\u099c\u0995\u09b0\u09cd\u09ae\u09c0 \u09a4\u09be\u09ae\u09be\u09b0\u09be \u0997\u09cd\u09b0\u09bf\u0997\u09b8\u09ac\u09bf? \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/himanta-biswa-sarma-news18-times-now-make-false-claim-of-pakistan-zindabad-slogans-in-assam-10542",
        headline:
          "\u09b9\u09bf\u09ae\u09a8\u09cd\u09a4 \u09ac\u09bf\u09b6\u09cd\u09ac \u09b6\u09b0\u09cd\u09ae\u09be \u0993 \u0997\u09a3\u09ae\u09be\u09a7\u09cd\u09af\u09ae\u09c7\u09b0 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u0985\u09b8\u09ae\u09c7 \u0989\u09a0\u09b2 '\u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8 \u099c\u09bf\u09a8\u09cd\u09a6\u09be\u09ac\u09be\u09a6' \u09a7\u09cd\u09ac\u09a8\u09bf",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/no-denmark-has-not-passed-a-law-banning-muslims-from-voting-10577",
        headline:
          "\u0928\u0939\u0940\u0902, \u0921\u0947\u0928\u092e\u093e\u0930\u094d\u0915 \u0928\u0947 \u092e\u0941\u0938\u0932\u092e\u093e\u0928\u094b\u0902 \u0915\u0947 \u092e\u0924\u0926\u093e\u0928 \u0905\u0927\u093f\u0915\u093e\u0930 \u0930\u0926\u094d\u0926 \u0928\u0939\u0940\u0902 \u0915\u093f\u090f \u0939\u0948\u0902",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-iit-delhi-has-not-developed-plant-that-grows-eggs-670082",
        headline:
          "Fact check: NO, IIT Delhi has not developed plant that grows eggs",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-lady-sub-inspector-in-viral-picture-is-not-woman-who-sold-stone-blocks-670084",
        headline:
          "Fact check: Lady sub-inspector in viral picture is not woman who sold stone blocks.",
      },
    ],
    "4": [
      {
        url:
          "https://factly.in/2016-video-shared-as-the-recent-video-of-arab-nations-destroying-france-products-in-the-desert/",
        headline:
          " 2016 video shared as the recent video of Arab nations destroying France products in the desert.",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-this-video-does-not-show-a-discussion-on-quran-in-frances-parliament-10504",
        headline:
          "\u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u094d\u0930\u093e\u0902\u0938\u093f\u0938\u0940 \u0938\u0902\u0938\u0926 \u092e\u0947\u0902 \u0958\u0941\u0930\u093e\u0928 \u092a\u0930 \u091a\u0930\u094d\u091a\u093e \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2018-video-from-russia-viral-as-stuffing-of-ballots-in-us-elections-10575",
        headline:
          "2018 Video From Russia Viral As Stuffing Of Ballots In US Elections",
      },
      {
        url:
          "https://factly.in/2015-video-of-belgium-politician-insulting-quran-now-shared-as-discussion-in-france-parliament/",
        headline:
          " 2015 Video of Belgium Politician insulting Quran now shared as discussion in France Parliament",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-video-does-not-show-kuwaitis-boycotting-french-products-10485",
        headline:
          "No, This Video Does Not Show Kuwaitis Boycotting French Products",
      },
      {
        url:
          "https://factly.in/old-video-from-saudi-arabia-falsely-shared-as-france-products-being-dumped-in-kuwait/",
        headline:
          " Old video from Saudi Arabia falsely shared as \u2018France products being dumped in Kuwait\u2019",
      },
      {
        url:
          "https://factly.in/telugu-2015-video-of-belgium-politician-insulting-quran-now-shared-as-discussion-in-france-parliament/",
        headline:
          " 2015\u0c32\u0c4b \u0c2c\u0c46\u0c32\u0c4d\u0c1c\u0c3f\u0c2f\u0c02 \u0c28\u0c3e\u0c2f\u0c15\u0c41\u0c21\u0c41 \u0c16\u0c41\u0c30\u0c3e\u0c28\u0c4d \u0c17\u0c4d\u0c30\u0c02\u0c25\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c05\u0c35\u0c2e\u0c3e\u0c28\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c2a\u0c3e\u0c30\u0c4d\u0c32\u0c2e\u0c46\u0c02\u0c1f\u0c4d \u0c32\u0c4b \u0c1a\u0c30\u0c4d\u0c1a \u0c05\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-video-does-not-show-a-discussion-on-quran-in-frances-parliament-10494",
        headline:
          "No, This Video Does Not Show A Discussion On Quran In France's Parliament",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/old-video-belgium-shared-anti-islam-speech-french-parliament-1737630-2020-11-03",
        headline:
          "Fact Check: Old video from Belgium shared as anti-Islam speech in French Parliament",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-video-does-not-show-kuwaitis-boycotting-french-products-10493",
        headline:
          "\u098f\u099f\u09bf \u0995\u09c1\u09df\u09c7\u09a4\u09bf\u09a6\u09c7\u09b0 \u09ab\u09b0\u09be\u09b8\u09bf \u099c\u09bf\u09a8\u09bf\u09b8 \u09ac\u09af\u09bc\u0995\u099f\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09a8\u09af\u09bc",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-this-video-clip-does-not-show-kuwaiti-boycotting-french-products-10502",
        headline:
          "\u0928\u0939\u0940\u0902, \u0907\u0938 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0915\u0941\u0935\u0948\u0924\u0940 \u095e\u094d\u0930\u093e\u0902\u0938 \u0915\u0947 \u0909\u0924\u094d\u092a\u093e\u0926\u094b\u0902 \u0915\u093e \u092c\u0939\u093f\u0937\u094d\u0915\u093e\u0930 \u0928\u0939\u0940\u0902 \u0915\u0930 \u0930\u0939\u0947",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-video-does-not-show-a-discussion-on-quran-in-frances-parliament-10525",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7\u09b0 \u09b8\u0982\u09b8\u09a6\u09c7 \u0995\u09cb\u09b0\u09be\u09a8\u09c7\u09b0 \u0989\u09aa\u09b0 \u0986\u09b2\u09cb\u099a\u09a8\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09a8\u09df",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/06/fact-check-were-people-in-bihar-chasing-gangs-on-the-streets-find-out-the-truth-behind-this-viral-video/",
        headline:
          "Fact Check: Were people in Bihar chasing gangs on the streets? Find out the truth behind this viral video",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-from-russsia-shared-with-false-claim-as-ballot-stuffing-in-us-presidential-election/",
        headline:
          "\u0930\u0942\u0938 \u0915\u0947 \u091a\u0941\u0928\u093e\u0935 \u092e\u0947\u0902 \u0939\u0941\u0908 \u0917\u095c\u092c\u095c\u0940 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0905\u092e\u0930\u0940\u0915\u0940 \u0930\u093e\u0937\u094d\u091f\u094d\u0930\u092a\u0924\u093f \u091a\u0941\u0928\u093e\u0935\u094b\u0902 \u092e\u0947\u0902 \u0927\u093e\u0902\u0927\u0932\u0940 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-of-french-muslims-praying-on-streets-during-protest-shared-as-recent/",
        headline:
          "\u095e\u094d\u0930\u093e\u0902\u0938 \u092e\u0947\u0902 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0947 \u0926\u094c\u0930\u093e\u0928 \u0938\u095c\u0915 \u092a\u0930 \u0928\u092e\u093e\u091c \u092a\u095d\u0924\u0947 \u0932\u094b\u0917\u094b\u0902 \u0915\u093e 3 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0939\u093e\u0932\u093f\u092f\u093e \u092c\u0924\u093e\u0915\u0930 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/fact-check-morphed-image-shared-to-claim-itbp-appeals-to-boycott-chinese-produsts-in-diwali/",
        headline:
          "\u091a\u0940\u0928\u0940 \u092a\u094d\u0930\u094b\u0921\u0915\u094d\u091f\u094d\u0938 \u0915\u0947 \u092c\u0939\u093f\u0937\u094d\u0915\u093e\u0930 \u0935\u093e\u0932\u093e \u0939\u094b\u0930\u094d\u0921\u093f\u0902\u0917 ITBP \u0915\u0940 \u0913\u0930 \u0938\u0947 \u091c\u093e\u0930\u0940 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/fact-check-morphed-image-shared-to-claim-itbp-appeals-to-boycott-chinese-produsts-in-diwali/",
        headline:
          "Did Indo-Tibetan Border Police call for boycott of Chinese products on Diwali? Morphed image shared",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-video-russia-shared-electoral-fraud-recent-us-elections-1739017-2020-11-08",
        headline:
          "Fact Check: Old video from Russia shared as electoral fraud in recent US elections",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/06/fact-check-were-people-in-bihar-chasing-gangs-on-the-streets-find-out-the-truth-behind-this-viral-video/",
        headline:
          "Fact Check: Were people in Bihar chasing gangs on the streets? Find out the truth behind this viral video",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-kuwaitis-boycotting-french-products-10493",
        headline:
          "\u098f\u099f\u09bf \u0995\u09c1\u09df\u09c7\u09a4\u09bf\u09a6\u09c7\u09b0 \u09ab\u09b0\u09be\u09b8\u09bf \u099c\u09bf\u09a8\u09bf\u09b8 \u09ac\u09af\u09bc\u0995\u099f\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09a8\u09af\u09bc",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-kuwaitis-boycotting-french-products-10485",
        headline:
          "No, This Video Does Not Show Kuwaitis Boycotting French Products",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2018-video-from-russia-viral-as-stuffing-of-ballots-in-us-elections-10575",
        headline:
          "2018 Video From Russia Viral As Stuffing Of Ballots In US Elections",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-a-discussion-on-quran-in-frances-parliament-10494",
        headline:
          "No, This Video Does Not Show A Discussion On Quran In France's Parliament",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-saudi-government-disposing-french-products-10486",
        headline:
          "\u09a8\u09be, \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u09b8\u09cc\u09a6\u09bf \u09b8\u09b0\u0995\u09be\u09b0\u09c7\u09b0 \u09ab\u09b0\u09be\u09b8\u09bf \u09a6\u09cd\u09b0\u09ac\u09cd\u09af \u09a8\u09b7\u09cd\u099f \u0995\u09b0\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-a-discussion-on-quran-in-frances-parliament-10525",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7\u09b0 \u09b8\u0982\u09b8\u09a6\u09c7 \u0995\u09cb\u09b0\u09be\u09a8\u09c7\u09b0 \u0989\u09aa\u09b0 \u0986\u09b2\u09cb\u099a\u09a8\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fake-post-claims-call-to-boycott-french-products-caused-economic-crisis-10508",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09aa\u09cb\u09b8\u09cd\u099f\u09c7\u09b0 \u09a6\u09be\u09ac\u09bf \u09ab\u09b0\u09be\u09b8\u09bf \u09aa\u09a3\u09cd\u09af \u09ac\u09df\u0995\u099f\u09c7\u09b0 \u09ab\u09b2\u09c7 \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u0985\u09b0\u09cd\u09a5\u09a8\u09c8\u09a4\u09bf\u0995 \u09b8\u0999\u09cd\u0995\u099f",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-a-discussion-on-quran-in-frances-parliament-10504",
        headline:
          "\u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u094d\u0930\u093e\u0902\u0938\u093f\u0938\u0940 \u0938\u0902\u0938\u0926 \u092e\u0947\u0902 \u0958\u0941\u0930\u093e\u0928 \u092a\u0930 \u091a\u0930\u094d\u091a\u093e \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/no-this-video-clip-does-not-show-kuwaiti-boycotting-french-products-10502",
        headline:
          "\u0928\u0939\u0940\u0902, \u0907\u0938 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0915\u0941\u0935\u0948\u0924\u0940 \u095e\u094d\u0930\u093e\u0902\u0938 \u0915\u0947 \u0909\u0924\u094d\u092a\u093e\u0926\u094b\u0902 \u0915\u093e \u092c\u0939\u093f\u0937\u094d\u0915\u093e\u0930 \u0928\u0939\u0940\u0902 \u0915\u0930 \u0930\u0939\u0947",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-rotten-chicken-disposal-in-saudi-arabia-passed-off-as-boycott-of-french-products-666988",
        headline:
          "Fact check: Rotten chicken disposal in Saudi Arabia passed off as boycott of French products",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/viral-claim-that-itbp-has-called-for-chinese-products-boycott-is-false-666991",
        headline:
          "Viral claim that ITBP has called for Chinese products boycott is false",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/face-check-kuwait-is-not-dumping-products-from-french-as-claimed-in-viral-video-670088",
        headline:
          "Face Check: Kuwait is not dumping products from French as claimed in viral video",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-pv-sindhu-announced-her-retirement-from-negativity-not-badminton-666975",
        headline:
          "Fact Check: PV Sindhu announced her retirement from negativity, not Badminton.",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/this-video-does-not-show-vienna-police-chasing-down-the-terrorist-who-carried-the-recent-terror-attack/",
        headline:
          " This video does not show Vienna Police chasing down the terrorist who carried out the recent terror attack",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-photos-from-up-falsely-shared-as-arnab-goswami-beated-by-police/",
        headline:
          " UP\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c18\u0c1f\u0c28\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c05\u0c30\u0c4d\u0c23\u0c2c\u0c4d \u0c17\u0c4b\u0c38\u0c4d\u0c35\u0c3e\u0c2e\u0c3f\u0c28\u0c3f \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c15\u0c4a\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/the-scuffle-between-police-and-hawkers-in-this-video-is-actually-from-mumbai-not-bihar/",
        headline:
          " The scuffle between police and hawkers in this video is actually from Mumbai, not Bihar.",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-charlie-hebdo-cartoon-on-shaheen-bagh-protestors-is-fake-10491",
        headline:
          "\u09b6\u09be\u09b9\u09bf\u09a8\u09ac\u09be\u0997\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c0\u09a6\u09c7\u09b0 \u09a8\u09bf\u09df\u09c7 \u09b6\u09be\u09b0\u09cd\u09b2\u09bf \u098f\u09ac\u09a6\u09cb\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u0995\u09be\u09b0\u09cd\u099f\u09c1\u09a8\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://factly.in/telugu-this-video-does-not-show-the-police-apprehending-the-knife-attacker-in-nice-france/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c32\u0c4b \u0c1f\u0c40\u0c1a\u0c30\u0c4d \u0c24\u0c32 \u0c28\u0c30\u0c3f\u0c15\u0c3f\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f\u0c28\u0c3f \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3e\u0c30\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-charlie-hebdo-cartoon-on-shaheen-bagh-protestors-is-fake-10464",
        headline:
          "\u0936\u093e\u0939\u0940\u0928 \u092c\u093e\u095a \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0936\u093e\u0930\u094d\u0932\u0940 \u090f\u092c\u094d\u0921\u094b \u0915\u093e \u092f\u0939 \u0915\u093e\u0930\u094d\u091f\u0942\u0928 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-heartbreaking-video-of-6-year-old-pushing-stretcher-in-hospital-is-not-from-bihar-1738698-2020-11-06",
        headline:
          "Fact Check: This heartbreaking video of 6-year-old pushing stretcher in hospital is not from Bihar",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-pornhub-logo-did-not-appear-on-cnn-during-the-us-poll-coverage-1738728-2020-11-07",
        headline:
          "Fact Check: No, Pornhub logo did not appear on CNN during the US poll coverage",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-time-magazine-cover-mocking-trump-exit-from-white-house-is-not-real-1739181-2020-11-08",
        headline:
          "Fact Check: Time magazine cover mocking Trump exit from White House is not real",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-beef-up-misleading-post-to-target-kamala-harris-1739195-2020-11-08",
        headline:
          "Fact Check: Netizens beef up misleading post to target Kamala Harris",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-a-pornhub-notification-did-not-pop-up-during-cnns-election-coverage-10539",
        headline:
          "No, A Pornhub Notification Did Not Pop Up During CNN's Election Coverage",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/photo-of-arnab-goswami-tortured-by-mumbai-police-not-really-10506",
        headline:
          "Photo Of Arnab Goswami Tortured By Mumbai Police? Not Really",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/photo-of-arnab-goswami-tortured-by-mumbai-police-not-really-10524",
        headline:
          "\u09ae\u09c1\u09ae\u09cd\u09ac\u0987 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u0985\u09b0\u09cd\u09a3\u09ac \u0997\u09cb\u09b8\u09cd\u09ac\u09be\u09ae\u09c0\u0995\u09c7 \u09a8\u09bf\u09b0\u09cd\u09af\u09be\u09a4\u09a8 \u0995\u09b0\u09be\u09b0 \u099b\u09ac\u09bf? \u09a8\u09be, \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09a0\u09bf\u0995 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/viral-charlie-hebdo-cartoon-on-shaheen-bagh-protestors-is-fake-10491",
        headline:
          "\u09b6\u09be\u09b9\u09bf\u09a8\u09ac\u09be\u0997\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c0\u09a6\u09c7\u09b0 \u09a8\u09bf\u09df\u09c7 \u09b6\u09be\u09b0\u09cd\u09b2\u09bf \u098f\u09ac\u09a6\u09cb\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u0995\u09be\u09b0\u09cd\u099f\u09c1\u09a8\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/edited-screenshot-falsely-claims-rana-ayyub-condoned-france-attacks-10473",
        headline:
          "\u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09b6\u09bf\u09b0\u09cb\u099a\u09cd\u099b\u09c7\u09a6 \u09aa\u09cd\u09b0\u09b8\u0999\u09cd\u0997\u09c7 \u09b0\u09be\u09a8\u09be \u0986\u09df\u09c1\u09ac\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/viral-charlie-hebdo-cartoon-on-shaheen-bagh-protestors-is-fake-10464",
        headline:
          "\u0936\u093e\u0939\u0940\u0928 \u092c\u093e\u095a \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0936\u093e\u0930\u094d\u0932\u0940 \u090f\u092c\u094d\u0921\u094b \u0915\u093e \u092f\u0939 \u0915\u093e\u0930\u094d\u091f\u0942\u0928 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/uttar-pradesh-photo-viral-as-mumbai-polices-treatment-of-republics-arnab-goswami-10509",
        headline:
          "\u0915\u094d\u092f\u093e \u092e\u0941\u0902\u092c\u0908 \u092a\u0941\u0932\u093f\u0938 \u0928\u0947 \u0905\u0930\u094d\u0928\u092c \u0917\u094b\u0938\u094d\u0935\u093e\u092e\u0940 \u0915\u0940 \u0925\u093e\u0928\u0947 \u092e\u0947\u0902 \u091c\u092e\u0915\u0930 \u092a\u093f\u091f\u093e\u0908 \u0915\u0940?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/cnn-channel-edited-screenshot-falsely-claims-rana-ayyub-condoned-france-attacks-10472",
        headline:
          "\u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0915\u093e \u0926\u093e\u0935\u093e: \u0930\u093e\u0923\u093e \u0905\u092f\u094d\u092f\u0942\u092c \u0928\u0947 \u095e\u094d\u0930\u093e\u0902\u0938 \u0939\u092e\u0932\u0947 \u0915\u094b \u092e\u0902\u095b\u0942\u0930 \u0915\u093f\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check-reports-about-france-rejecting-183-visas-of-pakistani-nationals-are-false",
        headline:
          "Fact Check: Reports about France rejecting 183 visas of Pakistani nationals are false",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-photos-of-arnab-goswami-being-tortured-by-police-are-false-670073",
        headline:
          "Fact Check: Viral photos of Arnab Goswami being tortured by police are false",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photograph-of-joe-biden-in-airplane-without-a-mask-is-from-2019-666983",
        headline:
          "Fact Check: Photograph of Joe Biden in airplane without a mask is from 2019",
      },
    ],
    "6": [
      {
        url:
          "https://www.boomlive.in/fake-news/no-sachin-tendulkar-did-not-say-this-about-prophet-mohammed-10520",
        headline:
          "No, Sachin Tendulkar Did Not Say This About Prophet Mohammed",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019s-images-of-evm-loaded-in-a-truck-in-bihar-revived-with-false-claim-10475",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b2\u09cb\u0995\u09b8\u09ad\u09be \u09a8\u09bf\u09b0\u09cd\u09ac\u09be\u099a\u09a8\u09c7 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u099f\u09cd\u09b0\u09be\u0995\u09ad\u09b0\u09cd\u09a4\u09bf \u0987\u09ad\u09bf\u098f\u09ae \u09ae\u09c7\u09b6\u09bf\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://factly.in/these-images-do-not-show-ram-temple-which-is-under-construction-in-ayodhya/",
        headline:
          " These images do not show Ram Temple construction in Ayodhya.",
      },
      {
        url:
          "https://factly.in/telugu-this-image-does-not-show-ram-temple-construction-in-ayodhya/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c05\u0c2f\u0c4b\u0c27\u0c4d\u0c2f\u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c4d\u0c2e\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c3e\u0c2e \u0c2e\u0c02\u0c26\u0c3f\u0c30\u0c3e\u0c28\u0c3f\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-unrelated-images-viral-as-muslim-congress-leader-caught-with-arms-10536",
        headline:
          "\u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09bf\u09df\u09c7 \u09a6\u09be\u09ac\u09bf \u0985\u09b8\u09cd\u09a4\u09cd\u09b0 \u09b8\u09b9 \u0985\u09b8\u09ae\u09c7 \u09a7\u09c3\u09a4 \u098f\u0995 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u0995\u0982\u0997\u09cd\u09b0\u09c7\u09b8 \u09a8\u09c7\u09a4\u09be",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-a-congress-leader-arrested-for-plotting-attack-on-hindus-1738264-2020-11-05",
        headline:
          "Fact Check: This is not a Congress leader arrested for plotting attack on Hindus",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/02/fact-check-old-photo-of-nitish-kumar-and-tejashwi-yadav-shared-in-context-of-ongoing-bihar-elections/",
        headline:
          "Fact Check: Old photo of Nitish Kumar and Tejashwi Yadav shared in context of ongoing Bihar elections",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-image-does-not-show-under-construction-ram-temple-in-ayodhya-10470",
        headline:
          "\u09a8\u09be, \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u09a8\u09bf\u09b0\u09cd\u09ae\u09c0\u09df\u09ae\u09be\u09a8 \u09b0\u09be\u09ae \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u09c7\u09b0 \u09a8\u09af\u09bc",
      },
      {
        url:
          "https://factly.in/telugu-a-video-of-the-attack-on-nitish-kumars-convoy-in-2018-falsely-shared-as-recent-attack-on-bjp/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c26\u0c3e\u0c21\u0c3f 2018 \u0c32\u0c4b \u0c28\u0c3f\u0c24\u0c40\u0c37\u0c4d \u0c15\u0c41\u0c2e\u0c3e\u0c30\u0c4d \u0c15\u0c3e\u0c28\u0c4d\u0c35\u0c3e\u0c2f\u0c4d \u0c2a\u0c48 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f, \u0c24\u0c3e\u0c1c\u0c17\u0c3e \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c15\u0c3e\u0c28\u0c4d\u0c35\u0c3e\u0c2f\u0c4d \u0c2a\u0c48 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/02/fact-check-are-these-first-pictures-of-under-construction-ram-temple-in-ayodhya-heres-the-truth/",
        headline:
          "Fact Check: Are these first pictures of under-construction Ram Temple in Ayodhya? Here\u2019s the truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/news-of-prime-minister-narendra-modis-sister-in-laws-demise-is-from-last-year-10492",
        headline:
          "\u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0915\u0940 \u092d\u093e\u092d\u0940 \u0915\u0947 \u0928\u093f\u0927\u0928 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0916\u092c\u0930 \u092b\u093f\u0930 \u0939\u0941\u0908 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://factly.in/telugu-jammu-and-kashmir-was-removed-from-the-u-n-list-of-disputes-in-2010-itself/",
        headline:
          " \u0c15\u0c3e\u0c36\u0c4d\u0c2e\u0c40\u0c30\u0c4d \u0c05\u0c02\u0c36\u0c02 UN \u0c38\u0c46\u0c15\u0c4d\u0c2f\u0c42\u0c30\u0c3f\u0c1f\u0c40 \u0c15\u0c4c\u0c28\u0c4d\u0c38\u0c3f\u0c32\u0c4d \u0c35\u0c3f\u0c35\u0c3e\u0c26\u0c3e\u0c38\u0c4d\u0c2a\u0c26 \u0c2d\u0c42\u0c2d\u0c3e\u0c17\u0c3e\u0c32 \u0c32\u0c3f\u0c38\u0c4d\u0c1f\u0c4d \u0c32\u0c4b\u0c28\u0c41\u0c02\u0c21\u0c3f 2010\u0c32\u0c4b\u0c28\u0c47 \u0c24\u0c4a\u0c32\u0c17\u0c3f\u0c02\u0c1a\u0c3e\u0c30\u0c41,\u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/dubbaka-bypolls-old-video-of-trs-workers-distributing-money-revived-10481",
        headline:
          "Dubbaka Bypolls: Old Video Of TRS Workers Distributing Money Revived",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-of-trs-members-distributing-cash-revived-ahead-of-dubbaka-bypolls/",
        headline:
          "\u0924\u0947\u0932\u0902\u0917\u093e\u0928\u093e \u0909\u092a\u091a\u0941\u0928\u093e\u0935\u094b\u0902 \u092e\u0947\u0902 \u092a\u0948\u0938\u093e \u092c\u093e\u0902\u091f\u0928\u0947 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u091a\u093e\u0932\u0942 \u0907\u0932\u0947\u0915\u094d\u0936\u0928 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/kashi-vishwanath-corridor-images-viral-as-first-picture-of-ram-mandir/",
        headline:
          "Photo of Kashi Vishwanath Corridor viral as \u2018first image of Ram Temple in Ayodhya\u2019",
      },
      {
        url:
          "https://www.altnews.in/old-video-of-trs-members-distributing-cash-revived-ahead-of-dubbaka-bypolls/",
        headline:
          "Old video of TRS members distributing cash revived ahead of Dubbaka by-polls",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/02/fact-check-old-photo-of-nitish-kumar-and-tejashwi-yadav-shared-in-context-of-ongoing-bihar-elections/",
        headline:
          "Fact Check: Old photo of Nitish Kumar and Tejashwi Yadav shared in context of ongoing Bihar elections",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/02/fact-check-are-these-first-pictures-of-under-construction-ram-temple-in-ayodhya-heres-the-truth/",
        headline:
          "Fact Check: Are these first pictures of under-construction Ram Temple in Ayodhya? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/has-the-un-removed-kashmir-from-unresolved-disputes-list-a-factcheck-10522",
        headline:
          "Has The UN Removed Kashmir From Unresolved Disputes List? A Factcheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/dubbaka-bypolls-old-video-of-trs-workers-distributing-money-revived-10481",
        headline:
          "Dubbaka Bypolls: Old Video Of TRS Workers Distributing Money Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/death-hoax-social-media-posts-falsely-claim-kapil-dev-has-passed-away-10479",
        headline:
          "Death Hoax: Social Media Posts Falsely Claim Kapil Dev Has Passed Away",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-sachin-tendulkar-did-not-say-this-about-prophet-mohammed-10520",
        headline:
          "No, Sachin Tendulkar Did Not Say This About Prophet Mohammed",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/viral-image-does-not-show-under-construction-ram-temple-in-ayodhya-10470",
        headline:
          "\u09a8\u09be, \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u09a8\u09bf\u09b0\u09cd\u09ae\u09c0\u09df\u09ae\u09be\u09a8 \u09b0\u09be\u09ae \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u09c7\u09b0 \u09a8\u09af\u09bc",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2019-video-viral-with-false-claims-of-evm-being-rigged-in-bihar-polls-10467",
        headline:
          "\u09ac\u09bf\u09b9\u09be\u09b0 \u09ad\u09cb\u099f\u09c7 \u0987\u09ad\u09bf\u098f\u09ae \u0995\u09be\u09b0\u099a\u09c1\u09aa\u09bf \u09ac\u09b2\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2019s-images-of-evm-loaded-in-a-truck-in-bihar-revived-with-false-claim-10475",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b2\u09cb\u0995\u09b8\u09ad\u09be \u09a8\u09bf\u09b0\u09cd\u09ac\u09be\u099a\u09a8\u09c7 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u099f\u09cd\u09b0\u09be\u0995\u09ad\u09b0\u09cd\u09a4\u09bf \u0987\u09ad\u09bf\u098f\u09ae \u09ae\u09c7\u09b6\u09bf\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/has-the-un-removed-kashmir-from-unresolved-disputes-list-a-factcheck-10567",
        headline:
          "\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09b8\u0982\u0998 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u0995\u09c7 \u09ac\u09bf\u09b0\u09cb\u09a7 \u0985\u09ae\u09c0\u09ae\u09be\u0982\u09b8\u09bf\u09a4 \u098f\u09b2\u09be\u0995\u09be\u09b0 \u09a4\u09be\u09b2\u09bf\u0995\u09be \u09a5\u09c7\u0995\u09c7 \u09ac\u09be\u09a6 \u09a6\u09bf\u09df\u09c7\u099b\u09c7?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/death-hoax-social-media-posts-falsely-claim-kapil-dev-has-passed-away-10484",
        headline:
          "\u09b8\u09cb\u09b6\u09be\u09b2 \u09ae\u09bf\u09a1\u09bf\u09df\u09be\u09df \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09aa\u09bf\u09b2 \u09a6\u09c7\u09ac\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u09ae\u09c3\u09a4\u09cd\u09af\u09c1 \u09b8\u0982\u09ac\u09be\u09a6",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/old-unrelated-images-viral-as-muslim-congress-leader-caught-with-arms-10536",
        headline:
          "\u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09bf\u09df\u09c7 \u09a6\u09be\u09ac\u09bf \u0985\u09b8\u09cd\u09a4\u09cd\u09b0 \u09b8\u09b9 \u0985\u09b8\u09ae\u09c7 \u09a7\u09c3\u09a4 \u098f\u0995 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u0995\u0982\u0997\u09cd\u09b0\u09c7\u09b8 \u09a8\u09c7\u09a4\u09be",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/news-of-prime-minister-narendra-modis-sister-in-laws-demise-is-from-last-year-10492",
        headline:
          "\u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0915\u0940 \u092d\u093e\u092d\u0940 \u0915\u0947 \u0928\u093f\u0927\u0928 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0916\u092c\u0930 \u092b\u093f\u0930 \u0939\u0941\u0908 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/news-that-cricketer-kapil-dev-has-passed-away-is-fake-10480",
        headline:
          "\u0915\u094d\u0930\u093f\u0915\u0947\u091f\u0930 \u0915\u092a\u093f\u0932 \u0926\u0947\u0935 \u0915\u0947 \u092e\u094c\u0924 \u0915\u0940 \u0916\u092c\u0930 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check-viral-video-of-trs-men-distributing-money-is-not-related-to-dubbaka-by-poll",
        headline:
          "Fact check: Viral video of TRS men distributing money is not related to Dubbaka by-poll",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check-old-video-falsely-claims-to-show-evm-manipulation-by-bjp-in-bihar-polls",
        headline:
          "Fact Check: Old video falsely claims to show EVM manipulation by BJP in Bihar polls",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-assam-congress-leader-was-not-caught-with-arms-ammunition-670080",
        headline:
          "Fact Check: No, Assam Congress leader was not caught with arms, ammunition",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-pictures-of-under-construction-kashi-viswanath-temple-passed-off-as-ayodhya-ram-mandir-666985",
        headline:
          "Fact Check: Viral pictures of under-construction Kashi Viswanath temple passed off as Ayodhya Ram Mandir",
      },
    ],
  },
  numpy_seed: 0,
  number_of_articles: 151,
}
