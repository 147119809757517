export const data = {
  mdsDat: {
    x: [
      0.3775059479955502,
      -0.15975204551388852,
      0.0231820259091693,
      -0.2581308241800061,
      0.01719489578917454,
    ],
    y: [
      0.06768794628702512,
      -0.29588511523147726,
      -0.20481299393301486,
      0.27428763004484447,
      0.15872253283262253,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      16.581998123369893,
      17.603601195060133,
      23.439177679223473,
      19.754558473565595,
      22.620664528780914,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      15.0,
      13.0,
      12.0,
      10.0,
      8.0,
      8.0,
      8.0,
      6.0,
      7.0,
      8.0,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      8.000302304460435,
      8.000302304460435,
      8.000302304460435,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      5.000188940287773,
      4.0001511522302176,
      0.9999254864868459,
      0.9999254864868459,
      0.9999254864868459,
      0.9999254864868459,
      2.999776459460538,
      0.9999254864868459,
      1.9998509729736917,
      0.9999254864868459,
      0.9999254864868459,
      0.9999254864868459,
      6.999478405407922,
      5.999552918921076,
      5.999552918921076,
      3.9997019459473835,
      3.9997019459473835,
      3.9997019459473835,
      2.999776459460538,
      2.999776459460538,
      2.999776459460538,
      2.999776459460538,
      2.999776459460538,
      6.999478405407922,
      2.999776459460538,
      3.9997019459473835,
      1.0000033446550798,
      4.000013378620319,
      4.000013378620319,
      2.0000066893101596,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      13.000043480516037,
      7.000023412585558,
      6.000020067930479,
      6.000020067930479,
      5.000016723275399,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      6.000020067930479,
      6.000020067930479,
      5.000016723275399,
      1.0000105507084758,
      6.000063304250855,
      1.0000105507084758,
      1.0000105507084758,
      1.0000105507084758,
      5.000052753542379,
      1.0000105507084758,
      2.0000211014169516,
      1.0000105507084758,
      1.0000105507084758,
      7.00007385495933,
      6.000063304250855,
      6.000063304250855,
      6.000063304250855,
      6.000063304250855,
      5.000052753542379,
      4.000042202833903,
      4.000042202833903,
      4.000042202833903,
      4.000042202833903,
      6.000063304250855,
      5.000052753542379,
      6.0001056772721135,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      5.000088064393427,
      1.0000176128786855,
      15.000264193180282,
      12.000211354544227,
      10.000176128786855,
      6.0001056772721135,
      5.000088064393427,
      4.000070451514742,
      4.000070451514742,
      4.000070451514742,
      5.000088064393427,
      4.000070451514742,
      4.000070451514742,
    ],
    Term: [
      "disha",
      "protest",
      "ravi",
      "disha_ravi",
      "bengal",
      "west",
      "west_bengal",
      "adani",
      "kejriwal",
      "indian",
      "specific",
      "daughter",
      "figure",
      "mock",
      "cartoon",
      "october",
      "ben",
      "social",
      "legislative",
      "garrison",
      "west_bengal",
      "bengal",
      "west",
      "date",
      "bhagat",
      "bhagat_singh",
      "announce",
      "poll",
      "election",
      "sentence",
      "singh",
      "day",
      "death",
      "crown",
      "canal",
      "collect",
      "atlantic",
      "hotel",
      "patanjali",
      "temple",
      "levy",
      "related",
      "centre",
      "adani",
      "indian_oil",
      "oil",
      "sell",
      "gas",
      "adani_gas",
      "group",
      "adani_group",
      "abu",
      "dhabi",
      "state",
      "indian",
      "corporation",
      "hindu",
      "debunk",
      "rally",
      "stage",
      "support",
      "narendra",
      "employee",
      "recently",
      "minor",
      "islamic",
      "bomb",
      "protest",
      "kolkata",
      "amit_shah",
      "amit",
      "fuel",
      "law",
      "nadu",
      "tamil",
      "bajrang_dal",
      "bajrang",
      "tamil_nadu",
      "dal",
      "shah",
      "farmer",
      "price",
      "targets",
      "sachin_tendulkar",
      "next",
      "images",
      "exist",
      "rana",
      "lawyer",
      "priyanka",
      "girl",
      "vadra",
      "kejriwal",
      "sachin",
      "tendulkar",
      "arvind_kejriwal",
      "arvind",
      "jacob",
      "nikita_jacob",
      "nikita",
      "reply",
      "aap",
      "tweet",
      "say",
      "name",
      "clash",
      "cause",
      "many",
      "april",
      "yo",
      "resume",
      "trail",
      "joseph",
      "sinha",
      "disha",
      "ravi",
      "disha_ravi",
      "case",
      "earthquake",
      "rinku_sharma",
      "sharma",
      "toolkit_case",
      "link",
      "toolkit",
      "rinku",
    ],
    Total: [
      15.0,
      13.0,
      12.0,
      10.0,
      8.0,
      8.0,
      8.0,
      6.0,
      7.0,
      8.0,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      1.0000377880575544,
      8.000302304460435,
      8.000302304460435,
      8.000302304460435,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      4.0001511522302176,
      6.000206553166458,
      5.000168765108903,
      0.9999254864868459,
      0.9999254864868459,
      0.9999254864868459,
      0.9999254864868459,
      2.999776459460538,
      0.9999254864868459,
      1.9998509729736917,
      0.9999254864868459,
      0.9999254864868459,
      0.9999254864868459,
      6.999478405407922,
      5.999552918921076,
      5.999552918921076,
      3.9997019459473835,
      3.9997019459473835,
      3.9997019459473835,
      2.999776459460538,
      2.999776459460538,
      2.999776459460538,
      2.999776459460538,
      2.999776459460538,
      8.999485094718082,
      2.999776459460538,
      4.999719558826069,
      1.0000033446550798,
      4.000013378620319,
      4.000013378620319,
      2.0000066893101596,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      1.0000033446550798,
      13.000043480516037,
      7.000023412585558,
      6.000020067930479,
      6.000020067930479,
      5.000016723275399,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      4.000013378620319,
      7.000030618638955,
      7.000037680809164,
      5.999942209762245,
      1.0000105507084758,
      6.000063304250855,
      1.0000105507084758,
      1.0000105507084758,
      1.0000105507084758,
      5.000052753542379,
      1.0000105507084758,
      2.0000211014169516,
      1.0000105507084758,
      1.0000105507084758,
      7.00007385495933,
      6.000063304250855,
      6.000063304250855,
      6.000063304250855,
      6.000063304250855,
      5.000052753542379,
      4.000042202833903,
      4.000042202833903,
      4.000042202833903,
      4.000042202833903,
      7.00008091712954,
      5.9999782400292245,
      6.0001056772721135,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      1.0000176128786855,
      5.000088064393427,
      1.0000176128786855,
      15.000264193180282,
      12.000211354544227,
      10.000176128786855,
      6.0001056772721135,
      5.000088064393427,
      4.000070451514742,
      4.000070451514742,
      4.000070451514742,
      6.000091409048507,
      6.000091552931694,
      4.000070451514742,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.7969,
      1.6145,
      1.5737,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.7371,
      1.4857,
      1.7371,
      1.5139,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.4508,
      1.2966,
      1.2966,
      1.2685,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.6218,
      1.4676,
      1.4395,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.4863,
      1.304,
      1.0808,
      1.4863,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.0876,
      -5.0876,
      -5.0876,
      -5.0876,
      -5.0876,
      -5.0876,
      -5.0876,
      -5.0876,
      -5.0876,
      -5.0876,
      -3.0082,
      -3.0082,
      -3.0082,
      -3.7013,
      -3.7013,
      -3.7013,
      -3.7013,
      -3.7013,
      -3.7013,
      -3.7013,
      -3.7013,
      -3.4782,
      -3.7013,
      -5.1475,
      -5.1475,
      -5.1475,
      -5.1475,
      -4.0489,
      -5.1475,
      -4.4543,
      -5.1475,
      -5.1475,
      -5.1475,
      -3.2016,
      -3.3557,
      -3.3557,
      -3.7612,
      -3.7612,
      -3.7612,
      -4.0489,
      -4.0489,
      -4.0489,
      -4.0489,
      -4.0489,
      -3.2016,
      -4.0489,
      -3.7612,
      -5.4337,
      -4.0474,
      -4.0474,
      -4.7406,
      -5.4337,
      -5.4337,
      -5.4337,
      -5.4337,
      -5.4337,
      -5.4337,
      -2.8688,
      -3.4878,
      -3.642,
      -3.642,
      -3.8243,
      -4.0474,
      -4.0474,
      -4.0474,
      -4.0474,
      -4.0474,
      -4.0474,
      -4.0474,
      -3.642,
      -3.642,
      -3.8243,
      -5.2627,
      -3.4709,
      -5.2627,
      -5.2627,
      -5.2627,
      -3.6533,
      -5.2627,
      -4.5695,
      -5.2627,
      -5.2627,
      -3.3168,
      -3.4709,
      -3.4709,
      -3.4709,
      -3.4709,
      -3.6533,
      -3.8764,
      -3.8764,
      -3.8764,
      -3.8764,
      -3.4709,
      -3.6533,
      -3.6064,
      -5.3982,
      -5.3982,
      -5.3982,
      -5.3982,
      -5.3982,
      -5.3982,
      -5.3982,
      -3.7887,
      -5.3982,
      -2.6901,
      -2.9133,
      -3.0956,
      -3.6064,
      -3.7887,
      -4.0119,
      -4.0119,
      -4.0119,
      -3.7887,
      -4.0119,
      -4.0119,
    ],
  },
  "token.table": {
    Topic: [
      4,
      2,
      2,
      2,
      2,
      3,
      3,
      1,
      5,
      4,
      4,
      2,
      3,
      3,
      1,
      1,
      1,
      1,
      3,
      2,
      1,
      5,
      5,
      2,
      5,
      2,
      2,
      2,
      3,
      1,
      1,
      1,
      5,
      1,
      5,
      3,
      2,
      5,
      5,
      5,
      1,
      3,
      4,
      3,
      5,
      1,
      3,
      1,
      2,
      4,
      2,
      2,
      5,
      2,
      4,
      2,
      3,
      2,
      3,
      4,
      5,
      4,
      3,
      3,
      4,
      1,
      2,
      3,
      5,
      5,
      3,
      1,
      3,
      5,
      3,
      4,
      4,
      4,
      1,
      2,
      2,
      1,
      2,
      3,
      4,
      3,
      3,
      4,
      5,
      3,
      2,
      4,
      5,
      5,
      5,
      4,
      4,
      2,
      4,
      2,
      1,
      3,
      4,
      5,
      1,
      5,
      1,
      1,
      3,
      2,
      3,
      3,
      3,
      4,
      2,
      4,
      4,
      5,
      5,
      5,
      4,
      5,
      4,
      1,
      1,
      5,
    ],
    Freq: [
      0.9999894494028405,
      1.0000745190658316,
      1.0000745190658313,
      1.0000745190658316,
      1.0000745190658316,
      0.9999966553561068,
      0.9999966553561068,
      0.999962213370329,
      0.9999823874315226,
      0.9999894494028405,
      0.9999894494028405,
      1.0000745190658316,
      0.9999966553561069,
      0.9999966553561069,
      0.999962213370329,
      0.999962213370329,
      0.999962213370329,
      0.999962213370329,
      0.9999966553561069,
      1.0000745190658316,
      0.999962213370329,
      0.9999823874315225,
      0.9999823874315226,
      1.0000745190658316,
      0.9999823874315226,
      1.0000745190658316,
      1.0000745190658316,
      1.0000745190658316,
      0.9999966553561069,
      0.999962213370329,
      0.999962213370329,
      0.833304646381111,
      0.1666609292762222,
      0.799972998493958,
      0.1999932496234895,
      0.9999966553561069,
      1.0000745190658316,
      0.9999823874315226,
      0.9999823874315226,
      0.9999823874315226,
      0.999962213370329,
      0.9999966553561069,
      0.9999894494028405,
      0.8571382431910616,
      0.1428563738651769,
      0.999962213370329,
      0.9999966553561068,
      0.999962213370329,
      1.0000745190658316,
      0.9999894494028405,
      1.0000745190658316,
      0.8000448731046822,
      0.20001121827617055,
      1.0000745190658316,
      0.9999894494028405,
      0.7778222783110551,
      0.22223493666030145,
      1.0000745190658316,
      0.9999966553561069,
      0.9999894494028405,
      0.9999823874315226,
      0.9999894494028405,
      0.9999966553561069,
      0.9999966553561069,
      0.9999894494028405,
      0.999962213370329,
      1.0000745190658316,
      0.16666412756511317,
      0.8333206378255658,
      0.9999823874315226,
      0.9999966553561069,
      0.999962213370329,
      0.9999966553561069,
      0.9999823874315225,
      0.9999966553561069,
      0.9999894494028405,
      0.9999894494028405,
      0.9999894494028405,
      0.999962213370329,
      1.0000745190658316,
      1.0000745190658316,
      0.999962213370329,
      0.16666827196651054,
      0.8333413598325526,
      0.9999894494028405,
      0.9999966553561069,
      0.9999966553561069,
      0.9999894494028405,
      0.9999823874315225,
      0.9999966553561069,
      1.0000745190658316,
      0.9999894494028405,
      0.9999823874315226,
      0.9999823874315226,
      0.9999823874315226,
      0.9999894494028405,
      0.9999894494028405,
      0.16666727111249144,
      0.8333363555624572,
      1.0000745190658316,
      0.999962213370329,
      0.85713910793816,
      0.14285651798969334,
      0.9999823874315226,
      0.999962213370329,
      0.9999823874315226,
      0.999962213370329,
      0.999962213370329,
      0.9999966553561069,
      1.0000745190658316,
      0.9999966553561069,
      0.9999966553561069,
      0.9999966553561069,
      0.9999894494028405,
      1.0000745190658316,
      0.9999894494028405,
      0.33332824713695974,
      0.6666564942739195,
      0.9999823874315226,
      0.9999823874315226,
      0.8571329490374471,
      0.1428554915062412,
      0.9999894494028405,
      0.999962213370329,
      0.999962213370329,
      0.9999823874315226,
    ],
    Term: [
      "aap",
      "abu",
      "adani",
      "adani_gas",
      "adani_group",
      "amit",
      "amit_shah",
      "announce",
      "april",
      "arvind",
      "arvind_kejriwal",
      "atlantic",
      "bajrang",
      "bajrang_dal",
      "ben",
      "bengal",
      "bhagat",
      "bhagat_singh",
      "bomb",
      "canal",
      "cartoon",
      "case",
      "cause",
      "centre",
      "clash",
      "collect",
      "corporation",
      "crown",
      "dal",
      "date",
      "daughter",
      "day",
      "day",
      "death",
      "death",
      "debunk",
      "dhabi",
      "disha",
      "disha_ravi",
      "earthquake",
      "election",
      "employee",
      "exist",
      "farmer",
      "farmer",
      "figure",
      "fuel",
      "garrison",
      "gas",
      "girl",
      "group",
      "hindu",
      "hindu",
      "hotel",
      "images",
      "indian",
      "indian",
      "indian_oil",
      "islamic",
      "jacob",
      "joseph",
      "kejriwal",
      "kolkata",
      "law",
      "lawyer",
      "legislative",
      "levy",
      "link",
      "link",
      "many",
      "minor",
      "mock",
      "nadu",
      "name",
      "narendra",
      "next",
      "nikita",
      "nikita_jacob",
      "october",
      "oil",
      "patanjali",
      "poll",
      "price",
      "price",
      "priyanka",
      "protest",
      "rally",
      "rana",
      "ravi",
      "recently",
      "related",
      "reply",
      "resume",
      "rinku",
      "rinku_sharma",
      "sachin",
      "sachin_tendulkar",
      "say",
      "say",
      "sell",
      "sentence",
      "shah",
      "shah",
      "sharma",
      "singh",
      "sinha",
      "social",
      "specific",
      "stage",
      "state",
      "support",
      "tamil",
      "tamil_nadu",
      "targets",
      "temple",
      "tendulkar",
      "toolkit",
      "toolkit",
      "toolkit_case",
      "trail",
      "tweet",
      "tweet",
      "vadra",
      "west",
      "west_bengal",
      "yo",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/west-bengal-election-dates-2021-announced-poll-schedule-mamata-banerjee-tmc-bjp-11991",
        headline:
          "Viral Graphic Shares 2019 Lok Sabha Schedule As West Bengal Poll Dates",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/bjp-workers-pelting-stones-in-west-bengal-video-fake-news-fact-check-elections-12076",
        headline:
          "Video Of BJP Workers Pelting Stones In Telangana Viral As West Bengal",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/mamata-banerjee-amar-bari-prokolpo-housing-project-flat-for-poor-bangladesh-west-bengal-assembly-elections-2021-12029",
        headline:
          "\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6\u09c7\u09b0 \u0986\u09ac\u09be\u09b8\u09a8 \u09aa\u09cd\u09b0\u0995\u09b2\u09cd\u09aa\u09c7\u09b0 \u099b\u09ac\u09bf \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7\u09b0 \u09ac\u09b2\u09c7 \u099a\u09be\u09b2\u09be\u09a8\u09cb \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/no-this-image-does-not-show-virat-kohli-and-vamika-anushkha-sharmas-daughter-touching-their-hands-11992",
        headline:
          "\u098f\u0987 \u09ac\u09bf\u09b0\u09be\u099f \u0995\u09cb\u09b9\u09be\u09b2\u09bf \u0993 \u09a4\u09be\u0981\u09b0 \u0995\u09a8\u09cd\u09af\u09be \u09ad\u09be\u09ae\u09bf\u0995\u09be\u09b0 \u09ae\u09c1\u09a0\u09cb-\u09b9\u09be\u09a4\u09c7\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/west-bengal-assembly-election-dates-2021-announced-poll-schedule-mamata-banerjee-tmc-bjp-12002",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09b2\u09cb\u0995\u09b8\u09ad\u09be \u09ad\u09cb\u099f\u09c7\u09b0 Graphic \u099b\u09a1\u09bc\u09be\u09b2 West Bengal \u09ad\u09cb\u099f\u09c7\u09b0 \u09a6\u09bf\u09a8 \u0998\u09cb\u09b7\u09a3\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/17/fact-check-have-west-bengal-assembly-election-dates-been-announced-heres-the-truth/",
        headline:
          "Fact Check: Have West Bengal Assembly Election Dates Been Announced? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/18/fact-check-govt-has-not-announced-a-specific-date-for-self-registration-on-co-win-app-for-covid-19-vaccine/",
        headline:
          "Fact Check: Govt Has NOT Announced A Specific Date For Self-Registration On Co-WIN App For COVID-19 Vaccine",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/19/fact-check-bhagat-singh-sukhdev-rajguru-were-not-sentenced-to-death-on-february-14-heres-the-truth/",
        headline:
          "Fact Check: Bhagat Singh, Sukhdev, Rajguru Were Not Sentenced To Death On February 14; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/every-weekday-repeats-exactly-four-times-in-every-non-leap-year-month-of-february/",
        headline:
          " Every weekday repeats exactly four times in every non-leap year month of February",
      },
      {
        url:
          "https://factly.in/telugu-bhagat-singh-was-neither-hanged-on-valentines-day-nor-was-his-death-sentence-pronounced-on-that-day/",
        headline:
          " \u0c2d\u0c17\u0c24\u0c4d \u0c38\u0c3f\u0c02\u0c17\u0c4d\u200c\u0c15\u0c3f \u0c09\u0c30\u0c3f \u0c36\u0c3f\u0c15\u0c4d\u0c37 \u0c35\u0c47\u0c38\u0c4d\u0c24\u0c42 \u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41 \u0c24\u0c40\u0c30\u0c4d\u0c2a\u0c41 \u0c07\u0c1a\u0c4d\u0c1a\u0c3f\u0c02\u0c26\u0c3f 07 \u0c05\u0c15\u0c4d\u0c1f\u0c4b\u0c2c\u0c30\u0c4d 1930\u0c28, \u0c09\u0c30\u0c3f\u0c24\u0c40\u0c38\u0c3f\u0c02\u0c26\u0c3f 23 \u0c2e\u0c3e\u0c30\u0c4d\u0c1a\u0c3f 1931\u0c28; \u2018\u0c35\u0c3e\u0c32\u0c46\u0c02\u0c1f\u0c48\u0c28\u0c4d\u0c38\u0c4d \u0c21\u0c47\u2019 \u0c30\u0c4b\u0c1c\u0c41\u0c28 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-india-ranks-164th-in-gdp-growth-rate-for-the-year-2020-not-in-total-gdp-value/",
        headline:
          " 2020 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c17\u0c3e\u0c28\u0c41 GDP \u0c35\u0c43\u0c26\u0c4d\u0c26\u0c3f \u0c30\u0c47\u0c1f\u0c41\u0c32\u0c4b \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c02 164 \u0c38\u0c4d\u0c25\u0c3e\u0c28\u0c02\u0c32\u0c4b \u0c09\u0c02\u0c26\u0c3f, \u0c2e\u0c4a\u0c24\u0c4d\u0c24\u0c02 GDP \u0c35\u0c3f\u0c32\u0c41\u0c35\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/ben-garrison-did-not-create-this-cartoon-mocking-seven-years-of-bjp-rule/",
        headline:
          " Ben Garrison did not create this cartoon mocking seven years of BJP rule",
      },
      {
        url:
          "https://factly.in/inaccurate-figures-shared-as-population-growth-rate-of-various-religions-in-kerala/",
        headline:
          " Inaccurate figures shared as population growth rate of various religions in Kerala",
      },
      {
        url:
          "https://factly.in/bhagat-singh-was-neither-hanged-on-valentines-day-nor-was-his-death-sentence-pronounced-on-that-day/",
        headline:
          " Bhagat Singh was neither hanged on Valentines Day nor was his death sentence pronounced on that day",
      },
      {
        url:
          "https://factly.in/2019-general-election-schedule-is-passed-off-as-west-bengal-2020-assembly-polls/",
        headline:
          " 2019 Lok Sabha General Election schedule is passed off as that of 2021 West Bengal assembly polls",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-fake-west-bengal-election-schedule-circulates-on-social-media-1770180-2021-02-17",
        headline:
          "Fact Check: Fake West Bengal election schedule circulates on social media",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bhagat-singh-sukhdev-rajguru-not-sentenced-to-death-on-valentines-day-674250",
        headline:
          "Fact Check: Bhagat Singh, Sukhdev, Rajguru not sentenced to death on Valentine's Day",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-west-bengal-legislative-assembly-election-dates-have-not-been-announced-674321",
        headline:
          "FACT CHECK: No, West Bengal legislative assembly election dates have not been announced",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/viral/fact-check-this-post-regarding-february-month-is-not-true/?itm_source=homepage&itm_medium=dktp_s3&itm_campaign=editorpick",
        headline:
          "Fact Check: \u098f\u0987 \u09ac\u099b\u09f0 \u09ab\u09c7\u09ac\u09cd\u09f0\u09c1\u09f1\u09be\u09f0\u09c0 \u09ae\u09be\u09b9\u099f\u09cb\u0995 \u0985\u09a4\u09cd\u09af\u09a8\u09cd\u09a4 \u09ac\u09bf\u09b6\u09c7\u09b7 \u09ac\u09c1\u09b2\u09bf \u0985\u09ad\u09bf\u09b9\u09bf\u09a4 \u0995\u09f0\u09be \u098f\u0987 \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f \u09ad\u09c1\u09f1\u09be",
      },
    ],
    "2": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/pydhonie-terrorist-mumbai-police-video-fake-news-pakistani-khalistani-terrorist-fact-check-12040",
        headline:
          "Web Series Shoot Peddled As Terrorist Nabbed In Pydhonie, Mumbai",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/indian-oil-sold-to-adani-fake-news-fact-check-petrol-price-hike-fuel-prices-privitisation-government-gautam-adani-psus-12027",
        headline: "No, Indian Oil Corporation Has Not Been Sold To Adani Group",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/saraswati-puja-ban-west-bengal-viral-video-fake-news-fact-check-12055",
        headline:
          "\u0938\u0930\u0938\u094d\u0935\u0924\u0940 \u092a\u0942\u091c\u093e \u0938\u0947 \u091c\u0941\u095c\u0947 \u0907\u0938 \u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/indian-oil-adani-gas-joint-venture-fake-news-city-gas-distribution-bidding-tender-allotment-fact-check-12032",
        headline:
          "\u0915\u094d\u092f\u093e \u0907\u0902\u0921\u093f\u092f\u0928 \u0906\u092f\u0932 \u0915\u0949\u0930\u094d\u092a\u094b\u0930\u0947\u0936\u0928 \u0915\u094b \u0905\u0921\u093e\u0928\u0940 \u0917\u0948\u0938 \u0928\u0947 \u0916\u0930\u0940\u0926 \u0932\u093f\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/pydhonie-terrorist-mumbai-police-video-fake-news-pakistani-khalistani-terrorist-fact-check-12065",
        headline:
          "\u0993\u09df\u09c7\u09ac \u09b8\u09bf\u09b0\u09bf\u099c\u09c7\u09b0 \u09b6\u09c1\u099f\u09bf\u0982\u09df\u09c7\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09ae\u09c1\u09ae\u09cd\u09ac\u0987\u09df\u09c7\u09b0 \u0989\u0997\u09cd\u09b0\u09aa\u09a8\u09cd\u09a5\u09c0 \u0986\u099f\u0995 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-nita-ambani-reliance-ram-mandir-donation-ayodhya-uttar-pradesh-gold-crown-factcheck-12060",
        headline:
          "Nita Ambani Donates Three Gold Crowns For Ram Mandir? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/false-claims-go-viral-about-patanjalis-coronil-approved-by-who-12061",
        headline:
          "False Claims Go Viral About Patanjali's Coronil Approved By WHO",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-indian-oil-adani-gas-fake-news-petrol-price-hike-fuel-prices-privitisation-government-gautam-adani-psus-12049",
        headline:
          "\u09a8\u09be, \u0986\u09a6\u09be\u09a8\u09bf \u0997\u09cd\u09b0\u09c1\u09aa\u0995\u09c7 \u0987\u09a8\u09cd\u09a1\u09bf\u09df\u09be\u09a8 \u0985\u09df\u09c7\u09b2 \u0995\u09b0\u09cd\u09aa\u09cb\u09b0\u09c7\u09b6\u09a8 \u09ac\u09c7\u099a\u09c7 \u09a6\u09c7\u0993\u09df\u09be \u09b9\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/17/fact-check-were-gold-coins-found-while-digging-a-canal-in-baramulla-heres-the-truth/",
        headline:
          "Fact Check: Were Gold Coins Found While Digging A Canal In Baramulla? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/17/fact-check-has-govt-of-india-sold-indian-oil-to-adani-group-heres-the-truth/",
        headline:
          "Fact Check: Has Govt Of India Sold Indian Oil To Adani Group? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/this-price-buildup-showing-state-government-collects-more-tax-on-petrol-than-center-is-inaccurate/",
        headline:
          " This price buildup saying State government collects more tax on Petrol than the Centre is inaccurate",
      },
      {
        url:
          "https://factly.in/indian-oil-adani-gas-private-limited-ioagpl-is-a-joint-venture-incorporated-in-2013/",
        headline:
          " \u2018Indian Oil \u2013 Adani Gas Private Limited (IOAGPL)\u2019 is a joint venture incorporated in 2013",
      },
      {
        url:
          "https://factly.in/telugu-indian-oil-adani-gas-private-limited-ioagpl-is-a-joint-venture-incorporated-in-2013/",
        headline:
          " \u2018\u0c07\u0c02\u0c21\u0c3f\u0c2f\u0c28\u0c4d \u0c06\u0c2f\u0c3f\u0c32\u0c4d \u2013 \u0c05\u0c26\u0c3e\u0c28\u0c40 \u0c17\u0c4d\u0c2f\u0c3e\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c48\u0c35\u0c47\u0c1f\u0c4d \u0c32\u0c3f\u0c2e\u0c3f\u0c1f\u0c46\u0c21\u0c4d (IOAGPL)\u2019 \u0c05\u0c28\u0c47\u0c26\u0c3f 2013 \u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c3e\u0c30\u0c02\u0c2d\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c12\u0c15 \u0c1c\u0c3e\u0c2f\u0c3f\u0c02\u0c1f\u0c4d \u0c35\u0c46\u0c02\u0c1a\u0c30\u0c4d",
      },
      {
        url:
          "https://factly.in/telugu-this-video-shows-emirates-palace-hotel-in-abu-dhabi-not-a-hindu-temple/",
        headline:
          " \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c05\u0c2c\u0c41 \u0c27\u0c3e\u0c2c\u0c3f \u0c32\u0c4b\u0c28\u0c3f \u0c0e\u0c2e\u0c3f\u0c30\u0c47\u0c1f\u0c4d\u0c38\u0c4d \u0c2a\u0c3e\u0c32\u0c38\u0c4d \u0c39\u0c4b\u0c1f\u0c32\u0c4d; \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c06\u0c32\u0c2f\u0c02 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/old-religious-event-at-a-hotel-in-abu-dhabi-portrayed-as-visuals-of-first-hindu-temple-in-abu-dhabi/",
        headline:
          " Old religious event at a Hotel in Abu Dhabi portrayed as visuals of first Hindu temple in Abu Dhabi",
      },
      {
        url:
          "https://factly.in/telugu-no-truth-to-the-argument-that-states-collect-more-tax-on-petrol-than-centre/",
        headline:
          " \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d, \u0c21\u0c40\u0c1c\u0c3f\u0c32\u0c4d \u0c2a\u0c48 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c3e\u0c32\u0c41 \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c15\u0c28\u0c4d\u0c28\u0c3e \u0c0e\u0c15\u0c4d\u0c15\u0c41\u0c35 \u0c1f\u0c3e\u0c15\u0c4d\u0c38\u0c4d \u0c35\u0c38\u0c42\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c26\u0c28\u0c32\u0c4b \u0c28\u0c3f\u0c1c\u0c02 \u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/this-post-falsely-claims-that-the-police-were-beaten-by-muslims-for-issuing-a-challan/",
        headline:
          " This post falsely claims that the police were beaten by Muslims for issuing a challan.",
      },
      {
        url:
          "https://factly.in/telugu-the-sc-did-not-issue-any-order-to-government-for-granting-minority-status-to-hindus/",
        headline:
          " \u0c39\u0c3f\u0c02\u0c26\u0c41 \u0c1c\u0c28\u0c3e\u0c2d\u0c3e \u0c24\u0c15\u0c4d\u0c15\u0c41\u0c35\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c32\u0c32\u0c4b \u0c39\u0c3f\u0c02\u0c26\u0c41\u0c35\u0c41\u0c32\u0c15\u0c3f \u0c2e\u0c48\u0c28\u0c3e\u0c30\u0c3f\u0c1f\u0c40 \u0c38\u0c4d\u0c1f\u0c47\u0c1f\u0c38\u0c4d \u0c15\u0c32\u0c4d\u0c2a\u0c3f\u0c02\u0c1a\u0c2e\u0c28\u0c3f \u0c38\u0c41\u0c2a\u0c4d\u0c30\u0c40\u0c02\u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41 \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c4d\u0c35\u0c41 \u0c1c\u0c3e\u0c30\u0c3f \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-true-trump-plaza-hotel-was-razed-in-atlantic-city-674456",
        headline:
          "Fact check: True, Trump Plaza Hotel was razed in Atlantic City",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/ccmb-scientists-raise-red-flag-over-novel-variants-of-the-sars-cov-2-674439",
        headline:
          "CCMB scientists raise red flag over novel variants of the SARS-CoV-2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-ioc-has-not-been-sold-to-adani-gas-674376",
        headline: "Fact check: No, IOC has not been sold to Adani Gas",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-green-flag-in-viral-image-is-indian-union-muslim-league-ensign-not-pakistani-674407",
        headline:
          "Fact check: Green flag in viral image is Indian Union Muslim League ensign, not Pakistani",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-farmers-protest-tractor-rally-germany-delhi-11995",
        headline:
          "Old Pic Peddled As Tractor Rally In Germany To Support Indian Farmers",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/amit-shah-kolkata-west-bengal-elections-2021-viral-video-falling-from-a-stage-madhya-pradesh-election-rally-12038",
        headline:
          "Old Video Of Amit Shah Falling From A Stage In MP Shared As Kolkata",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/germany-petrol-price-hike-people-protest-car-strike-on-road-12028",
        headline:
          "\u0915\u094d\u092f\u093e \u091c\u0930\u094d\u092e\u0928\u0940 \u092e\u0947\u0902 \u092a\u0947\u091f\u094d\u0930\u094b\u0932 \u0915\u0947 \u0926\u093e\u092e \u092c\u095d\u0928\u0947 \u092a\u0930 \u0932\u094b\u0917 \u0938\u095c\u0915\u094b\u0902 \u092a\u0930 \u0939\u0940 \u091b\u094b\u095c \u0917\u090f \u0905\u092a\u0928\u0940 \u0915\u093e\u0930?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-clip-bajrang-dal-krishi-farm-laws-uttar-pradesh-bangar-shivsena-hingoli-pm-modi-kisan-andolan-12054",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0915\u093e \u0926\u093e\u0935\u093e, \u092c\u091c\u0930\u0902\u0917 \u0926\u0932 \u0928\u0947 \u092f\u0942\u092a\u0940 \u092e\u0947\u0902 \u0915\u0943\u0937\u093f \u0958\u093e\u0928\u0942\u0928 \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-lic-trade-union-protest-against-bjp-government-nirmala-sitharaman-lic-ipo-lic-stake-sale-12066",
        headline:
          "LIC \u0987\u0989\u09a8\u09bf\u09df\u09a8\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-farm-laws-farmers-protest-bajrang-dal-uttar-pradesh-bjp-narendra-modi-viral-clip-protest-shiv-sena-fuel-price-hike-factcheck-12052",
        headline:
          "Shiv Sena Fuel Hike Protest Peddled As Bajrang Dal Protesting Farm Laws",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/farmers-protest-tractor-rally-germany-delhi-kisan-andolan-11998",
        headline:
          "\u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u091c\u0930\u094d\u092e\u0928\u0940 \u092e\u0947\u0902 \u092d\u093e\u0930\u0924\u0940\u092f \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0915\u0947 \u0938\u092e\u0930\u094d\u0925\u0928 \u092e\u0947\u0902 \u091f\u094d\u0930\u0948\u0915\u094d\u091f\u0930 \u0930\u0948\u0932\u0940 \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/go-back-modi-on-road-esplanade-kolkata-fake-news-narendra-modi-tamilnadu-kerala-visit-11977",
        headline:
          "\u0930\u094b\u0921 \u092a\u0930 '\u0917\u094b \u092c\u0948\u0915 \u092e\u094b\u0926\u0940' \u0915\u093e \u0928\u093e\u0930\u093e \u0926\u093f\u0916\u093e\u0924\u0940 \u092f\u0939 \u095e\u094b\u091f\u094b \u0924\u092e\u093f\u0932\u0928\u093e\u0921\u0941 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/photo-of-up-suicide-victim-shared-as-minor-murdered-in-kolkata-11982",
        headline:
          "\u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u0986\u09a4\u09cd\u09ae\u09b9\u09a8\u09a8\u09c7\u09b0 \u0998\u099f\u09a8\u09be\u09b0 \u099b\u09ac\u09bf \u0995\u09b2\u0995\u09be\u09a4\u09be\u09df \u09a8\u09be\u09ac\u09be\u09b2\u09bf\u0995\u09be \u0996\u09c1\u09a8 \u09ac\u09b2\u09c7 \u099a\u09be\u09b2\u09be\u09a8\u09cb \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/amit-shah-kolkata-west-bengal-elections-2021-viral-video-falling-from-a-stage-madhya-pradesh-election-rally-12057",
        headline:
          "\u09ae\u09a7\u09cd\u09af\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09b8\u09ad\u09be\u09ae\u099e\u09cd\u099a\u09c7 Amit Shah-\u09b0 \u09aa\u09be \u09b9\u09a1\u09bc\u0995\u09be\u09a8\u09cb\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09b2\u0995\u09be\u09a4\u09be\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/morphed-image-of-narendra-modi-and-amit-shah-viral-wearing-skull-cap-revived-ahead-of-west-bengal-assembly-poll-12035",
        headline:
          "Nadrendra Modi \u0993 Amit Shah \u09ae\u09be\u09a5\u09be\u09df \u0987\u09b8\u09b2\u09be\u09ae\u09c0\u09df \u099f\u09c1\u09aa\u09bf \u09aa\u09b0\u09c7\u099b\u09c7\u09a8? \u09a8\u09be \u09a4\u09be \u09a0\u09bf\u0995 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-pastor-to-farmer-tn-priests-images-viral-with-fake-claim-11983",
        headline:
          "\u09aa\u09be\u09a6\u09cd\u09b0\u09c0 \u09a5\u09c7\u0995\u09c7 \u0995\u09c3\u09b7\u0995? \u09a4\u09be\u09ae\u09bf\u09b2\u09a8\u09be\u09a1\u09bc\u09bc\u09c1\u09b0 \u09aa\u09be\u09a6\u09cd\u09b0\u09c0\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/fact-check-old-picture-of-anti-caa-protest-in-kolkata-passed-off-as-recent-one-from-tamil-nadu/",
        headline:
          "Fact Check: Old Picture Of Anti-CAA Protest In Kolkata Circulated As Recent One From Tamil Nadu",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/fact-check-this-video-of-protest-by-lic-employees-is-from-2020-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Protest By LIC employees Is From 2020; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/16/fact-check-2019-pictures-of-farmers-protest-in-berlin-shared-as-recent/",
        headline:
          "Fact Check: 2019 Pictures Of Farmers\u2019 Protest In Berlin Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/16/fact-check-old-video-of-amit-shah-falling-off-stage-shared-with-false-claims/",
        headline:
          "Fact Check: Old Video Of Amit Shah Falling Off Stage Shared With False Claims",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/19/fact-check-viral-message-claiming-india-will-do-it-file-will-hack-your-phones-is-hoax/",
        headline:
          "Fact Check: Viral Message Claiming \u2018India Will Do It\u2019 File Will Hack Your Phones Is Hoax!",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/19/fact-check-was-pm-modi-wearing-a-pashmina-shawl-worth-rs-2-lakh-heres-the-truth/",
        headline:
          "Fact Check: Was PM Modi Wearing A Pashmina Shawl Worth Rs 2 Lakh? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/old-photo-of-go-back-modi-slogan-imprinted-on-a-kolkata-street-shared-as-that-from-tamil-nadu/",
        headline:
          " Old photo of \u2018Go Back Modi\u2019 slogan on a Kolkata street shared as that from Tamil Nadu",
      },
      {
        url:
          "https://factly.in/old-debunked-video-revived-again-as-the-visuals-of-2019-pulwama-terror-attack/",
        headline:
          " Old debunked video revived again as visuals of 2019 Pulwama terror attack",
      },
      {
        url:
          "https://factly.in/photo-of-a-traffic-jam-in-china-revived-again-as-german-fuel-protest/",
        headline:
          " Photo of a Traffic Jam in China revived again as German fuel protest",
      },
      {
        url:
          "https://factly.in/german-farmers-protests-are-being-shared-with-misleading-claims-linking-them-to-india/",
        headline:
          " German farmers\u2019 protests are being shared with misleading claims linking them to India",
      },
      {
        url:
          "https://factly.in/old-video-of-amit-shah-slipping-off-a-chariot-in-madhya-pradesh-is-passed-off-as-that-from-kolkata/",
        headline:
          " Old video of Amit Shah slipping off a chariot in Madhya Pradesh is passed off as that from Kolkata",
      },
      {
        url:
          "https://factly.in/this-post-misleads-by-just-listing-the-lpg-prices-and-not-mentioning-the-international-fob-prices-of-lpg/",
        headline:
          " This post misleads by just listing the LPG prices and not mentioning the international FOB prices of LPG.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-image-of-traffic-jam-in-china-shared-as-german-protest-against-fuel-price-hike-1769538-2021-02-15",
        headline:
          "Fact Check: Image of traffic jam in China shared as German protest against fuel price hike",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-these-pictures-of-farmers-protest-in-berlin-are-over-a-year-old-1769412-2021-02-15",
        headline:
          "Fact Check: These pictures of farmers\u2019 protest in Berlin are over a year old",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-these-are-not-bajrang-dal-members-in-up-demanding-repeal-of-farm-laws-1770140-2021-02-17",
        headline:
          "Fact Check: These are not Bajrang Dal members in UP demanding repeal of farm laws",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-amit-shah-did-not-fall-off-stage-at-kolkata-rally-674417",
        headline:
          "Fact check: No, Amit Shah did not fall off stage at Kolkata rally",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-shiv-senas-protest-against-fuel-price-rise-passed-off-as-bajrang-dal-rally-against-farm-laws-674418",
        headline:
          "Fact check: Shiv Sena's protest against fuel price rise passed off as Bajrang Dal rally against farm laws",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-germans-did-not-abandon-their-cars-on-roads-to-protest-fuel-hike-674304",
        headline:
          "Fact Check: No, Germans did not abandon their cars on roads to protest fuel hike",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-iraq-car-bomb-explosion-passed-off-as-2019-pulwama-attack-674231",
        headline:
          "Fact Check: Iraq car bomb explosion passed off as 2019 Pulwama attack",
      },
    ],
    "4": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/dinesh-trivedi-death-hoax-made-with-fake-abp-ananda-graphic-by-habitual-offender-11976",
        headline:
          "Dinesh Trivedi \u09aa\u09cd\u09b0\u09df\u09be\u09a4 \u09ac\u09b2\u09c7 \u09ad\u09c1\u09df\u09cb ABP Ananda \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995 \u09b6\u09c7\u09df\u09be\u09b0 \u0998\u09be\u0998\u09c1 \u09a8\u09c7\u099f\u09bf\u099c\u09c7\u09a8\u09c7\u09b0",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-aap-arvind-kejriwal-viral-photo-nikita-jacob-toolkit-case-delhi-police-farmers-protest-aam-aadmi-party-11984",
        headline:
          "Photo Of AAP Member With Arvind Kejriwal Falsely Shared As Nikita Jacob",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/sachin-tendulkars-reply-to-journo-rana-ayyub-not-thats-fake-handle-11997",
        headline:
          "Sachin Tendulkar's Reply To Journo Rana Ayyub? No, That's Fake Handle",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/indian-army-demolished-chinese-bunker-pangong-tso-clash-itbp-rescue-operation-chamoli-uttarakhand-12064",
        headline:
          "\u0909\u0924\u094d\u0924\u0930\u093e\u0916\u0902\u0921 \u092e\u0947\u0902 \u0930\u0947\u0938\u094d\u0915\u094d\u092f\u0942 \u0911\u092a\u0930\u0947\u0936\u0928 \u0915\u0940 \u0935\u0940\u0921\u093f\u092f\u094b \u091a\u0940\u0928\u0940 \u092c\u0902\u0915\u0930 \u0927\u094d\u0935\u0938\u094d\u0924 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/sachin-tendulkar-journalist-rana-ayyub-twitter-rihanna-kisan-andolan-fake-news-12031",
        headline:
          "\u0938\u091a\u093f\u0928 \u0924\u0947\u0902\u0926\u0941\u0932\u0915\u0930 \u0928\u0947 \u0926\u093f\u092f\u093e \u0930\u093e\u0923\u093e \u0905\u092f\u094d\u092f\u0942\u092c \u0915\u094b \u091c\u0935\u093e\u092c? \u0928\u0939\u0940\u0902, \u092f\u0939 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948\u0902\u0921\u0932 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-cropped-clip-arvind-kejriwal-gujarat-threatening-gujaratis-viral-clip-aam-aadmi-party-bharatiya-janata-party-amit-shah-12079",
        headline:
          "\u0997\u09c1\u099c\u09b0\u09be\u099f\u09bf\u09a6\u09c7\u09b0 \u09b9\u09c1\u09ae\u0995\u09bf Arvind Kejriwal-\u098f\u09b0, \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-cropped-clip-arvind-kejriwal-gujarat-threatening-gujaratis-viral-clip-aam-aadmi-party-bharatiya-janata-party-amit-shah-12058",
        headline:
          "Edited Video Falsely Shared As Arvind Kejriwal Threatening Gujaratis",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/pangong-tso-lake-indian-army-chinese-army-uttarakhand-floods-12062",
        headline:
          "Video Of Uttarakhand Rescue Ops Viral As Army Destroying Chinese Bunkers",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/priyanka-gandhi-vadra-kumbh-mela-ganga-river-snan-movi-govt-fake-tweet-11981",
        headline:
          "\u0917\u0902\u0917\u093e \u0938\u094d\u0928\u093e\u0928 \u092a\u0930 \u0938\u0930\u0915\u093e\u0930\u0940 \u0916\u0930\u094d\u091a \u0915\u0940 \u0906\u0932\u094b\u091a\u0928\u093e \u0915\u0930\u0924\u093e \u092a\u094d\u0930\u093f\u092f\u0902\u0915\u093e \u0917\u093e\u0902\u0927\u0940 \u0915\u093e \u092f\u0939 \u091f\u094d\u0935\u0940\u091f \u095e\u0947\u0915 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/arvind-kejriwal-nikita-jacob-picture-aam-admi-party-farmers-protest-toolkit-delhi-police-11986",
        headline:
          "\u0905\u0930\u0935\u093f\u0902\u0926 \u0915\u0947\u091c\u0930\u0940\u0935\u093e\u0932 \u0915\u0947 \u0938\u093e\u0925 '\u0906\u092a' \u0938\u0926\u0938\u094d\u092f\u093e \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0928\u093f\u0915\u093f\u0924\u093e \u091c\u0948\u0915\u092c \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/video-from-2020-shared-as-alcohol-distributed-at-farmers-protest-12008",
        headline:
          "\u09e8\u09e6\u09e8\u09e6 \u09b8\u09be\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09b6\u09c7\u09df\u09be\u09b0 \u0995\u09b0\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u0995\u09c3\u09b7\u0995 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7 \u09ae\u09a6 \u09ac\u09bf\u09b2\u09bf \u0995\u09b0\u09be \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-aap-arvind-kejriwal-viral-photo-nikita-jacob-toolkit-case-delhi-police-farmers-protest-aam-aadmi-party-12041",
        headline:
          "Toolkit: \u0985\u09b0\u09ac\u09bf\u09a8\u09cd\u09a6 \u0995\u09c7\u099c\u09b0\u09bf\u0993\u09df\u09be\u09b2\u09c7\u09b0 \u09aa\u09be\u09b6\u09c7 \u0986\u09aa \u0995\u09b0\u09cd\u09ae\u09c0\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 Nikit Jacob \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-sachin-tendulkars-reply-to-journo-rana-ayyub-not-thats-fake-handle-12033",
        headline:
          "Sachin Tendulkar-\u098f\u09b0 \u09ad\u09c1\u09df\u09cb \u099f\u09c1\u0987\u099f\u09c7 \u09a8\u09bf\u09b6\u09be\u09a8\u09be Rana Ayyub",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/fact-check-2013-picture-of-rss-relief-work-following-uttarakhand-floods-resurfaces-as-recent/",
        headline:
          "Fact Check: 2013 Picture Of RSS Relief Work Following Uttarakhand Floods Resurfaces As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/fact-check-did-rahul-gandhi-say-he-will-settle-in-london-heres-the-truth/",
        headline:
          "Fact Check: Did Rahul Gandhi Say He Will Settle In London? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/16/fact-check-this-girl-with-arvind-kejriwal-is-not-nikita-jacob-who-is-mired-in-toolkit-controversy/",
        headline:
          "Fact Check: This Girl With Arvind Kejriwal Is Not Nikita Jacob Who Is Mired In \u2018Toolkit\u2019 Controversy",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/19/fact-check-no-sachin-tendulkar-did-not-reply-to-rana-ayyubs-tweet/",
        headline:
          "Fact Check: No, Sachin Tendulkar Did NOT Reply To Rana Ayyub\u2019s Tweet",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-article-30a-in-the-constitution-which-bans-teaching-gita-and-ramayana-in-schools/",
        headline:
          " \u0c15\u0c3e\u0c32\u0c47\u0c1c\u0c40\u0c32\u0c32\u0c4b \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c27\u0c30\u0c4d\u0c2e\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c2c\u0c4b\u0c27\u0c3f\u0c02\u0c1a\u0c15\u0c42\u0c21\u0c26\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c47 \u0c06\u0c30\u0c4d\u0c1f\u0c3f\u0c15\u0c32\u0c4d \u201830\u0c0f\u2019 \u0c05\u0c38\u0c32\u0c41 \u0c30\u0c3e\u0c1c\u0c4d\u0c2f\u0c3e\u0c02\u0c17\u0c02\u0c32\u0c4b\u0c28\u0c47 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/a-tweet-referring-to-india-as-a-country-of-fools-is-falsely-attributed-to-priyanka-gandhi-vadra/",
        headline:
          " A tweet referring to India as a country of fools is falsely attributed to Priyanka Gandhi Vadra",
      },
      {
        url:
          "https://factly.in/telugu-pacific-institute-of-medical-sciences-in-rajasthan-does-not-provide-free-medical-services-to-all/",
        headline:
          " \u0c30\u0c3e\u0c1c\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c2a\u0c38\u0c3f\u0c2b\u0c3f\u0c15\u0c4d \u0c07\u0c28\u0c4d\u0c38\u0c4d\u0c1f\u0c3f\u0c1f\u0c4d\u0c2f\u0c42\u0c1f\u0c4d \u0c06\u0c2b\u0c4d \u0c2e\u0c46\u0c21\u0c3f\u0c15\u0c32\u0c4d \u0c38\u0c48\u0c28\u0c4d\u0c38\u0c4d \u0c05\u0c02\u0c26\u0c30\u0c3f\u0c15\u0c40 \u0c09\u0c1a\u0c3f\u0c24\u0c02\u0c17\u0c3e \u0c35\u0c48\u0c26\u0c4d\u0c2f\u0c02 \u0c05\u0c02\u0c26\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c28\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c24\u0c2a\u0c4d\u0c2a\u0c41",
      },
      {
        url:
          "https://factly.in/edited-photo-of-the-old-advertisement-issued-by-the-aap-government-shared-now-with-a-communal-narrative/",
        headline:
          " Edited photo of an old advertisement issued by the AAP Government shared now with a communal narrative",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-images-with-claim-of-5d-medical-beds-is-false-674474",
        headline:
          "Fact Check: Viral images with claim of 5D medical beds is false",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/factcheck-imposter-sachin-tendulkar-replied-to-rana-ayuubs-tweet-674459",
        headline:
          "Factcheck: Imposter `Sachin Tendulkar' replied to Rana Ayuub's tweet",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-woman-with-delhi-cm-kejriwal-in-viral-photo-is-aap-supporter-ankita-shah-not-lawyer-nikita-jacob-674306",
        headline:
          "Fact Check: Woman with Delhi CM Kejriwal in viral photo is AAP supporter Ankita Shah, not lawyer Nikita Jacob",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/viral/fact-check-no-sachin-tendulkar-has-not-replied-to-rana-ayyub-on-twitter-viral-tweet-is-not-true/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Fact Check: \u09b6\u099a\u09c0\u09a8 \u09a4\u09c7\u09a3\u09cd\u09a1\u09c1\u09b2\u0995\u09be\u09f0\u09c7 \u099f\u09c1\u0987\u099f\u09be\u09f0\u09af\u09cb\u0997\u09c7 \u0995\u09cb\u09a8\u09cb \u09b8\u09be\u0982\u09ac\u09be\u09a6\u09bf\u0995\u0995 \u09a6\u09bf\u09af\u09bc\u09be \u09a8\u09be\u099b\u09bf\u09b2 \u09aa\u09cd\u09f0\u09a4\u09cd\u09af\u09c1\u09a4\u09cd\u09a4\u09f0, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u099f\u09c1\u0987\u099f \u09ad\u09c1\u09f1\u09be\ufeff",
      },
    ],
    "5": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-disha-ravi-disha-annappa-ravi-hindu-syrian-christian-toolkit-greta-thunberg-delhi-police-12009",
        headline:
          "Her Name Is Disha Ravi, Not Disha Ravi Joseph: Friends Rubbish Fake Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-rinku-sharma-death-mangolpuri-anurag-poddar-munger-bihar-fact-check-viral-photo-poonam-devi-11987",
        headline:
          "Image From Munger Clash Last Year Falsely Linked To Rinku Sharma's Death",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/unnao-dalit-girls-death-shatrughan-sinha-tweet-fake-news-india-uttar-pradesh-12056",
        headline:
          "\u0909\u0928\u094d\u0928\u093e\u0935 \u092e\u093e\u092e\u0932\u0947 \u0915\u0947 \u092c\u093e\u0926 \u0936\u0924\u094d\u0930\u0941\u0918\u094d\u0928 \u0938\u093f\u0928\u094d\u0939\u093e \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u091f\u094d\u0935\u0940\u091f \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/environmental-activist-disha-ravi-akhil-sibal-money-pro-bono-kisan-andolan-greta-thunberg-toolkit-case-12081",
        headline:
          "\u0928\u0939\u0940\u0902, \u0926\u093f\u0936\u093e \u0930\u0935\u093f \u0928\u0947 \u0905\u0927\u093f\u0935\u0915\u094d\u0924\u093e \u0905\u0916\u093f\u0932 \u0938\u093f\u092c\u094d\u092c\u0932 \u0915\u094b \u092a\u0948\u0930\u0935\u0940 \u0915\u0947 \u0932\u093e\u0916\u094b\u0902 \u0930\u0942\u092a\u090f \u0928\u0939\u0940\u0902 \u0926\u093f\u090f",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/environmental-activist-disha-ravi-akhil-sibal-vrinda-bhandari-pro-bono-case-kisan-andolan-greta-thunberg-toolkit-12077",
        headline:
          "No, Disha Ravi Did Not Hire Akhil Sibal For Lakhs To Appear In Court",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-disha-ravi-fake-twitter-accounts-fridays-for-future-bengaluru-greta-thunberg-farmers-protest-tool-kit-delhi-police-case-activist-11979",
        headline:
          "Fake Disha Ravi Twitter Accounts Surface After Her Arrest In 'Toolkit' Case",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rinku-sharma-murder-case-mangolpuri-delhi-anurag-poddar-death-munger-bihar-poonam-devi-photo-11993",
        headline:
          "\u092e\u0941\u0902\u0917\u0947\u0930 \u0939\u093f\u0902\u0938\u093e \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0924\u0930\u0940\u0915\u0947 \u0938\u0947 \u0930\u093f\u0902\u0915\u0942 \u0936\u0930\u094d\u092e\u093e \u092e\u0930\u094d\u0921\u0930 \u092e\u093e\u092e\u0932\u0947 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0915\u0940 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/disha-ravi-joseph-viral-fake-news-hindu-syrian-christian-toolkit-case-greta-thunberg-delhi-police-fact-check-12010",
        headline:
          "\u0909\u0938\u0915\u093e \u0928\u093e\u092e \u0926\u093f\u0936\u093e \u0930\u0935\u093f \u0939\u0948, \u0926\u093f\u0936\u093e \u0930\u0935\u093f \u091c\u094b\u095b\u092b \u0928\u0939\u0940\u0902: \u0926\u094b\u0938\u094d\u0924\u094b\u0902 \u0928\u0947 \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u0947 \u0915\u093e \u0916\u0902\u0921\u0928 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-disha-ravi-disha-annappa-ravi-hindu-syrian-christian-toolkit-greta-thunberg-delhi-police-12012",
        headline:
          "\u099f\u09c1\u09b2\u0995\u09bf\u099f \u0995\u09be\u09a3\u09cd\u09a1: \u09a6\u09bf\u09b6\u09be \u09b0\u09ac\u09bf\u09b0 \u09aa\u09c1\u09b0\u09cb \u09a8\u09be\u09ae \u09a6\u09bf\u09b6\u09be \u09b0\u09ac\u09bf \u099c\u09cb\u09b8\u09c7\u09ab \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09ac\u09b2\u099b\u09c7 \u09ac\u09a8\u09cd\u09a7\u09c1\u09b0\u09be",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/did-a-road-crack-in-kashmir-following-tajikistan-earthquake-heres-the-truth/",
        headline:
          "Did A Road Crack In Kashmir Following Tajikistan Earthquake? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/fact-check-these-pictures-depicting-trail-of-devastation-left-behind-by-earthquake-are-not-from-tajikistan/",
        headline:
          "Fact Check: These Pictures Depicting Trail Of Devastation Left Behind By Earthquake Are Not From Tajikistan",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/fact-check-these-pictures-of-damage-caused-by-earthquake-are-not-from-afghanistan-heres-the-truth/",
        headline:
          "Fact Check: These Pictures Of Damage Caused By Earthquake Are NOT From Afghanistan; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/15/fact-check-no-delhi-hc-has-not-ordered-release-of-protesters-held-for-r-day-violence-linked-to-farmers-agitation/",
        headline:
          "Fact Check: No, Delhi HC Has NOT Ordered Release Of Protesters Held For R-Day Violence Linked To Farmers\u2019 Agitation",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/16/fact-check-normal-train-services-to-resume-from-april-1st-heres-the-truth/",
        headline:
          "Fact Check: Normal Train Services To Resume From April 1st? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/17/fact-check-video-of-rinku-sharmas-neighbour-shared-as-that-of-his-mothers/",
        headline:
          "Fact Check: Video Of Rinku Sharma\u2019s Neighbour Shared As That Of His Mother\u2019s",
      },
      {
        url:
          "https://factly.in/telugu-select-photos-shared-as-difference-in-respect-shown-towards-jawans-by-manmohan-and-modi/",
        headline:
          " \u0c24\u0c2e\u0c15\u0c41 \u0c35\u0c40\u0c32\u0c41\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c41 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, \u0c1c\u0c35\u0c3e\u0c28\u0c4d\u0c32\u0c15\u0c41 \u0c07\u0c1a\u0c4d\u0c1a\u0c47 \u0c17\u0c4c\u0c30\u0c35\u0c02\u0c32\u0c4b \u0c2e\u0c28\u0c4d\u0c2e\u0c4b\u0c39\u0c28\u0c4d \u0c2e\u0c30\u0c3f\u0c2f\u0c41 \u0c2e\u0c4b\u0c26\u0c40 \u0c2e\u0c27\u0c4d\u0c2f \u0c35\u0c4d\u0c2f\u0c24\u0c4d\u0c2f\u0c3e\u0c38\u0c02 \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c26\u0c4b\u0c35 \u0c2a\u0c1f\u0c4d\u0c1f\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/the-activations-in-this-mri-have-nothing-to-do-with-oxytocin-hormones-kissing-or-breastfeeding/",
        headline:
          " The activations in this MRI \u2018have nothing to do with oxytocin, hormones, kissing, or breastfeeding\u2019",
      },
      {
        url:
          "https://factly.in/old-photos-from-eastern-turkey-shared-as-recent-pictures-of-earthquake-hit-afghanistan/",
        headline:
          " Old Photos from Eastern Turkey shared as recent pictures of earthquake hit Afghanistan",
      },
      {
        url:
          "https://factly.in/old-photos-from-different-cities-linked-to-earthquake-hit-tajikistan/",
        headline:
          " Old photos from different cities linked to earthquake hit Tajikistan",
      },
      {
        url:
          "https://factly.in/toolkit-case-accused-disha-is-neither-a-christian-nor-her-full-name-is-disha-ravi-joseph/",
        headline:
          " \u2018Toolkit\u2019 case accused Disha is neither a Christian nor her full name is Disha Ravi Joseph",
      },
      {
        url:
          "https://factly.in/telugu-toolkit-case-accused-disha-is-neither-a-christian-nor-her-full-name-is-disha-ravi-joseph/",
        headline:
          " \u2018\u0c1f\u0c42\u0c32\u0c4d \u0c15\u0c3f\u0c1f\u0c4d\u2019 \u0c15\u0c47\u0c38\u0c41\u0c32\u0c4b \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d \u0c05\u0c2f\u0c3f\u0c28 \u0c26\u0c3f\u0c36\u0c3e \u0c30\u0c35\u0c3f \u0c2a\u0c42\u0c30\u0c4d\u0c24\u0c3f \u0c2a\u0c47\u0c30\u0c41 \u2018\u0c26\u0c3f\u0c36\u0c3e \u0c30\u0c35\u0c3f \u0c1c\u0c4b\u0c38\u0c46\u0c2b\u0c4d\u2019 \u0c15\u0c3e\u0c26\u0c41, \u0c24\u0c28\u0c41 \u0c15\u0c4d\u0c30\u0c3f\u0c38\u0c4d\u0c1f\u0c3f\u0c2f\u0c28\u0c4d \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-story-treasure-trove-kashmir-italian-connection-1769800-2021-02-16",
        headline:
          "Fact Check: This story about a treasure trove in Kashmir has an Italian connection",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-21yo-climate-activist-disha-ravi-is-not-a-single-mother-674282",
        headline:
          "Fact Check: 21YO climate activist Disha Ravi is not a single mother",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photos-of-2020-turkey-passed-off-as-recent-quake-in-afghanistan-674249",
        headline:
          "Fact Check: Photos of 2020 Turkey passed off as recent quake in Afghanistan",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-picture-of-greta-thunberg-eating-on-train-has-been-edited-674368",
        headline:
          "Fact check: Viral picture of Greta Thunberg eating on train has been edited",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-boy-in-viral-image-is-not-rinku-sharma-of-delhi-but-anurag-poddar-of-bihar-674377",
        headline:
          "Fact check: Boy in viral image is not Rinku Sharma of Delhi, but Anurag Poddar of Bihar",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-dominos-not-giving-free-pizzas-to-people-who-share-fake-links-674273",
        headline:
          "Fact Check: Dominos not giving free pizzas to people who share fake links",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/disha-ravi-is-a-hindu-not-syrian-christian-from-kerala-674313",
        headline:
          "Fact check: Disha Ravi is a Hindu, not Syrian Christian from Kerala",
      },
    ],
  },
  numpy_seed: 400,
  number_of_articles: 125,
}
