export const data = {
  mdsDat: {
    x: [
      0.03289967128415194,
      0.0461846277760468,
      -0.3188562222603054,
      -0.23078944020932615,
      0.2262599860090192,
      0.24430137740041374,
    ],
    y: [
      0.07059122584471192,
      0.2790642447447751,
      0.15485661860853178,
      -0.3262295521757516,
      -0.13471375840238128,
      -0.04356877861988568,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      20.483399134396603,
      17.65416572597149,
      10.149733221932316,
      12.850929935688646,
      14.42114302714161,
      24.440628954869343,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      21.0,
      12.0,
      7.0,
      7.0,
      8.0,
      9.0,
      6.0,
      5.0,
      6.0,
      6.0,
      5.000075024552001,
      5.000075024552001,
      2.0000300098208004,
      1.0000150049104002,
      3.0000450147312008,
      1.0000150049104002,
      1.0000150049104002,
      1.0000150049104002,
      3.0000450147312008,
      1.0000150049104002,
      6.0000900294624016,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      5.000075024552001,
      6.0000900294624016,
      4.000060019641601,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      7.987786459619887,
      7.987786459619887,
      3.9938932298099434,
      3.9938932298099434,
      3.9938932298099434,
      2.9954199223574576,
      3.9938932298099434,
      4.992366537262429,
      1.0004737033047568,
      2.0009474066095136,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      2.0009474066095136,
      1.0004737033047568,
      4.001894813219027,
      3.0014211099142702,
      3.0014211099142702,
      3.0014211099142702,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      1.0000535701832893,
      1.0000535701832893,
      1.0000535701832893,
      2.0001071403665787,
      1.0000535701832893,
      1.0000535701832893,
      1.0000535701832893,
      1.0000535701832893,
      6.000321421099736,
      1.0000535701832893,
      5.000267850916447,
      4.000214280733157,
      3.000160710549868,
      3.000160710549868,
      3.000160710549868,
      3.000160710549868,
      2.0001071403665787,
      2.0001071403665787,
      2.0001071403665787,
      2.0001071403665787,
      2.0001071403665787,
      7.000374991283025,
      2.0001071403665787,
      4.000214280733157,
      3.000160710549868,
      4.000214280733157,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      2.0034742326297406,
      1.0017371163148703,
      2.0034742326297406,
      1.0017371163148703,
      3.005211348944611,
      3.005211348944611,
      3.005211348944611,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      7.012159814204093,
      5.008685581574352,
      4.006948465259481,
      7.012159814204093,
      2.0034742326297406,
      2.0034742326297406,
      10.017371163148704,
      2.0034742326297406,
      3.005211348944611,
      3.005211348944611,
      3.005211348944611,
      3.005211348944611,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      6.998907382530766,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      2.999531735370328,
      4.999219558950547,
      5.999063470740656,
      6.998907382530766,
      10.998283029691203,
      4.999219558950547,
      5.999063470740656,
    ],
    Term: [
      "price",
      "protest",
      "police",
      "lockdown",
      "hyderabad",
      "fuel",
      "collapse",
      "varanasi",
      "account",
      "rally",
      "kerala",
      "yogi",
      "leave",
      "constitution",
      "incident",
      "face",
      "mehta",
      "program",
      "yogi_adityanath",
      "sachin",
      "rally",
      "stadium",
      "patanjali",
      "worker",
      "adityanath",
      "bjp_workers",
      "bengal",
      "flag",
      "gujarat",
      "bjp",
      "cm",
      "bajrang_dal",
      "bajrang",
      "gurudwara",
      "holidays",
      "dal",
      "mask_checking",
      "uttar_pradesh",
      "uttar",
      "number",
      "mask",
      "police",
      "lockdown",
      "march",
      "pradesh",
      "announce",
      "fight",
      "message",
      "protest",
      "quarrel",
      "toothpaste",
      "sci",
      "colored",
      "fisheries",
      "demolish",
      "tell",
      "disinformation",
      "trainer",
      "gudi",
      "army",
      "chinese",
      "bunker",
      "gandhi",
      "rahul",
      "operation",
      "daughter",
      "indian",
      "rescue",
      "ravi",
      "shared_one",
      "fitness",
      "disha",
      "datum",
      "ayodhya",
      "arabia",
      "africa",
      "kapoor",
      "mix",
      "wedding",
      "kashi",
      "collapse",
      "dhabi",
      "varanasi",
      "spit",
      "year",
      "temple",
      "meerut",
      "young",
      "linga",
      "archaeologist",
      "bread",
      "bridge",
      "visual",
      "hyderabad",
      "rotis",
      "flyover",
      "shoot",
      "man",
      "jam",
      "hold",
      "diesel",
      "rap",
      "restriction",
      "strain",
      "case",
      "traffic",
      "singh",
      "horse",
      "rise",
      "greta",
      "increase",
      "morph",
      "ongoing",
      "oil",
      "placard",
      "thunberg",
      "supporter",
      "fuel",
      "fuel_price",
      "show",
      "protest",
      "advani",
      "caa",
      "price",
      "leader",
      "farmer",
      "link",
      "edit",
      "petrol",
      "parody",
      "twitter",
      "lpg",
      "wrong",
      "prices_essential",
      "mp",
      "essential",
      "actor",
      "statement",
      "kejriwal",
      "account",
      "tiwari",
      "sinha",
      "shatrughan_sinha",
      "shatrughan",
      "manoj_tiwari",
      "manoj",
      "commodity",
      "government",
      "modi",
      "petrol",
      "price",
      "tweet",
      "bjp",
    ],
    Total: [
      21.0,
      12.0,
      7.0,
      7.0,
      8.0,
      9.0,
      6.0,
      5.0,
      6.0,
      6.0,
      5.000075024552001,
      5.000075024552001,
      2.0000300098208004,
      1.0000150049104002,
      3.0000450147312008,
      1.0000150049104002,
      1.0000150049104002,
      1.0000150049104002,
      3.0000450147312008,
      1.0000150049104002,
      6.0000900294624016,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      4.000060019641601,
      6.99976284813222,
      13.000890616517928,
      5.9983772388841965,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      2.9954199223574576,
      7.987786459619887,
      7.987786459619887,
      3.9938932298099434,
      3.9938932298099434,
      3.9938932298099434,
      2.9954199223574576,
      4.993737141600053,
      12.004526351466522,
      1.0004737033047568,
      2.0009474066095136,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      1.0004737033047568,
      2.0009474066095136,
      1.0004737033047568,
      4.001894813219027,
      3.0014211099142702,
      3.0014211099142702,
      3.0014211099142702,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      2.0009474066095136,
      3.002684522924384,
      2.0009474066095136,
      2.0009474066095136,
      1.0000535701832893,
      1.0000535701832893,
      1.0000535701832893,
      2.0001071403665787,
      1.0000535701832893,
      1.0000535701832893,
      1.0000535701832893,
      1.0000535701832893,
      6.000321421099736,
      1.0000535701832893,
      5.000267850916447,
      4.000214280733157,
      3.000160710549868,
      3.000160710549868,
      3.000160710549868,
      3.000160710549868,
      2.0001071403665787,
      2.0001071403665787,
      2.0001071403665787,
      2.0001071403665787,
      2.0001071403665787,
      8.000389996193425,
      2.0001071403665787,
      5.000687984037914,
      4.000634413854625,
      7.003532425153007,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      1.0017371163148703,
      2.0034742326297406,
      1.0017371163148703,
      2.0034742326297406,
      1.0017371163148703,
      3.005211348944611,
      3.005211348944611,
      3.005211348944611,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      2.0034742326297406,
      9.011847637784312,
      6.0085294933644615,
      5.0070020354427704,
      12.004526351466522,
      2.0034742326297406,
      2.0034742326297406,
      21.015654192839907,
      2.0034742326297406,
      5.002157963849583,
      7.0038068016740755,
      7.004586996105049,
      10.004118731475376,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      2.999531735370328,
      6.998907382530766,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      3.9993756471604374,
      2.999531735370328,
      5.997692866403034,
      7.999093480561456,
      10.004118731475376,
      21.015654192839907,
      7.002693791580288,
      13.000890616517928,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.5856,
      1.2491,
      0.8123,
      1.1804,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.7342,
      1.5108,
      0.8568,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      2.2877,
      1.8818,
      2.2877,
      2.2877,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      2.0518,
      1.9182,
      2.0518,
      1.8285,
      1.764,
      1.4917,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.9365,
      1.6856,
      1.7545,
      1.7137,
      1.3988,
      1.9365,
      1.9365,
      1.1955,
      1.9365,
      1.427,
      1.0904,
      1.0903,
      0.7338,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.4089,
      1.2268,
      1.1212,
      1.0517,
      0.7614,
      1.0719,
      0.6355,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.7471,
      -3.7471,
      -4.6634,
      -5.3566,
      -4.258,
      -5.3566,
      -5.3566,
      -5.3566,
      -4.258,
      -5.3566,
      -3.5648,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.7471,
      -3.5648,
      -3.9703,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.1109,
      -3.13,
      -3.13,
      -3.8232,
      -3.8232,
      -3.8232,
      -4.1109,
      -3.8232,
      -3.6,
      -4.654,
      -3.9608,
      -4.654,
      -4.654,
      -4.654,
      -4.654,
      -4.654,
      -4.654,
      -3.9608,
      -4.654,
      -3.2677,
      -3.5553,
      -3.5553,
      -3.5553,
      -3.9608,
      -3.9608,
      -3.9608,
      -3.9608,
      -3.9608,
      -3.9608,
      -3.9608,
      -3.9608,
      -3.9608,
      -4.8903,
      -4.8903,
      -4.8903,
      -4.1972,
      -4.8903,
      -4.8903,
      -4.8903,
      -4.8903,
      -3.0986,
      -4.8903,
      -3.2809,
      -3.5041,
      -3.7917,
      -3.7917,
      -3.7917,
      -3.7917,
      -4.1972,
      -4.1972,
      -4.1972,
      -4.1972,
      -4.1972,
      -2.9444,
      -4.1972,
      -3.5041,
      -3.7917,
      -3.5041,
      -5.0039,
      -5.0039,
      -5.0039,
      -5.0039,
      -5.0039,
      -5.0039,
      -4.3108,
      -5.0039,
      -4.3108,
      -5.0039,
      -3.9053,
      -3.9053,
      -3.9053,
      -4.3108,
      -4.3108,
      -4.3108,
      -4.3108,
      -4.3108,
      -4.3108,
      -3.058,
      -3.3945,
      -3.6177,
      -3.058,
      -4.3108,
      -4.3108,
      -2.7014,
      -4.3108,
      -3.9053,
      -3.9053,
      -3.9053,
      -3.9053,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -3.5875,
      -4.1471,
      -4.1471,
      -4.1471,
      -4.1471,
      -4.1471,
      -4.1471,
      -4.4348,
      -3.924,
      -3.7416,
      -3.5875,
      -3.1355,
      -3.924,
      -3.7416,
    ],
  },
  "token.table": {
    Topic: [
      6,
      6,
      1,
      5,
      4,
      2,
      4,
      4,
      3,
      4,
      2,
      2,
      1,
      1,
      5,
      6,
      1,
      4,
      4,
      3,
      5,
      5,
      3,
      1,
      2,
      6,
      4,
      3,
      6,
      1,
      2,
      4,
      3,
      3,
      4,
      5,
      3,
      3,
      5,
      6,
      6,
      1,
      2,
      5,
      2,
      3,
      3,
      1,
      3,
      4,
      5,
      6,
      5,
      6,
      3,
      2,
      6,
      5,
      3,
      1,
      6,
      2,
      5,
      2,
      5,
      1,
      4,
      1,
      5,
      3,
      5,
      4,
      4,
      6,
      1,
      5,
      1,
      4,
      1,
      2,
      4,
      5,
      2,
      6,
      4,
      5,
      6,
      6,
      6,
      2,
      2,
      2,
      4,
      1,
      2,
      6,
      4,
      1,
      6,
      5,
      6,
      2,
      5,
      5,
      3,
      6,
      1,
      5,
      6,
      5,
      2,
      2,
      5,
      6,
      6,
      1,
      2,
      5,
      3,
      3,
      1,
      5,
      3,
      3,
      5,
      5,
      4,
      1,
      3,
      3,
      5,
      6,
      6,
      3,
      4,
      4,
      5,
      5,
      6,
      4,
      1,
      6,
      5,
      5,
      3,
      4,
      5,
      6,
      3,
      5,
      3,
      5,
      6,
      6,
      2,
      2,
      4,
      4,
      4,
      1,
      6,
      4,
      1,
      1,
      4,
    ],
    Freq: [
      1.0001561125772234,
      1.0001561125772234,
      0.9999849953147437,
      0.9982658960254356,
      0.9999464326863214,
      1.0015290269014896,
      0.9999464326863214,
      0.9999464326863214,
      0.9995265209838179,
      0.9999464326863214,
      1.0015290269014896,
      1.0015290269014896,
      0.9999849953147437,
      0.4615068441831872,
      0.07691780736386453,
      0.4615068441831872,
      0.9999849953147437,
      0.9999464326863214,
      0.9999464326863214,
      0.999526520983818,
      0.9982658960254356,
      0.9982658960254356,
      0.999526520983818,
      0.6668470222363124,
      0.1667117555590781,
      0.1667117555590781,
      0.9999464326863214,
      0.9995265209838179,
      1.0001561125772234,
      0.9999849953147437,
      1.0015290269014896,
      0.9999464326863214,
      0.9995265209838179,
      0.9995265209838179,
      0.9999464326863214,
      0.9982658960254356,
      0.9995265209838179,
      0.9995265209838179,
      0.428290775982677,
      0.5710543679769027,
      1.0001561125772234,
      0.9999849953147437,
      0.3998274373688174,
      0.599741156053226,
      1.0015290269014896,
      0.9995265209838179,
      0.9995265209838179,
      0.9999849953147437,
      0.19997248442453877,
      0.7998899376981551,
      0.776755253900525,
      0.22193007254300715,
      0.8321503631665228,
      0.16643007263330456,
      0.999526520983818,
      0.16673077836340175,
      0.8336538918170088,
      0.9982658960254356,
      0.9995265209838179,
      0.7143099142757635,
      0.2857239657103054,
      1.0015290269014896,
      0.9982658960254356,
      1.0015290269014896,
      0.9982658960254356,
      0.12499390660652777,
      0.8749573462456944,
      0.9999849953147437,
      0.9982658960254356,
      0.9995265209838179,
      0.9982658960254356,
      0.9999464326863214,
      0.9999464326863214,
      1.0001561125772234,
      0.9999849953147437,
      0.9982658960254356,
      0.9999849953147437,
      0.9999464326863214,
      0.1427794952540633,
      0.1427794952540633,
      0.2855589905081266,
      0.4283384857621899,
      1.0015290269014896,
      1.0001561125772234,
      0.5711403556345513,
      0.28557017781727567,
      0.14278508890863784,
      1.0001561125772234,
      1.0001561125772234,
      1.0015290269014896,
      1.0015290269014896,
      1.0015290269014896,
      0.9999464326863214,
      0.9999849953147437,
      0.8010033140667777,
      0.20025082851669443,
      0.9999464326863214,
      0.25002833194288665,
      0.7500849958286598,
      0.9982658960254356,
      1.0001561125772234,
      1.0015290269014896,
      0.9982658960254356,
      0.9982658960254356,
      0.9995265209838179,
      1.0001561125772234,
      0.9999849953147437,
      0.29987648892663327,
      0.6997118074954776,
      0.9982658960254356,
      1.0015290269014896,
      1.0015290269014896,
      0.4758357702424999,
      0.5234193472667499,
      1.0001561125772234,
      0.9999849953147437,
      0.4165095609448331,
      0.5831133853227664,
      0.9995265209838179,
      0.9995265209838179,
      0.9999849953147437,
      0.9982658960254356,
      0.9995265209838179,
      0.9995265209838179,
      0.9982658960254356,
      0.9982658960254356,
      0.9999464326863214,
      0.9999849953147437,
      0.9995265209838179,
      0.6660706393664538,
      0.3330353196832269,
      1.0001561125772234,
      1.0001561125772234,
      0.24996035542185335,
      0.7498810662655601,
      0.1997203102617812,
      0.7988812410471248,
      0.9982658960254356,
      1.0001561125772234,
      0.9999464326863214,
      0.9999849953147437,
      1.0001561125772234,
      0.9982658960254356,
      0.9982658960254356,
      0.9995265209838179,
      0.9999464326863214,
      0.9982658960254356,
      1.0001561125772234,
      0.9995265209838179,
      0.9982658960254356,
      0.9995265209838179,
      0.2856043773332923,
      0.7140109433332308,
      1.0001561125772234,
      1.0015290269014896,
      1.0015290269014896,
      0.9999464326863214,
      0.9999464326863214,
      0.9999464326863214,
      0.9999849953147437,
      1.0001561125772234,
      0.9999464326863214,
      0.9999849953147437,
      0.9999849953147437,
      0.9999464326863214,
    ],
    Term: [
      "account",
      "actor",
      "adityanath",
      "advani",
      "africa",
      "announce",
      "arabia",
      "archaeologist",
      "army",
      "ayodhya",
      "bajrang",
      "bajrang_dal",
      "bengal",
      "bjp",
      "bjp",
      "bjp",
      "bjp_workers",
      "bread",
      "bridge",
      "bunker",
      "caa",
      "case",
      "chinese",
      "cm",
      "cm",
      "cm",
      "collapse",
      "colored",
      "commodity",
      "constitution",
      "dal",
      "datum",
      "daughter",
      "demolish",
      "dhabi",
      "diesel",
      "disha",
      "disinformation",
      "edit",
      "edit",
      "essential",
      "face",
      "farmer",
      "farmer",
      "fight",
      "fisheries",
      "fitness",
      "flag",
      "flyover",
      "flyover",
      "fuel",
      "fuel",
      "fuel_price",
      "fuel_price",
      "gandhi",
      "government",
      "government",
      "greta",
      "gudi",
      "gujarat",
      "gujarat",
      "gurudwara",
      "hold",
      "holidays",
      "horse",
      "hyderabad",
      "hyderabad",
      "incident",
      "increase",
      "indian",
      "jam",
      "kapoor",
      "kashi",
      "kejriwal",
      "kerala",
      "leader",
      "leave",
      "linga",
      "link",
      "link",
      "link",
      "link",
      "lockdown",
      "lpg",
      "man",
      "man",
      "man",
      "manoj",
      "manoj_tiwari",
      "march",
      "mask",
      "mask_checking",
      "meerut",
      "mehta",
      "message",
      "message",
      "mix",
      "modi",
      "modi",
      "morph",
      "mp",
      "number",
      "oil",
      "ongoing",
      "operation",
      "parody",
      "patanjali",
      "petrol",
      "petrol",
      "placard",
      "police",
      "pradesh",
      "price",
      "price",
      "prices_essential",
      "program",
      "protest",
      "protest",
      "quarrel",
      "rahul",
      "rally",
      "rap",
      "ravi",
      "rescue",
      "restriction",
      "rise",
      "rotis",
      "sachin",
      "sci",
      "shared_one",
      "shared_one",
      "shatrughan",
      "shatrughan_sinha",
      "shoot",
      "shoot",
      "show",
      "show",
      "singh",
      "sinha",
      "spit",
      "stadium",
      "statement",
      "strain",
      "supporter",
      "tell",
      "temple",
      "thunberg",
      "tiwari",
      "toothpaste",
      "traffic",
      "trainer",
      "tweet",
      "tweet",
      "twitter",
      "uttar",
      "uttar_pradesh",
      "varanasi",
      "visual",
      "wedding",
      "worker",
      "wrong",
      "year",
      "yogi",
      "yogi_adityanath",
      "young",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/images-from-adult-comedy-falsely-linked-to-yogi-adityanath-uttar-pradesh-revived-12110",
        headline:
          "\u0993\u09df\u09c7\u09ac \u09a8\u09be\u099f\u09bf\u0995\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u099b\u09ac\u09bf\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/covid19-false-claims-go-viral-about-patanjalis-coronil-approved-by-world-health-organisation-who-12097",
        headline:
          "COVID-19: \u09a8\u09be, \u09aa\u09a4\u099e\u09cd\u099c\u09b2\u09bf\u09b0 Coronil \u0995\u09c7 WHO \u0985\u09a8\u09c1\u09ae\u09cb\u09a6\u09a8 \u09a6\u09c7\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2015-image-of-foundation-day-celebration-in-gujarat-shared-as-crowd-in-kerala-vijaya-yatra-attended-by-yogi-adityanath-12086",
        headline:
          "\u09e8\u09e6\u09e7\u09eb'\u09b0 \u0997\u09c1\u099c\u09b0\u09be\u09a4\u09c7 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf\u09b0 \u0985\u09a8\u09c1\u09b7\u09cd\u09a0\u09be\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf \u0995\u09c7\u09b0\u09b2\u09c7 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u09b8\u09ad\u09be \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/kasargod-vijay-yatra-yogi-adityanath-bjp-kerala-elections-viral-photo-fake-news-12084",
        headline:
          "Human Formation Of BJP Symbol To Welcome CM Yogi In Kerala? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/narendra-modi-stadium-cricket-gujarat-sardar-vallabhbhai-patel-sports-enclave-motera-ramnath-kovind-amit-shah-12114",
        headline:
          "Yes, Motera Cricket Stadium Has Been Renamed To Narendra Modi Stadium",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-did-pfi-members-chain-heres-the-truth/",
        headline:
          "Fact Check: Did PFI Members Chain & Parade RSS Workers In Kerala; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-is-it-nirmala-sitharaman-alongside-late-tamil-nadu-cm-jayalalithaa-in-this-picture-heres-the-truth/",
        headline:
          "Fact Check: Is It Nirmala Sitharaman Alongside Late Tamil Nadu CM Jayalalithaa In This Picture? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-was-pakistani-flag-waved-at-a-congress-rally-heres-the-truth/",
        headline:
          "Fact Check: Was \u2018Pakistani\u2019 Flag Waved At A Congress Rally? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-has-patanjalis-coronil-been-certified-by-world-health-organisation-heres-the-truth/",
        headline:
          "Fact Check: Has Patanjali\u2019s \u2018Coronil\u2019 Been Certified By World Health Organisation? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/23/fact-check-did-bjp-workers-form-human-flag-in-yogis-rally-in-kerala-heres-the-truth/",
        headline:
          "Fact Check: Did BJP Workers Form Human Flag In Yogi\u2019s Rally In Kerala? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/23/fact-check-this-video-of-bjp-workers-pelting-stones-at-mlas-house-is-not-from-west-bengal-heres-the-truth/",
        headline:
          "Fact Check: This Video Of BJP Workers Pelting Stones At MLA\u2019s House Is NOT From West Bengal; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/old-debunked-photo-of-the-iuml-party-flag-revived-again-as-the-pakistan-national-flag-waived-during-a-congress-rally/",
        headline:
          " Old debunked photo of the IUML party flag revived again as the Pakistan National flag waived during a Congress rally",
      },
      {
        url:
          "https://factly.in/telugu-who-did-not-approve-patanjalis-coronil-medicine/",
        headline:
          " \u0c2a\u0c24\u0c02\u0c1c\u0c32\u0c3f \u0c35\u0c3e\u0c30\u0c3f \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c14\u0c37\u0c26\u0c02 \u0c15\u0c30\u0c4b\u0c28\u0c3f\u0c32\u0c4d \u0c15\u0c41 \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a \u0c06\u0c30\u0c4b\u0c17\u0c4d\u0c2f \u0c38\u0c02\u0c38\u0c4d\u0c25 (WHO) \u0c05\u0c28\u0c41\u0c2e\u0c24\u0c3f \u0c07\u0c35\u0c4d\u0c35\u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/bjp-workers-attack-on-trs-mlas-residence-in-telangana-portrayed-as-an-attack-in-west-bengal/",
        headline:
          " BJP workers attack on TRS MLA\u2019s residence in Telangana portrayed as an attack in West Bengal",
      },
      {
        url:
          "https://factly.in/role-play-depicting-rss-men-in-handcuffs-is-misconstrued-as-a-real-incident/",
        headline:
          " Roleplay depicting RSS workers in handcuffs is misconstrued as a real incident",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-stone-pelting-incident-in-viral-video-is-from-telangana-not-bengal-674593",
        headline:
          "Fact check: Stone pelting incident in viral video is from Telangana, not Bengal",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-image-of-gujarat-passed-off-as-yogi-adityanaths-rally-in-kerala-674550",
        headline:
          "Fact check: Old image of Gujarat passed off as Yogi Adityanath's rally in Kerala",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-patanjalis-coronil-has-not-been-approved-by-who-674544",
        headline:
          "FACT CHECK: No, Patanjali's Coronil has not been approved by WHO",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-gujarat-cm-vijay-rupani-is-perfectly-healthy-claims-made-with-viral-video-are-false/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0917\u0941\u091c\u0930\u093e\u0924 \u0915\u0947 \u0938\u0940\u090f\u092e \u0935\u093f\u091c\u092f \u0930\u0942\u092a\u093e\u0923\u0940 \u092a\u0942\u0930\u0940 \u0924\u0930\u0939 \u0938\u0947 \u0938\u094d\u0935\u0938\u094d\u0925 \u0939\u0948\u0902, \u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u0947 \u0938\u093e\u0925 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0926\u093e\u0935\u093e \u0917\u0932\u0924 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-old-pic-of-bjp-workers-forming-human-flag-is-not-related-to-yogi-adityanaths-recent-rally-in-kerala/",
        headline:
          "Fact Check: \u0917\u0941\u091c\u0930\u093e\u0924 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u0947 \u0939\u093e\u0932\u093f\u092f\u093e \u0915\u0947\u0930\u0932 \u0926\u094c\u0930\u0947 \u0938\u0947 \u091c\u094b\u0921\u093c \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-bjp-workers-old-video-from-telangana-shared-as-west-bengal/",
        headline:
          "Fact Check: \u092c\u0940\u091c\u0947\u092a\u0940 \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u0938\u0947 \u091c\u0941\u0921\u093c\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0924\u0947\u0932\u0902\u0917\u093e\u0928\u093e \u0915\u093e \u0939\u0948, \u092a\u0936\u094d\u091a\u093f\u092e \u092c\u0902\u0917\u093e\u0932 \u0915\u093e \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-no-sachin-pilot-did-not-appeal-to-president-to-stop-yakub-memons-hanging/",
        headline:
          "Fact Check: No! Sachin Pilot Did NOT Appeal To President To Stop Yakub Memon\u2019s Hanging",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-fake-news-about-demise-of-gujarat-cm-vijay-rupani-goes-viral/",
        headline:
          "Fact Check: Fake News About Demise Of Gujarat CM Vijay Rupani Goes Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/hyderabad-milk-adulteration-incident-revived-with-communal-claims-12155",
        headline:
          "Hyderabad Milk Adulteration Incident Revived With Communal Claims",
      },
      {
        url:
          "https://factly.in/telugu-vijay-mehta-has-stopped-providing-emergency-medicine-to-train-passengers/",
        headline:
          " \u0c30\u0c48\u0c32\u0c41 \u0c2a\u0c4d\u0c30\u0c2f\u0c23\u0c3f\u0c15\u0c41\u0c32\u0c15\u0c3f \u0c05\u0c24\u0c4d\u0c2f\u0c35\u0c38\u0c30 \u0c2a\u0c30\u0c3f\u0c38\u0c4d\u0c25\u0c3f\u0c24\u0c41\u0c32\u0c4d\u0c32\u0c4b \u0c2e\u0c02\u0c26\u0c41\u0c32\u0c41 \u0c38\u0c30\u0c2b\u0c30\u0c3e \u0c1a\u0c47\u0c38\u0c47 \u0c38\u0c47\u0c35\u0c32\u0c28\u0c3f \u0c35\u0c3f\u0c1c\u0c2f\u0c4d \u0c2e\u0c46\u0c39\u0c24\u0c3e \u0c28\u0c3f\u0c32\u0c3f\u0c2a\u0c3f\u0c35\u0c47\u0c36\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/west-bengal-election-bjp-left-front-rally-trinmool-congress-fake-news-fact-check-12168",
        headline: "Congress Handles Peddle 2019 Left Front Rally Pic As Recent",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-rss-ban-constitution-of-india-156-page-vallabhbhai-patel-1948-february-4-12163",
        headline:
          "\u09a8\u09be \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u09b8\u0982\u09ac\u09bf\u09a7\u09be\u09a8\u09c7 \u0986\u09b0\u098f\u09b8\u098f\u09b8-\u0995\u09c7 \u09a8\u09bf\u09b7\u09bf\u09a6\u09cd\u09a7 \u0995\u09b0\u09be \u09a8\u09bf\u09df\u09c7 \u0995\u09bf\u099b\u09c1 \u09b2\u09c7\u0996\u09be \u09a8\u09c7\u0987",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/west-bengal-election-bjp-left-front-rally-trinmool-congress-fake-news-fact-check-12169",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0 \u09ac\u09be\u09ae \u09ab\u09cd\u09b0\u09a8\u09cd\u099f\u09c7\u09b0 \u09ac\u09cd\u09b0\u09bf\u0997\u09c7\u09a1 \u09b0\u200d\u09cd\u09af\u09be\u09b2\u09bf\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-yes-motera-stadium-is-renamed-narendra-modi-stadium-674765",
        headline:
          "FACT CHECK: Yes, Motera Stadium is renamed Narendra Modi Stadium",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-true-baby-shark-was-born-with-human-face-in-indonesia-674789",
        headline:
          "Fact Check: True, baby shark was born with 'human face' in Indonesia",
      },
    ],
    "2": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-farm-laws-farmers-protest-bajrang-dal-uttar-pradesh-bjp-narendra-modi-viral-clip-protest-12096",
        headline:
          "\u09b6\u09bf\u09ac\u09b8\u09c7\u09a8\u09be\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ac\u099c\u09b0\u0982\u09a6\u09b2\u09c7\u09b0 \u0995\u09c3\u09b7\u09bf\u0986\u0987\u09a8-\u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/maharashtra-uddhav-thackeray-covid-19-lockdown-march-1-tv9-graphic-fake-12089",
        headline:
          "Fake Graphic Claims Maharashtra Imposes Total Lockdown From March 1",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/23/fact-check-maharashtra-govt-has-not-announced-a-total-lockdown-in-the-state-yet/",
        headline:
          "Fact Check: Maharashtra Govt Has Not Announced A Total Lockdown In The State Yet",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/23/fact-check-did-bajrang-dal-stage-protest-in-uttar-pradesh-in-support-of-farmers-heres-the-truth/",
        headline:
          "Fact Check: Did Bajrang Dal Stage Protest In Uttar Pradesh In Support Of Farmers? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/older-version-of-cias-world-factbook-listed-bajrang-dal-and-vhp-as-militant-religious-organisation/",
        headline:
          " Older version of CIA\u2019s World Factbook listed Bajrang Dal and VHP as \u2018militant religious organisation\u2019",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-people-working-with-jcb-excavators-is-not-from-lac-674564",
        headline:
          "Fact Check: Video of people working with JCB excavators is not from LAC",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-maha-cm-has-not-announced-statewide-lockdown-from-march-1-674617",
        headline:
          "Fact check: Maha CM has not announced statewide lockdown from March 1",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-9969777888-is-not-telangana-polices-helpline-number-for-women-674696",
        headline:
          "FACT CHECK: No, '9969777888' is not Telangana police's helpline number for women",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-of-brawl-in-california-gurudwara-passed-off-as-fight-in-canadian-sikh-religious-place-674726",
        headline:
          "Fact check: Old video of brawl in California Gurudwara passed off as fight in Canadian Sikh religious place",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-2016-california-gurudware-fight-video-viral-with-wrong-claim-as-canada/",
        headline:
          "Fact Check: 2016 \u092e\u0947\u0902 \u0915\u0948\u0932\u093f\u095e\u094b\u0930\u094d\u0928\u093f\u092f\u093e \u0915\u0947 \u0917\u0941\u0930\u0941\u0926\u094d\u0935\u093e\u0930\u0947 \u092e\u0947\u0902 \u0939\u0941\u0908 \u0932\u095c\u093e\u0908 \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0915\u0928\u093e\u0921\u093e \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-viral-post-about-lockdown-announcement-in-assam-is-not-true/",
        headline:
          "Fact Check: \u0905\u0938\u092e \u092e\u0947\u0902 \u0928\u0939\u0940\u0902 \u0915\u0940 \u0917\u0908 \u0939\u0948 \u0932\u0949\u0915\u0921\u093e\u0909\u0928 \u0915\u0940 \u0918\u094b\u0937\u0923\u093e, \u092f\u0939 \u092a\u094b\u0938\u094d\u091f \u092d\u094d\u0930\u093e\u092e\u0915 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-is-not-a-women-helpline-number-provided-by-bangluru-city-police/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u092e\u0947\u0902 \u0926\u093f\u090f \u0917\u090f \u0928\u0902\u092c\u0930 \u092a\u0930 \u092e\u0948\u0938\u0947\u091c \u0915\u0930\u0928\u0947 \u0938\u0947 \u092c\u0947\u0902\u0917\u0932\u0941\u0930\u0941 \u092a\u0941\u0932\u093f\u0938 \u0915\u093e \u0906\u092a\u0915\u0940 \u0932\u094b\u0915\u0947\u0936\u0928 \u091f\u094d\u0930\u0948\u0915 \u0915\u0930\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u0939\u0948 \u092b\u0930\u094d\u091c\u0940",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-up-police-is-not-running-any-mask-checking-campaign-for-30-days/",
        headline:
          "Fact Check : \u092e\u093e\u0938\u094d\u200d\u0915 \u091a\u0947\u0915\u093f\u0902\u0917 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u092f\u0942\u092a\u0940 \u092a\u0941\u0932\u093f\u0938 \u0915\u093e \u092b\u0930\u094d\u091c\u0940 \u092e\u0948\u0938\u0947\u091c \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-is-not-a-women-helpline-number-provided-by-bangluru-city-police/",
        headline:
          "Fact Check: \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u092e\u0947\u0902 \u0926\u093f\u090f \u0917\u090f \u0928\u0902\u092c\u0930 \u092a\u0930 \u092e\u0948\u0938\u0947\u091c \u0915\u0930\u0928\u0947 \u0938\u0947 \u092c\u0947\u0902\u0917\u0932\u0941\u0930\u0941 \u092a\u0941\u0932\u093f\u0938 \u0915\u093e \u0906\u092a\u0915\u0940 \u0932\u094b\u0915\u0947\u0936\u0928 \u091f\u094d\u0930\u0948\u0915 \u0915\u0930\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u0939\u0948 \u092b\u0930\u094d\u091c\u0940",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-osama-bin-laden-was-not-a-cia-agent-in-the-1980s-viral-claim-is-fake/",
        headline:
          "Fact Check: Osama Bin Laden Was Not A CIA Agent In The 1980s; Viral Claim Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/2019-lok-sabha-poll-dates-viral-as-uttar-pradesh-election-dates-12156",
        headline:
          "2019 Lok Sabha Poll Dates Viral As Uttar Pradesh Election Dates",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/uttar-pradesh-police-to-start-month-long-mask-checking-drive-corona-virus-lockdown-fake-news-fact-check-12141",
        headline:
          "Uttar Pradesh Police Rubbish Claims Of Month-Long Mask Checking Drive",
      },
      {
        url:
          "https://factly.in/satirical-message-about-government-imposing-lockdown-from-29-feb-to-31-feb-2021-is-shared-as-real/",
        headline:
          " Satirical message about Government imposing lockdown from 29 Feb to 31 Feb 2021 is shared as real",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/amravati-police-thrashing-covid-curfew-violators-beating-people-viral-video-lockdown-coronavirus-12162",
        headline:
          "2020 Video Of Police Thrashing Lockdown Violators In Amravati Revived",
      },
      {
        url:
          "https://factly.in/this-post-claiming-holidays-to-schools-and-colleges-in-ap-from-1-march-to-4-may-due-to-covid-19-is-fake/",
        headline:
          " This post claiming holidays to schools and colleges in AP from 01 March to 04 May due to COVID-19 is fake",
      },
      {
        url:
          "https://factly.in/all-the-11-holidays-in-march-2021-do-not-apply-to-all-the-banks-in-all-the-states/",
        headline:
          " All the 11 holidays in March 2021 do not apply to all the banks in all the States.",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/andhra-pradesh-telangana-state-school-college-holidays-covid-19-fake-orders-12167",
        headline:
          "Telangana, Andhra Pradesh Did Not Declare Holidays In Schools Till May",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/gurudwara-fight-canada-farmers-protest-justin-trudeau-farm-bills-12158",
        headline:
          "2016 Video Of Fight In US Gurudwara Shared With Farmers Protest Spin",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-telangana-ap-have-not-announced-2-month-holiday-for-schools-colleges-674807",
        headline:
          "Fact Check: Telangana, AP have not announced 2-month holiday for schools, colleges",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-image-of-protests-in-srinagar-not-recent-but-from-2016-674769",
        headline:
          "Fact Check: Image of protests in Srinagar not recent but from 2016",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2020-video-of-police-lathi-charging-people-for-violating-lockdown-falsely-linked-to-recent-amravati-lockdown-674841",
        headline:
          "Fact Check: 2020 video of police lathi-charging people for violating lockdown falsely linked to recent Amravati lockdown",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-up-police-not-to-conduct-30-day-mask-checking-drive-674802",
        headline:
          "FACT CHECK: UP police not to conduct 30-day mask checking drive",
      },
    ],
    "3": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/no-fir-even-if-army-personnel-shoot-at-stone-pelters-supreme-court-order-fake-news-fact-check-12090",
        headline:
          "\u0938\u0947\u0928\u093e \u0926\u094d\u0935\u093e\u0930\u093e \u092a\u0924\u094d\u0925\u0930\u092c\u093e\u095b\u094b\u0902 \u092a\u0930 \u0917\u094b\u0932\u0940 \u091a\u0932\u093e\u0928\u0947 \u092a\u0930 \u092d\u0940 \u0928\u0939\u0940\u0902 \u0939\u094b\u0917\u093e \u090d\u095e.\u0906\u0908.\u0906\u0930?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/environmental-activist-disha-ravi-akhil-sibal-vrinda-bhandari-pro-bono-case-kisan-andolan-greta-thunberg-toolkit-12116",
        headline:
          "\u09a8\u09be, Disha Ravi-\u09b0 \u09aa\u0995\u09cd\u09b7\u09c7 \u0995\u09cb\u09b0\u09cd\u099f \u09b8\u0993\u09df\u09be\u09b2\u09c7 Akhil Sibbal-\u0995\u09df\u09c7\u0995 \u09b2\u0995\u09cd\u09b7 \u099f\u09be\u0995\u09be \u09a8\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-pangong-tso-lake-indian-army-chinese-army-uttarakhand-chamoli-galier-burst-floods-12085",
        headline:
          "\u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09b8\u09c7\u09a8\u09be \u099a\u09bf\u09a8\u09be \u09ac\u09be\u0999\u09cd\u0995\u09be\u09b0 \u09ad\u09be\u0999\u09cd\u0997\u099b\u09c7 \u09ac\u09b2\u09c7 \u0989\u09a4\u09cd\u09a4\u09b0\u09be\u0996\u09a3\u09cd\u09a1\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-disha-ravi-toolkit-case-delhi-police-disinformation-fake-twitter-accounts-religion-lawyer-akhil-sibal-factcheck-12112",
        headline: "Disha Ravi: A Target Of Disinformation",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/factchecking-rahul-gandhis-demand-for-separate-fisheries-ministry-12119",
        headline:
          "FactChecking Rahul Gandhi's Demand For Separate Fisheries Ministry",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-video-of-chamoli-rescue-operation-shared-with-false-claims-of-army-destroying-chinese-bunkers/",
        headline:
          "Fact Check: Video Of Chamoli Rescue Operation Shared With False Claims Of Army Destroying Chinese Bunkers",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/24/fact-check-old-video-of-people-quarrelling-in-califonia-shared-as-recent-one-from-canada/",
        headline:
          "Fact Check: Old Video Of People Quarrelling In Califonia Shared As Recent One From Canada",
      },
      {
        url:
          "https://factly.in/video-of-rescue-operation-in-uttarakhand-is-falsely-shared-as-indian-army-demolishing-chinese-bunkers/",
        headline:
          " Video of rescue operation in Uttarakhand is falsely shared as the Indian army demolishing Chinese bunkers.",
      },
      {
        url:
          "https://factly.in/telugu-video-of-a-truck-catching-fire-in-pune-is-passed-off-as-that-from-vijayawada/",
        headline:
          " \u0c2a\u0c42\u0c23\u0c47\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c06\u0c15\u0c4d\u0c38\u0c3f\u0c21\u0c46\u0c02\u0c1f\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c35\u0c3f\u0c1c\u0c2f\u0c35\u0c3e\u0c21 \u0c26\u0c41\u0c30\u0c4d\u0c17\u0c2e\u0c4d\u0c2e \u0c17\u0c41\u0c21\u0c3f \u0c2b\u0c4d\u0c32\u0c48\u0c13\u0c35\u0c30\u0c4d \u0c2a\u0c48 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-us-first-lady-was-not-teenager-when-joe-biden-started-dating-her-674601",
        headline:
          "Fact check: US first lady was not teenager when Joe Biden started dating her",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-tampering-with-rahul-gandhis-picture-the-real-picture-is-mahatma-gandhi-not-the-mughal-emperor/",
        headline:
          "Fact Check : \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0902\u0927\u0940 \u0915\u0940 \u0924\u0938\u094d\u200d\u0935\u0940\u0930 \u0938\u0947 \u0915\u0940 \u0917\u0908 \u091b\u0947\u0921\u093c\u091b\u093e\u0921\u093c, \u0905\u0938\u0932\u0940 \u0924\u0938\u094d\u200d\u0935\u0940\u0930 \u092e\u0947\u0902 \u092e\u0941\u0917\u0932 \u092c\u093e\u0926\u0936\u093e\u0939 \u0928\u0939\u0940\u0902, \u092e\u0939\u093e\u0924\u094d\u200d\u092e\u093e \u0917\u093e\u0902\u0927\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-false-and-objectionable-claim-about-aamir-khans-daughter-ira-khan-and-her-fitness-trainer-nupur-shikhare-is-getting-viral-over-social-media/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0906\u092e\u093f\u0930 \u0916\u093e\u0928 \u0915\u0940 \u092c\u0947\u091f\u0940 \u0914\u0930 \u0909\u0928\u0915\u0947 \u092b\u093f\u091f\u0928\u0947\u0938 \u091f\u094d\u0930\u0947\u0928\u0930 \u0915\u094b \u0932\u0947\u0915\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u091d\u0942\u0920\u093e \u0926\u093e\u0935\u093e",
      },
      {
        url:
          "https://www.vishvasnews.com/health/fact-check-no-the-colour-squares-on-the-toothpaste-tube-doesnt-tell-about-the-ingredients/",
        headline:
          "Fact Check: \u091f\u0942\u0925\u092a\u0947\u0938\u094d\u091f \u0915\u0940 \u091f\u094d\u092f\u0942\u092c \u0915\u0947 \u0928\u0940\u091a\u0947 \u0915\u0940 \u0924\u0930\u092b \u092c\u0928\u0947 \u0930\u0902\u0917\u0940\u0928 \u092c\u0949\u0915\u094d\u0938 \u0928\u0939\u0940\u0902 \u092c\u0924\u093e\u0924\u0947 \u0915\u093f \u091f\u0942\u0925\u092a\u0947\u0938\u094d\u091f \u092e\u0947\u0902 \u0915\u094d\u092f\u093e \u0939\u0948, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u092b\u0930\u094d\u091c\u0940",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-false-and-objectionable-claim-about-aamir-khans-daughter-ira-khan-and-her-fitness-trainer-nupur-shikhare-is-getting-viral-over-social-media/",
        headline:
          "Fact Check: \u0906\u092e\u093f\u0930 \u0916\u093e\u0928 \u0915\u0940 \u092c\u0947\u091f\u0940 \u0914\u0930 \u0909\u0928\u0915\u0947 \u092b\u093f\u091f\u0928\u0947\u0938 \u091f\u094d\u0930\u0947\u0928\u0930 \u0915\u094b \u0932\u0947\u0915\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u091d\u0942\u0920\u093e \u0926\u093e\u0935\u093e",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-old-picture-of-unrest-in-srinagars-batmaloo-area-shared-as-recent-one/",
        headline:
          "Fact Check: Old Picture Of Unrest In Srinagar\u2019s Batmaloo Area Shared As Recent One",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-5d-medical-beds-no-these-are-3d-models-from-a-futuristic-sci-fi-lab/",
        headline:
          "Fact Check: 5D Medical Beds? No, These Are 3D Models From A Futuristic Sci-Fi Lab",
      },
    ],
    "4": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-paracetamol-p-500-tablets-macupo-virus-contamination-human-transmission-12113",
        headline:
          "\u09aa\u09cd\u09af\u09be\u09b0\u09be\u09b8\u09bf\u099f\u09be\u09ae\u09b2 P-500 \u09ac\u09a1\u09bc\u09bf \u09a5\u09c7\u0995\u09c7 \u09ae\u09cd\u09af\u09be\u099a\u09be\u09aa\u09c1 \u09ad\u09be\u0987\u09b0\u09be\u09b8 \u09b8\u0982\u0995\u09cd\u09b0\u09ae\u09a3? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/viral-video-hyderabad-under-construction-flyover-collapse-varanasi-12121",
        headline:
          "2018 Video Of Collapsed Varanasi Flyover Peddled As Hyderabad",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-video-of-web-series-shoot-falsely-shared-as-visuals-of-terrorists-nabbed-in-mumbai/",
        headline:
          "Fact Check: Video Of Web Series Shoot Falsely Shared As Visuals Of Terrorists Nabbed In Mumbai",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/23/fact-check-old-video-shared-as-footage-shot-by-nasas-perseverance-rover/",
        headline:
          "Fact Check: Old Video Shared As Footage Shot By NASA\u2019s Perseverance Rover",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/23/fact-check-no-this-video-does-not-show-visuals-of-first-hindu-temple-in-abu-dhabi/",
        headline:
          "Fact Check: No! This Video Does Not Show Visuals Of First Hindu Temple In Abu Dhabi",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-old-video-from-varanasi-shared-as-under-construction-bridge-collapse-in-hyderabad/",
        headline:
          "Fact Check: Old Video From Varanasi Shared As Under-Construction Bridge Collapse In Hyderabad",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-this-video-of-a-man-spitting-on-rotis-at-a-wedding-is-not-from-hyderabad/",
        headline:
          "Fact Check: This Video Of A Man Spitting On Rotis At A Wedding Is Not From Hyderabad",
      },
      {
        url:
          "https://factly.in/2018-video-of-varanasi-flyover-collapse-is-falsely-shared-as-balanar-jeedimetla-flyover-collapse/",
        headline:
          " 2018 video of Varanasi flyover collapse is falsely shared as Balanagar-Jeedimetla flyover collapse",
      },
      {
        url:
          "https://factly.in/6000-year-old-shiva-linga-was-not-discovered-by-archaeologists-in-the-sudwala-caves-south-africa/",
        headline:
          " 6000-year-old Shiva Linga was not discovered by archaeologists in the Sudwala Caves (South Africa)",
      },
      {
        url:
          "https://factly.in/this-video-of-a-man-allegedly-spitting-on-rotis-was-shot-in-meerut-not-in-nampally-hyderabad/",
        headline:
          " This video of a man allegedly spitting on rotis was shot in Meerut, not in Nampally (Hyderabad)",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/fact-check-biryani-jihad-muslim-coimbatore-tamil-nadu-garbh-nirodhak-goli-impotency-fake-news-12129",
        headline:
          "\u0917\u0930\u094d\u092d \u0928\u093f\u0930\u094b\u0927\u0915 \u0917\u094b\u0932\u093f\u092f\u093e\u0902 \u092c\u093f\u0930\u092f\u093e\u0928\u0940 \u092e\u0947\u0902 \u092e\u093f\u0932\u093e \u0915\u0930 \u092c\u0947\u091a\u0924\u0947 \u0925\u0947...\u0915\u094d\u092f\u093e \u0939\u0948 \u0907\u0938 \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u0947 \u0915\u093e \u0938\u091a?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-it-is-snowing-in-saudi-arabiaand-it-is-not-rare-674548",
        headline:
          "Fact check: It is snowing in Saudi Arabia\u2026and it is not rare",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-bridge-collapse-is-not-from-hyd-674660",
        headline: "Fact Check: Viral video of bridge collapse is not from Hyd",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-old-video-of-varanasi-bridge-collapse-now-viral-as-hyderabad-flyover-collapse/",
        headline:
          "Fact Check : \u0935\u093e\u0930\u093e\u0923\u0938\u0940 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0905\u092c \u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u092b\u094d\u0932\u093e\u0908\u0913\u0935\u0930 \u0915\u0947 \u0917\u093f\u0930\u0928\u0947 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-video-of-man-allegedly-spitting-on-breads-from-meerut-shared-with-false-claim/?itm_source=homepage&itm_medium=dktp_s3&itm_campaign=editorpick",
        headline:
          "Fact Check: \u092e\u0947\u0930\u0920 \u092e\u0947\u0902 \u0930\u094b\u091f\u0940 \u092e\u0947\u0902 \u0925\u0942\u0915 \u0932\u0917\u093e\u0928\u0947 \u0915\u0947 \u0906\u0930\u094b\u092a\u0940 \u092f\u0941\u0935\u0915 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0938\u0947 \u091c\u094b\u0921\u093c \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-video-of-man-allegedly-spitting-on-breads-from-meerut-shared-with-false-claim/",
        headline:
          "Fact Check: \u092e\u0947\u0930\u0920 \u092e\u0947\u0902 \u0930\u094b\u091f\u0940 \u092e\u0947\u0902 \u0925\u0942\u0915 \u0932\u0917\u093e\u0928\u0947 \u0915\u0947 \u0906\u0930\u094b\u092a\u0940 \u092f\u0941\u0935\u0915 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0938\u0947 \u091c\u094b\u0921\u093c \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-images-of-kashi-vishwanath-mandir-corridor-in-varansi-is-being-shared-in-the-name-of-ram-mandir-construction-in-ayodhya/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Fact Check: \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u0930\u093e\u0923\u0938\u0940 \u092e\u0947\u0902 \u0915\u093e\u0936\u0940 \u0935\u093f\u0936\u094d\u0935\u0928\u093e\u0925 \u092e\u0902\u0926\u093f\u0930 \u0915\u0949\u0930\u093f\u0921\u094b\u0930 \u0928\u093f\u0930\u094d\u092e\u093e\u0923 \u0915\u0940 \u0939\u0948, \u0905\u092f\u094b\u0927\u094d\u092f\u093e \u092e\u0947\u0902 \u092c\u0928\u0928\u0947 \u0935\u093e\u0932\u0947 \u0930\u093e\u092e \u092e\u0902\u0926\u093f\u0930 \u0915\u0940 \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-pictures-of-kareena-kapoor-khan-with-her-second-baby-are-misleading-heres-why/",
        headline:
          "Fact Check: Pictures Of Kareena Kapoor Khan With Her Second Baby Are Misleading; Here\u2019s Why",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-is-taimur-posing-with-his-younger-brother-in-this-picture-heres-the-truth/",
        headline:
          "Fact Check: Is Taimur Posing With His Younger Brother In This Picture? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-no-archaeologists-have-not-found-6000-year-old-shiva-linga-in-south-africa/",
        headline:
          "Fact Check: No, Archaeologists Have NOT Found 6000-Year-Old Shiva Linga in South Africa",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/did-nasa-train-17-year-old-astronaut-alyssa-carson-for-human-expedition-in-mars-mission-fact-check-12150",
        headline:
          "\u09e7\u09ed \u09ac\u099b\u09b0\u09c7\u09b0 Alyssa Carson \u09ae\u0999\u09cd\u0997\u09b2\u09c7 \u0997\u09bf\u09df\u09c7 \u0986\u09b0 \u09aa\u09c3\u09a5\u09bf\u09ac\u09c0\u09a4\u09c7 \u09ab\u09bf\u09b0\u09ac\u09c7 \u09a8\u09be? \u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987",
      },
    ],
    "5": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/greta-thunberg-viral-photo-train-africa-children-war-environment-kisan-andolan-fake-news-12104",
        headline:
          "\u092a\u0930\u094d\u092f\u093e\u0935\u0930\u0923 \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e \u0917\u094d\u0930\u0947\u091f\u093e \u0925\u0928\u094d\u092c\u0930\u094d\u0917 \u0915\u0940 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u090f\u0921\u093f\u091f \u0915\u0940 \u0917\u092f\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/lk-advani-bjp-leader-protest-fuel-price-hike-narendra-modi-government-fake-news-old-photo-parliament-house-12115",
        headline:
          "\u0924\u0947\u0932 \u0915\u0947 \u092c\u095d\u0924\u0947 \u0926\u093e\u092e\u094b\u0902 \u0915\u0947 \u092e\u0927\u094d\u092f \u0932\u093e\u0932 \u0915\u0943\u0937\u094d\u0923 \u0905\u0921\u0935\u093e\u0923\u0940 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-tmc-worker-gets-primary-teacher-job-without-qualifying-tet-12106",
        headline:
          "\u099f\u09c7\u099f \u09aa\u09be\u09b6 \u09a8\u09be \u0995\u09b0\u09c7\u0987 \u09aa\u09cd\u09b0\u09be\u0987\u09ae\u09be\u09b0\u09bf\u09a4\u09c7 \u099a\u09be\u0995\u09b0\u09bf? \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u09a4\u09c3\u09a3\u09ae\u09c2\u09b2 \u09a8\u09c7\u09a4\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/viral-photo-petrol-diesel-fuel-price-hike-man-holding-a-placard-narendra-modi-ashok-gehlot-12092",
        headline:
          "Old Placard Of CAA Supporter Edited And Linked To Fuel Price Hike",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/greta-thunberg-edited-photo-greta-toolkit-farmers-protest-farm-bills-2020-ask-greta-why-viral-photo-12103",
        headline:
          "Toolkit Case: Edited Image Of Greta Thunberg In A Train Resurfaces",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rajnath-singh-petrol-diesel-price-hike-protest-fake-news-old-photo-12126",
        headline:
          "\u0908\u0902\u0927\u0928 \u0926\u093e\u092e\u094b\u0902 \u092e\u0947\u0902 \u092c\u095d\u094b\u0924\u0930\u0940 \u0915\u0947 \u092c\u0940\u091a \u0930\u093e\u091c\u0928\u093e\u0925 \u0938\u093f\u0902\u0939 \u0915\u0947 \u092a\u0941\u0930\u093e\u0928\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-did-talent-show-contestant-rap-about-ongoing-farmers-protest-no-the-video-is-old/",
        headline:
          "Fact Check: Did Talent Show Contestant Rap About Ongoing Farmers\u2019 Protest? No! The Video Is Old",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-did-rajnath-singh-recently-protest-against-high-petrol-prices-heres-the-truth/",
        headline:
          "Fact Check: Did Rajnath Singh Recently Protest Against High Petrol Prices? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-old-picture-of-lk-advani-protesting-against-inflation-shared-as-recent-one/",
        headline:
          "Fact Check: Old Picture Of LK Advani Protesting Against Inflation Shared As Recent One",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-this-post-showing-fuel-price-build-up-is-misleading-heres-the-truth/",
        headline:
          "Fact Check: This Post Showing Fuel Price Build-Up Is Misleading; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-did-diljit-dosanjh-tweet-that-farmers-want-to-buy-petrol-diesel-directly-from-oil-companies-heres-the-truth/",
        headline:
          "Fact Check: Did Diljit Dosanjh Tweet That Farmers Want To Buy Petrol, Diesel Directly From Oil Companies? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/telugu-bjp-leaders-half-naked-protest-for-haryanas-development-in-2013-is-linked-to-petrol-prices/",
        headline:
          " \u0c39\u0c30\u0c4d\u0c2f\u0c3e\u0c28\u0c3e \u0c05\u0c2d\u0c3f\u0c35\u0c43\u0c26\u0c4d\u0c27\u0c3f \u0c15\u0c4b\u0c38\u0c02 2013\u0c32\u0c4b \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c28\u0c3e\u0c2f\u0c15\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c05\u0c30\u0c4d\u0c27\u0c28\u0c17\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c27\u0c30\u0c32\u0c15\u0c3f \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-a-man-holding-placard-about-fuel-prices-is-morphed-674702",
        headline:
          "Fact check: Viral image of a man holding placard about fuel prices is morphed",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-viral-pic-of-greta-thunberg-eating-lunch-in-front-of-poor-kids-is-morphed-fake-claim-getting-viral/",
        headline:
          "Fact Check: \u0917\u0930\u0940\u092c \u092c\u091a\u094d\u091a\u094b\u0902 \u0915\u0947 \u0938\u093e\u092e\u0928\u0947 \u0916\u093e\u0928\u093e \u0916\u093e\u0924\u0940 \u0917\u094d\u0930\u0947\u091f\u093e \u0925\u0928\u092c\u0930\u094d\u0917 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0949\u0930\u094d\u092b\u094d\u0921 \u0939\u0948, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0915\u093e \u0926\u093e\u0935\u093e \u091d\u0942\u0920\u093e",
      },
      {
        url:
          "https://www.vishvasnews.com/world/fact-check-the-old-picture-of-traffic-jam-in-china-is-being-viral-as-people-protesting-against-the-increase-in-fuel-prices-in-germany/",
        headline:
          "Fact Check: \u091a\u0940\u0928 \u092e\u0947\u0902 \u091f\u094d\u0930\u0948\u092b\u093f\u0915 \u091c\u093e\u092e \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0908\u0902\u0927\u0928 \u0915\u0940 \u0915\u0940\u092e\u0924\u094b\u0902 \u092e\u0947\u0902 \u0935\u0943\u0926\u094d\u0927\u093f \u0915\u0947 \u0916\u093f\u0932\u093e\u092b \u091c\u0930\u094d\u092e\u0928\u0940 \u092e\u0947\u0902 \u0939\u0941\u090f \u0935\u093f\u0930\u094b\u0927 \u0915\u093e \u092c\u0924\u093e \u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-are-new-coronavirus-mutant-strains-behind-rise-in-covid-19-cases-in-india-heres-the-truth/",
        headline:
          "Fact Check: Are New Coronavirus Mutant Strains Behind Rise In COVID-19 Cases In India? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-does-this-viral-picture-show-protest-in-romania-against-covid-19-restrictions-heres-the-truth/",
        headline:
          "Fact Check: Does This Viral Picture Show Protest In Romania Against COVID-19 Restrictions? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/27/fact-check-did-naseeruddin-shah-tweet-about-rising-fuel-prices-msp-heres-the-truth/",
        headline:
          "Fact Check: Did Naseeruddin Shah Tweet About Rising Fuel Prices & MSP? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/2019-video-from-mtv-hustle-reality-show-falsely-linked-to-the-ongoing-farmers-protests/",
        headline:
          " 2019 video from \u2018MTV Hustle\u2019 reality show falsely linked to the ongoing farmers\u2019 protests",
      },
      {
        url:
          "https://factly.in/image-of-a-man-carrying-a-horse-on-his-shoulders-is-shared-with-a-false-narrative/",
        headline:
          " Image of a man carrying a horse on his shoulders is shared with a false narrative",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/viral-photo-petrol-diesel-fuel-price-hike-man-holding-a-placard-narendra-modi-ashok-gehlot-12145",
        headline:
          "\u09b8\u09bf\u098f\u098f \u09b8\u09ae\u09b0\u09cd\u09a5\u0995\u09a6\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099c\u09cd\u09ac\u09be\u09b2\u09be\u09a8\u09bf\u09b0 \u09ae\u09c2\u09b2\u09cd\u09af\u09ac\u09c3\u09a6\u09cd\u09a7\u09bf\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u099c\u09c1\u09dc\u09c7 \u09a6\u09c7\u0993\u09df\u09be \u09b9\u09b2",
      },
    ],
    "6": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/actor-shatrughan-sinha-fake-twitter-handle-viral-facebook-congress-bjp-12091",
        headline:
          "\u0936\u0924\u094d\u0930\u0941\u0918\u0928 \u0938\u093f\u0928\u094d\u0939\u093e \u0928\u0947 \u0938\u0930\u0915\u093e\u0930 \u092a\u0930 '\u0932\u0942\u091f' \u0915\u093e \u0932\u0917\u093e\u092f\u093e \u0906\u0930\u094b\u092a? \u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-staff-selection-commission-ssc-twiter-acount-12109",
        headline:
          "\u099f\u09c1\u0987\u099f\u09be\u09b0\u09c7 \u09b8\u0995\u09cd\u09b0\u09bf\u09df \u09b8\u09cd\u099f\u09be\u09ab \u09b8\u09bf\u09b2\u09c7\u0995\u09b6\u09a8 \u0995\u09ae\u09bf\u09b6\u09a8\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u0985\u09cd\u09af\u09be\u0995\u09be\u0989\u09a8\u09cd\u099f",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/explained-why-lpg-cost-more-during-upa-than-post-2014-nda-regime-12108",
        headline:
          "Explained: Why LPG Cost More During UPA Than Post 2014 NDA Regime",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/22/fact-check-this-video-of-delhi-cm-arvind-kejriwal-threatening-people-of-gujarat-is-edited/",
        headline:
          "Fact Check: This Video Of Delhi CM Arvind Kejriwal \u2018Threatening\u2019 People Of Gujarat Is Edited",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-did-spider-man-actor-tom-holland-take-a-jibe-at-pm-modi-on-twitter-heres-the-truth/",
        headline:
          "Fact Check: Did Spider-Man Actor Tom Holland Take A Jibe At PM Modi On Twitter; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-old-statement-of-union-minister-nitin-gadkari-resurfaces-as-recent-one/",
        headline:
          "Fact Check: Old Statement Of Union Minister Nitin Gadkari Resurfaces As Recent One",
      },
      {
        url:
          "https://factly.in/telugu-this-image-shows-wrong-prices-of-essential-commodities-like-pulses-in-2014-and-2021/",
        headline:
          " 2014\u0c32\u0c4b\u0c28\u0c3f \u0c28\u0c3f\u0c24\u0c4d\u0c2f\u0c3e\u0c35\u0c38\u0c30 \u0c35\u0c38\u0c4d\u0c24\u0c41\u0c35\u0c41\u0c32 \u0c27\u0c30\u0c32\u0c15\u0c3f \u0c08 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c41\u0c32\u0c4b \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c27\u0c30\u0c32\u0c15\u0c3f \u0c1a\u0c3e\u0c32\u0c3e \u0c35\u0c4d\u0c2f\u0c24\u0c4d\u0c2f\u0c3e\u0c38\u0c02 \u0c09\u0c02\u0c26\u0c3f.",
      },
      {
        url:
          "https://factly.in/this-video-of-arvind-kejriwal-threatening-the-people-of-gujarat-is-an-edited-one/",
        headline:
          " This video of Arvind Kejriwal threatening the people of Gujarat is an edited one",
      },
      {
        url:
          "https://factly.in/telugu-this-post-only-mentions-those-bjp-ruled-states-where-petrol-prices-are-low/",
        headline:
          " \u0c08 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c41\u0c32\u0c4b \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c27\u0c30\u0c32\u0c41 \u0c24\u0c15\u0c4d\u0c15\u0c41\u0c35\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28 BJP \u0c2a\u0c3e\u0c32\u0c3f\u0c24 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c32 \u0c17\u0c41\u0c30\u0c3f\u0c02\u0c1a\u0c3f \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c2a\u0c4d\u0c30\u0c38\u0c4d\u0c24\u0c3e\u0c35\u0c3f\u0c02\u0c1a\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/five-among-top-ten-states-where-the-cost-of-petrol-is-high-are-ruled-by-bjp-and-its-allies/",
        headline:
          " Five among the top ten states where the cost of petrol is high are ruled by BJP and its allies",
      },
      {
        url:
          "https://factly.in/telugu-no-big-difference-in-the-prices-of-essential-commodities-from-2020-to-2021/",
        headline:
          " 2020 \u0c24\u0c4b \u0c2a\u0c4b\u0c32\u0c4d\u0c1a\u0c41\u0c15\u0c41\u0c02\u0c1f\u0c47 2021\u0c32\u0c4b \u0c28\u0c3f\u0c24\u0c4d\u0c2f\u0c3e\u0c35\u0c38\u0c30 \u0c35\u0c38\u0c4d\u0c24\u0c41\u0c35\u0c41\u0c32 \u0c27\u0c30\u0c32\u0c32\u0c4b \u0c2a\u0c46\u0c26\u0c4d\u0c26\u0c17\u0c3e \u0c24\u0c47\u0c21\u0c3e \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-morphed-photo-shared-as-muslim-women-chained-while-going-out/",
        headline:
          " \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c2e\u0c39\u0c3f\u0c33\u0c32\u0c28\u0c41 \u0c1a\u0c48\u0c28\u0c4d\u0c32\u0c24\u0c4b \u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c24\u0c40\u0c38\u0c41\u0c15\u0c46\u0c33\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c2f\u0c2c\u0c21\u0c3f\u0c28\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-this-post-misleads-by-just-listing-the-lpg-prices-and-not-mentioning-the-international-fob-prices-of-lpg/",
        headline:
          " \u0c05\u0c02\u0c24\u0c30\u0c4d\u0c1c\u0c3e\u0c24\u0c40\u0c2f FOB \u0c27\u0c30\u0c32\u0c28\u0c3f \u0c32\u0c46\u0c15\u0c4d\u0c15\u0c32\u0c4b\u0c15\u0c3f \u0c24\u0c40\u0c38\u0c41\u0c15\u0c4b\u0c15\u0c41\u0c02\u0c21\u0c3e \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c08 LPG \u0c38\u0c3f\u0c32\u0c3f\u0c02\u0c21\u0c30\u0c4d\u0c32 \u0c1c\u0c3e\u0c2c\u0c3f\u0c24\u0c3e \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c26\u0c4b\u0c35 \u0c2a\u0c1f\u0c4d\u0c1f\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/this-tweet-accusing-bjp-government-policies-is-not-made-by-bollywood-actor-and-former-mp-shatrughan-sinha/",
        headline:
          " This tweet accusing BJP Government policies is not made by Bollywood actor & former MP Shatrughan Sinha",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-tweet-shared-by-a-parody-account-falsely-attributed-to-manoj-tiwari-674699",
        headline:
          "Fact check: Tweet shared by a parody account falsely attributed to Manoj Tiwari",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-the-word-muslim-has-been-added-separately-by-tampering-with-the-advertisement-of-kejriwal-government/",
        headline:
          "Fact Check : \u0915\u0947\u091c\u0930\u0940\u0935\u093e\u0932 \u0938\u0930\u0915\u093e\u0930 \u0915\u0947 \u0935\u093f\u091c\u094d\u091e\u093e\u092a\u0928 \u0938\u0947 \u091b\u0947\u0921\u093c\u091b\u093e\u0921\u093c \u0915\u0930\u0915\u0947 \u0905\u0932\u0917 \u0938\u0947 \u091c\u094b\u0921\u093c\u093e \u0917\u092f\u093e \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0936\u092c\u094d\u200d\u0926",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-vk-sasikala-did-not-tweet-about-mk-stalin-viral-claim-is-false/",
        headline:
          "Fact Check: \u0935\u0940\u0915\u0947 \u0936\u0936\u093f\u0915\u0932\u093e \u0928\u0947 \u090f\u092e\u0915\u0947 \u0938\u094d\u091f\u093e\u0932\u093f\u0928 \u0915\u0947 \u092c\u093e\u0930\u0947 \u092e\u0947\u0902 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u092f\u0939 \u091f\u094d\u0935\u0940\u091f, \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u093e \u0917\u0932\u0924 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/quick-fact-check-no-this-image-is-not-from-kashmir-but-from-us/",
        headline:
          "Quick Fact Check: \u0938\u0947\u092c \u0916\u093e\u0924\u0947 \u092d\u093e\u0932\u0941\u0913\u0902 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0936\u094d\u092e\u0940\u0930 \u0915\u0940 \u0928\u0939\u0940\u0902 \u092f\u0942\u090f\u0938 \u0915\u0940 \u0939\u0948, \u092b\u0930\u094d\u091c\u0940 \u092a\u094b\u0938\u094d\u091f \u092b\u093f\u0930 \u0939\u0941\u0906 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-this-claim-on-hollywood-actor-gerard-butlers-conversion-to-hinduism-is-false/",
        headline:
          "Fact Check: \u0939\u0949\u0932\u0940\u0935\u0941\u0921 \u0905\u092d\u093f\u0928\u0947\u0924\u093e \u091c\u0947\u0930\u093e\u0930\u094d\u0921 \u092c\u091f\u0932\u0930 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0905\u092a\u0928\u093e\u092f\u093e \u0939\u0948 \u0939\u093f\u0902\u0926\u0942 \u0927\u0930\u094d\u092e, \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u093e \u0917\u0932\u0924 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/quick-fact-check-fake-post-about-himalaya-company-went-viral-again/",
        headline:
          "Quick Fact Check : \u0939\u093f\u092e\u093e\u0932\u092f\u093e \u0915\u0902\u092a\u0928\u0940 \u0915\u094b \u0932\u0947\u0915\u0930 \u092b\u093f\u0930 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0908 \u092b\u0930\u094d\u091c\u0940 \u092a\u094b\u0938\u094d\u200d\u091f",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-shatrughan-sinha-did-not-give-a-statement-with-a-viral-tweet-it-is-fake/",
        headline:
          "Fact Check : \u0936\u0924\u094d\u0930\u0941\u0918\u094d\u0928 \u0938\u093f\u0928\u094d\u0939\u093e \u0928\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e \u0935\u093e\u092f\u0930\u0932 \u091f\u094d\u0935\u0940\u091f \u0935\u093e\u0932\u093e \u092c\u092f\u093e\u0928, \u092f\u0939 \u092b\u0930\u094d\u091c\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-bjp-mp-manoj-tiwari-did-not-give-this-statement-on-petrol-prices-fake-post-went-viral/",
        headline:
          "Fact Check: \u092a\u0947\u091f\u094d\u0930\u094b\u0932 \u0915\u0940\u092e\u0924\u094b\u0902 \u092a\u0930 \u092c\u0940\u091c\u0947\u092a\u0940 \u0938\u093e\u0902\u0938\u0926 \u092e\u0928\u094b\u091c \u0924\u093f\u0935\u093e\u0930\u0940 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e \u092f\u0939 \u092c\u092f\u093e\u0928, \u095e\u0930\u094d\u095b\u0940 \u092a\u094b\u0938\u094d\u091f \u0939\u0941\u0908 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/viral/fact-check-viral-breakup-of-petrol-price-is-wrong-tax-changes-according-to-states/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: \u09aa\u09c7\u099f\u09cd\u09f0\u2019\u09b2\u09f0 \u09ae\u09c2\u09b2\u09cd\u09af\u09f0 \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u2018\u09ac\u09cd\u09f0\u09c7\u0995-\u0986\u09aa\u2019 \u09ad\u09c1\u09b2, \u09f0\u09be\u099c\u09cd\u09af\u09f0 \u09b9\u09bf\u099a\u09be\u09aa\u09a4 \u09b8\u09be\u09b2\u09b8\u09b2\u09a8\u09bf \u09b9\u09df \u0995\u09f0",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/25/fact-check-did-shatrughan-sinha-attack-bjp-govt-on-twitter-heres-the-truth/",
        headline:
          "Fact Check: Did Shatrughan Sinha Attack BJP Govt On Twitter? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-tweet-by-a-parody-account-on-fuel-hike-falsely-attributed-to-bjps-manoj-tiwari/",
        headline:
          "Fact Check: Tweet By A Parody Account On Fuel Hike Falsely Attributed To BJP\u2019s Manoj Tiwari",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-picture-of-petrol-bill-with-dont-vote-for-modi-written-on-it-is-fake/",
        headline:
          "Fact Check: Picture Of Petrol Bill With \u2018Don\u2019t Vote For Modi\u2019 Written On It Is Fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-this-video-of-pm-modi-and-former-cong-rajya-sabha-mp-is-edited-heres-the-truth/",
        headline:
          "Fact Check: This Video Of PM Modi And Former Cong Rajya Sabha MP Is Edited; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/26/fact-check-old-video-from-2008-shared-as-israeli-forces-firing-at-a-mosque-in-palestine/",
        headline:
          "Fact Check: Old Video From 2008 Shared As Israeli Forces Firing At A Mosque In Palestine",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-manoj-tiwari-bjp-fake-quote-parody-twitter-handle-petrol-fuel-price-hike-modi-government-factcheck-12124",
        headline:
          "Quote From Parody Handle Viral As Manoj Tiwari's Response On Fuel Prices",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/27/fact-check-govt-has-not-asked-for-verification-of-social-media-accounts-within-three-months/",
        headline:
          "Fact Check: Govt Has NOT Asked For Verification Of Social Media Accounts Within Three Months",
      },
      {
        url:
          "https://factly.in/telugu-old-fake-news-again-revived-as-pm-modi-tops-the-list-of-honest-leaders-released-by-america/",
        headline:
          " \u2018\u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e\u0c32\u0c4b \u0c1c\u0c3e\u0c30\u0c40\u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c28\u0c40\u0c24\u0c3f\u0c2e\u0c02\u0c24\u0c41\u0c32 \u0c1c\u0c3e\u0c2c\u0c3f\u0c24\u0c3e\u0c32\u0c4b \u0c2e\u0c4b\u0c26\u0c40 \u0c2a\u0c4d\u0c30\u0c25\u0c2e \u0c38\u0c4d\u0c25\u0c3e\u0c28\u0c02\u2019 \u0c05\u0c28\u0c47 \u0c2a\u0c3e\u0c24 \u0c2b\u0c47\u0c15\u0c4d \u0c28\u0c4d\u0c2f\u0c42\u0c38\u0c4d \u0c28\u0c3f \u0c2e\u0c33\u0c4d\u0c33\u0c40 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-modi-government-did-not-repay-about-rs-8-lakh-crore-debt-incurred-by-congress-government-on-petrol/",
        headline:
          " \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c2a\u0c48 \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c38\u0c41\u0c2e\u0c3e\u0c30\u0c41 8 \u0c32\u0c15\u0c4d\u0c37\u0c32 \u0c15\u0c4b\u0c1f\u0c4d\u0c32 \u0c05\u0c2a\u0c4d\u0c2a\u0c41 \u0c2e\u0c4b\u0c26\u0c40 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c24\u0c40\u0c30\u0c4d\u0c1a\u0c3f\u0c02\u0c26\u0c28\u0c21\u0c02\u0c32\u0c4b \u0c28\u0c3f\u0c1c\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/2014-essential-commodities-prices-listed-in-this-post-differs-from-the-actual-prices/",
        headline:
          " 2014 Prices of essential commodities listed in the post are much higher than the actual prices",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/explained-why-lpg-cost-more-during-upa-than-post-2014-nda-regime-12134",
        headline:
          "\u09ac\u09bf\u09b6\u09cd\u09b2\u09c7\u09b7\u09a3\u0987\u0989\u09aa\u09bf\u098f \u099c\u09ae\u09be\u09a8\u09be\u09b0 \u09a4\u09c1\u09b2\u09a8\u09be\u09df \u09e8\u09e6\u09e7\u09ea \u098f\u09a8\u09a1\u09bf\u098f \u09aa\u09b0\u09ac\u09b0\u09cd\u09a4\u09c0 \u09b8\u09ae\u09df\u09c7 \u098f\u09b2\u09aa\u09bf\u099c\u09bf\u09b0 \u09a6\u09be\u09ae \u0995\u09ae \u0995\u09c7\u09a8?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-image-of-petrol-bill-with-message-against-voting-for-modi-is-entirely-doctored-674767",
        headline:
          "Fact Check: Image of petrol bill with message against voting for Modi is entirely doctored",
      },
    ],
  },
  numpy_seed: 666,
  number_of_articles: 150,
}
