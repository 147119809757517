export const data = {
  mdsDat: {
    x: [
      0.15237666521205578,
      0.2470977375603828,
      -0.3354091803065553,
      0.1101659146808752,
      -0.1742311371467591,
    ],
    y: [
      -0.1571859895820302,
      -0.021990417138926327,
      -0.2010828980638672,
      0.09913330719306884,
      0.2811259975917548,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      22.91511919612322,
      16.11307227266863,
      16.56526455013801,
      28.996246367262835,
      15.410297613807305,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      80.0,
      16.0,
      13.0,
      15.0,
      7.0,
      8.0,
      10.0,
      8.0,
      6.0,
      6.0,
      2.0004279730669725,
      5.001069932667431,
      2.0004279730669725,
      4.000855946133945,
      2.0004279730669725,
      2.0004279730669725,
      2.0004279730669725,
      3.0006419596004585,
      3.0006419596004585,
      1.0002139865334863,
      8.00171189226789,
      6.001283919200917,
      5.001069932667431,
      5.001069932667431,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      9.001925878801377,
      12.002567838401834,
      8.00171189226789,
      7.001497905734404,
      5.001069932667431,
      12.002567838401834,
      12.002567838401834,
      1.000869681552301,
      1.000869681552301,
      2.001739363104602,
      2.001739363104602,
      1.000869681552301,
      1.000869681552301,
      1.000869681552301,
      2.001739363104602,
      3.002609044656903,
      1.000869681552301,
      4.003478726209204,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      7.006087770866107,
      5.004348407761506,
      7.006087770866107,
      15.013045223284514,
      5.004348407761506,
      10.008696815523011,
      5.004348407761506,
      3.002609044656903,
      3.002609044656903,
      5.000542476350071,
      5.000542476350071,
      7.0007594668900985,
      5.000542476350071,
      5.000542476350071,
      5.000542476350071,
      2.000216990540028,
      1.000108495270014,
      2.000216990540028,
      1.000108495270014,
      6.000650971620085,
      4.000433981080056,
      4.000433981080056,
      4.000433981080056,
      4.000433981080056,
      4.000433981080056,
      7.0007594668900985,
      6.000650971620085,
      5.000542476350071,
      9.000976457430127,
      1.9980346830135236,
      3.996069366027047,
      1.9980346830135236,
      1.9980346830135236,
      1.9980346830135236,
      1.9980346830135236,
      1.9980346830135236,
      0.9990173415067618,
      0.9990173415067618,
      0.9990173415067618,
      8.991156073560857,
      5.994104049040572,
      5.994104049040572,
      5.994104049040572,
      4.99508670753381,
      4.99508670753381,
      3.996069366027047,
      3.996069366027047,
      2.997052024520286,
      14.985260122601428,
      7.992138732054094,
      48.951849733831324,
      49.950867075338095,
      8.991156073560857,
      7.992138732054094,
      6.9931213905473335,
      6.9931213905473335,
      2.0010155293506564,
      4.002031058701313,
      2.0010155293506564,
      4.002031058701313,
      4.002031058701313,
      2.0010155293506564,
      2.0010155293506564,
      6.003046588051969,
      4.002031058701313,
      4.002031058701313,
      6.003046588051969,
      6.003046588051969,
      4.002031058701313,
      4.002031058701313,
      4.002031058701313,
      5.002538823376641,
      5.002538823376641,
      10.005077646753282,
      5.002538823376641,
    ],
    Term: [
      "protest",
      "delhi",
      "sikh",
      "peasant",
      "year",
      "tweet",
      "support",
      "ghmc",
      "army",
      "retire",
      "seat",
      "canadian",
      "harsh",
      "kamala_harri",
      "support_peasant",
      "shah",
      "orange",
      "tweet_support",
      "bangladesh_attack",
      "currency",
      "tweet",
      "demonstration",
      "bangladesh",
      "peasant_movement",
      "add",
      "bjp_economic",
      "economic",
      "flag",
      "crisis",
      "modi",
      "support",
      "peasant",
      "harris",
      "use",
      "congress",
      "protest",
      "farmer",
      "doctor",
      "untrue",
      "vp",
      "da",
      "nab",
      "islamic",
      "mns",
      "pose",
      "law",
      "mohammad",
      "maradona",
      "ahmed",
      "funeral",
      "hathras_bhabhi",
      "disguised_sikh",
      "ahmed_patel",
      "patel",
      "bhabhi",
      "disguise",
      "muslim",
      "muslim_man",
      "sikh",
      "protest",
      "ongoing",
      "farmer",
      "man",
      "pass",
      "leader",
      "hyderabad",
      "want",
      "year",
      "see",
      "want_see",
      "injury",
      "name",
      "possibility",
      "blast",
      "axis",
      "election",
      "current",
      "west_bengal",
      "bengal",
      "west",
      "vote",
      "ghmc",
      "irani",
      "poll",
      "farmer",
      "september",
      "sikhs",
      "community",
      "thousand",
      "block",
      "patra",
      "pic",
      "cup",
      "multiple",
      "eye",
      "rally",
      "khalistan",
      "pro",
      "pro_khalistan",
      "jat",
      "banner",
      "spread",
      "maharashtra",
      "bilkis",
      "delhi",
      "nihang",
      "protest",
      "farmer",
      "march",
      "link",
      "woman",
      "sikh",
      "final",
      "gulati",
      "shared",
      "mahashay_dharampal",
      "sonia",
      "mask",
      "mahtab",
      "army",
      "mahashay",
      "mann",
      "retired_army",
      "retire",
      "moment",
      "sonia_mann",
      "dharampal",
      "officer",
      "injure",
      "farmer",
      "protest",
    ],
    Total: [
      80.0,
      16.0,
      13.0,
      15.0,
      7.0,
      8.0,
      10.0,
      8.0,
      6.0,
      6.0,
      2.0004279730669725,
      5.001069932667431,
      2.0004279730669725,
      4.000855946133945,
      2.0004279730669725,
      2.0004279730669725,
      2.0004279730669725,
      3.0006419596004585,
      3.0006419596004585,
      1.0002139865334863,
      8.00171189226789,
      6.001283919200917,
      5.001069932667431,
      5.001069932667431,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      4.000855946133945,
      10.000943220308137,
      15.002600709259251,
      10.003451255372493,
      9.003237268839005,
      6.001178427937445,
      80.97000161889432,
      90.96818583344634,
      1.000869681552301,
      1.000869681552301,
      2.001739363104602,
      2.001739363104602,
      1.000869681552301,
      1.000869681552301,
      1.000869681552301,
      2.001739363104602,
      3.002609044656903,
      1.000869681552301,
      4.003478726209204,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      3.002609044656903,
      12.007052212270066,
      7.004776380828478,
      13.99920916141344,
      80.97000161889432,
      9.999435115295316,
      90.96818583344634,
      12.99888042986905,
      6.998678410683951,
      8.002167021696412,
      5.000542476350071,
      5.000542476350071,
      7.0007594668900985,
      5.000542476350071,
      5.000542476350071,
      5.000542476350071,
      2.000216990540028,
      1.000108495270014,
      2.000216990540028,
      1.000108495270014,
      6.000650971620085,
      4.000433981080056,
      4.000433981080056,
      4.000433981080056,
      4.000433981080056,
      4.000433981080056,
      8.001267231565427,
      9.002174265646069,
      8.002065770376056,
      90.96818583344634,
      1.9980346830135236,
      3.996069366027047,
      1.9980346830135236,
      1.9980346830135236,
      1.9980346830135236,
      1.9980346830135236,
      1.9980346830135236,
      0.9990173415067618,
      0.9990173415067618,
      0.9990173415067618,
      8.991156073560857,
      5.994104049040572,
      5.994104049040572,
      5.994104049040572,
      4.99508670753381,
      4.99508670753381,
      3.996069366027047,
      3.996069366027047,
      2.997052024520286,
      16.986275651952084,
      8.992247227324109,
      80.97000161889432,
      90.96818583344634,
      12.992599575978486,
      11.994095085698724,
      9.993763350147791,
      13.99920916141344,
      2.0010155293506564,
      4.002031058701313,
      2.0010155293506564,
      4.002031058701313,
      4.002031058701313,
      2.0010155293506564,
      2.0010155293506564,
      6.003046588051969,
      4.002031058701313,
      4.002031058701313,
      6.003046588051969,
      6.003046588051969,
      4.002031058701313,
      4.002031058701313,
      4.002031058701313,
      6.002647318646655,
      7.001664660153417,
      90.96818583344634,
      80.97000161889432,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.4734,
      1.3681,
      1.2503,
      1.2501,
      1.2219,
      1.2911,
      -0.4356,
      -0.552,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.8255,
      1.2868,
      1.4893,
      1.1333,
      0.1404,
      1.1333,
      -0.3815,
      0.871,
      0.9793,
      0.8453,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.7979,
      1.6643,
      1.3923,
      1.3277,
      -0.5153,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.238,
      1.1127,
      1.1201,
      0.7348,
      0.6385,
      0.8699,
      0.832,
      0.881,
      0.5439,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.8701,
      1.6879,
      1.5339,
      -0.3373,
      -0.914,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.9972,
      -4.0809,
      -4.9972,
      -4.3041,
      -4.9972,
      -4.9972,
      -4.9972,
      -4.5917,
      -4.5917,
      -5.6904,
      -3.6109,
      -3.8986,
      -4.0809,
      -4.0809,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -3.4931,
      -3.2055,
      -3.6109,
      -3.7444,
      -4.0809,
      -3.2055,
      -3.2055,
      -5.3375,
      -5.3375,
      -4.6444,
      -4.6444,
      -5.3375,
      -5.3375,
      -5.3375,
      -4.6444,
      -4.2389,
      -5.3375,
      -3.9512,
      -4.2389,
      -4.2389,
      -4.2389,
      -4.2389,
      -4.2389,
      -4.2389,
      -4.2389,
      -4.2389,
      -3.3916,
      -3.7281,
      -3.3916,
      -2.6295,
      -3.7281,
      -3.035,
      -3.7281,
      -4.2389,
      -4.2389,
      -3.7565,
      -3.7565,
      -3.4201,
      -3.7565,
      -3.7565,
      -3.7565,
      -4.6728,
      -5.366,
      -4.6728,
      -5.366,
      -3.5742,
      -3.9797,
      -3.9797,
      -3.9797,
      -3.9797,
      -3.9797,
      -3.4201,
      -3.5742,
      -3.7565,
      -3.1688,
      -5.2338,
      -4.5406,
      -5.2338,
      -5.2338,
      -5.2338,
      -5.2338,
      -5.2338,
      -5.9269,
      -5.9269,
      -5.9269,
      -3.7297,
      -4.1352,
      -4.1352,
      -4.1352,
      -4.3175,
      -4.3175,
      -4.5406,
      -4.5406,
      -4.8283,
      -3.2189,
      -3.8475,
      -2.0351,
      -2.0149,
      -3.7297,
      -3.8475,
      -3.981,
      -3.981,
      -4.6002,
      -3.907,
      -4.6002,
      -3.907,
      -3.907,
      -4.6002,
      -4.6002,
      -3.5015,
      -3.907,
      -3.907,
      -3.5015,
      -3.5015,
      -3.907,
      -3.907,
      -3.907,
      -3.6839,
      -3.6839,
      -2.9907,
      -3.6839,
    ],
  },
  "token.table": {
    Topic: [
      1,
      2,
      2,
      5,
      3,
      1,
      1,
      4,
      3,
      2,
      4,
      1,
      3,
      4,
      1,
      4,
      1,
      3,
      1,
      4,
      1,
      3,
      2,
      4,
      5,
      1,
      5,
      2,
      2,
      2,
      1,
      3,
      4,
      1,
      2,
      3,
      4,
      5,
      5,
      1,
      2,
      3,
      5,
      5,
      1,
      2,
      1,
      2,
      3,
      3,
      4,
      5,
      3,
      3,
      5,
      2,
      4,
      1,
      4,
      2,
      2,
      4,
      5,
      2,
      3,
      4,
      4,
      5,
      5,
      5,
      1,
      2,
      4,
      5,
      2,
      1,
      4,
      5,
      5,
      2,
      1,
      2,
      5,
      4,
      1,
      2,
      3,
      1,
      2,
      2,
      3,
      3,
      4,
      3,
      5,
      2,
      4,
      1,
      2,
      4,
      2,
      4,
      1,
      4,
      5,
      1,
      4,
      3,
      5,
      2,
      3,
      4,
      4,
      1,
      2,
      4,
      5,
      4,
      5,
      5,
      1,
      3,
      4,
      1,
      5,
      2,
      4,
      4,
      5,
      5,
      4,
      1,
      4,
      1,
      4,
      1,
      1,
      2,
      1,
      2,
      3,
      2,
      3,
      3,
      3,
      3,
      1,
      4,
      3,
    ],
    Freq: [
      0.9997860592469539,
      0.9991310741364927,
      0.9991310741364927,
      0.9994924930187893,
      0.9998915164999326,
      0.999786059246954,
      0.999786059246954,
      1.0009836250607582,
      0.9998915164999326,
      0.9991310741364927,
      1.0009836250607582,
      0.9997860592469539,
      0.9998915164999326,
      1.0009836250607582,
      0.999786059246954,
      1.0009836250607582,
      0.8331696949258112,
      0.16663393898516224,
      0.9997860592469539,
      1.0009836250607582,
      0.9997860592469539,
      0.9998915164999326,
      0.9991310741364927,
      0.8830658531245594,
      0.11774211374994126,
      0.999786059246954,
      0.9994924930187893,
      0.9991310741364927,
      0.9991310741364927,
      0.9991310741364927,
      0.9997860592469539,
      0.9998915164999325,
      1.0009836250607582,
      0.13191424991118106,
      0.10992854159265088,
      0.09893568743338578,
      0.5496427079632543,
      0.10992854159265088,
      0.9994924930187893,
      0.9997860592469539,
      0.9991310741364927,
      0.8748614184993879,
      0.12498020264276971,
      0.9994924930187893,
      0.7997239948266343,
      0.19993099870665856,
      0.9997860592469539,
      0.9991310741364927,
      0.9998915164999325,
      0.14282317827801946,
      0.14282317827801946,
      0.7141158913900972,
      0.9998915164999325,
      0.6665056488516434,
      0.3332528244258217,
      0.9991310741364927,
      1.0009836250607582,
      0.9997860592469539,
      1.0009836250607582,
      0.9991310741364927,
      0.37489844836605496,
      0.24993229891070332,
      0.37489844836605496,
      0.16674871974166017,
      0.16674871974166017,
      0.6669948789666407,
      1.0009836250607582,
      0.9994924930187893,
      0.9994924930187893,
      0.9994924930187893,
      0.15385940433795867,
      0.38464851084489665,
      0.46157821301387597,
      0.9994924930187893,
      0.9991310741364927,
      0.1539337827125622,
      0.6927020222065299,
      0.1539337827125622,
      0.9994924930187893,
      0.9991310741364927,
      0.9997860592469539,
      0.9991310741364927,
      0.9994924930187893,
      1.0009836250607582,
      0.3331375536047374,
      0.5829907188082905,
      0.08328438840118435,
      0.28551946432920294,
      0.7137986608230074,
      0.9991310741364927,
      0.9998915164999326,
      0.11120690687433174,
      0.8896552549946539,
      0.1665931624690984,
      0.8329658123454919,
      0.500028245830798,
      0.500028245830798,
      0.9997860592469539,
      0.42865235748227826,
      0.5715364766430376,
      0.9991310741364927,
      1.0009836250607582,
      0.7998613195506752,
      0.06665510996255626,
      0.13331021992511252,
      0.999786059246954,
      1.0009836250607582,
      0.6248386533525112,
      0.37490319201150674,
      0.9991310741364927,
      0.9998915164999326,
      1.0009836250607582,
      1.0009836250607582,
      0.1482030351991472,
      0.185253793998934,
      0.605162393729851,
      0.06175126466631133,
      1.0009836250607582,
      0.9994924930187893,
      0.9994924930187893,
      0.9997860592469539,
      0.9998915164999325,
      1.0009836250607582,
      0.9997860592469539,
      0.9994924930187893,
      0.5000282458307981,
      0.5000282458307981,
      1.0009836250607582,
      0.9994924930187893,
      0.9994924930187893,
      1.0009836250607582,
      0.8999151181784935,
      0.09999056868649926,
      0.9997860592469539,
      1.0009836250607582,
      0.9997860592469539,
      0.999786059246954,
      0.9991310741364927,
      0.777498114397986,
      0.22214231839942458,
      0.9998915164999326,
      0.9991310741364927,
      0.9998915164999325,
      0.9998915164999325,
      0.9998915164999326,
      0.9998915164999326,
      0.30018721625578965,
      0.7004368379301759,
      0.9998915164999326,
    ],
    Term: [
      "add",
      "ahmed",
      "ahmed_patel",
      "army",
      "axis",
      "bangladesh",
      "bangladesh_attack",
      "banner",
      "bengal",
      "bhabhi",
      "bilkis",
      "bjp_economic",
      "blast",
      "block",
      "canadian",
      "community",
      "congress",
      "congress",
      "crisis",
      "cup",
      "currency",
      "current",
      "da",
      "delhi",
      "delhi",
      "demonstration",
      "dharampal",
      "disguise",
      "disguised_sikh",
      "doctor",
      "economic",
      "election",
      "eye",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "final",
      "flag",
      "funeral",
      "ghmc",
      "ghmc",
      "gulati",
      "harris",
      "harris",
      "harsh",
      "hathras_bhabhi",
      "hyderabad",
      "injure",
      "injure",
      "injure",
      "injury",
      "irani",
      "irani",
      "islamic",
      "jat",
      "kamala_harri",
      "khalistan",
      "law",
      "leader",
      "leader",
      "leader",
      "link",
      "link",
      "link",
      "maharashtra",
      "mahashay",
      "mahashay_dharampal",
      "mahtab",
      "man",
      "man",
      "man",
      "mann",
      "maradona",
      "march",
      "march",
      "march",
      "mask",
      "mns",
      "modi",
      "mohammad",
      "moment",
      "multiple",
      "muslim",
      "muslim",
      "muslim",
      "muslim_man",
      "muslim_man",
      "nab",
      "name",
      "nihang",
      "nihang",
      "officer",
      "officer",
      "ongoing",
      "ongoing",
      "orange",
      "pass",
      "pass",
      "patel",
      "patra",
      "peasant",
      "peasant",
      "peasant",
      "peasant_movement",
      "pic",
      "poll",
      "poll",
      "pose",
      "possibility",
      "pro",
      "pro_khalistan",
      "protest",
      "protest",
      "protest",
      "protest",
      "rally",
      "retire",
      "retired_army",
      "seat",
      "see",
      "september",
      "shah",
      "shared",
      "sikh",
      "sikh",
      "sikhs",
      "sonia",
      "sonia_mann",
      "spread",
      "support",
      "support",
      "support_peasant",
      "thousand",
      "tweet",
      "tweet_support",
      "untrue",
      "use",
      "use",
      "vote",
      "vp",
      "want",
      "want_see",
      "west",
      "west_bengal",
      "woman",
      "woman",
      "year",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/hindi/2013-image-from-londan-shared-as-khalistani-supporters-disrespect-indian-flag-during-farmer-protest/",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0906\u0928\u094d\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u092d\u093e\u0930\u0924\u0940\u092f \u091d\u0902\u0921\u0947 \u092a\u0930 \u091c\u0942\u0924\u0947 \u0928\u0939\u0940\u0902 \u0930\u0916\u0947 \u0917\u090f, \u0932\u0902\u0921\u0928 \u0915\u0940 2013 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-unrelated-photo-shared-as-farmers-protesting-revocation-of-article-370-and-uapa/",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u094b\u0902 \u0915\u0947 \u0926\u094c\u0930\u093e\u0928 \u0906\u0930\u094d\u091f\u093f\u0915\u0932 370 \u0939\u091f\u093e\u0928\u0947 \u0915\u093e \u0935\u093f\u0930\u094b\u0927 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0917\u092f\u093e, \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0940 \u0917\u092f\u0940 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/morphed-tweet-of-jack-harris-shared-as-kamala-harris-tweeted-in-support-of-farmer-protest/",
        headline:
          "Kamala Harris criticised Modi for farmers\u2019 protest? No, Canadian MP\u2019s tweet edited",
      },
      {
        url:
          "https://www.altnews.in/congress-uses-2008-image-from-bangladesh-to-attack-modi-government/",
        headline:
          "Congress uses 2008 image from Bangladesh to attack Modi government",
      },
      {
        url:
          "https://factly.in/canadian-mps-tweet-in-support-of-farmers-protest-is-morphed-and-attributed-to-kamala-harris/",
        headline:
          " Canadian MP\u2019s tweet in support of farmers\u2019 protest is morphed and attributed to Kamala Harris",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/05/fact-check-no-dr-harsh-vardhan-did-not-tweet-about-aiims-reserving-90-nursing-seats-for-women/",
        headline:
          "Fact Check: No, Dr Harsh Vardhan did NOT tweet about AIIMS reserving 90% nursing seats for women",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-kamala-harris-did-not-tweet-her-support-for-the-farmers-protests-10951",
        headline:
          "No, Kamala Harris Did Not Tweet Her Support For The Farmers' Protests",
      },
      {
        url:
          "https://www.boomlive.in/health/health-secretary-contradicts-pm-modis-remarks-on-vaccine-for-all-citizens-10958",
        headline:
          "Health Secretary Contradicts PM Modi's Remarks On Vaccine For All Citizens",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/congress-uses-photo-from-bangladesh-to-attack-bjp-on-economic-crisis-10983",
        headline:
          "Congress Uses Photo From Bangladesh To Attack BJP On Economic Crisis",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2019-photo-of-protest-against-article-370-linked-to-farmers-march-10924",
        headline:
          "\u0927\u093e\u0930\u093e 370 \u0939\u091f\u0928\u0947 \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093f\u0938\u093e\u0928 \u092e\u093e\u0930\u094d\u091a \u0938\u0947 \u091c\u094b\u095c\u0940 \u0917\u092f\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photo-of-nazeer-mohd-viral-as-muslim-disguised-as-sikh-to-promote-khalistani-propaganda-10939",
        headline:
          "\u0915\u0948\u0938\u0947 \u090f\u0915 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0917\u0932\u0924 \u0924\u0930\u0940\u0915\u0947 \u0938\u0947 \u0915\u093f\u0938\u093e\u0928 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u0939\u0941\u0908 \u0907\u0938\u094d\u0924\u0947\u092e\u093e\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-photo-of-justin-trudeau-viral-as-canada-pradhanmantri-supporting-farmers-protest-10979",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0947 \u0938\u092e\u0930\u094d\u0925\u0928 \u092e\u0947\u0902 \u0915\u0928\u093e\u0921\u093e\u0908 \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u0927\u0930\u0928\u0947 \u092a\u0930 \u092c\u0948\u0920 \u0917\u090f \u0939\u0948\u0902?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/congress-shared-photo-from-bangladesh-to-attack-bjp-on-economic-crisis-10995",
        headline:
          "\u092c\u0940\u091c\u0947\u092a\u0940 \u0915\u094b \u0906\u0930\u094d\u0925\u093f\u0915 \u0938\u0902\u0915\u091f \u092a\u0930 \u0918\u0947\u0930\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 \u092c\u093e\u0902\u0917\u094d\u0932\u093e\u0926\u0947\u0936 \u0915\u0940 \u095e\u094b\u091f\u094b \u0936\u0947\u092f\u0930 \u0915\u0930 \u0926\u0940",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019-photo-of-protest-against-article-370-linked-to-farmers-march-10937",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09a4\u09cb\u09b2\u09be \u09e9\u09ed\u09e6 \u09a7\u09be\u09b0\u09be\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09c1\u09a1\u09bc\u09b2 \u0995\u09c3\u09b7\u0995 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/doctored-image-of-shah-rukh-khan-falsely-linked-him-to-aimim-10946",
        headline:
          "AIMIM \u098f\u09b0 \u09aa\u09a4\u09be\u0995\u09be \u09b9\u09be\u09a4\u09c7 \u09b6\u09be\u09b9\u09b0\u09c1\u0996 \u0996\u09be\u09a8? \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09a8\u09be \u0995\u09b0\u09be \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/how-a-muslim-mans-photo-was-used-to-discredit-the-farmers-protest-10973",
        headline:
          "\u098f\u0995 \u09ae\u09c1\u09b8\u09b2\u09ae\u09be\u09a8 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u099b\u09ac\u09bf \u09af\u09c7\u09ad\u09be\u09ac\u09c7 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad \u0995\u09b2\u0999\u09cd\u0995\u09bf\u09a4 \u0995\u09b0\u09a4\u09c7 \u09ac\u09cd\u09af\u09ac\u09b9\u09be\u09b0 \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-kamala-harris-did-not-tweet-her-support-for-the-farmers-protests-11004",
        headline:
          "\u09a8\u09be, \u0995\u09ae\u09b2\u09be \u09b9\u09cd\u09af\u09be\u09b0\u09bf\u09b8 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09b8\u09ae\u09b0\u09cd\u09a5\u09a8\u09c7 \u099f\u09c1\u0987\u099f \u0995\u09b0\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-canadian-mp-jack-harris-not-kamala-harris-tweeted-on-farmers-stir-in-india-1746162-2020-12-02",
        headline:
          "Fact Check: Canadian MP Jack Harris, not Kamala Harris, tweeted on farmers\u2019 stir in India",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-rbi-has-not-stopped-supply-of-rs-2-000-currency-notes-1746462-2020-12-03",
        headline:
          "Fact Check: No, RBI has not stopped supply of Rs 2,000 currency notes",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-people-waving-bjp-flags-is-from-jammu-and-not-pok-1747032-2020-12-05",
        headline:
          "Fact Check: Video of people waving BJP flags is from Jammu and not PoK",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-justin-trudeau-did-not-sit-on-dharna-to-support-agitating-indian-farmers-1746738-2020-12-04",
        headline:
          "Fact Check: No, Justin Trudeau did not sit on dharna to support agitating Indian farmers",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/05/fact-check-no-dr-harsh-vardhan-did-not-tweet-about-aiims-reserving-90-nursing-seats-for-women/",
        headline:
          "Fact Check: No, Dr Harsh Vardhan did NOT tweet about AIIMS reserving 90% nursing seats for women",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/doctored-image-of-shah-rukh-khan-falsely-linked-him-to-aimim-10946",
        headline:
          "AIMIM \u098f\u09b0 \u09aa\u09a4\u09be\u0995\u09be \u09b9\u09be\u09a4\u09c7 \u09b6\u09be\u09b9\u09b0\u09c1\u0996 \u0996\u09be\u09a8? \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09a8\u09be \u0995\u09b0\u09be \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-kamala-harris-did-not-tweet-her-support-for-the-farmers-protests-10951",
        headline:
          "No, Kamala Harris Did Not Tweet Her Support For The Farmers' Protests",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/congress-uses-photo-from-bangladesh-to-attack-bjp-on-economic-crisis-10983",
        headline:
          "Congress Uses Photo From Bangladesh To Attack BJP On Economic Crisis",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/health/health-secretary-contradicts-pm-modis-remarks-on-vaccine-for-all-citizens-10958",
        headline:
          "Health Secretary Contradicts PM Modi's Remarks On Vaccine For All Citizens",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2019-photo-of-protest-against-article-370-linked-to-farmers-march-10937",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09a4\u09cb\u09b2\u09be \u09e9\u09ed\u09e6 \u09a7\u09be\u09b0\u09be\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09c1\u09a1\u09bc\u09b2 \u0995\u09c3\u09b7\u0995 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-old-picture-of-woman-wielding-lathi-at-cops-revived-10988",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09aa\u09c1\u09b2\u09bf\u09b6\u09a6\u09c7\u09b0 \u09a4\u09be\u0995 \u0995\u09b0\u09be \u09b2\u09be\u09a0\u09bf \u09b9\u09be\u09a4\u09c7 \u09ae\u09b9\u09bf\u09b2\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-kamala-harris-did-not-tweet-her-support-for-the-farmers-protests-11004",
        headline:
          "\u09a8\u09be, \u0995\u09ae\u09b2\u09be \u09b9\u09cd\u09af\u09be\u09b0\u09bf\u09b8 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09b8\u09ae\u09b0\u09cd\u09a5\u09a8\u09c7 \u099f\u09c1\u0987\u099f \u0995\u09b0\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/how-a-muslim-mans-photo-was-used-to-discredit-the-farmers-protest-10973",
        headline:
          "\u098f\u0995 \u09ae\u09c1\u09b8\u09b2\u09ae\u09be\u09a8 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u099b\u09ac\u09bf \u09af\u09c7\u09ad\u09be\u09ac\u09c7 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad \u0995\u09b2\u0999\u09cd\u0995\u09bf\u09a4 \u0995\u09b0\u09a4\u09c7 \u09ac\u09cd\u09af\u09ac\u09b9\u09be\u09b0 \u09b9\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/congress-shared-photo-from-bangladesh-to-attack-bjp-on-economic-crisis-10995",
        headline:
          "\u092c\u0940\u091c\u0947\u092a\u0940 \u0915\u094b \u0906\u0930\u094d\u0925\u093f\u0915 \u0938\u0902\u0915\u091f \u092a\u0930 \u0918\u0947\u0930\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 \u092c\u093e\u0902\u0917\u094d\u0932\u093e\u0926\u0947\u0936 \u0915\u0940 \u095e\u094b\u091f\u094b \u0936\u0947\u092f\u0930 \u0915\u0930 \u0926\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/farmers-protest-2018-aap-rally-video-viral-as-dilli-chalo-protest-10971",
        headline:
          "\u0906\u092e \u0906\u0926\u092e\u0940 \u092a\u093e\u0930\u094d\u091f\u0940 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2019-photo-of-protest-against-article-370-linked-to-farmers-march-10924",
        headline:
          "\u0927\u093e\u0930\u093e 370 \u0939\u091f\u0928\u0947 \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093f\u0938\u093e\u0928 \u092e\u093e\u0930\u094d\u091a \u0938\u0947 \u091c\u094b\u095c\u0940 \u0917\u092f\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-photo-of-justin-trudeau-viral-as-canada-pradhanmantri-supporting-farmers-protest-10979",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0947 \u0938\u092e\u0930\u094d\u0925\u0928 \u092e\u0947\u0902 \u0915\u0928\u093e\u0921\u093e\u0908 \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u0927\u0930\u0928\u0947 \u092a\u0930 \u092c\u0948\u0920 \u0917\u090f \u0939\u0948\u0902?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/photo-of-nazeer-mohd-viral-as-muslim-disguised-as-sikh-to-promote-khalistani-propaganda-10939",
        headline:
          "\u0915\u0948\u0938\u0947 \u090f\u0915 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0917\u0932\u0924 \u0924\u0930\u0940\u0915\u0947 \u0938\u0947 \u0915\u093f\u0938\u093e\u0928 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u0939\u0941\u0908 \u0907\u0938\u094d\u0924\u0947\u092e\u093e\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-german-farmers-in-viral-video-are-protesting-against-fertilizer-rules-not-supporting-indian-farmers-671364",
        headline:
          "FACT CHECK: German farmers in viral video are protesting against fertilizer rules, not supporting Indian farmers",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-rbi-has-not-stopped-circulating-rs-2000-notes-671383",
        headline:
          "FACT CHECK: No, RBI has not stopped circulating Rs 2000 notes",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/old-debunked-video-is-being-passed-off-as-police-catching-hold-of-fake-sikh-in-farmers-protest/",
        headline:
          " Old debunked video is being passed off as police catching hold of fake Sikh in farmers protest",
      },
      {
        url:
          "https://factly.in/telugu-multiple-old-photos-are-being-shared-in-the-context-of-recent-farmers-protest-in-delhi/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c41 \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c3e\u0c2f \u0c1a\u0c1f\u0c4d\u0c1f\u0c3e\u0c32\u0c15\u0c41 \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c1c\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f \u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-it-is-not-true-that-not-a-single-person-has-died-in-islamic-terrorist-attacks-in-india-since-2014/",
        headline:
          " 2014 \u0c28\u0c41\u0c02\u0c21\u0c3f \u0c07\u0c38\u0c4d\u0c32\u0c3e\u0c2e\u0c3f\u0c15\u0c4d \u0c09\u0c17\u0c4d\u0c30\u0c35\u0c3e\u0c26\u0c41\u0c32 \u0c26\u0c3e\u0c21\u0c41\u0c32\u0c4d\u0c32\u0c4b \u0c12\u0c15\u0c4d\u0c15\u0c30\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c1a\u0c28\u0c3f\u0c2a\u0c4b\u0c32\u0c47\u0c26\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c26\u0c28\u0c32\u0c4b \u0c28\u0c3f\u0c1c\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-cong-leader-digvijaya-singhs-post-about-ahmed-patels-last-rites-viral-with-misleading-translation/",
        headline:
          "Fact Check: Cong leader Digvijaya Singh\u2019s post about Ahmed Patel\u2019s last rites viral with misleading translation",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-dont-fall-for-morphed-picture-of-pele-mourning-at-maradonas-grave-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t fall for morphed picture of Pele mourning at Maradona\u2019s grave; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-old-video-from-2019-shared-as-funeral-of-legendary-footballer-maradona/",
        headline:
          "Fact Check: Old video from 2019 shared as funeral of legendary footballer Maradona",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-did-us-vp-elect-kamala-harris-start-her-term-with-vedic-mantras-and-puja-heres-the-truth/",
        headline:
          "Fact Check: Did US VP-elect Kamala Harris start her term with vedic mantras and puja? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-was-a-muslim-man-posing-as-a-sikh-during-the-ongoing-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Was a Muslim man posing as a Sikh during the ongoing farmers\u2019 protest? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/04/fact-check-was-hathras-bhabhi-protesting-alongside-farmers-in-the-ongoing-protest-heres-the-truth/",
        headline:
          "Fact Check: Was \u2018Hathras Bhabhi\u2019 protesting alongside farmers in the ongoing protest? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/05/fact-check-has-dearness-allowance-da-has-been-increased-by-24-no-this-screengrab-is-fake/",
        headline:
          "Fact Check: Has Dearness Allowance (DA) been increased by 24 per cent? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/how-a-muslim-mans-photo-was-used-to-discredit-the-farmers-protest-10926",
        headline:
          "How A Muslim Man's Photo Was Used To Discredit The Farmers' Protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/did-cops-nab-muslim-sdpi-activist-disguised-sikh-farmers-protest-1745535-2020-12-01",
        headline:
          "Fact Check: Did cops nab Muslim SDPI activist disguised as Sikh in farmers' protest?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fictitious-post-tomar-adani-ambani-farm-laws-goes-viral-1746948-2020-12-05",
        headline:
          "Fact Check: Fictitious post about Tomar linking Adani, Ambani with farm laws goes viral",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-cong-leader-digvijaya-singhs-post-about-ahmed-patels-last-rites-viral-with-misleading-translation/",
        headline:
          "Fact Check: Cong leader Digvijaya Singh\u2019s post about Ahmed Patel\u2019s last rites viral with misleading translation",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-dont-fall-for-morphed-picture-of-pele-mourning-at-maradonas-grave-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t fall for morphed picture of Pele mourning at Maradona\u2019s grave; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-old-video-from-2019-shared-as-funeral-of-legendary-footballer-maradona/",
        headline:
          "Fact Check: Old video from 2019 shared as funeral of legendary footballer Maradona",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-did-us-vp-elect-kamala-harris-start-her-term-with-vedic-mantras-and-puja-heres-the-truth/",
        headline:
          "Fact Check: Did US VP-elect Kamala Harris start her term with vedic mantras and puja? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-was-a-muslim-man-posing-as-a-sikh-during-the-ongoing-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Was a Muslim man posing as a Sikh during the ongoing farmers\u2019 protest? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/04/fact-check-was-hathras-bhabhi-protesting-alongside-farmers-in-the-ongoing-protest-heres-the-truth/",
        headline:
          "Fact Check: Was \u2018Hathras Bhabhi\u2019 protesting alongside farmers in the ongoing protest? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/05/fact-check-has-dearness-allowance-da-has-been-increased-by-24-no-this-screengrab-is-fake/",
        headline:
          "Fact Check: Has Dearness Allowance (DA) been increased by 24 per cent? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/old-photo-passed-off-as-justin-trudeau-sitting-in-an-anti-farm-laws-protest-10980",
        headline:
          "Old Photo Passed Off As Justin Trudeau Sitting In An Anti-Farm Laws Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-is-not-hathras-bhabhi-at-the-farmers-protest-10920",
        headline: "No, This Is Not 'Hathras Bhabhi' At The Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-fight-between-two-ahmednagar-families-viral-police-deny-communal-angle-10972",
        headline:
          "Video Of Fight Between Two Ahmednagar Families Viral, Police Deny Communal Angle",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/doctored-photo-shared-with-fake-claim-of-a-muslim-disguised-as-a-sikh-10981",
        headline:
          "Doctored Photo Shared With Fake Claim Of A Muslim Disguised As A Sikh",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/how-a-muslim-mans-photo-was-used-to-discredit-the-farmers-protest-10926",
        headline:
          "How A Muslim Man's Photo Was Used To Discredit The Farmers' Protest",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-farm-bill-protester-disguised-as-sikh-man-10955",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u0995\u09c3\u09b7\u09bf \u09ac\u09bf\u09b2 \u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7 \u09a8\u09be\u099c\u09bf\u09b0 \u09ae\u09b9\u09ae\u09cd\u09ae\u09a6\u09c7\u09b0 \u09b6\u09bf\u0996 \u09b8\u09be\u099c\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09a8\u09df",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/asaduddin-owaisi-and-smriti-irani-photo-falsely-linked-to-hyderabad-nagar-nigam-chunav-2020-10935",
        headline: "          ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/picture-of-a-woman-carrying-lathi-on-policemen-is-not-from-the-farmers-protest-10931",
        headline: "            ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-nihang-singh-group-video-falsely-linked-to-farmers-protest-in-delhi-10944",
        headline: "   ''        ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/no-muslim-man-has-not-disguised-as-a-sikh-person-for-farmers-protest-671229",
        headline:
          "No, Muslim man has NOT disguised as a sikh person for Farmers protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-funeral-procession-of-mns-leader-jameel-shaikh-passed-off-as-ahmed-patels-last-journey-671282",
        headline:
          "FACT CHECK: Funeral procession of MNS leader Jameel Shaikh passed off as Ahmed Patel's last journey",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/hindi/2016-image-of-smriti-irani-asaduddin-owaisi-shared-as-they-met-before-ghmc-polls-hyderabad/",
        headline:
          "\u0938\u094d\u092e\u0943\u0924\u093f \u0908\u0930\u093e\u0928\u0940 \u0914\u0930 \u0913\u0935\u0948\u0938\u0940 \u0915\u0940 4 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0936\u0947\u092f\u0930 \u0915\u0930\u0924\u0947 \u0939\u0941\u090f \u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0928\u0917\u0930 \u0928\u093f\u0917\u092e \u091a\u0941\u0928\u093e\u0935\u094b\u0902 \u0938\u0947 \u091c\u094b\u095c\u093e",
      },
      {
        url:
          "https://factly.in/telugu-old-photo-of-smriti-irani-visit-to-hyderabad-shared-as-recent/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f GHMC \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c28\u0c47\u0c2a\u0c27\u0c4d\u0c2f\u0c02\u0c32\u0c4b \u0c38\u0c4d\u0c2e\u0c4d\u0c30\u0c3f\u0c24\u0c3f \u0c07\u0c30\u0c3e\u0c28\u0c40\u0c28\u0c3f \u0c13\u0c35\u0c48\u0c38\u0c40 \u0c30\u0c39\u0c38\u0c4d\u0c2f\u0c02\u0c17\u0c3e \u0c15\u0c32\u0c3f\u0c38\u0c3e\u0c21\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-fake-surveys-on-different-names-about-ghmc-election-results-being-shared/",
        headline:
          " \u0c35\u0c3f\u0c35\u0c3f\u0c27 \u0c38\u0c02\u0c38\u0c4d\u0c25\u0c32 \u0c2a\u0c47\u0c30\u0c4d\u0c32\u0c24\u0c4b GHMC \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c2b\u0c47\u0c15\u0c4d \u0c38\u0c30\u0c4d\u0c35\u0c47\u0c32\u0c28\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/the-hindu-population-in-ghmc-is-64-93-whereas-the-muslim-population-is-30-13-2011-census/",
        headline:
          " The Hindu Population in GHMC is 64.93% whereas the Muslim Population is 30.13% (2011 Census)",
      },
      {
        url:
          "https://factly.in/telugu-one-person-can-vote-from-two-different-places-in-two-different-elections/",
        headline:
          " \u0c12\u0c15\u0c47 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c4b \u0c30\u0c46\u0c02\u0c21\u0c41 \u0c1a\u0c4b\u0c1f\u0c4d\u0c32 \u0c28\u0c41\u0c02\u0c21\u0c3f \u0c13\u0c1f\u0c41 \u0c35\u0c47\u0c2f\u0c21\u0c02 \u0c1a\u0c1f\u0c4d\u0c1f\u0c35\u0c3f\u0c30\u0c41\u0c26\u0c4d\u0c27\u0c02. \u0c15\u0c3e\u0c28\u0c40, \u0c30\u0c46\u0c02\u0c21\u0c41 \u0c35\u0c47\u0c30\u0c41 \u0c35\u0c47\u0c30\u0c41 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c4d\u0c32\u0c4b \u0c35\u0c47\u0c30\u0c41 \u0c35\u0c47\u0c30\u0c41 \u0c1a\u0c4b\u0c1f\u0c4d\u0c32 \u0c13\u0c1f\u0c41 \u0c35\u0c47\u0c38\u0c47 \u0c05\u0c35\u0c15\u0c3e\u0c36\u0c02 \u0c09\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-picture-of-factory-fire-from-ukraine-shared-as-blast-in-west-bengal/",
        headline:
          "Fact Check: Picture of factory fire from Ukraine shared as blast in West Bengal",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/i-am-right-here-if-they-want-to-see-my-injuries-lathi-charged-farmer-10933",
        headline:
          "I Am Right Here If They Want To See My Injuries: Lathi-Charged Farmer",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2016-photo-of-asad-owaisi-and-smriti-irani-revived-ahead-of-ghmc-polls-10934",
        headline:
          "2016 Photo Of Asad Owaisi And Smriti Irani Revived Ahead Of GHMC Polls",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-nihang-singh-group-video-falsely-linked-to-farmers-protest-in-delhi-10944",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e '\u0928\u093f\u0939\u0902\u0917' \u0938\u092e\u0942\u0939 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/i-am-right-here-if-they-want-to-see-my-injuries-lathi-charged-farmer-10964",
        headline:
          "\u092e\u0948\u0902 \u092f\u0939\u0940\u0902 \u0939\u0942\u0901 \u092f\u0926\u093f \u0935\u094b \u092e\u0947\u0930\u0940 \u091a\u094b\u091f \u0926\u0947\u0916\u0928\u093e \u091a\u093e\u0939\u0924\u0947 \u0939\u0948\u0902: \u0915\u093f\u0938\u093e\u0928 \u091c\u093f\u0938\u092a\u0930 \u0932\u093e\u0920\u0940\u091a\u093e\u0930\u094d\u091c \u0939\u0941\u0906",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2018-photo-falsely-shared-as-banner-at-farmers-protest-10970",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0924\u0930\u0940\u0915\u0947 \u0938\u0947 \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0940 \u0917\u092f\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-picture-of-sikh-showing-shoe-people-standing-on-indian-flag-viral-as-farmers-protest-11003",
        headline:
          "\u092f\u0941.\u0915\u0947 \u0938\u0947 7 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0915\u0940 \u0917\u092f\u0940 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2014-video-shared-as-hindu-chants-recited-at-white-house-for-joe-biden-10910",
        headline:
          "\u09e8\u09e6\u09e7\u09ea \u09b8\u09be\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09b9\u09cb\u09df\u09be\u0987\u099f \u09b9\u09be\u0989\u09b8\u09c7 \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09ae\u09a8\u09cd\u09a4\u09cd\u09b0 \u09aa\u09be\u09a0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/toddler-rescued-from-train-in-wb-in-2015-revived-as-bangladesh-10923",
        headline:
          "\u09e8\u09e6\u09e7\u09eb \u09b8\u09be\u09b2\u09c7 \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u099f\u09cd\u09b0\u09c7\u09a8 \u09a5\u09c7\u0995\u09c7 \u09b6\u09bf\u09b6\u09c1 \u0989\u09a6\u09cd\u09a7\u09be\u09b0 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2016-photo-of-asad-owaisi-and-smriti-irani-revived-ahead-of-ghmc-polls-10952",
        headline:
          "\u09b9\u09be\u09df\u09a6\u09b0\u09be\u09ac\u09be\u09a6 \u0995\u09b0\u09cd\u09aa\u09cb\u09b0\u09c7\u09b6\u09a8 \u09ad\u09cb\u099f\u09c7\u09b0 \u0986\u0997\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u0993\u09df\u09c7\u0987\u09b8\u09bf-\u0987\u09b0\u09be\u09a8\u09bf\u09b0 \u09e8\u09e6\u09e7\u09ec \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-owaisi-did-not-say-he-will-convert-to-hinduism-if-bjp-wins-hyderabad-municipal-poll-1746769-2020-12-04",
        headline:
          "Fact Check: Owaisi did not say he will convert to Hinduism if BJP wins Hyderabad municipal poll",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-picture-of-factory-fire-from-ukraine-shared-as-blast-in-west-bengal/",
        headline:
          "Fact Check: Picture of factory fire from Ukraine shared as blast in West Bengal",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2016-photo-of-asad-owaisi-and-smriti-irani-revived-ahead-of-ghmc-polls-10952",
        headline:
          "\u09b9\u09be\u09df\u09a6\u09b0\u09be\u09ac\u09be\u09a6 \u0995\u09b0\u09cd\u09aa\u09cb\u09b0\u09c7\u09b6\u09a8 \u09ad\u09cb\u099f\u09c7\u09b0 \u0986\u0997\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u0993\u09df\u09c7\u0987\u09b8\u09bf-\u0987\u09b0\u09be\u09a8\u09bf\u09b0 \u09e8\u09e6\u09e7\u09ec \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/i-am-right-here-if-they-want-to-see-my-injuries-lathi-charged-farmer-10933",
        headline:
          "I Am Right Here If They Want To See My Injuries: Lathi-Charged Farmer",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2016-photo-of-asad-owaisi-and-smriti-irani-revived-ahead-of-ghmc-polls-10934",
        headline:
          "2016 Photo Of Asad Owaisi And Smriti Irani Revived Ahead Of GHMC Polls",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/toddler-rescued-from-train-in-wb-in-2015-revived-as-bangladesh-10923",
        headline:
          "\u09e8\u09e6\u09e7\u09eb \u09b8\u09be\u09b2\u09c7 \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u099f\u09cd\u09b0\u09c7\u09a8 \u09a5\u09c7\u0995\u09c7 \u09b6\u09bf\u09b6\u09c1 \u0989\u09a6\u09cd\u09a7\u09be\u09b0 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/i-am-right-here-if-they-want-to-see-my-injuries-lathi-charged-farmer-10947",
        headline:
          "\u0986\u09ae\u09be\u09b0 \u0986\u0998\u09be\u09a4 \u09a6\u09c7\u0996\u09a4\u09c7 \u099a\u09be\u0987\u09b2\u09c7, \u0986\u09b8\u09a4\u09c7 \u09aa\u09be\u09b0\u09c7\u09a8, \u0986\u09ae\u09bf \u098f\u0996\u09be\u09a8\u09c7\u0987 \u0986\u099b\u09bf: \u09b2\u09be\u09a0\u09bf\u09b0 \u0998\u09be\u09df\u09c7 \u0986\u09b9\u09a4 \u0995\u09c3\u09b7\u0995",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2014-video-shared-as-hindu-chants-recited-at-white-house-for-joe-biden-10910",
        headline:
          "\u09e8\u09e6\u09e7\u09ea \u09b8\u09be\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09b9\u09cb\u09df\u09be\u0987\u099f \u09b9\u09be\u0989\u09b8\u09c7 \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09ae\u09a8\u09cd\u09a4\u09cd\u09b0 \u09aa\u09be\ufffd",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/photo-of-a-woman-viral-as-hathras-bhabhi-in-farmers-protest-10930",
        headline: "       ' ' ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/pro-khalistan-slogans-from-cricket-world-cup-peddled-as-farmers-protest-10909",
        headline: "             ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2018-photo-falsely-shared-as-banner-at-farmers-protest-10970",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0924\u0930\u0940\u0915\u0947 \u0938\u0947 \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0940 \u0917\u092f\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/i-am-right-here-if-they-want-to-see-my-injuries-lathi-charged-farmer-10964",
        headline:
          "\u092e\u0948\u0902 \u092f\u0939\u0940\u0902 \u0939\u0942\u0901 \u092f\u0926\u093f \u0935\u094b \u092e\u0947\u0930\u0940 \u091a\u094b\u091f \u0926\u0947\u0916\u0928\u093e \u091a\u093e\u0939\u0924\u0947 \u0939\u0948\u0902: \u0915\u093f\u0938\u093e\u0928 \u091c\u093f\u0938\u092a\u0930 \u0932\u093e\u0920\u0940\u091a\u093e\u0930\u094d\u091c \u0939\u0941\u0906",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-picture-of-sikh-showing-shoe-people-standing-on-indian-flag-viral-as-farmers-protest-11003",
        headline:
          "\u092f\u0941.\u0915\u0947 \u0938\u0947 7 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0915\u0940 \u0917\u092f\u0940 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2018-photo-from-mumbai-viral-as-farmers-protest-in-delhi-2020-10928",
        headline: "              ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-17-year-old-boy-has-not-been-appointed-polling-officer-for-ghmc-elections-671308",
        headline:
          "Fact Check: No, 17-year-old boy has not been appointed polling officer for GHMC elections",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-exit-poll-by-axis-india-showing-congress-leading-in-ghmc-polls-is-fake-671203",
        headline:
          "Fact Check: Exit poll by Axis India showing Congress leading in GHMC polls is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-sarat-chandra-academy-has-named-arts-department-after-sonu-sood-in-vijayawada-not-hyderabad-671272",
        headline:
          "Fact Check: Sarat Chandra Academy has named arts department after Sonu Sood in Vijayawada, not Hyderabad",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/2018-image-of-farmer-protest-from-maharashtra-shared-recent/",
        headline:
          "Photo from 2018 viral as thousands gather for ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://www.altnews.in/farmers-protest-old-video-viral-as-thousands-of-nihang-sikhs-march-for-delhi-from-punjab/",
        headline:
          "Farmers\u2019 protest: Old video viral as thousands of Nihang Sikhs march for Delhi from Punjab",
      },
      {
        url:
          "https://factly.in/telugu-2018-video-shared-amidst-recent-protest-by-farmers/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c24\u0c3e\u0c1c\u0c3e\u0c17\u0c3e \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40\u0c32\u0c4b \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/old-rally-video-falsely-shared-as-related-to-the-recent-farmers-protest-in-delhi/",
        headline:
          " Old rally video falsely shared as related to the recent farmers\u2019 protest in Delhi.",
      },
      {
        url:
          "https://factly.in/multiple-old-photos-are-being-shared-in-the-context-of-recent-farmers-protest-in-delhi/",
        headline:
          " Multiple old photos are being shared in the context of recent farmers\u2019 protest in Delhi",
      },
      {
        url:
          "https://factly.in/old-video-falsely-shared-as-a-video-of-20000-nihang-sikhs-going-to-delhi-to-support-farmers-protest/",
        headline:
          " Old video falsely shared as a video of 20,000 Nihang Sikhs going to Delhi to support farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/old-image-of-a-banner-about-lord-ram-is-being-linked-to-ongoing-farmers-protest/",
        headline:
          " Old image of a banner about Lord Ram is being linked to ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/2017-image-of-jat-protests-is-shared-as-women-from-rajasthan-participating-in-farmers-protest/",
        headline:
          " 2017 image of Jat protests is shared as women from Rajasthan participating in farmers\u2019 protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-old-picture-of-a-lathi-wielding-woman-attributed-to-recent-farmers-protest/",
        headline:
          "Fact Check: Old picture of a lathi wielding woman attributed to recent farmers\u2019 protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-video-from-2019-shared-as-pro-khalistan-slogans-being-raised-at-farmers-protest/",
        headline:
          "Fact Check: Video from 2019 shared as pro-Khalistan slogans being raised at farmers protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-did-bjp-leader-sambit-patras-daughter-elope-with-a-man-from-the-minority-community-heres-the-truth/",
        headline:
          "Fact Check: Did BJP leader Sambit Patra\u2019s daughter elope with a man from the minority community? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/05/fact-check-video-of-policeman-removing-sikh-mans-turban-is-not-from-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Video of policeman removing Sikh man\u2019s turban is NOT from recent farmers\u2019 protest; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/05/fact-check-old-video-of-nihang-sikh-rally-falsely-linked-to-recent-farmers-protest/",
        headline:
          "Fact Check: Old video of Nihang Sikh rally falsely linked to recent farmers protest",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2019-photo-of-protest-against-article-370-linked-to-farmers-march-10908",
        headline:
          "2019 Photo Of Protest Against Article 370 Linked To Farmers' March",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2018-pic-from-farmers-march-in-maharashtra-viral-as-recent-10936",
        headline: "2018 Pic From Farmers' March In Maharashtra Viral As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/farmers-protest-2018-video-of-nihang-sikhs-rally-viral-as-recent-10943",
        headline:
          "Farmers' Protest: 2018 Video Of Nihang Sikhs' Rally Viral As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2018-photo-falsely-shared-as-banner-at-farmers-protest-10956",
        headline: "2018 Photo Falsely Shared As Banner At Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/farmers-protest-old-photo-of-separatists-disrespecting-tricolour-revived-11002",
        headline:
          "Farmers' Protest: Old Photo Of Separatists Disrespecting Tricolour Revived",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/farmers-protest-2018-aap-rally-video-viral-as-delhi-chalo-protest-11006",
        headline:
          "Farmers' Protest: 2018 AAP Rally Video Viral As Delhi Chalo Protest",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/pro-khalistan-slogans-from-cricket-world-cup-peddled-as-farmers-protest-10909",
        headline:
          "\u0935\u093f\u0936\u094d\u0935 \u0915\u092a \u092e\u0948\u091a \u092e\u0947\u0902 \u0916\u093e\u0932\u093f\u0938\u094d\u0924\u093e\u0928 \u0938\u092e\u0930\u094d\u0925\u0915 \u0928\u093e\u0930\u0947 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-woman-in-farmers-protest-photo-is-not-shaheen-baghs-bilkis-dadi-10925",
        headline:
          "\u09a8\u09be, \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u098f\u0987 \u09ac\u09c3\u09a6\u09cd\u09a7\u09be \u09b6\u09be\u09b9\u09bf\u09a8 \u09ac\u09be\u0997\u09c7\u09b0 \u09ac\u09bf\u09b2\u0995\u09bf\u09b8 \u09a6\u09be\u09a6\u09bf \u09a8\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-image-of-punjabs-highway-blocked-in-september-shared-as-delhi-10940",
        headline:
          "\u09b8\u09c7\u09aa\u09cd\u099f\u09c7\u09ae\u09cd\u09ac\u09b0\u09c7 \u09aa\u09be\u099e\u09cd\u099c\u09be\u09ac\u09c7 \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u099c\u09be\u09a4\u09c0\u09df \u09b8\u09a1\u09bc\u0995 \u09ac\u09a8\u09cd\u09a7\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018-pic-from-farmers-march-in-maharashtra-viral-as-recent-10961",
        headline:
          "\u09ae\u09b9\u09be\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09c7 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 '\u0995\u09c3\u09b7\u0995 \u09ae\u09be\u09b0\u09cd\u099a' \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018-photo-falsely-shared-as-banner-at-farmers-protest-10987",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u0995\u09b0\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-2018-video-of-nihang-sikhs-rally-viral-as-recent-11007",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09a8\u09bf\u09b9\u09be\u0999\u09cd\u0997 \u09b6\u09bf\u0996 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-image-jat-agitation-revived-ongoing-farmers-protest-1746193-2020-12-03",
        headline:
          "Fact Check: Old image from Jat agitation revived as ongoing farmers\u2019 protest\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/old-photo-farmer-long-march-revived-protests-delhi-1745530-2020-11-30",
        headline:
          "Fact Check: Old photo of farmers' long march revived as ongoing protests near Delhi",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-banner-against-lord-ram-not-from-ongoing-farmers-protest-1746189-2020-12-03",
        headline:
          "Fact Check: This banner against Lord Ram is not from the ongoing farmers\u2019 protest\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-man-with-bandaged-eye-viral-as-ex-armyman-injured-during-farmers-protest-1745830-2020-12-01",
        headline:
          "Fact Check: Man with bandaged eye viral as ex-armyman injured during farmers\u2019 protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-photo-from-london-passed-off-as-farmers-disrespecting-tricolour-1747222-2020-12-06",
        headline:
          "Fact Check: Old photo from London passed off as farmers disrespecting Tricolour",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-old-picture-of-a-lathi-wielding-woman-attributed-to-recent-farmers-protest/",
        headline:
          "Fact Check: Old picture of a lathi wielding woman attributed to recent farmers\u2019 protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-video-from-2019-shared-as-pro-khalistan-slogans-being-raised-at-farmers-protest/",
        headline:
          "Fact Check: Video from 2019 shared as pro-Khalistan slogans being raised at farmers protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-did-bjp-leader-sambit-patras-daughter-elope-with-a-man-from-the-minority-community-heres-the-truth/",
        headline:
          "Fact Check: Did BJP leader Sambit Patra\u2019s daughter elope with a man from the minority community? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/05/fact-check-video-of-policeman-removing-sikh-mans-turban-is-not-from-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Video of policeman removing Sikh man\u2019s turban is NOT from recent farmers\u2019 protest; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/05/fact-check-old-video-of-nihang-sikh-rally-falsely-linked-to-recent-farmers-protest/",
        headline:
          "Fact Check: Old video of Nihang Sikh rally falsely linked to recent farmers protest",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2018-pic-from-farmers-march-in-maharashtra-viral-as-recent-10961",
        headline:
          "\u09ae\u09b9\u09be\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09c7 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 '\u0995\u09c3\u09b7\u0995 \u09ae\u09be\u09b0\u09cd\u099a' \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-2018-video-of-nihang-sikhs-rally-viral-as-recent-10943",
        headline:
          "Farmers' Protest: 2018 Video Of Nihang Sikhs' Rally Viral As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-old-picture-of-woman-wielding-lathi-at-cops-revived-10929",
        headline:
          "Farmers' Protest: Old Picture Of Woman Wielding Lathi At Cops Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2017-image-of-jat-stir-in-haryana-peddled-as-farmers-protest-in-delhi-10963",
        headline:
          "2017 Image Of Jat Stir In Haryana Peddled As Farmers' Protest In Delhi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/photos-from-anti-caa-protests-falsely-linked-to-farmers-delhi-chalo-march-10996",
        headline:
          "Photos From Anti-CAA Protests Falsely Linked To Farmers' Delhi Chalo March",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2018-photo-falsely-shared-as-banner-at-farmers-protest-10956",
        headline: "2018 Photo Falsely Shared As Banner At Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2018-video-of-pro-khalistan-rally-in-us-falsely-linked-to-farmers-stir-10999",
        headline:
          "2018 Video Of Pro-Khalistan Rally In US Falsely Linked To Farmers Stir",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2018-pic-from-farmers-march-in-maharashtra-viral-as-recent-10936",
        headline: "2018 Pic From Farmers' March In Maharashtra Viral As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2013-photo-of-pro-khalistan-banners-linked-to-farmers-protest-10989",
        headline:
          "2013 Photo Of Pro-Khalistan Banners Linked To Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-2018-aap-rally-video-viral-as-delhi-chalo-protest-11006",
        headline:
          "Farmers' Protest: 2018 AAP Rally Video Viral As Delhi Chalo Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2019-photo-of-protest-against-article-370-linked-to-farmers-march-10908",
        headline:
          "2019 Photo Of Protest Against Article 370 Linked To Farmers' March",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-old-photo-of-separatists-disrespecting-tricolour-revived-11002",
        headline:
          "Farmers' Protest: Old Photo Of Separatists Disrespecting Tricolour Revived",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-woman-in-farmers-protest-photo-is-not-shaheen-baghs-bilkis-dadi-10925",
        headline:
          "\u09a8\u09be, \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u098f\u0987 \u09ac\u09c3\u09a6\u09cd\u09a7\u09be \u09b6\u09be\u09b9\u09bf\u09a8 \u09ac\u09be\u0997\u09c7\u09b0 \u09ac\u09bf\u09b2\u0995\u09bf\u09b8 \u09a6\u09be\u09a6\u09bf \u09a8\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-image-of-punjabs-highway-blocked-in-september-shared-as-delhi-10940",
        headline:
          "\u09b8\u09c7\u09aa\u09cd\u099f\u09c7\u09ae\u09cd\u09ac\u09b0\u09c7 \u09aa\u09be\u099e\u09cd\u099c\u09be\u09ac\u09c7 \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u099c\u09be\u09a4\u09c0\u09df \u09b8\u09a1\u09bc\u0995 \u09ac\u09a8\u09cd\u09a7\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-is-not-hathras-bhabhi-at-the-farmers-protest-10938",
        headline:
          "\u09a8\u09be, \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7 \u098f\u099f\u09bf '\u09b9\u09be\u09a5\u09b0\u09b8 \u09ad\u09be\u09ac\u09bf'\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/2011-video-of-police-removing-turban-of-a-sikh-protester-revived-falsely-linked-to-farmers-protest-10967",
        headline:
          "\u09e8\u09e6\u09e7\u09e7'\u09b0 \u09b6\u09bf\u0996 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u0995\u09be\u09b0\u09c0\u09b0 \u09aa\u09be\u0997\u09dc\u09bf \u0996\u09cb\u09b2\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-2018-video-of-nihang-sikhs-rally-viral-as-recent-11007",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09a8\u09bf\u09b9\u09be\u0999\u09cd\u0997 \u09b6\u09bf\u0996 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2017-image-of-jat-stir-in-haryana-falsely-shared-as-farmers-protest-in-delhi-10950",
        headline:
          "\u09b9\u09b0\u09bf\u09df\u09be\u09a8\u09be\u09b0 \u099c\u09be\u09a0 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09a4\u09c7 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2018-photo-falsely-shared-as-banner-at-farmers-protest-10987",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u0995\u09b0\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-man-raising-pro-khalistan-slogans-in-uk-passed-off-as-farmers-protests-in-delhi-671129",
        headline:
          "Fact check: Video of man raising pro-Khalistan slogans in UK passed off as farmers protests in Delhi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2019-image-of-article-370-protests-passed-off-as-2020-farmers-protests-in-delhi-671205",
        headline:
          "Fact Check: 2019 image of Article 370 protests passed off as 2020 farmers' protests in Delhi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-woman-protestor-at-farmers-rally-is-not-shaheen-baghs-bilkis-dadi-671139",
        headline:
          "Fact Check: Old woman protestor at farmer's rally is not Shaheen Bagh's Bilkis Dadi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-2017-jat-protests-passed-off-as-farmers-protest-671330",
        headline:
          "FACT CHECK: Viral image of 2017 Jat protests passed off as farmers protest",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/india-today-falsely-reportes-shehla-rashid-was-arrested-in-2016-jnu-sedition-case/",
        headline:
          "India Today falsely reports Shehla Rashid was arrested in 2016 JNU sedition case",
      },
      {
        url:
          "https://www.altnews.in/old-unrelated-photo-shared-as-farmers-protesting-revocation-of-article-370-and-uapa/",
        headline:
          "Old, unrelated photo shared as farmers protesting revocation of Article 370",
      },
      {
        url:
          "https://factly.in/telugu-this-video-shows-police-detaining-people-without-mask-in-ujjain-not-delhi/",
        headline:
          " \u0c2e\u0c3e\u0c38\u0c4d\u0c15\u0c4d \u0c27\u0c30\u0c3f\u0c02\u0c1a\u0c15\u0c41\u0c02\u0c21\u0c3e \u0c2c\u0c2f\u0c1f \u0c24\u0c3f\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c3f\u0c28\u0c3f \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c09\u0c1c\u0c4d\u0c1c\u0c48\u0c28\u0c4d \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40\u0c15\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-old-protest-photos-against-abrogation-of-article-of-article-370-35a-linked-to-ongoing-farmers-protests/",
        headline:
          " \u0c06\u0c30\u0c4d\u0c1f\u0c3f\u0c15\u0c32\u0c4d 370, 35A \u0c2a\u0c41\u0c28\u0c30\u0c41\u0c26\u0c4d\u0c27\u0c30\u0c23 \u0c15\u0c4b\u0c30\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c24\u0c3e\u0c1c\u0c3e \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c35\u0c3f \u0c15\u0c3e\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/unrelated-image-shared-as-retired-army-captain-injured-in-the-recent-farmers-protest/",
        headline:
          " Unrelated image shared as retired army captain injured in the recent farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-image-shared-as-retired-army-captain-injured-in-the-recent-farmers-protest/",
        headline:
          " \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c06\u0c02\u0c26\u0c4b\u0c33\u0c28\u0c32\u0c4d\u0c32\u0c4b \u0c30\u0c3f\u0c1f\u0c48\u0c30\u0c4d\u0c21\u0c4d \u0c06\u0c30\u0c4d\u0c2e\u0c40 \u0c15\u0c46\u0c2a\u0c4d\u0c1f\u0c46\u0c28\u0c4d \u0c15\u0c3f \u0c17\u0c3e\u0c2f\u0c2e\u0c48\u0c02\u0c26\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c42 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/30/fact-check-did-smriti-irani-meet-aimim-leader-asaduddin-owaisi-for-poll-alliance-heres-the-truth/",
        headline:
          "Fact Check: Did Smriti Irani meet AIMIM leader Asaduddin Owaisi for poll alliance? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/04/fact-check-2019-picture-shared-as-farmers-demanding-restoration-of-article-370-heres-the-truth/",
        headline:
          "Fact Check: 2019 picture shared as farmers demanding restoration of Article 370; here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/retired-army-officer-falsely-identified-as-injured-protester-at-farmers-march-10949",
        headline:
          "Retired Army Officer Falsely Identified As Injured Protester At Farmers March",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2019-video-of-mahashay-dharampal-gulati-shared-as-his-final-moments-10982",
        headline:
          "2019 Video Of Mahashay Dharampal Gulati Shared As His Final Moments",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/farmers-stir-photo-of-actress-sonia-mann-shared-with-a-fake-backstory-10985",
        headline:
          "Farmers' Stir: Photo Of Actress Sonia Mann Shared With A Fake Backstory",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2019-video-of-mahashay-dharampal-gulati-shared-as-mdh-masala-king-final-moments-10986",
        headline:
          "\u092e\u0939\u093e\u0936\u092f \u0927\u0930\u094d\u092e\u092a\u093e\u0932 \u0917\u0941\u0932\u093e\u091f\u0940 \u0915\u0947 \u0905\u0902\u0924\u093f\u092e \u0915\u094d\u0937\u0923 \u0915\u094b \u0926\u093f\u0916\u093e\u0924\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u0930\u0905\u0938\u0932  2019 \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photo-of-actress-and-singer-viral-as-california-engineer-doctor-couple-in-kisan-protest-10991",
        headline:
          "\u0938\u094b\u0928\u093f\u092f\u093e \u092e\u093e\u0928 \u0914\u0930 \u092e\u0939\u0924\u093e\u092c \u0935\u093f\u0930\u094d\u0915 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/retired-army-officer-falsely-identified-as-injured-protester-at-farmers-march-11001",
        headline:
          "\u0985\u09ac\u09b8\u09b0\u09aa\u09cd\u09b0\u09be\u09aa\u09cd\u09a4 \u09b8\u09c7\u09a8\u09be \u0986\u09a7\u09bf\u0995\u09be\u09b0\u09bf\u0995\u0995\u09c7 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7 \u0986\u09b9\u09a4 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/30/fact-check-did-smriti-irani-meet-aimim-leader-asaduddin-owaisi-for-poll-alliance-heres-the-truth/",
        headline:
          "Fact Check: Did Smriti Irani meet AIMIM leader Asaduddin Owaisi for poll alliance? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/04/fact-check-2019-picture-shared-as-farmers-demanding-restoration-of-article-370-heres-the-truth/",
        headline:
          "Fact Check: 2019 picture shared as farmers demanding restoration of Article 370; here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/retired-army-officer-falsely-identified-as-injured-protester-at-farmers-march-10949",
        headline:
          "Retired Army Officer Falsely Identified As Injured Protester At Farmers March",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-stir-photo-of-actress-sonia-mann-shared-with-a-fake-backstory-10985",
        headline:
          "Farmers' Stir: Photo Of Actress Sonia Mann Shared With A Fake Backstory",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2019-video-of-mahashay-dharampal-gulati-shared-as-his-final-moments-10982",
        headline:
          "2019 Video Of Mahashay Dharampal Gulati Shared As His Final Moments",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/retired-army-officer-falsely-identified-as-injured-protester-at-farmers-march-11001",
        headline:
          "\u0985\u09ac\u09b8\u09b0\u09aa\u09cd\u09b0\u09be\u09aa\u09cd\u09a4 \u09b8\u09c7\u09a8\u09be \u0986\u09a7\u09bf\u0995\u09be\u09b0\u09bf\u0995\u0995\u09c7 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7 \u0986\u09b9\u09a4 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/photo-of-actress-and-singer-viral-as-california-engineer-doctor-couple-in-kisan-protest-10991",
        headline:
          "\u0938\u094b\u0928\u093f\u092f\u093e \u092e\u093e\u0928 \u0914\u0930 \u092e\u0939\u0924\u093e\u092c \u0935\u093f\u0930\u094d\u0915 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2019-video-of-mahashay-dharampal-gulati-shared-as-mdh-masala-king-final-moments-10986",
        headline:
          "\u092e\u0939\u093e\u0936\u092f \u0927\u0930\u094d\u092e\u092a\u093e\u0932 \u0917\u0941\u0932\u093e\u091f\u0940 \u0915\u0947 \u0905\u0902\u0924\u093f\u092e \u0915\u094d\u0937\u0923 \u0915\u094b \u0926\u093f\u0916\u093e\u0924\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u0930\u0905\u0938\u0932 2019 \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-hyd-police-chief-did-not-suspend-92-officers-for-collaborating-with-ghmc-poll-candidates-671291",
        headline:
          "Fact Check: Hyd police chief did not suspend 92 officers for collaborating with GHMC poll candidates",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-police-detained-people-without-masks-is-from-mp-not-delhi-671187",
        headline:
          "FACT CHECK: Viral video of police detained people without masks is from MP, not Delhi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-picture-of-smriti-irani-asaduddin-owaisi-shared-with-false-claim-671134",
        headline:
          "Fact check: Old picture of Smriti Irani, Asaduddin Owaisi shared with false claim",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-purported-last-audio-message-of-cpi-party-leader-nomula-narasimhaiah-is-fake-671260",
        headline:
          "Fact check: Purported last audio message of late TRS leader Nomula Narasimhaiah is fake",
      },
    ],
  },
  numpy_seed: 20,
  number_of_articles: 184,
}
