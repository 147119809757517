export const data = {
  mdsDat: {
    x: [
      -0.022575539555297522,
      0.2614957565817505,
      -0.24789427839288067,
      0.2684904681905832,
      -0.20244177480748568,
      0.11341516758936465,
      -0.17048979960603444,
    ],
    y: [
      -0.1818234613974129,
      -0.16921126016051594,
      0.02088092045734569,
      0.35585538377349163,
      0.04145915594454801,
      -0.1876733979639822,
      0.12051265934652557,
    ],
    topics: [1, 2, 3, 4, 5, 6, 7],
    cluster: [1, 1, 1, 1, 1, 1, 1],
    Freq: [
      12.68358440474309,
      15.580082289381783,
      14.426828939448374,
      10.507664982363272,
      13.22098285821855,
      11.413320511787827,
      22.16753601405711,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
    ],
    Freq: [
      12.0,
      12.0,
      27.0,
      24.0,
      7.0,
      8.0,
      18.0,
      6.0,
      6.0,
      6.0,
      1.0001912273454547,
      2.0003824546909095,
      2.0003824546909095,
      1.0001912273454547,
      1.0001912273454547,
      2.0003824546909095,
      1.0001912273454547,
      3.0005736820363644,
      2.0003824546909095,
      2.0003824546909095,
      6.001147364072729,
      6.001147364072729,
      6.001147364072729,
      6.001147364072729,
      6.001147364072729,
      5.000956136727274,
      4.000764909381819,
      4.000764909381819,
      4.000764909381819,
      4.000764909381819,
      4.000764909381819,
      4.000764909381819,
      3.0000716594437473,
      6.000143318887495,
      3.0000716594437473,
      3.0000716594437473,
      6.000143318887495,
      12.00028663777499,
      6.000143318887495,
      3.0000716594437473,
      12.00028663777499,
      6.000143318887495,
      8.000191091849993,
      6.000143318887495,
      6.000143318887495,
      6.000143318887495,
      1.0017118961730191,
      1.0017118961730191,
      2.0034237923460383,
      1.0017118961730191,
      1.0017118961730191,
      3.005135688519057,
      6.010271377038114,
      2.0034237923460383,
      2.0034237923460383,
      2.0034237923460383,
      5.008559480865095,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      6.010271377038114,
      11.01883085790321,
      9.015407065557172,
      10.01711896173019,
      6.010271377038114,
      4.006847584692077,
      4.006847584692077,
      3.005135688519057,
      3.0001195191023404,
      1.0000398397007801,
      1.0000398397007801,
      1.0000398397007801,
      2.0000796794015603,
      1.0000398397007801,
      2.0000796794015603,
      1.0000398397007801,
      1.0000398397007801,
      4.000159358803121,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      0.9997236353063887,
      1.9994472706127775,
      2.9991709059191662,
      0.9997236353063887,
      0.9997236353063887,
      1.9994472706127775,
      0.9997236353063887,
      1.9994472706127775,
      1.9994472706127775,
      0.9997236353063887,
      3.998894541225555,
      3.998894541225555,
      2.9991709059191662,
      2.9991709059191662,
      2.9991709059191662,
      2.9991709059191662,
      2.9991709059191662,
      1.9994472706127775,
      1.9994472706127775,
      2.9991709059191662,
      7.99778908245111,
      5.9983418118383325,
      2.9991709059191662,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      4.000097093655161,
      3.000072820241371,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      7.000169913896532,
      5.000121367068951,
      4.000097093655161,
      3.000072820241371,
      3.000072820241371,
      3.000072820241371,
      3.000072820241371,
      2.0000485468275806,
      2.0000485468275806,
      6.000145640482742,
      3.000072820241371,
      2.996688950145189,
      5.993377900290378,
      2.996688950145189,
      2.996688950145189,
      4.994481583575315,
      2.996688950145189,
      2.996688950145189,
      2.996688950145189,
      5.993377900290378,
      4.994481583575315,
      7.9911705337205055,
      6.992274217005441,
      3.9955852668602527,
      3.9955852668602527,
      3.9955852668602527,
      3.9955852668602527,
      3.9955852668602527,
      5.993377900290378,
      6.992274217005441,
      8.990066850435568,
      6.992274217005441,
    ],
    Term: [
      "temple",
      "ram",
      "farmer",
      "protest",
      "crash",
      "donate",
      "link",
      "virat",
      "anushka",
      "khan",
      "staircase",
      "really",
      "gerua",
      "swami",
      "management",
      "see",
      "chicago",
      "daughter",
      "verification",
      "crop",
      "imran_khan",
      "imran",
      "virat",
      "anushka",
      "khan",
      "government",
      "anushka_sharma",
      "first",
      "sharma",
      "virat_kohli",
      "praise",
      "modi",
      "control",
      "solar_power",
      "population_control",
      "pm_modi",
      "balaji",
      "temple",
      "reliance",
      "pm",
      "ram",
      "tirupati_balaji",
      "donate",
      "tirupati",
      "power",
      "mandir",
      "washington",
      "sign",
      "pok",
      "current",
      "black",
      "support_farmer",
      "hindi",
      "march",
      "vandalising",
      "farmers",
      "farmers_protest",
      "coca_cola",
      "signboards_linke",
      "cola",
      "saffron",
      "deface",
      "support",
      "coca",
      "violence",
      "signboard",
      "protest",
      "link",
      "farmer",
      "man",
      "capitol",
      "us_capitol",
      "flag",
      "rajnath_singh",
      "lawyer",
      "tanker",
      "repeal",
      "hyderabad",
      "withdraw",
      "become",
      "passport",
      "social",
      "bjp",
      "harish",
      "caa",
      "rajnath",
      "harish_salve",
      "leader",
      "singh",
      "bjp_leaders",
      "salve",
      "audio",
      "wikileaks",
      "pride",
      "rahul_gandhi",
      "islamophobic",
      "page",
      "jnu",
      "tweet",
      "oppose",
      "azharudeen",
      "parody",
      "family",
      "mumbai",
      "god",
      "gandhi",
      "rahul",
      "cricketer",
      "police",
      "street",
      "dismissal",
      "hindu",
      "farmer",
      "protest",
      "morph",
      "mishaps",
      "hero",
      "army",
      "moxie",
      "embraced",
      "plane_crash",
      "die",
      "training",
      "vaccinate",
      "pfizer",
      "crash",
      "indonesia",
      "plane",
      "signal",
      "flight",
      "linked_indonesia",
      "photos",
      "work",
      "testing",
      "baby",
      "link",
      "offer",
      "railway",
      "platform",
      "board_exam",
      "woman",
      "prayer",
      "muslim",
      "admit",
      "delhi",
      "sikh_man",
      "mosque",
      "station",
      "diplomat",
      "railway_station",
      "mamata",
      "balakot",
      "prayagraj",
      "sikh",
      "man",
      "farmer",
      "protest",
    ],
    Total: [
      12.0,
      12.0,
      27.0,
      24.0,
      7.0,
      8.0,
      18.0,
      6.0,
      6.0,
      6.0,
      1.0001912273454547,
      2.0003824546909095,
      2.0003824546909095,
      1.0001912273454547,
      1.0001912273454547,
      2.0003824546909095,
      1.0001912273454547,
      3.0005736820363644,
      2.0003824546909095,
      2.0003824546909095,
      6.001147364072729,
      6.001147364072729,
      6.001147364072729,
      6.001147364072729,
      6.001147364072729,
      5.000956136727274,
      4.000764909381819,
      4.000764909381819,
      4.000764909381819,
      4.000764909381819,
      4.000764909381819,
      7.999732885540629,
      3.0000716594437473,
      6.000143318887495,
      3.0000716594437473,
      3.0000716594437473,
      6.000143318887495,
      12.00028663777499,
      6.000143318887495,
      3.0000716594437473,
      12.00028663777499,
      6.000143318887495,
      8.000191091849993,
      6.000143318887495,
      6.000143318887495,
      6.000143318887495,
      1.0017118961730191,
      1.0017118961730191,
      2.0034237923460383,
      1.0017118961730191,
      1.0017118961730191,
      3.005135688519057,
      6.010271377038114,
      2.0034237923460383,
      2.0034237923460383,
      2.0034237923460383,
      5.008559480865095,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      3.005135688519057,
      8.009718647650892,
      24.009446886746982,
      18.011112925621294,
      27.00497489461687,
      14.002569867457346,
      6.007230039382986,
      6.007230039382986,
      5.005518143209967,
      3.0001195191023404,
      1.0000398397007801,
      1.0000398397007801,
      1.0000398397007801,
      2.0000796794015603,
      1.0000398397007801,
      2.0000796794015603,
      1.0000398397007801,
      1.0000398397007801,
      4.000159358803121,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      3.0001195191023404,
      0.9997236353063887,
      1.9994472706127775,
      2.9991709059191662,
      0.9997236353063887,
      0.9997236353063887,
      1.9994472706127775,
      0.9997236353063887,
      1.9994472706127775,
      1.9994472706127775,
      0.9997236353063887,
      3.998894541225555,
      3.998894541225555,
      2.9991709059191662,
      2.9991709059191662,
      2.9991709059191662,
      2.9991709059191662,
      2.9991709059191662,
      1.9994472706127775,
      1.9994472706127775,
      3.9980672226342295,
      27.00497489461687,
      24.009446886746982,
      5.995859856064355,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      4.000097093655161,
      3.000072820241371,
      1.0000242734137903,
      1.0000242734137903,
      1.0000242734137903,
      7.000169913896532,
      5.000121367068951,
      4.000097093655161,
      3.000072820241371,
      3.000072820241371,
      3.000072820241371,
      3.000072820241371,
      2.0000485468275806,
      2.0000485468275806,
      7.000336867828197,
      18.011112925621294,
      2.996688950145189,
      5.993377900290378,
      2.996688950145189,
      2.996688950145189,
      4.994481583575315,
      2.996688950145189,
      2.996688950145189,
      2.996688950145189,
      5.993377900290378,
      4.994481583575315,
      7.9911705337205055,
      6.992274217005441,
      3.9955852668602527,
      3.9955852668602527,
      3.9955852668602527,
      3.9955852668602527,
      3.9955852668602527,
      8.998513588809434,
      14.002569867457346,
      27.00497489461687,
      24.009446886746982,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      2.0649,
      1.3719,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.8592,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.9361,
      1.6489,
      1.1572,
      1.244,
      0.9444,
      1.0903,
      1.5311,
      1.5311,
      1.4259,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.2531,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      2.0234,
      1.7359,
      0.8065,
      0.6364,
      1.3306,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.1704,
      2.0162,
      0.378,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.5065,
      1.1001,
      0.8121,
      0.4066,
      0.2729,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.9416,
      -4.2485,
      -4.2485,
      -4.9416,
      -4.9416,
      -4.2485,
      -4.9416,
      -3.843,
      -4.2485,
      -4.2485,
      -3.1499,
      -3.1499,
      -3.1499,
      -3.1499,
      -3.1499,
      -3.3322,
      -3.5553,
      -3.5553,
      -3.5553,
      -3.5553,
      -3.5553,
      -3.5553,
      -4.0489,
      -3.3557,
      -4.0489,
      -4.0489,
      -3.3557,
      -2.6626,
      -3.3557,
      -4.0489,
      -2.6626,
      -3.3557,
      -3.0681,
      -3.3557,
      -3.3557,
      -3.3557,
      -5.0689,
      -5.0689,
      -4.3758,
      -5.0689,
      -5.0689,
      -3.9703,
      -3.2771,
      -4.3758,
      -4.3758,
      -4.3758,
      -3.4595,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.2771,
      -2.671,
      -2.8717,
      -2.7663,
      -3.2771,
      -3.6826,
      -3.6826,
      -3.9703,
      -3.655,
      -4.7536,
      -4.7536,
      -4.7536,
      -4.0604,
      -4.7536,
      -4.0604,
      -4.7536,
      -4.7536,
      -3.3673,
      -3.655,
      -3.655,
      -3.655,
      -3.655,
      -3.655,
      -3.655,
      -3.655,
      -3.655,
      -3.655,
      -4.9836,
      -4.2905,
      -3.885,
      -4.9836,
      -4.9836,
      -4.2905,
      -4.9836,
      -4.2905,
      -4.2905,
      -4.9836,
      -3.5973,
      -3.5973,
      -3.885,
      -3.885,
      -3.885,
      -3.885,
      -3.885,
      -4.2905,
      -4.2905,
      -3.885,
      -2.9042,
      -3.1918,
      -3.885,
      -4.8363,
      -4.8363,
      -4.8363,
      -4.8363,
      -4.8363,
      -3.45,
      -3.7377,
      -4.8363,
      -4.8363,
      -4.8363,
      -2.8904,
      -3.2268,
      -3.45,
      -3.7377,
      -3.7377,
      -3.7377,
      -3.7377,
      -4.1431,
      -4.1431,
      -3.0445,
      -3.7377,
      -4.4026,
      -3.7095,
      -4.4026,
      -4.4026,
      -3.8918,
      -4.4026,
      -4.4026,
      -4.4026,
      -3.7095,
      -3.8918,
      -3.4218,
      -3.5553,
      -4.115,
      -4.115,
      -4.115,
      -4.115,
      -4.115,
      -3.7095,
      -3.5553,
      -3.304,
      -3.5553,
    ],
  },
  "token.table": {
    Topic: [
      7,
      1,
      1,
      6,
      4,
      5,
      1,
      6,
      2,
      7,
      4,
      4,
      4,
      3,
      7,
      4,
      1,
      3,
      1,
      3,
      3,
      3,
      2,
      6,
      5,
      1,
      3,
      1,
      3,
      7,
      6,
      7,
      5,
      2,
      6,
      5,
      3,
      5,
      7,
      3,
      3,
      1,
      1,
      3,
      6,
      5,
      1,
      5,
      1,
      4,
      4,
      6,
      3,
      5,
      7,
      4,
      1,
      1,
      6,
      5,
      5,
      1,
      4,
      4,
      2,
      3,
      6,
      7,
      6,
      7,
      3,
      6,
      7,
      1,
      2,
      3,
      6,
      1,
      2,
      7,
      5,
      7,
      7,
      6,
      5,
      7,
      7,
      5,
      5,
      5,
      4,
      6,
      6,
      6,
      6,
      7,
      2,
      2,
      3,
      5,
      2,
      2,
      1,
      7,
      7,
      5,
      3,
      5,
      7,
      5,
      5,
      7,
      7,
      4,
      4,
      2,
      1,
      2,
      4,
      3,
      4,
      1,
      1,
      3,
      6,
      3,
      5,
      3,
      3,
      7,
      7,
      4,
      4,
      2,
      1,
      7,
      5,
      3,
      3,
      1,
      4,
      2,
      6,
      2,
      2,
      6,
      5,
      1,
      3,
      6,
      3,
      1,
      3,
      1,
      1,
      3,
      5,
      4,
      7,
      6,
    ],
    Freq: [
      1.001104902747631,
      0.9998088092154513,
      0.9998088092154515,
      0.9999757271753941,
      0.9999601618863584,
      1.000276441092169,
      0.14285026833433556,
      0.8571016100060134,
      0.9999761140893012,
      1.0011049027476309,
      0.9999601618863584,
      0.9999601618863584,
      0.9999601618863584,
      0.9982910294071985,
      1.001104902747631,
      0.9999601618863584,
      0.3329321479097917,
      0.6658642958195834,
      0.9998088092154515,
      0.9982910294071986,
      0.9982910294071986,
      0.9982910294071986,
      0.9999761140893012,
      0.999975727175394,
      1.000276441092169,
      0.9998088092154515,
      0.9982910294071985,
      0.9998088092154513,
      0.9982910294071986,
      1.001104902747631,
      0.999975727175394,
      1.0011049027476309,
      1.000276441092169,
      0.9999761140893012,
      0.9999757271753941,
      1.000276441092169,
      0.37030214021763025,
      0.29624171217410417,
      0.3332719261958672,
      0.9982910294071985,
      0.9982910294071986,
      0.9998088092154515,
      0.39955903520458097,
      0.5993385528068714,
      0.999975727175394,
      1.000276441092169,
      0.9998088092154515,
      1.000276441092169,
      0.9998088092154513,
      0.9999601618863584,
      0.9999601618863584,
      0.9999757271753941,
      0.9982910294071986,
      0.7503625709483126,
      0.25012085698277087,
      0.9999601618863584,
      0.9998088092154513,
      0.9998088092154513,
      0.9999757271753941,
      1.000276441092169,
      1.000276441092169,
      0.9998088092154513,
      0.9999601618863584,
      0.9999601618863584,
      0.11104255513022442,
      0.4996914980860098,
      0.16656383269533662,
      0.22208511026044883,
      0.999975727175394,
      1.0011049027476309,
      0.42849277359753024,
      0.07141546226625504,
      0.4999082358637853,
      0.9998088092154515,
      0.9999761140893012,
      0.9982910294071985,
      0.9999757271753941,
      0.5000166952111522,
      0.37501252140836416,
      0.12500417380278805,
      0.5003452502255751,
      0.5003452502255751,
      1.0011049027476309,
      0.9999757271753941,
      1.000276441092169,
      1.001104902747631,
      1.001104902747631,
      1.000276441092169,
      1.000276441092169,
      1.000276441092169,
      0.9999601618863584,
      0.9999757271753941,
      0.999975727175394,
      0.9999757271753941,
      0.9999757271753941,
      1.001104902747631,
      0.9999761140893012,
      0.9999761140893012,
      0.9982910294071985,
      1.000276441092169,
      0.9999761140893012,
      0.9999761140893012,
      0.9998088092154515,
      1.0011049027476309,
      1.001104902747631,
      1.000276441092169,
      0.4581529950226346,
      0.2499016336487098,
      0.2915519059234947,
      1.000276441092169,
      1.000276441092169,
      1.001104902747631,
      1.0011049027476309,
      0.9999601618863584,
      0.9999601618863584,
      0.9999761140893012,
      0.9998088092154515,
      0.9999761140893012,
      0.9999601618863584,
      0.9982910294071986,
      0.9999601618863584,
      0.9998088092154515,
      0.9998088092154515,
      0.9982910294071985,
      0.999975727175394,
      0.7490899823004011,
      0.2496966607668004,
      0.9982910294071986,
      0.3333883946934086,
      0.6667767893868172,
      1.001104902747631,
      0.9999601618863584,
      0.9999601618863584,
      0.9999761140893012,
      0.9998088092154515,
      1.001104902747631,
      1.000276441092169,
      0.9982910294071986,
      0.9982910294071986,
      0.9998088092154515,
      0.9999601618863584,
      0.9999761140893012,
      0.9999757271753941,
      0.9999761140893012,
      0.9999761140893012,
      0.9999757271753941,
      1.000276441092169,
      0.3329321479097917,
      0.6658642958195834,
      0.9999757271753941,
      0.9982910294071985,
      0.9998088092154515,
      0.9982910294071986,
      0.9998088092154513,
      0.9998088092154515,
      0.9982910294071985,
      1.000276441092169,
      0.9999601618863584,
      1.001104902747631,
      0.9999757271753941,
    ],
    Term: [
      "admit",
      "anushka",
      "anushka_sharma",
      "army",
      "audio",
      "azharudeen",
      "baby",
      "baby",
      "balaji",
      "balakot",
      "become",
      "bjp",
      "bjp_leaders",
      "black",
      "board_exam",
      "caa",
      "capitol",
      "capitol",
      "chicago",
      "coca",
      "coca_cola",
      "cola",
      "control",
      "crash",
      "cricketer",
      "crop",
      "current",
      "daughter",
      "deface",
      "delhi",
      "die",
      "diplomat",
      "dismissal",
      "donate",
      "embraced",
      "family",
      "farmer",
      "farmer",
      "farmer",
      "farmers",
      "farmers_protest",
      "first",
      "flag",
      "flag",
      "flight",
      "gandhi",
      "gerua",
      "god",
      "government",
      "harish",
      "harish_salve",
      "hero",
      "hindi",
      "hindu",
      "hindu",
      "hyderabad",
      "imran",
      "imran_khan",
      "indonesia",
      "islamophobic",
      "jnu",
      "khan",
      "lawyer",
      "leader",
      "link",
      "link",
      "link",
      "link",
      "linked_indonesia",
      "mamata",
      "man",
      "man",
      "man",
      "management",
      "mandir",
      "march",
      "mishaps",
      "modi",
      "modi",
      "modi",
      "morph",
      "morph",
      "mosque",
      "moxie",
      "mumbai",
      "muslim",
      "offer",
      "oppose",
      "page",
      "parody",
      "passport",
      "pfizer",
      "photos",
      "plane",
      "plane_crash",
      "platform",
      "pm",
      "pm_modi",
      "pok",
      "police",
      "population_control",
      "power",
      "praise",
      "prayagraj",
      "prayer",
      "pride",
      "protest",
      "protest",
      "protest",
      "rahul",
      "rahul_gandhi",
      "railway",
      "railway_station",
      "rajnath",
      "rajnath_singh",
      "ram",
      "really",
      "reliance",
      "repeal",
      "saffron",
      "salve",
      "see",
      "sharma",
      "sign",
      "signal",
      "signboard",
      "signboard",
      "signboards_linke",
      "sikh",
      "sikh",
      "sikh_man",
      "singh",
      "social",
      "solar_power",
      "staircase",
      "station",
      "street",
      "support",
      "support_farmer",
      "swami",
      "tanker",
      "temple",
      "testing",
      "tirupati",
      "tirupati_balaji",
      "training",
      "tweet",
      "us_capitol",
      "us_capitol",
      "vaccinate",
      "vandalising",
      "verification",
      "violence",
      "virat",
      "virat_kohli",
      "washington",
      "wikileaks",
      "withdraw",
      "woman",
      "work",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6, 7],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://factly.in/time-magazines-article-on-up-governments-covid-19-management-is-sponsored-content/",
        headline:
          " \u2018TIME\u2019 magazine\u2019s article on UP government\u2019s COVID-19 management is \u2018sponsored content\u2019",
      },
      {
        url:
          "https://factly.in/these-pictures-do-not-show-virat-anushkas-newborn-baby/",
        headline:
          " These pictures do not show Virat-Anushka\u2019s, newborn baby",
      },
      {
        url:
          "https://factly.in/telugu-these-pictures-do-not-show-virat-anushkas-newborn-baby/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c35\u0c3f\u0c30\u0c3e\u0c1f\u0c4d-\u0c05\u0c28\u0c41\u0c37\u0c4d\u0c15\u0c32 \u0c15\u0c42\u0c24\u0c41\u0c30\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/chicago-art-institutes-staircase-is-not-being-lit-with-swami-vivekanandas-1893-speech-every-day/",
        headline:
          " Chicago Art Institute\u2019s staircase is not being lit with Swami Vivekananda\u2019s 1893 speech every day",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-pakistan-pm-imran-khan-narendra-modi-indian-army-shefali-vaidya-cropped-video-edited-video-73-years-iron-willed-11502",
        headline:
          "Cropped Video Of Imran Khan Shared To Claim He Praised Modi Government",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/virat-kohli-anushka-sharma-daughter-photo-newborn-11509",
        headline:
          "First Look Of Virat Kohli And Anushka Sharma's Daughter? Not Really",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/imran-khan-video-viral-as-praises-modi-sarkar-73-years-india-never-had-government-this-strong-11512",
        headline:
          "\u0907\u092e\u0930\u093e\u0928 \u0916\u093e\u0928 \u0915\u0947 \u0915\u094d\u0930\u0949\u092a\u094d\u0921 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e \u0926\u093e\u0935\u093e- \u092d\u093e\u0930\u0924 \u092e\u0947\u0902 73 \u0938\u093e\u0932\u094b\u0902 \u092e\u0947\u0902 \u0910\u0938\u0940 \u0938\u0930\u0915\u093e\u0930 \u0928\u0939\u0940\u0902 \u0926\u0947\u0916\u0940",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news-fact-check-man-saffron-flag-capitol-hill-united-states-dc-siege-pro-tump-supporters-hindutva-members-11496",
        headline:
          "\u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987: US Capitol \u0985\u09ac\u09b0\u09cb\u09a7\u09c7 \u09ae\u09be\u09b0\u09cd\u0995\u09bf\u09a8 \u0993 \u0997\u09c7\u09b0\u09c1\u09df\u09be \u09aa\u09a4\u09be\u0995\u09be \u09b9\u09be\u09a4\u09c7 \u098f\u0995 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/swami-vivekananda-birth-anniversary-abhishek-banerjee-banner-on-auto-viral-facebook-11515",
        headline:
          "\u09e8\u09e6\u09e8\u09e6'\u09b0 Vivekananda \u099c\u09a8\u09cd\u09ae\u09a6\u09bf\u09ac\u09b8\u09c7 Abhishek Banerjee-\u09b0 \u099b\u09ac\u09bf\u09b0 Banner \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news-fact-check-pakistan-pm-imran-khan-narendra-modi-indian-army-shefali-vaidya-cropped-video-edited-video-73-years-iron-willed-11559",
        headline:
          "Narendra Modi \u09b8\u09b0\u0995\u09be\u09b0\u09c7\u09b0 \u09aa\u09cd\u09b0\u09b6\u0982\u09b8\u09be \u0995\u09b0\u09c7\u099b\u09c7 Imran Khan \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/virat-kohli-anushka-sharma-daughter-photo-newborn-11564",
        headline:
          "Virat Kohli \u0993 Anushka Sharma \u09ae\u09c7\u09df\u09c7\u0995\u09c7 \u09aa\u09cd\u09b0\u09a5\u09ae \u09a6\u09c7\u0996\u09be \u0997\u09c7\u09b2 \u098f\u0987 \u099b\u09ac\u09bf\u09a4\u09c7? \u09a8\u09be, \u09a4\u09be \u09a8\u09df",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-an-australian-astronaut-jumping-from-spaceship-to-earth-1758971-2021-01-14",
        headline:
          "Fact Check: This is not an Australian astronaut jumping from spaceship to Earth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/virat-kohli-anushka-sharma-daughter-photo-newborn-11564",
        headline:
          "Virat Kohli \u0993 Anushka Sharma \u09ae\u09c7\u09df\u09c7\u0995\u09c7 \u09aa\u09cd\u09b0\u09a5\u09ae \u09a6\u09c7\u0996\u09be \u0997\u09c7\u09b2 \u098f\u0987 \u099b\u09ac\u09bf\u09a4\u09c7? \u09a8\u09be, \u09a4\u09be \u09a8\u09df",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/imran-khan-video-viral-as-praises-modi-sarkar-73-years-india-never-had-government-this-strong-11512",
        headline:
          "\u0907\u092e\u0930\u093e\u0928 \u0916\u093e\u0928 \u0915\u0947 \u0915\u094d\u0930\u0949\u092a\u094d\u0921 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e \u0926\u093e\u0935\u093e- \u092d\u093e\u0930\u0924 \u092e\u0947\u0902 73 \u0938\u093e\u0932\u094b\u0902 \u092e\u0947\u0902 \u0910\u0938\u0940 \u0938\u0930\u0915\u093e\u0930 \u0928\u0939\u0940\u0902 \u0926\u0947\u0916\u0940",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/swami-vivekananda-birth-anniversary-abhishek-banerjee-banner-on-auto-viral-facebook-11515",
        headline:
          "\u09e8\u09e6\u09e8\u09e6'\u09b0 Vivekananda \u099c\u09a8\u09cd\u09ae\u09a6\u09bf\u09ac\u09b8\u09c7 Abhishek Banerjee-\u09b0 \u099b\u09ac\u09bf\u09b0 Banner \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-pakistan-pm-imran-khan-narendra-modi-indian-army-shefali-vaidya-cropped-video-edited-video-73-years-iron-willed-11559",
        headline:
          "Narendra Modi \u09b8\u09b0\u0995\u09be\u09b0\u09c7\u09b0 \u09aa\u09cd\u09b0\u09b6\u0982\u09b8\u09be \u0995\u09b0\u09c7\u099b\u09c7 Imran Khan \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/virat-kohli-anushka-sharma-daughter-photo-newborn-11509",
        headline:
          "First Look Of Virat Kohli And Anushka Sharma's Daughter? Not Really",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-pakistan-pm-imran-khan-narendra-modi-indian-army-shefali-vaidya-cropped-video-edited-video-73-years-iron-willed-11502",
        headline:
          "Cropped Video Of Imran Khan Shared To Claim He Praised Modi Government",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-man-saffron-flag-capitol-hill-united-states-dc-siege-pro-tump-supporters-hindutva-members-11496",
        headline:
          "\u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987: US Capitol \u0985\u09ac\u09b0\u09cb\u09a7\u09c7 \u09ae\u09be\u09b0\u09cd\u0995\u09bf\u09a8 \u0993 \u0997\u09c7\u09b0\u09c1\u09df\u09be \u09aa\u09a4\u09be\u0995\u09be \u09b9\u09be\u09a4\u09c7 \u098f\u0995 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf",
      },
    ],
    "2": [
      {
        url:
          "http://newsmobile.in/articles/2021/01/12/fact-check-this-picture-of-pm-modi-holding-file-on-population-control-bill-2021-is-fake/",
        headline:
          "Fact Check: This Picture Of PM Modi Holding File On \u2018Population Control Bill 2021\u2019 Is Fake",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/13/fact-check-no-you-do-not-have-to-pay-to-use-whatsapp-its-a-hoax/",
        headline:
          "Fact Check: No, You Do Not Have To Pay to Use WhatsApp; It\u2019s A Hoax",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/reliance-to-donate-solar-power-to-ram-mandir-in-ayodhya-fake-news-11561",
        headline:
          "No, Reliance Has Not Donated A Solar Power Plant To Ram Mandir",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/tirupati-balaji-temple-to-donate-one-billion-rupees-to-ram-mandir-in-ayodhya-fake-news-11572",
        headline:
          "No, Tirupati Balaji Temple Did Not Donate 1 Billion Rupees To Ram Mandir",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/tirupati-temple-to-donate-one-billion-rupees-to-ram-mandir-in-ayodhya-fake-news-ek-arab-rupaye-11563",
        headline:
          "\u0930\u093e\u092e \u092e\u0902\u0926\u093f\u0930 \u0928\u093f\u0930\u094d\u092e\u093e\u0923: \u0915\u094d\u092f\u093e \u0924\u093f\u0930\u0941\u092a\u0924\u093f \u092c\u093e\u0932\u093e\u091c\u0940 \u092e\u0902\u0926\u093f\u0930 \u0926\u0947\u0917\u093e \u090f\u0915 \u0905\u0930\u092c \u0930\u0941\u092a\u092f\u0947 \u0915\u093e \u0926\u093e\u0928?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/nita-ambani-viral-tweets-reliance-donated-complete-solar-power-plant-to-ram-mandir-ayodhya-11568",
        headline:
          "\u0928\u0939\u0940\u0902, \u0930\u093f\u0932\u093e\u092f\u0902\u0938 \u0928\u0947 \u0930\u093e\u092e \u092e\u0902\u0926\u093f\u0930 \u0915\u0947 \u0932\u093f\u090f \u0938\u094c\u0930 \u090a\u0930\u094d\u091c\u093e \u092a\u094d\u0932\u093e\u0902\u091f \u0926\u093e\u0928 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-reliance-donate-solar-power-panel-ram-mandir-ayodhya-fake-news-nita-ambani-tweet-11579",
        headline:
          "\u09a8\u09be, \u09b0\u09be\u09ae \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u0995\u09c7 Reliance \u09b8\u09cc\u09b0 \u09ac\u09bf\u09a6\u09cd\u09af\u09c1\u09ce \u09aa\u09cd\u09b0\u0995\u09b2\u09cd\u09aa \u0989\u09aa\u09b9\u09be\u09b0 \u09a6\u09c7\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-fake-news-tirupati-balaji-donation-100-crore-ayodhya-ram-mandir-11582",
        headline:
          "\u09a8\u09be, Tirupati Balaji \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0 \u09b0\u09be\u09ae \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09e7\u09e6\u09e6 \u0995\u09cb\u099f\u09bf \u099f\u09be\u0995\u09be \u09a6\u09c7\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-conspiracy-theory-on-bird-flu-linking-bird-deaths-to-jio-5g-trial-goes-viral-1758796-2021-01-13",
        headline:
          "Fact Check: Conspiracy theory on bird flu linking bird deaths to Jio 5G trial goes viral",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/fact-check-avian-flu-conspiracy-theory-linking-bird-deaths-to-jio-5g-trial-1759393-2021-01-15",
        headline:
          "Fact Check: Avian flu conspiracy theory linking bird deaths to Jio 5G trial",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/12/fact-check-this-picture-of-pm-modi-holding-file-on-population-control-bill-2021-is-fake/",
        headline:
          "Fact Check: This Picture Of PM Modi Holding File On \u2018Population Control Bill 2021\u2019 Is Fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/13/fact-check-no-you-do-not-have-to-pay-to-use-whatsapp-its-a-hoax/",
        headline:
          "Fact Check: No, You Do Not Have To Pay to Use WhatsApp; It\u2019s A Hoax",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/tirupati-balaji-temple-to-donate-one-billion-rupees-to-ram-mandir-in-ayodhya-fake-news-11572",
        headline:
          "No, Tirupati Balaji Temple Did Not Donate 1 Billion Rupees To Ram Mandir",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/pm-narendra-modi-jansankhya-niyantran-kanoon-2021-image-is-edited-11497",
        headline:
          "\u091c\u0928\u0938\u0902\u0916\u094d\u092f\u093e \u0928\u093f\u092f\u0902\u0924\u094d\u0930\u0923 \u0958\u093e\u0928\u0942\u0928 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u0940 \u090f\u0921\u093f\u091f\u0947\u0921 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/tirupati-temple-to-donate-one-billion-rupees-to-ram-mandir-in-ayodhya-fake-news-ek-arab-rupaye-11563",
        headline:
          "\u0930\u093e\u092e \u092e\u0902\u0926\u093f\u0930 \u0928\u093f\u0930\u094d\u092e\u093e\u0923: \u0915\u094d\u092f\u093e \u0924\u093f\u0930\u0941\u092a\u0924\u093f \u092c\u093e\u0932\u093e\u091c\u0940 \u092e\u0902\u0926\u093f\u0930 \u0926\u0947\u0917\u093e \u090f\u0915 \u0905\u0930\u092c \u0930\u0941\u092a\u092f\u0947 \u0915\u093e \u0926\u093e\u0928?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/nita-ambani-viral-tweets-reliance-donated-complete-solar-power-plant-to-ram-mandir-ayodhya-11568",
        headline:
          "\u0928\u0939\u0940\u0902, \u0930\u093f\u0932\u093e\u092f\u0902\u0938 \u0928\u0947 \u0930\u093e\u092e \u092e\u0902\u0926\u093f\u0930 \u0915\u0947 \u0932\u093f\u090f \u0938\u094c\u0930 \u090a\u0930\u094d\u091c\u093e \u092a\u094d\u0932\u093e\u0902\u091f \u0926\u093e\u0928 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-reliance-donate-solar-power-panel-ram-mandir-ayodhya-fake-news-nita-ambani-tweet-11579",
        headline:
          "\u09a8\u09be, \u09b0\u09be\u09ae \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u0995\u09c7 Reliance \u09b8\u09cc\u09b0 \u09ac\u09bf\u09a6\u09cd\u09af\u09c1\u09ce \u09aa\u09cd\u09b0\u0995\u09b2\u09cd\u09aa \u0989\u09aa\u09b9\u09be\u09b0 \u09a6\u09c7\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-tirupati-balaji-donation-100-crore-ayodhya-ram-mandir-11582",
        headline:
          "\u09a8\u09be, Tirupati Balaji \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0 \u09b0\u09be\u09ae \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09e7\u09e6\u09e6 \u0995\u09cb\u099f\u09bf \u099f\u09be\u0995\u09be \u09a6\u09c7\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/reliance-to-donate-solar-power-to-ram-mandir-in-ayodhya-fake-news-11561",
        headline:
          "No, Reliance Has Not Donated A Solar Power Plant To Ram Mandir",
      },
    ],
    "3": [
      {
        url:
          "https://factly.in/old-video-from-black-lives-matter-protests-linked-to-us-capitol-building-protest/",
        headline:
          " Old video from \u2018Black Lives Matter\u2019 protests falsely linked to US Capitol building protest",
      },
      {
        url:
          "https://factly.in/2017-visuals-of-sikh-activists-defacing-hindi-signboards-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          " 2017 visuals of Sikh activists defacing Hindi signboards falsely linked to ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-old-photo-shared-as-fake-sikh-participating-in-the-farmers-protest/",
        headline:
          " \u0c15\u0c28\u0c40\u0c38\u0c02 \u0c10\u0c26\u0c47\u0c33\u0c4d\u0c33 \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, \u0c24\u0c3e\u0c1c\u0c3e \u0c30\u0c48\u0c24\u0c41 \u0c09\u0c26\u0c4d\u0c2f\u0c2e\u0c02\u0c32\u0c4b \u0c2a\u0c3e\u0c32\u0c4d\u0c17\u0c4a\u0c28\u0c4d\u0c28 \u0c28\u0c15\u0c3f\u0c32\u0c40 \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41 \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/five-year-old-photo-shared-as-an-imposter-muslim-in-farmers-protest/",
        headline:
          " Old photo shared as imposter dressed in Sikh attire after participating in farmers\u2019 protest.",
      },
      {
        url:
          "https://factly.in/telugu-2017-visuals-of-sikh-activists-defacing-hindi-signboards-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          " 2017\u0c32\u0c4b \u0c39\u0c3f\u0c02\u0c26\u0c40 \u0c2c\u0c4b\u0c30\u0c4d\u0c21\u0c41\u0c32\u0c15\u0c3f \u0c28\u0c32\u0c41\u0c2a\u0c41 \u0c30\u0c02\u0c17\u0c41 \u0c2a\u0c41\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f \u0c30\u0c48\u0c24\u0c41 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f \u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/13/fact-check-pictures-of-defaced-signboards-from-2017-falsely-linked-to-farmers-protest/",
        headline:
          "Fact Check: Pictures Of Defaced Signboards From 2017 Falsely Linked To Farmers\u2019 Protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/13/fact-check-this-picture-of-man-with-saffron-flag-is-not-related-to-us-capitol-hill-violence-heres-the-truth/",
        headline:
          "Fact Check: This Picture of Man With Saffron Flag Is NOT Related To US Capitol Hill Violence; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/14/fact-check-old-video-of-a-man-vandalising-hindi-signboard-shared-with-false-farmers-protest-link/",
        headline:
          "Fact Check: Old video Of A Man Vandalising Hindi Signboard Shared With False Farmers\u2019 Protest Link",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/video-of-protest-march-in-kargil-against-hazara-killings-shared-as-pok-11570",
        headline:
          "Video Of Protest March In Kargil Against Hazara Killings Shared As PoK",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-clip-of-man-blackening-hindi-text-on-signboard-linked-to-farmers-stir-1757943-2021-01-11",
        headline:
          "Fact Check: Two-year-old clip of man blackening Hindi text on signboard linked to farmers\u2019 stir",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/13/fact-check-pictures-of-defaced-signboards-from-2017-falsely-linked-to-farmers-protest/",
        headline:
          "Fact Check: Pictures Of Defaced Signboards From 2017 Falsely Linked To Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/13/fact-check-this-picture-of-man-with-saffron-flag-is-not-related-to-us-capitol-hill-violence-heres-the-truth/",
        headline:
          "Fact Check: This Picture of Man With Saffron Flag Is NOT Related To US Capitol Hill Violence; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/14/fact-check-old-video-of-a-man-vandalising-hindi-signboard-shared-with-false-farmers-protest-link/",
        headline:
          "Fact Check: Old video Of A Man Vandalising Hindi Signboard Shared With False Farmers\u2019 Protest Link",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-coca-cola-viral-photo-fact-check-farmers-protests-kisan-ekta-share-a-coke-11569",
        headline:
          "Fake Image Goes Viral Claiming Coca Cola's Support To Farmers",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/video-of-protest-march-in-kargil-against-hazara-killings-shared-as-pok-11570",
        headline:
          "Video Of Protest March In Kargil Against Hazara Killings Shared As PoK",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/hindi-imposition-protest-kisan-andolan-protestors-blackening-hindi-signboards-unrelated-kisan-andolan-11508",
        headline:
          "\u0939\u093f\u0902\u0926\u0940 \u092e\u0947\u0902 \u0932\u093f\u0916\u0947 \u0938\u093e\u0907\u0928 \u092c\u094b\u0930\u094d\u0921 \u092a\u0930 \u0915\u093e\u0932\u093f\u0916 \u092a\u094b\u0924\u0928\u0947 \u0915\u0940 \u092f\u0947 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-coca-cola-viral-photo-fact-check-farmers-protests-kisan-ekta-share-a-coke-11569",
        headline:
          "\u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b8\u09ae\u09b0\u09cd\u09a5\u09a8\u09c7 \u09ac\u09cd\u09b0\u09cd\u09af\u09be\u09a8\u09cd\u09a1 \u0995\u09cd\u09af\u09be\u09ae\u09cd\u09aa\u09c7\u09a8 \u09b6\u09c1\u09b0\u09c1\u09b0 \u0995\u09a5\u09be Coca Cola \u0985\u09b8\u09cd\u09ac\u09c0\u0995\u09be\u09b0 \u0995\u09b0\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-coca-cola-has-not-launched-support-farmers-coke-bottles-672928",
        headline:
          "Fact Check: Coca-Cola has not launched 'Support Farmers' coke bottles",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-image-of-man-waving-saffron-flag-at-us-capitol-linked-to-recent-washington-violence-672753",
        headline:
          "Fact Check: Old image of man waving saffron flag at US Capitol linked to recent Washington violence",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/old-unrelated-video-shared-as-bjp-leaders-begging-rajnath-singh-to-repeal-farm-bills/",
        headline:
          "Old, unrelated video shared as BJP leaders begging Rajnath Singh to repeal farm bills",
      },
      {
        url:
          "https://www.altnews.in/fact-check-did-modi-govt-remove-nationality-column-from-passport/",
        headline:
          "No, Indian govt has NOT removed \u2018nationality\u2019 column from passports",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-unrelated-video-shared-as-bjp-leaders-begging-rajnath-singh-to-repeal-farm-bills/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915 : BJP \u0928\u0947\u0924\u093e\u0913\u0902 \u0928\u0947 \u0930\u093e\u091c\u0928\u093e\u0925 \u0938\u093f\u0902\u0939 \u0938\u0947 \u0915\u0943\u0937\u093f \u0935\u093f\u0927\u0947\u092f\u0915 \u0935\u093e\u092a\u0938 \u0932\u0947\u0928\u0947 \u0915\u093e \u0917\u0941\u0939\u093e\u0930 \u0932\u0917\u093e\u092f\u0940?",
      },
      {
        url:
          "https://www.altnews.in/hindi/audio-clip-discrediting-caa-protestors-falsely-attributed-to-supreme-court-advocate-harish-salve/",
        headline:
          "CAA \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0915\u094b\u0902 \u0915\u094b \u0927\u094b\u0916\u0947\u092c\u093e\u095b \u092c\u0924\u093e\u0928\u0947 \u0935\u093e\u0932\u0940 \u0911\u0921\u093f\u092f\u094b \u0915\u094d\u0932\u093f\u092a \u0938\u0941\u092a\u094d\u0930\u0940\u092e \u0915\u094b\u0930\u094d\u091f \u0915\u0947 \u0935\u0915\u0940\u0932 \u0939\u0930\u0940\u0936 \u0938\u093e\u0932\u094d\u0935\u0947 \u0915\u0940 \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://factly.in/old-video-falsely-shared-as-bjp-leaders-pleading-rajnath-singh-to-revoke-the-new-farm-laws/",
        headline:
          " Old Video falsely shared as BJP leaders pleading Rajnath Singh to revoke the new farm laws",
      },
      {
        url:
          "https://factly.in/video-of-truck-catching-fire-in-pune-is-passed-off-as-that-from-hyderabad/",
        headline:
          " Video of a truck catching fire in Pune is passed off as that from Hyderabad.",
      },
      {
        url:
          "https://digiteye.in/fact-check-spoofed-online-banking-message-on-nominee-spreading/",
        headline:
          "Fact check: \u2018Spoofed\u2019 online banking message on nominee spreading",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-anjali-birla-om-birla-daughter-qualifies-for-ias-without-taking-exam-false-news-fact-check-lok-sabha-speaker-11498",
        headline:
          "False Posts Claim Anjali Birla Became An IAS Without Taking Exam",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-viral-whatsapp-audio-harish-salve-slamming-anti-caa-protestors-11521",
        headline:
          "CAA-\u09ac\u09bf\u09b0\u09cb\u09a7\u09c0\u09a6\u09c7\u09b0 \u09b8\u09ae\u09be\u09b2\u09cb\u099a\u09a8\u09be \u0995\u09b0\u09c7 Harish Salve \u0995\u09cb\u09a8\u0993 \u0985\u09a1\u09bf\u0993 \u0995\u09cd\u09b2\u09bf\u09aa \u09b0\u09c7\u0995\u09b0\u09cd\u09a1 \u0995\u09b0\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-truck-that-caught-fire-goes-viral-with-misleading-claims-1759984-2021-01-17",
        headline:
          "Fact Check: Video of truck that caught fire goes viral with misleading claims",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fast-check/video-claims-bjp-mla-anil-upadhyay-attacked-a-policeman-in-up-11583",
        headline:
          "2018 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092d\u094d\u0930\u093e\u092e\u0915 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u092b\u093f\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/placard-claiming-activist-threatens-to-disrobe-herself-is-morphed-11528",
        headline:
          "Fake News: \u09b8\u09ae\u09be\u099c\u0995\u09b0\u09cd\u09ae\u09c0\u09b0 \u09ac\u09bf\u09ac\u09b8\u09cd\u09a4\u09cd\u09b0 \u09b9\u09a4\u09c7 \u099a\u09be\u0993\u09df\u09be \u09b9\u09c1\u09ae\u0995\u09bf Placard-\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-viral-whatsapp-audio-harish-salve-slamming-anti-caa-protestors-11521",
        headline:
          "CAA-\u09ac\u09bf\u09b0\u09cb\u09a7\u09c0\u09a6\u09c7\u09b0 \u09b8\u09ae\u09be\u09b2\u09cb\u099a\u09a8\u09be \u0995\u09b0\u09c7 Harish Salve \u0995\u09cb\u09a8\u0993 \u0985\u09a1\u09bf\u0993 \u0995\u09cd\u09b2\u09bf\u09aa \u09b0\u09c7\u0995\u09b0\u09cd\u09a1 \u0995\u09b0\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-anjali-birla-om-birla-daughter-qualifies-for-ias-without-taking-exam-false-news-fact-check-lok-sabha-speaker-11498",
        headline:
          "False Posts Claim Anjali Birla Became An IAS Without Taking Exam",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-flipkart-package-with-funny-hyderabad-address-is-photoshopped-672943",
        headline:
          "Fact Check: Photo of Flipkart package with funny Hyderabad address is photoshopped",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-oil-tanker-in-flames-is-from-warje-bridge-pune-not-gachibowli-flyover-672906",
        headline:
          "Fact Check: Viral video of oil tanker in flames is from Warje Bridge Pune, not Gachibowli flyover",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-maadhavi-latha-has-not-announced-that-she-is-leaving-bjp-672814",
        headline:
          "Fact Check: Maadhavi Latha has not announced that she is leaving BJP",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/telugu-this-image-is-not-an-american-god-but-19th-century-statue-from-india/",
        headline:
          " \u0c07\u0c26\u0c3f 19\u0c35 \u0c36\u0c24\u0c3e\u0c2c\u0c4d\u0c26\u0c02\u0c32\u0c4b \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c24\u0c2f\u0c3e\u0c30\u0c41\u0c1a\u0c47\u0c2f\u0c2c\u0c21\u0c4d\u0c21 \u0c39\u0c28\u0c41\u0c2e\u0c3e\u0c28\u0c4d \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c3e\u0c02, \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c28\u0c4d \u0c26\u0c47\u0c35\u0c41\u0c21\u0c3f\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-wikileaks-did-not-publish-any-article-about-rahul-gandhi-marriage-or-wofe/",
        headline:
          " \u0c30\u0c3e\u0c39\u0c41\u0c32\u0c4d \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c35\u0c3f\u0c35\u0c3e\u0c39\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c35\u0c3f\u0c15\u0c3f\u0c32\u0c3f\u0c15\u0c4d\u0c38\u0c4d \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c15\u0c25\u0c28\u0c02 \u0c2a\u0c2c\u0c4d\u0c32\u0c3f\u0c37\u0c4d \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-is-an-old-sculpture-of-hindu-god-hanuman-not-american-monkey-god/",
        headline:
          " This is an old sculpture of Hindu God Hanuman, not American Monkey God",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-doesnt-show-a-5500-year-old-statue-of-three-lions/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f 5,500 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c3e\u0c32 \u0c2a\u0c41\u0c30\u0c3e\u0c24\u0c28\u0c2e\u0c48\u0c28 \u0c2e\u0c42\u0c21\u0c41 \u0c38\u0c3f\u0c02\u0c39\u0c3e\u0c32 \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c02 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-tweet-about-farmers-protest-is-posted-from-a-fan-page-parody-account-of-ranjan-gogoi/",
        headline:
          " This tweet about farmers protest is made from a fan page (Parody account) of Ranjan Gogoi",
      },
      {
        url:
          "https://factly.in/telugu-the-sculpture-in-this-picture-is-only-about-2900-years-old-and-does-not-belong-to-india/",
        headline:
          " \u0c08 \u0c1a\u0c3f\u0c24\u0c4d\u0c30\u0c02 \u0c32\u0c4b\u0c28\u0c3f \u0c36\u0c3f\u0c32\u0c4d\u0c2a\u0c02 \u0c38\u0c41\u0c2e\u0c3e\u0c30\u0c41 2900 \u0c0f\u0c33\u0c4d\u0c32 \u0c15\u0c3f\u0c02\u0c26\u0c1f\u0c3f\u0c26\u0c3f. \u0c07\u0c26\u0c3f \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/14/fact-check-video-of-mumbai-police-at-mahim-dargah-shared-with-misleading-claim/",
        headline:
          "Fact Check: Video Of Mumbai Police At Mahim Dargah Shared With Misleading Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/morphed-screenshot-of-the-news-minute-on-cricketer-azharudeen-viral-11585",
        headline:
          "Morphed Screenshot Of The News Minute On Cricketer Azharudeen Viral",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/kolkata-pride-walk-hindu-culture-jnu-protest-fake-news-11527",
        headline:
          "\u092a\u094d\u0930\u093e\u0907\u0921 \u0935\u0949\u0915 \u0915\u0940 \u095e\u094b\u091f\u094b \u0915\u094b \u091c\u0947\u090f\u0928\u092f\u0942 \u092e\u0947\u0902 \u0939\u093f\u0928\u094d\u0926\u0942 \u0938\u0902\u0938\u094d\u0915\u0943\u0924\u093f \u0915\u093e \u0935\u093f\u0930\u094b\u0927 \u092c\u0924\u093e\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news-fact-check-rahul-gandhi-married-secret-family-postcard-news-mahesh-hegde-wikileaks-cable-colombian-woman-two-children-niyak-mainak-11510",
        headline:
          "Rahul Gandhi-\u09b0 '\u0997\u09cb\u09aa\u09a8 \u09aa\u09b0\u09bf\u09ac\u09be\u09b0' \u09ac\u09bf\u09b7\u09df\u09c7 \u0996\u09be\u09b0\u09bf\u099c \u0995\u09b0\u09be \u09a6\u09be\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-farmers-protest-punjab-reliance-jio-towers-signboards-vandalised-hindi-words-blackened-11565",
        headline:
          "\u09aa\u09be\u099e\u09cd\u099c\u09be\u09ac\u09c7 \u09b8\u09be\u0987\u09a8\u09ac\u09cb\u09b0\u09cd\u09a1\u09c7 \u0995\u09be\u09b2\u09bf \u09ae\u09be\u0996\u09be\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf Farmers Protest \u098f\u09b0 \u09b8\u09be\u09a5\u09c7 \u099c\u09cb\u09dc\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/mumbai-families-sleeping-on-street-linked-to-farmers-protest-singhu-border-11574",
        headline:
          "\u09ae\u09c1\u09ae\u09cd\u09ac\u0987\u09df\u09c7\u09b0 \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09df \u0998\u09c1\u09ae\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 Farmers Protest-\u098f \u0995\u09c3\u09b7\u0995 \u09aa\u09b0\u09bf\u09ac\u09be\u09b0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-this-is-not-a-rehearsal-by-protesting-farmers-for-january-26-tractor-rally-1759432-2021-01-15",
        headline:
          "Fact Check: No, this is not a rehearsal by protesting farmers for January 26 tractor rally",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-hima-das-has-not-yet-qualified-for-the-2021-tokyo-olympics-1759811-2021-01-16",
        headline:
          "Fact Check: Hima Das has not yet qualified for the 2021 Tokyo Olympics",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-islamophobic-news-headline-about-kerala-cricketer-azharuddeen-is-fake-1759983-2021-01-17",
        headline:
          "Fact Check: This \u2018Islamophobic\u2019 news headline about Kerala cricketer Azharuddeen is fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/14/fact-check-video-of-mumbai-police-at-mahim-dargah-shared-with-misleading-claim/",
        headline:
          "Fact Check: Video Of Mumbai Police At Mahim Dargah Shared With Misleading Claim",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/kolkata-police-snooping-on-citizens-phone-tapping-social-media-surveillanc-fake-whatsapp-message-revived-11553",
        headline:
          "Kolkata Police \u09ab\u09cb\u09a8\u09c7 \u09a8\u099c\u09b0\u09a6\u09be\u09b0\u09bf \u099a\u09be\u09b2\u09be\u099a\u09cd\u099b\u09c7 \u0986\u09ac\u09be\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 WhatsApp \u09ad\u09c1\u09df\u09cb \u09ac\u09be\u09b0\u09cd\u09a4\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-farmers-protest-punjab-reliance-jio-towers-signboards-vandalised-hindi-words-blackened-11565",
        headline:
          "\u09aa\u09be\u099e\u09cd\u099c\u09be\u09ac\u09c7 \u09b8\u09be\u0987\u09a8\u09ac\u09cb\u09b0\u09cd\u09a1\u09c7 \u0995\u09be\u09b2\u09bf \u09ae\u09be\u0996\u09be\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf Farmers Protest \u098f\u09b0 \u09b8\u09be\u09a5\u09c7 \u099c\u09cb\u09dc\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/morphed-screenshot-of-the-news-minute-on-cricketer-azharudeen-viral-11585",
        headline:
          "Morphed Screenshot Of The News Minute On Cricketer Azharudeen Viral",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/kolkata-pride-walk-hindu-culture-jnu-protest-fake-news-11527",
        headline:
          "\u092a\u094d\u0930\u093e\u0907\u0921 \u0935\u0949\u0915 \u0915\u0940 \u095e\u094b\u091f\u094b \u0915\u094b \u091c\u0947\u090f\u0928\u092f\u0942 \u092e\u0947\u0902 \u0939\u093f\u0928\u094d\u0926\u0942 \u0938\u0902\u0938\u094d\u0915\u0943\u0924\u093f \u0915\u093e \u0935\u093f\u0930\u094b\u0927 \u092c\u0924\u093e\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/mumbai-families-sleeping-on-street-linked-to-farmers-protest-singhu-border-11574",
        headline:
          "\u09ae\u09c1\u09ae\u09cd\u09ac\u0987\u09df\u09c7\u09b0 \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09df \u0998\u09c1\u09ae\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 Farmers Protest-\u098f \u0995\u09c3\u09b7\u0995 \u09aa\u09b0\u09bf\u09ac\u09be\u09b0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-rahul-gandhi-married-secret-family-postcard-news-mahesh-hegde-wikileaks-cable-colombian-woman-two-children-niyak-mainak-11510",
        headline:
          "Rahul Gandhi-\u09b0 '\u0997\u09cb\u09aa\u09a8 \u09aa\u09b0\u09bf\u09ac\u09be\u09b0' \u09ac\u09bf\u09b7\u09df\u09c7 \u0996\u09be\u09b0\u09bf\u099c \u0995\u09b0\u09be \u09a6\u09be\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-a-building-inscribed-with-jay-bhim-is-morphed-672758",
        headline:
          "Fact check: Viral image of a building inscribed with Jay Bhim is morphed",
      },
    ],
    "6": [
      {
        url:
          "https://factly.in/telugu-covax-agreement-between-190-countries-is-misconstrued-as-that-for-the-vaccine-being-developed-in-india/",
        headline:
          " 190 \u0c26\u0c47\u0c36\u0c3e\u0c32\u0c15\u0c41 \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d \u0c05\u0c02\u0c26\u0c3f\u0c02\u0c1a\u0c47 COVAX \u0c12\u0c2a\u0c4d\u0c2a\u0c02\u0c26\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c2d\u0c3e\u0c30\u0c24\u0c4d \u0c32\u0c4b \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c1f\u0c40\u0c15\u0c3e\u0c28\u0c3f \u0c2c\u0c41\u0c15\u0c4d \u0c1a\u0c47\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c05\u0c30\u0c4d\u0c25\u0c02 \u0c1a\u0c47\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-images-are-falsely-share-as-hollywood-actor-will-smith-accepting-hindu-religion/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, \u0c39\u0c3e\u0c32\u0c40\u0c35\u0c41\u0c21\u0c4d \u0c39\u0c40\u0c30\u0c4b \u0c35\u0c3f\u0c32\u0c4d \u0c38\u0c4d\u0c2e\u0c3f\u0c24\u0c4d \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c27\u0c30\u0c4d\u0c2e\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c38\u0c4d\u0c35\u0c40\u0c15\u0c30\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/12/fact-check-old-picture-of-baby-falsely-linked-to-recent-indonesia-flight-crash-heres-the-truth/",
        headline:
          "Fact Check: Old Picture Of Baby Falsely Linked To Recent Indonesia Flight Crash; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/15/fact-check-claims-about-birds-dying-due-to-jios-5g-testing-are-false-heres-the-truth/",
        headline:
          "Fact Check: Claims About Birds Dying Due To Jio\u2019s 5G Testing Are False; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/photo-from-2018-viral-as-baby-found-alive-in-indonesian-plane-crash-11511",
        headline:
          "Photo From 2018 Viral As Baby Found Alive In Indonesian Plane Crash",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/america-ke-hanumanji-monkey-god-colorado-denver-art-museum-usa-lord-hanuman-11516",
        headline:
          "'\u0905\u092e\u0947\u0930\u093f\u0915\u093e \u0915\u0947 \u0939\u0928\u0941\u092e\u093e\u0928\u091c\u0940' \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u0907\u0938 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/playboys-miriam-gonzalezs-image-doctored-to-feature-tollywood-actor-koel-mallick-11499",
        headline:
          "\u09aa\u09cd\u09b2\u09c7\u09ac\u09df \u0996\u09cd\u09af\u09be\u09a4 Miriam Gonzalez \u098f\u09b0 \u09ab\u099f\u09cb\u09b6\u09aa \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2 Koel Mallick-\u098f\u09b0 \u09a8\u09be\u09ae\u09c7",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-baby-was-not-aboard-the-indonesia-flight-that-crashed-1758120-2021-01-11",
        headline:
          "Fact Check: This baby was not aboard the Indonesia flight that crashed",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-pictures-of-air-mishaps-linked-to-indonesia-plane-crash-1758316-2021-01-12",
        headline:
          "Fact Check: Old pictures of air mishaps linked to Indonesia plane crash",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-viral-post-on-made-in-india-signal-sanskrit-coding-is-a-work-of-satire-1758443-2021-01-12",
        headline:
          "Fact Check: Viral post on 'Made-in-India' Signal, Sanskrit coding is a work of satire",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/fact-check-viral-post-on-made-in-india-signal-sanskrit-coding-is-a-work-of-satire-1758656-2021-01-13",
        headline:
          "Fact Check: Viral post on 'Made-in-India' Signal, Sanskrit coding is a work of satire",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/12/fact-check-old-picture-of-baby-falsely-linked-to-recent-indonesia-flight-crash-heres-the-truth/",
        headline:
          "Fact Check: Old Picture Of Baby Falsely Linked To Recent Indonesia Flight Crash; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/15/fact-check-claims-about-birds-dying-due-to-jios-5g-testing-are-false-heres-the-truth/",
        headline:
          "Fact Check: Claims About Birds Dying Due To Jio\u2019s 5G Testing Are False; Here\u2019s The Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/america-ke-hanumanji-monkey-god-colorado-denver-art-museum-usa-lord-hanuman-11516",
        headline:
          "'\u0905\u092e\u0947\u0930\u093f\u0915\u093e \u0915\u0947 \u0939\u0928\u0941\u092e\u093e\u0928\u091c\u0940' \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u0907\u0938 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/photo-from-2018-viral-as-baby-found-alive-in-indonesian-plane-crash-11511",
        headline:
          "Photo From 2018 Viral As Baby Found Alive In Indonesian Plane Crash",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-old-photos-canada-army-china-pla-miliray-warfare-traning-exercise-factcheck-india-11556",
        headline:
          "Canadian Army Training China To Fight India? Old Pics Go Viral",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/playboys-miriam-gonzalezs-image-doctored-to-feature-tollywood-actor-koel-mallick-11499",
        headline:
          "\u09aa\u09cd\u09b2\u09c7\u09ac\u09df \u0996\u09cd\u09af\u09be\u09a4 Miriam Gonzalez \u098f\u09b0 \u09ab\u099f\u09cb\u09b6\u09aa \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2 Koel Mallick-\u098f\u09b0 \u09a8\u09be\u09ae\u09c7",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-for-the-record-moxie-marlinspike-and-brian-acton-created-signal-not-man-from-uttar-pradesh-672772",
        headline:
          "Fact check: For the record, Moxie Marlinspike and Brian Acton created `Signal', NOT man from Uttar Pradesh",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-true-23-people-died-in-norway-after-receiving-pfizer-vaccine-672937",
        headline:
          "Fact Check: True, 23 people died in Norway after receiving Pfizer vaccine",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-baby-has-not-been-rescued-after-plane-crash-in-indonesia-672841",
        headline:
          "FACT CHECK: No, baby has not been rescued after plane crash in Indonesia",
      },
    ],
    "7": [
      {
        url:
          "https://www.altnews.in/woman-placard-morphed-to-read-will-disrobe-but-not-show-documents-for-nrc/",
        headline:
          "Woman\u2019s placard morphed to read \u2018will disrobe but not show documents\u2019 for NRC",
      },
      {
        url:
          "https://www.altnews.in/old-unrelated-photo-of-sikh-man-praying-namaz-mosque-farmers-protest-false-claim/",
        headline:
          "Old image of Sikh man praying in mosque viral as Muslim man who posed as farmer",
      },
      {
        url:
          "https://www.altnews.in/hindi/fake-newspaper-clip-modi-farmer-muslim-amit-shah-fake-quotes/",
        headline:
          "\u0905\u0959\u092c\u093e\u0930 \u0915\u0940 \u095e\u0930\u094d\u095b\u0940 \u0915\u094d\u0932\u093f\u092a \u0935\u093e\u092f\u0930\u0932, \u092e\u094b\u0926\u0940 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0914\u0930 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0915\u094b \u092e\u0930\u0935\u093e\u0928\u0947 \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u092c\u092f\u093e\u0928",
      },
      {
        url:
          "https://www.altnews.in/hindi/woman-placard-morphed-to-read-will-disrobe-but-not-show-documents-for-nrc/",
        headline:
          "\u092e\u0939\u093f\u0932\u093e \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u092a\u094b\u0938\u094d\u091f\u0930 \u090f\u0921\u093f\u091f \u0915\u0930 \u095a\u0932\u0924 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://factly.in/image-from-2013-allahabad-kumbh-mela-is-falsely-passed-off-as-that-from-the-farmers-protests/",
        headline:
          " Image from 2013 Allahabad Kumbh Mela is passed off as that from the farmers\u2019 protests",
      },
      {
        url:
          "https://factly.in/pakistani-ex-diplomat-did-not-admit-that-300-persons-have-died-in-balakot-airstrikes/",
        headline:
          " \u0c2c\u0c3e\u0c32\u0c3e\u0c15\u0c4b\u0c1f\u0c4d \u0c26\u0c3e\u0c21\u0c41\u0c32\u0c4d\u0c32\u0c4b 300 \u0c2e\u0c02\u0c26\u0c3f \u0c2e\u0c43\u0c24\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28\u0c1f\u0c4d\u0c32\u0c41 \u0c2a\u0c3e\u0c15\u0c4d \u0c2e\u0c3e\u0c1c\u0c40 \u0c26\u0c4c\u0c24\u0c4d\u0c2f\u0c35\u0c47\u0c24\u0c4d\u0c24 \u0c39\u0c3f\u0c32\u0c3e\u0c32\u0c40 \u0c05\u0c02\u0c17\u0c40\u0c15\u0c30\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/morphed-image-shared-as-man-urinating-on-congress-party-wall-poster/",
        headline:
          " Morphed image shared as man urinating on Congress party wall poster",
      },
      {
        url:
          "https://factly.in/telugu-women-were-given-collateral-free-loans-even-before-2014/",
        headline:
          " 2014 \u0c15\u0c28\u0c4d\u0c28\u0c3e \u0c2e\u0c41\u0c02\u0c26\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c2e\u0c39\u0c3f\u0c33\u0c32\u0c15\u0c41 \u0c2a\u0c42\u0c1a\u0c40\u0c15\u0c24\u0c4d\u0c24\u0c41 \u0c32\u0c47\u0c15\u0c41\u0c02\u0c21\u0c3e \u0c30\u0c41\u0c23\u0c3e\u0c32\u0c41 \u0c05\u0c02\u0c26\u0c3f\u0c02\u0c1a\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/former-pakistan-diplomat-zafar-hilaly-did-not-admit-300-casualties-in-iaf-airstrike-at-balakot/",
        headline:
          " Former Pakistan Diplomat Zafar Hilaly did not admit to 300 casualties in IAF airstrike at Balakot",
      },
      {
        url:
          "https://factly.in/this-muslim-shrine-on-a-railway-station-platform-is-located-in-prayagraj-not-delhi/",
        headline:
          " This Muslim shrine on a railway station platform is from Prayagraj, not Delhi",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/12/fact-check-video-of-islamic-scholar-making-false-claims-about-covid-19-vaccine-is-not-from-india/",
        headline:
          "Fact Check: Video Of Islamic Scholar Making False Claims About COVID-19 Vaccine Is NOT From India",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/13/fact-check-this-picture-of-mosque-on-a-railway-platform-is-not-of-old-delhi-station-heres-the-truth/",
        headline:
          "Fact Check: This Picture Of Mosque On A Railway Platform Is NOT Of Old Delhi Station; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/16/fact-check-dont-fall-for-this-image-showing-a-khalistan-airlines-aircraft-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t Fall For This Image Showing A \u2018Khalistan Airlines\u2019 Aircraft; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/16/fact-check-old-picture-of-a-sikh-man-offering-prayers-in-a-mosque-falsely-linked-with-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of A Sikh Man Offering Prayers In A Mosque Falsely Linked With Farmers\u2019 Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/image-of-mazaar-at-prayagraj-railway-station-viral-as-delhi-11529",
        headline: "Image Of Mazaar At Prayagraj Railway Station Viral As Delhi",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-fake-news-balakot-zafar-hilaly-ani-india-today-surgical-strike-300-deaths-pakistan-narendra-modi-11501",
        headline:
          "ANI \u0993 \u0997\u09a3\u09ae\u09be\u09a7\u09cd\u09af\u09ae\u09c7 Fake News \u09aa\u09be\u0995 \u0995\u09c2\u099f\u09a8\u09c0\u09a4\u09bf\u0995 \u09b8\u09cd\u09ac\u09c0\u0995\u09be\u09b0 \u0995\u09b0\u09c7\u099b\u09c7 Balakot \u09aa\u09cd\u09b0\u09be\u09a3\u09b9\u09be\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/viral-video-fact-check-mamata-banerjee-bengal-boards-madhyamik-higher-secondary-final-exam-2021-11558",
        headline:
          "Mamata Banerjee \u0995\u09c0 \u09ac\u09b2\u09c7\u099b\u09c7\u09a8 \u099b\u09be\u09a4\u09cd\u09b0\u099b\u09be\u09a4\u09cd\u09b0\u09c0\u09a6\u09c7\u09b0 \u09ac\u09cb\u09b0\u09cd\u09a1\u09c7\u09b0 \u09aa\u09b0\u09c0\u0995\u09cd\u09b7\u09be \u09a6\u09bf\u09a4\u09c7 \u09b9\u09ac\u09c7 \u09a8\u09be?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-image-of-assaulted-woman-goes-viral-targeting-beti-bachao-campaign-1758458-2021-01-12",
        headline:
          "Fact Check: Old image of assaulted woman goes viral targeting 'Beti Bachao' campaign",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-board-exams-in-bengal-this-year-mamata-s-edited-clip-goes-viral-1759166-2021-01-14",
        headline:
          "Fact Check: No board exams in Bengal this year? Mamata\u2019s edited clip goes viral",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-image-of-animal-rights-campaign-viral-with-misleading-claims-1759546-2021-01-15",
        headline:
          "Fact Check: Old image of animal rights campaign viral with misleading claims",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/12/fact-check-video-of-islamic-scholar-making-false-claims-about-covid-19-vaccine-is-not-from-india/",
        headline:
          "Fact Check: Video Of Islamic Scholar Making False Claims About COVID-19 Vaccine Is NOT From India",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/13/fact-check-this-picture-of-mosque-on-a-railway-platform-is-not-of-old-delhi-station-heres-the-truth/",
        headline:
          "Fact Check: This Picture Of Mosque On A Railway Platform Is NOT Of Old Delhi Station; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/16/fact-check-dont-fall-for-this-image-showing-a-khalistan-airlines-aircraft-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t Fall For This Image Showing A \u2018Khalistan Airlines\u2019 Aircraft; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/16/fact-check-old-picture-of-a-sikh-man-offering-prayers-in-a-mosque-falsely-linked-with-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of A Sikh Man Offering Prayers In A Mosque Falsely Linked With Farmers\u2019 Protest",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-kasmiri-separatists-placard-solidarity-farmers-protest-delhi-11524",
        headline:
          "Kashmir-\u09a8\u09bf\u09df\u09c7 \u09ac\u09bf\u099a\u09cd\u099b\u09bf\u09a8\u09cd\u09a8\u09a4\u09be\u09ac\u09be\u09a6\u09c0 \u09aa\u09cd\u09b2\u09cd\u09af\u09be\u0995\u09be\u09b0\u09cd\u09a1 \u099c\u09c1\u09a1\u09bc\u09b2 Farmer Protest \u098f\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-muslim-women-burqa-kisan-morcha-farmers-protest-punjab-khalistan-khalistani-11573",
        headline:
          "Pic Of Muslim Women At Farmers' Protest Viral With Communal Spin",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-photo-sikh-man-in-mosque-muslim-topi-kisan-andolan-11526",
        headline:
          "\u0928\u092e\u093e\u095b \u092a\u095d\u0924\u0947 \u0938\u093f\u0916 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u0940 \u090f\u0915 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/defunct-spy-station-in-berlin-falsely-shared-as-mosque-in-turkey-11519",
        headline:
          "Berlin-\u098f\u09b0 \u0985\u099a\u09b2 \u099a\u09b0\u09ac\u09c3\u09a4\u09cd\u09a4\u09bf \u0995\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0\u0995\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u0995\u09b0\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 Turkey-\u09b0 \u09ae\u09b8\u099c\u09bf\u09a6",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/viral-video-fact-check-mamata-banerjee-bengal-boards-madhyamik-higher-secondary-final-exam-2021-11558",
        headline:
          "Mamata Banerjee \u0995\u09c0 \u09ac\u09b2\u09c7\u099b\u09c7\u09a8 \u099b\u09be\u09a4\u09cd\u09b0\u099b\u09be\u09a4\u09cd\u09b0\u09c0\u09a6\u09c7\u09b0 \u09ac\u09cb\u09b0\u09cd\u09a1\u09c7\u09b0 \u09aa\u09b0\u09c0\u0995\u09cd\u09b7\u09be \u09a6\u09bf\u09a4\u09c7 \u09b9\u09ac\u09c7 \u09a8\u09be?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-old-photo-sikh-man-namaz-mosque-fact-check-farmers-protests-kisan-rally-11571",
        headline:
          "\u09b6\u09bf\u0996 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u09a8\u09be\u09ae\u09be\u099c\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf Farmers Protest \u098f\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u099c\u09c1\u09a1\u09bc\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/placard-claiming-activist-threatens-to-disrobe-herself-is-morphed-11520",
        headline:
          "Placard Claiming Activist Threatens To Disrobe Herself Is Morphed",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/image-of-mazaar-at-prayagraj-railway-station-viral-as-delhi-11529",
        headline: "Image Of Mazaar At Prayagraj Railway Station Viral As Delhi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/defunct-spy-station-in-berlin-falsely-shared-as-mosque-in-turkey-11505",
        headline:
          "Defunct Spy Station In Berlin Falsely Shared As Mosque In Turkey",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-old-photo-sikh-man-namaz-mosque-fact-check-farmers-protests-kisan-rally-11523",
        headline:
          "Old Photo Of Sikh Man Offering Namaz Falsely Linked To Farmers' Protest",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-balakot-zafar-hilaly-ani-india-today-surgical-strike-300-deaths-pakistan-narendra-modi-11501",
        headline:
          "ANI \u0993 \u0997\u09a3\u09ae\u09be\u09a7\u09cd\u09af\u09ae\u09c7 Fake News \u09aa\u09be\u0995 \u0995\u09c2\u099f\u09a8\u09c0\u09a4\u09bf\u0995 \u09b8\u09cd\u09ac\u09c0\u0995\u09be\u09b0 \u0995\u09b0\u09c7\u099b\u09c7 Balakot \u09aa\u09cd\u09b0\u09be\u09a3\u09b9\u09be\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/west-bengal-cm-mamata-banerjees-speech-cropped-newspaper-clip-hinduism-dainik-jugasankha-revived-11506",
        headline:
          "Hindu \u09a7\u09b0\u09cd\u09ae \u09a8\u09bf\u09df\u09c7 Mamata Banerjee-\u09b0 \u09ac\u0995\u09cd\u09a4\u09ac\u09cd\u09af \u09b8\u09b9 \u0996\u09ac\u09b0\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-picture-of-tomb-in-prayagraj-passed-off-as-mosque-at-old-delhi-railway-station-672842",
        headline:
          "Fact check: Picture of tomb in Prayagraj passed off as mosque at Old Delhi railway station",
      },
    ],
  },
  numpy_seed: 300,
  number_of_articles: 154,
}
