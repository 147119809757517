export const data = {
  mdsDat: {
    x: [
      -0.20817001266662352,
      -0.2985150809746824,
      0.027651296306965868,
      0.25970299919256556,
      0.21933079814177425,
    ],
    y: [
      0.262886630701537,
      -0.07939109209024182,
      -0.36537329315420114,
      0.030647996650254933,
      0.15122975789265095,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      17.898241736078234,
      17.891510143023183,
      16.804165848621533,
      24.99822275927376,
      22.40785951300331,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      11.0,
      11.0,
      7.0,
      7.0,
      5.0,
      6.0,
      12.0,
      5.0,
      5.0,
      10.0,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      2.000230984856376,
      1.000115492428188,
      1.000115492428188,
      7.0008084469973175,
      4.000461969712752,
      3.0003464772845647,
      3.0003464772845647,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      3.0003464772845647,
      4.000461969712752,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      1.9994786907928193,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      1.9994786907928193,
      7.997914763171277,
      5.998436072378458,
      2.999218036189229,
      2.999218036189229,
      2.999218036189229,
      2.999218036189229,
      2.999218036189229,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      2.999218036189229,
      2.999218036189229,
      1.9994786907928193,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      1.0000527968447936,
      5.000263984223968,
      5.000263984223968,
      4.000211187379175,
      9.00047517160314,
      3.0001583905343803,
      0.9999289103709501,
      4.99964455185475,
      1.9998578207419002,
      0.9999289103709501,
      1.9998578207419002,
      1.9998578207419002,
      0.9999289103709501,
      0.9999289103709501,
      0.9999289103709501,
      0.9999289103709501,
      11.999146924451402,
      6.999502372596651,
      4.99964455185475,
      4.99964455185475,
      4.99964455185475,
      2.9997867311128505,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      3.9997156414838004,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      4.99964455185475,
      1.9998578207419002,
      3.9997156414838004,
      3.0004670421070276,
      3.0004670421070276,
      3.0004670421070276,
      3.0004670421070276,
      5.000778403511713,
      1.0001556807023426,
      1.0001556807023426,
      1.0001556807023426,
      1.0001556807023426,
      1.0001556807023426,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      5.000778403511713,
      7.001089764916399,
      6.000934084214055,
      4.0006227228093705,
    ],
    Term: [
      "bjp",
      "modi",
      "communal",
      "covid",
      "khan",
      "bengal",
      "rally",
      "morph",
      "president",
      "congress",
      "portal",
      "manasja",
      "toolkit",
      "bolivia",
      "prevent",
      "pig",
      "relate",
      "cream",
      "fraudulent",
      "covid",
      "vaccination",
      "unrelated",
      "covid_vaccination",
      "registration",
      "run",
      "part",
      "telangana",
      "scholar",
      "secret",
      "link",
      "police",
      "urine",
      "stealing",
      "domestic",
      "reduce",
      "social",
      "take",
      "rama",
      "build",
      "syllabus",
      "catch",
      "communal",
      "khan",
      "food",
      "communal_narrative",
      "maid",
      "narrative",
      "miss",
      "daughter",
      "lock",
      "pizza",
      "detroit",
      "contaminate",
      "lucknow",
      "aamir",
      "revive",
      "police",
      "hindu",
      "amit",
      "amit_shah",
      "narendra_modi",
      "minister",
      "shah",
      "narendra",
      "pm",
      "capsule",
      "pm_modi",
      "drug",
      "morph",
      "president",
      "chowk",
      "modi",
      "name",
      "collection",
      "west",
      "poll",
      "egypt",
      "government",
      "resurface",
      "treasury",
      "percentage",
      "ghee",
      "supreme",
      "bjp",
      "bengal",
      "west_bengal",
      "leader",
      "mla",
      "kerala",
      "rss",
      "promise",
      "panchayat",
      "anil",
      "election",
      "adityanath",
      "get",
      "yogi",
      "worker",
      "rally",
      "upadhyay",
      "congress",
      "world_larg",
      "british",
      "world",
      "peaceful_political",
      "use",
      "mcd",
      "assam",
      "king",
      "tiwari",
      "boy",
      "kolkata",
      "bbc",
      "political",
      "large",
      "google",
      "peaceful",
      "kangana",
      "gathering",
      "leave",
      "rally",
      "congress",
      "meeting",
    ],
    Total: [
      11.0,
      11.0,
      7.0,
      7.0,
      5.0,
      6.0,
      12.0,
      5.0,
      5.0,
      10.0,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      1.000115492428188,
      2.000230984856376,
      1.000115492428188,
      1.000115492428188,
      7.0008084469973175,
      4.000461969712752,
      3.0003464772845647,
      3.0003464772845647,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      2.000230984856376,
      4.000502157986907,
      7.999835686604324,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      1.9994786907928193,
      0.9997393453964096,
      0.9997393453964096,
      0.9997393453964096,
      1.9994786907928193,
      7.997914763171277,
      5.998436072378458,
      2.999218036189229,
      2.999218036189229,
      2.999218036189229,
      2.999218036189229,
      2.999218036189229,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      1.9994786907928193,
      4.999075856931129,
      7.999835686604324,
      1.9994786907928193,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      3.0001583905343803,
      1.0000527968447936,
      5.000263984223968,
      5.000263984223968,
      4.000211187379175,
      11.00033299234504,
      4.000087300905331,
      0.9999289103709501,
      4.99964455185475,
      1.9998578207419002,
      0.9999289103709501,
      1.9998578207419002,
      1.9998578207419002,
      0.9999289103709501,
      0.9999289103709501,
      0.9999289103709501,
      0.9999289103709501,
      11.999146924451402,
      6.999502372596651,
      4.99964455185475,
      4.99964455185475,
      4.99964455185475,
      2.9997867311128505,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      4.999871322186143,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      1.9998578207419002,
      12.00073431677115,
      1.9998578207419002,
      10.000649725697855,
      3.0004670421070276,
      3.0004670421070276,
      3.0004670421070276,
      3.0004670421070276,
      5.000778403511713,
      1.0001556807023426,
      1.0001556807023426,
      1.0001556807023426,
      1.0001556807023426,
      1.0001556807023426,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      4.0006227228093705,
      6.000893895939901,
      12.00073431677115,
      10.000649725697855,
      5.00055163318032,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.7205,
      1.4328,
      1.0275,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.7208,
      1.2099,
      0.7398,
      1.7208,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.7835,
      1.5829,
      1.4959,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      1.1632,
      1.3864,
      1.3864,
      1.3864,
      1.3864,
      0.5108,
      1.3864,
      0.4699,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.4958,
      1.3134,
      0.9569,
      0.985,
      1.2727,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.1821,
      -4.8752,
      -4.8752,
      -2.9293,
      -3.4889,
      -3.7766,
      -3.7766,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -3.7766,
      -3.4889,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.1821,
      -4.8752,
      -4.8752,
      -4.8752,
      -4.1821,
      -2.7958,
      -3.0834,
      -3.7766,
      -3.7766,
      -3.7766,
      -3.7766,
      -3.7766,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -3.7766,
      -3.7766,
      -4.1821,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -4.8122,
      -3.2027,
      -3.2027,
      -3.4259,
      -2.615,
      -3.7136,
      -5.2095,
      -3.6,
      -4.5163,
      -5.2095,
      -4.5163,
      -4.5163,
      -5.2095,
      -5.2095,
      -5.2095,
      -5.2095,
      -2.7246,
      -3.2636,
      -3.6,
      -3.6,
      -3.6,
      -4.1109,
      -4.5163,
      -4.5163,
      -4.5163,
      -4.5163,
      -3.8232,
      -4.5163,
      -4.5163,
      -4.5163,
      -4.5163,
      -3.6,
      -4.5163,
      -3.8232,
      -4.0013,
      -4.0013,
      -4.0013,
      -4.0013,
      -3.4904,
      -5.0999,
      -5.0999,
      -5.0999,
      -5.0999,
      -5.0999,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.7136,
      -3.4904,
      -3.154,
      -3.3081,
      -3.7136,
    ],
  },
  "token.table": {
    Topic: [
      2,
      4,
      3,
      3,
      4,
      5,
      5,
      4,
      4,
      1,
      5,
      5,
      2,
      3,
      2,
      3,
      4,
      2,
      2,
      4,
      5,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      3,
      4,
      4,
      5,
      2,
      1,
      5,
      4,
      4,
      5,
      4,
      2,
      5,
      4,
      2,
      5,
      5,
      5,
      4,
      1,
      5,
      1,
      5,
      2,
      2,
      2,
      1,
      5,
      4,
      5,
      3,
      2,
      4,
      3,
      4,
      3,
      3,
      4,
      3,
      3,
      2,
      4,
      1,
      5,
      5,
      4,
      1,
      2,
      3,
      3,
      1,
      2,
      5,
      5,
      4,
      1,
      3,
      1,
      4,
      4,
      5,
      2,
      2,
      1,
      1,
      4,
      2,
      4,
      4,
      1,
      1,
      1,
      3,
      2,
      2,
      4,
      2,
      2,
      1,
      5,
      1,
      4,
      1,
      4,
      2,
      5,
      1,
      4,
      4,
      4,
      5,
      5,
      4,
    ],
    Freq: [
      1.0002607225621265,
      1.0000710946831446,
      0.9999472059425663,
      0.9999472059425663,
      1.0000710946831446,
      0.9998443435303659,
      0.9998443435303659,
      1.0000710946831446,
      1.0000710946831444,
      0.9998845209087726,
      0.9998443435303659,
      0.999844343530366,
      1.0002607225621265,
      0.9999472059425663,
      1.0002607225621265,
      0.999947205942566,
      1.0000710946831446,
      1.0002607225621265,
      1.0002607225621265,
      0.39997401266055005,
      0.5999610189908251,
      1.0002607225621265,
      0.9998845209087724,
      0.9998845209087724,
      0.9998845209087726,
      1.0002607225621265,
      1.0002607225621265,
      1.0002607225621265,
      0.999947205942566,
      1.0000710946831446,
      0.8000205889800861,
      0.20000514724502153,
      1.0002607225621265,
      0.9998845209087726,
      0.9998443435303659,
      1.0000710946831446,
      1.0000710946831446,
      0.9998443435303659,
      1.0000710946831446,
      1.0002607225621265,
      0.9998443435303659,
      1.0000710946831444,
      1.0002607225621265,
      0.9998443435303659,
      0.9998443435303659,
      0.9998443435303659,
      1.0000710946831446,
      0.16664183992264592,
      0.8332091996132296,
      0.7499058571960951,
      0.24996861906536505,
      1.0002607225621265,
      1.0002607225621265,
      1.0002607225621265,
      0.9998845209087726,
      0.9998443435303659,
      0.19997793710691197,
      0.7999117484276479,
      0.9999472059425663,
      1.0002607225621265,
      1.0000710946831446,
      0.8181570509059098,
      0.18181267797909106,
      0.9999472059425661,
      0.7499836314374981,
      0.24999454381249936,
      0.9999472059425663,
      0.9999472059425663,
      1.0002607225621265,
      1.0000710946831446,
      0.9998845209087726,
      0.9998443435303659,
      0.999844343530366,
      1.0000710946831446,
      0.9998845209087726,
      1.0002607225621265,
      0.9999472059425663,
      0.9999472059425663,
      0.500010269798163,
      0.3750077023486222,
      0.12500256744954075,
      0.9998443435303659,
      1.0000710946831446,
      0.9998845209087726,
      0.9999472059425661,
      0.9998845209087726,
      1.0000710946831446,
      0.4166411711167081,
      0.5832976395633914,
      1.0002607225621265,
      1.0002607225621265,
      0.9998845209087726,
      0.9998845209087726,
      1.0000710946831446,
      0.6001109176690235,
      0.40007394511268235,
      1.0000710946831446,
      0.9998845209087726,
      0.9998845209087726,
      0.9998845209087726,
      0.9999472059425663,
      1.0002607225621265,
      1.0002607225621265,
      1.0000710946831446,
      1.0002607225621265,
      1.0002607225621265,
      0.9998845209087726,
      0.9998443435303659,
      0.9998845209087726,
      1.0000710946831446,
      0.9998845209087724,
      1.0000710946831446,
      1.0002607225621265,
      0.9998443435303659,
      0.9998845209087726,
      1.0000710946831446,
      1.0000710946831446,
      1.0000710946831446,
      0.999844343530366,
      0.999844343530366,
      1.0000710946831446,
    ],
    Term: [
      "aamir",
      "adityanath",
      "amit",
      "amit_shah",
      "anil",
      "assam",
      "bbc",
      "bengal",
      "bjp",
      "bolivia",
      "boy",
      "british",
      "build",
      "capsule",
      "catch",
      "chowk",
      "collection",
      "communal",
      "communal_narrative",
      "congress",
      "congress",
      "contaminate",
      "covid",
      "covid_vaccination",
      "cream",
      "daughter",
      "detroit",
      "domestic",
      "drug",
      "egypt",
      "election",
      "election",
      "food",
      "fraudulent",
      "gathering",
      "get",
      "ghee",
      "google",
      "government",
      "hindu",
      "kangana",
      "kerala",
      "khan",
      "king",
      "kolkata",
      "large",
      "leader",
      "leave",
      "leave",
      "link",
      "link",
      "lock",
      "lucknow",
      "maid",
      "manasja",
      "mcd",
      "meeting",
      "meeting",
      "minister",
      "miss",
      "mla",
      "modi",
      "modi",
      "morph",
      "name",
      "name",
      "narendra",
      "narendra_modi",
      "narrative",
      "panchayat",
      "part",
      "peaceful",
      "peaceful_political",
      "percentage",
      "pig",
      "pizza",
      "pm",
      "pm_modi",
      "police",
      "police",
      "police",
      "political",
      "poll",
      "portal",
      "president",
      "prevent",
      "promise",
      "rally",
      "rally",
      "rama",
      "reduce",
      "registration",
      "relate",
      "resurface",
      "revive",
      "revive",
      "rss",
      "run",
      "scholar",
      "secret",
      "shah",
      "social",
      "stealing",
      "supreme",
      "syllabus",
      "take",
      "telangana",
      "tiwari",
      "toolkit",
      "treasury",
      "unrelated",
      "upadhyay",
      "urine",
      "use",
      "vaccination",
      "west",
      "west_bengal",
      "worker",
      "world",
      "world_larg",
      "yogi",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-no-lowes-is-not-offering-150-coupon-by-clicking-on-a-link-viral-post-is-fake/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check: No, Lowe\u2019s is Not Offering $150 Coupon By Clicking on a link; Viral Post is Fake",
      },
      {
        url:
          "https://factly.in/telugu-a-hen-was-not-arrested-by-telangana-police-under-murder-charges/",
        headline:
          " \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c39\u0c24\u0c4d\u0c2f\u0c3e \u0c28\u0c47\u0c30\u0c02 \u0c15\u0c3f\u0c02\u0c26 \u0c12\u0c15 \u0c15\u0c4b\u0c21\u0c3f\u0c28\u0c3f \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41; \u0c26\u0c30\u0c4d\u0c2f\u0c3e\u0c2a\u0c4d\u0c24\u0c41\u0c32\u0c4b \u0c2d\u0c3e\u0c17\u0c02\u0c17\u0c3e \u0c38\u0c02\u0c30\u0c15\u0c4d\u0c37\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/no-g-o-has-been-issued-declaring-holidays-to-schools-and-colleges-in-telangana-from-01-march-to-07-may-2021/",
        headline:
          " No G.O. has been issued declaring holidays to schools and colleges in Telangana from 01 March to 07 May 2021",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-floating-stone-by-smaban-abbas-cairo-airport-rock-sculpture-explained-prophet-muhammad-12224",
        headline:
          "\u0915\u094d\u092f\u093e \u0939\u0948 \u090f\u0915 \u0930\u0938\u094d\u0938\u0940 \u0938\u0947 \u092c\u0902\u0927\u0947 \u0907\u0928 \u0926\u094b \u092a\u0924\u094d\u0925\u0930\u094b\u0902 \u0915\u093e \u0930\u0939\u0938\u094d\u092f?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/greta-thunberg-edited-photo-greta-toolkit-farmers-protest-farm-bills-2020-ask-greta-why-viral-photo-12206",
        headline:
          "\u099f\u09c1\u09b2\u0995\u09bf\u099f \u0995\u09be\u09a3\u09cd\u09a1: \u099f\u09cd\u09b0\u09c7\u09a8\u09c7\u09b0 \u09ae\u09a7\u09cd\u09af\u09c7 \u0997\u09cd\u09b0\u09c7\u099f\u09be \u09a5\u09c1\u09a8\u09ac\u09be\u09b0\u09cd\u0997\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u099b\u09ac\u09bf \u09ab\u09bf\u09b0\u09c7 \u098f\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/02/fact-check-2020-video-of-police-action-during-covid-19-curfew-in-amravati-shared-as-recent/",
        headline:
          "Fact Check: 2020 Video Of Police Action During COVID-19 Curfew In Amravati Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/05/fact-check-dont-fall-for-fake-links-related-to-covid-19-vaccination-registration/",
        headline:
          "Fact Check: Don\u2019t Fall For FAKE Links Related To COVID-19 Vaccination Registration",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/05/fact-check-uttarakhand-police-is-not-running-a-mask-checking-drive-post-is-fake/",
        headline:
          "Fact Check: Uttarakhand Police Is Not Running A Mask Checking Drive; Post Is Fake",
      },
      {
        url: "https://factly.in/amul-ice-cream-does-not-contain-pig-fat/",
        headline: " Amul Ice Cream does not contain Pig Fat",
      },
      {
        url:
          "https://factly.in/telugu-the-auto-driver-in-this-video-did-not-perform-these-stunts-as-part-of-trial-run-at-rto/",
        headline:
          " \u0c2e\u0c39\u0c3e\u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c32\u0c4b\u0c28\u0c3f \u0c06\u0c30\u0c4d\u0c1f\u0c40\u0c13 \u0c06\u0c2b\u0c40\u0c38\u0c4d\u200c\u0c32\u0c4b \u0c1f\u0c4d\u0c30\u0c2f\u0c32\u0c4d \u0c30\u0c28\u0c4d \u0c32\u0c4b \u0c2d\u0c3e\u0c17\u0c02\u0c17\u0c3e \u0c21\u0c4d\u0c30\u0c48\u0c35\u0c30\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c35\u0c3f\u0c28\u0c4d\u0c2f\u0c3e\u0c38\u0c3e\u0c32\u0c41 \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/inaccurate-information-related-to-new-covid-19-vaccination-policy-is-being-shared/",
        headline:
          " Inaccurate information related to new COVID-19 vaccination policy is being shared",
      },
      {
        url:
          "https://factly.in/fraudulent-website-link-shared-as-official-covid-19-vaccination-registration-portal/",
        headline:
          " Fraudulent website link shared as official COVID-19 Vaccination registration portal",
      },
      {
        url:
          "https://factly.in/all-these-nations-are-providing-covid-19-vaccinations-free-of-charge-to-their-citizens/",
        headline:
          " All these nations are providing COVID-19 vaccinations free of charge to their citizens.",
      },
      {
        url:
          "https://factly.in/telugu-the-hathras-victim-was-shot-dead-by-the-accused-gaurav-sharma-not-by-the-police/",
        headline:
          " \u0c39\u0c24\u0c4d\u0c30\u0c3e\u0c38\u0c4d \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c30\u0c3e\u0c32\u0c3f \u0c24\u0c02\u0c21\u0c4d\u0c30\u0c3f\u0c28\u0c3f \u0c39\u0c24\u0c4d\u0c2f \u0c1a\u0c47\u0c38\u0c3f\u0c02\u0c26\u0c3f \u0c17\u0c4c\u0c30\u0c35\u0c4d \u0c36\u0c30\u0c4d\u0c2e \u0c05\u0c28\u0c47 \u0c28\u0c3f\u0c02\u0c26\u0c3f\u0c24\u0c41\u0c21\u0c41, \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/unrelated-video-clips-falsely-shared-as-medicine-jihad-against-the-hindus-in-india/",
        headline:
          " Unrelated video clips falsely shared as \u2018Medicine-Jihad\u2019 against the Hindus in India",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-photos-shared-as-secret-tunnels-hidden-in-the-city-of-dwarka/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c26\u0c4d\u0c35\u0c3e\u0c30\u0c15 \u0c28\u0c17\u0c30\u0c02\u0c32\u0c4b \u0c26\u0c3e\u0c17\u0c3f \u0c09\u0c28\u0c4d\u0c28 \u0c30\u0c39\u0c38\u0c4d\u0c2f \u0c38\u0c4a\u0c30\u0c02\u0c17\u0c3e\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/an-edited-video-falsely-claims-that-eating-bananas-can-prevent-covid-19/",
        headline:
          " An edited video falsely claims that eating bananas can prevent COVID-19",
      },
      {
        url:
          "https://factly.in/video-of-a-railing-collapse-in-bolivia-is-passed-off-as-from-a-covid-19-vaccine-centre-in-bandra/",
        headline:
          " Video of a railing collapse in Bolivia is passed off as from a COVID-19 vaccine centre in Bandra",
      },
      {
        url:
          "https://factly.in/unrelated-photos-falsely-shared-as-wealth-left-by-the-dead-kuwait-billionaire-nasser-al-kharafi/",
        headline:
          " Unrelated photos falsely shared as wealth left behind by the dead Kuwait billionaire Nasser Al-Kharafi",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/fact-check-indias-first-pm-jawaharlal-nehru-pic-shared-with-false-claim/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: \u09e7\u09ef\u09ec\u09e8 \u099a\u09a8\u09f0 \u09af\u09c1\u09a6\u09cd\u09a7\u09f0 \u09b8\u09c8\u09a4\u09c7 \u099c\u09a1\u09bc\u09bf\u09a4 \u0995\u09f0\u09bf \u09aa\u09a3\u09cd\u09a1\u09bf\u09a4 \u09a8\u09c7\u09b9\u09f0\u09c1 \u09b8\u09a8\u09cd\u09a6\u09f0\u09cd\u09ad\u09a4 \u0995\u09f0\u09be \u09a6\u09be\u09ac\u09c0 \u09ae\u09a8\u09c7\u09b8\u099c\u09be",
      },
    ],
    "2": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-ira-khan-aamir-khan-nupur-shikhare-instagram-bollywood-12175",
        headline: "Fake, Communal Claim Targets Aamir Khan's Daughter Ira Khan",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-girl-missing-clip-mangalore-mumbai-beggars-child-found-factcheck-12217",
        headline: "Video Of Missing Child Viral With False Claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-lucknow-constable-adesh-kumar-salim-muslim-hindu-shoplifting-stealing-mall-clothes-police-communal-12190",
        headline:
          "Video Of Cop Caught Shoplifting In Lucknow Peddled With Communal Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/man-spitting-in-naan-muslims-food-jihad-viral-videos-fact-check-fake-news-12197",
        headline: "Debunked Claims Of Muslims Contaminating Food Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/man-spits-in-pizza-viral-video-muslim-islamophobia-fact-check-fake-news-12188",
        headline:
          "2018 Video Of Detroit Man Spitting On Pizza Revived With Communal Spin",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-christian-cross-edlapadu-andhra-pradesh-narasimha-guntur-12216",
        headline:
          "Cross Not Built On Hill With Hindu Shrine: AP Police Debunks Viral Claim",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bhopal-domestic-help-urinating-water-viral-video-fake-communal-news-12178",
        headline:
          "\u092a\u093e\u0928\u0940 \u092e\u0947\u0902 \u092a\u0947\u0936\u093e\u092c \u092e\u093f\u0932\u093e\u0924\u0940 \u0928\u094c\u0915\u0930\u093e\u0928\u0940 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u092e\u094d\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0915\u094b\u0923 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/usa-2018-video-man-spitting-on-pizza-detroit-algeria-muslim-fake-communal-claim-12194",
        headline:
          "\u0921\u0947\u091f\u094d\u0930\u0949\u0907\u091f \u092e\u0947\u0902 \u092a\u093f\u095b\u094d\u095b\u093e \u092a\u0930 \u0925\u0942\u0915\u0924\u0947 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/videos-fake-claim-target-muslim-detroit-hyderabad-milk-rotis-pizza-meerut-man-spitting-arrested-12219",
        headline:
          "\u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0938\u0902\u092a\u094d\u0930\u0926\u093e\u092f \u0915\u094b \u0928\u093f\u0936\u093e\u0928\u093e \u092c\u0928\u093e\u0924\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094d\u0932\u093f\u092a\u094d\u0938 \u092b\u093f\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-christian-cross-edlapadu-andhra-pradesh-narasimha-guntur-12235",
        headline:
          "\u09aa\u09be\u09b9\u09be\u09a1\u09bc\u09c7 \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09a7\u09b0\u09cd\u09ae\u09b8\u09cd\u09a5\u09be\u09a8\u09c7\u09b0 \u0989\u09aa\u09b0 \u0995\u09cd\u09b0\u09c1\u09b6\u099f\u09bf \u09ac\u09b8\u09be\u09a8\u09cb \u09b9\u09df\u09a8\u09bf: \u0985\u09a8\u09cd\u09a7\u09cd\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6 \u09aa\u09c1\u09b2\u09bf\u09b6",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-ira-khan-aamir-khan-nupur-shikhare-instagram-bollywood-12185",
        headline:
          "\u0986\u09ae\u09bf\u09b0 \u0996\u09be\u09a8\u09c7\u09b0 \u09ae\u09c7\u09df\u09c7 \u0987\u09b0\u09be \u0996\u09be\u09a8\u0995\u09c7 \u09b2\u0995\u09cd\u09b7\u09cd\u09af \u0995\u09b0\u09c7 \u09ad\u09c1\u09df\u09cb \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09a6\u09be\u09ac\u09bf \u0995\u09b0\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-girl-missing-clip-mangalore-mumbai-beggars-child-found-factcheck-12238",
        headline:
          "\u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09a8\u09bf\u0996\u09cb\u0981\u099c \u09b6\u09bf\u09b6\u09c1\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/fast-check-did-telugu-actor-ram-charan-adopted-islam-12191",
        headline:
          "\u09a4\u09c7\u09b2\u09c7\u0997\u09c1 \u0985\u09ad\u09bf\u09a8\u09c7\u09a4\u09be \u09b0\u09be\u09ae \u099a\u09b0\u09a8\u09c7\u09b0 \u0987\u09b8\u09b2\u09be\u09ae \u09a7\u09b0\u09cd\u09ae \u0997\u09cd\u09b0\u09b9\u09a3\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-up-cop-shared-with-false-communal-narrative-674928",
        headline:
          "FACT CHECK: Viral video of UP cop shared with false communal narrative",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/domestic-help-who-contaminated-food-of-her-employer-in-bhopal-is-not-muslim-674980",
        headline:
          "Fact check: Domestic help who contaminated food of her employer in Bhopal is not Muslim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/05/fact-check-no-cbse-has-not-reduced-class-10-social-science-syllabus-further-heres-the-truth/",
        headline:
          "Fact Check: No, CBSE Has NOT Reduced Class 10 Social Science Syllabus Further; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/old-debunked-video-of-a-maid-mixing-urine-in-the-food-is-shared-again-with-communal-narrative/",
        headline:
          " Old debunked video of a maid mixing urine in the food is shared again with a communal narrative",
      },
      {
        url:
          "https://factly.in/telugu-2020-lockdown-video-from-maharastra-shared-as-recent-one/",
        headline:
          " 2020 \u0c32\u0c3e\u0c15\u0c4d \u0c21\u0c4c\u0c28\u0c4d \u0c38\u0c2e\u0c2f\u0c02\u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c2e\u0c39\u0c3e\u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c32\u0c4b \u0c35\u0c3f\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c32\u0c3e\u0c15\u0c4d \u0c21\u0c4c\u0c28\u0c4d \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-video-of-a-missing-girl-who-got-reunited-with-her-parents-revived-again/",
        headline:
          " Old video of a missing girl already reunited with her parents is revived again",
      },
      {
        url:
          "https://factly.in/video-of-police-constable-caught-stealing-in-a-mall-in-lucknow-is-passed-off-with-a-communal-spin/",
        headline:
          " Video of a police constable caught stealing in a mall in Lucknow is passed off with a communal narrative",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/viral/fact-check-the-child-seen-in-the-picture-with-timur-is-not-the-newborn-son-of-saif-ali-khan-and-kareena-kapoor-2/?itm_source=homepage&itm_medium=dktp_s3&itm_campaign=editorpick",
        headline:
          "Fact Check: \u09ab\u099f\u09cb\u09a4 \u09a4\u09be\u0987\u09ae\u09c1\u09f0\u09f0 \u09b8\u09c8\u09a4\u09c7 \u09a5\u0995\u09be \u0995\u09c7\u0981\u099a\u09c1\u09f1\u09be\u099f\u09bf \u099b\u09c8\u0988\u09ab \u0986\u09b2\u09c0 \u0996\u09be\u09a8 \u0986\u09f0\u0995\u09c1 \u0995\u09f0\u09c0\u09a3\u09be \u0995\u09be\u09aa\u09c1\u09f0\u09f0 \u09a8\u09f1\u099c\u09be\u09a4 \u09b8\u09a8\u09cd\u09a4\u09be\u09a8 \u09a8\u09b9\u09af\u09bc",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/society/quick-fact-check-fake-post-about-himalaya-company-went-viral/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Quick Fact Check : \u09b9\u09bf\u09ae\u09be\u09b2\u09af\u09bc\u09be \u0995\u09cb\u09ae\u09cd\u09aa\u09be\u09a8\u09c0\u0995 \u09b2\u09c8 \u09aa\u09c1\u09a8\u09f0 \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09b9\u2019\u09b2 \u09ad\u09c1\u09f1\u09be \u09aa\u09cb\u09b7\u09cd\u099f",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/edited-image-of-pakistan-station-chowk-viral-image-narendra-modi-chowk-darbhanga-bihar-12187",
        headline:
          "Edited Image Of Pakistan Street Peddled As Chowk Named After PM Modi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/narendra-modi-pamela-goswami-riding-bicycle-edited-photo-bjp-viral-image-12226",
        headline:
          "Morphed Photo Of PM Modi And Pamela Goswami Riding Bicycle Surfaces",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/former-president-pratibha-patil-praises-prime-minister-narendra-modi-fact-check-fake-news-12228",
        headline: "No, Ex-President Pratibha Patil Did Not Praise PM Modi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-sushil-modi-congress-education-ministers-muslims-kl-shrimali-12218",
        headline:
          "Independent India's First Five Education Ministers Muslims?A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/misleading-screengrab-claims-amit-shah-disrespected-president-kovind-12203",
        headline:
          "Misleading Screengrab Claims Amit Shah Disrespected President Kovind",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-esoral-20-tablet-capsule-iron-nails-viral-clip-india-pakistan-bangladesh-pharmaceutical-factcheck-12201",
        headline: "Disturbing Videos Of Capsules With Nails Are Not From India",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/iron-nails-in-medicine-capsule-viral-video-hindu-muslim-12204",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915: \u0915\u094d\u092f\u093e \u0915\u0948\u092a\u094d\u0938\u0942\u0932 \u092e\u0947\u0902 \u0932\u094b\u0939\u0947 \u0915\u0940 \u0915\u0940\u0932 \u092d\u0930\u0915\u0930 \u092c\u0947\u091a \u0930\u0939\u0947 \u0939\u0948\u0902 \u092e\u0941\u0938\u0932\u092e\u093e\u0928?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/former-president-pratibha-patil-praised-narendra-modi-fake-statement-viral-12231",
        headline:
          "\u092a\u0942\u0930\u094d\u0935 \u0930\u093e\u0937\u094d\u091f\u094d\u0930\u092a\u0924\u093f \u092a\u094d\u0930\u0924\u093f\u092d\u093e \u092a\u093e\u091f\u093f\u0932 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u092c\u092f\u093e\u0928 \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/misleading-screengrab-claims-amit-shah-disrespected-president-kovind-12223",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u099b\u09ac\u09bf\u09b0 \u09a6\u09be\u09ac\u09bf \u0985\u09ae\u09bf\u09a4 \u09b6\u09be\u09b9 \u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09aa\u09a4\u09bf \u0995\u09cb\u09ac\u09bf\u09a8\u09cd\u09a6\u0995\u09c7 \u09b8\u09ae\u09cd\u09ae\u09be\u09a8 \u09a6\u09c7\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-esoral-20-tablet-capsule-iron-nails-viral-clip-india-pakistan-bangladesh-pharmaceutical-factcheck-12209",
        headline:
          "\u0993\u09b7\u09c1\u09a7\u09c7\u09b0 \u09b8\u09cd\u099f\u09cd\u09b0\u09bf\u09aa\u09c7 \u0995\u09cd\u09af\u09be\u09aa\u09b8\u09c1\u09b2\u09c7\u09b0 \u09ac\u09a6\u09b2\u09c7 \u0986\u09b2\u09aa\u09bf\u09a8 \u09ad\u09b0\u09be \u09ad\u09bf\u09a1\u09bf\u0993\u0997\u09c1\u09b2\u09bf \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-narendra-modi-amit-shah-muslim-skullcap-photoshopped-west-bengal-election-2021-mamata-avishek-bjp-tmc-left-congress-12230",
        headline:
          "\u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u099f\u09c1\u09aa\u09bf \u09ae\u09be\u09a5\u09be\u09df \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0 \u0993 \u0985\u09ae\u09bf\u09a4 \u09b6\u09be\u09b9\u09c7\u09b0 \u09ab\u09cb\u099f\u09cb\u09b6\u09aa \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-morphed-photo-claims-to-show-narendra-modi-chowk-674948",
        headline:
          "Fact Check: Morphed photo claims to show 'Narendra Modi Chowk'",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-first-five-ministers-of-education-did-not-come-from-the-same-community-675093",
        headline:
          "Fact Check: First five ministers of education did not come from the same community",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-karachi-bakery-did-not-shut-its-mumbai-branch-because-of-name-controversy-675023",
        headline:
          "Fact check: No, Karachi Bakery did not shut its Mumbai branch because of name controversy",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-president-kovind-shared-with-misleading-claim-675018",
        headline:
          "Fact check: Viral image of President Kovind shared with misleading claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/03/fact-check-no-this-picture-is-not-of-narendra-modi-chowk-in-india-picture-is-morphed/",
        headline:
          "Fact Check: No! This Picture Is Not Of \u2018Narendra Modi Chowk\u2019 In India; Picture Is Morphed",
      },
      {
        url:
          "https://factly.in/telugu-the-debts-of-both-america-and-india-have-increased-in-the-last-year/",
        headline:
          " \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e \u0c26\u0c47\u0c36\u0c2a\u0c41 \u0c05\u0c2a\u0c4d\u0c2a\u0c41 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c15\u0c3e\u0c26\u0c41, \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c02 \u0c05\u0c2a\u0c4d\u0c2a\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c17\u0c24 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c02 \u0c2a\u0c46\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/morphed-photo-from-pakistan-shared-as-modis-statue-at-narendra-modi-chowk/",
        headline:
          " Morphed photo from Pakistan shared as Modi\u2019s statue at \u2018Narendra Modi Chowk\u2019",
      },
      {
        url:
          "https://factly.in/morphed-image-shared-as-modi-riding-bicycle-alongside-drug-case-accused-pamela-goswami/",
        headline:
          " Morphed image shared as Modi riding bicycle alongside drug case accused Pamela Goswami",
      },
    ],
    "4": [
      {
        url:
          "https://factly.in/2019-video-of-rss-parade-in-hyderabad-portrayed-as-protest-against-the-pfi-rally-in-kerala/",
        headline:
          " 2019 video of RSS parade in Hyderabad portrayed as a protest against the PFI rally in Kerala",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-old-video-yogi-adityanath-west-bengal-rally-bjp-workers-bribing-people-to-attend-factcheck-12225",
        headline:
          "Old Video Shared As BJP Workers Bribing Crowd At Yogi Adityanath's Rally",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/kerala-bjp-n-sreeprakash-provide-quality-beef-2021-assembly-election-fact-check-fake-news-12236",
        headline:
          "BJP Leader's 2017 Promise Of 'Good Beef' Revived Ahead Of Kerala Polls",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/congress-mla-anil-upadhyay-video-cab-protests-mohan-chandra-pandey-12179",
        headline:
          "\u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0935\u093f\u0927\u093e\u092f\u0915 \u0905\u0928\u093f\u0932 \u0909\u092a\u093e\u0927\u094d\u092f\u093e\u092f \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u0907\u0938 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/kerala-assembly-election-2021-bjp-cpm-pinarayi-vijayan-old-news-report-12239",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u0947\u0930\u0932\u093e \u091a\u0941\u0928\u093e\u0935 \u0915\u094b \u0932\u0947\u0915\u0930 \u092c\u0940\u091c\u0947\u092a\u0940 \u0928\u0947 \u0915\u093f\u092f\u093e '\u0905\u091a\u094d\u091b\u0947 \u092c\u0940\u095e' \u0915\u093e \u0935\u093e\u0926\u093e?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/covidshield-vaccine-india-to-canada-justin-trudeau-narendra-modi-vaccine-maitri-programme-fake-news-12215",
        headline:
          "\u092d\u093e\u0930\u0924 \u0938\u0947 \u0935\u0948\u0915\u094d\u0938\u0940\u0928 \u092e\u093f\u0932\u0928\u0947 \u0915\u0947 \u0938\u092e\u094d\u092e\u093e\u0928 \u092e\u0947\u0902 \u0915\u0928\u093e\u0921\u093e \u092e\u0947\u0902 \u0924\u093f\u0930\u0902\u0917\u093e \u0915\u093e\u0930 \u0930\u0948\u0932\u0940 \u0928\u093f\u0915\u093e\u0932\u0940 \u0917\u0908?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2018-video-of-whirlwind-in-kenya-shared-as-trouble-at-bjp-meeting-in-west-bengal-assembly-poll-12237",
        headline:
          "\u0998\u09c2\u09b0\u09cd\u09a3\u09bf \u09b9\u09be\u0993\u09df\u09be\u09df \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf\u09b0 \u09b8\u09ad\u09be \u09ad\u09a8\u09cd\u09a1\u09c1\u09b2 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09c7\u09a8\u09bf\u09df\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/fast-check-secret-meeting-bjp-leader-suvendu-adhikari-with-indian-secular-front-leader-abbas-siddiqui-fake-image-bengal-assembly-election-12186",
        headline:
          "\u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09a8\u09c7\u09a4\u09be \u09b6\u09c1\u09ad\u09c7\u09a8\u09cd\u09a6\u09c1 \u0985\u09a7\u09bf\u0995\u09be\u09b0\u09c0\u09b0 \u09b8\u09be\u09a5\u09c7 \u0986\u09ac\u09cd\u09ac\u09be\u09b8 \u09b8\u09bf\u09a6\u09cd\u09a6\u09bf\u0995\u09bf? \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-modi-cyclesbut-not-with-tainted-bengal-leader-675119",
        headline:
          "FACT CHECK: Modi cycles...but not with tainted Bengal leader",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-photo-falsely-claims-puducherry-cong-mla-was-attacked-for-joining-bjp-675132",
        headline:
          "Fact Check: Viral photo falsely claims Puducherry Cong MLA was attacked for joining BJP",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-news-of-elon-musks-death-is-hoax-675172",
        headline: "Fact Check: News of Elon Musk's death is hoax",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-message-about-up-panchayat-election-is-fake-674937",
        headline:
          "FACT CHECK: Viral message about UP Panchayat election is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bjp-leader-claims-about-huge-cross-narasimha-swami-idol-located-on-same-hillock-is-false-674981",
        headline:
          "Fact check: BJP leader's claim about cross, Narasimha Swami Idol located on same hillock is false",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/02/fact-check-old-video-of-rss-march-in-hyderabad-resurfaces-with-misleading-claims/",
        headline:
          "Fact Check: Old Video Of RSS March In Hyderabad Resurfaces With Misleading Claims",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/02/fact-check-old-video-of-bjp-leader-from-west-bengal-resurfaces-ahead-of-upcoming-state-polls/",
        headline:
          "Fact Check: Old Video Of BJP Leader From West Bengal Resurfaces Ahead Of Upcoming State Polls",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/03/fact-check-2011-picture-from-egypt-falsely-shared-as-recent-one-from-congress-cpim-rally-in-bengal/",
        headline:
          "Fact Check: 2011 Picture from Egypt Falsely Shared As Recent One From Congress & CPIM Rally In Bengal",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/05/fact-check-2019-general-election-schedule-shared-as-upcoming-up-panchayat-election-dates/",
        headline:
          "Fact Check: 2019 General Election Schedule Shared As Upcoming UP Panchayat Election Dates",
      },
      {
        url:
          "https://factly.in/congress-mla-anil-upadhyay-old-fictional-character-revived-this-time-as-mla-praising-modi/",
        headline:
          " \u2018Congress MLA Anil Upadhyay ?\u2019 \u2013 Old fictional character revived this time as MLA praising Modi",
      },
      {
        url:
          "https://factly.in/telugu-sci-has-not-ruled-that-harassing-or-attacking-christians-is-punishable-by-up-to-ten-years-in-prison/",
        headline:
          " \u0c15\u0c4d\u0c30\u0c48\u0c38\u0c4d\u0c24\u0c35\u0c41\u0c32\u0c28\u0c41 \u0c35\u0c47\u0c27\u0c3f\u0c38\u0c4d\u0c24\u0c47 \u0c32\u0c47\u0c26\u0c3e \u0c35\u0c3e\u0c30\u0c3f \u0c2a\u0c48 \u0c26\u0c3e\u0c21\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c47, \u0c2a\u0c26\u0c47\u0c33\u0c4d\u0c32 \u0c1c\u0c48\u0c32\u0c41 \u0c36\u0c3f\u0c15\u0c4d\u0c37 \u0c05\u0c28\u0c3f \u0c38\u0c41\u0c2a\u0c4d\u0c30\u0c40\u0c02 \u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41 \u0c24\u0c40\u0c30\u0c4d\u0c2a\u0c41 \u0c07\u0c35\u0c4d\u0c35\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-even-during-the-upa-government-india-had-us-treasury-securities/",
        headline:
          " \u0c2f\u0c42\u0c2a\u0c40\u0c0f \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c39\u0c2f\u0c3e\u0c02\u0c32\u0c4b \u0c15\u0c42\u0c21\u0c3e \u0c2d\u0c3e\u0c30\u0c24\u0c4d \u0c26\u0c17\u0c4d\u0c17\u0c30 \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e \u0c1f\u0c4d\u0c30\u0c46\u0c1c\u0c30\u0c40 \u0c38\u0c46\u0c15\u0c4d\u0c2f\u0c42\u0c30\u0c3f\u0c1f\u0c40\u0c32\u0c41 \u0c09\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-congress-government-allocated-larger-percentage-of-budget-for-these-schemes-compared-to-the-bjp/",
        headline:
          " BJP\u0c24\u0c4b \u0c2a\u0c4b\u0c32\u0c4d\u0c1a\u0c41\u0c15\u0c41\u0c02\u0c1f\u0c47 \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c08 \u0c2a\u0c25\u0c15\u0c3e\u0c32\u0c15\u0c41 \u0c2c\u0c21\u0c4d\u0c1c\u0c46\u0c1f\u0c4d \u0c32\u0c4b \u0c0e\u0c15\u0c4d\u0c15\u0c41\u0c35 \u0c36\u0c3e\u0c24\u0c02 \u0c15\u0c47\u0c1f\u0c3e\u0c2f\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/old-video-falsely-shared-as-bjp-workers-distributing-money-for-yogi-adityanaths-rally-in-west-bengal/",
        headline:
          " Old video falsely shared as BJP workers distributing money for Yogi Adityanath\u2019s rally in West Bengal",
      },
      {
        url:
          "https://factly.in/old-video-from-kenya-portrayed-as-visuals-of-a-sand-tornado-disrupting-bjp-meeting-in-west-bengal/",
        headline:
          " Old video from Kenya portrayed as visuals of a sand tornado disrupting BJP meeting in West Bengal",
      },
      {
        url:
          "https://factly.in/telugu-the-argument-that-toll-collections-have-increased-simply-because-of-fastags-is-not-correct/",
        headline:
          " \u0c2b\u0c3e\u0c38\u0c4d\u0c1f\u0c4d\u200c\u0c1f\u0c4d\u0c2f\u0c3e\u0c17\u0c4d\u200c \u0c35\u0c32\u0c4d\u0c32 \u0c30\u0c4b\u0c1c\u0c41\u0c15\u0c3f 17 \u0c15\u0c4b\u0c1f\u0c4d\u0c32 \u0c1f\u0c4b\u0c32\u0c4d \u0c15\u0c32\u0c46\u0c15\u0c4d\u0c37\u0c28\u0c4d\u0c38\u0c4d \u0c2a\u0c46\u0c30\u0c17\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-bjp-mla-sudhir-gadgils-car-was-not-caught-with-rs-20000-crores-worth-of-new-currency/",
        headline:
          " \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 MLA \u0c38\u0c41\u0c27\u0c40\u0c30\u0c4d \u0c17\u0c3e\u0c21\u0c4d\u0c17\u0c3f\u0c32\u0c4d \u0c15\u0c3e\u0c30\u0c41\u0c32\u0c4b 20 \u0c35\u0c47\u0c32 \u0c15\u0c4b\u0c1f\u0c4d\u0c32\u0c41 \u0c35\u0c3f\u0c32\u0c41\u0c35\u0c48\u0c28 \u0c15\u0c4a\u0c24\u0c4d\u0c24 2000 \u0c28\u0c4b\u0c1f\u0c4d\u0c32\u0c41 \u0c26\u0c4a\u0c30\u0c15\u0c32\u0c47\u0c26\u0c41",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/old-image-from-a-2019-left-front-rally-is-being-shared-as-recent-one-from-congress-left-meeting/",
        headline:
          " Old image from a 2019 Left front rally is being shared as a recent one from Congress -Left meeting",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/yes-vehicles-registered-in-other-states-used-by-tmc-for-west-bengal-assembly-poll-campaign-didir-doot-12181",
        headline:
          "\u09b9\u09cd\u09af\u09be\u0981, \u09a4\u09c3\u09a3\u09ae\u09c2\u09b2 \u0995\u0982\u0997\u09cd\u09b0\u09c7\u09b8 \u09b0\u09be\u099c\u09a8\u09c8\u09a4\u09bf\u0995 \u09aa\u09cd\u09b0\u099a\u09be\u09b0\u09c7 \u09ad\u09bf\u09a8\u09cd \u09b0\u09be\u099c\u09cd\u09af\u09c7\u09b0 \u09af\u09be\u09a8 \u09ac\u09cd\u09af\u09ac\u09b9\u09be\u09b0 \u0995\u09b0\u099b\u09c7",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-sea-of-humanity-in-viral-images-is-not-from-cpim-congress-recent-kolkata-rally-674889",
        headline:
          "Fact check: `Sea of humanity' in viral images is not from CPIM-Congress' recent Kolkata rally",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-vintage-bicycle-in-viral-photo-was-not-used-in-indian-police-stations-during-british-rule-674911",
        headline:
          "Fact Check: Vintage bicycle in viral photo was not used in Indian police stations during British rule",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/west-bengal-assembly-poll-2021-fact-check-did-bbc-report-left-rally-in-kolkatas-brigade-as-worlds-largest-peaceful-political-gathering-12202",
        headline:
          "Fake BBC Graphic Claims Brigade Rally Was Largest Peaceful Gathering",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/chhattisgarh-congress-falsely-claims-aap-lost-deposit-in-1-mcd-seat-12221",
        headline:
          "Chhattisgarh Congress Falsely Claims AAP Lost Deposit In 1 MCD Seat",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/congress-shares-photos-from-taiwan-tea-garden-as-assam-12233",
        headline: "Congress Shares Photos From Taiwan Tea Garden As Assam",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/congress-left-rally-in-kolkata-west-bengal-election-2021-tmc-12176",
        headline:
          "\u0935\u093e\u092e \u0926\u0932\u094b\u0902 \u0915\u0940 2019 \u092e\u0947\u0902 \u0939\u0941\u0908 \u0930\u0948\u0932\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u093e\u0932\u093f\u092f\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/west-bengal-assembly-poll-2021-fact-check-did-bbc-report-left-rally-in-kolkatas-brigade-as-worlds-largest-peaceful-political-gathering-12189",
        headline:
          "\u09ac\u09bf\u09ac\u09bf\u09b8\u09bf \u09ac\u09cd\u09b0\u09bf\u0997\u09c7\u09a1 \u09b8\u09ad\u09be\u0995\u09c7 \u09ac\u09b2\u09c7\u09a8\u09bf \u09aa\u09c3\u09a5\u09bf\u09ac\u09c0\u09b0 \u09ac\u09c3\u09b9\u09a4\u09cd\u09a4\u09ae \u09b6\u09be\u09a8\u09cd\u09a4\u09bf\u09aa\u09c2\u09b0\u09cd\u09a3 \u09b0\u09be\u099c\u09a8\u09c8\u09a4\u09bf\u0995 \u099c\u09ae\u09be\u09df\u09c7\u09a4",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-manoj-tiwari-bjp-fake-quote-parody-twitter-handle-petrol-fuel-price-hike-modi-government-factcheck-12184",
        headline:
          "\u09aa\u09c7\u099f\u09cd\u09b0\u09b2\u09c7\u09b0 \u09a6\u09be\u09ae \u09a8\u09bf\u09df\u09c7 \u09ac\u09cd\u09af\u09be\u0999\u09cd\u0997-\u0995\u09b0\u09be \u098f\u0995 \u099f\u09c1\u0987\u099f\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09ae\u09a8\u09cb\u099c \u09a4\u09bf\u0993\u09af\u09bc\u09be\u09b0\u09bf\u09b0 \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bbc-has-not-described-february-28-kolkata-rally-as-worlds-largest-peaceful-political-gathering-674999",
        headline:
          "Fact check: BBC has not described February 28 Kolkata rally as `world's largest peaceful political gathering'",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-actor-kangana-ranaut-did-not-plagiarize-smoothie-pictures-675058",
        headline:
          "Fact check: No, actor Kangana Ranaut did not plagiarize smoothie pictures",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/02/fact-check-old-picture-of-left-rally-linked-to-recent-cong-left-public-meeting-in-kolkata/",
        headline:
          "Fact Check: Old Picture Of Left Rally Linked To Recent Cong-Left Public Meeting In Kolkata",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/03/fact-check-was-this-vintage-bicycle-used-in-india-during-british-rule-heres-the-truth/",
        headline:
          "Fact Check: Was This Vintage Bicycle Used In India During British Rule? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/03/fact-check-no-bbc-did-not-term-recent-cong-left-public-meeting-as-worlds-largest-peaceful-political-gathering/",
        headline:
          "Fact Check: No! BBC Did Not Term Recent Cong-Left Public Meeting As \u2018World\u2019s Largest Peaceful Political Gathering\u2019",
      },
      {
        url:
          "https://factly.in/telugu-old-image-from-a-2019-left-front-rally-is-being-shared-as-a-recent-one-from-congress-left-meeting/",
        headline:
          " 2019 \u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c40 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d-\u0c32\u0c46\u0c2b\u0c4d\u0c1f\u0c4d \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40\u0c32\u0c41 \u0c15\u0c4b\u0c32\u0c4d\u200c\u0c15\u0c24\u0c3e\u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c38\u0c2d \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/an-edited-video-portrayed-as-the-visuals-of-british-boys-singing-the-ramayana-serial-song-at-the-uk-talent-show/",
        headline:
          " An Edited video is portrayed as the visuals of British boys singing Ramayana song at a UK talent show",
      },
      {
        url:
          "https://factly.in/telugu-morphed-photo-shared-as-kangana-ranaut-posting-an-image-from-google-as-her-dish/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c35\u0c3e\u0c21\u0c3f \u0c15\u0c02\u0c17\u0c28\u0c3e \u0c30\u0c28\u0c4c\u0c24\u0c4d \u0c17\u0c42\u0c17\u0c41\u0c32\u0c4d \u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c12\u0c15 \u0c30\u0c46\u0c38\u0c3f\u0c2a\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c24\u0c28\u0c41 \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c02\u0c1f\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-an-edited-video-is-portrayed-as-the-visuals-of-british-boys-singing-ramayana-song-at-a-uk-talent-show/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2c\u0c4d\u0c30\u0c3f\u0c1f\u0c28\u0c4d \u0c2f\u0c41\u0c35 \u0c17\u0c3e\u0c2f\u0c15\u0c41\u0c32\u0c41 \u0c30\u0c3e\u0c2e\u0c3e\u0c2f\u0c23\u0c02 \u0c2a\u0c3e\u0c1f \u0c2a\u0c3e\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/edited-photo-shared-to-portray-the-self-made-dish-of-kangana-ranaut-as-a-recipe-from-google/",
        headline:
          " Edited photo shared to portray the self-made dish of Kangana Ranaut as a recipe from Google",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/kangana-used-google-image-of-smoothie-no-screenshots-are-edited/",
        headline:
          "Kangana Used Google Image of Smoothie? No, Screenshots Are Edited",
      },
    ],
  },
  numpy_seed: 13,
  number_of_articles: 107,
}
