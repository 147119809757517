export const data = {
  mdsDat: {
    x: [
      -0.004118408942324011,
      0.12181886943753273,
      0.038205083673033796,
      -0.050557269935159264,
      -0.3860708362746148,
      0.2807225620415314,
    ],
    y: [
      0.1360922303177952,
      -0.23018367767684453,
      0.342235513464453,
      -0.20312302336812954,
      -0.02683760726156883,
      -0.018183435475705056,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      18.662179715904465,
      27.88572869004618,
      11.637569886148578,
      14.697239567790652,
      14.051984063374205,
      13.065298076735923,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      27.0,
      31.0,
      10.0,
      10.0,
      8.0,
      7.0,
      6.0,
      5.0,
      6.0,
      6.0,
      1.000073277716998,
      1.000073277716998,
      2.000146555433996,
      4.000293110867992,
      1.000073277716998,
      1.000073277716998,
      2.000146555433996,
      1.000073277716998,
      3.0002198331509944,
      1.000073277716998,
      8.000586221735984,
      6.000439666301989,
      6.000439666301989,
      5.0003663885849905,
      4.000293110867992,
      4.000293110867992,
      3.0002198331509944,
      3.0002198331509944,
      3.0002198331509944,
      3.0002198331509944,
      3.0002198331509944,
      4.000293110867992,
      3.0002198331509944,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      7.0010744825363975,
      3.000460492515599,
      5.000767487525998,
      6.000920985031198,
      6.000920985031198,
      5.000767487525998,
      5.000767487525998,
      4.000613990020799,
      4.000613990020799,
      4.000613990020799,
      4.000613990020799,
      25.003837437629993,
      28.00429793014559,
      4.000613990020799,
      6.000920985031198,
      6.000920985031198,
      3.0005168395135913,
      1.0001722798378636,
      1.0001722798378636,
      3.0005168395135913,
      2.000344559675727,
      1.0001722798378636,
      1.0001722798378636,
      1.0001722798378636,
      2.000344559675727,
      1.0001722798378636,
      4.000689119351454,
      4.000689119351454,
      4.000689119351454,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      5.000861399189319,
      4.000689119351454,
      1.9983858576503408,
      1.9983858576503408,
      0.9991929288251704,
      0.9991929288251704,
      1.9983858576503408,
      0.9991929288251704,
      0.9991929288251704,
      0.9991929288251704,
      0.9991929288251704,
      0.9991929288251704,
      3.9967717153006816,
      2.9975787864755112,
      2.9975787864755112,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      5.9951575729510225,
      1.9983858576503408,
      6.994350501776193,
      1.9983858576503408,
      2.9975787864755112,
      3.9967717153006816,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      2.000212106520922,
      1.000106053260461,
      1.000106053260461,
      1.000106053260461,
      4.000424213041844,
      1.000106053260461,
      3.0003181597813833,
      1.000106053260461,
      1.000106053260461,
      3.0003181597813833,
      10.001060532604612,
      4.000424213041844,
      4.000424213041844,
      3.0003181597813833,
      2.000212106520922,
      2.000212106520922,
      2.000212106520922,
      2.000212106520922,
      2.000212106520922,
      4.000424213041844,
      2.000212106520922,
      3.0003181597813833,
      3.0006268608167463,
      1.000208953605582,
      1.000208953605582,
      1.000208953605582,
      4.000835814422328,
      2.000417907211164,
      2.000417907211164,
      2.000417907211164,
      1.000208953605582,
      1.000208953605582,
      5.001044768027911,
      4.000835814422328,
      4.000835814422328,
      3.0006268608167463,
      3.0006268608167463,
      3.0006268608167463,
      3.0006268608167463,
      3.0006268608167463,
    ],
    Term: [
      "protest",
      "farmer",
      "train",
      "police",
      "minister",
      "modi",
      "bjp",
      "gift",
      "vaccine",
      "health",
      "verification",
      "blackout",
      "tweet",
      "thailand",
      "data",
      "omit",
      "support",
      "busy",
      "thailand_health",
      "border",
      "minister",
      "vaccine",
      "health",
      "covid",
      "law",
      "farm",
      "take_covid",
      "professor",
      "farm_law",
      "professor_carrying",
      "baby",
      "take",
      "carry",
      "photoshoppe",
      "attacking_bus",
      "cop",
      "tractor",
      "linked_ongoing",
      "agitation",
      "link",
      "placard",
      "flag",
      "sikhs",
      "nihang_sikh",
      "rihanna",
      "attack",
      "ongoing",
      "related_farmer",
      "linked_farmer",
      "rally",
      "protest",
      "farmer",
      "hold",
      "nihang",
      "bus",
      "feed",
      "congress_workers",
      "former",
      "cake_mia",
      "times",
      "article",
      "film",
      "associate",
      "nita",
      "rjd",
      "mia",
      "khalifa",
      "cake",
      "congress_worker",
      "ambani",
      "praise",
      "ratna",
      "pm",
      "award",
      "khel_ratna",
      "pm_modi",
      "khel",
      "modi",
      "congress",
      "write",
      "rss",
      "later",
      "cell",
      "report",
      "communal",
      "subhash",
      "decision",
      "movement",
      "gita",
      "jai",
      "member",
      "policeman",
      "join",
      "hazare",
      "tableau",
      "officer",
      "jawan",
      "see",
      "poll",
      "bjp",
      "anna",
      "police",
      "shout",
      "government",
      "delhi",
      "activist",
      "war",
      "brutality",
      "take",
      "electric",
      "bike",
      "simulation",
      "diesel",
      "station",
      "lapse",
      "borivali_station",
      "olympic",
      "kmph",
      "hour",
      "train",
      "japan",
      "mumbai",
      "borivali",
      "run",
      "fly",
      "resume",
      "speed",
      "rush",
      "temple",
      "price",
      "local",
      "tata",
      "bmtc",
      "datum",
      "growth",
      "day",
      "hotel",
      "thermal",
      "come",
      "karnataka",
      "detain",
      "gift",
      "valentine_day",
      "valentine",
      "aung_san",
      "kyi",
      "test",
      "san",
      "suu_kyi",
    ],
    Total: [
      27.0,
      31.0,
      10.0,
      10.0,
      8.0,
      7.0,
      6.0,
      5.0,
      6.0,
      6.0,
      1.000073277716998,
      1.000073277716998,
      2.000146555433996,
      4.000293110867992,
      1.000073277716998,
      1.000073277716998,
      2.000146555433996,
      1.000073277716998,
      3.0002198331509944,
      1.000073277716998,
      8.000586221735984,
      6.000439666301989,
      6.000439666301989,
      5.0003663885849905,
      4.000293110867992,
      4.000293110867992,
      3.0002198331509944,
      3.0002198331509944,
      3.0002198331509944,
      3.0002198331509944,
      3.0002198331509944,
      6.998785021778794,
      3.0002198331509944,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      3.000460492515599,
      7.0010744825363975,
      3.000460492515599,
      5.000767487525998,
      6.000920985031198,
      6.000920985031198,
      5.000767487525998,
      5.000767487525998,
      4.000613990020799,
      4.000613990020799,
      4.000613990020799,
      4.000613990020799,
      27.00425534484116,
      31.003908766181926,
      4.000613990020799,
      7.000113913856368,
      7.00112993863678,
      3.0005168395135913,
      1.0001722798378636,
      1.0001722798378636,
      3.0005168395135913,
      2.000344559675727,
      1.0001722798378636,
      1.0001722798378636,
      1.0001722798378636,
      2.000344559675727,
      1.0001722798378636,
      4.000689119351454,
      4.000689119351454,
      4.000689119351454,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      3.0005168395135913,
      7.001007954623315,
      6.999228474506995,
      1.9983858576503408,
      1.9983858576503408,
      0.9991929288251704,
      0.9991929288251704,
      1.9983858576503408,
      0.9991929288251704,
      0.9991929288251704,
      0.9991929288251704,
      0.9991929288251704,
      0.9991929288251704,
      3.9967717153006816,
      2.9975787864755112,
      2.9975787864755112,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      6.995366526556604,
      1.9983858576503408,
      10.994964491796992,
      1.9983858576503408,
      4.997838337241172,
      9.99755104308814,
      1.9983858576503408,
      1.9983858576503408,
      1.9983858576503408,
      6.998785021778794,
      2.000212106520922,
      1.000106053260461,
      1.000106053260461,
      1.000106053260461,
      4.000424213041844,
      1.000106053260461,
      3.0003181597813833,
      1.000106053260461,
      1.000106053260461,
      3.0003181597813833,
      10.001060532604612,
      4.000424213041844,
      4.000424213041844,
      3.0003181597813833,
      2.000212106520922,
      2.000212106520922,
      2.000212106520922,
      2.000212106520922,
      2.000212106520922,
      4.999617141867015,
      2.000212106520922,
      3.9995110886065537,
      3.0006268608167463,
      1.000208953605582,
      1.000208953605582,
      1.000208953605582,
      4.000835814422328,
      2.000417907211164,
      2.000417907211164,
      2.000417907211164,
      1.000208953605582,
      1.000208953605582,
      5.001044768027911,
      4.000835814422328,
      4.000835814422328,
      3.0006268608167463,
      3.0006268608167463,
      3.0006268608167463,
      3.0006268608167463,
      3.0006268608167463,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.6787,
      1.1193,
      1.6787,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2771,
      1.2001,
      1.1753,
      1.2771,
      1.123,
      1.1229,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      2.1509,
      1.8145,
      1.5916,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.9175,
      1.7632,
      1.9175,
      1.4652,
      1.9175,
      1.4063,
      1.0007,
      1.9175,
      1.9175,
      1.9175,
      0.6641,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.9624,
      1.7394,
      1.9624,
      1.675,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
      2.0352,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.1358,
      -5.1358,
      -4.4427,
      -3.7495,
      -5.1358,
      -5.1358,
      -4.4427,
      -5.1358,
      -4.0372,
      -5.1358,
      -3.0564,
      -3.344,
      -3.344,
      -3.5264,
      -3.7495,
      -3.7495,
      -4.0372,
      -4.0372,
      -4.0372,
      -4.0372,
      -4.0372,
      -3.7495,
      -4.0372,
      -4.4387,
      -4.4387,
      -4.4387,
      -4.4387,
      -4.4387,
      -4.4387,
      -3.5914,
      -4.4387,
      -3.9279,
      -3.7456,
      -3.7456,
      -3.9279,
      -3.9279,
      -4.151,
      -4.151,
      -4.151,
      -4.151,
      -2.3185,
      -2.2051,
      -4.151,
      -3.7456,
      -3.7456,
      -3.5648,
      -4.6634,
      -4.6634,
      -3.5648,
      -3.9703,
      -4.6634,
      -4.6634,
      -4.6634,
      -3.9703,
      -4.6634,
      -3.2771,
      -3.2771,
      -3.2771,
      -3.5648,
      -3.5648,
      -3.5648,
      -3.5648,
      -3.5648,
      -3.5648,
      -3.5648,
      -3.5648,
      -3.5648,
      -3.054,
      -3.2771,
      -4.2047,
      -4.2047,
      -4.8978,
      -4.8978,
      -4.2047,
      -4.8978,
      -4.8978,
      -4.8978,
      -4.8978,
      -4.8978,
      -3.5115,
      -3.7992,
      -3.7992,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.2047,
      -3.1061,
      -4.2047,
      -2.9519,
      -4.2047,
      -3.7992,
      -3.5115,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.1589,
      -4.852,
      -4.852,
      -4.852,
      -3.4657,
      -4.852,
      -3.7534,
      -4.852,
      -4.852,
      -3.7534,
      -2.5494,
      -3.4657,
      -3.4657,
      -3.7534,
      -4.1589,
      -4.1589,
      -4.1589,
      -4.1589,
      -4.1589,
      -3.4657,
      -4.1589,
      -3.7534,
      -3.6805,
      -4.7791,
      -4.7791,
      -4.7791,
      -3.3928,
      -4.086,
      -4.086,
      -4.086,
      -4.7791,
      -4.7791,
      -3.1697,
      -3.3928,
      -3.3928,
      -3.6805,
      -3.6805,
      -3.6805,
      -3.6805,
      -3.6805,
    ],
  },
  "token.table": {
    Topic: [
      4,
      2,
      3,
      4,
      3,
      3,
      2,
      2,
      6,
      3,
      1,
      5,
      4,
      6,
      1,
      6,
      1,
      5,
      5,
      4,
      2,
      6,
      1,
      3,
      3,
      1,
      4,
      6,
      4,
      2,
      3,
      4,
      3,
      3,
      2,
      1,
      1,
      6,
      6,
      4,
      1,
      2,
      3,
      4,
      6,
      5,
      5,
      1,
      1,
      2,
      4,
      6,
      3,
      3,
      2,
      5,
      3,
      6,
      4,
      2,
      4,
      5,
      6,
      4,
      1,
      2,
      6,
      5,
      4,
      5,
      4,
      4,
      6,
      3,
      3,
      3,
      5,
      6,
      5,
      4,
      1,
      2,
      2,
      2,
      4,
      5,
      4,
      3,
      1,
      1,
      3,
      4,
      5,
      2,
      4,
      2,
      3,
      4,
      5,
      1,
      2,
      2,
      2,
      3,
      3,
      2,
      4,
      4,
      4,
      3,
      5,
      1,
      1,
      2,
      6,
      2,
      3,
      2,
      4,
      5,
      2,
      3,
      4,
      5,
      5,
      6,
      4,
      4,
      2,
      5,
      5,
      5,
      4,
      1,
      6,
      4,
      1,
      4,
      5,
      1,
      6,
      4,
      5,
      6,
      1,
      1,
      6,
      3,
      2,
      5,
      1,
      1,
      6,
      6,
      1,
      4,
      4,
    ],
    Freq: [
      1.0008077230648325,
      0.9998465260526684,
      0.9998277498373663,
      1.0008077230648325,
      0.9998277498373664,
      0.9998277498373664,
      0.9998465260526684,
      0.9998465260526684,
      0.9997910900469058,
      0.9998277498373663,
      0.9999267276522322,
      0.9998939579856403,
      0.8577105970390714,
      0.14295176617317856,
      0.9999267276522323,
      0.9997910900469059,
      0.9999267276522323,
      0.9998939579856403,
      0.9998939579856403,
      1.0008077230648325,
      0.8570045196401949,
      0.14283408660669913,
      0.9999267276522323,
      0.9998277498373664,
      0.9998277498373663,
      0.9999267276522322,
      1.0008077230648325,
      0.9997910900469059,
      1.0008077230648325,
      0.1428728900109861,
      0.5714915600439444,
      0.2857457800219722,
      0.9998277498373663,
      0.9998277498373664,
      0.9998465260526684,
      0.9999267276522322,
      0.9999267276522323,
      0.9997910900469059,
      0.9997910900469059,
      1.0008077230648325,
      0.20004899113595528,
      0.3000734867039329,
      0.10002449556797764,
      0.40009798227191057,
      0.9997910900469059,
      0.9998939579856403,
      0.9998939579856403,
      0.9999267276522323,
      0.9999267276522322,
      0.9031119337617683,
      0.03225399763434887,
      0.06450799526869774,
      0.9998277498373663,
      0.9998277498373664,
      0.9998465260526684,
      0.9998939579856403,
      0.9998277498373664,
      0.9997910900469058,
      1.0008077230648325,
      0.20008650390880878,
      0.6002595117264263,
      0.20008650390880878,
      0.9997910900469059,
      1.0008077230648325,
      0.9999267276522322,
      0.9998465260526682,
      0.9997910900469059,
      0.9998939579856403,
      1.0008077230648325,
      0.9998939579856403,
      1.0008077230648325,
      1.0008077230648325,
      0.9997910900469059,
      0.9998277498373664,
      0.9998277498373663,
      0.9998277498373663,
      0.9998939579856403,
      0.9997910900469058,
      0.9998939579856403,
      1.0008077230648325,
      0.9999267276522323,
      0.9998465260526683,
      0.9998465260526682,
      0.9998465260526684,
      0.2500305606974587,
      0.7500916820923761,
      1.0008077230648325,
      0.9998277498373664,
      0.9999267276522323,
      0.2856731506324376,
      0.7141828765810939,
      1.0008077230648325,
      0.9998939579856403,
      0.8571289087343716,
      0.14285481812239528,
      0.9998465260526684,
      0.9998277498373664,
      1.0008077230648325,
      0.9998939579856403,
      0.9999267276522323,
      0.9998465260526682,
      0.9998465260526684,
      0.9998465260526684,
      0.9998277498373663,
      0.9998277498373663,
      0.363802902954737,
      0.6366550801707898,
      1.0008077230648325,
      1.0008077230648325,
      0.9998277498373663,
      0.9998939579856403,
      0.9999267276522322,
      0.9999267276522322,
      0.9257800180287494,
      0.07406240144229995,
      0.9998465260526682,
      0.9998277498373663,
      0.9998465260526682,
      1.0008077230648325,
      0.9998939579856403,
      0.9998465260526684,
      0.9998277498373664,
      1.0008077230648325,
      0.9998939579856403,
      0.9998939579856403,
      0.9997910900469058,
      1.0008077230648325,
      1.0008077230648325,
      0.9998465260526684,
      0.9998939579856403,
      0.9998939579856403,
      0.9998939579856403,
      1.0008077230648325,
      0.9999267276522323,
      0.9997910900469058,
      1.0008077230648325,
      0.5715277705420033,
      0.28576388527100166,
      0.14288194263550083,
      0.9999267276522322,
      0.9997910900469058,
      0.20001531549805202,
      0.8000612619922081,
      0.9997910900469058,
      0.9999267276522323,
      0.9999267276522322,
      0.9997910900469059,
      0.9998277498373664,
      0.9998465260526684,
      0.9998939579856402,
      0.9999267276522323,
      0.9999267276522322,
      0.9997910900469059,
      0.9997910900469059,
      0.9999267276522323,
      1.0008077230648325,
      1.0008077230648325,
    ],
    Term: [
      "activist",
      "agitation",
      "ambani",
      "anna",
      "article",
      "associate",
      "attack",
      "attacking_bus",
      "aung_san",
      "award",
      "baby",
      "bike",
      "bjp",
      "bjp",
      "blackout",
      "bmtc",
      "border",
      "borivali",
      "borivali_station",
      "brutality",
      "bus",
      "bus",
      "busy",
      "cake",
      "cake_mia",
      "carry",
      "cell",
      "come",
      "communal",
      "congress",
      "congress",
      "congress",
      "congress_worker",
      "congress_workers",
      "cop",
      "covid",
      "data",
      "datum",
      "day",
      "decision",
      "delhi",
      "delhi",
      "delhi",
      "delhi",
      "detain",
      "diesel",
      "electric",
      "farm",
      "farm_law",
      "farmer",
      "farmer",
      "farmer",
      "feed",
      "film",
      "flag",
      "fly",
      "former",
      "gift",
      "gita",
      "government",
      "government",
      "government",
      "growth",
      "hazare",
      "health",
      "hold",
      "hotel",
      "hour",
      "jai",
      "japan",
      "jawan",
      "join",
      "karnataka",
      "khalifa",
      "khel",
      "khel_ratna",
      "kmph",
      "kyi",
      "lapse",
      "later",
      "law",
      "link",
      "linked_farmer",
      "linked_ongoing",
      "local",
      "local",
      "member",
      "mia",
      "minister",
      "modi",
      "modi",
      "movement",
      "mumbai",
      "nihang",
      "nihang",
      "nihang_sikh",
      "nita",
      "officer",
      "olympic",
      "omit",
      "ongoing",
      "photoshoppe",
      "placard",
      "pm",
      "pm_modi",
      "police",
      "police",
      "policeman",
      "poll",
      "praise",
      "price",
      "professor",
      "professor_carrying",
      "protest",
      "protest",
      "rally",
      "ratna",
      "related_farmer",
      "report",
      "resume",
      "rihanna",
      "rjd",
      "rss",
      "run",
      "rush",
      "san",
      "see",
      "shout",
      "sikhs",
      "simulation",
      "speed",
      "station",
      "subhash",
      "support",
      "suu_kyi",
      "tableau",
      "take",
      "take",
      "take",
      "take_covid",
      "tata",
      "temple",
      "temple",
      "test",
      "thailand",
      "thailand_health",
      "thermal",
      "times",
      "tractor",
      "train",
      "tweet",
      "vaccine",
      "valentine",
      "valentine_day",
      "verification",
      "war",
      "write",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-thailand-health-minister-anutin-charnvirakul-scared-of-taking-injection-vaccine-covid19-viral-video-11802",
        headline:
          "Thai Health Minister Scared To Take COVID-19 Vaccine? Not Really",
      },
      {
        url:
          "https://factly.in/telugu-edited-video-shared-as-kejriwal-support-to-the-new-agricultural-laws/",
        headline:
          " \u0c15\u0c47\u0c1c\u0c4d\u0c30\u0c40\u0c35\u0c3e\u0c32\u0c4d \u0c15\u0c4a\u0c24\u0c4d\u0c24 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c3e\u0c2f \u0c1a\u0c1f\u0c4d\u0c1f\u0c3e\u0c32\u0c28\u0c41 \u0c38\u0c2e\u0c30\u0c4d\u0c26\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c21\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c42 \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-thailand-health-minister-anutin-charnvirakul-scared-of-taking-injection-vaccine-covid19-viral-video-11855",
        headline:
          "Thailand \u098f\u09b0 \u09b8\u09cd\u09ac\u09be\u09b8\u09cd\u09a5\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 COVID-19 Vaccine \u09a8\u09bf\u09a4\u09c7 \u09ad\u09df \u09aa\u09be\u099a\u09cd\u099b\u09c7\u09a8?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/narendra-modi-priti-adani-gautam-adani-social-worker-viral-factcheck-farmers-protest-11862",
        headline:
          "\u0986\u09a6\u09be\u09a8\u09bf\u09b0 \u09b8\u09cd\u09a4\u09cd\u09b0\u09c0\u09b0 \u09b8\u09be\u09ae\u09a8\u09c7 \u09ae\u09be\u09a5\u09be \u09a8\u09a4 \u0995\u09b0\u09b2\u09c7\u09a8 \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/ani-usa-support-farm-laws-joe-biden-us-state-department-11843",
        headline:
          "ANI Omits US Govt Reference To Internet Blackout In Story On Farm Laws",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/aerobics-teacher-danced-through-myanmar-coup-11827",
        headline:
          "Yes, Myanmar Aerobics Teacher Did In Fact Dance Through The Coup",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-thailand-health-minister-anutin-charnvirakul-scared-of-taking-injection-vaccine-covid19-viral-video-11802",
        headline:
          "Thai Health Minister Scared To Take COVID-19 Vaccine? Not Really",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/aerobics-teacher-danced-through-myanmar-militarty-coup-11863",
        headline:
          "\u09b9\u09cd\u09af\u09be\u0981, Myanmar-\u098f \u09b8\u09c7\u09a8\u09be \u0985\u09ad\u09cd\u09af\u09c1\u09a4\u09cd\u09a5\u09be\u09a8\u09c7\u09b0 \u09b8\u09ae\u09df\u09c7 \u09a8\u09be\u099a\u099b\u09bf\u09b2\u09c7\u09a8 \u098f\u0987 \u098f\u09b0\u09cb\u09ac\u09bf\u0995\u09b8 \u09aa\u09cd\u09b0\u09b6\u09bf\u0995\u09cd\u09b7\u0995",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-viral-edited-image-narendra-modi-putting-nails-delhi-border-farmers-protest-11832",
        headline:
          "\u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u099b\u09ac\u09bf\u09b0 \u09a6\u09be\u09ac\u09bf \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0 \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf \u09b8\u09bf\u09ae\u09be\u09a8\u09cd\u09a4\u09c7 \u09aa\u09c7\u09b0\u09c7\u0995 \u09aa\u09c1\u0981\u09a4\u09b2\u09c7\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-sambit-patra-edited-clip-arvind-kejriwal-farm-bills-farmers-protests-11812",
        headline:
          "Farm Bill \u098f\u09b0 \u09aa\u0995\u09cd\u09b7\u09c7 Kejriwal, \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u0995\u09cd\u09b2\u09bf\u09aa \u099b\u09a1\u09bc\u09be\u09b2\u09c7\u09a8 Sambit Patra",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/02/fact-check-viral-image-of-old-patient-lying-on-straw-bed-is-not-from-india-heres-the-truth/",
        headline:
          "Fact Check: Viral Image Of Old Patient Lying On Straw Bed Is Not From India; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/02/fact-check-no-this-video-is-not-of-thailands-health-minister-being-vaccinated-against-covid-19/",
        headline:
          "Fact Check: No, This Video Is NOT Of Thailand\u2019s Health Minister Being Vaccinated Against COVID-19",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/02/fact-check-did-delhi-cm-arvind-kejriwal-support-farm-laws-heres-the-truth/",
        headline:
          "Fact Check: Did Delhi CM Arvind Kejriwal Support Farm Laws? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/04/fact-check-no-maharashtra-is-not-planning-to-ban-evms-heres-the-truth/",
        headline:
          "Fact Check: No, Maharashtra Is Not Planning To Ban EVMs; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/07/fact-check-this-notice-stating-govt-is-busy-responding-to-a-tweet-is-fake/",
        headline:
          "Fact Check: This Notice Stating \u2018Govt Is Busy Responding To A Tweet\u2019 Is Fake",
      },
      {
        url:
          "https://factly.in/123-countries-mentioned-in-the-post-is-about-the-reach-of-bharath-biotechs-all-vaccines/",
        headline:
          " \u2018123 countries\u2019 mentioned in the post is about the reach of all of Bharath Biotech\u2019s vaccines",
      },
      {
        url:
          "https://factly.in/old-video-of-a-chinese-man-portrayed-as-a-scared-thailand-health-minister-while-taking-covid-19-vaccine/",
        headline:
          " Old video of a Chinese man portrayed as a scared Thailand Health Minister while taking COVID-19 vaccine",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-a-chinese-man-portrayed-as-a-scared-thailand-health-minister-while-taking-covid-19-vaccine/",
        headline:
          " \u0c1a\u0c48\u0c28\u0c40\u0c38\u0c4d \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f, \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d \u0c15\u0c3f \u0c2d\u0c2f\u0c2a\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c25\u0c3e\u0c2f\u0c3f\u0c32\u0c3e\u0c02\u0c21\u0c4d \u0c06\u0c30\u0c4b\u0c17\u0c4d\u0c2f \u0c2e\u0c02\u0c24\u0c4d\u0c30\u0c3f \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/a-mexican-professor-carrying-his-students-baby-shared-with-a-false-narrative/",
        headline:
          " A Mexican professor carrying his student\u2019s baby is shared with a false narrative",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-a-widowed-professor-carrying-his-baby-to-class-1765933-2021-02-04",
        headline:
          "Fact Check: This is not a widowed professor carrying his baby to class",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/viral-tweet-on-hindu-mukt-india-by-bollywood-singer-is-fake-1766680-2021-02-06",
        headline:
          "Fact Check: This viral tweet on \u2018Hindu Mukt India\u2019 by Bollywood singer is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-professor-carrying-baby-in-viral-image-is-his-students-child-not-his-own-673725",
        headline:
          "Fact check: Professor carrying baby in viral image is his student's child, not his own",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-adani-group-did-not-enter-agribusiness-after-farm-laws-were-passed-673591",
        headline:
          "Fact check: No, Adani group did not enter agribusiness after farm laws were passed",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/telugu-this-placard-against-the-ayodhya-rama-mandir-was-not-displayed-by-protesting-farmers-in-delhi/",
        headline:
          " \u0c05\u0c2f\u0c4b\u0c27\u0c4d\u0c2f \u0c30\u0c3e\u0c2e \u0c2e\u0c02\u0c26\u0c3f\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c08 \u0c2a\u0c4d\u0c32\u0c15\u0c3e\u0c30\u0c4d\u0c21\u0c4d \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40\u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c38\u0c28 \u0c24\u0c46\u0c32\u0c41\u0c2a\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c2a\u0c4d\u0c30\u0c26\u0c30\u0c4d\u0c36\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-police-in-this-video-are-not-saying-that-they-will-not-do-lathi-charge-on-farmers/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4d\u0c32\u0c4b \u0c30\u0c48\u0c24\u0c41\u0c32\u0c2a\u0c48 \u0c32\u0c3e\u0c20\u0c40 \u0c1b\u0c3e\u0c30\u0c4d\u0c1c\u0c4d \u0c1a\u0c47\u0c2f\u0c2e\u0c28\u0c3f \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c1f\u0c4d\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-rihanna-united-states-holding-pakistan-flag-factcheck-farmers-protests-support-11836",
        headline:
          "Farmers' Protests: Photoshopped Photo Of Rihanna With Pak Flag Goes Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/unrelated-images-peddled-as-farmer-injured-after-clash-with-police-11844",
        headline:
          "Unrelated Images Peddled As Farmer Injured After Clash With Police",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/farmers-protests-delhi-police-crackdown-tractor-parade-rally-red-fort-fake-news-fact-check-11826",
        headline:
          "Farmers' Protest: Photo Of Plainclothes Cop From 2019 Viral With Fake Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-viral-clip-alcohol-distributed-farmers-protest-chakka-jam-delhi-old-video-factcheck-11868",
        headline:
          "Video From 2020 Shared As Alcohol Distributed At Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-farmers-protest-bulluck-cart-rally-photo-old-photo-madhya-pradesh-youth-congress-protest-factcheck-11864",
        headline: "Photo From 2018 Fuel Hike Protest Viral As Farmers' March",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/nihang-sikhs-bus-vandalised-farmers-protest-khalistan-fake-news-fact-check-11856",
        headline:
          "Old Video Of Nihang Sikhs Attacking Bus Revived With Misleading Claims",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/nihang-sikhs-attack-bus-kisan-andolan-punjab-kapurthala-delhi-fake-news-11860",
        headline:
          "\u0928\u093f\u0939\u0902\u0917 \u0938\u093f\u0916\u094b\u0902 \u0926\u094d\u0935\u093e\u0930\u093e \u092c\u0938 \u092a\u0930 \u0939\u092e\u0932\u0947 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0905\u092c \u092d\u094d\u0930\u093e\u092e\u0915 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rihanna-pakistan-flag-fake-tweet-united-states-delhi-kisan-andolan-11840",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928: \u0930\u093f\u0939\u093e\u0928\u093e \u0915\u0940 \u090f\u0921\u093f\u091f\u0947\u0921 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/kisan-andolan-congress-nsui-balaghat-madhya-pradesh-petrol-diesel-price-fake-news-11847",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928: \u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0930\u0948\u0932\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/old-unrelated-image-of-waving-indian-tricolour-on-boats-shared-as-campaign-in-support-of-farmers-protest-in-kerala-11820",
        headline:
          "\u09a8\u09cc\u0995\u09be\u09ac\u0995\u09cd\u09b7\u09c7 \u09aa\u09a4\u09be\u0995\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09c7\u09b0\u09b2\u09c7 Farmers Protest \u09a8\u09bf\u09df\u09c7 \u09aa\u09cd\u09b0\u099a\u09be\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-delhi-police-crying-lathicharge-farmers-protest-delhi-viral-video-tractor-rally-republic-day-11813",
        headline:
          "Fact Check: \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u0989\u09aa\u09b0 \u09b2\u09be\u09a0\u09bf\u099a\u09be\u09b0\u09cd\u099c \u09b9\u0993\u09af\u09bc\u09be\u09af\u09bc Delhi Police \u0995\u09b0\u09cd\u09ae\u09c0 \u0995\u09be\u0981\u09a6\u099b\u09c7\u09a8?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/morphed-clips-unrelated-images-revived-to-spread-child-kidnapping-rumours-11814",
        headline:
          "\u099b\u09c7\u09b2\u09c7\u09a7\u09b0\u09be\u09b0 \u0997\u09c1\u099c\u09ac: \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09a8\u09be \u0995\u09b0\u09be \u0995\u09cd\u09b2\u09bf\u09aa, \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/photoshopped-image-of-suvendu-adhikari-holding-a-placard-in-baruipur-meeting-viral-with-false-claim-11842",
        headline:
          "\u09aa\u09cd\u09b2\u09cd\u09af\u09be\u0995\u09be\u09b0\u09cd\u09a1 \u09b9\u09be\u09a4\u09c7 Suvendu Adhikari \u098f\u09b0 \u098f\u0987 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u09ab\u09cb\u099f\u09cb\u09b6\u09aa \u0995\u09b0\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-rihanna-united-states-holding-pakistan-flag-factcheck-farmers-protests-support-11849",
        headline:
          "\u09ab\u09cb\u099f\u09cb\u09b6\u09aa \u0995\u09b0\u09be Pakistan-\u098f\u09b0 \u09aa\u09a4\u09be\u0995\u09be \u09b9\u09be\u09a4\u09c7 Rihanna \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/02/fact-check-no-200-delhi-cops-havent-resigned-amid-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: No, 200 Delhi Cops Haven\u2019t Resigned Amid Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/03/fact-check-this-picture-of-a-cop-holding-stones-is-not-related-to-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: This Picture Of A Cop Holding Stones Is Not Related To Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/04/fact-check-old-picture-of-bullock-cart-rally-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Bullock Cart Rally Falsely Linked To Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/04/fact-check-photoshopped-image-of-rihanna-with-pakistani-flag-goes-viral/",
        headline:
          "Fact Check: Photoshopped Image Of Rihanna With Pakistani Flag Goes Viral",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/05/fact-check-old-image-of-2019-caa-protest-resurfaces-amid-farmers-agitation/",
        headline:
          "Fact Check: Old Image Of 2019 CAA Protest Resurfaces Amid Farmers\u2019 Agitation",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/05/fact-check-these-steel-wheeled-tractors-are-not-made-to-overcome-nails-spikes-near-protesting-sites/",
        headline:
          "Fact Check: These Steel-Wheeled Tractors Are Not Made To Overcome Nails, Spikes Near Protesting Sites",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/06/fact-check-old-video-of-nihang-sikhs-attacking-a-bus-falsely-linked-to-farmers-protest/",
        headline:
          "Fact Check: Old Video Of Nihang Sikhs Attacking A Bus Falsely Linked To Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/07/fact-check-old-video-of-liquor-being-distributed-shared-as-recent-one-from-farmers-protest/",
        headline:
          "Fact Check: Old Video Of Liquor Being Distributed Shared As Recent One From Farmers\u2019 Protest",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-is-related-to-a-traffic-jam-in-china-it-has-nothing-to-do-with-germany/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c1a\u0c48\u0c28\u0c3e\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c1f\u0c4d\u0c30\u0c3e\u0c2b\u0c3f\u0c15\u0c4d \u0c1c\u0c3e\u0c2e\u0c4d \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f; \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c15\u0c3f \u0c1c\u0c30\u0c4d\u0c2e\u0c28\u0c40\u0c15\u0c3f \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/accident-video-from-amritsar-portrayed-as-the-up-government-attack-on-protesting-farmers/",
        headline:
          " Accident video from Amritsar portrayed as UP Government attack on protesting farmers",
      },
      {
        url:
          "https://factly.in/unrelated-old-photos-and-videos-are-falsely-linked-to-the-ongoing-farmers-protest/",
        headline:
          " Unrelated old Photos and Videos are falsely linked to the ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-the-one-participating-in-farmers-protest-is-not-amulya-leona/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c30\u0c48\u0c24\u0c41 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c4d\u0c32\u0c4b \u0c2a\u0c3e\u0c32\u0c4d\u0c17\u0c4a\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u2018\u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c1c\u0c3f\u0c02\u0c26\u0c3e\u0c2c\u0c3e\u0c26\u0c4d\u2019 \u0c05\u0c28\u0c3f \u0c05\u0c28\u0c4d\u0c28 \u0c05\u0c2e\u0c42\u0c32\u0c4d\u0c2f \u0c32\u0c3f\u0c2f\u0c4b\u0c28\u0c3e \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/old-video-of-nihang-sikh-men-attacking-prtc-bus-linked-to-the-ongoing-farmers-protests__trashed/",
        headline:
          " Old video of Nihang Sikhs attacking PRTC bus is falsely linked to the ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/video-of-nihang-sikhs-attacking-public-bus-is-not-related-to-farmers-protest-1766429-2021-02-05",
        headline:
          "Fact Check: Video of Nihang Sikhs attacking bus is not related to farmers\u2019 protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-image-of-men-in-police-gear-and-civil-clothes-is-not-linked-to-farmers-stir-1764978-2021-02-02",
        headline:
          "Fact Check: This image of men in police gear and civil clothes is not linked to farmers\u2019 stir",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-these-antique-steel-wheeled-tractors-have-no-connection-with-farmers-protests-1765672-2021-02-03",
        headline:
          "Fact Check: These antique steel-wheeled tractors have no connection with farmers\u2019 protests",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-video-of-liquor-distribution-is-not-from-farmers-protest-1766842-2021-02-07",
        headline:
          "Fact Check: This video of liquor distribution is not from farmers\u2019 protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-dhoni-driving-a-tractor-is-not-related-to-farmers-protest-673721",
        headline:
          "Fact Check: Viral image of Dhoni driving a tractor is not related to farmers protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-of-unruly-drunk-mob-falsely-linked-to-farmers-protest-673854",
        headline:
          "Fact Check: Old video of unruly drunk mob falsely linked to farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-image-of-bullock-cart-rally-passed-off-as-ongoing-farmers-protest-673795",
        headline:
          "Fact check: Old image of bullock cart rally passed off as ongoing farmers protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-image-of-man-holding-placard-about-ram-mandir-is-not-from-india-673602",
        headline:
          "Fact Check: Image of man holding placard about Ram Mandir is not from India",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-picture-of-rihanna-holding-pakistani-flag-is-morphed-673723",
        headline:
          "FACT CHECK: Picture of Rihanna holding Pakistani flag is morphed",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-nihang-sikhs-vandalizing-bus-not-related-to-farmers-protest-673812",
        headline:
          "Fact Check: Video of Nihang Sikhs vandalizing bus not related to farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2019-video-of-pro-caa-rally-falsely-linked-to-farmers-protest-673647",
        headline:
          "Fact Check: 2019 video of pro-CAA rally falsely linked to farmers' protest",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/mia-khalifa-kisan-andolan-rahul-gandhi-congress-twitter-fake-news-11870",
        headline: "Congress Workers Feeding Cake To Mia Khalifa? Not Quite",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-nita-ambani-khel-ratna-puraskar-reliance-foundation-president-ramnath-kovind-11810",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0915\u093e \u0926\u093e\u0935\u093e, \u0928\u0940\u0924\u093e \u0905\u0902\u092c\u093e\u0928\u0940 \u0915\u094b \u0926\u093f\u092f\u093e \u0917\u092f\u093e \u0916\u0947\u0932 \u0930\u0924\u094d\u0928 \u092a\u0941\u0930\u0938\u094d\u0915\u093e\u0930",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/indian-youth-congress-rahul-gandhi-mia-khalifa-morphed-picture-viral-kisan-andolan-fake-news-11871",
        headline:
          "\u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0935\u0930\u094d\u0915\u0930\u094d\u0938 \u0928\u0947 \u092e\u093f\u092f\u093e \u0959\u0932\u0940\u095e\u093e \u0915\u094b \u0916\u093f\u0932\u093e\u092f\u093e \u0915\u0947\u0915? \u0928\u0939\u0940\u0902, \u095e\u094b\u091f\u094b \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/jats-delhi-narendra-modi-farmers-protest-haryana-tractor-rally-parade-fact-check-11845",
        headline:
          "\u0915\u094d\u092f\u093e '\u0926\u093f\u0932\u094d\u0932\u0940 \u0915\u0947 \u091c\u093e\u091f \u0915\u093f\u0938\u093e\u0928\u094b\u0902' \u0928\u0947 \u092a\u0940.\u090f\u092e. \u092e\u094b\u0926\u0940 \u0915\u093e \u0917\u0941\u0923\u0917\u093e\u0928 \u0915\u093f\u092f\u093e? \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u0941\u0930\u093e\u0928\u093e \u0939\u0948",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/03/fact-check-old-picture-of-pm-modi-with-netajis-associate-shared-as-recent/",
        headline:
          "Fact Check: Old Picture Of PM Modi With Netaji\u2019s Associate Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/04/fact-check-has-nita-ambani-been-conferred-with-khel-ratna-award-heres-the-truth/",
        headline:
          "Fact Check: Has Nita Ambani Been Conferred With Khel Ratna Award? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/05/fact-check-old-video-of-people-singing-praises-for-pm-modi-shared-as-recent/",
        headline:
          "Fact Check: Old Video Of People Singing Praises For PM Modi Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/06/fact-check-posts-regarding-rjd-chief-lalu-prasad-yadavs-demise-are-fake/",
        headline:
          "Fact Check: Posts Regarding RJD Chief Lalu Prasad Yadav\u2019s Demise Are Fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/07/fact-check-did-congress-workers-feed-cake-to-a-poster-of-former-adult-film-star-mia-khalifa-heres-the-truth/",
        headline:
          "Fact Check: Did Congress Workers Feed Cake To A Poster Of Former Adult Film Star Mia Khalifa? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/this-purported-message-of-the-new-york-times-editor-in-chief-praising-narendra-modi-is-fake/",
        headline:
          " This purported message of New York Times Editor-in-Chief praising Narendra Modi is fake",
      },
      {
        url:
          "https://factly.in/neeta-ambani-is-not-conferred-with-khel-ratna-award/",
        headline: " Neeta Ambani is not conferred with the Khel Ratna award",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-article-praising-pm-modi-was-not-published-by-the-new-york-times-1765308-2021-02-03",
        headline:
          "Fact Check: This article praising PM Modi was not published by \u2018The New York Times\u2019\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/morphed-photo-congress-workers-offering-cake-mia-khalifa-poster-1766686-2021-02-06",
        headline:
          "Fact Check: Morphed photo shows Congress workers offering cake to Mia Khalifa's poster",
      },
    ],
    "4": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/delhi-police-kisan-andolan-jai-jawan-jai-kisan-video-fake-claims-11801",
        headline:
          "\u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0906 '\u091c\u092f \u091c\u0935\u093e\u0928, \u091c\u092f \u0915\u093f\u0938\u093e\u0928' \u091a\u093f\u0932\u094d\u0932\u093e\u0924\u0947 \u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u093f\u092f\u094b\u0902 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/viral-video-delhi-police-officer-rashtriya-swayam-sevak-sangh-rss-kisan-tractor-rally-11803",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915: \u0915\u094d\u092f\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u093e \u092a\u0941\u0932\u093f\u0938 \u0905\u0927\u093f\u0915\u093e\u0930\u0940 \u0906\u0930\u090f\u0938\u090f\u0938 \u0915\u093e \u0938\u0926\u0938\u094d\u092f \u0939\u0948?",
      },
      {
        url:
          "https://factly.in/telgu-nehru-did-not-write-a-letter-to-attlee-calling-bose-as-a-war-criminal/",
        headline:
          " 1945 \u0c32\u0c4b \u0c38\u0c41\u0c2d\u0c3e\u0c37\u0c4d \u0c1a\u0c02\u0c26\u0c4d\u0c30\u0c2c\u0c4b\u0c38\u0c4d \u0c28\u0c3f \u2018\u0c2f\u0c41\u0c26\u0c4d\u0c27 \u0c28\u0c47\u0c30\u0c38\u0c4d\u0c25\u0c41\u0c21\u0c41\u2019 \u0c05\u0c02\u0c1f\u0c42 \u0c15\u0c4d\u0c32\u0c3f\u0c2e\u0c46\u0c02\u0c1f\u0c4d \u0c05\u0c1f\u0c4d\u0c32\u0c40\u0c15\u0c3f \u0c28\u0c46\u0c39\u0c4d\u0c30\u0c42 \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c02 \u0c30\u0c3e\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/khalistan-supporters-video-sacramento-united-states-india-kisan-andolan-fake-news-11804",
        headline:
          "\u0959\u093e\u0932\u093f\u0938\u094d\u0924\u093e\u0928 \u0938\u092e\u0930\u094d\u0925\u0915\u094b\u0902 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u092f\u0941.\u090f\u0938 \u0938\u0947 \u0939\u0948, \u092d\u093e\u0930\u0924 \u0938\u0947 \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/nihang-sikh-with-sword-delhi-police-kisan-andolan-injured-old-man-fake-news-11859",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u0940 \u092a\u0930 \u0924\u0932\u0935\u093e\u0930 \u0909\u0920\u093e\u0928\u0947 \u0935\u093e\u0932\u0947 \u0928\u093f\u0939\u0902\u0917 \u0938\u093f\u0916 \u0915\u094b \u092c\u093e\u0926 \u092e\u0947\u0902 \u092a\u0941\u0932\u093f\u0938 \u0928\u0947 \u092a\u0940\u091f\u093e?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/delhi-police-kisan-andolan-jai-jawan-jai-kisan-video-fake-claims-11801",
        headline:
          "\u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0906 '\u091c\u092f \u091c\u0935\u093e\u0928, \u091c\u092f \u0915\u093f\u0938\u093e\u0928' \u091a\u093f\u0932\u094d\u0932\u093e\u0924\u0947 \u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u093f\u092f\u094b\u0902 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/photoshopped-anti-bjp-signboard-viral-in-west-bengal-ahead-of-assembly-poll-11806",
        headline:
          "\u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u09ad\u09cb\u099f\u09c7\u09b0 \u0986\u0997\u09c7 BJP \u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u09b2\u09c7\u0996\u09be \u09b8\u09b9 \u09b8\u09be\u0987\u09a8\u09ac\u09cb\u09b0\u09cd\u09a1\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/worlds-largest-800-kg-bhagavad-gita-in-iskcon-unveiled-in-delhi-shared-with-misleading-claims-11874",
        headline:
          "\u0987\u09a4\u09be\u09b2\u09bf\u09b0 \u09ae\u09bf\u09b2\u09be\u09a8 \u09a8\u09df, \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09b0 \u0987\u09b8\u0995\u09a8 \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u09c7 \u09a5\u09be\u0995\u09be \u09ac\u09c3\u09b9\u09a4\u09cd\u09a4\u09ae \u0997\u09c0\u09a4\u09be\u09b0 \u099b\u09ac\u09bf \u098f\u099f\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/clickbait-article-with-images-of-taimur-ali-khan-with-saif-ali-khan-and-kareena-kapoor-khan-peddled-as-their-new-born-child-11869",
        headline:
          "\u0995\u09b0\u09bf\u09a8\u09be\u09b0 \u09a6\u09cd\u09ac\u09bf\u09a4\u09c0\u09df \u09b8\u09a8\u09cd\u09a4\u09be\u09a8 \u09ac\u09b2\u09c7 Clickbait \u09b0\u09bf\u09aa\u09cb\u09b0\u09cd\u099f \u099b\u09a1\u09bc\u09be\u09b2 Taimur-\u098f\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-delhi-police-brutality-farmers-protest-fake-news-fact-check-11837",
        headline:
          "\u09e8\u09e6\u09e7\u09e7-\u09df \u09b2\u0996\u09a8\u0989 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u09a8\u09c3\u09b6\u0982\u09b8\u09a4\u09be \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u0995\u09be\u099c \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09df\u09c7\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/image-of-delhi-cops-during-jamia-violence-shared-as-bjp-workers-attacked-10323-terminated-protesting-teachers-in-tripura-11811",
        headline:
          "\u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09bf\u09df\u09c7 \u09a6\u09be\u09ac\u09bf \u09a4\u09cd\u09b0\u09bf\u09aa\u09c1\u09b0\u09be\u09df \u09b6\u09bf\u0995\u09cd\u09b7\u0995 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7 \u09aa\u09c1\u09b2\u09bf\u09b6 \u09b8\u09c7\u099c\u09c7 BJP \u0995\u09b0\u09cd\u09ae\u09c0",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-video-delhi-police-officer-rashtriya-swayam-sevak-sangh-rss-kisan-tractor-rally-11803",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915: \u0915\u094d\u092f\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u093e \u092a\u0941\u0932\u093f\u0938 \u0905\u0927\u093f\u0915\u093e\u0930\u0940 \u0906\u0930\u090f\u0938\u090f\u0938 \u0915\u093e \u0938\u0926\u0938\u094d\u092f \u0939\u0948?",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/01/fact-check-no-this-image-of-police-brutality-is-not-from-delhi-heres-the-truth/",
        headline:
          "Fact Check: No, This Image Of Police Brutality Is Not From Delhi; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/06/fact-check-did-social-activist-anna-hazare-join-bjp-heres-the-truth/",
        headline:
          "Fact Check: Did Social Activist Anna Hazare Join BJP? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/telugu-khosla-commission-report-said-no-decision-was-taken-to-extradite-netaji-as-a-war-criminal/",
        headline:
          " \u0c28\u0c47\u0c24\u0c3e\u0c1c\u0c40\u0c28\u0c3f \u0c2f\u0c41\u0c26\u0c4d\u0c27 \u0c16\u0c48\u0c26\u0c40\u0c17\u0c3e \u0c05\u0c2a\u0c4d\u0c2a\u0c17\u0c3f\u0c02\u0c1a\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c05\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c28\u0c3f\u0c30\u0c4d\u0c23\u0c2f\u0c02 \u0c24\u0c40\u0c38\u0c41\u0c15\u0c4b\u0c32\u0c47\u0c26\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c3f\u0c28 \u0c16\u0c4b\u0c38\u0c4d\u0c32\u0c3e \u0c15\u0c2e\u0c3f\u0c37\u0c28\u0c4d \u0c30\u0c3f\u0c2a\u0c4b\u0c30\u0c4d\u0c1f\u0c4d",
      },
      {
        url:
          "https://factly.in/telugu-bjp-govt-has-not-settled-the-oil-bonds-amount-taken-under-the-congress-govt/",
        headline:
          " \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35 \u0c39\u0c2f\u0c3e\u0c02\u0c32\u0c4b \u0c24\u0c40\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c06\u0c2f\u0c3f\u0c32\u0c4d \u0c2c\u0c3e\u0c02\u0c21\u0c4d\u0c38\u0c4d \u0c2c\u0c15\u0c3e\u0c2f\u0c3f\u0c32\u0c41 \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c07\u0c02\u0c15\u0c3e \u0c24\u0c40\u0c30\u0c4d\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-list-of-up-congress-minority-cell-members-viral-with-a-communal-twist-1766657-2021-02-06",
        headline:
          "Fact Check: List of UP Congress' minority cell members viral with communal twist",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-emotional-post-about-a-farmer-and-his-soldier-son-is-misleading-1766878-2021-02-07",
        headline:
          "Fact Check: This emotional post about a farmer and his soldier son is misleading",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2011-rd-parades-bihar-tableau-being-shared-as-up-tableau-673610",
        headline:
          "Fact Check: 2011 RD parade's Bihar tableau being shared as UP tableau",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-anna-hazare-has-not-joined-bjp-673774",
        headline: "Fact check: No, Anna Hazare has not joined BJP",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bjps-2016-win-in-chandigarh-local-polls-revived-with-false-claim-673604",
        headline:
          "Fact Check: BJP's 2016 win in Chandigarh local polls revived with false claim",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/telugu-morphed-photo-shared-as-nara-lokesh-riding-a-bike-with-y-s-jagan-photo/",
        headline:
          " \u0c35\u0c48.\u0c2f\u0c46\u0c38\u0c4d.\u0c1c\u0c17\u0c28\u0c4d \u0c2b\u0c4b\u0c1f\u0c4b \u0c24\u0c17\u0c3f\u0c32\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2c\u0c48\u0c15\u0c4d\u200c\u0c28\u0c3f \u0c28\u0c3e\u0c30\u0c3e \u0c32\u0c4b\u0c15\u0c47\u0c37\u0c4d\u00a0\u0c28\u0c21\u0c3f\u0c2a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2b\u0c47\u0c15\u0c4d \u0c2b\u0c4b\u0c1f\u0c4b\u00a0",
      },
      {
        url:
          "https://factly.in/simulation-video-of-flying-train-is-passed-off-as-flying-train-unveiled-in-china/",
        headline:
          " Simulation video of flying train is passed off as flying train unveiled in China",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-tractor-runs-over-old-women-parade-rally-alka-lamba-bjp-fake-claim-viral-news-11841",
        headline:
          "\u09a6\u09c1\u0987 \u09ae\u09b9\u09bf\u09b2\u09be\u0995\u09c7 \u098f\u0995 \u099c\u09b2\u09c7\u09b0 \u0997\u09be\u09dc\u09bf \u099a\u09be\u09aa\u09be \u09a6\u09c7\u0993\u09df\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/old-video-peddled-as-rush-hour-at-mumbais-borivali-station-on-feb-1-11828",
        headline:
          "Old Video Peddled As Rush Hour At Mumbai's Borivali Station On Feb 1",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/03/fact-check-is-the-floating-train-in-the-video-in-china-heres-the-truth/",
        headline:
          "Fact Check: Is The \u2018Floating Train\u2019 In The Video In China? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/03/fact-check-video-of-water-tanker-accident-in-amritsar-shared-with-false-claims/",
        headline:
          "Fact Check: Video Of Water Tanker Accident In Amritsar Shared With False Claims",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/04/fact-check-this-video-is-not-of-borivali-station-after-mumbai-local-train-services-resumed/",
        headline:
          "Fact Check: This Video Is Not Of Borivali Station After Mumbai Local Train Services Resumed",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/04/fact-check-is-this-japans-new-high-speed-electric-train-in-the-video-heres-the-truth/",
        headline:
          "Fact Check: Is This Japan\u2019s New High-Speed Electric Train In The Video? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/06/fact-check-picture-of-bicycle-carving-on-indonesian-temple-claimed-to-be-of-temple-in-tamil-nadu/",
        headline:
          "Fact Check: Picture Of Bicycle Carving On Indonesian Temple Claimed To Be Of Temple In Tamil Nadu",
      },
      {
        url:
          "https://factly.in/bicycle-sculpture-on-an-indonesian-temple-shared-as-that-from-panchavarna-swamy-temple-in-tamil-nadu/",
        headline:
          " Bicycle sculpture from an Indonesian temple shared as that from Panchavarna temple in Tamil Nadu",
      },
      {
        url:
          "https://factly.in/telugu-agriculture-infrastructure-and-development-cess-will-not-increase-the-burden-on-petrol-and-diesel-consumers/",
        headline:
          " 2021-22 \u0c2c\u0c21\u0c4d\u0c1c\u0c46\u0c1f\u0c4d \u0c26\u0c4d\u0c35\u0c3e\u0c30\u0c3e \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d, \u0c21\u0c40\u0c1c\u0c3f\u0c32\u0c4d \u0c27\u0c30\u0c32\u0c41 \u0c2a\u0c46\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-of-a-japanese-train-running-at-a-speed-of-4800-kmph-is-a-simulated-time-lapse-video-not-real/",
        headline:
          " This video of a Japanese train running at a speed of 4800 kmph is a simulated time-lapse video, not real",
      },
      {
        url:
          "https://factly.in/telugu-this-incident-of-tractor-running-into-a-group-of-farmers-took-place-in-amritsar-not-uttar-pradesh/",
        headline:
          " \u0c30\u0c48\u0c24\u0c41\u0c32\u0c2a\u0c48\u0c15\u0c3f \u0c1f\u0c4d\u0c30\u0c3e\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c26\u0c42\u0c38\u0c41\u0c15\u0c4a\u0c1a\u0c4d\u0c1a\u0c3f\u0c28 \u0c08 \u0c18\u0c1f\u0c28 \u0c05\u0c2e\u0c4d\u0c30\u0c3f\u0c24\u0c4d\u200c\u0c38\u0c30\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f, \u0c09\u0c24\u0c4d\u0c24\u0c30 \u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-indian-football-team-played-in-the-1948-olympics-without-shoes-as-they-were-not-used-to-them/",
        headline:
          " \u0c2c\u0c42\u0c1f\u0c4d\u0c32\u0c41 \u0c32\u0c47\u0c15 \u0c15\u0c3e\u0c26\u0c41, \u0c05\u0c32\u0c35\u0c3e\u0c1f\u0c41 \u0c32\u0c47\u0c15 \u0c2d\u0c3e\u0c30\u0c24 \u0c2b\u0c41\u0c1f\u0c4d\u200c\u0c2c\u0c3e\u0c32\u0c4d \u0c1c\u0c1f\u0c4d\u0c1f\u0c41 \u0c32\u0c4b \u0c1a\u0c3e\u0c32\u0c3e \u0c2e\u0c02\u0c26\u0c3f \u0c2c\u0c42\u0c1f\u0c4d\u0c32\u0c41 \u0c35\u0c47\u0c38\u0c41\u0c15\u0c4b\u0c15\u0c41\u0c02\u0c21\u0c3e 1948 \u0c12\u0c32\u0c3f\u0c02\u0c2a\u0c3f\u0c15\u0c4d\u0c38\u0c4d \u0c32\u0c4b \u0c06\u0c21\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-petrol-prices-are-lower-in-pakistan-nepal-and-sri-lanka-than-in-india/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24\u0c4d \u0c32\u0c4b \u0c15\u0c28\u0c4d\u0c28\u0c3e \u0c28\u0c47\u0c2a\u0c3e\u0c32\u0c4d \u0c2e\u0c30\u0c3f\u0c2f\u0c41 \u0c36\u0c4d\u0c30\u0c40\u0c32\u0c02\u0c15\u0c32\u0c4b \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c27\u0c30\u0c32\u0c41 \u0c24\u0c15\u0c4d\u0c15\u0c41\u0c35\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-was-not-the-rush-at-borivali-station-after-local-trains-resumed-in-mumbai-1765304-2021-02-02",
        headline:
          "Fact Check: This was not the rush at Borivali station after local trains resumed in Mumbai",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-viral-video-of-japan-lightning-fast-electric-train-is-not-real-1764990-2021-02-02",
        headline:
          "Fact Check: Viral video of Japan\u2019s lightning-fast electric train is not real",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-of-crowded-mumbai-local-shared-with-false-claim-673803",
        headline:
          "FACT CHECK: Old video of crowded Mumbai local shared with false claim",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-japans-new-electric-train-runs-at-360-per-hour-not-4800-km-per-hour-673638",
        headline:
          "Fact check: Japan's new fastest train runs at 360 per hour, not 4800 km per hour",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-true-japan-opens-train-station-for-scenery-viewing-673801",
        headline:
          "Fact check: True, Japan opens train station for scenery viewing",
      },
    ],
    "6": [
      {
        url:
          "https://factly.in/telugu-these-two-colleges-did-not-issue-notices-saying-girls-are-allowed-into-college-only-if-they-have-a-boyfriend/",
        headline:
          " \u2018\u0c2c\u0c3e\u0c2f\u0c4d \u0c2b\u0c4d\u0c30\u0c46\u0c02\u0c21\u0c4d \u0c09\u0c02\u0c1f\u0c47\u0c28\u0c47 \u0c15\u0c3e\u0c32\u0c47\u0c1c\u0c40\u0c32\u0c4b\u0c15\u0c3f \u0c05\u0c2e\u0c4d\u0c2e\u0c3e\u0c2f\u0c3f\u0c32\u0c15\u0c41 \u0c05\u0c28\u0c41\u0c2e\u0c24\u0c3f\u2019 \u0c05\u0c28\u0c3f \u0c08 \u0c30\u0c46\u0c02\u0c21\u0c41 \u0c15\u0c3e\u0c32\u0c47\u0c1c\u0c40\u0c32\u0c41 \u0c28\u0c4b\u0c1f\u0c3f\u0c38\u0c41\u0c32\u0c41 \u0c07\u0c35\u0c4d\u0c35\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-gold-and-other-jewellery-is-not-allowed-into-the-test-center-as-per-the-ctet-test-guidelines/",
        headline:
          " CTET \u0c2a\u0c30\u0c40\u0c15\u0c4d\u0c37 \u0c17\u0c48\u0c21\u0c4d\u0c32\u0c48\u0c28\u0c4d\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c15\u0c3e\u0c30\u0c02 \u0c2c\u0c02\u0c17\u0c3e\u0c30\u0c02, \u0c07\u0c24\u0c30 \u0c28\u0c17\u0c32\u0c41 \u0c2a\u0c30\u0c40\u0c15\u0c4d\u0c37 \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30\u0c02\u0c32\u0c4b\u0c15\u0c3f \u0c05\u0c28\u0c41\u0c2e\u0c24\u0c3f\u0c02\u0c1a\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-karnataka-bmtc-bus-conductor-madhu-has-not-cleared-the-upsc-mains-test/",
        headline:
          " \u0c15\u0c30\u0c4d\u0c23\u0c3e\u0c1f\u0c15 BMTC \u0c2c\u0c38\u0c4d\u0c38\u0c41 \u0c15\u0c02\u0c21\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c2e\u0c27\u0c41 UPSC \u0c2e\u0c46\u0c2f\u0c3f\u0c28\u0c4d\u0c38\u0c4d \u0c2a\u0c30\u0c40\u0c15\u0c4d\u0c37 \u0c15\u0c4d\u0c32\u0c3f\u0c2f\u0c30\u0c4d \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-myanmar-coup-old-image-aung-san-suu-kyi-breaing-down-in-tear-military-captivity-11852",
        headline:
          "\u09b8\u09c7\u09a8\u09be\u09ac\u09be\u09b9\u09bf\u09a8\u09c0\u09b0 \u09b9\u09c7\u09ab\u09be\u099c\u09a4\u09c7 \u0986\u0982 \u09b8\u09be\u09a8 \u09b8\u09c1 \u099a\u09bf \u0995\u09be\u0981\u09a6\u099b\u09c7\u09a8 \u09ac\u09b2\u09c7 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/farmers-protest-viral-image-farmer-lying-dead-on-ground-old-image-thermal-plant-bathinda-11857",
        headline:
          "Image Of Farmer's Suicide To Protest Closure Of Thermal Plant Resurfaces",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/aung-san-suu-kyi-myanmar-military-coup-attending-funeral-u-aung-shwe-nld-party-crying-viral-image-11850",
        headline: "Myanmar Coup: 2017 Image Of Aung San Suu Kyi Resurfaces",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/farmers-protest-viral-image-farmer-lying-dead-on-ground-old-image-thermal-plant-bathinda-11861",
        headline:
          "\u09a4\u09be\u09aa \u09ac\u09bf\u09a6\u09cd\u09af\u09c1\u09ce \u0995\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ac\u09a8\u09cd\u09a7\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7 \u0995\u09c3\u09b7\u0995\u09c7\u09b0 \u0986\u09a4\u09cd\u09ae\u09b9\u09a8\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/bjp-imf-twitter-gdp-growth-8-115-2020-estimates-11805",
        headline:
          "IMF \u09aa\u09cd\u09b0\u09a6\u09a4\u09cd\u09a4 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 GDP \u09ac\u09c3\u09a6\u09cd\u09a7\u09bf\u09b0 \u09a4\u09a5\u09cd\u09af \u09a6\u09bf\u09a4\u09c7 \u0997\u09bf\u09df\u09c7 BJP \u09ac\u09bf\u09df\u09cb\u0997 \u099a\u09bf\u09b9\u09cd\u09a8 \u09a6\u09c7\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/01/fact-check-is-taj-hotels-gifting-gift-cards-for-valentines-day-heres-the-truth/",
        headline:
          "Fact Check: Is Taj Hotels Gifting Gift Cards For Valentine\u2019s Day? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/06/fact-check-tata-is-not-giving-valentines-day-gift-viral-message-is-fake/",
        headline:
          "Fact Check: TATA Is Not Giving Valentine\u2019s Day Gift; Viral Message Is Fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/06/fact-check-old-picture-of-detained-myanmar-leader-aung-san-suu-kyi-shared-as-recent/",
        headline:
          "Fact Check: Old Picture of Detained Myanmar Leader Aung San Suu Kyi Shared As Recent",
      },
      {
        url:
          "https://factly.in/neither-tata-group-not-taj-hotel-issued-any-valentines-day-gift-card-offer/",
        headline:
          " Neither the Tata group nor Taj Hotels launched any Valentine\u2019s day gift card offer",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-tata-taj-hotel-are-not-offering-any-gifts-to-people-on-valentines-day-673718",
        headline:
          "Fact check: No, TATA, TAJ HOTEL are not offering any gifts to people on Valentine's day",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-greta-thunberg-was-not-born-into-a-muslim-family-673858",
        headline:
          "Fact Check: No, Greta Thunberg was not born into a Muslim family",
      },
    ],
  },
  numpy_seed: 666,
  number_of_articles: 131,
}
