export const data = {
  mdsDat: {
    x: [
      0.31249582082121885,
      -0.3249606043823122,
      -0.11862600453203243,
      0.1632336865529873,
      -0.03214289845986154,
    ],
    y: [
      0.00780473381036696,
      0.18106605355402142,
      -0.3984327390534326,
      0.0812371253298251,
      0.1283248263592191,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      17.984014033087263,
      19.972723651969325,
      18.483143035335164,
      26.176176257599597,
      17.383943022008655,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      21.0,
      12.0,
      10.0,
      10.0,
      13.0,
      10.0,
      8.0,
      9.0,
      8.0,
      8.0,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      2.000222005235594,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      6.000666015706782,
      5.000555013088985,
      3.000333007853391,
      3.000333007853391,
      3.000333007853391,
      3.000333007853391,
      3.000333007853391,
      2.000222005235594,
      2.000222005235594,
      2.000222005235594,
      2.000222005235594,
      2.000222005235594,
      9.000999023560174,
      9.000999023560174,
      8.000888020942376,
      16.001776041884753,
      5.000555013088985,
      4.000444010471188,
      3.000333007853391,
      0.9996348187810645,
      1.999269637562129,
      0.9996348187810645,
      2.9989044563431935,
      1.999269637562129,
      0.9996348187810645,
      1.999269637562129,
      0.9996348187810645,
      0.9996348187810645,
      0.9996348187810645,
      4.998174093905323,
      4.998174093905323,
      3.998539275124258,
      3.998539275124258,
      3.998539275124258,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      3.998539275124258,
      3.998539275124258,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      8.000703212268323,
      5.000439507667703,
      3.0002637046006213,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      9.000791113801863,
      6.000527409201243,
      2.000175803067081,
      4.000351606134162,
      5.000439507667703,
      2.000175803067081,
      2.000175803067081,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      12.001077450621617,
      8.00071830041441,
      8.00071830041441,
      5.000448937759007,
      5.000448937759007,
      4.000359150207205,
      4.000359150207205,
      4.000359150207205,
      3.0002693626554042,
      3.0002693626554042,
      3.0002693626554042,
      3.0002693626554042,
      12.001077450621617,
      3.0002693626554042,
      10.000897875518014,
      5.000448937759007,
      1.0000762623580841,
      6.000457574148505,
      1.0000762623580841,
      1.0000762623580841,
      1.0000762623580841,
      2.0001525247161682,
      2.0001525247161682,
      1.0000762623580841,
      2.0001525247161682,
      2.0001525247161682,
      6.000457574148505,
      6.000457574148505,
      3.0002287870742523,
      3.0002287870742523,
      3.0002287870742523,
      3.0002287870742523,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      3.0002287870742523,
      3.0002287870742523,
      2.0001525247161682,
      3.0002287870742523,
    ],
    Term: [
      "modi",
      "bengal",
      "pm_modi",
      "bangladesh",
      "bjp",
      "lockdown",
      "covid",
      "pm",
      "west",
      "west_bengal",
      "thoothukudi",
      "maximum",
      "confusing",
      "formula",
      "display",
      "whole",
      "closed",
      "refugee",
      "bolivia",
      "construction",
      "protest",
      "anti",
      "prime_minister",
      "country",
      "minister",
      "france",
      "prime",
      "anti_france",
      "tuesday",
      "satyagraha",
      "french",
      "tour",
      "bangladesh",
      "pm_modi",
      "pm",
      "modi",
      "link",
      "visit",
      "speech",
      "tinemar",
      "lock",
      "value",
      "hdfc",
      "thailand",
      "road",
      "scarf",
      "privatise",
      "graze",
      "kashiraj",
      "bank",
      "university",
      "uttaranchal",
      "sculpture",
      "convocation",
      "andhra",
      "axis",
      "andhra_pradesh",
      "attack",
      "church",
      "uttaranchal_university",
      "icici",
      "show",
      "pradesh",
      "mettu",
      "constitution",
      "relation",
      "jihad",
      "footpath",
      "spread",
      "tirumala",
      "month",
      "institution",
      "decrease",
      "covid",
      "give",
      "telangana",
      "labourer",
      "health",
      "statement",
      "meet",
      "kcr",
      "unrelated",
      "message",
      "govt",
      "lockdown",
      "announce",
      "weapon",
      "revive",
      "government",
      "fall",
      "resurface",
      "win",
      "spot",
      "ongoing",
      "scuffle",
      "night",
      "clash",
      "enrollment",
      "skirmish",
      "beef",
      "amazon",
      "bengal",
      "west_bengal",
      "west",
      "banerjee",
      "shah",
      "mamata_banerjee",
      "mamata",
      "ahead",
      "manifesto",
      "empty",
      "bjp_worker",
      "amit",
      "bjp",
      "dargah",
      "election",
      "modi",
      "daughter",
      "gandhi",
      "markete",
      "artist",
      "station",
      "june",
      "saayoni",
      "tmc",
      "mountain",
      "department",
      "rahul_gandhi",
      "rahul",
      "dmk",
      "order",
      "wolf",
      "real",
      "stalin",
      "ghosh",
      "hill",
      "welcome",
      "party",
      "simulation",
      "school",
      "bihar",
      "plane",
      "temple",
    ],
    Total: [
      21.0,
      12.0,
      10.0,
      10.0,
      13.0,
      10.0,
      8.0,
      9.0,
      8.0,
      8.0,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      2.000222005235594,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      1.000111002617797,
      6.000666015706782,
      5.000555013088985,
      3.000333007853391,
      3.000333007853391,
      3.000333007853391,
      3.000333007853391,
      3.000333007853391,
      2.000222005235594,
      2.000222005235594,
      2.000222005235594,
      2.000222005235594,
      2.000222005235594,
      10.001075285918258,
      10.001088811111975,
      9.000977808494177,
      21.00222497964376,
      8.00082248972613,
      7.000713373126592,
      5.000485532569559,
      0.9996348187810645,
      1.999269637562129,
      0.9996348187810645,
      2.9989044563431935,
      1.999269637562129,
      0.9996348187810645,
      1.999269637562129,
      0.9996348187810645,
      0.9996348187810645,
      0.9996348187810645,
      4.998174093905323,
      4.998174093905323,
      3.998539275124258,
      3.998539275124258,
      3.998539275124258,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      2.9989044563431935,
      4.998629062676059,
      7.998871374944029,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      1.0000879015335404,
      8.000703212268323,
      5.000439507667703,
      3.0002637046006213,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      2.000175803067081,
      10.000867376159947,
      8.000252015534109,
      2.000175803067081,
      5.000427868492245,
      7.999343964010896,
      3.000286805684878,
      3.0002655906188824,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      1.0000897875518013,
      12.001077450621617,
      8.00071830041441,
      8.00071830041441,
      5.000448937759007,
      5.000448937759007,
      4.000359150207205,
      4.000359150207205,
      4.000359150207205,
      3.0002693626554042,
      3.0002693626554042,
      3.0002693626554042,
      3.0002693626554042,
      13.000712269402682,
      3.0002693626554042,
      12.001085140493894,
      21.00222497964376,
      1.0000762623580841,
      6.000457574148505,
      1.0000762623580841,
      1.0000762623580841,
      1.0000762623580841,
      2.0001525247161682,
      2.0001525247161682,
      1.0000762623580841,
      2.0001525247161682,
      2.0001525247161682,
      6.000457574148505,
      6.000457574148505,
      3.0002287870742523,
      3.0002287870742523,
      3.0002287870742523,
      3.0002287870742523,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      2.0001525247161682,
      3.999863605855317,
      3.999863605855317,
      2.0001525247161682,
      5.999586326169922,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.7157,
      1.6103,
      1.6103,
      1.5979,
      1.4438,
      1.2457,
      1.1561,
      1.2049,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.6108,
      1.3876,
      0.9174,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.6883,
      1.583,
      1.4007,
      1.6883,
      1.4652,
      1.2185,
      1.2828,
      1.2828,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.3403,
      1.2603,
      1.3403,
      1.158,
      -0.0948,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.7496,
      1.4621,
      1.4621,
      1.7496,
      1.0566,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.193,
      -5.193,
      -5.193,
      -5.193,
      -5.193,
      -4.4998,
      -5.193,
      -5.193,
      -5.193,
      -5.193,
      -3.4012,
      -3.5835,
      -4.0943,
      -4.0943,
      -4.0943,
      -4.0943,
      -4.0943,
      -4.4998,
      -4.4998,
      -4.4998,
      -4.4998,
      -4.4998,
      -2.9957,
      -2.9957,
      -3.1135,
      -2.4204,
      -3.5835,
      -3.8067,
      -4.0943,
      -5.2983,
      -4.6052,
      -5.2983,
      -4.1997,
      -4.6052,
      -5.2983,
      -4.6052,
      -5.2983,
      -5.2983,
      -5.2983,
      -3.6889,
      -3.6889,
      -3.912,
      -3.912,
      -3.912,
      -4.1997,
      -4.1997,
      -4.1997,
      -4.1997,
      -4.1997,
      -4.1997,
      -4.1997,
      -3.912,
      -3.912,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.2204,
      -3.1409,
      -3.6109,
      -4.1217,
      -4.5272,
      -4.5272,
      -4.5272,
      -4.5272,
      -4.5272,
      -4.5272,
      -4.5272,
      -4.5272,
      -3.0231,
      -3.4286,
      -4.5272,
      -3.8341,
      -3.6109,
      -4.5272,
      -4.5272,
      -5.5683,
      -5.5683,
      -5.5683,
      -5.5683,
      -5.5683,
      -5.5683,
      -5.5683,
      -5.5683,
      -5.5683,
      -5.5683,
      -3.0834,
      -3.4889,
      -3.4889,
      -3.9589,
      -3.9589,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.4697,
      -4.4697,
      -4.4697,
      -4.4697,
      -3.0834,
      -4.4697,
      -3.2658,
      -3.9589,
      -5.1591,
      -3.3673,
      -5.1591,
      -5.1591,
      -5.1591,
      -4.4659,
      -4.4659,
      -5.1591,
      -4.4659,
      -4.4659,
      -3.3673,
      -3.3673,
      -4.0604,
      -4.0604,
      -4.0604,
      -4.0604,
      -4.4659,
      -4.4659,
      -4.4659,
      -4.4659,
      -4.4659,
      -4.4659,
      -4.0604,
      -4.0604,
      -4.4659,
      -4.0604,
    ],
  },
  "token.table": {
    Topic: [
      4,
      4,
      4,
      2,
      2,
      2,
      3,
      4,
      1,
      1,
      5,
      2,
      2,
      4,
      1,
      5,
      2,
      4,
      4,
      2,
      5,
      2,
      4,
      4,
      1,
      2,
      4,
      1,
      1,
      3,
      1,
      2,
      1,
      3,
      4,
      5,
      3,
      5,
      1,
      5,
      1,
      4,
      5,
      4,
      4,
      1,
      3,
      3,
      1,
      1,
      1,
      5,
      5,
      3,
      2,
      3,
      3,
      2,
      2,
      3,
      5,
      2,
      3,
      3,
      5,
      2,
      3,
      3,
      1,
      3,
      4,
      2,
      3,
      5,
      4,
      4,
      4,
      5,
      1,
      3,
      3,
      3,
      1,
      1,
      4,
      3,
      5,
      4,
      4,
      5,
      5,
      5,
      1,
      4,
      1,
      4,
      2,
      4,
      5,
      1,
      1,
      2,
      1,
      5,
      5,
      5,
      1,
      3,
      3,
      4,
      3,
      5,
      2,
      5,
      1,
      2,
      2,
      5,
      4,
      2,
      4,
      2,
      4,
      5,
      4,
      1,
      5,
      4,
      3,
      5,
      3,
      5,
      3,
      2,
      3,
      5,
      2,
      1,
      2,
      3,
      5,
      1,
      1,
      2,
      3,
      2,
      2,
      2,
      1,
      4,
      3,
      5,
      4,
      4,
      1,
      4,
      5,
    ],
    Freq: [
      0.9999102205092794,
      0.9999102205092794,
      0.9999102205092792,
      1.0003653146249756,
      1.0003653146249756,
      0.12499606238132219,
      0.7499763742879331,
      0.12499606238132219,
      0.9998890097024166,
      0.9998890097024166,
      0.9999237434574196,
      1.0003653146249756,
      1.0003653146249756,
      0.9999102205092792,
      0.8999032346723962,
      0.09998924829693291,
      1.0003653146249754,
      0.9999102205092794,
      0.9999102205092792,
      0.25000852492473014,
      0.7500255747741904,
      0.07691886254213247,
      0.9230263505055897,
      0.9999102205092792,
      0.9998890097024166,
      1.0003653146249756,
      0.9999102205092794,
      0.9998890097024166,
      0.9998890097024166,
      0.9999121061924601,
      0.9998890097024166,
      1.0003653146249756,
      0.9998890097024166,
      0.9999121061924601,
      0.9999102205092792,
      0.9999237434574196,
      0.9999121061924601,
      0.9999237434574196,
      0.9998890097024166,
      0.9999237434574196,
      0.08332579831683837,
      0.8332579831683836,
      0.08332579831683837,
      0.9999102205092792,
      0.9999102205092794,
      0.33330146908129643,
      0.6666029381625929,
      0.9999121061924601,
      0.9998890097024166,
      0.9998890097024166,
      0.9998890097024166,
      0.9999237434574196,
      0.9999237434574196,
      0.99991210619246,
      0.37503075420897275,
      0.6250512570149546,
      0.9999121061924601,
      1.0003653146249756,
      1.0003653146249756,
      0.9999121061924601,
      0.9999237434574196,
      1.0003653146249756,
      0.9999121061924601,
      0.9999121061924601,
      0.9999237434574196,
      1.0003653146249756,
      0.9999121061924601,
      0.9999121061924601,
      0.6249357495958083,
      0.12498714991916167,
      0.24997429983832334,
      1.0003653146249756,
      0.8999219429160902,
      0.09999132699067668,
      0.9999102205092794,
      0.9999102205092794,
      0.9999102205092792,
      0.9999237434574196,
      0.9998890097024166,
      0.9999121061924601,
      0.9999121061924601,
      0.9999121061924601,
      0.9998890097024166,
      0.761824045571737,
      0.2380700142411678,
      0.9999121061924601,
      0.9999237434574196,
      0.9999102205092794,
      0.9999102205092794,
      0.9999237434574196,
      0.9999237434574196,
      0.9999237434574196,
      0.8887923257015966,
      0.11109904071269958,
      0.8999020176683474,
      0.09998911307426082,
      0.500070549018922,
      0.250035274509461,
      0.250035274509461,
      0.9998890097024166,
      0.9998890097024166,
      1.0003653146249756,
      0.9998890097024166,
      0.9999237434574196,
      0.9999237434574196,
      0.9999237434574196,
      0.9998890097024166,
      0.9999121061924601,
      0.6666076517537397,
      0.33330382587686985,
      0.7999315468990258,
      0.19998288672475645,
      1.0003653146249756,
      0.9999237434574196,
      0.9998890097024166,
      1.0003653146249756,
      0.25000852492473014,
      0.7500255747741904,
      0.9999102205092794,
      1.0003653146249756,
      0.9999102205092792,
      0.8002194101313382,
      0.20005485253283456,
      0.9999237434574196,
      0.9999102205092794,
      0.5999417417489086,
      0.39996116116593905,
      0.9999102205092794,
      0.9999121061924601,
      0.9999237434574196,
      0.9999121061924601,
      0.9999237434574196,
      0.9999121061924601,
      0.3333563167973917,
      0.16667815839869585,
      0.5000344751960876,
      1.0003653146249756,
      0.9998890097024166,
      1.0003653146249756,
      0.9999121061924601,
      0.9999237434574196,
      0.9998890097024166,
      0.9998890097024166,
      1.0003653146249754,
      0.9999121061924601,
      1.0003653146249756,
      1.0003653146249756,
      1.0003653146249756,
      0.5713703428217285,
      0.42852775711629637,
      0.9999121061924601,
      0.9999237434574196,
      0.9999102205092794,
      0.9999102205092794,
      0.9998890097024166,
      0.9999102205092794,
      0.9999237434574196,
    ],
    Term: [
      "ahead",
      "amazon",
      "amit",
      "andhra",
      "andhra_pradesh",
      "announce",
      "announce",
      "announce",
      "anti",
      "anti_france",
      "artist",
      "attack",
      "axis",
      "banerjee",
      "bangladesh",
      "bangladesh",
      "bank",
      "beef",
      "bengal",
      "bihar",
      "bihar",
      "bjp",
      "bjp",
      "bjp_worker",
      "bolivia",
      "church",
      "clash",
      "closed",
      "confusing",
      "constitution",
      "construction",
      "convocation",
      "country",
      "covid",
      "dargah",
      "daughter",
      "decrease",
      "department",
      "display",
      "dmk",
      "election",
      "election",
      "election",
      "empty",
      "enrollment",
      "fall",
      "fall",
      "footpath",
      "formula",
      "france",
      "french",
      "gandhi",
      "ghosh",
      "give",
      "government",
      "government",
      "govt",
      "graze",
      "hdfc",
      "health",
      "hill",
      "icici",
      "institution",
      "jihad",
      "june",
      "kashiraj",
      "kcr",
      "labourer",
      "link",
      "link",
      "link",
      "lock",
      "lockdown",
      "lockdown",
      "mamata",
      "mamata_banerjee",
      "manifesto",
      "markete",
      "maximum",
      "meet",
      "message",
      "mettu",
      "minister",
      "modi",
      "modi",
      "month",
      "mountain",
      "night",
      "ongoing",
      "order",
      "party",
      "plane",
      "pm",
      "pm",
      "pm_modi",
      "pm_modi",
      "pradesh",
      "pradesh",
      "pradesh",
      "prime",
      "prime_minister",
      "privatise",
      "protest",
      "rahul",
      "rahul_gandhi",
      "real",
      "refugee",
      "relation",
      "resurface",
      "resurface",
      "revive",
      "revive",
      "road",
      "saayoni",
      "satyagraha",
      "scarf",
      "school",
      "school",
      "scuffle",
      "sculpture",
      "shah",
      "show",
      "show",
      "simulation",
      "skirmish",
      "speech",
      "speech",
      "spot",
      "spread",
      "stalin",
      "statement",
      "station",
      "telangana",
      "temple",
      "temple",
      "temple",
      "thailand",
      "thoothukudi",
      "tinemar",
      "tirumala",
      "tmc",
      "tour",
      "tuesday",
      "university",
      "unrelated",
      "uttaranchal",
      "uttaranchal_university",
      "value",
      "visit",
      "visit",
      "weapon",
      "welcome",
      "west",
      "west_bengal",
      "whole",
      "win",
      "wolf",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-cropped-clip-pm-narendra-modi-speech-lie-to-the-poor-congress-party-assam-formula-assam-election-fact-check-12448",
        headline:
          "Video Of PM Modi Saying 'Lie To The Poor To Stay In Power' Is Cropped",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-modis-speech-about-lying-to-poor-edited-to-remove-context-675845",
        headline:
          "Fact Check: Modi's speech about 'lying to poor' edited to remove context",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-meat-shops-to-remain-closed-every-tuesday-is-not-a-decision-for-whole-nation-viral-post-is-misleading/",
        headline:
          "Fact Check: \u0939\u0930 \u092e\u0902\u0917\u0932\u0935\u093e\u0930 \u092a\u0942\u0930\u0947 \u0926\u0947\u0936 \u092e\u0947\u0902 \u092e\u0940\u091f \u0915\u0940 \u0926\u0941\u0915\u093e\u0928\u0947\u0902 \u092c\u0902\u0926 \u0930\u0939\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u0940 \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u092d\u094d\u0930\u093e\u092e\u0915",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/anti-france-protest-photo-falsely-linked-to-pm-modis-bangladesh-visit-12469",
        headline:
          "Anti-France Protest Photo Falsely Linked To PM Modi's Bangladesh Visit",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/narendra-modi-bangladesh-bjp-jana-sangh-1971-bangladesh-freedom-jana-sangh-satyagraha-12525",
        headline:
          "Did PM Modi Take Part In Satyagraha For Bangladesh? What We Know So Far",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/jan-sangh-satyagraha-for-bangladesh-liberation-war-1971-narendra-modi-atal-vihari-vajpayee-12527",
        headline: "          ?    ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/assam-assembly-election-2021-image-of-detention-centre-in-usa-texas-shared-as-detention-camp-in-assam-12504",
        headline:
          "\u0986\u09ae\u09c7\u09b0\u09bf\u0995\u09be\u09b0 \u099f\u09c7\u0995\u09cd\u09b8\u09be\u09b8\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09b9\u09b2 \u0985\u09b8\u09ae\u09c7\u09b0 \u09a1\u09bf\u099f\u09c7\u09a8\u09b6\u09a8 \u0995\u09cd\u09af\u09be\u09ae\u09cd\u09aa-\u09ac\u09a8\u09cd\u09a6\u09c0 \u09a8\u09be\u0997\u09b0\u09bf\u0995",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-anti-france-protest-photo-linked-to-pm-modi-bangladesh-visit-12505",
        headline:
          "\u09ae\u09cb\u09a6\u09c0\u09b0 \u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6 \u09b8\u09ab\u09b0\u09c7\u09b0 \u09b8\u09be\u09a5\u09c7 \u099c\u09cb\u09dc\u09be \u09b9\u09b2 \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8-\u09ac\u09bf\u09b0\u09c7\u09be\u09a7\u09c0 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2011-image-of-muslims-greet-narendra-modi-during-sadbhavana-mission-in-gujarat-shared-falsely-as-bangladesh-visit-12516",
        headline:
          "\u09e8\u09e6\u09e7\u09e7'\u09b0 \u0997\u09c1\u099c\u09b0\u09be\u09a4\u09c7 \u09ae\u09cb\u09a6\u09c0\u0995\u09c7 \u09b6\u09c1\u09ad\u09c7\u099a\u09cd\u099b\u09be \u099c\u09be\u09a8\u09be\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09c1\u09a1\u09bc\u09b2 \u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6 \u09b8\u09ab\u09b0\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/narendra-modi-bangladesh-bjp-jana-sangh-1971-bangladesh-freedom-jana-sangh-satyagraha-12531",
        headline:
          "\u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0 \u0995\u09c0 \u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09b8\u09a4\u09cd\u09af\u09be\u0997\u09cd\u09b0\u09b9\u09c7 \u0985\u0982\u09b6 \u09a8\u09c7\u09a8? \u09af\u09be \u099c\u09be\u09a8\u09be \u0997\u09c7\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-anti-france-protests-passed-off-as-anti-modi-protest-in-bangladesh-675960",
        headline:
          "Fact check: Anti-France protests passed off as anti-Modi rally in Bangladesh",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-an-old-image-of-anti-france-rally-in-dhaka-is-being-shared-as-a-recent-protest-against-the-narendra-modi-bangladesh-visit-with-the-false-claim/",
        headline:
          "Fact Check: \u0922\u093e\u0915\u093e \u092e\u0947\u0902 \u0939\u0941\u090f \u092b\u094d\u0930\u093e\u0902\u0938 \u0935\u093f\u0930\u094b\u0927\u0940 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0915\u0940 \u092c\u093e\u0902\u0917\u094d\u0932\u093e\u0926\u0947\u0936 \u092f\u093e\u0924\u094d\u0930\u093e \u0915\u0947 \u0935\u093f\u0930\u094b\u0927 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-clipped-video-of-pm-modis-bokakhat-assam-rally-viral-with-misleading-claim/",
        headline:
          "Fact Check: \u0905\u0938\u092e \u092e\u0947\u0902 \u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u0947 \u092d\u093e\u0937\u0923 \u0915\u093e \u0915\u094d\u0932\u093f\u092a\u094d\u0921 \u0935\u0940\u0921\u093f\u092f\u094b \u092d\u094d\u0930\u093e\u092e\u0915 \u0926\u093e\u0935\u0947 \u0938\u0902\u0917 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-video-of-people-falling-from-a-tall-building-is-not-from-assam-but-from-bolivia/",
        headline:
          "Fact Check: \u090a\u0902\u091a\u0940 \u0907\u092e\u093e\u0930\u0924 \u0938\u0947 \u0932\u094b\u0917\u094b\u0902 \u0915\u0947 \u0917\u093f\u0930\u0928\u0947 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0905\u0938\u092e \u0915\u093e \u0928\u0939\u0940\u0902, \u092c\u094b\u0932\u0940\u0935\u093f\u092f\u093e \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-this-old-photograph-of-pm-narendra-modi-was-clicked-in-gujarat-and-not-rajasthan/",
        headline:
          "Fact Check: \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0915\u0940 \u092f\u0939 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0930\u093e\u091c\u0938\u094d\u0925\u093e\u0928 \u092e\u0947\u0902 \u0928\u0939\u0940\u0902, \u092c\u0932\u094d\u0915\u093f \u0917\u0941\u091c\u0930\u093e\u0924 \u092e\u0947\u0902 \u0939\u0940 \u0932\u0940 \u0917\u0908 \u0925\u0940, \u092b\u0930\u094d\u091c\u0940 \u0939\u0948 \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0915\u093e \u0926\u093e\u0935\u093e",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-post-claiming-the-incompletion-of-phc-building-construction-in-thoothukudi-is-fake/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: Post claiming the incompletion of PHC building construction in Thoothukudi is fake",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/viral/fact-check-news-about-closure-of-meat-shops-across-india-on-tuesdays-is-fake/",
        headline:
          "Fact Check: \u0a2e\u0a70\u0a17\u0a32\u0a35\u0a3e\u0a30 \u0a28\u0a42\u0a70 \u0a2a\u0a42\u0a30\u0a47 \u0a26\u0a47\u0a36 \u0a35\u0a3f\u0a1a \u0a2e\u0a40\u0a1f \u0a26\u0a40 \u0a26\u0a41\u0a15\u0a3e\u0a28\u0a47 \u0a2c\u0a70\u0a26 \u0a30\u0a39\u0a3f\u0a23 \u0a26\u0a3e \u0a26\u0a3e\u0a05\u0a35\u0a3e \u0a15\u0a30\u0a26\u0a3e \u0a35\u0a3e\u0a07\u0a30\u0a32 \u0a2a\u0a4b\u0a38\u0a1f \u0a2d\u0a4d\u0a30\u0a2e\u0a15",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/viral/act-check-walmart-isnt-offering-250-coupon-by-clicking-on-a-link-viral-post-is-not-true/",
        headline:
          "Fact Check: \u0a32\u0a3f\u0a70\u0a15 \u0a09\u0a24\u0a47 \u0a15\u0a32\u0a3f\u0a15 \u0a15\u0a30\u0a28 \u0a24\u0a47 \u0a35\u0a3e\u0a32\u0a2e\u0a3e\u0a30\u0a1f \u0a28\u0a39\u0a40\u0a02 \u0a26\u0a47 \u0a30\u0a3f\u0a39\u0a3e 250 \u0a21\u0a3e\u0a32\u0a30 \u0a26\u0a3e \u0a15\u0a42\u0a2a\u0a28, \u0a35\u0a3e\u0a07\u0a30\u0a32 \u0a2a\u0a4b\u0a38\u0a1f \u0a2b\u0a3c\u0a30\u0a1c\u0a3c\u0a40 \u0a39\u0a48",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-old-picture-of-protest-circulated-as-recent-one-against-pm-modis-visit-to-bangladesh/",
        headline:
          "Fact Check: Old Picture Of Protest Circulated As Recent One Against PM Modi\u2019s Visit To Bangladesh",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-did-pm-modi-visit-hindu-refugees-in-rajasthan-in-1990-heres-the-truth/",
        headline:
          "Fact Check: Did PM Modi Visit Hindu Refugees In Rajasthan In 1990? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-clipped-video-of-pm-modis-speech-shared-to-create-misleading-narrative-heres-the-truth/",
        headline:
          "Fact Check: Clipped Video of PM Modi\u2019s Speech Shared To Create Misleading Narrative; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/clipped-video-shared-as-pm-modi-explaining-the-formula-to-fool-poor-people-of-this-country/",
        headline:
          " Clipped video shared as PM Modi explaining the formula to fool poor people of this country",
      },
      {
        url:
          "https://factly.in/old-photos-of-protest-against-french-cartoons-falsely-linked-to-pm-modis-recent-visit-to-bangladesh/",
        headline:
          " Old photo of a protest against French cartoons is falsely linked to PM Modi\u2019s visit to Bangladesh",
      },
      {
        url:
          "https://factly.in/telugu-2018-video-is-being-shared-again-in-the-wake-of-nagarjuna-sagar-by-election/",
        headline:
          " 2018 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c28\u0c3e\u0c17\u0c3e\u0c30\u0c4d\u0c1c\u0c41\u0c28 \u0c38\u0c3e\u0c17\u0c30\u0c4d \u0c09\u0c2a \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c28\u0c47\u0c2a\u0c25\u0c4d\u0c2f\u0c02\u0c32\u0c4b \u0c2e\u0c33\u0c4d\u0c33\u0c40 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/failure-to-link-pan-with-aadhaar-would-attract-a-maximum-penalty-of-rs-1000-not-rs-10000/",
        headline:
          " Failure to link PAN with Aadhaar would attract a maximum penalty of Rs. 1,000, not Rs. 10,000",
      },
    ],
    "2": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/nasa-india-diwali-photo-satellite-fake-news-12447",
        headline:
          "\u0938\u094b\u0936\u0932 \u092e\u0940\u0921\u093f\u092f\u093e \u0928\u0947 \u0926\u093f\u0916\u093e\u0908 \u091c\u0932\u094d\u0926\u092c\u093e\u095b\u0940, \u0926\u093f\u0935\u093e\u0932\u0940 \u0935\u093e\u0932\u0940 \u095e\u0947\u0915 \u0928\u094d\u092f\u0942\u095b \u0915\u0930 \u0926\u0940 \u0939\u094b\u0932\u0940 \u0915\u0947 \u092a\u0939\u0932\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://factly.in/icici-axis-and-hdfc-banks-were-not-setup-as-government-organisations-and-later-privatised/",
        headline:
          " ICICI, AXIS, & HDFC banks were not set up as government organisations and later privatised",
      },
      {
        url:
          "https://factly.in/image-of-an-artwork-passed-off-as-a-baby-born-with-face-resembling-hindu-god-ganesh/",
        headline:
          " Image of an artwork passed off as a baby born with a face resembling Lord Ganesha",
      },
      {
        url:
          "https://factly.in/telugu-the-man-attacking-a-woman-in-this-video-is-not-a-bjp-mla/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c2e\u0c39\u0c3f\u0c33\u0c32 \u0c2a\u0c48 \u0c26\u0c3e\u0c21\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c0e\u0c2e\u0c4d\u0c2e\u0c46\u0c32\u0c4d\u0c2f\u0c47 \u0c15\u0c3e\u0c26\u0c41; \u0c18\u0c1f\u0c28 \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c17\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-artwork-sculpture-viral-as-a-boy-look-like-ganpati-born/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0906\u0930\u094d\u091f\u0935\u0930\u094d\u0915 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0917\u0923\u092a\u0924\u093f \u0915\u0940 \u0936\u0915\u094d\u0932 \u0935\u093e\u0932\u0947 \u092c\u091a\u094d\u091a\u0947 \u0928\u0947 \u091c\u0928\u094d\u092e \u0932\u093f\u092f\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/health/fact-check-the-viral-advisory-is-not-issued-by-icmr-claim-with-the-viral-post-is-fake/",
        headline:
          "Fact Check: \u0915\u094b\u0935\u093f\u0921\u201419 \u0915\u094b \u0932\u0947\u0915\u0930 \u0906\u0908\u0938\u0940\u090f\u092e\u0906\u0930 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0940 \u092f\u0939 \u090f\u0921\u0935\u093e\u0907\u091c\u0930\u0940 \u092b\u0930\u094d\u091c\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/uttaranchal-university-convocation-saffron-dress-code-for-students-fact-check-12495",
        headline:
          "Photo From Uttaranchal University Convocation Viral With Misleading Claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/were-axis-hdfc-bank-icici-bank-psus-before-privatisation-a-factcheck-12485",
        headline:
          "Were Axis, HDFC Bank, ICICI Bank PSUs Before Privatisation? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-andhra-pradesh-church-blast-viral-video-sri-lanka-church-easter-bombing-fact-check-12491",
        headline:
          "Sri Lanka Easter Bombing Clip Passed Off As Andhra Pradesh Church Attack",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/muslim-youth-attack-when-asked-to-wear-helmets-viral-video-islamophobia-fact-check-12510",
        headline:
          "Road Safety Awareness Video Clipped And Shared With Communal Spin",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/uttaranchal-university-convocation-dress-code-saffron-scarf-ugc-dehradun-12508",
        headline:
          "\u0915\u094d\u092f\u093e \u0939\u0948 \u0909\u0924\u094d\u0924\u0930\u093e\u0902\u091a\u0932 \u0935\u093f\u0936\u094d\u0935\u0935\u093f\u0926\u094d\u092f\u093e\u0932\u092f \u0915\u0947 \u0926\u0940\u0915\u094d\u0937\u093e\u0902\u0924 \u0938\u092e\u093e\u0930\u094b\u0939 \u0915\u0940 \u0907\u0938 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093e \u0938\u091a?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/electronic-voting-machin-evm-fake-news-west-bengal-assembly-election-2021-12484",
        headline: "              ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/muslim-man-attacked-when-asked-to-wear-helmet-road-safety-awareness-video-helmet-man-12514",
        headline:
          "\u0939\u0947\u0932\u092e\u0947\u091f \u092a\u0939\u0928\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u091c\u093e\u0917\u0930\u0942\u0915\u0924\u093e \u092b\u0948\u0932\u093e\u0924\u0940 \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u090f\u0902\u0917\u0932 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-osmania-university-postpones-exams-revised-schedule-to-be-announced-later-675973",
        headline:
          "Fact Check: Osmania University postpones exams, revised schedule to be announced later",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-1978-amritsar-massacre-falsely-claims-to-show-bhagat-singhs-funeral-676053",
        headline:
          "Fact Check: Photo of 1978 Amritsar massacre falsely claims to show Bhagat Singh's funeral",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-morphed-photo-of-sharad-pawar-and-anil-deshmukh-goes-viral/",
        headline:
          "Fact Check : \u0936\u0930\u0926 \u092a\u0935\u093e\u0930 \u0914\u0930 \u0905\u0928\u093f\u0932 \u0926\u0947\u0936\u092e\u0941\u0916 \u0915\u0940 \u092e\u0949\u0930\u094d\u092b\u094d\u0921 \u0924\u0938\u094d\u200d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-morphed-photo-of-aap-mp-bhagwant-mann-goes-viral-with-fake-claim/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check : \u092d\u0917\u0935\u0902\u0924 \u092e\u093e\u0928 \u0915\u0940 \u092e\u0949\u0930\u094d\u092b\u094d\u0921 \u0924\u0938\u094d\u200d\u0935\u0940\u0930 \u092b\u093f\u0930 \u0938\u0947 \u092b\u0930\u094d\u091c\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-uttaranchal-university-all-students-were-not-wearing-saffron-scarf-at-convocation-ceremony-old-photo-going-viral-with-misleading-claim/",
        headline:
          "Fact Check: \u0909\u0924\u094d\u0924\u0930\u093e\u0902\u091a\u0932 \u092f\u0942\u0928\u093f\u0935\u0930\u094d\u0938\u093f\u091f\u0940 \u0915\u0947 \u0926\u0940\u0915\u094d\u0937\u093e\u0902\u0924 \u0938\u092e\u093e\u0930\u094b\u0939 \u092e\u0947\u0902 \u091b\u093e\u0924\u094d\u0930\u094b\u0902 \u0928\u0947 \u092a\u0939\u0928\u0947 \u0925\u0947 \u0905\u0932\u0917-\u0905\u0932\u0917 \u0930\u0902\u0917 \u0915\u0947 \u0938\u094d\u0915\u093e\u0930\u094d\u092b, \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092d\u094d\u0930\u093e\u092e\u0915 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/22/fact-check-this-picture-does-not-show-sunset-on-mars-heres-the-truth/",
        headline:
          "Fact Check: This Picture Does Not Show Sunset On Mars; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/25/fact-check-viral-image-of-sharad-pawar-heres-the-truth/",
        headline:
          "Fact Check: Viral Image Of Sharad Pawar & Anil Deshmukh With Handful Of Cash Is Morphed; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/25/fact-check-astronaut-sculpture-on-church-is-not-from-1600s-heres-the-truth/",
        headline:
          "Fact Check: Astronaut Sculpture On Church Is Not From 1600s; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/27/fact-check-picture-of-sculpture-in-thailand-museum-passed-off-as-that-of-temple-in-varanasi/",
        headline:
          "Fact Check: Picture Of Sculpture In Thailand Museum Passed Off As That Of Temple In Varanasi",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/27/fact-check-old-picture-of-bhagat-singh-wrongly-labelled-as-sukhdevs-shared-as-recent/",
        headline:
          "Fact Check: Old Picture Of Bhagat Singh Wrongly Labelled As Sukhdev\u2019s Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/27/fact-check-old-image-of-oil-tanker-on-fire-shared-as-missile-attack-on-israeli-owned-ship/",
        headline:
          "Fact Check: Old Image Of Oil Tanker On Fire Shared As Missile Attack On Israeli Owned Ship",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/28/fact-check-did-uttaranchal-university-make-students-wear-saffron-scarf-at-convocation-heres-the-truth/",
        headline:
          "Fact Check: Did Uttaranchal University Make Students Wear Saffron Scarf At Convocation? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/telugu-edited-abn-andhrajyothy-post-shared-as-bjp-is-behind-teenmar-mallanna/",
        headline:
          " \u2018ABN \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c1c\u0c4d\u0c2f\u0c4b\u0c24\u0c3f\u2019 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c41\u0c28\u0c3f \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f \u0c24\u0c40\u0c28\u0c4d\u0c2e\u0c3e\u0c30\u0c4d \u0c2e\u0c32\u0c4d\u0c32\u0c28\u0c4d\u0c28 \u0c24\u0c28 \u0c35\u0c46\u0c28\u0c41\u0c15 \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c09\u0c02\u0c26\u0c28\u0c3f \u0c12\u0c2a\u0c4d\u0c2a\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c21\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-2020-videos-now-shared-as-ap-government-imposing-lockdown-in-the-state/",
        headline:
          " 2020 \u0c28\u0c4d\u0c2f\u0c42\u0c38\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c2e\u0c33\u0c4d\u0c33\u0c40 \u0c32\u0c3e\u0c15\u0c4d \u0c21\u0c4c\u0c28\u0c4d \u0c35\u0c3f\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-icici-axis-hdfc-banks-were-not-set-up-as-government-organisations-and-later-privatised/",
        headline:
          " ICICI, AXIS, HDFC \u0c2c\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c41\u0c32\u0c41 1990\u0c32\u0c4d\u0c32\u0c4b \u0c06\u0c30\u0c4d\u0c27\u0c3f\u0c15 \u0c38\u0c02\u0c38\u0c4d\u0c15\u0c30\u0c23\u0c32\u0c41 \u0c1a\u0c47\u0c2a\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c24\u0c30\u0c4d\u0c35\u0c3e\u0c24 \u0c38\u0c4d\u0c25\u0c3e\u0c2a\u0c3f\u0c02\u0c1a\u0c2c\u0c21\u0c4d\u0c21 \u0c2a\u0c4d\u0c30\u0c48\u0c35\u0c47\u0c1f\u0c41 \u0c30\u0c02\u0c17 \u0c2c\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c41\u0c32\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-video-related-to-the-lockdown-imposed-in-anantapur-in-2020-is-being-shared-again/",
        headline:
          " 2020\u0c32\u0c4b \u0c05\u0c28\u0c02\u0c24\u0c2a\u0c42\u0c30\u0c4d \u0c32\u0c4b \u0c35\u0c3f\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c32\u0c3e\u0c15\u0c4d \u0c21\u0c4c\u0c28\u0c4d \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2a\u0c3e\u0c24 \u0c28\u0c4d\u0c2f\u0c42\u0c38\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c2e\u0c33\u0c4d\u0c33\u0c40 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/this-astronaut-sculpture-was-added-to-the-centuries-old-church-during-the-renovation-works-in-the-1990s/",
        headline:
          " This astronaut sculpture was added to the centuries-old church during the renovation works in the 1990s",
      },
      {
        url:
          "https://factly.in/telugu-mid-day-meal-scheme-photo-from-a-school-in-bihar-is-falsely-linked-andhra-pradesh-government/",
        headline:
          " \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c2a\u0c3e\u0c20\u0c36\u0c3e\u0c32\u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28 \u0c2e\u0c27\u0c4d\u0c2f\u0c3e\u0c39\u0c4d\u0c28 \u0c2d\u0c4b\u0c1c\u0c28 \u0c2a\u0c25\u0c15\u0c02 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c1c\u0c17\u0c28\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-video-of-a-couple-exercising-in-a-gym-in-trinidad-tobago-is-shared-as-love-jihad-in-india/",
        headline:
          " \u0c1f\u0c4d\u0c30\u0c3f\u0c28\u0c3f\u0c21\u0c3e\u0c21\u0c4d & \u0c1f\u0c4a\u0c2c\u0c3e\u0c17\u0c4b \u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c35\u0c4d\u0c2f\u0c3e\u0c2f\u0c3e\u0c2e\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2d\u0c3e\u0c30\u0c4d\u0c2f\u0c2d\u0c30\u0c4d\u0c24\u0c32 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c32\u0c35\u0c4d \u0c1c\u0c3f\u0c39\u0c3e\u0c26\u0c4d \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/picture-of-a-sculpture-in-thailand-museum-is-shared-as-that-from-kashiraj-kali-temple-in-varanasi-india/",
        headline:
          " Picture of a Sculpture in Thailand Museum is shared as that from Kashiraj Kali temple in Varanasi, India",
      },
    ],
    "3": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-rajasthan-government-100-crores-one-dargah-ashok-gehlot-hindu-muslim-communal-angle-fact-check-12449",
        headline:
          "\u09ac\u09bf\u099c\u09cd\u099e\u09be\u09aa\u09a8 \u099b\u09a1\u09bc\u09bf\u09df\u09c7 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b0\u09be\u099c\u09b8\u09cd\u09a5\u09be\u09a8 \u09b8\u09b0\u0995\u09be\u09b0 \u098f\u0995 \u09a6\u09b0\u0997\u09be\u09df \u09ac\u09b0\u09be\u09a6\u09cd\u09a6 \u0995\u09b0\u09b2 \u09e7\u09e6\u09e6 \u0995\u09cb\u099f\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/no-lockdown-in-telangana-kcrs-video-dates-back-to-2020-675833",
        headline: "No lockdown in Telangana, KCR's video dates back to 2020",
      },
      {
        url:
          "https://digiteye.in/old-videos-resurface-claiming-lockdown-imposed-in-ap-and-up-fact-check/",
        headline:
          "Old videos resurface claiming lockdown imposed in AP and UP; Fact Check",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/22/fact-check-has-sikkim-govt-announced-covid-19-lockdown-heres-the-truth/",
        headline:
          "Fact Check: Has Sikkim Govt Announced COVID-19 Lockdown? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/22/fact-check-did-haryana-govt-announce-lockdown-due-to-rise-in-covid-19-cases-heres-the-truth/",
        headline:
          "Fact Check: Did Haryana Govt Announce Lockdown Due To Rise In COVID-19 Cases? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/telugu-2020-kcr-press-meet-is-now-shared-as-telangana-government-is-shutting-down-educational-institutions/",
        headline:
          " \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c02\u0c32\u0c4b\u0c28\u0c3f \u0c35\u0c3f\u0c26\u0c4d\u0c2f\u0c3e \u0c38\u0c02\u0c38\u0c4d\u0c25\u0c32\u0c28\u0c3f \u0c2e\u0c42\u0c38\u0c3f\u0c35\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c1f\u0c4d\u0c1f\u0c41 2020 \u0c15\u0c47\u0c38\u0c40\u0c06\u0c30\u0c4d \u0c2a\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c2e\u0c40\u0c1f\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-social-media-post-claiming-odisha-govt-planning-to-sell-over-35000-acres-of-jagannath-temple-land-is-misleading-and-malicious-in-nature/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Fact Check: \u091c\u0917\u0928\u094d\u0928\u093e\u0925 \u092e\u0902\u0926\u093f\u0930 \u0915\u0940 35,000 \u090f\u0915\u0921\u093c \u091c\u092e\u0940\u0928 \u0915\u094b \u092c\u0947\u091a\u0947 \u091c\u093e\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u092d\u094d\u0930\u093e\u092e\u0915",
      },
      {
        url:
          "https://www.vishvasnews.com/health/quick-fact-check-hot-coconut-water-is-not-a-cure-to-all-types-of-cancer-viral-post-is-fake/",
        headline:
          "Quick Fact Check: \u0928\u093e\u0930\u093f\u092f\u0932 \u092a\u093e\u0928\u0940 \u0915\u094b \u0917\u0930\u094d\u092e \u0915\u0930\u0915\u0947 \u092a\u0940\u0928\u0947 \u0938\u0947 \u0915\u0948\u0902\u0938\u0930 \u0920\u0940\u0915 \u0939\u094b\u0928\u0947 \u0915\u093e \u0915\u094b\u0908 \u0935\u0948\u091c\u094d\u091e\u093e\u0928\u093f\u0915 \u092a\u094d\u0930\u092e\u093e\u0923 \u0928\u0939\u0940\u0902 \u0939\u0948, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u092b\u0930\u094d\u091c\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/2019-video-of-tribal-woman-assaulted-in-mp-revived-with-false-claims-12529",
        headline:
          "2019 Video Of Tribal Woman Assaulted In MP Revived With False Claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/screenshot-of-ranjan-gogoi-tweet-on-jamia-professor-abrar-ahmad-viral-caa-fact-check-12538",
        headline: "Tweet From Impostor Ranjan Gogoi Handle Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/covid-19-cure-audio-clip-that-was-falsely-linked-to-medanta-resurfaces-12537",
        headline:
          "COVID-19 Cure Audio Clip That Was Falsely Linked To Medanta Resurfaces",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/coronavirus-outbreak/fake-message-claims-jj-hospital-giving-covid-19-vaccines-to-all-above-45-12459",
        headline:
          "Fake Message Claims JJ Hospital Giving COVID-19 Vaccines To All Above 45",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/gym-love-jihad-fake-claim-couple-viral-video-fitness-video-trinidad-and-tobago-12483",
        headline:
          "'\u091c\u093f\u092e \u091c\u093f\u0939\u093e\u0926' \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932 \u0907\u0938 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://digiteye.in/viral-message-claims-madurai-doctor-died-one-month-after-vaccination-fact-check/",
        headline:
          "Viral message claims Madurai doctor died one month after vaccination; Fact Check",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/no-reliance-please-devotees-trek-mettu-to-reach-lord-venkateswaras-abode-675867",
        headline:
          "Srivari mettu footpath in Tirumala is constructed by Reliance?? NO !!",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-fake-statement-of-suresh-bhaiyyaji-joshi-about-changing-constitution-is-getting-viral/",
        headline:
          "Fact Check \u0938\u0941\u0930\u0947\u0936 \u091c\u094b\u0936\u0940 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e \u0938\u0902\u0935\u093f\u0927\u093e\u0928 \u0915\u094b \u092c\u0926\u0932\u0928\u0947 \u0915\u094b \u0932\u0947\u0915\u0930 \u092f\u0939 \u092c\u092f\u093e\u0928, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-these-photos-of-weapons-recovered-are-old-have-nothing-to-do-with-rss/",
        headline:
          "Fact Check : \u0939\u0925\u093f\u092f\u093e\u0930\u094b\u0902 \u0915\u0940 \u092c\u0930\u093e\u092e\u0926\u0940 \u0915\u0940 \u092f\u0947 \u0924\u0938\u094d\u200d\u0935\u0940\u0930\u0947\u0902 \u092a\u0941\u0930\u093e\u0928\u0940 \u0939\u0948\u0902, \u0907\u0928\u0915\u093e RSS \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948 \u0915\u094b\u0908 \u0938\u0902\u092c\u0902\u0927",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/viral/fact-check-nita-ambani-did-not-give-any-statement-regarding-caa/?itm_source=homepage&itm_medium=dktp_s3&itm_campaign=editorpick",
        headline:
          "Fact Check : \u0a28\u0a40\u0a24\u0a3e \u0a05\u0a70\u0a2c\u0a3e\u0a28\u0a40 \u0a28\u0a47 \u0a28\u0a39\u0a40 \u0a26\u0a3f\u0a71\u0a24\u0a3e \u0a07\u0a39 \u0a2c\u0a3f\u0a06\u0a28, \u0a1d\u0a42\u0a20\u0a40 \u0a39\u0a48 \u0a35\u0a3e\u0a07\u0a30\u0a32 \u0a2a\u0a4b\u0a38\u0a1f",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/viral/fact-check-the-billboard-with-image-of-queen-elizabeth-is-edited/",
        headline:
          "Fact Check: \u0a2c\u0a4d\u0a30\u0a3f\u0a1f\u0a47\u0a28 \u0a26\u0a40 \u0a2e\u0a39\u0a3e\u0a30\u0a3e\u0a23\u0a40 \u0a10\u0a32\u0a3f\u0a1c\u0a3c\u0a3e\u0a2c\u0a47\u0a25 \u0a26\u0a40 \u0a24\u0a38\u0a35\u0a40\u0a30 \u0a35\u0a3e\u0a32\u0a3e \u0a07\u0a39 \u0a2c\u0a3f\u0a71\u0a32 \u0a2c\u0a4b\u0a30\u0a21 \u0a10\u0a21\u0a40\u0a1f\u0a47\u0a21 \u0a39\u0a48",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/22/fact-check-government-is-not-providing-unemployment-allowance-dont-fall-for-false-claims/",
        headline:
          "Fact Check: Government Is Not Providing Unemployment Allowance, Don\u2019t Fall For False Claims",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/22/fact-check-has-government-announced-lockdown-in-delhi-again-heres-the-truth/",
        headline:
          "Fact Check: Has Government Announced Lockdown In Delhi Again? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-no-national-health-mission-employees-did-not-dance-during-covid-19-reforms-meeting/",
        headline:
          "Fact Check: No, National Health Mission Employees Did NOT Dance During COVID-19 Reforms Meeting",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-old-and-unrelated-picture-shared-as-photo-of-labourer-flogged-during-lockdown/",
        headline:
          "Fact Check: Old And Unrelated Picture Shared As Photo Of Labourer Flogged During Lockdown",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-this-video-of-students-falling-after-a-railing-gave-way-is-not-from-guwahati/",
        headline:
          "Fact Check: This Video Of Students Falling After A Railing Gave Way Is NOT From Guwahati",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/27/fact-check-old-video-of-karnataka-cm-announcing-covid-19-restrictions-shared-as-recent/",
        headline:
          "Fact Check: Old Video Of Karnataka CM Announcing COVID-19 Restrictions Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/27/fact-check-a-ten-day-lockdown-has-not-been-announced-in-bhopal-old-video-being-recirculated/",
        headline:
          "Fact Check: A Ten Day Lockdown Has Not Been Announced In Bhopal, Old Video Being Recirculated",
      },
      {
        url:
          "https://factly.in/world-health-organization-who-is-not-giving-210000-to-people-who-have-covid-19/",
        headline:
          " World Health Organization (WHO) is not giving $ 210,000 to people who have COVID-19",
      },
      {
        url:
          "https://factly.in/debunked-photo-revived-again-as-a-photo-of-poor-labourer-beaten-up-during-the-covid-19-lockdown/",
        headline:
          " Debunked photo revived again as a photo of poor labourer beaten up during the COVID-19 lockdown",
      },
      {
        url:
          "https://factly.in/2020-video-is-now-shared-as-yogi-adityanath-announcing-new-lockdown-measures-in-up/",
        headline:
          " 2020 video is now shared as Yogi Adityanath announcing new lockdown measures in UP",
      },
      {
        url:
          "https://factly.in/unrelated-old-photos-revived-again-as-the-seized-weapons-from-rss-hideouts-in-delhi/",
        headline:
          " Unrelated old photos revived again as the weapons seized from RSS hideouts in Delhi",
      },
      {
        url:
          "https://factly.in/telugu-telangana-govt-had-submitted-dpr-to-the-centre-for-development-of-bhadrachalam-as-part-of-the-ramayana-circuit/",
        headline:
          " \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c30\u0c3e\u0c2e\u0c3e\u0c2f\u0c23 \u0c38\u0c30\u0c4d\u0c15\u0c4d\u0c2f\u0c42\u0c1f\u0c4d \u0c32\u0c4b \u0c2d\u0c3e\u0c17\u0c02\u0c17\u0c3e \u0c2d\u0c26\u0c4d\u0c30\u0c3e\u0c1a\u0c32\u0c02 \u0c05\u0c2d\u0c3f\u0c35\u0c43\u0c26\u0c4d\u0c27\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c2a\u0c4d\u0c30\u0c24\u0c3f\u0c2a\u0c3e\u0c26\u0c28 \u0c38\u0c2e\u0c30\u0c4d\u0c2a\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-the-central-government-levies-gst-on-various-services-provided-by-ttd-not-on-the-hundi-income/",
        headline:
          " TTD \u0c05\u0c02\u0c26\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3f\u0c35\u0c3f\u0c27 \u0c38\u0c47\u0c35\u0c32\u0c2a\u0c48 GST \u0c35\u0c38\u0c42\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c3e\u0c30\u0c41, \u0c39\u0c41\u0c02\u0c21\u0c40 \u0c06\u0c26\u0c3e\u0c2f\u0c02\u0c2a\u0c48 \u0c15\u0c3e\u0c26\u0c41",
      },
    ],
    "4": [
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-aditya-thackerays-corona-positive-news-morphed-with-fake-claim/?itm_source=homepage&itm_medium=dktp_s3&itm_campaign=editorpick",
        headline:
          "Fact Check : \u0906\u0926\u093f\u0924\u094d\u200d\u092f \u0920\u093e\u0915\u0930\u0947 \u0915\u0947 \u0915\u094b\u0930\u094b\u0928\u093e \u092a\u0949\u091c\u093f\u091f\u093f\u0935 \u0935\u093e\u0932\u0940 \u0916\u092c\u0930 \u0938\u0947 \u0915\u0940 \u0917\u0908 \u091b\u0947\u0921\u093c\u091b\u093e\u0921\u093c, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u200d\u091f \u092b\u0947\u0915 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-old-images-of-clash-between-bjp-workers-shared-ahead-of-west-bengal-assembly-elections/",
        headline:
          "Fact Check: \u092c\u0940\u091c\u0947\u092a\u0940 \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u0915\u0947 \u092c\u0940\u091a \u091d\u0921\u093c\u092a \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u092a\u0936\u094d\u091a\u093f\u092e \u092c\u0902\u0917\u093e\u0932 \u0935\u093f\u0927\u093e\u0928\u0938\u092d\u093e \u091a\u0941\u0928\u093e\u0935 \u0938\u0947 \u092a\u0939\u0932\u0947 \u0939\u0941\u0908\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/narendra-modi-and-amit-shah-did-not-wear-islamic-skull-caps-viral-image-is-morphed/",
        headline:
          "Fact Check : \u092a. \u092c\u0902\u0917\u093e\u0932 \u091a\u0941\u0928\u093e\u0935 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0908 \u092e\u094b\u0926\u0940-\u0936\u093e\u0939 \u0915\u0940   \u092e\u0949\u0930\u094d\u092b\u094d\u0921 \u0924\u0938\u094d\u200d\u0935\u0940\u0930",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-mamata-banerjee-visiting-shrine-video-goes-viral-with-misleading-claim/",
        headline:
          "Fact Check: \u0935\u093f\u0927\u093e\u0928\u0938\u092d\u093e \u091a\u0941\u0928\u093e\u0935 \u0915\u0947 \u0928\u093e\u092e\u093e\u0902\u0915\u0928 \u0938\u0947 \u092a\u0939\u0932\u0947 \u0926\u0930\u0917\u093e\u0939 \u0917\u0908\u0902 \u092e\u092e\u0924\u093e \u092c\u0928\u0930\u094d\u091c\u0940 \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u092d\u094d\u0930\u093e\u092e\u0915 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/one-year-old-news-clip-viral-name-of-corona-now-in-bihar/",
        headline:
          "Fact Check : \u0915\u094b\u0930\u094b\u0928\u093e \u0938\u0947 \u091c\u0941\u0921\u093c\u0940 \u090f\u0915 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0905\u0916\u092c\u093e\u0930 \u0915\u0940 \u0916\u092c\u0930 \u0915\u094b \u0905\u092c \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-aditya-thackeray-hiv-positive-shiv-sena-uddhav-thackeray-tv9-bharatvarsh-edited-news-graphic-12477",
        headline:
          "TV9 Graphic Claiming Aditya Thackeray Tested HIV Positive Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/2018-photo-of-empty-chairs-from-bjp-event-viral-as-west-bengal-12526",
        headline:
          "2018 Photo Of Empty Chairs From BJP Event Viral As West Bengal",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/mamata-banerjee-secretly-visits-nandigram-dargah-not-really-12492",
        headline:
          "Mamata Banerjee Secretly Visits Nandigram Dargah? Not Really",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/amit-shah-narendra-modi-edited-photo-viral-west-bengal-assembly-election-2021-12494",
        headline:
          "\u092c\u0902\u0917\u093e\u0932 \u091a\u0941\u0928\u093e\u0935 \u0938\u0947 \u092a\u0939\u0932\u0947 \u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0914\u0930 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0915\u0940 \u090f\u0921\u093f\u091f\u0947\u0921 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bjp-workers-coffee-house-kolkata-roopa-ganguly-no-vote-to-bjp-campaign-west-bengal-election-12475",
        headline:
          "2016 \u092e\u0947\u0902 \u092c\u0940\u091c\u0947\u092a\u0940 \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u0915\u0940 \u0906\u092a\u0938\u0940 \u091d\u095c\u092a \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/mamata-banerjee-secretly-visits-nandigram-dargah-not-really-12512",
        headline:
          "\u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df \u0995\u09c0 \u0997\u09cb\u09aa\u09a8\u09c7 \u09a8\u09a8\u09cd\u09a6\u09c0\u0997\u09cd\u09b0\u09be\u09ae\u09c7\u09b0 \u09a6\u09b0\u0997\u09be\u09df \u0997\u09bf\u09df\u09c7\u099b\u09bf\u09b2\u09c7\u09a8? \u09a8\u09be \u09a0\u09bf\u0995 \u09a8\u09df",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-aaditya-thackeray-has-not-tested-positive-for-hivaids-675918",
        headline:
          "Fact Check: Aaditya Thackeray has not tested positive for HIV/AIDS",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-morphed-image-shows-modi-shah-wearing-skull-caps-ahead-of-bengal-polls-675932",
        headline:
          "Fact Check: Morphed image shows Modi, Shah wearing skull caps ahead of Bengal polls",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-amazon-not-offering-free-phone-on-its-30th-anniversary-viral-link-is-fake-675914",
        headline:
          "Fact Check: Amazon not offering free phone on its 30th anniversary, viral link is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-images-of-empty-chairs-at-bjp-rally-not-related-to-ongoing-poll-campaigns-676062",
        headline:
          "Fact Check: Images of empty chairs at BJP rally not related to ongoing poll campaigns",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-66-evms-not-seized-from-bengal-bjp-leaders-house-676066",
        headline:
          "Fact Check: No, 66 EVMs not seized from Bengal BJP leader's house",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-fake-news-graphics-plate-getting-viral-on-up-panchayat-election-date-and-reservation-list/",
        headline:
          "Fact Check: \u092f\u0942\u092a\u0940 \u092a\u0902\u091a\u093e\u092f\u0924 \u091a\u0941\u0928\u093e\u0935 \u0915\u0940 \u0924\u093e\u0930\u0940\u0916 \u0914\u0930 \u0906\u0930\u0915\u094d\u0937\u0923 \u0938\u0942\u091a\u0940 \u0915\u094b \u0932\u0947\u0915\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0940 \u092b\u0930\u094d\u091c\u0940 \u0917\u094d\u0930\u093e\u092b\u093f\u0915\u094d\u0938 \u092a\u094d\u0932\u0947\u091f",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-bjp-did-not-announce-in-its-manifesto-to-change-tamil-nadus-name-as-dakshina-or-nitchala-pradesh/",
        headline:
          "Fact Check: \u092d\u093e\u091c\u092a\u093e \u0928\u0947 \u0905\u092a\u0928\u0947 \u092e\u0947\u0928\u093f\u092b\u0947\u0938\u094d\u091f\u094b \u092e\u0947\u0902 \u0928\u0939\u0940\u0902 \u0915\u0939\u0940 \u0939\u0948 \u0924\u092e\u093f\u0932\u0928\u093e\u0921\u0941 \u0915\u093e \u0928\u093e\u092e \u092c\u0926\u0932\u0928\u0947 \u0915\u0940 \u092c\u093e\u0924, \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u093e \u092b\u0930\u094d\u091c\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-bjp-did-not-announce-in-its-manifesto-to-change-tamil-nadus-name-as-dakshina-or-nitchala-pradesh-viral-claim-is-fake/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Fact Check: BJP did not announce in it\u2019s manifesto to change Tamil Nadu\u2019s name as Dakshina or Nitchala Pradesh, viral claim is fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/22/fact-check-old-pictures-of-scuffle-between-bjp-workers-shared-with-misleading-claim/",
        headline:
          "Fact Check: Old Pictures Of Scuffle Between BJP Workers Shared With Misleading Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-ahead-of-elections-old-video-from-west-bengal-shared-with-misleading-claim/",
        headline:
          "Fact Check: Ahead of Elections, Old Video From West Bengal Shared With Misleading Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-no-pm-modi-and-amit-shah-were-not-spotted-wearing-skullcap-ahead-of-west-bengal-elections/",
        headline:
          "Fact Check: No! PM Modi and Amit Shah Were NOT Spotted Wearing Skullcap Ahead Of West Bengal Elections",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/25/fact-check-ahead-of-polls-video-from-assam-falsely-shared-as-that-from-west-bengal/",
        headline:
          "Fact Check: Ahead Of Polls, Video From Assam Falsely Shared As That From West Bengal",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/25/fact-check-old-video-of-delhi-cm-arvind-kejriwal-shared-as-him-supporting-farm-laws/",
        headline:
          "Fact Check: Old Video Of Delhi CM Arvind Kejriwal Shared As Him Supporting Farm Laws",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/25/fact-check-did-bjp-leader-promise-quality-beef-in-kerala-if-he-wins-the-elections-heres-the-truth/",
        headline:
          "Fact Check: Did BJP Leader Promise \u2018Quality Beef\u2019 In Kerala If He Wins The Elections? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/25/fact-check-does-bjp-manifesto-mention-tamil-nadus-name-will-be-changed-to-dakshina-pradesh/",
        headline:
          "Fact Check: Does BJP Manifesto Mention Tamil Nadu\u2019s Name Will Be Changed To \u2018Dakshina Pradesh\u2019?",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/26/fact-check-did-west-bengal-cm-mamata-banerjee-visit-a-dargah-secretly-at-night-heres-the-truth/",
        headline:
          "Fact Check: Did West Bengal CM Mamata Banerjee Visit A Dargah Secretly At Night? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/27/fact-check-amit-shah-yogi-adityanaths-photo-from-2018-resurfaces-again-this-election-season/",
        headline:
          "Fact Check: Amit Shah, Yogi Adityanath\u2019s Photo From 2018 Resurfaces Again This Election Season",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/28/fact-check-old-newspaper-report-on-fortunes-worlds-50-greatest-leaders-shared-as-recent/",
        headline:
          "Fact Check: Old Newspaper Report On Fortune\u2019s \u2018World\u2019s 50 Greatest Leaders\u2019 Shared As Recent",
      },
      {
        url:
          "https://factly.in/telugu-the-west-bengal-bjp-manifesto-did-not-mention-financial-assistance-of-%e2%82%b9-10000-to-the-rohingyas/",
        headline:
          " \u0c2a\u0c36\u0c4d\u0c1a\u0c3f\u0c2e \u0c2c\u0c46\u0c02\u0c17\u0c3e\u0c32\u0c4d BJP \u0c2e\u0c47\u0c28\u0c3f\u0c2b\u0c46\u0c38\u0c4d\u0c1f\u0c4b\u0c32\u0c4b \u0c30\u0c4b\u0c39\u0c3f\u0c02\u0c17\u0c4d\u0c2f\u0c3e\u0c32\u0c15\u0c3f \u20b910,000\u0c32 \u0c06\u0c30\u0c4d\u0c27\u0c3f\u0c15 \u0c38\u0c39\u0c3e\u0c2f\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c3e\u0c2e\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/fortunes-the-worlds-50-greatest-leaders-modi-in-2015-and-kejriwal-in-2016-got-ranks/",
        headline:
          " Fortune\u2019s \u2018The World\u2019s 50 Greatest Leaders\u2019: Modi (in 2015) and Kejriwal (in 2016) were part of this list",
      },
      {
        url:
          "https://factly.in/mamata-banerjees-visit-to-a-dargah-in-nandigram-was-not-a-secret/",
        headline:
          " Mamata Banerjee\u2019s visit to a dargah in Nandigram was not a secret",
      },
      {
        url:
          "https://factly.in/visuals-of-empty-chairs-at-a-program-held-by-bjp-in-2018-is-falsely-linked-to-west-bengal-election-rally/",
        headline:
          " Visuals of empty chairs at a program held by BJP in 2018 are falsely linked to West Bengal election rally",
      },
    ],
    "5": [
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-is-not-recent-kolkata-coffee-house-incident-but-a-clash-among-bjp-workers-in-2016-675840",
        headline:
          "Fact Check: Viral image is not recent Kolkata coffee house incident, but from 2016",
      },
      {
        url:
          "https://factly.in/bihar-education-department-has-not-issued-this-press-release-ordering-the-complete-shutdown-of-schools/",
        headline:
          " Bihar Education Department has not issued this press release ordering complete shutdown of schools",
      },
      {
        url:
          "https://factly.in/old-video-of-a-man-urinating-in-a-temple-revived-with-a-false-communal-narrative/",
        headline:
          " Old video of a man urinating in a temple revived with a false communal narrative",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-did-rahul-gandhi-say-that-congress-did-nothing-in-70-years-clipped-video-getting-viral/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0902\u0927\u0940 \u0924\u0902\u091c\u093e\u0924\u094d\u092e\u0915 \u0932\u0939\u091c\u0947 \u092e\u0947\u0902 \u0915\u0939 \u0930\u0939\u0947 \u0925\u0947 70 \u0938\u093e\u0932\u094b \u092e\u0947\u0902 \u0915\u0941\u091b \u0928\u0939\u0940\u0902 \u0939\u094b\u0928\u0947 \u0915\u0940 \u092c\u093e\u0924, \u0915\u094d\u0932\u093f\u092a\u094d\u0921 \u0935\u0940\u0921\u093f\u092f\u094b \u0917\u0932\u0924 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-in-the-viral-photo-nirmala-sitharaman-is-not-with-her-daughter-but-a-female-officer/",
        headline:
          "Fact Check : \u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u200d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0928\u093f\u0930\u094d\u092e\u0932\u093e \u0938\u0940\u0924\u093e\u0930\u092e\u0923 \u0915\u0947 \u0938\u093e\u0925 \u0909\u0928\u0915\u0940 \u092c\u0947\u091f\u0940 \u0928\u0939\u0940\u0902, \u092c\u0932\u094d\u0915\u093f \u090f\u0915 \u092e\u0939\u093f\u0932\u093e \u0911\u092b\u093f\u0938\u0930 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-flight-simulation-clip-boeing-747-carrying-antonov-an-225-cargo-plane-take-off-factcheck-12460",
        headline:
          "Flight Simulation Clip Shared As Boeing 747 Launched From A Cargo Plane",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/dmk-congress-rahul-gandhi-mk-stalin-fake-claim-tamil-nadu-assembly-elections-2021-12511",
        headline: "          ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-rahul-gandhi-interaction-with-college-students-assam-elections-unemployment-rate-bjp-congress-12507",
        headline:
          "\u0985\u09b8\u09ae\u09c7 \u09b0\u09be\u09b9\u09c1\u09b2 \u0997\u09be\u0981\u09a7\u09c0\u09b0 \u09a6\u09c7\u0993\u09df\u09be \u09ad\u09be\u09b7\u09a3\u09c7\u09b0 \u099b\u09be\u0981\u099f\u09be\u0987 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/2019-image-of-uttar-pradesh-minor-injured-shared-as-police-crackdown-during-pm-narendra-modis-visit-in-bangladesh-12534",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u0986\u09b9\u09a4 \u09ac\u09be\u09b2\u0995\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u09ae\u09be\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://digiteye.in/no-saayoni-ghosh-was-not-running-during-campaign-trail-fact-check/",
        headline:
          "No, Saayoni Ghosh was not running away from party workers in campaign; Fact Check",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-1000-yr-old-shiva-temple-in-paks-sialkot-reopened-in-2019-not-2020-676050",
        headline:
          "Fact Check: 1,000-yr-old Shiva temple in Pak's Sialkot reopened in 2019, not 2020",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-viral-post-claiming-rakesh-tikait-got-beaten-up-in-odisha-is-misleading/",
        headline:
          "Fact Check: \u0915\u093f\u0938\u093e\u0928 \u0928\u0947\u0924\u093e \u0930\u093e\u0915\u0947\u0936 \u091f\u093f\u0915\u0948\u0924 \u0915\u0947 \u0938\u093e\u0925 \u0913\u0921\u093f\u0936\u093e \u092e\u0947\u0902 \u092e\u093e\u0930\u092a\u0940\u091f \u0915\u093e \u0926\u093e\u0935\u093e \u0915\u0930\u0924\u093e \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0939\u0948 \u092d\u094d\u0930\u093e\u092e\u0915",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-schools-are-not-getting-closed-till-june-15-in-bihar-viral-letter-is-fake/",
        headline:
          "Fact Check: \u092c\u093f\u0939\u093e\u0930 \u092e\u0947\u0902 15 \u091c\u0942\u0928 \u0924\u0915 \u0938\u094d\u0915\u0942\u0932 \u092c\u0902\u0926 \u0915\u093f\u090f \u091c\u093e\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u0915\u0930\u0924\u093e \u092f\u0947 \u092a\u0924\u094d\u0930 \u092b\u0930\u094d\u091c\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/mp-home-minister-narottam-mishra-did-not-announce-10-day-lockdown-in-bhopal-recently-old-video-is-going-viral/",
        headline:
          "Fact Check : \u092e\u0927\u094d\u200d\u092f \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0947 \u0917\u0943\u0939\u092e\u0902\u0924\u094d\u0930\u0940 \u0915\u0947 \u092a\u093f\u091b\u0932\u0947 \u0938\u093e\u0932 \u0915\u0947 \u0932\u0949\u0915\u0921\u093e\u0909\u0928 \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0905\u092c \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-photograph-of-wolf-mountain-is-edited/",
        headline:
          "Fact Check: \u092d\u0947\u0921\u093c\u093f\u092f\u093e \u091c\u0948\u0938\u0940 \u0926\u093f\u0916\u0928\u0947 \u0935\u093e\u0932\u0940 \u092f\u0939 \u092a\u0939\u093e\u0921\u093c\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948 \u0905\u0938\u0932\u0940, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0939\u0948 \u092b\u0930\u094d\u091c\u0940",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-old-video-of-beating-of-temple-priest-is-being-viral-with-false-claim/",
        headline:
          "Fact Check: \u092e\u0902\u0926\u093f\u0930 \u0915\u0947 \u092a\u0941\u091c\u093e\u0930\u0940 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0915\u0947 \u092a\u0941\u0930\u093e\u0928\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0917\u0932\u0924 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/viral/fact-check-photograph-of-wolf-mountain-is-edited/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0a2d\u0a47\u0a5c\u0a40\u0a0f \u0a35\u0a30\u0a17\u0a40 \u0a26\u0a3f\u0a71\u0a16\u0a23 \u0a35\u0a3e\u0a32\u0a40 \u0a07\u0a39 \u0a2a\u0a39\u0a3e\u0a5c\u0a40 \u0a28\u0a39\u0a40\u0a02 \u0a39\u0a48 \u0a05\u0a38\u0a32\u0a40, \u0a35\u0a3e\u0a07\u0a30\u0a32 \u0a2a\u0a4b\u0a38\u0a1f \u0a39\u0a48 \u0a2b\u0a30\u0a1c\u0a3c\u0a40",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/viral/fact-check-viral-post-about-farmer-leader-rakesh-tikait-is-fake/",
        headline:
          "Fact Check: \u0a15\u0a3f\u0a38\u0a3e\u0a28 \u0a28\u0a47\u0a24\u0a3e \u0a30\u0a3e\u0a15\u0a47\u0a36 \u0a1f\u0a3f\u0a15\u0a48\u0a24 \u0a26\u0a47 \u0a28\u0a3e\u0a32 \u0a09\u0a5c\u0a40\u0a38\u0a3e \u0a35\u0a3f\u0a71\u0a1a \u0a39\u0a4b\u0a08 \u0a2e\u0a3e\u0a30\u0a2a\u0a40\u0a1f \u0a26\u0a3e \u0a26\u0a3e\u0a05\u0a35\u0a3e \u0a15\u0a30\u0a26\u0a3e \u0a39\u0a4b\u0a07\u0a06 \u0a2a\u0a4b\u0a38\u0a1f  \u0a2d\u0a4d\u0a30\u0a2e\u0a15 \u0a39\u0a48",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/22/fact-check-did-a-plane-land-on-a-truck-due-to-problem-in-front-wheel-heres-the-truth/",
        headline:
          "Fact Check: Did A Plane Land On A Truck Due To Problem In Front Wheel? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-no-bihar-education-department-has-not-ordered-closure-of-schools-till-june-15/",
        headline:
          "Fact Check: No! Bihar Education Department Has Not Ordered Closure Of Schools Till June 15",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/24/fact-check-no-the-president-has-not-ordered-for-bhagavad-gita-ramayana-to-be-added-to-school-curriculum/",
        headline:
          "Fact Check: No, The President Has NOT Ordered For Bhagavad Gita & Ramayana To Be Added To School Curriculum",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/26/fact-check-pictures-of-dmk-rally-shared-as-rahul-gandhis-welcome-in-tamil-nadu/",
        headline:
          "Fact Check: Pictures Of DMK Rally Shared As Rahul Gandhi\u2019s Welcome In Tamil Nadu",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/27/fact-check-tmc-candidate-saayoni-ghosh-was-not-running-because-her-party-members-were-harassing-her/",
        headline:
          "Fact Check: TMC Candidate Saayoni Ghosh Was Not Running Because Her Party Members Were Harassing Her",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/28/fact-check-digitally-altered-art-falsely-shared-as-wolf-mountain-in-norway-by-netizens/",
        headline:
          "Fact Check: Digitally Altered Art Falsely Shared As Wolf Mountain In Norway By Netizens",
      },
      {
        url:
          "https://factly.in/an-edited-photo-created-by-a-digital-artist-falsely-shared-as-a-photo-of-wolf-mountain-in-norway/",
        headline:
          " An edited photo created by a digital artist is falsely shared as a photo of \u2018Wolf Mountain\u2019 in Norway",
      },
      {
        url:
          "https://factly.in/the-person-in-these-election-rally-photos-is-m-k-stalin-dmk-not-rahul-gandhi-congress/",
        headline:
          " The person in these election rally photos is M. K. Stalin (DMK), not Rahul Gandhi (Congress)",
      },
      {
        url:
          "https://factly.in/telugu-the-person-in-these-election-rally-photos-is-m-k-stalin-dmk-not-rahul-gandhi-congress/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c41 \u0c38\u0c4d\u0c35\u0c3e\u0c17\u0c24\u0c02 \u0c2a\u0c32\u0c41\u0c15\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f DMK \u0c05\u0c27\u0c3f\u0c28\u0c47\u0c24 M.K. \u0c38\u0c4d\u0c1f\u0c3e\u0c32\u0c3f\u0c28\u0c4d \u0c15\u0c3f, \u0c30\u0c3e\u0c39\u0c41\u0c32\u0c4d \u0c17\u0c3e\u0c02\u0c27\u0c40\u0c15\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/simulation-video-of-antonov-an-225-carrying-boeing-747-shared-as-a-real-incident/",
        headline:
          " Simulation video of \u2018Antonov An-225 carrying Boeing 747\u2019 shared as a real incident",
      },
    ],
  },
  numpy_seed: 12,
  number_of_articles: 151,
}
