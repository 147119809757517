export const data = {
  mdsDat: {
    x: [
      -0.03940990608562956,
      0.06855255409809922,
      -0.1913213637530756,
      -0.2535143608265007,
      0.26359966891452014,
      0.24984252201300375,
      -0.13109621733007645,
      0.03334710296965894,
    ],
    y: [
      -0.13399184544668,
      0.2545386155434686,
      0.05204908783004629,
      0.16035750120414513,
      0.08732376096283842,
      -0.15671012307604326,
      -0.32111178014817465,
      0.05754478313039941,
    ],
    topics: [1, 2, 3, 4, 5, 6, 7, 8],
    cluster: [1, 1, 1, 1, 1, 1, 1, 1],
    Freq: [
      15.70721816493095,
      11.988382463588518,
      16.726338796585438,
      8.455824768722293,
      10.069793422485876,
      10.374478386416584,
      8.813131155952062,
      17.864832841318275,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
    ],
    Freq: [
      17.0,
      14.0,
      11.0,
      10.0,
      15.0,
      9.0,
      13.0,
      12.0,
      14.0,
      11.0,
      6.999903789837168,
      0.999986255691024,
      0.999986255691024,
      1.999972511382048,
      0.999986255691024,
      1.999972511382048,
      1.999972511382048,
      0.999986255691024,
      0.999986255691024,
      0.999986255691024,
      14.99979383536536,
      5.999917534146144,
      4.99993127845512,
      4.99993127845512,
      4.99993127845512,
      4.99993127845512,
      4.99993127845512,
      3.999945022764096,
      3.999945022764096,
      3.999945022764096,
      8.999876301219217,
      9.99986255691024,
      5.999917534146144,
      5.999917534146144,
      0.9998310974632827,
      0.9998310974632827,
      1.9996621949265654,
      0.9998310974632827,
      0.9998310974632827,
      2.999493292389848,
      0.9998310974632827,
      1.9996621949265654,
      1.9996621949265654,
      1.9996621949265654,
      11.997973169559392,
      10.99814207209611,
      9.998310974632828,
      6.998817682242979,
      5.998986584779696,
      5.998986584779696,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      14.997466461949239,
      3.9993243898531308,
      3.9993243898531308,
      8.999849391195005,
      0.999983265688334,
      0.999983265688334,
      0.999983265688334,
      2.999949797065002,
      1.999966531376668,
      1.999966531376668,
      4.999916328441669,
      0.999983265688334,
      0.999983265688334,
      7.999866125506672,
      7.999866125506672,
      5.999899594130004,
      4.999916328441669,
      4.999916328441669,
      3.999933062753336,
      3.999933062753336,
      3.999933062753336,
      3.999933062753336,
      11.999799188260008,
      7.999866125506672,
      6.999882859818338,
      6.999882859818338,
      8.999849391195005,
      5.999899594130004,
      5.999899594130004,
      2.979785010048335,
      2.979785010048335,
      2.979785010048335,
      2.979785010048335,
      1.9865233400322235,
      0.9932616700161118,
      0.9932616700161118,
      1.9865233400322235,
      0.9932616700161118,
      3.973046680064447,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      3.973046680064447,
      3.973046680064447,
      3.973046680064447,
      1.9865233400322235,
      3.973046680064447,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      1.9995732653221956,
      1.9995732653221956,
      5.998719795966587,
      5.998719795966587,
      3.999146530644391,
      3.999146530644391,
      2.9993598979832936,
      2.9993598979832936,
      2.9993598979832936,
      1.9995732653221956,
      1.9995732653221956,
      1.9995732653221956,
      1.9995732653221956,
      1.9995732653221956,
      6.998506428627685,
      1.9995732653221956,
      1.9995732653221956,
      3.999146530644391,
      1.9995732653221956,
      6.998506428627685,
      5.998719795966587,
      2.9993598979832936,
      1.0002676270834028,
      3.000802881250208,
      1.0002676270834028,
      5.001338135417014,
      1.0002676270834028,
      3.000802881250208,
      1.0002676270834028,
      1.0002676270834028,
      5.001338135417014,
      1.0002676270834028,
      8.002141016667222,
      6.001605762500416,
      6.001605762500416,
      5.001338135417014,
      5.001338135417014,
      4.001070508333611,
      3.000802881250208,
      8.002141016667222,
      9.002408643750625,
      10.002676270834028,
      7.001873389583819,
      2.000041192942591,
      2.000041192942591,
      2.000041192942591,
      1.0000205964712956,
      1.0000205964712956,
      2.000041192942591,
      2.000041192942591,
      2.000041192942591,
      1.0000205964712956,
      2.000041192942591,
      4.000082385885182,
      4.000082385885182,
      4.000082385885182,
      4.000082385885182,
      3.000061789413887,
      3.000061789413887,
      3.000061789413887,
      3.000061789413887,
      3.000061789413887,
      2.000041192942591,
      7.000144175299069,
      3.000061789413887,
      3.009953654476656,
      12.039814617906623,
      3.009953654476656,
      3.009953654476656,
      3.009953654476656,
      3.009953654476656,
      3.009953654476656,
      2.006635769651104,
      1.003317884825552,
      1.003317884825552,
      11.036496733081071,
      11.036496733081071,
      9.029860963429968,
      5.016589424127759,
      5.016589424127759,
      5.016589424127759,
      5.016589424127759,
      5.016589424127759,
      4.013271539302208,
      4.013271539302208,
      4.013271539302208,
      14.046450387557726,
      11.036496733081071,
      6.019907308953312,
    ],
    Term: [
      "modi",
      "muslim",
      "yoga",
      "pm",
      "chennai",
      "pm_modi",
      "delhi",
      "nivar",
      "maradona",
      "diwali",
      "water",
      "boy",
      "nirvana",
      "celebrate",
      "pray",
      "mandap",
      "item",
      "chalo",
      "youth",
      "banned_marriage",
      "muslim",
      "non",
      "patra_daughter",
      "cannon",
      "russia",
      "patra",
      "marriage",
      "claims_sambit",
      "non_muslim",
      "sambit",
      "man",
      "farmer",
      "muslims",
      "ban",
      "monthly",
      "announce",
      "munger",
      "demand",
      "pension",
      "perform",
      "candidature",
      "word",
      "apologising",
      "change",
      "yoga",
      "pm",
      "pm_modi",
      "iyengar",
      "bks",
      "bks_iyengar",
      "george_floyd",
      "narendra",
      "joe_biden",
      "narendra_modi",
      "george",
      "floyd",
      "modi",
      "joe",
      "biden",
      "congress",
      "fail",
      "draw",
      "fb",
      "instead",
      "decide",
      "failed",
      "missile",
      "khan",
      "legendary",
      "congress_leader",
      "madonna",
      "procession",
      "funeral_procession",
      "trump",
      "rhyme",
      "brahmos",
      "donald",
      "donald_trump",
      "leader",
      "funeral",
      "patel",
      "ahmed",
      "maradona",
      "ahmed_patel",
      "show",
      "goa",
      "adani",
      "chhattisgarh",
      "operation",
      "shut",
      "mohan",
      "soldier",
      "sitharaman",
      "bhagwat",
      "end",
      "mha",
      "msn",
      "follow",
      "recount",
      "prez",
      "cong",
      "veteran",
      "jameel",
      "shaikh",
      "order",
      "graphic",
      "year",
      "nirmala",
      "school",
      "bpcl",
      "emmanuel",
      "egg",
      "air",
      "hindus",
      "low",
      "cash",
      "double",
      "quality",
      "crore",
      "handle",
      "debts",
      "mangalore",
      "incoming",
      "maradona_grave",
      "grave",
      "pele",
      "morph",
      "house",
      "prime_minister",
      "prime",
      "put",
      "naked",
      "upset",
      "far",
      "wrong",
      "edit",
      "day",
      "explosion",
      "minister",
      "shlokas",
      "show",
      "maradona",
      "modi",
      "gair",
      "masks_delhi",
      "netizens",
      "cracker",
      "navy",
      "ujjain_police",
      "dhawan",
      "international",
      "cop",
      "name_delhi",
      "ashok",
      "gehlot",
      "ashok_gehlot",
      "ujjain",
      "people_without",
      "celebration",
      "diwali_celebration",
      "people",
      "diwali",
      "delhi",
      "mask",
      "overtime",
      "employee",
      "plan",
      "velugu",
      "conduct",
      "elderly",
      "actual",
      "dadi",
      "actor",
      "movement",
      "savarkar",
      "bagh",
      "shaheen",
      "shaheen_bagh",
      "veer_savarkar",
      "list",
      "footage",
      "veer",
      "remove",
      "jalil",
      "woman",
      "centre",
      "pak",
      "nivar",
      "alliance",
      "suicide",
      "tornado",
      "accident_ukraine",
      "ukraine",
      "partying",
      "social",
      "fun",
      "volkswagen",
      "cyclone_nivar",
      "spoof",
      "terrorist",
      "west_bengal",
      "bengal",
      "accident",
      "west",
      "billboard",
      "ad",
      "terrorist_commercial",
      "chennai",
      "cyclone",
      "bjp",
    ],
    Total: [
      17.0,
      14.0,
      11.0,
      10.0,
      15.0,
      9.0,
      13.0,
      12.0,
      14.0,
      11.0,
      6.999903789837168,
      0.999986255691024,
      0.999986255691024,
      1.999972511382048,
      0.999986255691024,
      1.999972511382048,
      1.999972511382048,
      0.999986255691024,
      0.999986255691024,
      0.999986255691024,
      14.99979383536536,
      5.999917534146144,
      4.99993127845512,
      4.99993127845512,
      4.99993127845512,
      4.99993127845512,
      4.99993127845512,
      3.999945022764096,
      3.999945022764096,
      3.999945022764096,
      9.999896897690512,
      14.999853546917834,
      8.006553303797247,
      9.000720415396351,
      0.9998310974632827,
      0.9998310974632827,
      1.9996621949265654,
      0.9998310974632827,
      0.9998310974632827,
      2.999493292389848,
      0.9998310974632827,
      1.9996621949265654,
      1.9996621949265654,
      1.9996621949265654,
      11.997973169559392,
      10.99814207209611,
      9.998310974632828,
      6.998817682242979,
      5.998986584779696,
      5.998986584779696,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      3.9993243898531308,
      17.996826359932534,
      6.005960159504235,
      9.005909956569237,
      8.999849391195005,
      0.999983265688334,
      0.999983265688334,
      0.999983265688334,
      2.999949797065002,
      1.999966531376668,
      1.999966531376668,
      4.999916328441669,
      0.999983265688334,
      0.999983265688334,
      7.999866125506672,
      7.999866125506672,
      5.999899594130004,
      4.999916328441669,
      4.999916328441669,
      3.999933062753336,
      3.999933062753336,
      3.999933062753336,
      3.999933062753336,
      13.98632252829223,
      9.986389465538895,
      8.986406199850562,
      8.986406199850562,
      14.998569187161593,
      7.986422934162228,
      17.00209025921333,
      2.979785010048335,
      2.979785010048335,
      2.979785010048335,
      2.979785010048335,
      1.9865233400322235,
      0.9932616700161118,
      0.9932616700161118,
      1.9865233400322235,
      0.9932616700161118,
      3.973046680064447,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      6.972805824107593,
      6.973005447137519,
      6.983000334541103,
      1.9865233400322235,
      7.972985722823163,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      1.9865233400322235,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      0.9997866326610978,
      1.9995732653221956,
      1.9995732653221956,
      5.998719795966587,
      5.998719795966587,
      3.999146530644391,
      3.999146530644391,
      2.9993598979832936,
      2.9993598979832936,
      2.9993598979832936,
      1.9995732653221956,
      1.9995732653221956,
      1.9995732653221956,
      1.9995732653221956,
      1.9995732653221956,
      7.998492684318709,
      1.9995732653221956,
      1.9995732653221956,
      4.998977628107674,
      1.9995732653221956,
      17.00209025921333,
      14.998569187161593,
      17.996826359932534,
      1.0002676270834028,
      3.000802881250208,
      1.0002676270834028,
      5.001338135417014,
      1.0002676270834028,
      3.000802881250208,
      1.0002676270834028,
      1.0002676270834028,
      5.001338135417014,
      1.0002676270834028,
      8.002141016667222,
      6.001605762500416,
      6.001605762500416,
      5.001338135417014,
      5.001338135417014,
      4.001070508333611,
      3.000802881250208,
      9.00192764932832,
      11.002381155132673,
      13.002235791847248,
      9.008509159234922,
      2.000041192942591,
      2.000041192942591,
      2.000041192942591,
      1.0000205964712956,
      1.0000205964712956,
      2.000041192942591,
      2.000041192942591,
      2.000041192942591,
      1.0000205964712956,
      2.000041192942591,
      4.000082385885182,
      4.000082385885182,
      4.000082385885182,
      4.000082385885182,
      3.000061789413887,
      3.000061789413887,
      3.000061789413887,
      3.000061789413887,
      3.000061789413887,
      2.000041192942591,
      9.000116686681118,
      5.0000283207905545,
      3.009953654476656,
      12.039814617906623,
      3.009953654476656,
      3.009953654476656,
      3.009953654476656,
      3.009953654476656,
      3.009953654476656,
      2.006635769651104,
      1.003317884825552,
      1.003317884825552,
      11.036496733081071,
      11.036496733081071,
      9.029860963429968,
      5.016589424127759,
      5.016589424127759,
      5.016589424127759,
      5.016589424127759,
      5.016589424127759,
      4.013271539302208,
      4.013271539302208,
      4.013271539302208,
      15.039712057573839,
      12.036482988772095,
      8.019680197305433,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.851,
      1.7457,
      1.4456,
      1.5625,
      1.4455,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      2.1212,
      1.9389,
      1.7146,
      1.3095,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.7882,
      1.635,
      1.5664,
      1.5384,
      1.5384,
      1.2774,
      1.5022,
      0.7466,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      1.9078,
      1.9078,
      1.9064,
      2.4703,
      1.7738,
      2.4703,
      2.4703,
      2.4703,
      2.4703,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.2956,
      2.1621,
      2.2956,
      2.2956,
      2.0725,
      2.2956,
      1.408,
      1.3792,
      0.5038,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.2658,
      2.1481,
      2.0652,
      2.0036,
      2.0138,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.4289,
      2.1776,
      1.9181,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.7223,
      1.654,
      1.6356,
      1.4355,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.6224,
      -5.5683,
      -5.5683,
      -4.8752,
      -5.5683,
      -4.8752,
      -4.8752,
      -5.5683,
      -5.5683,
      -5.5683,
      -2.8603,
      -3.7766,
      -3.9589,
      -3.9589,
      -3.9589,
      -3.9589,
      -3.9589,
      -4.1821,
      -4.1821,
      -4.1821,
      -3.3711,
      -3.2658,
      -3.7766,
      -3.7766,
      -5.2983,
      -5.2983,
      -4.6052,
      -5.2983,
      -5.2983,
      -4.1997,
      -5.2983,
      -4.6052,
      -4.6052,
      -4.6052,
      -2.8134,
      -2.9004,
      -2.9957,
      -3.3524,
      -3.5066,
      -3.5066,
      -3.912,
      -3.912,
      -3.912,
      -3.912,
      -3.912,
      -3.912,
      -2.5903,
      -3.912,
      -3.912,
      -3.434,
      -5.6312,
      -5.6312,
      -5.6312,
      -4.5326,
      -4.9381,
      -4.9381,
      -4.0218,
      -5.6312,
      -5.6312,
      -3.5518,
      -3.5518,
      -3.8395,
      -4.0218,
      -4.0218,
      -4.2449,
      -4.2449,
      -4.2449,
      -4.2449,
      -3.1463,
      -3.5518,
      -3.6853,
      -3.6853,
      -3.434,
      -3.8395,
      -3.8395,
      -3.8572,
      -3.8572,
      -3.8572,
      -3.8572,
      -4.2627,
      -4.9558,
      -4.9558,
      -4.2627,
      -4.9558,
      -3.5695,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -3.5695,
      -3.5695,
      -3.5695,
      -4.2627,
      -3.5695,
      -4.2627,
      -4.2627,
      -4.2627,
      -4.2627,
      -5.124,
      -5.124,
      -5.124,
      -5.124,
      -5.124,
      -5.124,
      -5.124,
      -5.124,
      -4.4308,
      -4.4308,
      -3.3322,
      -3.3322,
      -3.7377,
      -3.7377,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.4308,
      -4.4308,
      -4.4308,
      -4.4308,
      -4.4308,
      -3.1781,
      -4.4308,
      -4.4308,
      -3.7377,
      -4.4308,
      -3.1781,
      -3.3322,
      -4.0254,
      -5.1533,
      -4.0547,
      -5.1533,
      -3.5439,
      -5.1533,
      -4.0547,
      -5.1533,
      -5.1533,
      -3.5439,
      -5.1533,
      -3.0739,
      -3.3615,
      -3.3615,
      -3.5439,
      -3.5439,
      -3.767,
      -4.0547,
      -3.0739,
      -2.9561,
      -2.8507,
      -3.2074,
      -4.2973,
      -4.2973,
      -4.2973,
      -4.9904,
      -4.9904,
      -4.2973,
      -4.2973,
      -4.2973,
      -4.9904,
      -4.2973,
      -3.6041,
      -3.6041,
      -3.6041,
      -3.6041,
      -3.8918,
      -3.8918,
      -3.8918,
      -3.8918,
      -3.8918,
      -4.2973,
      -3.0445,
      -3.8918,
      -4.5951,
      -3.2088,
      -4.5951,
      -4.5951,
      -4.5951,
      -4.5951,
      -4.5951,
      -5.0006,
      -5.6937,
      -5.6937,
      -3.2958,
      -3.2958,
      -3.4965,
      -4.0843,
      -4.0843,
      -4.0843,
      -4.0843,
      -4.0843,
      -4.3074,
      -4.3074,
      -4.3074,
      -3.0547,
      -3.2958,
      -3.902,
    ],
  },
  "token.table": {
    Topic: [
      8,
      8,
      7,
      7,
      8,
      4,
      3,
      4,
      3,
      4,
      4,
      8,
      2,
      2,
      6,
      6,
      7,
      1,
      6,
      1,
      8,
      4,
      2,
      3,
      8,
      8,
      1,
      5,
      8,
      2,
      2,
      1,
      4,
      3,
      2,
      1,
      5,
      1,
      6,
      3,
      7,
      1,
      2,
      4,
      8,
      4,
      1,
      7,
      4,
      3,
      3,
      6,
      6,
      5,
      1,
      8,
      8,
      7,
      5,
      5,
      3,
      1,
      5,
      6,
      2,
      6,
      1,
      6,
      6,
      3,
      3,
      5,
      3,
      1,
      5,
      4,
      7,
      4,
      7,
      4,
      5,
      3,
      3,
      5,
      1,
      3,
      7,
      3,
      2,
      4,
      7,
      8,
      3,
      4,
      3,
      6,
      6,
      2,
      2,
      4,
      1,
      4,
      5,
      5,
      5,
      5,
      5,
      3,
      6,
      1,
      2,
      7,
      4,
      2,
      8,
      2,
      3,
      3,
      4,
      3,
      7,
      5,
      3,
      1,
      7,
      1,
      5,
      3,
      5,
      5,
      1,
      6,
      8,
      6,
      4,
      2,
      5,
      3,
      2,
      5,
      4,
      2,
      5,
      7,
      4,
      2,
      1,
      1,
      8,
      5,
      6,
      2,
      2,
      6,
      6,
      4,
      1,
      8,
      1,
      1,
      4,
      1,
      4,
      5,
      7,
      8,
      8,
      3,
      4,
      1,
      1,
      5,
      2,
      5,
      6,
      6,
      2,
      7,
      2,
      2,
      1,
      4,
      5,
      5,
      3,
      5,
      5,
      4,
      7,
      3,
      1,
      1,
      7,
      1,
      3,
      4,
      7,
      7,
      4,
      5,
      2,
      3,
      5,
      6,
      8,
      4,
      4,
      8,
      4,
      8,
      8,
      8,
      8,
      8,
      3,
      6,
      6,
      8,
      5,
      7,
      7,
      7,
      4,
      8,
      1,
      8,
      8,
      1,
      7,
      2,
      5,
      4,
      8,
      2,
      1,
    ],
    Freq: [
      0.9966930871304773,
      0.9966930871304772,
      0.9999794039529103,
      0.9999794039529103,
      0.9966930871304772,
      1.0067840431049544,
      0.7789543277173923,
      0.22255837934782635,
      0.7512750137905635,
      0.2504250045968545,
      1.0067840431049544,
      0.9966930871304772,
      1.0001689310696036,
      1.0001689310696036,
      0.9997324445216895,
      0.9997324445216896,
      0.9999794039529103,
      0.6666133068344826,
      0.3333066534172413,
      1.0000137444978847,
      0.9966930871304773,
      1.0067840431049544,
      0.4441527862581232,
      0.3331145896935924,
      0.2220763931290616,
      0.9966930871304772,
      0.12469325152591425,
      0.12469325152591425,
      0.7481595091554855,
      1.0001689310696036,
      1.0001689310696036,
      1.0000137444978847,
      1.0067840431049544,
      1.000016734591708,
      1.0001689310696036,
      1.0000137444978845,
      1.0002134128742393,
      1.0000137444978847,
      0.9997324445216895,
      0.39999773434958863,
      0.5999966015243829,
      1.0000137444978847,
      1.0001689310696036,
      0.06649063467251759,
      0.9308688854152463,
      1.0067840431049544,
      1.0000137444978847,
      0.9999794039529103,
      1.0067840431049544,
      1.000016734591708,
      1.000016734591708,
      0.9997324445216895,
      0.9997324445216895,
      1.0002134128742393,
      0.08308074716948653,
      0.9138882188643519,
      0.9966930871304773,
      0.9999794039529103,
      1.0002134128742393,
      1.0002134128742393,
      1.000016734591708,
      0.07690984966039663,
      0.15381969932079326,
      0.7690984966039663,
      1.0001689310696036,
      0.9997324445216895,
      0.18177883240001996,
      0.8180047458000897,
      0.9997324445216896,
      1.000016734591708,
      1.000016734591708,
      1.0002134128742393,
      1.000016734591708,
      0.1250235562458575,
      0.8751648937210026,
      1.0067840431049544,
      0.9999794039529103,
      1.0067840431049544,
      0.9999794039529103,
      1.0067840431049544,
      1.0002134128742393,
      1.000016734591708,
      1.000016734591708,
      1.0002134128742393,
      0.6666731757560925,
      0.20000195272682775,
      0.1333346351512185,
      1.000016734591708,
      1.0001689310696036,
      1.0067840431049544,
      0.9999794039529103,
      0.9966930871304772,
      0.8010903267498687,
      0.20027258168746717,
      1.000016734591708,
      0.9997324445216895,
      0.9997324445216896,
      1.0001689310696036,
      1.0001689310696036,
      1.0067840431049544,
      0.4302305544923283,
      0.5736407393231043,
      1.0002134128742393,
      1.0002134128742393,
      1.0002134128742393,
      1.0002134128742393,
      1.0002134128742393,
      1.000016734591708,
      0.9997324445216895,
      1.0000137444978847,
      1.0001689310696034,
      0.9999794039529103,
      1.0067840431049544,
      0.6660050839115427,
      0.33300254195577134,
      1.0001689310696036,
      1.000016734591708,
      0.8579810722744168,
      0.14299684537906945,
      1.000016734591708,
      0.9999794039529103,
      1.0002134128742393,
      1.000016734591708,
      0.9000092793035257,
      0.10000103103372508,
      1.0000137444978847,
      1.0002134128742393,
      0.6000572379733248,
      0.4000381586488832,
      1.0002134128742393,
      1.0000137444978845,
      0.7770431129355146,
      0.2220123179815756,
      0.9997324445216896,
      1.0067840431049544,
      0.20004090323935747,
      0.8001636129574299,
      1.000016734591708,
      0.8334802870241301,
      0.16669605740482604,
      1.0067840431049544,
      1.0001689310696036,
      1.0002134128742393,
      0.9999794039529103,
      1.0067840431049544,
      1.0001689310696036,
      1.0000137444978847,
      0.7493861306280688,
      0.24979537687602293,
      1.0002134128742393,
      0.9997324445216895,
      1.0001689310696036,
      1.0001689310696036,
      0.9997324445216895,
      0.9997324445216895,
      1.0067840431049544,
      1.0000137444978847,
      0.9966930871304772,
      1.0000137444978845,
      1.0000137444978847,
      1.0067840431049544,
      0.28682858098317515,
      0.5736571619663503,
      0.14341429049158758,
      0.9999794039529103,
      0.9966930871304772,
      0.9966930871304772,
      0.7789543277173923,
      0.22255837934782635,
      1.0000137444978845,
      1.0000137444978845,
      1.0002134128742393,
      1.0001689310696036,
      0.11108731806732697,
      0.8886985445386157,
      0.9997324445216895,
      1.0001689310696036,
      0.9999794039529103,
      1.0001689310696034,
      1.0001689310696034,
      1.0000137444978847,
      1.0067840431049544,
      1.0002134128742393,
      1.0002134128742393,
      1.000016734591708,
      1.0002134128742393,
      1.0002134128742393,
      1.0067840431049544,
      0.9999794039529103,
      1.000016734591708,
      1.0000137444978845,
      1.0000137444978847,
      0.9999794039529103,
      0.25084705648912387,
      0.25084705648912387,
      0.5016941129782477,
      0.9999794039529103,
      0.9999794039529103,
      1.0067840431049544,
      1.0002134128742393,
      0.058816297570124125,
      0.35289778542074474,
      0.41171408299086887,
      0.11763259514024825,
      0.058816297570124125,
      1.0067840431049544,
      1.0067840431049544,
      0.9966930871304772,
      1.0067840431049544,
      0.9966930871304772,
      0.9966930871304772,
      0.9966930871304773,
      0.9966930871304772,
      0.9966930871304772,
      1.000016734591708,
      0.9997324445216895,
      0.9997324445216896,
      0.9966930871304772,
      1.0002134128742393,
      0.9999794039529103,
      0.9999794039529103,
      0.9999794039529103,
      1.0067840431049544,
      0.9966930871304773,
      1.0000137444978847,
      0.9966930871304773,
      0.9966930871304773,
      0.22221934110695624,
      0.7777676938743469,
      1.0001689310696036,
      1.0002134128742393,
      0.5728196775552447,
      0.42961475816643346,
      1.0001689310696036,
      1.0000137444978847,
    ],
    Term: [
      "accident",
      "accident_ukraine",
      "actor",
      "actual",
      "ad",
      "adani",
      "ahmed",
      "ahmed",
      "ahmed_patel",
      "ahmed_patel",
      "air",
      "alliance",
      "announce",
      "apologising",
      "ashok",
      "ashok_gehlot",
      "bagh",
      "ban",
      "ban",
      "banned_marriage",
      "bengal",
      "bhagwat",
      "biden",
      "biden",
      "biden",
      "billboard",
      "bjp",
      "bjp",
      "bjp",
      "bks",
      "bks_iyengar",
      "boy",
      "bpcl",
      "brahmos",
      "candidature",
      "cannon",
      "cash",
      "celebrate",
      "celebration",
      "centre",
      "centre",
      "chalo",
      "change",
      "chennai",
      "chennai",
      "chhattisgarh",
      "claims_sambit",
      "conduct",
      "cong",
      "congress",
      "congress_leader",
      "cop",
      "cracker",
      "crore",
      "cyclone",
      "cyclone",
      "cyclone_nivar",
      "dadi",
      "day",
      "debts",
      "decide",
      "delhi",
      "delhi",
      "delhi",
      "demand",
      "dhawan",
      "diwali",
      "diwali",
      "diwali_celebration",
      "donald",
      "donald_trump",
      "double",
      "draw",
      "edit",
      "edit",
      "egg",
      "elderly",
      "emmanuel",
      "employee",
      "end",
      "explosion",
      "fail",
      "failed",
      "far",
      "farmer",
      "farmer",
      "farmer",
      "fb",
      "floyd",
      "follow",
      "footage",
      "fun",
      "funeral",
      "funeral",
      "funeral_procession",
      "gair",
      "gehlot",
      "george",
      "george_floyd",
      "goa",
      "graphic",
      "graphic",
      "grave",
      "handle",
      "hindus",
      "house",
      "incoming",
      "instead",
      "international",
      "item",
      "iyengar",
      "jalil",
      "jameel",
      "joe",
      "joe",
      "joe_biden",
      "khan",
      "leader",
      "leader",
      "legendary",
      "list",
      "low",
      "madonna",
      "man",
      "man",
      "mandap",
      "mangalore",
      "maradona",
      "maradona",
      "maradona_grave",
      "marriage",
      "mask",
      "mask",
      "masks_delhi",
      "mha",
      "minister",
      "minister",
      "missile",
      "modi",
      "modi",
      "mohan",
      "monthly",
      "morph",
      "movement",
      "msn",
      "munger",
      "muslim",
      "muslims",
      "muslims",
      "naked",
      "name_delhi",
      "narendra",
      "narendra_modi",
      "navy",
      "netizens",
      "nirmala",
      "nirvana",
      "nivar",
      "non",
      "non_muslim",
      "operation",
      "order",
      "order",
      "order",
      "overtime",
      "pak",
      "partying",
      "patel",
      "patel",
      "patra",
      "patra_daughter",
      "pele",
      "pension",
      "people",
      "people",
      "people_without",
      "perform",
      "plan",
      "pm",
      "pm_modi",
      "pray",
      "prez",
      "prime",
      "prime_minister",
      "procession",
      "put",
      "quality",
      "recount",
      "remove",
      "rhyme",
      "russia",
      "sambit",
      "savarkar",
      "school",
      "school",
      "school",
      "shaheen",
      "shaheen_bagh",
      "shaikh",
      "shlokas",
      "show",
      "show",
      "show",
      "show",
      "show",
      "shut",
      "sitharaman",
      "social",
      "soldier",
      "spoof",
      "suicide",
      "terrorist",
      "terrorist_commercial",
      "tornado",
      "trump",
      "ujjain",
      "ujjain_police",
      "ukraine",
      "upset",
      "veer",
      "veer_savarkar",
      "velugu",
      "veteran",
      "volkswagen",
      "water",
      "west",
      "west_bengal",
      "woman",
      "woman",
      "word",
      "wrong",
      "year",
      "year",
      "yoga",
      "youth",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6, 7, 8],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/hindi/photo-predating-farmer-protests-viral-as-muslim-man-disguised-as-sikh-farmer-during-protests/",
        headline:
          "\u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932, \u0926\u093e\u0935\u093e \u0915\u093f\u092f\u093e \u0915\u093f \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u092a\u0917\u095c\u0940 \u092a\u0939\u0928\u0915\u0930 \u092a\u0902\u091c\u093e\u092c \u0915\u0947 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u092e\u0947\u0902 \u0939\u0941\u0906 \u0936\u093e\u092e\u093f\u0932",
      },
      {
        url:
          "https://www.altnews.in/hindi/false-claim-of-russia-ban-muslims-from-marrying-in-other-religion-viral-on-social-media/",
        headline:
          "\u0930\u0942\u0938 \u0928\u0947 \u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0915\u094b \u0917\u0948\u0930-\u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0938\u0947 \u0936\u093e\u0926\u0940 \u0915\u0930\u0928\u0947 \u092a\u0930 \u092a\u093e\u092c\u0902\u0926\u0940 \u0932\u0917\u093e\u092f\u0940? \u095a\u0932\u0924 \u0926\u093e\u0935\u093e \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/fake-screenshot-of-abp-news-shared-with-false-claim-that-sambit-patras-daughter-eloped-with-a-muslim-man/",
        headline:
          "ABP \u0928\u094d\u092f\u0942\u095b \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0915\u0947 \u095b\u0930\u093f\u090f \u0938\u0902\u092c\u093f\u0924 \u092a\u093e\u0924\u094d\u0930\u093e \u0915\u0940 \u092c\u0947\u091f\u0940 \u0915\u0947 \u092c\u093e\u0930\u0947 \u092e\u0947\u0902 \u092b\u0948\u0932\u093e\u0908 \u0917\u092f\u0940 \u0905\u095e\u0935\u093e\u0939",
      },
      {
        url:
          "https://www.altnews.in/false-claim-of-russia-ban-muslims-from-marrying-in-other-religion-viral-on-social-media/",
        headline:
          "No, Russia has not banned marriage between Muslims and non-Muslims",
      },
      {
        url:
          "https://www.altnews.in/fake-screenshot-of-abp-news-shared-with-false-claim-that-sambit-patras-daughter-eloped-with-a-muslim-man/",
        headline:
          "Fake ABP News graphic plate claims Sambit Patra\u2019s daughter eloped with Muslim man",
      },
      {
        url:
          "https://factly.in/telugu-russia-did-not-ban-muslim-men-from-marrying-other-religious-women/",
        headline:
          " \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c05\u0c2c\u0c4d\u0c2c\u0c3e\u0c2f\u0c3f\u0c32\u0c41, \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c2e\u0c47\u0c24\u0c30 \u0c05\u0c2e\u0c4d\u0c2e\u0c3e\u0c2f\u0c3f\u0c32\u0c28\u0c3f \u0c2a\u0c46\u0c33\u0c4d\u0c32\u0c3f\u0c1a\u0c47\u0c38\u0c41\u0c15\u0c4b\u0c35\u0c21\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c30\u0c37\u0c4d\u0c2f\u0c3e \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c28\u0c3f\u0c37\u0c47\u0c26\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/russia-did-not-ban-muslim-men-from-marrying-other-religious-women/",
        headline:
          " Russian Government did not ban Muslim men from marrying non-Muslim women",
      },
      {
        url:
          "https://factly.in/telugu-old-photos-from-other-states-shared-as-muslim-women-in-bjp-ghmc-campaign/",
        headline:
          " \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c2e\u0c39\u0c3f\u0c33\u0c32\u0c41 \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c15\u0c02\u0c21\u0c41\u0c35\u0c3e \u0c35\u0c47\u0c38\u0c41\u0c15\u0c4a\u0c28\u0c3f \u0c09\u0c28\u0c4d\u0c28 \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28\u0c35\u0c3f \u0c15\u0c3e\u0c35\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/25/fact-check-old-video-of-rajput-community-celebrating-diwali-by-firing-gunshots-shared-as-recent-one/",
        headline:
          "Fact Check: Old video of Rajput community celebrating Diwali by firing gunshots shared as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/27/fact-check-old-picture-of-man-selling-items-for-chhath-puja-resurfaces-as-recent-one-with-false-claims/",
        headline:
          "Fact Check: Old picture of man selling items for \u2018Chhath Puja\u2019 resurfaces as recent one with false claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/28/fact-check-2018-picture-of-farmers-agitation-shared-as-recent-one-heres-the-truth/",
        headline:
          "Fact Check: 2018 picture of farmers\u2019 agitation shared as recent one; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/fake-graphic-claims-sambit-patras-daughter-eloped-with-a-muslim-man-10819",
        headline:
          "Fake Graphic Claims Sambit Patra's 'Daughter' Eloped With A Muslim Man",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/old-images-of-water-cannon-used-on-protesting-farmers-shared-as-recent-10836",
        headline:
          "Old Images Of Water Cannon Used On Protesting Farmers Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/prasar-bharati-dd-news-share-2017-video-of-marina-beach-as-recent-10841",
        headline:
          "Prasar Bharati, DD News Share 2017 Video Of Marina Beach As Recent",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/fake-screenshot-claims-sambit-patras-daughter-eloped-with-a-muslim-man-10820",
        headline:
          "\u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0915\u093e \u0926\u093e\u0935\u093e, \u0938\u0902\u092c\u093f\u0924 \u092a\u093e\u0924\u094d\u0930\u093e \u0915\u0940 '\u092c\u0947\u091f\u0940' \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u092f\u0941\u0935\u0915 \u0938\u0902\u0917 \u095e\u0930\u093e\u0930",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/russian-government-ban-on-muslims-marrying-non-muslims-is-fake-news-10830",
        headline:
          "\u0928\u0939\u0940\u0902, \u0930\u0942\u0938 \u0928\u0947 \u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0914\u0930 \u0917\u0948\u0930-\u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0915\u0940 \u0936\u093e\u0926\u0940 \u092a\u0930 \u0930\u094b\u0915 \u0928\u0939\u0940\u0902 \u0932\u0917\u093e\u0908 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-images-of-water-cannon-used-on-protesting-farmers-shared-as-recent-10846",
        headline:
          "\u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u092a\u0930 \u0935\u093e\u091f\u0930 \u0915\u0948\u0928\u0928 \u0938\u0947 \u0915\u093f\u092f\u0947 \u092a\u094d\u0930\u0939\u093e\u0930 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0939\u093e\u0932 \u0915\u0940 \u092c\u0924\u093e \u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/graphic-claiming-mha-has-asked-schools-to-be-shut-till-31-december-is-fake-10866",
        headline:
          "\u0928\u0939\u0940\u0902, \u0917\u0943\u0939 \u092e\u0902\u0924\u094d\u0930\u093e\u0932\u092f \u0928\u0947 31 \u0926\u093f\u0938\u0902\u092c\u0930 \u0924\u0915 \u0938\u094d\u0915\u0942\u0932-\u0915\u0949\u0932\u0947\u091c\u094b\u0902 \u0915\u094b \u092c\u0902\u0926 \u0930\u0916\u0928\u0947 \u0915\u093e \u0906\u0926\u0947\u0936 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/prasar-bharti-dd-news-share-old-video-as-chennai-nivar-cyclone-flood-10874",
        headline:
          "\u092a\u094d\u0930\u0938\u093e\u0930 \u092d\u093e\u0930\u0924\u0940, \u0921\u0940\u0921\u0940 \u0928\u094d\u092f\u0942\u095b \u0928\u0947 '\u0928\u093f\u0935\u093e\u0930 \u091a\u0915\u094d\u0930\u0935\u093e\u0924' \u092c\u0924\u093e\u0924\u0947 \u0939\u0941\u090f \u0938\u093e\u091d\u093e \u0915\u093f\u092f\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019-book-themed-jagadhatri-puja-pandal-in-chandannagar-shared-as-recent-10762",
        headline:
          "\u09a5\u09b0\u09c7 \u09a5\u09b0\u09c7 \u09ac\u0987 \u09b8\u09be\u099c\u09be\u09a8\u09cb \u099a\u09a8\u09cd\u09a6\u09a8\u09a8\u0997\u09b0\u09c7\u09b0 \u099c\u0997\u09a6\u09cd\u09a7\u09be\u09a4\u09cd\u09b0\u09c0 \u09aa\u09c1\u099c\u09cb\u09b0 \u09ae\u09a8\u09cd\u09a1\u09aa\u09c7\u09b0 \u099b\u09ac\u09bf\u099f\u09bf \u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-images-of-water-cannon-used-on-protesting-farmers-shared-as-recent-10851",
        headline:
          "\u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u0989\u09aa\u09b0 \u099c\u09b2\u0995\u09be\u09ae\u09be\u09a8 \u099a\u09be\u09b2\u09be\u09a8\u09cb\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-putin-did-not-ban-interfaith-marriages-for-muslim-men-in-russia-1744098-2020-11-25",
        headline:
          "Fact Check: Putin did not ban interfaith marriages for Muslim men in Russia",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/25/fact-check-old-video-of-rajput-community-celebrating-diwali-by-firing-gunshots-shared-as-recent-one/",
        headline:
          "Fact Check: Old video of Rajput community celebrating Diwali by firing gunshots shared as recent one",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/27/fact-check-old-picture-of-man-selling-items-for-chhath-puja-resurfaces-as-recent-one-with-false-claims/",
        headline:
          "Fact Check: Old picture of man selling items for \u2018Chhath Puja\u2019 resurfaces as recent one with false claims",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/28/fact-check-2018-picture-of-farmers-agitation-shared-as-recent-one-heres-the-truth/",
        headline:
          "Fact Check: 2018 picture of farmers\u2019 agitation shared as recent one; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/old-images-of-water-cannon-used-on-protesting-farmers-shared-as-recent-10836",
        headline:
          "Old Images Of Water Cannon Used On Protesting Farmers Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/prasar-bharati-dd-news-share-2017-video-of-marina-beach-as-recent-10841",
        headline:
          "Prasar Bharati, DD News Share 2017 Video Of Marina Beach As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/fake-graphic-claims-sambit-patras-daughter-eloped-with-a-muslim-man-10819",
        headline:
          "Fake Graphic Claims Sambit Patra's 'Daughter' Eloped With A Muslim Man",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/image-of-muslim-man-praying-amid-lions-on-the-road-is-morphed-10781",
        headline:
          "\u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09af\u09bc \u09b8\u09bf\u0982\u09b9\u09a6\u09c7\u09b0 \u09ae\u09be\u099d\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u09a8\u09be\u09ae\u09be\u099c \u09aa\u09a1\u09bc\u09be\u09b0 \u099b\u09ac\u09bf\u099f\u09bf \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/image-of-muslim-youths-reading-hindu-scripture-shared-with-communal-claim-10770",
        headline:
          "\u09ae\u09c1\u09b8\u09b2\u09bf\u09ae\u09a6\u09c7\u09b0 \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09a7\u09b0\u09cd\u09ae\u0997\u09cd\u09b0\u09a8\u09cd\u09a5 \u0985\u09a7\u09cd\u09af\u09af\u09bc\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/old-images-of-water-cannon-used-on-protesting-farmers-shared-as-recent-10851",
        headline:
          "\u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u0989\u09aa\u09b0 \u099c\u09b2\u0995\u09be\u09ae\u09be\u09a8 \u099a\u09be\u09b2\u09be\u09a8\u09cb\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2019-book-themed-jagadhatri-puja-pandal-in-chandannagar-shared-as-recent-10762",
        headline:
          "\u09a5\u09b0\u09c7 \u09a5\u09b0\u09c7 \u09ac\u0987 \u09b8\u09be\u099c\u09be\u09a8\u09cb \u099a\u09a8\u09cd\u09a6\u09a8\u09a8\u0997\u09b0\u09c7\u09b0 \u099c\u0997\u09a6\u09cd\u09a7\u09be\u09a4\u09cd\u09b0\u09c0 \u09aa\u09c1\u099c\u09cb\u09b0 \u09ae\u09a8\u09cd\u09a1\u09aa\u09c7\u09b0 \u099b\u09ac\u09bf\u099f\u09bf \u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-images-of-water-cannon-used-on-protesting-farmers-shared-as-recent-10846",
        headline:
          "\u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u092a\u0930 \u0935\u093e\u091f\u0930 \u0915\u0948\u0928\u0928 \u0938\u0947 \u0915\u093f\u092f\u0947 \u092a\u094d\u0930\u0939\u093e\u0930 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0939\u093e\u0932 \u0915\u0940 \u092c\u0924\u093e \u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/russian-government-ban-on-muslims-marrying-non-muslims-is-fake-news-10830",
        headline:
          "\u0928\u0939\u0940\u0902, \u0930\u0942\u0938 \u0928\u0947 \u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0914\u0930 \u0917\u0948\u0930-\u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0915\u0940 \u0936\u093e\u0926\u0940 \u092a\u0930 \u0930\u094b\u0915 \u0928\u0939\u0940\u0902 \u0932\u0917\u093e\u0908 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/graphic-claiming-mha-has-asked-schools-to-be-shut-till-31-december-is-fake-10866",
        headline:
          "\u0928\u0939\u0940\u0902, \u0917\u0943\u0939 \u092e\u0902\u0924\u094d\u0930\u093e\u0932\u092f \u0928\u0947 31 \u0926\u093f\u0938\u0902\u092c\u0930 \u0924\u0915 \u0938\u094d\u0915\u0942\u0932-\u0915\u0949\u0932\u0947\u091c\u094b\u0902 \u0915\u094b \u092c\u0902\u0926 \u0930\u0916\u0928\u0947 \u0915\u093e \u0906\u0926\u0947\u0936 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-protesting-farmers-facing-water-cannons-from-2018-march-not-recent-delhi-chalo-protest-671027",
        headline:
          "Fact Check: Photo of protesting farmers facing water cannons from 2018 march, not recent Delhi Chalo protest",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/this-video-does-not-show-young-modi-practicing-yoga/",
        headline: " This video does not show young Modi practicing Yoga.",
      },
      {
        url:
          "https://factly.in/telugu-the-young-person-practising-yoga-in-this-video-is-not-narendra-modi/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c2f\u0c4b\u0c17\u0c3e \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c42 \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c28\u0c30\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c4b\u0c26\u0c40 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-videos-are-being-shared-in-the-context-of-cyclone-nivar/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c28\u0c41 \u0c28\u0c3f\u0c35\u0c30\u0c4d \u0c24\u0c41\u0c2b\u0c3e\u0c28\u0c4d \u0c2f\u0c4a\u0c15\u0c4d\u0c15 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://digiteye.in/no-pm-jeevan-jyoti-bima-yojana-scheme-doesnt-offer-rs-30000-monthly-pension-fact-check/",
        headline:
          "No, PM Jeevan Jyoti Bima Yojana scheme doesn\u2019t offer Rs 30,000 monthly pension? Fact Check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/23/fact-check-unrelated-video-of-durga-puja-protests-in-munger-shared-with-bihar-assembly-elections-context/",
        headline:
          "Fact check: Unrelated video of Durga Puja protests in Munger shared with Bihar assembly elections context",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-no-pm-modi-is-not-performing-yoga-in-this-old-black-heres-the-truth/",
        headline:
          "Fact Check: No, PM Modi is NOT performing yoga in this old black & white video; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/27/fact-check-this-picture-is-not-of-joe-biden-apologising-to-george-floyds-daughter-heres-the-truth/",
        headline:
          "Fact Check: This picture is NOT of Joe Biden apologising to George Floyd\u2019s daughter; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/27/fact-check-can-mrna-covid-19-vaccine-change-human-dna-heres-the-truth/",
        headline:
          "Fact Check: Can mRNA COVID-19 vaccine change human DNA? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/1938-video-of-bks-iyengar-viral-as-pm-modi-doing-yoga-10792",
        headline: "1938 Video Of BKS Iyengar Viral As PM Modi Doing Yoga",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-joe-biden-is-not-kneeling-before-george-floyds-daughter-in-this-pic-10817",
        headline:
          "No, Joe Biden Is Not Kneeling Before George Floyd's Daughter In This Pic",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/1938-video-of-bks-iyengar-viral-as-pm-modi-doing-yoga-10813",
        headline:
          "\u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u09af\u09cb\u0997 \u099a\u09b0\u09cd\u099a\u09be \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u0995\u09c7\u098f\u09b8 \u0986\u09df\u09c7\u0999\u09cd\u0997\u09be\u09b0\u09c7\u09b0 \u09e7\u09ef\u09e9\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-1938-video-of-bks-iyengar-shared-as-pm-modi-performing-yoga-1743778-2020-11-24",
        headline:
          " Fact Check: 1938 video of BKS Iyengar shared as PM Modi performing yoga",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-pm-modi-has-not-announced-another-covid-19-lockdown-1743770-2020-11-24",
        headline:
          " Fact Check: No, PM Modi has not announced another Covid-19 lockdown",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-unmasking-a-fraud-running-govt-schemes-in-pm-modi-s-name-1744031-2020-11-25",
        headline:
          "Fact Check: Unmasking a fraud running \u2018govt\u2019 schemes in PM Modi\u2019s name",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/23/fact-check-unrelated-video-of-durga-puja-protests-in-munger-shared-with-bihar-assembly-elections-context/",
        headline:
          "Fact check: Unrelated video of Durga Puja protests in Munger shared with Bihar assembly elections context",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-no-pm-modi-is-not-performing-yoga-in-this-old-black-heres-the-truth/",
        headline:
          "Fact Check: No, PM Modi is NOT performing yoga in this old black & white video; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/27/fact-check-this-picture-is-not-of-joe-biden-apologising-to-george-floyds-daughter-heres-the-truth/",
        headline:
          "Fact Check: This picture is NOT of Joe Biden apologising to George Floyd\u2019s daughter; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/27/fact-check-can-mrna-covid-19-vaccine-change-human-dna-heres-the-truth/",
        headline:
          "Fact Check: Can mRNA COVID-19 vaccine change human DNA? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-joe-biden-is-not-kneeling-before-george-floyds-daughter-in-this-pic-10817",
        headline:
          "No, Joe Biden Is Not Kneeling Before George Floyd's Daughter In This Pic",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/1938-video-of-bks-iyengar-viral-as-pm-modi-doing-yoga-10792",
        headline: "1938 Video Of BKS Iyengar Viral As PM Modi Doing Yoga",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/edited-image-of-narendra-modi-and-amit-shah-revived-with-communal-spin-10803",
        headline:
          "\u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0 \u0993 \u0986\u09ae\u09bf\u09a4 \u09b6\u09be\u09b9\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/1938-video-of-bks-iyengar-viral-as-pm-modi-doing-yoga-10813",
        headline:
          "\u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u09af\u09cb\u0997 \u099a\u09b0\u09cd\u099a\u09be \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u0995\u09c7\u098f\u09b8 \u0986\u09df\u09c7\u0999\u09cd\u0997\u09be\u09b0\u09c7\u09b0 \u09e7\u09ef\u09e9\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/bks-iyengar-video-viral-as-pm-narendra-modi-doing-yoga-10831",
        headline:
          "\u092c\u0940.\u0915\u0947.\u090f\u0938 \u0905\u092f\u0902\u0917\u0930 \u0915\u0940 1938 \u0915\u0940 \u092f\u094b\u0917 \u0915\u0930\u0924\u0947 \u0939\u0941\u090f \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-clip-of-yoga-guru-iyengar-from-1938-shared-as-video-of-pm-modi-doing-yoga-671041",
        headline:
          "Fact Check: Clip of yoga guru Iyengar from 1938 shared as video of PM Modi doing yoga",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-purported-letter-of-pm-modi-supporting-candidature-of-giriraj-singh-for-bihar-chief-ministers-post-is-fake-670916",
        headline:
          "FACT CHECK: Purported letter of PM Modi supporting candidature of Giriraj Singh for Bihar chief minister's post is FAKE",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/hindi/video-of-funeral-procession-of-mns-leader-viral-as-congress-leader-ahmed-patels-last-rites/",
        headline:
          "\u0905\u0939\u092e\u0926 \u092a\u091f\u0947\u0932 \u0915\u0940 \u0936\u0935\u092f\u093e\u0924\u094d\u0930\u093e \u092e\u0947\u0902 \u092d\u0940\u095c \u0909\u092e\u095c\u0928\u0947 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0936\u0947\u092f\u0930 \u0939\u094b \u0930\u0939\u093e \u0935\u0940\u0921\u093f\u092f\u094b MNS \u0928\u0947\u0924\u093e \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.altnews.in/hindi/satirical-fb-post-on-ayub-khan-marrying-his-daughter-draws-ridicule-for-congress-leader/",
        headline:
          "\u0932\u095c\u0915\u0940 \u0928\u0947 \u0915\u093f\u092f\u093e \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947\u0924\u093e \u0915\u0947 \u0938\u093e\u0925 \u0936\u093e\u0926\u0940 \u0939\u094b\u0928\u0947 \u0915\u093e \u091d\u0942\u0920\u093e \u0926\u093e\u0935\u093e, \u0924\u0938\u094d\u0935\u0940\u0930\u094b\u0902 \u0938\u0947 \u0928\u0947\u0924\u093e \u0915\u094b \u092c\u0928\u093e\u092f\u093e \u0939\u0902\u0938\u0940 \u0915\u093e \u092a\u093e\u0924\u094d\u0930",
      },
      {
        url:
          "https://www.altnews.in/video-of-funeral-procession-of-mns-leader-viral-as-congress-leader-ahmed-patels-last-rites/",
        headline:
          "Video of funeral procession of MNS leader viral as Congress leader Ahmed Patel\u2019s last rites",
      },
      {
        url:
          "https://www.altnews.in/congress-leaders-shared-2018-images-of-farmer-protest-as-recent/",
        headline:
          "Congress leaders share images from 2018 farmers protest as recent",
      },
      {
        url:
          "https://www.altnews.in/satirical-fb-post-on-ayub-khan-marrying-his-daughter-draws-ridicule-for-congress-leader/",
        headline:
          "Satirical FB post on Ayub Khan marrying his daughter draws ridicule for Congress leader",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/25/fact-check-no-centre-has-not-decided-to-close-schools-and-colleges-till-dec-31-heres-the-truth/",
        headline:
          "Fact Check: No, Centre has NOT decided to close schools and colleges till Dec 31; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-did-donald-trump-tweet-madonnas-obit-instead-of-maradonas-heres-the-truth/",
        headline:
          "Fact Check: Did Donald Trump tweet Madonna\u2019s obit instead of Maradona\u2019s? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-no-pop-queen-madonna-has-not-passed-away-heres-the-truth/",
        headline:
          "Fact Check: No, Pop Queen Madonna has not passed away; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/27/fact-check-old-picture-from-1993-shared-as-bidens-under-construction-inaugural-stage/",
        headline:
          "Fact Check: Old picture from 1993 shared as Biden\u2019s under construction \u2018inaugural stage\u2019",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/28/fact-check-video-of-successful-brahmos-missile-launch-shared-as-failed-missile-testing/",
        headline:
          "Fact Check: Video of successful BrahMos missile launch shared as failed missile testing",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/fake-tweet-alert-donald-trump-did-not-mistake-maradona-for-madonna-10829",
        headline:
          "Fake Tweet Alert: Donald Trump Did Not Mistake Maradona For Madonna",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-video-does-not-show-congress-leader-ahmed-patels-funeral-procession-10857",
        headline:
          "No, Video Does Not Show Congress Leader Ahmed Patel's Funeral Procession",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/this-is-not-congress-leader-ahmed-patels-funeral-video-10882",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947\u0924\u093e \u0905\u0939\u092e\u0926 \u092a\u091f\u0947\u0932 \u0915\u0940 \u0905\u0902\u0924\u093f\u092e \u092f\u093e\u0924\u094d\u0930\u093e \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/misleading-posts-falsely-claim-aimim-mla-in-bihar-refused-to-take-oath-in-the-name-of-the-country-10845",
        headline:
          "\u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 AIMIM \u09ac\u09bf\u09a7\u09be\u09df\u0995\u09c7\u09b0 \u09b6\u09aa\u09a5 \u09ac\u09bf\u09a4\u09b0\u09cd\u0995 \u09a8\u09bf\u09df\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09aa\u09cb\u09b8\u09cd\u099f",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/pro-khalistan-slogans-from-cricket-world-cup-peddled-as-farmers-protest-10883",
        headline:
          "\u0995\u09cd\u09b0\u09bf\u0995\u09c7\u099f \u09ac\u09bf\u09b6\u09cd\u09ac\u0995\u09be\u09aa\u09c7 \u0996\u09be\u09b2\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09aa\u09a8\u09cd\u09a5\u09c0 \u09b8\u09cd\u09b2\u09cb\u0997\u09be\u09a8 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-trump-did-not-pay-tribute-to-singer-madonna-instead-of-maradona-1744358-2020-11-26",
        headline:
          " Fact Check: No, Trump did not pay tribute to singer Madonna instead of Maradona",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-video-of-khalistan-supporters-linked-to-ongoing-farmers-protest-1745126-2020-11-29",
        headline:
          "Fact Check: Old video of Khalistan supporters linked to ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/no-this-is-not-congress-leader-ahmed-patel-funeral-procession-1744639-2020-11-27",
        headline:
          "Fact Check: No, this is not Congress leader Ahmed Patel's funeral procession",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-brahmos-launch-video-shared-with-false-claims-of-failure-1745001-2020-11-28",
        headline:
          "Fact Check: BrahMos launch video shared with false claims of failure",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-massive-crowd-does-not-show-the-funeral-procession-of-legendary-maradona-1744724-2020-11-27",
        headline:
          "Fact Check: This massive crowd does not show the funeral procession of legendary Maradona",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/25/fact-check-no-centre-has-not-decided-to-close-schools-and-colleges-till-dec-31-heres-the-truth/",
        headline:
          "Fact Check: No, Centre has NOT decided to close schools and colleges till Dec 31; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-did-donald-trump-tweet-madonnas-obit-instead-of-maradonas-heres-the-truth/",
        headline:
          "Fact Check: Did Donald Trump tweet Madonna\u2019s obit instead of Maradona\u2019s? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-no-pop-queen-madonna-has-not-passed-away-heres-the-truth/",
        headline:
          "Fact Check: No, Pop Queen Madonna has not passed away; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/27/fact-check-old-picture-from-1993-shared-as-bidens-under-construction-inaugural-stage/",
        headline:
          "Fact Check: Old picture from 1993 shared as Biden\u2019s under construction \u2018inaugural stage\u2019",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/28/fact-check-video-of-successful-brahmos-missile-launch-shared-as-failed-missile-testing/",
        headline:
          "Fact Check: Video of successful BrahMos missile launch shared as failed missile testing",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2019-video-from-argentina-viral-as-footballer-maradonas-funeral-10848",
        headline:
          "2019 Video From Argentina Viral As Footballer Maradona's Funeral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-video-does-not-show-congress-leader-ahmed-patels-funeral-procession-10857",
        headline:
          "No, Video Does Not Show Congress Leader Ahmed Patel's Funeral Procession",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/pro-khalistan-slogans-from-cricket-world-cup-peddled-as-farmers-protest-10875",
        headline:
          "Pro-Khalistan Slogans From Cricket World Cup Peddled As Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/fake-tweet-alert-donald-trump-did-not-mistake-maradona-for-madonna-10829",
        headline:
          "Fake Tweet Alert: Donald Trump Did Not Mistake Maradona For Madonna",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/pro-khalistan-slogans-from-cricket-world-cup-peddled-as-farmers-protest-10883",
        headline:
          "\u0995\u09cd\u09b0\u09bf\u0995\u09c7\u099f \u09ac\u09bf\u09b6\u09cd\u09ac\u0995\u09be\u09aa\u09c7 \u0996\u09be\u09b2\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09aa\u09a8\u09cd\u09a5\u09c0 \u09b8\u09cd\u09b2\u09cb\u0997\u09be\u09a8 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/misleading-posts-falsely-claim-aimim-mla-in-bihar-refused-to-take-oath-in-the-name-of-the-country-10845",
        headline:
          "\u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 AIMIM \u09ac\u09bf\u09a7\u09be\u09df\u0995\u09c7\u09b0 \u09b6\u09aa\u09a5 \u09ac\u09bf\u09a4\u09b0\u09cd\u0995 \u09a8\u09bf\u09df\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09aa\u09cb\u09b8\u09cd\u099f",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/this-is-not-congress-leader-ahmed-patels-funeral-video-10882",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947\u0924\u093e \u0905\u0939\u092e\u0926 \u092a\u091f\u0947\u0932 \u0915\u0940 \u0905\u0902\u0924\u093f\u092e \u092f\u093e\u0924\u094d\u0930\u093e \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/video-from-argentina-viral-as-footballer-maradonas-funeral-10854",
        headline:
          "2019 \u0915\u0947 \u091a\u0941\u0928\u093e\u0935 \u0915\u0948\u0902\u092a\u0947\u0928 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0941\u091f\u092c\u0949\u0932\u0930 \u092e\u093e\u0930\u093e\u0921\u094b\u0928\u093e \u0915\u0947 \u0905\u0902\u0924\u093f\u092e \u0938\u0902\u0938\u094d\u0915\u093e\u0930 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/prasar-bharti-dd-news-share-old-video-as-chennai-nivar-cyclone-flood-10874",
        headline: " ,    ' '      ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-claim-that-brahmos-supersonic-cruise-missile-test-has-failed-is-fake-news-670978",
        headline:
          "Fact check: Viral claim that BrahMos supersonic cruise missile test has failed is Fake News.",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-showing-bidens-inaugural-stage-is-from-clintons-1993-inauguration-ceremony-671092",
        headline:
          "Fact Check: Photo showing Biden's inaugural stage is from Clinton's 1993 inauguration ceremony",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-argentine-soccer-great-diego-maradona-passes-away-american-pop-singer-madonna-is-still-alive-671013",
        headline:
          "Fact Check: Argentine soccer great Diego Maradona passes away; American pop singer Madonna is still alive",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/hindi/old-video-from-pakistan-shared-as-cyclone-nivar-in-chennai/",
        headline:
          "\u091a\u0947\u0928\u094d\u0928\u0908 \u092e\u0947\u0902 \u0906\u092f\u0947 \u0924\u0942\u095e\u093e\u0928 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/23/fact-check-post-elections-did-ec-order-recounting-in-25-bihar-assembly-seatsheres-the-truth/",
        headline:
          "Fact Check: Post elections, did EC order recounting in 25 Bihar assembly seats? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-was-french-prez-emmanuel-macron-hit-by-an-egg-following-his-recent-remarks-heres-the-truth/",
        headline:
          "Fact Check: Was French Prez Emmanuel Macron hit by an egg following his recent remarks? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-bharat-petroleum-dont-fall-for-old-newspaper-clips/",
        headline:
          "Fact Check: Nirmala Sitharaman has not claimed BPCL & Air India will be sold in March 2021; Don\u2019t fall for old newspaper clips",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/28/fact-check-msn-leader-jameel-shaikhs-funeral-video-falsely-shared-as-cong-veteran-ahmed-patels/",
        headline:
          "Fact Check: MSN leader Jameel Shaikh\u2019s funeral video falsely shared as Cong veteran Ahmed Patel\u2019s",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/old-video-from-chhattisgarh-falsely-linked-to-adanis-coal-operations-in-goa-10780",
        headline:
          "Old Video From Chhattisgarh Falsely Linked To Adani's Coal Operations In Goa",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/graphic-claiming-mha-has-asked-schools-to-be-shut-till-year-end-is-fake-10796",
        headline:
          "Graphic Claiming MHA Has Asked Schools To Be Shut Till Year End Is Fake",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-from-chhattisgarh-falsely-linked-to-adanis-coal-operations-in-goa-10784",
        headline:
          "\u091b\u0924\u094d\u0924\u0940\u0938\u0917\u095d \u0938\u0947 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0917\u094b\u0935\u093e \u092e\u0947\u0902 \u0905\u0921\u093e\u0928\u0940 \u0915\u0947 \u0911\u092a\u0930\u0947\u0936\u0928 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-rss-chief-mohan-bhagwat-has-not-given-this-viral-statement-10818",
        headline:
          "\u0928\u0939\u0940\u0902, \u0906\u0930\u090f\u0938\u090f\u0938 \u092a\u094d\u0930\u092e\u0941\u0916 \u092e\u094b\u0939\u0928 \u092d\u093e\u0917\u0935\u0924 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e \u0939\u0948 \u092f\u0939 \u0935\u093e\u092f\u0930\u0932 \u092c\u092f\u093e\u0928",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/graphic-claiming-mha-has-asked-schools-to-be-shut-till-year-end-is-fake-10828",
        headline:
          "\u09ac\u099b\u09b0\u09c7\u09b0 \u09b6\u09c7\u09b7 \u0985\u09ac\u09a7\u09bf \u09b8\u09cd\u0995\u09c1\u09b2 \u09ac\u09a8\u09cd\u09a7 \u09b8\u09cd\u09ac\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0 \u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u0995\u09c7\u09b0 \u0986\u09a6\u09c7\u09b6\u09c7\u09b0 \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/23/fact-check-post-elections-did-ec-order-recounting-in-25-bihar-assembly-seatsheres-the-truth/",
        headline:
          "Fact Check: Post elections, did EC order recounting in 25 Bihar assembly seats? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-was-french-prez-emmanuel-macron-hit-by-an-egg-following-his-recent-remarks-heres-the-truth/",
        headline:
          "Fact Check: Was French Prez Emmanuel Macron hit by an egg following his recent remarks? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-bharat-petroleum-dont-fall-for-old-newspaper-clips/",
        headline:
          "Fact Check: Nirmala Sitharaman has not claimed BPCL & Air India will be sold in March 2021; Don\u2019t fall for old newspaper clips",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/28/fact-check-msn-leader-jameel-shaikhs-funeral-video-falsely-shared-as-cong-veteran-ahmed-patels/",
        headline:
          "Fact Check: MSN leader Jameel Shaikh\u2019s funeral video falsely shared as Cong veteran Ahmed Patel\u2019s",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/graphic-claiming-mha-has-asked-schools-to-be-shut-till-year-end-is-fake-10796",
        headline:
          "Graphic Claiming MHA Has Asked Schools To Be Shut Till Year End Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/old-video-from-chhattisgarh-falsely-linked-to-adanis-coal-operations-in-goa-10780",
        headline:
          "Old Video From Chhattisgarh Falsely Linked To Adani's Coal Operations In Goa",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/graphic-claiming-mha-has-asked-schools-to-be-shut-till-year-end-is-fake-10828",
        headline:
          "\u09ac\u099b\u09b0\u09c7\u09b0 \u09b6\u09c7\u09b7 \u0985\u09ac\u09a7\u09bf \u09b8\u09cd\u0995\u09c1\u09b2 \u09ac\u09a8\u09cd\u09a7 \u09b8\u09cd\u09ac\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0 \u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u0995\u09c7\u09b0 \u0986\u09a6\u09c7\u09b6\u09c7\u09b0 \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bird-in-viral-picture-is-not-rainbow-harpy-eagle-but-digital-art-by-sergio-ramirez-670877",
        headline:
          "Fact check: Bird in viral picture is not `Rainbow Harpy Eagle', but digital art by Sergio Ramirez",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-fake-news-claims-china-used-microwave-weapons-to-cook-indian-soldiers-during-stand-off-670934",
        headline:
          "Fact Check: Fake news claims China used microwave weapons to 'cook' Indian soldiers during stand-off",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/hindi/graphic-images-of-unsolved-murder-in-haryana-viral-with-baseless-love-jihad-spin/",
        headline:
          "\u0939\u0930\u093f\u092f\u093e\u0923\u093e \u0915\u0947 \u0905\u0928\u0938\u0941\u0932\u091d\u0947 \u092e\u0930\u094d\u0921\u0930 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0936\u0947\u092f\u0930 \u0915\u0930\u0924\u0947 \u0939\u0941\u090f \u0932\u094b\u0917\u094b\u0902 \u0928\u0947 \u0915\u093f\u092f\u093e \u2018\u0932\u0935 \u091c\u093f\u0939\u093e\u0926\u2019 \u0915\u093e \u092c\u0947\u092c\u0941\u0928\u093f\u092f\u093e\u0926 \u0926\u093e\u0935\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-shared-with-false-claim-that-pm-modi-announced-another-lockdown/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915 : \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u092e\u094b\u0926\u0940 \u0928\u0947 \u0915\u0941\u091b \u0930\u093e\u091c\u094d\u092f\u094b\u0902 \u092e\u0947\u0902 \u092b\u093f\u0930 \u0938\u0947 \u0932\u0949\u0915\u0921\u093e\u0909\u0928 \u0915\u093e \u0906\u0926\u0947\u0936 \u091c\u093e\u0930\u0940 \u0915\u093f\u092f\u093e?",
      },
      {
        url:
          "https://factly.in/telugu-claim-that-not-a-single-2bhk-is-built-in-hyderabad-is-false/",
        headline:
          " \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c32\u0c4b \u0c05\u0c38\u0c32\u0c41 \u0c12\u0c15\u0c4d\u0c15 \u0c21\u0c2c\u0c32\u0c4d \u0c2c\u0c46\u0c21\u0c4d \u0c30\u0c42\u0c2e\u0c4d \u0c07\u0c02\u0c1f\u0c3f\u0c28\u0c3f \u0c15\u0c42\u0c21\u0c3e \u0c15\u0c1f\u0c4d\u0c1f\u0c3f\u0c02\u0c1a\u0c3f \u0c07\u0c35\u0c4d\u0c35\u0c32\u0c47\u0c26\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c26\u0c28 \u0c24\u0c2a\u0c4d\u0c2a\u0c41.",
      },
      {
        url:
          "https://factly.in/it-raids-on-believers-eastern-church-press-release-states-around-rs-6-crores-unexplained-cash-found/",
        headline:
          " IT raids on Believers Eastern Church: Press release states around Rs. 6 crores unexplained cash found.",
      },
      {
        url: "https://factly.in/telugu-jk-had-many-ministers-who-are-hindu/",
        headline:
          " \u0c1c\u0c2e\u0c4d\u0c2e\u0c42 \u0c15\u0c3e\u0c36\u0c4d\u0c2e\u0c40\u0c30\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c3e\u0c32\u0c32\u0c4b \u0c07\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f\u0c35\u0c30\u0c15\u0c41 \u0c39\u0c3f\u0c02\u0c26\u0c41\u0c35\u0c41\u0c32\u0c41 \u0c2e\u0c02\u0c24\u0c4d\u0c30\u0c41\u0c32\u0c41\u0c17\u0c3e \u0c2a\u0c28\u0c3f\u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c28\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c15\u0c4d\u0c32\u0c46\u0c2f\u0c3f\u0c2e\u0c4d \u0c24\u0c2a\u0c4d\u0c2a\u0c41",
      },
      {
        url:
          "https://factly.in/central-government-hasnt-provided-any-additional-assistance-for-the-hyderabad-floods-so-far/",
        headline:
          " Central Government hasn\u2019t provided any additional assistance for the Hyderabad Floods so far",
      },
      {
        url:
          "https://factly.in/telugu-central-government-debts-do-not-include-those-raised-by-state-both-are-separate/",
        headline:
          " \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c05\u0c2a\u0c4d\u0c2a\u0c41\u0c32 \u0c32\u0c46\u0c15\u0c4d\u0c15\u0c32\u0c32\u0c4b \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c05\u0c2a\u0c4d\u0c2a\u0c41\u0c32\u0c41 \u0c32\u0c46\u0c15\u0c4d\u0c15\u0c3f\u0c02\u0c1a\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-low-quality-video-footage-falsely-shared-as-ktr-asking-to-vote-for-bjp/",
        headline:
          " \u0c24\u0c15\u0c4d\u0c15\u0c41\u0c35 \u0c15\u0c4d\u0c35\u0c3e\u0c32\u0c3f\u0c1f\u0c40 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 KTR \u0c24\u0c28 \u0c30\u0c4b\u0c21\u0c4d \u0c37\u0c4b \u0c32\u0c4b \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c15\u0c3f \u0c35\u0c4b\u0c1f\u0c41 \u0c35\u0c47\u0c2f\u0c2e\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/23/fact-check-no-this-viral-video-of-light-show-is-not-from-rajasthan-heres-the-truth/",
        headline:
          "Fact Check: No, this viral video of light show is not from Rajasthan; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/23/fact-check-were-shlokas-recited-at-the-white-house-for-the-incoming-new-president-heres-the-truth/",
        headline:
          "Fact Check: Were \u2018shlokas\u2019 recited at the White House for the incoming new President? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/23/fact-check-have-delhi-markets-been-put-under-lockdown-for-20-days-heres-the-truth/",
        headline:
          "Fact Check: Have Delhi markets been put under lockdown for 20 days? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/25/fact-check-edited-old-videos-being-shared-as-gas-explosion-in-mangalore-heres-the-truth/",
        headline:
          "Fact Check: Edited old videos being shared as gas explosion in Mangalore; here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-showing-pele-crying-at-maradonas-grave-is-morphed-and-fake-10864",
        headline:
          "Photo Showing Pele Crying At Maradona's Grave Is Morphed And Fake",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/morphed-image-of-pm-modi-at-longewala-jaisalmer-viral-10771",
        headline:
          "\u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u099c\u09df\u09b8\u09be\u09b2\u09ae\u09c7\u09b0\u09c7\u09b0 \u09b2\u09cb\u0999\u09cd\u0997\u09c7\u0993\u09df\u09be\u09b2\u09be \u09b8\u09ab\u09b0\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-showing-pele-crying-at-maradonas-grave-is-morphed-and-fake-10868",
        headline:
          "\u09ae\u09be\u09b0\u09be\u09a6\u09cb\u09a8\u09be\u09b0 \u0995\u09ac\u09b0\u09c7 \u09ac\u09bf\u09ae\u09b0\u09cd\u09b7 \u09aa\u09c7\u09b2\u09c7? \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb \u0993 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/picture-of-pele-mourning-at-maradona-grave-is-morphed-1744930-2020-11-28",
        headline:
          "Fact Check: Picture of Pele mourning at Maradona's grave is morphed\u00a0",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/23/fact-check-no-this-viral-video-of-light-show-is-not-from-rajasthan-heres-the-truth/",
        headline:
          "Fact Check: No, this viral video of light show is not from Rajasthan; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/23/fact-check-were-shlokas-recited-at-the-white-house-for-the-incoming-new-president-heres-the-truth/",
        headline:
          "Fact Check: Were \u2018shlokas\u2019 recited at the White House for the incoming new President? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/23/fact-check-have-delhi-markets-been-put-under-lockdown-for-20-days-heres-the-truth/",
        headline:
          "Fact Check: Have Delhi markets been put under lockdown for 20 days? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/25/fact-check-edited-old-videos-being-shared-as-gas-explosion-in-mangalore-heres-the-truth/",
        headline:
          "Fact Check: Edited old videos being shared as gas explosion in Mangalore; here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/photo-showing-pele-crying-at-maradonas-grave-is-morphed-and-fake-10864",
        headline:
          "Photo Showing Pele Crying At Maradona's Grave Is Morphed And Fake",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/photo-showing-pele-crying-at-maradonas-grave-is-morphed-and-fake-10868",
        headline:
          "\u09ae\u09be\u09b0\u09be\u09a6\u09cb\u09a8\u09be\u09b0 \u0995\u09ac\u09b0\u09c7 \u09ac\u09bf\u09ae\u09b0\u09cd\u09b7 \u09aa\u09c7\u09b2\u09c7? \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb \u0993 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/morphed-image-of-pm-modi-at-longewala-jaisalmer-viral-10771",
        headline:
          "\u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u099c\u09df\u09b8\u09be\u09b2\u09ae\u09c7\u09b0\u09c7\u09b0 \u09b2\u09cb\u0999\u09cd\u0997\u09c7\u0993\u09df\u09be\u09b2\u09be \u09b8\u09ab\u09b0\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2019-photo-falsely-shared-as-ashok-gehlot-bursting-crackers-on-diwali-10766",
        headline: "           ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-morphed-photo-shows-footballer-pele-at-maradonas-grave-671095",
        headline:
          "Fact Check: Morphed photo shows footballer Pele at Maradona's grave",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-beware-of-fake-tweet-edited-under-raja-singhs-handle-670838",
        headline:
          "Fact Check: Beware of fake tweet edited under Raja Singh's handle",
      },
    ],
    "6": [
      {
        url:
          "https://www.altnews.in/hindi/video-of-ujjain-police-detaining-people-for-not-wearing-masks-viral-as-delhi/",
        headline:
          "\u0909\u091c\u094d\u091c\u0948\u0928 \u092e\u0947\u0902 \u092c\u093f\u0928\u093e \u092e\u093e\u0938\u094d\u0915 \u092a\u0930 10 \u0918\u0902\u091f\u0947 \u0915\u0940 \u091c\u0947\u0932 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u093f\u0932\u094d\u0932\u0940 \u0915\u093e \u092c\u0924\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/video-of-ujjain-police-detaining-people-for-not-wearing-masks-viral-as-delhi/",
        headline:
          "Video of Ujjain police detaining people for not wearing masks viral as Delhi",
      },
      {
        url:
          "https://www.altnews.in/delhi-cop-seema-dhaka-traced-76-missing-children-soon-after-many-twitter-accounts-created-using-her-name/",
        headline:
          "Fake Twitter account created in the name of Delhi cop Seema Dhaka",
      },
      {
        url:
          "https://factly.in/video-of-jabri-gair-tradition-falsely-portrayed-as-diwali-celebrations-in-rajasthan/",
        headline:
          " Video of \u2018Jabri Gair\u2019 tradition falsely portrayed as Diwali celebrations in Rajasthan",
      },
      {
        url:
          "https://factly.in/this-video-shows-police-detaining-people-without-mask-in-ujjain-not-delhi/",
        headline:
          " This video shows police detaining people without a mask in Ujjain, not Delhi",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/24/fact-check-no-cm-ashok-gehlot-did-not-visit-mosque-after-banning-crackers-in-rajasthan-heres-the-truth/",
        headline:
          "Fact Check: No, CM Ashok Gehlot did NOT visit mosque after banning crackers in Rajasthan; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/25/fact-check-this-viral-video-of-cops-arresting-people-without-masks-is-not-from-delhi-heres-the-truth/",
        headline:
          "Fact Check: This viral video of cops arresting people without masks is NOT from Delhi; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/27/fact-check-old-picture-of-snowclad-street-from-kabul-falsely-shared-as-recent-one-from-karachi/",
        headline:
          "Fact Check: Old picture of snowclad street from Kabul falsely shared as recent one from Karachi",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2019-photo-falsely-shared-as-ashok-gehlot-bursting-crackers-on-diwali-10759",
        headline:
          "2019 Photo Falsely Shared As Ashok Gehlot Bursting Crackers On Diwali",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-cops-rounding-up-people-without-mask-in-mp-viral-as-delhi-10804",
        headline:
          "Video Of Cops Rounding Up People Without Mask In MP Viral As Delhi",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2019-photo-falsely-shared-as-ashok-gehlot-bursting-crackers-on-diwali-10766",
        headline:
          "\u0905\u0936\u094b\u0915 \u0917\u0947\u0939\u0932\u094b\u0924 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0907\u0938 \u0926\u093f\u0935\u093e\u0932\u0940 \u0938\u0947\u0932\u093f\u092c\u094d\u0930\u0947\u0936\u0928 \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/ujjain-police-video-viral-as-no-mask-10-hrs-jail-in-delhi-10833",
        headline:
          "\u0909\u091c\u094d\u091c\u0948\u0928 \u092a\u0941\u0932\u093f\u0938 \u0926\u094d\u0935\u093e\u0930\u093e \u0932\u094b\u0917\u094b\u0902 \u0915\u094b \u092a\u0915\u095c\u0928\u0947 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u093f\u0932\u094d\u0932\u0940 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0939\u0941\u0906 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019-photo-falsely-shared-as-ashok-gehlot-bursting-crackers-on-diwali-10850",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09a6\u09c0\u09aa\u09be\u09ac\u09b2\u09bf\u09a4\u09c7 \u0985\u09b6\u09cb\u0995 \u0997\u09b9\u09b2\u09cc\u09a4\u09c7\u09b0 \u09ac\u09be\u099c\u09bf \u09aa\u09cb\u09dc\u09be\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-share-shikhar-dhawan-s-cropped-image-to-question-bcci-s-patriotism-1744079-2020-11-25",
        headline:
          "Fact Check: Netizens share Shikhar Dhawan\u2019s cropped image to question BCCI\u2019s patriotism",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-white-wonderland-is-not-a-street-in-karachi-1744450-2020-11-26",
        headline:
          "Fact Check: This white wonderland is not a street in Karachi",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/24/fact-check-no-cm-ashok-gehlot-did-not-visit-mosque-after-banning-crackers-in-rajasthan-heres-the-truth/",
        headline:
          "Fact Check: No, CM Ashok Gehlot did NOT visit mosque after banning crackers in Rajasthan; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/25/fact-check-this-viral-video-of-cops-arresting-people-without-masks-is-not-from-delhi-heres-the-truth/",
        headline:
          "Fact Check: This viral video of cops arresting people without masks is NOT from Delhi; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/27/fact-check-old-picture-of-snowclad-street-from-kabul-falsely-shared-as-recent-one-from-karachi/",
        headline:
          "Fact Check: Old picture of snowclad street from Kabul falsely shared as recent one from Karachi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2019-photo-falsely-shared-as-ashok-gehlot-bursting-crackers-on-diwali-10759",
        headline:
          "2019 Photo Falsely Shared As Ashok Gehlot Bursting Crackers On Diwali",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-cops-rounding-up-people-without-mask-in-mp-viral-as-delhi-10804",
        headline:
          "Video Of Cops Rounding Up People Without Mask In MP Viral As Delhi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-rajputs-defying-a-fire-cracker-ban-during-diwali-10778",
        headline:
          "No, This Video Does Not Show Rajputs Defying A Fire Cracker Ban During Diwali",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2019-photo-falsely-shared-as-ashok-gehlot-bursting-crackers-on-diwali-10850",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09a6\u09c0\u09aa\u09be\u09ac\u09b2\u09bf\u09a4\u09c7 \u0985\u09b6\u09cb\u0995 \u0997\u09b9\u09b2\u09cc\u09a4\u09c7\u09b0 \u09ac\u09be\u099c\u09bf \u09aa\u09cb\u09dc\u09be\u09a8\u09cb\u09b0 \u099b\u09ac\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/ujjain-police-video-viral-as-no-mask-10-hrs-jail-in-delhi-10833",
        headline:
          "\u0909\u091c\u094d\u091c\u0948\u0928 \u092a\u0941\u0932\u093f\u0938 \u0926\u094d\u0935\u093e\u0930\u093e \u0932\u094b\u0917\u094b\u0902 \u0915\u094b \u092a\u0915\u095c\u0928\u0947 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u093f\u0932\u094d\u0932\u0940 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0939\u0941\u0906 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/fake-screenshot-claims-sambit-patras-daughter-eloped-with-a-muslim-man-10820",
        headline: "   ,    ''    ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-ashok-gehlot-bursting-firecrackers-in-2019-being-shared-as-recent-diwali-celebrations-670852",
        headline:
          "Fact Check: Photo of Ashok Gehlot bursting firecrackers in 2019 being shared as recent Diwali celebrations",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-international-fleet-review-passed-off-as-diwali-celebrations-by-indian-navy-670841",
        headline:
          "Fact Check: Viral video of 'International Fleet Review' passed off as Diwali celebrations by Indian Navy",
      },
    ],
    "7": [
      {
        url:
          "https://factly.in/telugu-the-si-and-labourer-in-this-photo-are-not-same-person/",
        headline:
          " \u0c1a\u0c47\u0c24\u0c3f\u0c32\u0c4b \u0c2c\u0c3f\u0c21\u0c4d\u0c21, \u0c24\u0c32\u0c2a\u0c48 \u0c30\u0c4b\u0c33\u0c4d\u0c32\u0c41 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c28\u0c3f \u0c09\u0c28\u0c4d\u0c28 \u0c2e\u0c39\u0c3f\u0c33 \u0c2e\u0c30\u0c3f\u0c2f\u0c41 \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41 \u0c2f\u0c42\u0c28\u0c3f\u0c2b\u0c3e\u0c02\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c2e\u0c39\u0c3f\u0c33 \u0c12\u0c15\u0c30\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/movie-visuals-shared-as-footage-of-savarkar-shot-by-a-british-journalist-in-andaman-jail/",
        headline:
          " Movie visuals being shared as real footage of Savarkar shot by a British journalist in Andaman jail",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/23/fact-check-did-centre-cancel-newspaper-titles-and-remove-them-from-davp-list-heres-the-truth/",
        headline:
          "Fact Check: Did Centre cancel newspaper titles and remove them from DAVP list? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/24/fact-check-has-pakistani-cricket-chacha-sufi-abdul-jalil-passed-away-heres-the-truth/",
        headline:
          "Fact Check: Has Pakistani \u2018Cricket Chacha\u2019 Sufi Abdul Jalil passed away? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-is-this-the-actual-footage-of-freedom-fighter-veer-savarkar-in-prison-heres-the-truth/",
        headline:
          "Fact Check: Is this the actual footage of freedom fighter Veer Savarkar in prison? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/27/fact-check-is-railways-planning-to-deduct-overtime-travel-allowances-of-13-lakh-employees-heres-the-truth/",
        headline:
          "Fact Check: Is Railways planning to deduct overtime, travel allowances of 13 Lakh employees? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-woman-in-farmers-protest-photo-is-not-shaheen-baghs-bilkis-dadi-10860",
        headline:
          "No, Woman In Farmers' Protest Photo Is Not Shaheen Bagh's Bilkis Dadi",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/women-in-the-farmers-protest-picture-is-not-shaheen-baghs-bilkis-dadi-10861",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915 : \u0915\u094d\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0940 \u092f\u0939 \u092c\u0941\u095b\u0941\u0930\u094d\u0917 \u092e\u0939\u093f\u0932\u093e \u0936\u093e\u0939\u0940\u0928 \u092c\u093e\u095a \u0915\u0940 '\u0926\u093e\u0926\u0940' \u0939\u0948?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-who-is-this-woman-removing-a-trs-banner-in-hyderabad-1743497-2020-11-24",
        headline:
          "Fact Check: Who is this woman removing a TRS banner in Hyderabad?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-viral-video-of-baby-giraffes-is-too-cute-to-be-true-1743490-2020-11-24",
        headline:
          "Fact Check: This viral video of 'baby giraffes' is too cute to be true",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/23/fact-check-did-centre-cancel-newspaper-titles-and-remove-them-from-davp-list-heres-the-truth/",
        headline:
          "Fact Check: Did Centre cancel newspaper titles and remove them from DAVP list? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/24/fact-check-has-pakistani-cricket-chacha-sufi-abdul-jalil-passed-away-heres-the-truth/",
        headline:
          "Fact Check: Has Pakistani \u2018Cricket Chacha\u2019 Sufi Abdul Jalil passed away? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-is-this-the-actual-footage-of-freedom-fighter-veer-savarkar-in-prison-heres-the-truth/",
        headline:
          "Fact Check: Is this the actual footage of freedom fighter Veer Savarkar in prison? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/27/fact-check-is-railways-planning-to-deduct-overtime-travel-allowances-of-13-lakh-employees-heres-the-truth/",
        headline:
          "Fact Check: Is Railways planning to deduct overtime, travel allowances of 13 Lakh employees? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-woman-in-farmers-protest-photo-is-not-shaheen-baghs-bilkis-dadi-10860",
        headline:
          "No, Woman In Farmers' Protest Photo Is Not Shaheen Bagh's Bilkis Dadi",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/image-from-accident-in-ukraine-shared-as-west-bengal-10834",
        headline: "     ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/women-in-the-farmers-protest-picture-is-not-shaheen-baghs-bilkis-dadi-10861",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915 : \u0915\u094d\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0940 \u092f\u0939 \u092c\u0941\u095b\u0941\u0930\u094d\u0917 \u092e\u0939\u093f\u0932\u093e \u0936\u093e\u0939\u0940\u0928 \u092c\u093e\u095a \u0915\u0940 '\u0926\u093e\u0926\u0940' \u0939\u0948?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-baby-giraffes-in-viral-video-are-not-real-but-3d-animation-670920",
        headline:
          "Fact check: Baby giraffes in viral video are not real, but 3D animation",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-man-in-viral-video-is-not-veer-savarkar-but-actor-playing-his-character-in-1983-movie-671001",
        headline:
          "Fact Check: Man in viral video is not Veer Savarkar, but actor playing his character in 1983 movie",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-aimim-has-not-written-to-centre-seeking-inclusion-of-rohingyas-in-voter-list-670958",
        headline:
          "Fact check: No, AIMIM has not written to Centre seeking inclusion of Rohingyas in voter list",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-v6-velugu-did-not-conduct-any-opinion-poll-in-hyderabad-671048",
        headline:
          "Fact Check: No, V6 Velugu did not conduct any opinion poll in Hyderabad.",
      },
    ],
    "8": [
      {
        url:
          "https://www.altnews.in/hindi/false-dataset-made-viral-to-claim-us-elections-registered-more-votes-than-voters/",
        headline:
          "\u0938\u094b\u0936\u0932 \u092e\u0940\u0921\u093f\u092f\u093e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u090f \u095e\u0930\u094d\u095b\u0940 \u0906\u0902\u0915\u095c\u0947, \u0926\u093e\u0935\u093e \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0915\u093f \u0905\u092e\u0930\u0940\u0915\u093e \u092e\u0947\u0902 \u0935\u094b\u091f\u0930\u094d\u0938 \u0938\u0947 \u095b\u094d\u092f\u093e\u0926\u093e \u0935\u094b\u091f \u092a\u095c\u0947",
      },
      {
        url:
          "https://www.altnews.in/hindi/spoof-video-from-15-years-ago-falsely-viral-as-volkswagen-ad-mocks-muslims/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915 : \u0915\u094d\u092f\u093e \u092b\u093c\u0949\u0915\u094d\u0938\u0935\u0947\u0917\u0928 \u0928\u0947 \u0935\u093f\u091c\u094d\u091e\u093e\u092a\u0928 \u092c\u0928\u093e\u0915\u0930 \u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0915\u093e \u2018\u092e\u095b\u093e\u0915\u2019 \u092c\u0928\u093e\u092f\u093e ?",
      },
      {
        url:
          "https://www.altnews.in/hindi/fact-check-video-viral-with-false-claim-that-miracle-rain-shower-only-on-nataraj-idol-in-tamilnadu/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915 : \u0924\u092e\u093f\u0932\u0928\u093e\u0921\u0941 \u0915\u0947 \u092e\u0902\u0926\u093f\u0930 \u092e\u0947\u0902 \u0938\u093f\u0930\u094d\u095e \u0928\u091f\u0930\u093e\u091c \u0915\u0940 \u092e\u0942\u0930\u094d\u0924\u093f \u092a\u0930 \u0939\u0940 \u092c\u093e\u0930\u093f\u0936 \u0939\u0941\u0908?",
      },
      {
        url:
          "https://www.altnews.in/old-video-from-pakistan-shared-as-cyclone-nivar-in-chennai/",
        headline:
          "Old video from Karachi viral as fallen billboard injures bikers during Cyclone Nivar in Tamil Nadu",
      },
      {
        url:
          "https://www.altnews.in/spoof-video-from-15-years-ago-falsely-viral-as-volkswagen-ad-mocks-muslims/",
        headline:
          "Spoof video from 15 years ago falsely viral as Volkswagen ad \u2018mocks\u2019 Muslims",
      },
      {
        url:
          "https://www.altnews.in/false-dataset-made-viral-to-claim-us-elections-registered-more-votes-than-voters/",
        headline:
          "False dataset made viral to claim US elections registered more votes than voters",
      },
      {
        url:
          "https://factly.in/telugu-media-logos-falsely-used-to-target-bjp-in-ghmc-elections/",
        headline:
          " \u0c35\u0c3f\u0c35\u0c3f\u0c27 \u0c35\u0c3e\u0c30\u0c4d\u0c24\u0c3e\u0c38\u0c02\u0c38\u0c4d\u0c25\u0c32 \u0c32\u0c4b\u0c17\u0c4b\u0c32\u0c41 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, GHMC \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c2a\u0c48 \u0c2b\u0c47\u0c15\u0c4d \u0c35\u0c3e\u0c30\u0c4d\u0c24\u0c32\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-2005-spoof-video-is-shared-as-terrorist-commercial-ad-by-volkswagen/",
        headline:
          " \u0c12\u0c15 \u0c09\u0c17\u0c4d\u0c30\u0c35\u0c3e\u0c26\u0c3f \u0c06\u0c24\u0c4d\u0c2e\u0c3e\u0c39\u0c41\u0c24\u0c3f \u0c26\u0c3e\u0c21\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c08 \u0c2a\u0c4d\u0c30\u0c15\u0c1f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c35\u0c4b\u0c15\u0c4d\u0c38\u0c4d \u0c35\u0c3e\u0c17\u0c28\u0c4d \u0c38\u0c02\u0c38\u0c4d\u0c25 \u0c28\u0c3f\u0c30\u0c4d\u0c2e\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-volkswagen-advertisement-about-a-suicide-bomber-is-an-old-spoof-video/",
        headline:
          " This Volkswagen advertisement about a suicide bomber is an old spoof video.",
      },
      {
        url:
          "https://factly.in/video-of-billboard-falling-on-commuters-in-karachi-falsely-linked-to-chennai/",
        headline:
          " Video of billboard falling on commuters in Karachi falsely linked to Chennai",
      },
      {
        url:
          "https://factly.in/unrelated-old-videos-are-being-shared-in-the-context-of-cyclone-nivar/",
        headline:
          " Unrelated old videos are being shared in the context of Cyclone Nivar",
      },
      {
        url:
          "https://factly.in/old-video-of-flood-hit-marina-beach-is-being-passed-off-as-recent-one/",
        headline:
          " Old video of flood-hit Marina beach is being passed off as a recent one.",
      },
      {
        url:
          "https://factly.in/telugu-video-of-billboard-falling-on-commuters-in-karachi-falsely-linked-to-chennai/",
        headline:
          " \u0c2c\u0c48\u0c15\u0c4d \u0c2a\u0c48\u0c28 \u0c2a\u0c4d\u0c30\u0c2f\u0c3e\u0c23\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c3f\u0c2a\u0c48 \u0c39\u0c3e\u0c30\u0c4d\u0c21\u0c3f\u0c02\u0c17\u0c4d \u0c2a\u0c21\u0c4d\u0c21 \u0c08 \u0c18\u0c1f\u0c28 \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c15\u0c30\u0c3e\u0c1a\u0c40\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f, \u0c1a\u0c46\u0c28\u0c4d\u0c28\u0c48\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-flood-hit-marina-beach-is-being-passed-off-as-a-recent-one/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c28\u0c3f\u0c35\u0c30\u0c4d \u0c24\u0c41\u0c2b\u0c3e\u0c28\u0c41 \u0c35\u0c32\u0c4d\u0c32 \u0c1a\u0c46\u0c28\u0c4d\u0c28\u0c48\u0c32\u0c4b\u0c28\u0c3f \u0c2e\u0c30\u0c40\u0c28\u0c3e \u0c2c\u0c40\u0c1a\u0c4d \u0c2e\u0c41\u0c28\u0c3f\u0c17\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c02\u0c26\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://digiteye.in/no-1000-kg-meat-supplies-confiscated-in-chennai-egmore-station-not-of-dog-fact-check/",
        headline:
          "No, 1000 kg meat supplies confiscated in Chennai Egmore station not of Dog; Fact Check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/23/fact-check-have-bjp-and-aimim-joined-hands-for-upcoming-wb-assembly-polls-heres-the-truth/",
        headline:
          "Fact Check: Have BJP and AIMIM joined hands for upcoming WB Assembly polls? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/25/fact-check-no-government-is-not-paying-%e2%82%b9130000-to-citizens-above-the-age-of-18-heres-the-truth/",
        headline:
          "Fact Check: No, Government is NOT paying \u20b9130,000 to citizens above the age of 18; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/25/fact-check-spoof-video-from-2005-being-shared-as-volkswagen-terrorist-commercial-heres-the-truth/",
        headline:
          "Fact Check: Spoof video from 2005 being shared as \u2018Volkswagen terrorist commercial\u2019; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-was-president-elect-joe-biden-partying-with-his-friends-without-a-mask-heres-the-truth/",
        headline:
          "Fact Check: Was President-elect Joe Biden partying with his friends without mask? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/26/fact-check-in-the-aftermath-of-cyclone-nivar-accident-video-from-pak-falsely-claimed-to-be-from-chennai/",
        headline:
          "Fact Check: In the aftermath of Cyclone Nivar, accident video from Pak, falsely claimed to be from Chennai",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/27/fact-check-2017-video-of-chennai-floods-falsely-shared-as-that-of-cyclone-nivar/",
        headline:
          "Fact Check: 2017 video of Chennai floods falsely shared as that of Cyclone Nivar",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/28/fact-check-this-video-of-tornado-is-not-of-cyclone-nivar-from-chennai-heres-the-truth/",
        headline:
          "Fact Check: This video of tornado is NOT of Cyclone Nivar from Chennai; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/15-year-old-spoof-video-revived-as-terrorist-commercial-by-volkswagen-10782",
        headline:
          "15-Year Old Spoof Video Revived As 'Terrorist Commercial By Volkswagen'",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/image-from-accident-in-ukraine-shared-as-west-bengal-10821",
        headline: "Image From Accident In Ukraine Shared As West Bengal",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/15-year-old-spoof-video-revived-as-terrorist-commercial-by-volkswagen-10840",
        headline:
          "\u0938\u094d\u092a\u0942\u095e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0949\u0915\u094d\u0938\u0935\u0948\u0917\u0928 \u0915\u093e \u090f\u0921 \u0939\u094b\u0928\u0947 \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-from-accident-in-ukraine-shared-as-west-bengal-10834",
        headline:
          "\u0987\u0989\u0995\u09cd\u09b0\u09c7\u09a8\u09c7\u09b0 \u09a6\u09c2\u09b0\u09cd\u0998\u099f\u09a8\u09be\u09b0 \u099b\u09ac\u09bf \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-a-flying-billboard-injuring-bikers-in-karachi-shared-as-chennai-10838",
        headline:
          "\u09ac\u09bf\u09b2\u09ac\u09cb\u09b0\u09cd\u09a1 \u0986\u099b\u09a1\u09bc\u09c7 \u09ac\u09be\u0987\u0995 \u09b8\u09be\u0993\u09df\u09be\u09b0\u09bf\u09b0 \u0989\u09aa\u09b0, \u0995\u09b0\u09be\u099a\u09bf\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099b\u09a1\u09bc\u09be\u09b2 \u099a\u09c7\u09a8\u09cd\u09a8\u09be\u0987\u09df\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-2017-video-of-flooded-marina-beach-shared-as-recent-cyclone-nivar-visuals-in-chennai-1744398-2020-11-26",
        headline:
          " Fact Check: 2017 video of flooded Marina beach shared as recent Cyclone Nivar visuals in Chennai",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/23/fact-check-have-bjp-and-aimim-joined-hands-for-upcoming-wb-assembly-polls-heres-the-truth/",
        headline:
          "Fact Check: Have BJP and AIMIM joined hands for upcoming WB Assembly polls? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/25/fact-check-no-government-is-not-paying-%e2%82%b9130000-to-citizens-above-the-age-of-18-heres-the-truth/",
        headline:
          "Fact Check: No, Government is NOT paying \u20b9130,000 to citizens above the age of 18; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/25/fact-check-spoof-video-from-2005-being-shared-as-volkswagen-terrorist-commercial-heres-the-truth/",
        headline:
          "Fact Check: Spoof video from 2005 being shared as \u2018Volkswagen terrorist commercial\u2019; here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-was-president-elect-joe-biden-partying-with-his-friends-without-a-mask-heres-the-truth/",
        headline:
          "Fact Check: Was President-elect Joe Biden partying with his friends without mask? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/26/fact-check-in-the-aftermath-of-cyclone-nivar-accident-video-from-pak-falsely-claimed-to-be-from-chennai/",
        headline:
          "Fact Check: In the aftermath of Cyclone Nivar, accident video from Pak, falsely claimed to be from Chennai",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/27/fact-check-2017-video-of-chennai-floods-falsely-shared-as-that-of-cyclone-nivar/",
        headline:
          "Fact Check: 2017 video of Chennai floods falsely shared as that of Cyclone Nivar",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/28/fact-check-this-video-of-tornado-is-not-of-cyclone-nivar-from-chennai-heres-the-truth/",
        headline:
          "Fact Check: This video of tornado is NOT of Cyclone Nivar from Chennai; Here\u2019s the truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fake-tweet-screenshot-claims-bjp-aimim-alliance-for-west-bengal-polls-10790",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u099f\u09c1\u0987\u099f\u09c7\u09b0 \u09a6\u09be\u09ac\u09bf \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7\u09b0 \u09ad\u09cb\u099f\u09c7 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u0993 \u0993\u09df\u09c7\u0987\u09b8\u09bf\u09b0 \u098f\u0986\u0987\u098f\u09ae\u0986\u0987\u098f\u09ae \u098f\u09b0 \u099c\u09cb\u099f",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/image-from-accident-in-ukraine-shared-as-west-bengal-10821",
        headline: "Image From Accident In Ukraine Shared As West Bengal",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/fake-tweet-screenshot-claims-bjp-aimim-alliance-for-west-bengal-polls-10776",
        headline:
          "Fake Tweet Screenshot Claims BJP-AIMIM Alliance For West Bengal Polls",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/15-year-old-spoof-video-revived-as-terrorist-commercial-by-volkswagen-10782",
        headline:
          "15-Year Old Spoof Video Revived As 'Terrorist Commercial By Volkswagen'",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/cgi-clip-of-a-tornado-shared-as-cyclone-nivar-making-landfall-10837",
        headline:
          "CGI Clip Of A Tornado Shared As Cyclone Nivar Making Landfall",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-flying-hoarding-injuring-bikers-in-pak-viral-as-cyclone-nivar-10794",
        headline:
          "Video Of Flying Hoarding Injuring Bikers In Pak Viral As Cyclone Nivar",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/video-of-a-flying-billboard-injuring-bikers-in-karachi-shared-as-chennai-10838",
        headline:
          "\u09ac\u09bf\u09b2\u09ac\u09cb\u09b0\u09cd\u09a1 \u0986\u099b\u09a1\u09bc\u09c7 \u09ac\u09be\u0987\u0995 \u09b8\u09be\u0993\u09df\u09be\u09b0\u09bf\u09b0 \u0989\u09aa\u09b0, \u0995\u09b0\u09be\u099a\u09bf\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099b\u09a1\u09bc\u09be\u09b2 \u099a\u09c7\u09a8\u09cd\u09a8\u09be\u0987\u09df\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/26-11-mumbai-terror-attacks-film-stills-used-to-pay-tribute-to-died-cop-tukaram-omble-10823",
        headline: "/         ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/cgi-clip-of-a-tornado-shared-as-cyclone-nivar-making-landfall-10849",
        headline:
          "\u0938\u0940.\u091c\u0940.\u0906\u0908 \u0915\u094d\u0932\u093f\u092a \u0915\u094b \u091a\u0947\u0928\u094d\u0928\u0908 \u092e\u0947\u0902 '\u0928\u093f\u0935\u093e\u0930' \u0924\u0942\u095e\u093e\u0928 \u0915\u093e \u0935\u093f\u0928\u093e\u0936 \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-from-chhattisgarh-falsely-linked-to-adanis-coal-operations-in-goa-10784",
        headline: "            ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/15-year-old-spoof-video-revived-as-terrorist-commercial-by-volkswagen-10840",
        headline:
          "\u0938\u094d\u092a\u0942\u095e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0949\u0915\u094d\u0938\u0935\u0948\u0917\u0928 \u0915\u093e \u090f\u0921 \u0939\u094b\u0928\u0947 \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/no-rss-chief-mohan-bhagwat-has-not-given-this-viral-statement-10818",
        headline: ",           ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-a-driver-attempting-suicide-bombing-is-spoof-not-volkswagens-car-ad-670977",
        headline:
          "Fact check: Viral video of a driver attempting suicide bombing is spoof, not Volkswagen's car ad",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-dont-fall-for-fake-tweet-about-bjp-forming-alliance-with-aimim-670947",
        headline:
          "Fact Check: Don't fall for fake tweet about BJP forming alliance with AIMIM",
      },
    ],
  },
  numpy_seed: 2,
  number_of_articles: 239,
}
