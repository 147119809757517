export const data = {
  mdsDat: {
    x: [
      -0.10512842537268302,
      0.3048267164165493,
      0.012131130150935381,
      0.1569208988034867,
      -0.03434960305706274,
      -0.3344007169412252,
    ],
    y: [
      -0.007852570545842139,
      0.10016574037703524,
      0.02922341193072471,
      0.09153502903951788,
      -0.39102768874493954,
      0.17795607794350396,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      12.607932530438148,
      8.533355175589334,
      31.4108652935087,
      23.364240936143325,
      12.759784429091631,
      11.323821635228857,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      25.0,
      12.0,
      75.0,
      16.0,
      9.0,
      11.0,
      9.0,
      7.0,
      7.0,
      9.0,
      4.002619593207453,
      2.0013097966037265,
      1.0006548983018633,
      1.0006548983018633,
      1.0006548983018633,
      1.0006548983018633,
      2.0013097966037265,
      2.0013097966037265,
      1.0006548983018633,
      1.0006548983018633,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      3.00196469490559,
      3.00196469490559,
      2.0013097966037265,
      2.0013097966037265,
      5.003274491509317,
      12.00785877962236,
      12.00785877962236,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      2.00015465237178,
      2.00015465237178,
      2.00015465237178,
      1.00007732618589,
      4.00030930474356,
      2.00015465237178,
      2.00015465237178,
      2.00015465237178,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      4.00030930474356,
      1.00007732618589,
      5.00038663092945,
      5.00038663092945,
      4.00030930474356,
      5.00038663092945,
      4.00030930474356,
      4.00030930474356,
      2.00015465237178,
      1.999453049647711,
      1.999453049647711,
      1.999453049647711,
      0.9997265248238555,
      0.9997265248238555,
      1.999453049647711,
      0.9997265248238555,
      0.9997265248238555,
      1.999453049647711,
      5.998359148943134,
      11.996718297886268,
      10.996991773062412,
      8.997538723414701,
      5.998359148943134,
      4.998632624119278,
      3.998906099295422,
      3.998906099295422,
      2.999179574471567,
      2.999179574471567,
      2.999179574471567,
      15.995624397181688,
      19.99453049647711,
      46.987146666721216,
      36.98988141848266,
      10.996991773062412,
      9.997265248238556,
      4.999788077461387,
      4.999788077461387,
      4.999788077461387,
      4.999788077461387,
      4.999788077461387,
      9.999576154922774,
      1.999915230984555,
      2.999872846476832,
      0.9999576154922775,
      0.9999576154922775,
      12.999449001399608,
      6.999703308445943,
      5.999745692953664,
      5.999745692953664,
      5.999745692953664,
      5.999745692953664,
      9.999576154922774,
      5.999745692953664,
      8.999618539430497,
      10.999533770415052,
      6.999703308445943,
      8.999618539430497,
      1.0000481046300567,
      2.0000962092601133,
      1.0000481046300567,
      1.0000481046300567,
      4.000192418520227,
      1.0000481046300567,
      1.0000481046300567,
      4.000192418520227,
      1.0000481046300567,
      2.0000962092601133,
      5.000240523150283,
      5.000240523150283,
      5.000240523150283,
      4.000192418520227,
      4.000192418520227,
      3.0001443138901696,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      6.000288627780339,
      4.000192418520227,
      6.0000305622156285,
      6.0000305622156285,
      3.0000152811078142,
      1.0000050937026048,
      2.0000101874052096,
      1.0000050937026048,
      1.0000050937026048,
      1.0000050937026048,
      1.0000050937026048,
      1.0000050937026048,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      6.0000305622156285,
      4.000020374810419,
      4.000020374810419,
    ],
    Term: [
      "link",
      "ambani",
      "farmer",
      "khalistan",
      "mukesh",
      "rally",
      "say",
      "bandh",
      "bharat_bandh",
      "bharat",
      "company",
      "join",
      "resurface",
      "citizenship",
      "marching",
      "agro",
      "sikhs",
      "mahasaya",
      "cholo",
      "add",
      "traffic_jam",
      "national_flag",
      "national",
      "separatist",
      "insult",
      "disrespect",
      "adani",
      "agri",
      "gulati",
      "nihang",
      "flag",
      "protest",
      "farmer",
      "delhi",
      "jam",
      "peasant",
      "volcano",
      "spilt",
      "memorandum",
      "manuel",
      "submit",
      "agitate",
      "arrest",
      "religion",
      "vandalism",
      "mayawati",
      "agricultural",
      "congress",
      "regard",
      "mount",
      "leader",
      "nadu",
      "abdullah",
      "call",
      "simi",
      "bandh",
      "bharat_bandh",
      "law",
      "bharat",
      "link",
      "farmer",
      "trudeau",
      "photos",
      "buddhadev",
      "rumors",
      "student",
      "recitation",
      "social",
      "shaheen",
      "context",
      "pose",
      "rajnath",
      "rally",
      "pro",
      "pro_khalistan",
      "rajnath_singh",
      "pass",
      "pakistan",
      "hold",
      "slogans",
      "kisan",
      "address",
      "khalistan",
      "link",
      "farmer",
      "protest",
      "movement",
      "ongoing",
      "grandson",
      "mukesh_ambani",
      "singh_mukesh",
      "indian_army",
      "captain",
      "mukesh",
      "capt",
      "see",
      "meeting",
      "feel",
      "ambani",
      "amarinder",
      "army",
      "prime",
      "minister",
      "prime_minister",
      "modi",
      "pm",
      "singh",
      "protest",
      "indian",
      "farmer",
      "moon",
      "developer",
      "cartoonist",
      "place",
      "maharashtra",
      "ssi",
      "centre",
      "long_march",
      "uk",
      "germany",
      "vaccine",
      "family",
      "covid",
      "long",
      "march",
      "covid_vaccine",
      "organize",
      "donation",
      "portrait",
      "kidney",
      "biotech",
      "turkish",
      "central",
      "government",
      "protest",
      "farmer",
      "jp",
      "nadda",
      "sonia",
      "name",
      "maharana",
      "policeman",
      "waitress",
      "anil",
      "parody",
      "mla",
      "bear",
      "visva",
      "born_visva",
      "say_rabindranath",
      "rabindranath",
      "jp_nadda",
      "bharati",
      "say",
      "spread",
      "protest",
    ],
    Total: [
      25.0,
      12.0,
      75.0,
      16.0,
      9.0,
      11.0,
      9.0,
      7.0,
      7.0,
      9.0,
      4.002619593207453,
      2.0013097966037265,
      1.0006548983018633,
      1.0006548983018633,
      1.0006548983018633,
      1.0006548983018633,
      2.0013097966037265,
      2.0013097966037265,
      1.0006548983018633,
      1.0006548983018633,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      4.002619593207453,
      3.00196469490559,
      3.00196469490559,
      2.0013097966037265,
      2.0013097966037265,
      6.003001016333172,
      70.99766029729672,
      75.99512570903786,
      10.000978742150586,
      6.002715802467566,
      9.00180935508423,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      2.00015465237178,
      2.00015465237178,
      2.00015465237178,
      1.00007732618589,
      4.00030930474356,
      2.00015465237178,
      2.00015465237178,
      2.00015465237178,
      1.00007732618589,
      1.00007732618589,
      1.00007732618589,
      5.000964203045423,
      1.00007732618589,
      7.0003018619140045,
      7.0003018619140045,
      6.0002245357281145,
      9.000398071174118,
      25.994755032205227,
      75.99512570903786,
      5.999985114340889,
      1.999453049647711,
      1.999453049647711,
      1.999453049647711,
      0.9997265248238555,
      0.9997265248238555,
      1.999453049647711,
      0.9997265248238555,
      0.9997265248238555,
      1.999453049647711,
      5.998359148943134,
      11.996718297886268,
      10.996991773062412,
      8.997538723414701,
      5.998359148943134,
      4.998632624119278,
      3.998906099295422,
      3.998906099295422,
      2.999179574471567,
      2.999179574471567,
      2.999179574471567,
      16.99627929548355,
      25.994755032205227,
      75.99512570903786,
      70.99766029729672,
      14.99891408346028,
      13.99860998652045,
      4.999788077461387,
      4.999788077461387,
      4.999788077461387,
      4.999788077461387,
      4.999788077461387,
      9.999576154922774,
      1.999915230984555,
      2.999872846476832,
      0.9999576154922775,
      0.9999576154922775,
      12.999449001399608,
      6.999703308445943,
      5.999745692953664,
      5.999745692953664,
      5.999745692953664,
      5.999745692953664,
      10.99930267974663,
      6.9994722177775195,
      14.99797768837363,
      70.99766029729672,
      14.001131240404453,
      75.99512570903786,
      1.0000481046300567,
      2.0000962092601133,
      1.0000481046300567,
      1.0000481046300567,
      4.000192418520227,
      1.0000481046300567,
      1.0000481046300567,
      4.000192418520227,
      1.0000481046300567,
      2.0000962092601133,
      5.000240523150283,
      5.000240523150283,
      5.000240523150283,
      4.000192418520227,
      4.000192418520227,
      3.0001443138901696,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      2.0000962092601133,
      70.99766029729672,
      75.99512570903786,
      6.0000305622156285,
      6.0000305622156285,
      3.0000152811078142,
      1.0000050937026048,
      2.0000101874052096,
      1.0000050937026048,
      1.0000050937026048,
      1.0000050937026048,
      1.0000050937026048,
      1.0000050937026048,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      4.000020374810419,
      9.00199525712122,
      13.00029009546196,
      70.99766029729672,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      2.0708,
      1.8887,
      0.2938,
      0.2257,
      1.1551,
      1.6656,
      1.2604,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.4612,
      2.2379,
      2.4612,
      2.1247,
      2.1247,
      2.0558,
      1.8734,
      0.5897,
      -0.4831,
      1.3627,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.158,
      1.0973,
      0.8956,
      0.6772,
      0.506,
      0.8477,
      0.8214,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.454,
      1.3587,
      1.2998,
      0.9432,
      -0.4108,
      0.7607,
      -0.6795,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      2.0589,
      -0.412,
      -0.8855,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      2.1783,
      1.7726,
      0.9996,
      -0.6981,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.6763,
      -4.3694,
      -5.0626,
      -5.0626,
      -5.0626,
      -5.0626,
      -4.3694,
      -4.3694,
      -5.0626,
      -5.0626,
      -3.6763,
      -3.6763,
      -3.6763,
      -3.6763,
      -3.6763,
      -3.6763,
      -3.964,
      -3.964,
      -4.3694,
      -4.3694,
      -3.4532,
      -2.5777,
      -2.5777,
      -3.6763,
      -3.6763,
      -3.6763,
      -4.6728,
      -4.6728,
      -4.6728,
      -4.6728,
      -4.6728,
      -4.6728,
      -3.9797,
      -3.9797,
      -3.9797,
      -4.6728,
      -3.2865,
      -3.9797,
      -3.9797,
      -3.9797,
      -4.6728,
      -4.6728,
      -4.6728,
      -3.2865,
      -4.6728,
      -3.0634,
      -3.0634,
      -3.2865,
      -3.0634,
      -3.2865,
      -3.2865,
      -3.9797,
      -5.2832,
      -5.2832,
      -5.2832,
      -5.9764,
      -5.9764,
      -5.2832,
      -5.9764,
      -5.9764,
      -5.2832,
      -4.1846,
      -3.4914,
      -3.5785,
      -3.7791,
      -4.1846,
      -4.3669,
      -4.5901,
      -4.5901,
      -4.8777,
      -4.8777,
      -4.8777,
      -3.2038,
      -2.9806,
      -2.1262,
      -2.3654,
      -3.5785,
      -3.6738,
      -4.0707,
      -4.0707,
      -4.0707,
      -4.0707,
      -4.0707,
      -3.3776,
      -4.987,
      -4.5816,
      -5.6802,
      -5.6802,
      -3.1152,
      -3.7343,
      -3.8884,
      -3.8884,
      -3.8884,
      -3.8884,
      -3.3776,
      -3.8884,
      -3.4829,
      -3.2823,
      -3.7343,
      -3.4829,
      -5.0752,
      -4.382,
      -5.0752,
      -5.0752,
      -3.6889,
      -5.0752,
      -5.0752,
      -3.6889,
      -5.0752,
      -4.382,
      -3.4657,
      -3.4657,
      -3.4657,
      -3.6889,
      -3.6889,
      -3.9766,
      -4.382,
      -4.382,
      -4.382,
      -4.382,
      -4.382,
      -4.382,
      -4.382,
      -4.382,
      -3.2834,
      -3.6889,
      -3.1641,
      -3.1641,
      -3.8572,
      -4.9558,
      -4.2627,
      -4.9558,
      -4.9558,
      -4.9558,
      -4.9558,
      -4.9558,
      -3.5695,
      -3.5695,
      -3.5695,
      -3.5695,
      -3.5695,
      -3.5695,
      -3.5695,
      -3.1641,
      -3.5695,
      -3.5695,
    ],
  },
  "token.table": {
    Topic: [
      2,
      1,
      1,
      3,
      2,
      1,
      2,
      1,
      4,
      4,
      6,
      4,
      2,
      2,
      4,
      6,
      2,
      4,
      5,
      2,
      4,
      6,
      5,
      6,
      3,
      1,
      2,
      4,
      4,
      5,
      5,
      5,
      1,
      1,
      1,
      2,
      3,
      5,
      5,
      1,
      3,
      5,
      1,
      5,
      5,
      1,
      2,
      3,
      4,
      5,
      4,
      1,
      3,
      5,
      5,
      4,
      1,
      3,
      1,
      3,
      4,
      6,
      4,
      1,
      1,
      5,
      1,
      6,
      6,
      1,
      3,
      5,
      3,
      2,
      4,
      2,
      2,
      3,
      4,
      5,
      5,
      6,
      5,
      1,
      2,
      5,
      1,
      2,
      4,
      2,
      4,
      6,
      3,
      4,
      5,
      2,
      1,
      3,
      4,
      4,
      4,
      6,
      2,
      6,
      1,
      1,
      1,
      1,
      2,
      3,
      4,
      5,
      3,
      6,
      3,
      1,
      3,
      6,
      3,
      5,
      3,
      4,
      6,
      5,
      3,
      4,
      4,
      3,
      3,
      1,
      2,
      3,
      4,
      5,
      6,
      6,
      3,
      3,
      3,
      3,
      2,
      2,
      1,
      3,
      1,
      6,
      6,
      4,
      1,
      3,
      1,
      2,
      3,
      4,
      4,
      3,
      3,
      6,
      2,
      1,
      3,
      4,
      5,
      6,
      5,
      3,
      2,
      1,
      2,
      4,
      5,
      5,
      5,
      2,
      6,
      2,
      6,
    ],
    Freq: [
      0.9999226797929868,
      0.9993455303092258,
      0.9993455303092258,
      1.0002735499852746,
      0.9999226797929868,
      0.9993455303092258,
      0.9999226797929868,
      0.9993455303092258,
      1.0000423863042451,
      1.0000423863042451,
      0.9999949063233409,
      1.0000423863042454,
      0.9999226797929868,
      0.714254913377823,
      0.2857019653511292,
      0.9999949063233409,
      0.5555309843476447,
      0.22221239373905785,
      0.22221239373905785,
      0.714254913377823,
      0.2857019653511292,
      0.9999949063233409,
      0.9999518976838875,
      0.9999949063233409,
      1.0002735499852748,
      0.1999614393142492,
      0.7998457572569968,
      1.0000423863042451,
      1.0000423863042451,
      0.9999518976838875,
      0.9999518976838875,
      0.9999518976838875,
      0.9993455303092258,
      0.9993455303092258,
      0.9993455303092258,
      0.9999226797929868,
      1.0002735499852748,
      0.9999518976838876,
      0.9999518976838876,
      0.3999608541453463,
      0.5999412812180195,
      0.9999518976838875,
      0.9993455303092258,
      0.9999518976838875,
      0.9999518976838876,
      0.15790486413489643,
      0.05263495471163215,
      0.6184607178616778,
      0.11842864810117233,
      0.05263495471163215,
      1.0000423863042451,
      0.8329167338795758,
      0.16658334677591516,
      0.9999518976838875,
      0.9999518976838875,
      1.0000423863042451,
      0.9993455303092258,
      1.0002735499852748,
      0.21426840078054568,
      0.1428456005203638,
      0.49995960182127325,
      0.1428456005203638,
      1.0000423863042451,
      0.9993455303092258,
      0.6663650473600132,
      0.3331825236800066,
      0.9993455303092258,
      0.999994906323341,
      0.9999949063233409,
      0.058836406640230474,
      0.9413825062436876,
      0.9999518976838875,
      1.0002735499852746,
      0.6666417191860319,
      0.33332085959301594,
      0.9999226797929868,
      0.1538771954205512,
      0.7693859771027559,
      0.0769385977102756,
      0.9999518976838875,
      0.9999518976838875,
      0.9999949063233409,
      0.9999518976838875,
      0.9993455303092258,
      0.9999226797929868,
      0.9999518976838875,
      0.9993455303092258,
      0.9999226797929868,
      1.0000423863042451,
      0.9999226797929868,
      1.0000423863042454,
      0.9999949063233409,
      0.09091485425174563,
      0.9091485425174562,
      0.9999518976838875,
      0.9999226797929868,
      0.20001447993546304,
      0.7333864264300312,
      0.06667149331182101,
      1.0000423863042451,
      1.0000423863042451,
      0.999994906323341,
      0.9999226797929868,
      0.9999949063233409,
      0.9993455303092258,
      0.9993455303092258,
      0.9993455303092258,
      0.14287132807656197,
      0.07143566403828099,
      0.7143566403828099,
      0.07143566403828099,
      0.9999518976838875,
      1.0002735499852748,
      0.9999949063233409,
      1.0002735499852748,
      0.4443551115355266,
      0.333266333651645,
      0.2221775557677633,
      1.0002735499852748,
      0.9999518976838875,
      0.14286791473508,
      0.85720748841048,
      0.9999949063233409,
      0.9999518976838875,
      1.0002735499852748,
      1.0000423863042454,
      1.0000423863042454,
      1.0002735499852748,
      1.0002735499852746,
      0.1690196543062266,
      0.014084971192185549,
      0.5211439341108653,
      0.15493468311404104,
      0.0845098271531133,
      0.056339884768742195,
      0.9999949063233409,
      1.0002735499852746,
      1.0002735499852746,
      1.0002735499852746,
      1.0002735499852748,
      0.9999226797929868,
      0.9999226797929868,
      0.9993455303092258,
      1.0002735499852748,
      0.3332594513007309,
      0.6665189026014618,
      0.9999949063233409,
      1.0000423863042454,
      0.9993455303092258,
      1.0002735499852748,
      0.9993455303092258,
      0.9999226797929868,
      0.40005393558167346,
      0.6000809033725102,
      1.0000423863042451,
      1.0002735499852746,
      1.0002735499852748,
      0.999994906323341,
      0.9999226797929868,
      0.15384272084037143,
      0.30768544168074285,
      0.07692136042018571,
      0.15384272084037143,
      0.30768544168074285,
      0.9999518976838875,
      1.0002735499852748,
      0.9999226797929868,
      0.9993455303092258,
      0.33333416031644675,
      0.6666683206328935,
      0.9999518976838875,
      0.9999518976838875,
      0.9999518976838876,
      0.9999226797929868,
      0.9999949063233409,
      0.9999226797929868,
      0.9999949063233409,
    ],
    Term: [
      "abdullah",
      "adani",
      "add",
      "address",
      "agitate",
      "agri",
      "agricultural",
      "agro",
      "amarinder",
      "ambani",
      "anil",
      "army",
      "arrest",
      "bandh",
      "bandh",
      "bear",
      "bharat",
      "bharat",
      "bharat",
      "bharat_bandh",
      "bharat_bandh",
      "bharati",
      "biotech",
      "born_visva",
      "buddhadev",
      "call",
      "call",
      "capt",
      "captain",
      "cartoonist",
      "central",
      "centre",
      "cholo",
      "citizenship",
      "company",
      "congress",
      "context",
      "covid",
      "covid_vaccine",
      "delhi",
      "delhi",
      "developer",
      "disrespect",
      "donation",
      "family",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "feel",
      "flag",
      "flag",
      "germany",
      "government",
      "grandson",
      "gulati",
      "hold",
      "indian",
      "indian",
      "indian",
      "indian",
      "indian_army",
      "insult",
      "jam",
      "jam",
      "join",
      "jp",
      "jp_nadda",
      "khalistan",
      "khalistan",
      "kidney",
      "kisan",
      "law",
      "law",
      "leader",
      "link",
      "link",
      "link",
      "long",
      "long_march",
      "maharana",
      "maharashtra",
      "mahasaya",
      "manuel",
      "march",
      "marching",
      "mayawati",
      "meeting",
      "memorandum",
      "minister",
      "mla",
      "modi",
      "modi",
      "moon",
      "mount",
      "movement",
      "movement",
      "movement",
      "mukesh",
      "mukesh_ambani",
      "nadda",
      "nadu",
      "name",
      "national",
      "national_flag",
      "nihang",
      "ongoing",
      "ongoing",
      "ongoing",
      "ongoing",
      "organize",
      "pakistan",
      "parody",
      "pass",
      "peasant",
      "peasant",
      "peasant",
      "photos",
      "place",
      "pm",
      "pm",
      "policeman",
      "portrait",
      "pose",
      "prime",
      "prime_minister",
      "pro",
      "pro_khalistan",
      "protest",
      "protest",
      "protest",
      "protest",
      "protest",
      "protest",
      "rabindranath",
      "rajnath",
      "rajnath_singh",
      "rally",
      "recitation",
      "regard",
      "religion",
      "resurface",
      "rumors",
      "say",
      "say",
      "say_rabindranath",
      "see",
      "separatist",
      "shaheen",
      "sikhs",
      "simi",
      "singh",
      "singh",
      "singh_mukesh",
      "slogans",
      "social",
      "sonia",
      "spilt",
      "spread",
      "spread",
      "spread",
      "spread",
      "spread",
      "ssi",
      "student",
      "submit",
      "traffic_jam",
      "trudeau",
      "trudeau",
      "turkish",
      "uk",
      "vaccine",
      "vandalism",
      "visva",
      "volcano",
      "waitress",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://factly.in/old-photo-from-sikh-separatist-outfits-protest-in-london-shared-as-farmers-disrespecting-the-national-flag/",
        headline:
          " Old photo from Sikh Separatist outfit\u2019s protest in London shared as farmers disrespecting the national flag",
      },
      {
        url:
          "https://factly.in/telugu-old-photo-from-sikh-separatist-outfits-protest-shared-as-farmers-disrespecting-indian-flag/",
        headline:
          " \u0c16\u0c32\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c35\u0c47\u0c30\u0c4d\u0c2a\u0c3e\u0c1f\u0c41\u0c35\u0c3e\u0c26\u0c41\u0c32\u0c41 \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36 \u0c1c\u0c46\u0c02\u0c21\u0c3e\u0c28\u0c3f \u0c05\u0c35\u0c2e\u0c3e\u0c28\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c05\u0c02\u0c26\u0c4b\u0c33\u0c28\u0c32\u0c15\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-video-falsely-shared-as-a-video-of-20000-nihang-sikhs-going-to-delhi-to-support-farmers-protest/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c30\u0c48\u0c24\u0c41 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c2e\u0c26\u0c4d\u0c26\u0c24\u0c41\u0c17\u0c3e 2000 \u0c17\u0c41\u0c30\u0c4d\u0c30\u0c3e\u0c32\u0c2a\u0c48 20000 \u0c28\u0c3f\u0c39\u0c3e\u0c02\u0c17\u0c4d \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41\u0c32\u0c41 \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40\u0c15\u0c3f \u0c2c\u0c2f\u0c32\u0c41\u0c26\u0c47\u0c30\u0c3e\u0c30\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/adani-group-did-not-incorporate-these-agri-based-companies-in-2019/",
        headline:
          " Adani Group did not incorporate these Agri based companies in 2019",
      },
      {
        url:
          "https://factly.in/telugu-adani-group-did-not-incorporate-these-agri-based-companies-in-2019/",
        headline:
          " \u0c05\u0c26\u0c3e\u0c28\u0c3f \u0c17\u0c4d\u0c30\u0c42\u0c2a\u0c4d \u0c08 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c3e\u0c2f \u0c06\u0c27\u0c3e\u0c30\u0c3f\u0c24 \u0c15\u0c02\u0c2a\u0c46\u0c28\u0c40\u0c32\u0c28\u0c41 2019 \u0c15\u0c28\u0c4d\u0c28\u0c3e \u0c2e\u0c41\u0c02\u0c26\u0c47 \u0c38\u0c4d\u0c25\u0c3e\u0c2a\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-did-farmers-disrespect-the-indian-national-flag-during-the-ongoing-protests-heres-the-truth/",
        headline:
          "Fact Check: Did Farmers Disrespect The Indian National Flag During The Ongoing Protests? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/09/fact-check-no-this-picture-of-traffic-jam-is-not-from-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: No, This Picture Of Traffic Jam Is NOT From Recent Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/morphed-image-of-suvendu-adhikari-and-jp-nadda-shared-with-false-claims-11016",
        headline:
          "Fake Image Shared To Claim TMC's Suvendu Adhikari Joined BJP",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/viral-claim-stating-adani-registered-agri-companies-in-2019-are-false-11092",
        headline:
          "Viral Claim Stating Adani Registered Agri Companies In 2019 Is False",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/delhi-chalo-march-or-kisan-andolan-did-not-jam-traffic-in-this-picture-11080",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u091f\u094d\u0930\u0948\u095e\u093f\u0915 \u091c\u093e\u092e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0947 \u0915\u093e\u0930\u0923 \u0928\u0939\u0940\u0902 \u0939\u0941\u0906 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-old-photo-of-separatists-disrespecting-tricolour-revived-11055",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09ac\u09bf\u099a\u09cd\u099b\u09bf\u09a8\u09cd\u09a8\u09a4\u09be\u09ac\u09be\u09a6\u09c0\u09a6\u09c7\u09b0 \u09a4\u09c7\u09b0\u0999\u09be \u0985\u09ac\u09ae\u09be\u09a8\u09a8\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u0986\u09ac\u09be\u09b0 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019-video-of-mahashay-dharampal-gulati-shared-as-his-final-moments-11078",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09a4\u09cb\u09b2\u09be \u09ad\u09bf\u09a1\u09bf\u0993 \u09ae\u09b9\u09be\u09b6\u09df \u09a7\u09b0\u09cd\u09ae\u09aa\u09be\u09b2 \u0997\u09c1\u09b2\u09be\u099f\u09bf\u09b0 \u0985\u09a8\u09cd\u09a4\u09bf\u09ae \u09ae\u09c1\u09b9\u09c2\u09b0\u09cd\u09a4 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-2018-aap-rally-video-viral-as-delhi-chalo-protest-11085",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u0986\u09aa \u09a6\u09b2\u09c7\u09b0 \u09ae\u09bf\u099b\u09bf\u09b2\u0995\u09c7 '\u09a6\u09bf\u09b2\u09cd\u09b2\u09bf \u099a\u09b2\u09cb' \u0985\u09ad\u09bf\u09af\u09be\u09a8 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/old-video-goes-viral-nihang-sikhs-marching-delhi-farmers-protest-1747842-2020-12-08",
        headline:
          "Fact Check: Old video goes viral as Nihang Sikhs marching to Delhi for farmers\u2019 protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-did-farmers-disrespect-the-indian-national-flag-during-the-ongoing-protests-heres-the-truth/",
        headline:
          "Fact Check: Did Farmers Disrespect The Indian National Flag During The Ongoing Protests? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/09/fact-check-no-this-picture-of-traffic-jam-is-not-from-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: No, This Picture Of Traffic Jam Is NOT From Recent Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-old-photo-of-separatists-disrespecting-tricolour-revived-11055",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09ac\u09bf\u099a\u09cd\u099b\u09bf\u09a8\u09cd\u09a8\u09a4\u09be\u09ac\u09be\u09a6\u09c0\u09a6\u09c7\u09b0 \u09a4\u09c7\u09b0\u0999\u09be \u0985\u09ac\u09ae\u09be\u09a8\u09a8\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u0986\u09ac\u09be\u09b0 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/viral-claim-stating-adani-registered-agri-companies-in-2019-are-false-11092",
        headline:
          "Viral Claim Stating Adani Registered Agri Companies In 2019 Is False",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/morphed-image-of-suvendu-adhikari-and-jp-nadda-shared-with-false-claims-11016",
        headline:
          "Fake Image Shared To Claim TMC's Suvendu Adhikari Joined BJP",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-old-images-of-sikhs-disrespecting-tricolour-resurface-11079",
        headline:
          "Farmers Protest: Old Images Of Sikhs Disrespecting Tricolour Resurface",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-old-images-of-sikhs-disrespecting-tricolour-resurface-11107",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8: \u09b6\u09bf\u0996\u09a6\u09c7\u09b0 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u099c\u09be\u09a4\u09c0\u09df \u09aa\u09a4\u09be\u0995\u09be \u0985\u09ac\u09ae\u09be\u09a8\u09a8\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2019-video-of-mahashay-dharampal-gulati-shared-as-his-final-moments-11078",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09a4\u09cb\u09b2\u09be \u09ad\u09bf\u09a1\u09bf\u0993 \u09ae\u09b9\u09be\u09b6\u09df \u09a7\u09b0\u09cd\u09ae\u09aa\u09be\u09b2 \u0997\u09c1\u09b2\u09be\u099f\u09bf\u09b0 \u0985\u09a8\u09cd\u09a4\u09bf\u09ae \u09ae\u09c1\u09b9\u09c2\u09b0\u09cd\u09a4 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/photos-from-anti-caa-protests-falsely-linked-to-farmers-delhi-chalo-march-11083",
        headline:
          "\u09a8\u09be\u0997\u09b0\u09bf\u0995\u09a4\u09cd\u09ac \u0986\u0987\u09a8-\u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09c1\u09a1\u09bc\u09b2 \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \"\u09a6\u09bf\u09b2\u09cd\u09b2\u09bf \u099a\u09b2\u09cb' \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/delhi-chalo-march-or-kisan-andolan-did-not-jam-traffic-in-this-picture-11080",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u091f\u094d\u0930\u0948\u095e\u093f\u0915 \u091c\u093e\u092e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0947 \u0915\u093e\u0930\u0923 \u0928\u0939\u0940\u0902 \u0939\u0941\u0906 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-kisan-protest-video-played-as-facebook-live-as-recent-farmers-protest-in-delhi-11021",
        headline: "   ' '       ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/two-yr-old-video-of-khalistan-movement-supporters-falsely-linked-to-farmers-protest-11019",
        headline: "           ",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/old-image-of-vegetables-spilled-all-over-the-road-is-being-passed-off-linking-it-to-bharat-bandh/",
        headline:
          " Old image of vegetables spilt all over the road is being linked to Bharat bandh",
      },
      {
        url:
          "https://factly.in/telugu-this-bicycle-sculpture-is-in-indonesia-not-in-the-panchavarna-swamy-temple-tamil-nadu/",
        headline:
          " \u0c08 \u0c38\u0c48\u0c15\u0c3f\u0c32\u0c4d \u0c36\u0c3f\u0c32\u0c4d\u0c2a\u0c02 \u0c07\u0c02\u0c21\u0c4b\u0c28\u0c47\u0c37\u0c3f\u0c2f\u0c3e\u0c32\u0c4b \u0c09\u0c02\u0c26\u0c3f, \u0c24\u0c2e\u0c3f\u0c33\u0c28\u0c3e\u0c21\u0c41 \u0c32\u0c4b\u0c28\u0c3f \u0c2a\u0c02\u0c1a\u0c35\u0c30\u0c4d\u0c23 \u0c38\u0c4d\u0c35\u0c3e\u0c2e\u0c3f \u0c26\u0c47\u0c35\u0c3e\u0c32\u0c2f\u0c02\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-image-of-vegetables-spilt-all-over-the-road-is-being-linked-to-bharat-bandh/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c15\u0c4a\u0c24\u0c4d\u0c24 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c3e\u0c2f \u0c1a\u0c1f\u0c4d\u0c1f\u0c3e\u0c32\u0c15\u0c41 \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c2a\u0c3f\u0c32\u0c41\u0c2a\u0c41\u0c28\u0c3f\u0c1a\u0c4d\u0c1a\u0c3f\u0c28 \u0c2d\u0c3e\u0c30\u0c24\u0c4d \u0c2c\u0c02\u0c26\u0c4d \u0c15\u0c3f \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-link-between-the-arrested-simi-terrorist-abdullah-danish-and-the-ongoing-farmers-protest/",
        headline:
          " SIMI \u0c09\u0c17\u0c4d\u0c30\u0c35\u0c3e\u0c26\u0c3f \u0c05\u0c2c\u0c4d\u0c26\u0c41\u0c32\u0c4d\u0c32\u0c3e \u0c27\u0c3e\u0c28\u0c3f\u0c37\u0c4d \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d \u0c15\u0c3f \u0c15\u0c4a\u0c24\u0c4d\u0c24 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c3e\u0c2f \u0c1a\u0c1f\u0c4d\u0c1f\u0c3e\u0c32\u0c15\u0c41 \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c1c\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.factcrescendo.com/old-picture-of-mayawati-and-governor-anandiben-patel-linked-to-farm-bill/",
        headline:
          "\u0915\u094d\u092f\u093e \u092e\u093e\u092f\u093e\u0935\u0924\u0940 \u0928\u0947 \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u092c\u093f\u0932\u094b\u0902 \u0915\u094b \u0932\u0947\u0915\u0930 \u0909.\u092a\u094d\u0930 \u0915\u0940 \u0930\u093e\u091c\u094d\u092f\u092a\u093e\u0932 \u0906\u0928\u0902\u0926\u0940\u092c\u0947\u0928 \u092a\u091f\u0947\u0932 \u0938\u0947 \u092e\u0941\u0932\u093e\u0915\u093e\u0924 \u0915\u0930 \u091c\u094d\u091e\u093e\u092a\u0928 \u0938\u094c\u0902\u092a\u093e? \u091c\u093e\u0928\u093f\u090f \u0938\u091a\u2026",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/old-images-viral-as-vandalism-by-congress-during-bharat-bandh-11096",
        headline:
          "Old Images Viral As Vandalism By Congress During Bharat Bandh",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-kisan-protest-video-played-as-facebook-live-as-recent-farmers-protest-in-delhi-11021",
        headline:
          "\u092a\u0941\u0930\u093e\u0928\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b '\u095e\u0947\u0938\u092c\u0941\u0915 \u0932\u093e\u0907\u0935' \u092c\u0924\u093e\u0915\u0930 \u0939\u093e\u0932\u093f\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0938\u0947 \u091c\u094b\u095c\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/farmers-protest-call-for-bharat-band-viral-in-the-name-of-journalist-ravish-kumar-11028",
        headline:
          "\u092d\u093e\u0930\u0924 \u092c\u0902\u0926 \u0915\u094b \u0932\u0947\u0915\u0930 \u0930\u0935\u0940\u0936 \u0915\u0941\u092e\u093e\u0930 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 '\u0905\u092a\u0940\u0932' \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-photo-passed-off-as-justin-trudeau-sitting-in-an-anti-farm-laws-protest-11020",
        headline:
          "\u0995\u09c3\u09b7\u09bf \u0986\u0987\u09a8\u09c7\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u099c\u09be\u09b8\u09cd\u099f\u09bf\u09a8 \u099f\u09cd\u09b0\u09c1\u09a1\u09cb\u09b0 \u09a7\u09b0\u09cd\u09a8\u09be \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-aap-leaders-not-linked-cm-kejriwal-s-alleged-house-arrest-1748522-2020-12-11",
        headline:
          "Fact Check: This video of agitating AAP leaders is not linked to CM Kejriwal\u2019s alleged house arrest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/old-images-viral-as-vandalism-by-congress-during-bharat-bandh-11096",
        headline:
          "Old Images Viral As Vandalism By Congress During Bharat Bandh",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/old-photo-passed-off-as-justin-trudeau-sitting-in-an-anti-farm-laws-protest-11020",
        headline:
          "\u0995\u09c3\u09b7\u09bf \u0986\u0987\u09a8\u09c7\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u099c\u09be\u09b8\u09cd\u099f\u09bf\u09a8 \u099f\u09cd\u09b0\u09c1\u09a1\u09cb\u09b0 \u09a7\u09b0\u09cd\u09a8\u09be \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/image-of-kangana-ranaut-with-journalist-mark-manuel-revived-with-false-claims-11041",
        headline:
          "\u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u0995\u0999\u09cd\u0997\u09a8\u09be \u09b0\u09be\u09a8\u09be\u0989\u09a4 \u0993 \u09ae\u09be\u09b0\u09cd\u0995 \u09ae\u09cd\u09af\u09be\u09a8\u09c1\u09df\u09c7\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-volcano-eruption-in-viral-video-is-from-mount-sinaburg-not-mount-semeru-671657",
        headline:
          "Fact Check: Volcano eruption in viral video is from Mount Sinaburg, not Mount Semeru",
      },
    ],
    "3": [
      {
        url:
          "https://factly.in/old-and-unrelated-videos-and-images-are-being-shared-linking-them-to-ongoing-farmers-protest/",
        headline:
          " Old and unrelated videos and images are being shared linking them to ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-video-is-being-shared-as-a-muslim-in-sikh-dress-during-farmer-protest/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41 \u0c35\u0c47\u0c37\u0c02 \u0c27\u0c30\u0c3f\u0c02\u0c1a\u0c3f \u0c30\u0c48\u0c24\u0c41 \u0c09\u0c26\u0c4d\u0c2f\u0c2e\u0c02\u0c32\u0c4b \u0c2a\u0c3e\u0c32\u0c4d\u0c17\u0c4a\u0c28\u0c4d\u0c28 \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/old-photo-of-hrithik-roshan-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          " Old photo of Hrithik Roshan falsely linked to ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-2017-photos-shared-as-staged-photo-op-by-farmers-during-the-ongoing-protests/",
        headline:
          " \u2018\u0c30\u0c48\u0c24\u0c41\u0c32 \u0c35\u0c47\u0c37\u0c02 \u0c35\u0c47\u0c38\u0c41\u0c15\u0c4a\u0c28\u0c3f \u0c15\u0c46\u0c2e\u0c46\u0c30\u0c3e\u0c24\u0c4b \u0c37\u0c42\u0c1f\u0c3f\u0c02\u0c17\u0c4d\u2019 \u0c05\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28\u0c35\u0c3f \u0c24\u0c3e\u0c1c\u0c3e \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40 \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c15\u0c3e\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-protest-photos-against-caa-nrc-from-shaheen-bagh-falsely-linked-to-farmers-protest/",
        headline:
          " CAA, NRC \u0c2c\u0c3f\u0c32\u0c4d\u0c32\u0c41\u0c32\u0c28\u0c3f \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c37\u0c3e\u0c39\u0c40\u0c28\u0c4d \u0c2d\u0c3e\u0c17\u0c4d \u0c32\u0c4b \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c41\u0c17\u0c3e \u0c1a\u0c3f\u0c24\u0c4d\u0c30\u0c3f\u0c15\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/a-2013-video-of-rajnath-singh-shared-now-as-proof-of-his-support-to-the-farmers-protest/",
        headline:
          " A 2013 video of Rajnath Singh shared now as proof of his support to the farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/2016-rally-video-by-sikh-groups-is-now-shared-as-the-farmers-protest-over-the-new-farm-bills/",
        headline:
          " 2016 rally video by Sikh groups is now shared as the farmers\u2019 protest over the new farm bills",
      },
      {
        url:
          "https://factly.in/telugu-old-photo-of-khalistan-supporters-arrest-is-falsely-linked-to-farmer-protests/",
        headline:
          " \u0c2a\u0c02\u0c1c\u0c3e\u0c2c\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35 \u0c15\u0c3e\u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c2f\u0c02\u0c2a\u0c48 \u0c16\u0c32\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c1c\u0c46\u0c02\u0c21\u0c3e \u0c0e\u0c17\u0c30\u0c47\u0c38\u0c3f\u0c28 \u0c28\u0c3f\u0c02\u0c26\u0c3f\u0c24\u0c41\u0c32\u0c28\u0c3f \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c2a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-video-shared-as-farmers-shouting-pakistan-zindabad-slogans-in-the-ongoing-farmers-protest/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40\u0c32\u0c4b \u0c1c\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c27\u0c30\u0c4d\u0c28\u0c3e\u0c32\u0c4b \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c1c\u0c3f\u0c02\u0c26\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c28\u0c3f\u0c28\u0c3e\u0c26\u0c3e\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c3e\u0c30\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/pro-khalistan-supporters-in-us-praising-pakistan-pm-imran-khan-is-falsely-linked-to-farmers-protest/",
        headline:
          " Video of pro-Khalistan supporters in US praising Pakistan PM, Imran Khan is falsely linked to farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-2016-rally-video-by-sikh-groups-is-now-shared-as-the-farmers-protest-over-the-new-farm-bills/",
        headline:
          " 2016\u0c32\u0c4b \u0c2a\u0c02\u0c1c\u0c3e\u0c2c\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c12\u0c15 \u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c40 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40\u0c32\u0c4b \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c3f \u0c2e\u0c41\u0c21\u0c3f \u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/2015-video-of-sikh-men-protests-in-baramullah-falsely-linked-to-the-ongoing-farmers-protest/",
        headline:
          " 2015 video of protests in Baramulla falsely linked to the ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://www.factcrescendo.com/protesting-farmers-disrespecting-indian-national-flag/",
        headline:
          "\u0968\u0966\u0967\u0969 \u0915\u0940 \u0932\u0902\u0926\u0928 \u092e\u0947\u0902 \u0932\u0940 \u0917\u0908 \u090f\u0915 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u093e \u092c\u0924\u093e \u092b\u0948\u0932\u093e\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 |",
      },
      {
        url:
          "https://www.factcrescendo.com/video-of-pro-khalistan-movement-in-usa-2018-viral-as-farmer-protest/",
        headline:
          "\u0905\u092e\u0947\u0930\u093f\u0915\u093e \u092e\u0947\u0902 \u0968\u0966\u0967\u096e \u0915\u094b \u0939\u0941\u090f \u092a\u094d\u0930\u094b-\u0916\u093e\u0932\u093f\u0938\u094d\u0924\u093e\u0928 \u0930\u0948\u0932\u0940 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u092d\u093e\u0930\u0924 \u092e\u0947\u0902 \u0939\u094b \u0930\u0939\u0947 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928\u094b\u0902 \u0915\u093e \u092c\u0924\u093e \u0935\u093e\u092f\u0930\u0932 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948|",
      },
      {
        url:
          "https://www.factcrescendo.com/old-video-of-rajnath-singh-viral-as-addressing-recent-farmer-protest-and-bandh/",
        headline:
          "\u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0915\u094b \u0938\u0902\u092c\u094b\u0927\u093f\u0924 \u0915\u0930\u0924\u0947 \u0939\u0941\u090f \u0930\u093e\u091c\u0928\u093e\u0925 \u0938\u093f\u0902\u0939 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0915\u094d\u0932\u093f\u092a \u0915\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u0921\u093c\u0915\u0930 \u092b\u0948\u0932\u093e\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 |",
      },
      {
        url:
          "https://www.factcrescendo.com/old-video-of-protest-in-amritsar-linked-with-ongoing-farmers-protest/",
        headline:
          "2016 \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u0947 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u093e \u092c\u0924\u093e \u0935\u093e\u092f\u0930\u0932 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948\u0964",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-did-kamala-harris-extend-support-to-protesting-farmers-in-india-heres-the-truth/",
        headline:
          "Fact Check: Did Kamala Harris extend support to protesting farmers in India? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-old-picture-of-tractor-rally-taken-out-by-women-falsely-linked-to-recent-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Tractor Rally Taken Out By Women, Falsely Linked To Recent Farmers\u2019 Protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-picture-of-people-demanding-khalistan-is-not-from-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Picture Of People Demanding Khalistan Is NOT From Recent Farmers Protest; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-a-2018-picture-of-people-holding-black-banner-is-falsely-shared-as-recent-farmers-protest/",
        headline:
          "Fact Check: A 2018 Picture Of People Holding Black Banner, Is Falsely Shared As Recent Farmers Protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-old-2018-picture-of-kisan-rally-from-thane-shared-as-recent-one-from-delhi/",
        headline:
          "Fact Check: Old 2018 Picture Of Kisan Rally From Thane Shared As Recent One From Delhi",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-old-picture-of-indian-journalist-falsely-linked-to-farmers-agitation-heres-the-truth/",
        headline:
          "Fact Check: Old Picture Of Indian Journalist Falsely Linked To Farmers\u2019 Agitation; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/09/fact-check-old-video-of-pro-khalistan-protest-from-the-us-linked-to-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Video Of Pro-Khalistan Protest From The US Linked To Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/two-yr-old-video-of-khalistan-movement-supporters-falsely-linked-to-farmers-protest-11019",
        headline:
          "\u0959\u093e\u0932\u093f\u0938\u094d\u0924\u093e\u0928 \u0938\u092e\u0930\u094d\u0925\u0915\u094b\u0902 \u0915\u093e \u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/two-images-from-anti-caa-protest-shaheen-bagh-falsely-linked-to-kisan-andolan-delhi-11024",
        headline:
          "\u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u094b\u0902 \u0915\u094b \u095e\u0930\u094d\u095b\u0940 \u0924\u0930\u0940\u0915\u0947 \u0938\u0947 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0915\u093f\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/two-year-old-picture-of-hrithik-roshan-shared-as-recent-11033",
        headline:
          "\u0915\u094d\u092f\u093e \u090b\u0924\u093f\u0915 \u0930\u094b\u0936\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u0938\u092e\u0930\u094d\u0925\u0928 \u0926\u0947\u0928\u0947 \u092a\u0939\u0941\u0902\u091a\u0947 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-of-rajnath-singh-viral-as-supporting-recent-kisan-andolan-11048",
        headline:
          "\u0930\u093e\u091c\u0928\u093e\u0925 \u0938\u093f\u0902\u0939 \u0915\u093e 7 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/death-hoax-social-media-posts-misleadingly-claim-buddhadeb-bhattacharya-has-passed-away-11066",
        headline:
          "\u09b8\u09cb\u09b6\u09be\u09b2 \u09ae\u09bf\u09a1\u09bf\u09df\u09be\u09df \u099b\u09a1\u09bc\u09be\u09b2 \u09b9\u09be\u09b8\u09aa\u09be\u09a4\u09be\u09b2\u09c7 \u09ad\u09b0\u09cd\u09a4\u09bf \u09ac\u09c1\u09a6\u09cd\u09a7\u09a6\u09c7\u09ac \u09ad\u099f\u09cd\u099f\u09be\u099a\u09be\u09b0\u09cd\u09af\u09c7\u09b0 \u09ae\u09c3\u09a4\u09cd\u09af\u09c1 \u0997\u09c1\u099c\u09ac",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-video-of-pro-khalistan-rally-is-not-linked-to-farmers-protest-1748158-2020-12-09",
        headline:
          "Fact Check: This video of pro-Khalistan rally is not linked to farmers\u2019 protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/old-clip-rajnath-farmers-passed-off-support-ongoing-stir-1747567-2020-12-07",
        headline:
          "Fact Check: Old clip of Rajnath Singh addressing farmers passed off as support to ongoing stir",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/this-banner-demanding-jnu-student-sharjeel-imam-s-release-is-not-from-ongoing-farmers-protest-1748839-2020-12-11",
        headline:
          "Fact Check: Poster demanding JNU student Sharjeel Imam\u2019s release is not from ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/old-video-of-pro-pak-pro-khalistan-rally-revived-in-context-of-current-farmers-agitation-1749041-2020-12-12",
        headline:
          "Fact Check: Old video of pro-Pak, pro-Khalistan rally revived in context of current farmers' agitation",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-did-kamala-harris-extend-support-to-protesting-farmers-in-india-heres-the-truth/",
        headline:
          "Fact Check: Did Kamala Harris extend support to protesting farmers in India? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-old-picture-of-tractor-rally-taken-out-by-women-falsely-linked-to-recent-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Tractor Rally Taken Out By Women, Falsely Linked To Recent Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-picture-of-people-demanding-khalistan-is-not-from-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Picture Of People Demanding Khalistan Is NOT From Recent Farmers Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-a-2018-picture-of-people-holding-black-banner-is-falsely-shared-as-recent-farmers-protest/",
        headline:
          "Fact Check: A 2018 Picture Of People Holding Black Banner, Is Falsely Shared As Recent Farmers Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-old-2018-picture-of-kisan-rally-from-thane-shared-as-recent-one-from-delhi/",
        headline:
          "Fact Check: Old 2018 Picture Of Kisan Rally From Thane Shared As Recent One From Delhi",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-old-picture-of-indian-journalist-falsely-linked-to-farmers-agitation-heres-the-truth/",
        headline:
          "Fact Check: Old Picture Of Indian Journalist Falsely Linked To Farmers\u2019 Agitation; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/09/fact-check-old-video-of-pro-khalistan-protest-from-the-us-linked-to-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Video Of Pro-Khalistan Protest From The US Linked To Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protests-clip-of-2019-pro-khalistan-rally-in-us-revived-11099",
        headline:
          "Farmers' Protests: Clip Of 2019 Pro-Khalistan Rally In US Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2013-video-of-rajnath-singh-passed-off-as-support-to-farmers-protest-11052",
        headline:
          "2013 Video Of Rajnath Singh Passed Off As Support To Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2016-video-of-pro-khalistan-rally-falsely-linked-to-farmers-protests-11058",
        headline:
          "2016 Video Of Pro-Khalistan Rally Falsely Linked To Farmers' Protests",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/pro-khalistan-rally-video-from-2015-falsely-linked-to-farmers-protest-11070",
        headline:
          "Pro-Khalistan Rally Video From 2015 Falsely Linked To Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protests-how-old-visuals-were-used-to-peddle-a-pro-khalistan-narrative-11090",
        headline:
          "Farmers' Protests: How Old Visuals Were Used To Peddle A Pro-Khalistan Narrative",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/death-hoax-social-media-posts-misleadingly-claim-buddhadeb-bhattacharya-has-passed-away-11066",
        headline:
          "\u09b8\u09cb\u09b6\u09be\u09b2 \u09ae\u09bf\u09a1\u09bf\u09df\u09be\u09df \u099b\u09a1\u09bc\u09be\u09b2 \u09b9\u09be\u09b8\u09aa\u09be\u09a4\u09be\u09b2\u09c7 \u09ad\u09b0\u09cd\u09a4\u09bf \u09ac\u09c1\u09a6\u09cd\u09a7\u09a6\u09c7\u09ac \u09ad\u099f\u09cd\u099f\u09be\u099a\u09be\u09b0\u09cd\u09af\u09c7\u09b0 \u09ae\u09c3\u09a4\u09cd\u09af\u09c1 \u0997\u09c1\u099c\u09ac",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/pro-khalistan-rally-video-from-2015-falsely-linked-to-farmers-protest-11103",
        headline:
          "\u09e8\u09e6\u09e7\u09eb-\u09b0 \u0996\u09be\u09b2\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09aa\u09a8\u09cd\u09a5\u09c0 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u099c\u09c1\u09dc\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/doctored-photo-shared-with-fake-claim-of-a-muslim-disguised-as-a-sikh-11025",
        headline:
          "\u09b6\u09bf\u0996 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u099b\u09a6\u09cd\u09ae\u09ac\u09c7\u09b6\u09c7 \u098f\u0995 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u098f\u0987 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09be\u09a1\u09bc\u09be\u09b2 \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2013-photo-of-pro-khalistan-banners-linked-to-farmers-protest-11044",
        headline:
          "\u09e8\u09e6\u09e7\u09e9'\u09b0 \u0996\u09be\u09b2\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09aa\u09a8\u09cd\u09a5\u09c0 \u09ac\u09cd\u09af\u09be\u09a8\u09be\u09b0\u09c7\u09b0 \u099b\u09ac\u09bf \u0995\u09c3\u09b7\u0995 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u099c\u09c1\u09dc\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/2014-clip-revived-as-hindu-chants-recited-for-kamala-harris-at-white-house-11027",
        headline:
          "\u0995\u09ae\u09b2\u09be \u09b9\u09cd\u09af\u09be\u09b0\u09bf\u09b8\u09c7\u09b0 \u09b6\u09aa\u09a5\u09c7 \u09ac\u09c8\u09a6\u09bf\u0995 \u09ae\u09a8\u09cd\u09a4\u09cd\u09b0 \u09aa\u09be\u09a0\u2014\u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/farmers-protest-call-for-bharat-band-viral-in-the-name-of-journalist-ravish-kumar-11028",
        headline: "         '' ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/pro-khalistan-rally-video-from-2015-falsely-linked-to-farmers-protest-11097",
        headline:
          "\u0916\u093e\u0932\u093f\u0938\u094d\u0924\u093e\u0928 \u0938\u092e\u0930\u094d\u0925\u0915 \u0928\u093e\u0930\u094b\u0902 \u0915\u093e \u092a\u093e\u0902\u091a \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/two-year-old-picture-of-hrithik-roshan-shared-as-recent-11033",
        headline: "         ?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-of-sikhs-raising-pro-khalistan-slogans-passed-off-as-farmers-protest-671568",
        headline:
          "Fact check: Old video of Sikhs raising pro-Khalistan slogans passed off as farmers protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photos-from-delhi-caa-protests-passed-off-as-ongoing-farmers-protest-671504",
        headline:
          "Fact Check: Photos from Delhi CAA protests passed off as ongoing farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/video-from-2019-anti-modi-protests-in-new-york-passed-off-as-ongoing-farmers-protests-671705",
        headline:
          "Fact Check: Video from 2019 anti-Modi protests in New York passed off as ongoing farmers' protests",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-rajnath-singhs-address-is-from-2013-not-2020-671570",
        headline:
          "Fact check: Viral video of Rajnath Singh's address is from 2013, not 2020",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2018-photo-of-hrithik-roshan-falsely-claims-to-show-actor-at-ongoing-farmers-protests-671514",
        headline:
          "Fact Check: 2018 photo of Hrithik Roshan falsely claims to show actor at ongoing farmers' protests",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-claim-that-muslim-man-posed-as-sikh-farmer-is-false-671539",
        headline:
          "Fact check: Viral claim that Muslim man posed as Sikh farmer is false",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/2017-image-and-video-of-punjab-cm-capt-amrinder-singh-and-mukesh-ambani-shared-as-recent-meeting-during-farmer-protest/",
        headline:
          "Visuals from 2017 viral as Punjab CM met Mukesh Ambani amid farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/old-image-of-trudeaus-visit-to-a-sikh-congregation-is-falsely-linked-to-farmers-protest/",
        headline:
          " Old image of Trudeau\u2019s visit to a Sikh congregation is falsely linked to farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/morphed-tweet-shared-as-barack-obama-feeling-ashamed-to-shake-hands-with-pm-modi/",
        headline:
          " Morphed tweet shared as Barack Obama feeling ashamed to shake hands with PM Modi",
      },
      {
        url:
          "https://factly.in/old-video-of-amarinder-singhs-meet-up-with-mukesh-ambani-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          " Old video of Amarinder Singh\u2019s meeting with Mukesh Ambani falsely linked to ongoing farmer\u2019s protest",
      },
      {
        url:
          "https://www.factcrescendo.com/old-picture-of-capt-amarinder-singh-and-mukesh-ambani-shared-as-recent-one/",
        headline:
          "2017 \u0915\u0940 \u0915\u0948\u092a\u094d\u091f\u0928 \u0905\u092e\u0930\u093f\u0902\u0926\u0930 \u0938\u093f\u0902\u0939 \u0914\u0930 \u092e\u0941\u0915\u0947\u0936 \u0905\u0902\u092c\u093e\u0928\u0940 \u0915\u0940 \u090f\u0915 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u092d\u093e\u0930\u0924 \u092c\u0902\u0926 \u0938\u0947 \u090f\u0915 \u0926\u093f\u0928 \u092a\u0939\u0932\u0947 \u0915\u0940 \u092c\u0924\u093e \u0935\u093e\u092f\u0930\u0932 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948\u0964",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-was-canadian-pm-justin-trudeau-part-of-the-protests-against-indian-farm-laws-heres-the-truth/",
        headline:
          "Fact Check: Was Canadian PM Justin Trudeau part of the protests against Indian farm laws? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-did-aam-aadmi-party-pay-people-to-participate-in-the-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Did Aam Aadmi Party Pay People To Participate In The Farmers\u2019 Protest? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2017-image-of-punjab-cm-capt-amarinder-singh-with-mukesh-ambani-viral-11060",
        headline:
          "2017 Image Of Punjab CM Capt Amarinder Singh With Mukesh Ambani Viral",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/indian-army-denies-claims-of-troops-being-sent-to-end-farmers-protest-11098",
        headline:
          "Indian Army Denies Claims Of Troops Being Sent To End Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/did-pm-modi-visit-the-ambanis-newborn-grandson-at-the-hospital-11112",
        headline:
          "Did PM Modi Visit Ambanis' Newborn Grandson At The Hospital?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/pakistani-accounts-spread-fake-news-of-sikh-soldiers-quitting-indian-army-11063",
        headline:
          "\u092a\u093e\u0915 \u0928\u0947\u091f\u093f\u095b\u0947\u0902\u0938 \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u093e: \u0938\u093f\u0916 \u0938\u0948\u0928\u093f\u0915 \u092d\u093e\u0930\u0924\u0940\u092f \u0938\u0947\u0928\u093e \u091b\u094b\u095c \u0930\u0939\u0947 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2017-image-of-punjab-cm-capt-amarinder-singh-with-mukesh-ambani-viral-11086",
        headline:
          "\u0915\u0948\u092a\u094d\u091f\u0928 \u0905\u092e\u0930\u093f\u0902\u0926\u0930 \u0938\u093f\u0902\u0939 \u0914\u0930 \u092e\u0941\u0915\u0947\u0936 \u0905\u092e\u094d\u092c\u093e\u0928\u0940 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/indian-army-denies-claims-of-troops-being-sent-to-end-farmers-protest-11109",
        headline:
          "\u0938\u0947\u0928\u093e \u0928\u0947 \u0959\u093e\u0930\u093f\u095b \u0915\u093f\u092f\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u091f\u094d\u0930\u0941\u092a\u094d\u0938 \u092d\u0947\u091c\u0928\u0947 \u0935\u093e\u0932\u093e \u0926\u093e\u0935\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/did-modi-pay-a-visit-to-ambanis-newborn-grandson-at-the-hospital-11113",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u092e\u094b\u0926\u0940 \u092e\u0941\u0915\u0947\u0936 \u0905\u0902\u092c\u093e\u0928\u0940 \u0915\u0947 \u092a\u094b\u0924\u0947 \u0915\u094b \u0926\u0947\u0916\u0928\u0947 \u092e\u0941\u0902\u092c\u0908 \u0917\u090f \u0925\u0947?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-quote-by-ex-australian-pm-malcolm-turnbull-on-reelecting-narendra-modi-11043",
        headline:
          "\u0985\u09b8\u09cd\u099f\u09cd\u09b0\u09c7\u09b2\u09bf\u09df\u09be\u09b0 \u09aa\u09cd\u09b0\u09be\u0995\u09cd\u09a4\u09a8 \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u099f\u09be\u09b0\u09cd\u09a8\u09ac\u09c1\u09b2\u09c7\u09b0 \u09ae\u09cb\u09a6\u09c0 \u09a8\u09bf\u09df\u09c7 \u09ad\u09c1\u09df\u09cb \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-image-of-punjab-cm-capt-amarinder-singh-with-mukesh-ambani-viral-11102",
        headline:
          "\u0995\u09cd\u09af\u09be\u09aa\u09cd\u099f\u09c7\u09a8 \u0985\u09ae\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09b8\u09bf\u0982\u09b9 \u0993 \u09ae\u09c1\u0995\u09c7\u09b6 \u0985\u09ae\u09cd\u09ac\u09be\u09a8\u09c0\u09b0 \u09e8\u09e6\u09e7\u09ed-\u09b0 \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/did-pm-modi-visit-ambanis-newborn-grandson-at-the-hospital-11114",
        headline:
          "\u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0 \u0995\u09bf \u0985\u09ae\u09cd\u09ac\u09be\u09a8\u09c0\u09b0 \u09a8\u09be\u09a4\u09bf \u09a6\u09c7\u0996\u09a4\u09c7 \u09b9\u09be\u09b8\u09aa\u09be\u09a4\u09be\u09b2\u09c7 \u0997\u09c7\u09b2\u09c7\u09a8?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-punjab-cm-amarinder-singh-didn-t-meet-ambani-a-day-before-bharat-bandh-1747858-2020-12-08",
        headline:
          "Fact Check: Punjab CM Amarinder Singh didn't meet Ambani a day before Bharat Bandh",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-a-wuhan-lab-that-obama-was-visiting-1748138-2020-12-09",
        headline:
          " Fact Check: This is not a Wuhan lab that Obama was visiting",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/obama-s-tweet-snubbing-pm-modi-farmers-protest-morphed-1747574-2020-12-08",
        headline:
          "Fact Check: Obama's tweet snubbing PM Modi over farmers' protest is morphed",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-was-canadian-pm-justin-trudeau-part-of-the-protests-against-indian-farm-laws-heres-the-truth/",
        headline:
          "Fact Check: Was Canadian PM Justin Trudeau part of the protests against Indian farm laws? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-did-aam-aadmi-party-pay-people-to-participate-in-the-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Did Aam Aadmi Party Pay People To Participate In The Farmers\u2019 Protest? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/pakistani-accounts-spread-fake-news-of-sikh-soldiers-quitting-indian-army-11057",
        headline:
          "Pakistani Accounts Spread Fake News Of Sikh Soldiers Quitting Indian Army",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2017-image-of-punjab-cm-capt-amarinder-singh-with-mukesh-ambani-viral-11060",
        headline:
          "2017 Image Of Punjab CM Capt Amarinder Singh With Mukesh Ambani Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/indian-army-denies-claims-of-troops-being-sent-to-end-farmers-protest-11098",
        headline:
          "Indian Army Denies Claims Of Troops Being Sent To End Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/did-pm-modi-visit-the-ambanis-newborn-grandson-at-the-hospital-11112",
        headline:
          "Did PM Modi Visit Ambanis' Newborn Grandson At The Hospital?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/did-pm-modi-visit-ambanis-newborn-grandson-at-the-hospital-11114",
        headline:
          "\u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0 \u0995\u09bf \u0985\u09ae\u09cd\u09ac\u09be\u09a8\u09c0\u09b0 \u09a8\u09be\u09a4\u09bf \u09a6\u09c7\u0996\u09a4\u09c7 \u09b9\u09be\u09b8\u09aa\u09be\u09a4\u09be\u09b2\u09c7 \u0997\u09c7\u09b2\u09c7\u09a8?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2017-image-of-punjab-cm-capt-amarinder-singh-with-mukesh-ambani-viral-11102",
        headline:
          "\u0995\u09cd\u09af\u09be\u09aa\u09cd\u099f\u09c7\u09a8 \u0985\u09ae\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09b8\u09bf\u0982\u09b9 \u0993 \u09ae\u09c1\u0995\u09c7\u09b6 \u0985\u09ae\u09cd\u09ac\u09be\u09a8\u09c0\u09b0 \u09e8\u09e6\u09e7\u09ed-\u09b0 \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fake-quote-by-ex-australian-pm-malcolm-turnbull-on-reelecting-narendra-modi-11043",
        headline:
          "\u0985\u09b8\u09cd\u099f\u09cd\u09b0\u09c7\u09b2\u09bf\u09df\u09be\u09b0 \u09aa\u09cd\u09b0\u09be\u0995\u09cd\u09a4\u09a8 \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u099f\u09be\u09b0\u09cd\u09a8\u09ac\u09c1\u09b2\u09c7\u09b0 \u09ae\u09cb\u09a6\u09c0 \u09a8\u09bf\u09df\u09c7 \u09ad\u09c1\u09df\u09cb \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2017-image-of-punjab-cm-capt-amarinder-singh-with-mukesh-ambani-viral-11086",
        headline:
          "\u0915\u0948\u092a\u094d\u091f\u0928 \u0905\u092e\u0930\u093f\u0902\u0926\u0930 \u0938\u093f\u0902\u0939 \u0914\u0930 \u092e\u0941\u0915\u0947\u0936 \u0905\u092e\u094d\u092c\u093e\u0928\u0940 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/farmers-protest-old-images-of-sikhs-disrespecting-tricolour-resurface-11108",
        headline: "           ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/pakistani-accounts-spread-fake-news-of-sikh-soldiers-quitting-indian-army-11063",
        headline:
          "\u092a\u093e\u0915 \u0928\u0947\u091f\u093f\u095b\u0947\u0902\u0938 \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u093e: \u0938\u093f\u0916 \u0938\u0948\u0928\u093f\u0915 \u092d\u093e\u0930\u0924\u0940\u092f \u0938\u0947\u0928\u093e \u091b\u094b\u095c \u0930\u0939\u0947 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-of-rajnath-singh-viral-as-supporting-recent-kisan-andolan-11048",
        headline: "   7         ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/indian-army-denies-claims-of-troops-being-sent-to-end-farmers-protest-11109",
        headline: "          ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-purported-tweet-of-former-us-president-barack-obama-about-prime-minister-modi-is-fake-671500",
        headline:
          "Fact check: Purported tweet of former US president Barack Obama about Prime Minister Modi is Fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-photograph-of-justin-trudeau-sitting-with-people-from-sikh-community-is-not-a-protest-671470",
        headline:
          "Fact Check: Viral photograph of Justin Trudeau sitting with people from Sikh community is not a protest",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/hindi/photo-of-turkish-family-from-1979-viral-as-old-family-photo-of-biontech-co-founder-sahin/",
        headline:
          "40 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b\u0930\u094b\u0928\u093e \u0915\u0940 \u0935\u0948\u0915\u094d\u0938\u0940\u0928 \u092c\u0928\u093e\u0928\u0947 \u0935\u093e\u0932\u0947 \u0938\u093e\u0907\u0902\u091f\u093f\u0938\u094d\u091f \u0915\u0947 \u092c\u091a\u092a\u0928 \u0915\u0940 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/photo-of-turkish-family-from-1979-viral-as-old-family-photo-of-biontech-co-founder-sahin/",
        headline:
          "Photo of Turkish family from 1970s viral as old family photo of BioNtech co-founder \u015eahin",
      },
      {
        url:
          "https://factly.in/no-farm-loan-waiver-up-to-two-lakh-rupees-from-december-2020-was-announced-by-the-central-government/",
        headline:
          " No farm loan waiver up to 2 lakh rupees from December 2020 was announced by the Central Government.",
      },
      {
        url:
          "https://factly.in/morphed-photo-shared-as-german-photographer-captured-sun-and-moon-between-two-trees-using-16-cameras/",
        headline:
          " Morphed photo shared as German photographer\u2019s capture sun and moon between two trees using 16 cameras",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-people-beating-a-police-constable-is-fro-rajasthan-not-telangana/",
        headline:
          " \u0c15\u0c3e\u0c28\u0c3f\u0c38\u0c4d\u0c1f\u0c47\u0c2c\u0c41\u0c32\u0c4d \u0c28\u0c3f \u0c15\u0c4a\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c18\u0c1f\u0c28 \u0c30\u0c3e\u0c1c\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f; \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-government-did-not-reject-the-application-of-bharath-biotech-sought-more-information/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24\u0c4d \u0c2c\u0c2f\u0c4b\u0c1f\u0c46\u0c15\u0c4d \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d \u0c05\u0c24\u0c4d\u0c2f\u0c35\u0c38\u0c30 \u0c35\u0c3f\u0c28\u0c3f\u0c2f\u0c4b\u0c17\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c05\u0c2a\u0c4d\u0c32\u0c3f\u0c15\u0c47\u0c37\u0c28\u0c4d \u0c28\u0c3f \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c24\u0c3f\u0c30\u0c38\u0c4d\u0c15\u0c30\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41, \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c2e\u0c30\u0c3f\u0c02\u0c24 \u0c38\u0c2e\u0c3e\u0c1a\u0c3e\u0c30\u0c02 \u0c15\u0c4b\u0c30\u0c3f\u0c02\u0c26\u0c3f.",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/old-fake-message-about-kidney-donation-viral-again-11022",
        headline: "Old Fake Message About Kidney Donation Viral Again",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/farmers-protest-2018-image-of-long-march-in-maharashtra-revived-11047",
        headline:
          "Farmers Protest: 2018 Image Of Long March In Maharashtra Revived",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-is-not-the-family-portrait-of-covid-19-vaccine-developer-11093",
        headline:
          "No, This Is Not The Family Portrait Of COVID-19 Vaccine Developer",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-2018-image-of-long-march-in-maharashtra-revived-11059",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09ae\u09b9\u09be\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09c7 \u0986\u09df\u09cb\u099c\u09bf\u09a4 '\u09b2\u0982 \u09ae\u09be\u09b0\u09cd\u099a\u09c7\u09b0' \u099b\u09ac\u09bf \u09ab\u09bf\u09b0\u09c7 \u098f\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fuel-price-pinch-in-india-viral-pic-claiming-german-mass-protest-is-from-china-11089",
        headline:
          "\u09e8\u09e6\u09e7\u09e8 \u099a\u09bf\u09a8\u09c7\u09b0 \u09af\u09be\u09a8\u099c\u099f\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u099c\u09be\u09b0\u09cd\u09ae\u09be\u09a8\u09bf\u09a4\u09c7 \u09a4\u09c7\u09b2\u09c7\u09b0 \u09a6\u09be\u09ae \u09ac\u09be\u09a1\u09bc\u09be\u09df \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-was-pfizer-s-first-covid-19-vaccination-in-the-uk-a-staged-event-1749168-2020-12-13",
        headline:
          "Fact Check: Was Pfizer\u2019s first Covid-19 vaccination in the UK a staged event?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-the-childhood-family-photo-of-covid-19-vaccine-inventor-1748508-2020-12-10",
        headline:
          " Fact Check: This is not the childhood family photo of Covid-19 vaccine inventor",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/old-fake-message-about-kidney-donation-viral-again-11022",
        headline: "Old Fake Message About Kidney Donation Viral Again",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-is-not-the-family-portrait-of-covid-19-vaccine-developer-11093",
        headline:
          "No, This Is Not The Family Portrait Of COVID-19 Vaccine Developer",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-2018-image-of-long-march-in-maharashtra-revived-11047",
        headline:
          "Farmers Protest: 2018 Image Of Long March In Maharashtra Revived",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fuel-price-pinch-in-india-viral-pic-claiming-german-mass-protest-is-from-china-11089",
        headline:
          "\u09e8\u09e6\u09e7\u09e8 \u099a\u09bf\u09a8\u09c7\u09b0 \u09af\u09be\u09a8\u099c\u099f\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u099c\u09be\u09b0\u09cd\u09ae\u09be\u09a8\u09bf\u09a4\u09c7 \u09a4\u09c7\u09b2\u09c7\u09b0 \u09a6\u09be\u09ae \u09ac\u09be\u09a1\u09bc\u09be\u09df \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-2018-aap-rally-video-viral-as-delhi-chalo-protest-11085",
        headline: " :      ' '   ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-2018-image-of-long-march-in-maharashtra-revived-11059",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09ae\u09b9\u09be\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09c7 \u0986\u09df\u09cb\u099c\u09bf\u09a4 '\u09b2\u0982 \u09ae\u09be\u09b0\u09cd\u099a\u09c7\u09b0' \u099b\u09ac\u09bf \u09ab\u09bf\u09b0\u09c7 \u098f\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/did-modi-pay-a-visit-to-ambanis-newborn-grandson-at-the-hospital-11113",
        headline: "           ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/two-images-from-anti-caa-protest-shaheen-bagh-falsely-linked-to-kisan-andolan-delhi-11024",
        headline: "           ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photograph-of-turkish-immigrant-boy-falsely-claimed-to-be-dr-ugur-sahin-671701",
        headline:
          "Fact Check: Photograph of Turkish immigrant boy falsely claimed to be Dr Ugur Sahin",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-doctored-caricature-shared-online-has-not-been-created-by-american-cartoonist-ben-garrison-671449",
        headline:
          "Fact check: Doctored caricature shared online has not been created by American cartoonist Ben Garrison",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-centre-has-not-rejected-emergency-authorization-of-ssi-bharat-biotechs-covid-vaccines-671579",
        headline:
          "Fact Check: No, Centre has not rejected emergency authorization of SSI, Bharat Biotech's Covid vaccines",
      },
    ],
    "6": [
      {
        url:
          "https://www.altnews.in/hindi/parody-post-claiming-owaisi-said-he-will-convert-to-hinduism-believed-to-be-true/",
        headline:
          "\u0938\u091f\u093e\u092f\u0930 \u091f\u094d\u0935\u0940\u091f \u0915\u094b \u0938\u091a \u092e\u093e\u0928\u0924\u0947 \u0939\u0941\u090f \u0932\u094b\u0917\u094b\u0902 \u0928\u0947 \u0913\u0935\u0948\u0938\u0940 \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u092c\u092f\u093e\u0928 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-from-rajasthan-shared-with-communal-angle-as-muslims-beaten-up-a-cop-in-hyderabad/",
        headline:
          "\u0905\u091c\u092e\u0947\u0930 \u0926\u0930\u0917\u093e\u0939 \u0915\u0947 \u092a\u093e\u0938 \u0928\u0936\u0947 \u092e\u0947\u0902 \u0927\u0941\u0924 \u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u0940 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/video-from-rajasthan-shared-with-communal-angle-as-muslims-beaten-up-a-cop-in-hyderabad/",
        headline:
          "Drunk cop beaten for alleged misbehavior with woman shared with false communal spin",
      },
      {
        url:
          "https://www.altnews.in/parody-post-claiming-owaisi-said-he-will-convert-to-hinduism-believed-to-be-true/",
        headline:
          "Parody post claiming Owaisi said he will convert to Hinduism believed to be true",
      },
      {
        url:
          "https://www.factcrescendo.com/sudhir-pandeys-video-shared-as-anil-upadhyays/",
        headline:
          "\u0938\u0941\u0927\u0940\u0930 \u092a\u093e\u0902\u0921\u0947 \u0928\u093e\u092e\u0915 \u0936\u0916\u094d\u0938 \u0915\u094b \u0915\u0925\u093f\u0924 \u0915\u093e\u0932\u094d\u092a\u0928\u093f\u0915 \u0935\u093f\u0927\u093e\u092f\u0915 \u0905\u0928\u093f\u0932 \u0909\u092a\u093e\u0927\u094d\u092f\u093e\u092f \u092c\u0924\u093e \u0935\u093e\u092f\u0930\u0932 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948\u0964",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/07/fact-check-no-indian-railways-has-not-renamed-hazrat-nizamuddin-station-as-maharana-pratap-expression/",
        headline:
          "Fact Check: No, Indian Railways Has NOT Renamed Hazrat Nizamuddin Station As \u2018Maharana Pratap Expression\u2019",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/farmers-protest-2018-pic-of-hrithik-roshan-shared-as-recent-11030",
        headline:
          "Farmers' Protest: 2018 Pic Of Hrithik Roshan Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/did-jp-nadda-say-rabindranath-tagore-was-born-at-visva-bharati-11077",
        headline:
          "Did JP Nadda Say Rabindranath Tagore Was Born At Visva Bharati?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/morphed-image-of-suvendu-adhikari-and-jp-nadda-shared-with-false-claims-11023",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u099c\u09c7\u09aa\u09bf \u09a8\u09be\u09a1\u09cd\u09a1\u09be\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09b6\u09c1\u09ad\u09c7\u09a8\u09cd\u09a6\u09c1 \u0985\u09a7\u09bf\u0995\u09be\u09b0\u09c0\u09b0 \u09ac\u09bf\u0995\u09c3\u09a4 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-stir-photo-of-actress-sonia-mann-shared-with-a-fake-backstory-11049",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09ad\u09c1\u09df\u09cb \u0997\u09be\u09b2\u0997\u09b2\u09cd\u09aa\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0985\u09ad\u09bf\u09a8\u09c7\u09a4\u09cd\u09b0\u09c0 \u09b8\u09cb\u09a8\u09bf\u09df\u09be \u09ae\u09be\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/did-jp-nadda-say-rabindranath-tagore-was-born-at-visva-bharati-11088",
        headline:
          "\u099c\u09c7\u09aa\u09bf \u09a8\u09be\u09a1\u09cd\u09a1\u09be \u0995\u09bf \u09ac\u09b2\u09c7\u099b\u09bf\u09b2\u09c7\u09a8 \u09b0\u09ac\u09c0\u09a8\u09cd\u09a6\u09cd\u09b0\u09a8\u09be\u09a5\u09c7\u09b0 \u099c\u09a8\u09cd\u09ae \u09ac\u09bf\u09b6\u09cd\u09ac\u09ad\u09be\u09b0\u09a4\u09c0\u09a4\u09c7?",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/07/fact-check-no-indian-railways-has-not-renamed-hazrat-nizamuddin-station-as-maharana-pratap-expression/",
        headline:
          "Fact Check: No, Indian Railways Has NOT Renamed Hazrat Nizamuddin Station As \u2018Maharana Pratap Expression\u2019",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-2018-pic-of-hrithik-roshan-shared-as-recent-11030",
        headline:
          "Farmers' Protest: 2018 Pic Of Hrithik Roshan Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/did-jp-nadda-say-rabindranath-tagore-was-born-at-visva-bharati-11077",
        headline:
          "Did JP Nadda Say Rabindranath Tagore Was Born At Visva Bharati?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/did-jp-nadda-say-rabindranath-tagore-was-born-at-visva-bharati-11088",
        headline:
          "\u099c\u09c7\u09aa\u09bf \u09a8\u09be\u09a1\u09cd\u09a1\u09be \u0995\u09bf \u09ac\u09b2\u09c7\u099b\u09bf\u09b2\u09c7\u09a8 \u09b0\u09ac\u09c0\u09a8\u09cd\u09a6\u09cd\u09b0\u09a8\u09be\u09a5\u09c7\u09b0 \u099c\u09a8\u09cd\u09ae \u09ac\u09bf\u09b6\u09cd\u09ac\u09ad\u09be\u09b0\u09a4\u09c0\u09a4\u09c7?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/morphed-image-of-suvendu-adhikari-and-jp-nadda-shared-with-false-claims-11023",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u099c\u09c7\u09aa\u09bf \u09a8\u09be\u09a1\u09cd\u09a1\u09be\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09b6\u09c1\u09ad\u09c7\u09a8\u09cd\u09a6\u09c1 \u0985\u09a7\u09bf\u0995\u09be\u09b0\u09c0\u09b0 \u09ac\u09bf\u0995\u09c3\u09a4 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-stir-photo-of-actress-sonia-mann-shared-with-a-fake-backstory-11049",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09ad\u09c1\u09df\u09cb \u0997\u09be\u09b2\u0997\u09b2\u09cd\u09aa\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0985\u09ad\u09bf\u09a8\u09c7\u09a4\u09cd\u09b0\u09c0 \u09b8\u09cb\u09a8\u09bf\u09df\u09be \u09ae\u09be\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-photos-do-not-show-young-sonia-gandhi-as-bar-waitress-671609",
        headline:
          "Fact Check: Viral photos do not show young Sonia Gandhi as 'bar waitress'",
      },
    ],
  },
  numpy_seed: 20,
  number_of_articles: 176,
}
