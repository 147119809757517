export const data = {
  mdsDat: {
    x: [
      0.33160020274298013,
      -0.15045130985440378,
      0.18026153133208017,
      -0.2428876864005915,
      -0.1185227378200651,
    ],
    y: [
      -0.18578050277291308,
      -0.16663938638732184,
      0.3186676142025329,
      0.1359656547086789,
      -0.10221337975097716,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      17.77353350027229,
      23.07124784558193,
      21.991710231763243,
      14.260904201307294,
      22.902604221075233,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      21.0,
      10.0,
      23.0,
      8.0,
      12.0,
      23.0,
      13.0,
      9.0,
      12.0,
      9.0,
      3.9973682891178433,
      0.9993420722794608,
      1.9986841445589216,
      0.9993420722794608,
      0.9993420722794608,
      0.9993420722794608,
      0.9993420722794608,
      1.9986841445589216,
      0.9993420722794608,
      0.9993420722794608,
      4.996710361397305,
      4.996710361397305,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      2.9980262168383827,
      7.9947365782356865,
      8.994078650515148,
      7.9947365782356865,
      4.996710361397305,
      4.996710361397305,
      1.0000337247975877,
      1.0000337247975877,
      2.0000674495951754,
      2.0000674495951754,
      2.0000674495951754,
      2.0000674495951754,
      2.0000674495951754,
      1.0000337247975877,
      2.0000674495951754,
      1.0000337247975877,
      6.0002023487855265,
      5.000168623987938,
      5.000168623987938,
      5.000168623987938,
      5.000168623987938,
      5.000168623987938,
      4.000134899190351,
      4.000134899190351,
      4.000134899190351,
      4.000134899190351,
      4.000134899190351,
      12.000404697571053,
      12.000404697571053,
      7.000236073583114,
      6.0002023487855265,
      14.000472147166228,
      6.0002023487855265,
      6.0002023487855265,
      1.0005388777199153,
      2.0010777554398307,
      1.0005388777199153,
      1.0005388777199153,
      1.0005388777199153,
      2.0010777554398307,
      1.0005388777199153,
      4.002155510879661,
      1.0005388777199153,
      4.002155510879661,
      9.00484989947924,
      9.00484989947924,
      6.0032332663194925,
      4.002155510879661,
      4.002155510879661,
      4.002155510879661,
      3.0016166331597463,
      3.0016166331597463,
      3.0016166331597463,
      3.0016166331597463,
      3.0016166331597463,
      17.00916092123856,
      3.0016166331597463,
      6.0032332663194925,
      5.002694388599577,
      4.002155510879661,
      4.002155510879661,
      1.9998820950539171,
      3.9997641901078342,
      3.9997641901078342,
      0.9999410475269586,
      1.9998820950539171,
      2.9998231425808757,
      0.9999410475269586,
      0.9999410475269586,
      1.9998820950539171,
      0.9999410475269586,
      8.999469427742627,
      4.999705237634792,
      4.999705237634792,
      3.9997641901078342,
      2.9998231425808757,
      2.9998231425808757,
      2.9998231425808757,
      9.999410475269585,
      5.999646285161751,
      7.9995283802156685,
      3.9997641901078342,
      4.999982459985656,
      8.99996842797418,
      9.999964919971312,
      4.999982459985656,
      0.9999964919971311,
      0.9999964919971311,
      1.9999929839942623,
      0.9999964919971311,
      2.9999894759913937,
      1.9999929839942623,
      6.999975443979918,
      3.9999859679885246,
      2.9999894759913937,
      2.9999894759913937,
      2.9999894759913937,
      2.9999894759913937,
      17.99993685594836,
      15.999943871954098,
      7.999971935977049,
      6.999975443979918,
      6.999975443979918,
      11.999957903965575,
      9.999964919971312,
    ],
    Term: [
      "protest",
      "macron",
      "bihar",
      "egg",
      "woman",
      "french",
      "embassy",
      "teacher",
      "police",
      "election",
      "idol",
      "use",
      "vacancy",
      "daughter",
      "july",
      "sound",
      "bangladeshi",
      "zuckerberg",
      "short",
      "abuse",
      "protester",
      "munger",
      "parliament",
      "idol_immersion",
      "bengal",
      "immersion",
      "brutality",
      "modi_modi",
      "uttar",
      "durga",
      "police",
      "modi",
      "say",
      "france",
      "padmashila",
      "see",
      "hebdo",
      "blast",
      "shaheen",
      "azerbaijan",
      "bomb",
      "lumpur",
      "play",
      "interview",
      "war",
      "construction",
      "ram",
      "construction_ram",
      "sudan",
      "assault",
      "muslim_woman",
      "tatarstan",
      "karachi",
      "civil_war",
      "civil",
      "woman",
      "embassy",
      "temple",
      "french_embassy",
      "attack",
      "french",
      "france",
      "podium",
      "unbranded",
      "patent",
      "person",
      "ballot",
      "adult",
      "narendra",
      "jhansi",
      "thailand",
      "bihar_election",
      "election",
      "rally",
      "gujarat",
      "surround",
      "homeless",
      "people",
      "body",
      "leader",
      "district",
      "yemeni",
      "yadav",
      "bihar",
      "kodagu",
      "covid",
      "link",
      "man",
      "yogi",
      "name",
      "french_president",
      "yemen_goe",
      "recruitment",
      "paris_beheade",
      "emanuel",
      "debts",
      "imf",
      "language",
      "include",
      "egg",
      "emmanuel",
      "emmanuel_macron",
      "beheading",
      "team",
      "pogba",
      "remark",
      "macron",
      "yemen",
      "french",
      "president",
      "french_teacher",
      "behead",
      "teacher",
      "brazil",
      "sabotage",
      "action",
      "teacher_paris",
      "chouhan",
      "muslim_man",
      "asha",
      "muslims",
      "nice",
      "cctv",
      "minister",
      "unrelated_video",
      "church",
      "protest",
      "france",
      "muslim",
      "paris",
      "show",
      "attack",
      "french",
    ],
    Total: [
      21.0,
      10.0,
      23.0,
      8.0,
      12.0,
      23.0,
      13.0,
      9.0,
      12.0,
      9.0,
      3.9973682891178433,
      0.9993420722794608,
      1.9986841445589216,
      0.9993420722794608,
      0.9993420722794608,
      0.9993420722794608,
      0.9993420722794608,
      1.9986841445589216,
      0.9993420722794608,
      0.9993420722794608,
      4.996710361397305,
      4.996710361397305,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      3.9973682891178433,
      2.9980262168383827,
      8.995275455955602,
      12.994064618503671,
      11.996892089115349,
      8.99785556643231,
      28.996738677190848,
      1.0000337247975877,
      1.0000337247975877,
      2.0000674495951754,
      2.0000674495951754,
      2.0000674495951754,
      2.0000674495951754,
      2.0000674495951754,
      1.0000337247975877,
      2.0000674495951754,
      1.0000337247975877,
      6.0002023487855265,
      5.000168623987938,
      5.000168623987938,
      5.000168623987938,
      5.000168623987938,
      5.000168623987938,
      4.000134899190351,
      4.000134899190351,
      4.000134899190351,
      4.000134899190351,
      4.000134899190351,
      12.999746769850514,
      13.000345745098011,
      8.000774951303029,
      7.000143396312485,
      30.000792071432592,
      23.999695648972505,
      28.996738677190848,
      1.0005388777199153,
      2.0010777554398307,
      1.0005388777199153,
      1.0005388777199153,
      1.0005388777199153,
      2.0010777554398307,
      1.0005388777199153,
      4.002155510879661,
      1.0005388777199153,
      4.002155510879661,
      9.00484989947924,
      9.00484989947924,
      6.0032332663194925,
      4.002155510879661,
      4.002155510879661,
      4.002155510879661,
      3.0016166331597463,
      3.0016166331597463,
      3.0016166331597463,
      3.0016166331597463,
      3.0016166331597463,
      23.006522194350666,
      3.0016166331597463,
      8.003300715914667,
      12.002688853710236,
      7.0021449868710555,
      6.002222960474837,
      1.9998820950539171,
      3.9997641901078342,
      3.9997641901078342,
      0.9999410475269586,
      1.9998820950539171,
      2.9998231425808757,
      0.9999410475269586,
      0.9999410475269586,
      1.9998820950539171,
      0.9999410475269586,
      8.999469427742627,
      4.999705237634792,
      4.999705237634792,
      3.9997641901078342,
      2.9998231425808757,
      2.9998231425808757,
      2.9998231425808757,
      10.999406967266715,
      9.001262918321498,
      23.999695648972505,
      5.998448334666756,
      4.999982459985656,
      8.99996842797418,
      9.999964919971312,
      4.999982459985656,
      0.9999964919971311,
      0.9999964919971311,
      1.9999929839942623,
      0.9999964919971311,
      2.9999894759913937,
      1.9999929839942623,
      6.999975443979918,
      3.9999859679885246,
      2.9999894759913937,
      2.9999894759913937,
      2.9999894759913937,
      2.9999894759913937,
      21.00095565891515,
      28.996738677190848,
      12.999448907446862,
      10.999739634087753,
      11.001120649014924,
      30.000792071432592,
      23.999695648972505,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.7275,
      1.6095,
      1.3595,
      1.3216,
      1.1393,
      -0.0309,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.4666,
      1.3866,
      1.3865,
      1.333,
      1.3124,
      0.7045,
      0.0803,
      -0.1088,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.5145,
      1.2125,
      1.5145,
      1.2269,
      0.6394,
      0.9551,
      1.1092,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.9476,
      1.8523,
      1.542,
      0.849,
      1.5424,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.4739,
      1.3197,
      0.8793,
      0.9885,
      1.022,
      1.0218,
      0.5576,
      0.5985,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.9703,
      -5.3566,
      -4.6634,
      -5.3566,
      -5.3566,
      -5.3566,
      -5.3566,
      -4.6634,
      -5.3566,
      -5.3566,
      -3.7471,
      -3.7471,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -4.258,
      -3.2771,
      -3.1594,
      -3.2771,
      -3.7471,
      -3.7471,
      -5.6168,
      -5.6168,
      -4.9236,
      -4.9236,
      -4.9236,
      -4.9236,
      -4.9236,
      -5.6168,
      -4.9236,
      -5.6168,
      -3.825,
      -4.0073,
      -4.0073,
      -4.0073,
      -4.0073,
      -4.0073,
      -4.2305,
      -4.2305,
      -4.2305,
      -4.2305,
      -4.2305,
      -3.1319,
      -3.1319,
      -3.6709,
      -3.825,
      -2.9777,
      -3.825,
      -3.825,
      -5.5683,
      -4.8752,
      -5.5683,
      -5.5683,
      -5.5683,
      -4.8752,
      -5.5683,
      -4.1821,
      -5.5683,
      -4.1821,
      -3.3711,
      -3.3711,
      -3.7766,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.4697,
      -4.4697,
      -4.4697,
      -4.4697,
      -4.4697,
      -2.7351,
      -4.4697,
      -3.7766,
      -3.9589,
      -4.1821,
      -4.1821,
      -4.4427,
      -3.7495,
      -3.7495,
      -5.1358,
      -4.4427,
      -4.0372,
      -5.1358,
      -5.1358,
      -4.4427,
      -5.1358,
      -2.9386,
      -3.5264,
      -3.5264,
      -3.7495,
      -4.0372,
      -4.0372,
      -4.0372,
      -2.8332,
      -3.344,
      -3.0564,
      -3.7495,
      -4.0,
      -3.4122,
      -3.3069,
      -4.0,
      -5.6095,
      -5.6095,
      -4.9163,
      -5.6095,
      -4.5109,
      -4.9163,
      -3.6636,
      -4.2232,
      -4.5109,
      -4.5109,
      -4.5109,
      -4.5109,
      -2.7191,
      -2.8369,
      -3.53,
      -3.6636,
      -3.6636,
      -3.1246,
      -3.3069,
    ],
  },
  "token.table": {
    Topic: [
      1,
      5,
      3,
      5,
      2,
      2,
      3,
      4,
      5,
      2,
      3,
      1,
      5,
      4,
      1,
      1,
      3,
      5,
      3,
      2,
      3,
      2,
      5,
      1,
      5,
      5,
      5,
      2,
      2,
      2,
      2,
      2,
      3,
      1,
      4,
      3,
      1,
      3,
      4,
      3,
      4,
      2,
      4,
      4,
      4,
      1,
      2,
      4,
      5,
      2,
      4,
      5,
      2,
      4,
      4,
      5,
      3,
      2,
      3,
      1,
      1,
      4,
      1,
      4,
      2,
      3,
      1,
      2,
      3,
      4,
      3,
      2,
      3,
      4,
      5,
      2,
      4,
      5,
      3,
      5,
      5,
      1,
      3,
      1,
      1,
      1,
      2,
      5,
      5,
      2,
      5,
      4,
      3,
      5,
      2,
      4,
      5,
      4,
      1,
      3,
      3,
      3,
      2,
      3,
      4,
      1,
      5,
      1,
      4,
      3,
      4,
      5,
      1,
      3,
      2,
      4,
      4,
      5,
      1,
      2,
      3,
      2,
      2,
      1,
      2,
      3,
      5,
      1,
      2,
      3,
      2,
      5,
      5,
      4,
      2,
      3,
      3,
      3,
      5,
      1,
      1,
      1,
      2,
      1,
      2,
      3,
      3,
      4,
      4,
      3,
      2,
      3,
      1,
    ],
    Freq: [
      1.0006583608744086,
      1.000003508015175,
      0.9994614125128817,
      1.000003508015175,
      0.999966276339736,
      0.4666543458807911,
      0.033332453277199366,
      0.09999735983159809,
      0.39998943932639236,
      0.9999662763397359,
      0.9994614125128817,
      1.0006583608744086,
      1.000003508015175,
      1.0000589559486404,
      1.0006583608744086,
      0.17386374029979265,
      0.7389208962741188,
      0.08693187014989633,
      0.9994614125128817,
      0.9999662763397359,
      0.9994614125128816,
      0.9999662763397359,
      1.000003508015175,
      1.0006583608744086,
      1.000003508015175,
      1.000003508015175,
      1.000003508015175,
      0.9999662763397359,
      0.9999662763397359,
      0.999966276339736,
      0.999966276339736,
      0.24989689516763677,
      0.7496906855029103,
      1.0006583608744086,
      1.0000589559486404,
      0.9994614125128816,
      0.8893557556042768,
      0.1111694694505346,
      1.0000589559486404,
      0.9994614125128816,
      1.0000589559486404,
      0.9230523737820429,
      0.07692103114850357,
      1.0000589559486406,
      1.0000589559486406,
      0.17243318483719877,
      0.20691982180463853,
      0.06897327393487951,
      0.5517861914790361,
      0.25000317036340736,
      0.33333756048454316,
      0.41667195060567896,
      0.8571252987704028,
      0.1428542164617338,
      1.0000589559486404,
      1.000003508015175,
      0.9994614125128816,
      0.9999662763397359,
      0.9994614125128817,
      1.0006583608744086,
      1.0006583608744086,
      1.0000589559486404,
      1.0006583608744086,
      1.0000589559486404,
      0.9999662763397359,
      0.9994614125128817,
      1.0006583608744086,
      0.9999662763397359,
      0.9994614125128816,
      1.0000589559486404,
      0.9994614125128816,
      0.1666293298423516,
      0.41657332460587904,
      0.0833146649211758,
      0.3332586596847032,
      0.9999662763397359,
      0.9091399227030271,
      0.09091399227030272,
      0.5712535240986806,
      0.42844014307401046,
      1.000003508015175,
      0.6668393731121675,
      0.3334196865560837,
      1.0006583608744086,
      1.0006583608744084,
      0.07692633796399939,
      0.30770535185599757,
      0.6154107037119951,
      1.000003508015175,
      0.9999662763397359,
      1.000003508015175,
      1.0000589559486404,
      0.9994614125128817,
      1.000003508015175,
      0.9999662763397359,
      0.36364497097769116,
      0.6363786992109595,
      1.0000589559486404,
      1.0006583608744086,
      0.9994614125128817,
      0.9994614125128817,
      0.9994614125128817,
      0.9999662763397359,
      0.9994614125128817,
      1.0000589559486404,
      0.692623922093162,
      0.30783285426362755,
      0.333419559261922,
      0.666839118523844,
      0.09523376138128156,
      0.04761688069064078,
      0.8571038524315341,
      1.0006583608744084,
      0.9994614125128816,
      0.999966276339736,
      1.0000589559486404,
      1.0000589559486404,
      1.000003508015175,
      0.5556879595459568,
      0.22227518381838274,
      0.22227518381838274,
      0.9999662763397359,
      0.9999662763397359,
      1.0006583608744086,
      0.1817996605808597,
      0.1817996605808597,
      0.6362988120330089,
      1.0006583608744086,
      0.999966276339736,
      0.9994614125128817,
      0.9999662763397359,
      1.000003508015175,
      1.000003508015175,
      1.0000589559486404,
      0.8749152479110739,
      0.12498789255872485,
      0.9994614125128817,
      0.9994614125128817,
      1.000003508015175,
      1.0006583608744086,
      1.0006583608744084,
      1.0006583608744086,
      0.9999662763397359,
      0.07692457535551665,
      0.9230949042661998,
      0.9994614125128816,
      0.33328656514339683,
      0.6665731302867937,
      1.0000589559486404,
      0.9994614125128816,
      0.3332098812673529,
      0.6664197625347058,
      1.0006583608744086,
    ],
    Term: [
      "abuse",
      "action",
      "adult",
      "asha",
      "assault",
      "attack",
      "attack",
      "attack",
      "attack",
      "azerbaijan",
      "ballot",
      "bangladeshi",
      "behead",
      "beheading",
      "bengal",
      "bihar",
      "bihar",
      "bihar",
      "bihar_election",
      "blast",
      "body",
      "bomb",
      "brazil",
      "brutality",
      "cctv",
      "chouhan",
      "church",
      "civil",
      "civil_war",
      "construction",
      "construction_ram",
      "covid",
      "covid",
      "daughter",
      "debts",
      "district",
      "durga",
      "durga",
      "egg",
      "election",
      "emanuel",
      "embassy",
      "embassy",
      "emmanuel",
      "emmanuel_macron",
      "france",
      "france",
      "france",
      "france",
      "french",
      "french",
      "french",
      "french_embassy",
      "french_embassy",
      "french_president",
      "french_teacher",
      "gujarat",
      "hebdo",
      "homeless",
      "idol",
      "idol_immersion",
      "imf",
      "immersion",
      "include",
      "interview",
      "jhansi",
      "july",
      "karachi",
      "kodagu",
      "language",
      "leader",
      "link",
      "link",
      "link",
      "link",
      "lumpur",
      "macron",
      "macron",
      "man",
      "man",
      "minister",
      "modi",
      "modi",
      "modi_modi",
      "munger",
      "muslim",
      "muslim",
      "muslim",
      "muslim_man",
      "muslim_woman",
      "muslims",
      "name",
      "narendra",
      "nice",
      "padmashila",
      "paris",
      "paris",
      "paris_beheade",
      "parliament",
      "patent",
      "people",
      "person",
      "play",
      "podium",
      "pogba",
      "police",
      "police",
      "president",
      "president",
      "protest",
      "protest",
      "protest",
      "protester",
      "rally",
      "ram",
      "recruitment",
      "remark",
      "sabotage",
      "say",
      "say",
      "say",
      "see",
      "shaheen",
      "short",
      "show",
      "show",
      "show",
      "sound",
      "sudan",
      "surround",
      "tatarstan",
      "teacher",
      "teacher_paris",
      "team",
      "temple",
      "temple",
      "thailand",
      "unbranded",
      "unrelated_video",
      "use",
      "uttar",
      "vacancy",
      "war",
      "woman",
      "woman",
      "yadav",
      "yemen",
      "yemen",
      "yemen_goe",
      "yemeni",
      "yogi",
      "yogi",
      "zuckerberg",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018s-image-of-flooded-underpass-in-up-shared-as-bihar-10378",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09a1\u09c1\u09ac\u09c7 \u09af\u09be\u0993\u09df\u09be \u0986\u09a8\u09cd\u09a1\u09be\u09b0\u09aa\u09be\u09b8\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-posts-claiming-about-no-vote-no-covid-vaccine-is-misleading-2/",
        headline:
          "Fact Check: \u2018\u0935\u094b\u091f \u0928\u0939\u0940\u0902 \u0924\u094b \u0915\u094b\u0930\u094b\u0928\u093e \u0935\u0948\u0915\u094d\u0938\u0940\u0928 \u0928\u0939\u0940\u0902\u2019 \u0915\u093e \u0926\u093e\u0935\u093e \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u0940 \u092a\u094b\u0938\u094d\u091f \u092d\u094d\u0930\u093e\u092e\u0915",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/police-brutality-during-idol-immersion-in-munger-shared-as-west-bengal-10389",
        headline:
          "Police Brutality During Idol Immersion In Munger Shared As West Bengal",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2012-video-of-turkish-police-dispersing-protesters-falsely-shared-as-france-10410",
        headline:
          "2012 Video Of Turkish Police Dispersing Protesters Falsely Shared As France",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-from-turkey-shared-as-french-police-attack-on-muslims-praying-on-street-10424",
        headline:
          "\u0928\u092e\u093e\u095b \u092a\u095d\u0924\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u0915\u093e\u0930\u093f\u092f\u094b\u0902 \u092a\u0930 \u0906\u0902\u0938\u0942 \u0917\u0948\u0938 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u094d\u0930\u093e\u0902\u0938 \u0915\u093e \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/police-brutality-during-idol-immersion-in-munger-shared-as-west-bengal-10393",
        headline:
          "\u09a6\u09c1\u09b0\u09cd\u0997\u09be \u09a0\u09be\u0995\u09c1\u09b0 \u09ac\u09bf\u09b8\u09b0\u09cd\u099c\u09a8\u09c7 \u09ae\u09c1\u0999\u09cd\u0997\u09c7\u09b0\u09c7 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u09ac\u09b0\u09cd\u09ac\u09b0\u09a4\u09be \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://factly.in/this-video-of-police-lathi-charge-on-a-durga-pooja-procession-is-from-bihar-not-bengal/",
        headline:
          " This video of Police lathi charge on a Durga Pooja procession is from Bihar not Bengal.",
      },
      {
        url:
          "https://factly.in/2012-video-from-turkey-is-being-shared-as-police-using-tear-gas-against-muslim-protesters-in-france/",
        headline:
          " 2012 video from Turkey is being shared as police using tear gas against Muslim protesters in France",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-did-pakistan-mps-chant-modi-modi-inside-parliament-1736393-2020-10-29",
        headline:
          "Fact Check: Did Pakistan MPs chant \u2018Modi, Modi\u2019 inside Parliament?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-image-of-mark-zuckerberg-and-emmanuel-macron-shared-with-misleading-claim-10434",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u099c\u09be\u0995\u09be\u09b0\u09ac\u09be\u09b0\u09cd\u0997 \u0993 \u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09aa\u09a4\u09bf \u09ae\u09be\u0995\u09b0\u0981\u09b0 \u09ac\u09c8\u09a0\u0995\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/26/fact-check-dont-believe-this-post-about-job-vacancies-listed-by-a-fake-institute-claiming-to-be-under-health-ministry/",
        headline:
          "Fact check: Don\u2019t believe this post about job vacancies listed by a fake institute claiming to be under Health ministry",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-munger-victim-anurag-poddar-s-mother-is-not-dead-1736388-2020-10-29",
        headline:
          "Fact check: No, Munger victim Anurag Poddar\u2019s mother is not dead",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-of-police-lathi-charge-during-durga-idol-immersion-is-from-bihar/",
        headline:
          "\u0926\u0941\u0930\u094d\u0917\u093e \u092e\u0942\u0930\u094d\u0924\u093f \u0935\u093f\u0938\u0930\u094d\u091c\u0928 \u092e\u0947\u0902 \u0932\u093e\u0920\u0940\u091a\u093e\u0930\u094d\u091c \u0915\u093e \u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u0902\u0917\u093e\u0932 \u0928\u0939\u0940\u0902 \u092c\u093f\u0939\u093e\u0930 \u0915\u093e",
      },
      {
        url:
          "https://www.altnews.in/visuals-from-bangladesh-short-film-shared-with-false-claim-to-target-madarsa-in-india/",
        headline:
          "Bangladeshi short film scenes shared to claim sexual abuse in Indian madrasas",
      },
      {
        url:
          "https://www.altnews.in/video-of-police-lathi-charge-during-durga-idol-immersion-is-from-bihar/",
        headline:
          "Video of police lathi-charge during Durga idol immersion is from Bihar",
      },
      {
        url:
          "https://www.altnews.in/video-verification-in-july-murder-of-a-gangrape-victim-and-her-mother-by-rape-accused-in-kasganj-up-shared-as-recent/",
        headline:
          "Video of mother-daughter killed by rape-accused in Kasganj is from July",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/26/fact-check-dont-believe-this-post-about-job-vacancies-listed-by-a-fake-institute-claiming-to-be-under-health-ministry/",
        headline:
          "Fact check: Don\u2019t believe this post about job vacancies listed by a fake institute claiming to be under Health ministry",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/cropped-screenshot-of-the-wire-article-on-durga-puja-is-misleading-10364",
        headline:
          "\u09a6\u09cd\u09af \u0993\u09df\u09cd\u09af\u09be\u09b0\u09c7 \u09a6\u09c1\u09b0\u09cd\u0997\u09be\u09aa\u09c1\u099c\u09cb\u09b0 \u09a8\u09bf\u09df\u09c7 \u09b2\u09c7\u0996\u09be\u09b0 \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u09b8\u09cd\u0995\u09cd\u09b0\u09bf\u09a8\u09b6\u099f\u099f\u09bf \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/did-pak-mps-chant-modi-modi-in-parliament-no-theyre-chanting-voting-10406",
        headline:
          "Did Pak MPs Chant 'Modi Modi' In Parliament? No, They're Chanting 'Voting'",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/police-brutality-during-idol-immersion-in-munger-shared-as-west-bengal-10389",
        headline:
          "Police Brutality During Idol Immersion In Munger Shared As West Bengal",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/cropped-screenshot-of-the-wire-article-on-durga-puja-is-misleading-10354",
        headline:
          "Cropped Screenshot Of The Wire Article On Durga Puja Is Misleading",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2012-video-of-turkish-police-dispersing-protesters-falsely-shared-as-france-10410",
        headline:
          "2012 Video Of Turkish Police Dispersing Protesters Falsely Shared As France",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2018s-image-of-flooded-underpass-in-up-shared-as-bihar-10378",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09a1\u09c1\u09ac\u09c7 \u09af\u09be\u0993\u09df\u09be \u0986\u09a8\u09cd\u09a1\u09be\u09b0\u09aa\u09be\u09b8\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/2019-tribal-woman-assaulted-by-kin-in-mp-shared-as-uttar-pradesh-10405",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u09ae\u09a7\u09cd\u09af\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09a8\u09be\u09b0\u09c0 \u09a8\u09bf\u09b0\u09cd\u09af\u09be\u09a4\u09a8\u09c7\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/police-brutality-during-idol-immersion-in-munger-shared-as-west-bengal-10393",
        headline:
          "\u09a6\u09c1\u09b0\u09cd\u0997\u09be \u09a0\u09be\u0995\u09c1\u09b0 \u09ac\u09bf\u09b8\u09b0\u09cd\u099c\u09a8\u09c7 \u09ae\u09c1\u0999\u09cd\u0997\u09c7\u09b0\u09c7 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u09ac\u09b0\u09cd\u09ac\u09b0\u09a4\u09be \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/did-pak-mps-chant-modi-modi-in-parliament-no-theyre-chanting-voting-10421",
        headline:
          "\u09aa\u09be\u0995 \u09b8\u09be\u0982\u09b8\u09a6\u09b0\u09be \u0995\u09bf \u09b8\u0982\u09b8\u09a6\u09c7 '\u09ae\u09cb\u09a6\u09c0 \u09ae\u09cb\u09a6\u09c0' \u09a7\u09cd\u09ac\u09a8\u09bf \u09a6\u09c7\u09a8? \u09a8\u09be, \u09a4\u09be\u0981\u09b0\u09be \u09ac\u09b2\u09c7\u09a8 '\u09ad\u09cb\u099f'",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/old-image-of-mark-zuckerberg-and-emmanuel-macron-shared-with-misleading-claim-10434",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u099c\u09be\u0995\u09be\u09b0\u09ac\u09be\u09b0\u09cd\u0997 \u0993 \u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09aa\u09a4\u09bf \u09ae\u09be\u0995\u09b0\u0981\u09b0 \u09ac\u09c8\u09a0\u0995\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/times-now-india-tv-zee-news-falsely-claim-members-in-pakistan-parliament-chanted-modi-10413",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u0915\u0940 \u0938\u0902\u0938\u0926 \u092e\u0947\u0902 '\u092e\u094b\u0926\u0940 \u092e\u094b\u0926\u0940' \u0915\u0947 \u0928\u093e\u0930\u0947 \u0932\u0917\u093e\u090f \u0917\u090f ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-from-turkey-shared-as-french-police-attack-on-muslims-praying-on-street-10424",
        headline:
          "\u0928\u092e\u093e\u095b \u092a\u095d\u0924\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u0915\u093e\u0930\u093f\u092f\u094b\u0902 \u092a\u0930 \u0906\u0902\u0938\u0942 \u0917\u0948\u0938 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u094d\u0930\u093e\u0902\u0938 \u0915\u093e \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/screenshot-of-the-wires-article-on-durga-puja-goes-viral-with-misleading-claims-10377",
        headline:
          "\u0926\u0941\u0930\u094d\u0917\u093e \u092a\u0942\u091c\u093e \u092a\u0930 \u0926 \u0935\u093e\u092f\u0930 \u0915\u0947 \u0932\u0947\u0916 \u0915\u093e \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u092d\u094d\u0930\u093e\u092e\u0915 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
    ],
    "2": [
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-photos-from-pakistan-and-syria-viral-with-civil-war-in-karachi-claims-10358",
        headline:
          "\u0905\u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0924\u0938\u094d\u0935\u0940\u0930\u094b\u0902 \u0915\u094b '\u0915\u0930\u093e\u091a\u0940 \u092e\u0947\u0902 \u0917\u0943\u0939\u092f\u0941\u0926\u094d\u0927' \u0915\u093e \u092e\u0902\u095b\u0930 \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/world-doctors-alliance-video-repeats-debunked-covid-19-conspiracies-10361",
        headline:
          "World Doctors Alliance Video Repeats Debunked COVID-19 Conspiracies",
      },
      {
        url:
          "https://factly.in/video-of-an-assault-on-women-in-malaysia-is-passed-off-as-an-assault-in-hyderabad-metro/",
        headline:
          " Video of assault on a Woman in Malaysia is passed off as an assault in Hyderabad Metro",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-the-labourer-woman-in-the-viral-post-is-not-padmashila-tirpude/",
        headline:
          "Fact Check: \u0938\u093f\u0930 \u092a\u0930 \u092a\u0924\u094d\u0925\u0930 \u0909\u0920\u093e\u090f \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u0940 \u092e\u0939\u093f\u0932\u093e \u0928\u0939\u0940\u0902 \u0939\u0948 \u092a\u0940\u090f\u0938\u0906\u0908 \u092a\u0926\u094d\u092e\u0936\u0940\u0932\u093e \u0924\u093f\u0930\u092a\u0941\u0921\u093c\u0947",
      },
      {
        url:
          "https://digiteye.in/video-of-a-woman-sitting-in-front-of-a-tiger-is-not-real-its-a-google-feature/",
        headline:
          "Video of a woman sitting in front of a tiger is not real; It\u2019s a Google feature",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/viral-charlie-hebdo-cartoon-on-shaheen-bagh-protestors-is-fake-10456",
        headline:
          "Viral Charlie Hebdo Cartoon On Shaheen Bagh Protestors Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/viral-image-does-not-show-under-construction-ram-temple-in-ayodhya-10459",
        headline:
          "Viral Image Does Not Show Under-Construction Ram Temple In Ayodhya",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-from-tatarstan-viral-as-muslim-woman-attacked-in-france-10452",
        headline:
          "Video From Tatarstan Viral As Muslim Woman Attacked In France",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/set-of-old-images-revived-with-civil-war-in-karachi-claims-10402",
        headline:
          "'\u0995\u09b0\u09be\u099a\u09bf\u09a4\u09c7 \u0997\u09c3\u09b9\u09af\u09c1\u09a6\u09cd\u09a7' \u09ac\u09b2\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u098f\u0995 \u09b8\u09c7\u099f \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/post-claims-construction-of-ram-temple-in-ayodhya-dham-is-going-fast-10460",
        headline:
          "\u091c\u0940 \u0928\u0939\u0940\u0902, \u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0930\u093e\u092e \u092e\u0902\u0926\u093f\u0930 \u0915\u0947 \u0928\u093f\u0930\u094d\u092e\u093e\u0923 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2012-embassy-attacks-in-sudan-falsely-shared-as-attack-on-french-embassy-10392",
        headline:
          "2012 Embassy Attacks In Sudan Falsely Shared As Attack On French Embassy",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-viral-as-france-embassy-being-burnt-in-sudan-10409",
        headline:
          "\u095e\u0930\u094d\u095b\u0940: \u0938\u0942\u0921\u093e\u0928 \u092e\u0947\u0902 \u091c\u0930\u094d\u092e\u0928 \u090f\u092e\u094d\u092c\u0947\u0938\u0940 \u092a\u0930 2012 \u0915\u0947 \u0939\u092e\u0932\u0947 \u0915\u094b \u095e\u094d\u0930\u0947\u0902\u091a \u090f\u092e\u094d\u092c\u0947\u0938\u0940 \u0938\u0947 \u091c\u094b\u095c\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-from-tatarstan-viral-as-muslim-woman-attacked-in-france-10461",
        headline:
          "\u099f\u09be\u099f\u09be\u09b0\u09b8\u09cd\u09a4\u09be\u09a8\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u09ae\u09b9\u09bf\u09b2\u09be\u09b0 \u0993\u09aa\u09b0 \u0986\u0995\u09cd\u09b0\u09ae\u09a3 \u09ac\u09b2\u09c7 \u099a\u09be\u09b2\u09be\u09a8\u09cb \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/26/fact-check-was-this-woman-arrested-in-singapore-for-not-wearing-a-mask-heres-the-truth/",
        headline:
          "Fact Check: Was this woman arrested in Singapore for not wearing a mask? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/28/fact-check-old-pictures-of-bomb-blasts-in-pakistan-shared-as-recent-ones/",
        headline:
          "Fact Check: Old pictures of bomb blasts in Pakistan shared as recent ones",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/28/fact-check-2019-video-from-russia-shared-as-iranians-watching-azerbaijan-armenia-war/",
        headline:
          "Fact Check: 2019 video from Russia shared as Iranians watching Azerbaijan-Armenia \u2018war\u2019",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-these-images-are-not-of-the-under-construction-ram-temple-in-ayodhya-1736932-2020-10-31",
        headline:
          "Fact Check: No, these images are not of the under-construction Ram Temple in Ayodhya",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-morphed-screengrab-of-rana-ayyub-s-old-interview-goes-viral-with-false-claims-1736910-2020-10-31",
        headline:
          "Fact Check: Morphed screengrab of Rana Ayyub\u2019s old interview goes viral with false claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/28/fact-check-old-picture-of-vandalised-temple-in-sydney-shared-with-misleading-claim/",
        headline:
          "Fact Check: Old picture of vandalised temple in Sydney shared with misleading claim",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/images-from-adult-comedy-falsely-linked-to-yogi-adityanath-10404",
        headline:
          "\u0993\u09df\u09c7\u09ac \u09a1\u09cd\u09b0\u09be\u09ae\u09be\u09b0 \u09b8\u09cd\u0995\u09cd\u09b0\u09bf\u09a8\u09b6\u099f \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u099b\u09ac\u09bf\u09b0 \u09b8\u09be\u09a5\u09c7 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-an-embassy-being-set-on-fire-is-an-old-2012-video-from-sudan/",
        headline:
          " 2012\u0c32\u0c4b \u0c38\u0c41\u0c21\u0c3e\u0c28\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c4d\u0c2e\u0c28\u0c40 \u0c0e\u0c02\u0c2c\u0c38\u0c40\u0c15\u0c3f \u0c28\u0c3f\u0c2a\u0c4d\u0c2a\u0c02\u0c1f\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c0e\u0c02\u0c2c\u0c38\u0c40\u0c15\u0c3f \u0c28\u0c3f\u0c2a\u0c4d\u0c2a\u0c02\u0c1f\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c05\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2012-embassy-attacks-in-sudan-falsely-shared-as-attack-on-french-embassy-10415",
        headline:
          "\u09e8\u09e6\u09e7\u09e8 \u09b8\u09be\u09b2\u09c7 \u09b8\u09c1\u09a6\u09be\u09a8\u09c7 \u09a6\u09c2\u09a4\u09be\u09ac\u09be\u09b8\u09c7 \u09b9\u09be\u09ae\u09b2\u09be\u0995\u09c7 \u09ab\u09b0\u09be\u09b8\u09bf \u09a6\u09c2\u09a4\u09be\u09ac\u09be\u09b8\u09c7\u09b0 \u0989\u09aa\u09b0 \u09b9\u09be\u09ae\u09b2\u09be \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://www.altnews.in/hindi/delhi-youth-congress-shares-old-video-from-malaysia-as-woman-attacked-in-delhi-metro/",
        headline:
          "\u092f\u0942\u0925 \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 \u092e\u0932\u092f\u0947\u0936\u093f\u092f\u093e \u092e\u0947\u0902 \u0939\u0941\u0908 \u0918\u091f\u0928\u093e \u0915\u094b \u092c\u0924\u093e\u092f\u093e \u0926\u093f\u0932\u094d\u0932\u0940 \u092e\u0947\u091f\u094d\u0930\u094b \u0938\u094d\u091f\u0947\u0936\u0928 \u0915\u0940 \u0932\u093f\u092b\u093c\u094d\u091f \u092e\u0947\u0902 \u092e\u0939\u093f\u0932\u093e \u092a\u0930 \u0939\u092e\u0932\u093e",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/26/fact-check-was-this-woman-arrested-in-singapore-for-not-wearing-a-mask-heres-the-truth/",
        headline:
          "Fact Check: Was this woman arrested in Singapore for not wearing a mask? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/28/fact-check-old-pictures-of-bomb-blasts-in-pakistan-shared-as-recent-ones/",
        headline:
          "Fact Check: Old pictures of bomb blasts in Pakistan shared as recent ones",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/28/fact-check-old-picture-of-vandalised-temple-in-sydney-shared-with-misleading-claim/",
        headline:
          "Fact Check: Old picture of vandalised temple in Sydney shared with misleading claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/28/fact-check-2019-video-from-russia-shared-as-iranians-watching-azerbaijan-armenia-war/",
        headline:
          "Fact Check: 2019 video from Russia shared as Iranians watching Azerbaijan-Armenia \u2018war\u2019",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/delhi-youth-congress-peddles-old-assault-video-from-kuala-lumpur-as-delhi-10369",
        headline:
          "Delhi Youth Congress Peddles Old Assault Video From Kuala Lumpur As Delhi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2012-embassy-attacks-in-sudan-falsely-shared-as-attack-on-french-embassy-10392",
        headline:
          "2012 Embassy Attacks In Sudan Falsely Shared As Attack On French Embassy",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-from-tatarstan-viral-as-muslim-woman-attacked-in-france-10452",
        headline:
          "Video From Tatarstan Viral As Muslim Woman Attacked In France",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/viral-charlie-hebdo-cartoon-on-shaheen-bagh-protestors-is-fake-10456",
        headline:
          "Viral Charlie Hebdo Cartoon On Shaheen Bagh Protestors Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/world-doctors-alliance-video-repeats-debunked-covid-19-conspiracies-10361",
        headline:
          "World Doctors Alliance Video Repeats Debunked COVID-19 Conspiracies",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/edited-screenshot-falsely-claims-rana-ayyub-condoned-france-attacks-10439",
        headline:
          "Edited Screenshot Falsely Claims Rana Ayyub Condoned France Attacks",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/viral-image-does-not-show-under-construction-ram-temple-in-ayodhya-10459",
        headline:
          "Viral Image Does Not Show Under-Construction Ram Temple In Ayodhya",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/set-of-old-images-revived-with-civil-war-in-karachi-claims-10402",
        headline:
          "'\u0995\u09b0\u09be\u099a\u09bf\u09a4\u09c7 \u0997\u09c3\u09b9\u09af\u09c1\u09a6\u09cd\u09a7' \u09ac\u09b2\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u098f\u0995 \u09b8\u09c7\u099f \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/images-from-adult-comedy-falsely-linked-to-yogi-adityanath-10404",
        headline:
          "\u0993\u09df\u09c7\u09ac \u09a1\u09cd\u09b0\u09be\u09ae\u09be\u09b0 \u09b8\u09cd\u0995\u09cd\u09b0\u09bf\u09a8\u09b6\u099f \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u099b\u09ac\u09bf\u09b0 \u09b8\u09be\u09a5\u09c7 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2012-embassy-attacks-in-sudan-falsely-shared-as-attack-on-french-embassy-10415",
        headline: "          ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/video-from-tatarstan-viral-as-muslim-woman-attacked-in-france-10461",
        headline:
          "\u099f\u09be\u099f\u09be\u09b0\u09b8\u09cd\u09a4\u09be\u09a8\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u09ae\u09b9\u09bf\u09b2\u09be\u09b0 \u0993\u09aa\u09b0 \u0986\u0995\u09cd\u09b0\u09ae\u09a3 \u09ac\u09b2\u09c7 \u099a\u09be\u09b2\u09be\u09a8\u09cb \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/youth-congress-shares-woman-assault-video-from-malaysia-as-delhi-metro-lift-incident-10375",
        headline:
          "\u092e\u0939\u093f\u0932\u093e \u0915\u0947 \u0938\u093e\u0925 \u092e\u093e\u0930\u092a\u0940\u091f \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092f\u0942\u0925 \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-viral-as-france-embassy-being-burnt-in-sudan-10409",
        headline:
          "\u095e\u0930\u094d\u095b\u0940: \u0938\u0942\u0921\u093e\u0928 \u092e\u0947\u0902 \u091c\u0930\u094d\u092e\u0928 \u090f\u092e\u094d\u092c\u0947\u0938\u0940 \u092a\u0930 2012 \u0915\u0947 \u0939\u092e\u0932\u0947 \u0915\u094b \u095e\u094d\u0930\u0947\u0902\u091a \u090f\u092e\u094d\u092c\u0947\u0938\u0940 \u0938\u0947 \u091c\u094b\u095c\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-photos-from-pakistan-and-syria-viral-with-civil-war-in-karachi-claims-10358",
        headline:
          "\u0905\u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0924\u0938\u094d\u0935\u0940\u0930\u094b\u0902 \u0915\u094b '\u0915\u0930\u093e\u091a\u0940 \u092e\u0947\u0902 \u0917\u0943\u0939\u092f\u0941\u0926\u094d\u0927' \u0915\u093e \u092e\u0902\u095b\u0930 \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/post-claims-construction-of-ram-temple-in-ayodhya-dham-is-going-fast-10460",
        headline:
          "\u091c\u0940 \u0928\u0939\u0940\u0902, \u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0930\u093e\u092e \u092e\u0902\u0926\u093f\u0930 \u0915\u0947 \u0928\u093f\u0930\u094d\u092e\u093e\u0923 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check-video-of-burqa-clad-woman-being-assaulted-is-from-russia-not-france",
        headline:
          "Fact Check: Video of burqa-clad woman being assaulted is from Russia, not France",
      },
    ],
    "3": [
      {
        url:
          "https://factly.in/safe-shop-india-e-commerce-company-executive-is-now-falsely-portrayed-as-the-kodagu-district-collector/",
        headline:
          " \u2018Safe Shop India\u2019 e-commerce company executive is now falsely portrayed as the Kodagu District Collector",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-viral-post-claiming-covid-19-test-was-patented-in-2015-is-fake/",
        headline:
          "Fact Check: COVID-19 \u091f\u0947\u0938\u094d\u091f \u0915\u0947 \u0932\u093f\u090f \u0938\u093e\u0932 2015 \u092e\u0947\u0902 \u0928\u0939\u0940\u0902 \u0932\u093f\u0916\u093e \u0917\u092f\u093e \u0925\u093e \u092a\u0947\u091f\u0947\u0902\u091f, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u092b\u0930\u094d\u091c\u0940",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/artwork-on-leonardo-dicaprio-playing-the-role-of-lenin-in-imaginary-film-surfaces-10362",
        headline:
          "\u09b8\u09cd\u09aa\u09bf\u09b2\u09ac\u09be\u09b0\u09cd\u0997\u09c7\u09b0 \u099b\u09ac\u09bf\u09a4\u09c7 \u09b2\u09c7\u09a8\u09bf\u09a8\u09c7\u09b0 \u09ad\u09c2\u09ae\u09bf\u0995\u09be\u09df \u09a1\u09bf\u0995\u09cd\u09af\u09be\u09aa\u09cd\u09b0\u09bf\u0993? \u09aa\u09cb\u09b8\u09cd\u099f\u09be\u09b0\u099f\u09bf \u0995\u09be\u09b2\u09cd\u09aa\u09a8\u09bf\u0995",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/uninstallmyntra-trends-over-old-graphic-not-made-by-company-10371",
        headline:
          "#UninstallMyntra: \u09a4\u09c8\u09b0\u09bf \u09a8\u09be \u0995\u09b0\u09be \u09aa\u09c1\u09b0\u09a8\u09cb \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995 \u0998\u09bf\u09b0\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09be \u09aa\u09cd\u09b0\u099a\u09be\u09b0",
      },
      {
        url:
          "https://factly.in/telugu-safe-shop-india-e-commerce-company-executive-is-now-falsely-portrayed-as-kodagu-district-collector/",
        headline:
          " \u2018Safe Shop India\u2019 \u0c08-\u0c15\u0c3e\u0c2e\u0c30\u0c4d\u0c38\u0c4d \u0c15\u0c02\u0c2a\u0c46\u0c28\u0c40 \u0c05\u0c38\u0c4b\u0c38\u0c3f\u0c2f\u0c47\u0c1f\u0c4d \u0c28\u0c3f \u0c15\u0c4a\u0c21\u0c17\u0c41 \u0c1c\u0c3f\u0c32\u0c4d\u0c32\u0c3e \u0c15\u0c32\u0c46\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-posts-claiming-about-no-vote-no-covid-vaccine-is-misleading/",
        headline:
          "Fact Check: Posts claiming about \u2018no vote no covid vaccine\u2019 is misleading",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-old-picture-of-liquor-bottles-in-thailand-falsely-linked-to-bihar/",
        headline:
          "Fact Check: Old Picture of Liquor Bottles in Thailand Falsely Linked to Bihar",
      },
      {
        url:
          "https://digiteye.in/image-of-priyanka-gandhis-durga-temple-visit-from-2009-resurfaces-ahead-of-bihar-elections/",
        headline:
          "Image of Priyanka Gandhi\u2019s Durga Temple visit from 2009 resurfaces ahead of Bihar elections",
      },
      {
        url:
          "https://digiteye.in/trump-claims-99-of-people-recover-from-covid-19-fact-check/",
        headline:
          "Trump claims 99% of people recover from COVID-19: Fact Check",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-of-narendra-modis-patna-rally-viral-as-recent-one-ahead-of-bihar-elections-10379",
        headline:
          "\u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935: \u092e\u094b\u0926\u0940 \u0915\u0940 \u0938\u093e\u0932 \u092d\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u092a\u091f\u0928\u093e \u0930\u0948\u0932\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0905\u092c \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/images-from-adult-comedy-falsely-linked-to-yogi-adityanath-10397",
        headline: "Images From Adult Comedy Falsely Linked To Yogi Adityanath",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-from-uttar-pradesh-viral-as-bsp-rally-getting-support-in-bihar-elections-10431",
        headline:
          "\u091d\u093e\u0902\u0938\u0940 \u092e\u0947\u0902 2016 \u092e\u0947\u0902 \u0928\u093f\u0915\u093e\u0932\u0940 \u0917\u092f\u0940 \u0930\u0948\u0932\u0940 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://factly.in/telugu-old-videos-being-shared-as-people-attacking-bjp-leaders-in-bihar/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c28\u0c41 \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 BJP \u0c28\u0c47\u0c24\u0c32\u0c2a\u0c48 \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c41 \u0c26\u0c3e\u0c21\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/28/fact-check-did-who-doctors-say-that-social-distancing-and-isolation-is-not-required-for-covid-19-heres-the-truth/",
        headline:
          "Fact Check: Did WHO doctors say that social distancing and isolation is not required for COVID-19? Here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/telugu-new-rules-allow-covid-19-positive-suspect-or-above-80-years-or-pwd-electors-to-use-postal-ballot-facility/",
        headline:
          " 80 \u0c0f\u0c33\u0c4d\u0c33\u0c41 \u0c2a\u0c48\u0c2c\u0c21\u0c3f\u0c28\u0c35\u0c3e\u0c30\u0c41, \u0c35\u0c3f\u0c15\u0c32\u0c3e\u0c02\u0c17\u0c41\u0c32\u0c41,\u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d- 19 \u0c15\u0c4d\u0c35\u0c3e\u0c30\u0c02\u0c1f\u0c48\u0c28\u0c4d \u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c35\u0c3e\u0c30\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c32\u0c4d \u0c2c\u0c4d\u0c2f\u0c3e\u0c32\u0c46\u0c1f\u0c4d \u0c26\u0c4d\u0c35\u0c3e\u0c30\u0c3e \u0c13\u0c1f\u0c41 \u0c35\u0c47\u0c38\u0c47 \u0c38\u0c4c\u0c15\u0c30\u0c4d\u0c2f\u0c02 \u0c2a\u0c4a\u0c02\u0c26\u0c35\u0c4a\u0c1a\u0c4d\u0c1a\u0c41",
      },
      {
        url:
          "https://factly.in/old-photo-and-video-of-election-rallies-in-bihar-and-mp-are-being-shared-in-the-current-context/",
        headline:
          " Old photo & video of election rallies in Bihar and MP are being shared in the current context.",
      },
      {
        url:
          "https://factly.in/telugu-this-image-of-dogs-surrounding-a-dead-man-on-the-street-is-from-yemen-not-gujarat/",
        headline:
          " \u0c2f\u0c46\u0c2e\u0c46\u0c28\u0c4d \u0c26\u0c47\u0c36\u0c38\u0c4d\u0c25\u0c41\u0c21\u0c3f \u0c2e\u0c43\u0c24\u0c26\u0c47\u0c39\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c17\u0c41\u0c1c\u0c30\u0c3e\u0c24\u0c4d \u0c32\u0c4b \u0c12\u0c15  \u0c05\u0c28\u0c3e\u0c25 \u0c2e\u0c43\u0c24\u0c26\u0c47\u0c39\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c1a\u0c41\u0c1f\u0c4d\u0c1f\u0c41\u0c2e\u0c41\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c05\u0c24\u0c21\u0c3f \u0c2a\u0c46\u0c02\u0c2a\u0c41\u0c21\u0c41 \u0c15\u0c41\u0c15\u0c4d\u0c15\u0c32\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-this-crowd-protesting-against-bjp-is-not-from-bihar-election-campaigns-1736023-2020-10-28",
        headline:
          "Fact Check: No, this crowd protesting against BJP is not from Bihar election campaigns",
      },
      {
        url:
          "https://factly.in/telugu-video-of-tejashwi-yadav-distributing-money-to-flood-victims-is-falsely-linked-to-bihar-elections/",
        headline:
          " \u0c35\u0c30\u0c26 \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c32\u0c15\u0c3f \u0c24\u0c47\u0c1c\u0c38\u0c4d\u0c35\u0c3f \u0c2f\u0c3e\u0c26\u0c35\u0c4d \u0c21\u0c2c\u0c4d\u0c2c\u0c41\u0c32\u0c41 \u0c2a\u0c02\u0c1a\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f \u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/28/fact-check-old-video-of-bsp-bike-rally-in-jhansi-shared-as-recent-one-from-bihar/",
        headline:
          "Fact Check: Old video of BSP bike rally in Jhansi shared as recent one from Bihar",
      },
      {
        url:
          "https://factly.in/telugu-this-person-is-neither-a-congress-party-contestant-nor-the-accused-in-2013-bomb-blasts-in-patna/",
        headline:
          " \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c28\u0c3e\u0c2e\u0c3f\u0c28\u0c47\u0c37\u0c28\u0c4d \u0c39\u0c3e\u0c32\u0c4d\u0c32\u0c4b \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d \u0c05\u0c2f\u0c3f\u0c28 \u0c08 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f\u0c15\u0c3f, 2013\u0c32\u0c4b \u0c2a\u0c3e\u0c1f\u0c4d\u0c28\u0c3e\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c2c\u0c3e\u0c02\u0c2c\u0c41 \u0c26\u0c3e\u0c21\u0c41\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/image-from-yemen-shared-as-dogs-surrounding-the-dead-body-of-their-homeless-owner-in-gujarat/",
        headline:
          " Image from Yemen shared as dogs surrounding the dead body of their homeless owner in Gujarat",
      },
      {
        url:
          "https://factly.in/this-image-doesnt-show-tej-pratap-yadav-receiving-a-doctorate/",
        headline:
          " This image doesn\u2019t show Tej Pratap Yadav receiving a doctorate",
      },
      {
        url:
          "https://factly.in/it-is-rjd-leader-tejashwi-yadav-who-stood-on-the-tricolour-podium-not-pm-narendra-modi/",
        headline:
          " It is RJD leader Tejashwi Yadav who stood on the tricolour podium, not PM Narendra Modi.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/26/fact-check-is-this-the-picture-of-up-cm-yogi-adityanaths-recent-rally-in-bihar-heres-the-truth/",
        headline:
          "Fact Check: Is this the picture of UP CM Yogi Adityanath\u2019s recent rally in Bihar? Here\u2019s the truth",
      },
      {
        url:
          "https://www.altnews.in/unrelated-video-viral-as-kodagu-district-collector-felicitated-for-leading-fight-against-covid/",
        headline:
          "Unrelated video viral as Kodagu \u2018district collector\u2019 felicitated for leading fight against COVID",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/26/fact-check-is-this-the-picture-of-up-cm-yogi-adityanaths-recent-rally-in-bihar-heres-the-truth/",
        headline:
          "Fact Check: Is this the picture of UP CM Yogi Adityanath\u2019s recent rally in Bihar? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/28/fact-check-did-who-doctors-say-that-social-distancing-and-isolation-is-not-required-for-covid-19-heres-the-truth/",
        headline:
          "Fact Check: Did WHO doctors say that social distancing and isolation is not required for COVID-19? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/28/fact-check-old-video-of-bsp-bike-rally-in-jhansi-shared-as-recent-one-from-bihar/",
        headline:
          "Fact Check: Old video of BSP bike rally in Jhansi shared as recent one from Bihar",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/images-from-adult-comedy-falsely-linked-to-yogi-adityanath-10397",
        headline: "Images From Adult Comedy Falsely Linked To Yogi Adityanath",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2019-video-viral-with-false-claims-of-evm-being-rigged-in-bihar-polls-10462",
        headline:
          "2019 Video Viral With False Claims Of EVM Being Rigged In Bihar Polls",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/image-of-yemeni-homeless-man-falsely-shared-as-from-gujarat-10408",
        headline: "Image of Yemeni Homeless Man Falsely Shared As From Gujarat",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/uninstallmyntra-trends-over-old-graphic-not-made-by-company-10371",
        headline:
          "#UninstallMyntra: \u09a4\u09c8\u09b0\u09bf \u09a8\u09be \u0995\u09b0\u09be \u09aa\u09c1\u09b0\u09a8\u09cb \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995 \u0998\u09bf\u09b0\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09be \u09aa\u09cd\u09b0\u099a\u09be\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/artwork-on-leonardo-dicaprio-playing-the-role-of-lenin-in-imaginary-film-surfaces-10362",
        headline:
          "\u09b8\u09cd\u09aa\u09bf\u09b2\u09ac\u09be\u09b0\u09cd\u0997\u09c7\u09b0 \u099b\u09ac\u09bf\u09a4\u09c7 \u09b2\u09c7\u09a8\u09bf\u09a8\u09c7\u09b0 \u09ad\u09c2\u09ae\u09bf\u0995\u09be\u09df \u09a1\u09bf\u0995\u09cd\u09af\u09be\u09aa\u09cd\u09b0\u09bf\u0993? \u09aa\u09cb\u09b8\u09cd\u099f\u09be\u09b0\u099f\u09bf \u0995\u09be\u09b2\u09cd\u09aa\u09a8\u09bf\u0995",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/image-of-yemeni-homeless-man-falsely-shared-as-from-gujarat-10423",
        headline:
          "\u0987\u09df\u09c7\u09ae\u09c7\u09a8\u09c7\u09b0 \u098f\u0995 \u0997\u09c3\u09b9\u09b9\u09c0\u09a8 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u0997\u09c1\u099c\u09b0\u09be\u09a4\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/yemeni-homeless-man-picture-shared-as-pappu-shukla-from-gujarat-10442",
        headline:
          "\u092f\u092e\u0928 \u0915\u0947 \u092c\u0947\u0918\u0930 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u0947 \u092e\u0943\u0924 \u0936\u0930\u0940\u0930 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0917\u0941\u091c\u0930\u093e\u0924 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-of-narendra-modis-patna-rally-viral-as-recent-one-ahead-of-bihar-elections-10379",
        headline:
          "\u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935: \u092e\u094b\u0926\u0940 \u0915\u0940 \u0938\u093e\u0932 \u092d\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u092a\u091f\u0928\u093e \u0930\u0948\u0932\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0905\u092c \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-from-uttar-pradesh-viral-as-bsp-rally-getting-support-in-bihar-elections-10431",
        headline:
          "\u091d\u093e\u0902\u0938\u0940 \u092e\u0947\u0902 2016 \u092e\u0947\u0902 \u0928\u093f\u0915\u093e\u0932\u0940 \u0917\u092f\u0940 \u0930\u0948\u0932\u0940 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/go-back-modi-photo-from-west-bengal-shared-ahead-of-bihar-elections-2020-10357",
        headline:
          "\u0915\u094d\u092f\u093e \u092c\u093f\u0939\u093e\u0930 \u092e\u0947\u0902 \u0932\u094b\u0917 '\u0917\u094b \u092c\u0948\u0915 \u092e\u094b\u0926\u0940' \u0928\u093e\u0930\u093e \u0932\u093f\u0916\u0915\u0930 \u0935\u093f\u0930\u094b\u0927 \u091c\u0924\u093e \u0930\u0939\u0947 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/yemeni-man-passed-off-as-pappu-shukla-of-gujarat-659636",
        headline:
          "Fact Check: Yemeni man passed off as 'Pappu Shukla' of Gujarat",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check-a-2019-election-news-clip-shared-by-bjp-leader-with-false-claims",
        headline:
          "Fact Check: A 2019 election news clip shared by BJP leader with false claims",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fake-news/paris-beheading-old-video-from-yemen-viral-with-false-claims-10376",
        headline:
          "Paris Beheading: Old Video From Yemen Viral With False Claims",
      },
      {
        url:
          "https://factly.in/indias-89-3-debt-to-gdp-ratio-2020-projected-by-imf-includes-both-central-and-state-government-debts/",
        headline:
          " India\u2019s 89.3% debt-to-GDP ratio (2020) projected by IMF includes both Central and State Government debts",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/quick-fact-check-quick-fact-check-fake-statement-attributed-to-kiran-kher-resurfaces-again-on-social-media/",
        headline:
          "Quick Fact Check: \u0930\u0947\u092a \u0915\u094b \u0932\u0947\u0915\u0930 \u0915\u093f\u0930\u0923 \u0916\u0947\u0930 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u092c\u092f\u093e\u0928 \u092b\u0930\u094d\u091c\u0940, \u092a\u0939\u0932\u0947 \u092d\u0940 \u0938\u094b\u0936\u0932 \u092e\u0940\u0921\u093f\u092f\u093e \u092a\u0930 \u0939\u094b \u091a\u0941\u0915\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2017-video-of-emmanuel-macron-getting-egged-revived-with-false-claim-10372",
        headline:
          "2017 Video Of Emmanuel Macron Getting Egged Revived With False Claim",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-incident-of-15-foot-long-python-caught-in-fishing-net-in-krishna-district-is-being-shared-in-the-name-of-hyderabad/",
        headline:
          "Fact Check: \u0935\u093f\u091c\u092f\u0935\u093e\u0921\u093c\u093e \u092e\u0947\u0902 \u092e\u091b\u0941\u0906\u0930\u094b\u0902 \u0915\u0947 \u091c\u093e\u0932 \u092e\u0947\u0902 \u092b\u0902\u0938\u0947 15 \u092b\u0941\u091f \u0932\u0902\u092c\u0947 \u0905\u091c\u0917\u0930 \u0915\u0940 \u0918\u091f\u0928\u093e \u0915\u094b \u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-fake-agents-cheating-people-in-the-name-of-jio-towers/",
        headline:
          "Fact Check: \u091f\u093e\u0935\u0930 \u0932\u0917\u093e\u0928\u0947 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u091c\u093f\u092f\u094b \u0928\u0939\u0940\u0902 \u0932\u0947\u0924\u093e \u0939\u0948 \u092a\u0948\u0938\u093e, \u092b\u094d\u0930\u0949\u0921 \u0938\u0947 \u0930\u0939\u0947\u0902 \u0938\u093e\u0935\u0927\u093e\u0928",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/paris-beheading-old-video-from-yemen-viral-with-false-claims-10432",
        headline:
          "\u095e\u094d\u0930\u093e\u0902\u0938: \u092f\u092e\u0928 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/egg-attack-claim-on-emmanuel-macron-after-paris-beheading-is-fake-10429",
        headline:
          "\u095e\u094d\u0930\u0947\u0902\u091a \u092a\u094d\u0930\u0947\u095b\u093f\u0921\u0947\u0902\u091f \u092a\u0930 \u0905\u0923\u094d\u0921\u094b\u0902 \u0915\u0947 \u0939\u092e\u0932\u0947 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0924\u0940\u0928 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-video-of-emmanuel-macron-getting-egged-revived-with-false-claim-10436",
        headline:
          "\u09e8\u09e6\u09e7\u09ed \u09a4\u09c7 \u0987\u09ae\u09be\u09a8\u09c1\u09df\u09c7\u09b2 \u09ae\u09be\u0995\u09b0\u0981\u0995\u09c7 \u09a1\u09bf\u09ae \u099b\u09cb\u09dc\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-french-president-macron-egged-has-no-link-to-remarks-on-islam-1735707-2020-10-27",
        headline:
          "Fact Check: Video of French President Macron egged has no link to remarks on Islam",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/26/fact-check-has-telugu-been-recognised-as-an-official-language-in-the-united-states-heres-the-truth/",
        headline:
          "Fact Check: Has Telugu been recognised as an official language in the United States? Here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/the-ayushman-mitra-recruitment-2020-notification-in-this-video-is-fake/",
        headline:
          " The \u2018Ayushman Mitra Recruitment 2020\u2019 notification in this video is fake",
      },
      {
        url:
          "https://factly.in/old-video-of-emmanuel-macron-being-egged-is-being-shared-as-a-recent-incident/",
        headline:
          " Old video of Emmanuel Macron being egged is being shared as a recent incident.",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/paris-beheading-old-video-from-yemen-viral-with-false-claims-10453",
        headline:
          "\u09aa\u09cd\u09af\u09be\u09b0\u09bf\u09b8 \u09b6\u09bf\u09b0\u099a\u09cd\u099b\u09c7\u09a6: \u0987\u09df\u09c7\u09ae\u09c7\u09a8\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/old-video-is-being-passed-off-as-french-embassy-set-on-fire-in-the-wake-of-the-latest-protests-against-emmanuel-macron/",
        headline:
          " Old video is being passed off as French embassy set on fire in the wake of the latest protests against Emmanuel Macron",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-emmanuel-macron-being-egged-is-shared-as-a-recent-incident/",
        headline:
          " \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c05\u0c27\u0c4d\u0c2f\u0c15\u0c4d\u0c37\u0c41\u0c21\u0c41 \u0c07\u0c2e\u0c4d\u0c2e\u0c3e\u0c28\u0c4d\u0c2f\u0c41\u0c2f\u0c46\u0c32\u0c4d\u200c \u0c2e\u0c3e\u0c15\u0c4d\u0c30\u0c4b\u0c28\u0c4d\u200c\u200c \u0c2a\u0c48 \u0c15\u0c4b\u0c21\u0c3f\u0c17\u0c41\u0c21\u0c4d\u0c21\u0c41 \u0c24\u0c4b \u0c26\u0c3e\u0c21\u0c3f \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c08 \u0c18\u0c1f\u0c28 2017 \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://www.altnews.in/paul-pogba-did-not-quit-french-football-team-after-pres-macrons-remarks-on-islam/",
        headline:
          "Paul Pogba did not quit French football team after Pres Macron\u2019s remarks on Islam",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/26/fact-check-has-telugu-been-recognised-as-an-official-language-in-the-united-states-heres-the-truth/",
        headline:
          "Fact Check: Has Telugu been recognised as an official language in the United States? Here\u2019s the truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/paris-beheading-old-video-from-yemen-viral-with-false-claims-10453",
        headline:
          "\u09aa\u09cd\u09af\u09be\u09b0\u09bf\u09b8 \u09b6\u09bf\u09b0\u099a\u09cd\u099b\u09c7\u09a6: \u0987\u09df\u09c7\u09ae\u09c7\u09a8\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2017-video-of-emmanuel-macron-getting-egged-revived-with-false-claim-10372",
        headline:
          "2017 Video Of Emmanuel Macron Getting Egged Revived With False Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-pogba-has-not-quit-the-french-national-team-over-macrons-remarks-10373",
        headline:
          "No, Pogba Has Not Quit The French National Team Over Macron's Remarks",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/paris-beheading-old-video-from-yemen-viral-with-false-claims-10376",
        headline:
          "Paris Beheading: Old Video From Yemen Viral With False Claims",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2017-video-of-emmanuel-macron-getting-egged-revived-with-false-claim-10436",
        headline:
          "\u09e8\u09e6\u09e7\u09ed \u09a4\u09c7 \u0987\u09ae\u09be\u09a8\u09c1\u09df\u09c7\u09b2 \u09ae\u09be\u0995\u09b0\u0981\u0995\u09c7 \u09a1\u09bf\u09ae \u099b\u09cb\u09dc\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-pogba-has-not-quit-the-french-national-team-over-macrons-remarks-10398",
        headline:
          "\u0987\u09ae\u09be\u09a8\u09c1\u09df\u09c7\u09b2 \u09ae\u09be\u0995\u09b0\u0981\u09b0 \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af\u09c7\u09b0 \u099c\u09c7\u09b0\u09c7 \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7\u09b0 \u099c\u09be\u09a4\u09c0\u09df \u09a6\u09b2 \u099b\u09be\u09dc\u09c7\u09a8\u09a8\u09bf \u09aa\u09b2 \u09aa\u09cb\u0997\u09ac\u09be",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/egg-attack-claim-on-emmanuel-macron-after-paris-beheading-is-fake-10429",
        headline:
          "\u095e\u094d\u0930\u0947\u0902\u091a \u092a\u094d\u0930\u0947\u095b\u093f\u0921\u0947\u0902\u091f \u092a\u0930 \u0905\u0923\u094d\u0921\u094b\u0902 \u0915\u0947 \u0939\u092e\u0932\u0947 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0924\u0940\u0928 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/paris-beheading-old-video-from-yemen-viral-with-false-claims-10432",
        headline:
          "\u095e\u094d\u0930\u093e\u0902\u0938: \u092f\u092e\u0928 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
    ],
    "5": [
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-isnt-a-shivling-in-south-india-edited-image-viral-with-false-claim/",
        headline:
          "Fact Check: \u090f\u0921\u093f\u091f\u0947\u0921 \u092b\u094b\u091f\u094b \u0915\u094b \u0926\u0915\u094d\u0937\u093f\u0923 \u092d\u093e\u0930\u0924 \u0915\u093e \u0936\u093f\u0935\u0932\u093f\u0902\u0917 \u092c\u0924\u093e \u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2017-protest-clip-revived-after-french-teachers-beheading-in-paris-10356",
        headline:
          "2017 Protest Clip Revived After French Teacher's Beheading In Paris",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-image-is-not-of-the-teacher-who-was-beheaded-in-paris-10360",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u09aa\u09cd\u09af\u09be\u09b0\u09bf\u09b8\u09c7 \u09b6\u09bf\u09b0\u099a\u09cd\u099b\u09c7\u09a6 \u09b9\u0993\u09df\u09be \u09b6\u09bf\u0995\u09cd\u09b7\u0995\u09c7\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://factly.in/image-of-anti-caa-protest-in-kolkata-is-being-shared-as-a-protest-from-bihar/",
        headline:
          " Image of anti-CAA protest in Kolkata is being shared as a protest from Bihar.",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/facebook-posts-claiming-indian-education-ministers-appointed-between-1947-77-were-muslims-are-false-10391",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0: \u09b8\u09cd\u09ac\u09be\u09a7\u09c0\u09a8\u09a4\u09be\u09b0 \u09aa\u09b0 \u09a4\u09bf\u09a8\u09a6\u09b6\u0995 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u09b8\u09ac \u09b6\u09bf\u0995\u09cd\u09b7\u09be\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0\u09b0\u09be \u099b\u09bf\u09b2\u09c7\u09a8 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-this-video-does-not-show-a-muslim-man-being-attacked-in-france-10428",
        headline:
          "\u092c\u094d\u0930\u093e\u095b\u0940\u0932 \u0915\u093e \u0938\u0940\u0938\u0940\u091f\u0940\u0935\u0940 \u095e\u0941\u091f\u0947\u091c \u095e\u094d\u0930\u093e\u0902\u0938 \u092e\u0947\u0902 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u092f\u0941\u0935\u0915 \u092a\u0930 \u0939\u092e\u0932\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://factly.in/unrelated-videos-are-being-passed-off-as-recent-protests-in-france/",
        headline:
          " Unrelated videos are being passed off as those of recent protests in France.",
      },
      {
        url:
          "https://factly.in/this-video-is-not-related-to-todays-knife-attack-at-the-notre-dame-basilica-church-in-nice-france/",
        headline:
          " This video is not related to today\u2019s knife attack at the Notre Dame Basilica church in Nice (France).",
      },
      {
        url:
          "https://factly.in/telugu-old-videos-are-now-shared-as-muslim-protests-against-the-french-government-in-chechnya/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c15\u0c3f \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/3-year-old-protest-video-against-street-prayers-revived-beheading-teacher-paris-1735716-2020-10-28",
        headline:
          "Fact check: 3-year-old protest video against street prayers revived after beheading of teacher in Paris",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-is-from-the-2012-protests-in-turkey-and-have-nothing-to-do-with-the-current-protests-in-france/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b 2012\u0c32\u0c4b \u0c1f\u0c30\u0c4d\u0c15\u0c40\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/this-video-does-not-show-police-apprehending-the-knife-attacker-in-nice-france/",
        headline:
          " This video does not show the police apprehending the knife attacker in Nice, France",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/28/fact-check-old-video-of-protest-by-asha-workers-in-madhya-pradesh-resurfaces-as-recent-one/",
        headline:
          "Fact Check: Old video of protest by ASHA workers in Madhya Pradesh resurfaces as recent one",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-video-shared-as-saudi-government-throwing-away-french-products-in-a-desert/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c38\u0c4c\u0c26\u0c40 \u0c05\u0c30\u0c47\u0c2c\u0c3f\u0c2f\u0c3e \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c35\u0c38\u0c4d\u0c24\u0c41\u0c35\u0c41\u0c32\u0c28\u0c3f \u0c0e\u0c21\u0c3e\u0c30\u0c3f\u0c32\u0c4b \u0c35\u0c3f\u0c38\u0c3f\u0c30\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/old-video-police-action-muslims-turkey-revived-erdogan-macron-feud-1736047-2020-10-29",
        headline:
          "Fact Check: Old video of police action on Muslims in Turkey revived during recent Erdogan-Macron feud",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2012-video-of-turkish-police-dispersing-protesters-falsely-shared-as-france-10443",
        headline:
          "\u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae\u09a6\u09c7\u09b0 \u0986\u0995\u09cd\u09b0\u09ae\u09a3 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09e8\u09e6\u09e7\u09e8'\u09b0 \u09a4\u09c1\u09b0\u09b8\u09cd\u0995\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://factly.in/old-videos-are-now-shared-as-the-muslims-protests-against-the-france-government-in-chechnya/",
        headline:
          " Old videos are now shared as  Muslim protests against the French Government in Chechnya",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-two-year-old-protest-video-against-shivraj-singh-revived-before-mp-bypolls-1735375-2020-10-27",
        headline:
          "Fact Check: Two-year-old protest video against Shivraj Singh revived before MP bypolls",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-brawl-in-brazil-falsely-shared-as-muslim-man-attacked-in-france-1736739-2020-10-31",
        headline:
          "Fact Check: Video of brawl in Brazil falsely shared as Muslim man attacked in France",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-protest-clip-revived-after-french-teachers-beheading-in-paris-10417",
        headline:
          "\u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09b6\u09bf\u0995\u09cd\u09b7\u0995\u09c7\u09b0 \u09b6\u09bf\u09b0\u099a\u09cd\u099b\u09c7\u09a6\u09c7\u09b0 \u09aa\u09b0 \u099c\u09bf\u0987\u09af\u09bc\u09c7 \u0989\u09a0\u09b2 \u09e8\u09e6\u09e7\u09ed'\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/28/fact-check-old-protest-video-from-paris-falsely-linked-with-recent-beheading-of-a-french-teacher/",
        headline:
          "Fact Check: Old protest video from Paris falsely linked with recent beheading of a French teacher",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-from-yemen-falsely-shared-as-chechen-muslims-protesting-against-france/",
        headline:
          "\u092a\u0948\u0917\u0902\u092c\u0930 \u0915\u0947 \u091c\u0928\u094d\u092e\u0926\u093f\u0928 \u0915\u093e\u0930\u094d\u092f\u0915\u094d\u0930\u092e \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0939\u093e\u0932 \u092e\u0947\u0902 \u095e\u094d\u0930\u093e\u0902\u0938 \u0915\u0947 \u0916\u093f\u0932\u093e\u095e \u091a\u0932 \u0930\u0939\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-of-protest-by-muslim-community-in-paris-revived-after-french-teachers-killing/",
        headline:
          "\u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0938\u092e\u0941\u0926\u093e\u092f \u0915\u0947 3 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092b\u094d\u093c\u0930\u0947\u0902\u091a \u091f\u0940\u091a\u0930 \u0915\u0940 \u0939\u0924\u094d\u092f\u093e \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/congress-members-share-image-from-caa-protest-as-media-ob-van-recently-vandalised-in-lucknow/",
        headline:
          "\u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u092e\u0947\u092e\u094d\u092c\u0930\u094d\u0938 \u0915\u093e \u095a\u0932\u0924 \u0926\u093e\u0935\u093e, CAA \u092a\u094d\u0930\u094b\u091f\u0947\u0938\u094d\u091f \u092e\u0947\u0902 \u0939\u0941\u0908 \u0924\u094b\u095c-\u092b\u094b\u095c \u0915\u094b \u092e\u0940\u0921\u093f\u092f\u093e \u0935\u0948\u0928 \u092a\u0930 \u0939\u093e\u0932\u093f\u092f\u093e \u0939\u092e\u0932\u093e \u092c\u0924\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/video-from-brazil-viral-as-muslim-man-hits-in-self-defence-when-attacked-in-france/",
        headline:
          "Video from Brazil viral as Muslim man hits in self-defence when attacked in France",
      },
      {
        url:
          "https://www.altnews.in/mp-polls-two-year-old-video-of-people-shouting-slogans-against-shiv-raj-singh-chauhan-is-viral-with-misleading-claims-2/",
        headline:
          "Video of protest against Shivraj Singh Chouhan in 2018 falsely linked to upcoming by-polls",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/28/fact-check-old-video-of-protest-by-asha-workers-in-madhya-pradesh-resurfaces-as-recent-one/",
        headline:
          "Fact Check: Old video of protest by ASHA workers in Madhya Pradesh resurfaces as recent one",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/10/28/fact-check-old-protest-video-from-paris-falsely-linked-with-recent-beheading-of-a-french-teacher/",
        headline:
          "Fact Check: Old protest video from Paris falsely linked with recent beheading of a French teacher",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-image-is-not-of-the-teacher-who-was-beheaded-in-paris-10360",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u09aa\u09cd\u09af\u09be\u09b0\u09bf\u09b8\u09c7 \u09b6\u09bf\u09b0\u099a\u09cd\u099b\u09c7\u09a6 \u09b9\u0993\u09df\u09be \u09b6\u09bf\u0995\u09cd\u09b7\u0995\u09c7\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/cctv-clip-from-brazil-falsely-shared-as-muslim-man-attacked-in-france-10427",
        headline:
          "CCTV Clip From Brazil Falsely Shared As Muslim Man Attacked In France",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/unrelated-videos-falsely-shared-as-french-police-crackdown-on-muslims-10416",
        headline:
          "Unrelated Videos Falsely Shared As French Police Crackdown On Muslims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/fake-post-claims-call-to-boycott-french-products-caused-economic-crisis-10450",
        headline:
          "Fake Post Claims Call To Boycott French Products Caused Economic Crisis",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-saudi-government-disposing-french-products-10445",
        headline:
          "No, This Video Does Not Show Saudi Government Disposing French Products",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-video-is-not-related-to-the-nice-church-attack-10426",
        headline: "No, This Video Is Not Related To The Nice Church Attack",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2017-protest-clip-revived-after-french-teachers-beheading-in-paris-10356",
        headline:
          "2017 Protest Clip Revived After French Teacher's Beheading In Paris",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/facebook-posts-claiming-indian-education-ministers-appointed-between-1947-77-were-muslims-are-false-10391",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0: \u09b8\u09cd\u09ac\u09be\u09a7\u09c0\u09a8\u09a4\u09be\u09b0 \u09aa\u09b0 \u09a4\u09bf\u09a8\u09a6\u09b6\u0995 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u09b8\u09ac \u09b6\u09bf\u0995\u09cd\u09b7\u09be\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0\u09b0\u09be \u099b\u09bf\u09b2\u09c7\u09a8 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2017-protest-clip-revived-after-french-teachers-beheading-in-paris-10417",
        headline:
          "\u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09b6\u09bf\u0995\u09cd\u09b7\u0995\u09c7\u09b0 \u09b6\u09bf\u09b0\u099a\u09cd\u099b\u09c7\u09a6\u09c7\u09b0 \u09aa\u09b0 \u099c\u09bf\u0987\u09af\u09bc\u09c7 \u0989\u09a0\u09b2 \u09e8\u09e6\u09e7\u09ed'\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-video-is-not-related-to-the-nice-church-attack-10438",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09a8\u09bf\u09b8-\u098f\u09b0 \u099a\u09be\u09b0\u09cd\u099a\u09c7 \u09b9\u09be\u09ae\u09b2\u09be\u09b0 \u0995\u09cb\u09a8\u0993 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995 \u09a8\u09c7\u0987",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/unrelated-videos-falsely-shared-as-french-police-crackdown-on-muslims-10454",
        headline:
          "\u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u09ad\u09bf\u09a1\u09bf\u0993 \u099b\u09a1\u09bc\u09bf\u09df\u09c7 \u09a6\u09be\u09ac\u09bf \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae\u09a6\u09c7\u09b0 \u0989\u09aa\u09b0 \u09ab\u09b0\u09be\u09b8\u09bf \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u0995\u09a0\u09cb\u09b0 \u09aa\u09a6\u0995\u09cd\u09b7\u09c7\u09aa",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2018-video-of-attack-on-bihar-cms-cavalcade-revived-ahead-of-polls-10374",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u09ae\u09c1\u0996\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0\u09b0 \u0995\u09a8\u09ad\u09df\u09a4\u09c7 \u09b9\u09be\u09ae\u09b2\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u0986\u09ac\u09be\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2012-video-of-turkish-police-dispersing-protesters-falsely-shared-as-france-10443",
        headline:
          "\u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae\u09a6\u09c7\u09b0 \u0986\u0995\u09cd\u09b0\u09ae\u09a3 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09e8\u09e6\u09e7\u09e8'\u09b0 \u09a4\u09c1\u09b0\u09b8\u09cd\u0995\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-a-muslim-man-being-attacked-in-france-10428",
        headline:
          "\u092c\u094d\u0930\u093e\u095b\u0940\u0932 \u0915\u093e \u0938\u0940\u0938\u0940\u091f\u0940\u0935\u0940 \u095e\u0941\u091f\u0947\u091c \u095e\u094d\u0930\u093e\u0902\u0938 \u092e\u0947\u0902 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u092f\u0941\u0935\u0915 \u092a\u0930 \u0939\u092e\u0932\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
    ],
  },
  numpy_seed: 0,
  number_of_articles: 182,
}
