export const data = {
  mdsDat: {
    x: [
      0.010174131499197077,
      0.3396139601673879,
      -0.18851983628699895,
      0.10548603926932394,
      -0.2667542946489101,
    ],
    y: [
      -0.13567931177015083,
      -0.0880274042085455,
      0.30101462589703454,
      0.1810678730318574,
      -0.25837578295019564,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      25.032835383391905,
      21.51010868944208,
      17.391446782579216,
      18.421175254574177,
      17.64443389001262,
    ],
  },
  tinfo: {
    Term: [
      "flag",
      "pm",
      "road",
      "modi",
      "mumbai",
      "covid",
      "indian",
      "pm_modi",
      "khan",
      "delhi",
      "bhagwat",
      "shah",
      "boycott",
      "fight",
      "outrageous",
      "pass",
      "mt",
      "spin",
      "home",
      "paint",
      "modi",
      "pm_modi",
      "firework",
      "krishna",
      "khalifa",
      "burj_khalifa",
      "burj",
      "deity",
      "israeli",
      "make",
      "painting",
      "pm",
      "statue",
      "covid",
      "flag",
      "coward",
      "digitally",
      "imaginary",
      "islam",
      "prince",
      "foot",
      "remark",
      "prey",
      "media",
      "sweep",
      "time",
      "skeleton",
      "trump",
      "bodyguard",
      "bahrain",
      "singh",
      "king",
      "salman",
      "report",
      "priest",
      "away",
      "khan",
      "rajput",
      "worship",
      "fall",
      "sushant",
      "create",
      "morphed",
      "eve",
      "bride",
      "founder",
      "google",
      "worker",
      "beat",
      "august",
      "obscene",
      "blue",
      "rss",
      "day",
      "sundar_pichai",
      "burqa",
      "pichai",
      "hoist",
      "independence_day",
      "doval",
      "sundar",
      "independence",
      "catch",
      "egyptian",
      "flag",
      "national",
      "man",
      "pakistan",
      "anil",
      "protest",
      "government",
      "jai",
      "bengal",
      "power",
      "wash",
      "sewage",
      "play",
      "hanmakonda",
      "road",
      "indian",
      "get",
      "bhatt",
      "dislike",
      "mahesh",
      "mahesh_bhatt",
      "fire",
      "upadhyay",
      "varanasi",
      "furious",
      "fill",
      "delhi",
      "circulate",
      "link",
      "youth",
      "trailer",
      "go",
      "strain",
      "vaccine",
      "kerala",
      "dose",
      "pretext",
      "gaza",
      "connection",
      "vogue",
      "questioning",
      "quite",
      "mumbai",
      "milk",
      "people",
      "gaekwad",
      "mislead",
      "culture",
      "radhikaraje",
      "expensive",
      "bharatiya",
      "hyderabad",
      "world",
      "covid",
      "man",
      "adulterate",
      "currency",
      "take",
      "peddle",
      "new",
    ],
    Freq: [
      12.0,
      9.0,
      6.0,
      6.0,
      5.0,
      9.0,
      5.0,
      5.0,
      6.0,
      5.0,
      0.999027311647695,
      1.99805462329539,
      1.99805462329539,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      6.993191181533865,
      5.99416386988617,
      4.995136558238475,
      3.99610924659078,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      8.991245804829255,
      3.99610924659078,
      3.99610924659078,
      3.99610924659078,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      1.9999824462311038,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      3.9999648924622075,
      3.9999648924622075,
      3.9999648924622075,
      2.9999736693466557,
      2.9999736693466557,
      2.9999736693466557,
      2.9999736693466557,
      2.9999736693466557,
      1.9999824462311038,
      1.9999824462311038,
      1.9999824462311038,
      5.999947338693311,
      1.9999824462311038,
      1.9999824462311038,
      4.99995611557776,
      2.9999736693466557,
      2.9999736693466557,
      2.9999736693466557,
      1.0000081899983047,
      1.0000081899983047,
      2.0000163799966093,
      2.0000163799966093,
      2.0000163799966093,
      1.0000081899983047,
      1.0000081899983047,
      1.0000081899983047,
      1.0000081899983047,
      4.000032759993219,
      4.000032759993219,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      9.000073709984743,
      4.000032759993219,
      3.000024569994914,
      3.000024569994914,
      2.0000133133537674,
      2.0000133133537674,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      6.000039940061303,
      5.000033283384419,
      4.000026626707535,
      3.0000199700306514,
      3.0000199700306514,
      3.0000199700306514,
      3.0000199700306514,
      2.0000133133537674,
      2.0000133133537674,
      2.0000133133537674,
      2.0000133133537674,
      2.0000133133537674,
      5.000033283384419,
      2.0000133133537674,
      4.000026626707535,
      2.0000133133537674,
      3.0000199700306514,
      3.0000199700306514,
      1.0013789103812356,
      3.004136731143707,
      2.002757820762471,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      5.006894551906178,
      4.005515641524942,
      3.004136731143707,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      4.005515641524942,
      2.002757820762471,
      5.006894551906178,
      5.006894551906178,
      2.002757820762471,
      2.002757820762471,
      4.005515641524942,
      2.002757820762471,
      3.004136731143707,
    ],
    Total: [
      12.0,
      9.0,
      6.0,
      6.0,
      5.0,
      9.0,
      5.0,
      5.0,
      6.0,
      5.0,
      0.999027311647695,
      1.99805462329539,
      1.99805462329539,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      0.999027311647695,
      6.993191181533865,
      5.99416386988617,
      4.995136558238475,
      3.99610924659078,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      2.997081934943085,
      9.991237027944807,
      4.9974881569720155,
      9.003003798496957,
      12.996182956575524,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      1.9999824462311038,
      0.9999912231155519,
      0.9999912231155519,
      0.9999912231155519,
      3.9999648924622075,
      3.9999648924622075,
      3.9999648924622075,
      2.9999736693466557,
      2.9999736693466557,
      2.9999736693466557,
      2.9999736693466557,
      2.9999736693466557,
      1.9999824462311038,
      1.9999824462311038,
      1.9999824462311038,
      6.998974650341006,
      1.9999824462311038,
      1.9999824462311038,
      7.00036233760669,
      3.9999803260235396,
      4.999007637671235,
      4.998028292642045,
      1.0000081899983047,
      1.0000081899983047,
      2.0000163799966093,
      2.0000163799966093,
      2.0000163799966093,
      1.0000081899983047,
      1.0000081899983047,
      1.0000081899983047,
      1.0000081899983047,
      4.000032759993219,
      4.000032759993219,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      3.000024569994914,
      12.996182956575524,
      5.999066728317797,
      9.006925778577976,
      4.001403480376149,
      2.0000133133537674,
      2.0000133133537674,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      1.0000066566768837,
      6.000039940061303,
      5.000033283384419,
      4.000026626707535,
      3.0000199700306514,
      3.0000199700306514,
      3.0000199700306514,
      3.0000199700306514,
      2.0000133133537674,
      2.0000133133537674,
      2.0000133133537674,
      2.0000133133537674,
      2.0000133133537674,
      5.999060595032114,
      2.0000133133537674,
      6.99710856165062,
      2.0000133133537674,
      4.9980745933260415,
      7.99806522955545,
      1.0013789103812356,
      3.004136731143707,
      2.002757820762471,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      1.0013789103812356,
      5.006894551906178,
      4.005515641524942,
      3.004136731143707,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      2.002757820762471,
      5.0045429531726375,
      2.002757820762471,
      9.003003798496957,
      9.006925778577976,
      2.002757820762471,
      2.002757820762471,
      7.002597576468027,
      2.002757820762471,
      6.002198011115981,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.3891,
      -4.6959,
      -4.6959,
      -5.3891,
      -5.3891,
      -5.3891,
      -5.3891,
      -5.3891,
      -5.3891,
      -5.3891,
      -3.4432,
      -3.5973,
      -3.7796,
      -4.0028,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -3.1918,
      -4.0028,
      -4.0028,
      -4.0028,
      -5.2364,
      -5.2364,
      -5.2364,
      -5.2364,
      -5.2364,
      -5.2364,
      -4.5433,
      -5.2364,
      -5.2364,
      -5.2364,
      -3.8501,
      -3.8501,
      -3.8501,
      -4.1378,
      -4.1378,
      -4.1378,
      -4.1378,
      -4.1378,
      -4.5433,
      -4.5433,
      -4.5433,
      -3.4447,
      -4.5433,
      -4.5433,
      -3.627,
      -4.1378,
      -4.1378,
      -4.1378,
      -5.0239,
      -5.0239,
      -4.3307,
      -4.3307,
      -4.3307,
      -5.0239,
      -5.0239,
      -5.0239,
      -5.0239,
      -3.6376,
      -3.6376,
      -3.9253,
      -3.9253,
      -3.9253,
      -3.9253,
      -3.9253,
      -3.9253,
      -3.9253,
      -3.9253,
      -3.9253,
      -3.9253,
      -2.8267,
      -3.6376,
      -3.9253,
      -3.9253,
      -4.3883,
      -4.3883,
      -5.0814,
      -5.0814,
      -5.0814,
      -5.0814,
      -5.0814,
      -5.0814,
      -5.0814,
      -5.0814,
      -3.2896,
      -3.472,
      -3.6951,
      -3.9828,
      -3.9828,
      -3.9828,
      -3.9828,
      -4.3883,
      -4.3883,
      -4.3883,
      -4.3883,
      -4.3883,
      -3.472,
      -4.3883,
      -3.6951,
      -4.3883,
      -3.9828,
      -3.9828,
      -5.037,
      -3.9383,
      -4.3438,
      -5.037,
      -5.037,
      -5.037,
      -5.037,
      -5.037,
      -5.037,
      -5.037,
      -3.4275,
      -3.6507,
      -3.9383,
      -4.3438,
      -4.3438,
      -4.3438,
      -4.3438,
      -4.3438,
      -4.3438,
      -3.6507,
      -4.3438,
      -3.4275,
      -3.4275,
      -4.3438,
      -4.3438,
      -3.6507,
      -4.3438,
      -3.9383,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.385,
      1.2795,
      1.1614,
      0.5727,
      0.2056,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.5366,
      1.3826,
      1.5366,
      1.5366,
      1.2001,
      1.249,
      1.026,
      1.0262,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.7492,
      1.3818,
      1.3439,
      0.6498,
      1.4612,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.6917,
      1.5095,
      1.6917,
      1.1325,
      1.6917,
      1.1812,
      0.7111,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.7347,
      1.5121,
      1.7347,
      1.148,
      1.1476,
      1.7347,
      1.7347,
      1.1761,
      1.7347,
      1.0426,
    ],
  },
  "token.table": {
    Topic: [
      5,
      4,
      3,
      2,
      2,
      3,
      4,
      1,
      5,
      4,
      3,
      2,
      1,
      3,
      1,
      1,
      3,
      3,
      4,
      5,
      1,
      5,
      2,
      1,
      2,
      4,
      5,
      5,
      3,
      1,
      1,
      4,
      2,
      4,
      5,
      3,
      3,
      3,
      5,
      1,
      2,
      5,
      1,
      4,
      4,
      1,
      1,
      3,
      2,
      3,
      4,
      5,
      5,
      4,
      1,
      2,
      3,
      4,
      3,
      4,
      4,
      3,
      1,
      1,
      5,
      2,
      3,
      3,
      4,
      2,
      1,
      4,
      5,
      1,
      1,
      2,
      2,
      1,
      1,
      4,
      4,
      4,
      1,
      3,
      4,
      5,
      2,
      5,
      5,
      1,
      1,
      2,
      1,
      5,
      1,
      3,
      4,
      1,
      4,
      5,
      3,
      1,
      1,
      1,
      3,
      5,
      1,
      5,
      5,
      3,
      4,
      1,
      2,
      1,
      4,
      5,
      2,
      2,
      2,
      4,
      5,
      5,
      5,
      2,
      2,
      2,
      4,
      3,
      2,
      4,
      1,
      2,
      2,
      1,
      1,
      5,
      5,
      3,
      3,
      2,
      4,
      2,
      1,
      5,
      2,
      1,
      4,
      2,
      4,
      5,
      4,
      5,
      4,
      3,
      5,
      2,
      4,
    ],
    Freq: [
      0.9986229883943625,
      0.9999933433674273,
      0.9999918100687709,
      1.0000087769614825,
      1.0000087769614825,
      0.9999918100687709,
      0.9999933433674273,
      1.0009736353961143,
      0.9986229883943625,
      0.9999933433674273,
      0.9999918100687709,
      1.0000087769614825,
      1.0009736353961143,
      0.9999918100687709,
      1.0009736353961143,
      1.0009736353961143,
      0.9999918100687708,
      0.9999918100687708,
      0.9999933433674273,
      0.9986229883943625,
      0.44429615820753027,
      0.5553701977594129,
      1.0000087769614825,
      0.20003970237297847,
      0.6001191071189355,
      0.20003970237297847,
      0.9986229883943625,
      0.9986229883943625,
      0.9999918100687709,
      1.0009736353961143,
      0.1666927653353111,
      0.8334638266765555,
      1.0000087769614825,
      0.9999933433674273,
      0.9986229883943625,
      0.9999918100687708,
      0.9999918100687708,
      0.9999918100687709,
      0.9986229883943625,
      0.14284974859485397,
      0.7142487429742699,
      0.14284974859485397,
      1.0009736353961143,
      0.9999933433674273,
      0.9999933433674273,
      1.0009736353961143,
      0.30778267844991886,
      0.6925110265123174,
      1.0000087769614825,
      0.9999918100687709,
      0.9999933433674273,
      0.9986229883943625,
      0.9986229883943625,
      0.9999933433674273,
      0.2500604762023383,
      0.2500604762023383,
      0.12503023810116914,
      0.37509071430350743,
      0.9999918100687709,
      0.9999933433674273,
      0.9999933433674273,
      0.9999918100687708,
      1.0009736353961143,
      0.19981844683060387,
      0.7992737873224155,
      1.0000087769614825,
      0.9999918100687708,
      0.9999918100687708,
      0.9999933433674272,
      1.0000087769614825,
      1.0009736353961143,
      0.9999933433674273,
      0.9986229883943625,
      1.0009736353961143,
      0.14287807142597347,
      0.8572684285558408,
      1.0000087769614825,
      1.0009736353961143,
      0.42874852856253226,
      0.571664704750043,
      0.9999933433674273,
      0.9999933433674273,
      1.0009736353961143,
      0.33307702025647684,
      0.11102567341882562,
      0.555128367094128,
      1.0000087769614825,
      0.9986229883943625,
      0.9986229883943625,
      1.0009736353961143,
      0.4001577988152534,
      0.6002366982228801,
      1.0009736353961143,
      0.9986229883943626,
      0.16669259491307756,
      0.6667703796523102,
      0.16669259491307756,
      0.333211266322109,
      0.1666056331610545,
      0.4998168994831635,
      0.9999918100687709,
      1.0009736353961143,
      1.0009736353961143,
      1.0009736353961143,
      0.7497369397294539,
      0.24991231324315127,
      1.0009736353961143,
      0.9986229883943625,
      0.9986229883943625,
      0.9999918100687708,
      0.9999933433674273,
      0.9007893591982269,
      0.10008770657758077,
      1.0009736353961143,
      0.9999933433674273,
      0.9986229883943625,
      1.0000087769614825,
      1.0000087769614825,
      1.0000087769614825,
      0.9999933433674273,
      0.9986229883943625,
      0.9986229883943625,
      0.9986229883943625,
      1.0000087769614825,
      1.0000087769614825,
      1.0000087769614825,
      0.9999933433674273,
      0.9999918100687709,
      1.0000087769614825,
      0.9999933433674273,
      1.0009736353961143,
      1.0000087769614825,
      1.0000087769614825,
      1.0009736353961143,
      0.8004020968853292,
      0.2001005242213323,
      0.9986229883943625,
      0.9999918100687708,
      0.9999918100687708,
      0.7500036888887301,
      0.2500012296295767,
      1.0000087769614825,
      0.4284124522707674,
      0.5712166030276898,
      1.0000087769614825,
      0.4001540918718204,
      0.6002311378077305,
      1.0000087769614825,
      0.9999933433674273,
      0.9986229883943625,
      0.9999933433674273,
      0.9986229883943625,
      0.9999933433674273,
      0.9999918100687709,
      0.9986229883943625,
      1.0000087769614825,
      0.9999933433674273,
    ],
    Term: [
      "adulterate",
      "anil",
      "august",
      "away",
      "bahrain",
      "beat",
      "bengal",
      "bhagwat",
      "bharatiya",
      "bhatt",
      "blue",
      "bodyguard",
      "boycott",
      "bride",
      "burj",
      "burj_khalifa",
      "burqa",
      "catch",
      "circulate",
      "connection",
      "covid",
      "covid",
      "coward",
      "create",
      "create",
      "create",
      "culture",
      "currency",
      "day",
      "deity",
      "delhi",
      "delhi",
      "digitally",
      "dislike",
      "dose",
      "doval",
      "egyptian",
      "eve",
      "expensive",
      "fall",
      "fall",
      "fall",
      "fight",
      "fill",
      "fire",
      "firework",
      "flag",
      "flag",
      "foot",
      "founder",
      "furious",
      "gaekwad",
      "gaza",
      "get",
      "go",
      "go",
      "go",
      "go",
      "google",
      "government",
      "hanmakonda",
      "hoist",
      "home",
      "hyderabad",
      "hyderabad",
      "imaginary",
      "independence",
      "independence_day",
      "indian",
      "islam",
      "israeli",
      "jai",
      "kerala",
      "khalifa",
      "khan",
      "khan",
      "king",
      "krishna",
      "link",
      "link",
      "mahesh",
      "mahesh_bhatt",
      "make",
      "man",
      "man",
      "man",
      "media",
      "milk",
      "mislead",
      "modi",
      "morphed",
      "morphed",
      "mt",
      "mumbai",
      "national",
      "national",
      "national",
      "new",
      "new",
      "new",
      "obscene",
      "outrageous",
      "paint",
      "painting",
      "pakistan",
      "pakistan",
      "pass",
      "peddle",
      "people",
      "pichai",
      "play",
      "pm",
      "pm",
      "pm_modi",
      "power",
      "pretext",
      "prey",
      "priest",
      "prince",
      "protest",
      "questioning",
      "quite",
      "radhikaraje",
      "rajput",
      "remark",
      "report",
      "road",
      "rss",
      "salman",
      "sewage",
      "shah",
      "singh",
      "skeleton",
      "spin",
      "statue",
      "statue",
      "strain",
      "sundar",
      "sundar_pichai",
      "sushant",
      "sushant",
      "sweep",
      "take",
      "take",
      "time",
      "trailer",
      "trailer",
      "trump",
      "upadhyay",
      "vaccine",
      "varanasi",
      "vogue",
      "wash",
      "worker",
      "world",
      "worship",
      "youth",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/hindi/congresss-video-on-pm-modis-relationship-china-includes-old-photo-with-japanese-pm-shinzo-abe/",
        headline:
          "\u092e\u094b\u0926\u0940 \u092a\u0930 \u091a\u0940\u0928 \u0938\u0947 \u0928\u095b\u0926\u0940\u0915\u0940 \u0915\u0947 \u2018\u0906\u0930\u094b\u092a\u2019 \u0932\u0917\u093e\u0924\u0947 \u0939\u0941\u090f \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0932\u0917\u093e\u092f\u0940 \u091c\u093e\u092a\u093e\u0928\u0940 PM \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930",
      },
      {
        url: "https://factly.in/no-burj-khalifa-is-not-lit-up-in-israeli-flag/",
        headline: " No, Burj Khalifa is not lit up in Israeli flag",
      },
      {
        url:
          "https://www.altnews.in/derogatory-painting-of-hindu-deity-krishna-from-5-years-ago-revived-on-social-media/",
        headline:
          "Derogatory painting of Hindu deity Krishna from 5 years ago revived on social media",
      },
      {
        url:
          "https://www.altnews.in/hindi/sdpi-muslim-community-falsely-blamed-for-placing-flag-atop-shankaracharya-statue-in-sringeri/",
        headline:
          "SDPI \u0914\u0930 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0938\u092e\u0941\u0926\u093e\u092f \u092a\u0930 \u0936\u0902\u0915\u0930\u093e\u091a\u093e\u0930\u094d\u092f \u0915\u0940 \u092e\u0942\u0930\u094d\u0924\u093f \u0915\u0947 \u090a\u092a\u0930 \u091d\u0902\u0921\u093e \u0932\u0917\u093e\u0928\u0947 \u0915\u093e \u0906\u0930\u094b\u092a \u095a\u0932\u0924",
      },
      {
        url:
          "https://www.altnews.in/hindi/derogatory-painting-of-hindu-deity-krishna-from-5-years-ago-revived-on-social-media/",
        headline:
          "\u0915\u0943\u0937\u094d\u0923 \u0915\u0940 \u2018\u0905\u092a\u092e\u093e\u0928\u091c\u0928\u0915 \u092a\u0947\u0902\u091f\u093f\u0902\u0917\u2019 \u092b\u093f\u0930 \u0938\u0947 \u0939\u094b \u0930\u0939\u0940 \u0935\u093e\u092f\u0930\u0932, \u092a\u0947\u0902\u091f\u0930 5 \u0938\u093e\u0932 \u092a\u0939\u0932\u0947 \u0917\u093f\u0930\u095e\u094d\u0924\u093e\u0930 \u0939\u094b \u091a\u0941\u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/netizens-boycott-fan-made-trailers-of-upcoming-srk-starrer-pathan-9337",
        headline:
          "Netizens Boycott Fan-made Trailers Of Upcoming SRK-Starrer 'Pathan'",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/derogatory-painting-of-krishna-from-2015-passed-off-as-recent-9355",
        headline:
          "Derogatory Painting Of Krishna From 2015 Passed Off As Recent",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/netizens-boycott-fan-made-trailers-of-upcoming-srk-starrer-pathan-9354",
        headline:
          "\u0915\u094d\u092f\u094b\u0902 \u0939\u094b \u0930\u0939\u093e \u0939\u0948 \u0936\u093e\u0939\u0930\u0941\u0959 \u0916\u093e\u0928 \u0915\u0940 \u0906\u0917\u093e\u092e\u0940 \u095e\u093f\u0932\u094d\u092e '\u092a\u0920\u093e\u0928' \u0915\u0947 \u095e\u0948\u0928-\u092e\u0947\u0921 \u091f\u094d\u0930\u0947\u0932\u0930 \u0915\u093e \u092c\u0939\u093f\u0937\u094d\u0915\u093e\u0930?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-image-of-amit-shahs-temple-visit-viral-with-covid-19-twist-9360",
        headline:
          "\u0917\u0943\u0939\u092e\u0902\u0924\u094d\u0930\u0940 \u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0915\u093f \u0938\u093e\u0932\u094b\u0902 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-a-couple-swimming-in-their-flooded-home-is-not-from-delhi-9401",
        headline:
          "\u092c\u093e\u095d \u0915\u0947 \u092a\u093e\u0928\u0940 \u092e\u0947\u0902 \u0905\u092a\u0928\u0947 \u0939\u0940 \u0918\u0930 \u092e\u0947\u0902 \u0921\u0941\u092c\u0915\u0940 \u0932\u0917\u093e\u0924\u0947 \u0926\u0902\u092a\u0924\u094d\u0924\u093f \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u093f\u0932\u094d\u0932\u0940 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/new-covid-19-strain-detected-in-malaysia-not-quite-9373",
        headline:
          "\u09ae\u09be\u09b2\u09df\u09c7\u09b6\u09bf\u09df\u09be\u09df \u0995\u09bf \u09a8\u09a4\u09c1\u09a8 \u09a7\u09b0\u09a3\u09c7\u09b0 \u0995\u09cb\u09ad\u09bf\u09a1? \u09a8\u09be, \u09a4\u09c7\u09ae\u09a8\u099f\u09be \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/derogatory-painting-of-krishna-from-2015-passed-off-as-recent-9383",
        headline:
          "\u09e8\u09e6\u09e7\u09eb \u09b8\u09be\u09b2\u09c7 \u0986\u0981\u0995\u09be \u0995\u09c3\u09b7\u09cd\u09a3\u09c7\u09b0 \u0985\u09ac\u09ae\u09be\u09a8\u09a8\u09be\u0995\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u09a6\u09be\u09ac\u09bf \u0995\u09b0\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-no-burj-khalifa-is-not-lit-up-in-israeli-flag/",
        headline:
          " \u0c26\u0c41\u0c2c\u0c3e\u0c2f\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c2c\u0c41\u0c30\u0c4d\u0c1c\u0c4d \u0c16\u0c32\u0c40\u0c2b\u0c3e\u0c28\u0c41 \u0c07\u0c1c\u0c4d\u0c30\u0c3e\u0c2f\u0c3f\u0c32\u0c4d \u0c26\u0c47\u0c36 \u0c1c\u0c3e\u0c24\u0c40\u0c2f \u0c1c\u0c46\u0c02\u0c21\u0c3e \u0c2a\u0c4d\u0c30\u0c24\u0c3f\u0c2c\u0c3f\u0c02\u0c2c\u0c3f\u0c02\u0c1a\u0c47\u0c32\u0c3e \u0c35\u0c46\u0c32\u0c3f\u0c17\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/most-of-these-statements-were-not-made-by-former-rbi-governor-y-v-reddy-in-his-autobiography/",
        headline:
          " Most of these statements were not made by former RBI Governor Y. V. Reddy in his autobiography",
      },
      {
        url:
          "https://factly.in/image-of-pm-modi-bowing-to-gandhis-statue-is-edited-with-nehrus-statue/",
        headline:
          " Image of PM Modi bowing to Gandhi\u2019s statue is edited with Nehru\u2019s statue",
      },
      {
        url:
          "https://factly.in/telugu-these-photos-of-houses-being-buried-under-mud-are-not-from-telangana/",
        headline:
          " \u0c35\u0c30\u0c4d\u0c37\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c2e\u0c1f\u0c4d\u0c1f\u0c3f\u0c32\u0c4b \u0c15\u0c42\u0c30\u0c41\u0c15\u0c41\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c07\u0c33\u0c4d\u0c33\u0c41, \u0c35\u0c3e\u0c39\u0c28\u0c3e\u0c32 \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41  \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c35\u0c3f \u0c15\u0c3e\u0c35\u0c41.",
      },
      {
        url:
          "https://factly.in/this-photo-was-taken-in-russia-during-the-pancha-tattva-installation-of-deities-ceremony-in-2019/",
        headline:
          " This photo was taken in Russia during the \u2018Pancha-Tattva: Installation of deities\u2019 ceremony in 2019.",
      },
      {
        url:
          "https://factly.in/unrelated-simulated-fireworks-video-shared-as-the-fireworks-prepared-for-tokyo-2020/",
        headline:
          " Unrelated simulated fireworks video shared as the fireworks prepared for \u2018TOKYO 2020\u2019",
      },
      {
        url:
          "https://factly.in/an-edited-photo-is-falsely-shared-as-the-koneswaram-temple-in-sri-lanka/",
        headline:
          " An edited photo is falsely shared as the Koneswaram temple in Sri Lanka",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-simulated-fireworks-display-is-being-shared-as-fireworks-made-ready-for-tokyo-2020/",
        headline:
          " \u0c12\u0c15 \u0c35\u0c46\u0c2c\u0c4d\u0c38\u0c48\u0c1f\u0c41\u0c32\u0c4b \u0c38\u0c43\u0c37\u0c4d\u0c1f\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2c\u0c3e\u0c23\u0c38\u0c02\u0c1a\u0c3e \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c12\u0c32\u0c3f\u0c02\u0c2a\u0c3f\u0c15\u0c4d\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c3e\u0c30\u0c02\u0c2d\u0c4b\u0c24\u0c4d\u0c38\u0c35 \u0c15\u0c3e\u0c30\u0c4d\u0c2f\u0c15\u0c4d\u0c30\u0c2e\u0c02 \u0c15\u0c4b\u0c38\u0c02 \u0c38\u0c3f\u0c26\u0c4d\u0c27\u0c02 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2c\u0c3e\u0c23\u0c38\u0c02\u0c1a\u0c3e\u0c32\u0c28\u0c3f  \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/there-is-no-such-scheme-called-pm-kisan-tractor-yojana/",
        headline:
          " There is no scheme with the name \u2018PM Kisan Tractor Yojana\u2019",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/17/fact-check-video-of-new-zealand-pms-visit-to-temple-falsely-linked-with-covid-19/",
        headline:
          "Fact Check: Video of New Zealand PM\u2019s visit to temple falsely linked with COVID-19",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/19/fact-check-was-burj-khalifa-lit-up-with-the-israeli-flag-heres-the-truth/",
        headline:
          "Fact Check: Was Burj Khalifa lit up with the Israeli Flag? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/20/fact-check-picture-showing-pm-modi-bowing-in-front-of-nehrus-statue-is-fake/",
        headline:
          "Fact Check: Picture showing PM Modi bowing in front of Nehru\u2019s statue is fake",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/21/fact-check-heres-the-truth-behind-this-morphed-picture-of-pm-modi-wearing-a-skull-cap/",
        headline:
          "Fact Check: Here\u2019s the truth behind this morphed picture of PM Modi wearing a skull cap",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/21/fact-check-dont-fall-for-this-picture-of-pm-modi-mohan-bhagwat-amit-shah-together-in-a-group/",
        headline:
          "Fact Check: Don\u2019t fall for this picture of PM Modi, Mohan Bhagwat, Amit Shah, together in a group",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-how-a-fight-between-two-hindu-groups-in-hyderabad-took-a-communal-spin-on-social-media-1714257-2020-08-23",
        headline:
          "Fact Check: How a fight between two Hindu groups in Hyderabad took a communal spin on social media",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/mt-fuji-lights-up-on-internet-with-digital-fireworks-and-an-olympic-twist-1713354-2020-08-20",
        headline:
          "Fact Check: Mt Fuji lights up on Internet with digital fireworks and an Olympic twist",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/21/picture-of-pancha-tattva-deities-from-2019-being-shared-with-false-covid-19-link/",
        headline:
          "Fact Check: Picture of Pancha-Tattva deities from 2019 being shared with false COVID-19 link",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/22/fact-check-morphed-picture-of-pm-modi-shared-with-false-covid-19-link/",
        headline:
          "Fact Check: Morphed picture of PM Modi shared with false COVID-19 link",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/22/fact-check-heres-the-truth-behind-this-picture-of-pm-modi-holding-srk-jersey/",
        headline:
          "Fact Check: Here\u2019s the truth behind this picture of PM Modi holding SRK jersey",
      },
    ],
    "2": [
      {
        url:
          "https://www.altnews.in/hindi/morphed-image-of-time-to-go-cover-featuring-donald-trump-shared-by-journalists/",
        headline:
          "\u091f\u093e\u0907\u092e \u0915\u0947 \u0915\u0935\u0930 \u092a\u0947\u091c \u092a\u0930 \u2018Time to go\u2019 \u0915\u0947 \u0938\u093e\u0925 \u092c\u093e\u0939\u0930 \u091c\u093e\u0924\u0947 \u0921\u0949\u0928\u0932\u094d\u0921 \u091f\u094d\u0930\u092e\u094d\u092a \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092c\u0928\u093e\u0935\u091f\u0940",
      },
      {
        url:
          "https://factly.in/sp-divya-sara-thomas-has-not-forced-the-temple-priest-to-offer-pooja-to-a-jesus-photo/",
        headline:
          " SP Divya Sara Thomas has not forced the temple priest to offer pooja to a Jesus photo",
      },
      {
        url:
          "https://www.altnews.in/morphed-image-of-time-to-go-cover-featuring-donald-trump-shared-by-journalists/",
        headline:
          "Morphed: TIME magazine cover featuring Donald Trump with \u2018Time to go\u2019 headline",
      },
      {
        url:
          "https://www.altnews.in/sushant-singh-rajput-dancing-with-his-choreographer-manpreet-toor-media-misreports-he-is-dancing-with-his-niece-mallika-singh/",
        headline:
          "Sushant Singh Rajput is not dancing with his \u2018niece\u2019 in this video, media misreport",
      },
      {
        url:
          "https://www.altnews.in/hindi/sushant-singh-rajput-dancing-with-his-choreographer-manpreet-toor-media-misreports-he-is-dancing-with-his-niece-mallika-singh/",
        headline:
          "\u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0938\u0941\u0936\u093e\u0902\u0924 \u0915\u0947 \u0938\u093e\u0925 \u0909\u0928\u0915\u0940 \u0915\u094b\u0930\u093f\u092f\u094b\u0917\u094d\u0930\u093e\u095e\u0930 \u0921\u093e\u0902\u0938 \u0915\u0930 \u0930\u0939\u0940 \u0925\u0940\u0902, \u092e\u0940\u0921\u093f\u092f\u093e \u0928\u0947 \u092d\u093e\u0902\u091c\u0940 \u092c\u0924\u093e\u0915\u0930 \u091a\u0932\u093e \u0926\u093f\u092f\u093e",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/kangana-ranaut-shares-fake-aamir-khan-interview-on-islam-9421",
        headline: "Kangana Ranaut Shares Fake Aamir Khan Interview On Islam",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/fake-breaking-news-graphic-claims-schools-colleges-in-india-will-open-next-year-9405",
        headline:
          "\u0915\u094d\u092f\u093e \u0926\u0947\u0936 \u092d\u0930 \u092e\u0947\u0902 \u0938\u094d\u0915\u0942\u0932-\u0915\u0949\u0932\u0947\u091c \u0905\u092c \u0905\u0917\u0932\u0947 \u0938\u093e\u0932 \u0939\u0940 \u0916\u0941\u0932\u0947\u0902\u0917\u0947 ?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-quote-of-converting-hindus-credited-to-saudi-arabian-crown-prince-9339",
        headline:
          "\u09b8\u09cc\u09a6\u09bf \u0986\u09b0\u09ac\u09c7\u09b0 \u09af\u09c1\u09ac\u09b0\u09be\u099c \u09ac\u09bf\u09a8 \u09b8\u09b2\u09ae\u09a8\u09c7\u09b0 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae\u09a6\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u09ad\u09c1\u09df\u09cb \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-file/wsj-expos-on-facebook-bjp-triggers-political-row-in-india-9353",
        headline:
          "\u09ab\u09c7\u09b8\u09ac\u09c1\u0995 \u09a8\u09bf\u09df\u09c7 \u0993\u09df\u09be\u09b2 \u09b8\u09cd\u099f\u09cd\u09b0\u09bf\u099f \u099c\u09be\u09b0\u09cd\u09a8\u09be\u09b2\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09c7\u09a6\u09a8 \u0998\u09bf\u09b0\u09c7 \u09ad\u09be\u09b0\u09a4\u09c7 \u09b0\u09be\u099c\u09a8\u09c8\u09a4\u09bf\u0995 \u09a4\u09b0\u099c\u09be",
      },
      {
        url:
          "https://factly.in/telugu-sp-divya-sara-thomas-has-not-forced-the-temple-priest-to-offer-pooja-to-a-jesus-photo/",
        headline:
          " \u0c2f\u0c47\u0c38\u0c41\u0c15\u0c4d\u0c30\u0c40\u0c38\u0c4d\u0c24\u0c41 \u0c2b\u0c4b\u0c1f\u0c4b\u0c15\u0c3f \u0c2a\u0c42\u0c1c \u0c1a\u0c47\u0c2f\u0c2e\u0c28\u0c3f \u0c2a\u0c42\u0c1c\u0c3e\u0c30\u0c3f\u0c28\u0c3f \u0c0e\u0c38\u0c4d\u0c2a\u0c40 \u0c26\u0c3f\u0c35\u0c4d\u0c2f \u0c38\u0c3e\u0c30\u0c3e \u0c25\u0c3e\u0c2e\u0c38\u0c4d \u0c12\u0c24\u0c4d\u0c24\u0c3f\u0c21\u0c3f \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/no-this-robot-is-not-the-bodyguard-of-the-king-of-bahrain/",
        headline: " No, this Robot is not the bodyguard of the King of Bahrain",
      },
      {
        url:
          "https://factly.in/telugu-no-this-robot-is-not-the-bodyguard-of-the-king-of-bahrain/",
        headline:
          " \u0c08 \u0c30\u0c4b\u0c2c\u0c4b \u0c2c\u0c39\u0c4d\u0c30\u0c46\u0c2f\u0c3f\u0c28\u0c4d \u0c30\u0c3e\u0c1c\u0c41 \u0c2c\u0c3e\u0c21\u0c40\u0c17\u0c3e\u0c30\u0c4d\u0c21\u0c4d \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-fictitious-tale-of-the-coversation-between-nehru-khan-abdul-ghaffar-khan-and-vallabhbhai-patel/",
        headline:
          " \u0c28\u0c46\u0c39\u0c4d\u0c30\u0c41, \u0c16\u0c3e\u0c28\u0c4d \u0c05\u0c2c\u0c4d\u0c26\u0c41\u0c32\u0c4d \u0c17\u0c2b\u0c4d\u0c2b\u0c3e\u0c30\u0c4d \u0c16\u0c3e\u0c28\u0c4d, \u0c35\u0c32\u0c4d\u0c32\u0c2d\u0c3e\u0c2f\u0c4d \u0c2a\u0c1f\u0c47\u0c32\u0c4d \u0c2e\u0c27\u0c4d\u0c2f \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c38\u0c02\u0c2d\u0c3e\u0c37\u0c23\u0c17\u0c3e \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c12\u0c15 \u0c0a\u0c39\u0c3e\u0c24\u0c4d\u0c2e\u0c15 \u0c38\u0c02\u0c2d\u0c3e\u0c37\u0c23",
      },
      {
        url:
          "https://factly.in/telugu-this-persons-devotion-to-trump-has-nothing-to-do-with-his-comments-on-immigrants/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c1f\u0c4d\u0c30\u0c02\u0c2a\u0c4d \u0c28\u0c3f \u0c2a\u0c42\u0c1c\u0c3f\u0c02\u0c1a\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f, \u0c35\u0c32\u0c38\u0c26\u0c3e\u0c30\u0c41\u0c32 \u0c35\u0c3f\u0c37\u0c2f\u0c02\u0c32\u0c4b \u0c1f\u0c4d\u0c30\u0c02\u0c2a\u0c4d \u0c35\u0c4d\u0c2f\u0c3e\u0c16\u0c4d\u0c2f\u0c32\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/digitally-created-photo-of-a-giant-skeleton-is-falsely-shared-as-the-skeleton-of-ghatotkacha/",
        headline:
          " Digitally created photo of a giant skeleton is falsely shared as the skeleton of Ghatotkacha",
      },
      {
        url:
          "https://factly.in/video-of-traffic-signal-post-being-swept-away-in-a-flooded-street-is-from-china-not-india/",
        headline:
          " Video of traffic signal post being swept away in a flooded street is from China, not India",
      },
      {
        url:
          "https://factly.in/telugu-photoshopped-image-of-a-giant-skeleton-is-shared-as-the-skeleton-of-ghatotkacha/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c37\u0c3e\u0c2a\u0c4d \u0c26\u0c4d\u0c35\u0c3e\u0c30\u0c3e \u0c38\u0c43\u0c37\u0c4d\u0c1f\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c05\u0c38\u0c4d\u0c25\u0c3f\u0c2a\u0c02\u0c1c\u0c30\u0c02 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 80 \u0c05\u0c21\u0c41\u0c17\u0c41\u0c32 \u0c18\u0c1f\u0c4b\u0c24\u0c4d\u0c15\u0c1a\u0c41\u0c21\u0c3f \u0c05\u0c38\u0c4d\u0c25\u0c3f\u0c2a\u0c02\u0c1c\u0c30\u0c2e\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-british-pm-boris-johnson-did-not-apologise-for-what-britain-did-to-india-74-years-ago-1712211-2020-08-17",
        headline:
          "Fact Check: Netizens fall prey to British PM Boris Johnson's imposter account offering apology for colonial rule in India",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-not-king-bahrain-in-dubai-automaton-bodyguard-1712063-2020-08-17",
        headline:
          "Fact Check: This is not King of Bahrain arriving in Dubai with his automaton bodyguard",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/18/fact-check-salman-khan-did-not-call-sushant-singh-rajput-a-coward/",
        headline:
          "Fact Check: Salman Khan did not call Sushant Singh Rajput a coward",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/19/fact-check-dont-fall-for-fake-news-suggesting-dhoni-paid-the-price-for-not-campaigning-for-bjp/",
        headline:
          "Fact Check: Don\u2019t fall for fake news suggesting Dhoni paid the price for not campaigning for BJP",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/20/fact-check-quote-attributed-to-arbaaz-khan-on-his-brother-salman-khans-movies-is-false/",
        headline:
          "Fact Check: Quote attributed to Arbaaz Khan on his brother Salman Khan\u2019s movies is false",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/21/fact-check-dont-fall-for-this-morphed-picture-of-bjp-spokesperson-sambit-patra/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed picture of BJP spokesperson Sambit Patra",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-did-kashmir-witness-stone-pelting-like-this-viral-post-claims-1712622-2020-08-18",
        headline:
          "Fact Check: Did Kashmir witness stone-pelting like this viral post claims?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-created-in-1962-did-this-illustration-predict-the-pandemic-in-2020-1713821-2020-08-22",
        headline:
          "Fact Check: Created in 1962, did this illustration predict the pandemic in 2020?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/21/fact-check-dont-fall-for-this-morphed-picture-of-defence-minister-rajnath-singh/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed picture of Defence Minister Rajnath Singh",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/22/fact-check-dont-fall-for-this-old-newspaper-report-of-forced-retirement-of-railway-employees/",
        headline:
          "Fact Check: Don\u2019t fall for this old newspaper report of \u2018forced retirement\u2019 of Railway employees",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/hindi/bjp-leaders-share-morphed-image-of-national-flag-hoisted-in-lal-chowk-srinagar/",
        headline:
          "BJP \u0928\u0947\u0924\u093e\u0913\u0902 \u0928\u0947 \u0936\u094d\u0930\u0940\u0928\u0917\u0930 \u0915\u0947 \u0932\u093e\u0932 \u091a\u094c\u0915 \u092a\u0930 \u091d\u0902\u0921\u093e \u092b\u093c\u0939\u0930\u093e\u0928\u0947 \u0935\u093e\u0932\u0940 \u092b\u0947\u093c\u0915 \u0924\u0938\u094d\u0935\u0940\u0930 \u0927\u095c\u0932\u094d\u0932\u0947 \u0938\u0947 \u0936\u0947\u092f\u0930 \u0915\u0940",
      },
      {
        url:
          "https://factly.in/video-of-arrest-of-a-burqa-clad-man-for-bootlegging-is-shared-with-a-false-communal-narrative/",
        headline:
          " Video of arrest of a burqa-clad man for bootlegging is shared with a false communal narrative",
      },
      {
        url:
          "https://www.altnews.in/did-rss-worker-wearing-burqa-held-for-waving-pakistani-flag-video-shared-with-false-claim/",
        headline:
          "RSS man dressed in burqa caught by the police for waving Pak flag? False claim",
      },
      {
        url:
          "https://www.altnews.in/old-pornographic-visual-from-pakistan-shared-as-rss-worker-caught-in-obscene-act/",
        headline:
          "Old pornographic visual from Pakistan shared as RSS worker caught in obscene act",
      },
      {
        url:
          "https://www.altnews.in/hindi/did-rss-worker-wearing-burqa-held-for-waving-pakistani-flag-video-shared-with-false-claim/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915: \u092c\u0941\u0930\u094d\u0915\u093e \u092a\u0939\u0928\u0915\u0930 \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u091d\u0902\u0921\u093e \u0932\u0939\u0930\u093e\u0924\u0947 \u0939\u0941\u090f RSS \u0938\u0926\u0938\u094d\u092f \u0915\u094b \u092a\u0941\u0932\u093f\u0938 \u0928\u0947 \u092a\u0915\u095c\u093e?",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-pornographic-visual-from-pakistan-shared-as-rss-worker-caught-in-obscene-act/",
        headline:
          "\u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u0915\u0947 \u092a\u0941\u0930\u093e\u0928\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0936\u0947\u092f\u0930 \u0915\u0930 RSS \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e \u092a\u0930 \u0932\u0917\u093e\u092f\u093e \u0905\u092d\u0926\u094d\u0930\u0924\u093e \u0915\u093e \u0906\u0930\u094b\u092a",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-mughal-gardens-has-not-been-renamed-to-dr-rajendra-prasad-garden-9403",
        headline:
          "No, Mughal Gardens Has Not Been Renamed To Dr. Rajendra Prasad Garden",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-mughal-gardens-has-not-been-renamed-to-dr-rajendra-prasad-garden-9406",
        headline:
          "\u0928\u0939\u0940\u0902, \u092e\u0941\u095a\u0932 \u0917\u093e\u0930\u094d\u0921\u0928\u094d\u0938 \u0915\u093e \u0928\u093e\u092e \u092c\u0926\u0932 \u0915\u0930 \u0921\u0949 \u0930\u093e\u091c\u0947\u0902\u0926\u094d\u0930 \u092a\u094d\u0930\u0938\u093e\u0926 \u0917\u093e\u0930\u094d\u0921\u0928 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/nsa-ajit-doval-shared-egypts-flag-to-wish-on-aug-15-thats-post-from-dovals-fan-page-9338",
        headline:
          "\u099c\u09be\u09a4\u09c0\u09df \u09a8\u09bf\u09b0\u09be\u09aa\u09a4\u09cd\u09a4\u09be \u0989\u09aa\u09a6\u09c7\u09b7\u09cd\u099f\u09be \u09a6\u09cb\u09ad\u09be\u09b2\u09c7\u09b0 \u09ae\u09bf\u09b6\u09b0\u09c7\u09b0 \u09aa\u09a4\u09be\u0995\u09be\u09df \u09e7\u09eb \u0985\u0997\u09b8\u09cd\u099f\u09c7\u09b0 \u09b6\u09c1\u09ad\u09c7\u099a\u09cd\u099b\u09be? \u098f\u099f\u09bf \u09a6\u09cb\u09ad\u09be\u09b2\u09c7\u09b0 \u09ab\u09cd\u09af\u09be\u09a8 \u09aa\u09c7\u099c",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-video-of-clash-between-groups-after-independence-day-of-pakistan-revived-9358",
        headline:
          "\u09e8\u09e6\u09e7\u09ed \u09b8\u09be\u09b2\u09c7 \u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09c7\u09b0 \u09b8\u09cd\u09ac\u09be\u09a7\u09c0\u09a8\u09a4\u09be \u09a6\u09bf\u09ac\u09b8\u09c7 \u09b8\u0982\u0998\u09b0\u09cd\u09b7\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://factly.in/this-man-was-not-beaten-for-hoisting-blue-colour-bsp-flag-instead-of-national-flag/",
        headline:
          " This man was not beaten for hoisting blue colour BSP flag instead of National flag",
      },
      {
        url:
          "https://factly.in/old-video-of-ic3-institute-founder-ganesh-kohli-is-falsely-attributed-to-google-ceo-sundar-pichai/",
        headline:
          " Old video of IC3 Institute founder Ganesh Kohli is falsely attributed to Google CEO Sundar Pichai",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-ic3-institute-founder-ganesh-kohli-is-falsely-attributed-to-google-ceo-sundar-pichai/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c24\u0c28 \u0c17\u0c41\u0c30\u0c41\u0c35\u0c41\u0c28\u0c3f \u0c15\u0c32\u0c41\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f IC3 \u0c38\u0c02\u0c38\u0c4d\u0c25 \u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c4d\u0c25\u0c3e\u0c2a\u0c15\u0c41\u0c21\u0c41 \u0c17\u0c23\u0c47\u0c37\u0c4d \u0c15\u0c4b\u0c39\u0c4d\u0c32\u0c3f, \u0c17\u0c42\u0c17\u0c41\u0c32\u0c4d \u0c38\u0c40\u0c08\u0c13 \u0c38\u0c41\u0c02\u0c26\u0c30\u0c4d \u0c2a\u0c3f\u0c1a\u0c2f\u0c4d \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-post-of-ajit-dovals-independence-day-greeting-with-egyptian-flag-is-fake/",
        headline:
          " This post of Ajit Doval\u2019s Independence Day greeting with Egyptian flag is fake",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-did-national-security-adviser-use-egyptian-national-flag-in-his-i-day-wish-to-india-1712252-2020-08-17",
        headline:
          "Fact Check: Did National Security Advisor use Egyptian national flag in his I-Day wish to India?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/17/fact-check-raga-hoisted-the-flag-on-the-eve-of-74th-independence-day-heres-the-truth/",
        headline:
          "Fact Check: RaGa hoisted the flag on the eve of 74th Independence Day? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/17/fact-check-did-sundar-pichai-go-to-meet-his-school-teacher-heres-the-truth/",
        headline:
          "Fact Check: Did Sundar Pichai go to meet his school teacher? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/20/fact-check-this-video-of-the-waterfall-of-the-bride-is-from-peru-not-romania/",
        headline:
          "Fact Check: This video of the \u2018Waterfall of the Bride\u2019 is from Peru, not Romania",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/here-truth-behind-viral-story-incestuous-marriage-son-mother-1713007-2020-08-19",
        headline:
          "Fact Check: Here's truth behind viral story of incestuous marriage between son and his mother",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/the-link-between-misinformation-and-radicalisation-revisiting-a-delhi-riots-hate-speech-video/",
        headline:
          "The link between misinformation and radicalisation: Revisiting a Delhi riots hate speech video",
      },
      {
        url:
          "https://www.altnews.in/hindi/two-decade-old-hostage-rescue-video-from-venezuela-viral-as-spanish-police-shoot-is-terrorist/",
        headline:
          "\u092c\u0902\u0927\u0915 \u0915\u094b \u091b\u0941\u095c\u093e\u092f\u0947 \u091c\u093e\u0928\u0947 \u0915\u093e 1998 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b IS \u0906\u0924\u0902\u0915\u0940 \u0915\u094b \u0917\u094b\u0932\u0940 \u092e\u093e\u0930\u0928\u0947 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0915\u093f\u092f\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/fact-check-supreme-court-didnt-changed-its-motto-satyamev-jayate-to-yato-dharmastato-jaya/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915: \u0938\u0941\u092a\u094d\u0930\u0940\u092e \u0915\u094b\u0930\u094d\u091f \u0928\u0947 \u2018\u0938\u0924\u094d\u092f\u092e\u0947\u0935 \u091c\u092f\u0924\u0947\u2019 \u0915\u094b \u0939\u091f\u093e\u0915\u0930 \u2018\u092f\u0924\u094b \u0927\u0930\u094d\u092e\u0938\u094d\u0924\u0924\u094b \u091c\u092f:\u2019 \u0915\u0930 \u0926\u093f\u092f\u093e?",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/misleading-anti-mask-video-by-indian-youth-goes-viral-9367",
        headline: "Misleading Anti-Mask Video By Indian Youth Goes Viral",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-is-not-mahesh-bhatt-angry-at-sadak-2-trailer-getting-dislikes-9387",
        headline:
          "No, This Is Not Mahesh Bhatt Angry At Sadak 2 Trailer Getting Dislikes",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-from-protest-in-bengal-falsely-linked-to-bengaluru-9439",
        headline: "Video From Protest In Bengal Falsely Linked To Bengaluru",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-this-is-not-mahesh-bhatt-angry-at-sadak-2-trailer-getting-dislikes-9404",
        headline:
          "\u0928\u0939\u0940\u0902, \u092e\u0939\u0947\u0936 \u092d\u091f\u094d\u091f \u0938\u095c\u0915-2 \u0915\u0947 \u091f\u094d\u0930\u0947\u0932\u0930 \u0915\u094b \u0921\u093f\u0938\u0932\u093e\u0907\u0915\u094d\u0938 \u092e\u093f\u0932\u0928\u0947 \u092a\u0930 \u0928\u0939\u0940\u0902 \u092d\u095c\u0915 \u0930\u0939\u0947 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-video-clip-of-fire-breaking-out-at-delhis-tughlakabad-slum-shared-with-communal-spin-9381",
        headline:
          "\u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09b0 \u09a4\u09c1\u0998\u09b2\u0995\u09be\u09ac\u09be\u09a6\u09c7 \u0986\u0997\u09c1\u09a8 \u09b2\u09be\u0997\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09a6\u09c3\u09b6\u09cd\u09af \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09b0\u0999 \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-is-not-mahesh-bhatt-angry-at-sadak-2-trailer-getting-dislikes-9397",
        headline:
          "\u098f\u099f\u09bf '\u09b8\u09dc\u0995-\u09e8' \u099f\u09cd\u09b0\u09c7\u09b2\u09be\u09b0 '\u09a1\u09bf\u09b8\u09b2\u09be\u0987\u0995' \u09aa\u09be\u0993\u09df\u09be\u09df \u09ae\u09b9\u09c7\u09b6 \u09ad\u09be\u099f\u09c7\u09b0 \u09b0\u09c7\u0997\u09c7 \u09af\u09be\u0993\u09df\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/misleading-anti-mask-video-by-indian-youth-goes-viral-9418",
        headline:
          "\u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09a4\u09b0\u09c1\u09a3\u09a6\u09c7\u09b0 \u09ae\u09be\u09b8\u09cd\u0995-\u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-viral-image-does-not-show-a-well-built-indian-army-soldier-9435",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u0995\u09cb\u09a8\u0993 \u09af\u09a5\u09cb\u09aa\u09af\u09c1\u0995\u09cd\u09a4 \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09b8\u09c7\u09a8\u09be\u09b0 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fast-check/2017-photo-of-potholed-road-in-bihar-revived-with-misleading-claims-9438",
        headline:
          "\u09e8\u09e6\u09e7\u09ed'\u09b0 \u0996\u09be\u09a8\u09be\u0996\u09a8\u09cd\u09a6\u09c7 \u09ad\u09b0\u09be \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u09b0\u09be\u09b8\u09cd\u09a4\u09be\u09b0 \u099b\u09ac\u09bf \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09ad\u09be\u09ac\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://factly.in/old-video-of-u-s-army-band-playing-the-indian-national-anthem-is-falsely-shared-as-a-recent-video/",
        headline:
          " Old video of U.S army band playing the Indian National Anthem is falsely shared as a recent video",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-of-man-being-washed-away-in-the-floods-is-from-jaipur-not-hanmakonda-telangana/",
        headline:
          " \u0c15\u0c3e\u0c32\u0c28\u0c40\u0c32\u0c4d\u0c32\u0c4b \u0c1a\u0c47\u0c30\u0c3f\u0c28 \u0c35\u0c30\u0c26 \u0c28\u0c40\u0c1f\u0c3f\u0c32\u0c4b \u0c12\u0c15 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c15\u0c4a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c2a\u0c4b\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c15\u0c3f \u0c39\u0c28\u0c4d\u0c2e\u0c15\u0c4a\u0c02\u0c21 \u0c35\u0c30\u0c26\u0c32\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/old-photo-of-a-new-delhi-road-is-falsely-shared-as-a-road-in-varanasi-up-ruled-by-bjp/",
        headline:
          " Old photo of a New Delhi road is falsely shared as a road in Varanasi (UP) ruled by BJP",
      },
      {
        url:
          "https://factly.in/telugu-new-delhi-road-old-photo-falsely-shared-as-varanasi-up-road-under-bjp/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c2c\u0c41\u0c30\u0c26\u0c24\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c08 \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40 \u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28\u0c26\u0c3f; \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c09\u0c28\u0c4d\u0c28 \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b\u0c28\u0c3f\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/old-fictional-character-mla-anil-upadhyay-revived-again-as-congress-mla-anil-upadhyay/",
        headline:
          " Old fictional character \u2018MLA Anil Upadhyay\u2019 revived again as \u2018Congress MLA Anil Upadhyay\u2019",
      },
      {
        url:
          "https://factly.in/old-image-of-a-sinkhole-on-a-road-in-ahmedabad-is-falsely-linked-to-banaras-in-uttar-pradesh/",
        headline:
          " Old image of a sinkhole on a road in Ahmedabad is falsely linked to Banaras in Uttar Pradesh",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/19/fact-check-news-about-fast-furious-actor-jason-stathams-death-is-fake/",
        headline:
          "Fact Check: News about \u2018Fast & Furious\u2019 actor Jason Statham\u2019s death is fake",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-image-from-bangladesh-falsely-circulated-as-that-depicting-miserable-life-in-indian-slums-1712697-2020-08-19",
        headline:
          "Fact Check: Old image from Bangladesh falsely circulated as that depicting miserable life in Indian slums",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-sewage-filled-dug-up-road-questions-cleanliness-in-pm-modi-s-varanasi-1713780-2020-08-21",
        headline:
          "Fact Check: Image of sewage-filled dug up road from Delhi goes viral as that from Varanasi",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-vfx-video-created-by-russian-artist-wrongly-circulated-as-protest-scene-in-belarus-1713564-2020-08-21",
        headline:
          "Fact Check: VFX video created by Russian artist wrongly circulated as protest scene in Belarus",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/sushant-death-gets-dawood-link-on-social-media-1713950-2020-08-22",
        headline:
          "Fact Check: Sushant's death gets a Dawood link on social media\u00a0",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/22/fact-check-old-picture-of-fire-tornado-shared-as-a-recent-one-from-recent-california-wildfires/",
        headline:
          "Fact Check: Old picture of \u2018fire tornado\u2019 shared as a recent one from recent California wildfires",
      },
    ],
    "5": [
      {
        url:
          "https://www.boomlive.in/fake-news/no-maharani-radhikaraje-gaekwad-of-baroda-has-not-taken-up-diksha-9336",
        headline:
          "No, Maharani Radhikaraje Gaekwad Of Baroda Has Not Taken Up Diksha",
      },
      {
        url:
          "https://www.altnews.in/video-from-hyderabad-falsely-viral-as-muslim-man-adulterating-milk-in-mumbai/",
        headline:
          "Video from Hyderabad falsely viral as Muslim man adulterating milk in Mumbai",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-from-hyderabad-falsely-viral-as-muslim-man-adulterating-milk-in-mumbai/",
        headline:
          "\u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0915\u093e\u00a0\u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0941\u0902\u092c\u0908 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u092a\u0930 \u0926\u0942\u0927 \u0917\u0928\u094d\u0926\u093e \u0915\u0930\u0928\u0947 \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u0906\u0930\u094b\u092a \u0932\u0917\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/new-covid-19-strain-detected-in-malaysia-not-quite-9350",
        headline: "New COVID-19 Strain Detected In Malaysia? Not Quite",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/raam-bearer-bond-falsely-peddled-as-worlds-most-expensive-currency-9361",
        headline:
          "'Raam' Bearer Bond Falsely Peddled As World's Most Expensive Currency",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/kerala-yoga-ashram-photo-viral-as-bharatiya-culture-in-new-zealand-9400",
        headline:
          "Kerala Yoga Ashram Photo Viral As 'Bharatiya' Culture In New Zealand",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-milk-adulteration-from-hyderabad-peddled-with-false-claims-9428",
        headline:
          "Video Of Milk Adulteration From Hyderabad Peddled With False Claims",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/fact-check-did-putins-daughter-die-after-taking-covid-19-vaccine-9437",
        headline:
          "Fact Check: Did Putin's Daughter Die After Taking COVID-19 Vaccine?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-maharani-radhikaraje-gaekwad-of-baroda-has-not-taken-up-diksha-9377",
        headline:
          "\u0928\u0939\u0940\u0902, \u092e\u0939\u093e\u0930\u093e\u0928\u0940 \u0930\u093e\u0927\u093f\u0915\u093e\u0930\u093e\u091c\u0947 \u0917\u093e\u092f\u0915\u0935\u093e\u095c \u0928\u0947 \u0926\u0940\u0915\u094d\u0937\u093e \u0928\u0939\u0940\u0902 \u0932\u0940 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/raam-bearer-bond-falsely-peddled-as-worlds-most-expensive-currency-9390",
        headline:
          "\u09ac\u09be\u09b9\u0995-\u09ac\u09a8\u09cd\u09a1 '\u09b0\u09be\u09ae'\u0995\u09c7 \u09ac\u09bf\u09b6\u09cd\u09ac\u09c7\u09b0 \u09b8\u09ac\u099a\u09c7\u09df\u09c7 \u09a6\u09be\u09ae\u09bf \u09ae\u09c1\u09a6\u09cd\u09b0\u09be \u09ac\u09b2\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u0995\u09b0\u09be \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-belongs-to-pancha-tattva-tradition-from-russia-in-2019/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u2018\u0c2a\u0c02\u0c1a\u0c24\u0c24\u0c4d\u0c24\u0c4d\u0c35 \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c3e\u0c32 \u0c2a\u0c4d\u0c30\u0c24\u0c3f\u0c37\u0c4d\u0c1f\u2019 \u0c35\u0c47\u0c21\u0c41\u0c15\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f 2019 \u0c32\u0c4b \u0c30\u0c37\u0c4d\u0c2f\u0c3e \u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/attack-on-two-men-is-falsely-shared-as-an-assault-on-haryana-mla/",
        headline:
          " Attack on two men is falsely shared as an assault on Haryana MLA",
      },
      {
        url:
          "https://factly.in/no-the-kidneys-were-not-robbed-by-this-mumbai-hospital-on-the-pretext-of-covid-19/",
        headline:
          " No, the kidneys were not robbed by this Mumbai hospital on the pretext of COVID-19",
      },
      {
        url:
          "https://factly.in/this-photo-does-not-show-people-following-bharatiya-culture-in-new-zealand/",
        headline:
          " This photo does not show people following Bharatiya culture in New Zealand",
      },
      {
        url:
          "https://factly.in/telugu-covid-19-does-not-spread-through-5g-network-bill-gates-dont-hold-its-vaccine-patent/",
        headline:
          " 5G \u0c2e\u0c4a\u0c2c\u0c48\u0c32\u0c4d \u0c28\u0c46\u0c1f\u0c4d\u0c35\u0c30\u0c4d\u0c15\u0c4d \u0c35\u0c32\u0c4d\u0c32 \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c35\u0c4d\u0c2f\u0c3e\u0c2a\u0c4d\u0c24\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c26\u0c41; \u0c2c\u0c3f\u0c32\u0c4d \u0c17\u0c47\u0c1f\u0c4d\u0c38\u0c4d \u0c26\u0c17\u0c4d\u0c17\u0c30 \u0c09\u0c28\u0c4d\u0c28 \u0c2a\u0c47\u0c1f\u0c46\u0c02\u0c1f\u0c4d \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c35\u0c4d\u0c2f\u0c3e\u0c2a\u0c4d\u0c24\u0c3f\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c15\u0c30\u0c4b\u0c28\u0c3e\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d \u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/video-of-a-man-adulterating-milk-in-hyderabad-is-shared-as-that-from-mumbai/",
        headline:
          " Video of a man adulterating milk in Hyderabad is shared as that from Mumbai",
      },
      {
        url:
          "https://factly.in/telugu-ysrcp-govt-has-not-performed-badly-compared-to-tdp-in-the-swach-survekshan-rankings/",
        headline:
          " \u2018\u0c38\u0c4d\u0c35\u0c1a\u0c4d\u0c1b \u0c38\u0c30\u0c4d\u0c35\u0c47\u0c15\u0c4d\u0c37\u0c23\u0c4d\u200c-2020\u2019 \u0c30\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c41\u0c32\u0c28\u0c41 \u0c2a\u0c3e\u0c24 \u0c30\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c41\u0c32\u0c24\u0c4b \u0c24\u0c2e\u0c15\u0c41 \u0c28\u0c1a\u0c4d\u0c1a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c2a\u0c4b\u0c32\u0c41\u0c38\u0c4d\u0c24\u0c42 \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c28\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c26\u0c4b\u0c35 \u0c2a\u0c1f\u0c4d\u0c1f\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/ap-swachh-survekshan-rankings-were-selectively-shared-to-mislead-people/",
        headline:
          " AP Swachh Survekshan Rankings were selectively shared to mislead people",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/18/fact-check-dont-fall-for-this-picture-of-a-man-holding-a-placard-questioning-mumbai-police-over-ssrs-death/",
        headline:
          "Fact Check: Don\u2019t fall for this picture of a man holding a placard questioning Mumbai Police over SSR\u2019s death",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/18/fact-check-is-kamala-harris-ineligible-to-serve-as-us-president-if-need-be-heres-the-truth/",
        headline:
          "Fact Check: Is Kamala Harris ineligible to serve as US President if need be? Here\u2019s the truth",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-more-than-one-year-old-picture-shared-as-recent-gaza-strip-bombing-by-israel-1712535-2020-08-18",
        headline:
          "Fact Check: Israel may still be pounding bombs over Gaza, but this massive blast is not recent",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-has-russia-gifted-1-million-doses-of-covid-vaccine-to-pakistan-1712655-2020-08-18",
        headline:
          "Fact Check: Has Russia gifted 1 million doses of Covid vaccine to Pakistan?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/bjp-mp-kerala-coronavirus-mother-funeral-fact-check-1712828-2020-08-19",
        headline:
          "Fact Check: Did BJP MP from Kerala conduct his mother's funeral without revealing she died of Covid-19?",
      },
    ],
  },
  numpy_seed: 6,
  number_of_articles: 124,
}
