export const data = {
  mdsDat: {
    x: [
      0.020550014686260012,
      0.34571132797730514,
      -0.15341040100102676,
      0.03214485270121665,
      -0.301627597097419,
      0.05663180273366405,
    ],
    y: [
      -0.27576365517318097,
      -0.006493097714719816,
      -0.1985153582494683,
      0.3163741507688959,
      0.11729654399209392,
      0.04710141637637915,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      14.044718212686618,
      15.303282421892892,
      14.782556762595286,
      14.469550275808576,
      14.780576583961954,
      26.61931574305466,
    ],
  },
  tinfo: {
    Term: [
      "bengal",
      "exam",
      "das",
      "west_bengal",
      "pm",
      "west",
      "pm_modi",
      "facebook",
      "modi",
      "indian",
      "find",
      "delhi",
      "hit",
      "group",
      "vaccine",
      "armed",
      "anywhere",
      "television",
      "election",
      "cow",
      "tsunami",
      "china",
      "japan",
      "story",
      "day",
      "ahmednagar",
      "mercury",
      "pathan",
      "say",
      "red",
      "narrative",
      "asteroid",
      "remove",
      "woman",
      "chant",
      "performance",
      "christianity",
      "terminal",
      "mount",
      "fatah",
      "taiba",
      "uproot",
      "khan",
      "aamir",
      "aamir_khan",
      "mishra",
      "court",
      "kapil",
      "kapil_mishra",
      "terrorist",
      "jayate",
      "change",
      "ranaut",
      "interview",
      "islam",
      "police",
      "kangana",
      "cola",
      "bounce",
      "plasma",
      "ed",
      "boycott",
      "message",
      "distribute",
      "bow",
      "ranveer",
      "actor",
      "exam",
      "student",
      "comedk",
      "positive",
      "drink",
      "screenshot",
      "test",
      "bhatt",
      "ibrahim",
      "chakraborty",
      "dawood",
      "death",
      "people",
      "rhea",
      "covid",
      "corona",
      "idol",
      "projection",
      "project",
      "body",
      "rasbhari",
      "imf",
      "floodwater",
      "pilot",
      "pollution",
      "airs",
      "gdp",
      "galwan",
      "galwan_valley",
      "growth",
      "soldier",
      "valley",
      "chakra",
      "gunjan",
      "model",
      "saxena",
      "chaturthi",
      "estimate",
      "show",
      "indore",
      "shaurya",
      "awardee",
      "indian",
      "pakistan",
      "man",
      "ambani",
      "come",
      "lakh",
      "kerala",
      "flyover",
      "worker",
      "worth",
      "ankhi",
      "cove",
      "offer",
      "das",
      "facebook",
      "cake",
      "ankhi_das",
      "cut",
      "gurugram",
      "collapse",
      "patient",
      "indian",
      "leopard",
      "truck",
      "slogans",
      "ayodhya",
      "rahul",
      "anti",
      "hold",
      "personal",
      "raise",
      "mock",
      "bengal",
      "west",
      "pm",
      "west_bengal",
      "pm_modi",
      "protest",
      "duck",
      "protest_bangladesh",
      "mandir",
      "ram",
      "pay",
      "modi",
      "bangladesh",
      "attack",
      "photos",
    ],
    Freq: [
      10.0,
      7.0,
      7.0,
      9.0,
      9.0,
      9.0,
      8.0,
      6.0,
      15.0,
      8.0,
      2.9997002630379925,
      2.9997002630379925,
      2.9997002630379925,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      3.9996003507173237,
      3.9996003507173237,
      3.9996003507173237,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      2.9997002630379925,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      0.9999542075675217,
      1.9999084151350435,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      1.9999084151350435,
      0.9999542075675217,
      4.999771037837609,
      4.999771037837609,
      4.999771037837609,
      3.999816830270087,
      2.9998626227025653,
      2.9998626227025653,
      2.9998626227025653,
      2.9998626227025653,
      1.9999084151350435,
      1.9999084151350435,
      1.9999084151350435,
      1.9999084151350435,
      2.9998626227025653,
      1.9999084151350435,
      1.9999084151350435,
      1.0001815001075107,
      2.0003630002150214,
      1.0001815001075107,
      2.0003630002150214,
      1.0001815001075107,
      2.0003630002150214,
      1.0001815001075107,
      1.0001815001075107,
      1.0001815001075107,
      1.0001815001075107,
      7.001270500752576,
      4.000726000430043,
      3.0005445003225324,
      3.0005445003225324,
      3.0005445003225324,
      3.0005445003225324,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      4.000726000430043,
      2.0003630002150214,
      4.000726000430043,
      3.0005445003225324,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      3.0008589050263876,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      4.0011452067018505,
      3.0008589050263876,
      3.0008589050263876,
      3.0008589050263876,
      3.0008589050263876,
      3.0008589050263876,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      6.001717810052775,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      4.0011452067018505,
      3.0008589050263876,
      3.0008589050263876,
      1.000047522063809,
      1.000047522063809,
      2.000095044127618,
      1.000047522063809,
      4.000190088255236,
      2.000095044127618,
      1.000047522063809,
      4.000190088255236,
      1.000047522063809,
      1.000047522063809,
      7.000332654446662,
      6.000285132382853,
      5.000237610319045,
      4.000190088255236,
      4.000190088255236,
      4.000190088255236,
      4.000190088255236,
      3.0001425661914265,
      4.000190088255236,
      1.9995926943995388,
      2.9993890415993083,
      0.9997963471997694,
      1.9995926943995388,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      10.997759819197464,
      9.997963471997695,
      9.997963471997695,
      9.997963471997695,
      8.998167124797925,
      6.998574430398386,
      3.9991853887990776,
      3.9991853887990776,
      2.9993890415993083,
      2.9993890415993083,
      2.9993890415993083,
      11.997556166397233,
      5.998778083198617,
      3.9991853887990776,
      3.9991853887990776,
    ],
    Total: [
      10.0,
      7.0,
      7.0,
      9.0,
      9.0,
      9.0,
      8.0,
      6.0,
      15.0,
      8.0,
      2.9997002630379925,
      2.9997002630379925,
      2.9997002630379925,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      0.9999000876793309,
      3.9996003507173237,
      3.9996003507173237,
      3.9996003507173237,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      4.9992929574375315,
      1.9998001753586618,
      1.9998001753586618,
      1.9998001753586618,
      0.9999542075675217,
      1.9999084151350435,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      0.9999542075675217,
      1.9999084151350435,
      0.9999542075675217,
      4.999771037837609,
      4.999771037837609,
      4.999771037837609,
      3.999816830270087,
      2.9998626227025653,
      2.9998626227025653,
      2.9998626227025653,
      2.9998626227025653,
      1.9999084151350435,
      1.9999084151350435,
      1.9999084151350435,
      1.9999084151350435,
      3.9996589699023346,
      1.9999084151350435,
      1.9999084151350435,
      1.0001815001075107,
      2.0003630002150214,
      1.0001815001075107,
      2.0003630002150214,
      1.0001815001075107,
      2.0003630002150214,
      1.0001815001075107,
      1.0001815001075107,
      1.0001815001075107,
      1.0001815001075107,
      7.001270500752576,
      4.000726000430043,
      3.0005445003225324,
      3.0005445003225324,
      3.0005445003225324,
      3.0005445003225324,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      2.0003630002150214,
      5.000680207997565,
      2.0003630002150214,
      8.0004137389572,
      6.000392197745004,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      3.0008589050263876,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      1.0002863016754626,
      4.0011452067018505,
      3.0008589050263876,
      3.0008589050263876,
      3.0008589050263876,
      3.0008589050263876,
      3.0008589050263876,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      10.001164799699167,
      2.0005726033509252,
      2.0005726033509252,
      2.0005726033509252,
      8.001335294957087,
      5.001221905241409,
      7.000202154193218,
      1.000047522063809,
      1.000047522063809,
      2.000095044127618,
      1.000047522063809,
      4.000190088255236,
      2.000095044127618,
      1.000047522063809,
      4.000190088255236,
      1.000047522063809,
      1.000047522063809,
      7.000332654446662,
      6.000285132382853,
      5.000237610319045,
      4.000190088255236,
      4.000190088255236,
      4.000190088255236,
      4.000190088255236,
      3.0001425661914265,
      8.001335294957087,
      1.9995926943995388,
      2.9993890415993083,
      0.9997963471997694,
      1.9995926943995388,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      0.9997963471997694,
      10.997759819197464,
      9.997963471997695,
      9.997963471997695,
      9.997963471997695,
      8.998167124797925,
      6.998574430398386,
      3.9991853887990776,
      3.9991853887990776,
      2.9993890415993083,
      2.9993890415993083,
      2.9993890415993083,
      15.997719341970917,
      6.998959583306127,
      4.99947169047454,
      4.999232910862887,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.7992,
      -3.7992,
      -3.7992,
      -4.8978,
      -4.8978,
      -4.8978,
      -4.8978,
      -4.8978,
      -4.8978,
      -4.8978,
      -3.5115,
      -3.5115,
      -3.5115,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.2047,
      -3.7992,
      -4.2047,
      -4.2047,
      -4.2047,
      -4.9836,
      -4.2905,
      -4.9836,
      -4.9836,
      -4.9836,
      -4.9836,
      -4.9836,
      -4.9836,
      -4.2905,
      -4.9836,
      -3.3742,
      -3.3742,
      -3.3742,
      -3.5973,
      -3.885,
      -3.885,
      -3.885,
      -3.885,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -3.885,
      -4.2905,
      -4.2905,
      -4.9488,
      -4.2556,
      -4.9488,
      -4.2556,
      -4.9488,
      -4.2556,
      -4.9488,
      -4.9488,
      -4.9488,
      -4.9488,
      -3.0028,
      -3.5625,
      -3.8501,
      -3.8501,
      -3.8501,
      -3.8501,
      -4.2556,
      -4.2556,
      -4.2556,
      -4.2556,
      -4.2556,
      -4.2556,
      -3.5625,
      -4.2556,
      -3.5625,
      -3.8501,
      -4.9273,
      -4.9273,
      -4.9273,
      -4.9273,
      -4.9273,
      -3.8286,
      -4.9273,
      -4.9273,
      -4.9273,
      -4.9273,
      -3.541,
      -3.8286,
      -3.8286,
      -3.8286,
      -3.8286,
      -3.8286,
      -4.2341,
      -4.2341,
      -4.2341,
      -4.2341,
      -4.2341,
      -4.2341,
      -3.1355,
      -4.2341,
      -4.2341,
      -4.2341,
      -3.541,
      -3.8286,
      -3.8286,
      -4.9488,
      -4.9488,
      -4.2556,
      -4.9488,
      -3.5625,
      -4.2556,
      -4.9488,
      -3.5625,
      -4.9488,
      -4.9488,
      -3.0028,
      -3.157,
      -3.3393,
      -3.5625,
      -3.5625,
      -3.5625,
      -3.5625,
      -3.8501,
      -3.5625,
      -4.8442,
      -4.4387,
      -5.5373,
      -4.8442,
      -5.5373,
      -5.5373,
      -5.5373,
      -5.5373,
      -5.5373,
      -5.5373,
      -3.1394,
      -3.2347,
      -3.2347,
      -3.2347,
      -3.3401,
      -3.5914,
      -4.151,
      -4.151,
      -4.4387,
      -4.4387,
      -4.4387,
      -3.0524,
      -3.7456,
      -4.151,
      -4.151,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.9629,
      1.4521,
      1.9629,
      1.9629,
      1.9629,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.8771,
      1.5895,
      1.8771,
      1.8771,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.9117,
      1.6886,
      1.9117,
      1.2187,
      1.2187,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.9331,
      1.4225,
      1.9331,
      1.9331,
      1.9331,
      1.2401,
      1.4223,
      1.0861,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.9119,
      1.2186,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.3235,
      1.0358,
      1.1693,
      1.1003,
      1.1003,
    ],
  },
  "token.table": {
    Topic: [
      2,
      2,
      3,
      1,
      4,
      5,
      5,
      5,
      6,
      1,
      1,
      1,
      4,
      6,
      4,
      6,
      3,
      6,
      6,
      3,
      4,
      3,
      3,
      3,
      5,
      4,
      3,
      2,
      2,
      4,
      1,
      2,
      3,
      5,
      5,
      3,
      1,
      3,
      5,
      2,
      5,
      3,
      5,
      6,
      1,
      5,
      5,
      3,
      1,
      3,
      1,
      3,
      3,
      6,
      3,
      1,
      4,
      3,
      5,
      2,
      1,
      4,
      5,
      4,
      4,
      4,
      1,
      4,
      4,
      5,
      1,
      6,
      3,
      4,
      4,
      4,
      5,
      4,
      2,
      2,
      6,
      1,
      2,
      2,
      2,
      2,
      5,
      2,
      5,
      6,
      2,
      4,
      6,
      6,
      1,
      3,
      2,
      6,
      4,
      1,
      3,
      6,
      2,
      1,
      5,
      3,
      4,
      1,
      5,
      6,
      2,
      3,
      2,
      6,
      5,
      6,
      4,
      3,
      6,
      6,
      2,
      4,
      3,
      4,
      4,
      6,
      6,
      6,
      6,
      6,
      2,
      3,
      4,
      1,
      2,
      3,
      4,
      1,
      6,
      3,
      4,
      1,
      2,
      4,
      6,
      6,
      4,
      1,
      3,
      2,
      1,
      2,
      2,
      3,
      6,
      1,
      2,
      1,
      4,
      6,
      6,
      2,
      5,
      5,
    ],
    Freq: [
      1.0000457945295211,
      1.0000457945295211,
      0.9998185328288004,
      1.0000999223041385,
      0.9997137802697257,
      0.9999524801944303,
      0.9999524801944303,
      0.9999524801944303,
      1.0002036942831418,
      1.0000999223041385,
      1.0000999223041385,
      1.0000999223041385,
      0.20002113461414198,
      0.8000845384565679,
      0.9997137802697257,
      1.0002036942831418,
      0.14287837900724465,
      0.857270274043468,
      1.0002036942831416,
      0.9998185328288004,
      0.9997137802697257,
      0.9998185328288004,
      0.9998185328288004,
      0.9998185328288004,
      0.9999524801944302,
      0.9997137802697257,
      0.9998185328288004,
      1.0000457945295211,
      1.0000457945295211,
      0.9997137802697257,
      1.0000999223041385,
      1.0000457945295211,
      0.9998185328288004,
      0.9999524801944303,
      0.9999524801944303,
      0.9998185328288003,
      0.3333115459938796,
      0.49996731899081936,
      0.1666557729969398,
      1.0000457945295211,
      0.9999524801944303,
      0.49997414265244905,
      0.24998707132622452,
      0.24998707132622452,
      1.0000999223041385,
      0.9999524801944303,
      0.9999524801944303,
      0.9998185328288004,
      1.0000999223041385,
      0.9998185328288004,
      1.0000999223041385,
      0.9998185328288004,
      0.9998185328288003,
      1.0002036942831418,
      0.9998185328288004,
      1.0000999223041385,
      0.9997137802697257,
      0.9998185328288002,
      0.9999524801944304,
      1.0000457945295211,
      1.0000999223041385,
      0.9997137802697257,
      0.9999524801944303,
      0.9997137802697258,
      0.9997137802697258,
      0.9997137802697257,
      1.0000999223041385,
      0.9997137802697258,
      0.9997137802697257,
      0.9999524801944303,
      1.0000999223041385,
      1.0002036942831418,
      0.9998185328288004,
      0.9997137802697257,
      0.9997137802697258,
      0.4999165579926435,
      0.4999165579926435,
      0.9997137802697257,
      1.0000457945295211,
      0.7500639485954111,
      0.2500213161984704,
      1.0000999223041385,
      1.0000457945295211,
      1.0000457945295211,
      1.0000457945295211,
      1.0000457945295211,
      0.9999524801944303,
      1.0000457945295211,
      0.9999524801944303,
      1.0002036942831418,
      0.14285301738050324,
      0.42855905214150974,
      0.42855905214150974,
      1.0002036942831418,
      1.0000999223041385,
      0.9998185328288004,
      1.0000457945295211,
      1.0002036942831418,
      0.9997137802697257,
      0.1250178201809609,
      0.1250178201809609,
      0.7501069210857653,
      1.0000457945295211,
      1.0000999223041385,
      0.9999524801944303,
      0.39990227146368945,
      0.5998534071955341,
      1.0000999223041385,
      0.9999524801944304,
      1.0002036942831418,
      0.19997279538105728,
      0.7998911815242291,
      1.0000457945295211,
      1.0002036942831418,
      0.2000306882736128,
      0.8001227530944512,
      0.9997137802697257,
      0.9998185328288004,
      1.0002036942831416,
      1.0002036942831416,
      1.0000457945295211,
      0.9997137802697257,
      0.9998185328288003,
      0.9997137802697257,
      0.9997137802697257,
      1.0002036942831418,
      1.0002036942831418,
      1.0002036942831418,
      1.0002036942831418,
      1.0002036942831418,
      1.0000457945295211,
      0.9998185328288004,
      0.9997137802697257,
      1.0000999223041385,
      1.0000457945295211,
      0.9998185328288004,
      0.9997137802697257,
      0.6000848571070135,
      0.40005657140467565,
      0.9998185328288003,
      0.9997137802697257,
      0.09998835335960865,
      0.09998835335960865,
      0.5999301201576519,
      0.1999767067192173,
      1.0002036942831418,
      0.9997137802697258,
      1.0000999223041385,
      0.9998185328288004,
      1.0000457945295211,
      1.0000999223041385,
      1.0000457945295211,
      1.0000457945295211,
      0.9998185328288004,
      1.0002036942831418,
      1.0000999223041385,
      1.0000457945295211,
      1.0000999223041385,
      0.9997137802697258,
      1.0002036942831416,
      1.0002036942831416,
      1.0000457945295211,
      0.9999524801944303,
      0.9999524801944303,
    ],
    Term: [
      "aamir",
      "aamir_khan",
      "actor",
      "ahmednagar",
      "airs",
      "ambani",
      "ankhi",
      "ankhi_das",
      "anti",
      "anywhere",
      "armed",
      "asteroid",
      "attack",
      "attack",
      "awardee",
      "ayodhya",
      "bangladesh",
      "bangladesh",
      "bengal",
      "bhatt",
      "body",
      "bounce",
      "bow",
      "boycott",
      "cake",
      "chakra",
      "chakraborty",
      "change",
      "chant",
      "chaturthi",
      "china",
      "christianity",
      "cola",
      "collapse",
      "come",
      "comedk",
      "corona",
      "corona",
      "corona",
      "court",
      "cove",
      "covid",
      "covid",
      "covid",
      "cow",
      "cut",
      "das",
      "dawood",
      "day",
      "death",
      "delhi",
      "distribute",
      "drink",
      "duck",
      "ed",
      "election",
      "estimate",
      "exam",
      "facebook",
      "fatah",
      "find",
      "floodwater",
      "flyover",
      "galwan",
      "galwan_valley",
      "gdp",
      "group",
      "growth",
      "gunjan",
      "gurugram",
      "hit",
      "hold",
      "ibrahim",
      "idol",
      "imf",
      "indian",
      "indian",
      "indore",
      "interview",
      "islam",
      "islam",
      "japan",
      "jayate",
      "kangana",
      "kapil",
      "kapil_mishra",
      "kerala",
      "khan",
      "lakh",
      "leopard",
      "man",
      "man",
      "man",
      "mandir",
      "mercury",
      "message",
      "mishra",
      "mock",
      "model",
      "modi",
      "modi",
      "modi",
      "mount",
      "narrative",
      "offer",
      "pakistan",
      "pakistan",
      "pathan",
      "patient",
      "pay",
      "people",
      "people",
      "performance",
      "personal",
      "photos",
      "photos",
      "pilot",
      "plasma",
      "pm",
      "pm_modi",
      "police",
      "pollution",
      "positive",
      "project",
      "projection",
      "protest",
      "protest_bangladesh",
      "rahul",
      "raise",
      "ram",
      "ranaut",
      "ranveer",
      "rasbhari",
      "red",
      "remove",
      "rhea",
      "saxena",
      "say",
      "say",
      "screenshot",
      "shaurya",
      "show",
      "show",
      "show",
      "show",
      "slogans",
      "soldier",
      "story",
      "student",
      "taiba",
      "television",
      "terminal",
      "terrorist",
      "test",
      "truck",
      "tsunami",
      "uproot",
      "vaccine",
      "valley",
      "west",
      "west_bengal",
      "woman",
      "worker",
      "worth",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/video-of-delhi-cop-beating-minor-is-not-an-old-video-of-armed-chain-snatcher/",
        headline:
          "Video of Delhi cop beating minor is not an old video of armed chain snatcher",
      },
      {
        url:
          "https://www.altnews.in/hindi/the-link-between-misinformation-and-radicalisation-revisiting-a-delhi-riots-hate-speech-video/",
        headline:
          "\u095a\u0932\u0924 \u091c\u093e\u0928\u0915\u093e\u0930\u0940 \u0914\u0930 \u0915\u091f\u094d\u091f\u0930\u092a\u0902\u0925 \u0915\u0940 \u091c\u094b\u095c\u0940 \u2013 \u0938\u092e\u091d\u0947\u0902 \u0926\u093f\u0932\u094d\u0932\u0940 \u0926\u0902\u0917\u094b\u0902 \u0915\u0947 \u092d\u095c\u0915\u093e\u090a \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0915\u0939\u0940 \u092c\u093e\u0924\u094b\u0902 \u0915\u0947 \u095b\u0930\u093f\u092f\u0947",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/story-of-bababhai-pathan-from-ahmednagar-shared-with-false-claims-9477",
        headline:
          "Story Of Bababhai Pathan From Ahmednagar Shared With False Claims",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2011-video-of-tsunami-in-japan-passed-off-as-china-9530",
        headline: "2011 Video Of Tsunami In Japan Passed Off As China",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-of-detention-camp-in-texas-usa-shared-as-assam-9476",
        headline:
          "\u0986\u09ae\u09c7\u09b0\u09bf\u0995\u09be\u09b0 \u099f\u09c7\u0995\u09cd\u09b8\u09be\u09b8\u09c7\u09b0 \u09a1\u09bf\u099f\u09c7\u09a8\u09b6\u09a8 \u0995\u09cd\u09af\u09be\u09ae\u09cd\u09aa\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u0985\u09b8\u09ae\u09c7\u09b0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/story-of-bababhai-pathan-from-ahmednagar-shared-with-false-claims-9489",
        headline:
          "\u0986\u09b9\u09ae\u09c7\u09a6\u09a8\u0997\u09b0\u09c7\u09b0 \u09ac\u09be\u09ac\u09be\u09ad\u09be\u0987 \u09aa\u09be\u09a0\u09be\u09a8\u09c7\u09b0 \u0997\u09b2\u09cd\u09aa\u099f\u09bf \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09b6\u09c7\u09df\u09be\u09b0 \u0995\u09b0\u09be \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-photos-of-modi-with-ducks-viral-as-pr-stunt-during-pandemic-9516",
        headline:
          "\u0985\u09a4\u09bf\u09ae\u09be\u09b0\u09bf\u09b0 \u09b8\u09ae\u09df \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u09aa\u09cd\u09b0\u099a\u09be\u09b0 \u09ac\u09b2\u09c7 \u09b9\u09be\u0981\u09b8\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/a-fight-over-ganesh-statue-installation-in-hyderabad-is-shared-with-a-false-communal-narrative/",
        headline:
          " A fight over Ganesh statue installation in Hyderabad is shared with a false communal narrative",
      },
      {
        url:
          "https://factly.in/telugu-serum-institute-did-not-say-that-covishield-will-be-available-in-the-market-in-73-days/",
        headline:
          " 73 \u0c30\u0c4b\u0c1c\u0c41\u0c32\u0c4d\u0c32\u0c4b \u2018\u0c15\u0c4b\u0c35\u0c3f\u0c37\u0c40\u0c32\u0c4d\u0c21\u0c4d\u2019 \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c35\u0c4d\u0c2f\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d \u0c2e\u0c3e\u0c30\u0c4d\u0c15\u0c46\u0c1f\u0c4d\u0c32\u0c4b \u0c2b\u0c4d\u0c30\u0c40\u0c17\u0c3e \u0c05\u0c02\u0c26\u0c41\u0c2c\u0c3e\u0c1f\u0c41\u0c32\u0c4b\u0c15\u0c3f \u0c35\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c28\u0c3f \u2018Serum Institute of India\u2019 \u0c2a\u0c4d\u0c30\u0c24\u0c3f\u0c28\u0c3f\u0c27\u0c41\u0c32\u0c41 \u0c24\u0c46\u0c32\u0c41\u0c2a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-the-coins-these-photos-are-not-unearthed-in-indonesia-excavations/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4d\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c28\u0c3e\u0c23\u0c47\u0c32\u0c41 \u0c07\u0c02\u0c21\u0c4b\u0c28\u0c47\u0c37\u0c3f\u0c2f\u0c3e\u0c32\u0c4b \u0c26\u0c4a\u0c30\u0c15\u0c32\u0c47\u0c26\u0c41; \u0c2d\u0c3e\u0c30\u0c24\u0c4d \u0c32\u0c4b \u0c35\u0c3f\u0c35\u0c3f\u0c27 \u0c38\u0c02\u0c26\u0c30\u0c4d\u0c2d\u0c3e\u0c32\u0c4d\u0c32\u0c4b \u0c26\u0c4a\u0c30\u0c3f\u0c15\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://factly.in/video-of-a-group-of-cows-swept-away-in-floodwaters-is-from-mexico-not-india/",
        headline:
          " Video of a group of cows swept away in floodwaters is from Mexico, not India",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-evidence-for-the-existence-of-red-mercury-its-a-myth/",
        headline:
          " \u2018\u0c30\u0c46\u0c21\u0c4d \u0c2e\u0c46\u0c30\u0c4d\u0c15\u0c4d\u0c2f\u0c41\u0c30\u0c40\u2019 \u0c05\u0c28\u0c47\u0c26\u0c3f \u0c12\u0c15\u0c1f\u0c3f \u0c09\u0c02\u0c26\u0c28\u0c3f \u0c0e\u0c15\u0c4d\u0c15\u0c21\u0c3e \u0c15\u0c42\u0c21\u0c3e \u0c38\u0c2e\u0c3e\u0c1a\u0c3e\u0c30\u0c02 \u0c32\u0c47\u0c26\u0c41. \u0c05\u0c26\u0c3f \u0c12\u0c15 \u0c17\u0c3e\u0c32\u0c3f \u0c35\u0c3e\u0c30\u0c4d\u0c24",
      },
      {
        url:
          "https://factly.in/red-mercury-is-a-myth-it-is-not-found-in-old-televisions-and-radios/",
        headline:
          " \u2018Red Mercury\u2019 is a myth. It is not found in old televisions and radios",
      },
      {
        url:
          "https://factly.in/old-and-unrelated-images-recirculated-with-a-false-narrative-of-robbery/",
        headline:
          " Old and unrelated images recirculated with a false narrative of Robbery",
      },
      {
        url:
          "https://factly.in/telugu-asteroid-2018vp1-is-very-small-and-poses-no-threat-to-earth/",
        headline:
          " 02 \u0c28\u0c35\u0c02\u0c2c\u0c30\u0c4d 2020\u0c28 2018 VP1 \u0c17\u0c4d\u0c30\u0c39\u0c36\u0c15\u0c32\u0c02 \u0c2d\u0c42\u0c2e\u0c3f \u0c35\u0c48\u0c2a\u0c41 \u0c35\u0c1a\u0c4d\u0c1a\u0c3f\u0c28\u0c3e \u0c2a\u0c4d\u0c30\u0c33\u0c2f\u0c02 \u0c30\u0c3e\u0c26\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c3f\u0c28 \u0c28\u0c3e\u0c38\u0c3e",
      },
      {
        url:
          "https://factly.in/2011-video-of-tsunami-devastation-in-japan-is-falsely-portrayed-as-the-floods-in-china/",
        headline:
          " 2011 video of Tsunami devastation in Japan is falsely portrayed as the floods in China",
      },
      {
        url:
          "https://factly.in/telugu-2011-japan-tsunami-video-is-shared-as-that-from-china/",
        headline:
          " 2011\u0c32\u0c4b \u0c1c\u0c2a\u0c3e\u0c28\u0c4d \u0c26\u0c47\u0c36\u0c02 \u0c2a\u0c48 \u0c35\u0c3f\u0c30\u0c41\u0c1a\u0c41\u0c15\u0c41\u0c2a\u0c21\u0c4d\u0c21 \u2018\u0c38\u0c41\u0c28\u0c3e\u0c2e\u0c3f\u2019 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f  \u0c35\u0c30\u0c26\u0c32\u0c24\u0c4b \u0c28\u0c3f\u0c02\u0c21\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c1a\u0c48\u0c28\u0c3e \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-photos-of-modi-shared-as-the-ones-taken-during-covid/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c1f\u0c48\u0c02\u0c32\u0c4b \u0c2e\u0c4b\u0c26\u0c40 \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c15\u0c4b\u0c38\u0c02 \u0c2b\u0c4b\u0c1f\u0c4b\u0c37\u0c42\u0c1f\u0c4d \u0c32\u0c4b \u0c2a\u0c3e\u0c32\u0c4d\u0c17\u0c4a\u0c28\u0c4d\u0c28\u0c3e\u0c21\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-video-of-2011-tsunami-that-hit-japan-falsely-shared-as-that-of-recent-china-floods/",
        headline:
          "Fact Check: Video of 2011 Tsunami that hit Japan falsely shared as that of recent China floods",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-old-picture-from-delhi-falsely-shared-as-that-of-varanasi/",
        headline:
          "Fact Check: Old picture from Delhi falsely shared as that of Varanasi",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-warn-of-asteroid-hit-a-day-before-us-elections-1715198-2020-08-26",
        headline:
          "Fact Check: Netizens warn of asteroid hit a day before US elections",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-unmasking-misleading-claims-around-kamala-s-us-presidential-poll-campaign-1715444-2020-08-26",
        headline:
          "Fact Check: Unmasking misleading claims around Kamala's US presidential poll campaign",
      },
    ],
    "2": [
      {
        url:
          "https://www.altnews.in/hindi/video-of-delhi-cop-beating-minor-is-not-an-old-video-of-armed-chain-snatcher/",
        headline:
          "\u0915\u092a\u093f\u0932 \u092e\u093f\u0936\u094d\u0930\u093e \u0938\u092e\u0947\u0924 \u0932\u094b\u0917\u094b\u0902 \u0928\u0947 \u092c\u0930\u0916\u093e \u0926\u0924\u094d\u0924 \u092a\u0930 \u2018\u095e\u0947\u0915 \u0928\u094d\u092f\u0942\u095b\u2019 \u0915\u0947 \u0906\u0930\u094b\u092a \u0932\u0917\u093e\u090f, \u092a\u0941\u0932\u093f\u0938 \u0928\u0947 \u092e\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u0941\u0930\u093e\u0928\u093e \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://www.altnews.in/hindi/tarek-fatah-twitted-a-three-year-old-video-from-bangladesh-as-kolkata-later-deletes-the-tweet/",
        headline:
          "\u0924\u093e\u0930\u093f\u0915 \u095e\u0924\u0939 \u0928\u0947 \u0922\u093e\u0915\u093e \u0915\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u094b \u0915\u094b\u0932\u0915\u093e\u0924\u093e \u0915\u093e \u092c\u0924\u093e\u092f\u093e, \u092a\u0941\u0932\u093f\u0938 \u0928\u0947 \u091c\u0935\u093e\u092c \u0926\u093f\u092f\u093e \u0924\u094b \u091f\u094d\u0935\u0940\u091f \u0921\u093f\u0932\u0940\u091f \u0915\u0930 \u092e\u093e\u0902\u0917\u0940 \u092e\u093e\u095e\u0940",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-photo-does-not-show-aamir-khan-with-lashkar-e-taiba-terrorists-9450",
        headline:
          "No, This Photo Does Not Show Aamir Khan With Lashkar-E-Taiba Terrorists",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/circular-refusing-a-farewell-for-retiring-sc-judge-arun-mishra-is-fake-9496",
        headline:
          "Circular Refusing A Farewell For Retiring SC Judge Arun Mishra Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2016-photo-of-inter-faith-couple-falsely-linked-to-kapil-mishras-sister-9546",
        headline:
          "2016 Photo Of Inter-Faith Couple Falsely Linked To Kapil Mishra's Sister",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/kangana-ranaut-shares-fake-aamir-khan-interview-on-islam-9490",
        headline:
          "\u0915\u0902\u0917\u0928\u093e \u0930\u0928\u094c\u0924 \u0928\u0947 \u0907\u0938\u094d\u0932\u093e\u092e \u092a\u0930 \u0906\u092e\u093f\u0930 \u0916\u093e\u0928 \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u0907\u0902\u091f\u0930\u0935\u094d\u092f\u0942 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2016-photo-of-inter-faith-couple-falsely-linked-to-kapil-mishras-sister-9553",
        headline:
          "\u0928\u0939\u0940\u0902, \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u0940 \u092e\u0939\u093f\u0932\u093e \u092d\u093e\u091c\u092a\u093e \u0928\u0947\u0924\u093e \u0915\u092a\u093f\u0932 \u092e\u093f\u0936\u094d\u0930\u093e \u0915\u0940 \u092c\u0939\u0928 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/kangana-ranaut-shares-fake-aamir-khan-interview-on-islam-9445",
        headline:
          "\u0987\u09b8\u09b2\u09be\u09ae \u09a8\u09bf\u09df\u09c7 \u0986\u09ae\u09bf\u09b0 \u0996\u09be\u09a8\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u09b8\u09be\u0995\u09cd\u09b7\u09be\u09ce\u0995\u09be\u09b0 \u09b6\u09c7\u09df\u09be\u09b0 \u0995\u09b0\u09b2\u09c7\u09a8 \u0995\u0999\u09cd\u0997\u09a8\u09be \u09b0\u09be\u09a8\u09be\u0989\u09a4",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-photo-does-not-show-aamir-khan-with-lashkar-e-taiba-terrorists-9452",
        headline:
          "\u0986\u09ae\u09bf\u09b0 \u0996\u09be\u09a8\u09c7\u09b0 \u098f\u0987 \u099b\u09ac\u09bf\u099f\u09bf \u09b2\u09b8\u09cd\u0995\u09b0-\u0987-\u09a4\u09c8\u09ac\u09be \u099c\u0999\u09cd\u0997\u09bf\u09a6\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09a8\u09df",
      },
      {
        url:
          "https://factly.in/alambagh-bus-terminal-image-is-falsely-shared-as-that-built-by-mim-party-in-hyderabad/",
        headline:
          " Alambagh bus terminal image is falsely shared as that built by MIM party in Hyderabad",
      },
      {
        url:
          "https://factly.in/supreme-court-of-india-has-not-changed-its-moto-from-satyameva-jayate-to-yato-dharmastato-jayah/",
        headline:
          " Supreme court of India has not changed its moto from \u2018Satyameva Jayate\u2019 to \u2018Yato Dharmastato Jayah\u2019",
      },
      {
        url:
          "https://factly.in/this-tweet-of-prashant-bhushans-fight-on-bjp-agents-in-supreme-court-is-fake/",
        headline:
          " This tweet of Prashant Bhushan\u2019s fight against BJP agents in Supreme Court is Fake",
      },
      {
        url:
          "https://factly.in/telugu-the-court-did-not-say-that-those-who-convert-to-christianity-should-remove-shastri-reddy-from-their-names/",
        headline:
          " \u0c15\u0c4d\u0c30\u0c48\u0c38\u0c4d\u0c24\u0c35 \u0c2e\u0c24\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c2e\u0c3e\u0c30\u0c3f\u0c28 \u0c39\u0c3f\u0c02\u0c26\u0c41\u0c35\u0c41\u0c32\u0c41 \u0c24\u0c2e \u0c2a\u0c47\u0c30\u0c41 \u0c28\u0c41\u0c02\u0c21\u0c3f \u0c36\u0c3e\u0c38\u0c4d\u0c24\u0c4d\u0c30\u0c3f, \u0c30\u0c46\u0c21\u0c4d\u0c21\u0c3f \u0c2e\u0c4a\u0c26\u0c32\u0c48\u0c28\u0c35\u0c3f \u0c24\u0c4a\u0c32\u0c17\u0c3f\u0c02\u0c1a\u0c3e\u0c32\u0c28\u0c3f \u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41 \u0c24\u0c40\u0c30\u0c4d\u0c2a\u0c41 \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/no-the-value-of-one-rupee-in-1917-was-not-equal-to-13-usd/",
        headline: " No, the value of one rupee in 1917 was not equal to 13 USD",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-did-aamir-khan-meet-pakistani-terrorists-here-is-the-truth-1714898-2020-08-25",
        headline:
          "Fact Check: Did Aamir Khan meet Pakistani terrorists? Here is the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/24/were-the-fireworks-for-tokyo-olympics-2020-recently-showcased-at-mount-fuji-heres-the-fact-check/",
        headline:
          "Were the fireworks for Tokyo Olympics 2020 recently showcased at Mount Fuji? Here\u2019s the fact check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/25/fact-check-video-of-pakistani-men-uprooting-saplings-shared-with-false-claim/",
        headline:
          "Fact Check: Video of Pakistani men uprooting saplings shared with false claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/25/fact-check-false-claim-that-sc-motto-has-changed-from-satyamev-jayate-to-yato-dharmastato-jai/",
        headline:
          "Fact Check: False claim that SC motto has changed from \u2018Satyamev Jayate\u2019 to \u2018Yato Dharmastato Jai\u2019",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/malmo-women-not-injured-sweden-riots-1716745-2020-08-30",
        headline:
          "Fact Check: These women were not injured in the Sweden riots",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-viral-video-of-muslims-chanting-islam-zindabad-is-not-from-kolkata-1716318-2020-08-28",
        headline:
          "Fact Check: This viral video of Muslims chanting 'Islam Zindabad' is not from Kolkata",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-who-was-this-terrorist-captured-at-a-karnataka-bus-stand-1715897-2020-08-27",
        headline:
          "Fact Check: Who was this 'terrorist' captured at a Karnataka bus stand?",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/old-video-from-pakistan-shared-as-a-student-tested-positive-after-up-bed-exam-in-august/",
        headline:
          "Old video from Pakistan shared as UP student tests COVID positive after B.Ed exam",
      },
      {
        url:
          "https://www.altnews.in/fake-news18-screenshot-shared-to-claim-57-covid-positive-cases-after-comedk-exam-in-karnataka/",
        headline:
          "Fake News18 screenshot shared to claim 57 COVID deaths after COMEDK exam in Karnataka",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-from-pakistan-shared-as-a-student-tested-positive-after-up-bed-exam-in-august/",
        headline:
          "\u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b UP \u0915\u0940 B.Ed. \u092a\u0930\u0940\u0915\u094d\u0937\u093e \u0938\u0947 \u0915\u094b\u0930\u094b\u0928\u093e \u092a\u0949\u095b\u093f\u091f\u093f\u0935 \u0939\u0941\u090f \u091b\u093e\u0924\u094d\u0930 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/fake-news18-screenshot-shared-to-claim-57-covid-positive-cases-after-comedk-exam-in-karnataka/",
        headline:
          "COMEDK \u090f\u0917\u094d\u091c\u093c\u093e\u092e \u0915\u0947 \u092c\u093e\u0926 \u0915\u094b\u0930\u094b\u0928\u093e \u0938\u0947 57 \u0932\u094b\u0917\u094b\u0902 \u0915\u0940 \u092e\u094c\u0924 \u0915\u093e \u0926\u093e\u0935\u093e, \u0928\u094d\u092f\u0942\u095b18 \u0915\u093e \u095e\u0947\u0915 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/times-now-links-rhea-chakrabortys-imma-bounce-chat-to-bounced-cheque-9513",
        headline:
          "Times Now Links Rhea Chakraborty's 'Imma Bounce' Chat To Bounced Cheque",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/fake-screenshot-claims-students-test-covid-19-positive-after-comedk-exams-9535",
        headline:
          "Fake Screenshot Claims Students Test COVID-19 Positive After COMEDK Exams",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-mughal-gardens-has-not-been-renamed-to-dr-rajendra-prasad-garden-9446",
        headline:
          "\u09a8\u09be, \u09ae\u09c1\u0998\u09b2 \u0997\u09be\u09b0\u09cd\u09a1\u09c7\u09a8\u09c7\u09b0 \u09a8\u09be\u09ae \u09aa\u09be\u09b2\u09cd\u099f\u09c7 \u09a1\u09be\u0983 \u09b0\u09be\u099c\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09aa\u09cd\u09b0\u09b8\u09be\u09a6 \u0997\u09be\u09b0\u09cd\u09a1\u09c7\u09a8 \u0995\u09b0\u09be \u09b9\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fast-check/no-prime-minister-narendra-modi-did-not-bow-in-front-of-adanis-wife-9466",
        headline:
          "\u09a8\u09be \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0 \u0986\u09a6\u09be\u09a8\u09bf\u09b0 \u09b8\u09cd\u09a4\u09cd\u09b0\u09c0\u0995\u09c7 \u09a8\u09a4 \u09ae\u09b8\u09cd\u09a4\u0995\u09c7 \u09b8\u09ae\u09cd\u09ad\u09be\u09b7\u09a3 \u099c\u09be\u09a8\u09be\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://factly.in/telugu-this-viral-list-of-covid-19-plasma-donors-contacts-is-fake/",
        headline:
          " \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d-19 \u0c1a\u0c3f\u0c15\u0c3f\u0c24\u0c4d\u0c38 \u0c15\u0c4b\u0c38\u0c02 \u0c2a\u0c4d\u0c32\u0c3e\u0c38\u0c4d\u0c2e\u0c3e \u0c26\u0c3e\u0c28\u0c02 \u0c1a\u0c47\u0c2f\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c3f\u0c26\u0c4d\u0c27\u0c02\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c41\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c05\u0c35\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c32\u0c3f\u0c38\u0c4d\u0c1f\u0c41 \u0c2b\u0c47\u0c15\u0c4d",
      },
      {
        url:
          "https://factly.in/telugu-pm-modi-did-not-give-this-message-to-switch-from-drinking-cool-drinks-to-fruit-juices/",
        headline:
          " \u2018\u0c15\u0c4b\u0c15\u0c3e \u0c15\u0c4b\u0c32\u0c3e\u2019 \u0c21\u0c4d\u0c30\u0c3f\u0c02\u0c15\u0c4d\u0c38\u0c4d \u0c2e\u0c3e\u0c28\u0c47\u0c38\u0c3f \u0c2a\u0c33\u0c4d\u0c33 \u0c30\u0c38\u0c3e\u0c32\u0c41 \u0c24\u0c3e\u0c17\u0c2e\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c47 \u0c08 \u0c2e\u0c46\u0c38\u0c47\u0c1c\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c38\u0c02\u0c26\u0c47\u0c36\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c2e\u0c4b\u0c26\u0c40 \u0c07\u0c35\u0c4d\u0c35\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-conspiracy-theory-claims-sushant-was-killed-for-a-corona-testing-patent-1714587-2020-08-24",
        headline:
          " Fact Check: Conspiracy theory claims Sushant was killed for a corona testing patent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/25/fact-check-claims-of-govt-distributing-smart-phones-to-students-is-false/",
        headline:
          "Fact Check: Claims of govt distributing smart phones to students is false",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/26/fact-check-bollywood-actor-alia-bhatt-is-not-asking-for-a-boycott-of-her-movie-sadak-2/",
        headline:
          "Fact Check: Bollywood actor Alia Bhatt is not asking for a boycott of her movie \u2018Sadak 2\u2019",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/26/fact-check-reports-of-underworld-don-dawood-ibrahim-dying-of-covid-19-are-false/",
        headline:
          "Fact Check: Reports of underworld don Dawood Ibrahim dying of Covid-19 are false",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/28/fact-check-dont-believe-this-fake-news-about-centre-banning-%e2%82%b92000-currency-notes/",
        headline:
          "Fact Check: Don\u2019t believe this FAKE news about Centre banning \u20b92,000 currency notes",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/28/fact-check-deepika-padukone-ranveer-singh-and-ssrs-friend-sandip-ssingh-falsely-linked-with-gangster-dawood-ibrahim/",
        headline:
          "Fact Check: Deepika Padukone, Ranveer Singh and SSR\u2019s friend Sandip Ssingh falsely linked with gangster Dawood Ibrahim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-old-pictures-of-flooded-exam-centres-being-circulated-with-jee-neet-link/",
        headline:
          "Fact Check: Old pictures of flooded exam centres being circulated with JEE-NEET link",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-heres-the-truth-behind-this-video-of-rhea-chakraborty-mahesh-bhatt/",
        headline:
          "Fact Check: Here\u2019s the truth behind this video of Rhea Chakraborty, Mahesh Bhatt",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-old-picture-of-people-living-in-abandoned-pipes-from-bangladesh-shared-as-being-from-india/",
        headline:
          "Fact Check: Old picture of people living in abandoned pipes from Bangladesh shared as being from India",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-amid-jee-neet-row-false-claims-of-clat-postponement-flood-internet-1715923-2020-08-28",
        headline:
          "Fact Check: Following a hoax about exam dates, CLAT now indeed postponed to September 28",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/news18-india-airs-video-from-indore-as-man-jumping-into-floodwater-in-pakistan/",
        headline:
          "News18 India airs video from Indore as man jumping into floodwater in Pakistan",
      },
      {
        url:
          "https://www.altnews.in/bjp-shares-old-imf-data-to-make-misleading-claim-about-indian-gdp-growth-projection/",
        headline:
          "BJP shares old IMF data to make misleading claim about Indian GDP growth projection",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-of-personal-dispute-in-hyderabad-shared-with-false-communal-angle/",
        headline:
          "\u0917\u0923\u0947\u0936 \u091a\u0924\u0941\u0930\u094d\u0925\u0940 \u092a\u0930 \u0926\u094b \u092a\u0930\u093f\u0935\u093e\u0930\u094b\u0902 \u0915\u0947 \u0906\u092a\u0938\u0940 \u091d\u0917\u095c\u0947 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0939\u093f\u0928\u094d\u0926\u0942-\u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0910\u0902\u0917\u0932 \u0938\u0947 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/news18-india-airs-video-from-indore-as-man-jumping-into-floodwater-in-pakistan/",
        headline:
          "\u0907\u0902\u0926\u094c\u0930 \u092e\u0947\u0902 \u090f\u0915 \u0936\u0916\u094d\u0938 \u092c\u093e\u0930\u093f\u0936 \u0915\u0947 \u092a\u093e\u0928\u0940 \u092e\u0947\u0902 \u0915\u0942\u0926\u093e, \u0928\u094d\u092f\u0942\u095b 18 \u0928\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u091a\u0932\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/bjp-shares-old-imf-data-to-make-misleading-claim-about-indian-gdp-growth-projection/",
        headline:
          "\u092d\u093e\u0930\u0924 \u0915\u0940 GDP \u092e\u0947\u0902 \u092c\u095d\u094b\u0924\u094d\u0924\u0930\u0940 \u0926\u093f\u0916\u093e\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u092d\u093e\u091c\u092a\u093e \u0928\u0947 IMF \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0906\u0902\u0915\u095c\u093e \u0915\u093f\u092f\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/did-gunjan-saxenas-biopic-claim-she-was-a-shaurya-chakra-awardee-9512",
        headline:
          "Did Gunjan Saxena's Biopic Claim She Was A Shaurya Chakra Awardee?",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-video-does-not-show-soldiers-celebrating-ganpati-at-galwan-valley-9527",
        headline:
          "No, This Video Does Not Show Soldiers Celebrating Ganpati At Galwan Valley",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/this-official-advertisement-of-the-mask-released-in-march-is-going-viral-now-9484",
        headline:
          "\u092e\u093e\u0930\u094d\u091a \u092e\u0947\u0902 \u091c\u093e\u0930\u0940 \u092e\u093e\u0938\u094d\u0915 \u0915\u093e \u092f\u0939 \u0938\u0930\u0915\u093e\u0930\u0940 \u0935\u093f\u091c\u094d\u091e\u093e\u092a\u0928 \u0905\u092c \u0915\u094d\u092f\u094b\u0902 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u0939\u0948!",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-video-does-not-show-soldiers-celebrating-ganpati-at-galwan-valley-9545",
        headline:
          "\u098f\u099f\u09bf \u09b8\u09c7\u09a8\u09be\u09a6\u09c7\u09b0 \u0997\u09be\u09b2\u0993\u09df\u09be\u09a8 \u0989\u09aa\u09a4\u09cd\u09af\u0995\u09be\u09df \u0997\u09a3\u09c7\u09b6 \u09aa\u09c1\u099c\u09cb \u0995\u09b0\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09a8\u09df",
      },
      {
        url:
          "https://factly.in/telugu-a-fight-over-ganesh-statue-installation-in-hyderabad-is-shared-with-a-false-communal-narrative/",
        headline:
          " \u0c17\u0c23\u0c2a\u0c24\u0c3f \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c02 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c21\u0c02\u0c2a\u0c48 \u0c30\u0c46\u0c02\u0c21\u0c41 \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c35\u0c30\u0c4d\u0c17\u0c3e\u0c32 \u0c2e\u0c27\u0c4d\u0c2f \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c17\u0c4a\u0c21\u0c35\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-srilankan-soldier-photo-is-shared-as-old-image-of-injured-indian-solider/",
        headline:
          " \u0c36\u0c4d\u0c30\u0c40\u0c32\u0c02\u0c15\u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c38\u0c48\u0c28\u0c3f\u0c15\u0c41\u0c21\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c41 \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2d\u0c3e\u0c30\u0c24 \u0c38\u0c48\u0c28\u0c3f\u0c15\u0c41\u0c21\u0c3f \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/an-indian-models-morphed-photo-falsely-shared-as-teacher-suspended-for-her-body-shape-in-lahore/",
        headline:
          " An Indian model\u2019s morphed photo falsely shared as \u2018Teacher suspended for her body shape in Pakistan\u2019.",
      },
      {
        url:
          "https://factly.in/telugu-edited-picture-is-shared-to-portray-as-if-wife-was-hit-by-husband-for-not-wearing-burkha/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2c\u0c41\u0c30\u0c4d\u0c16\u0c3e \u0c27\u0c30\u0c3f\u0c02\u0c1a\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c07\u0c37\u0c4d\u0c1f\u0c2a\u0c21\u0c28\u0c3f  \u0c2d\u0c3e\u0c30\u0c4d\u0c2f \u0c2a\u0c28\u0c4d\u0c28\u0c41 \u0c35\u0c3f\u0c30\u0c17\u0c4a\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c13 \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-india-gdp-growth-estimate-of-1-9-percent-in-2020-is-old-estimate/",
        headline:
          " 2020\u0c35 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c17\u0c3e\u0c28\u0c41 \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36 GDP \u0c35\u0c43\u0c26\u0c4d\u0c27\u0c3f 1.9% \u0c05\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2a\u0c3e\u0c24 \u0c05\u0c02\u0c1a\u0c28\u0c3e.",
      },
      {
        url:
          "https://factly.in/imfs-april-2020-estimate-of-1-9-gdp-growth-in-india-fell-to-4-5-in-recent-estimates/",
        headline:
          " IMF\u2019s April 2020 estimate of 1.9% GDP growth in India fell to -4.5% in recent estimates",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-did-irdai-make-puc-certificate-mandatory-for-motor-insurance-claims-1714615-2020-08-24",
        headline:
          "Fact Check: Is pollution control certificate mandatory for motor insurance claim?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/29/fact-check-the-truth-about-this-video-of-indian-tv-anchor-going-viral/",
        headline:
          "Fact Check: The truth about this video of Indian TV anchor going viral",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-reports-of-iaf-pilot-gunjan-saxena-being-a-shaurya-chakra-awardee-are-false/",
        headline:
          "Fact Check: Reports of IAF pilot Gunjan Saxena being a \u2018Shaurya Chakra\u2019 awardee are FALSE",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-this-video-is-not-of-ganesh-chaturthi-celebration-at-galwan-valley/",
        headline:
          "Fact Check: This video is not of Ganesh Chaturthi celebration at Galwan Valley",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-don-t-slip-over-this-rasbhari-gossip-with-a-pak-twist-1715248-2020-08-26",
        headline:
          "Fact Check: Don\u2019t slip over this Rasbhari gossip with a Pak twist",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-love-jihad-angle-in-acid-attack-on-aspiring-model-1715479-2020-08-26",
        headline:
          "Fact Check: No love jihad angle in acid attack on aspiring model",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/fact-check-image-of-ambassador-sangita-bahadur-cutting-tricolor-cake-shared-as-facebook-public-policy-director-ankhi-das/",
        headline:
          "No, this is not Facebook\u2019s Ankhi Das cutting tricoloured cake",
      },
      {
        url:
          "https://www.altnews.in/hindi/fact-check-image-of-ambassador-sangita-bahadur-cutting-tricolor-cake-shared-as-facebook-public-policy-director-ankhi-das/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915: \u095e\u0947\u0938\u092c\u0941\u0915 \u0915\u0940 \u0906\u0902\u0916\u0940 \u0926\u093e\u0938 \u0928\u0947 \u092d\u093e\u0930\u0924\u0940\u092f \u091d\u0902\u0921\u0947 \u0915\u0947 3 \u0930\u0902\u0917\u094b\u0902 \u0935\u093e\u0932\u093e \u0915\u0947\u0915 \u0915\u093e\u091f\u093e?",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/pics-of-gurugram-flyover-collapse-peddled-as-mumbai-bengaluru-ahmedabad-9468",
        headline:
          "Pics Of Gurugram Flyover Collapse Peddled As Mumbai, Bengaluru, Ahmedabad",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photos-of-indian-ambassador-to-belarus-peddled-as-facebooks-ankhi-das-9505",
        headline:
          "Photos Of Indian Ambassador To Belarus Peddled As Facebook's Ankhi Das",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photos-of-indian-ambassador-to-belarus-peddled-as-facebooks-ankhi-das-9518",
        headline:
          "\u0915\u094d\u092f\u093e \u095e\u0947\u0938\u092c\u0941\u0915 \u0915\u0940 \u092a\u092c\u094d\u0932\u093f\u0915 \u092a\u0949\u0932\u093f\u0938\u0940 \u0939\u0947\u0921 \u0906\u0902\u0916\u0940 \u0926\u093e\u0938 \u0928\u0947 \u0924\u093f\u0930\u0902\u0917\u0947 \u0935\u093e\u0932\u093e \u0915\u0947\u0915 \u0915\u093e\u091f\u093e \u0939\u0948 ?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photos-of-indian-ambassador-to-belarus-peddled-as-facebooks-ankhi-das-9507",
        headline:
          "\u09a4\u09c7\u09b0\u0999\u09be \u0995\u09c7\u0995 \u0995\u09be\u099f\u099b\u09c7 \u09ab\u09c7\u09b8\u09ac\u09c1\u0995 \u0995\u09b0\u09cd\u09ae\u09c0 \u0986\u0996\u09bf\u0981 \u09a6\u09be\u09b8? \u09a8\u09be, \u09a4\u09be \u09a0\u09bf\u0995 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/kerala-yoga-ashram-photo-viral-as-bharatiya-culture-in-new-zealand-9536",
        headline:
          "\u09a4\u09bf\u09b0\u09c1\u09ac\u09a8\u09cd\u09a4\u09aa\u09c1\u09b0\u09ae\u09c7\u09b0 \u09af\u09cb\u0997 \u0986\u09b6\u09cd\u09b0\u09ae\u09c7\u09b0 \u099b\u09ac\u09bf \u09a8\u09bf\u0989\u099c\u09bf\u09b2\u09cd\u09af\u09be\u09a8\u09cd\u09a1\u09c7 '\u09ad\u09be\u09b0\u09a4\u09c0\u09df' \u09b8\u0982\u09b8\u09cd\u0995\u09c3\u09a4\u09bf \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fast-check/video-of-medicos-cheering-after-cure-of-last-covid-patient-is-from-italy-9539",
        headline:
          "\u09b6\u09c7\u09b7 \u0995\u09cb\u09ad\u09bf\u09a1 \u09b0\u09cb\u0997\u09c0 \u09b8\u09be\u09b0\u09bf\u09df\u09c7 \u09b8\u09cd\u09ac\u09be\u09b8\u09cd\u09a5\u09cd\u09af\u0995\u09b0\u09cd\u09ae\u09c0\u09a6\u09c7\u09b0 \u0989\u09b2\u09cd\u09b2\u09be\u09b8\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u09a8\u09bf\u0989\u099c\u09bf\u09b2\u09cd\u09af\u09be\u09a8\u09cd\u09a1\u09c7\u09b0 \u09a8\u09df",
      },
      {
        url:
          "https://factly.in/old-photo-shared-as-clouds-taking-the-form-of-lord-ganesh-during-covid19-times/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c38\u0c2e\u0c2f\u0c02\u0c32\u0c4b  \u0c06\u0c15\u0c3e\u0c36\u0c02\u0c32\u0c4b \u0c35\u0c46\u0c32\u0c38\u0c3f\u0c28 \u0c35\u0c3f\u0c28\u0c3e\u0c2f\u0c15\u0c41\u0c21\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/indian-ambassador-to-the-republic-of-belarus-portrayed-as-the-facebook-indias-executive-ankhi-das/",
        headline:
          " Indian Ambassador to the Republic of Belarus portrayed as the Facebook India\u2019s Executive Ankhi Das",
      },
      {
        url:
          "https://factly.in/government-of-india-is-not-providing-rs-1-5-lakh-per-covid-19-patient-to-every-municipality/",
        headline:
          " Government of India is not providing Rs 1.5 Lakh per COVID-19 patient to every Municipality",
      },
      {
        url:
          "https://factly.in/photos-of-the-collapsed-under-construction-flyover-in-gurugram-is-falsely-portrayed-as-the-metro-line-collapse-in-mumbai/",
        headline:
          " Photos of the collapsed under-construction flyover in Gurugram is falsely shared as the metro line collapse in Mumbai",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/26/dont-fall-for-this-fake-jio-news-nita-ambani-has-not-offered-a-free-recharge-scheme-worth-rs-349/",
        headline:
          "Don\u2019t fall for this FAKE Jio news: Nita Ambani has not offered a free recharge scheme worth Rs. 349",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/26/fact-check-pictures-of-gurugram-flyover-collapse-shared-as-mumbai-ahmedabad/",
        headline:
          "Fact Check: Pictures of Gurugram flyover collapse shared as Mumbai, Ahmedabad",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/28/fact-check-claims-of-centre-giving-rs-1-5-lakh-to-municipalities-for-each-covid-19-patient-is-fake/",
        headline:
          "Fact Check: Claims of Centre giving Rs 1.5 lakh to municipalities for each COVID-19 patient is FAKE",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/29/fact-check-picture-taken-in-a-kerala-ashram-falsely-shared-as-being-from-new-zealand/",
        headline:
          "Fact Check: Picture taken in a Kerala ashram falsely shared as being from New Zealand",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-gurugram-flyover-comes-crashing-down-on-social-media-with-misleading-claims-1714968-2020-08-25",
        headline:
          "Fact Check: Gurugram flyover comes crashing down on social media with misleading claims",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-bake-confusion-over-tricolour-cake-accuse-ankhi-das-of-insulting-india-1715016-2020-08-25",
        headline:
          "Fact Check: Netizens bake confusion over Tricolour cake, accuse Ankhi Das of insulting India",
      },
    ],
    "6": [
      {
        url:
          "https://www.altnews.in/video-of-personal-dispute-in-hyderabad-shared-with-false-communal-angle/",
        headline:
          "Video of personal dispute in Hyderabad shared with false communal angle",
      },
      {
        url:
          "https://www.altnews.in/video-of-angry-mob-from-west-bengal-shared-as-banglore-with-false-communal-angle/",
        headline:
          "Video from West Bengal passed off as communal violence in Bengaluru",
      },
      {
        url:
          "https://www.altnews.in/congress-shares-2012-image-of-pm-modi-with-ducks-as-pr-during-covid-19/",
        headline:
          "Congress shares 2012 image of PM Modi with ducks as PR during COVID-19",
      },
      {
        url:
          "https://www.altnews.in/tarek-fatah-twitted-a-three-year-old-video-from-bangladesh-as-kolkata-later-deletes-the-tweet/",
        headline:
          "Old video from Bangladesh shared as \u2018Islam Zindabad\u2019 slogans raised in West Bengal",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-of-angry-mob-from-west-bengal-shared-as-banglore-with-false-communal-angle/",
        headline:
          "\u092a\u0936\u094d\u091a\u093f\u092e \u092c\u0902\u0917\u093e\u0932 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u0947\u0902\u0917\u0932\u0941\u0930\u0941 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0938\u093e\u092e\u094d\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0910\u0902\u0917\u0932 \u0938\u0947 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/congress-shares-2012-image-of-pm-modi-with-ducks-as-pr-during-covid-19/",
        headline:
          "\u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 2012 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0936\u0947\u092f\u0930 \u0915\u0930\u0924\u0947 \u0939\u0941\u090f PM \u092e\u094b\u0926\u0940 \u092a\u0930 \u092e\u0939\u093e\u092e\u093e\u0930\u0940 \u092e\u0947\u0902 \u092d\u0940 PR \u0915\u0930\u0928\u0947 \u0915\u093e \u0906\u0930\u094b\u092a \u0932\u0917\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/images-from-west-bengal-where-a-man-sitting-in-pothole-wearing-helmet-shared-as-uttar-pradesh/",
        headline:
          "\u0938\u095c\u0915 \u0915\u0940 \u0926\u0941\u0930\u094d\u0926\u0936\u093e \u0926\u093f\u0916\u093e\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u0917\u0921\u094d\u0922\u094b\u0902 \u092e\u0947\u0902 \u092a\u094b\u095b \u0926\u0947\u0924\u0947 \u092a\u0936\u094d\u091a\u093f\u092e \u092c\u0902\u0917\u093e\u0932 \u0915\u0947 \u0936\u0916\u094d\u0938 \u0915\u0940 \u095e\u094b\u091f\u094b\u095b UP \u0915\u0940 \u092c\u0924\u093e\u0908 \u0917\u092f\u0940\u0902",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-protest-in-bangladesh-passed-off-as-west-bengal-9485",
        headline: "Video Of Protest In Bangladesh Passed Off As West Bengal",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/old-photos-of-modi-with-ducks-viral-as-pr-stunt-during-pandemic",
        headline:
          "Old Photos Of Modi With Ducks Viral As PR Stunt During Pandemic",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/images-from-anti-pothole-campaign-in-west-bengal-viral-as-up-chattisgarh-9538",
        headline:
          "Images From Anti Pothole Campaign In West Bengal Viral As UP, Chattisgarh",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-mob-stripping-old-man-in-bangladesh-viral-as-india-9497",
        headline:
          "\u092c\u093e\u0902\u0917\u094d\u0932\u093e\u0926\u0947\u0936 \u092e\u0947\u0902 \u0935\u0943\u0926\u094d\u0927 \u092a\u0930 \u0939\u0941\u0906 \u0939\u092e\u0932\u093e, \u0935\u0940\u0921\u093f\u092f\u094b \u092d\u093e\u0930\u0924 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-photos-of-modi-with-ducks-viral-as-pr-stunt-during-pandemic-9509",
        headline:
          "\u092c\u0924\u094d\u0924\u0916\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u092e\u094b\u0926\u0940 \u0915\u0940 \u0938\u093e\u0932\u094b\u0902 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u092e\u0939\u093e\u092e\u093e\u0930\u0940 \u0915\u0947 \u0926\u094c\u0930\u093e\u0928 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-from-protest-in-bengal-falsely-linked-to-bengaluru-9471",
        headline:
          "\u09ac\u09c7\u0999\u09cd\u0997\u09be\u09b2\u09c1\u09b0\u09c1\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u0995\u09b0\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7\u09b0 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2001-image-of-protest-by-members-of-world-hindu-council-revived-with-misleading-claim-9503",
        headline:
          "\u09e8\u09e6\u09e6\u09e7'\u09b0 \u09ac\u09bf\u09b6\u09cd\u09ac \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09aa\u09b0\u09bf\u09b7\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-protest-in-bangladesh-passed-off-as-west-bengal-9528",
        headline:
          "\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6\u09c7\u09b0 \u098f\u0995\u099f\u09bf \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6-\u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u0995\u09c7 \u0995\u09b2\u0995\u09be\u09a4\u09be\u09b0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2012-newspaper-clipping-of-proposed-bill-for-payment-of-wage-to-wife-by-husband-resurfaces-9548",
        headline:
          "\u09b8\u09cd\u09ac\u09be\u09ae\u09c0\u09b0 \u09aa\u0995\u09c7\u099f \u09a5\u09c7\u0995\u09c7 '\u0997\u09c3\u09b9\u09ac\u09a7\u09c2\u09b0 \u09ae\u09be\u09b8 \u09ae\u09be\u0987\u09a8\u09c7' \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2 \u09e8\u09e6\u09e7\u09e8 \u09b8\u09be\u09b2\u09c7\u09b0 \u0996\u09ac\u09b0",
      },
      {
        url:
          "https://factly.in/telugu-2018-leopard-attack-images-from-maharastra-shared-as-those-from-a-recent-attack-on-srisailam-ghat-road/",
        headline:
          " \u0c2e\u0c39\u0c3e\u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c32\u0c4b\u0c28\u0c3f \u0c1a\u0c3f\u0c2a\u0c4d\u0c32\u0c41\u0c28\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c1a\u0c3f\u0c30\u0c41\u0c24 \u0c26\u0c3e\u0c21\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c36\u0c4d\u0c30\u0c40\u0c36\u0c48\u0c32\u0c02\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-no-35-year-jail-term-for-people-who-took-bribe/",
        headline:
          " \u0c32\u0c02\u0c1a\u0c02 \u0c24\u0c40\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c3f\u0c15\u0c3f 35 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c3e\u0c32\u0c41 \u0c1c\u0c48\u0c32\u0c41 \u0c36\u0c3f\u0c15\u0c4d\u0c37 \u0c05\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c4d\u0c24\u0c32\u0c4b \u0c28\u0c3f\u0c1c\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-of-a-truck-with-billboards-displaying-the-ayodhya-ram-mandir-visuals-is-from-sydney-australia/",
        headline:
          " This video of a truck with billboards displaying the Ayodhya Ram Mandir visuals is from Sydney (Australia).",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-bill-board-with-ayodhya-ram-mandir-display-on-truck-is-from-australia-not-israel/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c1f\u0c4d\u0c30\u0c15\u0c4d\u0c15\u0c41 \u0c2a\u0c48 \u0c05\u0c2f\u0c4b\u0c27\u0c4d\u0c2f \u0c30\u0c3e\u0c2e \u0c2e\u0c02\u0c26\u0c3f\u0c30\u0c02 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c41 \u0c2a\u0c4d\u0c30\u0c26\u0c30\u0c4d\u0c36\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c38\u0c3f\u0c21\u0c4d\u0c28\u0c40 (\u0c06\u0c38\u0c4d\u0c1f\u0c4d\u0c30\u0c47\u0c32\u0c3f\u0c2f\u0c3e) \u0c32\u0c4b",
      },
      {
        url:
          "https://factly.in/photos-of-the-leopard-attack-in-maharashtra-is-falsely-shared-as-the-attack-on-srisailam-ghat-road/",
        headline:
          " Photos of the Leopard attack in Maharashtra from 2018 is falsely shared as the attack on Srisailam ghat road",
      },
      {
        url:
          "https://factly.in/protest-video-from-bangladesh-shared-as-a-massive-protest-by-muslims-in-west-bengal/",
        headline:
          " Protest video from Bangladesh shared as a massive protest by Muslims in West Bengal",
      },
      {
        url:
          "https://factly.in/old-image-shared-as-a-photo-op-by-modi-during-the-covid-pandemic/",
        headline:
          " Old image shared as a photo-op by Modi during the COVID pandemic",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-know-the-bengal-link-behind-this-viral-bengaluru-riots-video-1714680-2020-08-25",
        headline:
          "Fact Check: Video of Bengal violence passed off as Bengaluru riots",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/24/fact-check-did-bihar-dgp-gupteshwar-pandey-resign-from-his-position-heres-the-truth/",
        headline:
          "Fact Check: Did Bihar DGP Gupteshwar Pandey resign from his position? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/24/fact-check-old-picture-of-former-pm-indira-gandhi-shared-with-a-false-claim/",
        headline:
          "Fact Check: Old picture of former PM Indira Gandhi shared with a false claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/27/fact-check-dont-fall-for-this-picture-of-pm-modi-holding-rahul-gandhis-portrait/",
        headline:
          "Fact Check: Don\u2019t fall for this picture of PM Modi holding Rahul Gandhi\u2019s portrait",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/27/fact-check-this-picture-of-pm-modi-holding-a-placard-is-fake/",
        headline:
          "Fact Check: This picture of PM Modi holding a placard is FAKE",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/27/fact-check-old-pictures-of-pm-modi-with-ducks-shared-as-recent/",
        headline:
          "Fact Check: Old pictures of PM Modi with ducks shared as recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/28/fact-check-dont-fall-for-this-statement-falsely-attributed-to-president-kovind/",
        headline:
          "Fact Check: Don\u2019t fall for this statement falsely attributed to President Kovind",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/28/ffact-check-picture-of-a-truck-beaming-visuals-of-ram-mandir-bhoomi-pujan-in-israel-is-misleading/",
        headline:
          "Fact Check: Picture of a truck beaming visuals of Ram Mandir \u2018bhoomi pujan\u2019 in Israel is misleading",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/28/fact-check-old-video-of-protest-in-bangladesh-resurfaces-as-west-bengal/",
        headline:
          "Fact Check: Old video of protest in Bangladesh resurfaces as West Bengal",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/28/fact-check-veteran-bjp-leader-lk-advani-did-not-make-this-statement-mocking-pm-modi/",
        headline:
          "Fact Check: Veteran BJP leader LK Advani did not make this statement mocking PM Modi",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/29/fact-check-dont-believe-this-morphed-picture-of-pm-modi/",
        headline:
          "Fact Check: Don\u2019t believe this morphed picture of PM Modi",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/29/fact-check-did-pm-modi-pay-homage-to-nathuram-godse-heres-the-truth/",
        headline:
          "Fact Check: Did PM Modi pay homage to Nathuram Godse? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/08/30/fact-check-pictures-of-potholes-in-west-bengal-shared-as-being-from-up-bihar/",
        headline:
          "Fact Check: Pictures of potholes in West Bengal shared as being from UP, Bihar",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-the-man-pm-modi-paying-homage-nathuram-godse-1716147-2020-08-28",
        headline:
          "Fact Check: No, the man PM Modi is paying homage to is not Nathuram Godse",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/zuckerberg-never-said-this-about-the-jai-shri-ram-slogan-1715937-2020-08-28",
        headline:
          "Fact Check: Zuckerberg never said this about the 'Jai Shri Ram' slogan\u00a0",
      },
    ],
  },
  numpy_seed: 1,
  number_of_articles: 140,
}
