export const data = {
  mdsDat: {
    x: [
      -0.1236627337289781,
      0.24855193660037173,
      0.05150353718347018,
      0.03294711248920884,
      -0.26624381159796856,
      0.2623131484458572,
      0.06001858355651252,
      -0.26542777294847314,
    ],
    y: [
      0.145716822851759,
      -0.09617226942854841,
      0.21659186550566983,
      -0.04929229762735138,
      -0.19440358593755508,
      -0.22666836963429488,
      0.2897541037934139,
      -0.08552626952309271,
    ],
    topics: [1, 2, 3, 4, 5, 6, 7, 8],
    cluster: [1, 1, 1, 1, 1, 1, 1, 1],
    Freq: [
      9.459398209158902,
      20.480957086986027,
      16.73067227994053,
      7.422117806223237,
      6.404490635316264,
      8.148941980920327,
      13.749250429498275,
      17.60417157195643,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
      "Topic8",
    ],
    Freq: [
      17.0,
      14.0,
      10.0,
      20.0,
      9.0,
      15.0,
      7.0,
      12.0,
      11.0,
      14.0,
      1.0005132721225762,
      2.0010265442451525,
      2.0010265442451525,
      1.0005132721225762,
      2.0010265442451525,
      3.0015398163677287,
      1.0005132721225762,
      2.0010265442451525,
      1.0005132721225762,
      2.0010265442451525,
      10.005132721225763,
      5.002566360612882,
      4.002053088490305,
      3.0015398163677287,
      2.0010265442451525,
      2.0010265442451525,
      2.0010265442451525,
      7.003592904858034,
      7.003592904858034,
      5.002566360612882,
      2.0010265442451525,
      2.0010265442451525,
      3.0015398163677287,
      2.0010265442451525,
      2.0010265442451525,
      2.995884680277211,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      1.9972564535181407,
      0.9986282267590704,
      6.990397587313494,
      6.990397587313494,
      6.990397587313494,
      6.990397587313494,
      4.993141133795352,
      4.993141133795352,
      3.9945129070362815,
      3.9945129070362815,
      3.9945129070362815,
      2.995884680277211,
      2.995884680277211,
      2.995884680277211,
      11.983538721108845,
      11.983538721108845,
      9.986282267590704,
      6.990397587313494,
      11.983538721108845,
      5.991769360554422,
      5.991769360554422,
      2.0004064682537592,
      1.0002032341268796,
      1.0002032341268796,
      5.001016170634398,
      1.0002032341268796,
      2.0004064682537592,
      1.0002032341268796,
      1.0002032341268796,
      1.0002032341268796,
      2.0004064682537592,
      6.001219404761278,
      4.0008129365075185,
      4.0008129365075185,
      3.000609702380639,
      3.000609702380639,
      3.000609702380639,
      3.000609702380639,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      4.0008129365075185,
      4.0008129365075185,
      7.001422638888158,
      6.001219404761278,
      8.001625873015037,
      5.001016170634398,
      5.001016170634398,
      4.0008129365075185,
      4.0008129365075185,
      1.0005305866232304,
      2.0010611732464607,
      2.0010611732464607,
      2.0010611732464607,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      2.0010611732464607,
      3.0015917598696915,
      3.0015917598696915,
      1.0007016617681663,
      2.0014033235363327,
      1.0007016617681663,
      1.0007016617681663,
      1.0007016617681663,
      1.0007016617681663,
      1.0007016617681663,
      2.0014033235363327,
      1.0007016617681663,
      5.003508308840831,
      7.004911632377164,
      3.0021049853044985,
      3.0021049853044985,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      7.004911632377164,
      2.0014033235363327,
      4.001712579916231,
      1.0004281449790577,
      2.0008562899581155,
      1.0004281449790577,
      1.0004281449790577,
      1.0004281449790577,
      1.0004281449790577,
      4.001712579916231,
      1.0004281449790577,
      1.0004281449790577,
      4.001712579916231,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      2.0008562899581155,
      2.0008562899581155,
      2.0008562899581155,
      2.0008562899581155,
      4.001712579916231,
      4.001712579916231,
      3.0012844349371735,
      3.0012844349371735,
      5.001380777925959,
      5.001380777925959,
      1.000276155585192,
      2.000552311170384,
      2.000552311170384,
      3.000828466755576,
      2.000552311170384,
      1.000276155585192,
      1.000276155585192,
      1.000276155585192,
      6.001656933511152,
      3.000828466755576,
      3.000828466755576,
      3.000828466755576,
      3.000828466755576,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      11.003037711437111,
      7.001933089096344,
      7.001933089096344,
      6.001656933511152,
      6.001656933511152,
      5.001380777925959,
      4.001104622340768,
      5.001380777925959,
      1.0002370211338882,
      2.0004740422677765,
      1.0002370211338882,
      2.0004740422677765,
      2.0004740422677765,
      1.0002370211338882,
      1.0002370211338882,
      2.0004740422677765,
      2.0004740422677765,
      2.0004740422677765,
      14.003318295874434,
      11.00260723247277,
      9.002133190204994,
      5.001185105669441,
      4.000948084535553,
      4.000948084535553,
      4.000948084535553,
      3.0007110634016643,
      3.0007110634016643,
      3.0007110634016643,
      16.003792338142212,
      3.0007110634016643,
      3.0007110634016643,
      3.0007110634016643,
      3.0007110634016643,
      4.000948084535553,
      4.000948084535553,
      4.000948084535553,
      4.000948084535553,
    ],
    Term: [
      "covid",
      "vaccine",
      "murder",
      "biden",
      "message",
      "day",
      "whatsapp",
      "hyderabad",
      "covid_vaccine",
      "bjp",
      "leave",
      "ambedkar",
      "see",
      "rajasthan_shared",
      "vaccinate",
      "america",
      "maker",
      "saheb",
      "gang",
      "tell",
      "murder",
      "murder_hyderabad",
      "rajasthan",
      "police",
      "child_kidnapping",
      "brutal",
      "rajasthan_share",
      "delhi",
      "hyderabad",
      "child",
      "health",
      "somnath",
      "kidnapping",
      "edited",
      "aadmi",
      "tmc",
      "put",
      "come",
      "showcased",
      "sack",
      "marine",
      "ask",
      "home",
      "peddle",
      "wildfire",
      "chief_guest",
      "suriname",
      "guest",
      "chief",
      "california",
      "president_suriname",
      "ghaziabad",
      "parade",
      "tractor",
      "leader_shop",
      "tractor_parade",
      "wheat",
      "bjp",
      "day",
      "republic",
      "president",
      "farmer",
      "protest",
      "leader",
      "know",
      "article",
      "person",
      "trump",
      "loan",
      "quote",
      "andhra",
      "rabindranath",
      "change",
      "nationality",
      "government",
      "pass",
      "church",
      "gandhi",
      "mandir",
      "ram",
      "board",
      "tamil",
      "temple",
      "passport",
      "wishing",
      "last",
      "hindu",
      "pm",
      "pm_modi",
      "modi",
      "letter",
      "biden",
      "say",
      "joe",
      "edit",
      "joe_biden",
      "angle",
      "issues",
      "peace",
      "prize",
      "approve",
      "death",
      "company",
      "female",
      "involve",
      "majid",
      "ravish",
      "coca",
      "ndtv",
      "rice",
      "paddy",
      "cola",
      "kumar",
      "ravish_kumar",
      "coca_cola",
      "campaign",
      "support",
      "farmer",
      "tokyo",
      "match",
      "ancient",
      "rule",
      "tank",
      "policy",
      "hima",
      "singing_vande",
      "art",
      "send",
      "whatsapp",
      "charge",
      "chargeable",
      "audience",
      "gaba",
      "morning",
      "test",
      "become",
      "user",
      "yet",
      "percent",
      "good",
      "message",
      "vande",
      "islamic",
      "tweaked",
      "celebrate",
      "prayer",
      "tv",
      "national",
      "chhara",
      "mamata",
      "azharuddeen",
      "minute",
      "shut",
      "order",
      "mamata_banerjee",
      "madrasas",
      "recite",
      "banerjee",
      "reciting_islamic",
      "chaudhary",
      "school",
      "verse",
      "defy",
      "bengal",
      "day",
      "republic",
      "bjp",
      "tweet",
      "world",
      "biden_tweet",
      "pakistan",
      "rss",
      "narendra_modi",
      "genocide",
      "moreover",
      "film",
      "read",
      "pune",
      "accident_pune",
      "world_leader",
      "truck",
      "happen",
      "bridge",
      "impersonate",
      "flyover",
      "presidential",
      "hazara",
      "occupy",
      "invite",
      "biden",
      "joe_biden",
      "joe",
      "leader",
      "modi",
      "hyderabad",
      "us",
      "farmer",
      "taiwan",
      "coach",
      "africa",
      "kite",
      "look",
      "side",
      "lakh",
      "gujarat",
      "give",
      "gates",
      "vaccine",
      "covid_vaccine",
      "take",
      "gabba",
      "crowd_singing",
      "crowd",
      "vande_mataram",
      "nurse",
      "win",
      "palsy",
      "covid",
      "bell_palsy",
      "place",
      "bell",
      "dubai",
      "official",
      "vande",
      "mataram",
      "incident",
    ],
    Total: [
      17.0,
      14.0,
      10.0,
      20.0,
      9.0,
      15.0,
      7.0,
      12.0,
      11.0,
      14.0,
      1.0005132721225762,
      2.0010265442451525,
      2.0010265442451525,
      1.0005132721225762,
      2.0010265442451525,
      3.0015398163677287,
      1.0005132721225762,
      2.0010265442451525,
      1.0005132721225762,
      2.0010265442451525,
      10.005132721225763,
      5.002566360612882,
      4.002053088490305,
      3.0015398163677287,
      2.0010265442451525,
      2.0010265442451525,
      2.0010265442451525,
      9.999477585135246,
      12.004973682783994,
      8.003277424014545,
      2.0010265442451525,
      2.0010265442451525,
      5.002013858635506,
      2.0010265442451525,
      2.0010265442451525,
      2.995884680277211,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      0.9986282267590704,
      1.9972564535181407,
      0.9986282267590704,
      6.990397587313494,
      6.990397587313494,
      6.990397587313494,
      6.990397587313494,
      4.993141133795352,
      4.993141133795352,
      3.9945129070362815,
      3.9945129070362815,
      3.9945129070362815,
      2.995884680277211,
      2.995884680277211,
      2.995884680277211,
      14.98482315604602,
      15.985251301025077,
      12.987566702527879,
      8.990949898483878,
      22.98734587213731,
      7.992321671724806,
      12.99393956618815,
      2.0004064682537592,
      1.0002032341268796,
      1.0002032341268796,
      5.001016170634398,
      1.0002032341268796,
      2.0004064682537592,
      1.0002032341268796,
      1.0002032341268796,
      1.0002032341268796,
      2.0004064682537592,
      6.001219404761278,
      4.0008129365075185,
      4.0008129365075185,
      3.000609702380639,
      3.000609702380639,
      3.000609702380639,
      3.000609702380639,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      2.0004064682537592,
      5.001089092092711,
      5.001089092092711,
      13.00307957239931,
      10.99436053855663,
      20.005176856574725,
      9.995766098804568,
      12.002949259730741,
      7.0022676257317285,
      11.002746025603862,
      1.0005305866232304,
      2.0010611732464607,
      2.0010611732464607,
      2.0010611732464607,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      1.0005305866232304,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      3.0015917598696915,
      2.0010611732464607,
      4.001867915454883,
      22.98734587213731,
      1.0007016617681663,
      2.0014033235363327,
      1.0007016617681663,
      1.0007016617681663,
      1.0007016617681663,
      1.0007016617681663,
      1.0007016617681663,
      2.0014033235363327,
      1.0007016617681663,
      5.003508308840831,
      7.004911632377164,
      3.0021049853044985,
      3.0021049853044985,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      2.0014033235363327,
      9.005385674644941,
      6.002351408071886,
      4.001712579916231,
      1.0004281449790577,
      2.0008562899581155,
      1.0004281449790577,
      1.0004281449790577,
      1.0004281449790577,
      1.0004281449790577,
      4.001712579916231,
      1.0004281449790577,
      1.0004281449790577,
      4.001712579916231,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      3.0012844349371735,
      2.0008562899581155,
      2.0008562899581155,
      2.0008562899581155,
      2.0008562899581155,
      8.994853713711583,
      15.985251301025077,
      12.987566702527879,
      14.98482315604602,
      5.001380777925959,
      5.001380777925959,
      1.000276155585192,
      2.000552311170384,
      2.000552311170384,
      3.000828466755576,
      2.000552311170384,
      1.000276155585192,
      1.000276155585192,
      1.000276155585192,
      6.001656933511152,
      3.000828466755576,
      3.000828466755576,
      3.000828466755576,
      3.000828466755576,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      2.000552311170384,
      20.005176856574725,
      11.002746025603862,
      12.002949259730741,
      12.99393956618815,
      13.00307957239931,
      12.004973682783994,
      8.002605208853698,
      22.98734587213731,
      1.0002370211338882,
      2.0004740422677765,
      1.0002370211338882,
      2.0004740422677765,
      2.0004740422677765,
      1.0002370211338882,
      1.0002370211338882,
      2.0004740422677765,
      2.0004740422677765,
      2.0004740422677765,
      14.003318295874434,
      11.00260723247277,
      9.002133190204994,
      5.001185105669441,
      4.000948084535553,
      4.000948084535553,
      4.000948084535553,
      3.0007110634016643,
      3.0007110634016643,
      3.0007110634016643,
      17.004068493727402,
      3.0007110634016643,
      3.0007110634016643,
      3.0007110634016643,
      3.0007110634016643,
      6.001974628780705,
      6.002351408071886,
      6.002351408071886,
      8.002526939951089,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.3582,
      2.0021,
      1.8193,
      1.8883,
      2.3582,
      2.3582,
      1.8474,
      2.3582,
      2.3582,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.5857,
      1.3622,
      1.2975,
      1.3229,
      1.334,
      0.9343,
      1.2976,
      0.8116,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.7879,
      1.5648,
      1.5648,
      1.1689,
      1.1825,
      0.8716,
      1.0954,
      0.9124,
      1.2282,
      0.7763,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.6007,
      2.3131,
      0.5649,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.7482,
      2.497,
      1.6499,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      2.5073,
      1.6974,
      1.1223,
      1.0423,
      0.8993,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.9842,
      1.3864,
      1.5322,
      1.4452,
      1.2117,
      1.211,
      1.1086,
      1.291,
      0.459,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.737,
      1.6764,
      1.737,
      1.737,
      1.737,
      1.737,
      1.3315,
      1.3314,
      1.3314,
      1.0438,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.8675,
      -4.1744,
      -4.1744,
      -4.8675,
      -4.1744,
      -3.7689,
      -4.8675,
      -4.1744,
      -4.8675,
      -4.1744,
      -2.5649,
      -3.2581,
      -3.4812,
      -3.7689,
      -4.1744,
      -4.1744,
      -4.1744,
      -2.9216,
      -2.9216,
      -3.2581,
      -4.1744,
      -4.1744,
      -3.7689,
      -4.1744,
      -4.1744,
      -4.5433,
      -5.6419,
      -5.6419,
      -5.6419,
      -5.6419,
      -5.6419,
      -5.6419,
      -5.6419,
      -4.9488,
      -5.6419,
      -3.696,
      -3.696,
      -3.696,
      -3.696,
      -4.0325,
      -4.0325,
      -4.2556,
      -4.2556,
      -4.2556,
      -4.5433,
      -4.5433,
      -4.5433,
      -3.157,
      -3.157,
      -3.3393,
      -3.696,
      -3.157,
      -3.8501,
      -3.8501,
      -4.7449,
      -5.4381,
      -5.4381,
      -3.8286,
      -5.4381,
      -4.7449,
      -5.4381,
      -5.4381,
      -5.4381,
      -4.7449,
      -3.6463,
      -4.0518,
      -4.0518,
      -4.3395,
      -4.3395,
      -4.3395,
      -4.3395,
      -4.7449,
      -4.7449,
      -4.7449,
      -4.7449,
      -4.7449,
      -4.7449,
      -4.0518,
      -4.0518,
      -3.4922,
      -3.6463,
      -3.3586,
      -3.8286,
      -3.8286,
      -4.0518,
      -4.0518,
      -4.625,
      -3.9318,
      -3.9318,
      -3.9318,
      -4.625,
      -4.625,
      -4.625,
      -4.625,
      -4.625,
      -4.625,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.9318,
      -3.5264,
      -3.5264,
      -4.4773,
      -3.7842,
      -4.4773,
      -4.4773,
      -4.4773,
      -4.4773,
      -4.4773,
      -3.7842,
      -4.4773,
      -2.8679,
      -2.5314,
      -3.3787,
      -3.3787,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.7842,
      -2.5314,
      -3.7842,
      -3.3322,
      -4.7185,
      -4.0254,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -3.3322,
      -4.7185,
      -4.7185,
      -3.3322,
      -3.6199,
      -3.6199,
      -3.6199,
      -3.6199,
      -3.6199,
      -3.6199,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -3.3322,
      -3.3322,
      -3.6199,
      -3.6199,
      -3.6323,
      -3.6323,
      -5.2417,
      -4.5486,
      -4.5486,
      -4.1431,
      -4.5486,
      -5.2417,
      -5.2417,
      -5.2417,
      -3.45,
      -4.1431,
      -4.1431,
      -4.1431,
      -4.1431,
      -4.5486,
      -4.5486,
      -4.5486,
      -4.5486,
      -4.5486,
      -4.5486,
      -4.5486,
      -2.8439,
      -3.2958,
      -3.2958,
      -3.45,
      -3.45,
      -3.6323,
      -3.8555,
      -3.6323,
      -5.4889,
      -4.7958,
      -5.4889,
      -4.7958,
      -4.7958,
      -5.4889,
      -5.4889,
      -4.7958,
      -4.7958,
      -4.7958,
      -2.8499,
      -3.091,
      -3.2917,
      -3.8795,
      -4.1026,
      -4.1026,
      -4.1026,
      -4.3903,
      -4.3903,
      -4.3903,
      -2.7163,
      -4.3903,
      -4.3903,
      -4.3903,
      -4.3903,
      -4.1026,
      -4.1026,
      -4.1026,
      -4.1026,
    ],
  },
  "token.table": {
    Topic: [
      1,
      7,
      8,
      1,
      1,
      5,
      3,
      4,
      4,
      5,
      3,
      2,
      5,
      6,
      6,
      5,
      8,
      8,
      2,
      6,
      1,
      3,
      7,
      7,
      2,
      6,
      3,
      7,
      1,
      2,
      4,
      6,
      3,
      5,
      5,
      6,
      6,
      2,
      2,
      1,
      8,
      1,
      3,
      8,
      4,
      4,
      4,
      2,
      4,
      7,
      8,
      8,
      8,
      8,
      2,
      6,
      4,
      6,
      1,
      2,
      8,
      1,
      3,
      6,
      1,
      2,
      3,
      4,
      6,
      7,
      4,
      7,
      7,
      5,
      8,
      3,
      1,
      8,
      7,
      2,
      8,
      5,
      3,
      2,
      8,
      7,
      7,
      1,
      5,
      3,
      2,
      1,
      7,
      7,
      1,
      7,
      8,
      7,
      4,
      6,
      4,
      3,
      7,
      3,
      7,
      1,
      8,
      8,
      3,
      4,
      8,
      3,
      1,
      2,
      7,
      2,
      1,
      2,
      3,
      3,
      8,
      6,
      4,
      1,
      6,
      6,
      3,
      2,
      5,
      8,
      5,
      5,
      8,
      6,
      3,
      7,
      7,
      5,
      1,
      1,
      7,
      6,
      3,
      4,
      8,
      7,
      1,
      8,
      6,
      4,
      7,
      8,
      2,
      3,
      3,
      4,
      2,
      5,
      3,
      8,
      3,
      7,
      3,
      7,
      1,
      5,
      6,
      2,
      7,
      2,
      7,
      4,
      2,
      7,
      7,
      2,
      3,
      3,
      1,
      1,
      1,
      3,
      4,
      4,
      7,
      6,
      6,
      2,
      6,
      4,
      7,
      5,
      2,
      1,
      2,
      3,
      8,
      6,
      1,
      5,
      2,
      6,
      8,
      5,
      1,
      4,
      7,
      2,
      8,
      8,
      3,
      5,
      1,
      3,
      5,
      2,
      5,
      2,
      2,
      7,
      3,
      6,
      6,
      7,
      1,
      7,
      8,
      5,
      1,
      8,
      5,
      8,
      8,
      6,
      5,
      2,
      2,
      8,
      3,
      7,
      7,
      5,
    ],
    Freq: [
      0.9994869911905443,
      0.999723920655661,
      0.9997630350318172,
      0.9994869911905443,
      0.9994869911905443,
      0.9992988302158642,
      0.999796807168838,
      0.9994696947496418,
      0.9994696947496418,
      0.9992988302158642,
      0.999796807168838,
      1.0013736575876506,
      0.9992988302158642,
      0.9995720382506165,
      0.9995720382506164,
      0.9992988302158642,
      0.9997630350318174,
      0.9997630350318174,
      0.5558734093004866,
      0.4446987274403892,
      0.04998706120767679,
      0.39989648966141433,
      0.5498576732844447,
      0.9997239206556608,
      0.8008102514815656,
      0.2002025628703914,
      0.999796807168838,
      0.9997239206556608,
      0.9994869911905443,
      1.0013736575876504,
      0.9994696947496418,
      0.9995720382506165,
      0.999796807168838,
      0.9992988302158643,
      0.9992988302158643,
      0.9995720382506165,
      0.9995720382506165,
      1.0013736575876504,
      1.0013736575876504,
      0.6247440561034477,
      0.37484643366206866,
      0.9994869911905443,
      0.999796807168838,
      0.9997630350318172,
      0.9994696947496415,
      0.9994696947496415,
      0.9994696947496415,
      1.0013736575876506,
      0.9994696947496418,
      0.05880945494714327,
      0.9409512791542923,
      0.9997630350318173,
      0.9997630350318172,
      0.9997630350318172,
      0.7506919831301296,
      0.25023066104337655,
      0.9994696947496418,
      0.9995720382506165,
      0.7000365709510536,
      0.30001567326473727,
      0.9997630350318174,
      0.2856217595355051,
      0.5712435190710102,
      0.14281087976775256,
      0.9994869911905443,
      0.5220263386102811,
      0.08700438976838018,
      0.13050658465257028,
      0.04350219488419009,
      0.21751097442095046,
      0.9994696947496418,
      0.9997239206556608,
      0.9997239206556608,
      0.9992988302158642,
      0.9997630350318173,
      0.999796807168838,
      0.9994869911905443,
      0.9997630350318172,
      0.9997239206556608,
      1.0013736575876506,
      0.9997630350318172,
      0.9992988302158642,
      0.999796807168838,
      1.0013736575876504,
      0.9997630350318172,
      0.999723920655661,
      0.9997239206556608,
      0.9994869911905443,
      0.9992988302158642,
      0.999796807168838,
      1.0013736575876506,
      0.5830916572552349,
      0.41649404089659636,
      0.9997239206556608,
      0.24992105806172069,
      0.24992105806172069,
      0.49984211612344137,
      0.9997239206556608,
      0.9994696947496418,
      0.9995720382506165,
      0.9994696947496418,
      0.4165642869769295,
      0.5831900017677013,
      0.3635456085864227,
      0.6362048150262398,
      0.5997584342595897,
      0.39983895617305987,
      0.9997630350318172,
      0.999796807168838,
      0.9994696947496415,
      0.9997630350318172,
      0.999796807168838,
      0.07695895420370621,
      0.4617537252222373,
      0.4617537252222373,
      1.0013736575876506,
      0.9994869911905443,
      0.4547786096758669,
      0.5457343316110403,
      0.999796807168838,
      0.9997630350318172,
      0.9995720382506164,
      0.9994696947496418,
      0.9994869911905443,
      0.9995720382506165,
      0.9995720382506164,
      0.999796807168838,
      1.0013736575876506,
      0.33320275072706096,
      0.6664055014541219,
      0.9992988302158642,
      0.7773126274545695,
      0.222089322129877,
      0.9995720382506165,
      0.5383340124179806,
      0.46142915350112623,
      0.9997239206556608,
      0.9992988302158642,
      0.9994869911905442,
      0.9994869911905442,
      0.999723920655661,
      0.9995720382506165,
      0.999796807168838,
      0.9994696947496415,
      0.9997630350318174,
      0.9997239206556608,
      0.33322366782584983,
      0.6664473356516997,
      0.9995720382506164,
      0.9994696947496415,
      0.9997239206556608,
      0.9997630350318174,
      1.0013736575876506,
      0.999796807168838,
      0.999796807168838,
      0.9994696947496418,
      1.0013736575876506,
      0.9992988302158642,
      0.999796807168838,
      0.9997630350318174,
      0.7998257832127914,
      0.19995644580319785,
      0.7998257832127914,
      0.19995644580319785,
      0.9994869911905443,
      0.9992988302158642,
      0.9995720382506165,
      0.7785606725692458,
      0.22244590644835593,
      1.0013736575876504,
      0.9997239206556608,
      0.9994696947496418,
      0.7507205348386776,
      0.2502401782795592,
      0.999723920655661,
      1.0013736575876506,
      0.999796807168838,
      0.999796807168838,
      0.9994869911905443,
      0.9994869911905443,
      0.9994869911905443,
      0.999796807168838,
      0.9994696947496415,
      0.9994696947496415,
      0.9997239206556608,
      0.9995720382506164,
      0.9995720382506164,
      0.7699671716067965,
      0.23099015148203897,
      0.9994696947496415,
      0.9997239206556608,
      0.9992988302158642,
      1.0013736575876506,
      0.9994869911905443,
      0.4001694277818661,
      0.5002117847273326,
      0.10004235694546652,
      0.9995720382506165,
      0.9994869911905443,
      0.9992988302158643,
      1.0013736575876506,
      0.9995720382506165,
      0.9997630350318172,
      0.9992988302158642,
      0.9994869911905443,
      0.749649929327814,
      0.24988330977593803,
      1.0013736575876504,
      0.9997630350318172,
      0.9997630350318173,
      0.999796807168838,
      0.9992988302158642,
      0.9994869911905443,
      0.999796807168838,
      0.9992988302158642,
      1.0013736575876506,
      0.9992988302158642,
      1.0013736575876506,
      1.0013736575876506,
      0.999723920655661,
      0.999796807168838,
      0.9995720382506165,
      0.9995720382506165,
      0.9997239206556611,
      0.24991861372685187,
      0.49983722745370374,
      0.24991861372685187,
      0.9992988302158642,
      0.9994869911905443,
      0.9997630350318173,
      0.33320275072706096,
      0.6664055014541219,
      0.9997630350318172,
      0.9995720382506165,
      0.9992988302158643,
      1.0013736575876506,
      1.0013736575876506,
      0.9997630350318174,
      0.999796807168838,
      0.9997239206556611,
      0.999723920655661,
      0.9992988302158642,
    ],
    Term: [
      "aadmi",
      "accident_pune",
      "africa",
      "ambedkar",
      "america",
      "ancient",
      "andhra",
      "angle",
      "approve",
      "art",
      "article",
      "ask",
      "audience",
      "azharuddeen",
      "banerjee",
      "become",
      "bell",
      "bell_palsy",
      "bengal",
      "bengal",
      "biden",
      "biden",
      "biden",
      "biden_tweet",
      "bjp",
      "bjp",
      "board",
      "bridge",
      "brutal",
      "california",
      "campaign",
      "celebrate",
      "change",
      "charge",
      "chargeable",
      "chaudhary",
      "chhara",
      "chief",
      "chief_guest",
      "child",
      "child",
      "child_kidnapping",
      "church",
      "coach",
      "coca",
      "coca_cola",
      "cola",
      "come",
      "company",
      "covid",
      "covid",
      "covid_vaccine",
      "crowd",
      "crowd_singing",
      "day",
      "day",
      "death",
      "defy",
      "delhi",
      "delhi",
      "dubai",
      "edit",
      "edit",
      "edit",
      "edited",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "female",
      "film",
      "flyover",
      "gaba",
      "gabba",
      "gandhi",
      "gang",
      "gates",
      "genocide",
      "ghaziabad",
      "give",
      "good",
      "government",
      "guest",
      "gujarat",
      "happen",
      "hazara",
      "health",
      "hima",
      "hindu",
      "home",
      "hyderabad",
      "hyderabad",
      "impersonate",
      "incident",
      "incident",
      "incident",
      "invite",
      "involve",
      "islamic",
      "issues",
      "joe",
      "joe",
      "joe_biden",
      "joe_biden",
      "kidnapping",
      "kidnapping",
      "kite",
      "know",
      "kumar",
      "lakh",
      "last",
      "leader",
      "leader",
      "leader",
      "leader_shop",
      "leave",
      "letter",
      "letter",
      "loan",
      "look",
      "madrasas",
      "majid",
      "maker",
      "mamata",
      "mamata_banerjee",
      "mandir",
      "marine",
      "mataram",
      "mataram",
      "match",
      "message",
      "message",
      "minute",
      "modi",
      "modi",
      "moreover",
      "morning",
      "murder",
      "murder_hyderabad",
      "narendra_modi",
      "national",
      "nationality",
      "ndtv",
      "nurse",
      "occupy",
      "official",
      "official",
      "order",
      "paddy",
      "pakistan",
      "palsy",
      "parade",
      "pass",
      "passport",
      "peace",
      "peddle",
      "percent",
      "person",
      "place",
      "pm",
      "pm",
      "pm_modi",
      "pm_modi",
      "police",
      "policy",
      "prayer",
      "president",
      "president",
      "president_suriname",
      "presidential",
      "prize",
      "protest",
      "protest",
      "pune",
      "put",
      "quote",
      "rabindranath",
      "rajasthan",
      "rajasthan_share",
      "rajasthan_shared",
      "ram",
      "ravish",
      "ravish_kumar",
      "read",
      "recite",
      "reciting_islamic",
      "republic",
      "republic",
      "rice",
      "rss",
      "rule",
      "sack",
      "saheb",
      "say",
      "say",
      "say",
      "school",
      "see",
      "send",
      "showcased",
      "shut",
      "side",
      "singing_vande",
      "somnath",
      "support",
      "support",
      "suriname",
      "taiwan",
      "take",
      "tamil",
      "tank",
      "tell",
      "temple",
      "test",
      "tmc",
      "tokyo",
      "tractor",
      "tractor_parade",
      "truck",
      "trump",
      "tv",
      "tweaked",
      "tweet",
      "us",
      "us",
      "us",
      "user",
      "vaccinate",
      "vaccine",
      "vande",
      "vande",
      "vande_mataram",
      "verse",
      "whatsapp",
      "wheat",
      "wildfire",
      "win",
      "wishing",
      "world",
      "world_leader",
      "yet",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6, 7, 8],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://factly.in/telugu-edited-video-falsely-shared-as-police-warning-on-gorakhpur-kidnap-gang/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c17\u0c4b\u0c30\u0c16\u0c4d\u200c\u0c2a\u0c42\u0c30\u0c4d \u0c15\u0c3f\u0c21\u0c4d\u0c28\u0c3e\u0c2a\u0c4d \u0c2e\u0c41\u0c20\u0c3e \u0c17\u0c41\u0c30\u0c3f\u0c02\u0c1a\u0c3f \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c39\u0c46\u0c1a\u0c4d\u0c1a\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-show-a-murder-happened-in-hyderabad-not-delhi/",
        headline: " This video shows a murder in Hyderabad, not Delhi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/video-of-murder-in-rajasthan-shared-with-false-child-kidnapping-claim-11621",
        headline:
          "Video Of Murder In Rajasthan Shared With False Child Kidnapping Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/video-of-brutal-hyderabad-murder-viral-as-delhi-11640",
        headline: "Video Of Brutal Hyderabad Murder Viral As Delhi",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/aam-aadmi-party-somnath-bharti-police-custody-11604",
        headline:
          "\u0906\u092e \u0906\u0926\u092e\u0940 \u092a\u093e\u0930\u094d\u091f\u0940 \u0915\u0947 \u0938\u094b\u092e\u0928\u093e\u0925 \u092d\u093e\u0930\u0924\u0940 \u0915\u0940 \u090f\u0921\u093f\u091f \u0915\u0940 \u0939\u0941\u0908 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/viral-image-baba-saheb-ambedkar-library-in-america-vincent-callebaut-architecure-tour-and-taxis-11605",
        headline:
          "\u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u0940 \u0907\u092e\u093e\u0930\u0924 \u0905\u092e\u0947\u0930\u093f\u0915\u093e \u092e\u0947\u0902 \u092c\u093e\u092c\u093e \u0938\u093e\u0939\u092c \u0906\u0902\u092c\u0947\u0921\u0915\u0930 \u0915\u0940 \u0932\u093e\u0907\u092c\u094d\u0930\u0947\u0930\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/delhi-karol-bagh-hyderabad-rajendranagar-murder-case-viral-video-11643",
        headline:
          "\u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u092e\u0947\u0902 \u0939\u0941\u090f \u0907\u0938 \u092e\u0930\u094d\u0921\u0930 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u093f\u0932\u094d\u0932\u0940 \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/viral-video-of-child-killed-in-rajasthan-shared-with-false-claim/",
        headline:
          "Viral Video of Child Killed in Rajasthan Shared With False Claim",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/video-of-man-hacked-to-death-in-hyd-shared-as-incident-in-delhi-fact-check/",
        headline:
          "AIMIM Leader\u2019s Murder in Hyderabad Shared as Incident in Delhi",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-vaccination-tumkur-health-official-viral-video-karnataka-11659",
        headline:
          "\u0939\u092e\u0928\u0947\u0902 \u091f\u0940\u0915\u093e \u0932\u0917\u0935\u093e \u0932\u093f\u092f\u093e \u0925\u093e: \u091f\u0941\u092e\u0915\u0941\u0930 \u0938\u094d\u0935\u093e\u0938\u094d\u0925\u094d\u092f \u0905\u0927\u093f\u0915\u093e\u0930\u093f\u092f\u094b\u0902 \u0928\u0947 \u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u092c\u0924\u093e\u092f\u093e \u092d\u094d\u0930\u093e\u092e\u0915",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-decade-old-image-of-friday-namaz-at-us-capitol-hill-viral-with-misleading-claims-1762125-2021-01-24",
        headline:
          "Fact Check: Decade-old image of Friday namaz at US Capitol Hill viral with misleading claims\u00a0",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/21/fact-check-old-picture-of-police-van-shared-as-up-police-leaving-for-mumbai-to-arresting-tandav-makers/",
        headline:
          "Fact Check: Old Picture Of Police Van Shared As That Of UP Police Leaving For Mumbai To Arrest \u2018Tandav\u2019 Makers",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/22/fact-check-horrific-murder-video-from-hyderabad-falsely-attributed-to-delhi/",
        headline:
          "Fact Check: Horrific Murder Video From Hyderabad Falsely Attributed To Delhi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/video-of-murder-in-rajasthan-shared-with-false-child-kidnapping-claim-11621",
        headline:
          "Video Of Murder In Rajasthan Shared With False Child Kidnapping Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/video-of-brutal-hyderabad-murder-viral-as-delhi-11640",
        headline: "Video Of Brutal Hyderabad Murder Viral As Delhi",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-baba-saheb-ambedkar-library-in-america-vincent-callebaut-architecure-tour-and-taxis-11605",
        headline:
          "\u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u0940 \u0907\u092e\u093e\u0930\u0924 \u0905\u092e\u0947\u0930\u093f\u0915\u093e \u092e\u0947\u0902 \u092c\u093e\u092c\u093e \u0938\u093e\u0939\u092c \u0906\u0902\u092c\u0947\u0921\u0915\u0930 \u0915\u0940 \u0932\u093e\u0907\u092c\u094d\u0930\u0947\u0930\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/fake-vaccination-tumkur-health-official-viral-video-karnataka-11659",
        headline:
          "\u0939\u092e\u0928\u0947\u0902 \u091f\u0940\u0915\u093e \u0932\u0917\u0935\u093e \u0932\u093f\u092f\u093e \u0925\u093e: \u091f\u0941\u092e\u0915\u0941\u0930 \u0938\u094d\u0935\u093e\u0938\u094d\u0925\u094d\u092f \u0905\u0927\u093f\u0915\u093e\u0930\u093f\u092f\u094b\u0902 \u0928\u0947 \u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u092c\u0924\u093e\u092f\u093e \u092d\u094d\u0930\u093e\u092e\u0915",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/aam-aadmi-party-somnath-bharti-police-custody-11604",
        headline:
          "\u0906\u092e \u0906\u0926\u092e\u0940 \u092a\u093e\u0930\u094d\u091f\u0940 \u0915\u0947 \u0938\u094b\u092e\u0928\u093e\u0925 \u092d\u093e\u0930\u0924\u0940 \u0915\u0940 \u090f\u0921\u093f\u091f \u0915\u0940 \u0939\u0941\u0908 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/delhi-karol-bagh-hyderabad-rajendranagar-murder-case-viral-video-11643",
        headline:
          "\u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u092e\u0947\u0902 \u0939\u0941\u090f \u0907\u0938 \u092e\u0930\u094d\u0921\u0930 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0926\u093f\u0932\u094d\u0932\u0940 \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/video-of-murder-in-rajasthan-shared-with-false-child-kidnapping-claim-11662",
        headline:
          "Rajasthan'\u09b0 \u09b6\u09bf\u09b6\u09c1 \u0996\u09c1\u09a8\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993, \u09b6\u09bf\u09b6\u09c1 \u0985\u09aa\u09b9\u09b0\u09a3\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7 Viral",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2009-photo-of-friday-namaz-at-us-capitol-hill-falsely-claims-to-show-bidens-new-america-673231",
        headline:
          "Fact Check: 2009 photo of Friday namaz at US Capitol Hill falsely claims to show 'Biden's new America'",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/viral/fact-check-edited-image-of-singapore-airlines-plane-viral-with-fake-claim/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0a38\u0a3f\u0a70\u0a17\u0a3e\u0a2a\u0a41\u0a30 \u0a0f\u0a05\u0a30 \u0a32\u0a3e\u0a08\u0a28\u0a1c\u0a3c \u0a26\u0a47 \u0a2a\u0a32\u0a47\u0a28 \u0a26\u0a47 \u0a09\u0a71\u0a24\u0a47 \u0a10\u0a21\u0a3f\u0a1f \u0a15\u0a30\u0a15\u0a47 \u0a32\u0a3f\u0a16\u0a3f\u0a06 \u0a17\u0a3f\u0a06 \u0a39\u0a48 \u0a16\u0a3e\u0a32\u0a3f\u0a38\u0a24\u0a3e\u0a28",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/this-video-does-not-show-protesting-farmers-rehearsal-of-proposed-tractor-parade-on-republic-day/",
        headline:
          " This video does not show protesting farmers\u2019 rehearsal of the proposed tractor parade on Republic day",
      },
      {
        url:
          "https://factly.in/president-of-suriname-is-not-the-chief-guest-for-this-years-republic-day/",
        headline:
          " The President of Suriname is not the chief guest for this year\u2019s Republic Day",
      },
      {
        url:
          "https://factly.in/telugu-old-video-falsely-shared-as-punjab-farmers-spoiling-wheat-by-pouring-water-on-them/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, \u0c2a\u0c02\u0c1c\u0c3e\u0c2c\u0c4d \u0c32\u0c4b \u0c17\u0c4b\u0c27\u0c41\u0c2e\u0c32\u0c41 \u0c15\u0c41\u0c33\u0c4d\u0c32\u0c3f\u0c2a\u0c4b\u0c35\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c35\u0c3e\u0c1f\u0c3f \u0c2a\u0c48 \u0c28\u0c40\u0c33\u0c4d\u0c32\u0c41 \u0c2a\u0c4b\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/19/fact-check-video-from-ireland-falsely-linked-to-upcoming-kisan-tractor-parade/",
        headline:
          "Fact Check: Video From Ireland Falsely Linked To Upcoming \u2018Kisan Tractor Parade\u2019",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/20/fact-check-no-surinames-president-chandrikapersad-santokhi-is-not-the-chief-guest-for-2021-republic-day-celebration/",
        headline:
          "Fact Check: No, Suriname\u2019s President Chandrikapersad Santokhi Is NOT The Chief Guest For 2021 Republic Day Celebration",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-president-of-suriname-is-not-the-chief-guest-for-2021-republic-day-11602",
        headline:
          "No, President Of Suriname Is Not The Chief Guest For Republic Day 2021",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-letter-bjp-delhi-farmers-protest-tractor-rally-january-26-republic-day-violence-11611",
        headline:
          "Delhi BJP Says Letter Instigating Party Workers Against Farmers Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/bjp-west-bengal-tmc-trinamool-congress-mamata-banerjee-basirhat-shop-school-burnt-california-fire-11615",
        headline:
          "BJP Bengal Shares California Fire Image As Leader's Shop Burnt By TMC",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/delhi-bjp-letter-rajesh-bhatia-kisan-andolan-fake-11626",
        headline:
          "\u092d\u093e\u091c\u092a\u093e \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0907\u0938 \u092a\u0924\u094d\u0930 \u0915\u094b \u092a\u093e\u0930\u094d\u091f\u0940 \u0928\u0947 \u095e\u0930\u094d\u095b\u0940 \u092c\u0924\u093e\u092f\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/rabindranath-tagores-kalantor-letter-to-kalidas-nag-on-religion-misleadingly-quotes-hindu-muslim-christian-zee-debate-bjp-leader-11598",
        headline: null,
      },
      {
        url:
          "https://www.altnews.in/bjp-bengal-uses-2014-image-of-california-wildfires-in-poster-against-tmc-violence/",
        headline:
          "BJP Bengal uses 2014 image of California wildfires in poster against TMC",
      },
      {
        url:
          "https://www.altnews.in/hindi/bjp-bengal-uses-2014-image-of-california-wildfires-in-poster-against-tmc-violence/",
        headline:
          "\u092c\u0902\u0917\u093e\u0932 BJP \u0928\u0947 TMC \u0915\u094b \u0928\u093f\u0936\u093e\u0928\u093e \u092c\u0928\u093e\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u0915\u0948\u0932\u093f\u095e\u094b\u0930\u094d\u0928\u093f\u092f\u093e \u092e\u0947\u0902 \u0932\u0917\u0940 \u0906\u0917 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0936\u0947\u092f\u0930 \u0915\u0940",
      },
      {
        url:
          "https://www.altnews.in/hindi/suriname-president-chandrikapersad-santokhi-wont-be-republic-day-chief-guest-media-misreport/",
        headline:
          "\u0928 \u0938\u0942\u0930\u0940\u0928\u093e\u092e \u0915\u0947 \u0930\u093e\u0937\u094d\u091f\u094d\u0930\u092a\u0924\u093f, \u0928 \u0915\u094b\u0908 \u0914\u0930 \u0935\u093f\u0926\u0947\u0936\u0940 \u0928\u0947\u0924\u093e \u0907\u0938 \u0917\u0923\u0924\u0902\u0924\u094d\u0930 \u0926\u093f\u0935\u0938 \u092a\u0930 \u092e\u0941\u0916\u094d\u092f \u0905\u0924\u093f\u0925\u093f \u0915\u0947 \u0924\u094c\u0930 \u092a\u0930 \u0906\u092f\u0947\u0902\u0917\u0947",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/vehicles-with-christmas-decorations-falsely-linked-to-farmers-republic-day-rally/",
        headline:
          "Old Christmas Revelry Video Falsely Linked to Farmers\u2019 R-Day Rally",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/bjp-bengal-uses-pic-from-california-to-blame-tmc-vandalised-homes/",
        headline:
          "BJP Bengal Uses  Pic From California to Say TMC Vandalised Homes",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/farmer-sprinkling-water-on-wheat-sacks-fake-news-fact-check/",
        headline:
          "Viral Video From 2018 Used to Discredit Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/up-police-muslim-bareilly-ghaziabad-viral-video-fake-news-fact-check-11655",
        headline:
          "2018 Clip Showing Attack On Ghaziabad Cop Peddled With Communal Spin",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/viral-video-2018-ghaziabad-police-muslim-women-bareilly-viral-as-recent-11666",
        headline:
          "\u0917\u093e\u095b\u093f\u092f\u093e\u092c\u093e\u0926 \u092e\u0947\u0902 \u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u0940 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0915\u093e \u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092b\u093f\u0930 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/viral-image-dwarka-nagari-underwater-gujarat-bali-devata-vishnu-temple-11686",
        headline:
          "9 \u0939\u091c\u093e\u0930 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0926\u094d\u0935\u093e\u0930\u0915\u093e \u0928\u0917\u0930\u0940 \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0939\u093e\u0902 \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://factly.in/marine-habitats-photos-shared-as-underwater-pictures-of-dwaraka-city-in-india/",
        headline:
          " Marine habitats photos shared as underwater pictures of Dwaraka city in India",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/19/fact-check-video-from-ireland-falsely-linked-to-upcoming-kisan-tractor-parade/",
        headline:
          "Fact Check: Video From Ireland Falsely Linked To Upcoming \u2018Kisan Tractor Parade\u2019",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/20/fact-check-no-surinames-president-chandrikapersad-santokhi-is-not-the-chief-guest-for-2021-republic-day-celebration/",
        headline:
          "Fact Check: No, Suriname\u2019s President Chandrikapersad Santokhi Is NOT The Chief Guest For 2021 Republic Day Celebration",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/22/fact-check-old-video-from-2018-falsely-linked-to-the-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Video from 2018 Falsely Linked To The Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/23/fact-check-did-bjps-delhi-unit-leader-ask-supporters-to-resort-to-violence-against-protesting-farmers-heres-the-truth/",
        headline:
          "Fact Check: Did BJP\u2019s Delhi Unit Leader Ask Supporters To Resort To Violence Against Protesting Farmers? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/farmers-protest-delhi-borders-tractor-rally-republic-day-parade-january-26-viral-video-christmas-parade-11597",
        headline:
          "Christmas Procession In Germany Viral As Farmers Rehearsing For R-Day",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/up-police-muslim-bareilly-ghaziabad-viral-video-fake-news-fact-check-11655",
        headline:
          "2018 Clip Showing Attack On Ghaziabad Cop Peddled With Communal Spin",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-president-of-suriname-is-not-the-chief-guest-for-2021-republic-day-11602",
        headline:
          "No, President Of Suriname Is Not The Chief Guest For Republic Day 2021",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-letter-bjp-delhi-farmers-protest-tractor-rally-january-26-republic-day-violence-11611",
        headline:
          "Delhi BJP Says Letter Instigating Party Workers Against Farmers Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/bjp-west-bengal-tmc-trinamool-congress-mamata-banerjee-basirhat-shop-school-burnt-california-fire-11615",
        headline:
          "BJP Bengal Shares California Fire Image As Leader's Shop Burnt By TMC",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-wheat-destroying-crops-video-fci-punjab-distilleries-farmers-protest-11613",
        headline:
          "Old Video Of Man Destroying Wheat Produce Falsely Linked To Farmers' Protest",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-video-2018-ghaziabad-police-muslim-women-bareilly-viral-as-recent-11666",
        headline:
          "\u0917\u093e\u095b\u093f\u092f\u093e\u092c\u093e\u0926 \u092e\u0947\u0902 \u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u0940 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0915\u093e \u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092b\u093f\u0930 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-germany-sanatan-gurukul-iskcon-west-bengal-mayapur-bhaktivedanta-academy-11638",
        headline:
          "\u091c\u0930\u094d\u092e\u0928\u0940 \u0915\u0947 \u0917\u0941\u0930\u0941\u0915\u0941\u0932 \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0905\u0938\u0932 \u092e\u0947\u0902 \u0915\u0939\u093e\u0902 \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/delhi-bjp-letter-rajesh-bhatia-kisan-andolan-fake-11626",
        headline:
          "\u092d\u093e\u091c\u092a\u093e \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0907\u0938 \u092a\u0924\u094d\u0930 \u0915\u094b \u092a\u093e\u0930\u094d\u091f\u0940 \u0928\u0947 \u095e\u0930\u094d\u095b\u0940 \u092c\u0924\u093e\u092f\u093e",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-dwarka-nagari-underwater-gujarat-bali-devata-vishnu-temple-11686",
        headline:
          "9 \u0939\u091c\u093e\u0930 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0926\u094d\u0935\u093e\u0930\u0915\u093e \u0928\u0917\u0930\u0940 \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0939\u093e\u0902 \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-fact-check-president-of-suriname-is-not-the-chief-guest-for-2021-republic-day-11625",
        headline:
          "\u09a8\u09be, Surinam-\u098f\u09b0 \u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09aa\u09a4\u09bf \u09e8\u09e6\u09e8\u09e7-\u098f\u09b0 Reoublic Day-\u098f\u09b0 \u09aa\u09cd\u09b0\u09a7\u09be\u09a8 \u0985\u09a4\u09bf\u09a5\u09bf \u09a8\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/farmers-protest-delhi-borders-tractor-rally-republic-day-parade-january-26-viral-video-christmas-parade-11632",
        headline:
          "Republic Day \u09a4\u09c7 \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 Tractor Rally \u09ae\u09b9\u09dc\u09be \u09ac\u09b2\u09c7 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf Viral",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/bjp-west-bengal-tmc-trinamool-congress-mamata-banerjee-basirhat-shop-school-burnt-california-fire-11637",
        headline:
          "TMC \u0995\u09b0\u09cd\u09ae\u09c0\u09b0\u09be BJP \u09a8\u09c7\u09a4\u09be\u09b0 \u09a6\u09cb\u0995\u09be\u09a8 \u09aa\u09c1\u09dc\u09bf\u09df\u09c7\u099b\u09c7 \u09ac\u09b2\u09c7 \u09ab\u09c7\u09b8\u09ac\u09c1\u0995\u09c7 \u09ac\u09bf\u09a6\u09c7\u09b6\u09c7\u09b0 \u099b\u09ac\u09bf \u09aa\u09cb\u09b8\u09cd\u099f",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2018-video-showing-man-spraying-water-on-wheat-sacks-falsely-linked-to-2020-farmers-protest-673173",
        headline:
          "Fact Check: 2018 video showing man spraying water on wheat sacks falsely linked to 2020 farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-true-newly-inducted-rafale-jets-to-be-showcased-at-republic-day-parade-673043",
        headline:
          "Fact check: True, newly inducted Rafale jets to be showcased at Republic day parade",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/politics/fact-check-a-fake-letter-being-shared-by-users-on-social-media-claiming-delhi-bjp-is-asking-party-workers-to-be-ready-for-any-possible-clash/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0a15\u0a3f\u0a38\u0a3e\u0a28 \u0a05\u0a70\u0a26\u0a4b\u0a32\u0a28 \u0a26\u0a47 \u0a38\u0a70\u0a2c\u0a70\u0a27 \u0a35\u0a3f\u0a71\u0a1a \u0a2c\u0a40 \u0a1c\u0a47 \u0a2a\u0a40 \u0a28\u0a47\u0a24\u0a3e \u0a26\u0a47 \u0a28\u0a3e\u0a2e \u0a24\u0a4b\u0a02 \u0a35\u0a3e\u0a07\u0a30\u0a32 \u0a39\u0a4b \u0a30\u0a39\u0a40 \u0a2d\u0a5c\u0a15\u0a3e\u0a0a \u0a1a\u0a3f\u0a71\u0a20\u0a40 \u0a2b\u0a47\u0a15",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/fact-check-reliance-group-donation-solar-plant-ram-mandir-false/",
        headline:
          "Fake account in Nita Ambani\u2019s name claims Reliance gifted solar power plant for Ram Mandir",
      },
      {
        url:
          "https://www.altnews.in/fake-newspaper-clip-modi-farmer-muslim-amit-shah-fake-quotes/",
        headline:
          "False statement of killing Muslims and farmers ascribed to PM Modi",
      },
      {
        url:
          "https://www.altnews.in/hindi/fact-check-cbsc-didnt-decrease-passing-marks-by-23-from-33-false-claim-viral/",
        headline:
          "\u0905\u092c \u092c\u094b\u0930\u094d\u0921 \u092a\u0930\u0940\u0915\u094d\u0937\u093e \u092e\u0947\u0902 33% \u0928\u0939\u0940\u0902 \u0915\u0947\u0935\u0932 23% \u0928\u0902\u092c\u0930\u094b\u0902 \u092e\u0947\u0902 \u092a\u093e\u0938 \u0939\u094b \u091c\u093e\u090f\u0902\u0917\u0947 \u091b\u093e\u0924\u094d\u0930? \u095a\u0932\u0924 \u0926\u093e\u0935\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://factly.in/telugu-india-gate-does-not-have-the-names-of-freedom-fighters-inscribed-on-it/",
        headline:
          " \u0c07\u0c02\u0c21\u0c3f\u0c2f\u0c3e \u0c17\u0c47\u0c1f\u0c41 \u0c2a\u0c48 \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36 \u0c38\u0c4d\u0c35\u0c3e\u0c24\u0c02\u0c24\u0c4d\u0c30\u0c02 \u0c15\u0c4b\u0c38\u0c02 \u0c09\u0c26\u0c4d\u0c2f\u0c2e\u0c3f\u0c02\u0c1a\u0c3f \u0c2e\u0c30\u0c23\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c41\u0c32 \u0c2a\u0c47\u0c30\u0c4d\u0c32\u0c41 \u0c30\u0c3e\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/government-of-india-did-not-announce-any-revised-pass-percentage-criteria-for-board-examinations-2021/",
        headline:
          " Government of India did not announce any revised pass percentage criteria for 2021 Board Examinations",
      },
      {
        url:
          "https://factly.in/up-government-introduced-gst-on-church-offerings-no-its-an-edited-newspaper-clipping/",
        headline:
          " UP government introduced GST on Church offerings? No, it\u2019s an edited newspaper clipping",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/19/fact-check-no-government-has-not-removed-nationality-column-from-indian-passports/",
        headline:
          "Fact Check: No, Government Has Not Removed Nationality Column From Indian Passports",
      },
      {
        url:
          "https://www.altnews.in/hoax-letter-claims-donald-trump-wrote-joe-you-know-i-won-to-joe-biden/",
        headline:
          "Hoax letter claims Donald Trump wrote \u201cJoe, You know I won\u201d to Joe Biden",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/tirupati-temple-hasnt-donated-rs-100-crore-to-ram-mandir-fact-check/",
        headline:
          "No, Tirupati  Temple Hasn\u2019t Donated Rs 100 Crore To Ram Mandir",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/donald-trump-letter-to-joe-biden-fact-check/",
        headline:
          "Did Donald Trump Say \u2018He Won\u2019 in His Last Letter to Joe Biden?",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/false-quotes-on-ram-mandir-farmers-ascribed-to-amit-shah-narendra-modi/",
        headline:
          "False Quotes on Ram Mandir and Farmers Ascribed to Shah, PM Modi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-priyanka-gandhi-fan-page-post-narendra-modi-joe-biden-congress-priyanka-gandhi-vadra-kamala-harris-11664",
        headline:
          "Priyanka Gandhi Vadra Criticises PM Modi While Wishing Joe Biden? A FactCheck",
      },
      {
        url:
          "https://factly.in/videos-of-2013-chemical-attack-in-syria-are-passed-off-as-those-from-tamil-nadu/",
        headline:
          " Video of a 2013 chemical attack in Syria are passed off as those from Tamil Nadu",
      },
      {
        url:
          "https://factly.in/telugu-2008-video-falsely-shared-as-christians-arrested-for-making-bombs-in-a-church/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, \u2018\u0c24\u0c2e\u0c3f\u0c33\u0c28\u0c3e\u0c21\u0c41 \u0c1a\u0c30\u0c4d\u0c1a\u0c3f\u0c32\u0c4b \u0c28\u0c3e\u0c1f\u0c41 \u0c2c\u0c3e\u0c02\u0c2c\u0c41\u0c32\u0c41 \u2013 \u0c15\u0c4d\u0c30\u0c48\u0c38\u0c4d\u0c24\u0c35\u0c41\u0c32\u0c41 \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d\u2019 \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-up-government-introduced-gst-on-church-offerings-no-its-an-edited-newspaper-clipping/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f \u0c1a\u0c30\u0c4d\u0c1a\u0c3f\u0c32\u0c15\u0c3f \u0c35\u0c1a\u0c4d\u0c1a\u0c3f\u0c28 \u0c15\u0c3e\u0c28\u0c41\u0c15\u0c32 \u0c2a\u0c48 \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c37\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 6% GST \u0c35\u0c3f\u0c27\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/edited-fox-news-screenshot-claims-400000-americans-died-within-9-hours-of-bidens-presidentship/",
        headline:
          " Edited \u2018Fox News\u2019 screenshot claims 400,000 Americans died within 9 hours of Biden\u2019s Presidentship",
      },
      {
        url:
          "https://factly.in/edited-clip-falsely-shared-as-rahul-gandhi-demand-to-not-waive-off-farm-loans/",
        headline:
          " Edited clip falsely shared as Rahul Gandhi\u2019s demand to not waive off farm loans",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-modi-govt-is-not-introducing-one-nation-one-power-tariff-law-1760283-2021-01-18",
        headline:
          "Fact Check: No, Modi govt is not introducing 'one nation, one power tariff' law",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-trump-letter-needling-biden-election-victory-is-fake-1761494-2021-01-21",
        headline:
          "Fact Check: This Trump letter needling Biden on election victory is fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/19/fact-check-no-government-has-not-removed-nationality-column-from-indian-passports/",
        headline:
          "Fact Check: No, Government Has Not Removed Nationality Column From Indian Passports",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/21/fact-check-no-the-passing-percentage-in-board-exams-has-not-been-changed-from-33-to-23/",
        headline:
          "Fact Check: No, The Pass Percentage In Board Exams Has NOT Been Changed From 33% to 23%",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/21/fact-check-no-trump-did-not-say-you-know-i-won-in-his-last-letter-to-biden-heres-the-truth/",
        headline:
          "Fact Check: No, Trump Did NOT Say \u2018You know I won\u2019 In His Last Letter To Biden; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/23/fact-check-did-up-govt-introduce-6-gst-on-church-offerings-heres-the-truth/",
        headline:
          "Fact Check: Did UP Govt Introduce 6% GST On Church Offerings; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/24/fact-check-old-photos-of-vandalism-shared-as-recent-attack-on-temple-in-andhra-pradesh/",
        headline:
          "Fact Check: Old Photos of Vandalism Shared as Recent Attack on Temple in Andhra Pradesh",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-priyanka-gandhi-fan-page-post-narendra-modi-joe-biden-congress-priyanka-gandhi-vadra-kamala-harris-11664",
        headline:
          "Priyanka Gandhi Vadra Criticises PM Modi While Wishing Joe Biden? A FactCheck",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bhagat-singh-photo-1919-jalianwala-bagh-fake-news-11620",
        headline:
          "\u0928\u0939\u0940\u0902, \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u092e\u093e\u0930 \u0916\u093e\u0924\u0947 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u092d\u0917\u0924 \u0938\u093f\u0902\u0939 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/rabindranath-tagores-kalantor-letter-to-kalidas-nag-on-religion-misleadingly-quotes-hindu-muslim-christian-zee-debate-bjp-leader-11598",
        headline:
          "\u09ac\u09bf\u09b6\u09cd\u09b2\u09c7\u09b7\u09a3:\u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u09aa\u09cd\u09b0\u09b8\u0999\u09cd\u0997\u09c7 Kalidas Nag-\u0995\u09c7 \u09b2\u09c7\u0996\u09be Rabindranath Tagore-\u098f\u09b0 \u099a\u09bf\u09a0\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-viral-graphic-modi-government-enacting-one-nation-one-electricity-tariff-11608",
        headline:
          "Fact Check: Modi \u09b8\u09b0\u0995\u09be\u09b0 \u09ac\u09bf\u09a6\u09cd\u09af\u09c1\u09a4\u09c7 '\u098f\u0995 \u09a6\u09c7\u09b6 \u098f\u0995 \u09a6\u09be\u09ae' \u0986\u0987\u09a8 \u0986\u09a8\u09a4\u09c7 \u099a\u09b2\u09c7\u099b\u09c7?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-viral-image-of-virat-kohli-anushka-sharma-newborn-daughter-newsportal-misreports-11653",
        headline:
          "Virat Kohli Anushka Sharma-\u098f\u09b0 \u09b8\u09a6\u09cd\u09af\u099c\u09be\u09a4 \u09ae\u09c7\u09df\u09c7\u09b0 \u099b\u09ac\u09bf \u09ac\u09b2\u09c7 \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf Viral",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-morphed-newspaper-article-falsely-quotes-modi-saying-muslims-had-to-be-killed-to-gain-trust-of-hindus-673225",
        headline:
          "Fact Check: Morphed newspaper article falsely quotes Modi saying Muslims had to be killed to gain trust of Hindus",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-trumps-letter-to-biden-circulating-on-social-media-are-fake-673100",
        headline:
          "Fact Check: Trump's letter to Biden circulating on social media are fake",
      },
    ],
    "4": [
      {
        url:
          "https://factly.in/the-supreme-court-has-not-approved-any-logo-to-be-used-by-teachers-on-their-vehicles/",
        headline:
          " The Supreme Court has not approved any logo to be used by teachers on their vehicles",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/20/fact-check-no-coca-cola-has-not-launched-any-campaign-to-support-farmers-heres-the-truth/",
        headline:
          "Fact Check: No, Coca-Cola Has Not Launched Any Campaign To Support Farmers; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/ndtv-india-jumbles-rice-paddy-data-ravish-kumar-issues-apology-11592",
        headline:
          "NDTV India Jumbles Rice & Paddy Data, Ravish Kumar Issues Apology",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/international-fact-checking-network-nobel-peace-prize-11648",
        headline:
          "International Fact-Checking Network Nominated For Nobel Peace Prize 2021",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-viral-post-claiming-death-of-17-female-docs-in-karnataka-bus-crash-is-misleading-1760296-2021-01-18",
        headline:
          "Fact Check: Viral post claiming death of 17 female docs in Karnataka bus crash is misleading\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-morphed-images-of-thailand-floods-inundate-social-media-1761156-2021-01-21",
        headline:
          "Fact Check: Morphed images of Thailand floods inundate social media",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/20/fact-check-no-coca-cola-has-not-launched-any-campaign-to-support-farmers-heres-the-truth/",
        headline:
          "Fact Check: No, Coca-Cola Has Not Launched Any Campaign To Support Farmers; Here\u2019s The Truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/ndtv-india-jumbles-rice-paddy-data-ravish-kumar-issues-apology-11618",
        headline:
          "NDTV \u09a7\u09be\u09a8 \u0993 \u099a\u09be\u09b2 \u09ad\u09c2\u09b2 \u09a4\u09a5\u09cd\u09af \u09aa\u09b0\u09bf\u09ac\u09c7\u09b6\u09a8 \u09a8\u09bf\u09df\u09c7 Ravish Kumar \u0995\u09cd\u09b7\u09ae\u09be \u099a\u09be\u0987\u09b2\u09c7\u09a8",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/ndtv-india-jumbles-rice-paddy-data-ravish-kumar-issues-apology-11592",
        headline:
          "NDTV India Jumbles Rice & Paddy Data, Ravish Kumar Issues Apology",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/international-fact-checking-network-nobel-peace-prize-11648",
        headline:
          "International Fact-Checking Network Nominated For Nobel Peace Prize 2021",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-claims-coca-cola-supports-farmers-protest-kisan-ekta-share-a-coke-11590",
        headline:
          "\u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915 : \u0915\u094b\u0915\u093e-\u0915\u094b\u0932\u093e \u0915\u0902\u092a\u0928\u0940 \u0928\u0947 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u093e \u0938\u092e\u0930\u094d\u0925\u0928 \u0915\u093f\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-muslim-women-supports-farmers-protest-kisan-morcha-bku-ugrahan-khalistan-11614",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u0936\u093e\u092e\u093f\u0932 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u092e\u0939\u093f\u0932\u093e\u0913\u0902 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0938\u093e\u092e\u094d\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u090f\u0902\u0917\u0932 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/majid-kavousifars-image-shared-as-convicted-hacker-hamza-bendallaz-who-did-charity-work-in-palestine-11616",
        headline:
          "\u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09b9\u09be\u09ae\u099c\u09be \u09ac\u09c7\u09a8\u09a6\u09c7\u09b2\u09be\u099c\u09c7\u09b0 \u09ab\u09be\u0981\u09b8\u09bf \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ae\u09be\u099c\u09bf\u09a6 \u0995\u09be\u09ad\u09cb\u0993\u09b8\u09bf\u09ab\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-claim-that-17-women-doctors-from-ktaka-were-killed-in-road-accident-is-misleading-673168",
        headline:
          "Fact Check: Claim that 17 women doctors from K'taka were killed in road accident is misleading",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/whatsapp-is-not-going-to-charge-their-users-for-sending-messages/",
        headline: " WhatsApp is not going to charge users for sending messages",
      },
      {
        url:
          "https://factly.in/assyrian-art-shared-as-5000-year-old-representation-of-ancient-indian-swimming-with-a-life-tank/",
        headline:
          " Assyrian art shared as a 5000-year-old representation of ancient Indian swimming with a life tank",
      },
      {
        url:
          "https://factly.in/telugu-whatsapp-is-not-going-to-charge-users-for-sending-messages/",
        headline:
          " \u0c35\u0c3f\u0c28\u0c3f\u0c2f\u0c4b\u0c17\u0c26\u0c3e\u0c30\u0c41\u0c32 \u0c28\u0c41\u0c02\u0c21\u0c3f \u0c21\u0c2c\u0c4d\u0c2c\u0c41\u0c32\u0c41 \u0c1b\u0c3e\u0c30\u0c4d\u0c1c\u0c4d \u0c1a\u0c47\u0c38\u0c47 \u0c15\u0c4a\u0c24\u0c4d\u0c24 \u0c2a\u0c3e\u0c32\u0c38\u0c40 \u0c0f\u0c26\u0c40 \u0c35\u0c3e\u0c1f\u0c4d\u0c38\u0c2a\u0c4d \u0c2a\u0c4d\u0c30\u0c35\u0c47\u0c36\u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/whatsapp-good-morning-message-18-gst-fake-news-11641",
        headline:
          "\u0915\u094d\u092f\u093e \u0917\u0941\u0921 \u092e\u0949\u0930\u094d\u0928\u093f\u0902\u0917 \u092e\u0947\u0938\u0947\u091c \u092d\u0947\u091c\u0928\u0947 \u092a\u0930 \u0932\u0917\u0928\u0947 \u091c\u093e \u0930\u0939\u093e \u0939\u0948 18 \u092a\u094d\u0930\u0924\u093f\u0936\u0924 GST?",
      },
      {
        url:
          "https://www.altnews.in/old-hoax-message-that-whatsapp-will-be-chargeable-revived-with-hindi-audio-clip/",
        headline:
          "Old hoax message that WhatsApp will be chargeable revived with Hindi audio clip",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-whatsapp-is-going-to-charge-money-for-its-services-viral-message-11689",
        headline:
          "Viral Messages Claiming WhatsApp Will Become Chargeable Are False",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/viral-video-team-india-fans-cheering-vande-mataram-gabba-asia-cup-dubai-bharat-paksitan-11668",
        headline:
          "'\u0935\u0902\u0926\u0947 \u092e\u093e\u0924\u0930\u092e' \u0917\u093e\u0924\u0947 \u0926\u0930\u094d\u0936\u0915\u094b\u0902 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0917\u093e\u092c\u093e \u091f\u0947\u0938\u094d\u091f \u092e\u0948\u091a \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-whatsapp-is-not-recording-your-calls-but-privacy-concerns-can-t-be-ruled-out-yet-1762105-2021-01-23",
        headline:
          "Fact Check: No, WhatsApp is not recording your calls but privacy concerns can't be ruled out yet",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/22/fact-check-has-golden-girl-hima-das-qualified-for-tokyo-olympics-2021-yet/",
        headline:
          "Fact Check: Has \u2018Golden Girl\u2019 Hima Das Qualified For Tokyo Olympics 2021 Yet?",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-whatsapp-is-going-to-charge-money-for-its-services-viral-message-11689",
        headline:
          "Viral Messages Claiming WhatsApp Will Become Chargeable Are False",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/whatsapp-good-morning-message-18-gst-fake-news-11641",
        headline:
          "\u0915\u094d\u092f\u093e \u0917\u0941\u0921 \u092e\u0949\u0930\u094d\u0928\u093f\u0902\u0917 \u092e\u0947\u0938\u0947\u091c \u092d\u0947\u091c\u0928\u0947 \u092a\u0930 \u0932\u0917\u0928\u0947 \u091c\u093e \u0930\u0939\u093e \u0939\u0948 18 \u092a\u094d\u0930\u0924\u093f\u0936\u0924 GST?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-video-team-india-fans-cheering-vande-mataram-gabba-asia-cup-dubai-bharat-paksitan-11668",
        headline:
          "'\u0935\u0902\u0926\u0947 \u092e\u093e\u0924\u0930\u092e' \u0917\u093e\u0924\u0947 \u0926\u0930\u094d\u0936\u0915\u094b\u0902 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0917\u093e\u092c\u093e \u091f\u0947\u0938\u094d\u091f \u092e\u0948\u091a \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-message-from-whatsapp-director-claiming-app-will-start-charging-users-is-fake-672981",
        headline:
          "Fact Check: Message from WhatsApp 'director' claiming app will start charging users is fake",
      },
    ],
    "6": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/narendra-chaudhary-steel-man-256-bomb-defusal-unit-chattisgarh-11642",
        headline:
          "\u0938\u0948\u0915\u095c\u094b\u0902 \u092c\u092e \u0921\u093f\u092b\u094d\u092f\u0942\u095b \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u0947 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u091a\u094c\u0927\u0930\u0940 \u0915\u093e \u0926\u0947\u0939\u093e\u0902\u0924 2016 \u092e\u0947\u0902 \u0939\u0941\u0906 \u0925\u093e",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/morphed-images-shared-to-claims-mha-has-ordered-to-shut-schools/",
        headline:
          "Morphed Images Shared to Claim MHA Has Ordered to Shut Schools",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/bjp-bengal-shares-clipped-video-of-mamata-banerjee-reciting-islamic-verse-11685",
        headline:
          "BJP Bengal Shares Clipped Video Of Mamata Banerjee Reciting Islamic Verse",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-yogi-govt-will-not-shut-madrasas-that-do-not-celebrate-republic-day-1760946-2021-01-20",
        headline:
          "Fact Check: No, Yogi govt will not shut madrasas that do not celebrate Republic Day",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-edited-clip-of-mamata-reciting-islamic-prayers-linked-to-netaji-event-controversy-1762285-2021-01-24",
        headline:
          "Fact Check: Edited clip of Mamata reciting Islamic prayers linked to Netaji event controversy",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/up-madrasas-ordered-shut-for-not-celebrating-r-day-1761520-2021-01-21",
        headline: "UP madrasas ordered shut for not celebrating R-Day?",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/22/fact-check-has-up-cm-yogi-adityanath-ordered-shutting-down-of-madrasas-if-national-flag-is-not-hoisted-on-republic-day-heres-the-truth/",
        headline:
          "Fact Check: Has UP CM Yogi Adityanath Ordered Shutting Down Of Madrasas If National Flag Is Not Hoisted On Republic Day? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/22/fact-check-dont-fall-for-these-morphed-tv-screengrabs-about-closure-of-schools-and-colleges/",
        headline:
          "Fact Check: Don\u2019t Fall For These Morphed TV Screengrabs About Closure Of Schools And Colleges",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/23/fact-check-did-boris-johnson-cancel-his-republic-day-visit-to-india-due-to-farmers-agitation-heres-the-truth/",
        headline:
          "Fact Check: Did Boris Johnson Cancel His Republic Day Visit To India Due To Farmers Agitation? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/bjp-bengal-shares-clipped-video-of-mamata-banerjee-reciting-islamic-verse-11685",
        headline:
          "BJP Bengal Shares Clipped Video Of Mamata Banerjee Reciting Islamic Verse",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/narendra-chaudhary-steel-man-256-bomb-defusal-unit-chattisgarh-11642",
        headline:
          "\u0938\u0948\u0915\u095c\u094b\u0902 \u092c\u092e \u0921\u093f\u092b\u094d\u092f\u0942\u095b \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u0947 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u091a\u094c\u0927\u0930\u0940 \u0915\u093e \u0926\u0947\u0939\u093e\u0902\u0924 2016 \u092e\u0947\u0902 \u0939\u0941\u0906 \u0925\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/bjp-bengal-shares-clipped-video-of-mamata-banerjee-reciting-islamic-verse-11688",
        headline:
          "BJP Bengal \u099b\u09a1\u09bc\u09be\u09b2 Mamata Banerjee-\u09b0 \u0987\u09b8\u09b2\u09be\u09ae\u09bf \u09b8\u09cd\u09a4\u09cb\u09a4\u09cd\u09b0 \u09aa\u09be\u09a0\u09c7\u09b0 \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/fact-check-fake-news-old-image-smriti-irani-assaduddin-owaisi-meeting-west-bengal-assembly-election-2021-11589",
        headline:
          "West Bengal Assembly Election 2021: \u09ad\u09be\u0987\u09b0\u09be\u09b2 Owaisi - Irani \u098f\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-the-news-minutes-story-on-cricketer-azharuddeen-shared-with-tweaked-headline-673008",
        headline:
          "FACT CHECK: The News Minute's Story on cricketer Azharuddeen shared with tweaked headline",
      },
    ],
    "7": [
      {
        url:
          "https://factly.in/telugu-tata-evision-is-just-a-concept-electric-car/",
        headline:
          " \u0c1f\u0c3e\u0c1f\u0c3e Evision \u0c05\u0c28\u0c47\u0c26\u0c3f \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c15\u0c3e\u0c28\u0c4d\u0c38\u0c46\u0c2a\u0c4d\u0c1f\u0c4d \u0c15\u0c3e\u0c30\u0c41 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47, \u0c2a\u0c48\u0c17\u0c3e \u0c08 \u0c2c\u0c4d\u0c2f\u0c3e\u0c1f\u0c30\u0c40 \u0c35\u0c3f\u0c35\u0c30\u0c3e\u0c32\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c24\u0c2a\u0c4d\u0c2a\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-video-of-a-truck-catching-fire-in-pune-is-passed-off-as-that-from-hyderabad/",
        headline:
          " \u0c2a\u0c42\u0c23\u0c47\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c06\u0c15\u0c4d\u0c38\u0c3f\u0c21\u0c46\u0c02\u0c1f\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c17\u0c1a\u0c4d\u0c1a\u0c3f\u0c2c\u0c4c\u0c32\u0c3f \u0c2b\u0c4d\u0c32\u0c48\u0c13\u0c35\u0c30\u0c4d \u0c2a\u0c48 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-these-placards-ask-to-repeal-the-three-farmer-laws-not-to-abolish-triple-talaq/",
        headline:
          " \u2018\u0c1f\u0c4d\u0c30\u0c3f\u0c2a\u0c41\u0c32\u0c4d \u0c24\u0c32\u0c3e\u0c15\u0c4d\u200c\u0c28\u0c41 \u0c30\u0c26\u0c4d\u0c26\u0c41 \u0c1a\u0c47\u0c2f\u0c02\u0c21\u0c3f\u2019 \u0c05\u0c28\u0c3f \u0c15\u0c3e\u0c26\u0c41, \u0c2e\u0c42\u0c21\u0c41 \u0c30\u0c48\u0c24\u0c41 \u0c1a\u0c1f\u0c4d\u0c1f\u0c3e\u0c32\u0c28\u0c41 \u0c2c\u0c39\u0c3f\u0c37\u0c4d\u0c15\u0c30\u0c3f\u0c02\u0c1a\u0c02\u0c21\u0c28\u0c3f \u0c2a\u0c4d\u0c32\u0c15\u0c3e\u0c30\u0c4d\u0c21\u0c41 \u0c2e\u0c40\u0c26 \u0c30\u0c3e\u0c38\u0c3f \u0c09\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-us-president-joe-biden-did-not-tweet-addressing-pm-modi-as-a-world-leader/",
        headline:
          " \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e \u0c05\u0c27\u0c4d\u0c2f\u0c15\u0c4d\u0c37\u0c41\u0c21\u0c41 \u0c1c\u0c4b \u0c2c\u0c48\u0c21\u0c46\u0c28\u0c4d \u0c2e\u0c4b\u0c26\u0c40\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a \u0c28\u0c3e\u0c2f\u0c15\u0c41\u0c21\u0c28\u0c3f \u0c38\u0c02\u0c2c\u0c4b\u0c27\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c1f\u0c4d\u0c35\u0c40\u0c1f\u0c4d \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/19/fact-check-video-of-accident-from-pune-shared-as-that-of-hyderabad/",
        headline:
          "Fact Check: Video Of Accident From Pune Shared As That Of Hyderabad",
      },
      {
        url:
          "http://newsmobile.in/articles/2021/01/20/fact-check-farmers-didnt-storm-rss-headquarters-over-farm-laws-heres-the-truth/",
        headline:
          "Fact Check: Farmers Didn\u2019t Storm RSS Headquarters Over Farm Laws; Here\u2019s The Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/hyderabad-gachibowli-pune-maharashtra-warje-bridge-truck-11595",
        headline:
          "\u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0915\u0947 \u092a\u0941\u0932 \u092a\u0930 \u0928\u0939\u0940\u0902 \u0932\u0917\u0940 \u091f\u094d\u0930\u0915 \u092e\u0947\u0902 \u0906\u0917, \u092f\u0939 \u0918\u091f\u0928\u093e \u092a\u0941\u0923\u0947 \u0915\u0940 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check-video-of-protest-march-kargil-against-hazara-killings-shared-as-pok-11587",
        headline:
          "Hazara \u0997\u09a3\u09b9\u09a4\u09cd\u09af\u09be\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09aa\u09be\u0995 \u0985\u09a7\u09bf\u0995\u09c3\u09a4 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u09c7\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/video-of-truck-catching-fire-in-pune-viral-as-hyderabad-flyover/",
        headline:
          "Video of Truck Catching Fire in Pune Viral as Hyderabad Flyover\u2019s",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/joe-biden-narendra-modi-world-leader-us-president-india-america-partnership-viral-fake-tweet-11657",
        headline:
          "Fake Tweet Impersonating Joe Biden Calls Narendra Modi A World Leader",
      },
      {
        url:
          "https://factly.in/telugu-due-to-covid-19-only-fewer-people-were-permitted-for-the-swearing-in-ceremony-of-joe-biden/",
        headline:
          " \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d-19\u0c28\u0c3f \u0c26\u0c43\u0c37\u0c4d\u0c1f\u0c3f\u0c32\u0c4b \u0c09\u0c02\u0c1a\u0c41\u0c15\u0c4a\u0c28\u0c3f \u0c1c\u0c4b \u0c2c\u0c48\u0c21\u0c46\u0c28\u0c4d \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c23\u0c38\u0c4d\u0c35\u0c40\u0c15\u0c3e\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c15\u0c3e\u0c35\u0c3e\u0c32\u0c28\u0c47 \u0c24\u0c15\u0c4d\u0c15\u0c41\u0c35\u0c2e\u0c02\u0c26\u0c3f\u0c28\u0c3f \u0c05\u0c28\u0c41\u0c2e\u0c24\u0c3f\u0c02\u0c1a\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/us-president-joe-biden-did-not-tweet-addressing-pm-modi-as-a-world-leader/",
        headline:
          " US President Joe Biden did not tweet addressing PM Modi as a world leader",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-narendra-modi-talk-time-scheme-that-offers-free-recharge-of-rs-400/",
        headline:
          " \u0c28\u0c30\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c4b\u0c26\u0c40 \u0c1f\u0c3e\u0c15\u0c4d \u0c1f\u0c48\u0c02 \u0c38\u0c4d\u0c15\u0c40\u0c02 \u0c15\u0c3f\u0c02\u0c26 \u0c30\u0c42.400 \u0c09\u0c1a\u0c3f\u0c24 \u0c30\u0c40\u0c1b\u0c3e\u0c30\u0c4d\u0c1c\u0c3f \u0c05\u0c02\u0c26\u0c3f\u0c02\u0c1a\u0c47 \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c2a\u0c25\u0c15\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-biden-didn-t-ignore-india-foreign-leaders-are-not-invited-to-us-presidential-inauguration-1761861-2021-01-22",
        headline:
          "Fact Check: Biden didn\u2019t ignore India; foreign leaders are not invited to US Presidential inauguration",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/19/fact-check-video-of-accident-from-pune-shared-as-that-of-hyderabad/",
        headline:
          "Fact Check: Video Of Accident From Pune Shared As That Of Hyderabad",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/20/fact-check-farmers-didnt-storm-rss-headquarters-over-farm-laws-heres-the-truth/",
        headline:
          "Fact Check: Farmers Didn\u2019t Storm RSS Headquarters Over Farm Laws; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/24/fact-check-statement-related-to-farmers-protest-falsely-attributed-to-us-prez-joe-biden/",
        headline:
          "Fact Check: Statement Related to Farmers\u2019 Protest Falsely Attributed to US Prez Joe Biden",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/24/fact-check-did-biden-ignore-india-but-invited-all-our-neighbours-to-the-us-presidential-inauguration-heres-the-truth/",
        headline:
          "Fact Check: Did Biden Ignore India But Invited All Our Neighbours To The US Presidential Inauguration? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/joe-biden-narendra-modi-world-leader-us-president-india-america-partnership-viral-fake-tweet-11657",
        headline:
          "Fake Tweet Impersonating Joe Biden Calls Narendra Modi A World Leader",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/hyderabad-gachibowli-pune-maharashtra-warje-bridge-truck-11595",
        headline:
          "\u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0915\u0947 \u092a\u0941\u0932 \u092a\u0930 \u0928\u0939\u0940\u0902 \u0932\u0917\u0940 \u091f\u094d\u0930\u0915 \u092e\u0947\u0902 \u0906\u0917, \u092f\u0939 \u0918\u091f\u0928\u093e \u092a\u0941\u0923\u0947 \u0915\u0940 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/no-joe-biden-is-not-kneeling-before-george-floyds-daughter-in-this-pic-11646",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u099b\u09ac\u09bf\u09a4\u09c7 George Floyd \u098f\u09b0 \u09ae\u09c7\u09df\u09c7\u09b0 \u0995\u09be\u099b\u09c7 Joe Biden \u0995\u09cd\u09b7\u09ae\u09be \u099a\u09be\u0987\u099b\u09c7\u09a8 \u09a8\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-video-of-protest-march-kargil-against-hazara-killings-shared-as-pok-11587",
        headline:
          "Hazara \u0997\u09a3\u09b9\u09a4\u09cd\u09af\u09be\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09aa\u09be\u0995 \u0985\u09a7\u09bf\u0995\u09c3\u09a4 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u09c7\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bidens-tweet-calling-modi-world-leader-is-fake-673233",
        headline:
          "Fact Check: Biden's tweet calling Modi 'world leader' is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-biden-made-no-statement-supporting-farmers-protest-in-india-673204",
        headline:
          "Fact Check: Biden made no statement supporting farmers' protest in India",
      },
    ],
    "8": [
      {
        url:
          "https://factly.in/this-incident-of-students-getting-hospitalized-after-vaccination-is-old-and-not-related-to-covid-19/",
        headline:
          " This incident of students getting hospitalized after vaccination is old and not related to COVID-19",
      },
      {
        url:
          "https://factly.in/telugu-video-of-a-man-sleeping-alongside-three-cheetahs-is-from-a-breeding-centre-in-south-africa/",
        headline:
          " \u0c1a\u0c3f\u0c30\u0c41\u0c24\u0c2a\u0c41\u0c32\u0c41\u0c32 \u0c38\u0c2e\u0c41\u0c39\u0c02\u0c24\u0c4b \u0c15\u0c32\u0c3f\u0c38\u0c3f \u0c2e\u0c28\u0c3f\u0c37\u0c3f \u0c28\u0c3f\u0c26\u0c4d\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c06\u0c2b\u0c4d\u0c30\u0c3f\u0c15\u0c3e\u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28\u0c26\u0c3f, \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-incident-of-a-child-being-swept-into-air-by-a-kite-took-place-in-taiwan-not-gujarat/",
        headline:
          " This incident of a child being swept into the air by a kite took place in Taiwan, not Gujarat",
      },
      {
        url:
          "https://factly.in/telugu-destruction-of-hanuman-statue-at-eluru-tecu-bank-took-place-in-2014-not-recently/",
        headline:
          " \u2018\u0c0f\u0c32\u0c42\u0c30\u0c41 TECU \u0c2c\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c41 \u0c35\u0c26\u0c4d\u0c26 \u0c39\u0c28\u0c41\u0c2e\u0c02\u0c24\u0c41\u0c21\u0c3f \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c02 \u0c27\u0c4d\u0c35\u0c02\u0c38\u0c02\u2019 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f 2014\u0c32\u0c4b, \u0c24\u0c3e\u0c1c\u0c3e\u0c17\u0c3e \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-of-crowd-singing-vande-mataram-was-taken-at-the-dubai-stadium-not-at-the-gabba/",
        headline:
          " This video of crowd singing \u2018Vande Mataram\u2019 was taken at Dubai Stadium, not at the Gabba",
      },
      {
        url:
          "https://factly.in/telugu-this-incident-of-lions-roaming-the-streets-took-place-in-gujarat-not-nizamabad/",
        headline:
          " \u0c35\u0c40\u0c27\u0c41\u0c32\u0c4d\u0c32\u0c4b \u0c38\u0c3f\u0c02\u0c39\u0c3e\u0c32\u0c41 \u0c38\u0c02\u0c1a\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c18\u0c1f\u0c28 \u0c17\u0c41\u0c1c\u0c30\u0c3e\u0c24\u0c4d\u200c\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f, \u0c28\u0c3f\u0c1c\u0c3e\u0c2e\u0c3e\u0c2c\u0c3e\u0c26\u0c4d\u200c\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/2014-photos-of-vandalized-hanuma-idol-in-eluru-shared-as-a-recent-incident/",
        headline:
          " 2014 photos of vandalized Hanuman idol in Eluru shared as a recent incident",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/nurse-bells-palsy-nashville-tennessee-khalilah-mitchell-covid-19-vaccine-coronavirus-pfizer-moderna-us-11624",
        headline:
          "Nurse In US Did Not Suffer From Bell's Palsy After COVID-19 Vaccine",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/morphed-clips-unrelated-images-revived-to-spread-child-kidnapping-rumours-11628",
        headline:
          "Morphed Clips, Unrelated Images Revived To Spread Child Kidnapping Rumours",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/crowd-singing-vande-mataram-gabba-cricket-ground-dubai-stadium-india-australia-test-match-11639",
        headline:
          "Old Video Of Crowd Singing 'Vande Mataram' In Dubai Shared As Gabba",
      },
      {
        url:
          "https://www.thequint.com/news/webqoof/us-govt-fact-sheet-misrepresented-to-claim-covid-19-was-lab-made/",
        headline:
          "US Govt Fact Sheet Misrepresented to Claim COVID-19 Was \u2018Lab-Made\u2019",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/coronavirus-vaccine-does-not-act-on-covid-19-false-message-impact-11671",
        headline:
          "Viral Message Misleadingly Claims COVID-19 Vaccine Is Futile",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/bill-gates-vaccines-and-population-control-make-for-infectious-conspiracy-theory-1760336-2021-01-18",
        headline:
          "Bill Gates, vaccines and population control make for infectious conspiracy theory",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-conspiracy-theory-claims-covid-19-vaccine-caused-new-strain-in-uk-1760761-2021-01-19",
        headline:
          "Fact Check: Conspiracy theory claims Covid-19 vaccine caused new strain in UK",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-bill-gates-never-said-covid-vaccines-will-kill-or-disable-7-lakh-people-1761114-2021-01-20",
        headline:
          "Fact Check: Bill Gates never said Covid vaccines will kill or disable 7 lakh people",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-officials-posing-for-covid-19-vaccine-is-not-what-it-looks-like-1761512-2021-01-21",
        headline:
          "Fact Check: These officials posing for Covid-19 vaccine is not what it looks like",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/these-officials-posing-for-covid-19-vaccine-is-not-what-it-looks-like-1762122-2021-01-23",
        headline:
          "These officials posing for Covid-19 vaccine is not what it looks like",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/20/fact-check-this-video-of-girl-being-lifted-in-the-air-by-a-giant-kite-is-not-from-india-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Girl Being Lifted In The Air By A Giant Kite Is NOT From India; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/21/fact-check-old-newspaper-clip-shared-with-false-claim-of-40-students-falling-sick-after-taking-covid-19-vaccine/",
        headline:
          "Fact Check: Old Newspaper Clip Shared With False Claim Of 40 Students Falling Sick After Taking COVID-19 Vaccine",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/23/fact-check-this-video-of-angry-australian-cricket-coach-was-not-taken-after-indias-gabba-win-heres-the-truth/",
        headline:
          "Fact Check: This Video Of \u2018Angry\u2019 Australian Cricket Coach Was NOT Taken After India\u2019s Gabba Win; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/23/fact-check-did-the-crowd-sing-vande-mataram-at-the-gabba-after-team-indias-historic-win-heres-the-truth/",
        headline:
          "False: The Video Of The Crowd Singing Vande Mataram Is Not From The Gabba",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/2018-newspaper-clipping-on-vaccine-side-effects-falsely-linked-to-covid-11649",
        headline:
          "2018 News Clipping On Measles Vaccine Side Effects Linked To COVID",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-india-vs-australia-test-series-loss-aussie-coach-langer-reaction-australia-lost-rishabh-pant-gabba-steve-smith-india-win-11636",
        headline:
          "No, This Is Not How Aussie Coach Justin Langer Reacted To India's Win",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/crowd-singing-vande-mataram-gabba-cricket-ground-dubai-stadium-india-australia-test-match-11639",
        headline:
          "Old Video Of Crowd Singing 'Vande Mataram' In Dubai Shared As Gabba",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/nurse-bells-palsy-nashville-tennessee-khalilah-mitchell-covid-19-vaccine-coronavirus-pfizer-moderna-us-11624",
        headline:
          "Nurse In US Did Not Suffer From Bell's Palsy After COVID-19 Vaccine",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/we-took-the-vaccine-earlier-tumkur-officials-call-viral-video-misleading-11652",
        headline:
          "We Took The Vaccine Earlier: Tumkur Officials Call Viral Video Misleading",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/coronavirus-vaccine-does-not-act-on-covid-19-false-message-impact-11671",
        headline:
          "Viral Message Misleadingly Claims COVID-19 Vaccine Is Futile",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/morphed-clips-unrelated-images-revived-to-spread-child-kidnapping-rumours-11628",
        headline:
          "Morphed Clips, Unrelated Images Revived To Spread Child Kidnapping Rumours",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/nurse-bells-palsy-nashville-tennessee-khalilah-mitchell-covid-19-vaccine-coronavirus-pfizer-moderna-us-11651",
        headline:
          "Covid-19 Vaccine \u09a8\u09c7\u0993\u09df\u09be\u09b0 \u09aa\u09b0, \u09ae\u09be\u09b0\u09cd\u0995\u09bf\u09a8 \u098f\u0995 \u09a8\u09be\u09b0\u09cd\u09b8\u09c7\u09b0 '\u09ac\u09c7\u09b2\u09b8 \u09aa\u09be\u09b2\u09b8\u09bf' \u09b9\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-no-cases-of-side-effects-reported-in-india-after-taking-covid-19-vaccine-11622",
        headline:
          "\u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987: \u09ad\u09be\u09b0\u09a4\u09c7 COVID Vaccine \u09a8\u09c7\u0993\u09df\u09be\u09b0 \u09aa\u09b0 \u0995\u09c7\u0989 \u0985\u09b8\u09c1\u09b8\u09cd\u09a5\u09cd\u09af \u09b9\u09df\u09a8\u09bf?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-ktaka-medical-officials-did-not-fake-taking-covid-19-vaccine-video-shared-with-misleading-claims-673145",
        headline:
          "Fact Check: K'taka medical officials did not 'fake' taking COVID-19 vaccine, video shared with misleading claims",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-indias-win-is-not-responsible-for-justin-langers-anger-673207",
        headline:
          "Fact Check: No, India's win is not responsible for Justin Langer's anger",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-us-factsheet-twisted-to-claim-covid-19-was-lab-made-in-china-673007",
        headline:
          "FACT CHECK: US factsheet twisted to claim COVID-19 was 'Lab-Made' in China",
      },
    ],
  },
  numpy_seed: 441,
  number_of_articles: 191,
}
