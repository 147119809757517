export const data = {
  mdsDat: {
    x: [
      0.2519298224978957,
      0.18696785972083885,
      -0.31936067747194785,
      0.14718926458557385,
      -0.26672626933236165,
    ],
    y: [
      -0.2521066218091839,
      -0.01837100410482311,
      0.020065075245721722,
      0.33858976959021714,
      -0.08817721892193171,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      18.24535429366643,
      25.567653835804986,
      18.244432867820255,
      16.10326081492515,
      21.83929818778319,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      64.0,
      19.0,
      30.0,
      31.0,
      43.0,
      14.0,
      15.0,
      11.0,
      18.0,
      10.0,
      1.0000389266263376,
      1.0000389266263376,
      2.0000778532526753,
      5.000194633131689,
      1.0000389266263376,
      3.000116779879013,
      1.0000389266263376,
      7.000272486384364,
      4.000155706505351,
      1.0000389266263376,
      11.000428192889714,
      7.000272486384364,
      6.000233559758026,
      6.000233559758026,
      5.000194633131689,
      5.000194633131689,
      4.000155706505351,
      4.000155706505351,
      4.000155706505351,
      4.000155706505351,
      7.000272486384364,
      7.000272486384364,
      1.9998051405626922,
      2.999707710844038,
      1.9998051405626922,
      2.999707710844038,
      1.9998051405626922,
      1.9998051405626922,
      1.9998051405626922,
      0.9999025702813461,
      1.9998051405626922,
      0.9999025702813461,
      15.998441124501538,
      5.999415421688076,
      5.999415421688076,
      5.999415421688076,
      4.99951285140673,
      4.99951285140673,
      4.99951285140673,
      3.9996102811253844,
      3.9996102811253844,
      3.9996102811253844,
      3.9996102811253844,
      3.9996102811253844,
      14.998538554220191,
      3.9996102811253844,
      30.99697967872173,
      39.99610281125384,
      8.999123132532114,
      5.999415421688076,
      1.999976845434538,
      1.999976845434538,
      3.999953690869076,
      0.999988422717269,
      0.999988422717269,
      0.999988422717269,
      1.999976845434538,
      0.999988422717269,
      0.999988422717269,
      4.999942113586345,
      7.999907381738152,
      5.999930536303614,
      5.999930536303614,
      5.999930536303614,
      5.999930536303614,
      4.999942113586345,
      4.999942113586345,
      3.999953690869076,
      3.999953690869076,
      13.999837918041766,
      20.999756877062648,
      15.999814763476303,
      7.999907381738152,
      4.999942113586345,
      2.000121751004007,
      2.000121751004007,
      1.0000608755020035,
      1.0000608755020035,
      1.0000608755020035,
      4.000243502008014,
      2.000121751004007,
      1.0000608755020035,
      1.0000608755020035,
      1.0000608755020035,
      14.00085225702805,
      10.000608755020036,
      6.0003652530120215,
      6.0003652530120215,
      6.0003652530120215,
      5.000304377510018,
      4.000243502008014,
      4.000243502008014,
      3.0001826265060108,
      3.0001826265060108,
      9.000547879518033,
      18.001095759036065,
      5.000304377510018,
      6.0003652530120215,
      5.0002317211585865,
      9.000417098085455,
      9.000417098085455,
      2.0000926884634347,
      1.0000463442317173,
      2.0000926884634347,
      1.0000463442317173,
      3.000139032695152,
      1.0000463442317173,
      4.000185376926869,
      19.00088054040263,
      12.000556130780607,
      7.000324409622021,
      7.000324409622021,
      7.000324409622021,
      6.000278065390304,
      6.000278065390304,
      6.000278065390304,
      6.000278065390304,
      14.000648819244041,
      8.000370753853739,
      6.000278065390304,
    ],
    Term: [
      "farmer",
      "airport",
      "adani",
      "modi",
      "protest",
      "leader",
      "link",
      "khan",
      "pm",
      "supermarket",
      "argument",
      "raze",
      "tweet",
      "actor",
      "popular",
      "lookalike",
      "form",
      "shah",
      "hrithik_roshan",
      "iconic",
      "khan",
      "kashmiri",
      "rukh",
      "shah_rukh",
      "bollywood",
      "kashmir",
      "boy",
      "hrithik",
      "digitally",
      "roshan",
      "protest",
      "farmer",
      "army",
      "mayawati",
      "dispute",
      "love",
      "release",
      "sharjeel",
      "related",
      "exercise",
      "give",
      "bravery",
      "link",
      "cpi",
      "movement",
      "linked_ongoing",
      "pro",
      "unrelated",
      "khalistan",
      "soldier",
      "pro_khalistan",
      "agitation",
      "shaurya",
      "shaurya_chakra",
      "ongoing",
      "chakra",
      "protest",
      "farmer",
      "ongoing_farmer",
      "indian",
      "ngo",
      "salute",
      "covid",
      "indonesia",
      "celebrate",
      "married",
      "centre",
      "greeting",
      "recruitment",
      "gautam_adani",
      "journalist",
      "wife",
      "pakistani",
      "pakistani_journalist",
      "narendra",
      "ad",
      "gautam",
      "vaccine",
      "narendra_modi",
      "pm",
      "modi",
      "adani",
      "pm_modi",
      "prime_minister",
      "speak",
      "shop",
      "nadda",
      "jesus",
      "policeman",
      "law",
      "rajnath",
      "mamata",
      "jp",
      "ration",
      "leader",
      "supermarket",
      "run",
      "bengaluru",
      "beat",
      "congress",
      "start",
      "mask",
      "aap",
      "address",
      "bjp",
      "farmer",
      "man",
      "protest",
      "amarinder",
      "mukesh_ambani",
      "mukesh",
      "harris",
      "altered",
      "farmers",
      "bratya",
      "grandson_hospital",
      "widen",
      "capt",
      "airport",
      "ambani",
      "ahmedabad",
      "rename",
      "ayodhya",
      "renamed_adani",
      "grandson",
      "ahmedabad_airport",
      "hospital",
      "adani",
      "modi",
      "singh",
    ],
    Total: [
      64.0,
      19.0,
      30.0,
      31.0,
      43.0,
      14.0,
      15.0,
      11.0,
      18.0,
      10.0,
      1.0000389266263376,
      1.0000389266263376,
      2.0000778532526753,
      5.000194633131689,
      1.0000389266263376,
      3.000116779879013,
      1.0000389266263376,
      7.000272486384364,
      4.000155706505351,
      1.0000389266263376,
      11.000428192889714,
      7.000272486384364,
      6.000233559758026,
      6.000233559758026,
      5.000194633131689,
      5.000194633131689,
      4.000155706505351,
      4.000155706505351,
      4.000155706505351,
      4.000155706505351,
      43.99761741811811,
      64.99747105667427,
      1.9998051405626922,
      2.999707710844038,
      1.9998051405626922,
      2.999707710844038,
      1.9998051405626922,
      1.9998051405626922,
      1.9998051405626922,
      0.9999025702813461,
      1.9998051405626922,
      0.9999025702813461,
      15.998441124501538,
      5.999415421688076,
      5.999415421688076,
      5.999415421688076,
      4.99951285140673,
      4.99951285140673,
      4.99951285140673,
      3.9996102811253844,
      3.9996102811253844,
      3.9996102811253844,
      3.9996102811253844,
      3.9996102811253844,
      16.998616407472866,
      3.9996102811253844,
      43.99761741811811,
      64.99747105667427,
      10.999200985784789,
      8.999554454383228,
      1.999976845434538,
      1.999976845434538,
      3.999953690869076,
      0.999988422717269,
      0.999988422717269,
      0.999988422717269,
      1.999976845434538,
      0.999988422717269,
      0.999988422717269,
      4.999942113586345,
      7.999907381738152,
      5.999930536303614,
      5.999930536303614,
      5.999930536303614,
      5.999930536303614,
      4.999942113586345,
      4.999942113586345,
      3.999953690869076,
      3.999953690869076,
      18.99978209129961,
      31.999835341760424,
      30.000463582720343,
      12.999851554995995,
      5.999988457818062,
      2.000121751004007,
      2.000121751004007,
      1.0000608755020035,
      1.0000608755020035,
      1.0000608755020035,
      4.000243502008014,
      2.000121751004007,
      1.0000608755020035,
      1.0000608755020035,
      1.0000608755020035,
      14.00085225702805,
      10.000608755020036,
      6.0003652530120215,
      6.0003652530120215,
      6.0003652530120215,
      5.000304377510018,
      4.000243502008014,
      4.000243502008014,
      3.0001826265060108,
      3.0001826265060108,
      15.000372370241084,
      64.99747105667427,
      7.0002812229445555,
      43.99761741811811,
      5.0002317211585865,
      9.000417098085455,
      9.000417098085455,
      2.0000926884634347,
      1.0000463442317173,
      2.0000926884634347,
      1.0000463442317173,
      3.000139032695152,
      1.0000463442317173,
      4.000185376926869,
      19.00088054040263,
      12.000556130780607,
      7.000324409622021,
      7.000324409622021,
      7.000324409622021,
      6.000278065390304,
      6.000278065390304,
      6.000278065390304,
      6.000278065390304,
      30.000463582720343,
      31.999835341760424,
      10.000521567398318,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      -0.1369,
      -0.5271,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.3638,
      1.2387,
      1.3638,
      1.0136,
      0.8783,
      1.1631,
      0.9583,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.7013,
      1.3959,
      1.2801,
      1.0727,
      1.2158,
      1.519,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.8261,
      1.3154,
      0.5422,
      1.4897,
      -0.1662,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      1.5215,
      0.7594,
      0.1352,
      1.0106,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.5759,
      -5.5759,
      -4.8828,
      -3.9665,
      -5.5759,
      -4.4773,
      -5.5759,
      -3.63,
      -4.1897,
      -5.5759,
      -3.1781,
      -3.63,
      -3.7842,
      -3.7842,
      -3.9665,
      -3.9665,
      -4.1897,
      -4.1897,
      -4.1897,
      -4.1897,
      -3.63,
      -3.63,
      -5.2204,
      -4.8149,
      -5.2204,
      -4.8149,
      -5.2204,
      -5.2204,
      -5.2204,
      -5.9135,
      -5.2204,
      -5.9135,
      -3.1409,
      -4.1217,
      -4.1217,
      -4.1217,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.5272,
      -4.5272,
      -4.5272,
      -4.5272,
      -4.5272,
      -3.2055,
      -4.5272,
      -2.4795,
      -2.2246,
      -3.7163,
      -4.1217,
      -4.8828,
      -4.8828,
      -4.1897,
      -5.5759,
      -5.5759,
      -5.5759,
      -4.8828,
      -5.5759,
      -5.5759,
      -3.9665,
      -3.4965,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.7842,
      -3.9665,
      -3.9665,
      -4.1897,
      -4.1897,
      -2.9369,
      -2.5314,
      -2.8034,
      -3.4965,
      -3.9665,
      -4.7579,
      -4.7579,
      -5.451,
      -5.451,
      -5.451,
      -4.0647,
      -4.7579,
      -5.451,
      -5.451,
      -5.451,
      -2.812,
      -3.1485,
      -3.6593,
      -3.6593,
      -3.6593,
      -3.8416,
      -4.0647,
      -4.0647,
      -4.3524,
      -4.3524,
      -3.2538,
      -2.5607,
      -3.8416,
      -3.6593,
      -4.1463,
      -3.5585,
      -3.5585,
      -5.0626,
      -5.7557,
      -5.0626,
      -5.7557,
      -4.6571,
      -5.7557,
      -4.3694,
      -2.8113,
      -3.2708,
      -3.8098,
      -3.8098,
      -3.8098,
      -3.964,
      -3.964,
      -3.964,
      -3.964,
      -3.1167,
      -3.6763,
      -3.964,
    ],
  },
  "token.table": {
    Topic: [
      4,
      1,
      3,
      3,
      5,
      4,
      2,
      5,
      5,
      5,
      5,
      5,
      5,
      1,
      2,
      5,
      4,
      4,
      1,
      2,
      4,
      1,
      1,
      5,
      2,
      5,
      3,
      3,
      2,
      4,
      3,
      2,
      1,
      2,
      2,
      1,
      2,
      4,
      5,
      1,
      3,
      3,
      2,
      5,
      5,
      3,
      5,
      5,
      1,
      1,
      1,
      2,
      5,
      3,
      4,
      3,
      4,
      1,
      1,
      2,
      1,
      4,
      4,
      2,
      2,
      1,
      2,
      4,
      3,
      4,
      3,
      4,
      2,
      2,
      3,
      5,
      2,
      5,
      5,
      4,
      3,
      3,
      3,
      1,
      2,
      1,
      2,
      3,
      3,
      2,
      3,
      5,
      2,
      3,
      5,
      4,
      1,
      3,
      5,
      2,
      2,
      1,
      2,
      4,
      4,
      4,
      1,
      3,
      2,
      2,
      5,
      5,
      1,
      1,
      4,
      3,
      1,
      1,
      2,
      2,
      2,
      4,
      4,
      5,
      2,
      4,
      4,
      4,
      1,
      2,
      3,
      5,
      3,
    ],
    Freq: [
      0.9999391282035975,
      0.9999610748888855,
      1.0000115774167662,
      0.5333250919901009,
      0.4666594554913383,
      0.9999391282035975,
      1.000097439212129,
      0.999953657915971,
      0.999953657915971,
      0.9999536579159709,
      0.9999536579159709,
      0.999953657915971,
      0.999953657915971,
      0.9999610748888856,
      1.000097439212129,
      0.999953657915971,
      0.9999391282035975,
      0.9999391282035975,
      0.1999950351867021,
      0.1999950351867021,
      0.5999851055601063,
      0.9999610748888855,
      0.9999610748888856,
      0.9999536579159709,
      1.000097439212129,
      0.9999536579159709,
      1.0000115774167662,
      1.0000115774167662,
      1.000097439212129,
      0.9999391282035976,
      1.0000115774167662,
      1.000097439212129,
      0.9999610748888856,
      1.000097439212129,
      1.000097439212129,
      0.10769649782060566,
      0.6154085589748894,
      0.27693385153870026,
      0.9999536579159709,
      0.9999610748888856,
      1.0000115774167662,
      1.0000115774167662,
      1.000097439212129,
      0.999953657915971,
      0.999953657915971,
      1.0000115774167662,
      0.9999536579159709,
      0.999953657915971,
      0.9999610748888856,
      0.9999610748888856,
      0.9999610748888856,
      0.6666996716796023,
      0.33334983583980116,
      1.0000115774167662,
      0.9999391282035976,
      1.0000115774167662,
      0.9999391282035976,
      0.9999610748888855,
      0.9999610748888855,
      1.000097439212129,
      0.9999610748888856,
      0.9999391282035976,
      0.9999391282035975,
      1.000097439212129,
      1.000097439212129,
      0.9999610748888855,
      1.000097439212129,
      0.9999391282035976,
      0.2857028076878792,
      0.714257019219698,
      1.0000115774167662,
      0.9999391282035976,
      1.000097439212129,
      0.09375048239966847,
      0.6562533767976794,
      0.25000128639911595,
      1.000097439212129,
      0.999953657915971,
      0.999953657915971,
      0.9999391282035976,
      1.000011577416766,
      1.0000115774167662,
      1.0000115774167662,
      0.11765663463767367,
      0.8824247597825525,
      0.18183138962409828,
      0.8182412533084423,
      1.000011577416766,
      1.000011577416766,
      0.10526436515900048,
      0.7368505561130033,
      0.1578965477385007,
      0.1538479106118236,
      0.6153916424472944,
      0.2307718659177354,
      0.9999391282035976,
      0.9999610748888856,
      0.8333349364172419,
      0.1666669872834484,
      1.000097439212129,
      1.000097439212129,
      0.15909952426463478,
      0.7045836074576682,
      0.13637102079825839,
      0.9999391282035976,
      0.9999391282035976,
      0.9999610748888856,
      1.0000115774167662,
      1.000097439212129,
      1.000097439212129,
      0.999953657915971,
      0.999953657915971,
      0.9999610748888856,
      0.9999610748888855,
      0.9999391282035975,
      1.0000115774167662,
      0.9999610748888855,
      0.9999610748888855,
      1.000097439212129,
      1.000097439212129,
      1.000097439212129,
      0.9999391282035976,
      0.3999791383921407,
      0.5999687075882111,
      1.000097439212129,
      0.9999391282035976,
      0.9999391282035976,
      0.9999391282035976,
      0.9999610748888856,
      1.000097439212129,
      1.0000115774167662,
      0.9999536579159709,
      1.000011577416766,
    ],
    Term: [
      "aap",
      "actor",
      "ad",
      "adani",
      "adani",
      "address",
      "agitation",
      "ahmedabad",
      "ahmedabad_airport",
      "airport",
      "altered",
      "amarinder",
      "ambani",
      "argument",
      "army",
      "ayodhya",
      "beat",
      "bengaluru",
      "bjp",
      "bjp",
      "bjp",
      "bollywood",
      "boy",
      "bratya",
      "bravery",
      "capt",
      "celebrate",
      "centre",
      "chakra",
      "congress",
      "covid",
      "cpi",
      "digitally",
      "dispute",
      "exercise",
      "farmer",
      "farmer",
      "farmer",
      "farmers",
      "form",
      "gautam",
      "gautam_adani",
      "give",
      "grandson",
      "grandson_hospital",
      "greeting",
      "harris",
      "hospital",
      "hrithik",
      "hrithik_roshan",
      "iconic",
      "indian",
      "indian",
      "indonesia",
      "jesus",
      "journalist",
      "jp",
      "kashmir",
      "kashmiri",
      "khalistan",
      "khan",
      "law",
      "leader",
      "link",
      "linked_ongoing",
      "lookalike",
      "love",
      "mamata",
      "man",
      "man",
      "married",
      "mask",
      "mayawati",
      "modi",
      "modi",
      "modi",
      "movement",
      "mukesh",
      "mukesh_ambani",
      "nadda",
      "narendra",
      "narendra_modi",
      "ngo",
      "ongoing",
      "ongoing",
      "ongoing_farmer",
      "ongoing_farmer",
      "pakistani",
      "pakistani_journalist",
      "pm",
      "pm",
      "pm",
      "pm_modi",
      "pm_modi",
      "pm_modi",
      "policeman",
      "popular",
      "prime_minister",
      "prime_minister",
      "pro",
      "pro_khalistan",
      "protest",
      "protest",
      "protest",
      "rajnath",
      "ration",
      "raze",
      "recruitment",
      "related",
      "release",
      "rename",
      "renamed_adani",
      "roshan",
      "rukh",
      "run",
      "salute",
      "shah",
      "shah_rukh",
      "sharjeel",
      "shaurya",
      "shaurya_chakra",
      "shop",
      "singh",
      "singh",
      "soldier",
      "speak",
      "start",
      "supermarket",
      "tweet",
      "unrelated",
      "vaccine",
      "widen",
      "wife",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/digitally-created-image-viral-as-shahrukh-khans-lookalike-discovered-in-kashmir/",
        headline:
          "Digitally-created image viral as Shahrukh Khan\u2019s lookalike discovered in Kashmir",
      },
      {
        url:
          "https://factly.in/telugu-video-of-pro-khalistan-supporters-in-us-praising-pakistan-pm-imran-khan-is-falsely-linked-to-farmers-protest/",
        headline:
          " \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c28\u0c3f \u0c38\u0c2a\u0c4b\u0c30\u0c4d\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c42 \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41\u0c32\u0c41 \u0c28\u0c3f\u0c28\u0c3e\u0c26\u0c3e\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c38\u0c02\u0c18\u0c1f\u0c28 \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f, \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/digitally-created-photo-shared-as-that-of-a-kashmir-boy-who-looks-like-bollywood-actor-shahrukh-khan/",
        headline:
          " Digitally created photo shared as that of a Kashmir boy who looks like Bollywood actor Shahrukh Khan",
      },
      {
        url:
          "https://factly.in/an-old-image-is-passed-off-as-protesting-farmers-demand-for-restoration-of-art-370-in-kashmir/",
        headline:
          " An Old image is passed off as protesting farmers demand for restoration of Art 370 in Kashmir",
      },
      {
        url:
          "https://factly.in/telugu-states-do-not-get-75-per-cent-of-the-tax-on-petrol/",
        headline:
          " \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c2a\u0c48 \u0c1f\u0c3e\u0c15\u0c4d\u0c38\u0c4d \u0c30\u0c42\u0c2a\u0c02\u0c32\u0c4b \u0c35\u0c38\u0c42\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c47 \u0c2e\u0c4a\u0c24\u0c4d\u0c24\u0c02\u0c32\u0c4b \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c32 \u0c35\u0c3e\u0c1f\u0c3e 75% \u0c05\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c26\u0c28 \u0c24\u0c2a\u0c4d\u0c2a\u0c41.",
      },
      {
        url:
          "https://www.factcrescendo.com/no-bjp-flags-were-not-waved-in-pakistan-occupied-kashmir/",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u0905\u0927\u093f\u0915\u0943\u0924 \u0915\u0936\u094d\u092e\u0940\u0930 \u092e\u0947\u0902 \u092d\u093e.\u091c.\u092a\u093e \u0915\u0947 \u091d\u0902\u0921\u0947 \u0932\u0939\u0930\u093e\u092f\u0947 \u0917\u092f\u0947? \u091c\u093e\u0928\u093f\u092f\u0947 \u0938\u091a\u2026",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-video-of-rally-in-kishtwar-shared-as-bjp-raising-party-flags-in-pok-heres-the-truth/",
        headline:
          "Fact Check: Video Of Rally In Kishtwar Shared As BJP Raising Party Flags In PoK; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-did-bollywood-actor-hrithik-roshan-take-part-in-the-ongoing-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Did Bollywood Actor Hrithik Roshan Take Part In The Ongoing Farmers\u2019 Protest? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-old-video-of-protestors-raising-pakistan-zindabaad-slogans-falsely-shared-as-recent-one/",
        headline:
          "Fact Check: Old Video Of Sikh Protestors Raising \u2018Pakistan Zindabaad\u2019 Slogans Falsely Shared As Being Recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-dont-fall-for-this-fake-tweet-on-dalits-attributed-to-shatrughan-sinha/",
        headline:
          "Fact Check: Don\u2019t Fall For This Fake Tweet On Dalits Attributed To Shatrughan Sinha",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-no-this-isnt-a-kashmiri-boy-resembling-shah-rukh-khan-heres-the-truth/",
        headline:
          "Fact Check: No, This Isn\u2019t A Kashmiri Boy Resembling Shah Rukh Khan; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-is-the-little-girl-in-the-picture-serving-langar-in-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Is The Little Girl In The Picture Serving Langar In Recent Farmers\u2019 Protest? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-old-pictures-of-former-actor-sana-khan-resurface-with-false-claims/",
        headline:
          "Fact Check: Old Pictures Of Former Actor Sana Khan Resurface With False Claims",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/kashmiri-boy-who-looks-like-shahrukh-khan-photo-is-edited-11126",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u0936\u093e\u0939\u0930\u0941\u0959 \u0916\u093e\u0928 \u0915\u093e \u0915\u0936\u094d\u092e\u0940\u0930\u0940 \u0939\u092e\u0936\u0915\u094d\u0932 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/rajgir-nalanda-glass-bridge-bihar-china-fake-news-11235",
        headline:
          "\u0915\u094d\u092f\u093e \u092f\u0947 \u092c\u093f\u0939\u093e\u0930 \u0915\u0947 \u0928\u093e\u0932\u0902\u0926\u093e \u092e\u0947\u0902 \u092c\u0928\u0947 '\u0917\u094d\u0932\u093e\u0938 \u092c\u094d\u0930\u093f\u091c' \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/shah-rukh-khans-young-kashmiri-lookalike-no-photo-digitally-created-11149",
        headline:
          "\u09b6\u09be\u09b9\u09b0\u09c1\u0996 \u0996\u09be\u09a8\u09c7\u09b0 \u09ae\u09a4 \u09a4\u09b0\u09c1\u09a8 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u09bf? \u09a8\u09be, \u098f\u099f\u09bf \u09a1\u09bf\u099c\u09bf\u099f\u09cd\u09af\u09be\u09b2 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-2018-pic-of-hrithik-roshan-shared-as-recent-11165",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09b9\u09c3\u09a4\u09bf\u0995 \u09b0\u09cb\u09b6\u09a8\u09c7\u09b0 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-twisted-data-about-rapes-become-hot-issue-before-bengal-election-1749843-2020-12-15",
        headline:
          "Fact Check: Twisted data about rapes become hot issue before Bengal election",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-truth-behind-srk-s-doppelganger-from-kashmir-1749439-2020-12-14",
        headline:
          "Fact Check: Truth behind SRK\u2019s \u2018doppelganger from Kashmir\u2019",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-this-is-not-george-foreman-and-ricky-ponting-singing-a-popular-bollywood-song-1751002-2020-12-19",
        headline:
          "Fact Check: No, this is not George Foreman and Ricky Ponting singing a popular Bollywood song",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-iconic-bell-tower-in-tagore-varsity-was-not-razed-due-to-amit-shah-visit-1751446-2020-12-20",
        headline:
          "Fact Check: Iconic bell tower in Tagore's varsity was not razed due to Amit Shah's visit",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-video-of-rally-in-kishtwar-shared-as-bjp-raising-party-flags-in-pok-heres-the-truth/",
        headline:
          "Fact Check: Video Of Rally In Kishtwar Shared As BJP Raising Party Flags In PoK; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-did-bollywood-actor-hrithik-roshan-take-part-in-the-ongoing-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Did Bollywood Actor Hrithik Roshan Take Part In The Ongoing Farmers\u2019 Protest? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-old-video-of-protestors-raising-pakistan-zindabaad-slogans-falsely-shared-as-recent-one/",
        headline:
          "Fact Check: Old Video Of Sikh Protestors Raising \u2018Pakistan Zindabaad\u2019 Slogans Falsely Shared As Being Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-dont-fall-for-this-fake-tweet-on-dalits-attributed-to-shatrughan-sinha/",
        headline:
          "Fact Check: Don\u2019t Fall For This Fake Tweet On Dalits Attributed To Shatrughan Sinha",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-no-this-isnt-a-kashmiri-boy-resembling-shah-rukh-khan-heres-the-truth/",
        headline:
          "Fact Check: No, This Isn\u2019t A Kashmiri Boy Resembling Shah Rukh Khan; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-is-the-little-girl-in-the-picture-serving-langar-in-recent-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Is The Little Girl In The Picture Serving Langar In Recent Farmers\u2019 Protest? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-old-pictures-of-former-actor-sana-khan-resurface-with-false-claims/",
        headline:
          "Fact Check: Old Pictures Of Former Actor Sana Khan Resurface With False Claims",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-2018-pic-of-hrithik-roshan-shared-as-recent-11165",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u09b9\u09c3\u09a4\u09bf\u0995 \u09b0\u09cb\u09b6\u09a8\u09c7\u09b0 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/shah-rukh-khans-young-kashmiri-lookalike-no-photo-digitally-created-11149",
        headline:
          "\u09b6\u09be\u09b9\u09b0\u09c1\u0996 \u0996\u09be\u09a8\u09c7\u09b0 \u09ae\u09a4 \u09a4\u09b0\u09c1\u09a8 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u09bf? \u09a8\u09be, \u098f\u099f\u09bf \u09a1\u09bf\u099c\u09bf\u099f\u09cd\u09af\u09be\u09b2 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/shah-rukh-khans-young-kashmiri-lookalike-no-photo-digitally-created-11117",
        headline:
          "Shah Rukh Khan's Young Kashmiri Lookalike? No, Photo Digitally Created",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/rajgir-nalanda-glass-bridge-bihar-china-fake-news-11235",
        headline:
          "\u0915\u094d\u092f\u093e \u092f\u0947 \u092c\u093f\u0939\u093e\u0930 \u0915\u0947 \u0928\u093e\u0932\u0902\u0926\u093e \u092e\u0947\u0902 \u092c\u0928\u0947 '\u0917\u094d\u0932\u093e\u0938 \u092c\u094d\u0930\u093f\u091c' \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0948?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-ricky-ponting-george-foreman-did-not-make-cover-of-bollywood-song-mitwa-671979",
        headline:
          "Fact Check: Ricky Ponting, George Foreman did not make cover of Bollywood song 'Mitwa'",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-shah-rukh-khans-doppelganger-is-not-kashmiri-boy-but-digitally-edited-photo-using-faceapp-671729",
        headline:
          "Fact check: Shah Rukh Khan's doppelganger is not Kashmiri boy, but digitally edited photo using Faceapp",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-transparent-frog-also-known-as-glass-frog-is-true-671800",
        headline:
          "Fact check: Viral image of transparent frog, also known as Glass Frog, is True",
      },
    ],
    "2": [
      {
        url:
          "https://www.altnews.in/images-of-protests-held-in-the-past-shared-as-massive-crowd-at-farmers-protest/",
        headline:
          "Images of protests held in the past shared as massive crowd at farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-2019-video-of-khalistani-supporters-arrest-being-falsely-linked-to-farmers-protests/",
        headline:
          " 2019 \u0c28\u0c4d\u0c2f\u0c42\u0c38\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2a\u0c4d\u0c30\u0c38\u0c4d\u0c24\u0c41\u0c24 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c24\u0c4b \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-25000-soldiers-did-not-return-their-shaurya-chakra-awards-in-support-of-farmers-protest/",
        headline:
          " \u2018\u0c30\u0c48\u0c24\u0c41\u0c32\u0c15\u0c41 \u0c2e\u0c26\u0c4d\u0c26\u0c24\u0c41\u0c17\u0c3e 25 \u0c35\u0c47\u0c32 \u0c2e\u0c02\u0c26\u0c3f \u0c1c\u0c35\u0c3e\u0c28\u0c4d\u0c32\u0c41 \u0c36\u0c4c\u0c30\u0c4d\u0c2f \u0c1a\u0c15\u0c4d\u0c30 \u0c05\u0c35\u0c3e\u0c30\u0c4d\u0c21\u0c41\u0c32\u0c41 \u0c35\u0c46\u0c28\u0c15\u0c4d\u0c15\u0c3f\u2019 \u0c05\u0c28\u0c47\u0c26\u0c3f \u0c2b\u0c47\u0c15\u0c4d \u0c35\u0c3e\u0c30\u0c4d\u0c24",
      },
      {
        url:
          "https://factly.in/old-and-unrelated-videos-are-being-passed-off-linking-them-to-the-ongoing-farmers-protest/",
        headline:
          " Old and unrelated videos are being shared as related to the ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/telugu-old-image-of-trudeaus-visit-to-a-sikh-congregation-is-falsely-linked-to-farmers-protest/",
        headline:
          " 2015\u0c32\u0c4b \u0c1c\u0c38\u0c4d\u0c1f\u0c3f\u0c28\u0c4d \u0c1f\u0c4d\u0c30\u0c42\u0c21\u0c4b \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41 \u0c38\u0c4a\u0c38\u0c48\u0c1f\u0c40\u0c28\u0c3f \u0c38\u0c02\u0c26\u0c30\u0c4d\u0c36\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-and-unrelated-videos-are-being-shared-as-related-to-the-ongoing-farmers-protest/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c28\u0c41 \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c32\u0c4b \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c1c\u0c3f\u0c02\u0c26\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c28\u0c3f\u0c28\u0c3e\u0c26\u0c3e\u0c32\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-and-unrelated-images-of-pro-khalistan-protests-are-falsely-linked-to-the-ongoing-farmers-protest/",
        headline:
          " Old and unrelated images of Pro-Khalistan protests are falsely linked to the ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/old-and-unrelated-images-shared-as-farmers-disrespecting-the-national-flag-in-the-ongoing-protests/",
        headline:
          " Old unrelated images shared as farmers disrespecting the national flag in the ongoing protests",
      },
      {
        url:
          "https://factly.in/old-video-of-the-attack-on-west-bengal-bjp-president-falsely-linked-to-the-ongoing-farmers-protests/",
        headline:
          " Old video of the attack on West Bengal BJP President falsely linked to the ongoing farmers\u2019 protests",
      },
      {
        url:
          "https://factly.in/2015-video-of-military-exercise-conducted-by-the-serbian-special-force-falsely-linked-to-indian-army/",
        headline:
          " 2015 video of a military exercise conducted by the Serbian special force falsely linked to Indian army",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-army-trucks-vehicles-in-a-difficult-terrain-is-not-from-india/",
        headline:
          " \u2018\u0c2d\u0c3e\u0c30\u0c24 \u0c38\u0c48\u0c28\u0c4d\u0c2f\u0c02 \u0c27\u0c48\u0c30\u0c4d\u0c2f \u0c38\u0c3e\u0c39\u0c38\u0c3e\u0c32 \u0c2a\u0c4d\u0c30\u0c26\u0c30\u0c4d\u0c36\u0c28\u2019 \u0c05\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c42 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://www.factcrescendo.com/video-of-pro-khalistani-protest-in-us-shared-as-recent-one/",
        headline:
          "\u0905\u092e\u0947\u0930\u093f\u0915\u093e \u092e\u0947\u0902 2019 \u092e\u0947\u0902 \u0939\u0941\u092f\u0947 \u092e\u094b\u0926\u0940 \u0935\u093f\u0930\u094b\u0927\u0940 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u092e\u0947\u0902 \u0939\u094b \u0930\u0939\u0947 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u093e \u092c\u0924\u093e \u0935\u093e\u092f\u0930\u0932 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948\u0964",
      },
      {
        url:
          "https://www.factcrescendo.com/pakistan-zindabad-slogan-video-is-five-years-old-not-from-kisan-andolan/",
        headline:
          "\u201c\u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u091c\u093f\u0902\u0926\u093e\u092c\u093e\u0926\u201d \u0915\u0947 \u0928\u093e\u0930\u0947 \u0932\u0917\u093e\u0928\u0947 \u0915\u093e \u092f\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u0941\u0930\u093e\u0928\u093e \u0939\u0948, \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u0938\u092e\u094d\u092c\u0902\u0927\u093f\u0924 \u0928\u0939\u0940\u0902 \u0939\u0948|",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-old-video-of-farmers-crawling-on-a-road-shared-as-recent-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Farmers Crawling On A Road Shared As Recent; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-old-video-from-2016-falsely-linked-to-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Old Video From 2016 Falsely Linked To Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-did-barack-obama-give-pm-modi-a-cold-shoulder-due-to-the-ongoing-farmers-protest/",
        headline:
          "Fact Check: Did Barack Obama Give PM Modi A Cold Shoulder Due To The Ongoing Farmers\u2019 Protest?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-did-bjp-mp-sanjay-dhotre-recently-say-let-farmers-die-heres-the-truth/",
        headline:
          "Fact Check: Did BJP MP Sanjay Dhotre Recently Say \u2018Let Farmers Die\u2019? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/16/fact-check-video-showing-protesters-raising-pro-khalistan-slogans-is-not-from-ongoing-farmers-agitation-heres-the-truth/",
        headline:
          "Fact Check: Video Showing Protesters Raising Pro-Khalistan Slogans Is NOT From Ongoing Farmers\u2019 Agitation; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/16/fact-check-old-pictures-of-people-disrespecting-indian-flag-falsely-shared-as-ongoing-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Old Pictures Of Indian Flag Being Disrespected Falsely Linked To Ongoing Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-no-this-large-gathering-of-people-on-a-winter-night-is-not-from-the-ongoing-farmers-protest/",
        headline:
          "Fact Check: No, This Large Gathering Of People On a Winter Night Is Not From The Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-old-picture-of-mayawati-and-anandiben-patel-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Mayawati And Anandiben Patel Falsely Linked To Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-did-25000-soldiers-return-shaurya-chakra-awards-to-support-farmers-heres-the-truth/",
        headline:
          "Fact Check: Did 25,000 Soldiers Return Shaurya Chakra Awards To Support Farmers? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/images-from-old-cpi-protests-viral-as-ongoing-farmers-agitation-11162",
        headline:
          "Images From Old CPI Protests Viral As Ongoing Farmers' Agitation",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-marital-dispute-mediation-viral-with-fake-love-jihad-claim-11186",
        headline:
          "Video Of Marital Dispute Mediation Viral With Fake 'Love Jihad' Claim",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/250-million-people-in-kisan-andolan-photos-show-old-cpi-protests-11183",
        headline:
          "\u0938\u0940.\u092a\u0940.\u0906\u0908 \u0926\u094d\u0935\u093e\u0930\u093e \u092a\u0941\u0930\u093e\u0928\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u094b\u0902 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/images-from-old-cpi-protests-viral-as-ongoing-farmers-agitation-11196",
        headline:
          "\u09b8\u09bf\u09aa\u09bf\u0986\u0987-\u098f\u09b0 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-images-of-pro-khalistan-demonstrations-linked-to-farmers-protests-1750221-2020-12-16",
        headline:
          "Fact Check: Old images of pro-Khalistan demonstrations linked to farmers\u2019 protests",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-old-video-of-farmers-crawling-on-a-road-shared-as-recent-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Farmers Crawling On A Road Shared As Recent; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-old-video-from-2016-falsely-linked-to-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Old Video From 2016 Falsely Linked To Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-did-barack-obama-give-pm-modi-a-cold-shoulder-due-to-the-ongoing-farmers-protest/",
        headline:
          "Fact Check: Did Barack Obama Give PM Modi A Cold Shoulder Due To The Ongoing Farmers\u2019 Protest?",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-did-bjp-mp-sanjay-dhotre-recently-say-let-farmers-die-heres-the-truth/",
        headline:
          "Fact Check: Did BJP MP Sanjay Dhotre Recently Say \u2018Let Farmers Die\u2019? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/16/fact-check-video-showing-protesters-raising-pro-khalistan-slogans-is-not-from-ongoing-farmers-agitation-heres-the-truth/",
        headline:
          "Fact Check: Video Showing Protesters Raising Pro-Khalistan Slogans Is NOT From Ongoing Farmers\u2019 Agitation; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/16/fact-check-old-pictures-of-people-disrespecting-indian-flag-falsely-shared-as-ongoing-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Old Pictures Of Indian Flag Being Disrespected Falsely Linked To Ongoing Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-no-this-large-gathering-of-people-on-a-winter-night-is-not-from-the-ongoing-farmers-protest/",
        headline:
          "Fact Check: No, This Large Gathering Of People On a Winter Night Is Not From The Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-old-picture-of-mayawati-and-anandiben-patel-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Mayawati And Anandiben Patel Falsely Linked To Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-did-25000-soldiers-return-shaurya-chakra-awards-to-support-farmers-heres-the-truth/",
        headline:
          "Fact Check: Did 25,000 Soldiers Return Shaurya Chakra Awards To Support Farmers? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/images-from-old-cpi-protests-viral-as-ongoing-farmers-agitation-11162",
        headline:
          "Images From Old CPI Protests Viral As Ongoing Farmers' Agitation",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-release-sharjeel-imam-posters-11217",
        headline:
          "Old Poster Demanding Release Of Sharjeel Imam Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-marital-dispute-mediation-viral-with-fake-love-jihad-claim-11186",
        headline:
          "Video Of Marital Dispute Mediation Viral With Fake 'Love Jihad' Claim",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2016-video-of-pro-khalistan-rally-falsely-linked-to-farmers-protests-11156",
        headline:
          "\u09e8\u09e6\u09e7\u09ec'\u09b0 \u0996\u09be\u09b2\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09aa\u09a8\u09cd\u09a5\u09c0 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u0995\u09c3\u09b7\u0995 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u099c\u09cb\u09a1\u09bc\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/images-from-old-cpi-protests-viral-as-ongoing-farmers-agitation-11196",
        headline:
          "\u09b8\u09bf\u09aa\u09bf\u0986\u0987-\u098f\u09b0 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/image-of-miniature-set-up-ahared-as-mortal-remains-of-indian-soldier-11145",
        headline:
          "\u09b8\u09cb\u09b6\u09be\u09b2 \u09ae\u09bf\u09a1\u09bf\u09df\u09be\u09df \u09a1\u09be\u09df\u09cb\u09b0\u09be\u09ae\u09be \u09ae\u09a1\u09c7\u09b2\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09ae\u09c3\u09a4 \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09b8\u09c7\u09a8\u09be\u09b0 \u09a6\u09c7\u09b9\u09be\u09ac\u09b6\u09c7\u09b7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2018-video-of-pro-khalistan-rally-in-us-falsely-linked-to-farmers-stir-11123",
        headline:
          "\u09e8\u09e6\u09e7\u09ee'\u09a4\u09c7 \u0986\u09ae\u09c7\u09b0\u09bf\u0995\u09be\u09df \u0996\u09be\u09b2\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09aa\u09a8\u09cd\u09a5\u09c0 \u09ae\u09bf\u099b\u09bf\u09b2 \u099c\u09c1\u09a1\u09bc\u09b2 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protests-clip-of-2019-pro-khalistan-rally-in-us-revived-11152",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7 \u0996\u09be\u09b2\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09bf \u09b8\u09cd\u09b2\u09cb\u0997\u09be\u09a8 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0986\u09ae\u09c7\u09b0\u09bf\u0995\u09be\u09b0 \u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/farmers-protest-delhi-punjab-sharjeel-imam-11218",
        headline:
          "\u0936\u0930\u091c\u0940\u0932 \u0907\u092e\u093e\u092e \u0915\u0940 \u0930\u093f\u0939\u093e\u0908 \u0915\u0940 \u092e\u093e\u0902\u0917 \u0915\u0930\u0924\u093e \u092f\u0939 \u092c\u0948\u0928\u0930 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u093e \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-photo-viral-with-pm-narendra-modi-ki-shaadi-fake-claim-11185",
        headline: "          ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/viral-image-claiming-to-be-ayodhya-divya-chauraha-is-from-gujarat-11147",
        headline: " ,          ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/250-million-people-in-kisan-andolan-photos-show-old-cpi-protests-11183",
        headline:
          "\u0938\u0940.\u092a\u0940.\u0906\u0908 \u0926\u094d\u0935\u093e\u0930\u093e \u092a\u0941\u0930\u093e\u0928\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u094b\u0902 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/two-year-old-video-of-alleged-marriage-counselling-viral-with-love-jihad-twist-11168",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e \u0939\u093f\u0938\u094d\u0938\u093e \u0932\u0935 \u091c\u093f\u0939\u093e\u0926 \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-25000-indian-soldiers-did-not-return-their-shaurya-chakra-medals-to-support-farmers-protest-671816",
        headline:
          "Fact Check: 25,000 Indian soldiers did not return their Shaurya Chakra medals to support farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-unrelated-images-passed-off-as-farmers-protest-in-delhi-671779",
        headline:
          "FACT CHECK: Old unrelated images passed off as farmers protest in Delhi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bangalore-agri-startup-falsely-linked-to-ongoing-farmers-protest-671883",
        headline:
          "Fact Check: Bangalore agri startup falsely linked to ongoing farmers protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-mayawati-with-up-governor-from-2019-unrelated-to-2020-farmers-protests-671793",
        headline:
          "Fact Check: Photo of Mayawati with UP Governor from 2019, unrelated to 2020 farmers' protests",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/govt-fact-checks-its-own-fact-checking-arm-pib-on-false-news-about-ib-recruitment/",
        headline:
          "Govt fact-checks its own fact-checking arm PIB on false news about IB recruitment",
      },
      {
        url:
          "https://factly.in/telugu-the-one-holding-umbrella-for-modi-in-this-picture-is-kyrgyzstan-president-not-chinese-premier/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c27\u0c3e\u0c28\u0c3f \u0c2e\u0c4b\u0c26\u0c40 \u0c15\u0c3f \u0c17\u0c4a\u0c21\u0c41\u0c17\u0c41 \u0c2a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c4a\u0c28\u0c3f \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c15\u0c3f\u0c30\u0c4d\u0c17\u0c3f\u0c1c\u0c4d\u200c\u0c38\u0c4d\u0c25\u0c3e\u0c28\u0c4d \u0c05\u0c27\u0c4d\u0c2f\u0c15\u0c4d\u0c37\u0c41\u0c21\u0c41",
      },
      {
        url:
          "https://factly.in/goi-did-not-introduce-any-widow-pension-scheme-called-pm-vidhwa-mahila-samruddhi-yojana/",
        headline:
          " The Central Government did not introduce any widow pension scheme called \u2018PM Vidhva Mahila Samriddhi Yojana\u2019",
      },
      {
        url:
          "https://factly.in/pm-modi-greeting-an-ngo-worker-is-shared-as-him-greeting-gautam-adanis-wife/",
        headline:
          " PM Modi greeting an NGO worker is shared as him greeting Gautam Adani\u2019s wife",
      },
      {
        url:
          "https://factly.in/telugu-pm-modi-greeting-an-ngo-worker-is-shared-as-him-greeting-gautam-adanis-wife/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c27\u0c3e\u0c28\u0c3f \u0c2e\u0c4b\u0c26\u0c40 \u0c12\u0c15 NGO \u0c35\u0c30\u0c4d\u0c15\u0c30\u0c4d \u0c15\u0c3f \u0c28\u0c2e\u0c38\u0c4d\u0c15\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f, \u0c05\u0c26\u0c3e\u0c28\u0c3f \u0c2d\u0c3e\u0c30\u0c4d\u0c2f\u0c15\u0c41 \u0c28\u0c2e\u0c38\u0c4d\u0c15\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.factcrescendo.com/the-man-praising-modi-is-not-a-pakistani-journalist/",
        headline:
          "\u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u092e\u094b\u0926\u0940 \u0915\u0940 \u0924\u093e\u0930\u0940\u092b \u0915\u0930\u0924\u093e \u092f\u0947 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u092a\u0924\u094d\u0930\u0915\u093e\u0930 \u0928\u0939\u0940\u0902 \u0939\u0948 |",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-did-centre-reject-emergency-use-approval-of-covid-19-vaccines-heres-the-truth/",
        headline:
          "Fact Check: Did Centre Reject \u2018Emergency Use\u2019 Approval of COVID-19 Vaccines? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-is-the-man-praising-pm-modi-in-this-video-a-pak-journalist-heres-the-truth/",
        headline:
          "Fact Check: Is The Man Praising PM Modi In This Video A Pak Journalist? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-did-pm-modi-bow-down-before-gautam-adanis-wife-heres-the-truth/",
        headline:
          "Fact Check: Did PM Modi Bow Down Before Gautam Adani\u2019s Wife? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-train-with-adani-wilmar-ad-viral-with-false-claim-11164",
        headline: "Video Of Train With Adani-Wilmar Ad Viral With False Claim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/rare-photo-of-narendra-modi-and-jashodabens-wedding-a-factcheck-11166",
        headline:
          "Rare Photo Of Narendra Modi And Jashodaben's Wedding? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/pakistani-journalist-praising-prime-minister-narendra-modi-factcheck-11216",
        headline:
          "No, This Is Not A Pakistani Journalist Praising PM Narendra Modi",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-adani-wilmar-ad-on-indian-railway-viral-with-false-claims-during-kisan-andolan-11169",
        headline:
          "\u0905\u0921\u093e\u0928\u0940-\u0935\u093f\u0932\u092e\u093e\u0930 \u0935\u093f\u091c\u094d\u091e\u093e\u092a\u0928 \u0915\u0947 \u0938\u093e\u0925 \u091f\u094d\u0930\u0947\u0928 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-photo-viral-with-pm-narendra-modi-ki-shaadi-fake-claim-11185",
        headline:
          "\u0915\u094d\u092f\u093e \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0914\u0930 \u091c\u0936\u094b\u0926\u093e\u092c\u0947\u0928 \u0915\u0940 \u0936\u093e\u0926\u0940 \u0915\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/pm-narendra-modi-not-doing-pranam-to-adanis-wife-11199",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u092a\u094d\u0930\u0940\u0924\u0940 \u0905\u0921\u093e\u0928\u0940 \u0915\u094b \u0928\u092e\u0928 \u0915\u0930 \u0930\u0939\u0947 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-clip-claims-pakistani-journalist-praising-modi-11214",
        headline:
          "\u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u0940 \u0938\u0930\u093e\u0939\u0928\u093e \u0915\u0930\u0924\u093e \u092f\u0939 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u092a\u0924\u094d\u0930\u0915\u093e\u0930 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-is-not-the-family-portrait-of-covid-19-vaccine-developer-11121",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u0995\u09cb\u09ad\u09bf\u09a1-\u09e7\u09ef \u099f\u09bf\u0995\u09be \u09a8\u09bf\u09b0\u09cd\u09ae\u09be\u09a4\u09be\u09b0 \u09aa\u09be\u09b0\u09bf\u09ac\u09be\u09b0\u09bf\u0995 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-claim-stating-adani-registered-agri-companies-in-2019-is-false-11124",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u0986\u09a6\u09be\u09a8\u09bf \u09a8\u09a5\u09bf\u09ad\u09c1\u0995\u09cd\u09a4 \u0995\u09c3\u09b7\u09bf\u09aa\u09a3\u09cd\u09af \u09b8\u0982\u09b8\u09cd\u09a5\u09be, \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-images-showing-fish-eggs-infested-nematodes-shared-with-misleading-claim-11202",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u09ae\u09be\u099b\u09c7\u09b0 \u09a1\u09bf\u09ae\u09c7 \u09a8\u09bf\u09ae\u09be\u099f\u09cb\u09a1\u09b8 \u09b8\u0982\u0995\u09cd\u09b0\u09ae\u09a3\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-train-with-adani-wilmar-ad-viral-with-false-claim-11208",
        headline:
          "\u0986\u09a6\u09be\u09a8\u09bf-\u0989\u0987\u09b2\u09ae\u09be\u09b0-\u098f\u09b0 \u09ac\u09bf\u099c\u09cd\u099e\u09be\u09aa\u09a8 \u09b2\u09be\u0997\u09be\u09a8\u09cb \u099f\u09cd\u09b0\u09c7\u09a8\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/no-this-is-not-a-pakistani-journalist-praising-pm-modi-1750195-2020-12-16",
        headline:
          "Fact Check: No, this is not a Pakistani journalist praising PM Modi\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-pm-modi-is-not-greeting-gautam-adani-s-wife-in-this-viral-picture-1750581-2020-12-17",
        headline:
          "Fact Check: PM Modi is not greeting Gautam Adani's wife in this viral picture",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-did-centre-reject-emergency-use-approval-of-covid-19-vaccines-heres-the-truth/",
        headline:
          "Fact Check: Did Centre Reject \u2018Emergency Use\u2019 Approval of COVID-19 Vaccines? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-is-the-man-praising-pm-modi-in-this-video-a-pak-journalist-heres-the-truth/",
        headline:
          "Fact Check: Is The Man Praising PM Modi In This Video A Pak Journalist? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-did-pm-modi-bow-down-before-gautam-adanis-wife-heres-the-truth/",
        headline:
          "Fact Check: Did PM Modi Bow Down Before Gautam Adani\u2019s Wife? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/narendra-modi-priti-adani-gautam-adani-social-worker-viral-factcheck-farmers-protest-11197",
        headline:
          "PM Modi Greeting A Social Worker Viral Claiming He Bowed To Priti Adani",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/pakistani-journalist-praising-prime-minister-narendra-modi-factcheck-11216",
        headline:
          "No, This Is Not A Pakistani Journalist Praising PM Narendra Modi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-train-with-adani-wilmar-ad-viral-with-false-claim-11164",
        headline: "Video Of Train With Adani-Wilmar Ad Viral With False Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/rare-photo-of-narendra-modi-and-jashodabens-wedding-a-factcheck-11166",
        headline:
          "Rare Photo Of Narendra Modi And Jashodaben's Wedding? A FactCheck",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/old-images-showing-fish-eggs-infested-nematodes-shared-with-misleading-claim-11202",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u09ae\u09be\u099b\u09c7\u09b0 \u09a1\u09bf\u09ae\u09c7 \u09a8\u09bf\u09ae\u09be\u099f\u09cb\u09a1\u09b8 \u09b8\u0982\u0995\u09cd\u09b0\u09ae\u09a3\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-is-not-the-family-portrait-of-covid-19-vaccine-developer-11121",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u0995\u09cb\u09ad\u09bf\u09a1-\u09e7\u09ef \u099f\u09bf\u0995\u09be \u09a8\u09bf\u09b0\u09cd\u09ae\u09be\u09a4\u09be\u09b0 \u09aa\u09be\u09b0\u09bf\u09ac\u09be\u09b0\u09bf\u0995 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/video-of-train-with-adani-wilmar-ad-viral-with-false-claim-11208",
        headline:
          "\u0986\u09a6\u09be\u09a8\u09bf-\u0989\u0987\u09b2\u09ae\u09be\u09b0-\u098f\u09b0 \u09ac\u09bf\u099c\u09cd\u099e\u09be\u09aa\u09a8 \u09b2\u09be\u0997\u09be\u09a8\u09cb \u099f\u09cd\u09b0\u09c7\u09a8\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/viral-claim-stating-adani-registered-agri-companies-in-2019-is-false-11124",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7 \u0986\u09a6\u09be\u09a8\u09bf \u09a8\u09a5\u09bf\u09ad\u09c1\u0995\u09cd\u09a4 \u0995\u09c3\u09b7\u09bf\u09aa\u09a3\u09cd\u09af \u09b8\u0982\u09b8\u09cd\u09a5\u09be, \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/video-of-adani-wilmar-ad-on-indian-railway-viral-with-false-claims-during-kisan-andolan-11169",
        headline: "-           ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/viral-clip-claims-pakistani-journalist-praising-modi-11214",
        headline:
          "\u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u0940 \u0938\u0930\u093e\u0939\u0928\u093e \u0915\u0930\u0924\u093e \u092f\u0939 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u092a\u0924\u094d\u0930\u0915\u093e\u0930 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/pm-narendra-modi-not-doing-pranam-to-adanis-wife-11199",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u092a\u094d\u0930\u0940\u0924\u0940 \u0905\u0921\u093e\u0928\u0940 \u0915\u094b \u0928\u092e\u0928 \u0915\u0930 \u0930\u0939\u0947 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-claims-about-pm-narendra-modis-family-are-false-671934",
        headline:
          "Fact check: Viral claims about PM Narendra Modi's family are FALSE.",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-picture-of-facebook-ceo-mark-zuckerberg-pm-narendra-modi-is-morphed-671882",
        headline:
          "Fact check: Viral picture of Facebook CEO Mark Zuckerberg, PM Narendra Modi is morphed",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-woman-seen-with-modi-in-viral-photo-is-not-industrialist-gautam-adanis-wife-671914",
        headline:
          "Fact Check: Woman seen with Modi in viral photo is not industrialist Gautam Adani's wife",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-triceratops-was-not-caught-alive-in-indonesia-671860",
        headline:
          "Fact Check: No, Triceratops was not caught alive in Indonesia",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-us-celebrating-feb-19-as-shivaji-day-is-fake-news-671748",
        headline:
          "FACT CHECK: US celebrating Feb 19 as `Shivaji Day' is Fake news",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-adani-group-did-not-register-agricultural-companies-in-2019-671718",
        headline:
          "Fact Check: Adani Group did not register agricultural companies in 2019",
      },
    ],
    "4": [
      {
        url:
          "https://factly.in/telugu-sonia-gandhi-is-not-the-4th-richest-person-in-the-world/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a\u0c02\u0c32\u0c4b\u0c28\u0c3f \u0c27\u0c28\u0c35\u0c02\u0c24\u0c41\u0c32\u0c4d\u0c32\u0c4b \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c32\u0c40\u0c21\u0c30\u0c4d \u0c38\u0c4b\u0c28\u0c3f\u0c2f\u0c3e \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c28\u0c3e\u0c32\u0c4d\u0c17\u0c35 \u0c38\u0c4d\u0c25\u0c3e\u0c28\u0c02\u0c32\u0c4b \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/old-images-shared-as-staged-photoshoot-of-farmers-protesting-against-the-new-farm-bills/",
        headline:
          " Old images shared as staged photoshoot of farmers protesting against the new farm legislation",
      },
      {
        url:
          "https://digiteye.in/do-the-ration-cards-in-andhra-pradesh-carry-the-picture-of-jesus-christ-on-them-fact-check/",
        headline:
          "Do the ration cards in Andhra Pradesh carry the picture of Jesus Christ on them? Fact Check",
      },
      {
        url:
          "https://www.factcrescendo.com/bjp-leader-umesh-singh-misidentified-as-beaten-during-farmers-protest/",
        headline:
          "\u0915\u094d\u092f\u093e \u0926\u0947\u0936 \u0935\u093f\u0930\u094b\u0927\u0940 \u0928\u093e\u0930\u0947 \u0932\u0917\u093e\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0926\u094d\u0935\u093e\u0930\u093e \u092d\u093e\u091c\u092a\u093e \u0928\u0947\u0924\u093e \u0909\u092e\u0947\u0936 \u0938\u093f\u0902\u0939 \u0915\u094b \u092a\u0940\u091f\u093e \u0917\u092f\u093e? \u091c\u093e\u0928\u093f\u090f \u0938\u091a",
      },
      {
        url:
          "https://www.factcrescendo.com/this-is-not-a-supermarket-run-by-farmers-in-bangalore/",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u092c\u0947\u0902\u0917\u0932\u0941\u0930\u0941 \u092e\u0947\u0902 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0926\u094d\u0935\u093e\u0930\u093e \u091a\u0932\u093e\u090f \u0917\u092f\u0947 \u0938\u0941\u092a\u0930\u092e\u093e\u0930\u094d\u0915\u0947\u091f \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948 |",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-did-cong-supporters-vandalise-vegetable-shops-during-bharat-bandh-heres-the-truth/",
        headline:
          "Fact Check: Did Cong Supporters Vandalise Vegetable Shops During Bharat Bandh? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-old-video-of-rajnath-singh-addressing-farmers-shared-as-recent-one-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Rajnath Singh Addressing Farmers Shared As Recent One; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-no-aap-leaders-are-not-protesting-against-farm-laws-in-the-viral-video-heres-the-truth/",
        headline:
          "Fact Check: No, AAP Leaders Are NOT Protesting Against Farm Laws In The Viral Video; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-man-being-beaten-by-a-mob-in-this-viral-video-is-not-a-bjp-leader-heres-the-truth/",
        headline:
          "Fact Check: Man Being Beaten By A Mob In This Viral Video Is NOT A BJP Leader; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-dont-fall-for-this-morphed-picture-of-a-man-wearing-mask-with-cpm-imprinted-on-it/",
        headline:
          "Fact Check: Don\u2019t Fall For This Morphed Picture Of A Man Wearing Mask With CPM Imprinted On It",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-no-this-supermarket-in-bangalore-is-not-run-by-farmers-heres-the-truth/",
        headline:
          "Fact Check: No, This Supermarket In Bangalore Is NOT Run By Farmers; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-old-video-of-attack-on-bjp-leader-shared-as-being-recent-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Attack On BJP Leader Shared As Being Recent; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/bengaluru-based-start-ups-images-peddled-as-supermarket-run-by-farmers-11182",
        headline:
          "Bengaluru Start-Up's Images Peddled As Supermarket Run By Farmers",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-images-viral-as-vandalism-by-congress-during-bharat-bandh-11119",
        headline:
          "\u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0926\u094d\u0935\u093e\u0930\u093e \u0924\u094b\u095c\u092b\u094b\u095c \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/this-is-not-bjp-neta-umesh-singh-beaten-for-pakistan-zindabad-slogan-in-kisan-andolan-11158",
        headline:
          "\u0915\u094d\u092f\u093e \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0928\u0947 \u0927\u0930\u0928\u093e \u0938\u094d\u0925\u0932 \u092a\u0930 \u090f\u0915 \u092d\u093e\u091c\u092a\u093e \u0928\u0947\u0924\u093e \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0915\u0930 \u0926\u0940?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/bengaluru-farmers-supermarket-humus-start-up-kisan-andolan-farm-bills-news-11236",
        headline:
          "\u0928\u0939\u0940\u0902, \u092c\u0947\u0902\u0917\u0932\u0941\u0930\u0941 \u0915\u0947 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0928\u0947 \u092f\u0939 \u0938\u0941\u092a\u0930\u092e\u093e\u0930\u094d\u0915\u0947\u091f \u0928\u0939\u0940\u0902 \u0916\u094b\u0932\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-economist-kaushik-basus-quote-in-newspaper-clipping-viral-as-abhijeet-banerjee-11151",
        headline:
          "\u0995\u09c3\u09b7\u09bf \u0986\u0987\u09a8 \u09a8\u09bf\u09df\u09c7 \u0985\u09b0\u09cd\u09a5\u09a8\u09c0\u09a4\u09bf\u09ac\u09bf\u09a6 \u0995\u09cc\u09b6\u09bf\u0995 \u09ac\u09b8\u09c1\u09b0 \u09ac\u0995\u09cd\u09a4\u09ac\u09cd\u09af \u099b\u09a1\u09bc\u09be\u09b2 \u0985\u09ad\u09bf\u099c\u09bf\u09ce \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09a8\u09be\u09ae\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/bengaluru-start-ups-images-peddled-as-supermarket-run-by-farmers-11215",
        headline:
          "\u09ac\u09c7\u0999\u09cd\u0997\u09be\u09b2\u09c1\u09b0\u09c1\u09b0 \u09b8\u09cd\u099f\u09be\u09b0\u09cd\u099f-\u0986\u09aa\u09c7\u09b0 \u099b\u09ac\u09bf \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b8\u09c1\u09aa\u09be\u09b0 \u09ae\u09be\u09b0\u09cd\u0995\u09c7\u099f \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-a-supermarket-run-by-farmers-in-bengaluru-1749852-2020-12-15",
        headline:
          "Fact Check: This is not a supermarket run by farmers in Bengaluru",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-sudanese-model-didn-t-marry-herself-after-getting-fed-up-of-men-1750211-2020-12-16",
        headline:
          "Fact Check: This Sudanese model didn\u2019t marry herself after getting \u2018fed up of men\u2019",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-did-cong-supporters-vandalise-vegetable-shops-during-bharat-bandh-heres-the-truth/",
        headline:
          "Fact Check: Did Cong Supporters Vandalise Vegetable Shops During Bharat Bandh? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-old-video-of-rajnath-singh-addressing-farmers-shared-as-recent-one-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Rajnath Singh Addressing Farmers Shared As Recent One; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-no-aap-leaders-are-not-protesting-against-farm-laws-in-the-viral-video-heres-the-truth/",
        headline:
          "Fact Check: No, AAP Leaders Are NOT Protesting Against Farm Laws In The Viral Video; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-man-being-beaten-by-a-mob-in-this-viral-video-is-not-a-bjp-leader-heres-the-truth/",
        headline:
          "Fact Check: Man Being Beaten By A Mob In This Viral Video Is NOT A BJP Leader; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-dont-fall-for-this-morphed-picture-of-a-man-wearing-mask-with-cpm-imprinted-on-it/",
        headline:
          "Fact Check: Don\u2019t Fall For This Morphed Picture Of A Man Wearing Mask With CPM Imprinted On It",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-no-this-supermarket-in-bangalore-is-not-run-by-farmers-heres-the-truth/",
        headline:
          "Fact Check: No, This Supermarket In Bangalore Is NOT Run By Farmers; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-old-video-of-attack-on-bjp-leader-shared-as-being-recent-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Attack On BJP Leader Shared As Being Recent; Here\u2019s The Truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-economist-kaushik-basus-quote-in-newspaper-clipping-viral-as-abhijeet-banerjee-11151",
        headline:
          "\u0995\u09c3\u09b7\u09bf \u0986\u0987\u09a8 \u09a8\u09bf\u09df\u09c7 \u0985\u09b0\u09cd\u09a5\u09a8\u09c0\u09a4\u09bf\u09ac\u09bf\u09a6 \u0995\u09cc\u09b6\u09bf\u0995 \u09ac\u09b8\u09c1\u09b0 \u09ac\u0995\u09cd\u09a4\u09ac\u09cd\u09af \u099b\u09a1\u09bc\u09be\u09b2 \u0985\u09ad\u09bf\u099c\u09bf\u09ce \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09a8\u09be\u09ae\u09c7",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/cpim-mask-made-out-of-an-underwear-edited-image-viral-11201",
        headline: "CPIM Mask Made Out Of An Underwear? Edited Image Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/bengaluru-based-start-ups-images-peddled-as-supermarket-run-by-farmers-11182",
        headline:
          "Bengaluru Start-Up's Images Peddled As Supermarket Run By Farmers",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/was-a-bjp-leader-beaten-by-protesting-farmers-a-factcheck-11150",
        headline: "Was A BJP Leader Beaten By Protesting Farmers? A FactCheck",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/was-a-bjp-leader-beaten-by-protesting-farmers-a-factcheck-11184",
        headline:
          "\u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u0995\u09be\u09b0\u09c0 \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b9\u09be\u09a4\u09c7 \u09aa\u09cd\u09b0\u09b9\u09c3\u09a4 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09a8\u09c7\u09a4\u09be? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/amit-shah-in-west-bengal-mamata-banerjee-video-11237",
        headline:
          "\u09e8\u09e6\u09e6\u09ec \u09a4\u09c7 \u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09ac\u09bf\u09a7\u09be\u09a8\u09b8\u09ad\u09be \u09b2\u09ac\u09bf\u09a4\u09c7 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ab\u09bf\u09b0\u09c7 \u098f\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/bengaluru-start-ups-images-peddled-as-supermarket-run-by-farmers-11215",
        headline:
          "\u09ac\u09c7\u0999\u09cd\u0997\u09be\u09b2\u09c1\u09b0\u09c1\u09b0 \u09b8\u09cd\u099f\u09be\u09b0\u09cd\u099f-\u0986\u09aa\u09c7\u09b0 \u099b\u09ac\u09bf \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b8\u09c1\u09aa\u09be\u09b0 \u09ae\u09be\u09b0\u09cd\u0995\u09c7\u099f \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-images-viral-as-vandalism-by-congress-during-bharat-bandh-11119",
        headline:
          "\u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0926\u094d\u0935\u093e\u0930\u093e \u0924\u094b\u095c\u092b\u094b\u095c \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/bengaluru-farmers-supermarket-humus-start-up-kisan-andolan-farm-bills-news-11236",
        headline:
          "\u0928\u0939\u0940\u0902, \u092c\u0947\u0902\u0917\u0932\u0941\u0930\u0941 \u0915\u0947 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0928\u0947 \u092f\u0939 \u0938\u0941\u092a\u0930\u092e\u093e\u0930\u094d\u0915\u0947\u091f \u0928\u0939\u0940\u0902 \u0916\u094b\u0932\u093e \u0939\u0948",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-picture-of-congress-leader-sachin-pilot-with-bjp-chief-jp-nadda-is-morphed-671746",
        headline:
          "FACT CHECK: Picture of Congress leader Sachin Pilot with BJP chief JP Nadda Is morphed",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photograph-of-aap-minister-addressing-defence-volunteers-without-masks-is-from-2019-671717",
        headline:
          "Fact Check: Photograph of AAP minister addressing Defence volunteers without masks is from 2019",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-farmer-leader-vm-singh-is-billionaire-but-not-member-of-congress-671959",
        headline:
          "Fact check: Farmer leader VM Singh is billionaire, but not member of Congress",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-ysrp-leaders-did-not-attack-policeman-in-andhra-pradesh-671928",
        headline:
          "Fact check: No, YSRCP leaders did not attack policeman in Andhra Pradesh",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/2014-image-falsely-shared-as-pm-modis-visit-to-hospital-on-the-birth-of-ambanis-grandson/",
        headline:
          " 2014 image falsely shared as PM Modi\u2019s visit to hospital on the birth of Ambani\u2019s grandson",
      },
      {
        url:
          "https://factly.in/telugu-2014-image-falsely-shared-as-pm-modis-visit-to-hospital-on-the-birth-of-ambanis-grandson/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2e\u0c41\u0c15\u0c47\u0c37\u0c4d \u0c05\u0c02\u0c2c\u0c3e\u0c28\u0c40 \u0c2e\u0c28\u0c35\u0c21\u0c3f\u0c28\u0c3f \u0c1a\u0c42\u0c21\u0c4d\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c2a\u0c4d\u0c30\u0c27\u0c3e\u0c28\u0c3f \u0c2e\u0c4b\u0c26\u0c40 \u0c06\u0c38\u0c41\u0c2a\u0c24\u0c4d\u0c30\u0c3f\u0c15\u0c3f \u0c35\u0c46\u0c33\u0c4d\u0c33\u0c3e\u0c21\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-ahmadabad-airport-is-not-renamed-as-adani-airport-and-this-is-not-adani-rail/",
        headline:
          " \u0c05\u0c39\u0c4d\u0c2e\u0c26\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c35\u0c3f\u0c2e\u0c3e\u0c28\u0c3e\u0c36\u0c4d\u0c30\u0c2f\u0c02 \u0c2a\u0c47\u0c30\u0c41 \u2018\u0c05\u0c26\u0c3e\u0c28\u0c40 \u0c0e\u0c2f\u0c3f\u0c30\u0c4d \u0c2a\u0c4b\u0c30\u0c4d\u0c1f\u0c4d\u2019 \u0c17\u0c3e \u0c2e\u0c3e\u0c30\u0c32\u0c47\u0c26\u0c41, \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f\u0c26\u0c3f \u0c05\u0c26\u0c3e\u0c28\u0c40 \u0c30\u0c48\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/ahmedabad-airport-is-not-renamed-as-adani-airport-the-train-in-the-video-not-owned-by-adani/",
        headline:
          " Ahmedabad Airport is not renamed as \u2018Adani Airport\u2019; the train in the video not owned by Adani",
      },
      {
        url:
          "https://digiteye.in/fact-check-old-2014-image-resurfaces-claiming-modi-visited-mukesh-ambanis-grandson-in-hospital/",
        headline:
          "Fact Check: Old 2014 image resurfaces claiming Modi visited Mukesh Ambani\u2019s grandson in hospital",
      },
      {
        url:
          "https://www.factcrescendo.com/ahmedabad-airport-name-has-not-been-changed-to-adani-airport/",
        headline:
          "FactCheck- \u0915\u094d\u092f\u093e \u0905\u0939\u092e\u0926\u093e\u092c\u093e\u0926 \u090f\u092f\u0930\u092a\u094b\u0930\u094d\u091f \u0915\u093e \u0928\u093e\u092e \u2018\u0938\u0930\u0926\u093e\u0930 \u0935\u0932\u094d\u0932\u092d\u092d\u093e\u0908 \u092a\u091f\u0947\u0932 \u0907\u0902\u091f\u0930\u0928\u0947\u0936\u0928\u0932 \u090f\u092f\u0930\u092a\u094b\u0930\u094d\u091f\u2019 \u0938\u0947 \u092c\u0926\u0932\u0915\u0930 \u201c\u0905\u0921\u093e\u0928\u0940 \u090f\u092f\u0930\u092a\u094b\u0930\u094d\u091f\u201d \u0930\u0916 \u0926\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/14/fact-check-did-mukesh-ambani-meet-punjab-cm-capt-amarinder-singh-recently-in-mumbai-heres-the-truth/",
        headline:
          "Fact Check: Did Mukesh Ambani Meet Punjab CM Capt Amarinder Singh Recently In Mumbai? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-has-indian-railways-been-sold-to-the-adani-group-heres-the-truth/",
        headline:
          "Fact Check: Has Indian Railways Been \u2018Sold\u2019 To The Adani Group? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-has-sardar-vallabhbhai-patel-airport-been-renamed-as-adani-airport-heres-the-truth/",
        headline:
          "Fact Check: Has Sardar Vallabhbhai Patel Airport Been Renamed As Adani Airport? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-no-pm-modi-did-not-visit-ambanis-newborn-grandson-at-the-hospital-heres-the-truth/",
        headline:
          "Fact Check: No, PM Modi Did Not Visit Ambani\u2019s Newborn Grandson At The Hospital; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/17/fact-check-mukesh-ambanis-meeting-with-capt-amarinder-singh-falsely-claimed-to-be-recent/",
        headline:
          "Fact Check: Mukesh Ambani\u2019s Meeting With Capt Amarinder Singh Falsely Claimed To Be Recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/20/fact-check-dont-fall-for-morphed-time-magazine-cover-of-joe-biden-and-kamala-harris-with-xi-jinping/",
        headline:
          "Fact Check: Don\u2019t Fall For Morphed Time Magazine Cover Of Joe Biden And Kamala Harris With Xi Jinping",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-image-claiming-to-be-ayodhya-divya-chauraha-is-from-gujarat-11147",
        headline:
          "\u091c\u0940 \u0928\u0939\u0940\u0902, \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0905\u092f\u094b\u0927\u094d\u092f\u093e \u092e\u0947\u0902 \u0928\u093f\u0930\u094d\u092e\u093f\u0924 \u0926\u093f\u0935\u094d\u092f \u091a\u094c\u0930\u093e\u0939\u0947 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/ahmedabad-adani-group-sardar-vallabhbhai-patel-airport-11205",
        headline:
          "\u0905\u0939\u092e\u0926\u093e\u092c\u093e\u0926 \u0939\u0935\u093e\u0908\u0905\u0921\u094d\u0921\u0947 \u0915\u093e \u0928\u093e\u092e \u0905\u0921\u093e\u0928\u0940 \u090f\u092f\u0930\u092a\u094b\u0930\u094d\u091f \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/misleading-images-of-patel-protest-and-pm-modis-bhumi-pujan-linked-to-farmers-protest-and-central-vista-project-11159",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7 \u099c\u09c1\u09a1\u09bc\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb \u09aa\u09be\u09a4\u09bf\u09a6\u09be\u09b0 \u09ae\u09bf\u099b\u09bf\u09b2 \u0993 \u09ae\u09cb\u09a6\u09c0\u09b0 \u0985\u09af\u09cb\u09a7\u09cd\u09af\u09be \u09ad\u09c2\u09ae\u09bf \u09aa\u09c2\u099c\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-altered-cover-image-of-time-shows-xi-jinping-as-person-of-the-year-1750546-2020-12-17",
        headline:
          "Fact Check: Altered cover image of TIME shows Xi Jinping as 'Person of the Year'",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/this-is-not-a-5-000-year-old-temple-discovered-during-road-widening-in-ayodhya-1751208-2020-12-19",
        headline:
          "Fact Check: This is not a 5,000-year-old temple discovered during road widening in Ayodhya\u00a0",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/14/fact-check-did-mukesh-ambani-meet-punjab-cm-capt-amarinder-singh-recently-in-mumbai-heres-the-truth/",
        headline:
          "Fact Check: Did Mukesh Ambani Meet Punjab CM Capt Amarinder Singh Recently In Mumbai? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-has-indian-railways-been-sold-to-the-adani-group-heres-the-truth/",
        headline:
          "Fact Check: Has Indian Railways Been \u2018Sold\u2019 To The Adani Group? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-has-sardar-vallabhbhai-patel-airport-been-renamed-as-adani-airport-heres-the-truth/",
        headline:
          "Fact Check: Has Sardar Vallabhbhai Patel Airport Been Renamed As Adani Airport? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-no-pm-modi-did-not-visit-ambanis-newborn-grandson-at-the-hospital-heres-the-truth/",
        headline:
          "Fact Check: No, PM Modi Did Not Visit Ambani\u2019s Newborn Grandson At The Hospital; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/17/fact-check-mukesh-ambanis-meeting-with-capt-amarinder-singh-falsely-claimed-to-be-recent/",
        headline:
          "Fact Check: Mukesh Ambani\u2019s Meeting With Capt Amarinder Singh Falsely Claimed To Be Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/20/fact-check-dont-fall-for-morphed-time-magazine-cover-of-joe-biden-and-kamala-harris-with-xi-jinping/",
        headline:
          "Fact Check: Don\u2019t Fall For Morphed Time Magazine Cover Of Joe Biden And Kamala Harris With Xi Jinping",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2017-video-of-punjab-cm-amarinder-singh-mukesh-ambani-meeting-revived-11146",
        headline:
          "2017 Video Of Punjab CM Amarinder Singh-Mukesh Ambani Meeting Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/viral-image-does-not-show-an-under-construction-ram-temple-in-ayodhya-11177",
        headline:
          "Viral Image Does Not Show An Under-Construction Ram Temple In Ayodhya",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/adani-airport-ahmedabad-airports-sardar-vallabhbhai-patel-factcheck-11204",
        headline: "Ahmedabad Airport Has Not Been Renamed To Adani Airport",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2017-video-of-punjab-cm-amarinder-singh-mukesh-ambani-meeting-revived-11187",
        headline:
          "\u0985\u09ae\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09b8\u09bf\u0982\u09b9 \u0993 \u09ae\u09c1\u0995\u09c7\u09b6 \u0985\u09ae\u09cd\u09ac\u09be\u09a8\u09c0\u09b0 \u09e8\u09e6\u09e7\u09ed \u09b8\u09be\u09b2\u09c7 \u09b8\u09be\u0995\u09cd\u09b7\u09be\u09a4\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/cropped-image-of-asansol-municipality-signboard-viral-with-false-claims-11176",
        headline:
          "\u0986\u09b8\u09be\u09a8\u09b8\u09cb\u09b2 \u09aa\u09cc\u09b0\u09a8\u09bf\u0997\u09ae \u09b8\u09be\u0987\u09a8\u09ac\u09cb\u09b0\u09cd\u09a1\u09c7 \u09ac\u09be\u0982\u09b2\u09be \u09ac\u09cd\u09b0\u09be\u09a4\u09cd\u09af? \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u0995\u09b0\u09be \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/image-of-detention-camp-in-texas-revived-as-assam-11118",
        headline:
          "\u099f\u09c7\u0995\u09cd\u09b8\u09be\u09b8\u09c7\u09b0 \u09a1\u09bf\u099f\u09c7\u09a8\u09b6\u09a8 \u0995\u09cd\u09af\u09be\u09ae\u09cd\u09aa\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u0985\u09b8\u09ae\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/misleading-images-of-patel-protest-and-pm-modis-bhumi-pujan-linked-to-farmers-protest-and-central-vista-project-11159",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7 \u099c\u09c1\u09a1\u09bc\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb \u09aa\u09be\u09a4\u09bf\u09a6\u09be\u09b0 \u09ae\u09bf\u099b\u09bf\u09b2 \u0993 \u09ae\u09cb\u09a6\u09c0\u09b0 \u0985\u09af\u09cb\u09a7\u09cd\u09af\u09be \u09ad\u09c2\u09ae\u09bf \u09aa\u09c2\u099c\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/ahmedabad-adani-group-sardar-vallabhbhai-patel-airport-11205",
        headline:
          "\u0905\u0939\u092e\u0926\u093e\u092c\u093e\u0926 \u0939\u0935\u093e\u0908\u0905\u0921\u094d\u0921\u0947 \u0915\u093e \u0928\u093e\u092e \u0905\u0921\u093e\u0928\u0940 \u090f\u092f\u0930\u092a\u094b\u0930\u094d\u091f \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/kashmiri-boy-who-looks-like-shahrukh-khan-photo-is-edited-11126",
        headline: ",        ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/this-is-not-bjp-neta-umesh-singh-beaten-for-pakistan-zindabad-slogan-in-kisan-andolan-11158",
        headline: "            ?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-indian-railways-has-not-been-taken-over-by-adani-group-671831",
        headline:
          "Fact Check: No, Indian Railways has not been taken over by Adani group",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-modi-did-not-visit-mukesh-ambanis-newborn-grandson-at-hospital-671750",
        headline:
          "Fact Check: Modi did not visit Mukesh Ambani's newborn grandson at hospital",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-picture-of-gigantic-mace-is-from-gujarat-not-ayodhya-671832",
        headline:
          "Fact Check: Picture of gigantic mace is from Gujarat, not Ayodhya",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-temple-in-viral-image-is-from-varanasi-not-ayodhya-671936",
        headline:
          "Fact Check: Temple in viral image is from Varanasi, not Ayodhya",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-ahmedabads-sardar-vallabhbhai-patel-airport-has-not-been-renamed-as-adani-airport-671815",
        headline:
          "Fact Check: No, Ahmedabad's Sardar Vallabhbhai Patel airport has not been renamed as Adani airport",
      },
    ],
  },
  numpy_seed: 20,
  number_of_articles: 208,
}
