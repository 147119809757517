export const data = {
  mdsDat: {
    x: [
      -0.11681113305031525,
      0.2532782236406616,
      0.1534747088277017,
      -0.08032208320893196,
      0.13280578797616058,
      -0.3424255041852767,
    ],
    y: [
      0.35686938494080567,
      0.07138978402937483,
      0.033199581641806815,
      -0.27119742300216965,
      -0.1439802696994109,
      -0.046281057910407215,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      13.437197754605702,
      15.634794729513523,
      21.706102338558985,
      17.70083578150516,
      13.437925148073868,
      18.083144247742762,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      11.0,
      8.0,
      10.0,
      8.0,
      6.0,
      5.0,
      5.0,
      6.0,
      5.0,
      5.0,
      3.0001128063648492,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      4.000150408486466,
      4.000150408486466,
      4.000150408486466,
      3.0001128063648492,
      3.0001128063648492,
      2.000075204243233,
      2.000075204243233,
      2.000075204243233,
      1.0000376021216164,
      5.000188010608082,
      3.0001128063648492,
      3.0001128063648492,
      3.0001128063648492,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      1.0001100099705345,
      1.0001100099705345,
      2.000220019941069,
      2.000220019941069,
      1.0001100099705345,
      1.0001100099705345,
      1.0001100099705345,
      4.000440039882138,
      4.000440039882138,
      3.0003300299116034,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      3.0003300299116034,
      2.000220019941069,
      2.000220019941069,
      1.0000311434550389,
      1.0000311434550389,
      2.0000622869100777,
      1.0000311434550389,
      1.0000311434550389,
      2.0000622869100777,
      1.0000311434550389,
      1.0000311434550389,
      1.0000311434550389,
      4.000124573820155,
      11.000342578005428,
      10.000311434550389,
      8.00024914764031,
      5.000155717275194,
      5.000155717275194,
      4.000124573820155,
      4.000124573820155,
      3.0000934303651166,
      3.0000934303651166,
      3.0000934303651166,
      3.0000934303651166,
      5.000155717275194,
      4.000124573820155,
      5.000163100322989,
      5.000163100322989,
      5.000163100322989,
      1.000032620064598,
      3.0000978601937938,
      1.000032620064598,
      2.000065240129196,
      1.000032620064598,
      1.000032620064598,
      2.000065240129196,
      6.0001957203875875,
      4.000130480258392,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      1.0000917369816513,
      3.0002752109449538,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      2.0001834739633026,
      4.000366947926605,
      3.0002752109449538,
      3.0002752109449538,
      3.0002752109449538,
      3.0002752109449538,
      3.0002752109449538,
      2.0001834739633026,
      2.0001834739633026,
      2.0001834739633026,
      2.0001834739633026,
      2.0001834739633026,
      4.000366947926605,
      2.0001834739633026,
      2.0001834739633026,
      1.9994790925361277,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      1.9994790925361277,
      1.9994790925361277,
      0.9997395462680638,
      8.997655916412574,
      5.998437277608383,
      5.998437277608383,
      4.998697731340319,
      4.998697731340319,
      3.9989581850722553,
      3.9989581850722553,
      2.9992186388041917,
      2.9992186388041917,
      2.9992186388041917,
      4.998697731340319,
      2.9992186388041917,
    ],
    Term: [
      "mamata",
      "boy",
      "banerjee",
      "mamata_banerjee",
      "gandhi",
      "water",
      "ghaziabad",
      "lockdown",
      "rahul",
      "rahul_gandhi",
      "shah",
      "sc",
      "list",
      "energy",
      "second",
      "shivle",
      "new",
      "release",
      "wave",
      "pour",
      "announce",
      "youth",
      "covid",
      "revive",
      "restriction",
      "amit",
      "assaduddin",
      "district",
      "dwarka",
      "lockdown",
      "yogi",
      "adityanath",
      "cm",
      "ipl",
      "tell",
      "make",
      "tikeet",
      "police",
      "people",
      "adopt",
      "recently",
      "top",
      "sourav",
      "dhoni",
      "buddhism",
      "free",
      "mamta",
      "pretend",
      "hike",
      "mps",
      "nita",
      "campaign",
      "title",
      "telangana",
      "ambani",
      "bandyopadhyay",
      "normally",
      "mc",
      "toe",
      "goondas",
      "mirror",
      "hindu",
      "pakistan",
      "gundas",
      "sachin",
      "show",
      "mamata",
      "banerjee",
      "mamata_banerjee",
      "say",
      "leg",
      "queen",
      "injury",
      "left_leg",
      "morph",
      "wheelchair",
      "right",
      "modi",
      "edit",
      "rahul_gandhi",
      "rahul",
      "train",
      "congratulate",
      "head",
      "ferrix",
      "comedy",
      "non",
      "join",
      "tonsure",
      "gandhi",
      "cancel",
      "congress",
      "minister",
      "kerala",
      "prime",
      "election",
      "march",
      "railway",
      "om",
      "dargah",
      "activist",
      "fountain",
      "rss",
      "circuit",
      "land",
      "strange",
      "sector",
      "govt",
      "house",
      "robot",
      "coffee_house",
      "sell",
      "rajasthan",
      "coffee",
      "intelligent",
      "central",
      "worker",
      "cr",
      "sound",
      "bjp",
      "report",
      "government",
      "teenager",
      "fly",
      "event",
      "hot",
      "annual",
      "population",
      "allegedly",
      "distribute",
      "story",
      "belgium",
      "boy",
      "ghaziabad",
      "water",
      "beat",
      "bangladesh",
      "minor",
      "muslim",
      "drinking_water",
      "drink",
      "thrash",
      "temple",
      "madrasa",
    ],
    Total: [
      11.0,
      8.0,
      10.0,
      8.0,
      6.0,
      5.0,
      5.0,
      6.0,
      5.0,
      5.0,
      3.0001128063648492,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      1.0000376021216164,
      4.000150408486466,
      4.000150408486466,
      4.000150408486466,
      3.0001128063648492,
      3.0001128063648492,
      2.000075204243233,
      2.000075204243233,
      2.000075204243233,
      1.0000376021216164,
      6.99966710314421,
      4.000143949819888,
      4.000143949819888,
      4.0002045433465,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      1.0001100099705345,
      1.0001100099705345,
      2.000220019941069,
      2.000220019941069,
      1.0001100099705345,
      1.0001100099705345,
      1.0001100099705345,
      4.000440039882138,
      4.000440039882138,
      3.0003300299116034,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      2.000220019941069,
      4.000361173366642,
      2.000220019941069,
      3.0002511633961078,
      1.0000311434550389,
      1.0000311434550389,
      2.0000622869100777,
      1.0000311434550389,
      1.0000311434550389,
      2.0000622869100777,
      1.0000311434550389,
      1.0000311434550389,
      1.0000311434550389,
      4.000124573820155,
      11.000342578005428,
      10.000311434550389,
      8.00024914764031,
      5.000155717275194,
      5.000155717275194,
      4.000124573820155,
      4.000124573820155,
      3.0000934303651166,
      3.0000934303651166,
      3.0000934303651166,
      3.0000934303651166,
      7.000280074321443,
      5.000157193884753,
      5.000163100322989,
      5.000163100322989,
      5.000163100322989,
      1.000032620064598,
      3.0000978601937938,
      1.000032620064598,
      2.000065240129196,
      1.000032620064598,
      1.000032620064598,
      2.000065240129196,
      6.0001957203875875,
      4.000130480258392,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.0000978601937938,
      3.9998374064618574,
      1.0000917369816513,
      3.0002752109449538,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      1.0000917369816513,
      2.0001834739633026,
      4.000366947926605,
      3.0002752109449538,
      3.0002752109449538,
      3.0002752109449538,
      3.0002752109449538,
      3.0002752109449538,
      2.0001834739633026,
      2.0001834739633026,
      2.0001834739633026,
      2.0001834739633026,
      2.0001834739633026,
      6.000432188055801,
      2.0001834739633026,
      4.000324627388876,
      1.9994790925361277,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      0.9997395462680638,
      1.9994790925361277,
      1.9994790925361277,
      0.9997395462680638,
      8.997655916412574,
      5.998437277608383,
      5.998437277608383,
      4.998697731340319,
      4.998697731340319,
      3.9989581850722553,
      3.9989581850722553,
      2.9992186388041917,
      2.9992186388041917,
      2.9992186388041917,
      5.99878946832197,
      2.9992186388041917,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      1.6708,
      1.7195,
      1.7195,
      1.7194,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.8557,
      1.568,
      1.8557,
      1.4502,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.5276,
      1.1911,
      1.3044,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.7316,
      1.4439,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      2.0071,
      1.6016,
      2.0071,
      1.314,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.7102,
      1.5278,
      1.7102,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.5458,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -3.2581,
      -3.2581,
      -3.2581,
      -3.5458,
      -3.5458,
      -3.9512,
      -3.9512,
      -3.9512,
      -4.6444,
      -3.035,
      -3.5458,
      -3.5458,
      -3.5458,
      -4.1026,
      -4.1026,
      -4.1026,
      -4.7958,
      -4.7958,
      -4.1026,
      -4.1026,
      -4.7958,
      -4.7958,
      -4.7958,
      -3.4095,
      -3.4095,
      -3.6972,
      -4.1026,
      -4.1026,
      -4.1026,
      -4.1026,
      -4.1026,
      -4.1026,
      -4.1026,
      -3.6972,
      -4.1026,
      -4.1026,
      -5.124,
      -5.124,
      -4.4308,
      -5.124,
      -5.124,
      -4.4308,
      -5.124,
      -5.124,
      -5.124,
      -3.7377,
      -2.7261,
      -2.8214,
      -3.0445,
      -3.5145,
      -3.5145,
      -3.7377,
      -3.7377,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -3.5145,
      -3.7377,
      -3.3105,
      -3.3105,
      -3.3105,
      -4.92,
      -3.8214,
      -4.92,
      -4.2268,
      -4.92,
      -4.92,
      -4.2268,
      -3.1282,
      -3.5337,
      -3.8214,
      -3.8214,
      -3.8214,
      -3.8214,
      -3.8214,
      -3.8214,
      -3.8214,
      -4.6444,
      -3.5458,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -4.6444,
      -3.9512,
      -3.2581,
      -3.5458,
      -3.5458,
      -3.5458,
      -3.5458,
      -3.5458,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.2581,
      -3.9512,
      -3.9512,
      -4.2485,
      -4.9416,
      -4.9416,
      -4.9416,
      -4.9416,
      -4.9416,
      -4.9416,
      -4.2485,
      -4.2485,
      -4.9416,
      -2.7444,
      -3.1499,
      -3.1499,
      -3.3322,
      -3.3322,
      -3.5553,
      -3.5553,
      -3.843,
      -3.843,
      -3.843,
      -3.3322,
      -3.843,
    ],
  },
  "token.table": {
    Topic: [
      5,
      1,
      3,
      2,
      6,
      2,
      1,
      1,
      6,
      1,
      2,
      3,
      3,
      6,
      6,
      6,
      4,
      5,
      6,
      2,
      2,
      4,
      5,
      5,
      1,
      5,
      5,
      5,
      4,
      4,
      4,
      1,
      5,
      5,
      2,
      6,
      1,
      6,
      6,
      1,
      3,
      4,
      4,
      1,
      6,
      4,
      6,
      5,
      2,
      4,
      6,
      3,
      2,
      3,
      5,
      5,
      3,
      4,
      2,
      3,
      6,
      5,
      3,
      5,
      2,
      4,
      4,
      5,
      3,
      3,
      1,
      1,
      6,
      6,
      2,
      3,
      3,
      2,
      4,
      3,
      4,
      6,
      3,
      3,
      4,
      5,
      3,
      2,
      6,
      1,
      2,
      4,
      3,
      5,
      3,
      2,
      2,
      6,
      1,
      2,
      4,
      3,
      4,
      4,
      4,
      6,
      5,
      2,
      1,
      5,
      1,
      1,
      3,
      5,
      5,
      3,
      3,
      1,
      1,
      5,
      5,
      1,
      1,
      3,
      5,
      2,
      6,
      5,
      6,
      2,
      3,
      2,
      5,
      6,
      6,
      2,
      2,
      3,
      4,
      2,
      4,
      6,
      1,
      3,
      5,
      1,
      3,
      1,
    ],
    Freq: [
      0.9999082714332506,
      0.7499730103800587,
      0.24999100346001957,
      0.999890002130328,
      1.0002605215857554,
      0.999890002130328,
      0.99996239929225,
      0.99996239929225,
      1.0002605215857554,
      0.99996239929225,
      0.6666108572510702,
      0.3333054286255351,
      0.9999688575148458,
      1.0002605215857554,
      1.0002605215857554,
      1.0002605215857554,
      0.33330932461516904,
      0.6666186492303381,
      1.0002605215857554,
      0.999890002130328,
      0.999890002130328,
      0.9999673809994359,
      0.9999082714332506,
      0.9999082714332506,
      0.7499616500835863,
      0.24998721669452875,
      0.9999082714332506,
      0.9999082714332506,
      0.9999673809994359,
      0.9999673809994359,
      0.9999673809994359,
      0.99996239929225,
      0.9999082714332506,
      0.9999082714332506,
      0.999890002130328,
      1.0002605215857554,
      0.99996239929225,
      1.0002605215857554,
      1.0002605215857554,
      0.99996239929225,
      0.7999748497691319,
      0.19999371244228298,
      0.9999673809994359,
      0.99996239929225,
      1.0002605215857554,
      0.9999673809994359,
      1.0002605215857554,
      0.9999082714332506,
      0.999890002130328,
      0.9999673809994359,
      1.0002605215857554,
      0.9999688575148458,
      0.2499797124346701,
      0.2499797124346701,
      0.4999594248693402,
      0.9999082714332506,
      0.9999688575148458,
      0.9999673809994359,
      0.999890002130328,
      0.9999688575148458,
      1.0002605215857554,
      0.9999082714332506,
      0.9999688575148458,
      0.9999082714332506,
      0.999890002130328,
      0.9999673809994359,
      0.9999673809994359,
      0.9999082714332506,
      0.9999688575148458,
      0.9999688575148458,
      0.99996239929225,
      0.714319684968165,
      0.28572787398726596,
      1.0002605215857554,
      0.999890002130328,
      0.9999688575148457,
      0.9999688575148458,
      0.999890002130328,
      0.9999673809994359,
      0.9999688575148458,
      0.9999673809994359,
      1.0002605215857554,
      0.9999688575148458,
      0.7142571364167403,
      0.14285142728334807,
      0.14285142728334807,
      0.9999688575148458,
      0.999890002130328,
      1.0002605215857554,
      0.99996239929225,
      0.999890002130328,
      0.9999673809994359,
      0.9999688575148458,
      0.9999082714332506,
      0.9999688575148458,
      0.999890002130328,
      0.999890002130328,
      1.0002605215857554,
      0.99996239929225,
      0.999890002130328,
      0.9999673809994359,
      0.9999688575148458,
      0.9999673809994362,
      0.9999673809994362,
      0.7500304875276705,
      0.2500101625092235,
      0.9999082714332506,
      0.999890002130328,
      0.99996239929225,
      0.9999082714332506,
      0.99996239929225,
      0.99996239929225,
      0.9999688575148458,
      0.9999082714332506,
      0.9999082714332506,
      0.9999688575148458,
      0.9999688575148458,
      0.99996239929225,
      0.99996239929225,
      0.9999082714332506,
      0.9999082714332506,
      0.99996239929225,
      0.99996239929225,
      0.9999688575148458,
      0.9999082714332506,
      0.999890002130328,
      1.0002605215857554,
      0.9999082714332506,
      1.0002605215857554,
      0.7499322861078682,
      0.24997742870262277,
      0.999890002130328,
      0.16670029933217978,
      0.8335014966608989,
      1.0002605215857554,
      0.999890002130328,
      0.999890002130328,
      0.9999688575148458,
      0.9999673809994359,
      0.999890002130328,
      0.9999673809994362,
      1.0002605215857554,
      0.99996239929225,
      0.9999688575148458,
      0.9999082714332506,
      0.7499730103800587,
      0.24999100346001957,
      0.99996239929225,
    ],
    Term: [
      "activist",
      "adityanath",
      "adityanath",
      "adopt",
      "allegedly",
      "ambani",
      "amit",
      "announce",
      "annual",
      "assaduddin",
      "bandyopadhyay",
      "bandyopadhyay",
      "banerjee",
      "bangladesh",
      "beat",
      "belgium",
      "bjp",
      "bjp",
      "boy",
      "buddhism",
      "campaign",
      "cancel",
      "central",
      "circuit",
      "cm",
      "cm",
      "coffee",
      "coffee_house",
      "comedy",
      "congratulate",
      "congress",
      "covid",
      "cr",
      "dargah",
      "dhoni",
      "distribute",
      "district",
      "drink",
      "drinking_water",
      "dwarka",
      "edit",
      "edit",
      "election",
      "energy",
      "event",
      "ferrix",
      "fly",
      "fountain",
      "free",
      "gandhi",
      "ghaziabad",
      "goondas",
      "government",
      "government",
      "government",
      "govt",
      "gundas",
      "head",
      "hike",
      "hindu",
      "hot",
      "house",
      "injury",
      "intelligent",
      "ipl",
      "join",
      "kerala",
      "land",
      "left_leg",
      "leg",
      "list",
      "lockdown",
      "lockdown",
      "madrasa",
      "make",
      "mamata",
      "mamata_banerjee",
      "mamta",
      "march",
      "mc",
      "minister",
      "minor",
      "mirror",
      "modi",
      "modi",
      "modi",
      "morph",
      "mps",
      "muslim",
      "new",
      "nita",
      "non",
      "normally",
      "om",
      "pakistan",
      "people",
      "police",
      "population",
      "pour",
      "pretend",
      "prime",
      "queen",
      "rahul",
      "rahul_gandhi",
      "railway",
      "railway",
      "rajasthan",
      "recently",
      "release",
      "report",
      "restriction",
      "revive",
      "right",
      "robot",
      "rss",
      "sachin",
      "say",
      "sc",
      "second",
      "sector",
      "sell",
      "shah",
      "shivle",
      "show",
      "sound",
      "sourav",
      "story",
      "strange",
      "teenager",
      "telangana",
      "telangana",
      "tell",
      "temple",
      "temple",
      "thrash",
      "tikeet",
      "title",
      "toe",
      "tonsure",
      "top",
      "train",
      "water",
      "wave",
      "wheelchair",
      "worker",
      "yogi",
      "yogi",
      "youth",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-men-urinating-on-shivling-at-temple-viral-video-fake-news-12383",
        headline:
          "Old Video Of Youth Urinating On Shivling Revived With Fake Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-the-wire-jay-shah-case-supreme-court-apologising-amit-shah-defamation-case-factcheck-12420",
        headline:
          "No Element Of Truth: The Wire On Claims Of Apologising To Jay Shah In SC",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-yogi-adityanath-uttar-pradesh-lockdown-covid-19-old-video-12409",
        headline:
          "Old Video Of Yogi Adityanath Announcing Lockdown In 15 Districts Revived",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/old-video-of-covid-19-restrictions-in-karnataka-viral-as-recent-12393",
        headline:
          "Old Video Of COVID-19 Restrictions In Karnataka Viral As Recent",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/anakapalle-andhra-pradesh-youth-peeing-shivlinga-2018-fake-claim-12386",
        headline:
          "\u092f\u0941\u0935\u093e\u0913\u0902 \u0915\u093e \u0936\u093f\u0935\u0932\u093f\u0902\u0917 \u092a\u0930 \u092a\u0947\u0936\u093e\u092c \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bihar-me-15-june-tak-school-band-fake-letter-sanjay-kumar-singh-education-project-council-bihar-12418",
        headline: "     15           ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rahul-gandhi-assam-election-speech-dibrugarh-narendra-modi-speech-fake-news-12438",
        headline: "          :   ?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/amit-shah-asaduddin-owaisi-edited-image-west-bengal-assembly-elections-2021-akbaruddin-owaisi-12339",
        headline:
          "\u09ac\u09bf\u09a7\u09be\u09a8\u09b8\u09ad\u09be \u09ad\u09cb\u099f\u09c7\u09b0 \u0986\u0997\u09c7 \u0985\u09ae\u09bf\u09a4 \u09b6\u09be\u09b9-\u0986\u09b8\u09be\u09a6\u0989\u09a6\u09cd\u09a6\u09bf\u09a8 \u0993\u09df\u09c7\u0987\u09b8\u09bf\u09b0 \u099c\u09cb\u09dc\u09be\u09a4\u09be\u09b2\u09bf \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-dont-confuse-2020-video-of-covid-restrictions-announcement-by-yeddiyurappa-as-his-latest-move-675704",
        headline:
          "Fact Check: Don't confuse 2020 video of COVID restrictions announcement by Yeddiyurappa as his latest move",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-cm-yogi-adityanath-has-not-announced-lockdown-in-15-districts-of-up-675725",
        headline:
          "Fact Check: CM Yogi Adityanath has not announced lockdown in 15 districts of UP",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/youth-beware-viral-job-notification-is-fake-675713",
        headline: "Youth beware! Viral job notification is fake",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/17/fact-check-these-mysterious-tunnels-are-not-located-in-dwarka-india-heres-the-truth/",
        headline:
          "Fact Check: These \u2018Mysterious Tunnels\u2019 Are Not Located In Dwarka, India; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/18/fact-check-did-up-cm-yogi-adityanath-announce-lockdown-in-the-state-heres-the-truth/",
        headline:
          "Fact Check: Did UP CM Yogi Adityanath Announce Lockdown In the State? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/19/fact-check-did-former-jk-cm-farooq-abdullah-pour-milk-on-shiva-linga-on-mahashivaratri-heres-the-truth/",
        headline:
          "Fact Check: Did Former J&K CM Farooq Abdullah Pour Milk On Shiva Linga On Mahashivratri? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/national-rural-youth-training-and-employment-scheme-does-not-exist/",
        headline:
          " \u2018National Rural Youth Training and Employment Scheme\u2019 does not exist",
      },
      {
        url:
          "https://factly.in/old-message-revived-as-new-lockdown-restrictions-in-various-countries-to-contain-second-wave-of-covid-19/",
        headline:
          " Old message revived as new lockdown restrictions in various countries to contain second wave of COVID-19",
      },
      {
        url:
          "https://factly.in/24c-setting-for-air-conditioners-is-recommended-for-energy-conservation/",
        headline:
          " 24\u00b0C setting for air conditioners is recommended for energy conservation",
      },
      {
        url:
          "https://factly.in/icmr-did-not-release-this-list-of-covid-19-precautions/",
        headline: " ICMR did not release this list of COVID-19 precautions",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-haryana-has-not-announced-weekend-lockdown-viral-claim-is-not-true/",
        headline:
          "Fact Check: \u0939\u0930\u093f\u092f\u093e\u0923\u093e \u0928\u0947 \u0935\u0940\u0915\u0947\u0902\u0921 \u0932\u0949\u0915\u0921\u093e\u0909\u0928 \u0915\u0940 \u0918\u094b\u0937\u0923\u093e \u0928\u0939\u0940\u0902 \u0915\u0940 \u0939\u0948, \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u093e \u0917\u0932\u0924 \u0939\u0948",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/telugu-all-these-nations-are-providing-covid-19-vaccinations-free-of-charge-to-their-citizens/",
        headline:
          " \u0c08 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c41\u0c32\u0c4b \u0c2a\u0c47\u0c30\u0c4d\u0c15\u0c4a\u0c28\u0c4d\u0c28 \u0c05\u0c28\u0c4d\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a \u0c26\u0c47\u0c36\u0c3e\u0c32\u0c41 \u0c24\u0c2e \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c15\u0c3f \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d\u0c32\u0c28\u0c3f \u0c28\u0c3f \u0c09\u0c1a\u0c3f\u0c24\u0c02\u0c17\u0c3e \u0c05\u0c02\u0c26\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-an-old-images-related-with-delhi-riots-is-being-shared-as-recent-violence-in-telanganas-bhainsa-town/",
        headline:
          "Fact Check: \u0926\u093f\u0932\u094d\u0932\u0940 \u092e\u0947\u0902 \u0939\u0941\u090f \u0926\u0902\u0917\u0947 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u094b\u0902 \u0915\u094b \u0924\u0947\u0932\u0902\u0917\u093e\u0928\u093e \u092e\u0947\u0902 \u0939\u0941\u0908 \u0939\u093e\u0932\u093f\u092f\u093e \u0939\u093f\u0902\u0938\u093e \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-no-dhoni-has-not-converted-to-buddhism-this-image-is-related-to-an-ad-campaign/",
        headline:
          "Fact Check: \u0927\u094c\u0928\u0940 \u0915\u0947 \u092c\u094c\u0926\u094d\u0927 \u0927\u0930\u094d\u092e \u0905\u092a\u0928\u093e\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u092b\u0930\u094d\u091c\u0940, \u0910\u0921 \u0915\u0948\u0902\u092a\u0947\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u091d\u0942\u0920\u0947 \u092e\u0948\u0938\u0947\u091c \u0915\u0947 \u0938\u093e\u0925 \u0915\u0940 \u091c\u093e \u0930\u0939\u0940 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-no-indore-police-hasnt-started-free-ride-scheme-viral-message-is-fake/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: No, Indore Police Hasn\u2019t Started Free Ride Scheme, Viral Message Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/woman-dancing-video-national-health-mission-office-bhopal-madhya-pradesh-coronavirus-covid-19-corona-guidelines-fact-check-12439",
        headline:
          "Women's Day Celebration Clip From Bhopal Viral With Misleading Claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/nita-ambani-bhu-reliance-industries-visiting-faculty-12382",
        headline:
          "Nita Ambani As Visiting Faculty? Reliance, BHU Deny Post Protest",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/fact-check-mahendra-singh-dhoni-converts-to-buddhism-bhim-army-viral-fake-news-12356",
        headline:
          "\u0915\u094d\u092f\u093e \u0906\u0908\u092a\u0940\u090f\u0932 2021 \u0938\u0947 \u092a\u0939\u0932\u0947 \u092e\u0939\u0947\u0902\u0926\u094d\u0930 \u0938\u093f\u0902\u0939 \u0927\u094b\u0928\u0940 \u0928\u0947 \u092c\u094c\u0926\u094d\u0927 \u0927\u0930\u094d\u092e \u0905\u092a\u0928\u093e \u0932\u093f\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/kisan-andolan-rakesh-tikait-baba-ramdev-tweets-farmers-protest-12372",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0928\u0947\u0924\u093e \u0930\u093e\u0915\u0947\u0936 \u091f\u093f\u0915\u0948\u0924 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0915\u093f\u092f\u0947 \u0917\u090f \u0907\u0938 \u0935\u093e\u092f\u0930\u0932 \u091f\u094d\u0935\u0940\u091f \u0915\u093e \u0938\u091a \u0915\u094d\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/west-bengal-assembly-election-2021-morphed-image-of-newspaper-headline-with-mamata-banerjees-speech-revived-12391",
        headline:
          "\u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1\u09a6\u09c7\u09b0 \u0995\u09be\u0981\u09a6\u09be\u09ac\u09c7\u09a8 \u09ac\u09b2\u09be \u09b6\u09bf\u09b0\u09cb\u09a8\u09be\u09ae \u09b8\u09b9 \u099b\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/west-bengal-assembly-election-2021-fake-image-fact-check-nandigram-incident-morphed-image-viral-as-mamata-banerjee-faking-injury-12362",
        headline:
          "\u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df \u099a\u09cb\u099f\u09c7\u09b0 \u09ad\u09be\u09a8 \u0995\u09b0\u099b\u09c7\u09a8 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09be\u09b0\u099a\u09c1\u09aa\u09bf \u0995\u09b0\u09be \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/sourav-ganguly-fake-posters-edited-posters-viral-image-west-bengal-assembly-elections-2021-bjp-tmc-cpim-12384",
        headline:
          "\u09b8\u09cc\u09b0\u09ad \u0997\u0999\u09cd\u0997\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u0995\u09c7 \u09b0\u09be\u099c\u09a8\u09c0\u09a4\u09bf\u09a4\u09c7 \u09b8\u09cd\u09ac\u09be\u0997\u09a4 \u099c\u09be\u09a8\u09bf\u09df\u09c7 \u09ad\u09c1\u09df\u09cb \u09aa\u09cb\u09b8\u09cd\u099f\u09be\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-forget-hike-mps-face-30-pay-cut-675614",
        headline: "FACT CHECK: Forget hike, MPs face 30 % pay cut",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-dhoni-has-not-converted-to-buddhism-675610",
        headline: "Fact Check: No, Dhoni has not converted to Buddhism",
      },
      {
        url:
          "https://digiteye.in/uttarakhand-cms-daughter-seen-in-ripped-jeans-fact-check/",
        headline: "Uttarakhand CMs daughter seen in ripped jeans? Fact Check",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/16/fact-check-ipls-advt-campaign-image-falsely-shared-as-dhoni-converting-to-buddhism/",
        headline:
          "Fact Check: IPL\u2019s Advt Campaign Image Falsely Shared As Dhoni Converting To Buddhism",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/17/fact-check-did-nita-ambani-tweet-asking-people-to-write-about-hindutva-on-social-media-heres-the-truth/",
        headline:
          "Fact Check: Did Nita Ambani Tweet Asking People To Write About Hindutva On Social Media? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/18/fact-check-has-there-been-a-hike-in-allowances-of-mps-recently-heres-the-truth/",
        headline:
          "Fact Check: Has There Been A Hike In Allowances Of MPs Recently? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-basis-for-these-numbers-which-say-telangana-is-top-in-love-jihad-cases/",
        headline:
          " \u2018\u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b\u0c28\u0c47 \u2018\u0c32\u0c35\u0c4d \u0c1c\u0c3f\u0c39\u0c3e\u0c26\u0c4d\u2019 \u0c32\u0c4b \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c1f\u0c3e\u0c2a\u0c4d\u2019 \u0c05\u0c02\u0c1f\u0c42 \u0c07\u0c1a\u0c4d\u0c1a\u0c3f\u0c28 \u0c08 \u0c05\u0c02\u0c15\u0c46\u0c32\u0c15\u0c3f \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c05\u0c27\u0c3f\u0c15\u0c3e\u0c30\u0c3f\u0c15 \u0c06\u0c27\u0c3e\u0c30\u0c3e\u0c32\u0c41 \u0c32\u0c47\u0c35\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-telangana-government-provides-free-coaching-for-all-those-who-are-eligible/",
        headline:
          " \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32\u0c15\u0c47 \u0c15\u0c3e\u0c26\u0c41, \u0c05\u0c30\u0c4d\u0c39\u0c41\u0c32\u0c48\u0c28 \u0c05\u0c02\u0c26\u0c30\u0c3f\u0c15\u0c3f \u0c09\u0c1a\u0c3f\u0c24\u0c02\u0c17\u0c3e \u0c15\u0c4b\u0c1a\u0c3f\u0c02\u0c17\u0c4d \u0c05\u0c02\u0c26\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-image-created-using-editing-tools-viral-in-the-name-of-sun-set-on-mars/",
        headline:
          "Fact Check: \u0906\u0930\u094d\u091f\u093f\u0938\u094d\u091f \u0915\u0940 \u092c\u0928\u093e\u092f\u0940\u0902 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u092e\u0902\u0917\u0932 \u0917\u094d\u0930\u0939 \u0915\u0947 \u0938\u0942\u0930\u094d\u092f\u093e\u0938\u094d\u0924 \u0915\u0940 \u092a\u0939\u0932\u0940 \u092b\u094b\u091f\u094b \u092c\u0924\u093e \u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
    ],
    "3": [
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-uks-queen-says-thank-you-but-not-to-pm-modi-675561",
        headline:
          "Fact check: UK's queen says thank you\u2026 but not to PM Modi",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-of-mamata-banerjee-getting-up-from-her-wheelchair-and-walking-is-edited/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2e\u0c2e\u0c24\u0c3e \u0c2c\u0c46\u0c28\u0c30\u0c4d\u0c1c\u0c40 \u0c09\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c02\u0c21\u0c3f \u0c24\u0c28 \u0c35\u0c40\u0c32\u0c4d \u0c1a\u0c48\u0c30\u0c4d \u0c28\u0c41\u0c02\u0c21\u0c3f \u0c32\u0c47\u0c1a\u0c3f \u0c28\u0c21\u0c41\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-edited-image-shared-as-a-billboard-showing-the-queen-thanking-pm-modi-for-sending-vaccines-to-the-uk/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c07\u0c02\u0c17\u0c4d\u0c32\u0c3e\u0c02\u0c17\u0c4d \u0c2e\u0c39\u0c3e\u0c30\u0c3e\u0c23\u0c3f \u0c15\u0c4d\u0c35\u0c40\u0c28\u0c4d \u0c0e\u0c32\u0c3f\u0c1c\u0c2c\u0c46\u0c24\u0c4d \u0c28\u0c30\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c4b\u0c26\u0c40\u0c15\u0c3f \u0c27\u0c28\u0c4d\u0c2f\u0c35\u0c3e\u0c26\u0c3e\u0c32\u0c41 \u0c24\u0c46\u0c32\u0c41\u0c2a\u0c41\u0c24\u0c42 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c39\u0c3e\u0c30\u0c4d\u0c21\u0c3f\u0c02\u0c17\u0c4d \u0c05\u0c02\u0c1f\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/mamata-banerjees-bandage-changed-from-her-left-leg-to-right-leg-no-it-is-a-mirrored-image/",
        headline:
          " Mamata Banerjee\u2019s bandage changed from her left leg to right leg? No, it is a mirror image",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-this-bill-board-with-the-photo-of-queen-elizabeth-is-edited/",
        headline:
          "Fact Check: \u092c\u094d\u0930\u093f\u091f\u0947\u0928 \u0915\u0940 \u092e\u0939\u093e\u0930\u093e\u0928\u0940 \u090f\u0932\u093f\u091c\u093e\u092c\u0947\u0925 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0935\u093e\u0932\u093e \u092f\u0939 \u092c\u093f\u0932 \u092c\u094b\u0930\u094d\u0921 \u090f\u0921\u093f\u091f\u0947\u0921 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/west-bengal-assembly-election-2021-fake-image-fact-check-nandigram-incident-morphed-image-viral-as-mamata-banerjee-faking-injury-12347",
        headline: "Morphed Image Viral As Mamata Banerjee Faking Injury",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/mahua-moitra-yogi-adityanath-rally-west-bengal-elections-bjp-tmc-goondas-campaigning-uttar-pradesh-mamata-banerjee-fact-check-12377",
        headline:
          "Mahua Moitra Falsely Claims Yogi Adityanath Said He Will Kill 'TMC Goondas'",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-billboard-of-queen-elizabeth-ii-thanking-prime-minister-narendra-modi-for-covid-vaccine-fake-news-12354",
        headline:
          "Satire Photo Of Queen Thanking PM Modi For Vaccines Shared As Real",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-fake-news-gajendra-chauhan-shares-narendra-modis-old-picture-from-gujarat-village-12415",
        headline:
          "Photo Shows Narendra Modi Meeting Pakistani Hindu Refugees? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/no-mamata-banerjee-did-not-fake-her-injury-viral-pic-is-flipped-12387",
        headline:
          "No, Mamata Banerjee Did Not Fake Her Injury; Viral Pic Is Flipped",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/narendra-modi-meeting-pakistani-hindu-displaced-31-years-old-picture-gajendra-chauhan-12421",
        headline: "              ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/the-wire-apologize-to-jay-shah-supreme-court-amit-shah-gujarat-high-court-12431",
        headline: "              ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bangladesh-chittagong-madarsa-video-uttarpradesh-fake-claim-ghaziabad-muslim-boy-news-12401",
        headline: "        ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/narendra-modi-sachin-tendulkar-mukesh-ambani-photo-edit-viral-fake-claim-12412",
        headline:
          "\u092e\u094b\u0926\u0940-\u0938\u091a\u093f\u0928 \u0915\u0940 \u092e\u0941\u0932\u093e\u0958\u093e\u0924 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/no-mamata-banerjee-did-not-fake-her-injury-viral-pic-is-flipped-12397",
        headline:
          "\u09a8\u09be, \u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u0986\u0998\u09be\u09a4 \u09ae\u09c7\u0995\u09bf \u09a8\u09df; \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u0993\u09b2\u09cd\u099f\u09be\u09a8\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/mahua-moitra-yogi-adityanath-rally-west-bengal-elections-bjp-tmc-goondas-campaigning-uttar-pradesh-mamata-banerjee-fact-check-12390",
        headline:
          "\u09ae\u09b9\u09c1\u09df\u09be\u09b0 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5 \u09ac\u09b2\u09c7\u099b\u09c7\u09a8\u2014'\u099f\u09bf\u098f\u09ae\u09b8\u09bf \u0997\u09c1\u09a8\u09cd\u09a1\u09be\u09a6\u09c7\u09b0' \u09ae\u09c7\u09b0\u09c7 \u09ab\u09c7\u09b2\u09ac\u09c7\u09a8",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-didis-injuries-are-real-images-are-fake-675664",
        headline: "Fact check: Didi's injuries are real, images are fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-come-polls-morphed-mamata-banerjee-images-flood-social-media-675571",
        headline:
          "FACT CHECK: Come polls, morphed Mamata Banerjee images flood social media",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-photo-does-not-show-modi-visiting-hindu-refugees-from-pakistan-675780",
        headline:
          "Fact Check: Viral photo does not show Modi visiting Hindu refugees from Pakistan",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/16/fact-check-did-injured-mamata-banerjee-discard-wheelchair-walk-normally-heres-the-truth/",
        headline:
          "Fact Check: Did Injured Mamata Banerjee Discard Wheelchair & Walk Normally? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/17/fact-check-mamata-banerjees-bandage-dressing-did-not-change-from-left-leg-to-right-heres-the-truth/",
        headline:
          "Fact Check: Mamata Banerjee\u2019s Bandage Dressing Did Not Change From Left Leg To Right; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/morphed-image-shared-as-visuals-of-mamata-banerjee-suddenly-walking-away-from-her-wheelchair/",
        headline:
          " Morphed image shared as visuals of Mamata Banerjee suddenly walking away from her wheelchair",
      },
      {
        url:
          "https://factly.in/nissan-commercial-is-shared-as-visuals-of-a-truck-driver-rescuing-an-airliner-from-crashing/",
        headline:
          " Nissan commercial is shared as visuals of a truck driver rescuing an airliner from crashing",
      },
      {
        url:
          "https://factly.in/telugu-mamata-banerjees-bandage-changed-from-her-left-leg-to-right-leg-no-it-is-a-mirror-image/",
        headline:
          " \u0c05\u0c38\u0c32\u0c41 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f, \u0c2e\u0c2e\u0c24\u0c3e \u0c2c\u0c46\u0c28\u0c30\u0c4d\u0c1c\u0c40 \u0c0e\u0c21\u0c2e \u0c15\u0c3e\u0c32\u0c3f\u0c15\u0c3f \u0c17\u0c3e\u0c2f\u0c2e\u0c48\u0c24\u0c47 \u0c15\u0c41\u0c21\u0c3f \u0c15\u0c3e\u0c32\u0c3f\u0c15\u0c3f \u0c2a\u0c1f\u0c4d\u0c1f\u0c40 \u0c15\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-photos-shared-as-the-visuals-of-vandalised-properties-in-the-recent-bhainsa-communal-riots/",
        headline:
          " Old Photos shared as visuals of vandalised properties in the recent Bhainsa communal riots",
      },
      {
        url:
          "https://factly.in/telugu-2020-video-is-shared-as-lockdown-to-be-imposed-again-in-hyderabad/",
        headline:
          " 2020 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c32\u0c4b \u0c2e\u0c33\u0c4d\u0c33\u0c40 \u0c32\u0c3e\u0c15\u0c4d \u0c21\u0c4c\u0c28\u0c4d \u0c35\u0c3f\u0c27\u0c3f\u0c02\u0c1a\u0c2c\u0c4b\u0c24\u0c41\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-edited-picture-of-mamata-banerjee-leg-injury-viral-with-fake-claim/",
        headline:
          "Fact Check: Edited Picture of Mamata Banerjee Leg Injury Viral With Fake Claim",
      },
    ],
    "4": [
      {
        url:
          "https://factly.in/indian-railways-cancelling-all-passenger-train-services-in-march-2020-shared-as-recent/",
        headline:
          " Indian Railways cancelling all passenger train services in March 2020 shared as recent decision",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-the-viral-news-of-train-cancellation-is-one-year-old/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check : \u091f\u094d\u0930\u0947\u0928 \u0915\u0947 \u0930\u0926\u094d\u0926 \u0939\u094b\u0928\u0947 \u0915\u0940 \u0935\u093e\u092f\u0930\u0932 \u0916\u092c\u0930 \u090f\u0915 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-fake-news-rahul-gandhi-interaction-with-college-students-assam-elections-unemployment-rate-bjp-congress-12437",
        headline:
          "Clipped Video Of Rahul Gandhi's Assam Speech Viral With Fake Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/march-2020-bulletin-on-train-cancellation-shared-as-recent-12357",
        headline: "March 2020 Bulletin On Train Cancellation Shared As Recent",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/dasna-devi-mandir-ghaziabad-yati-ghaziabad-police-12395",
        headline: " :              ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/yashwant-sinha-tmc-news-fake-tweet-viral-narendra-modi-mamata-banerjee-12373",
        headline:
          "\u092a\u0942\u0930\u094d\u0935 \u0915\u0947\u0902\u0926\u094d\u0930\u0940\u092f \u092e\u0902\u0924\u094d\u0930\u0940 \u092f\u0936\u0935\u0902\u0924 \u0938\u093f\u0928\u094d\u0939\u093e \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u095e\u0930\u094d\u095b\u0940 \u091f\u094d\u0935\u0940\u091f \u0939\u0941\u0906 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-billboard-of-queen-elizabeth-ii-thanking-prime-minister-narendra-modi-for-covid-vaccine-fake-news-12365",
        headline:
          "\u099f\u09bf\u0995\u09be\u09b0 \u099c\u09a8\u09cd\u09af \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0\u0995\u09c7 \u09a7\u09a8\u09cd\u09af\u09ac\u09be\u09a6 \u09b0\u09be\u09a3\u09c0\u09b0, \u0995\u09cc\u09a4\u09c1\u0995 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u0986\u09b8\u09b2 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/trains-not-canceled-old-news-clip-shared-to-create-confusion-675573",
        headline:
          "Trains not canceled, old news clip shared to create confusion",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/17/fact-check-picture-of-railway-station-shared-as-mosque-built-by-bangladeshi-cricketer-shakib-al-hasan/",
        headline:
          "Fact Check: Picture Of Railway Station Shared As Mosque Built By Bangladeshi Cricketer Shakib Al Hasan",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/17/fact-check-railways-will-not-impose-extra-charge-on-passengers-who-sleep-during-journey-heres-the-truth/",
        headline:
          "Fact Check: Railways Will NOT Impose Extra Charge On Passengers Who Sleep During Journey; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/17/fact-check-have-bjp-mlas-in-punjab-joined-congress-heres-the-truth/",
        headline:
          "Fact Check: Have BJP MLAs In Punjab Joined Congress? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/18/fact-check-have-trains-been-cancelled-till-march-31-2021-in-india-heres-the-truth/",
        headline:
          "Fact Check: Have Trains Been Cancelled Till March 31, 2021 In India? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/18/fact-check-no-rahul-gandhi-did-not-congratulate-kerala-mahila-congress-president-who-tonsured-her-head/",
        headline:
          "Fact Check: No, Rahul Gandhi Did NOT Congratulate Kerala Mahila Congress President Who Tonsured Her Head",
      },
      {
        url:
          "https://factly.in/rahul-gandhi-did-not-tweet-congratulating-kerala-mahila-congress-president-who-tonsured-her-head/",
        headline:
          " Rahul Gandhi did not tweet congratulating Kerala Mahila Congress president who tonsured her head",
      },
      {
        url:
          "https://factly.in/telugu-the-supreme-court-did-not-order-a-re-election-if-nota-received-the-highest-number-of-votes/",
        headline:
          " NOTA\u0c15\u0c3f \u0c05\u0c02\u0c26\u0c30\u0c3f\u0c15\u0c02\u0c1f\u0c47 \u0c0e\u0c15\u0c4d\u0c15\u0c41\u0c35 \u0c35\u0c4b\u0c1f\u0c4d\u0c32\u0c41 \u0c35\u0c38\u0c4d\u0c24\u0c47 \u0c06 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c41 \u0c30\u0c26\u0c4d\u0c26\u0c41 \u0c1a\u0c47\u0c2f\u0c3e\u0c32\u0c28\u0c3f \u0c38\u0c41\u0c2a\u0c4d\u0c30\u0c40\u0c02 \u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41 \u0c06\u0c26\u0c47\u0c36\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-edited-article-shared-as-bjp-seeking-details-of-mp-bandi-sanjay/",
        headline:
          " \u2018\u0c2c\u0c02\u0c21\u0c3f \u0c38\u0c02\u0c1c\u0c2f\u0c4d \u0c06\u0c38\u0c4d\u0c24\u0c41\u0c32\u0c2a\u0c48 \u0c2c\u0c3f\u0c1c\u0c46\u0c2a\u0c3f \u0c05\u0c27\u0c3f\u0c37\u0c4d\u0c1f\u0c3e\u0c28\u0c02 \u0c06\u0c30\u0c3e\u2019, \u0c05\u0c28\u0c3f \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c2d \u0c2a\u0c4d\u0c30\u0c1a\u0c41\u0c30\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c12\u0c15 \u0c0e\u0c21\u0c3f\u0c1f\u0c46\u0c21\u0c4d \u0c2b\u0c4b\u0c1f\u0c4b",
      },
      {
        url:
          "https://factly.in/telugu-this-survey-showing-rahul-gandhi-leading-modi-on-most-suitable-pm-candidate-is-limited-to-kerala-state/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c27\u0c3e\u0c28\u0c3f \u0c2a\u0c26\u0c35\u0c3f\u0c15\u0c3f \u0c2e\u0c4b\u0c26\u0c40 \u0c15\u0c02\u0c1f\u0c47 \u0c30\u0c3e\u0c39\u0c41\u0c32\u0c4d \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c05\u0c30\u0c4d\u0c39\u0c41\u0c21\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c38\u0c30\u0c4d\u0c35\u0c47 \u0c15\u0c47\u0c30\u0c33 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c2a\u0c30\u0c3f\u0c2e\u0c3f\u0c24\u0c02",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-this-image-is-not-related-to-tamil-nadu-election-old-photo-from-thailand-viral-with-wrong-claim/",
        headline:
          "Fact Check: \u0925\u093e\u0908\u0932\u0948\u0902\u0921 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0924\u092e\u093f\u0932\u0928\u093e\u0921\u0941 \u091a\u0941\u0928\u093e\u0935 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-rahul-gandhi-cited-not-only-islam-but-many-other-sources-from-where-mahatama-gandhi-piced-up-the-idea-of-non-violence/",
        headline:
          "Fact Check: \u0907\u0938\u094d\u0932\u093e\u092e \u0914\u0930 \u092e\u0939\u093e\u0924\u094d\u092e\u093e \u0917\u093e\u0902\u0927\u0940 \u0915\u0947 \u0905\u0939\u093f\u0902\u0938\u093e \u0915\u0947 \u0935\u093f\u091a\u093e\u0930 \u092a\u0930 \u092c\u094b\u0932\u0924\u0947 \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0902\u0927\u0940 \u0915\u093e \u0915\u094d\u0932\u093f\u092a\u094d\u0921 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-central-government-has-not-started-pradhan-mantri-berojgar-bhatta-yojna-yet-viral-post-is-fake/",
        headline:
          "Fact Check: \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u092c\u0947\u0930\u094b\u091c\u0917\u093e\u0930\u0940 \u092d\u0924\u094d\u0924\u093e \u091c\u0948\u0938\u0940 \u0915\u094b\u0908 \u092f\u094b\u091c\u0928\u093e \u092e\u094c\u091c\u0942\u0926 \u0928\u0939\u0940\u0902, \u0930\u091c\u093f\u0938\u094d\u091f\u094d\u0930\u0947\u0936\u0928 \u0915\u0940 \u0905\u092a\u0940\u0932 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u0932\u093f\u0902\u0915 \u092b\u0930\u094d\u091c\u0940",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-old-image-of-flood-relief-alcohol-packages-in-thailand-viral-with-fake-claims/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact check: This image is not related to Tamil Nadu election; Old photo from Thailand viral with fake claim",
      },
    ],
    "5": [
      {
        url:
          "https://factly.in/telugu-the-fountain-in-this-video-works-for-all-sounds-not-just-for-om/",
        headline:
          " \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f\u0c26\u0c3f \u2018\u0c13\u0c02\u2019 \u0c05\u0c28\u0c47 \u0c36\u0c2c\u0c4d\u0c26\u0c02\u0c24\u0c4b \u0c38\u0c39\u0c1c\u0c38\u0c3f\u0c26\u0c4d\u0c27\u0c02\u0c17\u0c3e \u0c1c\u0c30\u0c3f\u0c17\u0c47 \u0c35\u0c3f\u0c02\u0c24 \u0c15\u0c3e\u0c26\u0c41; \u0c35\u0c3f\u0c35\u0c3f\u0c27 \u0c36\u0c2c\u0c4d\u0c26\u0c3e\u0c32 \u0c26\u0c4d\u0c35\u0c3e\u0c30\u0c3e \u0c2a\u0c28\u0c3f \u0c1a\u0c47\u0c38\u0c47 \u0c12\u0c15 \u0c2b\u0c4c\u0c02\u0c1f\u0c46\u0c28\u0c4d",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-the-budget-of-rs-100-crore-for-religious-tourism-in-rajasthan-is-being-made-viral-by-calling-it-the-budget-of-dargah/?itm_source=homepage&itm_medium=dktp_s2&itm_campaign=editorpick",
        headline:
          "Fact Check : \u0930\u093e\u091c\u0938\u094d\u200d\u0925\u093e\u0928 \u0915\u0947 \u0927\u093e\u0930\u094d\u092e\u093f\u0915 \u092a\u0930\u094d\u092f\u091f\u0928 \u0938\u0930\u094d\u0915\u093f\u091f \u0915\u0947 100 \u0915\u0930\u094b\u0921\u093c \u0915\u0947 \u092c\u091c\u091f \u0915\u094b \u0926\u0930\u0917\u093e\u0939 \u0915\u093e \u092c\u091c\u091f \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-bjp-workers-attack-coffee-house-kolkata-old-photos-no-vote-bjp-posters-west-bengal-elections-2021-fact-check-12408",
        headline:
          "2016 BJP Workers Clash Photos Shared As Attack At Coffee House Kolkata",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-rajasthan-government-100-crores-one-dargah-ashok-gehlot-hindu-muslim-communal-angle-fact-check-12434",
        headline:
          "Ad Shared With False Claim That Rajasthan Govt Allotted 100 Cr To One Dargah",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/lockdown-in-uttar-pradesh-yogi-adityanath-old-video-fake-news-coronavirus-12406",
        headline: "         ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bangladesh-cricketer-shakib-al-hasan-mosque-viral-fake-photo-ukrain-railway-station-12394",
        headline: "  --          ?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-bjp-workers-attack-coffee-house-kolkata-old-photos-no-vote-bjp-posters-west-bengal-elections-2021-fact-check-12411",
        headline:
          "\u09e8\u09e6\u09e7\u09ec \u09b8\u09be\u09b2\u09c7 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u0995\u09b0\u09cd\u09ae\u09c0\u09a6\u09c7\u09b0 \u09b9\u09be\u09a4\u09be\u09b9\u09be\u09a4\u09bf\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09ab\u09bf \u09b9\u09be\u0989\u09b8\u09c7 \u09a4\u09be\u09a3\u09cd\u09a1\u09ac \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-graphic-of-abp-ananda-about-observation-by-bjp-central-leaders-about-west-bengal-assembly-election-2021-outcome-viral-12370",
        headline:
          "\u09ac\u09bf\u099c\u09c7\u09aa\u09bf\u09b0 \u0995\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0\u09c0\u09df \u09a6\u09b2\u09c7\u09b0 \u09b0\u09bf\u09aa\u09cb\u09b0\u09cd\u099f \u09b8\u0982\u0995\u09cd\u09b0\u09be\u09a8\u09cd\u09a4 \u098f\u09ac\u09bf\u09aa\u09bf \u0986\u09a8\u09a8\u09cd\u09a6\u09c7\u09b0 \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-rajasthan-cm-ashok-gehlot-did-not-give-rs-100-cr-to-dargah-675807",
        headline:
          "Fact Check: Rajasthan CM Ashok Gehlot did not give Rs. 100 cr to dargah",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/18/fact-check-is-this-the-worlds-most-intelligent-robot-ever-heres-the-truth/",
        headline:
          "Fact Check: Is This The World\u2019s Most Intelligent Robot Ever? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/19/fact-check-have-fake-evms-been-seized-from-a-bjp-leaders-house-ahead-of-upcoming-polls-heres-the-truth/",
        headline:
          "Fact Check: Have Fake EVMs Been Seized From A BJP Leader\u2019s House Ahead Of Upcoming Polls? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/21/fact-check-reports-of-odisha-govt-selling-jagannath-temple-land-are-misleading/",
        headline:
          "Fact Check: Reports Of Odisha Govt Selling Jagannath Temple Land Are Misleading",
      },
      {
        url:
          "https://factly.in/telugu-central-government-is-not-depositing-rs-5000-in-jan-dhan-accounts/",
        headline:
          " \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u2018\u0c1c\u0c28\u0c4d\u200c\u0c27\u0c28\u0c4d\u2019 \u0c0e\u0c15\u0c4c\u0c02\u0c1f\u0c4d\u0c32\u0c32\u0c4b 5,000 \u0c30\u0c42\u0c2a\u0c3e\u0c2f\u0c32\u0c41 \u0c1c\u0c2e\u0c1a\u0c47\u0c2f\u0c1f\u0c4d\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/digitally-created-military-robot-is-portrayed-as-the-worlds-most-intelligent-robot/",
        headline:
          " Digitally created military robot is portrayed as the world\u2019s most intelligent robot",
      },
      {
        url:
          "https://factly.in/telugu-modi-government-has-just-sold-stake-in-various-psus-not-sold-them-outright/",
        headline:
          " \u0c2e\u0c4b\u0c26\u0c40 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c35\u0c3f\u0c35\u0c3f\u0c27 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35 \u0c30\u0c02\u0c17 \u0c38\u0c02\u0c38\u0c4d\u0c25\u0c32\u0c4d\u0c32\u0c4b \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c35\u0c3e\u0c1f\u0c3e\u0c32\u0c41 \u0c35\u0c3f\u0c15\u0c4d\u0c30\u0c2f\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c2a\u0c42\u0c30\u0c4d\u0c24\u0c3f\u0c17\u0c3e \u0c05\u0c2e\u0c4d\u0c2e\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-video-of-a-murder-in-nagpur-goes-viral-claiming-rss-workers-killed-dalit-ias-officer/",
        headline:
          "Fact Check: Video of a murder in Nagpur goes viral claiming RSS workers killed IAS officer",
      },
    ],
    "6": [
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/bhainsa-violence-old-images-communal-narrative-675526",
        headline: "Bhainsa violence: Old images, communal narrative",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-yemeni-boy-passed-off-as-up-muslim-teenager-675567",
        headline: "Fact check: Yemeni boy passed off as UP Muslim teenager",
      },
      {
        url:
          "https://factly.in/these-photos-are-not-of-the-muslim-boy-who-was-allegedly-beaten-for-drinking-water-inside-a-temple-in-ghaziabad/",
        headline:
          " These photos are not of the Muslim boy who was allegedly beaten for drinking water inside a temple in Ghaziabad",
      },
      {
        url:
          "https://factly.in/video-of-birds-flying-around-the-ujjain-mahakaleshwar-temple-has-no-connection-with-maha-shivratri/",
        headline:
          " Video of birds flying around the Ujjain Mahakaleshwar temple has no connection with Maha Shivratri",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-video-of-madrasa-teacher-beating-the-child-is-from-bangladesh-not-from-india/?itm_source=homepage&itm_medium=dktp_s1&itm_campaign=editorpick",
        headline:
          "Fact Check: \u092e\u0926\u0930\u0938\u093e \u0936\u093f\u0915\u094d\u0937\u0915 \u0926\u094d\u0935\u093e\u0930\u093e \u092c\u091a\u094d\u091a\u0947 \u0915\u094b \u092a\u0940\u091f\u0928\u0947 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u093e\u0902\u0917\u094d\u0932\u093e\u0926\u0947\u0936 \u0915\u093e \u0939\u0948, \u092d\u093e\u0930\u0924 \u0915\u093e \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://www.vishvasnews.com/world/fact-check-these-trees-emanating-from-the-chairs-have-no-relation-with-the-world-war-this-is-an-art-installation/?itm_source=homepage&itm_medium=dktp_s3&itm_campaign=editorpick",
        headline:
          "Fact Check: \u0915\u0941\u0930\u094d\u0938\u093f\u092f\u094b\u0902 \u0915\u0947 \u092c\u0940\u091a \u0938\u0947 \u0928\u093f\u0915\u0932\u0924\u0947 \u0907\u0928 \u092a\u0947\u0921\u093c\u094b\u0902 \u0915\u093e \u0935\u093f\u0936\u094d\u0935\u092f\u0941\u0926\u094d\u0927 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948 \u0915\u094b\u0908 \u0938\u0902\u092c\u0902\u0927, \u092f\u0939 \u090f\u0915 \u0906\u0930\u094d\u091f \u0907\u0902\u0938\u094d\u091f\u093e\u0932\u0947\u0936\u0928 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-assaulted-child-photos-yemen-ghaziabad-muslim-minor-boy-beaten-temple-water-communal-incident-viral-video-12352",
        headline:
          "No, These Are Not Photos Of The Minor Boy Assaulted In Ghaziabad",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/2016-image-viral-as-ghaziabad-minor-distributing-water-during-lockdown-12380",
        headline:
          "2016 Image Viral As Ghaziabad Minor Distributing Water During Lockdown",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/video-of-minor-boy-thrashed-in-bangladesh-madrasa-peddled-as-india-12396",
        headline:
          "Video Of Minor Boy Thrashed In Bangladesh Madrasa Peddled As India",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/mamata-banerjee-wheelchair-image-nandigram-incident-west-bengal-assembly-election-2021-12359",
        headline: " :          ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/west-bengal-assembly-election-2021-mahua-moitra-trinamool-congress-yogi-adityanath-false-claims-12379",
        headline: "  :           ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/ghaziabad-muslim-boy-assault-news-drinking-water-temple-viral-photos-fake-news-12368",
        headline: "            ?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-fictional-story-on-release-of-jacksonville-us-juvenile-prisoner-cristian-fernandez-who-killed-his-2-year-old-stepbrother-12378",
        headline:
          "\u0986\u09ae\u09c7\u09b0\u09bf\u0995\u09be\u09df \u09b8\u09ce\u09ad\u09be\u0987 \u0996\u09c1\u09a8\u09c7 \u09a6\u09cb\u09b7\u09c0 \u0996\u09cd\u09b0\u09bf\u09b8\u09cd\u099f\u09bf\u09df\u09be\u09a8 \u09ab\u09be\u09b0\u09cd\u09a8\u09be\u09a8\u09cd\u09a1\u09c7\u099c \u0993 \u09ad\u09c1\u09df\u09cb \u0997\u09b2\u09cd\u09aa \u0995\u09be\u09b9\u09bf\u09a8\u09c0",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-assaulted-child-photos-yemen-ghaziabad-muslim-minor-boy-beaten-temple-water-communal-incident-viral-video-12375",
        headline:
          "\u09a8\u09be, \u098f\u0997\u09c1\u09b2\u09bf \u0997\u09be\u099c\u09bf\u09df\u09be\u09ac\u09be\u09a6\u09c7 \u09a8\u09bf\u09b0\u09cd\u09ae\u09ae\u09ad\u09be\u09ac\u09c7 \u09a8\u09bf\u0997\u09c3\u09b9\u09c0\u09a4 \u09ac\u09be\u09b2\u0995\u09c7\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/video-of-minor-boy-thrashed-in-bangladesh-madrasa-peddled-as-india-12416",
        headline:
          "\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6\u09c7\u09b0 \u09ae\u09be\u09a6\u09cd\u09b0\u09be\u09b8\u09be\u09df \u09a8\u09be\u09ac\u09be\u09b2\u0995\u0995\u09c7 \u09ae\u09be\u09b0\u09a7\u09b0 \u0995\u09b0\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u099b\u09a1\u09bc\u09be\u09b2 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-men-urinating-on-shivling-at-temple-viral-video-fake-news-12403",
        headline:
          "\u09b6\u09bf\u09ac\u09b2\u09bf\u0999\u09cd\u0997\u09c7\u09b0 \u0989\u09aa\u09b0 \u09aa\u09cd\u09b0\u09b8\u09cd\u09b0\u09be\u09ac \u0995\u09b0\u099b\u09c7 \u098f\u0995 \u0995\u09bf\u09b6\u09cb\u09b0, \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09aa\u09c1\u09b0\u09be\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-image-claims-uttar-pradesh-ghaziabads-muslim-boy-beaten-for-entering-temple-to-drink-water-served-drinking-water-to-rail-passengers-during-lockdown-12358",
        headline:
          "\u09b2\u0995\u09a1\u09be\u0989\u09a8\u09c7 \u09b0\u09c7\u09b2\u09af\u09be\u09a4\u09cd\u09b0\u09c0\u09a6\u09c7\u09b0 \u099c\u09b2\u09aa\u09be\u09a8 \u0995\u09b0\u09bf\u09df\u09c7\u099b\u09c7 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7\u09b0 \u09a8\u09bf\u0997\u09c3\u09b9\u09c0\u09a4 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u09ac\u09be\u09b2\u0995?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-fake-news-gajendra-chauhan-shares-narendra-modis-old-picture-from-gujarat-village-12436",
        headline: "      ?   ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/ghaziabad-incident-unrelated-images-ludicrous-narrative-675653",
        headline: "Ghaziabad incident: Unrelated images, ludicrous narrative",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/sorry-boy-video-is-from-bangladesh-not-india-675632",
        headline: "Sorry, boy video is from Bangladesh, not India",
      },
      {
        url:
          "https://digiteye.in/video-boy-being-beaten-mercilessly-in-madrasa-goes-viral-with-wrong-claim-fact-check/",
        headline:
          "Video of boy being beaten mercilessly in madrasa goes viral with wrong claim; Fact Check",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/16/fact-check-no-these-pictures-are-not-of-the-boy-beaten-up-for-drinking-water-in-ghaziabad-temple/",
        headline:
          "Fact Check: No, These Pictures Are NOT Of The Boy Beaten Up For Drinking Water In Ghaziabad Temple",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/19/fact-check-this-video-of-man-thrashing-a-child-is-not-from-india-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Man Thrashing A Child Is NOT From India; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/03/21/fact-check-old-image-of-children-distributing-water-falsely-linked-to-boy-thrashed-for-drinking-water-in-temple/",
        headline:
          "Fact Check: Old Image Of Children Distributing Water Falsely Linked To Boy Thrashed For Drinking Water In Temple",
      },
      {
        url:
          "https://factly.in/telugu-not-all-photos-in-this-post-are-related-to-the-recent-riots-in-bhainsa/",
        headline:
          " \u0c08 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c05\u0c28\u0c4d\u0c28\u0c40 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c24\u0c3e\u0c1c\u0c3e\u0c17\u0c3e \u0c2d\u0c48\u0c02\u0c38\u0c3e\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c05\u0c32\u0c4d\u0c32\u0c30\u0c4d\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c35\u0c3f \u0c15\u0c3e\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/molenbeek-municipality-belgium-does-not-have-a-100-per-cent-muslim-population/",
        headline:
          " Molenbeek municipality (Belgium) does not have a 100 percent Muslim population",
      },
      {
        url:
          "https://factly.in/no-drinking-of-hot-coconut-water-does-not-kill-cancer-cells/",
        headline: " No, drinking hot coconut water does not kill cancer cells",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-image-is-of-an-art-installation-not-of-any-book-donation-festival-in-toronto/",
        headline:
          "Fact Check: \u0906\u0930\u094d\u091f \u0907\u0902\u0938\u094d\u091f\u0949\u0932\u0947\u0936\u0928 \u0915\u094b \u091f\u094b\u0930\u0902\u091f\u094b \u0915\u093e \u0935\u093e\u0930\u094d\u0937\u093f\u0915 \u092a\u0941\u0938\u094d\u0924\u0915 \u0921\u094b\u0928\u0947\u0936\u0928 \u092b\u0947\u0938\u094d\u091f\u093f\u0935\u0932 \u092c\u0924\u093e \u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-no-these-images-are-not-related-with-the-incident-of-muslim-boy-beaten-for-drinking-water-in-ghaziabad-temple/",
        headline:
          "Fact Check: \u0917\u093e\u091c\u093f\u092f\u093e\u092c\u093e\u0926 \u0915\u0947 \u092e\u0902\u0926\u093f\u0930 \u092e\u0947\u0902 \u0939\u0941\u0908 \u092a\u093f\u091f\u093e\u0908 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u092f\u092e\u0928 \u092e\u0947\u0902 \u0939\u0941\u0908 \u0918\u091f\u0928\u093e \u0938\u0947 \u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0939\u0948",
      },
    ],
  },
  numpy_seed: 299,
  number_of_articles: 132,
}
