export const data = {
  mdsDat: {
    x: [
      0.4080525516504082,
      0.03360537418032426,
      -0.14993223165985298,
      -0.1581167076008621,
      -0.13360898657001774,
    ],
    y: [
      0.12355341768427419,
      -0.3682741006926432,
      0.21789513915509828,
      0.07290193096559792,
      -0.046076387112327255,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      18.498630537957286,
      22.80321384194145,
      10.440692146245176,
      19.991206137732014,
      28.266257336124074,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      29.0,
      20.0,
      41.0,
      8.0,
      9.0,
      12.0,
      12.0,
      14.0,
      11.0,
      10.0,
      3.0000749327895075,
      4.000099910386011,
      1.0000249775965027,
      2.0000499551930053,
      4.000099910386011,
      1.0000249775965027,
      2.0000499551930053,
      3.0000749327895075,
      3.0000749327895075,
      1.0000249775965027,
      8.000199820772021,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      3.0000749327895075,
      7.000174843175519,
      5.000124887982513,
      5.000124887982513,
      4.000099910386011,
      4.000099910386011,
      3.0001336765542237,
      3.0001336765542237,
      3.0001336765542237,
      3.0001336765542237,
      3.0001336765542237,
      6.000267353108447,
      3.0001336765542237,
      3.0001336765542237,
      6.000267353108447,
      4.000178235405631,
      9.00040102966267,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      10.00044558851408,
      10.00044558851408,
      12.000534706216895,
      5.00022279425704,
      5.00022279425704,
      5.00022279425704,
      5.00022279425704,
      1.0001084055876956,
      2.0002168111753913,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      3.0003252167630867,
      3.0003252167630867,
      3.0003252167630867,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      3.0003252167630867,
      3.0003252167630867,
      3.0003252167630867,
      1.99364357099574,
      0.99682178549787,
      1.99364357099574,
      0.99682178549787,
      1.99364357099574,
      0.99682178549787,
      1.99364357099574,
      0.99682178549787,
      2.99046535649361,
      1.99364357099574,
      4.98410892748935,
      4.98410892748935,
      4.98410892748935,
      3.98728714199148,
      3.98728714199148,
      3.98728714199148,
      3.98728714199148,
      3.98728714199148,
      2.99046535649361,
      2.99046535649361,
      2.99046535649361,
      2.99046535649361,
      5.98093071298722,
      7.97457428398296,
      9.9682178549787,
      8.97139606948083,
      4.98410892748935,
      3.98728714199148,
      5.010836527767447,
      6.013003833320937,
      3.0065019166604685,
      3.0065019166604685,
      3.0065019166604685,
      5.010836527767447,
      3.0065019166604685,
      3.0065019166604685,
      3.0065019166604685,
      3.0065019166604685,
      6.013003833320937,
      5.010836527767447,
      5.010836527767447,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      27.05851724994422,
      4.008669222213959,
      8.017338444427917,
      8.017338444427917,
      17.036844194409323,
      10.021673055534894,
      7.015171138874427,
      8.017338444427917,
    ],
    Term: [
      "protest",
      "bengal",
      "farmer",
      "communal",
      "ambani",
      "west",
      "west_bengal",
      "covid",
      "bjp",
      "singh",
      "navami",
      "bjp_mp",
      "maharashtra",
      "ram_navami",
      "ramesh",
      "leap",
      "art",
      "accidentally",
      "year_temple",
      "barack",
      "communal",
      "temple",
      "ayodhya",
      "communal_claim",
      "mp",
      "ramesh_bidhuri",
      "bidhuri",
      "glass",
      "bjp",
      "woman",
      "year",
      "shoot",
      "farmer",
      "throw",
      "rohingya",
      "gurudwara",
      "refugee",
      "grandson",
      "mukesh",
      "public_meeting",
      "house",
      "mukesh_ambani",
      "remove",
      "ambani",
      "mamata_banerjee",
      "carpet",
      "banerjee",
      "graphic",
      "mamata",
      "party",
      "rape",
      "rule",
      "public",
      "west",
      "west_bengal",
      "bengal",
      "visit",
      "shah",
      "amit",
      "amit_shah",
      "anniversary",
      "associated",
      "grain",
      "national",
      "swift",
      "mahasabha",
      "win",
      "sell",
      "part",
      "ghazipur",
      "narendra",
      "journalist",
      "narendra_modi",
      "us",
      "praise",
      "surat_singh",
      "farmers",
      "faint",
      "nurse",
      "say",
      "pakistani",
      "border",
      "jio",
      "world",
      "celebration",
      "modi",
      "covid",
      "love",
      "cnn",
      "due",
      "victim",
      "look",
      "irrelevant",
      "elderly",
      "red",
      "restriction",
      "late",
      "rickshaw",
      "auto",
      "race",
      "shared_one",
      "auto_rickshaw",
      "pfizer_covid",
      "accident",
      "pfizer",
      "death",
      "covid_restriction",
      "thrill",
      "family",
      "vaccine",
      "covid",
      "protest",
      "farmer",
      "link",
      "man",
      "activist",
      "airport",
      "eating_fish",
      "alive",
      "ongoing_farmer",
      "dadi",
      "dubai_airport",
      "christmas_tree",
      "eat",
      "biryani",
      "bilkis",
      "farmers_protest",
      "movement",
      "shaheen",
      "bapu",
      "bilkis_dadi",
      "activist_surat",
      "shaheen_bagh",
      "bapu_surat",
      "support",
      "farmer",
      "singh_linked",
      "singh",
      "surat",
      "protest",
      "link",
      "ongoing",
      "bengal",
    ],
    Total: [
      29.0,
      20.0,
      41.0,
      8.0,
      9.0,
      12.0,
      12.0,
      14.0,
      11.0,
      10.0,
      3.0000749327895075,
      4.000099910386011,
      1.0000249775965027,
      2.0000499551930053,
      4.000099910386011,
      1.0000249775965027,
      2.0000499551930053,
      3.0000749327895075,
      3.0000749327895075,
      1.0000249775965027,
      8.000199820772021,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      4.000099910386011,
      3.0000749327895075,
      11.00035307858115,
      6.993768458978253,
      7.004459499089492,
      6.000316721561402,
      41.03012163539876,
      3.0001336765542237,
      3.0001336765542237,
      3.0001336765542237,
      3.0001336765542237,
      3.0001336765542237,
      6.000267353108447,
      3.0001336765542237,
      3.0001336765542237,
      6.000267353108447,
      4.000178235405631,
      9.00040102966267,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      4.000178235405631,
      12.00478019962106,
      12.00478019962106,
      20.01787315064481,
      7.004557405364019,
      8.006724710917508,
      8.006724710917508,
      8.006724710917508,
      1.0001084055876956,
      2.0002168111753913,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      1.0001084055876956,
      3.0003252167630867,
      3.0003252167630867,
      3.0003252167630867,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      2.0002168111753913,
      5.000414334465902,
      10.987790594160199,
      14.975077736151679,
      1.99364357099574,
      0.99682178549787,
      1.99364357099574,
      0.99682178549787,
      1.99364357099574,
      0.99682178549787,
      1.99364357099574,
      0.99682178549787,
      2.99046535649361,
      1.99364357099574,
      4.98410892748935,
      4.98410892748935,
      4.98410892748935,
      3.98728714199148,
      3.98728714199148,
      3.98728714199148,
      3.98728714199148,
      3.98728714199148,
      2.99046535649361,
      2.99046535649361,
      2.99046535649361,
      2.99046535649361,
      7.981147524162612,
      14.975077736151679,
      29.005278860563415,
      41.03012163539876,
      18.00591565957847,
      4.98945444754497,
      5.010836527767447,
      6.013003833320937,
      3.0065019166604685,
      3.0065019166604685,
      3.0065019166604685,
      5.010836527767447,
      3.0065019166604685,
      3.0065019166604685,
      3.0065019166604685,
      3.0065019166604685,
      6.013003833320937,
      5.010836527767447,
      5.010836527767447,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      4.008669222213959,
      41.03012163539876,
      4.008669222213959,
      10.017555255603309,
      10.017555255603309,
      29.005278860563415,
      18.00591565957847,
      10.005636495368037,
      20.01787315064481,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.6875,
      1.2355,
      1.3519,
      1.3504,
      1.282,
      -0.6405,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.4783,
      1.2956,
      1.2956,
      0.9666,
      1.1412,
      1.0075,
      1.0075,
      1.0075,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      2.2595,
      1.7487,
      0.9614,
      0.6518,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.6099,
      1.3214,
      0.9797,
      0.5418,
      0.0896,
      0.3254,
      1.3857,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      1.2635,
      0.8472,
      1.2635,
      1.0408,
      1.0408,
      0.7314,
      0.6776,
      0.9084,
      0.3485,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.2097,
      -3.922,
      -5.3083,
      -4.6151,
      -3.922,
      -5.3083,
      -4.6151,
      -4.2097,
      -4.2097,
      -5.3083,
      -3.2288,
      -3.922,
      -3.922,
      -3.922,
      -3.922,
      -3.922,
      -3.922,
      -4.2097,
      -3.3624,
      -3.6988,
      -3.6988,
      -3.922,
      -3.922,
      -4.4188,
      -4.4188,
      -4.4188,
      -4.4188,
      -4.4188,
      -3.7257,
      -4.4188,
      -4.4188,
      -3.7257,
      -4.1312,
      -3.3202,
      -4.1312,
      -4.1312,
      -4.1312,
      -4.1312,
      -4.1312,
      -4.1312,
      -4.1312,
      -4.1312,
      -4.1312,
      -3.2149,
      -3.2149,
      -3.0325,
      -3.908,
      -3.908,
      -3.908,
      -3.908,
      -4.7362,
      -4.0431,
      -4.7362,
      -4.7362,
      -4.7362,
      -4.7362,
      -4.7362,
      -4.7362,
      -4.7362,
      -4.7362,
      -3.6376,
      -3.6376,
      -3.6376,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -4.0431,
      -3.6376,
      -3.6376,
      -3.6376,
      -4.6959,
      -5.3891,
      -4.6959,
      -5.3891,
      -4.6959,
      -5.3891,
      -4.6959,
      -5.3891,
      -4.2905,
      -4.6959,
      -3.7796,
      -3.7796,
      -3.7796,
      -4.0028,
      -4.0028,
      -4.0028,
      -4.0028,
      -4.0028,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -3.5973,
      -3.3096,
      -3.0865,
      -3.1918,
      -3.7796,
      -4.0028,
      -4.1207,
      -3.9383,
      -4.6315,
      -4.6315,
      -4.6315,
      -4.1207,
      -4.6315,
      -4.6315,
      -4.6315,
      -4.6315,
      -3.9383,
      -4.1207,
      -4.1207,
      -4.3438,
      -4.3438,
      -4.3438,
      -4.3438,
      -4.3438,
      -4.3438,
      -4.3438,
      -2.4343,
      -4.3438,
      -3.6507,
      -3.6507,
      -2.8969,
      -3.4275,
      -3.7842,
      -3.6507,
    ],
  },
  "token.table": {
    Topic: [
      4,
      1,
      5,
      5,
      5,
      5,
      2,
      2,
      5,
      2,
      5,
      3,
      1,
      3,
      4,
      4,
      1,
      2,
      5,
      5,
      1,
      2,
      5,
      1,
      5,
      5,
      5,
      1,
      2,
      1,
      3,
      2,
      2,
      3,
      5,
      4,
      1,
      1,
      2,
      3,
      4,
      4,
      5,
      4,
      5,
      4,
      5,
      5,
      4,
      3,
      4,
      1,
      3,
      4,
      5,
      3,
      5,
      3,
      1,
      3,
      2,
      2,
      2,
      2,
      4,
      3,
      3,
      4,
      1,
      2,
      4,
      5,
      4,
      4,
      1,
      3,
      2,
      2,
      4,
      5,
      2,
      3,
      4,
      5,
      1,
      2,
      2,
      3,
      3,
      3,
      1,
      3,
      4,
      5,
      5,
      3,
      3,
      2,
      4,
      4,
      3,
      3,
      4,
      5,
      2,
      2,
      4,
      1,
      1,
      1,
      2,
      4,
      2,
      2,
      4,
      4,
      2,
      2,
      3,
      3,
      2,
      5,
      5,
      5,
      4,
      1,
      3,
      3,
      5,
      5,
      5,
      3,
      5,
      3,
      3,
      1,
      4,
      2,
      3,
      3,
      4,
      4,
      2,
      5,
      2,
      5,
      2,
      5,
      3,
      1,
      4,
      3,
      1,
      5,
      1,
    ],
    Freq: [
      1.0031883477552035,
      0.9999750230273622,
      0.9978373815015923,
      0.9978373815015921,
      0.9978373815015922,
      0.9978373815015922,
      0.9999554431339949,
      0.6244750732071865,
      0.3746850439243119,
      0.6244750732071865,
      0.3746850439243119,
      0.9998916061628019,
      0.9999750230273621,
      0.9998916061628019,
      1.0031883477552035,
      1.0031883477552035,
      0.9999750230273621,
      0.999955443133995,
      0.9978373815015921,
      0.9978373815015921,
      0.9999750230273621,
      0.5994642842270913,
      0.3996428561513942,
      0.9999750230273621,
      0.9978373815015922,
      0.9978373815015921,
      0.9978373815015922,
      0.6363432109856311,
      0.36362469199178915,
      0.9999750230273621,
      0.9998916061628019,
      0.999955443133995,
      0.399966855989269,
      0.5999502839839035,
      0.9978373815015922,
      1.0031883477552035,
      0.9999750230273621,
      0.9999750230273621,
      0.2671104665015199,
      0.20033284987613995,
      0.5342209330030399,
      1.0031883477552035,
      0.9978373815015923,
      1.0031883477552035,
      0.9978373815015922,
      1.0031883477552035,
      0.9978373815015922,
      0.9978373815015922,
      1.0031883477552035,
      0.9998916061628019,
      1.0031883477552035,
      0.09748935271371455,
      0.024372338178428637,
      0.21935104360585775,
      0.6580531308175732,
      0.9998916061628019,
      0.9978373815015923,
      0.9998916061628019,
      0.9999750230273622,
      0.9998916061628019,
      0.9999554431339949,
      0.999955443133995,
      0.9999554431339949,
      0.9999554431339949,
      1.0031883477552035,
      0.9998916061628019,
      0.999891606162802,
      1.0031883477552035,
      0.9999750230273621,
      0.1666119100365836,
      0.27768651672763933,
      0.5553730334552787,
      1.0031883477552035,
      1.0031883477552035,
      0.9999750230273621,
      0.9998916061628019,
      0.999955443133995,
      0.999955443133995,
      0.8016908545919634,
      0.20042271364799086,
      0.36404042884890103,
      0.2730303216366758,
      0.36404042884890103,
      0.9978373815015923,
      0.9999750230273621,
      0.9999554431339949,
      0.9999554431339949,
      0.999891606162802,
      0.999891606162802,
      0.9998916061628019,
      0.9999750230273622,
      0.9998916061628019,
      0.2998310003955077,
      0.6996056675895179,
      0.9978373815015922,
      0.9998916061628019,
      0.9998916061628019,
      0.999955443133995,
      1.0031883477552035,
      1.0031883477552035,
      0.9998916061628019,
      0.0689529657554601,
      0.3447648287773005,
      0.5861002089214109,
      0.999955443133995,
      0.9999554431339949,
      1.0031883477552035,
      0.9999750230273621,
      0.9999750230273621,
      0.9999750230273621,
      0.999955443133995,
      1.0031883477552035,
      0.9999554431339949,
      0.999955443133995,
      1.0031883477552035,
      1.0031883477552035,
      0.9999554431339949,
      0.999955443133995,
      0.9998916061628019,
      0.9998916061628019,
      0.6244750732071865,
      0.3746850439243119,
      0.9978373815015921,
      0.9978373815015921,
      1.0031883477552035,
      0.6666314772396081,
      0.33331573861980407,
      0.19964951018176835,
      0.7985980407270734,
      0.9978373815015921,
      0.9978373815015921,
      0.19964951018176835,
      0.7985980407270734,
      0.9998916061628019,
      0.9998916061628019,
      0.9999750230273621,
      1.0031883477552035,
      0.9999554431339949,
      0.9998916061628019,
      0.2505905314925051,
      0.7517715944775153,
      1.0031883477552035,
      0.713820975493905,
      0.285528390197562,
      0.8330015072092414,
      0.1666003014418483,
      0.8330015072092414,
      0.1666003014418483,
      0.9998916061628019,
      0.7149221523885665,
      0.28596886095542656,
      0.9998916061628019,
      0.7138309530735311,
      0.28553238122941244,
      0.9999750230273622,
    ],
    Term: [
      "accident",
      "accidentally",
      "activist",
      "activist_surat",
      "airport",
      "alive",
      "ambani",
      "amit",
      "amit",
      "amit_shah",
      "amit_shah",
      "anniversary",
      "art",
      "associated",
      "auto",
      "auto_rickshaw",
      "ayodhya",
      "banerjee",
      "bapu",
      "bapu_surat",
      "barack",
      "bengal",
      "bengal",
      "bidhuri",
      "bilkis",
      "bilkis_dadi",
      "biryani",
      "bjp",
      "bjp",
      "bjp_mp",
      "border",
      "carpet",
      "celebration",
      "celebration",
      "christmas_tree",
      "cnn",
      "communal",
      "communal_claim",
      "covid",
      "covid",
      "covid",
      "covid_restriction",
      "dadi",
      "death",
      "dubai_airport",
      "due",
      "eat",
      "eating_fish",
      "elderly",
      "faint",
      "family",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "farmers",
      "farmers_protest",
      "ghazipur",
      "glass",
      "grain",
      "grandson",
      "graphic",
      "gurudwara",
      "house",
      "irrelevant",
      "jio",
      "journalist",
      "late",
      "leap",
      "link",
      "link",
      "link",
      "look",
      "love",
      "maharashtra",
      "mahasabha",
      "mamata",
      "mamata_banerjee",
      "man",
      "man",
      "modi",
      "modi",
      "modi",
      "movement",
      "mp",
      "mukesh",
      "mukesh_ambani",
      "narendra",
      "narendra_modi",
      "national",
      "navami",
      "nurse",
      "ongoing",
      "ongoing",
      "ongoing_farmer",
      "pakistani",
      "part",
      "party",
      "pfizer",
      "pfizer_covid",
      "praise",
      "protest",
      "protest",
      "protest",
      "public",
      "public_meeting",
      "race",
      "ram_navami",
      "ramesh",
      "ramesh_bidhuri",
      "rape",
      "red",
      "refugee",
      "remove",
      "restriction",
      "rickshaw",
      "rohingya",
      "rule",
      "say",
      "sell",
      "shah",
      "shah",
      "shaheen",
      "shaheen_bagh",
      "shared_one",
      "shoot",
      "shoot",
      "singh",
      "singh",
      "singh_linked",
      "support",
      "surat",
      "surat",
      "surat_singh",
      "swift",
      "temple",
      "thrill",
      "throw",
      "us",
      "vaccine",
      "vaccine",
      "victim",
      "visit",
      "visit",
      "west",
      "west",
      "west_bengal",
      "west_bengal",
      "win",
      "woman",
      "woman",
      "world",
      "year",
      "year",
      "year_temple",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/hindi/old-image-of-kashi-vishwanath-corridor-shared-as-temple-discovered-during-road-widening-in-ayodhya/",
        headline:
          "\u092c\u0928\u093e\u0930\u0938 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0936\u0947\u092f\u0930 \u0915\u0930\u0924\u0947 \u0939\u0941\u090f \u0905\u092f\u094b\u0927\u094d\u092f\u093e \u092e\u0947\u0902 5000 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u092e\u0902\u0926\u093f\u0930 \u2018\u0928\u093f\u0915\u0932\u0928\u0947\u2019 \u0915\u093e \u095a\u0932\u0924 \u0926\u093e\u0935\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/video-of-woman-accidently-shot-in-foot-by-a-delhi-cop-shared-with-false-communal-angle/",
        headline:
          "Video of woman accidentally shot by cop resolving parking dispute viral with false communal spin",
      },
      {
        url:
          "https://www.altnews.in/old-image-of-kashi-vishwanath-corridor-shared-as-temple-discovered-during-road-widening-in-ayodhya/",
        headline:
          "Photo from Varanasi falsely viral as 5000-yo temple \u2018discovered\u2019 in Ayodhya",
      },
      {
        url:
          "https://www.altnews.in/bjp-delhi-falsely-accused-aap-govt-of-buying-oximeters-at-extremely-high-price/",
        headline:
          "BJP Delhi falsely accuses AAP of purchasing oximeters at inflated price",
      },
      {
        url:
          "https://factly.in/telugu-each-day-of-the-week-appears-exactly-4-times-in-february-of-every-year-except-leap-year/",
        headline:
          " \u0c32\u0c40\u0c2a\u0c4d \u0c07\u0c2f\u0c30\u0c4d \u0c15\u0c3e\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c24\u0c40 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c02 \u0c2b\u0c3f\u0c2c\u0c4d\u0c30\u0c35\u0c30\u0c3f \u0c28\u0c46\u0c32\u0c32\u0c4b \u0c12\u0c15 \u0c35\u0c3e\u0c30\u0c02\u0c32\u0c4b\u0c28\u0c3f \u0c05\u0c28\u0c4d\u0c28\u0c3f \u0c30\u0c4b\u0c1c\u0c41\u0c32\u0c41 \u0c28\u0c3e\u0c32\u0c41\u0c17\u0c41 \u0c38\u0c3e\u0c30\u0c4d\u0c32\u0c41 \u0c35\u0c38\u0c4d\u0c24\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-obama-serving-thanksgiving-meal-in-2016-shared-as-him-working-as-a-hotel-server-now/",
        headline:
          " \u0c2c\u0c30\u0c3e\u0c15\u0c4d \u0c12\u0c2c\u0c3e\u0c2e\u0c3e 2016 \u0c25\u0c3e\u0c02\u0c15\u0c4d\u0c38\u0c4d \u0c17\u0c3f\u0c35\u0c3f\u0c02\u0c17\u0c4d \u0c35\u0c3f\u0c02\u0c26\u0c41 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c24\u0c28\u0c41 \u0c39\u0c4b\u0c1f\u0c32\u0c4d \u0c38\u0c30\u0c4d\u0c35\u0c30\u0c4d \u0c17\u0c3e \u0c2a\u0c28\u0c3f\u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/incident-of-accidental-firing-by-a-police-official-is-falsely-shared-with-a-communal-narrative/",
        headline:
          " Incident of accidental firing by a police officer is falsely shared with a communal narrative",
      },
      {
        url:
          "https://factly.in/telugu-video-of-a-tiger-attack-in-maharastra-is-shared-as-that-from-telangana/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c2a\u0c41\u0c32\u0c3f \u0c26\u0c3e\u0c21\u0c3f \u0c2e\u0c39\u0c3e\u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f, \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c3e\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/21/fact-check-is-this-glass-bridge-in-bihar-here-is-the-truth/",
        headline:
          "Fact Check: Is This Glass Bridge In Bihar? Here Is The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-picture-of-camel-hair-art-from-bikaner-falsely-attributed-to-pakistan-heres-the-truth/",
        headline:
          "Fact Check: Picture Of Camel Hair Art From Bikaner Falsely Attributed To Pakistan; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/viral-video-delhi-police-constable-shoots-woman-in-her-foot-11244",
        headline:
          "Video Of Woman Accidentally Shot By Delhi Cop Viral With Fake Claim",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-video-claims-delhi-police-shot-muslim-lady-is-fake-news-11247",
        headline:
          "\u0915\u094d\u092f\u093e \u0926\u093f\u0932\u094d\u0932\u0940 \u092a\u0941\u0932\u093f\u0938 \u0915\u0947 \u0915\u093e\u0902\u0938\u094d\u091f\u0947\u092c\u0932 \u0928\u0947 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u092e\u0939\u093f\u0932\u093e \u0915\u094b \u0917\u094b\u0932\u0940 \u092e\u093e\u0930 \u0926\u0940?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/paschim-bangal-me-hinduon-ki-sthithi-rohingya-basti-me-11260",
        headline:
          "\u092d\u093e\u091c\u092a\u093e \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u092a\u0930 \u0939\u092e\u0932\u0947 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/fake-news-ayodhya-5000-year-old-temple-road-widening-11288",
        headline:
          "\u0915\u094d\u092f\u093e \u0905\u092f\u094b\u0927\u094d\u092f\u093e \u092e\u0947\u0902 \u0930\u094b\u0921 \u0928\u093f\u0930\u094d\u092e\u093e\u0923 \u0915\u0947 \u0926\u094c\u0930\u093e\u0928 \u092e\u093f\u0932\u093e 5,000 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u092e\u0902\u0926\u093f\u0930?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/ujjain-stone-pelting-gulbarga-karnataka-ram-navmi-viral-video-fake-news-11309",
        headline:
          "\u0917\u0941\u0932\u092c\u0930\u094d\u0917\u093e \u0938\u0947 \u092a\u0941\u0930\u093e\u0928\u093e \u0930\u093e\u092e \u0928\u0935\u092e\u0940 \u0930\u0948\u0932\u0940 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u092e\u094d\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/aam-aadmi-party-claims-bjp-mp-ramesh-bidhuri-abused-farmers-protest-delhi-borders-11306",
        headline:
          "\u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09b8\u09be\u0982\u09b8\u09a6 \u09b0\u09ae\u09c7\u09b6 \u09ac\u09bf\u09a7\u09c1\u09b0\u09bf \u0995\u09bf \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u0996\u09be\u09b0\u09be\u09aa \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u0995\u09b0\u09c7\u099b\u09c7\u09a8?",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/21/fact-check-is-this-glass-bridge-in-bihar-here-is-the-truth/",
        headline:
          "Fact Check: Is This Glass Bridge In Bihar? Here Is The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-picture-of-camel-hair-art-from-bikaner-falsely-attributed-to-pakistan-heres-the-truth/",
        headline:
          "Fact Check: Picture Of Camel Hair Art From Bikaner Falsely Attributed To Pakistan; Here\u2019s The Truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/fake-news-ayodhya-5000-year-old-temple-road-widening-11288",
        headline:
          "\u0915\u094d\u092f\u093e \u0905\u092f\u094b\u0927\u094d\u092f\u093e \u092e\u0947\u0902 \u0930\u094b\u0921 \u0928\u093f\u0930\u094d\u092e\u093e\u0923 \u0915\u0947 \u0926\u094c\u0930\u093e\u0928 \u092e\u093f\u0932\u093e 5,000 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u092e\u0902\u0926\u093f\u0930?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/ujjain-stone-pelting-gulbarga-karnataka-ram-navmi-viral-video-fake-news-11309",
        headline:
          "\u0917\u0941\u0932\u092c\u0930\u094d\u0917\u093e \u0938\u0947 \u092a\u0941\u0930\u093e\u0928\u093e \u0930\u093e\u092e \u0928\u0935\u092e\u0940 \u0930\u0948\u0932\u0940 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u092e\u094d\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/ujjain-madhya-pradesh-stone-pelting-hindu-muslim-begambagh-mahakali-temple-viral-video-11307",
        headline:
          "Old Ram Navami Rally Clip From Gulbarga Shared With Communal Twist",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/aam-aadmi-party-claims-bjp-mp-ramesh-bidhri-abused-farmers-farmers-protest-delhi-borders-11276",
        headline:
          "Did BJP MP Ramesh Bidhuri Use A Derogatory Word For Farmers? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/viral-video-delhi-police-constable-shoots-woman-in-her-foot-11244",
        headline:
          "Video Of Woman Accidentally Shot By Delhi Cop Viral With Fake Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-machete-attack-on-woman-viral-with-false-communal-spin-11300",
        headline:
          "Video Of Machete Attack On Woman Viral With False Communal Spin",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/aam-aadmi-party-claims-bjp-mp-ramesh-bidhuri-abused-farmers-protest-delhi-borders-11306",
        headline:
          "\u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09b8\u09be\u0982\u09b8\u09a6 \u09b0\u09ae\u09c7\u09b6 \u09ac\u09bf\u09a7\u09c1\u09b0\u09bf \u0995\u09bf \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u0996\u09be\u09b0\u09be\u09aa \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u0995\u09b0\u09c7\u099b\u09c7\u09a8?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/cpim-mask-made-out-of-an-underwear-edited-image-viral-11284",
        headline:
          "Edited Viral Image: \u0985\u09a8\u09cd\u09a4\u09b0\u09cd\u09ac\u09be\u09b8 \u09a5\u09c7\u0995\u09c7 \u09a4\u09c8\u09b0\u09bf CPIM \u09ae\u09be\u09b8\u09cd\u0995",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/fake-news-bjp-mp-ramesh-bidhuri-aam-aadmi-party-kisan-andolan-11279",
        headline:
          "\u0915\u094d\u092f\u093e \u092d\u093e\u091c\u092a\u093e \u0938\u093e\u0902\u0938\u0926 \u0930\u092e\u0947\u0936 \u092c\u093f\u0927\u0942\u095c\u0940 \u0928\u0947 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0915\u094b \u0905\u092a\u0936\u092c\u094d\u0926 \u0915\u0939\u0947?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/bilkis-bano-in-jail-shaheen-bagh-dadi-kisan-andolan-11262",
        headline: ",          ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/paschim-bangal-me-hinduon-ki-sthithi-rohingya-basti-me-11260",
        headline:
          "\u092d\u093e\u091c\u092a\u093e \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u092a\u0930 \u0939\u092e\u0932\u0947 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-chinas-glass-bridge-passed-off-as-one-being-built-in-bihar-672085",
        headline:
          "Fact Check: Photo of China's glass bridge passed off as one being built in Bihar",
      },
    ],
    "2": [
      {
        url:
          "https://www.altnews.in/video-from-2019-falsely-viral-as-ambanis-throw-baby-shower-without-covid-protocols/",
        headline:
          "Video from 2019 falsely viral as Ambanis throw baby shower without COVID protocols",
      },
      {
        url:
          "https://factly.in/old-photos-of-bjp-public-meetings-falsely-shared-as-recent-pictures-of-amit-shah-roadshow-in-west-bengal/",
        headline:
          " Old photos of BJP public meetings falsely shared as recent pictures of Amit Shah\u2019s roadshow in West Bengal",
      },
      {
        url:
          "https://factly.in/telugu-old-photos-of-bjp-public-meetings-falsely-shared-as-recent-pictures-of-amit-shahs-roadshow-in-west-bengal/",
        headline:
          " \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2a\u0c3e\u0c24 \u0c2c\u0c39\u0c3f\u0c30\u0c02\u0c17 \u0c38\u0c2d\u0c32 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c05\u0c2e\u0c3f\u0c24\u0c4d \u0c37\u0c3e \u0c2a\u0c36\u0c4d\u0c1a\u0c3f\u0c2e \u0c2c\u0c46\u0c02\u0c17\u0c3e\u0c32\u0c4d \u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2c\u0c39\u0c3f\u0c30\u0c02\u0c17 \u0c38\u0c2d \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/2006-video-shared-as-the-mamata-banerjees-reaction-to-amit-shah-recent-roadshows-in-west-bengal/",
        headline:
          " 2006 video shared as the Mamata Banerjee\u2019s reaction to Amit Shah\u2019s recent roadshows in West Bengal",
      },
      {
        url:
          "https://factly.in/west-bengal-do-not-have-such-whopping-amount-of-public-debt/",
        headline:
          " West Bengal\u2019s public debt is around 4.9 lakh crores, not 400 lakh crores",
      },
      {
        url:
          "https://factly.in/2019-video-shared-as-celebrations-at-mukesh-ambanis-abode-welcoming-his-grandson-amid-covid-19/",
        headline:
          " 2019 video shared as celebrations at Mukesh Ambani\u2019s abode welcoming his grandson amid COVID-19",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/24/fact-check-old-video-of-an-agitated-mamata-banerjee-shared-as-recent/",
        headline:
          "Fact Check: Old Video Of An Agitated Mamata Banerjee Shared As Recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/24/fact-check-did-mukesh-ambani-throw-a-party-for-his-newborn-grandson-heres-the-truth/",
        headline:
          "Fact Check: Did Mukesh Ambani Throw A Party For His Newborn Grandson? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-old-video-of-attack-on-bjp-wb-chief-falsely-linked-to-rohingya-refugees/",
        headline:
          "Fact Check: Old video Of Attack On BJP WB Chief Falsely Linked To Rohingya Refugees",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/mamata-banerjee-rape-statistics-west-bengal-ncrb-2018-tmc-11248",
        headline:
          "False: Graphic Stating West Bengal Has Highest Rapes In India",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/covid-19-rules-violated-at-mukesh-ambanis-party-a-factcheck-11258",
        headline:
          "COVID-19 Rules Violated At Mukesh Ambani's Party? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/carpet-removed-narendra-modis-gurudwara-visit-viral-image-fact-check-11266",
        headline:
          "Rakab Ganj Gurudwara Removes Carpet Ahead Of PM Modi's Visit? Fact-Check",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/pm-narendra-modi-rakab-ganj-gurudwara-visit-delhi-kisan-andolan-11281",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u094d\u0930\u0927\u093e\u0928\u092e\u0902\u0924\u094d\u0930\u0940 \u092e\u094b\u0926\u0940 \u0915\u0947 \u0926\u094c\u0930\u0947 \u0938\u0947 \u092a\u0939\u0932\u0947 \u0930\u0915\u093e\u092c\u0917\u0902\u091c \u0917\u0941\u0930\u0942\u0926\u094d\u0935\u093e\u0930\u0947 \u0928\u0947 \u0939\u091f\u093e\u092f\u093e \u0915\u093e\u0932\u0940\u0928?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/antila-mukesh-ambani-covid-19-rules-amitabh-bacchan-rekha-nita-ambani-11304",
        headline:
          "\u0905\u0902\u092c\u093e\u0928\u0940 \u0915\u0947 \u0918\u0930 \u092a\u0930 \u0915\u094b\u0935\u093f\u0921-19 \u0928\u093f\u092f\u092e\u094b\u0902 \u0915\u093e \u0909\u0932\u094d\u0932\u0902\u0918\u0928? \u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/mamata-banerjee-rape-statistics-west-bengal-ncrb-2018-tmc-fact-check-11257",
        headline:
          "Fact Check: \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995\u09c7\u09b0 \u09a6\u09be\u09ac\u09bf \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u09b8\u09ac\u099a\u09c7\u09df\u09c7 \u09ac\u09c7\u09b6\u09bf \u09a7\u09b0\u09cd\u09b7\u09a3 \u09b9\u09df",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-picture-of-dubai-passed-off-as-varanasi-after-pm-modi-s-beautification-drive-1752861-2020-12-24",
        headline:
          "Fact Check: Picture of Dubai passed off as Varanasi after PM Modi\u2019s beautification drive",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-supreme-court-of-nepal-has-not-criminalised-loudspeakers-in-mosques-1752499-2020-12-23",
        headline:
          "Fact Check: Supreme Court of Nepal has not criminalised loudspeakers in mosques",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-social-media-gives-rohingya-angle-to-attack-on-bjp-leaders-in-bengal-1752143-2020-12-22",
        headline:
          "Fact Check: Social media gives \u2018Rohingya\u2019 angle to attack on BJP leaders in Bengal",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/24/fact-check-old-video-of-an-agitated-mamata-banerjee-shared-as-recent/",
        headline:
          "Fact Check: Old Video Of An Agitated Mamata Banerjee Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/24/fact-check-did-mukesh-ambani-throw-a-party-for-his-newborn-grandson-heres-the-truth/",
        headline:
          "Fact Check: Did Mukesh Ambani Throw A Party For His Newborn Grandson? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-old-video-of-attack-on-bjp-wb-chief-falsely-linked-to-rohingya-refugees/",
        headline:
          "Fact Check: Old video Of Attack On BJP WB Chief Falsely Linked To Rohingya Refugees",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/antila-mukesh-ambani-covid-19-rules-amitabh-bacchan-rekha-nita-ambani-11304",
        headline:
          "\u0905\u0902\u092c\u093e\u0928\u0940 \u0915\u0947 \u0918\u0930 \u092a\u0930 \u0915\u094b\u0935\u093f\u0921-19 \u0928\u093f\u092f\u092e\u094b\u0902 \u0915\u093e \u0909\u0932\u094d\u0932\u0902\u0918\u0928? \u095e\u0948\u0915\u094d\u091f \u091a\u0947\u0915",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/carpet-removed-narendra-modis-gurudwara-visit-viral-image-fact-check-11266",
        headline:
          "Rakab Ganj Gurudwara Removes Carpet Ahead Of PM Modi's Visit? Fact-Check",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/mamata-banerjee-rape-statistics-west-bengal-ncrb-2018-tmc-11248",
        headline:
          "False: Graphic Stating West Bengal Has Highest Rapes In India",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/covid-19-rules-violated-at-mukesh-ambanis-party-a-factcheck-11258",
        headline:
          "COVID-19 Rules Violated At Mukesh Ambani's Party? A FactCheck",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/mamata-banerjee-rape-statistics-west-bengal-ncrb-2018-tmc-fact-check-11257",
        headline:
          "Fact Check: \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995\u09c7\u09b0 \u09a6\u09be\u09ac\u09bf \u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u09b8\u09ac\u099a\u09c7\u09df\u09c7 \u09ac\u09c7\u09b6\u09bf \u09a7\u09b0\u09cd\u09b7\u09a3 \u09b9\u09df",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/viral-video-claims-delhi-police-shot-muslim-lady-is-fake-news-11247",
        headline: "           ?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-demolition-of-iconic-bell-tower-at-visva-bharati-university-in-bengal-not-related-to-amit-shahs-visit-672056",
        headline:
          "Fact Check: Demolition of iconic bell tower at Visva Bharati University in Bengal not related to Amit Shah's visit",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-of-mamata-banerjee-falsely-linked-to-amit-shahs-visit-to-west-bengal-672102",
        headline:
          "FACT CHECK: Old video of Mamata Banerjee falsely linked to Amit Shah's visit to West Bengal",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-the-gurudwara-committee-did-not-remove-carpet-for-pm-672149",
        headline:
          "Fact Check: No, the Gurudwara committee did not remove carpet for PM",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-west-bengal-president-dilip-ghosh-was-heckled-by-anti-bimal-gurung-protestors-not-bangladeshi-refugees-672117",
        headline:
          "FACT CHECK: West Bengal president Dilip Ghosh was heckled by anti-Bimal Gurung protestors, not Bangladeshi refugees",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-ganesh-chaturthi-celebration-at-mukesh-ambanis-house-shared-with-false-claims-672125",
        headline:
          "Fact check: Video of Ganesh Chaturthi celebration at Mukesh Ambani's house shared with false claims",
      },
    ],
    "3": [
      {
        url:
          "https://factly.in/telugu-poster-of-mp-bandi-sanjay-attending-a-christian-event-is-old-he-didnt-attend-the-event/",
        headline:
          " \u2018\u0c38\u0c30\u0c4d\u0c35\u0c38\u0c24\u0c4d\u0c2f \u0c2e\u0c39\u0c3e\u0c38\u0c2d\u0c32\u0c15\u0c41 \u0c2c\u0c02\u0c21\u0c3f \u0c38\u0c02\u0c1c\u0c2f\u0c4d\u2019 \u0c05\u0c28\u0c3f \u0c09\u0c28\u0c4d\u0c28 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c30\u0c4d \u0c2a\u0c3e\u0c24\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/central-government-hasnt-issued-any-guidelines-saying-ration-card-would-be-cancelled-if-not-used-for-three-months/",
        headline:
          " Central government hasn\u2019t issued any guidelines saying ration card would be cancelled if not used for three months",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-people-fighting-for-cake-is-not-from-ap-cm-y-s-jagan-birthday-celebrations/",
        headline:
          " \u0c15\u0c47\u0c15\u0c4d \u0c15\u0c4b\u0c38\u0c02 \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c41 \u0c15\u0c4a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c02\u0c1f\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c35\u0c48.\u0c0e\u0c38\u0c4d.\u0c1c\u0c17\u0c28\u0c4d \u0c2a\u0c41\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c30\u0c4b\u0c1c\u0c41 \u0c35\u0c47\u0c21\u0c41\u0c15\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/pakistani-journalist-praising-prime-minister-narendra-modi-factcheck-11286",
        headline:
          "Viral Video: \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u09aa\u09cd\u09b0\u09b6\u0982\u09b8\u09be \u0995\u09b0\u09b2\u09c7\u09a8 \u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09bf \u09b8\u09be\u0982\u09ac\u09be\u09a6\u09bf\u0995?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-bapu-surat-singh-on-hunger-strike-for-farmers-viral-11299",
        headline:
          "Surat Singh Khalsa \u0985\u09a8\u09b6\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u099c\u09cb\u09a1\u09bc\u09be \u09b9\u09b2 Farmers Protest-\u098f\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/a-viral-message-on-social-media-claims-that-tiffany-dover-the-nurse-in-tennessee-united-states-who-fainted-after-receiving-her-first-dose-of-the-pfizer-biontech-1752156-2020-12-22",
        headline:
          "Fact Check: US nurse who fainted after Covid-19 vaccine shot is not dead",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-fifa-world-cup-win-celebrations-in-paris-viral-as-march-against-covid-curbs-1752515-2020-12-23",
        headline:
          "Fact Check: FIFA World Cup win celebrations in Paris viral as march against Covid curbs",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/reliance-jio-sacks-food-grain-farmers-protest-fact-check-1752962-2020-12-25",
        headline:
          "Fact Check: Reliance Jio is not selling food grains, sacks with Jio logos freely available\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/us-nurse-who-fainted-covid-19-vaccine-shot-not-dead-1753059-2020-12-25",
        headline:
          "US nurse who fainted after Covid-19 vaccine shot is not dead",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-this-picture-of-narendra-modi-is-not-with-the-staff-of-prime-minister-s-office-1753412-2020-12-26",
        headline:
          "Fact Check: No, this picture of Narendra Modi is not with the staff of Prime Minister\u2019s Office",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/pm-narendra-modi-rakab-ganj-gurudwara-visit-delhi-kisan-andolan-11281",
        headline: "           ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/ajit-anjum-farmers-protest-gajipur-border-11280",
        headline:
          "\u0915\u094d\u092f\u093e \u0917\u093e\u095b\u0940\u092a\u0941\u0930 \u092c\u0949\u0930\u094d\u0921\u0930 \u092a\u0930 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0928\u0947 \u092a\u0924\u094d\u0930\u0915\u093e\u0930 \u0905\u091c\u0940\u0924 \u0905\u0902\u091c\u0941\u092e \u0915\u0947 \u0938\u093e\u0925 \u092e\u093e\u0930\u092a\u0940\u091f \u0915\u0940?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/photo-of-bapu-surat-singh-on-hunger-strike-for-farmers-viral-11299",
        headline:
          "Surat Singh Khalsa \u0985\u09a8\u09b6\u09a8\u09c7\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u099c\u09cb\u09a1\u09bc\u09be \u09b9\u09b2 Farmers Protest-\u098f\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/pakistani-journalist-praising-prime-minister-narendra-modi-factcheck-11286",
        headline:
          "Viral Video: \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u09aa\u09cd\u09b0\u09b6\u0982\u09b8\u09be \u0995\u09b0\u09b2\u09c7\u09a8 \u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09bf \u09b8\u09be\u0982\u09ac\u09be\u09a6\u09bf\u0995?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-beware-shopclues-is-not-giving-away-swift-dzire-car-as-part-of-its-10th-anniversary-celebrations-672042",
        headline:
          "Fact check: Beware! Shopclues is not giving away SWIFT DZIRE car as part of its 10th-anniversary celebrations",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/national-flag-at-wagah-border-does-not-hold-world-record-672008",
        headline:
          "Fact check: National flag at Wagah border does not hold world record",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/hindi/image-from-2018-shared-as-farmer-deceased-during-ongoing-protests/",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u092e\u0947\u0902 \u090f\u0915 \u0914\u0930 \u0915\u093f\u0938\u093e\u0928 \u0915\u0940 \u092e\u094c\u0924 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 2018 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://factly.in/old-image-of-a-little-girl-catering-rotis-is-falsely-linked-to-the-ongoing-farmers-protest/",
        headline:
          " Old image of a little girl serving Rotis is falsely linked to the ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/pfizers-covid-19-vaccine-vaporizer-cartridge-in-this-photo-is-a-fake-one/",
        headline:
          " Pfizer\u2019s COVID-19 Vaccine (vaporizer cartridge) in this photo is a fake one",
      },
      {
        url:
          "https://factly.in/telugu-pfizers-covid-19-vaccine-vaporizer-cartridge-in-this-photo-is-a-fake-one/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c2b\u0c48\u0c1c\u0c30\u0c4d \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d-19 \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d (vaporizer cartridge) \u0c2a\u0c47\u0c30\u0c41\u0c24\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2b\u0c47\u0c15\u0c4d \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-pictures-are-compared-as-farmers-protest-against-british-vs-modi/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c2c\u0c4d\u0c30\u0c3f\u0c1f\u0c3f\u0c37\u0c4d \u0c2e\u0c30\u0c3f\u0c2f\u0c41 \u0c2e\u0c4b\u0c26\u0c40 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c3e\u0c32 \u0c39\u0c2f\u0c3e\u0c02\u0c32\u0c4b \u0c30\u0c48\u0c24\u0c41\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/the-auto-rickshaw-race-in-this-video-shows-red-bull-tuk-it-event-in-sri-lanka/",
        headline:
          " The auto-rickshaw race in this video shows \u2018Red Bull Tuk It\u2019 event in Sri Lanka",
      },
      {
        url:
          "https://digiteye.in/viral-message-about-pm-modis-family-is-false/",
        headline: "Viral message about PM Modi\u2019s family is false",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/22/fact-check-dont-fall-for-this-morphed-picture-of-pm-modi-and-mark-zuckerberg/",
        headline:
          "Fact Check: Don\u2019t Fall For This Morphed Picture Of PM Modi And Mark Zuckerberg",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/23/fact-check-this-video-of-thrilling-auto-rickshaw-race-is-not-from-india-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Thrilling Auto-Rickshaw Race Is Not From India; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/24/fact-check-old-pictures-of-late-major-vs-dhoundiyals-cremation-shared-as-recent/",
        headline:
          "Fact Check: Old Pictures Of Late Major VS Dhoundiyal\u2019s Cremation Shared As Recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-were-french-citizens-protesting-against-covid-19-restrictions-in-this-picture-heres-the-truth/",
        headline:
          "Fact Check: Were French Citizens Protesting Against COVID-19 Restrictions In This Picture? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-old-tik-tok-video-of-sikh-family-falsely-linked-with-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Tik Tok Video Of Sikh Family Falsely Linked With Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-old-video-of-accident-due-to-fog-shared-as-recent-one-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Accident Due To Fog Shared As Recent One; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-old-picture-of-elderly-man-falsely-linked-to-deaths-in-recent-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Elderly Man Falsely Linked To Deaths In Recent Farmers\u2019 Protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-picture-of-island-in-dubai-falsely-shared-as-one-from-india/",
        headline:
          "Fact Check: Picture Of Island In Dubai Falsely Shared As One From India",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/viral-image-covid-19-vaccine-pfizer-vaccine-coronavirus-vapourizer-vape-fake-news-fact-check-11268",
        headline: "No, This Is Not What Pfizer's COVID-19 Vaccine Looks Like",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-video-claims-muslim-man-ismail-attacked-on-hindu-woman-asha-love-jihad-hubli-11301",
        headline:
          "\u092e\u0939\u093f\u0932\u093e \u092a\u0930 \u0915\u0941\u0932\u094d\u0939\u093e\u095c\u0940 \u0938\u0947 \u0939\u092e\u0932\u093e \u0915\u0930\u0924\u0947 \u092f\u0941\u0935\u0915 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b '\u0932\u0935 \u091c\u093f\u0939\u093e\u0926' \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-thrilling-race-between-auto-rickshaws-is-not-from-india-1752858-2020-12-24",
        headline:
          "Fact Check: This thrilling race between auto-rickshaws is not from India",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/morphed-cnn-visual-claiming-covid-vaccine-turns-people-into-zombies-1753195-2020-12-25",
        headline:
          "Fact Check: Don't fall for this morphed CNN visual claiming Covid vaccine turns people into zombies",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/22/fact-check-dont-fall-for-this-morphed-picture-of-pm-modi-and-mark-zuckerberg/",
        headline:
          "Fact Check: Don\u2019t Fall For This Morphed Picture Of PM Modi And Mark Zuckerberg",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/23/fact-check-this-video-of-thrilling-auto-rickshaw-race-is-not-from-india-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Thrilling Auto-Rickshaw Race Is Not From India; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/24/fact-check-old-pictures-of-late-major-vs-dhoundiyals-cremation-shared-as-recent/",
        headline:
          "Fact Check: Old Pictures Of Late Major VS Dhoundiyal\u2019s Cremation Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-were-french-citizens-protesting-against-covid-19-restrictions-in-this-picture-heres-the-truth/",
        headline:
          "Fact Check: Were French Citizens Protesting Against COVID-19 Restrictions In This Picture? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-old-tik-tok-video-of-sikh-family-falsely-linked-with-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Tik Tok Video Of Sikh Family Falsely Linked With Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-old-video-of-accident-due-to-fog-shared-as-recent-one-heres-the-truth/",
        headline:
          "Fact Check: Old Video Of Accident Due To Fog Shared As Recent One; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-old-picture-of-elderly-man-falsely-linked-to-deaths-in-recent-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Elderly Man Falsely Linked To Deaths In Recent Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-picture-of-island-in-dubai-falsely-shared-as-one-from-india/",
        headline:
          "Fact Check: Picture Of Island In Dubai Falsely Shared As One From India",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/viral-video-claims-muslim-man-ismail-attacked-on-hindu-woman-asha-love-jihad-hubli-11301",
        headline:
          "\u092e\u0939\u093f\u0932\u093e \u092a\u0930 \u0915\u0941\u0932\u094d\u0939\u093e\u095c\u0940 \u0938\u0947 \u0939\u092e\u0932\u093e \u0915\u0930\u0924\u0947 \u092f\u0941\u0935\u0915 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b '\u0932\u0935 \u091c\u093f\u0939\u093e\u0926' \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/viral-image-covid-19-vaccine-pfizer-vaccine-coronavirus-vapourizer-vape-fake-news-fact-check-11268",
        headline: "No, This Is Not What Pfizer's COVID-19 Vaccine Looks Like",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/viral-image-little-girl-distributing-roti-farmers-protest-fact-check-11242",
        headline:
          "Farmers' Protest: Old Image Of Little Girl Distributing Rotis Revived",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-auto-rickshaw-race-video-is-from-sri-lanka-not-india-672229",
        headline:
          "Fact Check: Viral auto-rickshaw race video is from Sri Lanka, not India",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-french-people-are-not-protesting-against-government-over-covid-19-restrictions-672158",
        headline:
          "Fact check: No, French people are not protesting against government over Covid 19 restrictions",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-4-eyed-animal-was-not-discovered-in-south-sudan-photo-shows-sculpture-of-mythical-creature-672020",
        headline:
          "Fact Check: 4-eyed animal was not discovered in South Sudan, photo shows sculpture of mythical creature",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/road-accident-video-viral-with-fake-claim/",
        headline:
          "\u09ab\u09c7\u0995\u09cd\u099f \u099a\u09c7\u0995\u0983  \u09f0\u09cb\u09a1 \u0985\u0995\u09cd\u09b8\u09bf\u09a1\u09c7\u09a8\u09cd\u099f\u09f0 \u099a\u09bf\u0995\u09be\u09f0 \u09b9\u09cb\u09f1\u09be \u09b6\u09bf\u09b6\u09c1 \u0995\u09a8\u09cd\u09af\u09be\u099f\u09bf\u09f0 \u09ad\u09bf\u09a1\u09bf\u0993\u09f0  \u09ad\u09cd\u09f0\u09ae\u09bf\u09a4 \u09a6\u09be\u09ac\u09c0\u09f0 \u09b8\u09c8\u09a4\u09c7 \u0995\u09f0\u09be \u0997\u09c8\u099b\u09c7 \u09ad\u09be\u0987\u09f0\u09c7\u09b2|",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/hindi/2015-images-of-bapu-surat-singh-shared-as-he-is-on-hunger-strike-for-farmers/",
        headline:
          "\u092e\u093e\u0928\u0935 \u0905\u0927\u093f\u0915\u093e\u0930\u094b\u0902 \u0915\u0947 \u0932\u093f\u090f \u0932\u095c\u0928\u0947 \u0935\u093e\u0932\u0947 \u092c\u093e\u092a\u0942 \u0938\u0942\u0930\u0924 \u0938\u093f\u0902\u0939 \u0915\u0940 2015 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093f\u0938\u093e\u0928 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u094b\u0902 \u0938\u0947 \u091c\u094b\u095c\u0940 \u0917\u092f\u0940",
      },
      {
        url:
          "https://www.altnews.in/image-from-2018-shared-as-farmer-deceased-during-ongoing-protests/",
        headline:
          "Image from 2018 shared as farmer deceased during ongoing protests",
      },
      {
        url:
          "https://factly.in/2019-video-of-protests-over-caa-and-nrc-bills-falsely-linked-to-the-ongoing-farmers-protests/",
        headline:
          " 2019 video of protests over CAA and NRC falsely linked to the ongoing farmers\u2019 protests",
      },
      {
        url:
          "https://factly.in/telugu-old-image-of-a-little-girl-serving-rotis-is-falsely-linked-to-the-ongoing-farmers-protest/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c3f\u0c35\u0c32 \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40\u0c32\u0c4b \u0c1c\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c48\u0c24\u0c41 \u0c09\u0c26\u0c4d\u0c2f\u0c2e\u0c02\u0c32\u0c4b \u0c13 \u0c1a\u0c3f\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c3f \u0c06\u0c39\u0c30\u0c02 \u0c2a\u0c02\u0c1a\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/2019-photo-of-a-labour-rally-in-west-bengal-falsely-linked-to-the-ongoing-farmers-protest/",
        headline:
          " 2019 photo of labourers rally in West Bengal falsely linked to the ongoing farmers\u2019 protest",
      },
      {
        url:
          "https://factly.in/morphed-image-shared-as-amit-shah-eating-fish-biryani-in-west-bengal-while-farmers-are-protesting/",
        headline:
          " Morphed image shared as Amit Shah eating fish biryani in West Bengal amidst farmers\u2019 protests",
      },
      {
        url:
          "https://www.factcrescendo.com/bapu-surat-singh-is-on-hunger-strike-not-for-famers/",
        headline:
          "\u092c\u093e\u092a\u0942 \u0938\u0942\u0930\u0924 \u0938\u093f\u0902\u0939 \u0915\u0940 \u092d\u0942\u0916 \u0939\u0921\u093c\u0924\u093e\u0932 \u0915\u093e \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u0915\u094b\u0908 \u0938\u092e\u094d\u092c\u0928\u094d\u0927 \u0928\u0939\u0940\u0902 \u0939\u0948|",
      },
      {
        url: "https://www.factcrescendo.com/farmers-protest-in-west-bengal/",
        headline:
          "\u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u092c\u0902\u0917\u093e\u0932 \u092e\u0947\u0902 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u0947 \u0938\u092e\u0930\u094d\u0925\u0928 \u092e\u0947\u0902 \u0939\u0941\u0908 \u0930\u0948\u0932\u0940 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948 |",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/22/fact-check-dont-fall-for-altered-picture-of-amit-shah-eating-fish-biryani-on-his-recent-visit-to-bengal/",
        headline:
          "Fact Check: Don\u2019t Fall For Altered Picture Of Amit Shah Eating Fish, Biryani On His Recent Visit To Bengal",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/22/fact-check-old-picture-from-bengal-falsely-shared-as-people-supporting-farmers-heres-the-truth/",
        headline:
          "Fact Check: Old Picture From Bengal Falsely Shared As People Supporting Farmers; Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/23/fact-check-was-dubai-airport-runway-decorated-like-a-christmas-tree-heres-the-truth/",
        headline:
          "Fact Check: Was Dubai Airport Runway Decorated Like A Christmas Tree? Here\u2019s The Truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/12/27/fact-check-old-picture-of-activist-surat-singh-falsely-linked-to-the-ongoing-farmers-agitation/",
        headline:
          "Fact Check: Old Picture Of Activist Surat Singh Falsely Linked To The Ongoing Farmers\u2019 Agitation",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/shaheen-bagh-dadi-bilkis-bano-in-jail-fake-news-fact-check-congress-delhi-police-caa-farmers-protest-86-year-old-11255",
        headline: "No, Shaheen Bagh's Bilkis Dadi Is Not In Jail",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-bapu-surat-singh-on-hunger-strike-for-farmers-viral-11296",
        headline:
          "Activist Surat Singh's 2015 Photo Linked To Farmers' Protest",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/bilkis-bano-in-jail-shaheen-bagh-dadi-kisan-andolan-11262",
        headline:
          "\u0928\u0939\u0940\u0902, \u0936\u093e\u0939\u0940\u0928 \u092c\u093e\u095a \u0915\u0940 \u0926\u093e\u0926\u0940 \u092c\u093f\u0932\u0915\u093f\u0938 \u092c\u093e\u0928\u094b \u091c\u0947\u0932 \u092e\u0947\u0902 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-photo-of-man-shared-as-dead-farmer-in-delhi-kisan-andolan-2020-11293",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u092e\u0943\u0924 \u0915\u093f\u0938\u093e\u0928 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/surat-singh-khalsa-fasting-kisan-andolan-farmers-protest-11298",
        headline:
          "'\u092c\u093e\u092a\u0942 \u0938\u0942\u0930\u0924 \u0938\u093f\u0902\u0939' \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0941\u0908 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmers-protest-kisan-long-march-nashik-maharashtra-viral-image-cpim-11263",
        headline:
          "Farmers' Protest: \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 Kisan Long March-\u098f\u09b0 \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-news-soldier-carrying-donkey-11270",
        headline:
          "Fake News: \u0986\u09b2\u099c\u09c7\u09b0\u09bf\u09df\u09be\u09df \u09ab\u09b0\u09be\u09b8\u09bf \u09b8\u09c7\u09a8\u09be\u09b0 \u0997\u09be\u09a7\u09be \u09aa\u09bf\u09a0\u09c7 \u099b\u09ac\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/bilkis-dadi-shaheen-bagh-caa-protest-delhi-viral-farmers-protest-11292",
        headline:
          "\u09a8\u09be, \u09b6\u09be\u09b9\u09bf\u09a8 \u09ac\u09be\u0997\u09c7\u09b0 Bilkis Dadi \u099c\u09c7\u09b2\u09c7 \u09a8\u09c7\u0987",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-christmas-tree-lighting-is-not-at-dubai-airport-1752165-2020-12-22",
        headline:
          "Fact Check: This Christmas tree lighting is not at Dubai airport",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-shaheen-bagh-dadi-bilkis-bano-is-not-in-jail-for-meeting-farmers-1751731-2020-12-21",
        headline:
          "Fact Check: Shaheen Bagh dadi Bilkis Bano is not in jail for meeting farmers",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-picture-of-platform-ticket-with-adani-railway-on-it-is-morphed-1753643-2020-12-27",
        headline:
          "Fact Check: Picture of platform ticket with \u2018Adani Railway\u2019 on it is morphed",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/22/fact-check-dont-fall-for-altered-picture-of-amit-shah-eating-fish-biryani-on-his-recent-visit-to-bengal/",
        headline:
          "Fact Check: Don\u2019t Fall For Altered Picture Of Amit Shah Eating Fish, Biryani On His Recent Visit To Bengal",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/22/fact-check-old-picture-from-bengal-falsely-shared-as-people-supporting-farmers-heres-the-truth/",
        headline:
          "Fact Check: Old Picture From Bengal Falsely Shared As People Supporting Farmers; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/23/fact-check-was-dubai-airport-runway-decorated-like-a-christmas-tree-heres-the-truth/",
        headline:
          "Fact Check: Was Dubai Airport Runway Decorated Like A Christmas Tree? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/12/27/fact-check-old-picture-of-activist-surat-singh-falsely-linked-to-the-ongoing-farmers-agitation/",
        headline:
          "Fact Check: Old Picture Of Activist Surat Singh Falsely Linked To The Ongoing Farmers\u2019 Agitation",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fake-news-soldier-carrying-donkey-11270",
        headline:
          "Fake News: \u0986\u09b2\u099c\u09c7\u09b0\u09bf\u09df\u09be\u09df \u09ab\u09b0\u09be\u09b8\u09bf \u09b8\u09c7\u09a8\u09be\u09b0 \u0997\u09be\u09a7\u09be \u09aa\u09bf\u09a0\u09c7 \u099b\u09ac\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-photo-of-man-shared-as-dead-farmer-in-delhi-kisan-andolan-2020-11293",
        headline:
          "\u0926\u094b \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u092e\u0943\u0924 \u0915\u093f\u0938\u093e\u0928 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/surat-singh-khalsa-fasting-kisan-andolan-farmers-protest-11298",
        headline:
          "'\u092c\u093e\u092a\u0942 \u0938\u0942\u0930\u0924 \u0938\u093f\u0902\u0939' \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0941\u0908 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/shaheen-bagh-dadi-bilkis-bano-in-jail-fake-news-fact-check-congress-delhi-police-caa-farmers-protest-86-year-old-11255",
        headline: "No, Shaheen Bagh's Bilkis Dadi Is Not In Jail",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/photo-from-2018-viral-as-deceased-farmer-at-delhi-protests-11289",
        headline: "Photo From 2018 Viral As Deceased Farmer At Delhi Protests",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/photo-of-bapu-surat-singh-on-hunger-strike-for-farmers-viral-11296",
        headline:
          "Activist Surat Singh's 2015 Photo Linked To Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/farmers-protest-cpim-rally-west-bengal-viral-image-fact-check-11291",
        headline:
          "2019 Image Shared As Recent Rally In WB To Support Farmers' Protest",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/farmers-protest-kisan-long-march-nashik-maharashtra-viral-image-cpim-11263",
        headline:
          "Farmers' Protest: \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7\u09b0 Kisan Long March-\u098f\u09b0 \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/bilkis-dadi-shaheen-bagh-caa-protest-delhi-viral-farmers-protest-11292",
        headline:
          "\u09a8\u09be, \u09b6\u09be\u09b9\u09bf\u09a8 \u09ac\u09be\u0997\u09c7\u09b0 Bilkis Dadi \u099c\u09c7\u09b2\u09c7 \u09a8\u09c7\u0987",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/adani-airport-ahmedabad-airports-sardar-vallabhbhai-patel-factcheck-11241",
        headline:
          "\u09a8\u09be, \u0986\u09ae\u09a6\u09be\u09ac\u09be\u09a6 \u09ac\u09bf\u09ae\u09be\u09a8\u09ac\u09a8\u09cd\u09a6\u09b0\u09c7\u09b0 \u09a8\u09be\u09ae \u0986\u09a6\u09be\u09a8\u09bf \u098f\u09df\u09be\u09b0\u09aa\u09cb\u09b0\u09cd\u099f \u09b0\u09be\u0996\u09be \u09b9\u09df\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/viral-image-little-girl-distributing-roti-farmers-protest-fact-check-11246",
        headline:
          "\u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad: \u099b\u09cb\u099f\u09cd\u099f \u098f\u0995 \u09ae\u09c7\u09df\u09c7\u09b0 \u09b0\u09c1\u099f\u09bf \u09aa\u09b0\u09bf\u09ac\u09c7\u09b6\u09a8\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/photo-from-2018-viral-as-deceased-farmer-at-delhi-protests-11305",
        headline:
          "Farmers Protest: \u09e8\u09e6\u09e7\u09ee'\u09b0 \u099b\u09ac\u09bf\u0995\u09c7 \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09a4\u09c7 \u0986\u09a8\u09cd\u09a6\u09cb\u09b2\u09a8\u09b0\u09a4 \u09ae\u09c3\u09a4 \u0995\u09c3\u09b7\u0995 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-airport-runway-decorated-with-christmas-lights-is-from-ireland-not-dubai-672115",
        headline:
          "FACT CHECK: Airport runway decorated with Christmas lights is from Ireland, not Dubai",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-from-2019-anti-cab-protest-in-bengal-falsely-linked-to-2020-farmers-protest-672202",
        headline:
          "Fact Check: Photo from 2019 anti-CAB protest in Bengal falsely linked to 2020 farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2018-photo-of-dead-sikh-man-falsely-linked-to-ongoing-farmers-protest-672222",
        headline:
          "Fact Check: 2018 photo of dead Sikh man falsely linked to ongoing farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2015-photo-of-sikh-activist-on-hunger-strike-falsely-linked-to-ongoing-farmers-protest-672201",
        headline:
          "Fact Check: 2015 photo of Sikh activist on hunger strike falsely linked to ongoing farmers' protest",
      },
    ],
  },
  numpy_seed: 20,
  number_of_articles: 155,
}
