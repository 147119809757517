export const data = {
  mdsDat: {
    x: [
      0.3261428439583681,
      -0.197423296911452,
      -0.2660343915878475,
      0.07965982920525837,
      0.057655015335673084,
    ],
    y: [
      0.031081102174149704,
      -0.04826520398720571,
      0.04112414760306332,
      -0.3337839394105603,
      0.30984389362055303,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      17.24178749138626,
      20.667086630765187,
      17.24019218004813,
      22.829119912021362,
      22.02181378577907,
    ],
  },
  tinfo: {
    Term: [
      "shiv",
      "protest",
      "sena",
      "kangana",
      "crash",
      "ranaut",
      "flag",
      "shiv_sena",
      "farmer",
      "police",
      "kasab",
      "salahuddin",
      "animal",
      "global",
      "lathicharge",
      "dancing",
      "ayodhya",
      "inhale",
      "arabia",
      "contest",
      "farmer",
      "police",
      "rajasthan",
      "haryana",
      "unemployment",
      "lucknow",
      "kill",
      "jihad",
      "rally",
      "terrorist",
      "sye",
      "love",
      "protest",
      "hindu",
      "africa",
      "muslim",
      "honour",
      "say",
      "go",
      "shiv_sena",
      "controversy",
      "idbi",
      "row",
      "youth",
      "sabha",
      "manager_maharashtra",
      "airs",
      "fight",
      "fb",
      "shiv",
      "sena",
      "assault",
      "ambedkar",
      "manager",
      "bus",
      "maharashtra",
      "secret",
      "wife",
      "activist",
      "ambedkar_wife",
      "worker",
      "bank",
      "kangana",
      "social_media",
      "ladakh",
      "salem",
      "abu",
      "journalist",
      "helicopter",
      "spin",
      "pilot",
      "mirage",
      "support",
      "abu_salem",
      "tweet",
      "gangster",
      "ranaut",
      "crash",
      "kangana_ranaut",
      "kangana",
      "social",
      "haji_ali",
      "chauhan",
      "also",
      "service",
      "shweta",
      "leg",
      "difference",
      "vaccine",
      "government",
      "sudha",
      "dance",
      "visit",
      "shivraj",
      "upsc",
      "madhya",
      "madhya_pradesh",
      "religion",
      "ali",
      "based_religion",
      "shivraj_singh",
      "leader",
      "base",
      "shoe",
      "pradesh",
      "amitabh",
      "bachchan",
      "show",
      "aap",
      "nadu",
      "tycoon",
      "intercept",
      "mount",
      "zee",
      "shoot",
      "jiog",
      "yogi",
      "flood",
      "flag",
      "pakistan",
      "kailash",
      "soldier",
      "report",
      "convoy",
      "adityanath",
      "yogi_adityanath",
      "mount_kailash",
      "minister",
      "indian",
      "student",
      "year",
    ],
    Freq: [
      13.0,
      13.0,
      11.0,
      16.0,
      11.0,
      11.0,
      10.0,
      8.0,
      8.0,
      7.0,
      1.0002042167777998,
      2.0004084335555996,
      1.0002042167777998,
      1.0002042167777998,
      2.0004084335555996,
      1.0002042167777998,
      1.0002042167777998,
      1.0002042167777998,
      1.0002042167777998,
      1.0002042167777998,
      8.001633734222398,
      7.001429517444598,
      7.001429517444598,
      6.001225300666799,
      3.0006126503333994,
      3.0006126503333994,
      3.0006126503333994,
      2.0004084335555996,
      2.0004084335555996,
      2.0004084335555996,
      2.0004084335555996,
      2.0004084335555996,
      12.002450601333598,
      2.0004084335555996,
      2.0004084335555996,
      4.000816867111199,
      2.0004084335555996,
      3.0006126503333994,
      3.0006126503333994,
      8.999658859475566,
      2.999886286491855,
      2.999886286491855,
      0.9999620954972851,
      0.9999620954972851,
      1.9999241909945702,
      2.999886286491855,
      1.9999241909945702,
      0.9999620954972851,
      2.999886286491855,
      13.99946933696199,
      11.99954514596742,
      4.999810477486426,
      4.999810477486426,
      4.999810477486426,
      4.999810477486426,
      3.9998483819891404,
      3.9998483819891404,
      3.9998483819891404,
      3.9998483819891404,
      3.9998483819891404,
      6.999734668480995,
      4.999810477486426,
      4.999810477486426,
      3.0003350160460722,
      3.0003350160460722,
      6.0006700320921444,
      7.000781704107501,
      3.0003350160460722,
      3.0003350160460722,
      1.0001116720153576,
      2.000223344030715,
      1.0001116720153576,
      1.0001116720153576,
      4.00044668806143,
      2.000223344030715,
      2.000223344030715,
      10.001116720153574,
      10.001116720153574,
      5.000558360076787,
      11.001228392168931,
      3.0003350160460722,
      2.9993673750023317,
      1.9995782500015546,
      0.9997891250007773,
      1.9995782500015546,
      0.9997891250007773,
      0.9997891250007773,
      0.9997891250007773,
      0.9997891250007773,
      1.9995782500015546,
      3.9991565000031093,
      6.998523875005442,
      4.998945625003887,
      3.9991565000031093,
      3.9991565000031093,
      3.9991565000031093,
      3.9991565000031093,
      3.9991565000031093,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      3.9991565000031093,
      3.9991565000031093,
      3.9991565000031093,
      4.998945625003887,
      1.00000695387882,
      1.00000695387882,
      1.00000695387882,
      1.00000695387882,
      3.0000208616364605,
      1.00000695387882,
      1.00000695387882,
      1.00000695387882,
      3.0000208616364605,
      1.00000695387882,
      10.000069538788201,
      7.000048677151741,
      6.000041723272921,
      5.000034769394101,
      4.00002781551528,
      4.00002781551528,
      4.00002781551528,
      3.0000208616364605,
      3.0000208616364605,
      3.0000208616364605,
      9.000062584909381,
      4.00002781551528,
      4.00002781551528,
    ],
    Total: [
      13.0,
      13.0,
      11.0,
      16.0,
      11.0,
      11.0,
      10.0,
      8.0,
      8.0,
      7.0,
      1.0002042167777998,
      2.0004084335555996,
      1.0002042167777998,
      1.0002042167777998,
      2.0004084335555996,
      1.0002042167777998,
      1.0002042167777998,
      1.0002042167777998,
      1.0002042167777998,
      1.0002042167777998,
      8.001633734222398,
      7.001429517444598,
      7.001429517444598,
      6.001225300666799,
      3.0006126503333994,
      3.0006126503333994,
      3.0006126503333994,
      2.0004084335555996,
      2.0004084335555996,
      2.0004084335555996,
      2.0004084335555996,
      2.0004084335555996,
      13.002457555212418,
      2.0004084335555996,
      2.0004084335555996,
      5.0008238209900195,
      2.0004084335555996,
      5.000581699709505,
      7.999993933093371,
      8.999658859475566,
      2.999886286491855,
      2.999886286491855,
      0.9999620954972851,
      0.9999620954972851,
      1.9999241909945702,
      2.999886286491855,
      1.9999241909945702,
      0.9999620954972851,
      2.999886286491855,
      13.99946933696199,
      11.99954514596742,
      4.999810477486426,
      4.999810477486426,
      4.999810477486426,
      4.999810477486426,
      3.9998483819891404,
      3.9998483819891404,
      3.9998483819891404,
      3.9998483819891404,
      3.9998483819891404,
      9.99951713526035,
      5.999817431365246,
      16.001038869655357,
      3.0003350160460722,
      3.0003350160460722,
      6.0006700320921444,
      7.000781704107501,
      3.0003350160460722,
      3.0003350160460722,
      1.0001116720153576,
      2.000223344030715,
      1.0001116720153576,
      1.0001116720153576,
      4.00044668806143,
      2.000223344030715,
      2.000223344030715,
      11.001078815650859,
      11.001123674032394,
      6.000520455574072,
      16.001038869655357,
      4.000297111543357,
      2.9993673750023317,
      1.9995782500015546,
      0.9997891250007773,
      1.9995782500015546,
      0.9997891250007773,
      0.9997891250007773,
      0.9997891250007773,
      0.9997891250007773,
      1.9995782500015546,
      3.9991565000031093,
      6.998523875005442,
      4.998945625003887,
      3.9991565000031093,
      3.9991565000031093,
      3.9991565000031093,
      3.9991565000031093,
      3.9991565000031093,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      2.9993673750023317,
      4.99916345388193,
      4.999268172018467,
      4.999268172018467,
      8.998838865374562,
      1.00000695387882,
      1.00000695387882,
      1.00000695387882,
      1.00000695387882,
      3.0000208616364605,
      1.00000695387882,
      1.00000695387882,
      1.00000695387882,
      3.0000208616364605,
      1.00000695387882,
      10.000069538788201,
      7.000048677151741,
      6.000041723272921,
      5.000034769394101,
      4.00002781551528,
      4.00002781551528,
      4.00002781551528,
      3.0000208616364605,
      3.0000208616364605,
      3.0000208616364605,
      13.000060543416595,
      5.000232032293081,
      5.999779036013343,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.2523,
      -4.5591,
      -5.2523,
      -5.2523,
      -4.5591,
      -5.2523,
      -5.2523,
      -5.2523,
      -5.2523,
      -5.2523,
      -3.1728,
      -3.3064,
      -3.3064,
      -3.4605,
      -4.1537,
      -4.1537,
      -4.1537,
      -4.5591,
      -4.5591,
      -4.5591,
      -4.5591,
      -4.5591,
      -2.7674,
      -4.5591,
      -4.5591,
      -3.866,
      -4.5591,
      -4.1537,
      -4.1537,
      -3.2365,
      -4.3351,
      -4.3351,
      -5.4337,
      -5.4337,
      -4.7406,
      -4.3351,
      -4.7406,
      -5.4337,
      -4.3351,
      -2.7947,
      -2.9488,
      -3.8243,
      -3.8243,
      -3.8243,
      -3.8243,
      -4.0474,
      -4.0474,
      -4.0474,
      -4.0474,
      -4.0474,
      -3.4878,
      -3.8243,
      -3.8243,
      -4.1537,
      -4.1537,
      -3.4605,
      -3.3064,
      -4.1537,
      -4.1537,
      -5.2523,
      -4.5591,
      -5.2523,
      -5.2523,
      -3.866,
      -4.5591,
      -4.5591,
      -2.9497,
      -2.9497,
      -3.6428,
      -2.8544,
      -4.1537,
      -4.4348,
      -4.8402,
      -5.5334,
      -4.8402,
      -5.5334,
      -5.5334,
      -5.5334,
      -5.5334,
      -4.8402,
      -4.1471,
      -3.5875,
      -3.924,
      -4.1471,
      -4.1471,
      -4.1471,
      -4.1471,
      -4.1471,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.4348,
      -4.1471,
      -4.1471,
      -4.1471,
      -3.924,
      -5.4972,
      -5.4972,
      -5.4972,
      -5.4972,
      -4.3986,
      -5.4972,
      -5.4972,
      -5.4972,
      -4.3986,
      -5.4972,
      -3.1946,
      -3.5513,
      -3.7054,
      -3.8877,
      -4.1109,
      -4.1109,
      -4.1109,
      -4.3986,
      -4.3986,
      -4.3986,
      -3.2999,
      -4.1109,
      -4.1109,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.7578,
      1.6778,
      1.7578,
      1.7578,
      1.5347,
      1.7578,
      1.2471,
      0.7772,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.5766,
      1.22,
      1.3943,
      0.4134,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.7579,
      1.6626,
      1.6626,
      1.5756,
      1.3833,
      1.4703,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.2539,
      1.2539,
      1.2539,
      0.8893,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.5131,
      1.1454,
      1.29,
      1.1077,
    ],
  },
  "token.table": {
    Topic: [
      5,
      3,
      3,
      2,
      5,
      1,
      2,
      4,
      4,
      2,
      2,
      3,
      4,
      1,
      1,
      2,
      1,
      3,
      4,
      2,
      5,
      4,
      4,
      2,
      4,
      1,
      2,
      5,
      3,
      5,
      4,
      1,
      4,
      1,
      2,
      2,
      5,
      5,
      3,
      1,
      1,
      4,
      5,
      4,
      4,
      1,
      3,
      1,
      1,
      2,
      2,
      3,
      5,
      1,
      5,
      1,
      5,
      3,
      5,
      2,
      3,
      2,
      3,
      1,
      1,
      3,
      1,
      4,
      4,
      1,
      1,
      4,
      4,
      2,
      2,
      2,
      5,
      3,
      5,
      5,
      1,
      5,
      5,
      5,
      3,
      1,
      4,
      5,
      1,
      5,
      1,
      1,
      2,
      3,
      4,
      5,
      2,
      2,
      1,
      3,
      1,
      2,
      5,
      2,
      2,
      4,
      2,
      2,
      4,
      4,
      4,
      5,
      2,
      4,
      5,
      4,
      2,
      3,
      3,
      5,
      3,
      1,
      5,
      4,
      3,
      1,
      1,
      3,
      5,
      1,
      4,
      4,
      4,
      2,
      1,
      2,
      4,
      2,
      4,
      5,
      5,
      5,
      2,
      5,
    ],
    Freq: [
      0.999993046169536,
      0.9998883404538893,
      0.999888340453889,
      1.0000379059395208,
      0.999993046169536,
      0.9997958249181775,
      1.0000379059395208,
      1.0002109194768674,
      1.0002109194768671,
      1.0000379059395206,
      1.0000379059395208,
      0.20002927740446608,
      0.8001171096178643,
      0.9997958249181775,
      0.9997958249181775,
      1.0000379059395206,
      0.9997958249181775,
      0.20002927740446608,
      0.8001171096178643,
      0.8333586908597418,
      0.16667173817194836,
      1.0002109194768674,
      1.0002109194768674,
      1.0000379059395206,
      1.0002109194768671,
      0.9997958249181775,
      1.0000379059395208,
      0.999993046169536,
      0.9089980529538545,
      0.09089980529538544,
      1.0002109194768671,
      0.9997958249181775,
      1.0002109194768671,
      0.9997958249181775,
      1.0000379059395208,
      1.0000379059395208,
      0.999993046169536,
      0.999993046169536,
      0.999888340453889,
      0.9997958249181775,
      0.3750002843864639,
      0.3750002843864639,
      0.2500001895909759,
      1.0002109194768671,
      1.0002109194768674,
      0.9997958249181775,
      0.9998883404538892,
      0.9997958249181775,
      0.9997958249181775,
      1.0000379059395208,
      0.23076815603903017,
      0.07692271867967672,
      0.6923044681170905,
      0.9997958249181775,
      0.999993046169536,
      0.9997958249181775,
      0.999993046169536,
      0.9998883404538892,
      0.9999930461695359,
      0.3124797108944023,
      0.6874553639676851,
      0.16665221082132445,
      0.8332610541066222,
      0.9997958249181775,
      0.9997958249181775,
      0.9998883404538892,
      0.9997958249181775,
      1.0002109194768674,
      1.0002109194768671,
      0.9997958249181775,
      0.9997958249181775,
      1.0002109194768671,
      1.0002109194768671,
      1.0000379059395208,
      1.0000379059395206,
      1.0000379059395208,
      0.9999930461695359,
      0.999888340453889,
      0.9999930461695359,
      0.9999930461695359,
      0.7998682103558119,
      0.19996705258895298,
      0.999993046169536,
      0.9999930461695359,
      0.999888340453889,
      0.9997958249181775,
      0.8001338697765397,
      0.20003346744413492,
      0.9229024550969941,
      0.07690853792474951,
      0.9997958249181775,
      0.9997958249181775,
      0.09090017595159251,
      0.9090017595159251,
      1.0002109194768671,
      0.999993046169536,
      1.0000379059395208,
      1.0000379059395208,
      0.9997958249181775,
      0.9998883404538892,
      0.599930204154904,
      0.19997673471830132,
      0.19997673471830132,
      1.0000379059395208,
      1.0000379059395208,
      1.0002109194768671,
      1.0000379059395208,
      1.0000379059395206,
      1.0002109194768671,
      1.0002109194768674,
      1.0002109194768674,
      0.999993046169536,
      0.33337634386846304,
      0.5556272397807718,
      0.11112544795615435,
      1.0002109194768671,
      0.2499814319077388,
      0.7499442957232164,
      0.9998883404538892,
      0.999993046169536,
      0.999888340453889,
      0.19999071913896865,
      0.7999628765558746,
      1.0002109194768671,
      0.999888340453889,
      0.9997958249181775,
      0.9997958249181775,
      0.999888340453889,
      0.999993046169536,
      0.9997958249181775,
      1.0002109194768671,
      1.0002109194768671,
      1.0002109194768671,
      1.0000379059395208,
      0.10000482888056612,
      0.7000338021639628,
      0.20000965776113225,
      0.1666728047812353,
      0.1666728047812353,
      0.6666912191249412,
      0.9999930461695359,
      0.9999930461695359,
      1.0000379059395208,
      0.999993046169536,
    ],
    Term: [
      "aap",
      "abu",
      "abu_salem",
      "activist",
      "adityanath",
      "africa",
      "airs",
      "ali",
      "also",
      "ambedkar",
      "ambedkar_wife",
      "amitabh",
      "amitabh",
      "animal",
      "arabia",
      "assault",
      "ayodhya",
      "bachchan",
      "bachchan",
      "bank",
      "bank",
      "base",
      "based_religion",
      "bus",
      "chauhan",
      "contest",
      "controversy",
      "convoy",
      "crash",
      "crash",
      "dance",
      "dancing",
      "difference",
      "farmer",
      "fb",
      "fight",
      "flag",
      "flood",
      "gangster",
      "global",
      "go",
      "go",
      "go",
      "government",
      "haji_ali",
      "haryana",
      "helicopter",
      "hindu",
      "honour",
      "idbi",
      "indian",
      "indian",
      "indian",
      "inhale",
      "intercept",
      "jihad",
      "jiog",
      "journalist",
      "kailash",
      "kangana",
      "kangana",
      "kangana_ranaut",
      "kangana_ranaut",
      "kasab",
      "kill",
      "ladakh",
      "lathicharge",
      "leader",
      "leg",
      "love",
      "lucknow",
      "madhya",
      "madhya_pradesh",
      "maharashtra",
      "manager",
      "manager_maharashtra",
      "minister",
      "mirage",
      "mount",
      "mount_kailash",
      "muslim",
      "muslim",
      "nadu",
      "pakistan",
      "pilot",
      "police",
      "pradesh",
      "pradesh",
      "protest",
      "protest",
      "rajasthan",
      "rally",
      "ranaut",
      "ranaut",
      "religion",
      "report",
      "row",
      "sabha",
      "salahuddin",
      "salem",
      "say",
      "say",
      "say",
      "secret",
      "sena",
      "service",
      "shiv",
      "shiv_sena",
      "shivraj",
      "shivraj_singh",
      "shoe",
      "shoot",
      "show",
      "show",
      "show",
      "shweta",
      "social",
      "social",
      "social_media",
      "soldier",
      "spin",
      "student",
      "student",
      "sudha",
      "support",
      "sye",
      "terrorist",
      "tweet",
      "tycoon",
      "unemployment",
      "upsc",
      "vaccine",
      "visit",
      "wife",
      "worker",
      "worker",
      "worker",
      "year",
      "year",
      "year",
      "yogi",
      "yogi_adityanath",
      "youth",
      "zee",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/honor-killing-case-of-2018-and-an-unrelated-wedding-card-of-inter-faith-couple-shared-with-false-love-jihad-angle/",
        headline:
          "Old case of Muslim woman\u2019s \u2018honour\u2019 killing revived as Hindu girl killed in \u2018love Jihad\u2019",
      },
      {
        url:
          "https://factly.in/telugu-global-terrorist-sayeed-salahudeen-contested-in-1987-jk-elections-as-an-independent-mla/",
        headline:
          " \u0c05\u0c02\u0c24\u0c30\u0c4d\u0c1c\u0c3e\u0c24\u0c40\u0c2f \u0c09\u0c17\u0c4d\u0c30\u0c35\u0c3e\u0c26\u0c3f \u0c38\u0c2f\u0c4d\u0c2f\u0c26\u0c4d \u0c38\u0c32\u0c3e\u0c35\u0c41\u0c26\u0c4d\u0c26\u0c40\u0c28\u0c4d 1987 \u0c0e\u0c32\u0c15\u0c4d\u0c37\u0c28\u0c4d\u0c38\u0c4d \u0c32\u0c4b \u0c07\u0c02\u0c21\u0c3f\u0c2a\u0c46\u0c02\u0c21\u0c46\u0c02\u0c1f\u0c4d \u0c17\u0c3e \u0c2a\u0c4b\u0c1f\u0c3f \u0c1a\u0c47\u0c38\u0c3e\u0c21\u0c41, \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40 \u0c24\u0c30\u0c2a\u0c41\u0c28 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/global-terrorist-syed-salauddin-did-not-contest-1987-election-on-congress-party-ticket/",
        headline:
          " Global terrorist Syed Salahuddin did not contest 1987 election on Congress party ticket.",
      },
      {
        url:
          "https://www.altnews.in/old-images-viral-as-students-protesting-against-unemployment-in-lucknow/",
        headline:
          "Old images viral as police crackdown on students protesting against unemployment in Lucknow",
      },
      {
        url:
          "https://www.altnews.in/old-images-shared-as-recent-farmer-protest-in-haryana/",
        headline:
          "Old photos shared to depict recent farmers protest in Pipli, Haryana",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-of-maharashtra-cop-viral-as-shiv-sena-mp-sanjay-raut-dancing/",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0921\u093e\u0902\u0938 \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u093e \u0936\u0916\u094d\u0938 \u0938\u0902\u091c\u092f \u0930\u093e\u0909\u0924 \u0928\u0939\u0940\u0902 \u092c\u0932\u094d\u0915\u093f \u090f\u0915 \u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-images-shared-as-recent-farmer-protest-in-haryana/",
        headline:
          "\u0939\u0930\u093f\u092f\u093e\u0923\u093e \u0930\u0948\u0932\u0940 \u092e\u0947\u0902 \u0915\u093f\u0938\u093e\u0928 \u0914\u0930 \u092a\u0941\u0932\u093f\u0938 \u0915\u0947 \u092c\u0940\u091a \u0939\u0941\u0908 \u091d\u095c\u092a \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0939\u0941\u0908\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-images-viral-as-students-protesting-against-unemployment-in-lucknow/",
        headline:
          "\u092c\u0947\u0930\u094b\u095b\u0917\u093e\u0930\u0940 \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u091a\u0932 \u0930\u0939\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0940 \u092c\u0924\u093e\u0915\u0930 \u092a\u0941\u0932\u093f\u0938 \u0932\u093e\u0920\u0940\u091a\u093e\u0930\u094d\u091c \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0936\u0947\u092f\u0930 \u0915\u0940 \u0917\u092f\u0940\u0902",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/sighting-of-leopard-in-south-africa-resort-shared-as-rajasthan-9791",
        headline:
          "Sighting of Leopard In South Africa Resort Shared As Rajasthan",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2017-rajasthan-protest-photo-viral-as-recent-haryana-farmer-protests-9799",
        headline:
          "2017 Rajasthan Protest Photo Viral As Recent Haryana Farmer Protests",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2018-images-from-protest-in-lucknow-revived-with-unemployment-twist-9827",
        headline:
          "2018 Images From Protest In Lucknow Revived With Unemployment Twist",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photos-of-inter-faith-couple-peddled-with-false-murder-claim-9735",
        headline:
          "\u0939\u093f\u0902\u0926\u0942-\u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0915\u092a\u0932 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0930\u0902\u0917 \u0926\u0947\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/sambit-patra-falsely-claims-terrorist-ajmal-kasab-was-fed-biryani-in-jail-9771",
        headline:
          "\u095e\u0930\u094d\u095b\u0940: \u0938\u0902\u092c\u093f\u0924 \u092a\u093e\u0924\u094d\u0930\u093e \u0928\u0947 \u0926\u093e\u0935\u093e \u0915\u093f\u092f\u093e \u0915\u093f \u0905\u091c\u092e\u0932 \u0915\u0938\u093e\u092c \u0915\u094b \u091c\u0947\u0932 \u092e\u0947\u0902 \u092c\u093f\u0930\u092f\u093e\u0928\u0940 \u0916\u093f\u0932\u093e\u0908 \u0917\u092f\u0940 \u0925\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/farmers-protest-farmer-bill-kisaan-agriculture-police-lathi-charge-haryana-farmers-9833",
        headline:
          "\u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u092a\u0930 \u092a\u0941\u0932\u093f\u0938 \u0932\u093e\u0920\u0940\u091a\u093e\u0930\u094d\u091c \u092c\u0924\u093e\u0915\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u091c\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/tripura-govt-invoking-esma-in-april-shared-as-recent-9787",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0\u09ad\u09be\u09ac\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u098f\u09aa\u09cd\u09b0\u09bf\u09b2 \u09ae\u09be\u09b8\u09c7 \u09a4\u09cd\u09b0\u09bf\u09aa\u09c1\u09b0\u09be\u09df \u098f\u09b8\u09ae\u09be \u0986\u0987\u09a8 \u09b2\u09be\u0997\u09c1 \u09b9\u0993\u09df\u09be\u09b0 \u0996\u09ac\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/sighting-of-leopard-in-south-africa-resort-shared-as-rajasthan-9793",
        headline:
          "\u09a6\u0995\u09cd\u09b7\u09bf\u09a3 \u0986\u09ab\u09cd\u09b0\u09bf\u0995\u09be\u09b0 \u09b9\u09cb\u099f\u09c7\u09b2\u09c7 \u099a\u09bf\u09a4\u09be \u09ac\u09be\u0998\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09b0\u09be\u099c\u09b8\u09cd\u09a5\u09be\u09a8\u09c7\u09b0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/murshidabad-police-debunks-fake-posts-claiming-no-loudspeaker-allowed-in-temples-during-azaan-9804",
        headline:
          "\u0986\u099c\u09be\u09a8\u09c7\u09b0 \u09b8\u09ae\u09af\u09bc \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u09c7 \u09ae\u09be\u0987\u0995 \u09a8\u09af\u09bc, \u09ad\u09c1\u09af\u09bc\u09cb \u09aa\u09cb\u09b8\u09cd\u099f \u099c\u09be\u09a8\u09be\u09b2 \u09ae\u09c1\u09b0\u09cd\u09b6\u09bf\u09a6\u09be\u09ac\u09be\u09a6 \u09aa\u09c1\u09b2\u09bf\u09b6",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-of-bike-repair-shop-owned-by-muslim-in-baghajatin-performing-vishwakarma-puja-shared-with-false-claim-9815",
        headline:
          "\u09ac\u09be\u0998\u09be\u09af\u09a4\u09c0\u09a8\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u09a6\u09cb\u0995\u09be\u09a8\u09c7 \u09ac\u09bf\u09b6\u09cd\u09ac\u0995\u09b0\u09cd\u09ae\u09be \u09aa\u09c1\u099c\u09cb\u09b0 \u09b8\u09be\u0987\u09a8 \u09ac\u09cb\u09b0\u09cd\u09a1 \u0995\u09c0 \u09ac\u09a6\u09b2 \u0995\u09b0\u09be?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/16/fact-check-old-picture-of-honour-killing-falsely-shared-as-death-due-to-love-jihad/",
        headline:
          "Fact Check: Old Picture of \u2018honour killing\u2019 falsely shared as death due to \u2018love jihad\u2019",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/16/fact-check-2017-photo-from-rajasthan-kisan-rally-being-attributed-to-recent-farmers-protest-in-haryana/",
        headline:
          "Fact Check: 2017 photo from Rajasthan kisan rally being attributed to recent farmers protest in Haryana",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/17/fact-check-can-coronavirus-be-killed-by-inhaling-steam-at-70c-heres-the-truth/",
        headline:
          "Fact Check: Can Coronavirus be killed by inhaling steam at 70\u00b0C? Here\u2019s the truth",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/farmers-protest-kisan-rally-haryana-farmers-bill-krishi-bill-9841",
        headline:
          "\u0930\u093e\u091c\u0938\u094d\u0925\u093e\u0928 \u092a\u094d\u0930\u094b\u091f\u0947\u0938\u094d\u091f \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0930\u093f\u092f\u093e\u0923\u093e \u092e\u0947\u0902 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/silicon-sculpture-by-italian-artist-shared-as-rare-animal-found-in-ajodhya-hills-9842",
        headline:
          "\u0987\u09a4\u09be\u09b2\u09bf\u09b0 \u09b6\u09bf\u09b2\u09cd\u09aa\u09c0\u09b0 \u09b8\u09bf\u09b2\u09bf\u0995\u09a8 \u09ad\u09be\u09b8\u09cd\u0995\u09b0\u09cd\u09af\u0995\u09c7 \u0985\u09af\u09cb\u09a7\u09cd\u09af\u09be \u09aa\u09be\u09b9\u09be\u09dc\u09c7\u09b0 \u09ac\u09bf\u09b0\u09b2 \u09aa\u09cd\u09b0\u09be\u09a3\u09c0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-rajasthan-protest-photo-viral-as-recent-haryana-farmer-protests-9848",
        headline:
          "\u09e8\u09e6\u09e7\u09ed \u09b8\u09be\u09b2\u09c7 \u09b0\u09be\u099c\u09b8\u09cd\u09a5\u09be\u09a8\u09c7 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u0995\u09c7 \u09b9\u09b0\u09bf\u09df\u09be\u09a8\u09be\u09df \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u099b\u09ac\u09bf \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://factly.in/2013-photo-falsely-linked-to-the-farmers-present-protest-against-the-agriculture-laws/",
        headline:
          " 2013 photo falsely linked to the farmers\u2019 present protest against the Agriculture laws",
      },
      {
        url:
          "https://factly.in/old-video-from-saudi-arabia-is-being-falsely-shared-as-the-one-from-rajasthan/",
        headline:
          " Old video from Saudi Arabia is being falsely shared as the one from Rajasthan.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/no-bjp-worker-did-not-have-george-floyd-moment-kerala-police-1723071-2020-09-18",
        headline:
          "Fact Check: No, BJP worker didn't have a George Floyd moment with Kerala police",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-belgian-city-terror-links-100-muslim-population-1723499-2020-09-19",
        headline:
          "Fact Check: Does this Belgian city with terror links have 100% Muslim population?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/18/fact-check-old-photos-of-protest-in-lucknow-resurfaces-as-recent-protest-pictures/",
        headline:
          "Fact Check: Old photos of protest in Lucknow resurfaces as recent protest pictures",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-dont-fall-for-this-old-picture-it-has-nothing-to-do-with-recent-farmers-agitation/",
        headline:
          "Fact Check: Don\u2019t fall for this old picture, it has nothing to do with recent farmers\u2019 agitation",
      },
    ],
    "2": [
      {
        url:
          "https://www.altnews.in/times-now-airs-fb-live-video-as-secret-admission-by-left-wing-activists-on-umar-khalid/",
        headline:
          "Times Now airs FB Live video as \u2018secret\u2019 admission by Left-wing activists on Umar Khalid",
      },
      {
        url:
          "https://www.altnews.in/man-thrashed-on-camera-by-shiv-sena-workers-is-indeed-ex-indian-navy-serviceman/",
        headline:
          "Man thrashed on camera by Shiv Sena workers is indeed ex-Indian Navy serviceman",
      },
      {
        url:
          "https://www.altnews.in/kangana-ranaut-falsely-claims-she-was-forced-to-vote-for-shiv-sena-in-2019-lok-sabha-polls/",
        headline:
          "Kangana Ranaut falsely claims she was \u2018forced\u2019 to vote for Shiv Sena in 2019 Lok Sabha polls",
      },
      {
        url:
          "https://www.altnews.in/hindi/times-now-airs-fb-live-video-as-secret-admission-by-left-wing-activists-on-umar-khalid/",
        headline:
          "\u091f\u093e\u0907\u092e\u094d\u0938 \u0928\u093e\u0909 \u0928\u0947 FB Live \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0932\u0947\u095e\u094d\u091f \u0938\u0947 \u091c\u0941\u095c\u0947 \u0910\u0915\u094d\u091f\u093f\u0935\u093f\u0938\u094d\u091f\u094d\u0938 \u0915\u0940 \u2018\u0938\u0940\u0915\u094d\u0930\u0947\u091f \u092c\u093e\u0924\u091a\u0940\u0924\u2019 \u092c\u0924\u093e\u0924\u0947 \u0939\u0941\u090f \u0926\u093f\u0916\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/man-thrashed-on-camera-by-shiv-sena-workers-is-indeed-ex-indian-navy-serviceman/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915: \u0936\u093f\u0935\u0938\u0947\u0928\u093e \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u0928\u0947 \u091c\u093f\u0938\u0915\u0947 \u0938\u093e\u0925 \u092e\u093e\u0930\u092a\u0940\u091f \u0915\u0940 \u0935\u094b \u092a\u0942\u0930\u094d\u0935 \u0928\u094c\u0938\u0948\u0928\u093f\u0915 \u0928\u0939\u0940\u0902?",
      },
      {
        url:
          "https://www.altnews.in/hindi/kangana-ranaut-falsely-claims-she-was-forced-to-vote-for-shiv-sena-in-2019-lok-sabha-polls/",
        headline:
          "\u0915\u0902\u0917\u0928\u093e \u0915\u093e \u091d\u0942\u0920\u093e \u0926\u093e\u0935\u093e: \u0915\u0939\u093e \u0915\u093f 2019 \u0932\u094b\u0915\u0938\u092d\u093e \u091a\u0941\u0928\u093e\u0935 \u092e\u0947\u0902 \u0936\u093f\u0935\u0938\u0947\u0928\u093e \u0915\u094b \u0935\u094b\u091f \u0926\u0947\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u092e\u091c\u092c\u0942\u0930 \u0925\u0940\u0902",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/man-assaulted-by-shiv-sainiks-worked-in-the-indian-navy-id-cards-show-9734",
        headline:
          "Man Assaulted By Shiv Sainiks Worked In The Indian Navy, ID Cards Show",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/times-now-airs-fb-live-video-as-secret-admission-by-activists-on-pfi-9748",
        headline:
          "Times Now Airs FB Live Video As 'Secret Admission' By Activists On PFI",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/bank-manager-assaulted-by-youth-congress-workers-not-shiv-sainiks-9769",
        headline:
          "Bank Manager Assaulted By Youth Congress Workers Not Shiv Sainiks",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2019-video-of-shiv-sena-workers-tonsuring-a-mans-head-shared-as-recent-9784",
        headline:
          "2019 Video Of Shiv Sena Workers Tonsuring A Man's Head Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/kangana-urmila-row-old-amul-ad-falsely-linked-to-recent-controversy-9797",
        headline:
          "Kangana-Urmila Row: Old Amul Ad Falsely Linked To Recent Controversy",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/image-of-bus-with-br-ambedkar-and-his-wifes-photo-across-it-is-fake-9829",
        headline:
          "Image Of Bus With BR Ambedkar And His Wife's Photo Across It Is Fake",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/bank-manager-assaulted-by-youth-congress-workers-not-shiv-sainiks-9785",
        headline:
          "\u0928\u0939\u0940\u0902, \u092e\u0939\u093e\u0930\u093e\u0937\u094d\u091f\u094d\u0930 \u092e\u0947\u0902 \u0936\u093f\u0935\u0938\u0947\u0928\u093e \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u0928\u0947 \u092c\u0948\u0902\u0915 \u092e\u0948\u0928\u0947\u091c\u0930 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0928\u0939\u0940\u0902 \u0915\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2019-video-of-shiv-sena-workers-tonsuring-a-mans-head-shared-as-recent-9786",
        headline:
          "\u0936\u093f\u0935 \u0938\u0947\u0928\u093e \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u0915\u0947 \u092e\u093e\u0930\u092a\u0940\u091f \u0915\u093e \u092f\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u093f\u091b\u0932\u0947 \u0938\u093e\u0932 \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/kangana-urmila-row-old-amul-ad-falsely-linked-to-recent-controversy-9801",
        headline:
          "\u0915\u0902\u0917\u0928\u093e-\u0909\u0930\u094d\u092e\u093f\u0932\u093e \u0915\u0947 \u0915\u0939\u093e\u0938\u0941\u0928\u0940 \u0915\u0947 \u092c\u0940\u091a \u0905\u092e\u0942\u0932 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u093f\u091c\u094d\u091e\u093e\u092a\u0928 \u0917\u0932\u0924 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/image-of-bus-with-br-ambedkar-and-his-wifes-photo-across-it-is-fake-9832",
        headline:
          "\u0915\u094d\u092f\u093e \u0921\u0949 \u0906\u0902\u092c\u0947\u0921\u0915\u0930 \u0914\u0930 \u0909\u0928\u0915\u0940 \u092a\u0924\u094d\u0928\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0947 \u0938\u093e\u0925 \u0915\u094b\u0932\u0902\u092c\u093f\u092f\u093e \u092e\u0947\u0902 \u090f\u0915 \u092c\u0938 \u091a\u0932\u093e\u0908 \u0917\u092f\u0940 \u0939\u0948?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/man-assaulted-by-shiv-sainiks-worked-in-the-indian-navy-id-cards-show-9766",
        headline:
          "\u09aa\u09b0\u09bf\u099a\u09df\u09aa\u09a4\u09cd\u09b0 \u09ac\u09b2\u09c7 \u09b6\u09bf\u09ac \u09b8\u09c8\u09a8\u09bf\u0995\u09a6\u09c7\u09b0 \u09b9\u09be\u09a4\u09c7 \u09a8\u09bf\u0997\u09c3\u09b9\u09c0\u09a4 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09a8\u09cc \u0995\u09b0\u09cd\u09ae\u09c0 \u099b\u09bf\u09b2\u09c7\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/times-now-airs-fb-live-video-as-secret-admission-by-activists-on-pfi-9824",
        headline:
          "\u09aa\u09bf\u098f\u09ab\u0986\u0987 \u09b8\u09ae\u09be\u099c\u0995\u09b0\u09cd\u09ae\u09c0\u09a6\u09c7\u09b0 '\u0997\u09cb\u09aa\u09a8 \u099c\u09ac\u09be\u09a8\u09bf' \u09ac\u09b2\u09c7 \u09ab\u09c7\u09b8\u09ac\u09c1\u0995 \u09b2\u09be\u0987\u09ad \u09ad\u09bf\u09a1\u09bf\u0993 \u09a6\u09c7\u0996\u09be\u09b2 \u099f\u09be\u0987\u09ae\u09b8 \u09a8\u09be\u0989",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/kangana-urmila-row-old-amul-ad-falsely-linked-to-recent-controversy-9830",
        headline:
          "\u098a\u09b0\u09cd\u09ae\u09bf\u09b2\u09be-\u0995\u0999\u09cd\u0997\u09a8\u09be \u09a4\u09b0\u099c\u09be: \u0986\u09ae\u09c2\u09b2\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ac\u09bf\u099c\u09cd\u099e\u09be\u09aa\u09a8 \u099c\u09a1\u09bc\u09be\u09b2 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09bf\u09a4\u09b0\u09cd\u0995\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019-video-of-shiv-sena-workers-tonsuring-a-mans-head-shared-as-recent-9835",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b6\u09c7 \u09b6\u09bf\u09ac \u09b8\u09c7\u09a8\u09be \u0995\u09b0\u09cd\u09ae\u09c0\u09a6\u09c7\u09b0 \u098f\u0995 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u09ae\u09be\u09a5\u09be \u09ae\u09cb\u09a1\u09bc\u09be\u09a8\u09cb\u0995\u09c7 \u09b9\u09be\u09b2\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://factly.in/idbi-bank-manager-in-maharashtra-was-not-assaulted-by-the-shiv-sena-workers/",
        headline:
          " IDBI Bank Manager in Maharashtra was not assaulted by the Shiv Sena workers",
      },
      {
        url:
          "https://factly.in/telugu-idbi-bank-manager-in-maharashtra-was-not-assaulted-by-the-shiv-sena-workers/",
        headline:
          " \u0c2e\u0c39\u0c3e\u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c32\u0c4b\u0c28\u0c3f IDBI \u0c2c\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c4d \u0c2e\u0c47\u0c28\u0c47\u0c1c\u0c30\u0c4d \u0c2a\u0c48 \u0c26\u0c3e\u0c21\u0c3f \u0c1a\u0c47\u0c38\u0c3f\u0c02\u0c26\u0c3f \u0c36\u0c3f\u0c35 \u0c38\u0c47\u0c28 \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40 \u0c38\u0c2d\u0c4d\u0c2f\u0c41\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/17/fact-check-did-shiv-sena-members-assault-an-idbi-bank-manager-in-maharashtra-heres-the-truth/",
        headline:
          "Fact Check: Did Shiv Sena members assault an IDBI Bank manager in Maharashtra? Here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/morphed-photo-shared-as-babasaheb-ambedkar-and-his-wifes-picture-on-a-bus-in-america/",
        headline:
          " Morphed photo shared as  Babasaheb Ambedkar and his wife\u2019s picture on a bus in America",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/is-24-magic-number-covid-patients-coronavirus-1722733-2020-09-17",
        headline: "Fact Check: Is 24 the magic number for Covid patients?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-image-of-ambedkar-on-a-bus-in-the-united-states-is-morphed-1723883-2020-09-21",
        headline:
          "Fact Check: This image of Ambedkar on a bus in the \u2018United States\u2019 is morphed",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-2019-video-of-a-mans-head-being-tonsured-by-shiv-sena-members-resurfaces-as-recent/",
        headline:
          "Fact Check: 2019 video of a man\u2019s head being tonsured by Shiv Sena members resurfaces as recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/ffact-check-photo-of-br-ambedkar-his-wife-displayed-on-bus-in-columbia-heres-the-truth/",
        headline:
          "Fact Check: Photo of BR Ambedkar & his wife displayed on bus in Columbia? Here\u2019s the truth",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/pakistani-journalist-users-share-2018-image-as-indian-chopper-recently-crashed-in-ladakh/",
        headline:
          "Pakistani journalist, users share 2018 image as Indian chopper recently crashed in Ladakh",
      },
      {
        url:
          "https://www.altnews.in/old-images-of-mirage-2000-crash-viral-as-two-pilots-dead-in-rafale-crash-at-ambala/",
        headline:
          "Old images of Mirage 2000 crash viral as two pilots dead in Rafale crash during training",
      },
      {
        url:
          "https://factly.in/2018-image-of-a-helicopter-crash-is-shared-as-that-of-a-recent-crash-in-ladakh/",
        headline:
          " 2018 image of a Helicopter crash is shared as that of a recent crash in Ladakh",
      },
      {
        url:
          "https://www.altnews.in/no-this-is-not-a-photo-of-kangana-ranaut-with-abu-salem/",
        headline:
          "No, this is not a photo of Kangana Ranaut with Abu Salem\u2019s brother",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-images-of-mirage-2000-crash-viral-as-two-pilots-dead-in-rafale-crash-at-ambala/",
        headline:
          "\u092a\u094d\u0932\u0947\u0928 \u0915\u094d\u0930\u0948\u0936 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0930\u093e\u095e\u0947\u0932 \u0915\u094d\u0930\u0948\u0936 \u092e\u0947\u0902 2 \u092a\u093e\u092f\u0932\u091f \u0915\u0940 \u092e\u094c\u0924 \u0915\u0947 \u0917\u0932\u0924 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0936\u0947\u092f\u0930 \u0915\u0940 \u0917\u092f\u0940\u0902",
      },
      {
        url:
          "https://www.altnews.in/hindi/no-this-is-not-a-photo-of-kangana-ranaut-with-abu-salem/",
        headline:
          "\u0915\u0902\u0917\u0928\u093e \u0930\u093e\u0928\u094c\u0924 \u0915\u0947 \u0938\u093e\u0925 \u0905\u092c\u0942 \u0938\u0932\u0947\u092e \u0915\u0947 \u092d\u093e\u0908 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930? \u0917\u0932\u0924 \u0926\u093e\u0935\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/hindi/pakistani-journalist-users-share-2018-image-as-indian-chopper-recently-crashed-in-ladakh/",
        headline:
          "\u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u092a\u0924\u094d\u0930\u0915\u093e\u0930 \u0928\u0947 2018 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u094b \u0932\u0926\u094d\u0926\u093e\u0916 \u092e\u0947\u0902 \u0915\u094d\u0930\u0948\u0936 \u092d\u093e\u0930\u0924\u0940\u092f \u0935\u093e\u092f\u0941\u0938\u0947\u0928\u093e \u0915\u093e MI-17 \u0939\u0947\u0932\u093f\u0915\u0949\u092a\u094d\u091f\u0930 \u092c\u0924\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/fake-abp-news-graphic-plates-on-kangana-ranaut-and-reopening-of-schools-viral/",
        headline:
          "\u0915\u0902\u0917\u0928\u093e \u0930\u093e\u0928\u094c\u0924 \u0915\u0947 BJP \u0915\u0947 \u0932\u093f\u090f \u092a\u094d\u0930\u091a\u093e\u0930 \u0915\u0930\u0928\u0947 \u0914\u0930 \u0938\u094d\u0915\u0942\u0932 \u0916\u094b\u0932\u0947 \u091c\u093e\u0928\u0947 \u0915\u0940 \u0959\u092c\u0930 \u0935\u093e\u0932\u0947 \u095e\u0930\u094d\u095b\u0940 \u0917\u094d\u0930\u093e\u095e\u093f\u0915 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/hindi/shashi-tharoor-tweets-fake-university-letter-rejecting-albert-einsteins-doctoral-application/",
        headline:
          "\u0906\u0907\u0928\u094d\u0938\u094d\u091f\u093e\u0907\u0928 \u0915\u094b \u0921\u0949\u0915\u094d\u091f\u0930\u0947\u091f \u0915\u0947 \u0932\u093f\u090f \u0930\u093f\u091c\u0947\u0915\u094d\u091f \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u093e \u092c\u0930\u094d\u0928 \u092f\u0942\u0928\u093f\u0935\u0930\u094d\u0938\u093f\u091f\u0940 \u0915\u093e \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u0959\u0924 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-is-not-abu-salem-with-kangana-ranaut-9745",
        headline: "No, This Is Not Abu Salem With Kangana Ranaut",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2015-image-of-jackie-chan-in-emirati-attire-shared-with-religious-spin-9779",
        headline:
          "2015 Image Of Jackie Chan In Emirati Attire Shared With Religious Spin",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-image-doesnt-show-kangana-ranaut-with-gangster-abu-salem-9746",
        headline:
          "\u091c\u0940 \u0928\u0939\u0940\u0902, \u0907\u0938 \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0915\u0902\u0917\u0928\u093e \u0930\u0928\u094c\u0924 \u0915\u0947 \u0938\u0902\u0917 \u0917\u0948\u0902\u0917\u0938\u094d\u091f\u0930 \u0905\u092c\u0942 \u0938\u0932\u0947\u092e \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/screenshots-of-facebook-account-impersonating-raj-and-subhashrees-son-viral-9740",
        headline:
          "\u09b0\u09be\u099c \u09b6\u09c1\u09ad\u09b6\u09cd\u09b0\u09c0\u09b0 \u09b8\u09a6\u09cd\u09af\u099c\u09be\u09a4 \u099b\u09c7\u09b2\u09c7 \u09df\u09c1\u09ad\u09be\u09a8\u09c7\u09b0 \u09a8\u09be\u09ae\u09c7 \u09ad\u09c1\u09df\u09cb \u09ab\u09c7\u09b8\u09ac\u09c1\u0995 \u09aa\u09cd\u09b0\u09cb\u09ab\u09be\u0987\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-is-not-abu-salem-with-kangana-ranaut-9747",
        headline:
          "\u09a8\u09be, \u098f\u099f\u09bf \u0995\u0999\u09cd\u0997\u09a8\u09be \u09b0\u09be\u09a8\u09be\u0989\u09a4\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u0986\u09ac\u09c1 \u09b8\u09be\u09b2\u09c7\u09ae\u09c7\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/bjp-announces-kangana-ranaut-as-cm-candidate-of-west-bengal-morphed-article-shared-9828",
        headline:
          "\u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf\u09b0 \u09ae\u09c1\u0996\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09aa\u09a6\u09aa\u09cd\u09b0\u09be\u09b0\u09cd\u09a5\u09c0 \u0995\u0999\u09cd\u0997\u09a8\u09be? \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09ad\u09c1\u09df\u09cb \u0996\u09ac\u09b0",
      },
      {
        url:
          "https://factly.in/telugu-most-of-the-persons-in-this-video-are-local-village-tribals/",
        headline:
          " \u0c08 \u0c21\u0c4d\u0c30\u0c4b\u0c28\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c02\u0c26\u0c30\u0c42 \u0c2e\u0c3e\u0c35\u0c4b\u0c2f\u0c3f\u0c38\u0c4d\u0c1f\u0c41\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/there-is-no-scientific-evidence-to-prove-that-coronavirus-dies-at-70c/",
        headline:
          " There is no scientific evidence to prove that Coronavirus dies at 70\u00b0C",
      },
      {
        url:
          "https://factly.in/telugu-the-person-with-kangana-ranaut-in-this-photo-is-not-underworld-don-abu-salem/",
        headline:
          " \u0c2b\u0c4a\u0c1f\u0c4b\u0c32\u0c4b \u0c15\u0c02\u0c17\u0c28\u0c3e \u0c30\u0c28\u0c4c\u0c24\u0c4d \u0c24\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c12\u0c15 \u0c2b\u0c3f\u0c32\u0c3f\u0c02 \u0c1c\u0c30\u0c4d\u0c28\u0c32\u0c3f\u0c38\u0c4d\u0c1f\u0c4d, \u0c05\u0c02\u0c21\u0c30\u0c4d \u0c35\u0c30\u0c32\u0c4d\u0c21\u0c4d \u0c21\u0c3e\u0c28\u0c4d \u0c05\u0c2c\u0c42 \u0c38\u0c32\u0c40\u0c02 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/japanese-pop-singer-hiraku-nishida-live-performance-is-falsely-shared-as-the-video-of-xi-jinping-daughter/",
        headline:
          " Japanese pop singer Hiraku Nishida live performance is falsely shared as the video of Xi Jinping daughter",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/14/fact-check-dont-fall-for-this-fake-tweet-of-amitabh-bachchan-supporting-bmc-over-kangana-ranaut/",
        headline:
          "Fact Check: Don\u2019t fall for this fake tweet of Amitabh Bachchan supporting BMC over Kangana Ranaut",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/15/fact-check-old-picture-of-mi-17-helicopter-crash-resurfaces-as-recent-one/",
        headline:
          "Fact Check: Old picture of Mi-17 helicopter crash resurfaces as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/16/fact-check-this-man-with-kangana-ranaut-in-this-viral-picture-is-not-gangster-abu-salem/",
        headline:
          "Fact Check: This man with Kangana Ranaut in this viral picture is NOT gangster Abu Salem",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/albert-einstein-university-of-bern-viral-rejection-letter-9844",
        headline:
          "\u0905\u0932\u094d\u092c\u0930\u094d\u091f \u0906\u0907\u0902\u0938\u094d\u091f\u093e\u0907\u0928 \u0914\u0930 \u092f\u0942\u0928\u093f\u0935\u0930\u094d\u0938\u093f\u091f\u0940 \u0911\u095e \u092c\u0930\u094d\u0928 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u095e\u0930\u094d\u095b\u0940 \u092a\u0924\u094d\u0930 \u0915\u0940 \u0926\u093e\u0938\u094d\u0924\u093e\u0928",
      },
      {
        url:
          "https://factly.in/raj-thackeray-did-not-tweet-praising-kangana-ranaut-and-criticizing-kareena-kapoor/",
        headline:
          " Raj Thackeray did not tweet praising Kangana Ranaut and criticizing Kareena Kapoor",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-shaktimaan-struggles-with-his-fake-avatars-on-social-media-1722179-2020-09-15",
        headline:
          "Fact Check: Shaktimaan struggles with his fake avatars on social media",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-actor-s-picture-shared-on-social-media-as-doc-who-died-of-covid-19-1722520-2020-09-16",
        headline:
          "Fact Check: Actor's picture shared on social media as doc who died of Covid-19",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/why-pak-social-media-users-glee-at-crashed-iaf-chopper-is-misplaced-1721805-2020-09-14",
        headline:
          "Fact Check: Why Pak social media users' glee at crashed IAF chopper is misplaced\u00a0",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/19/fact-check-old-video-of-rss-march-in-hyderabad-resurfaces-as-recent/",
        headline:
          "Fact Check: Old video of RSS march in Hyderabad resurfaces as recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-who-has-not-approved-home-remedy-for-covid-19-dont-fall-for-misinformation/",
        headline:
          "Fact Check: WHO has not approved home remedy for COVID-19, don\u2019t fall for misinformation",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/hindi/2011-image-of-amitabh-bachchan-visit-ajmer-sharif-shared-as-he-went-there-after-tested-coronavirus-negative/",
        headline:
          "\u0905\u092e\u093f\u0924\u093e\u092d \u092a\u0930 \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0906\u0930\u094b\u092a\u094b\u0902 \u0915\u0940 \u0935\u091c\u0939 2011 \u0915\u0940 \u0905\u091c\u092e\u0947\u0930 \u092f\u093e\u0924\u094d\u0930\u093e \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902, KBC \u092d\u0940 \u0928\u093f\u0936\u093e\u0928\u0947 \u092a\u0930",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/fake-screenshots-of-abp-news-viral-in-name-of-kangana-ranaut-opening-of-schools-9733",
        headline:
          "\u090f.\u092c\u0940.\u092a\u0940 \u0928\u094d\u092f\u0942\u095b \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f\u094d\u0938 \u0939\u094b \u0930\u0939\u0947 \u0939\u0948\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/murder-victims-photo-viral-with-unrelated-wedding-card-of-inter-faith-couple-9729",
        headline:
          "\u09ad\u09bf\u09a8\u09cd\u09a8-\u09a7\u09b0\u09cd\u09ae\u09c7 \u09a6\u09ae\u09cd\u09aa\u09a4\u09bf\u09b0 \u09ac\u09bf\u09df\u09c7\u09b0 \u0995\u09be\u09b0\u09cd\u09a1\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u0996\u09c1\u09a8\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://factly.in/telugu-no-discriminationin-eligibility-criteria-in-upsc-based-on-religion/",
        headline:
          " \u0c2f\u0c42\u0c2a\u0c40\u0c0e\u0c38\u0c4d\u0c38\u0c40 \u0c38\u0c3f\u0c35\u0c3f\u0c32\u0c4d\u0c38\u0c4d \u0c05\u0c30\u0c4d\u0c39\u0c24 \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c23\u0c3e\u0c32\u0c32\u0c4b \u0c2e\u0c24\u0c02 \u0c06\u0c27\u0c3e\u0c30\u0c02\u0c17\u0c3e \u0c35\u0c47\u0c30\u0c41 \u0c35\u0c47\u0c30\u0c41 \u0c2a\u0c30\u0c3f\u0c2e\u0c3f\u0c24\u0c41\u0c32\u0c41 \u0c32\u0c47\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/upsc-civil-services-exam-no-difference-in-attempts-age-criteria-based-on-religion/",
        headline:
          " UPSC Civil Services Exam: No difference in Attempts & Age criteria based on Religion",
      },
      {
        url:
          "https://factly.in/telugu-this-masjid-in-the-middle-of-the-road-is-from-madhya-pradesh-not-from-bandra-in-mumbai/",
        headline:
          " \u0c2e\u0c27\u0c4d\u0c2f\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c2e\u0c38\u0c40\u0c26\u0c41 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c2e\u0c41\u0c02\u0c2c\u0c48 \u0c2c\u0c3e\u0c02\u0c26\u0c4d\u0c30\u0c3e \u0c32\u0c4b\u0c28\u0c3f \u0c2e\u0c38\u0c40\u0c26\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/2018-video-of-a-shoe-hurled-at-madhya-pradesh-cm-shivraj-singh-chauhan-shared-as-a-recent-incident/",
        headline:
          " 2018 video of a shoe hurled at Madhya Pradesh CM Shivraj Singh Chauhan shared as a recent incident",
      },
      {
        url:
          "https://www.altnews.in/2011-image-of-amitabh-bachchan-visit-ajmer-sharif-shared-as-he-went-there-after-tested-coronavirus-negative/",
        headline:
          "Old image of Amitabh Bachchan\u2019s visit to Ajmer Sharif shared as recent visit to Haji Ali dargah",
      },
      {
        url:
          "https://www.altnews.in/hindi/two-year-old-video-of-a-shoe-hurled-at-madhya-pradesh-cm-shivraj-singh-chauhan-shared-as-recent/",
        headline:
          "\u0936\u093f\u0935\u0930\u093e\u091c \u0938\u093f\u0902\u0939 \u091a\u094c\u0939\u093e\u0928 \u092a\u0930 \u091c\u0942\u0924\u093e \u092b\u0947\u0902\u0915\u0947 \u091c\u093e\u0928\u0947 \u0915\u093e \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u093e \u0935\u0940\u0921\u093f\u092f\u094b 2018 \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-sudha-murty-at-bengaluru-mutt-shared-with-misleading-claim-9739",
        headline:
          "Photo Of Sudha Murty At Bengaluru Mutt Shared With Misleading Claim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/amitabh-bachchan-visits-haji-ali-after-recovering-from-covid-19not-really-9763",
        headline:
          "Amitabh Bachchan Visits Haji Ali After Recovering From COVID-19?Not Really",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photos-of-nayva-naveli-nanda-partying-falsely-shared-as-shweta-bachchan-9814",
        headline:
          "Photos Of Nayva Naveli Nanda Partying Falsely Shared As Shweta Bachchan",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-message-claims-thieves-are-posing-as-mha-officials-on-census-duty-9738",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09ac\u09be\u09b0\u09cd\u09a4\u09be: \u099c\u09a8\u0997\u09a3\u09a8\u09be\u09b0 \u09a8\u09be\u09ae\u09c7 \u09b8\u09cd\u09ac\u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0 \u09a6\u09aa\u09cd\u09a4\u09b0\u09c7\u09b0 \u0995\u09b0\u09cd\u09ae\u09c0 \u09b8\u09c7\u099c\u09c7 \u0998\u09c1\u09b0\u099b\u09c7 \u099a\u09cb\u09b0\u09c7\u09b0\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-sudha-murty-at-bengaluru-mutt-shared-with-misleading-claim-9773",
        headline:
          "\u09ac\u09c7\u0999\u09cd\u0997\u09be\u09b2\u09c1\u09b0\u09c1\u09b0 \u098f\u0995\u099f\u09bf \u09ae\u09a0\u09c7 \u09b8\u09c1\u09a7\u09be \u09ae\u09c2\u09b0\u09cd\u09a4\u09bf\u09b0 \u099b\u09ac\u09bf \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09b6\u09c7\u09df\u09be\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-is-not-a-bjp-leader-threatening-judges-of-dance-india-dance-9795",
        headline:
          "\u09a8\u09be, '\u09a1\u09be\u09a8\u09cd\u09b8 \u0987\u09a8\u09cd\u09a1\u09bf\u09df\u09be \u09a1\u09be\u09a8\u09cd\u09b8' \u09ac\u09bf\u099a\u09be\u09b0\u0995\u09a6\u09c7\u09b0 \u09b9\u09c1\u09ae\u0995\u09bf \u09a6\u09bf\u099a\u09cd\u099b\u09c7\u09a8 \u09a8\u09be \u0995\u09cb\u09a8\u0993 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09a8\u09c7\u09a4\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/images-of-navya-naveli-nanda-with-her-friends-shared-with-misleading-context-9807",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09b6\u09c7\u09df\u09be\u09b0 \u09b9\u09b2 \u09ac\u09a8\u09cd\u09a7\u09c1\u09a6\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09a8\u09ad\u09cd\u09af\u09be \u09a8\u09ad\u09c7\u09b2\u09bf \u09a8\u09a8\u09cd\u09a6\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/sudarshan-tv-upsc-show-5-misleading-claims-9809",
        headline:
          "\u09b8\u09c1\u09a6\u09b0\u09cd\u09b6\u09a8 \u099f\u09bf\u09ad\u09bf'\u09b0 \u0987\u0989\u09aa\u09bf\u098f\u09b8\u09b8\u09bf \u0985\u09a8\u09c1\u09b7\u09cd\u09a0\u09be\u09a8: \u09eb\u099f\u09bf \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf",
      },
      {
        url:
          "https://factly.in/telugu-sudha-murthy-seva-photo-at-raghavendra-swamy-mutt-shared-as-that-of-her-selling-vegetables/",
        headline:
          " \u0c38\u0c47\u0c35\u0c3e \u0c15\u0c3e\u0c30\u0c4d\u0c2f\u0c15\u0c4d\u0c30\u0c2e\u0c3e\u0c32\u0c4d\u0c32\u0c4b \u0c2a\u0c3e\u0c32\u0c4d\u0c17\u0c4a\u0c28\u0c4d\u0c28\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c24\u0c40\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c15\u0c42\u0c30\u0c17\u0c3e\u0c2f\u0c32\u0c41 \u0c05\u0c2e\u0c4d\u0c2e\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c07\u0c28\u0c4d\u0c2b\u0c4b\u0c38\u0c3f\u0c38\u0c4d \u0c15\u0c4b-\u0c2b\u0c4c\u0c02\u0c21\u0c30\u0c4d \u0c38\u0c41\u0c27\u0c3e \u0c2e\u0c42\u0c30\u0c4d\u0c24\u0c3f \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/ndma-did-not-ask-the-government-to-reimpose-lockdown-from-25-sep-2020/",
        headline:
          " NDMA did not ask the government to reimpose lockdown from 25 Sep 2020.",
      },
      {
        url:
          "https://factly.in/no-the-government-does-not-incur-a-loss-of-rs-2600-crores-due-to-scribbled-notes/",
        headline:
          " No, the government does not incur a loss of Rs. 2600 crores due to scribbled notes.",
      },
      {
        url:
          "https://factly.in/telugu-prank-fight-in-a-dance-show-is-being-portrayed-as-highandedness-of-bjp-leader/",
        headline:
          " \u2018Dance India Dance\u2019 \u0c37\u0c4b \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c38\u0c30\u0c26\u0c3e \u0c18\u0c30\u0c4d\u0c37\u0c23 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c21\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c37\u0c4b \u0c32\u0c4b \u0c26\u0c3e\u0c26\u0c3e\u0c17\u0c3f\u0c30\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c3f \u0c32\u0c40\u0c21\u0c30\u0c4d \u0c15\u0c4a\u0c21\u0c41\u0c15\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/14/fact-check-does-sudha-murthy-sell-vegetables-once-a-year-heres-the-truth-behind-the-viral-picture/",
        headline:
          "Fact Check: Does Sudha Murthy \u2018sell\u2019 vegetables once a year? Here\u2019s the truth behind the viral picture",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/15/fact-check-old-video-of-shoes-being-hurled-at-madhya-pradesh-cm-shivraj-singh-chouhan-resurfaces-as-recent-one/",
        headline:
          "Fact Check: Old video of shoes being hurled at Madhya Pradesh CM Shivraj Singh Chouhan resurfaces as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/15/fact-check-a-prank-shot-during-a-dance-reality-show-shared-with-false-political-claim/",
        headline:
          "Fact Check: A prank shot during a dance reality show shared with false \u2018political\u2019 claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/15/fact-check-does-upsc-differentiate-between-aspirants-based-on-religion-heres-the-truth/",
        headline:
          "Fact Check: Does UPSC differentiate between aspirants based on religion? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/17/fact-check-did-amitabh-bachchan-visit-haji-ali-dargah-after-recovering-from-covid-19-heres-the-truth/",
        headline:
          "Fact Check: Did Amitabh Bachchan visit Haji Ali after recovering from COVID-19? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/17/fact-check-video-of-healthcare-workers-from-saudi-shared-as-russia-after-covid-19-vaccine-rollout/",
        headline:
          "Fact Check: Video of healthcare workers from Saudi shared as Russia after COVID-19 vaccine rollout",
      },
      {
        url:
          "https://factly.in/unrelated-video-falsely-shared-as-pm-modi-celebrating-his-birthday-with-industrialists/",
        headline:
          " Unrelated video falsely shared as PM Modi celebrating his birthday with industrialists",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-madhya-pradesh-congress-shares-fake-video-to-take-a-dig-at-shivraj-chouhan-1724022-2020-09-21",
        headline:
          "Fact Check: Madhya Pradesh Congress shares fake video to take a dig at Shivraj Chouhan",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-book-seats-for-fake-news-on-theatres-reopening-1722416-2020-09-16",
        headline:
          "Fact Check: Netizens book seats for fake news on theatres reopening",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/when-nagma-shook-the-wrong-leg-on-twitter-over-a-dance-reality-show-1721829-2020-09-14",
        headline:
          "Fact Check: When Nagma shook the wrong leg on Twitter over a dance reality show\u00a0",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/18/fact-check-2017-photo-of-pm-modis-visit-to-israel-falsely-shared-as-that-of-his-birthday-celebration/",
        headline:
          "Fact Check: 2017 photo of PM Modi\u2019s visit to Israel falsely shared as that of his birthday celebration",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/18/fact-check-false-claims-of-lockdown-in-mumbai-goes-viral/",
        headline: "Fact Check: False claims of lockdown in Mumbai goes viral",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-no-bjp-leaders-are-not-being-beaten-up-in-bihar/",
        headline:
          "Fact Check: No, BJP leaders are not being beaten up in Bihar",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-old-picture-of-clouds-during-sunset-shared-with-false-california-wildfires-claim/",
        headline:
          "Fact Check: Old picture of clouds during sunset shared with false California wildfires claim",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/misleading-reports-claimed-pakistan-admitted-to-dawood-ibrahims-presence-in-karachi/",
        headline:
          "Misleading reports claimed Pakistan admitted to Dawood Ibrahim\u2019s presence in Karachi",
      },
      {
        url:
          "https://www.altnews.in/morphed-photo-shared-as-indian-army-soldiers-raising-tricolour-flag-at-mount-kailash/",
        headline:
          "Morphed photo shared as Indian army soldiers raising tricolour flag at Mount Kailash",
      },
      {
        url:
          "https://www.altnews.in/hindi/morphed-photo-shared-as-indian-army-soldiers-raising-tricolour-flag-at-mount-kailash/",
        headline:
          "\u0924\u093f\u0930\u0902\u0917\u093e \u0932\u0939\u0930\u093e\u0924\u0947 \u0939\u0941\u090f \u092d\u093e\u0930\u0924\u0940\u092f \u0938\u0948\u0928\u093f\u0915\u094b\u0902 \u0915\u0940 \u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0948\u0932\u093e\u0936 \u092a\u0930\u094d\u0935\u0924 \u0915\u0940 \u0928\u0939\u0940\u0902 \u092c\u0932\u094d\u0915\u093f \u095e\u094b\u091f\u094b\u0936\u0949\u092a\u094d\u0921 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2017-video-of-protesters-stopping-up-cms-cavalcade-viral-as-recent-9749",
        headline:
          "\u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u0947 \u0915\u093e\u095e\u093f\u0932\u0947 \u0915\u094b \u0930\u094b\u0915\u0924\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928\u0915\u093e\u0930\u093f\u092f\u094b\u0902 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b 2017 \u0938\u0947 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/scholarship-uttar-pradesh-students-intermediate-unemployment-corona-virus-9778",
        headline:
          "\u0915\u094d\u092f\u093e \u0938\u091a \u092e\u0947\u0902 \u0907\u0938 \u0938\u093e\u0932 \u0909\u0924\u094d\u0924\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0947 \u091b\u093e\u0924\u094d\u0930\u094b\u0902 \u0915\u094b \u0938\u094d\u0915\u0949\u0932\u0930\u0936\u093f\u092a \u0928\u0939\u0940\u0902 \u092e\u093f\u0932\u0947\u0917\u0940?",
      },
      {
        url:
          "https://factly.in/this-image-of-a-child-marriage-is-from-pakistan-not-india/",
        headline: " This image of a child marriage is from Pakistan, not India",
      },
      {
        url:
          "https://factly.in/this-video-of-a-crocodile-caught-in-the-waterlogged-streets-is-from-vadodara-not-pakistan/",
        headline:
          " This video of a crocodile caught in the waterlogged streets is from Vadodara, not Pakistan",
      },
      {
        url:
          "https://factly.in/2017-video-of-students-attacking-up-cm-yogi-adityanath-convoy-is-shared-as-a-recent-video/",
        headline:
          " 2017 video of students attacking UP CM Yogi Adityanath convoy is shared as a recent video",
      },
      {
        url:
          "https://factly.in/this-photo-of-indian-army-waving-indian-flag-near-kailash-mountain-is-a-photoshopped-one/",
        headline:
          " This photo of Indian soldiers waving Indian flag near Kailash mountain is a photoshopped one",
      },
      {
        url:
          "https://factly.in/telugu-this-crashed-paf-aircraft-is-not-the-jf-17-aircraft-developed-by-pakistan-and-china/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c15\u0c42\u0c32\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d  \u0c2b\u0c48\u0c1f\u0c30\u0c4d \u0c1c\u0c46\u0c1f\u0c4d \u0c1a\u0c48\u0c28\u0c3e-\u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c26\u0c47\u0c36\u0c3e\u0c32\u0c41 \u0c15\u0c32\u0c3f\u0c38\u0c3f \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28 JF-17 \u0c2b\u0c48\u0c1f\u0c30\u0c4d \u0c1c\u0c46\u0c1f\u0c4d \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/indian-union-muslim-league-iuml-party-flag-on-a-car-is-falsely-portrayed-as-the-pakistan-national-flag/",
        headline:
          " Indian Union Muslim League (IUML) party flag on a car is falsely portrayed as the Pakistan National flag",
      },
      {
        url:
          "https://factly.in/telugu-photo-of-indian-soldiers-waving-indian-flag-near-kailash-mountain-is-a-photoshopped-one/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c37\u0c3e\u0c2a\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c15\u0c48\u0c32\u0c3e\u0c38\u0c4d \u0c2e\u0c3e\u0c28\u0c38\u0c30\u0c4b\u0c35\u0c30\u0c4d \u0c2e\u0c41\u0c02\u0c26\u0c41 \u0c24\u0c4d\u0c30\u0c3f\u0c35\u0c30\u0c4d\u0c23 \u0c2a\u0c24\u0c3e\u0c15\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c0e\u0c17\u0c30\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2d\u0c3e\u0c30\u0c24 \u0c1c\u0c35\u0c3e\u0c28\u0c4d\u0c32\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url: "https://factly.in/these-images-are-not-from-kailash-manasarovar/",
        headline: " These images are not from Kailash Manasarovar",
      },
      {
        url:
          "https://factly.in/this-purported-post-mortem-report-of-actress-sridevi-is-not-genuine/",
        headline:
          " This purported post-mortem report of actress Sridevi is not genuine",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/14/fact-check-old-zee-news-report-of-union-minister-piyush-goyal-facing-protests-resurfaces-as-recent-one/",
        headline:
          "Fact Check: Old Zee News report of Union Minister Piyush Goyal facing protests, resurfaces as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/14/fact-check-did-business-tycoon-mukesh-ambani-announce-a-new-multiplayer-game-jiog-heres-the-truth/",
        headline:
          "Fact Check: Did business tycoon Mukesh Ambani announce a new multiplayer game JioG? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/14/fact-check-will-up-students-not-get-scholarships-this-year-heres-the-truth/",
        headline:
          "Fact Check: Will UP students not get scholarships this year? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/14/fact-check-this-video-of-the-indian-health-minister-slapping-people-without-masks-is-from-pakistan/",
        headline:
          "Fact Check: This video of the Indian Health Minister slapping people without masks is from Pakistan",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/14/fact-check-old-picture-of-vandalised-statue-of-martyr-shared-with-a-misleading-claim/",
        headline:
          "Fact Check: Old picture of vandalised statue of martyr shared with a misleading claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/15/fact-check-old-video-of-students-showing-black-flags-at-yogi-adityanaths-convoy-being-circulated-with-false-claims/",
        headline:
          "Fact Check: Old video of students showing black flags at Yogi Adityanath\u2019s convoy being circulated with false claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/15/fact-check-indian-railways-has-not-been-renamed-as-adani-railways-heres-the-truth/",
        headline:
          "Fact Check: Indian Railways has not been renamed as Adani Railways; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/15/fact-check-old-video-of-failed-parachute-landing-is-not-of-pakistani-paratrooper/",
        headline:
          "Fact Check: Old video of failed parachute landing is NOT of Pakistani paratrooper",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/16/fact-check-viral-video-of-crocodile-in-flooded-street-is-not-from-pakistan/",
        headline:
          "Fact Check: Viral video of crocodile in flooded street is NOT from Pakistan",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/16/fact-check-picture-of-soldiers-holding-tricolour-at-mount-kailash-is-morphed/",
        headline:
          "Fact Check: Picture of soldiers holding Tricolour at Mount Kailash is morphed",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/17/fact-check-have-indian-deposits-in-swiss-banks-gone-up-in-the-past-one-year-heres-the-truth/",
        headline:
          "Fact Check: Have Indian deposits in Swiss Banks gone up in the past one year? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/17/fact-check-viral-video-of-failed-missile-launch-wrongly-attributed-to-china/",
        headline:
          "Fact Check: Viral video of failed missile launch wrongly attributed to China",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/18/fact-check-iuml-flag-on-kerala-mlas-car-falsely-passed-off-as-pakistani-flag/",
        headline:
          "Fact Check: IUML flag on Kerala MLA\u2019s car falsely passed off as Pakistani flag",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-video-of-student-protesters-blocking-convoy-of-up-cm-yogi-adityanath-shared-as-recent-9838",
        headline:
          "\u09ae\u09c1\u0996\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u0995\u09a8\u09ad\u09df \u0986\u099f\u0995\u09be\u09a8\u09cb\u09b0 \u09e8\u09e6\u09e7\u09ed'\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-the-image-of-narendra-modi-sweeping-the-floor-is-a-photoshopped-one/",
        headline:
          " \u0c28\u0c30\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c4b\u0c26\u0c40 \u0c0a\u0c21\u0c41\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c37\u0c3e\u0c2a\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-justin-trudeau-did-not-back-tamil-nadu-s-anti-hindi-stir-1721501-2020-09-14",
        headline:
          "Fact Check: Justin Trudeau did not back Tamil Nadu\u2019s anti-Hindi stir",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/why-rakhi-sawant-was-draped-in-the-pakistani-flag-1723715-2020-09-20",
        headline:
          "Fact Check: Why Rakhi Sawant was draped in the Pakistani flag\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-dead-monk-smiling-even-after-a-hundred-years-no-here-is-the-truth-about-the-viral-pic-1723379-2020-09-19",
        headline:
          "Fact Check: Dead monk smiling even after a hundred years? No, here is the truth about the viral pic",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/19/fact-check-dont-fall-for-fake-autopsy-report-of-actor-sridevi-which-has-gone-viral/",
        headline:
          "Fact Check: Don\u2019t fall for fake autopsy report of actor Sridevi which has gone viral",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-dont-fall-for-this-morphed-picture-of-aap-mp-bhagwant-mann-having-liquor-in-office/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed picture of AAP MP Bhagwant Mann having liquor in office",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-is-big-b-with-underworld-don-dawood-ibrahim-in-this-viral-picture-find-out-the-truth/",
        headline:
          "Fact Check: Is Big B with underworld don Dawood Ibrahim in this viral picture? Find out the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-rakhi-sawants-2019-film-shoot-picture-with-pak-flag-resurfaces-with-misleading-claims/",
        headline:
          "Fact Check: Rakhi Sawant\u2019s 2019 film shoot picture with Pak flag resurfaces with misleading claims",
      },
    ],
  },
  numpy_seed: 1,
  number_of_articles: 158,
}
