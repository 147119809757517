export const data = {
  mdsDat: {
    x: [
      -0.01359675594824136,
      -0.33725757340860296,
      -0.10055026263396093,
      0.15009336784345273,
      0.3013112241473526,
    ],
    y: [
      -0.18298313616054243,
      -0.15983606326040062,
      0.34092777773719685,
      0.15000496257558,
      -0.14811354089183357,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      15.222182498781939,
      19.332807805104306,
      18.333312019577093,
      24.667079419435257,
      22.4446182571014,
    ],
  },
  tinfo: {
    Term: [
      "ranaut",
      "kangana",
      "andhra",
      "sena",
      "andhra_pradesh",
      "kerala",
      "girl",
      "china",
      "officer",
      "pradesh",
      "riot",
      "hc",
      "government",
      "vice",
      "biryani",
      "vandalize",
      "imf",
      "pubji",
      "seem",
      "trinidad",
      "patra",
      "kasab",
      "take",
      "tv",
      "sambit",
      "sambit_patra",
      "game",
      "sweden",
      "affect",
      "repay",
      "ban",
      "mobile",
      "govt",
      "singh",
      "fed",
      "economy",
      "scholarship",
      "ivermectin",
      "gland",
      "body",
      "shoot",
      "protest",
      "corporation",
      "inauthentic",
      "child",
      "proof",
      "writing",
      "rafale",
      "vaccine",
      "jet",
      "vaccine_trial",
      "rajasthan",
      "issue",
      "icmr",
      "crash",
      "railway",
      "trial",
      "covid",
      "abu",
      "landing",
      "female",
      "dhabi",
      "service",
      "ladakh",
      "hijab",
      "cab",
      "shah",
      "launch",
      "officer",
      "china",
      "ips",
      "exercise",
      "three_ips",
      "chakraborty",
      "batch",
      "sibling",
      "military",
      "riya",
      "rhea",
      "fall",
      "seek",
      "morphed",
      "show",
      "pictures",
      "film",
      "cop",
      "mother",
      "studio",
      "right",
      "madhya",
      "kangana_ranaut",
      "pay",
      "advani",
      "ranaut",
      "sena",
      "go",
      "karni",
      "karni_sena",
      "mosque",
      "nita",
      "support",
      "kangana",
      "nita_ambani",
      "mumbai",
      "dislikes",
      "blue",
      "rss",
      "brutal",
      "kerala",
      "beat",
      "ki",
      "woman",
      "ease",
      "card",
      "andhra",
      "girl",
      "andhra_pradesh",
      "communal",
      "brazil",
      "molestation",
      "incident",
      "man",
      "pradesh",
      "year",
    ],
    Freq: [
      13.0,
      15.0,
      8.0,
      8.0,
      7.0,
      7.0,
      7.0,
      5.0,
      5.0,
      8.0,
      0.99999739043093,
      0.99999739043093,
      1.99999478086186,
      0.99999739043093,
      3.99998956172372,
      0.99999739043093,
      0.99999739043093,
      0.99999739043093,
      0.99999739043093,
      0.99999739043093,
      2.9999921712927904,
      2.9999921712927904,
      2.9999921712927904,
      2.9999921712927904,
      2.9999921712927904,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      2.9999921712927904,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      3.99989127002158,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      4.9998640875269755,
      3.99989127002158,
      3.99989127002158,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      5.99983690503237,
      0.9999988374314775,
      0.9999988374314775,
      0.9999988374314775,
      0.9999988374314775,
      1.999997674862955,
      2.9999965122944325,
      0.9999988374314775,
      2.9999965122944325,
      0.9999988374314775,
      0.9999988374314775,
      5.999993024588865,
      5.999993024588865,
      4.999994187157388,
      3.99999534972591,
      3.99999534972591,
      3.99999534972591,
      1.999997674862955,
      1.999997674862955,
      1.999997674862955,
      1.999997674862955,
      1.999997674862955,
      4.999994187157388,
      1.999997674862955,
      2.9999965122944325,
      2.9999965122944325,
      1.0000167332203482,
      1.0000167332203482,
      2.0000334664406965,
      2.0000334664406965,
      4.000066932881393,
      2.0000334664406965,
      1.0000167332203482,
      6.00010039932209,
      1.0000167332203482,
      1.0000167332203482,
      13.000217531864527,
      8.000133865762786,
      6.00010039932209,
      5.000083666101741,
      5.000083666101741,
      4.000066932881393,
      3.000050199661045,
      3.000050199661045,
      14.000234265084874,
      3.000050199661045,
      4.000066932881393,
      1.0000077441282802,
      1.0000077441282802,
      2.0000154882565604,
      1.0000077441282802,
      7.000054208897962,
      2.0000154882565604,
      1.0000077441282802,
      5.000038720641401,
      1.0000077441282802,
      1.0000077441282802,
      8.000061953026242,
      7.000054208897962,
      7.000054208897962,
      4.000030976513121,
      4.000030976513121,
      4.000030976513121,
      3.0000232323848404,
      3.0000232323848404,
      7.000054208897962,
      3.0000232323848404,
    ],
    Total: [
      13.0,
      15.0,
      8.0,
      8.0,
      7.0,
      7.0,
      7.0,
      5.0,
      5.0,
      8.0,
      0.99999739043093,
      0.99999739043093,
      1.99999478086186,
      0.99999739043093,
      3.99998956172372,
      0.99999739043093,
      0.99999739043093,
      0.99999739043093,
      0.99999739043093,
      0.99999739043093,
      2.9999921712927904,
      2.9999921712927904,
      2.9999921712927904,
      2.9999921712927904,
      2.9999921712927904,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      3.9999649887981854,
      1.99999478086186,
      1.99999478086186,
      1.99999478086186,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      3.99989127002158,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      0.999972817505395,
      4.9998640875269755,
      3.99989127002158,
      3.99989127002158,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      2.999918452516185,
      6.999853638252718,
      0.9999988374314775,
      0.9999988374314775,
      0.9999988374314775,
      0.9999988374314775,
      1.999997674862955,
      2.9999965122944325,
      0.9999988374314775,
      2.9999965122944325,
      0.9999988374314775,
      0.9999988374314775,
      5.999993024588865,
      5.999993024588865,
      4.999994187157388,
      3.99999534972591,
      3.99999534972591,
      3.99999534972591,
      1.999997674862955,
      1.999997674862955,
      1.999997674862955,
      1.999997674862955,
      1.999997674862955,
      6.999983737883132,
      1.999997674862955,
      3.999993902725363,
      4.000004256422713,
      1.0000167332203482,
      1.0000167332203482,
      2.0000334664406965,
      2.0000334664406965,
      4.000066932881393,
      2.0000334664406965,
      1.0000167332203482,
      6.00010039932209,
      1.0000167332203482,
      1.0000167332203482,
      13.000217531864527,
      8.000133865762786,
      6.00010039932209,
      5.000083666101741,
      5.000083666101741,
      4.000066932881393,
      3.000050199661045,
      3.000050199661045,
      15.00023310251635,
      3.000050199661045,
      5.000074677009673,
      1.0000077441282802,
      1.0000077441282802,
      2.0000154882565604,
      1.0000077441282802,
      7.000054208897962,
      2.0000154882565604,
      1.0000077441282802,
      5.000038720641401,
      1.0000077441282802,
      1.0000077441282802,
      8.000061953026242,
      7.000054208897962,
      7.000054208897962,
      4.000030976513121,
      4.000030976513121,
      4.000030976513121,
      3.0000232323848404,
      3.0000232323848404,
      8.00007094211831,
      5.0000180132467005,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.92,
      -4.92,
      -4.2268,
      -4.92,
      -3.5337,
      -4.92,
      -4.92,
      -4.92,
      -4.92,
      -4.92,
      -3.8214,
      -3.8214,
      -3.8214,
      -3.8214,
      -3.8214,
      -4.2268,
      -4.2268,
      -4.2268,
      -4.2268,
      -4.2268,
      -4.2268,
      -4.2268,
      -4.2268,
      -3.8214,
      -4.2268,
      -4.2268,
      -4.2268,
      -5.1591,
      -5.1591,
      -5.1591,
      -5.1591,
      -3.7728,
      -5.1591,
      -5.1591,
      -5.1591,
      -5.1591,
      -5.1591,
      -3.5496,
      -3.7728,
      -3.7728,
      -4.0604,
      -4.0604,
      -4.0604,
      -4.0604,
      -4.0604,
      -4.0604,
      -4.0604,
      -3.3673,
      -5.1059,
      -5.1059,
      -5.1059,
      -5.1059,
      -4.4128,
      -4.0073,
      -5.1059,
      -4.0073,
      -5.1059,
      -5.1059,
      -3.3142,
      -3.3142,
      -3.4965,
      -3.7197,
      -3.7197,
      -3.7197,
      -4.4128,
      -4.4128,
      -4.4128,
      -4.4128,
      -4.4128,
      -3.4965,
      -4.4128,
      -4.0073,
      -4.0073,
      -5.4027,
      -5.4027,
      -4.7095,
      -4.7095,
      -4.0164,
      -4.7095,
      -5.4027,
      -3.6109,
      -5.4027,
      -5.4027,
      -2.8377,
      -3.3232,
      -3.6109,
      -3.7932,
      -3.7932,
      -4.0164,
      -4.3041,
      -4.3041,
      -2.7636,
      -4.3041,
      -4.0164,
      -5.3083,
      -5.3083,
      -4.6151,
      -5.3083,
      -3.3624,
      -4.6151,
      -5.3083,
      -3.6988,
      -5.3083,
      -5.3083,
      -3.2288,
      -3.3624,
      -3.3624,
      -3.922,
      -3.922,
      -3.922,
      -4.2097,
      -4.2097,
      -3.3624,
      -4.2097,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.8824,
      1.5947,
      1.8824,
      1.8824,
      1.8824,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.6434,
      1.4892,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.6965,
      1.36,
      1.6965,
      1.4088,
      1.4088,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3997,
      1.3307,
      1.3997,
      1.1766,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.4941,
      1.3606,
      0.9833,
    ],
  },
  "token.table": {
    Topic: [
      3,
      4,
      1,
      5,
      5,
      1,
      3,
      5,
      1,
      5,
      2,
      5,
      5,
      3,
      5,
      3,
      2,
      3,
      5,
      4,
      2,
      2,
      4,
      2,
      3,
      5,
      5,
      1,
      3,
      2,
      3,
      4,
      1,
      3,
      4,
      1,
      5,
      2,
      4,
      1,
      1,
      1,
      3,
      2,
      1,
      2,
      5,
      3,
      2,
      2,
      2,
      3,
      4,
      4,
      4,
      4,
      1,
      5,
      5,
      3,
      3,
      3,
      4,
      5,
      3,
      1,
      5,
      1,
      3,
      4,
      4,
      4,
      5,
      4,
      4,
      3,
      1,
      4,
      4,
      4,
      5,
      2,
      2,
      1,
      2,
      2,
      2,
      4,
      1,
      3,
      4,
      1,
      3,
      5,
      1,
      1,
      1,
      3,
      1,
      4,
      3,
      3,
      2,
      3,
      5,
      3,
      1,
      2,
      4,
      4,
      1,
      1,
      3,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      5,
      2,
      1,
      5,
    ],
    Freq: [
      1.000001162569874,
      0.9999832670596478,
      1.0000026095758798,
      0.9999922559316908,
      0.9999922559316908,
      1.0000026095758798,
      1.000001162569874,
      0.9999922559316908,
      1.0000026095758798,
      0.9999922559316908,
      1.000027183233513,
      0.9999922559316908,
      0.9999922559316908,
      1.0000011625698741,
      0.9999922559316908,
      1.000001162569874,
      1.000027183233513,
      1.0000011625698741,
      0.9999922559316908,
      0.9999832670596478,
      1.000027183233513,
      0.8571607793641957,
      0.1428601298940326,
      1.000027183233513,
      1.000001162569874,
      0.9999922559316908,
      0.9999922559316908,
      1.0000026095758798,
      1.000001162569874,
      0.14285747473784996,
      0.7142873736892498,
      0.14285747473784996,
      1.0000026095758798,
      1.000001162569874,
      0.9999832670596478,
      1.0000026095758798,
      0.9999922559316908,
      1.000027183233513,
      0.9999832670596477,
      1.0000026095758798,
      1.0000026095758798,
      1.0000026095758798,
      1.000001162569874,
      1.000027183233513,
      1.0000026095758798,
      1.000027183233513,
      0.9999922559316909,
      1.000001162569874,
      1.000027183233513,
      1.000027183233513,
      1.000027183233513,
      0.06666563067158242,
      0.9333188294021539,
      0.9999832670596477,
      0.9999832670596478,
      0.9999832670596478,
      1.0000026095758798,
      0.9999922559316908,
      0.9999922559316908,
      1.0000011625698741,
      1.000001162569874,
      1.000001162569874,
      0.9999832670596478,
      0.9999922559316909,
      1.000001162569874,
      1.0000026095758798,
      0.9999922559316908,
      0.2500003810802457,
      0.7500011432407372,
      0.9999832670596478,
      0.9999832670596478,
      0.7999880518569027,
      0.19999701296422567,
      0.9999832670596477,
      0.9999832670596477,
      1.0000011625698741,
      1.0000026095758798,
      0.9999832670596478,
      0.9999832670596478,
      0.12499889153923098,
      0.8749922407746169,
      1.000027183233513,
      1.000027183233513,
      1.0000026095758798,
      1.000027183233513,
      1.000027183233513,
      1.000027183233513,
      0.9999832670596478,
      1.0000026095758798,
      1.000001162569874,
      0.9999832670596478,
      1.0000026095758798,
      1.000001162569874,
      0.9999922559316908,
      1.0000026095758798,
      1.0000026095758798,
      1.0000026095758798,
      1.000001162569874,
      1.0000026095758798,
      0.9999832670596478,
      1.000001162569874,
      1.000001162569874,
      1.000027183233513,
      0.7499992019215905,
      0.24999973397386352,
      1.000001162569874,
      0.7500065646577994,
      0.25000218821926645,
      0.9999832670596478,
      0.9999832670596477,
      1.0000026095758798,
      1.0000026095758798,
      1.000001162569874,
      1.000027183233513,
      1.0000026095758798,
      1.0000026095758798,
      1.000027183233513,
      1.000027183233513,
      1.0000026095758798,
      1.0000026095758798,
      0.9999922559316909,
      1.000027183233513,
      0.39999855894545555,
      0.5999978384181833,
    ],
    Term: [
      "abu",
      "advani",
      "affect",
      "andhra",
      "andhra_pradesh",
      "ban",
      "batch",
      "beat",
      "biryani",
      "blue",
      "body",
      "brazil",
      "brutal",
      "cab",
      "card",
      "chakraborty",
      "child",
      "china",
      "communal",
      "cop",
      "corporation",
      "covid",
      "covid",
      "crash",
      "dhabi",
      "dislikes",
      "ease",
      "economy",
      "exercise",
      "fall",
      "fall",
      "fall",
      "fed",
      "female",
      "film",
      "game",
      "girl",
      "gland",
      "go",
      "government",
      "govt",
      "hc",
      "hijab",
      "icmr",
      "imf",
      "inauthentic",
      "incident",
      "ips",
      "issue",
      "ivermectin",
      "jet",
      "kangana",
      "kangana",
      "kangana_ranaut",
      "karni",
      "karni_sena",
      "kasab",
      "kerala",
      "ki",
      "ladakh",
      "landing",
      "launch",
      "madhya",
      "man",
      "military",
      "mobile",
      "molestation",
      "morphed",
      "morphed",
      "mosque",
      "mother",
      "mumbai",
      "mumbai",
      "nita",
      "nita_ambani",
      "officer",
      "patra",
      "pay",
      "pictures",
      "pradesh",
      "pradesh",
      "proof",
      "protest",
      "pubji",
      "rafale",
      "railway",
      "rajasthan",
      "ranaut",
      "repay",
      "rhea",
      "right",
      "riot",
      "riya",
      "rss",
      "sambit",
      "sambit_patra",
      "scholarship",
      "seek",
      "seem",
      "sena",
      "service",
      "shah",
      "shoot",
      "show",
      "show",
      "sibling",
      "singh",
      "singh",
      "studio",
      "support",
      "sweden",
      "take",
      "three_ips",
      "trial",
      "trinidad",
      "tv",
      "vaccine",
      "vaccine_trial",
      "vandalize",
      "vice",
      "woman",
      "writing",
      "year",
      "year",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/morphed-abp-news-graphic-claims-up-govt-will-not-provide-scholarships-to-students-this-year/",
        headline:
          "Morphed ABP News graphic claims UP govt will not provide scholarships to students this year",
      },
      {
        url:
          "https://www.altnews.in/bjps-sambit-patra-revives-false-debunked-claim-of-biryani-fed-to-kasab-on-national-tv/",
        headline:
          "BJP\u2019s Sambit Patra revives false, debunked claim of \u2018biryani fed to Kasab\u2019 on national TV",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-vandalism-video-of-footballs-in-switzerland-revived-as-muslims-rioting-in-sweden/",
        headline:
          "\u0938\u094d\u0935\u093f\u091f\u094d\u095b\u0930\u0932\u0948\u0902\u0921 \u092e\u0947\u0902 \u092b\u093c\u0941\u091f\u092c\u0949\u0932 \u092b\u093c\u0948\u0928\u094d\u0938 \u0915\u0947 \u0924\u094b\u095c-\u092b\u094b\u095c \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u094d\u0935\u0940\u0921\u0928 \u092e\u0947\u0902 \u092e\u0941\u0938\u094d\u0932\u093f\u092e\u094b\u0902 \u0926\u0902\u0917\u093e\u0907\u092f\u094b\u0902 \u0915\u093e \u092c\u0924\u093e\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/morphed-abp-news-graphic-claims-up-govt-will-not-provide-scholarships-to-students-this-year/",
        headline:
          "APB \u0928\u094d\u092f\u0942\u095b \u0915\u093e \u095e\u0930\u094d\u095b\u0940 \u0917\u094d\u0930\u093e\u095e\u093f\u0915 \u0935\u093e\u092f\u0930\u0932, \u0926\u093e\u0935\u093e \u0915\u093f\u092f\u093e \u0915\u093f UP \u0938\u0930\u0915\u093e\u0930 \u0907\u0938 \u0938\u093e\u0932 \u0928\u0939\u0940\u0902 \u0926\u0947\u0917\u0940 \u0938\u094d\u0915\u093e\u0945\u0932\u0930\u0936\u093f\u092a",
      },
      {
        url:
          "https://www.altnews.in/hindi/bjps-sambit-patra-revives-false-debunked-claim-of-biryani-fed-to-kasab-on-national-tv/",
        headline:
          "\u0938\u0902\u092c\u093f\u0924 \u092a\u093e\u0924\u094d\u0930\u093e \u0928\u0947 \u0915\u093f\u092f\u093e \u0915\u0938\u093e\u092c \u0915\u094b \u092c\u093f\u0930\u092f\u093e\u0928\u0940 \u0926\u093f\u090f \u091c\u093e\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e, \u0938\u0930\u0915\u093e\u0930\u0940 \u0935\u0915\u0940\u0932 \u0907\u0938\u0915\u093e \u0938\u091a \u092c\u0924\u093e \u091a\u0941\u0915\u0947 \u0939\u0948\u0902",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-amartya-sen-did-not-say-pubg-ban-to-affect-indias-economy-9658",
        headline:
          "No, Amartya Sen Did Not Say PUBG Ban To Affect India's Economy",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/did-sushant-singh-rajput-conceptualise-upcoming-mobile-game-fau-g-9666",
        headline:
          "Did Sushant Singh Rajput Conceptualise Upcoming Mobile Game Fau-G?",
      },
      {
        url:
          "https://www.boomlive.in/law/why-tv-media-not-regulated-nba-is-toothless-bombay-hc-to-govt-9682",
        headline:
          "Why TV Media Not Regulated? NBA Is Toothless: Bombay HC To Govt",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/sambit-patra-falsely-claims-terrorist-ajmal-kasab-was-fed-biryani-in-jail-9704",
        headline:
          "Sambit Patra Falsely Claims Terrorist Ajmal Kasab Was Fed Biryani In Jail",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-amartya-sen-did-not-say-pubg-ban-to-affect-indias-economy-9659",
        headline:
          "\u09a8\u09be, \u0985\u09ae\u09b0\u09cd\u09a4\u09cd\u09af \u09b8\u09c7\u09a8 \u09ac\u09b2\u09c7\u09a8\u09a8\u09bf \u09aa\u09be\u09ac\u099c\u09bf \u09ac\u09cd\u09af\u09be\u09a8 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u0985\u09b0\u09cd\u09a5\u09a8\u09c0\u09a4\u09bf\u09a4\u09c7 \u09aa\u09cd\u09b0\u09ad\u09be\u09ac \u09ab\u09c7\u09b2\u09ac\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/did-sushant-singh-rajput-conceptualise-upcoming-mobile-game-fau-g-9698",
        headline:
          "\u09ab\u09cc-\u099c\u09bf \u09a8\u09be\u09ae\u09c7\u09b0 \u09ae\u09cb\u09ac\u09be\u0987\u09b2 \u0997\u09c7\u09ae \u09b8\u09a4\u09cd\u09af\u09bf\u0987 \u0995\u09bf \u09b8\u09c1\u09b6\u09be\u09a8\u09cd\u09a4 \u09b8\u09bf\u0982\u09b9 \u09b0\u09be\u099c\u09aa\u09c1\u09a4\u09c7\u09b0 \u09ae\u09b8\u09cd\u09a4\u09bf\u09b7\u09cd\u0995\u09aa\u09cd\u09b0\u09b8\u09c2\u09a4?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/sambit-patra-falsely-claims-terrorist-ajmal-kasab-was-fed-biryani-in-jail-9723",
        headline:
          "\u09b8\u09ae\u09cd\u09ac\u09bf\u09a4 \u09aa\u09be\u09a4\u09cd\u09b0\u09b0 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u099c\u09c7\u09b2\u09c7\u09b0 \u09ae\u09a7\u09cd\u09af\u09c7 \u099c\u0999\u09cd\u0997\u09bf \u0986\u099c\u09ae\u09b2 \u0995\u09be\u09b8\u09ad \u09ac\u09bf\u09b0\u09bf\u09df\u09be\u09a8\u09bf \u0996\u09c7\u09a4",
      },
      {
        url:
          "https://factly.in/telugu-imf-loans-were-repaid-by-the-year-2000-not-during-manmohan-singhs-prime-ministership/",
        headline:
          " 1991 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c02\u0c32\u0c4b IMF \u0c35\u0c26\u0c4d\u0c26 \u0c24\u0c40\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c41\u0c23\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c2e\u0c28\u0c4d\u0c2e\u0c4b\u0c39\u0c28\u0c4d \u0c38\u0c3f\u0c02\u0c17\u0c4d \u0c24\u0c40\u0c30\u0c4d\u0c1a\u0c32\u0c47\u0c26\u0c41, \u0c06 \u0c30\u0c41\u0c23\u0c3e\u0c32\u0c28\u0c4d\u0c28\u0c40 2000\u0c35 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c02 \u0c15\u0c32\u0c4d\u0c32\u0c3e \u0c24\u0c40\u0c30\u0c4d\u0c1a\u0c2c\u0c21\u0c4d\u0c21\u0c3e\u0c2f\u0c3f.",
      },
      {
        url:
          "https://factly.in/telugu-these-images-show-trinidad-tobago-members-of-parliament-taking-oath-on-the-bhagavadgita/",
        headline:
          " \u0c15\u0c4a\u0c02\u0c26\u0c30\u0c41 \u0c1f\u0c4d\u0c30\u0c3f\u0c28\u0c3f\u0c21\u0c3e\u0c21\u0c4d & \u0c1f\u0c4a\u0c2c\u0c3e\u0c17\u0c4b \u0c2a\u0c3e\u0c30\u0c4d\u0c32\u0c2e\u0c46\u0c02\u0c1f\u0c4d \u0c38\u0c2d\u0c4d\u0c2f\u0c41\u0c32\u0c41 \u0c28\u0c3f\u0c1c\u0c02\u0c17\u0c3e\u0c28\u0c47 \u0c2d\u0c17\u0c35\u0c26\u0c4d\u0c17\u0c40\u0c24 \u0c2a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c4a\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c23 \u0c38\u0c4d\u0c35\u0c40\u0c15\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-old-video-of-cars-being-vandalized-in-switzerland-falsely-linked-to-the-recent-sweden-riots/",
        headline:
          "Fact Check: Old video of cars being vandalized in Switzerland falsely linked to the recent Sweden riots",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-did-former-us-vice-president-joe-biden-sleep-during-a-tv-interview-heres-the-truth/",
        headline:
          "Fact Check: Did former US Vice President Joe Biden sleep during a TV interview? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/10/fact-check-was-this-picture-taken-during-t-series-founder-gulshan-kumars-assassination-heres-the-truth/",
        headline:
          "Fact Check: Was this picture taken during T-series founder Gulshan Kumar\u2019s assassination? Here\u2019s the truth",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-kidnapping-drama-is-not-what-it-seems-1719595-2020-09-07",
        headline: "Fact Check: This kidnapping drama is not what it seems",
      },
    ],
    "2": [
      {
        url:
          "https://www.altnews.in/old-video-of-piyush-goyal-facing-protests-by-railway-employees-shared-as-recent/",
        headline:
          "Old video of Piyush Goyal facing protests by railway employees shared as recent",
      },
      {
        url:
          "https://www.altnews.in/photo-of-10-year-girl-marry-with-40-year-old-man-from-pakistan-shared-with-false-claim-in-india/",
        headline:
          "Photo of child marriage in Pakistan shared as India to target Brahmin community",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-of-piyush-goyal-facing-protests-by-railway-employees-shared-as-recent/",
        headline:
          "\u092a\u0940\u092f\u0942\u0937 \u0917\u094b\u092f\u0932 \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u0930\u0947\u0932\u0935\u0947 \u0915\u0930\u094d\u092e\u091a\u093e\u0930\u093f\u092f\u094b\u0902 \u0915\u0947 2018 \u0915\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0939\u093e\u0932 \u0915\u093e \u092c\u0924\u093e\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/cropped-video-of-a-multi-faith-ceremony-for-rafale-jets-is-misleading-9689",
        headline:
          "Cropped Video Of A Multi-Faith Ceremony For Rafale Jets Is Misleading",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/cropped-image-of-asansol-municipality-signboard-viral-with-false-claims-9693",
        headline:
          "Cropped Image Of Asansol Municipality Signboard Viral With False Claims",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/aaj-tak-times-now-air-video-of-1962-war-memorial-as-proof-of-galwan-9645",
        headline:
          "\u0906\u091c \u0924\u0915, \u091f\u093e\u0907\u092e\u094d\u0938 \u0928\u093e\u0909 \u0928\u0947 1962 \u0935\u0949\u0930 \u092e\u0947\u092e\u094b\u0930\u093f\u092f\u0932 \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b '\u0917\u0932\u0935\u093e\u0928 \u0915\u093e \u0938\u092c\u0942\u0924' \u092c\u0924\u093e\u0915\u0930 \u092a\u094d\u0930\u0938\u093e\u0930\u093f\u0924 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/cropped-image-of-asansol-municipality-signboard-viral-with-false-claims-9717",
        headline:
          "\u0986\u09b8\u09be\u09a8\u09b8\u09cb\u09b2 \u09aa\u09cc\u09b0\u09a8\u09bf\u0997\u09ae\u09c7\u09b0 \u09b8\u09be\u0987\u09a8\u09ac\u09cb\u09b0\u09cd\u09a1\u09b0 \u0995\u09be\u099f\u099b\u09be\u0981\u099f \u0995\u09b0\u09be \u099b\u09ac\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/photos-of-a-hyper-realistic-sculpture-falsely-shared-as-dangerous-animal-roaming-in-gujarat-rajasthan/",
        headline:
          " Photos of a hyper-realistic silicon sculpture falsely shared as dangerous animal roaming in Gujarat-Rajasthan.",
      },
      {
        url:
          "https://factly.in/these-images-are-not-of-protests-against-coronavirus-vaccine-trials-in-different-parts-of-the-world/",
        headline:
          " These images are not of protests against COVID-19 vaccine trials in different parts of the world.",
      },
      {
        url:
          "https://factly.in/using-the-infrared-thermometer-on-the-forehead-will-not-harm-the-organs-in-the-head/",
        headline:
          " Using the infrared thermometer on the forehead will not harm the glands in the head.",
      },
      {
        url:
          "https://factly.in/telugu-astrazeneca-university-of-oxford-vaccine-did-not-fail-vaccine-trials-are-put-on-hold/",
        headline:
          " \u0c06\u0c38\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c1c\u0c46\u0c28\u0c46\u0c15\u0c3e \u2013 \u0c06\u0c15\u0c4d\u0c38\u0c4d\u200c\u0c2b\u0c30\u0c4d\u0c21\u0c4d \u0c2f\u0c42\u0c28\u0c3f\u0c35\u0c30\u0c4d\u0c36\u0c3f\u0c1f\u0c40 \u0c15\u0c32\u0c3f\u0c38\u0c3f \u0c05\u0c2d\u0c3f\u0c35\u0c43\u0c26\u0c4d\u0c27\u0c3f \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c35\u0c4d\u0c2f\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d\u200c \u0c2b\u0c46\u0c2f\u0c3f\u0c32\u0c4d \u0c05\u0c35\u0c4d\u0c35\u0c32\u0c47\u0c26\u0c41, \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d \u0c1f\u0c4d\u0c30\u0c2f\u0c32\u0c4d\u0c38\u0c4d \u0c15\u0c3f \u0c38\u0c4d\u0c35\u0c32\u0c4d\u0c2a \u0c35\u0c3f\u0c30\u0c3e\u0c2e\u0c02 \u0c07\u0c1a\u0c4d\u0c1a\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/this-photo-of-rajnath-singh-writing-om-and-swastik-on-rafale-fighter-jet-is-an-edited-one/",
        headline:
          " This photo of Rajnath Singh writing \u2018Om\u2019 and \u2018Swastik\u2019 on Rafale fighter jet is an edited one",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/why-claim-adani-run-trains-off-track-indian-railways-1719431-2020-09-07",
        headline:
          "Fact Check: Why this claim on Adani-run trains is off the track",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-did-taiwan-shoot-down-a-chinese-su-35-fighter-jet-heres-the-truth/",
        headline:
          "Fact Check: Did Taiwan shoot down a Chinese SU-35 fighter jet? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-indian-railways-is-not-being-fully-privatized-heres-the-truth/",
        headline:
          "Fact Check: Indian Railways is not being fully privatized; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/08/fact-check-a-1945-letter-of-jawaharlal-nehru-addressing-sc-bose-as-a-war-criminal-is-inauthentic/",
        headline:
          "Fact Check: A 1945 letter of Jawaharlal Nehru addressing SC Bose as a \u2018war criminal\u2019 is inauthentic",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/08/fact-check-viral-post-about-india-losing-a-rafale-jet-in-a-crash-is-fake/",
        headline:
          "Fact Check: Viral post about India losing a Rafale jet in a crash is fake",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/08/fact-check-was-this-strange-alien-creature-spotted-in-rajasthan-heres-the-truth/",
        headline:
          "Fact Check: Was this strange \u2018alien\u2019 creature spotted in Rajasthan? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/09/fact-check-dont-fall-for-these-pictures-of-people-protesting-against-covid-19-vaccine-trials/",
        headline:
          "Fact Check: Don\u2019t fall for these pictures of people protesting against COVID-19 vaccine trials",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/09/fact-check-old-who-guidelines-on-masks-being-shared-as-recent-even-as-multiple-advisories-have-been-issued-post-this/",
        headline:
          "Fact Check: Old WHO guidelines on masks being shared as recent even as multiple advisories have been issued post this",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/09/fact-check-has-icmr-issued-these-covid-19-guidelines-dont-believe-this-fake-forward/",
        headline:
          "Fact Check: Has ICMR issued these COVID-19 guidelines? Don\u2019t believe this fake forward",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/viral-image-rally-rajasthan-recent-haryana-protest-1721187-2020-09-12",
        headline:
          "Fact Check: Image from 2017 rally in Rajasthan shared as recent Haryana protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/who-blocked-uttar-pradesh-yogi-adityanath-convoy-1721403-2020-09-13",
        headline: "Fact Check: Who blocked Yogi Adityanath's convoy?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-icmr-hasn-t-prescribed-this-drug-to-prevent-covid-19-1720939-2020-09-11",
        headline:
          "Fact Check: No, ICMR hasn\u2019t prescribed this drug to prevent Covid-19",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/12/fact-check-icmr-has-not-issued-any-fresh-prescription-on-use-of-ivermectin-for-covid-19-treatment/",
        headline:
          "Fact Check: ICMR has NOT issued any fresh prescription on use of Ivermectin for COVID-19 treatment",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/12/fact-check-as-india-inducts-rafale-jets-old-pictures-of-air-crash-falsely-shared-as-rafale-crash/",
        headline:
          "Fact Check: As India inducts Rafale jets, old pictures of air crash falsely shared as Rafale crash",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/did-top-us-health-body-replace-pandemic-with-outbreak-for-covid19-1721485-2020-09-13",
        headline:
          "Fact Check: Did top US health body replace \u2018pandemic\u2019 with \u2018outbreak\u2019 for Covid-19?\u00a0",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/morphed-news-graphics-falsely-linked-with-abp-news-rhea-chakraborty-palak-paneer-fake/",
        headline:
          "Morphed ABP News screengrab on Rhea Chakraborty \u2018eating palak paneer\u2019 surfaces online",
      },
      {
        url:
          "https://www.altnews.in/abp-news-hindustan-times-toi-fall-for-a-fake-twitter-handle-impersonating-rheas-father-indrajit-chakraborty/",
        headline:
          "ABP News, TOI fall for fake Twitter account impersonating Rhea Chakraborty\u2019s father",
      },
      {
        url:
          "https://www.altnews.in/hindi/morphed-news-graphics-falsely-linked-with-abp-news-rhea-chakraborty-palak-paneer-fake/",
        headline:
          "IAS \u0905\u095e\u0938\u0930, \u091c\u0930\u094d\u0928\u0932\u093f\u0938\u094d\u091f \u0928\u0947 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e \u0930\u093f\u092f\u093e \u091a\u0915\u094d\u0930\u0935\u0930\u094d\u0924\u0940 \u0938\u0947 \u091c\u0941\u095c\u093e \u095e\u0930\u094d\u095b\u0940 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f, \u092c\u093e\u0926 \u092e\u0947\u0902 \u0926\u0940 \u0938\u095e\u093e\u0908",
      },
      {
        url:
          "https://www.altnews.in/hindi/abp-news-hindustan-times-toi-fall-for-a-fake-twitter-handle-impersonating-rheas-father-indrajit-chakraborty/",
        headline:
          "ABP \u0928\u094d\u092f\u0942\u095b, TOI, HT \u0928\u0947 \u0930\u093f\u092f\u093e \u091a\u0915\u094d\u0930\u0935\u0930\u094d\u0924\u0940 \u0915\u0947 \u092a\u093f\u0924\u093e \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u092c\u0928\u0947 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948\u0902\u0921\u0932 \u0915\u0947 \u091f\u094d\u0935\u0940\u091f\u094d\u0938 \u0926\u093f\u0916\u093e\u090f",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/image-of-three-ips-officers-sitting-together-viral-as-siblings-9687",
        headline:
          '\u0924\u0940\u0928 \u0906\u0908\u092a\u0940\u090f\u0938 \u0905\u095e\u0938\u0930\u094b\u0902 \u0915\u0940 \u090f\u0915 \u0938\u093e\u0925 \u092c\u0948\u0920\u0947 \u0924\u0938\u094d\u0935\u0940\u0930 "\u092d\u093e\u0908-\u092c\u0939\u0928" \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932',
      },
      {
        url:
          "https://factly.in/telugu-this-video-does-not-show-the-exercise-in-dinanath-mangeshkar-hospital/",
        headline:
          " \u0c26\u0c40\u0c28\u0c3e\u0c28\u0c3e\u0c25\u0c4d \u0c2e\u0c02\u0c17\u0c47\u0c37\u0c4d\u0c15\u0c30\u0c4d \u0c39\u0c3e\u0c38\u0c4d\u0c2a\u0c3f\u0c1f\u0c32\u0c4d \u0c32\u0c4b \u0c35\u0c48\u0c26\u0c4d\u0c2f \u0c38\u0c3f\u0c2c\u0c4d\u0c2c\u0c02\u0c26\u0c3f \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c30\u0c3e\u0c15\u0c41\u0c02\u0c21\u0c3e \u0c2a\u0c3e\u0c1f\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c1a\u0c42\u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c0e\u0c15\u0c4d\u0c38\u0c30\u0c4d\u200c\u0c38\u0c48\u0c1c\u0c4d \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/this-tweet-about-family-planning-is-from-a-parody-account-of-former-cji-ranjan-gogoi/",
        headline:
          " This tweet about family planning is from a parody account of Former CJI Ranjan Gogoi",
      },
      {
        url:
          "https://factly.in/cab-e-taxi-service-has-no-affiliation-with-the-tata-group/",
        headline: " Cab-E taxi service has no affiliation with the Tata Group",
      },
      {
        url:
          "https://factly.in/no-these-three-ips-officers-are-not-siblings-they-are-batch-mates/",
        headline:
          " No, these three IPS officers are not siblings, they are batch mates",
      },
      {
        url:
          "https://factly.in/telugu-the-persons-who-are-ips-probationers-in-the-picture-are-not-siblings-but-batchmates/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c2e\u0c41\u0c17\u0c4d\u0c17\u0c41\u0c30\u0c41 IPS \u0c06\u0c2b\u0c40\u0c38\u0c30\u0c4d\u0c38\u0c4d \u0c12\u0c15\u0c47 \u0c15\u0c41\u0c1f\u0c41\u0c02\u0c2c\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28\u0c35\u0c3e\u0c30\u0c41 \u0c15\u0c3e\u0c26\u0c41, \u0c35\u0c40\u0c30\u0c41 \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c12\u0c15\u0c47 \u0c2c\u0c4d\u0c2f\u0c3e\u0c1a\u0c4d \u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c35\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-military-exercise-photo-is-linked-to-clashes-between-india-and-china-at-eastern-ladakh/",
        headline:
          " Old military exercise photo is linked to clashes between India and China at Eastern Ladakh",
      },
      {
        url:
          "https://factly.in/telugu-subramanian-swamy-didnt-file-a-pil-that-only-indian-citizens-should-have-own-media-houses-in-india/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c2d\u0c3e\u0c30\u0c24\u0c40\u0c2f\u0c41\u0c32\u0c47 \u0c2e\u0c40\u0c21\u0c3f\u0c2f\u0c3e \u0c38\u0c02\u0c38\u0c4d\u0c25\u0c32\u0c41 \u0c28\u0c21\u0c2a\u0c3e\u0c32\u0c28\u0c3f \u0c15\u0c4b\u0c30\u0c41\u0c24\u0c42 \u0c38\u0c41\u0c2a\u0c4d\u0c30\u0c40\u0c02 \u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41\u0c32\u0c4b \u0c38\u0c41\u0c2c\u0c4d\u0c30\u0c39\u0c4d\u0c2e\u0c23\u0c4d\u0c2f \u0c38\u0c4d\u0c35\u0c3e\u0c2e\u0c3f \u0c2a\u0c3f\u0c32\u0c4d \u0c35\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-video-of-an-explosion-in-abu-dhabi-shared-with-misleading-claims/",
        headline:
          "Fact Check: Video of an explosion in Abu Dhabi shared with misleading claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-heres-the-truth-about-a-female-ips-officer-who-preferred-to-wear-hijab-over-khakhi-uniform/",
        headline:
          "Fact Check: Here\u2019s the truth about a female IPS officer, who preferred to wear Hijab over Khakhi uniform",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/08/fact-check-dont-fall-for-this-morphed-picture-of-amit-shah-and-yediyurappa-sitting-with-naga-sadhus/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed picture of Amit Shah and Yediyurappa sitting with Naga Sadhus",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/09/fact-check-these-three-ips-officers-are-not-siblings-dont-fall-for-false-claimsfact-check-these-three-ips-officers-are-not-siblings-dont-fall-for-false-claims/",
        headline:
          "Fact Check: These three IPS officers are NOT siblings; don\u2019t fall for false claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/10/fact-check-is-this-picture-from-the-recent-clash-between-india-and-china-in-ladakh-heres-the-truth/",
        headline:
          "Fact Check: Is this picture from the recent clash between India and China in Ladakh? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/10/fact-check-did-tata-motors-launch-a-cab-service-by-the-name-of-cab-e-heres-the-truth/",
        headline:
          "Fact Check: Did Tata Motors launch a cab service by the name of Cab-E? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/10/fact-check-dont-fall-for-this-video-of-indian-army-celebrating-after-entering-china/",
        headline:
          "Fact Check: Don\u2019t fall for this video of Indian Army celebrating after entering China",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/clip-of-a-nasty-parachute-landing-has-no-link-pakistan-china-1720247-2020-09-09",
        headline:
          "Fact Check: This clip of a nasty parachute landing has no link to Pakistan or China\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/did-china-seek-un-mediation-on-border-face-off-with-india-1721266-2020-09-12",
        headline:
          "Fact Check: Did China seek UN mediation on border face-off with India?\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-pic-of-joint-military-exercise-viral-as-indo-china-clashes-in-ladakh-1719876-2020-09-08",
        headline:
          "Fact Check: Old pic of joint military exercise viral as Indo-China clashes in Ladakh",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/12/fact-check-dont-fall-for-this-morphed-picture-collage-attributed-to-kanganas-fans/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed picture collage attributed to Kangana\u2019s fans",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/right-wing-groups-film-minors-give-false-love-jihad-angle-to-birthday-parties-held-in-bhopal/",
        headline:
          "Right wing groups film minors, give false \u2018love Jihad\u2019 angle to birthday parties held in Bhopal",
      },
      {
        url:
          "https://www.altnews.in/old-image-from-meerut-shared-as-funeral-procession-of-pune-based-doctor-who-died-of-covid/",
        headline:
          "Old image from Meerut shared as funeral procession of Pune-based doctor who died of COVID",
      },
      {
        url:
          "https://www.altnews.in/image-of-mosque-in-crowded-locality-in-mp-viral-as-mumbai/",
        headline: "Image of mosque in crowded locality in MP viral as Mumbai",
      },
      {
        url:
          "https://www.altnews.in/fake-abp-news-graphic-plates-on-kangana-ranaut-and-reopening-of-schools-viral/",
        headline:
          "Fake ABP News graphic plates on Kangana Ranaut and reopening of schools viral",
      },
      {
        url:
          "https://www.altnews.in/old-video-of-maharashtra-cop-viral-as-shiv-sena-mp-sanjay-raut-dancing/",
        headline:
          "Old video of Maharashtra cop viral as Shiv Sena MP Sanjay Raut dancing",
      },
      {
        url:
          "https://www.altnews.in/hindi/right-wing-groups-film-minors-give-false-love-jihad-angle-to-birthday-parties-held-in-bhopal/",
        headline:
          "\u092d\u094b\u092a\u093e\u0932 \u092e\u0947\u0902 \u092c\u091a\u094d\u091a\u094b\u0902 \u0915\u0940 \u092c\u0930\u094d\u0925\u0921\u0947 \u092a\u093e\u0930\u094d\u091f\u0940 \u0915\u094b \u0930\u093e\u0907\u091f-\u0935\u093f\u0902\u0917 \u0917\u094d\u0930\u0941\u092a\u094d\u0938 \u0928\u0947 \u0932\u0935 \u091c\u093f\u0939\u093e\u0926 \u0915\u093e \u0910\u0902\u0917\u0932 \u0926\u093f\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-image-from-meerut-shared-as-funeral-procession-of-pune-based-doctor-who-died-of-covid/",
        headline:
          "\u092e\u0947\u0930\u0920 \u0915\u0947 \u092a\u0941\u091c\u093e\u0930\u0940 \u0915\u0940 \u0936\u0935 \u092f\u093e\u0924\u094d\u0930\u093e \u092e\u0947\u0902 \u0936\u093e\u092e\u093f\u0932 \u0939\u0941\u090f \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u092f\u0941\u0935\u0915, \u0924\u0938\u094d\u0935\u0940\u0930 \u092a\u0941\u0923\u0947 \u0915\u0947 \u0921\u0949\u0915\u094d\u091f\u0930 \u0915\u0940 \u092c\u0924\u093e\u0908 \u0917\u092f\u0940",
      },
      {
        url:
          "https://www.altnews.in/hindi/image-of-mosque-in-crowded-locality-in-mp-viral-as-mumbai/",
        headline:
          "\u092e\u0927\u094d\u092f \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0940 \u092e\u0938\u094d\u091c\u093f\u0926 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0941\u0902\u092c\u0908 \u092e\u0947\u0902 \u092c\u0928\u0940 \u0915\u0925\u093f\u0924 \u0905\u0935\u0948\u0927 \u092e\u0938\u094d\u091c\u093f\u0926 \u092c\u0924\u093e\u0915\u0930 \u0939\u0941\u0908 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/times-now-falls-for-fake-account-impersonating-kangana-ranauts-mother-9699",
        headline:
          "Times Now Falls For Fake Account Impersonating Kangana Ranaut's Mother",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-nita-ambani-did-not-pledge-to-help-kangana-ranaut-rebuild-her-studio-9701",
        headline:
          "No, Nita Ambani Did Not Pledge To Help Kangana Ranaut Rebuild Her Studio",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2019-clip-of-maharashtra-cop-viral-as-shiv-sena-mp-sanjay-raut-dancing-9721",
        headline:
          "2019 Clip Of Maharashtra Cop Viral As Shiv Sena MP Sanjay Raut Dancing",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/fake-kangana-ranaut-twitter-account-calls-for-ban-on-kapil-sharma-show-9683",
        headline:
          "\u0915\u0902\u0917\u0928\u093e \u0930\u0928\u094c\u0924 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948\u0902\u0921\u0932 \u0938\u0947 \u0939\u0941\u0906 \u0935\u093f\u0935\u093e\u0926\u093e\u0938\u094d\u092a\u0926 \u091f\u094d\u0935\u0940\u091f \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2019-clip-of-maharashtra-cop-viral-as-shiv-sena-mp-sanjay-raut-dancing-9726",
        headline:
          "\u0928\u0939\u0940\u0902, \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0936\u093f\u0935 \u0938\u0947\u0928\u093e \u0915\u0947 \u0938\u0902\u091c\u092f \u0930\u093e\u0909\u0924 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-these-images-do-not-show-convoys-by-karni-sena-for-kangana-ranaut-9696",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u099b\u09ac\u09bf\u0997\u09c1\u09b2\u09bf \u0995\u0999\u09cd\u0997\u09a8\u09be \u09b0\u09be\u09a8\u09be\u0989\u09a4\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u0995\u09b0\u09a3\u09c0 \u09b8\u09c7\u09a8\u09be\u09b0 \u0995\u09a8\u09ad\u09df \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-nita-ambani-did-not-pledge-to-help-kangana-ranaut-rebuild-her-studio-9705",
        headline:
          "\u09b8\u09cd\u099f\u09c1\u09a1\u09bf\u0993 \u0997\u09a1\u09bc\u09a4\u09c7 \u0995\u0999\u09cd\u0997\u09a8\u09be \u09b0\u09be\u09a8\u09be\u0989\u09a4\u0995\u09c7 \u09e8\u09e6\u09e6 \u0995\u09cb\u099f\u09bf \u099f\u09be\u0995\u09be \u09a6\u09c7\u09ac\u09c7\u09a8, \u09ac\u09b2\u09c7\u09a8\u09a8\u09bf \u09a8\u09c0\u09a4\u09be \u0985\u09ae\u09cd\u09ac\u09be\u09a8\u09c0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/times-now-falls-for-fake-account-impersonating-kangana-ranauts-mother-9718",
        headline:
          "\u099f\u09be\u0987\u09ae\u09b8 \u09a8\u09be\u0989 \u09ad\u09c7\u0995 \u09a7\u09b0\u09be \u098f\u0995\u099f\u09bf \u09ad\u09c1\u09df\u09cb \u0985\u09cd\u09af\u09be\u0995\u09be\u0989\u09a8\u09cd\u099f\u0995\u09c7 \u09ac\u09b2\u09b2 \u0995\u0999\u09cd\u0997\u09a8\u09be \u09b0\u09be\u09a8\u09be\u0989\u09a4\u09c7\u09b0 \u09ae\u09be",
      },
      {
        url:
          "https://factly.in/old-photos-are-falsely-shared-as-karni-sena-convoy-going-to-mumbai-in-support-of-kangana-ranaut/",
        headline:
          " Old photos are falsely shared as \u2018Karni Sena\u2019 convoy going to Mumbai in support of Kangana Ranaut",
      },
      {
        url:
          "https://factly.in/telugu-old-photos-are-falsely-shared-as-karni-sena-convoy-going-to-mumbai-in-support-of-kangana-ranaut/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c41 \u0c15\u0c02\u0c17\u0c28\u0c3e \u0c30\u0c28\u0c4c\u0c24\u0c4d \u0c30\u0c15\u0c4d\u0c37\u0c23 \u0c15\u0c4b\u0c38\u0c02 \u0c35\u0c46\u0c2f\u0c4d\u0c2f\u0c3f \u0c35\u0c3e\u0c39\u0c28\u0c3e\u0c32\u0c24\u0c4b \u0c2c\u0c2f\u0c32\u0c41\u0c26\u0c47\u0c30\u0c3f\u0c28 \u0c15\u0c30\u0c4d\u0c23\u0c3f \u0c38\u0c47\u0c28 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-video-from-jammu-is-falsely-shared-as-karni-sena-rally-in-support-of-kangana-ranaut/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c3f \u0c15\u0c02\u0c17\u0c28\u0c3e \u0c30\u0c28\u0c4c\u0c24\u0c4d \u0c30\u0c15\u0c4d\u0c37\u0c23 \u0c15\u0c4b\u0c38\u0c02 \u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c40\u0c17\u0c3e \u0c2c\u0c2f\u0c32\u0c41\u0c26\u0c47\u0c30\u0c3f\u0c28 \u0c15\u0c30\u0c4d\u0c23\u0c3f \u0c38\u0c47\u0c28 \u0c05\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-fake-tweet-of-mns-chief-raj-thackeray-supporting-kangana-ranaut-goes-viral/",
        headline:
          "Fact Check: Fake tweet of MNS Chief Raj Thackeray supporting Kangana Ranaut goes viral",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-bjp-veteran-l-k-advanis-daughter-pratibha-is-not-joining-the-congress-party/",
        headline:
          "Fact Check: BJP veteran L.K. Advani\u2019s daughter Pratibha is not joining the Congress Party",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/10/fact-check-did-karni-sena-supporters-go-to-maharashtra-in-these-cars-to-support-kangana-ranaut/",
        headline:
          "Fact Check: Did Karni Sena supporters go to Maharashtra in these cars to support Kangana Ranaut?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/11/fact-check-this-picture-of-a-mosque-in-middle-of-the-road-is-not-from-mumbai-heres-the-truth/",
        headline:
          "Fact Check: This picture of a mosque in middle of the road is NOT from Mumbai; here\u2019s the truth",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-ambanis-to-help-kangana-with-rs-200-crore-for-new-studio-1720625-2020-09-10",
        headline:
          "Fact Check: Ambanis to help Kangana with Rs 200 crore for new studio?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/12/fact-check-nita-ambani-did-not-give-rs-200-crore-for-kangana-ranauts-new-studio-heres-the-truth/",
        headline:
          "Fact Check: Nita Ambani did NOT give Rs 200 crore for Kangana Ranaut\u2019s new studio; here\u2019s the truth",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/video-of-woman-brutally-hacked-to-death-in-brazil-viral-as-india/",
        headline:
          "Video of woman hacked to death in Brazil revived with \u2018love Jihad\u2019 claim",
      },
      {
        url:
          "https://www.altnews.in/old-video-of-a-girl-sexually-assaulted-in-andhra-pradesh-shared-as-kerala-with-false-communal-narrative/",
        headline:
          "Old video of girl sexually assaulted in Andhra Pradesh viral as recent incident from Kerala",
      },
      {
        url:
          "https://www.altnews.in/images-from-malayalam-web-series-viral-as-rss-man-beaten-for-attending-muslim-wedding/",
        headline:
          "Images from Malayalam series viral as RSS man beaten at Muslim wedding in Kerala",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-video-of-a-girl-sexually-assaulted-in-andhra-pradesh-shared-as-kerala-with-false-communal-narrative/",
        headline:
          "\u0906\u0902\u0927\u094d\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u092e\u0947\u0902 \u0932\u095c\u0915\u0940 \u0915\u0947 \u0938\u093e\u0925 \u0939\u0941\u0908 \u092f\u094c\u0928 \u0939\u093f\u0902\u0938\u093e \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u0947\u0930\u0932\u093e \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0938\u093e\u0902\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0910\u0902\u0917\u0932 \u0938\u0947 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/video-of-woman-brutally-hacked-to-death-in-brazil-viral-as-india/",
        headline:
          "\u092c\u094d\u0930\u093e\u095b\u0940\u0932 \u0915\u093e \u0935\u0940\u092d\u0924\u094d\u0938 \u0935\u0940\u0921\u093f\u092f\u094b \u0907\u0902\u0921\u093f\u092f\u093e \u092e\u0947\u0902 \u092e\u0939\u093f\u0932\u093e \u0915\u0947 \u092e\u093e\u0930\u0947 \u091c\u093e\u0928\u0947 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/hindi/photo-of-10-year-girl-marry-with-40-year-old-man-from-pakistan-shared-with-false-claim-in-india/",
        headline:
          "\u092a\u093e\u0915 \u092e\u0947\u0902 10 \u0938\u093e\u0932 \u0915\u0940 \u092c\u091a\u094d\u091a\u0940 \u0915\u0940 40 \u0938\u093e\u0932 \u0915\u0947 \u0936\u0916\u094d\u0938 \u0938\u0947 \u0936\u093e\u0926\u0940 \u0939\u094b \u0930\u0939\u0940 \u0925\u0940, \u0924\u0938\u094d\u0935\u0940\u0930 \u0907\u0902\u0921\u093f\u092f\u093e \u0915\u0940 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.altnews.in/hindi/images-from-malayalam-web-series-viral-as-rss-man-beaten-for-attending-muslim-wedding/",
        headline:
          "\u092e\u0932\u092f\u093e\u0932\u092e \u0935\u0947\u092c\u0938\u0940\u0930\u0940\u095b \u0915\u0947 \u0938\u094d\u0915\u094d\u0930\u0940\u0928\u0936\u0949\u091f RSS \u092e\u0947\u0902\u092c\u0930 \u0915\u0940 \u092a\u093f\u091f\u093e\u0908 \u0915\u0947 \u091d\u0942\u0920\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u0947 \u0917\u090f",
      },
      {
        url:
          "https://www.altnews.in/hindi/honor-killing-case-of-2018-and-an-unrelated-wedding-card-of-inter-faith-couple-shared-with-false-love-jihad-angle/",
        headline:
          "\u092c\u093e\u092a-\u092d\u093e\u0908 \u0928\u0947 \u0932\u095c\u0915\u0940 \u0915\u0940 \u0939\u0924\u094d\u092f\u093e \u0915\u0940 \u0925\u0940, \u2018\u0932\u0935 \u091c\u093f\u0939\u093e\u0926\u2019 \u0915\u093e \u0915\u0947\u0938 \u092c\u0924\u093e\u0915\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/gruesome-video-of-a-woman-hacked-to-death-in-brazil-peddled-as-india-9655",
        headline:
          "Gruesome Video Of A Woman Hacked To Death In Brazil Peddled As India",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-bengaluru-woman-abusing-2-year-old-grandson-viral-as-mumbai-9657",
        headline:
          "Video Of Bengaluru Woman Abusing 2-Year Old Grandson Viral As Mumbai",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2017-video-of-teen-assaulted-by-a-man-in-andhra-pradesh-peddled-as-kerala-9684",
        headline:
          "2017 Video Of Teen Assaulted By A Man In Andhra Pradesh Peddled As Kerala",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-is-not-a-bjp-leader-threatening-judges-of-dance-india-dance-9686",
        headline:
          "No, This Is Not A BJP Leader Threatening Judges Of Dance India Dance",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/murder-victims-photo-viral-with-unrelated-wedding-card-of-inter-faith-couple-9713",
        headline:
          "Murder Victim's Photo Viral With Unrelated Wedding Card Of Inter-Faith Couple",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/west-bengal-police-refute-claim-about-curfew-during-nights-on-durga-puja-9665",
        headline:
          "\u09aa\u09c1\u099c\u09cb\u09a4\u09c7 \u09ac\u09bf\u0995\u09c7\u09b2 \u09a5\u09c7\u0995\u09c7 \u09b0\u09be\u09a4-\u09ad\u09cb\u09b0 \u0995\u09be\u09b0\u09cd\u09ab\u09c1? \u09b0\u09be\u099c\u09cd\u09af \u09aa\u09c1\u09b2\u09bf\u09b6 \u0996\u09a3\u09cd\u09a1\u09a8 \u0995\u09b0\u09b2 \u09ad\u09c1\u09df\u09cb \u09ac\u09be\u09b0\u09cd\u09a4\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-video-of-teen-assaulted-by-a-man-in-andhra-pradesh-peddled-as-kerala-9685",
        headline:
          "\u09e8\u09e6\u09e7\u09ed'\u09b0 \u0985\u09a8\u09cd\u09a7\u09cd\u09b0\u09b0 \u098f\u0995 \u09b6\u09cd\u09b2\u09c0\u09b2\u09a4\u09be\u09b9\u09be\u09a8\u09bf\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u0995\u09c7\u09b0\u09b2\u09c7\u09b0 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/gruesome-video-of-a-woman-hacked-to-death-in-brazil-peddled-as-india-9691",
        headline:
          "\u09ac\u09cd\u09b0\u09be\u099c\u09bf\u09b2\u09c7 \u098f\u0995 \u09ae\u09b9\u09bf\u09b2\u09be\u0995\u09c7 \u09a8\u09bf\u09b0\u09cd\u09ae\u09ae \u09ae\u09be\u09b0\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7 \u099a\u09be\u09b2\u09be\u09a8\u09cb \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-muslim-teen-made-cop-for-a-day-peddled-with-communal-claim-9707",
        headline:
          "\u09ae\u09c1\u09b8\u09b2\u09bf\u09ae \u0995\u09bf\u09b6\u09cb\u09b0\u09c0\u0995\u09c7 \u09b8\u09be\u09ae\u09be\u09df\u09bf\u0995 \u098f\u0995 \u09a6\u09bf\u09a8\u09c7\u09b0 \u09aa\u09c1\u09b2\u09bf\u09b6 \u0995\u09b0\u09be\u09b0 \u099b\u09ac\u09bf \u09a8\u09bf\u09b2 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09b0\u0999",
      },
      {
        url:
          "https://factly.in/telugu-person-in-the-picture-is-bhupalapally-district-collector-abdul-azeem-not-district-judge/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c1c\u0c21\u0c4d\u0c1c\u0c3f \u0c15\u0c3e\u0c26\u0c41, \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c32\u0c4b\u0c28\u0c3f \u0c1c\u0c2f\u0c36\u0c02\u0c15\u0c30\u0c4d \u0c2d\u0c41\u0c2a\u0c3e\u0c32\u0c2a\u0c32\u0c4d\u0c32\u0c3f \u0c1c\u0c3f\u0c32\u0c4d\u0c32\u0c3e \u0c15\u0c32\u0c46\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c05\u0c2c\u0c4d\u0c26\u0c41\u0c32\u0c4d \u0c05\u0c1c\u0c40\u0c02",
      },
      {
        url:
          "https://factly.in/telugu-andhra-pradesh-first-rank-in-eodb-2019-is-not-because-of-y-s-jagan-reforms/",
        headline:
          " \u0c06\u0c02\u0c27\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c3f \u2018\u0c08\u0c1c\u0c4d \u0c05\u0c2b\u0c4d \u0c21\u0c42\u0c2f\u0c3f\u0c02\u0c17\u0c4d \u0c2c\u0c3f\u0c1c\u0c3f\u0c28\u0c46\u0c38\u0c4d-2019\u2019 \u0c30\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c3f\u0c02\u0c17\u0c4d\u0c38\u0c4d \u0c32\u0c4b \u0c2e\u0c4a\u0c26\u0c1f\u0c3f \u0c30\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c41 \u0c30\u0c3e\u0c35\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c1c\u0c17\u0c28\u0c4d \u0c05\u0c2e\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c38\u0c02\u0c38\u0c4d\u0c15\u0c30\u0c23\u0c32\u0c41 \u0c15\u0c3e\u0c30\u0c23\u0c02 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/this-is-not-the-picture-of-chatrapati-shivaji-inside-london-museum/",
        headline:
          " This is not the picture of Chatrapati Shivaji inside London Museum",
      },
      {
        url:
          "https://factly.in/2017-molestation-video-of-a-girl-from-andhra-pradesh-is-shared-as-the-molestation-video-of-hindu-girl-in-kerala/",
        headline:
          " 2017 molestation video of a girl from Andhra Pradesh is shared as the molestation video of Hindu girl in Kerala",
      },
      {
        url:
          "https://factly.in/the-pmo-india-youtube-channel-did-not-disable-the-comment-section-after-mann-ki-baat-video-drew-more-dislikes/",
        headline:
          " The PMO India YouTube channel did not disable the comment section after Mann Ki Baat video drew more dislikes",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-pictures-falsely-shared-as-kali-maa-revenge-on-muslims/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u2018\u0c24\u0c28 \u0c35\u0c3f\u0c17\u0c4d\u0c30\u0c39\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c24\u0c17\u0c32\u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32\u0c2a\u0c48 \u0c15\u0c3e\u0c33\u0c40 \u0c2e\u0c3e\u0c24 \u0c2a\u0c4d\u0c30\u0c24\u0c40\u0c15\u0c3e\u0c30\u0c02\u2019 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/07/fact-check-dont-believe-this-false-news-of-a-hindu-idol-being-burnt-in-west-bengal-by-muslims/",
        headline:
          "Fact Check: Don\u2019t believe this false news of a Hindu idol being burnt in West Bengal by Muslims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/09/fact-check-do-all-good-morning-pictures-and-videos-contain-malware-dont-believe-this-viral-message/",
        headline:
          "Fact Check: Do all \u2018good morning\u2019 pictures and videos contain malware? Don\u2019t believe this viral message",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/09/fact-check-location-of-old-molestation-video-from-andhra-pradesh-shared-as-kerala-with-false-communal-angle/",
        headline:
          "Fact Check: Location of old molestation video from Andhra Pradesh shared as Kerala with false communal angle",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-truth-behind-disturbing-video-of-girl-s-molestation-1719532-2020-09-07",
        headline:
          "Fact Check: Truth behind disturbing video of girl\u2019s molestation",
      },
    ],
  },
  numpy_seed: 7,
  number_of_articles: 120,
}
