export const data = {
  mdsDat: {
    x: [
      0.2882044935758496,
      0.08869045688423556,
      0.17473842918370383,
      -0.26928708122022205,
      -0.2823462984235668,
    ],
    y: [
      -0.12279654362635026,
      0.41964782027356806,
      -0.18472667933252881,
      -0.09245243893165292,
      -0.019672158383036535,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      15.288681017599739,
      16.3007316217889,
      27.448415452588858,
      18.28598532832023,
      22.676186579702275,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    Freq: [
      22.0,
      19.0,
      20.0,
      11.0,
      17.0,
      10.0,
      9.0,
      8.0,
      10.0,
      7.0,
      1.0021416256056814,
      1.0021416256056814,
      2.004283251211363,
      1.0021416256056814,
      1.0021416256056814,
      1.0021416256056814,
      1.0021416256056814,
      2.004283251211363,
      3.0064248768170443,
      2.004283251211363,
      4.008566502422726,
      3.0064248768170443,
      3.0064248768170443,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      6.012849753634089,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      4.008566502422726,
      3.0064248768170443,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      3.9999487595005068,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      3.9999487595005068,
      1.9999743797502534,
      2.9999615696253805,
      4.9939417467923075,
      0.9987883493584616,
      0.9987883493584616,
      0.9987883493584616,
      0.9987883493584616,
      4.9939417467923075,
      1.9975766987169232,
      0.9987883493584616,
      0.9987883493584616,
      0.9987883493584616,
      8.989095144226155,
      4.9939417467923075,
      4.9939417467923075,
      4.9939417467923075,
      4.9939417467923075,
      3.9951533974338465,
      3.9951533974338465,
      3.9951533974338465,
      16.979401939093847,
      18.97697863781077,
      16.979401939093847,
      6.9915184455092305,
      0.9999821690972833,
      0.9999821690972833,
      0.9999821690972833,
      0.9999821690972833,
      3.9999286763891333,
      1.9999643381945666,
      1.9999643381945666,
      1.9999643381945666,
      0.9999821690972833,
      0.9999821690972833,
      5.999893014583701,
      5.999893014583701,
      4.999910845486417,
      4.999910845486417,
      3.9999286763891333,
      2.9999465072918503,
      2.9999465072918503,
      2.9999465072918503,
      2.9999465072918503,
      6.999875183680984,
      5.999893014583701,
      7.999857352778267,
      3.9999286763891333,
      3.9999286763891333,
      3.9999286763891333,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      2.0001023554631403,
      2.0001023554631403,
      1.0000511777315702,
      2.0001023554631403,
      11.000562955047274,
      10.000511777315703,
      9.000460599584132,
      7.000358244120991,
      6.000307066389422,
      6.000307066389422,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      9.000460599584132,
      5.000255888657851,
    ],
    Term: [
      "farmer",
      "protest",
      "tweet",
      "modi",
      "uttarakhand",
      "work",
      "relief",
      "rihanna",
      "burst",
      "criticize",
      "school",
      "site",
      "ludhiana",
      "thug",
      "destruction",
      "cause",
      "boost",
      "chowdhury",
      "meet",
      "ncc",
      "tractor",
      "father",
      "soldier",
      "release",
      "nasa",
      "border",
      "tire",
      "adhir",
      "earthquake",
      "delhi",
      "order",
      "agitation",
      "sun",
      "chair",
      "farmer",
      "protest",
      "sit",
      "make",
      "kisan",
      "marketing",
      "safety",
      "political",
      "gallo",
      "minor",
      "april",
      "builder",
      "body",
      "showroom",
      "bhat",
      "greta_thunberg",
      "mia",
      "taka",
      "thunberg",
      "greta",
      "khalifa",
      "cake",
      "congress_worker",
      "supreme",
      "mia_khalifa",
      "murder",
      "congress",
      "housing",
      "project",
      "scheme",
      "suicide",
      "bangladesh",
      "morph",
      "falsify",
      "say",
      "kapil",
      "teacher",
      "take",
      "tongue",
      "double",
      "dev",
      "farmers_protest",
      "parody",
      "daughter",
      "injure",
      "rihanna",
      "kangana",
      "rihanna_tweet",
      "support",
      "kapil_dev",
      "twitter",
      "amulya",
      "pakistan",
      "protest",
      "farmer",
      "tweet",
      "shah",
      "st",
      "critic",
      "chatterjee",
      "mukt",
      "sharad_pawar",
      "election",
      "alaska",
      "announce",
      "exam",
      "yet",
      "pawar",
      "nepal",
      "sharad",
      "avalanche",
      "avalanche_nepal",
      "singer",
      "caralisa",
      "singer_caralisa",
      "tendulkar",
      "burst",
      "glacier",
      "uttarakhand",
      "slap",
      "student",
      "tweet",
      "slam",
      "recently",
      "remark",
      "criticising",
      "monk",
      "foreigner",
      "rss_relief",
      "hike",
      "photos",
      "indian",
      "modi",
      "work",
      "relief",
      "criticize",
      "relief_work",
      "gadkari",
      "khalsa",
      "nitin",
      "nitin_gadkari",
      "pm",
      "aid",
      "pm_modi",
      "uttarakhand",
      "rss",
    ],
    Total: [
      22.0,
      19.0,
      20.0,
      11.0,
      17.0,
      10.0,
      9.0,
      8.0,
      10.0,
      7.0,
      1.0021416256056814,
      1.0021416256056814,
      2.004283251211363,
      1.0021416256056814,
      1.0021416256056814,
      1.0021416256056814,
      1.0021416256056814,
      2.004283251211363,
      3.0064248768170443,
      2.004283251211363,
      4.008566502422726,
      3.0064248768170443,
      3.0064248768170443,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      8.011625292867677,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      2.004283251211363,
      22.985545140233498,
      19.98582681591089,
      4.004385606674504,
      3.0030716005698244,
      3.0030716005698244,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      0.9999871898751267,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      2.9999615696253805,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      4.999999937232077,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      1.9999743797502534,
      6.998701447053535,
      1.9999743797502534,
      5.9996798939479845,
      4.9939417467923075,
      0.9987883493584616,
      0.9987883493584616,
      0.9987883493584616,
      0.9987883493584616,
      4.9939417467923075,
      1.9975766987169232,
      0.9987883493584616,
      0.9987883493584616,
      0.9987883493584616,
      8.989095144226155,
      4.9939417467923075,
      4.9939417467923075,
      4.9939417467923075,
      4.9939417467923075,
      3.9951533974338465,
      3.9951533974338465,
      3.9951533974338465,
      19.98582681591089,
      22.985545140233498,
      20.97933061548298,
      10.995904052183734,
      0.9999821690972833,
      0.9999821690972833,
      0.9999821690972833,
      0.9999821690972833,
      3.9999286763891333,
      1.9999643381945666,
      1.9999643381945666,
      1.9999643381945666,
      0.9999821690972833,
      0.9999821690972833,
      5.999893014583701,
      5.999893014583701,
      4.999910845486417,
      4.999910845486417,
      3.9999286763891333,
      2.9999465072918503,
      2.9999465072918503,
      2.9999465072918503,
      2.9999465072918503,
      10.000028716875695,
      9.000046547778412,
      17.000317952362398,
      4.998717025747595,
      6.996293724464518,
      20.97933061548298,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      1.0000511777315702,
      2.0001023554631403,
      2.0001023554631403,
      1.0000511777315702,
      2.0001023554631403,
      11.000562955047274,
      10.000511777315703,
      9.000460599584132,
      7.000358244120991,
      6.000307066389422,
      6.000307066389422,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      5.000255888657851,
      17.000317952362398,
      6.000238057755134,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      1.5911,
      1.8781,
      1.8781,
      1.8781,
      1.8781,
      0.1316,
      -0.0162,
      1.186,
      1.4737,
      1.4737,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.5908,
      1.814,
      1.814,
      1.814,
      1.814,
      1.814,
      1.2545,
      1.814,
      1.1209,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.2929,
      1.1298,
      1.1012,
      1.0813,
      0.84,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.699,
      1.3423,
      1.2935,
      0.9452,
      1.4761,
      1.1399,
      0.0418,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      1.4839,
      0.8479,
      1.3015,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.9836,
      -4.9836,
      -4.2905,
      -4.9836,
      -4.9836,
      -4.9836,
      -4.9836,
      -4.2905,
      -3.885,
      -4.2905,
      -3.5973,
      -3.885,
      -3.885,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -3.1918,
      -4.2905,
      -4.2905,
      -4.2905,
      -4.2905,
      -3.5973,
      -3.885,
      -4.2905,
      -4.2905,
      -4.2905,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -4.3567,
      -4.3567,
      -4.3567,
      -4.3567,
      -3.6636,
      -4.3567,
      -4.3567,
      -4.3567,
      -4.3567,
      -4.3567,
      -3.6636,
      -4.3567,
      -3.9512,
      -3.9627,
      -5.5722,
      -5.5722,
      -5.5722,
      -5.5722,
      -3.9627,
      -4.879,
      -5.5722,
      -5.5722,
      -5.5722,
      -3.3749,
      -3.9627,
      -3.9627,
      -3.9627,
      -3.9627,
      -4.1859,
      -4.1859,
      -4.1859,
      -2.7389,
      -2.6277,
      -2.7389,
      -3.6262,
      -5.1648,
      -5.1648,
      -5.1648,
      -5.1648,
      -3.7785,
      -4.4716,
      -4.4716,
      -4.4716,
      -5.1648,
      -5.1648,
      -3.373,
      -3.373,
      -3.5553,
      -3.5553,
      -3.7785,
      -4.0662,
      -4.0662,
      -4.0662,
      -4.0662,
      -3.2189,
      -3.373,
      -3.0853,
      -3.7785,
      -3.7785,
      -3.7785,
      -5.3799,
      -5.3799,
      -5.3799,
      -5.3799,
      -5.3799,
      -5.3799,
      -4.6868,
      -4.6868,
      -5.3799,
      -4.6868,
      -2.982,
      -3.0773,
      -3.1827,
      -3.434,
      -3.5881,
      -3.5881,
      -3.7705,
      -3.7705,
      -3.7705,
      -3.7705,
      -3.7705,
      -3.7705,
      -3.1827,
      -3.7705,
    ],
  },
  "token.table": {
    Topic: [
      1,
      1,
      5,
      4,
      3,
      4,
      2,
      4,
      4,
      2,
      2,
      2,
      1,
      1,
      2,
      4,
      5,
      2,
      4,
      1,
      1,
      4,
      1,
      2,
      5,
      2,
      4,
      5,
      5,
      3,
      1,
      2,
      3,
      1,
      3,
      3,
      1,
      4,
      4,
      2,
      1,
      3,
      3,
      1,
      5,
      5,
      2,
      4,
      5,
      2,
      2,
      5,
      2,
      5,
      3,
      3,
      3,
      3,
      2,
      5,
      1,
      3,
      1,
      1,
      3,
      2,
      1,
      2,
      2,
      2,
      5,
      5,
      2,
      3,
      4,
      4,
      2,
      1,
      1,
      4,
      5,
      5,
      1,
      3,
      3,
      4,
      5,
      5,
      5,
      2,
      2,
      1,
      3,
      5,
      1,
      5,
      5,
      5,
      3,
      3,
      4,
      5,
      5,
      2,
      1,
      2,
      3,
      2,
      1,
      1,
      3,
      5,
      4,
      4,
      2,
      4,
      4,
      1,
      5,
      1,
      5,
      3,
      4,
      1,
      4,
      3,
      4,
      2,
      1,
      3,
      2,
      2,
      3,
      3,
      4,
      1,
      2,
      1,
      3,
      1,
      3,
      4,
      3,
      4,
      5,
      5,
      4,
    ],
    Freq: [
      0.9978629511528502,
      0.9978629511528502,
      0.9999488248874558,
      1.0000178312206633,
      1.0012131205197945,
      1.0000178312206633,
      1.0000128102889747,
      1.0000178312206633,
      1.0000178312206633,
      1.0000128102889747,
      1.0000128102889747,
      1.0000128102889747,
      0.9978629511528502,
      0.9978629511528502,
      1.0000128102889747,
      0.6999979898244739,
      0.29999913849620313,
      1.0000128102889745,
      1.0000178312206633,
      0.9978629511528502,
      0.9978629511528502,
      1.0000178312206633,
      0.9978629511528502,
      0.8000000100428678,
      0.20000000251071695,
      1.0000128102889747,
      1.0000178312206633,
      0.9999488248874561,
      0.999948824887456,
      1.0012131205197945,
      0.7489117102545323,
      0.12481861837575538,
      0.12481861837575538,
      0.9978629511528502,
      1.0012131205197945,
      1.0012131205197945,
      0.9978629511528502,
      1.0000178312206633,
      1.0000178312206633,
      1.0000128102889747,
      0.17402241171989738,
      0.8266064556695125,
      1.0012131205197945,
      0.9978629511528502,
      0.9999488248874561,
      0.9999488248874558,
      1.0000128102889747,
      0.6666632187009134,
      0.3333316093504567,
      1.0000128102889745,
      1.0000128102889745,
      0.9999488248874561,
      1.0000128102889747,
      0.9999488248874561,
      1.0012131205197945,
      1.0012131205197945,
      1.0012131205197945,
      1.0012131205197945,
      1.0000128102889745,
      0.9999488248874558,
      0.6659847869163378,
      0.3329923934581689,
      0.9978629511528502,
      0.6659847869163378,
      0.3329923934581689,
      1.0000128102889747,
      0.9978629511528502,
      1.0000128102889745,
      1.0000128102889747,
      1.0000128102889747,
      0.9999488248874558,
      0.9999488248874561,
      0.571534595418984,
      0.142883648854746,
      0.285767297709492,
      1.0000178312206633,
      1.0000128102889747,
      0.9978629511528502,
      0.9978629511528502,
      1.0000178312206633,
      0.9999488248874558,
      0.9999488248874558,
      0.9978629511528502,
      1.0012131205197945,
      1.0012131205197945,
      1.0000178312206633,
      0.9999488248874561,
      0.9999488248874558,
      0.9999488248874558,
      1.0000128102889747,
      1.0000128102889747,
      0.15010637426376947,
      0.8506027874946936,
      0.9999488248874561,
      0.9978629511528502,
      0.999948824887456,
      0.9999488248874558,
      0.9999488248874561,
      1.0012131205197943,
      1.0012131205197945,
      0.1666600542136039,
      0.8333002710680194,
      0.9999488248874561,
      1.0000128102889747,
      0.16667555897585853,
      0.5000266769275756,
      0.33335111795171707,
      1.0000128102889747,
      0.9978629511528502,
      0.18188590865366905,
      0.6366006802878417,
      0.18188590865366905,
      1.0000178312206633,
      1.0000178312206633,
      1.0000128102889747,
      1.0000178312206633,
      1.0000178312206633,
      0.4994523995557279,
      0.4994523995557279,
      0.9978629511528502,
      0.9999488248874561,
      0.2000513321416594,
      0.8002053285666376,
      0.9978629511528502,
      1.0000178312206633,
      0.42879846360790314,
      0.5717312848105376,
      1.0000128102889747,
      0.9978629511528502,
      1.0012131205197945,
      1.0000128102889747,
      1.0000128102889745,
      1.0012131205197945,
      1.0012131205197945,
      1.0000178312206633,
      0.9978629511528502,
      1.0000128102889745,
      0.9978629511528502,
      1.0012131205197945,
      0.9978629511528502,
      0.810321373526275,
      0.19066385259441765,
      1.0012131205197945,
      0.4705794340092506,
      0.529401863260407,
      0.9999488248874558,
      1.0000178312206633,
    ],
    Term: [
      "adhir",
      "agitation",
      "aid",
      "alaska",
      "amulya",
      "announce",
      "april",
      "avalanche",
      "avalanche_nepal",
      "bangladesh",
      "bhat",
      "body",
      "boost",
      "border",
      "builder",
      "burst",
      "burst",
      "cake",
      "caralisa",
      "cause",
      "chair",
      "chatterjee",
      "chowdhury",
      "congress",
      "congress",
      "congress_worker",
      "critic",
      "criticising",
      "criticize",
      "daughter",
      "delhi",
      "delhi",
      "delhi",
      "destruction",
      "dev",
      "double",
      "earthquake",
      "election",
      "exam",
      "falsify",
      "farmer",
      "farmer",
      "farmers_protest",
      "father",
      "foreigner",
      "gadkari",
      "gallo",
      "glacier",
      "glacier",
      "greta",
      "greta_thunberg",
      "hike",
      "housing",
      "indian",
      "injure",
      "kangana",
      "kapil",
      "kapil_dev",
      "khalifa",
      "khalsa",
      "kisan",
      "kisan",
      "ludhiana",
      "make",
      "make",
      "marketing",
      "meet",
      "mia",
      "mia_khalifa",
      "minor",
      "modi",
      "monk",
      "morph",
      "morph",
      "morph",
      "mukt",
      "murder",
      "nasa",
      "ncc",
      "nepal",
      "nitin",
      "nitin_gadkari",
      "order",
      "pakistan",
      "parody",
      "pawar",
      "photos",
      "pm",
      "pm_modi",
      "political",
      "project",
      "protest",
      "protest",
      "recently",
      "release",
      "relief",
      "relief_work",
      "remark",
      "rihanna",
      "rihanna_tweet",
      "rss",
      "rss",
      "rss_relief",
      "safety",
      "say",
      "say",
      "say",
      "scheme",
      "school",
      "shah",
      "shah",
      "shah",
      "sharad",
      "sharad_pawar",
      "showroom",
      "singer",
      "singer_caralisa",
      "sit",
      "sit",
      "site",
      "slam",
      "slap",
      "slap",
      "soldier",
      "st",
      "student",
      "student",
      "suicide",
      "sun",
      "support",
      "supreme",
      "taka",
      "take",
      "teacher",
      "tendulkar",
      "thug",
      "thunberg",
      "tire",
      "tongue",
      "tractor",
      "tweet",
      "tweet",
      "twitter",
      "uttarakhand",
      "uttarakhand",
      "work",
      "yet",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-old-photos-unrealed-photos-tubeless-tyres-tractors-farmers-protest-delhi-kisan-andhonlan-barricade-nails-delhi-police-11957",
        headline:
          "Farmers Use Tractors With Tubeless Tyres As Reply To Nails? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/photo-of-sikh-soldiers-meeting-family-protesting-in-delhi-a-factcheck-11882",
        headline:
          "Photo Of Sikh Soldiers Meeting Family Protesting In Delhi? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/who-herd-immunity-covid-19-definition-change-not-to-boost-covid-19-vaccination-russell-okung-11883",
        headline:
          "WHO Changed Its Herd Immunity Definition To Boost Vaccinations? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/amit-shah-sat-on-tagores-chair-adhir-chowdhury-makes-false-claims-11897",
        headline:
          "Amit Shah Sat On Tagore's Chair? Adhir Chowdhury Makes False Claims",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/-kisan-andolan-fake-tractors-news-old-photos-delhi-farm-laws-11966",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928: \u0915\u0940\u0932\u094b\u0902 \u0915\u0947 \u091c\u0935\u093e\u092c \u092e\u0947\u0902 \u092c\u093f\u0928\u093e \u091f\u093e\u092f\u0930 \u0915\u0947 \u091f\u094d\u0930\u0948\u0915\u094d\u091f\u0930? \u0935\u093e\u092f\u0930\u0932 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0905\u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-imge-kisan-andolan-sikh-sainik-went-to-the-delhi-border-to-visit-his-family-in-farmers-protest-11902",
        headline:
          "\u0915\u094d\u092f\u093e \u091b\u0941\u091f\u094d\u091f\u0940 \u092a\u0930 \u0906\u0924\u0947 \u0939\u0940 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u092a\u093f\u0924\u093e \u0938\u0947 \u092e\u093f\u0932\u0928\u0947 \u092a\u0939\u0941\u0902\u091a\u093e \u0938\u0947\u0928\u093e \u0915\u093e \u091c\u0935\u093e\u0928?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/delhi-high-court-ordered-delhi-police-to-release-all-122-farmers-kisan-andolan-tractor-parade-11950",
        headline:
          "\u0915\u094d\u092f\u093e \u0926\u093f\u0932\u094d\u0932\u0940 \u0939\u093e\u0908\u0915\u094b\u0930\u094d\u091f \u0928\u0947 \u0917\u093f\u0930\u095e\u094d\u0924\u093e\u0930 \u0938\u092d\u0940 \u0915\u093f\u0938\u093e\u0928\u094b\u0902 \u0915\u094b \u091b\u094b\u095c\u0928\u0947 \u0915\u093e \u0906\u0926\u0947\u0936 \u0926\u093f\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/free-liquor-at-kisan-protest-delhi-borders-tikri-singhu-farm-laws-central-government-fake-news-11914",
        headline:
          "\u0926\u0938 \u092e\u0939\u0940\u0928\u0947 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092e\u0947\u0902 \u0936\u0930\u093e\u092c \u0935\u093f\u0924\u0930\u0923 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-amit-shah-sat-on-tagores-chair-adhir-chowdhury-makes-false-claims-11924",
        headline:
          "Amit Shah \u09b0\u09ac\u09c0\u09a8\u09cd\u09a6\u09cd\u09b0\u09a8\u09be\u09a5\u09c7\u09b0 \u099a\u09c7\u09df\u09be\u09b0\u09c7 \u09ac\u09b8\u09c7\u09a8? \u0985\u09a7\u09c0\u09b0 \u099a\u09cc\u09a7\u09c1\u09b0\u09c0\u09b0 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/10/fact-check-this-picture-of-the-sun-hasnt-been-published-by-nasa-heres-the-truth/",
        headline:
          "Fact Check: This Picture Of The Sun Hasn\u2019t Been Published By NASA; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/12/fact-check-these-photos-of-soldiers-are-from-ludhiana-and-not-delhi-border/",
        headline:
          "Soldier Went To Delhi Border To Meet Protesting Father? No, The Pictures Are From Ludhiana",
      },
      {
        url:
          "https://factly.in/telugu-this-tractor-with-iron-tires-has-nothing-to-do-with-the-tractor-rally-held-on-republic-day/",
        headline:
          " \u0c07\u0c28\u0c41\u0c2a \u0c1f\u0c48\u0c30\u0c4d\u0c32 \u0c08 \u0c1f\u0c4d\u0c30\u0c3e\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c15\u0c3f \u0c30\u0c3f\u0c2a\u0c2c\u0c4d\u0c32\u0c3f\u0c15\u0c4d \u0c21\u0c47 \u0c30\u0c4b\u0c1c\u0c41\u0c28 \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c1f\u0c4d\u0c30\u0c3e\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c40\u0c15\u0c3f \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-justice-markandey-katju-did-not-call-bjp-a-group-of-goondas-and-idiots/",
        headline:
          " \u0c1c\u0c38\u0c4d\u0c1f\u0c3f\u0c38\u0c4d \u0c2e\u0c3e\u0c30\u0c4d\u0c15\u0c02\u0c21\u0c47\u0c2f \u0c15\u0c1f\u0c4d\u0c1c\u0c41, BJP \u0c12\u0c15 \u0c17\u0c42\u0c02\u0c21\u0c3e\u0c32 \u0c17\u0c41\u0c02\u0c2a, \u0c2e\u0c42\u0c30\u0c4d\u0c16\u0c41\u0c32 \u0c38\u0c02\u0c38\u0c4d\u0c25 \u0c05\u0c28\u0c3f \u0c05\u0c28\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-allocations-were-also-made-for-telangana-region-in-the-railway-budget-between-2009-14/",
        headline:
          " 2009-14 \u0c2e\u0c27\u0c4d\u0c2f \u0c15\u0c42\u0c21\u0c3e \u0c30\u0c48\u0c32\u0c4d\u0c35\u0c47 \u0c2c\u0c21\u0c4d\u0c1c\u0c46\u0c1f\u0c4d \u0c32\u0c4b \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c2a\u0c4d\u0c30\u0c3e\u0c02\u0c24\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c15\u0c47\u0c1f\u0c3e\u0c2f\u0c3f\u0c02\u0c2a\u0c41\u0c32\u0c41 \u0c1c\u0c30\u0c3f\u0c17\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://factly.in/unrelated-old-video-shared-as-farmers-drinking-alcohol-at-a-protesting-site-in-delhi/",
        headline:
          " Unrelated old video shared as farmers drinking alcohol at a protest site in Delhi",
      },
      {
        url:
          "https://factly.in/telugu-the-ncc-act-of-1948-does-not-stipulate-that-every-school-must-have-a-ncc/",
        headline:
          " 1948\u0c32\u0c4b \u0c30\u0c42\u0c2a\u0c4a\u0c02\u0c26\u0c3f\u0c02\u0c1a\u0c3f\u0c28 NCC \u0c1a\u0c1f\u0c4d\u0c1f\u0c02\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c24\u0c40 \u0c38\u0c4d\u0c15\u0c42\u0c32\u0c4d \u0c32\u0c4b \u0c15\u0c1a\u0c4d\u0c1a\u0c3f\u0c24\u0c02\u0c17\u0c3e NCC \u0c09\u0c02\u0c21\u0c3e\u0c32\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c2c\u0c02\u0c27\u0c28 \u0c0f\u0c26\u0c3f \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-nasa-has-nothing-to-do-with-this-edited-image-of-the-sun-surface-1767228-2021-02-08",
        headline:
          "Fact Check: NASA has nothing to do with this edited image of the sun\u2019s surface",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/earthquake-destruction-scenes-not-from-tajikistan-1768970-2021-02-13",
        headline:
          "Fact Check: No, these earthquake destruction scenes are not from Tajikistan \u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-images-from-turkey-shared-damage-caused-earthquake-afghanistan-1769107-2021-02-14",
        headline:
          "Fact Check: Old images from Turkey shared as damage caused by earthquake in Afghanistan",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-delhi-hc-has-not-ordered-release-of-protesters-held-during-farmers-agitation-1768005-2021-02-10",
        headline:
          "Fact Check: Delhi HC has not ordered release of protesters held during farmers\u2019 agitation",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-horrifying-video-of-vehicles-skidding-piling-up-at-dallas-is-true-674105",
        headline:
          "Fact check: Horrifying video of vehicles skidding, piling up at Dallas is true",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photos-of-soldiers-reuniting-with-their-protesting-father-at-delhi-border-is-from-ludhiana-673957",
        headline:
          "Fact Check: Photos of soldiers reuniting with their protesting father at Delhi border is from Ludhiana",
      },
    ],
    "2": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/mia-khalifa-kisan-andolan-rahul-gandhi-congress-twitter-fake-news-11892",
        headline:
          "Congress \u0995\u09b0\u09cd\u09ae\u09c0\u09b0\u09be \u0995\u09bf Mia Khalifa-\u0995\u09c7 \u0995\u09c7\u0995 \u0996\u09be\u0993\u09df\u09be\u099a\u09cd\u099b\u09c7\u09a8? \u09a8\u09be, \u09a4\u09be \u09a8\u09df",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/mamata-banerjee-amar-bari-prokolpo-housing-project-flat-for-poor-bangladesh-west-bengal-assembly-elections-2021-11969",
        headline:
          "Image Of Bangladesh Housing Project Shared As West Bengal Scheme",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/morphed-political-cartoon-targeting-congress-viral-11889",
        headline: "Morphed Political Cartoon Targeting Congress Viral",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/photo-of-up-suicide-victim-shared-as-minor-murdered-in-kolkata-11921",
        headline:
          "Photo Of UP Suicide Victim Shared As Minor Murdered In Kolkata",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/uttar-pradesh-etah-suicide-west-bengal-kolkata-jorabagan-rape-victim-murder-11941",
        headline:
          "\u0909\u0924\u094d\u0924\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u0938\u0941\u0938\u093e\u0907\u0921 \u0935\u093f\u0915\u094d\u091f\u093f\u092e \u0915\u0940 \u095e\u094b\u091f\u094b \u0915\u094b\u0932\u0915\u093e\u0924\u093e \u0915\u0947 \u092e\u0930\u094d\u0921\u0930 \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bihar-former-cm-lalu-prasad-yadav-health-condition-death-news-is-fake-11879",
        headline:
          "\u0932\u093e\u0932\u0942 \u092f\u093e\u0926\u0935 \u0915\u0947 \u0928\u093f\u0927\u0928 \u0915\u0940 \u095e\u0930\u094d\u095b\u0940 \u0959\u092c\u0930 \u0938\u094b\u0936\u0932 \u092e\u0940\u0921\u093f\u092f\u093e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/stray-dog-as-salesman-hyundai-showroom-not-really-11884",
        headline:
          "Brazil-\u098f \u09b6\u09be\u09b0\u09ae\u09c7\u09df Hyundai \u09b6\u09cb\u09b0\u09c1\u09ae\u09c7 \u09ac\u09bf\u09aa\u09a8\u09a8 \u0995\u09b0\u09cd\u09ae\u09c0? \u09a8\u09be \u09a4\u09be \u09a0\u09bf\u0995 \u09a8\u09df",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/08/fact-check-morphed-news-article-screengrab-claiming-greta-thunberg-is-a-muslim-is-fake/",
        headline:
          "Fact Check: Morphed News Article Screengrab Claiming Greta Thunberg Is A Muslim Is Fake",
      },
      {
        url:
          "https://factly.in/opindia-claims-greta-thunbergs-real-name-is-ghazala-bhat-no-the-posted-image-is-fake/",
        headline:
          " OpIndia did not claim that Greta Thunberg\u2019s real name is \u2018Ghazala Bhat\u2019",
      },
      {
        url:
          "https://factly.in/telugu-supreme-court-did-not-say-that-beheading-woman-who-breakup-is-allowed/",
        headline:
          " \u0c2c\u0c4d\u0c30\u0c47\u0c15\u0c2a\u0c4d \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c3f\u0c28 \u0c05\u0c2e\u0c4d\u0c2e\u0c3e\u0c2f\u0c3f\u0c32\u0c28\u0c3f \u0c28\u0c30\u0c3f\u0c15\u0c47\u0c38\u0c3f\u0c28\u0c3e \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c32\u0c47\u0c26\u0c28\u0c3f \u0c38\u0c41\u0c2a\u0c4d\u0c30\u0c40\u0c02 \u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41 \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c06\u0c27\u0c3e\u0c30\u0c3e\u0c32\u0c41 \u0c32\u0c47\u0c35\u0c41.",
      },
      {
        url:
          "https://factly.in/mrophed-photo-shared-as-congress-workers-offering-cake-to-the-mia-khalifa/",
        headline:
          " Morphed photo shared as Congress workers offering cake to Mia Khalifa",
      },
      {
        url:
          "https://factly.in/telugu-simulation-video-of-flying-train-is-passed-off-as-flying-train-unveiled-in-china/",
        headline:
          " \u0c38\u0c3f\u0c2e\u0c41\u0c32\u0c47\u0c37\u0c28\u0c4d \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c1a\u0c48\u0c28\u0c3e \u0c26\u0c47\u0c36\u0c02 \u0c17\u0c3e\u0c32\u0c4d\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c2f\u0c3e\u0c23\u0c3f\u0c02\u0c1a\u0c47 \u0c30\u0c48\u0c32\u0c41\u0c28\u0c3f \u0c30\u0c42\u0c2a\u0c4a\u0c02\u0c26\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-supreme-court-did-not-pass-any-order-enforcing-stricter-rules-on-road-safety-from-april-01/",
        headline:
          " 01 \u0c0f\u0c2a\u0c4d\u0c30\u0c3f\u0c32\u0c4d \u0c28\u0c41\u0c02\u0c21\u0c3f \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c2d\u0c26\u0c4d\u0c30\u0c24\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c15\u0c20\u0c3f\u0c28 \u0c28\u0c3f\u0c30\u0c4d\u0c23\u0c2f\u0c3e\u0c32\u0c41 \u0c05\u0c2e\u0c32\u0c41 \u0c1a\u0c47\u0c2f\u0c3e\u0c32\u0c28\u0c3f \u0c38\u0c41\u0c2a\u0c4d\u0c30\u0c40\u0c02 \u0c15\u0c4b\u0c30\u0c4d\u0c1f\u0c41 \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c24\u0c40\u0c30\u0c4d\u0c2a\u0c41 \u0c07\u0c35\u0c4d\u0c35\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-a-japanese-train-running-at-a-speed-of-4800-kmph-is-not-real/",
        headline:
          " \u0c17\u0c02\u0c1f\u0c15\u0c41 4800 \u0c15\u0c3f\u0c32\u0c4b\u0c2e\u0c40\u0c1f\u0c30\u0c4d\u0c32 \u0c35\u0c47\u0c17\u0c02\u0c24\u0c4b \u0c1c\u0c2a\u0c3e\u0c28\u0c4d \u0c0e\u0c32\u0c15\u0c4d\u0c1f\u0c4d\u0c30\u0c3f\u0c15\u0c4d \u0c1f\u0c4d\u0c30\u0c48\u0c28\u0c4d \u0c2a\u0c4d\u0c30\u0c2f\u0c3e\u0c23\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c05\u0c35\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c28\u0c3f\u0c1c\u0c2e\u0c48\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-no-truth-in-the-news-that-prominent-body-builder-ronnie-coleman-has-converted-to-hinduism/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c2e\u0c41\u0c16 \u0c2c\u0c3e\u0c21\u0c40 \u0c2c\u0c3f\u0c32\u0c4d\u0c21\u0c30\u0c4d \u0c30\u0c4b\u0c28\u0c4d\u0c28\u0c40 \u0c15\u0c4b\u0c32\u0c4d\u0c2e\u0c28\u0c4d \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c2e\u0c24\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c38\u0c4d\u0c35\u0c40\u0c15\u0c30\u0c3f\u0c02\u0c1a\u0c3e\u0c21\u0c28\u0c4d\u0c28 \u0c35\u0c3e\u0c30\u0c4d\u0c24\u0c32\u0c4b \u0c28\u0c3f\u0c1c\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-edited-clip-of-kcr-speech-is-being-shared-with-misleading-narrative/",
        headline:
          " \u0c15\u0c47\u0c38\u0c40\u0c06\u0c30\u0c4d \u0c2a\u0c4d\u0c30\u0c38\u0c02\u0c17\u0c02\u0c32\u0c4b\u0c28\u0c3f \u0c15\u0c4a\u0c02\u0c24 \u0c2d\u0c3e\u0c17\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c15\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c42 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c26\u0c4b\u0c35 \u0c2a\u0c1f\u0c4d\u0c1f\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-shows-people-doing-surya-namaskar-at-an-event-in-mongolia-not-japan/",
        headline:
          " This video shows people doing Surya Namaskar at an event in Mongolia, not Japan",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-picture-of-housing-project-in-bangladesh-shared-as-mamata-s-scheme-for-slum-dwellers-1768375-2021-02-11",
        headline:
          "Fact Check: Picture of housing project in Bangladesh shared as Mamata\u2019s scheme for slum dwellers",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-congress-workers-feeding-cake-to-mia-khalifas-portrait-is-morphed-673895",
        headline:
          "Fact check: Viral image of Congress workers offering cake to Mia Khalifa's portrait is morphed",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-delhi-police-did-not-arrest-greta-in-sweden-673910",
        headline:
          "Fact Check: Delhi Police did not arrest Greta Thunberg in Sweden",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/fact-check-anna-hazare-has-not-joined-bjp-viral-image-is-edited/",
        headline:
          "Fact Check : \u098f\u0987 \u09ab\u099f\u09cb \u0986\u09a8\u09cd\u09a8\u09be \u09b9\u09be\u099c\u09be\u09f0\u09c7\u09f0 \u09a8\u09b9\u09af\u09bc, \u0986\u09f0\u09c1 \u09a4\u09c7\u0993\u0981 \u09ac\u09bf\u099c\u09c7\u09aa\u09bf\u09a4 \u09af\u09cb\u0997\u09a6\u09be\u09a8\u09cb \u0995\u09f0\u09be \u09a8\u09be\u0987, \u098f\u0987 \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f \u09ad\u09c1\u09f1\u09be",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/viral/fact-check-no-rbi-is-not-going-to-stop-the-circulation-of-rs-100-rs-10-and-rs-5-old-notes/",
        headline:
          "Fact Check: \u09f0\u09bf\u099c\u09be\u09f0\u09cd\u09ad \u09ac\u09c7\u0982\u0995\u09c7 \u09aa\u09c1\u09f0\u09a3\u09bf \u09e7\u09e6\u09e6 \u099f\u0995\u09c0\u09af\u09bc\u09be, \u09e7\u09e6 \u099f\u0995\u09c0\u09af\u09bc\u09be \u0986\u09f0\u09c1 \u09eb \u099f\u0995\u09c0\u09af\u09bc\u09be \u09a8\u09cb\u099f \u0993\u09ad\u09a4\u09be\u0987 \u09a8\u09b2\u09af\u09bc, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f \u09ad\u09c1\u09f1\u09be",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/fact-check-who-fake-statment-viral-about-mask/?itm_source=homepage&itm_medium=dktp_s4&itm_campaign=editorpick",
        headline:
          "Fact Check: WHO \u0987 \u0995\u09c7\u09a4\u09bf\u09df\u09be\u0993 \u0995\u09cb\u09f1\u09be \u09a8\u09be\u0987 \u09af\u09c7 \u09ae\u09be\u09b8\u09cd\u0995 \u09aa\u09bf\u09a8\u09cd\u09a7\u09be\u09f0 \u09aa\u09cd\u09f0\u09df\u09cb\u099c\u09a8 \u09a8\u09be\u0987, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f \u09ad\u09c1\u09f1\u09be",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-fake-news-kapil-dev-amit-shah-jay-shah-icc-cricketers-tweet-on-farmers-protest-11927",
        headline:
          "No, Kapil Dev Did Not Say Cricketers Tweeted Under Pressure From Jay Shah",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/amulya-leona-valarmathi-student-activist-viral-photo-farmers-protest-delhi-borders-pakistan-zindabad-slogan-11967",
        headline:
          "TN Student Activist's Image Shared As Amulya Leona At Farmers' Protest",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/farmers-protest-ad-super-bowl-fresno-county-california-united-states-farmers-protests-delhi-super-bowl-sunday-pregame-coverage-cbs-11885",
        headline:
          "Yes, A Farmers' Protest Ad Played In California Ahead Of The Super Bowl",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-lic-trade-union-protest-against-bjp-government-nirmala-sitharaman-lic-ipo-lic-stake-sale-11923",
        headline: "Old Video Of LIC Union's Protest Peddled As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/pastor-to-farmer-tn-priests-images-viral-with-fake-claim-11926",
        headline: "Pastor To Farmer? TN Priest's Images Viral With Fake Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fake-tweet-screenshot-rihanna-kangana-ranaut-farmers-protest-viral-11880",
        headline:
          "Fake Tweet Of Rihanna Taking A Dig At Kangana's Income Surfaces",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/fake-twitter-account-naseeruddin-shah-tweets-support-farmers-protest-delhi-11887",
        headline:
          "\u091f\u094d\u0935\u093f\u091f\u0930 \u092a\u0930 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902 \u0928\u0938\u0940\u0930\u0941\u0926\u094d\u0926\u0940\u0928 \u0936\u093e\u0939, \u0935\u093e\u092f\u0930\u0932 \u091f\u094d\u0935\u0940\u091f\u094d\u0938 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/undertaker-wrestler-support-kisan-andolan-fake-tweet-viral-news-11933",
        headline:
          "\u0905\u0902\u0921\u0930\u091f\u0947\u0915\u0930 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0939\u0948 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0915\u093e \u0938\u092e\u0930\u094d\u0925\u0928, \u0935\u093e\u092f\u0930\u0932 \u091f\u094d\u0935\u0940\u091f \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rihanna-fake-tweet-dancers-kangana-ranaut-farmers-protest-india-11876",
        headline:
          "\u092f\u0939 \u0935\u093e\u092f\u0930\u0932 \u091f\u094d\u0935\u0940\u091f \u092a\u0949\u092a\u0938\u094d\u091f\u093e\u0930 \u0930\u093f\u0939\u093e\u0928\u093e \u0915\u0947 \u091f\u094d\u0935\u093f\u091f\u0930 \u0939\u0948\u0902\u0921\u0932 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/kapil-dev-fake-quote-bcci-secretary-jay-shah-indian-cricketers-tweets-on-india-together-11893",
        headline:
          "\u0915\u092a\u093f\u0932 \u0926\u0947\u0935 \u0928\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e \u092f\u0939 \u092c\u092f\u093e\u0928, \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0915\u093e \u0926\u093e\u0935\u093e \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/tamilnadu-pastor-kisan-andolan-disguise-congress-fake-news-11931",
        headline:
          "\u092a\u093e\u0926\u0930\u0940 \u092f\u093e \u0915\u093f\u0938\u093e\u0928? \u0924\u092e\u093f\u0932\u0928\u093e\u0921\u0941 \u0915\u0947 \u092a\u093e\u0926\u0930\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2015-video-of-abhishek-banerjee-slapped-on-stage-at-public-meeting-in-purba-medinipur-revived-with-misleading-claim-11937",
        headline:
          "\u0995\u09be\u0981\u09a5\u09bf\u09b0 \u099c\u09a8\u09b8\u09ad\u09be\u09b0 \u09aa\u09b0 \u09b8\u09be\u0982\u09b8\u09a6 Abhishek Banerjee-\u0995\u09c7 \u099a\u09a1\u09bc \u09ae\u09c7\u09b0\u09c7\u099b\u09c7 \u098f\u0995 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/kapil-dev-fake-quote-bcci-secretary-jay-shah-forces-cricketers-for-tweeting-about-india-together-11877",
        headline:
          "'\u0990\u0995\u09cd\u09af\u09ac\u09a6\u09cd\u09a7 \u09ad\u09be\u09b0\u09a4' \u09a8\u09bf\u09df\u09c7 \u0995\u09aa\u09bf\u09b2 \u09a6\u09c7\u09ac\u09c7\u09b0 Jay Shah \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u09ad\u09c1\u09df\u09cb \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-tweet-of-mukesh-ambani-claims-rihanna-performed-daughters-isha-ambani-wedding-ceremony-and-called-india-poor-nation-11891",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf: \u09ae\u09c1\u0995\u09c7\u09b6 \u0985\u09ae\u09cd\u09ac\u09be\u09a8\u09c0\u09b0 \u09ae\u09c7\u09df\u09c7\u09b0 \u09ac\u09bf\u09df\u09c7\u09a4\u09c7 Rihanna \u09b8\u0999\u09cd\u0997\u09c0\u09a4 \u09aa\u09b0\u09bf\u09ac\u09c7\u09b6\u09a8 \u0995\u09b0\u09c7\u099b\u09c7\u09a8",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/08/fact-check-picture-of-teacher-carrying-his-students-child-shared-with-false-claim/",
        headline:
          "Fact Check: Picture Of Teacher Carrying His Student\u2019s Child Shared With False Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/08/fact-check-no-rihanna-didnt-tweet-this-viral-message-heres-the-truth/",
        headline:
          "Fact Check: No, Rihanna Didn\u2019t Tweet This Viral Message; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/08/fact-check-no-mukesh-ambani-hasnt-tweeted-about-rihanna-heres-the-truth/",
        headline:
          "Fact Check: No, Mukesh Ambani Hasn\u2019t Tweeted About Rihanna; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/08/fact-check-picture-of-farmer-injured-in-an-accident-shared-with-false-claim/",
        headline:
          "Fact Check: Picture Of Farmer Injured In An Accident Shared With False Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/08/fact-check-old-picture-of-deceased-farmer-falsely-linked-to-ongoing-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Deceased Farmer Falsely Linked To Ongoing Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/10/fact-check-no-rihanna-did-not-post-tweet-mocking-kangana-heres-the-truth/",
        headline:
          "Fact Check: No, Rihanna Did NOT Post Tweet Mocking Kangana; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/10/fact-check-no-former-wrestler-undertaker-did-not-tweet-in-support-of-farmers-protest/",
        headline:
          "Fact Check: No, Former Wrestler Undertaker Did NOT Tweet In Support Of Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/11/fact-check-did-kapil-dev-make-a-statement-with-regards-to-tweets-by-cricketers-on-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Did Kapil Dev Make A Statement With Regards To Tweets By Cricketers\u2019 On Farmers\u2019 Protest? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/13/fact-check-no-the-girl-who-raised-pro-pakistan-slogans-in-owaisis-rally-wasnt-spotted-at-farmers-protest/",
        headline:
          "Fact Check: No, The Girl Who Raised \u2018Pro-Pakistan\u2019 Slogans In Owaisi\u2019s Rally Wasn\u2019t Spotted At Farmers\u2019 Protest",
      },
      {
        url:
          "https://factly.in/rihanna-did-not-actually-tweet-about-kangana-and-bollywood/",
        headline: " Rihanna did not tweet about Kangana and Bollywood",
      },
      {
        url:
          "https://factly.in/telugu-people-urging-for-alcohol-in-this-video-are-not-the-protesting-farmers-in-delhi/",
        headline:
          " \u0c2e\u0c26\u0c4d\u0c2f\u0c02 \u0c15\u0c4b\u0c38\u0c02 \u0c0e\u0c17\u0c2c\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c41\u0c32\u0c41 \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40 \u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c38\u0c28 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-girl-from-the-farmers-protest-is-not-amulya-leona-who-chanted-pakistan-zindabad-slogans-last-year/",
        headline:
          " This girl from the farmers\u2019 protest is not Amulya Leona who chanted \u2018Pakistan Zindabad\u2019 slogans last year",
      },
      {
        url:
          "https://factly.in/these-tweets-in-support-of-farmers-protest-are-from-a-parody-account-of-naseeruddin-shah/",
        headline:
          " These tweets in support of farmers\u2019 protest are from a parody account of Naseeruddin Shah",
      },
      {
        url:
          "https://factly.in/old-video-of-lic-employees-protest-against-privatization-shared-as-the-recent-one/",
        headline:
          " Old video of LIC employees protest against privatisation shared as a recent one",
      },
      {
        url:
          "https://factly.in/telugu-2018-video-shared-as-protests-in-the-wake-of-the-recent-spate-of-rising-petrol-prices/",
        headline:
          " 2018 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c35\u0c30\u0c41\u0c38\u0c17\u0c3e \u0c2a\u0c46\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c27\u0c30\u0c32 \u0c28\u0c47\u0c2a\u0c25\u0c4d\u0c2f\u0c02\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c17\u0c3e \u0c1a\u0c3f\u0c24\u0c4d\u0c30\u0c3f\u0c15\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-man-playing-double-naseeruddin-shah-rakesh-tikait-twitter-1767644-2021-02-10",
        headline:
          "Fact Check: The man who was playing double role of Naseeruddin Shah and Rakesh Tikait on Twitter",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-undertaker-would-rise-from-the-dead-after-this-morphed-tweet-in-support-of-farmers-1767598-2021-02-09",
        headline:
          "Fact Check: Undertaker would rise from the dead after this morphed tweet in support of farmers",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-amulya-the-girl-who-raised-pakistan-zindabad-slogans-in-2020-at-the-farmers-protest-1768604-2021-02-12",
        headline:
          "Fact Check: This is not Amulya, the girl who raised \u2018Pakistan Zindabad\u2019 slogans in 2020, at the farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-rihannas-tweet-about-kangana-is-fake-673963",
        headline: "Fact Check: Rihanna's tweet about Kangana is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-kapil-dev-did-not-say-that-cricketers-are-tweeting-against-farmers-protest-under-pressure-from-amit-shahs-son-674094",
        headline:
          "Fact check: No, Kapil Dev did not say that cricketers are tweeting against farmer's protest under pressure from Amit Shah's son",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-student-activist-at-farmers-protest-is-not-amulya-leona-who-raised-pro-pakistan-slogans-in-2020-674186",
        headline:
          "Fact Check: Student activist at farmers' protest is not Amulya Leona who raised pro-Pakistan slogans in 2020",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-fake-naseeruddin-shah-twitter-account-posts-tweets-supporting-farmers-protest-673955",
        headline:
          "Fact Check: Fake Naseeruddin Shah Twitter account posts tweets supporting farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-notice-outside-india-gate-is-not-about-farmers-protest-673907",
        headline:
          "Fact Check: Notice outside India Gate is not about farmers' protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-true-archeologists-excavated-mummy-with-a-golden-tongue-in-egypt-673941",
        headline:
          "Fact check: True, archeologists excavated mummy with a golden tongue in Egypt",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/viral/fact-check-rihannas-tweet-for-kangana-is-fake/",
        headline:
          "Fact Check: \u09f0\u09bf\u09b9\u09be\u09a8\u09be\u0987 \u0995\u0982\u0997\u09a8\u09be\u09f0 \u09ac\u09bf\u09b7\u09af\u09bc\u09c7 \u098f\u0987 \u099f\u09c1\u0987\u099f \u0995\u09f0\u09be \u09a8\u09be\u099b\u09bf\u09b2, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f\u099f\u09cb \u09ad\u09c1\u09f1\u09be",
      },
    ],
    "4": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-tweet-calling-hindu-mukt-india-by-bollywood-singer-caralisa-monteiro-viral-11881",
        headline:
          "\u0997\u09be\u09df\u09bf\u0995\u09be Caralisa Monteiro \u098f\u09b0 '\u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09ae\u09c1\u0995\u09cd\u09a4 \u09ad\u09be\u09b0\u09a4' \u09b2\u09c7\u0996\u09be \u09ad\u09c1\u09df\u09cb \u099f\u09c1\u0987\u099f \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/uttarakhand-glacier-burst-viral-image-snow-fall-down-mountain-alaska-melting-glacier-11899",
        headline:
          "Alaska's Melting Glacier Image Shared As Uttarakhand Glacial Burst",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/string-reveals-video-vinodh-kumar-youtube-greta-thunberg-toolkit-george-soros-11962",
        headline: "'String Reveals' Video Is Riddled With Misleading Claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-sharad-pawar-sachin-tendulkar-mia-khalifa-slapped-old-video-ncp-tweet-farmers-protest-11895",
        headline:
          "Old Video Falsely Shared As Pawar Slapped For Criticising Tendulkar",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/uttarakhand-glacier-burst-chamoli-avalanche-nepal-viral-video-11886",
        headline:
          "Avalanche Video From Nepal Peddled As Glacier Burst In Uttarakhand",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-video-man-slapped-sharad-pawar-ndtv-sachin-tendulkar-tweet-kisan-andolan-11920",
        headline:
          "\u0936\u0930\u0926 \u092a\u0935\u093e\u0930 \u0915\u094b \u0925\u092a\u094d\u092a\u095c \u092e\u093e\u0930\u0928\u0947 \u0915\u093e 10 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/uttarakhand-chamoli-glacier-burst-video-nepal-valanche-2021-caught-on-camera-kapuche-lake-11896",
        headline:
          "\u0928\u0947\u092a\u093e\u0932 \u092e\u0947\u0902 \u0939\u093f\u092e\u0938\u094d\u0916\u0932\u0928 \u0926\u093f\u0916\u093e\u0924\u0940 \u0935\u0940\u0921\u093f\u092f\u094b \u091a\u092e\u094b\u0932\u0940 \u092e\u0947\u0902 \u0917\u094d\u0932\u0947\u0936\u093f\u092f\u0930 \u092b\u091f\u0928\u0947 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-quote-towards-critical-thinkers-of-rss-by-tibets-spiritual-leader-14th-dalai-lama-viral-on-facebook-11964",
        headline:
          "\u09ab\u09c7\u09b8\u09ac\u09c1\u0995\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 RSS \u09b8\u09ae\u09be\u09b2\u09cb\u099a\u0995\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09b8\u0999\u09cd\u0997\u09c7 Dalai Lama-\u098f\u09b0 \u09ad\u09c1\u09df\u09cb \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-uttarakhand-glacier-burst-viral-image-snow-fall-down-mountain-alaska-melting-glacier-11935",
        headline:
          "Alaska \u09a4\u09c7 \u0997\u09b2\u09a4\u09c7 \u09a5\u09be\u0995\u09be \u09b9\u09bf\u09ae\u09ac\u09be\u09b9\u09c7\u09b0 \u099b\u09ac\u09bf Uttarakhand \u098f\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/fact-check-west-bengal-education-minister-partha-chatterjees-statement-mocking-sc-st-students-of-west-bengal-is-fake-11894",
        headline:
          "\u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 SC-ST \u09aa\u09a1\u09bc\u09c1\u09df\u09be\u09a6\u09c7\u09b0 \u09a8\u09bf\u09df\u09c7 Partha Chatterjee \u098f\u09b0 \u09ad\u09c1\u09df\u09cb \u09ae\u09a8\u09cd\u09a4\u09ac\u09cd\u09af",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-abp-ananda-graphics-about-bjp-mp-dilip-ghoshs-remarks-on-suvendu-adhikari-viral-11898",
        headline:
          "\u09b6\u09c1\u09ad\u09c7\u09a8\u09cd\u09a6\u09c1 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u09a6\u09bf\u09b2\u09c0\u09aa \u0998\u09cb\u09b7\u09c7\u09b0 \u0989\u0995\u09cd\u09a4\u09bf \u09ac\u09b2\u09c7 \u09ad\u09c1\u09df\u09cb ABP Ananda \u0997\u09cd\u09b0\u09be\u09ab\u09bf\u0995 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/09/fact-check-10-yr-old-video-of-sharad-pawar-being-slapped-shared-as-recent/",
        headline:
          "Fact Check: 10-Yr-Old Video Of Sharad Pawar Being Slapped Shared As Recent",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/09/fact-check-video-of-avalanche-from-nepal-shared-as-recent-uttarakhand-glacier-burst/",
        headline:
          "Fact Check: Video Of Avalanche From Nepal Shared As Recent Uttarakhand Glacier Burst",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/09/fact-check-this-video-of-students-caught-cheating-in-bihar-board-exams-is-from-2017/",
        headline:
          "Fact Check: This Video Of Students Caught Cheating In Bihar Board Exams Is From 2017",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/09/fact-check-morphed-tweet-attributed-to-singer-caralisa-monteiro-goes-viral/",
        headline:
          "Fact Check: Morphed Tweet Attributed To Singer Caralisa Monteiro Goes Viral",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/11/fact-check-this-picture-of-melting-glacier-is-not-from-uttarakhand-heres-the-truth/",
        headline:
          "Fact Check: This Picture Of Melting Glacier Is NOT From Uttarakhand; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/13/fact-check-assembly-election-dates-for-these-5-states-have-not-been-announced-yet-viral-post-is-fake/",
        headline:
          "Fact Check: Assembly Election Dates For These 5 States Have Not Been Announced Yet; Viral Post Is Fake",
      },
      {
        url:
          "https://factly.in/old-video-of-students-getting-expelled-in-bihar-passed-off-as-recent-incident/",
        headline:
          " Old video of students getting expelled in Bihar is passed off as a recent incident",
      },
      {
        url:
          "https://factly.in/video-from-nepal-falsely-shared-as-visuals-of-the-glacial-burst-in-chamoli-uttarakhand/",
        headline:
          " Video from Nepal falsely shared as visuals of the glacial burst in Chamoli (Uttarakhand)",
      },
      {
        url:
          "https://factly.in/2011-video-falsely-shared-as-recent-video-of-sharad-pawar-getting-slapped-after-advising-sachin/",
        headline:
          " 2011 video falsely shared as recent video of Sharad Pawar getting slapped after advising Sachin",
      },
      {
        url:
          "https://factly.in/morphed-tweet-shared-as-bollywood-singer-caralisa-monterio-tweet-on-hindu-mukt-india/",
        headline:
          " Morphed tweet shared as Bollywood singer Caralisa Monterio\u2019s tweet on Hindu Mukt India",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-was-sharad-pawar-slapped-in-public-for-his-statement-on-tendulkar-1767175-2021-02-08",
        headline:
          "Fact Check: Was Sharad Pawar slapped in public for his statement on Tendulkar?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-avalanche-video-from-nepal-shared-as-uttarakhand-glacier-burst-1767146-2021-02-08",
        headline:
          "Fact Check: Avalanche video from Nepal shared as Uttarakhand glacier burst",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-falsely-linked-to-sharad-pawars-reaction-to-tendulkar-674095",
        headline:
          "FACT CHECK: Old video falsely linked to Sharad Pawar's reaction to Tendulkar",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-showing-students-expelled-for-cheating-dates-back-to-2017-674053",
        headline:
          "Fact Check: Viral video showing students expelled for cheating dates back to 2017",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-nepal-avalanche-passed-off-as-uttarakhand-glacier-burst-674047",
        headline:
          "FACT CHECK: Nepal avalanche passed off as Uttarakhand glacier burst",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/fake-assam-assembly-election-date-viral/",
        headline:
          "Fact Check : \u0985\u09b8\u09ae \u09ac\u09bf\u09a7\u09be\u09a8\u09b8\u09ad\u09be \u09a8\u09bf\u09f0\u09cd\u09ac\u09be\u099a\u09a8\u09f0 \u09a4\u09be\u09f0\u09bf\u0996 \u098f\u0987\u09aa\u09f0\u09cd\u09af\u09a8\u09cd\u09a4 \u0998\u09cb\u09b7\u09a3\u09be \u09b9\u09cb\u09f1\u09be \u09a8\u09be\u0987, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09aa\u09cb\u09b7\u09cd\u099f \u09ad\u09c1\u09f1\u09be",
      },
    ],
    "5": [
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-nitin-gadkari-viral-video-pm-narendra-modi-andolanjivi-farmers-protest-old-video-anna-hazare-ravishankar-prasad-lokpal-movement-manmohan-singh-11946",
        headline:
          "Narendra Modi \u098f\u09b0 \u09b8\u09ae\u09be\u09b2\u09cb\u099a\u09a8\u09be \u0995\u09b0\u099b\u09c7\u09a8 Nitin Gadkari: \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/uttarakhand-glacier-burst-uttarakhand-floods-2013-rss-volunteers-workers-food-and-relief-viral-image-11916",
        headline: "Photo Of RSS Relief Work In Uttarakhand Is From 2013",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/fact-check-old-image-jammu-kashmir-flood-2014-khalsa-aid-relief-uttarakhand-flood-glacier-curst-chamoli-11936",
        headline:
          "U'khand Tragedy: Old Images of Khalsa Aid's Relief Work Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/edited-video-shared-as-pm-modi-evading-question-on-fuel-price-hike-11934",
        headline:
          "Edited Video Shared As PM Modi Evading Question On Fuel Price Hike",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/hindu-seer-who-slammed-modi-shah-also-criticised-mamata-banerjee-11888",
        headline:
          "Hindu Seer Who Slammed Modi, Shah Also Criticised Mamata Banerjee",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-nitin-gadkari-viral-video-pm-narendra-modi-andolanjivi-farmers-protest-old-video-anna-hazare-ravishankar-prasad-lokpal-movement-manmohan-singh-11910",
        headline:
          "Nearly Decade Old Video Falsely Shared As Gadkari Criticising PM Modi",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/go-back-modi-photo-from-west-bengal-revived-as-tamil-nadu-11968",
        headline: "'Go Back Modi' Photo From Kolkata Resurfaces As Chennai",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/nitin-gadkari-statement-narendra-modi-manmohan-singh-anna-hazare-kisan-andolan-11919",
        headline:
          "\u0915\u094d\u092f\u093e \u0907\u0938 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0947\u0902 \u0928\u093f\u0924\u093f\u0928 \u0917\u0921\u0915\u0930\u0940 \u0928\u0930\u0947\u0902\u0926\u094d\u0930 \u092e\u094b\u0926\u0940 \u0915\u0940 \u0906\u0932\u094b\u091a\u0928\u093e \u0915\u0930 \u0930\u0939\u0947 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/rss-relief-work-2013-kedarnath-floods-fake-news-chamoli-glacier-burst-11925",
        headline:
          "\u0906\u0930\u090f\u0938\u090f\u0938 \u0915\u0947 \u092c\u091a\u093e\u0935 \u0915\u093e\u0930\u094d\u092f \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 2013 \u0915\u0947\u0926\u093e\u0930\u0928\u093e\u0925 \u0924\u094d\u0930\u093e\u0938\u0926\u0940 \u0915\u0940 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/old-image-of-rescue-ops-during-disaster-by-army-men-shared-linked-to-uttarakhand-glacier-burst-chamoli-rishiganga-dhauliganga-floods-11917",
        headline:
          "\u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 Uttarakhand \u09ac\u09bf\u09aa\u09b0\u09cd\u09af\u09df\u09c7 \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09b8\u09c7\u09a8\u09be\u09b0 \u0989\u09a6\u09cd\u09a7\u09be\u09b0\u0995\u09be\u099c",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-old-image-jammu-kashmir-flood-2014-khalsa-aid-relief-uttarakhand-flood-glacier-burst-chamoli-11972",
        headline:
          "Uttarakhand: \u0996\u09be\u09b2\u09b8\u09be\u09b0 \u09a4\u09cd\u09b0\u09be\u09a3\u0995\u09be\u09b0\u09cd\u09af\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-uttarakhand-glacier-burst-uttarakhand-floods-2013-rss-volunteers-workers-food-and-relief-viral-image-11947",
        headline:
          "\u0989\u09a4\u09cd\u09a4\u09b0\u09be\u0996\u09a3\u09cd\u09a1 \u09ac\u09bf\u09aa\u09b0\u09cd\u09af\u09df\u09c7 RSS \u098f\u09b0 \u09a4\u09cd\u09b0\u09be\u09a3 \u0995\u09be\u09b0\u09cd\u09af \u09ac\u09b2\u09c7 \u09e8\u09e6\u09e7\u09e9 \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/hindu-seer-who-slammed-modi-shah-also-criticised-mamata-banerjee-11900",
        headline:
          "\u09ae\u09cb\u09a6\u09c0 \u0993 \u09b6\u09be\u09b9\u09c7\u09b0 \u09b8\u09ae\u09be\u09b2\u09cb\u099a\u09a8\u09be \u0995\u09b0\u09be \u09b9\u09bf\u09a8\u09cd\u09a6\u09c1 \u09b8\u09a8\u09cd\u09a8\u09cd\u09af\u09be\u09b8\u09c0 \u09ae\u09ae\u09a4\u09be\u09b0 \u09b8\u09ae\u09be\u09b2\u09cb\u099a\u09a8\u09be \u0995\u09b0\u09c7\u099b\u09c7\u09a8",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/10/fact-check-pm-modi-did-not-try-to-dodge-question-related-to-petrol-price-hike-video-is-altered/",
        headline:
          "Fact Check: PM Modi Did Not Try To Dodge Question Related To Petrol Price Hike; Video is Altered",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/11/fact-check-chetan-bhagats-speech-from-2008-falsely-attributed-to-ratan-tata/",
        headline:
          "Fact Check: Chetan Bhagat\u2019s Speech From 2008 Falsely Attributed To Ratan Tata",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/11/fact-check-did-nitin-gadkari-criticize-pm-modi-for-his-andolanjeevi-remark-heres-the-truth/",
        headline:
          "Fact Check: Did Nitin Gadkari Criticize PM Modi For His \u2018Andolanjeevi\u2019 Remark? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/02/12/fact-check-old-picture-of-khalsa-aid-volunteers-linked-to-relief-work-following-uttarakhand-glacier-burst/",
        headline:
          "Fact Check: Old Picture Of Khalsa Aid Volunteers Linked To Relief Work Following Uttarakhand Glacier Burst",
      },
      {
        url:
          "https://factly.in/chetan-bhagats-speech-at-symbiosis-is-passed-off-as-that-of-ratan-tatas/",
        headline:
          " Chetan Bhagat\u2019s speech at Symbiosis is passed off as that of Ratan Tata\u2019s",
      },
      {
        url:
          "https://factly.in/telugu-edited-video-shared-as-pm-modi-asking-the-man-who-asked-about-petrol-prices-to-sit/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f, \u0c2a\u0c46\u0c1f\u0c4d\u0c30\u0c4b\u0c32\u0c4d \u0c27\u0c30 \u0c17\u0c41\u0c30\u0c3f\u0c02\u0c1a\u0c3f \u0c05\u0c21\u0c3f\u0c17\u0c3f\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f\u0c28\u0c3f \u0c2e\u0c4b\u0c26\u0c40 \u0c15\u0c42\u0c30\u0c4d\u0c1a\u0c4b\u0c2e\u0c28\u0c3f \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/2018-image-of-khalsa-aids-relief-work-passed-off-as-that-during-recent-glacier-burst-in-uttarakhand/",
        headline:
          " 2018 image of Khalsa Aid\u2019s relief work passed off linking it to recent glacier burst in Uttarakhand",
      },
      {
        url:
          "https://factly.in/edited-video-shared-as-pm-modi-asking-the-man-who-asked-about-petrol-prices-to-sit/",
        headline:
          " Edited video shared as PM Modi asking the man who asked about petrol prices to sit",
      },
      {
        url:
          "https://factly.in/old-image-of-rss-relief-work-in-uttarakhand-is-passed-off-as-recent-one/",
        headline:
          " Old image of RSS relief work in Uttarakhand is passed off as a recent one",
      },
      {
        url:
          "https://factly.in/2011-video-of-nitin-gadkari-criticizing-the-then-government-shared-as-recent/",
        headline:
          " 2011 video of Nitin Gadkari criticizing the then Government shared as a recent one",
      },
      {
        url:
          "https://factly.in/telugu-between-2015-19-the-central-government-has-granted-indian-citizenship-to-only-18855-foreigners/",
        headline:
          " 2015-19 \u0c2e\u0c27\u0c4d\u0c2f\u0c32\u0c4b \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 18,855 \u0c2e\u0c02\u0c26\u0c3f \u0c35\u0c3f\u0c26\u0c47\u0c36\u0c40\u0c2f\u0c41\u0c32\u0c15\u0c41 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36 \u0c2a\u0c4c\u0c30\u0c38\u0c24\u0c4d\u0c35 \u0c17\u0c41\u0c30\u0c4d\u0c24\u0c3f\u0c02\u0c2a\u0c41\u0c28\u0c3f\u0c1a\u0c4d\u0c1a\u0c3f\u0c02\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-2011-video-of-nitin-gadkari-criticizing-the-then-government-shared-as-a-recent-one/",
        headline:
          " 2011\u0c32\u0c4b \u0c28\u0c3f\u0c24\u0c3f\u0c28\u0c4d \u0c17\u0c21\u0c4d\u0c15\u0c30\u0c3f \u0c05\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c35\u0c3f\u0c2e\u0c30\u0c4d\u0c36\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f\u0c26\u0c3f\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-bhagat-singh-sukhdev-and-rajguru-were-not-sentenced-to-death-on-february-14-1769186-2021-02-14",
        headline:
          "Fact Check: Bhagat Singh, Sukhdev and Rajguru were not sentenced to death on February 14",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photos-of-khalsa-aids-relief-work-in-jk-bihar-passed-off-as-uttarakhand-flash-flood-aid-674188",
        headline:
          "Fact Check: Photos of Khalsa Aid's relief work in J&K, Bihar passed off as Uttarakhand flash flood aid",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-picture-of-rss-relief-work-dates-back-to-2013-uttarakhand-floods-not-recent-glacier-burst-tragedy-674107",
        headline:
          "Fact check: Viral picture of RSS relief work dates back to 2013 Uttarakhand floods, not recent glacier burst tragedy",
      },
    ],
  },
  numpy_seed: 40,
  number_of_articles: 139,
}
