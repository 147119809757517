export const data = {
  mdsDat: {
    x: [
      -0.08281326822961506,
      -0.08187416540108243,
      -0.10012604593885713,
      0.43349849857209033,
      -0.16868501900253605,
    ],
    y: [
      0.23508238513516386,
      -0.38960720767084733,
      0.04768242380564381,
      0.017214254405705863,
      0.0896281443243341,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      18.69904502644144,
      17.53748002305634,
      24.50619247199903,
      18.11848474419756,
      21.138797734305626,
    ],
  },
  tinfo: {
    Term: [
      "bihar",
      "singh",
      "pakistan",
      "bridge",
      "rally",
      "hyderabad",
      "meeting",
      "leader",
      "election",
      "woman",
      "army",
      "rally_pakistan",
      "indian_flag",
      "pakistan",
      "donald",
      "tank",
      "trump",
      "story",
      "protest",
      "save",
      "shared_claim",
      "flag",
      "linga",
      "ghosh",
      "minor",
      "victim",
      "street",
      "west",
      "indian",
      "opposition",
      "rally",
      "karachi",
      "cleric",
      "morphed",
      "girl",
      "bengal",
      "bjp",
      "singh",
      "attend",
      "flog",
      "neet",
      "topper",
      "australia",
      "chauhan",
      "bhagat",
      "shatrughan",
      "nigerian",
      "shivraj_singh",
      "bhagat_singh",
      "shivraj",
      "tej",
      "justice",
      "morph",
      "bear",
      "pratap",
      "sushant",
      "man",
      "kashmir",
      "apple",
      "fish",
      "pm",
      "muzaffarpur",
      "liquor",
      "voter",
      "packet",
      "relation",
      "flyover",
      "category",
      "landing",
      "bihar",
      "meeting",
      "bottle",
      "yogi",
      "adityanath",
      "flood",
      "yogi_adityanath",
      "hyderabad_flood",
      "bihar_election",
      "shoe",
      "leader",
      "election",
      "hyderabad",
      "public",
      "expressway",
      "statement",
      "delhi",
      "make",
      "delhi_mumbai",
      "singapore",
      "mumbai",
      "bridge",
      "unity",
      "teenager",
      "gates",
      "patent",
      "bill",
      "cream",
      "employment",
      "caption",
      "punjab",
      "metro",
      "kejriwal",
      "mask",
      "color",
      "murder",
      "rank",
      "hindu",
      "teacher",
      "behead",
      "uttar",
      "uttar_pradesh",
      "arrest",
      "zodiac",
      "member",
      "telangana",
      "religious",
      "kasganj",
      "second",
      "woman",
      "case",
      "july",
      "name",
      "attribute",
      "pradesh",
      "hyderabad",
      "quick",
    ],
    Freq: [
      15.0,
      10.0,
      8.0,
      6.0,
      8.0,
      11.0,
      5.0,
      7.0,
      7.0,
      7.0,
      2.9999772238073446,
      2.9999772238073446,
      2.9999772238073446,
      8.999931671422035,
      0.9999924079357815,
      1.999984815871563,
      1.999984815871563,
      0.9999924079357815,
      0.9999924079357815,
      0.9999924079357815,
      3.999969631743126,
      3.999969631743126,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      3.999969631743126,
      1.999984815871563,
      5.999954447614689,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      2.9999772238073446,
      1.999984815871563,
      1.999984815871563,
      10.999832668766,
      0.9999847880696365,
      0.9999847880696365,
      3.999939152278546,
      0.9999847880696365,
      0.9999847880696365,
      1.999969576139273,
      3.999939152278546,
      0.9999847880696365,
      0.9999847880696365,
      3.999939152278546,
      3.999939152278546,
      3.999939152278546,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      2.9999543642089095,
      1.999969576139273,
      1.999969576139273,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      3.9999680982732073,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      15.99987239309283,
      5.99995214740981,
      4.999960122841508,
      4.999960122841508,
      3.9999680982732073,
      3.9999680982732073,
      3.9999680982732073,
      2.999976073704905,
      2.999976073704905,
      2.999976073704905,
      6.999944171978113,
      6.999944171978113,
      6.999944171978113,
      3.9999680982732073,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      6.000005909520809,
      1.0000009849201348,
      1.0000009849201348,
      4.000003939680539,
      2.0000019698402696,
      4.000003939680539,
      1.000027738969074,
      1.000027738969074,
      1.000027738969074,
      2.000055477938148,
      1.000027738969074,
      1.000027738969074,
      1.000027738969074,
      1.000027738969074,
      4.000110955876296,
      1.000027738969074,
      4.000110955876296,
      3.000083216907222,
      3.000083216907222,
      3.000083216907222,
      3.000083216907222,
      3.000083216907222,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      6.000166433814444,
      2.000055477938148,
      2.000055477938148,
      4.000110955876296,
      2.000055477938148,
      4.000110955876296,
      4.000110955876296,
      3.000083216907222,
    ],
    Total: [
      15.0,
      10.0,
      8.0,
      6.0,
      8.0,
      11.0,
      5.0,
      7.0,
      7.0,
      7.0,
      2.9999772238073446,
      2.9999772238073446,
      2.9999772238073446,
      8.999931671422035,
      0.9999924079357815,
      1.999984815871563,
      1.999984815871563,
      0.9999924079357815,
      0.9999924079357815,
      0.9999924079357815,
      3.999969631743126,
      3.999969631743126,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      4.9999973707122,
      1.999984815871563,
      8.999930521319595,
      1.999984815871563,
      1.999984815871563,
      1.999984815871563,
      4.999989750846055,
      1.999984815871563,
      5.999945677646105,
      10.999832668766,
      0.9999847880696365,
      0.9999847880696365,
      3.999939152278546,
      0.9999847880696365,
      0.9999847880696365,
      1.999969576139273,
      3.999939152278546,
      0.9999847880696365,
      0.9999847880696365,
      3.999939152278546,
      3.999939152278546,
      3.999939152278546,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      1.999969576139273,
      4.999974511113765,
      1.999969576139273,
      1.999969576139273,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      3.9999680982732073,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      0.9999920245683018,
      15.99987239309283,
      5.99995214740981,
      4.999960122841508,
      4.999960122841508,
      3.9999680982732073,
      3.9999680982732073,
      3.9999680982732073,
      2.999976073704905,
      2.999976073704905,
      2.999976073704905,
      7.9999289600477494,
      7.9999289600477494,
      11.00005512785441,
      4.999995837242281,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      3.0000029547604044,
      6.000005909520809,
      1.0000009849201348,
      1.0000009849201348,
      4.000003939680539,
      2.0000019698402696,
      4.999996347616321,
      1.000027738969074,
      1.000027738969074,
      1.000027738969074,
      2.000055477938148,
      1.000027738969074,
      1.000027738969074,
      1.000027738969074,
      1.000027738969074,
      4.000110955876296,
      1.000027738969074,
      4.000110955876296,
      3.000083216907222,
      3.000083216907222,
      3.000083216907222,
      3.000083216907222,
      3.000083216907222,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      2.000055477938148,
      7.000158841750226,
      2.000055477938148,
      2.000055477938148,
      5.0001029804445976,
      2.000055477938148,
      6.000095005012899,
      11.00005512785441,
      5.000052793046495,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.9828,
      -3.9828,
      -3.9828,
      -2.8842,
      -5.0814,
      -4.3883,
      -4.3883,
      -5.0814,
      -5.0814,
      -5.0814,
      -3.6951,
      -3.6951,
      -4.3883,
      -4.3883,
      -4.3883,
      -4.3883,
      -4.3883,
      -4.3883,
      -3.6951,
      -4.3883,
      -3.2896,
      -4.3883,
      -4.3883,
      -4.3883,
      -3.9828,
      -4.3883,
      -4.3883,
      -2.6194,
      -5.0173,
      -5.0173,
      -3.631,
      -5.0173,
      -5.0173,
      -4.3241,
      -3.631,
      -5.0173,
      -5.0173,
      -3.631,
      -3.631,
      -3.631,
      -4.3241,
      -4.3241,
      -4.3241,
      -4.3241,
      -4.3241,
      -4.3241,
      -3.9187,
      -4.3241,
      -4.3241,
      -5.3519,
      -5.3519,
      -5.3519,
      -3.9656,
      -5.3519,
      -5.3519,
      -5.3519,
      -5.3519,
      -5.3519,
      -5.3519,
      -2.5793,
      -3.5601,
      -3.7424,
      -3.7424,
      -3.9656,
      -3.9656,
      -3.9656,
      -4.2532,
      -4.2532,
      -4.2532,
      -3.4059,
      -3.4059,
      -3.4059,
      -3.9656,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.2581,
      -5.0499,
      -5.0499,
      -3.6636,
      -4.3567,
      -3.6636,
      -5.204,
      -5.204,
      -5.204,
      -4.5109,
      -5.204,
      -5.204,
      -5.204,
      -5.204,
      -3.8177,
      -5.204,
      -3.8177,
      -4.1054,
      -4.1054,
      -4.1054,
      -4.1054,
      -4.1054,
      -4.5109,
      -4.5109,
      -4.5109,
      -4.5109,
      -4.5109,
      -4.5109,
      -3.4122,
      -4.5109,
      -4.5109,
      -3.8177,
      -4.5109,
      -3.8177,
      -3.8177,
      -4.1054,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.6767,
      1.4535,
      1.6767,
      1.2712,
      1.6767,
      1.6767,
      1.6767,
      1.1659,
      1.6767,
      0.5781,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.7408,
      1.23,
      1.7408,
      1.7408,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.4062,
      1.2727,
      1.2727,
      0.9542,
      1.1831,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.7082,
      1.4851,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.5541,
      1.3999,
      1.5541,
      1.5541,
      1.3309,
      1.5541,
      1.1486,
      0.5425,
      1.0433,
    ],
  },
  "token.table": {
    Topic: [
      3,
      2,
      1,
      5,
      2,
      5,
      2,
      2,
      5,
      1,
      2,
      2,
      3,
      3,
      1,
      4,
      1,
      2,
      3,
      3,
      4,
      5,
      5,
      3,
      2,
      1,
      5,
      5,
      4,
      4,
      1,
      2,
      3,
      5,
      4,
      3,
      1,
      2,
      3,
      3,
      4,
      1,
      1,
      2,
      5,
      5,
      3,
      5,
      3,
      1,
      5,
      1,
      5,
      2,
      1,
      5,
      2,
      5,
      3,
      2,
      3,
      1,
      3,
      4,
      1,
      2,
      5,
      5,
      3,
      5,
      5,
      1,
      2,
      1,
      4,
      5,
      3,
      3,
      5,
      2,
      2,
      1,
      3,
      1,
      4,
      3,
      3,
      5,
      2,
      1,
      3,
      5,
      5,
      2,
      5,
      1,
      3,
      1,
      5,
      3,
      5,
      1,
      5,
      1,
      2,
      2,
      2,
      3,
      4,
      2,
      4,
      1,
      1,
      2,
      1,
      5,
      4,
      2,
      5,
      2,
      1,
      4,
      5,
      5,
      1,
      3,
      1,
      1,
      5,
      3,
      3,
      5,
    ],
    Freq: [
      1.0000079754953062,
      1.0000152121617698,
      1.0000075921218583,
      0.999972261800355,
      1.0000152121617698,
      0.9999722618003551,
      1.0000152121617698,
      1.0000152121617698,
      0.999972261800355,
      1.0000075921218583,
      1.0000152121617698,
      1.0000152121617698,
      1.0000079754953062,
      1.0000079754953064,
      0.20000014609545388,
      0.8000005843818155,
      0.33333635126920663,
      0.16666817563460332,
      0.5000045269038099,
      1.0000079754953064,
      0.9999990150808353,
      0.9999722618003551,
      0.9999722618003551,
      1.0000079754953062,
      1.0000152121617698,
      1.0000075921218583,
      0.9999722618003551,
      0.9999722618003551,
      0.9999990150808353,
      0.9999990150808353,
      1.0000075921218583,
      0.1250011100091108,
      0.8750077700637755,
      0.9999722618003551,
      0.9999990150808353,
      1.0000079754953062,
      1.0000075921218583,
      1.0000152121617698,
      1.0000079754953062,
      1.0000079754953062,
      0.9999990150808353,
      1.0000075921218583,
      0.6000012299009945,
      0.20000040996699817,
      0.20000040996699817,
      0.9999722618003551,
      0.6363604471649015,
      0.3636345412370866,
      1.0000079754953064,
      0.8000004206862692,
      0.2000001051715673,
      1.0000075921218583,
      0.9999722618003551,
      1.0000152121617698,
      1.0000075921218583,
      0.9999722618003551,
      1.0000152121617698,
      0.9999722618003551,
      1.0000079754953062,
      0.1250011100091108,
      0.8750077700637755,
      1.0000075921218583,
      1.0000079754953062,
      0.9999990150808353,
      0.20000101956064692,
      0.6000030586819407,
      0.20000101956064692,
      0.9999722618003551,
      1.0000079754953064,
      0.9999722618003551,
      0.9999722618003551,
      1.0000075921218583,
      1.0000152121617698,
      1.0000075921218583,
      0.9999990150808353,
      0.9999722618003551,
      1.0000079754953062,
      0.19999588086705414,
      0.7999835234682166,
      1.0000152121617698,
      1.0000152121617698,
      1.0000075921218583,
      1.0000079754953062,
      1.0000075921218583,
      0.9999990150808353,
      1.0000079754953062,
      0.3333280553606335,
      0.666656110721267,
      1.0000152121617698,
      1.0000075921218583,
      0.8000006660417895,
      0.20000016651044739,
      0.9999722618003551,
      0.3999957766008736,
      0.5999936649013103,
      0.6666718132753167,
      0.33333590663765833,
      1.0000075921218583,
      0.9999722618003551,
      1.0000079754953062,
      0.9999722618003551,
      1.0000075921218583,
      0.9999722618003551,
      1.0000075921218583,
      1.0000152121617698,
      1.0000152121617698,
      1.0000152121617698,
      1.0000079754953064,
      0.9999990150808353,
      1.00001521216177,
      0.9999990150808353,
      1.0000075921218583,
      1.0000075921218583,
      1.0000152121617698,
      1.0000075921218583,
      0.999972261800355,
      0.9999990150808353,
      1.0000152121617698,
      0.9999722618003551,
      1.0000152121617698,
      1.0000075921218583,
      0.9999990150808353,
      0.999972261800355,
      0.999972261800355,
      1.0000075921218583,
      1.0000079754953062,
      1.0000075921218583,
      0.14285390126232814,
      0.8571234075739688,
      1.0000079754953064,
      1.0000079754953062,
      0.9999722618003551,
    ],
    Term: [
      "adityanath",
      "apple",
      "army",
      "arrest",
      "attend",
      "attribute",
      "australia",
      "bear",
      "behead",
      "bengal",
      "bhagat",
      "bhagat_singh",
      "bihar",
      "bihar_election",
      "bill",
      "bill",
      "bjp",
      "bjp",
      "bjp",
      "bottle",
      "bridge",
      "caption",
      "case",
      "category",
      "chauhan",
      "cleric",
      "color",
      "cream",
      "delhi",
      "delhi_mumbai",
      "donald",
      "election",
      "election",
      "employment",
      "expressway",
      "fish",
      "flag",
      "flog",
      "flood",
      "flyover",
      "gates",
      "ghosh",
      "girl",
      "girl",
      "girl",
      "hindu",
      "hyderabad",
      "hyderabad",
      "hyderabad_flood",
      "indian",
      "indian",
      "indian_flag",
      "july",
      "justice",
      "karachi",
      "kasganj",
      "kashmir",
      "kejriwal",
      "landing",
      "leader",
      "leader",
      "linga",
      "liquor",
      "make",
      "man",
      "man",
      "man",
      "mask",
      "meeting",
      "member",
      "metro",
      "minor",
      "morph",
      "morphed",
      "mumbai",
      "murder",
      "muzaffarpur",
      "name",
      "name",
      "neet",
      "nigerian",
      "opposition",
      "packet",
      "pakistan",
      "patent",
      "pm",
      "pradesh",
      "pradesh",
      "pratap",
      "protest",
      "public",
      "public",
      "punjab",
      "quick",
      "quick",
      "rally",
      "rally",
      "rally_pakistan",
      "rank",
      "relation",
      "religious",
      "save",
      "second",
      "shared_claim",
      "shatrughan",
      "shivraj",
      "shivraj_singh",
      "shoe",
      "singapore",
      "singh",
      "statement",
      "story",
      "street",
      "sushant",
      "tank",
      "teacher",
      "teenager",
      "tej",
      "telangana",
      "topper",
      "trump",
      "unity",
      "uttar",
      "uttar_pradesh",
      "victim",
      "voter",
      "west",
      "woman",
      "woman",
      "yogi",
      "yogi_adityanath",
      "zodiac",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fake-news/image-of-indian-flag-at-pakistan-opposition-rally-is-fake-10302",
        headline: "Image Of Indian Flag At Pakistan Opposition Rally Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-russian-artillery-show-shared-as-armenia-azerbaijan-conflict-10308",
        headline:
          "Video Of Russian Artillery Show Shared As Armenia-Azerbaijan Conflict",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/set-of-old-images-revived-with-civil-war-in-karachi-claims-10349",
        headline:
          "Set Of Old Images Revived With 'Civil War In Karachi' Claims",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-video-of-a-maulvi-molesting-a-minor-girl-is-from-pakistan-10297",
        headline:
          "\u0928\u093e\u092c\u093e\u0932\u093f\u0917 \u0938\u0947 \u091b\u0947\u0921\u093c\u091b\u093e\u0921\u093c \u0915\u0930\u0928\u0947 \u0935\u093e\u0932\u0947 \u092e\u094c\u0932\u0935\u0940 \u0915\u093e \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928 \u0938\u0947 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-of-indian-flag-at-pakistan-opposition-rally-is-fake-10309",
        headline:
          "\u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09c7 \u09ac\u09bf\u09b0\u09cb\u09a7\u09c0\u09a6\u09c7\u09b0 \u09b8\u09ae\u09be\u09ac\u09c7\u09b6\u09c7 \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09aa\u09a4\u09be\u0995\u09be\u09b0 \u099b\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-video-of-a-maulvi-molesting-a-minor-is-from-pakistan-10315",
        headline:
          "\u098f\u0995 \u09ae\u09cc\u09b2\u09ac\u09bf\u09b0 \u09a8\u09be\u09ac\u09be\u09b2\u09bf\u0995\u09be\u0995\u09c7 \u09af\u09cc\u09a8 \u09a8\u09bf\u0997\u09cd\u09b0\u09b9\u09c7\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09c7\u09b0",
      },
      {
        url:
          "https://factly.in/telugu-the-woman-hugged-by-priyanka-gandhi-hugged-in-this-photo-is-the-mother-of-alleged-hathras-rape-victim/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c3f\u0c2f\u0c3e\u0c02\u0c15 \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c13\u0c26\u0c3e\u0c30\u0c41\u0c38\u0c4d\u0c24\u0c42\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c28\u0c15\u0c4d\u0c38\u0c32\u0c48\u0c1f\u0c4d \u0c15\u0c3e\u0c26\u0c41, \u0c39\u0c24\u0c4d\u0c30\u0c3e\u0c38\u0c4d \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c30\u0c3e\u0c32\u0c3f \u0c24\u0c32\u0c4d\u0c32\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-govt-scheme-to-fund-the-travel-expenses-of-tourists-who-visit-15-destinations-is-not-yet-launched/",
        headline:
          " 15 \u0c2a\u0c30\u0c4d\u0c2f\u0c3e\u0c1f\u0c15 \u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c3e\u0c32\u0c41 \u0c38\u0c02\u0c26\u0c30\u0c4d\u0c36\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c3e\u0c30\u0c3f\u0c15\u0c3f \u0c05\u0c2f\u0c3f\u0c28 \u0c16\u0c30\u0c4d\u0c1a\u0c41 \u0c24\u0c3f\u0c30\u0c3f\u0c17\u0c3f\u0c1a\u0c4d\u0c1a\u0c47 \u0c2a\u0c25\u0c15\u0c02 \u0c07\u0c02\u0c15\u0c3e \u0c2e\u0c4a\u0c26\u0c32\u0c35\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/an-edited-photo-is-being-falsely-shared-as-india-flag-waved-at-a-rally-in-pakistan/",
        headline:
          " An edited photo is being falsely shared as \u2018India flag waved at a rally in Pakistan\u2019",
      },
      {
        url:
          "https://factly.in/morphed-image-shared-as-shiva-linga-hanging-between-two-mountains/",
        headline:
          " Morphed image shared as Shiva Linga hanging between two mountains",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-is-not-related-to-the-current-clashes-between-pakistan-army-and-police-in-karachi/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d \u0c32\u0c4b \u0c06\u0c30\u0c4d\u0c2e\u0c40\u0c15\u0c3f , \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c15\u0c3f \u0c2e\u0c27\u0c4d\u0c2f \u0c1c\u0c30\u0c41\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c17\u0c4a\u0c21\u0c35\u0c32\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/photos-from-taiwan-and-syria-shared-as-army-tanks-on-karachi-streets/",
        headline:
          " Photos from Taiwan and Syria shared as \u2018Army tanks on Karachi streets\u2019.",
      },
      {
        url:
          "https://factly.in/telugu-fake-photos-shared-as-mahatma-gandhi-moving-closely-with-women/",
        headline:
          " \u0c2b\u0c47\u0c15\u0c4d \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f \u0c05\u0c2e\u0c4d\u0c2e\u0c3e\u0c2f\u0c3f\u0c32\u0c24\u0c4b \u0c1a\u0c28\u0c41\u0c35\u0c41\u0c17\u0c3e \u0c09\u0c02\u0c1f\u0c42 \u0c21\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2e\u0c39\u0c3e\u0c24\u0c4d\u0c2e\u0c3e \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c05\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-picture-of-swiss-music-fest-passed-off-as-trump-poll-rally-1733677-2020-10-21",
        headline:
          "Fact Check: Old picture of Swiss music fest passed off as Trump poll rally",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-braveheart-who-saved-girl-from-miscreants-goes-viral-with-a-twisted-story-1733176-2020-10-19",
        headline:
          "Fact Check: Braveheart who saved girl from miscreants goes viral\u2026 with a twisted story",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-no-cbse-has-not-reduces-50-percent-syllabus-viral-post-is-misleading/",
        headline:
          "Fact Check: \u0938\u0940\u092c\u0940\u090f\u0938\u0908 \u0915\u0947 \u0938\u093f\u0932\u0947\u092c\u0938 \u092e\u0947\u0902 50 \u092a\u094d\u0930\u0924\u093f\u0936\u0924 \u0915\u0940 \u0915\u091f\u094c\u0924\u0940 \u0935\u093e\u0932\u0940 \u0935\u093e\u092f\u0930\u0932 \u092a\u094b\u0938\u094d\u091f \u0939\u0948 \u092d\u094d\u0930\u093e\u092e\u0915",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-west-bengal-bjp-chief-dilip-ghoshs-old-picture-viral-with-fake-claim/",
        headline:
          "Fact Check: West Bengal BJP Chief Dilip Ghosh\u2019s Old Picture viral with fake claim",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/politics/fact-check-old-image-of-a-brave-sikh-got-injured-while-saving-a-girl-from-kidnapping-in-punjab-going-viral-with-misleading-claim-2/",
        headline:
          "Fact Check: \u0a05\u0a17\u0a35\u0a3e \u0a39\u0a4b\u0a23 \u0a24\u0a4b\u0a02 \u0a15\u0a41\u0a5c\u0a40 \u0a28\u0a42\u0a70 \u0a2c\u0a1a\u0a3e\u0a09\u0a02\u0a26\u0a47 \u0a38\u0a2e\u0a47\u0a02 \u0a1c\u0a16\u0a2e\u0a40 \u0a39\u0a4b\u0a0f \u0a38\u0a3f\u0a71\u0a16 \u0a26\u0a40 \u0a24\u0a38\u0a35\u0a40\u0a30 \u0a28\u0a42\u0a70 \u0a17\u0a41\u0a70\u0a2e\u0a30\u0a3e\u0a39\u0a15\u0a30\u0a28 \u0a26\u0a3e\u0a05\u0a35\u0a47 \u0a28\u0a3e\u0a32 \u0a15\u0a40\u0a24\u0a3e \u0a1c\u0a3e \u0a30\u0a3f\u0a39\u0a3e \u0a39\u0a48 \u0a35\u0a3e\u0a07\u0a30\u0a32",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/19/fact-check-dont-fall-for-fake-news-on-non-existent-muslim-regiment-of-the-indian-army/",
        headline:
          "Fact Check: Don\u2019t fall for fake news on non-existent Muslim regiment of the Indian Army",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/20/fact-check-old-picture-of-mexican-mp-protesting-against-oil-and-gas-bill-shared-with-misleading-claims/",
        headline:
          "Fact Check: Old picture of Mexican MP protesting against oil and gas bill shared with misleading claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/21/fact-check-pictures-of-rings-of-holocaust-victims-being-shared-with-misleading-claims/",
        headline:
          "Fact Check: Pictures of rings of Holocaust victims being shared with misleading claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/fact-check-this-picture-is-not-of-president-donald-trumps-rally-in-florida-find-out-the-truth/",
        headline:
          "Fact Check: This picture is NOT of President Donald Trump\u2019s rally in Florida; find out the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/fact-check-old-picture-of-wb-bjp-president-dilip-ghosh-in-hospital-being-shared-with-misleading-claims/",
        headline:
          "Fact Check: Old picture of WB BJP president Dilip Ghosh in hospital being shared with misleading claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/ffact-check-picture-of-a-gurukul-in-west-bengal-shared-as-being-from-germany/",
        headline:
          "Fact Check: Picture of a Gurukul in West Bengal shared as being from Germany",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/fact-check-was-indian-flag-waved-at-a-rally-in-pakistan-heres-the-truth/",
        headline:
          "Fact Check: Was Indian flag waved at a rally in Pakistan? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/24/fact-check-morphed-picture-of-the-shiv-linga-between-two-mountains-shared-with-false-claims/",
        headline:
          "Fact Check: Morphed picture of the Shiv Linga between two mountains shared with false claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/24/fact-check-this-video-of-man-harassing-a-woman-on-a-bus-is-from-pakistan/",
        headline:
          "Fact Check: This video of man harassing a woman on a bus is from Pakistan",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/24/fact-check-no-this-picture-of-a-tank-on-the-streets-is-not-from-pakistan-heres-the-truth/",
        headline:
          "Fact Check: No, this picture of a tank on the streets is NOT from Pakistan; here\u2019s the truth",
      },
    ],
    "2": [
      {
        url:
          "https://www.boomlive.in/fake-news/fake-account-impersonating-neet-topper-akanksha-singh-stirs-bigotry-10250",
        headline:
          "Fake Account Impersonating NEET Topper Akanksha Singh Stirs Bigotry",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/unrelated-picture-of-man-being-flogged-misidentified-as-bhagat-singh-10312",
        headline:
          "Unrelated Picture Of Man Being Flogged Misidentified As Bhagat Singh",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2018-mock-funeral-clip-of-shivraj-singh-chouhan-viral-ahead-of-bypolls-10327",
        headline:
          "2018 Mock Funeral Clip Of Shivraj Singh Chouhan Viral Ahead Of Bypolls",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-picture-shows-bhagat-singh-being-whipped-by-british-police-officer-10320",
        headline:
          "\u091a\u093e\u092c\u0941\u0915 \u0938\u0947 \u092e\u093e\u0930 \u0916\u093e\u0924\u0947 \u0935\u094d\u092f\u0915\u094d\u0924\u093f \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092d\u0917\u0924 \u0938\u093f\u0902\u0939 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-mp-citizens-shouting-mama-nahi-kasai-hai-against-shivraj-singh-chauhan-is-old-10328",
        headline:
          "\u0936\u093f\u0935\u0930\u093e\u091c \u0938\u093f\u0902\u0939 \u091a\u094c\u0939\u093e\u0928 \u0915\u0947 \u0935\u093f\u0930\u094b\u0927 \u092e\u0947\u0902 \u0928\u093e\u0930\u094b\u0902 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0905\u092c \u0939\u094b \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/mp-congress-includes-mock-drill-video-to-target-cm-shivraj-singh-chouhan-10246",
        headline:
          "\u09b6\u09bf\u09ac\u09b0\u09be\u099c \u09b8\u09bf\u0982\u09b9 \u099a\u09cc\u09b9\u09be\u09a8\u0995\u09c7 \u09a4\u09be\u0995 \u0995\u09b0\u09a4\u09c7 \u0995\u0982\u0997\u09cd\u09b0\u09c7\u09b8 \u098f\u0995\u099f\u09bf \u09ae\u09b9\u09dc\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ac\u09cd\u09af\u09ac\u09b9\u09be\u09b0 \u0995\u09b0\u09c7\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/unrelated-picture-of-man-being-flogged-misidentified-as-bhagat-singh-10337",
        headline:
          "\u09ac\u09c7\u09a4\u09c7\u09b0 \u09ae\u09be\u09b0 \u0996\u09be\u0993\u09af\u09bc\u09be \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u0995\u09c7 \u09ad\u0997\u09ce \u09b8\u09bf\u0982 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018-mock-funeral-clip-of-shivraj-singh-chouhan-viral-ahead-of-bypolls-10350",
        headline:
          "\u09b6\u09bf\u09ac\u09b0\u09be\u099c \u09b8\u09bf\u0982\u09b9\u09c7\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u09ac\u09c7\u09b0\u09a8\u09cb \u09aa\u09cd\u09b0\u09a4\u09c0\u0995\u09c0 \u09b6\u09ac\u09af\u09be\u09a4\u09cd\u09b0\u09be \u0989\u09aa\u09a8\u09bf\u09b0\u09cd\u09ac\u09be\u099a\u09a8\u09c7\u09b0 \u0986\u0997\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/no-not-all-top-five-rankers-of-neet-2020-are-muslims/",
        headline: " No, not all top five rankers of NEET 2020 are Muslims",
      },
      {
        url:
          "https://factly.in/this-photo-of-nigerian-people-holding-justice-for-sushant-placards-is-a-morphed-picture/",
        headline:
          " This Photo of Nigerian people holding \u2018Justice for Sushant\u2019 Placards is a morphed one",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-fall-for-fake-twitter-account-impersonating-shatrughan-sinha-s-son-1733237-2020-10-20",
        headline:
          "Fact Check: Netizens fall for fake Twitter account impersonating Shatrughan Sinha\u2019s son",
      },
      {
        url:
          "https://www.vishvasnews.com/world/quick-fact-check-australias-video-was-viral-again-as-tamil-nadu/",
        headline:
          "Quick Fact Check : \u0911\u0938\u094d\u091f\u094d\u0930\u0947\u0932\u093f\u092f\u093e \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0924\u092e\u093f\u0932\u0928\u093e\u0921\u0941 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u092b\u093f\u0930 \u0938\u0947 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-no-tej-pratap-did-not-get-a-doctorate-from-takshashila-university-old-image-of-him-is-being-shared-with-false-claim/",
        headline:
          "Fact Check: 2017 \u092e\u0947\u0902 \u092c\u0924\u094c\u0930 \u0938\u094d\u0935\u093e\u0938\u094d\u0925\u094d\u092f \u092e\u0902\u0924\u094d\u0930\u0940 \u092a\u091f\u0928\u093e \u0915\u0947 IGIMS \u0915\u0947 \u0926\u0940\u0915\u094d\u0937\u093e\u0902\u0924 \u0938\u092e\u093e\u0930\u094b\u0939 \u092e\u0947\u0902 \u0936\u093e\u092e\u093f\u0932 \u0939\u0941\u090f \u0924\u0947\u091c \u092a\u094d\u0930\u0924\u093e\u092a \u092f\u093e\u0926\u0935 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0917\u0932\u0924 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/world/fact-check-photograph-of-bears-eating-apples-is-not-from-kashmir/",
        headline:
          "Fact Check: \u0938\u0947\u092c \u0916\u093e\u0924\u0947 \u092d\u093e\u0932\u0941\u0913\u0902 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0936\u094d\u092e\u0940\u0930 \u0938\u0947 \u0928\u0939\u0940\u0902, \u092c\u0932\u094d\u0915\u093f \u092f\u0942\u090f\u0938 \u0915\u0947 \u0915\u0902\u091c\u0930\u094d\u0935\u0947\u0936\u0928 \u0938\u0947\u0902\u091f\u0930 \u0915\u0940 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-no-photo-showing-bears-eating-apples-is-not-from-kashmir-but-from-a-conservation-centre-in-the-us/",
        headline:
          "Fact Check: No, Photo showing bears eating apples is not from Kashmir but from a conservation centre in the US",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-govts-festival-advance-does-not-have-18-per-cent-gst-the-viral-message-is-misleading/",
        headline:
          "Fact Check: Govt\u2019s festival advance does not have 18 per cent GST, the viral message is misleading",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/society/quick-fact-check-old-image-from-1919-marshal-law-period-of-brutality-going-viral-in-the-name-of-bhagat-singh/",
        headline:
          "Quick Fact Check: \u0a07\u0a38 \u0a24\u0a38\u0a35\u0a40\u0a30 \u0a35\u0a3f\u0a1a \u0a2d\u0a17\u0a24 \u0a38\u0a3f\u0a70\u0a18 \u0a28\u0a39\u0a40\u0a02, \u0a38\u0a3e\u0a32 1919 \u0a26\u0a40 \u0a24\u0a38\u0a35\u0a40\u0a30 \u0a17\u0a32\u0a24 \u0a26\u0a3e\u0a05\u0a35\u0a47 \u0a28\u0a3e\u0a32 \u0a39\u0a4b \u0a30\u0a39\u0a40 \u0a39\u0a48 \u0a35\u0a3e\u0a07\u0a30\u0a32",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/19/fact-check-false-post-about-neet-2020-examination-toppers-goes-viral-heres-the-truth/",
        headline:
          "Fact Check: False post about NEET 2020 examination toppers goes viral; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/19/fact-check-picture-of-nigerians-demanding-justice-for-sushant-singh-rajput-is-morphed/",
        headline:
          "Fact Check: Picture of Nigerians demanding justice for Sushant Singh Rajput is morphed",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/21/fact-check-did-khushbu-sundar-slap-a-man-after-joining-bjp-recently-heres-the-truth/",
        headline:
          "Fact Check: Did Khushbu Sundar slap a man after joining BJP recently? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/21/fact-check-was-aap-leader-chetan-singh-recently-shot-at-while-saving-a-girl-from-being-raped-heres-the-truth/",
        headline:
          "Fact Check: Was AAP leader Chetan Singh recently shot at while saving a girl from being raped? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/21/fact-check-viral-news-about-major-blunder-in-neet-2020-exam-results-is-fake-heres-the-truth/",
        headline:
          "Fact Check: Viral news about major blunder in NEET 2020 exam results is FAKE; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/21/fact-check-did-lalus-son-tej-pratap-yadav-obtained-a-doctorate-degree-heres-the-truth/",
        headline:
          "Fact Check: Did Lalu\u2019s son Tej Pratap Yadav obtain a doctorate degree? Here\u2019s the truth",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fake-news/hyderabad-floods-old-photos-falsely-shared-as-congress-leaders-partying-10284",
        headline:
          "Hyderabad Floods: Old Photos Falsely Shared As Congress Leaders Partying",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-photo-of-alcohol-bottles-is-not-linked-to-bihar-polls-10304",
        headline:
          "No, This Photo Of Alcohol Bottles Is Not Linked To Bihar Polls",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/bihar-polls-old-photo-falsely-shared-as-crowd-at-yogi-adityanaths-rally-10321",
        headline:
          "Bihar Polls: Old Photo Falsely Shared As Crowd At Yogi Adityanath's Rally",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-saying-bjp-neta-welcomed-with-juto-ki-mala-not-related-to-bihar-elections-10275",
        headline:
          "\u092d\u093e\u091c\u092a\u093e \u092a\u094d\u0930\u0924\u094d\u092f\u093e\u0936\u0940 \u0915\u094b \u091c\u0942\u0924\u094b\u0902 \u0915\u0940 \u092e\u093e\u0932\u093e \u092a\u0939\u0928\u093e\u0924\u0947 \u0926\u093f\u0916\u093e\u0924\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0927\u094d\u092f\u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/viral-post-claims-bjp-jdu-alliance-is-offering-liquor-to-attract-voters-in-bihar-elections-10311",
        headline:
          "\u0915\u094d\u092f\u093e \u0936\u0930\u093e\u092c \u0915\u0940 \u092c\u094b\u0924\u0932\u094b\u0902 \u0938\u0947 \u092d\u0930\u0947 \u092f\u0947 \u092a\u0948\u0915\u0947\u091f \u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935 \u092e\u0947\u0902 \u0935\u094b\u091f\u0930\u094b\u0902 \u0915\u0947 \u0932\u093f\u090f \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/yogi-adityanath-ki-rally-me-bheed-ki-yeh-tasveer-farzi-hai-narendra-modi-2014-rally-photo-10324",
        headline:
          "\u0928\u0939\u0940\u0902, \u091c\u0928\u0938\u0948\u0932\u093e\u092c \u0926\u093f\u0916\u093e\u0924\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u0940 \u0930\u0948\u0932\u0940 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/uninstallmyntra-trends-over-old-graphic-not-made-by-company-10348",
        headline:
          "Myntra \u0926\u094d\u0935\u093e\u0930\u093e \u0928\u0939\u0940\u0902 \u092c\u0928\u093e\u092f\u093e \u0917\u092f\u093e \u0939\u0948 \u092d\u0917\u0935\u093e\u0928 \u0915\u0943\u0937\u094d\u0923 \u0914\u0930 \u0926\u094d\u0930\u094c\u092a\u0926\u0940 \u0915\u093e \u092f\u0939 \u0915\u093e\u0930\u094d\u091f\u0942\u0928",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/bihar-minister-shares-photo-from-hyderabad-to-show-development-in-state-10265",
        headline:
          "\u09b0\u09be\u099c\u09cd\u09af\u09c7\u09b0 \u0989\u09a8\u09cd\u09a8\u09df\u09a8\u09c7\u09b0 \u09a8\u099c\u09bf\u09b0 \u09ac\u09b2\u09c7 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09b6\u09c7\u09df\u09be\u09b0 \u0995\u09b0\u09b2\u09c7\u09a8 \u09b9\u09be\u09df\u09a6\u09b0\u09be\u09ac\u09be\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-clip-of-bjp-candidate-being-greeted-with-shoe-garland-is-from-mp-10303",
        headline:
          "\u09ac\u09bf\u099c\u09c7\u09aa\u09bf \u09a8\u09c7\u09a4\u09be\u0995\u09c7 \u099c\u09c1\u09a4\u09cb\u09b0 \u09ae\u09be\u09b2\u09be \u09aa\u09b0\u09be\u09a8\u09cb\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u0995\u09cd\u09b2\u09bf\u09aa\u099f\u09bf \u0986\u09b8\u09b2\u09c7 \u09ae\u09a7\u09cd\u09af\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-photo-of-alcohol-bottles-is-not-linked-to-bihar-polls-10325",
        headline:
          "\u09a8\u09be, \u09aa\u09cd\u09b2\u09be\u09b8\u09cd\u099f\u09bf\u0995\u09c7 \u09ad\u09b0\u09be \u09ae\u09a6\u09c7\u09b0 \u09ac\u09cb\u09a4\u09b2\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09ac\u09bf\u09b9\u09be\u09b0 \u09ad\u09cb\u099f\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995 \u09a8\u09c7\u0987",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/bihar-polls-old-photo-falsely-shared-as-crowd-at-yogi-adityanaths-rally-10342",
        headline:
          "\u09ac\u09bf\u09b9\u09be\u09b0 \u09ad\u09cb\u099f: \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u09b6\u09c7\u09df\u09be\u09b0 \u0995\u09b0\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u099c\u09a8\u09b8\u09ad\u09be\u09df \u09ad\u09bf\u09a1\u09bc",
      },
      {
        url:
          "https://factly.in/telugu-2018-video-shared-as-modi-participating-in-prayers-of-muslims-for-2020-bihar-elections/",
        headline:
          " \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c2a\u0c4d\u0c30\u0c3e\u0c30\u0c4d\u0c25\u0c28\u0c32\u0c4d\u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c27\u0c3e\u0c28\u0c3f \u0c2e\u0c4b\u0c26\u0c40 \u0c2a\u0c3e\u0c32\u0c4d\u0c17\u0c4a\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b 2018\u0c35 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c02\u0c26\u0c3f, \u0c07\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-relation-between-the-ncp-leader-sanjay-shinde-who-died-in-car-accident-and-palghar-lynchings/",
        headline:
          " \u0c15\u0c3e\u0c30\u0c41 \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c02\u0c32\u0c4b \u0c2e\u0c30\u0c23\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c28\u0c3e\u0c38\u0c3f\u0c15\u0c4d \u0c0e\u0c28\u0c4d\u0c38\u0c40\u0c2a\u0c40 \u0c28\u0c47\u0c24 \u0c38\u0c02\u0c1c\u0c2f\u0c4d \u0c37\u0c3f\u0c02\u0c21\u0c47 \u0c15\u0c40, \u0c2a\u0c3e\u0c32\u0c4d\u0c17\u0c30\u0c4d \u0c18\u0c1f\u0c28\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/2018-incident-of-a-bjp-leader-being-welcomed-with-a-shoe-garland-falsely-linked-to-bihar-election/",
        headline:
          " 2018 incident of a BJP leader being welcomed with a shoe garland falsely linked to Bihar election",
      },
      {
        url:
          "https://factly.in/old-photos-of-congress-party-meetings-in-hyderabad-falsely-linked-to-the-hyderabad-floods/",
        headline:
          " Old photos of Congress party meetings in Hyderabad falsely linked to the Hyderabad floods",
      },
      {
        url:
          "https://factly.in/2014-photo-of-pm-modis-public-meeting-in-kolkata-shared-as-yogis-public-meeting-in-bihar/",
        headline:
          " 2014 photo of PM Modi\u2019s public meeting in Kolkata shared as Yogi\u2019s  public meeting in Bihar.",
      },
      {
        url:
          "https://factly.in/telugu-2014-photo-of-pm-modis-public-meeting-in-kolkata-shared-as-yogis-public-meeting-in-bihar/",
        headline:
          " \u0c28\u0c30\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c4b\u0c26\u0c40 2014\u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c38\u0c2d \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c32\u0c4b \u0c2f\u0c4b\u0c17\u0c3f \u0c06\u0c26\u0c3f\u0c24\u0c4d\u0c2f\u0c28\u0c3e\u0c25\u0c4d \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c38\u0c2d \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-old-picture-of-thailands-liquor-bottles-went-viral-in-the-name-of-bihar-election/",
        headline:
          "Fact Check : \u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0908 \u0925\u093e\u0908\u0932\u0948\u0902\u0921 \u0915\u0940 \u0936\u0930\u093e\u092c \u0915\u0940 \u092c\u094b\u0924\u0932\u094b\u0902 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u200d\u0935\u0940\u0930",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-post-claiming-about-bihar-govt-denying-permission-to-rahul-gandhi-helicopter-is-fake/",
        headline:
          "Fact Check: \u092c\u093f\u0939\u093e\u0930 \u092e\u0947\u0902 \u091a\u0941\u0928\u093e\u0935 \u092a\u094d\u0930\u091a\u093e\u0930 \u0915\u0947 \u0932\u093f\u090f \u0906 \u0930\u0939\u0947 \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0902\u0927\u0940 \u0915\u0947 \u0935\u093f\u092e\u093e\u0928 \u0915\u094b \u0932\u0948\u0902\u0921\u093f\u0902\u0917 \u0915\u0940 \u0905\u0928\u0941\u092e\u0924\u093f \u0928\u0939\u0940\u0902 \u0926\u093f\u090f \u091c\u093e\u0928\u0947 \u0915\u093e \u0926\u093e\u0935\u093e \u0917\u0932\u0924",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-photo-of-2014-viral-in-bihar-election-photo-has-no-relation-with-yogis-rally/",
        headline:
          "Fact Check : \u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935 \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0908 2014 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930, \u092b\u094b\u091f\u094b \u0915\u093e \u092f\u094b\u0917\u0940 \u0915\u0940 \u0930\u0948\u0932\u0940 \u0938\u0947 \u0928\u0939\u0940\u0902 \u0939\u0948 \u0915\u094b\u0908 \u0938\u0902\u092c\u0902\u0927",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-this-is-not-the-picture-of-muzaffarpur-flyover-in-bihar-viral-claim-is-fake/",
        headline:
          "Fact Check: This is not the picture of Muzaffarpur flyover in Bihar, viral claim is fake",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/politics/fact-check-old-video-from-madhya-pradesh-going-viral-in-the-name-of-bihar-elections-2020/",
        headline:
          "Fact Check : \u0a1c\u0a41\u0a71\u0a24\u0a40\u0a06\u0a02 \u0a26\u0a3e \u0a39\u0a3e\u0a30 \u0a2a\u0a3e\u0a09\u0a23 \u0a35\u0a3e\u0a32\u0a47 \u0a35\u0a40\u0a21\u0a40\u0a13 \u0a26\u0a3e \u0a2c\u0a3f\u0a39\u0a3e\u0a30 \u0a1a\u0a4b\u0a23\u0a3e\u0a02 \u0a28\u0a3e\u0a32 \u0a28\u0a39\u0a40\u0a02 \u0a39\u0a48 \u0a15\u0a4b\u0a08 \u0a38\u0a2c\u0a70\u0a27",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/politics/fact-check-image-of-a-bjp-leader-going-viral-with-false-claim-linking-with-hathras-gangrape/",
        headline:
          "Fact Check: \u09aa\u09cd\u09f0\u09df\u09be\u0997\u09f0\u09be\u099c\u09f0 \u09a8\u09c7\u09a4\u09be\u09f0 \u09ab\u099f\u09cb \u09b9\u09be\u09a5\u09f0\u09b8 \u0995\u09c7\u099a\u09f0 \u0986\u09f0\u09cb\u09aa\u09c0\u09f0 \u09aa\u09bf\u09a4\u09be\u0995 \u0995\u09f0\u09bf \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u0995\u09f0\u09be \u0997\u09c8\u099b\u09c7",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/19/fact-check-months-old-video-of-fish-swimming-in-house-linked-to-recent-hyderabad-floods/",
        headline:
          "Fact Check: Months-old video of fish swimming in house linked to recent Hyderabad floods",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/19/fact-check-old-video-of-flooded-road-from-hyderabad-shared-as-recent-to-show-monsoon-mayhem/",
        headline:
          "Fact Check: Old video of flooded road from Hyderabad shared as recent to show monsoon mayhem",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/19/fact-check-this-trending-video-is-not-from-the-2020-bihar-elections-heres-the-truth/",
        headline:
          "Fact Check: This trending video is NOT from the 2020 Bihar elections; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/20/fact-check-was-rahul-gandhi-listed-as-the-seventh-most-educated-leader-by-forbes-heres-the-truth/",
        headline:
          "Fact Check: Was Rahul Gandhi listed as the seventh most educated leader by Forbes? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/20/fact-check-upsc-has-not-changed-the-age-limit-for-general-category-candidates/",
        headline:
          "Fact Check: UPSC has NOT changed the age limit for General category candidates",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/fact-check-are-these-liquor-bottle-packages-from-bihar-heres-the-truth/",
        headline:
          "Fact Check: Are these liquor bottle packages from Bihar? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/24/fact-check-dont-fall-for-false-claims-of-congress-leaders-partying-while-hyderabad-suffers-heavy-rainfall/",
        headline:
          "Fact Check: Don\u2019t fall for false claims of Congress leaders partying while Hyderabad suffers heavy rainfall",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fake-news/no-bill-gates-did-not-say-this-about-india-and-its-people-10288",
        headline: "No, Bill Gates Did Not Say This About India And Its People",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/uninstallmyntra-trends-over-old-graphic-not-made-by-company-10347",
        headline:
          "#UninstallMyntra Trends Over Old Graphic Not Made By Company",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/whatsapp-forward-opinion-of-bill-gates-about-indian-people-is-fake-10291",
        headline:
          "\u0928\u0939\u0940\u0902, \u092c\u093f\u0932 \u0917\u0947\u091f\u094d\u0938 \u0928\u0947 \u092d\u093e\u0930\u0924\u0935\u093e\u0938\u093f\u092f\u094b\u0902 \u0915\u0947 \u0932\u093f\u090f \u092f\u0939 \u0928\u0939\u0940\u0902 \u092c\u094b\u0932\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-quote-by-sachin-tendulkar-on-prophet-muhammad-shared-10285",
        headline:
          "\u09b9\u099c\u09b0\u09a4 \u09ae\u09b9\u09be\u09ae\u09cd\u09ae\u09a6 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u09b8\u099a\u09bf\u09a8 \u099f\u09c7\u09a8\u09cd\u09a1\u09c1\u09b2\u0995\u09b0\u09c7\u09b0 \u098f\u0987 \u09ac\u0995\u09cd\u09a4\u09ac\u09cd\u09af\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-bill-gates-did-not-say-this-about-india-and-its-people-10319",
        headline:
          "\u09a8\u09be, \u09ad\u09be\u09b0\u09a4 \u0993 \u09ad\u09be\u09b0\u09a4\u09c0\u09df\u09a6\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09c7 \u098f\u0987 \u0989\u0995\u09cd\u09a4\u09bf \u0995\u09b0\u09c7\u09a8\u09a8\u09bf \u09ac\u09bf\u09b2 \u0997\u09c7\u099f\u09b8",
      },
      {
        url:
          "https://factly.in/these-images-are-actually-of-an-animal-bridge-in-singapore-not-delhi-mumbai-expressway/",
        headline:
          " These images are actually of an animal bridge in Singapore, not Delhi-Mumbai Expressway",
      },
      {
        url:
          "https://factly.in/telugu-these-images-are-actually-of-an-animal-bridge-in-singapore-not-delhi-mumbai-expressway/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c41 \u0c38\u0c3f\u0c02\u0c17\u0c2a\u0c42\u0c30\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c12\u0c15 \u0c39\u0c48\u0c35\u0c47 \u0c2a\u0c48 \u0c28\u0c3f\u0c30\u0c4d\u0c2e\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c12\u0c15 \u0c2c\u0c4d\u0c30\u0c3f\u0c21\u0c4d\u0c1c\u0c3f\u0c35\u0c3f, \u0c22\u0c3f\u0c32\u0c4d\u0c32\u0c40-\u0c2e\u0c41\u0c02\u0c2c\u0c48 \u0c0e\u0c15\u0c4d\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c35\u0c47\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c35\u0c3f \u0c15\u0c3e\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-jama-masjid-did-not-issue-any-fatwa-against-tanishq-for-their-controversial-baby-shower-ad/",
        headline:
          " \u0c24\u0c28\u0c3f\u0c37\u0c4d\u0c15\u0c4d  \u0c15\u0c02\u0c2a\u0c46\u0c28\u0c40 \u2018\u0c0f\u0c15\u0c24\u0c4d\u0c35\u0c02\u2019 \u0c2f\u0c3e\u0c21\u0c4d \u0c28\u0c47\u0c2a\u0c25\u0c4d\u0c2f\u0c02\u0c32\u0c4b \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2a\u0c4d\u0c30\u0c15\u0c1f\u0c28\u0c28\u0c3f \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c1c\u0c3e\u0c2e\u0c3e \u0c2e\u0c38\u0c40\u0c26\u0c41 \u0c2b\u0c24\u0c4d\u0c35\u0c3e \u0c1c\u0c3e\u0c30\u0c40 \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-who-did-not-say-that-covid-19-virus-has-become-more-dangerous-and-it-will-kill-anyone-in-3-days/",
        headline:
          " \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c35\u0c48\u0c30\u0c38\u0c4d \u0c05\u0c24\u0c4d\u0c2f\u0c02\u0c24 \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c02\u0c17\u0c3e \u0c2e\u0c3e\u0c30\u0c3f\u0c02\u0c26\u0c28\u0c3f, \u0c0e\u0c35\u0c30\u0c3f\u0c28\u0c48\u0c28\u0c3e \u0c2e\u0c42\u0c21\u0c41 \u0c30\u0c4b\u0c1c\u0c41\u0c32\u0c4d\u0c32\u0c4b\u0c28\u0c47 \u0c1a\u0c02\u0c2a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a \u0c06\u0c30\u0c4b\u0c17\u0c4d\u0c2f \u0c38\u0c02\u0c38\u0c4d\u0c25 \u0c1a\u0c46\u0c2a\u0c4d\u0c2a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-people-demolishing-trs-party-yard-falsely-linked-to-dubbaka-by-election/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, \u0c26\u0c41\u0c2c\u0c4d\u0c2c\u0c3e\u0c15\u0c32\u0c4b \u0c1f\u0c40\u0c06\u0c30\u0c4d\u0c0e\u0c38\u0c4d \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40 \u0c17\u0c26\u0c4d\u0c26\u0c46\u0c28\u0c41 \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c41 \u0c15\u0c42\u0c32\u0c4d\u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-moon-will-not-appear-in-blue-colour-on-blue-moon-day/",
        headline:
          " \u2018\u0c2c\u0c4d\u0c32\u0c42 \u0c2e\u0c42\u0c28\u0c4d\u2019 \u0c30\u0c4b\u0c1c\u0c41 \u0c1a\u0c02\u0c26\u0c4d\u0c30\u0c41\u0c21\u0c41 \u0c28\u0c40\u0c32\u0c02 (\u0c2c\u0c4d\u0c32\u0c42) \u0c30\u0c02\u0c17\u0c41\u0c32\u0c4b \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c02\u0c1a\u0c21\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-language-has-not-been-recognized-as-the-official-language-in-the-usa/",
        headline:
          " Telugu has not been recognized as the official language in the USA",
      },
      {
        url:
          "https://factly.in/telugu-the-telugu-language-is-not-recognized-as-official-language-of-usa/",
        headline:
          " \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e\u0c32\u0c4b \u0c24\u0c46\u0c32\u0c41\u0c17\u0c41\u0c28\u0c41 \u0c05\u0c27\u0c3f\u0c15\u0c3e\u0c30\u0c3f\u0c15 \u0c2d\u0c3e\u0c37\u0c17\u0c3e \u0c17\u0c41\u0c30\u0c4d\u0c24\u0c3f\u0c02\u0c1a\u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/kisan-vikas-mitra-samithi-website-kvms-org-in-offering-jobs-in-the-agriculture-sector-is-a-dubious-one/",
        headline:
          " Kisan Vikas Mitra Samithi website (kvms.org.in) offering jobs in the Agriculture sector is a dubious one",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-old-video-of-mumbai-stop-to-bhajans-in-durga-pandal-viral-again/",
        headline:
          "Fact Check : \u0926\u0941\u0930\u094d\u0917\u093e \u092a\u0902\u0921\u093e\u0932 \u092e\u0947\u0902 \u092d\u091c\u0928 \u092c\u0902\u0926 \u0915\u0930\u093e\u0928\u0947 \u0915\u093e \u092e\u091c\u093e\u0915 \u092e\u0947\u0902 \u092c\u0928\u093e\u092f\u093e \u0917\u092f\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0939\u0941\u0906 \u0935\u093e\u092f\u0930\u0932, \u092a\u094b\u0938\u094d\u200d\u091f \u092b\u0947\u0915 \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-this-is-singapores-eco-link-bridge-not-a-animal-crosing-bridge-in-india/",
        headline:
          "Fact Check: \u092f\u0939 \u092d\u093e\u0930\u0924 \u0915\u093e \u090f\u0928\u093f\u092e\u0932 \u092a\u0941\u0932 \u0928\u0939\u0940\u0902, \u0938\u093f\u0902\u0917\u093e\u092a\u0941\u0930 \u0915\u093e \u0908\u0915\u094b \u0932\u093f\u0902\u0915 \u092c\u094d\u0930\u093f\u091c \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-post-claiming-covid-19-testing-patent-was-submitted-in-2015-is-fake/",
        headline:
          "Fact Check: Post claiming COVID-19 testing patent was submitted in 2015 is fake",
      },
      {
        url:
          "https://digiteye.in/can-inhalation-of-steam-for-a-week-eliminate-coronavirus-fact-check/",
        headline:
          "Can inhalation of steam for a week eliminate coronavirus? Fact Check",
      },
      {
        url:
          "https://digiteye.in/did-bill-gates-funded-pirbright-institute-patent-coronavirus-fact-check/",
        headline:
          "Did Bill Gates-funded Pirbright Institute patent coronavirus? Fact Check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/19/fact-check-2012-video-of-submerged-railway-bridge-shared-as-a-recent-one/",
        headline:
          "Fact Check: 2012 video of submerged railway bridge shared as a recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/20/fact-check-did-a-husband-pose-for-a-photoshoot-after-pregnant-wife-refused-heres-the-truth/",
        headline:
          "Fact Check: Did a husband pose for a photoshoot after pregnant wife refused? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/20/fact-check-no-this-ecological-bridge-has-not-been-constructed-on-delhi-mumbai-expressway-heres-the-truth/",
        headline:
          "Fact Check: No, this ecological bridge has not been constructed on Delhi-Mumbai Expressway; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/21/fact-check-2018-newspaper-clipping-of-a-teenager-offering-eyes-to-goddess-durga-shared-as-recent-one/",
        headline:
          "Fact Check: 2018 newspaper clipping of a teenager offering eyes to goddess Durga shared as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/fact-check-has-indias-imports-from-china-increased-by-27-in-april-august-heres-the-truth/",
        headline:
          "Fact Check: Has India\u2019s imports from China increased by 27% in April-August? Here\u2019s the truth",
      },
    ],
    "5": [
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-image-is-not-of-the-teacher-who-was-beheaded-in-paris-10322",
        headline:
          "No, This Image Is Not Of The Teacher Who Was Beheaded In Paris",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-of-stone-pelting-on-nitish-kumars-car-revived-ahead-of-bihar-election-10278",
        headline:
          "\u0928\u0940\u0924\u0940\u0936 \u0915\u0941\u092e\u093e\u0930 \u0915\u0947 \u0915\u093e\u095e\u093f\u0932\u0947 \u092a\u0930 \u092a\u0925\u0930\u093e\u0935 \u0915\u093e \u092f\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u092c \u0915\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-this-image-is-not-of-the-teacher-who-was-beheaded-in-paris-10326",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0909\u0938 \u0936\u093f\u0915\u094d\u0937\u0915 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948 \u091c\u093f\u0928\u0915\u093e \u095e\u094d\u0930\u093e\u0902\u0938 \u092e\u0947\u0902 \u0938\u0930 \u0915\u0932\u092e \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019-murshidabad-triple-murder-case-shared-with-false-claim-10263",
        headline:
          "\u09ae\u09c1\u09b0\u09cd\u09b6\u09bf\u09a6\u09be\u09ac\u09be\u09a6\u09c7 \u098f\u0995 \u09aa\u09b0\u09bf\u09ac\u09be\u09b0\u09c7\u09b0 \u09a4\u09bf\u09a8 \u09b8\u09a6\u09b8\u09cd\u09af \u0996\u09c1\u09a8\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u0998\u099f\u09a8\u09be \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/did-avoiding-the-future-plague-psa-predict-covid-19-pandemic-10269",
        headline:
          "'\u0986\u09b8\u09a8\u09cd\u09a8 \u09aa\u09cd\u09b2\u09c7\u0997 \u098f\u09dc\u09be\u09a8' \u09ac\u09bf\u09b7\u09df\u09c7 \u099c\u09a8\u09b8\u09cd\u09ac\u09be\u09b0\u09cd\u09a5 \u0998\u09cb\u09b7\u09a3\u09be \u0995\u09cb\u09ad\u09bf\u09a1 \u0985\u09a4\u09bf\u09ae\u09be\u09b0\u09bf\u09b0 \u0986\u0997\u09be\u09ae \u09b8\u09a4\u09b0\u09cd\u0995\u09a4\u09be?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-woman-vandalising-shop-viral-with-false-communal-spin-10280",
        headline:
          "\u098f\u0995 \u09ae\u09b9\u09bf\u09b2\u09be\u09b0 \u09a6\u09cb\u0995\u09be\u09a8 \u09ad\u09be\u0999\u099a\u09c1\u09b0\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u09a4\u09c7 \u09b2\u09be\u0997\u09b2 \u09a7\u09b0\u09cd\u09ae\u09c0\u09af\u09bc \u09b0\u0999",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-image-of-polices-assault-against-woman-protester-in-unnao-shared-with-communal-spin-10286",
        headline:
          "\u0989\u09a8\u09cd\u09a8\u09be\u0993\u09df\u09c7 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u09ae\u09b9\u09bf\u09b2\u09be \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c0\u0995\u09c7 \u09a8\u09bf\u0997\u09cd\u09b0\u09b9\u09c7\u09b0 \u099b\u09ac\u09bf \u09a7\u09b0\u09cd\u09ae\u09c0\u09df \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-account-impersonating-neet-topper-akanksha-singh-stirs-bigotry-10301",
        headline:
          "\u09a8\u09bf\u099f \u09b6\u09c0\u09b0\u09cd\u09b7\u09c7 \u09a5\u09be\u0995\u09be \u0986\u0995\u09be\u0999\u09cd\u0996\u09be\u09b0 \u09a8\u09be\u09ae\u09c7 \u09ad\u09c1\u09df\u09cb \u0985\u09cd\u09af\u09be\u0995\u09be\u0989\u09a8\u09cd\u099f \u0997\u09cb\u0981\u09a1\u09bc\u09be\u09ae\u09bf \u099b\u09a1\u09bc\u09be\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/viral-image-of-police-beating-a-woman-in-punjab-shared-as-caste-atrocities-in-uttar-pradesh-10314",
        headline:
          "\u09aa\u09be\u099e\u09cd\u099c\u09be\u09ac \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u098f\u0995 \u09a8\u09be\u09b0\u09c0\u0995\u09c7 \u09aa\u09cd\u09b0\u09b9\u09be\u09b0 \u099b\u09a1\u09bc\u09be\u09b2 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09a6\u09b2\u09bf\u09a4 \u09a8\u09bf\u09b0\u09cd\u09af\u09be\u09a4\u09a8 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://factly.in/telangana-state-is-not-in-the-2nd-position-for-crimes-committed-against-women-in-the-country/",
        headline:
          " \u0c2e\u0c39\u0c3f\u0c33\u0c32\u0c2a\u0c48 \u0c1c\u0c30\u0c3f\u0c17\u0c47 \u0c28\u0c47\u0c30\u0c3e\u0c32\u0c32\u0c4b \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23 \u0c30\u0c46\u0c02\u0c21\u0c4b \u0c38\u0c4d\u0c25\u0c3e\u0c28\u0c02\u0c32\u0c4b \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-2018-video-of-women-arrested-for-playing-bathukamma-shared-as-recent/",
        headline:
          " \u2018\u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c2a\u0c3e\u0c24 \u0c2c\u0c38\u0c4d\u0c24\u0c40 \u0c32\u0c4b \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c2e\u0c39\u0c3f\u0c33\u0c32\u0c41 \u0c2c\u0c24\u0c41\u0c15\u0c2e\u0c4d\u0c2e \u0c06\u0c21\u0c3f\u0c28\u0c02\u0c26\u0c41\u0c15\u0c41 \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d\u2019 \u0c05\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b 2018 \u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-econd-marriage-by-a-hindu-man-falsely-shared-as-love-jihad-by-a-muslim/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c39\u0c3f\u0c02\u0c26\u0c42  \u0c2f\u0c41\u0c35\u0c24\u0c3f\u0c28\u0c3f \u0c2e\u0c4b\u0c38\u0c02 \u0c1a\u0c47\u0c38\u0c3f \u0c30\u0c46\u0c02\u0c21\u0c4b \u0c2a\u0c46\u0c33\u0c4d\u0c32\u0c3f \u0c1a\u0c47\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c15\u0c42\u0c21\u0c3e \u0c39\u0c3f\u0c02\u0c26\u0c41\u0c35\u0c47, \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-video-of-bullock-cart-being-washed-away-in-flood-water-is-from-karnataka-not-telangana/",
        headline:
          " \u0c35\u0c30\u0c26 \u0c2a\u0c4d\u0c30\u0c35\u0c3e\u0c39\u0c02\u0c32\u0c4b \u0c0e\u0c21\u0c4d\u0c32\u0c2c\u0c02\u0c21\u0c3f  \u0c15\u0c4a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c2a\u0c4b\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c18\u0c1f\u0c28 \u0c15\u0c30\u0c4d\u0c23\u0c3e\u0c1f\u0c15 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c02\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f, \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-photo-of-a-snake-trapped-in-fishermans-net-is-from-andhra-pradesh-not-hyderabad/",
        headline:
          " This photo of a snake trapped in fisherman\u2019s net is from Andhra Pradesh, not Hyderabad",
      },
      {
        url:
          "https://factly.in/the-woman-in-this-video-was-not-arrested-for-not-wearing-a-mask/",
        headline:
          " The Woman in this video was not arrested for not wearing a mask.",
      },
      {
        url:
          "https://factly.in/telugu-the-person-in-this-photo-is-not-the-decapitated-france-teacher-samuel-paty/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c32\u0c4b \u0c24\u0c32 \u0c28\u0c30\u0c3f\u0c15\u0c3f\u0c35\u0c47\u0c2f\u0c2c\u0c21\u0c4d\u0c21 \u0c1f\u0c40\u0c1a\u0c30\u0c4d \u0c36\u0c3e\u0c2e\u0c4d\u0c2f\u0c42\u0c32\u0c4d \u0c2a\u0c4d\u0c2f\u0c3e\u0c1f\u0c40 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-boy-in-the-photo-is-arrested-for-murder-not-for-stealing/",
        headline:
          " The boy in this photo was arrested for murder, not for stealing",
      },
      {
        url:
          "https://www.vishvasnews.com/world/quick-fact-check-no-nasa-has-not-added-13th-zodiac-sign-ophiuchus-fake-claim-resurfaces/",
        headline:
          "Quick Fact Check: \u0928\u093e\u0938\u093e \u0928\u0947 \u0930\u093e\u0936\u093f\u092f\u094b\u0902 \u0915\u0940 \u0938\u0942\u091a\u0940 \u092e\u0947\u0902 13\u0935\u0940\u0902 \u0930\u093e\u0936\u093f \u0911\u092b\u093f\u092f\u0941\u091a\u0941\u0938 \u0915\u093e \u0928\u093e\u092e \u0928\u0939\u0940\u0902 \u091c\u094b\u0921\u093c\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/quick-fact-check-this-is-not-a-god-sent-rare-flower-fake-claim-again-viral/",
        headline:
          "Quick Fact Check: \u092d\u0917\u0935\u093e\u0928 \u0915\u093e \u092d\u0947\u091c\u093e \u0926\u0941\u0930\u094d\u0932\u092d \u092b\u0942\u0932 \u0928\u0939\u0940\u0902 \u0939\u0948 \u092f\u0947, \u092b\u0930\u094d\u091c\u0940 \u0926\u093e\u0935\u093e \u092b\u093f\u0930 \u0939\u0941\u0906 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/society/fact-check-image-of-hindu-temple-vandalizing-in-sydney-going-viral-in-the-name-of-punjab/",
        headline:
          "Fact Check: \u0938\u093f\u0921\u0928\u0940 \u092e\u0947\u0902 \u0939\u0941\u090f \u092d\u093e\u0930\u0924\u0940\u092f \u092e\u0902\u0926\u093f\u0930 \u092e\u0947\u0902 \u0924\u094b\u095c\u092b\u094b\u095c \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092a\u0902\u091c\u093e\u092c \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0939\u094b \u0930\u0939\u0940 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/viral/fact-check-govt-is-not-offering-jobs-to-people-who-have-smartphone-fake-message-getting-viral/",
        headline:
          "Fact Check: \u0938\u0930\u0915\u093e\u0930 \u0915\u0940 \u0924\u0930\u092b \u0938\u0947 \u0918\u0930 \u092c\u0948\u0920\u0947 \u0930\u094b\u091c\u0917\u093e\u0930 \u0926\u0947\u0928\u0947 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932 \u092e\u0948\u0938\u0947\u091c \u092b\u0930\u094d\u091c\u0940, \u0905\u0928\u091c\u093e\u0928 \u0932\u093f\u0902\u0915 \u092a\u0930 \u0915\u094d\u0932\u093f\u0915 \u0915\u0930\u0928\u093e \u0916\u0924\u0930\u0928\u093e\u0915",
      },
      {
        url:
          "https://www.vishvasnews.com/politics/fact-check-old-incident-of-murder-of-rape-victim-and-her-mother-in-kasganj-uttar-pradesh-is-being-shared-as-recent/",
        headline:
          "Fact Check: \u0909\u0924\u094d\u0924\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0947 \u0915\u093e\u0938\u0917\u0902\u091c \u092e\u0947\u0902 \u091c\u0941\u0932\u093e\u0908 \u092e\u0939\u0940\u0928\u0947 \u0915\u0940 \u0939\u0924\u094d\u092f\u093e \u0915\u0940 \u0918\u091f\u0928\u093e \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0939\u093e\u0932 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/society/fact-check-there-is-no-communal-angle-in-this-video-both-male-and-female-belongs-to-the-same-community/",
        headline:
          "Fact Check : \u0a07\u0a70\u0a26\u0a4c\u0a30 \u0a26\u0a47 \u0a06\u0a08\u0a38\u0a15\u0a4d\u0a30\u0a40\u0a2e \u0a2a\u0a3e\u0a30\u0a32\u0a30 \u0a35\u0a3f\u0a1a \u0a2d\u0a70\u0a28\u0a24\u0a4b\u0a5c \u0a26\u0a47 \u0a35\u0a3e\u0a07\u0a30\u0a32 \u0a35\u0a40\u0a21\u0a40\u0a13 \u0a35\u0a3f\u0a1a \u0a28\u0a39\u0a40\u0a02 \u0a39\u0a48 \u0a15\u0a4b\u0a08 \u0a39\u0a3f\u0a70\u0a26\u0a42-\u0a2e\u0a41\u0a38\u0a32\u0a3f\u0a2e \u0a10\u0a02\u0a17\u0a32",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/politics/quick-fact-check-no-arvind-kejriwal-did-not-accepted-that-his-family-was-rss-members-viral-video-is-cropped/",
        headline:
          "Quick Fact Check: \u0a05\u0a30\u0a35\u0a3f\u0a70\u0a26 \u0a15\u0a47\u0a1c\u0a30\u0a40\u0a35\u0a3e\u0a32 \u0a26\u0a40 \u0a07\u0a39 \u0a15\u0a32\u0a3f\u0a2a \u0a15\u0a4d\u0a30\u0a4b\u0a2a\u0a21 \u0a39\u0a48, \u0a09\u0a28\u0a4d\u0a39\u0a3e\u0a02 \u0a28\u0a47 \u0a28\u0a39\u0a40\u0a02 \u0a15\u0a3f\u0a39\u0a3e \u0a15\u0a3f \u0a09\u0a39 RSS \u0a26\u0a47 \u0a2e\u0a47\u0a02\u0a2c\u0a30 \u0a38\u0a28",
      },
      {
        url:
          "https://www.vishvasnews.com/punjabi/society/fact-check-old-image-of-a-murder-of-rape-victim-and-her-mother-going-viral-with-misleading-claim/",
        headline:
          "Fact Check: \u0a09\u0a71\u0a24\u0a30 \u0a2a\u0a4d\u0a30\u0a26\u0a47\u0a36 \u0a26\u0a47 \u0a15\u0a3e\u0a38\u0a17\u0a70\u0a1c \u0a35\u0a3f\u0a1a \u0a1c\u0a41\u0a32\u0a3e\u0a08 \u0a2e\u0a39\u0a40\u0a28\u0a47 \u0a26\u0a40 \u0a39\u0a71\u0a24\u0a3f\u0a06 \u0a26\u0a40 \u0a18\u0a1f\u0a28\u0a3e \u0a26\u0a40 \u0a24\u0a38\u0a35\u0a40\u0a30 \u0a28\u0a42\u0a70 \u0a39\u0a3e\u0a32\u0a40\u0a06 \u0a26\u0a71\u0a38\u0a15\u0a47 \u0a15\u0a40\u0a24\u0a3e \u0a1c\u0a3e \u0a30\u0a3f\u0a39\u0a3e \u0a39\u0a48 \u0a35\u0a3e\u0a07\u0a30\u0a32",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/fact-check-old-video-of-woman-being-attacked-in-a-metro-elevator-in-malaysia-attributed-to-hyderabad/",
        headline:
          "Fact Check: Old video of woman being attacked in a metro elevator in Malaysia attributed to Hyderabad",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/22/fact-check-15-foot-long-snake-rescued-in-vijaywada-wrongly-attributed-to-hyderabad-in-edited-video/",
        headline:
          "Fact Check: 15-foot-long snake rescued in Vijaywada wrongly attributed to Hyderabad in edited video",
      },
    ],
  },
  numpy_seed: 0,
  number_of_articles: 132,
}
