export const data = {
  mdsDat: {
    x: [
      0.021492274986647977,
      -0.21248909705918728,
      -0.2797906932880326,
      0.19231831129655536,
      0.2784692040640165,
    ],
    y: [
      0.05440295805794177,
      0.3337015814018513,
      -0.322941517991202,
      0.02868724888921257,
      -0.0938502703578041,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      19.687378545477507,
      15.724930584999669,
      14.537448302724606,
      33.689069097127486,
      16.361173469670735,
    ],
  },
  tinfo: {
    Term: [
      "woman",
      "bengal",
      "gandhi",
      "tunnel",
      "hathras",
      "rape",
      "priyanka",
      "singh",
      "pradesh",
      "west",
      "priyanka_gandhi",
      "rahul_priyanka",
      "digvijay_singh",
      "gandhi",
      "rahul",
      "rahul_gandhi",
      "digvijay",
      "indira",
      "assembly",
      "delhi",
      "priyanka",
      "congress",
      "congress_leader",
      "singh",
      "daughter",
      "leader",
      "bihar",
      "cong",
      "kick",
      "farmers",
      "october",
      "air",
      "include",
      "west",
      "soon",
      "huge",
      "aircraft",
      "bengal",
      "west_bengal",
      "indonesia",
      "tweet",
      "modi",
      "rock",
      "gas",
      "bjp",
      "bihar",
      "structure",
      "war",
      "tagore",
      "give",
      "brazilian",
      "revoke",
      "government",
      "note",
      "click",
      "azerbaijani",
      "tunnel",
      "atal",
      "california",
      "feast",
      "universe",
      "atal_tunnel",
      "shoot",
      "kashmir",
      "slide",
      "screenshot",
      "azerbaijan",
      "hathra",
      "rape_victim",
      "live",
      "perform",
      "kill",
      "associate",
      "beat",
      "company",
      "sri",
      "gang",
      "yogi",
      "adityanath",
      "accuse",
      "yogi_adityanath",
      "incident",
      "mother",
      "man",
      "minor",
      "victim_mother",
      "rape",
      "victim",
      "hathras",
      "case",
      "pradesh",
      "title",
      "telangana",
      "find",
      "top",
      "wire",
      "android",
      "dead",
      "corpse",
      "palasa",
      "sister",
      "station",
      "murder",
      "road",
      "message",
      "karnataka",
      "andhra",
      "abduct",
      "chandrababu",
      "phulbagan",
      "woman",
      "body",
      "pradesh",
      "relate",
      "burn",
      "communal",
      "madhya",
      "madhya_pradesh",
      "go",
    ],
    Freq: [
      11.0,
      8.0,
      9.0,
      7.0,
      19.0,
      15.0,
      7.0,
      9.0,
      15.0,
      6.0,
      3.000673602468417,
      3.000673602468417,
      3.000673602468417,
      9.002020807405252,
      6.001347204936834,
      3.000673602468417,
      3.000673602468417,
      2.000449068312278,
      1.000224534156139,
      1.000224534156139,
      7.001571739092974,
      5.0011226707806955,
      4.000898136624556,
      8.001796273249113,
      5.0011226707806955,
      6.001347204936834,
      5.0011226707806955,
      1.0003170128440964,
      1.0003170128440964,
      1.0003170128440964,
      1.0003170128440964,
      4.001268051376385,
      1.0003170128440964,
      6.001902077064578,
      1.0003170128440964,
      2.0006340256881927,
      2.0006340256881927,
      8.00253610275277,
      6.001902077064578,
      3.000951038532289,
      3.000951038532289,
      3.000951038532289,
      2.0006340256881927,
      2.0006340256881927,
      4.001268051376385,
      3.000951038532289,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      7.0030853232852435,
      4.001763041877282,
      3.0013222814079614,
      2.000881520938641,
      2.000881520938641,
      2.000881520938641,
      2.000881520938641,
      2.000881520938641,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      7.00072067237955,
      7.00072067237955,
      2.000205906394157,
      1.0001029531970784,
      2.000205906394157,
      2.000205906394157,
      2.000205906394157,
      1.0001029531970784,
      2.000205906394157,
      1.0001029531970784,
      9.000926578773706,
      9.000926578773706,
      9.000926578773706,
      9.000926578773706,
      4.000411812788314,
      4.000411812788314,
      3.0003088595912355,
      3.0003088595912355,
      3.0003088595912355,
      14.0014413447591,
      11.001132485167863,
      16.001647251153255,
      6.000617719182471,
      8.000823625576627,
      0.9988232513339309,
      2.9964697540017924,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      2.9964697540017924,
      2.9964697540017924,
      2.9964697540017924,
      1.9976465026678618,
      1.9976465026678618,
      1.9976465026678618,
      1.9976465026678618,
      0.9988232513339309,
      0.9988232513339309,
      8.98940926200538,
      3.9952930053357236,
      6.991762759337516,
      2.9964697540017924,
      2.9964697540017924,
      2.9964697540017924,
      3.9952930053357236,
      3.9952930053357236,
      3.9952930053357236,
    ],
    Total: [
      11.0,
      8.0,
      9.0,
      7.0,
      19.0,
      15.0,
      7.0,
      9.0,
      15.0,
      6.0,
      3.000673602468417,
      3.000673602468417,
      3.000673602468417,
      9.002020807405252,
      6.001347204936834,
      3.000673602468417,
      3.000673602468417,
      2.000449068312278,
      1.000224534156139,
      1.000224534156139,
      7.001571739092974,
      5.0011226707806955,
      4.000898136624556,
      9.002237033718433,
      6.001563431250016,
      9.001870124175086,
      8.002073709312985,
      1.0003170128440964,
      1.0003170128440964,
      1.0003170128440964,
      1.0003170128440964,
      4.001268051376385,
      1.0003170128440964,
      6.001902077064578,
      1.0003170128440964,
      2.0006340256881927,
      2.0006340256881927,
      8.00253610275277,
      6.001902077064578,
      3.000951038532289,
      3.000951038532289,
      3.000951038532289,
      2.0006340256881927,
      2.0006340256881927,
      9.0020259792799,
      8.002073709312985,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      7.0030853232852435,
      4.001763041877282,
      3.0013222814079614,
      2.000881520938641,
      2.000881520938641,
      2.000881520938641,
      2.000881520938641,
      2.000881520938641,
      1.0004407604693204,
      1.0004407604693204,
      1.0004407604693204,
      7.00072067237955,
      7.00072067237955,
      2.000205906394157,
      1.0001029531970784,
      2.000205906394157,
      2.000205906394157,
      2.000205906394157,
      1.0001029531970784,
      2.000205906394157,
      1.0001029531970784,
      9.000926578773706,
      9.000926578773706,
      9.000926578773706,
      9.000926578773706,
      4.000411812788314,
      4.000411812788314,
      3.0003088595912355,
      3.0003088595912355,
      3.0003088595912355,
      15.00026459609303,
      12.001357019324002,
      19.002320853621672,
      7.00084225333861,
      15.992810919070283,
      0.9988232513339309,
      2.9964697540017924,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      0.9988232513339309,
      2.9964697540017924,
      2.9964697540017924,
      2.9964697540017924,
      1.9976465026678618,
      1.9976465026678618,
      1.9976465026678618,
      1.9976465026678618,
      0.9988232513339309,
      0.9988232513339309,
      11.989718121596615,
      4.995395958532802,
      15.992810919070283,
      3.9967867668458887,
      3.9969105144711126,
      3.9969105144711126,
      7.995826399083098,
      7.995826399083098,
      7.99616420635534,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.9053,
      -3.9053,
      -3.9053,
      -2.8067,
      -3.2122,
      -3.9053,
      -3.9053,
      -4.3108,
      -5.0039,
      -5.0039,
      -3.058,
      -3.3945,
      -3.6177,
      -2.9245,
      -3.3945,
      -3.2122,
      -3.3945,
      -4.7791,
      -4.7791,
      -4.7791,
      -4.7791,
      -3.3928,
      -4.7791,
      -2.9874,
      -4.7791,
      -4.086,
      -4.086,
      -2.6997,
      -2.9874,
      -3.6805,
      -3.6805,
      -3.6805,
      -4.086,
      -4.086,
      -3.3928,
      -3.6805,
      -4.7005,
      -4.7005,
      -4.7005,
      -4.7005,
      -4.7005,
      -4.7005,
      -4.7005,
      -4.7005,
      -4.7005,
      -4.7005,
      -2.7546,
      -3.3142,
      -3.6019,
      -4.0073,
      -4.0073,
      -4.0073,
      -4.0073,
      -4.0073,
      -4.7005,
      -4.7005,
      -4.7005,
      -3.5954,
      -3.5954,
      -4.8481,
      -5.5413,
      -4.8481,
      -4.8481,
      -4.8481,
      -5.5413,
      -4.8481,
      -5.5413,
      -3.344,
      -3.344,
      -3.344,
      -3.344,
      -4.155,
      -4.155,
      -4.4427,
      -4.4427,
      -4.4427,
      -2.9022,
      -3.1434,
      -2.7687,
      -3.7495,
      -3.4618,
      -4.8203,
      -3.7217,
      -4.8203,
      -4.8203,
      -4.8203,
      -4.8203,
      -4.8203,
      -4.8203,
      -4.8203,
      -4.8203,
      -3.7217,
      -3.7217,
      -3.7217,
      -4.1271,
      -4.1271,
      -4.1271,
      -4.1271,
      -4.8203,
      -4.8203,
      -2.6231,
      -3.434,
      -2.8744,
      -3.7217,
      -3.7217,
      -3.7217,
      -3.434,
      -3.434,
      -3.434,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.5074,
      1.4428,
      1.2197,
      1.1552,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.8499,
      1.0391,
      0.8692,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.088,
      1.0191,
      1.001,
      0.9161,
      0.9338,
      0.3954,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.8103,
      1.5223,
      1.5869,
      0.9829,
      1.5222,
      1.5222,
      1.5222,
      1.1165,
      1.1165,
      1.1164,
    ],
  },
  "token.table": {
    Topic: [
      5,
      4,
      4,
      2,
      2,
      5,
      5,
      1,
      4,
      3,
      3,
      3,
      3,
      4,
      2,
      1,
      2,
      1,
      2,
      4,
      4,
      5,
      3,
      3,
      5,
      3,
      1,
      4,
      5,
      3,
      3,
      5,
      4,
      2,
      1,
      1,
      5,
      1,
      3,
      5,
      1,
      1,
      1,
      2,
      3,
      5,
      1,
      4,
      2,
      3,
      1,
      3,
      4,
      5,
      3,
      4,
      1,
      4,
      2,
      4,
      2,
      1,
      2,
      5,
      3,
      2,
      4,
      1,
      2,
      4,
      4,
      1,
      4,
      5,
      1,
      4,
      5,
      4,
      5,
      4,
      2,
      4,
      5,
      3,
      2,
      5,
      4,
      5,
      1,
      4,
      5,
      1,
      1,
      1,
      1,
      1,
      4,
      5,
      4,
      2,
      5,
      3,
      5,
      2,
      3,
      3,
      1,
      3,
      5,
      3,
      2,
      4,
      5,
      3,
      3,
      5,
      5,
      5,
      3,
      2,
      3,
      1,
      4,
      4,
      3,
      2,
      2,
      5,
      4,
      5,
      4,
      4,
    ],
    Freq: [
      1.0011781350348998,
      0.9998970574011912,
      0.9998970574011912,
      0.9996830876211982,
      0.9996830876211982,
      1.0011781350348998,
      1.0011781350348998,
      0.9997755162481307,
      0.9998970574011913,
      0.9995594337148822,
      0.9995594337148822,
      0.9995594337148822,
      0.9995594337148822,
      0.9998970574011913,
      0.9996830876211982,
      0.6248380334438676,
      0.37490282006632053,
      0.2221722093008208,
      0.4443444186016416,
      0.3332583139512312,
      0.20018433139256295,
      0.8007373255702518,
      0.9995594337148822,
      0.25019324210022353,
      0.7505797263006706,
      0.9995594337148821,
      0.14283995608144334,
      0.8570397364886602,
      1.0011781350348998,
      0.9995594337148822,
      0.25019324210022353,
      0.7505797263006706,
      0.9998970574011913,
      0.9996830876211982,
      0.9997755162481307,
      0.9997755162481307,
      1.0011781350348998,
      0.8331162466708432,
      0.1666232493341686,
      1.0011781350348998,
      0.9997755162481307,
      0.9997755162481309,
      0.9997755162481309,
      0.9996830876211982,
      0.9995594337148822,
      1.0011781350348998,
      0.9997755162481307,
      0.9998970574011913,
      0.9996830876211982,
      0.9995594337148822,
      0.12505996302642228,
      0.12505996302642228,
      0.25011992605284455,
      0.5002398521056891,
      0.9995594337148822,
      0.9998970574011912,
      0.15787545232551037,
      0.842002412402722,
      0.9996830876211982,
      0.9998970574011913,
      0.9996830876211982,
      0.9997755162481307,
      0.9996830876211982,
      1.0011781350348998,
      0.9995594337148822,
      0.9996830876211982,
      0.9998970574011913,
      0.666528167728906,
      0.11108802795481766,
      0.22217605590963532,
      0.9998970574011913,
      0.12506524655346102,
      0.37519573966038305,
      0.5002609862138441,
      0.12506524655346102,
      0.37519573966038305,
      0.5002609862138441,
      0.9998970574011912,
      1.0011781350348998,
      0.9998970574011912,
      0.9996830876211982,
      0.9998970574011913,
      1.0011781350349,
      0.9995594337148822,
      0.9996830876211982,
      1.0011781350348998,
      0.9998970574011913,
      1.0011781350348998,
      0.06252809497094544,
      0.5002247597675635,
      0.4376966647966181,
      0.9997755162481307,
      0.9997755162481309,
      0.9997755162481309,
      0.9997755162481309,
      0.9997755162481309,
      0.9333168698668449,
      0.06666549070477464,
      0.9998970574011912,
      0.2502009885278823,
      0.7506029655836469,
      0.9995594337148822,
      1.0011781350349,
      0.9996830876211982,
      0.9995594337148822,
      0.9995594337148822,
      0.8886680021905119,
      0.11108350027381399,
      1.0011781350348998,
      0.9995594337148822,
      0.9996830876211982,
      0.9998970574011913,
      1.0011781350349,
      0.9995594337148822,
      0.9995594337148822,
      1.0011781350349,
      1.0011781350348998,
      1.0011781350348998,
      0.9995594337148821,
      0.9996830876211982,
      0.9995594337148822,
      0.08332391065359097,
      0.9165630171895006,
      0.9998970574011912,
      0.9995594337148822,
      0.9996830876211982,
      0.9996830876211982,
      1.0011781350348998,
      0.25021438949396285,
      0.7506431684818885,
      0.9998970574011912,
      0.9998970574011912,
    ],
    Term: [
      "abduct",
      "accuse",
      "adityanath",
      "air",
      "aircraft",
      "andhra",
      "android",
      "assembly",
      "associate",
      "atal",
      "atal_tunnel",
      "azerbaijan",
      "azerbaijani",
      "beat",
      "bengal",
      "bihar",
      "bihar",
      "bjp",
      "bjp",
      "bjp",
      "body",
      "body",
      "brazilian",
      "burn",
      "burn",
      "california",
      "case",
      "case",
      "chandrababu",
      "click",
      "communal",
      "communal",
      "company",
      "cong",
      "congress",
      "congress_leader",
      "corpse",
      "daughter",
      "daughter",
      "dead",
      "delhi",
      "digvijay",
      "digvijay_singh",
      "farmers",
      "feast",
      "find",
      "gandhi",
      "gang",
      "gas",
      "give",
      "go",
      "go",
      "go",
      "go",
      "government",
      "hathra",
      "hathras",
      "hathras",
      "huge",
      "incident",
      "include",
      "indira",
      "indonesia",
      "karnataka",
      "kashmir",
      "kick",
      "kill",
      "leader",
      "leader",
      "leader",
      "live",
      "madhya",
      "madhya",
      "madhya",
      "madhya_pradesh",
      "madhya_pradesh",
      "madhya_pradesh",
      "man",
      "message",
      "minor",
      "modi",
      "mother",
      "murder",
      "note",
      "october",
      "palasa",
      "perform",
      "phulbagan",
      "pradesh",
      "pradesh",
      "pradesh",
      "priyanka",
      "priyanka_gandhi",
      "rahul",
      "rahul_gandhi",
      "rahul_priyanka",
      "rape",
      "rape",
      "rape_victim",
      "relate",
      "relate",
      "revoke",
      "road",
      "rock",
      "screenshot",
      "shoot",
      "singh",
      "singh",
      "sister",
      "slide",
      "soon",
      "sri",
      "station",
      "structure",
      "tagore",
      "telangana",
      "title",
      "top",
      "tunnel",
      "tweet",
      "universe",
      "victim",
      "victim",
      "victim_mother",
      "war",
      "west",
      "west_bengal",
      "wire",
      "woman",
      "woman",
      "yogi",
      "yogi_adityanath",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://hindi.boomlive.in/fake-news/aap-ajay-dutt-video-viral-as-police-manhandling-rahul-gandhi-on-hathras-victim-visit-10051",
        headline:
          "'\u0906\u092a' \u0915\u0947 \u0928\u0947\u0924\u093e \u0914\u0930 \u0926\u093f\u0932\u094d\u0932\u0940 \u092a\u0941\u0932\u093f\u0938 \u0915\u0947 \u092c\u0940\u091a \u092c\u0939\u0938 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0901\u0927\u0940 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url: "https://factly.in/lt-gen-taranjit-singh-has-not-been-arrested/",
        headline: " Lt.Gen.Taranjit Singh has not been arrested.",
      },
      {
        url:
          "https://factly.in/telugu-the-woman-in-this-picture-is-not-the-daughter-of-former-madhya-pradesh-cm-digvijay-singh/",
        headline:
          " \u0c08 \u0c2b\u0c4a\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c2e\u0c3e\u0c1c\u0c40 \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30\u0c2e\u0c02\u0c24\u0c4d\u0c30\u0c3f \u0c26\u0c3f\u0c17\u0c4d\u0c35\u0c3f\u0c1c\u0c2f\u0c4d \u0c38\u0c3f\u0c02\u0c17\u0c4d \u0c15\u0c42\u0c24\u0c41\u0c30\u0c41, \u0c2e\u0c27\u0c4d\u0c2f\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c2e\u0c3e\u0c1c\u0c40 \u0c2e\u0c41\u0c16\u0c4d\u0c2f\u0c2e\u0c02\u0c24\u0c4d\u0c30\u0c3f \u0c26\u0c3f\u0c17\u0c4d\u0c35\u0c3f\u0c1c\u0c2f\u0c4d \u0c38\u0c3f\u0c02\u0c17\u0c4d \u0c15\u0c42\u0c24\u0c41\u0c30\u0c41 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/2019-photo-falsely-shared-as-rahul-and-priyanka-gandhi-laughing-en-route-to-hathras/",
        headline:
          " 2019 photo falsely shared as Rahul and Priyanka Gandhi laughing en route to Hathras",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2016-video-resurfaces-as-assault-on-congress-leader-for-sexist-comments-10080",
        headline:
          "2016 Video Resurfaces As Assault On Congress Leader For Sexist Comments",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/bjp-entrant-shreyasi-singh-is-not-the-daughter-of-congresss-digvijay-singh-10096",
        headline:
          "BJP Entrant Shreyasi Singh Is Not The Daughter Of Congress's Digvijay Singh",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-priyanka-gandhi-vadra-is-not-hugging-naxal-bhabhi-in-this-photo-10135",
        headline:
          "No, Priyanka Gandhi Vadra Is Not Hugging 'Naxal Bhabhi' In This Photo",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-photos-of-kanhaiya-kumar-falsely-linked-to-bihar-assembly-election-campaign-10107",
        headline:
          "\u09ac\u09bf\u09b9\u09be\u09b0 \u09ad\u09cb\u099f\u09c7 \u09aa\u09cd\u09b0\u099a\u09be\u09b0 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09b8\u09bf\u09aa\u09bf\u0986\u0987 \u09a8\u09c7\u09a4\u09be \u0995\u09be\u09a8\u09b9\u09be\u0987\u09df\u09be \u0995\u09c1\u09ae\u09be\u09b0\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://factly.in/shooter-shreyasi-singh-is-falsely-portrayed-as-the-daughter-of-congress-leader-digvijaya-singh/",
        headline:
          " Shooter Shreyasi Singh is falsely portrayed as the daughter of Congress leader Digvijaya Singh",
      },
      {
        url:
          "https://factly.in/a-2009-photo-falsely-shared-as-priyanka-wearing-indiras-saree-because-of-bihar-assembly-elections-2020/",
        headline:
          " A 2009 photo falsely shared as \u2018Priyanka wearing Indira\u2019s saree because of Bihar Assembly elections \u2013 2020\u2032",
      },
      {
        url:
          "https://factly.in/rahul-gandhi-did-not-call-himself-a-fool-in-this-video/",
        headline: " Rahul Gandhi did not call himself a fool in this video",
      },
      {
        url:
          "https://factly.in/telugu-2009-photo-falsely-shared-as-priyanka-wearing-indiras-saree-because-of-bihar-assembly-elections-2020/",
        headline:
          " 2009 \u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c38\u0c02\u0c26\u0c30\u0c4d\u0c2d\u0c02\u0c17\u0c3e \u0c07\u0c02\u0c26\u0c3f\u0c30\u0c3e \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c1a\u0c40\u0c30 \u0c15\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c2a\u0c4d\u0c30\u0c3f\u0c2f\u0c3e\u0c02\u0c15 \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-were-rahul-and-priyanka-caught-joking-on-way-to-meet-hathras-victim-s-kin-1728691-2020-10-05",
        headline:
          "Fact Check: Were Rahul and Priyanka caught joking on way to meet Hathras victim\u2019s kin?",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/06/fact-check-dont-fall-for-this-2019-picture-of-rahul-and-priyanka-gandhi-falsely-linked-to-the-hathras-case/",
        headline:
          "Fact Check: Don\u2019t fall for this 2019 picture of Rahul and Priyanka Gandhi falsely linked to the Hathras case",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/07/fact-check-viral-picture-of-congress-leader-rahul-gandhi-donating-blood-is-morphed/",
        headline:
          "Fact Check: Viral picture of Congress leader Rahul Gandhi donating blood is morphed",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/09/fact-check-11-yr-old-picture-of-priyanka-gandhi-vadra-falsely-linked-to-campaigning-for-upcoming-bihar-polls/",
        headline:
          "Fact Check: 11-yr-old picture of Priyanka Gandhi Vadra falsely linked to campaigning for upcoming Bihar polls",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/11/fact-check-did-congress-leader-digvijaya-singhs-daughter-join-bjp-heres-the-truth/",
        headline:
          "Fact Check: Did Congress leader Digvijaya Singh\u2019s daughter join BJP? Here\u2019s the truth",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/no-huge-rock-or-2020-rk2-will-hit-the-earths-surface-on-07-october-2020/",
        headline:
          " No huge rock or \u20182020 RK2\u2019 will hit the Earth\u2019s surface on 07 October 2020.",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-viral-photo-does-not-show-interiors-of-air-india-one-10085",
        headline: "No, Viral Photo Does Not Show Interiors Of Air India One",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/dilip-ghosh-tweets-photo-from-bihar-as-west-bengal-farmers-supporting-bjp-10093",
        headline:
          "Dilip Ghosh Tweets Photo From Bihar As West Bengal Farmers Supporting BJP",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/dilip-ghosh-tweets-photo-from-bihar-as-west-bengal-farmers-supporting-bjp-10100",
        headline:
          "\u09ac\u09be\u0982\u09b2\u09be\u09b0 \u0995\u09c3\u09b7\u0995\u09b0\u09be \u09ac\u09bf\u099c\u09c7\u09aa\u09bf\u09b0 \u09aa\u0995\u09cd\u09b7\u09c7 \u09ac\u09b2\u09c7 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u099b\u09ac\u09bf \u099f\u09c1\u0987\u099f \u0995\u09b0\u09b2\u09c7\u09a8 \u09a6\u09bf\u09b2\u09c0\u09aa \u0998\u09cb\u09b7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-viral-photo-does-not-show-interiors-of-air-india-one-10101",
        headline:
          "\u09a8\u09be, \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf\u099f\u09bf \u098f\u09df\u09be\u09b0 \u0987\u09a8\u09cd\u09a1\u09bf\u09df\u09be \u0993\u09df\u09be\u09a8 \u09ac\u09bf\u09ae\u09be\u09a8\u09c7\u09b0 \u0985\u09a8\u09cd\u09a6\u09b0\u09b8\u099c\u09cd\u099c\u09be \u09a8\u09df",
      },
      {
        url:
          "https://factly.in/photo-from-bihar-is-portrayed-as-west-bengal-farmers-expression-of-gratitude-towards-pm-modi/",
        headline:
          " Photo from Bihar is portrayed as West Bengal farmers expression of gratitude  towards PM Modi",
      },
      {
        url:
          "https://factly.in/this-is-not-the-retrofitted-boeing-777-300-er-air-india-one-which-will-be-used-to-fly-vvips-including-pm-modi/",
        headline:
          " This is not the retrofitted Boeing 777-300 ER (\u2018Air India One\u2019) which will be used to fly VVIPs including PM Modi",
      },
      {
        url:
          "https://factly.in/telugu-this-is-not-the-retrofitted-boeing-777-300-er-air-india-one-which-will-be-used-to-fly-vvips-including-pm-modi/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c35\u0c3f\u0c2e\u0c3e\u0c28\u0c02 \u0c2e\u0c4b\u0c26\u0c40, \u0c07\u0c24\u0c30 VVIP\u0c32 \u0c2a\u0c4d\u0c30\u0c2f\u0c3e\u0c23\u0c3e\u0c32 \u0c15\u0c4b\u0c38\u0c02 \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c2f\u0c2c\u0c21\u0c3f\u0c28 \u2018\u0c2c\u0c4b\u0c2f\u0c3f\u0c02\u0c17\u0c4d 777-300 \u0c08\u0c06\u0c30\u0c4d\u2019 (\u0c0e\u0c2f\u0c3f\u0c30\u0c4d \u0c07\u0c02\u0c21\u0c3f\u0c2f\u0c3e \u0c35\u0c28\u0c4d) \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/ali-fazals-old-tweet-in-support-of-caa-protests-is-being-shared-with-a-misleading-claim/",
        headline:
          " Ali Fazal\u2019s old tweet in support of CAA protests is being shared with a misleading claim",
      },
      {
        url:
          "https://factly.in/indonesia-video-falsely-shared-as-bjp-working-kicking-back-the-tear-gas-canister-in-west-bengal/",
        headline:
          " Video from Indonesia is falsely shared as \u2018BJP worker kicking back the tear gas canister in West Bengal\u2019.",
      },
      {
        url:
          "https://factly.in/telugu-kerala-campaign-photo-is-shared-as-the-one-from-west-bengal/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c15\u0c47\u0c30\u0c33\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c2a\u0c36\u0c4d\u0c1a\u0c3f\u0c2e \u0c2c\u0c46\u0c02\u0c17\u0c3e\u0c32\u0c4d \u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-a-person-kicking-the-tear-gas-canister-is-from-indonesia/",
        headline:
          " \u0c1f\u0c3f\u0c2f\u0c30\u0c4d \u0c17\u0c4d\u0c2f\u0c3e\u0c38\u0c4d \u0c15\u0c4d\u0c2f\u0c3e\u0c28\u0c4d \u0c28\u0c3f \u0c24\u0c02\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c07\u0c02\u0c21\u0c4b\u0c28\u0c47\u0c37\u0c3f\u0c2f\u0c3e\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c2c\u0c46\u0c02\u0c17\u0c3e\u0c32\u0c4d \u0c15\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-video-falsely-shared-as-an-accident-video-on-durgam-cheruvu-cable-bridge-hyderabad/",
        headline:
          " \u0c08 \u0c06\u0c15\u0c4d\u0c38\u0c3f\u0c21\u0c46\u0c02\u0c1f\u0c4d \u0c07\u0c1f\u0c40\u0c35\u0c32\u0c47 \u0c28\u0c3f\u0c30\u0c4d\u0c2e\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c26\u0c41\u0c30\u0c4d\u0c17\u0c02 \u0c1a\u0c46\u0c30\u0c41\u0c35\u0c41 \u0c15\u0c47\u0c2c\u0c41\u0c32\u0c4d \u0c2c\u0c4d\u0c30\u0c3f\u0c21\u0c4d\u0c1c\u0c3f\u0c2a\u0c48 \u0c1c\u0c30\u0c17\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/this-accident-video-is-no-way-related-to-durgam-cheruvu-cable-bridge/",
        headline:
          " This accident video has nothing to do with Durgam Cheruvu cable bridge",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/07/fact-check-dont-fall-for-this-fake-news-that-a-huge-rock-will-hit-the-earths-surface-soon/",
        headline:
          "Fact Check: Don\u2019t fall for this fake news that a huge rock will hit the earth\u2019s surface soon",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/08/fact-check-old-video-of-bjp-worker-assaulted-in-west-bengal-shared-as-that-of-cong-leader-from-mp/",
        headline:
          "Fact Check: Old video of BJP worker assaulted in West Bengal shared as that of Cong leader from MP",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/11/fact-check-video-of-protest-in-indonesia-falsely-shared-as-that-of-west-bengal/",
        headline:
          "Fact Check: Video of protest in Indonesia falsely shared as that of West Bengal",
      },
    ],
    "3": [
      {
        url:
          "https://hindi.boomlive.in/fake-news/california-tunnel-photo-viral-as-atal-surang-inaugurated-by-narendra-modi-10054",
        headline:
          "\u0915\u0948\u0932\u093f\u095e\u094b\u0930\u094d\u0928\u093f\u092f\u093e \u092e\u0947\u0902 \u0938\u094d\u0925\u093f\u0924 \u090f\u0915 \u0938\u0941\u0930\u0902\u0917 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0905\u091f\u0932 \u091f\u0928\u0932 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0939\u0941\u0908 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2012-photo-from-california-falsely-shared-as-image-of-atal-tunnel-10053",
        headline:
          "\u09e8\u09e6\u09e7\u09e8 \u09b8\u09be\u09b2\u09c7 \u0995\u09cd\u09af\u09be\u09b2\u09bf\u09ab\u09cb\u09b0\u09cd\u09a8\u09bf\u09df\u09be\u09df \u09a4\u09cb\u09b2\u09be \u099b\u09ac\u09bf \u0985\u099f\u09b2 \u099f\u09be\u09a8\u09c7\u09b2 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/up-cm-did-not-say-thakurs-make-mistakes-screenshot-is-edited-10058",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09b8\u09cd\u0995\u09cd\u09b0\u09bf\u09a8\u09b6\u099f: \u0987\u0989\u09aa\u09bf\u09b0 \u09ae\u09c1\u0996\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ac\u09b2\u09c7\u09a8\u09a8\u09bf, '\u09a0\u09be\u0995\u09c1\u09b0\u09b0\u09be \u09ad\u09c1\u09b2 \u0995\u09b0\u09a4\u09c7 \u09aa\u09be\u09b0\u09c7'",
      },
      {
        url:
          "https://factly.in/telugu-ugc-did-not-cancel-the-gitam-vignan-kl-deemed-to-be-university-status/",
        headline:
          " \u0c24\u0c3e\u0c1c\u0c17\u0c3e \u0c35\u0c3f\u0c1c\u0c4d\u0c1e\u0c3e\u0c28\u0c4d, \u0c17\u0c40\u0c24\u0c02, \u0c15\u0c4b\u0c28\u0c47\u0c30\u0c41 \u0c32\u0c15\u0c4d\u0c37\u0c4d\u0c2e\u0c2f\u0c4d\u0c2f \u2018\u0c21\u0c40\u0c2e\u0c4d\u0c21\u0c4d \u0c1f\u0c41 \u0c2c\u0c40 \u0c2f\u0c42\u0c28\u0c3f\u0c35\u200c\u0c30\u0c4d\u0c36\u0c3f\u0c1f\u0c40\u2019 \u0c39\u0c4b\u0c26\u0c3e\u0c28\u0c3f \u0c2f\u0c42\u0c1c\u0c40\u0c38\u0c40 \u0c30\u0c26\u0c4d\u0c26\u0c41 \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41, \u0c07\u0c26\u0c3f 2017 \u0c35\u0c3e\u0c30\u0c4d\u0c24",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-did-farmers-feast-on-silver-plates-after-burning-tractor-in-india-gate-heres-the-truth/",
        headline:
          "Fact Check: Did farmers feast on silver plates after burning tractor in India Gate? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-did-brazilian-government-display-currency-notes-recovered-recovered-from-corrupt-politicians-heres-the-truth/",
        headline:
          "Fact Check: Did Brazilian government display currency notes recovered from corrupt politicians? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-viral-picture-of-black-bears-feasting-on-apples-is-not-from-kashmir/",
        headline:
          "Fact Check: Viral picture of black bears feasting on apples is not from Kashmir",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/tv9-bharatvarsh-runs-arma-3-clip-as-armenia-azerbaijan-war-footage-10120",
        headline:
          "TV9 Bharatvarsh Runs ARMA-3 Clip As Armenia-Azerbaijan War Footage",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-father-asking-daughter-to-not-marry-viral-with-communal-claim-10131",
        headline:
          "Video Of Father Asking Daughter To Not Marry Viral With Communal Claim",
      },
      {
        url:
          "https://factly.in/image-of-devils-slide-tunnel-california-is-falsely-shared-as-that-of-atal-tunnel/",
        headline:
          " Image of Devil\u2019s Slide Tunnel, California is falsely shared as that of Atal Tunnel",
      },
      {
        url:
          "https://factly.in/timeline-of-the-universe-given-by-nasa-falsely-shared-as-structure-of-the-universe/",
        headline:
          " \u2018Timeline of the Universe\u2019 given by NASA is falsely shared as \u2018Structure of the Universe\u2019.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-confuse-2-popular-bloggers-in-emotional-post-on-love-1728530-2020-10-05",
        headline:
          "Fact Check: Netizens confuse 2 popular bloggers in emotional post on love",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/06/fact-check-picture-of-tunnel-in-us-clicked-in-2012-shared-as-atal-tunnel-photo/",
        headline:
          "Fact Check: Picture of tunnel in US clicked in 2012 shared as Atal Tunnel photo",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/08/fact-check-iaf-sukhoi-fighter-jet-has-not-been-shot-down-in-tibet-dont-fall-for-fake-claims/",
        headline:
          "Fact Check: IAF Sukhoi fighter jet has NOT been shot down in Tibet; don\u2019t fall for fake claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/08/fact-check-did-samajwadi-party-supremo-mulayam-singh-yadav-pass-away-heres-the-truth/",
        headline:
          "Fact Check: Did Samajwadi Party supremo Mulayam Singh Yadav pass away? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/09/fact-check-no-this-picture-of-tree-carved-into-giant-hand-is-not-from-kashmir-heres-the-truth/",
        headline:
          "Fact Check: No, this picture of tree carved into a giant hand is NOT from Kashmir; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/11/fact-check-video-game-simulation-shared-as-azerbaijani-mig-25-shot-down-by-armenian-defence-forces/",
        headline:
          "Fact Check: Video game simulation shared as Azerbaijani MiG-25 shot down by\u00a0Armenian defence forces",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fake-news/photos-of-punjab-cop-killed-in-hit-and-run-case-peddled-as-rape-victim-10046",
        headline:
          "Photos Of Punjab Cop Killed In Hit-And-Run Case Peddled As Rape Victim",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-minor-in-gujarat-assaulted-by-family-viral-as-uttar-pradesh-10060",
        headline:
          "Video Of Minor In Gujarat Assaulted By Family Viral As Uttar Pradesh",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/video-of-unrelated-woman-viral-as-hathras-ki-beti-10049",
        headline:
          "\u0924\u093e\u0932\u093f\u092f\u094b\u0902 \u0914\u0930 \u092b\u0942\u0932\u094b\u0902 \u0938\u0947 \u0938\u092e\u094d\u092e\u093e\u0928\u093f\u0924 \u092f\u0939 \u092e\u0939\u093f\u0932\u093e \u0939\u093e\u0925\u0930\u0938 \u0917\u0948\u0902\u0917\u0930\u0947\u092a \u092a\u0940\u095c\u093f\u0924\u093e \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photo-of-sri-lankan-artist-viral-as-protest-against-hathras-incident-10061",
        headline:
          "\u0936\u094d\u0930\u0940\u0932\u0902\u0915\u093e \u0938\u0947 \u090f\u0915 \u0906\u0930\u094d\u091f\u093f\u0938\u094d\u091f \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u093e\u0925\u0930\u0938 \u0930\u0947\u092a \u0915\u0947 \u0959\u093f\u0932\u093e\u095e \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-yogi-adityanath-did-not-say-our-job-to-save-cows-not-women-10048",
        headline:
          '\u09a8\u09be, \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5 \u09ac\u09b2\u09c7\u09a8\u09a8\u09bf "\u09ae\u09c7\u09af\u09bc\u09c7 \u09a8\u09af\u09bc, \u0986\u09ae\u09be\u09a6\u09c7\u09b0 \u0995\u09be\u099c \u0997\u09b0\u09c1 \u09b0\u0995\u09cd\u09b7\u09be"',
      },
      {
        url:
          "https://factly.in/telugu-unrelated-photo-falsely-shared-as-the-accused-congress-leader-in-hathras-rape-case/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f\u0c15\u0c3f \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c05\u0c24\u0c4d\u0c2f\u0c3e\u0c1a\u0c3e\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f  \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-bjp-leader-in-this-photo-has-no-relation-to-the-hathras-incident/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d BJP \u0c28\u0c47\u0c24, \u0c39\u0c24\u0c4d\u0c30\u0c3e\u0c38\u0c4d \u0c30\u0c47\u0c2a\u0c4d \u0c28\u0c3f\u0c02\u0c26\u0c3f\u0c24\u0c41\u0c28\u0c3f  \u0c24\u0c02\u0c21\u0c4d\u0c30\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/felicitation-video-of-an-e-commerce-associate-is-portrayed-as-that-of-hathras-rape-victim/",
        headline:
          " Felicitation video of an e-commerce associate is portrayed as that of Hathras rape victim",
      },
      {
        url:
          "https://factly.in/morphed-footage-shared-as-yogi-adityanaths-statement-in-support-of-the-thakur-community/",
        headline:
          " Morphed footage shared as Yogi Adityanath\u2019s statement in support of the Thakur community",
      },
      {
        url:
          "https://factly.in/image-of-yogi-adityanath-watching-live-telecast-of-the-cremation-of-hathras-rape-victim-is-morphed/",
        headline:
          " Image of Yogi Adityanath watching live telecast of the cremation of Hathras rape victim is morphed",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-did-cm-yogi-adityanath-watch-the-live-telecast-of-hathras-rape-victims-cremation-heres-the-truth/",
        headline:
          "Fact Check: Did CM Yogi Adityanath watch the live telecast of Hathras rape victim\u2019s cremation? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-did-uttar-pradesh-cm-yogi-adityanath-defend-the-accused-in-hathras-case-heres-the-truth/",
        headline:
          "Fact Check: Did Uttar Pradesh CM Yogi Adityanath defend the accused in Hathras case? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/image-of-rape-accused-in-madhya-pradesh-falsely-linked-to-hathras-case-10074",
        headline:
          "Image Of Rape Accused In Madhya Pradesh Falsely Linked To Hathras Case",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/unrelated-video-passed-off-as-hathras-victims-mother-assaulted-by-police-10077",
        headline:
          "Unrelated Video Passed Off As Hathras Victim's Mother Assaulted By Police",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/factchecking-yogi-adityanaths-claims-on-crimes-against-women-in-up-10094",
        headline:
          "Factchecking Yogi Adityanath's Claims On Crimes Against Women In UP",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/image-of-rape-accused-in-madhya-pradesh-falsely-linked-to-hathras-case-10121",
        headline:
          "\u092e\u0927\u094d\u092f \u092a\u094d\u0930\u0926\u0947\u0936 \u092e\u0947\u0902 \u092c\u0932\u093e\u0924\u094d\u0915\u093e\u0930 \u0915\u0947 \u0906\u0930\u094b\u092a\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u093e\u0925\u0930\u0938 \u092e\u093e\u092e\u0932\u0947 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-sri-lankan-artist-viral-as-protest-against-hathras-incident-10073",
        headline:
          "\u09b6\u09cd\u09b0\u09c0\u09b2\u0999\u09cd\u0995\u09be\u09b0 \u098f\u0995 \u09b6\u09bf\u09b2\u09cd\u09aa\u09c0\u09b0 \u099b\u09ac\u09bf \u09b9\u09be\u09a5\u09b0\u09b8\u09c7\u09b0 \u0998\u099f\u09a8\u09be\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photos-of-punjab-cop-killed-in-hit-and-run-case-peddled-as-rape-victim-10091",
        headline:
          "\u09a6\u09c1\u09b0\u09cd\u0998\u099f\u09a8\u09be\u09df \u09ae\u09c3\u09a4 \u09aa\u09be\u099e\u09cd\u099c\u09be\u09ac\u09c7\u09b0 \u098f\u0995 \u09aa\u09c1\u09b2\u09bf\u09b6\u0995\u09b0\u09cd\u09ae\u09c0\u09b0 \u099b\u09ac\u09bf \u09a7\u09b0\u09cd\u09b7\u09a3\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-yogi-adityanath-watching-hathras-victims-cremation-is-fake-10092",
        headline:
          "\u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u09b9\u09be\u09a5\u09b0\u09b8 \u09a8\u09bf\u09b0\u09cd\u09af\u09be\u09a4\u09bf\u09a4\u09be\u09b0 \u09a6\u09be\u09b9 \u09a6\u09c7\u0996\u09be\u09b0 \u099b\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/feminist-intervention-in-kolkata-shared-as-protest-after-hathras-incident-10108",
        headline:
          "\u0995\u09b2\u0995\u09be\u09a4\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09a8\u09be\u09b0\u09c0\u09ac\u09be\u09a6\u09c0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u099c\u09cb\u09a1\u09bc\u09be \u09b9\u09b2 \u09b9\u09be\u09a5\u09b0\u09b8\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-of-rape-accused-in-madhya-pradesh-falsely-linked-to-hathras-case-10114",
        headline:
          "\u09ae\u09a7\u09cd\u09af\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09a7\u09b0\u09cd\u09b7\u09a3\u09c7 \u0985\u09ad\u09bf\u09af\u09c1\u0995\u09cd\u09a4\u09b0 \u099b\u09ac\u09bf \u099c\u09cb\u09a1\u09bc\u09be \u09b9\u09b2 \u09b9\u09be\u09a5\u09b0\u09b8\u09c7\u09b0 \u0998\u099f\u09a8\u09be\u09b0 \u09b8\u0999\u09cd\u0997\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/unrelated-video-passed-off-as-hathras-victims-mother-assaulted-by-police-10123",
        headline:
          "\u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u09ad\u09bf\u09a1\u09bf\u0993 \u099b\u09a1\u09bc\u09be\u09b2 \u09b9\u09be\u09a5\u09b0\u09b8\u09c7\u09b0 \u09a8\u09bf\u09b0\u09cd\u09af\u09be\u09a4\u09bf\u09a4\u09be\u09b0 \u09ae\u09be\u0995\u09c7 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u09ae\u09be\u09b0\u09a7\u09cb\u09b0 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-minor-in-gujarat-assaulted-by-family-viral-as-uttar-pradesh-10126",
        headline:
          "\u0997\u09c1\u099c\u09b0\u09be\u09a4\u09c7 \u09a8\u09be\u09ac\u09be\u09b2\u09bf\u0995\u09be\u0995\u09c7 \u09ae\u09be\u09b0\u09a7\u09cb\u09b0 \u0995\u09b0\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7\u09b0 \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-felicitation-video-of-an-e-commerce-associate-is-portrayed-as-that-of-hathras-rape-victim/",
        headline:
          " \u2018Safe Shop India\u2019 \u0c08-\u0c15\u0c3e\u0c2e\u0c30\u0c4d\u0c38\u0c4d \u0c15\u0c02\u0c2a\u0c46\u0c28\u0c40 \u0c05\u0c38\u0c4b\u0c38\u0c3f\u0c2f\u0c47\u0c1f\u0c4d \u0c28\u0c3f \u0c39\u0c24\u0c4d\u0c30\u0c3e\u0c38\u0c4d \u0c05\u0c24\u0c4d\u0c2f\u0c3e\u0c1a\u0c3e\u0c30 \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c30\u0c3e\u0c32\u0c41\u0c17\u0c3e \u0c1a\u0c3f\u0c24\u0c4d\u0c30\u0c3f\u0c15\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-image-of-yogi-adityanath-watching-live-telecast-of-the-cremation-of-hathras-rape-victim-is-morphed/",
        headline:
          " \u0c0e\u0c21\u0c3f\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c39\u0c24\u0c4d\u0c30\u0c3e\u0c38\u0c4d \u0c05\u0c24\u0c4d\u0c2f\u0c3e\u0c1a\u0c3e\u0c30 \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c30\u0c3e\u0c32\u0c3f \u0c05\u0c02\u0c24\u0c4d\u0c2f\u0c15\u0c4d\u0c30\u0c3f\u0c2f\u0c32\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c24\u0c4d\u0c2f\u0c15\u0c4d\u0c37\u0c02\u0c17\u0c3e \u0c35\u0c40\u0c15\u0c4d\u0c37\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2f\u0c4b\u0c17\u0c3f \u0c06\u0c26\u0c3f\u0c24\u0c4d\u0c2f\u0c28\u0c3e\u0c25\u0c4d \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-up-police-barging-into-a-house-is-not-of-hathras-rape-victim/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c18\u0c1f\u0c28\u0c15\u0c40, \u0c24\u0c3e\u0c1c\u0c3e \u0c39\u0c24\u0c4d\u0c30\u0c3e\u0c38\u0c4d \u0c18\u0c1f\u0c28 \u0c2c\u0c3e\u0c27\u0c3f\u0c24\u0c41\u0c30\u0c3e\u0c32\u0c3f\u0c15\u0c3f \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-up-cm-yogi-adityanath-did-not-make-these-comments-on-women/",
        headline:
          " \u0c08 \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c41\u0c32\u0c4b\u0c28\u0c3f \u0c35\u0c4d\u0c2f\u0c3e\u0c16\u0c4d\u0c2f\u0c32\u0c41 \u0c2f\u0c4b\u0c17\u0c3f \u0c06\u0c26\u0c3f\u0c24\u0c4d\u0c2f\u0c28\u0c3e\u0c25\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28\u0c35\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/hathras-case-woman-abused-police-fact-check-1728897-2020-10-06",
        headline:
          "Fact Check: This woman abused by policemen is not the Hathras victim\u2019s mother\u00a0",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-is-the-man-in-the-picture-with-bjp-leaders-the-father-of-hathras-accused-heres-the-truth/",
        headline:
          "Fact Check: Is the man in the picture with BJP leaders the father of Hathras accused? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/07/fact-check-woman-being-assaulted-by-cop-in-viral-video-is-not-hathras-victims-mother/",
        headline:
          "Fact Check: Woman being assaulted by cop in viral video is NOT Hathras victim\u2019s mother",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/08/fact-check-picture-of-man-accused-of-raping-a-minor-in-mp-falsely-shared-as-being-an-accused-in-hathras-case/",
        headline:
          "Fact Check: Picture of man accused of raping a minor in MP falsely shared as being an accused in Hathras case",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/08/fact-check-did-bjps-sambit-patra-say-that-china-and-pakistan-are-involved-in-hathras-case-heres-the-truth/",
        headline:
          "Fact Check: Did BJP\u2019s Sambit Patra say that China and Pakistan are involved in Hathras case? Here\u2019s the truth",
      },
    ],
    "5": [
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-charred-body-viral-with-false-communal-spin-10067",
        headline: "Photo Of Charred Body Viral With False Communal Spin",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photos-of-punjab-cop-killed-in-hit-and-run-case-peddled-as-rape-victim-10065",
        headline:
          "\u092e\u0943\u0924 \u092e\u0939\u093f\u0932\u093e \u0938\u093f\u092a\u093e\u0939\u0940 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0930\u0947\u092a \u0914\u0930 \u0939\u0924\u094d\u092f\u093e \u0915\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-beheading-incident-in-karnataka-resurfaces-with-a-rape-twist-10047",
        headline:
          "\u09ac\u09cb\u09a8\u09c7\u09b0 \u09a7\u09b0\u09cd\u09b7\u0995\u09c7\u09b0 \u09ae\u09be\u09a5\u09be \u0995\u09c7\u099f\u09c7 \u09a5\u09be\u09a8\u09be\u09df? \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u0995\u09b0\u09cd\u09a8\u09be\u099f\u0995\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/cropped-image-of-phoolbagan-metro-stations-signboard-viral-with-false-claim-10066",
        headline:
          "\u09ab\u09c1\u09b2\u09ac\u09be\u0997\u09be\u09a8 \u09ae\u09c7\u099f\u09cd\u09b0\u09cb \u09b8\u09cd\u099f\u09c7\u09b6\u09a8\u09c7 \u09ac\u09be\u0982\u09b2\u09be \u09b2\u09c7\u0996\u09be \u09a8\u09c7\u0987 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09ac \u099b\u09ac\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/this-video-of-a-woman-getting-abducted-is-from-kolar-karnataka/",
        headline:
          " This video of a woman getting abducted is from Kolar (Karnataka).",
      },
      {
        url:
          "https://factly.in/this-picture-of-a-half-burnt-woman-body-is-related-to-a-murder-in-madhya-pradesh/",
        headline:
          " This picture of a half-burnt body of a Woman is related to a murder in Madhya Pradesh.",
      },
      {
        url:
          "https://factly.in/telugu-this-incident-of-half-burnt-womans-body-is-from-madhya-pradesh/",
        headline:
          " \u0c08 \u0c38\u0c17\u0c02 \u0c15\u0c3e\u0c32\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c36\u0c35\u0c02 \u0c2b\u0c4b\u0c1f\u0c4b \u0c2e\u0c27\u0c4d\u0c2f\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c12\u0c15 \u0c2e\u0c39\u0c3f\u0c33 \u0c39\u0c24\u0c4d\u0c2f \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f.",
      },
      {
        url:
          "https://factly.in/up-topped-the-states-in-crime-against-women-while-the-rate-of-crime-against-women-speaks-otherwise/",
        headline:
          " UP topped the states in crime against women, while the rate of crime against women speaks otherwise",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-no-this-picture-of-woman-wearing-barbed-wires-is-not-from-india/",
        headline:
          "Fact Check: No, this picture of woman wearing barbed wires is NOT from India",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/05/fact-check-picture-of-charred-body-from-madhya-pradesh-linked-to-rajasthan-with-false-claims/",
        headline:
          "Fact check: Picture of charred body from Madhya Pradesh linked to Rajasthan with false claims",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/edited-photo-of-woman-holding-a-placard-with-communal-claims-revived-10129",
        headline:
          "Edited Photo Of Woman Holding A Placard With Communal Claims Revived",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photos-of-bangladesh-domestic-abuse-victim-viral-with-communal-spin-as-kerala-10130",
        headline:
          "\u0928\u0939\u0940\u0902, \u0935\u093e\u092f\u0930\u0932 \u0939\u094b \u0930\u0939\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0915\u0947\u0930\u0932\u093e \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-charred-body-viral-with-false-communal-spin-10086",
        headline:
          "\u09ae\u09a7\u09cd\u09af\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7\u09b0 \u09aa\u09cb\u09dc\u09be \u09a6\u09c7\u09b9\u09c7\u09b0 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09ac\u09be\u09b0\u09cd\u09a4\u09be \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-the-lost-wallet-of-a-army-jawan-at-palasa-railway-station-was-returned-in-2019-itself/",
        headline:
          " \u0c2a\u0c32\u0c3e\u0c38 \u0c30\u0c48\u0c32\u0c4d\u0c35\u0c47 \u0c38\u0c4d\u0c1f\u0c47\u0c37\u0c28\u0c4d \u0c32\u0c4b \u0c26\u0c4a\u0c30\u0c3f\u0c15\u0c3f\u0c28 \u0c2a\u0c30\u0c4d\u0c38\u0c41, ID \u0c15\u0c3e\u0c30\u0c4d\u0c21\u0c41\u0c32\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f \u0c35\u0c48\u0c30\u0c32\u0c4d \u0c05\u0c35\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c2e\u0c46\u0c38\u0c47\u0c1c\u0c4d, 2019\u0c32\u0c4b \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-1971-photo-is-falsely-shared-as-hindu-families-being-thrown-out-of-telangana-by-nizams-in-1948/",
        headline:
          " 1971 \u0c2c\u0c3e\u0c02\u0c17\u0c4d\u0c32\u0c3e\u0c26\u0c47\u0c36\u0c4d \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c02\u0c1a\u0c3f \u0c39\u0c3f\u0c02\u0c26\u0c41\u0c35\u0c41\u0c32\u0c28\u0c3f \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c3e \u0c28\u0c41\u0c02\u0c1a\u0c3f \u0c24\u0c30\u0c3f\u0c2e\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3f\u0c1c\u0c3e\u0c02 \u0c30\u0c1c\u0c3e\u0c15\u0c3e\u0c30\u0c4d\u0c32\u0c41 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-ap-highway-photo-is-falsely-credited-to-kcr/",
        headline:
          " \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c30\u0c39\u0c26\u0c3e\u0c30\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c05\u0c26\u0c4d\u0c2d\u0c41\u0c24\u0c2e\u0c48\u0c28 \u0c30\u0c39\u0c26\u0c3e\u0c30\u0c3f \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-ahmedabad-vadodara-expressway-falsely-credited-to-andhra-pradesh-ex-cm-chandrababu-naidu/",
        headline:
          " \u2018\u0c1a\u0c02\u0c26\u0c4d\u0c30\u0c2c\u0c3e\u0c2c\u0c41 \u0c2a\u0c28\u0c3f\u0c24\u0c40\u0c30\u0c41\u2019 \u0c05\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f\u0c28 \u0c08 \u0c30\u0c39\u0c26\u0c3e\u0c30\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/06/fact-check-no-the-govt-is-not-providing-free-android-smartphones-to-students/",
        headline:
          "Fact Check: No, the Govt is NOT providing free Android smartphones to students",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/06/fact-check-viral-video-of-woman-being-abducted-is-neither-from-uttar-pradesh-nor-telangana/",
        headline:
          "Fact Check: Viral video of woman being abducted is neither from Uttar Pradesh nor Telangana",
      },
    ],
  },
  numpy_seed: 1,
  number_of_articles: 102,
}
