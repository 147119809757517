export const data = {
  mdsDat: {
    x: [
      0.4218853657631871,
      -0.12226759225104107,
      -0.1339055736700229,
      -0.16571219984212335,
    ],
    y: [
      0.003889204157130512,
      -0.3028069415855893,
      0.3417540043182775,
      -0.04283626688981894,
    ],
    topics: [1, 2, 3, 4],
    cluster: [1, 1, 1, 1],
    Freq: [
      19.6862945472819,
      24.567956957448224,
      25.828686324351718,
      29.917062170918168,
    ],
  },
  tinfo: {
    Term: [
      "protest",
      "victim",
      "farmer",
      "ambedkar",
      "bus",
      "kerala",
      "bill",
      "wife",
      "morphed",
      "shivraj",
      "moon",
      "intercept",
      "intoxicate",
      "cloud",
      "sabha",
      "list",
      "kailas",
      "narrative",
      "grow",
      "animal",
      "ambedkar",
      "bus",
      "shivraj",
      "wife",
      "edit",
      "ambedkar_wife",
      "morphed",
      "habit",
      "rare",
      "take",
      "chouhan",
      "brain",
      "mp",
      "chief",
      "say",
      "incident",
      "huge",
      "apply",
      "store",
      "shiv",
      "edited",
      "suicide",
      "kya",
      "beware",
      "offer",
      "death",
      "pmjjby_pmsby",
      "pmsby",
      "pmjjby",
      "cabinet",
      "year",
      "cover",
      "roentgen",
      "crowd",
      "government",
      "sena",
      "covid",
      "insurance",
      "related",
      "saudi",
      "film",
      "kerala",
      "show",
      "attack",
      "beat",
      "bangladesh",
      "station",
      "make",
      "yogi",
      "election",
      "einstein",
      "bruise",
      "sweep",
      "victim",
      "bill",
      "farm_bills",
      "jihad",
      "love_jihad",
      "love",
      "farm",
      "bjp",
      "domestic",
      "kerala",
      "go",
      "link",
      "sonia",
      "unrelated",
      "panel",
      "haryana",
      "solar",
      "scene",
      "pm_modi",
      "appear",
      "home",
      "message",
      "pm",
      "rajasthan",
      "shared_one",
      "farmers",
      "protest",
      "farmer",
      "fall",
      "modi",
    ],
    Freq: [
      9.0,
      5.0,
      7.0,
      4.0,
      4.0,
      9.0,
      4.0,
      3.0,
      3.0,
      3.0,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      2.000127526003841,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      2.000127526003841,
      4.000255052007682,
      4.000255052007682,
      3.0001912890057616,
      3.0001912890057616,
      3.0001912890057616,
      3.0001912890057616,
      3.0001912890057616,
      2.000127526003841,
      2.000127526003841,
      2.000127526003841,
      2.000127526003841,
      2.000127526003841,
      3.0001912890057616,
      2.000127526003841,
      2.000127526003841,
      2.000083675382003,
      1.0000418376910014,
      1.0000418376910014,
      2.000083675382003,
      2.000083675382003,
      1.0000418376910014,
      1.0000418376910014,
      1.0000418376910014,
      1.0000418376910014,
      2.000083675382003,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      2.000083675382003,
      2.000083675382003,
      2.000083675382003,
      2.000083675382003,
      4.000167350764006,
      2.000083675382003,
      2.000083675382003,
      3.0001255130730047,
      3.0001255130730047,
      4.000167350764006,
      3.0001255130730047,
      1.000074135119716,
      1.000074135119716,
      3.000222405359148,
      1.000074135119716,
      2.000148270239432,
      1.000074135119716,
      2.000148270239432,
      1.000074135119716,
      1.000074135119716,
      1.000074135119716,
      5.00037067559858,
      4.000296540478864,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      5.00037067559858,
      3.000222405359148,
      3.000222405359148,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      0.9998597093964756,
      0.9998597093964756,
      0.9998597093964756,
      3.9994388375859025,
      3.9994388375859025,
      3.9994388375859025,
      3.9994388375859025,
      8.998737384568281,
      6.999017965775328,
      2.999579128189427,
      2.999579128189427,
    ],
    Total: [
      9.0,
      5.0,
      7.0,
      4.0,
      4.0,
      9.0,
      4.0,
      3.0,
      3.0,
      3.0,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      2.000127526003841,
      1.0000637630019205,
      1.0000637630019205,
      1.0000637630019205,
      2.000127526003841,
      4.000255052007682,
      4.000255052007682,
      3.0001912890057616,
      3.0001912890057616,
      3.0001912890057616,
      3.0001912890057616,
      3.0001912890057616,
      2.000127526003841,
      2.000127526003841,
      2.000127526003841,
      2.000127526003841,
      2.000127526003841,
      4.000265424125478,
      2.000127526003841,
      4.000061370520033,
      2.000083675382003,
      1.0000418376910014,
      1.0000418376910014,
      2.000083675382003,
      2.000083675382003,
      1.0000418376910014,
      1.0000418376910014,
      1.0000418376910014,
      1.0000418376910014,
      2.000083675382003,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      3.0001255130730047,
      2.000083675382003,
      2.000083675382003,
      2.000083675382003,
      2.000083675382003,
      5.000027060160481,
      2.000083675382003,
      2.000083675382003,
      3.9999852224694803,
      4.000199648192721,
      9.000538026362586,
      5.999908694867877,
      1.000074135119716,
      1.000074135119716,
      3.000222405359148,
      1.000074135119716,
      2.000148270239432,
      1.000074135119716,
      2.000148270239432,
      1.000074135119716,
      1.000074135119716,
      1.000074135119716,
      5.00037067559858,
      4.000296540478864,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      3.000222405359148,
      9.000538026362586,
      4.999941824152099,
      5.000123952446625,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      2.999579128189427,
      0.9998597093964756,
      0.9998597093964756,
      0.9998597093964756,
      3.9994388375859025,
      3.9994388375859025,
      3.9994388375859025,
      3.9994388375859025,
      9.998811519687997,
      7.999092100895044,
      3.999642891191347,
      4.999727398428859,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.8283,
      -4.8283,
      -4.8283,
      -4.8283,
      -4.8283,
      -4.1352,
      -4.8283,
      -4.8283,
      -4.8283,
      -4.1352,
      -3.442,
      -3.442,
      -3.7297,
      -3.7297,
      -3.7297,
      -3.7297,
      -3.7297,
      -4.1352,
      -4.1352,
      -4.1352,
      -4.1352,
      -4.1352,
      -3.7297,
      -4.1352,
      -4.1352,
      -4.3567,
      -5.0499,
      -5.0499,
      -4.3567,
      -4.3567,
      -5.0499,
      -5.0499,
      -5.0499,
      -5.0499,
      -4.3567,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -4.3567,
      -4.3567,
      -4.3567,
      -4.3567,
      -3.6636,
      -4.3567,
      -4.3567,
      -3.9512,
      -3.9512,
      -3.6636,
      -3.9512,
      -5.0999,
      -5.0999,
      -4.0013,
      -5.0999,
      -4.4067,
      -5.0999,
      -4.4067,
      -5.0999,
      -5.0999,
      -5.0999,
      -3.4904,
      -3.7136,
      -4.0013,
      -4.0013,
      -4.0013,
      -4.0013,
      -4.0013,
      -4.0013,
      -4.0013,
      -3.4904,
      -4.0013,
      -4.0013,
      -4.1484,
      -4.1484,
      -4.1484,
      -4.1484,
      -4.1484,
      -4.1484,
      -4.1484,
      -5.247,
      -5.247,
      -5.247,
      -3.8607,
      -3.8607,
      -3.8607,
      -3.8607,
      -3.0498,
      -3.3011,
      -4.1484,
      -4.1484,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.6252,
      1.3376,
      1.6252,
      0.9321,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.4037,
      1.1806,
      1.4037,
      1.4037,
      1.1161,
      1.116,
      0.5928,
      0.7106,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      1.3537,
      0.7659,
      0.8429,
      0.8429,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.2067,
      1.1014,
      1.0732,
      0.919,
      0.6958,
    ],
  },
  "token.table": {
    Topic: [
      1,
      1,
      1,
      4,
      2,
      3,
      3,
      3,
      2,
      3,
      3,
      1,
      3,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      4,
      2,
      2,
      3,
      1,
      2,
      3,
      3,
      1,
      4,
      3,
      3,
      3,
      4,
      4,
      2,
      3,
      3,
      4,
      2,
      1,
      1,
      4,
      4,
      2,
      2,
      2,
      1,
      1,
      3,
      1,
      2,
      3,
      2,
      2,
      3,
      4,
      1,
      3,
      3,
      3,
      4,
      3,
      4,
      1,
      1,
      1,
      3,
      1,
      2,
      4,
      4,
      4,
      2,
      2,
      2,
      3,
      4,
      4,
      1,
      2,
      2,
      1,
      2,
      4,
      1,
      3,
      4,
      4,
      2,
      4,
      2,
      1,
      1,
      2,
      4,
      4,
      4,
      3,
      2,
      2,
      3,
      1,
      4,
      3,
      1,
      2,
      3,
    ],
    Freq: [
      0.9999362410635407,
      0.9999362410635406,
      0.9999362410635407,
      1.0001403102877393,
      0.9999581640593177,
      0.9999258703758925,
      0.9999258703758925,
      0.9999258703758925,
      0.9999581640593177,
      0.9999258703758925,
      0.9999258703758925,
      0.9999362410635407,
      0.9999258703758925,
      0.9999362410635407,
      0.9999581640593176,
      0.9999362410635407,
      0.9999362410635407,
      0.9999362410635407,
      0.9999581640593176,
      0.799995670397755,
      0.19999891759943875,
      0.9999581640593177,
      0.9999581640593176,
      0.9999258703758925,
      0.9999362410635406,
      0.9999581640593177,
      0.9999258703758925,
      0.9999258703758925,
      0.2500223212933234,
      0.7500669638799703,
      0.9999258703758925,
      0.9999258703758925,
      0.12501418753362106,
      0.8750993127353475,
      1.0001403102877393,
      0.749962567832181,
      0.249987522610727,
      0.6000069811829754,
      0.40000465412198355,
      0.9999581640593177,
      0.9999362410635407,
      0.9999362410635407,
      1.0001403102877393,
      1.0001403102877393,
      0.9999581640593177,
      0.9999581640593177,
      0.9999581640593177,
      0.9999362410635407,
      0.9999362410635407,
      0.9999258703758925,
      0.9999362410635407,
      0.4444178768295846,
      0.5555223460369807,
      0.9999581640593177,
      0.19999504202504562,
      0.5999851260751369,
      0.19999504202504562,
      0.9999362410635407,
      0.9999258703758925,
      0.9999258703758925,
      0.9999258703758925,
      1.0001403102877393,
      0.40002180931474196,
      0.600032713972113,
      0.9999362410635407,
      0.9999362410635406,
      0.749950236278596,
      0.24998341209286534,
      0.9999362410635407,
      0.9999581640593177,
      1.0001403102877393,
      1.0001403102877393,
      1.0001403102877393,
      0.9999581640593176,
      0.9999581640593176,
      0.9999581640593176,
      0.10001188621577337,
      0.9001069759419603,
      1.0001403102877393,
      0.9999362410635407,
      0.9999581640593177,
      0.9999581640593177,
      0.9999362410635407,
      0.7500027707972088,
      0.2500009235990696,
      0.49999232880269223,
      0.24999616440134612,
      0.24999616440134612,
      1.0001403102877393,
      0.9999581640593177,
      1.0001403102877393,
      0.9999581640593177,
      0.9999362410635406,
      0.16666920295893284,
      0.5000076088767985,
      0.3333384059178657,
      1.0001403102877393,
      1.0001403102877393,
      0.9999258703758925,
      0.9999581640593177,
      0.9999581640593177,
      0.9999258703758925,
      0.9999362410635407,
      1.0001403102877393,
      0.9999258703758925,
      0.9999362410635406,
      0.9999581640593176,
      0.9999258703758925,
    ],
    Term: [
      "ambedkar",
      "ambedkar_wife",
      "animal",
      "appear",
      "apply",
      "attack",
      "bangladesh",
      "beat",
      "beware",
      "bill",
      "bjp",
      "brain",
      "bruise",
      "bus",
      "cabinet",
      "chief",
      "chouhan",
      "cloud",
      "cover",
      "covid",
      "covid",
      "crowd",
      "death",
      "domestic",
      "edit",
      "edited",
      "einstein",
      "election",
      "fall",
      "fall",
      "farm",
      "farm_bills",
      "farmer",
      "farmer",
      "farmers",
      "film",
      "film",
      "go",
      "go",
      "government",
      "grow",
      "habit",
      "haryana",
      "home",
      "huge",
      "incident",
      "insurance",
      "intercept",
      "intoxicate",
      "jihad",
      "kailas",
      "kerala",
      "kerala",
      "kya",
      "link",
      "link",
      "link",
      "list",
      "love",
      "love_jihad",
      "make",
      "message",
      "modi",
      "modi",
      "moon",
      "morphed",
      "mp",
      "mp",
      "narrative",
      "offer",
      "panel",
      "pm",
      "pm_modi",
      "pmjjby",
      "pmjjby_pmsby",
      "pmsby",
      "protest",
      "protest",
      "rajasthan",
      "rare",
      "related",
      "roentgen",
      "sabha",
      "saudi",
      "saudi",
      "say",
      "say",
      "say",
      "scene",
      "sena",
      "shared_one",
      "shiv",
      "shivraj",
      "show",
      "show",
      "show",
      "solar",
      "sonia",
      "station",
      "store",
      "suicide",
      "sweep",
      "take",
      "unrelated",
      "victim",
      "wife",
      "year",
      "yogi",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://hindi.boomlive.in/fake-news/madhya-pradesh-shivraj-singh-chouhan-slipper-chappal-bjp-mp-chief-minister-9874",
        headline:
          "\u092e\u0927\u094d\u092f\u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0947 \u092e\u0941\u0916\u094d\u092f\u092e\u0902\u0924\u094d\u0930\u0940 \u0936\u093f\u0935\u0930\u093e\u091c \u0938\u093f\u0902\u0939 \u091a\u094c\u0939\u093e\u0928 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092b\u093f\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-video-of-student-protesters-blocking-convoy-of-up-cm-yogi-adityanath-shared-as-recent-9838",
        headline:
          "\u09ae\u09c1\u0996\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u0995\u09a8\u09ad\u09df \u0986\u099f\u0995\u09be\u09a8\u09cb\u09b0 \u09e8\u09e6\u09e7\u09ed'\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/silicon-sculpture-by-italian-artist-shared-as-rare-animal-found-in-ajodhya-hills-9842",
        headline:
          "\u0987\u09a4\u09be\u09b2\u09bf\u09b0 \u09b6\u09bf\u09b2\u09cd\u09aa\u09c0\u09b0 \u09b8\u09bf\u09b2\u09bf\u0995\u09a8 \u09ad\u09be\u09b8\u09cd\u0995\u09b0\u09cd\u09af\u0995\u09c7 \u0985\u09af\u09cb\u09a7\u09cd\u09af\u09be \u09aa\u09be\u09b9\u09be\u09dc\u09c7\u09b0 \u09ac\u09bf\u09b0\u09b2 \u09aa\u09cd\u09b0\u09be\u09a3\u09c0 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-doctored-speech-by-tmc-mp-kalyan-banerjee-in-lok-sabha-viral-with-false-claim-9859",
        headline:
          "\u09a4\u09c3\u09a3\u09ae\u09c2\u09b2 \u09b8\u09be\u0982\u09b8\u09a6 \u0995\u09b2\u09cd\u09af\u09be\u09a3 \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df \u09a8\u09c7\u09b6\u09be \u0995\u09b0\u09c7 \u09b2\u09cb\u0995\u09b8\u09ad\u09be\u09df? \u0995\u09cd\u09b2\u09bf\u09aa\u099f\u09bf \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-of-bus-with-br-ambedkar-and-his-wifes-photo-across-it-is-fake-9863",
        headline:
          "\u09ac\u09bf \u0986\u09b0 \u0985\u09ae\u09cd\u09ac\u09c7\u09a6\u0995\u09b0 \u0993 \u09a4\u09be\u0981\u09b0 \u09b8\u09cd\u09a4\u09cd\u09b0\u09c0\u09b0 \u099b\u09ac\u09bf \u09b2\u09be\u0997\u09be\u09a8\u09cb \u09ac\u09be\u09b8\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/rumours-about-rare-animal-found-in-purulia-rife-after-social-media-posts-media-misreports-aggravate-9890",
        headline:
          "\u09b8\u09cb\u09b6\u09be\u09b2 \u09ae\u09bf\u09a1\u09bf\u09df\u09be \u09aa\u09cb\u09b8\u09cd\u099f\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09c1\u09b0\u09c1\u09b2\u09bf\u09df\u09be\u09df \u09ac\u09bf\u09b0\u09b2 \u09aa\u09cd\u09b0\u09be\u09a3\u09c0 \u09b9\u09a6\u09bf\u09b8 \u09aa\u09be\u0993\u09df\u09be\u09b0 \u0997\u09c1\u099c\u09ac",
      },
      {
        url:
          "https://bangla.boomlive.in/fast-check/morphed-image-of-mamata-banerjee-with-cash-revived-with-misleading-claims-9902",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09ad\u09be\u09ac\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u099f\u09be\u0995\u09be \u09b8\u09b9 \u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://factly.in/morphed-photo-shared-as-babasaheb-ambedkar-and-his-wifes-picture-on-a-bus-in-america/",
        headline:
          " Morphed photo shared as  Babasaheb Ambedkar and his wife\u2019s picture on a bus in America",
      },
      {
        url:
          "https://factly.in/telugu-this-image-of-moon-is-not-from-kailash-manasarovar/",
        headline:
          " \u0c08 \u0c1a\u0c02\u0c26\u0c4d\u0c30\u0c41\u0c21\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b \u0c15\u0c48\u0c32\u0c3e\u0c38\u0c4d \u0c2e\u0c3e\u0c28\u0c38\u0c30\u0c4b\u0c35\u0c30\u0c4d \u0c26\u0c17\u0c4d\u0c17\u0c30\u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/who-did-not-list-out-these-7-brain-damaging-habits/",
        headline: " WHO did not list out these 7 brain-damaging habits",
      },
      {
        url:
          "https://factly.in/telugu-a-normally-grown-plant-is-being-portrayed-as-shivalinga-pushpam/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c38\u0c3e\u0c27\u0c3e\u0c30\u0c23\u0c02\u0c17\u0c3e \u0c2a\u0c46\u0c30\u0c3f\u0c17\u0c47  \u0c35\u0c3f\u0c24\u0c4d\u0c24\u0c28\u0c2a\u0c41 \u0c2e\u0c4a\u0c15\u0c4d\u0c15\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c39\u0c3f\u0c2e\u0c3e\u0c32\u0c2f\u0c3e\u0c32\u0c4d\u0c32\u0c4b\u0c28\u0c3f \u0c36\u0c3f\u0c35\u0c32\u0c3f\u0c02\u0c17 \u0c2a\u0c41\u0c37\u0c4d\u0c2a\u0c02 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-who-did-not-release-the-seven-brain-damaging-habits-list/",
        headline:
          " \u0c2e\u0c46\u0c26\u0c21\u0c41\u0c15\u0c3f \u0c39\u0c3e\u0c28\u0c3f \u0c15\u0c32\u0c3f\u0c17\u0c3f\u0c02\u0c1a\u0c47 7 \u0c05\u0c32\u0c35\u0c3e\u0c1f\u0c4d\u0c32 \u0c32\u0c3f\u0c38\u0c4d\u0c1f\u0c4d WHO \u0c35\u0c3f\u0c21\u0c41\u0c26\u0c32 \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-madhya-pradesh-congress-shares-fake-video-to-take-a-dig-at-shivraj-chouhan-1724022-2020-09-21",
        headline:
          "Fact Check: Madhya Pradesh Congress shares fake video to take a dig at Shivraj Chouhan",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-image-of-ambedkar-on-a-bus-in-the-united-states-is-morphed-1723883-2020-09-21",
        headline:
          "Fact Check: This image of Ambedkar on a bus in the \u2018United States\u2019 is morphed",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-dont-fall-for-this-morphed-picture-of-aap-mp-bhagwant-mann-having-liquor-in-office/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed picture of AAP MP Bhagwant Mann having liquor in office",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/ffact-check-photo-of-br-ambedkar-his-wife-displayed-on-bus-in-columbia-heres-the-truth/",
        headline:
          "Fact Check: Photo of BR Ambedkar & his wife displayed on bus in Columbia? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-is-big-b-with-underworld-don-dawood-ibrahim-in-this-viral-picture-find-out-the-truth/",
        headline:
          "Fact Check: Is Big B with underworld don Dawood Ibrahim in this viral picture? Find out the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-old-picture-of-clouds-during-sunset-shared-with-false-california-wildfires-claim/",
        headline:
          "Fact Check: Old picture of clouds during sunset shared with false California wildfires claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/23/fact-check-edited-video-of-mp-cm-shivraj-singh-chouhans-rally-shared-with-false-claim/",
        headline:
          "Fact Check: Edited video of MP CM Shivraj Singh Chouhan\u2019s rally shared with false claim",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/26/fact-check-shahrukh-khans-2019-picture-morphed-to-create-false-narrative/",
        headline:
          "Fact Check: Shahrukh Khan\u2019s 2019 picture morphed to create false narrative",
      },
    ],
    "2": [
      {
        url:
          "https://bangla.boomlive.in/fake-news/2019-video-of-shiv-sena-workers-tonsuring-a-mans-head-shared-as-recent-9835",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b6\u09c7 \u09b6\u09bf\u09ac \u09b8\u09c7\u09a8\u09be \u0995\u09b0\u09cd\u09ae\u09c0\u09a6\u09c7\u09b0 \u098f\u0995 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u09ae\u09be\u09a5\u09be \u09ae\u09cb\u09a1\u09bc\u09be\u09a8\u09cb\u0995\u09c7 \u09b9\u09be\u09b2\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/insurance-claims-for-covid-19-deaths-only-under-pmjjby-not-pmsby-9875",
        headline:
          "Insurance Claims For COVID-19 Deaths Only Under PMJJBY, Not PMSBY",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-the-man-in-this-viral-clip-is-not-the-owner-of-himalaya-drug-co-9938",
        headline:
          "No, The Man In This Viral Clip Is Not The Owner Of Himalaya Drug Co",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/picture-showing-indian-army-soldiers-atop-kailash-mansarovar-is-edited-9914",
        headline:
          "\u0915\u0948\u0932\u093e\u0936 \u092e\u093e\u0928\u0938\u0930\u094b\u0935\u0930 \u092a\u0930 \u092d\u093e\u0930\u0924\u0940\u092f \u0938\u0947\u0928\u093e \u0915\u093e \u0915\u092c\u094d\u095b\u093e \u0926\u093f\u0916\u093e\u0924\u0940 \u092f\u0947 \u0924\u0938\u094d\u0935\u0940\u0930 \u090f\u0921\u093f\u091f\u0947\u0921 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2017-video-of-teen-assaulted-by-a-man-in-andhra-pradesh-peddled-as-kerala-9936",
        headline:
          "\u0906\u0902\u0927\u094d\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u092e\u0947\u0902 2017 \u0915\u0940 \u090f\u0915 \u0918\u091f\u0928\u093e \u0915\u094b \u0915\u0947\u0930\u0932\u093e \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0915\u093f\u092f\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://factly.in/covid-19-related-deaths-are-covered-under-pmjjby-but-not-under-pmsby/",
        headline:
          " COVID-19 related deaths are covered under PMJJBY but not under PMSBY",
      },
      {
        url:
          "https://factly.in/this-photo-is-from-the-bollywood-film-kya-yahi-sach-hai/",
        headline:
          " This photo is from the Bollywood film \u2018Kya Yahi Sach Hai\u2019",
      },
      {
        url:
          "https://factly.in/telugu-covid-19-related-deaths-are-covered-under-pmjjby-but-not-under-pmsby/",
        headline:
          " \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d-19 \u0c2e\u0c30\u0c23\u0c3e\u0c32\u0c15\u0c41 \u2018\u0c2a\u0c4d\u0c30\u0c27\u0c3e\u0c28\u0c2e\u0c02\u0c24\u0c4d\u0c30\u0c3f \u0c1c\u0c40\u0c35\u200c\u0c28\u0c4d \u0c1c\u0c4d\u0c2f\u0c4b\u0c24\u0c3f \u0c2d\u0c40\u0c2e\u0c3e \u0c2f\u0c4b\u0c1c\u200c\u0c28\u2019 \u0c35\u0c30\u0c4d\u0c24\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c26\u0c3f; \u2018\u0c38\u0c41\u0c30\u200c\u0c15\u0c4d\u0c37\u0c3e \u0c2d\u0c40\u0c2e\u0c3e \u0c2f\u0c4b\u0c1c\u200c\u0c28\u2019 \u0c35\u0c30\u0c4d\u0c24\u0c3f\u0c02\u0c1a\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/the-roentgens-berlin-secretary-cabinet-is-portrayed-as-the-150-years-almirah-in-kerala/",
        headline:
          " \u2018The Roentgen\u2019s Berlin Secretary Cabinet\u2019 is portrayed as the 150 years Almirah in Kerala",
      },
      {
        url:
          "https://factly.in/telugu-the-roentgens-berlin-secretary-cabinet-is-portrayed-as-the-150-years-almirah-in-kerala/",
        headline:
          " \u2018The Roentgen\u2019s Berlin Secretery Cabinet\u2019 \u0c28\u0c3f \u0c15\u0c47\u0c30\u0c33 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c02\u0c32\u0c4b\u0c28\u0c3f 150 \u0c38\u0c02\u0c35\u0c24\u0c4d\u0c38\u0c30\u0c3e\u0c32 \u0c05\u0c32\u0c4d\u0c2e\u0c30\u0c3e\u0c17\u0c3e \u0c1a\u0c3f\u0c24\u0c4d\u0c30\u0c3f\u0c15\u0c30\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/beware-of-bogus-websites-offering-free-laptops-smartphones-and-scholarships-in-the-name-of-government/",
        headline:
          " Beware of bogus websites offering free laptops, smartphones, and scholarships in the name of Government",
      },
      {
        url:
          "https://factly.in/telugu-government-is-not-distributing-free-laptops-smartphones-dont-register-on-unknown-links/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c09\u0c1a\u0c3f\u0c24\u0c02\u0c17\u0c3e \u0c38\u0c4d\u0c2e\u0c3e\u0c30\u0c4d\u0c1f\u0c4d \u0c2b\u0c4b\u0c28\u0c4d\u0c32\u0c41, \u0c32\u0c3e\u0c2a\u0c4d \u0c1f\u0c3e\u0c2a\u0c4d\u0c32\u0c41, \u0c38\u0c4d\u0c15\u0c3e\u0c32\u0c30\u0c4d\u0c37\u0c3f\u0c2a\u0c4d \u0c32\u0c41 \u0c05\u0c02\u0c26\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c1a\u0c46\u0c2a\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2b\u0c47\u0c15\u0c4d \u0c35\u0c46\u0c2c\u0c4d \u0c38\u0c48\u0c1f\u0c4d\u0c32\u0c15\u0c41 \u0c26\u0c42\u0c30\u0c02\u0c17\u0c3e \u0c09\u0c02\u0c21\u0c02\u0c21\u0c3f.",
      },
      {
        url:
          "https://factly.in/this-video-shows-a-crowd-gathering-in-front-of-a-discount-store-in-saudi-arabia-in-2019/",
        headline:
          " This video shows a crowd gathering in front of a discount store in Saudi Arabia in 2019",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-israeli-film-in-saudi-theatre-truth-behind-crowd-trying-to-gatecrash-building-1724546-2020-09-23",
        headline:
          "Fact Check: Israeli film in Saudi theatre? Truth behind crowd trying to gatecrash building",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-2019-video-of-a-mans-head-being-tonsured-by-shiv-sena-members-resurfaces-as-recent/",
        headline:
          "Fact Check: 2019 video of a man\u2019s head being tonsured by Shiv Sena members resurfaces as recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-rakhi-sawants-2019-film-shoot-picture-with-pak-flag-resurfaces-with-misleading-claims/",
        headline:
          "Fact Check: Rakhi Sawant\u2019s 2019 film shoot picture with Pak flag resurfaces with misleading claims",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/23/fact-check-does-this-video-show-moving-roots-of-shivnaga-tree-heres-the-truth/",
        headline:
          "Fact Check: Does this video show moving roots of \u2018Shivnaga\u2019 tree? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/24/fact-check-old-news-report-of-a-group-of-friends-committing-suicide-falsely-linked-to-covid-19-related-unemployment/",
        headline:
          "Fact Check: Old news report of a group of friends committing suicide falsely linked to COVID-19 related unemployment",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/24/fact-check-is-this-a-150-year-old-cabinet-from-kerala-heres-the-truth/",
        headline:
          "Fact Check: Is this a 150-year-old cabinet from Kerala? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/25/fact-check-old-video-of-crowded-store-falsely-shared-as-huge-gathering-in-saudi-theatre/",
        headline:
          "Fact Check: Old video of crowded store falsely shared as huge gathering in Saudi theatre",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/26/fact-check-does-pmjjby-and-pmsby-provide-insurance-cover-for-covid-19-related-deaths-heres-the-truth/",
        headline:
          "Fact Check: Does PMJJBY and PMSBY provide insurance cover for COVID-19 related deaths? Here\u2019s the truth",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fake-news/farm-bills-protest-2013-photo-shared-as-recent-9878",
        headline: "Farm Bills Protest: 2013 Photo Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photos-of-bangladesh-domestic-abuse-victim-viral-with-communal-spin-as-kerala-9903",
        headline:
          "Photos Of Bangladesh Domestic Abuse Victim Viral With Communal Spin As Kerala",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/albert-einstein-university-of-bern-viral-rejection-letter-9844",
        headline:
          "\u0905\u0932\u094d\u092c\u0930\u094d\u091f \u0906\u0907\u0902\u0938\u094d\u091f\u093e\u0907\u0928 \u0914\u0930 \u092f\u0942\u0928\u093f\u0935\u0930\u094d\u0938\u093f\u091f\u0940 \u0911\u095e \u092c\u0930\u094d\u0928 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932 \u095e\u0930\u094d\u095b\u0940 \u092a\u0924\u094d\u0930 \u0915\u0940 \u0926\u093e\u0938\u094d\u0924\u093e\u0928",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/jobs-employment-unemployment-youth-joblessness-ravishankar-prasad-9853",
        headline:
          "\u0930\u094b\u095b\u0917\u093e\u0930 \u0915\u094b \u0932\u0947\u0915\u0930 \u0930\u0935\u093f\u0936\u0902\u0915\u0930 \u092a\u094d\u0930\u0938\u093e\u0926 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u093e \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/film-city-uttar-pradesh-yogi-adityanath-yamuna-expressway-noida-up-sector-21-uttar-pradesh-cm-9858",
        headline:
          "\u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u093f\u0932\u094d\u092e \u0938\u093f\u091f\u0940 \u0915\u093e \u0928\u093f\u0930\u0940\u0915\u094d\u0937\u0923 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/no-an-adani-food-silo-has-not-come-up-after-passage-of-agri-bills-9901",
        headline:
          "\u0928\u0939\u0940\u0902, \u0915\u0943\u0937\u093f \u092c\u093f\u0932\u094d\u0938 \u092a\u093e\u0930\u093f\u0924 \u0939\u094b\u0924\u0947 \u0939\u0940 \u0905\u0921\u093e\u0928\u0940 \u0915\u093e \u092f\u0939 \u0938\u093e\u0907\u0932\u094b \u0928\u0939\u0940\u0902 \u092c\u0928\u093e\u092f\u093e \u0917\u092f\u093e \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/image-of-pm-modi-with-swami-atmasthananda-from-2015-shared-with-misleading-claims-9912",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099b\u09a1\u09bc\u09be\u09b2 \u09b8\u09cd\u09ac\u09be\u09ae\u09c0 \u0986\u09a4\u09cd\u09ae\u09b8\u09cd\u09a5\u09be\u09a8\u09a8\u09cd\u09a6\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 \u09ae\u09cb\u09a6\u09c0\u09b0 \u09e8\u09e6\u09e7\u09eb \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photos-of-bangladesh-domestic-abuse-victim-viral-with-communal-spin-as-kerala-9939",
        headline:
          "\u0997\u09be\u09b0\u09cd\u09b9\u09b8\u09cd\u09a5\u09cd\u09af \u09b9\u09bf\u0982\u09b8\u09be\u09b0 \u09b6\u09bf\u0995\u09be\u09b0 \u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6\u09bf \u09ae\u09b9\u09bf\u09b2\u09be \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09b0\u0999\u09c7 \u09b9\u09b2\u09c7\u09a8 \u0995\u09c7\u09b0\u09b2\u09c7\u09b0",
      },
      {
        url:
          "https://factly.in/raj-thackeray-did-not-tweet-praising-kangana-ranaut-and-criticizing-kareena-kapoor/",
        headline:
          " Raj Thackeray did not tweet praising Kangana Ranaut and criticizing Kareena Kapoor",
      },
      {
        url:
          "https://factly.in/telugu-the-image-of-narendra-modi-sweeping-the-floor-is-a-photoshopped-one/",
        headline:
          " \u0c28\u0c30\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c2e\u0c4b\u0c26\u0c40 \u0c0a\u0c21\u0c41\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b\u0c37\u0c3e\u0c2a\u0c4d \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b",
      },
      {
        url:
          "https://factly.in/photos-of-a-bangladesh-housewife-are-falsely-shared-as-those-of-a-love-jihad-victim-in-kerala/",
        headline:
          " Photos of a Bangladesh housewife are falsely shared as those of a love jihad victim in Kerala",
      },
      {
        url:
          "https://factly.in/mobile-tower-installation-fraud-at-least-a-decade-old-but-still-continues/",
        headline:
          " Mobile Tower Installation Fraud: At least a decade old but still continues",
      },
      {
        url:
          "https://factly.in/telugu-mobile-tower-installation-fraud-at-least-a-decade-old-but-still-continues/",
        headline:
          " \u0c2e\u0c4a\u0c2c\u0c48\u0c32\u0c4d \u0c1f\u0c35\u0c30\u0c4d\u0c32 \u0c05\u0c28\u0c41\u0c2e\u0c24\u0c3f \u0c2a\u0c47\u0c30\u0c41\u0c24\u0c4b \u0c17\u0c24 \u0c26\u0c36\u0c3e\u0c2c\u0c4d\u0c26 \u0c15\u0c3e\u0c32\u0c02\u0c17\u0c3e \u0c2e\u0c4b\u0c38\u0c3e\u0c32\u0c41 \u0c1c\u0c30\u0c41\u0c17\u0c41\u0c24\u0c42\u0c28\u0c47 \u0c09\u0c28\u0c4d\u0c28\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-2018-statement-by-trs-mla-harish-rao-falsely-linked-to-dubbaka-by-polls/",
        headline:
          " 2018\u0c32\u0c4b \u0c39\u0c30\u0c40\u0c37\u0c4d \u0c30\u0c3e\u0c35\u0c41 \u0c2d\u0c3e\u0c35\u0c4b\u0c26\u0c4d\u0c35\u0c47\u0c17\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c32\u0c4b\u0c28\u0c48 \u0c2e\u0c3e\u0c1f\u0c4d\u0c32\u0c3e\u0c21\u0c3f\u0c28 \u0c2e\u0c3e\u0c1f\u0c32\u0c28\u0c3f \u0c2a\u0c4d\u0c30\u0c38\u0c4d\u0c24\u0c41\u0c24 \u0c26\u0c41\u0c2c\u0c4d\u0c2c\u0c3e\u0c15 \u0c2c\u0c48-\u0c0e\u0c32\u0c15\u0c4d\u0c37\u0c28\u0c4d\u0c38\u0c4d \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02\u0c32\u0c4b \u0c05\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-adani-group-s-punjab-grain-storage-wasn-t-set-up-overnight-after-farm-bills-were-passed-1725524-2020-09-25",
        headline:
          "Fact Check: No, Adani Group's Punjab grain storage wasn't set up overnight after Farm Bills were passed",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-woman-bruises-not-love-jihad-victim-kerala-1725157-2020-09-25",
        headline:
          "Fact Check: This woman with bruises is not a love jihad victim from Kerala",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-ram-temple-story-does-ring-a-bell-but-is-not-entirely-true-1724742-2020-09-23",
        headline:
          "Fact Check: This Ram temple story does ring a bell, but is not entirely true",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-no-bjp-leaders-are-not-being-beaten-up-in-bihar/",
        headline:
          "Fact Check: No, BJP leaders are not being beaten up in Bihar",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/23/fact-check-photo-of-domestic-violence-victim-from-bangladesh-falsely-linked-to-love-jihad-in-kerala/",
        headline:
          "Fact Check: Photo of domestic violence victim from Bangladesh falsely linked to \u2018love jihad\u2019 in Kerala",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/24/fact-check-did-china-fire-a-missile-amid-border-standoff-with-india-heres-the-truth/",
        headline:
          "Fact Check: Did China fire a missile amid border standoff with India? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/25/fact-check-did-kangana-ranaut-say-shell-campaign-for-bjp-in-bihar-elections-heres-the-truth/",
        headline:
          "Fact Check: Did Kangana Ranaut say she\u2019ll campaign for BJP in Bihar elections? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/26/fact-check-video-of-ink-attack-on-former-bjp-mp-linked-to-the-current-farm-bill-crisis/",
        headline:
          "Fact Check: Video of ink attack on former BJP MP linked to the current Farm Bills crisis",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/26/fact-check-old-video-of-couple-setting-themselves-on-fire-in-a-police-station-in-mathura-falsely-linked-to-farmers/",
        headline:
          "Fact Check: Old video of couple setting themselves on fire in a police station in Mathura, falsely linked to farmers",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fake-news/fake-claim-of-sonia-gandhi-among-worlds-richest-politicians-revived-9906",
        headline:
          "Fake Claim Of Sonia Gandhi Among World's Richest Politicians Revived",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/visuals-from-old-unrelated-farmer-protests-viral-as-recent-9907",
        headline: "Visuals From Old Unrelated Farmer Protests Viral As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/farmer-protest-photo-of-2018-delhi-farmers-march-viral-as-recent-9923",
        headline:
          "Farmer Protest: Photo Of 2018 Delhi Farmers March Viral As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-bhojpuri-singer-peddled-as-osama-bin-ladens-son-in-law-9928",
        headline:
          "Photo Of Bhojpuri Singer Peddled As Osama Bin Laden's Son-In Law",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/farmers-protest-kisan-rally-haryana-farmers-bill-krishi-bill-9841",
        headline:
          "\u0930\u093e\u091c\u0938\u094d\u0925\u093e\u0928 \u092a\u094d\u0930\u094b\u091f\u0947\u0938\u094d\u091f \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u0930\u093f\u092f\u093e\u0923\u093e \u092e\u0947\u0902 \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/rozgaar-unemployment-employment-naukri-national-unemployment-day-no-jobs-uttar-pradesh-9876",
        headline:
          "\u092f\u0941\u0935\u093e\u0913\u0902 \u092a\u0930 \u092a\u0941\u0932\u093f\u0938 \u092c\u0930\u094d\u092c\u0930\u0924\u093e \u0926\u093f\u0916\u093e\u0924\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u092a\u0941\u0930\u093e\u0928\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/sonia-gandhi-congress-richest-politicians-sonia-rahul-gandhi-vladimir-putin-corruption-9915",
        headline:
          "\u0915\u094d\u092f\u093e \u0938\u094b\u0928\u093f\u092f\u093e \u0917\u093e\u0902\u0927\u0940 \u0926\u0941\u0928\u093f\u092f\u093e \u0915\u0947 \u0938\u092c\u0938\u0947 \u0905\u092e\u0940\u0930 \u0930\u093e\u091c\u0928\u0947\u0924\u093e\u0913\u0902 \u092e\u0947\u0902 \u0936\u093e\u092e\u093f\u0932 \u0939\u0948\u0902?",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2017-rajasthan-protest-photo-viral-as-recent-haryana-farmer-protests-9848",
        headline:
          "\u09e8\u09e6\u09e7\u09ed \u09b8\u09be\u09b2\u09c7 \u09b0\u09be\u099c\u09b8\u09cd\u09a5\u09be\u09a8\u09c7 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u0995\u09c7 \u09b9\u09b0\u09bf\u09df\u09be\u09a8\u09be\u09df \u0995\u09c3\u09b7\u0995\u09a6\u09c7\u09b0 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u099b\u09ac\u09bf \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farm-bills-protest-2013-photo-shared-as-recent-9905",
        headline:
          "\u09e8\u09e6\u09e7\u09e9 \u09b8\u09be\u09b2\u09c7\u09b0 \u099b\u09ac\u09bf \u09b6\u09c7\u09df\u09be\u09b0 \u09b9\u09b2 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/visuals-from-old-unrelated-farmer-protests-viral-as-recent-9930",
        headline:
          "\u09aa\u09c1\u09b0\u09a8\u09cb \u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u0995\u09c3\u09b7\u0995 \u09ac\u09bf\u0995\u09cd\u09b7\u09cb\u09ad\u09c7\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/farmer-protest-photo-of-2018-delhi-farmers-march-viral-as-recent-9932",
        headline:
          "\u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09a4\u09c7 \u0995\u09bf\u09b7\u09be\u09a8 \u09ae\u09c1\u0995\u09cd\u09a4\u09bf \u09ae\u09be\u09b0\u09cd\u099a\u09c7\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://factly.in/2013-photo-falsely-linked-to-the-farmers-present-protest-against-the-agriculture-laws/",
        headline:
          " 2013 photo falsely linked to the farmers\u2019 present protest against the Agriculture laws",
      },
      {
        url:
          "https://factly.in/old-video-from-saudi-arabia-is-being-falsely-shared-as-the-one-from-rajasthan/",
        headline:
          " Old video from Saudi Arabia is being falsely shared as the one from Rajasthan.",
      },
      {
        url:
          "https://factly.in/unrelated-video-falsely-shared-as-pm-modi-celebrating-his-birthday-with-industrialists/",
        headline:
          " Unrelated video falsely shared as PM Modi celebrating his birthday with industrialists",
      },
      {
        url:
          "https://factly.in/there-is-no-pradhan-mantri-solar-panel-yojana-scheme-providing-free-solar-panels/",
        headline:
          " There is no \u2018Pradhan Mantri Solar Panel Yojana\u2019 scheme providing free solar panels",
      },
      {
        url:
          "https://factly.in/no-the-count-of-hindus-appointed-in-various-departments-is-not-less-than-muslims-and-christians/",
        headline:
          " The count of Hindus appointed in various departments is not less than Muslims and Christians.",
      },
      {
        url:
          "https://factly.in/old-video-and-photos-are-shared-as-the-current-farmers-protest-in-panipat/",
        headline:
          " Old video and photos are  shared as the current farmers protest in Panipat",
      },
      {
        url:
          "https://factly.in/telugu-nsigse-is-not-only-for-previous-girl-students-it-is-for-girl-students-also/",
        headline:
          " NSIGSE \u0c38\u0c4d\u0c15\u0c3e\u0c32\u0c30\u0c4d\u200c\u0c37\u0c3f\u0c2a\u0c4d \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c2a\u0c42\u0c30\u0c4d\u0c35 \u0c35\u0c3f\u0c26\u0c4d\u0c2f\u0c3e\u0c30\u0c4d\u0c27\u0c3f\u0c28\u0c3f\u0c32\u0c15\u0c41 \u0c2e\u0c3e\u0c24\u0c4d\u0c30\u0c2e\u0c47 \u0c15\u0c3e\u0c26\u0c41, \u0c2a\u0c4d\u0c30\u0c38\u0c4d\u0c24\u0c41\u0c24\u0c02 \u0c1a\u0c26\u0c41\u0c35\u0c41\u0c15\u0c41\u0c02\u0c1f\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c3f\u0c26\u0c4d\u0c2f\u0c3e\u0c30\u0c4d\u0c25\u0c3f\u0c28\u0c3f\u0c32\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c05\u0c30\u0c4d\u0c39\u0c41\u0c32\u0c47.",
      },
      {
        url:
          "https://factly.in/the-man-speaking-about-the-criminalization-of-politics-in-this-video-is-not-ips-officer-shailja-kant-mishra/",
        headline:
          " The man speaking about the criminalization of politics in this video is not IPS Officer Shailja Kant Mishra.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/viral-ipl-shah-rukh-khan-kkr-investing-reliance-digital-us-firm-1725188-2020-09-25",
        headline:
          "Fact Check: KKR is investing in Reliance Retail, but it's not what you think",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/tablighi-jamaat-celebrating-pm-modi-s-birthday-nizamuddin-markaz-fact-check-1724226-2020-09-22",
        headline:
          "Fact Check: The truth about Tablighi Jamaat celebrating PM Modi's birthday inside Nizamuddin Markaz",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-when-some-netizens-chaired-fake-news-on-manmohan-and-sonia-1726010-2020-09-27",
        headline:
          "Fact Check: Manmohan changing his seat in Sonia\u2019s presence goes viral with wrong claim",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-deepika-did-not-appear-before-ncb-in-a-t-shirt-with-pro-farmer-message-1725747-2020-09-26",
        headline:
          "Fact Check: No, Deepika did not appear before NCB in a t-shirt with pro-farmer message",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-who-has-not-approved-home-remedy-for-covid-19-dont-fall-for-misinformation/",
        headline:
          "Fact Check: WHO has not approved home remedy for COVID-19, don\u2019t fall for misinformation",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/21/fact-check-dont-fall-for-this-old-picture-it-has-nothing-to-do-with-recent-farmers-agitation/",
        headline:
          "Fact Check: Don\u2019t fall for this old picture, it has nothing to do with recent farmers\u2019 agitation",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/22/fact-check-the-centre-has-not-launched-the-pm-free-solar-panel-scheme-heres-the-truth/",
        headline:
          "Fact Check: Centre has not launched PM Free Solar Panel Yojana; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/23/fact-check-did-jackie-chan-return-from-hajj-heres-the-truth/",
        headline:
          "Fact Check: Did Jackie Chan return from Hajj? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/23/fact-check-dont-fall-for-this-picture-showing-ips-officers-kneeling-down-it-is-a-movie-scene/",
        headline:
          "Fact Check: Don\u2019t fall for this picture showing IPS officers kneeling down, it is a movie scene",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/24/fact-check-2017-picture-of-a-farmers-agitation-in-rajasthan-shared-as-recent-one-from-haryana/",
        headline:
          "Fact Check: 2017 picture of a farmers\u2019 agitation in Rajasthan shared as recent one from Haryana",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/25/fact-check-rail-roko-picture-from-2015-shared-as-recent-one-from-farmers-protest/",
        headline:
          "Fact Check: \u2018Rail Roko\u2019 picture from 2015 shared as recent one from farmers\u2019 protest",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/26/fact-check-doctored-video-of-pm-modi-viral-heres-the-truth/",
        headline:
          "Fact Check: Doctored video of PM Modi viral; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/26/fact-check-centre-did-not-impose-tax-on-school-books-heres-the-truth/",
        headline:
          "Fact Check: Centre did not impose tax on school books; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/09/26/fact-check-2015-rohingya-colony-picture-from-myanmar-being-shared-as-recent-one-from-a-jammu-settlement/",
        headline:
          "Fact Check: 2015 Rohingya colony picture from Myanmar being shared as recent one from a Jammu settlement",
      },
    ],
  },
  numpy_seed: 0,
  number_of_articles: 97,
}
