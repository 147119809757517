export const data = {
  mdsDat: {
    x: [
      -0.20258960316743066,
      0.28286765800130415,
      -0.2816604457039636,
      -0.0762074014339557,
      0.16567446215649548,
      -0.08869768789602572,
      0.20061301804357617,
    ],
    y: [
      0.014404040184842355,
      0.11907117554232961,
      0.03599930161538176,
      -0.2079950735989286,
      -0.3361912303706159,
      0.1933845533463393,
      0.1813272332806516,
    ],
    topics: [1, 2, 3, 4, 5, 6, 7],
    cluster: [1, 1, 1, 1, 1, 1, 1],
    Freq: [
      30.901350583363573,
      8.581526561476402,
      18.031777824647513,
      10.448874370499391,
      6.962546019915406,
      16.040069493818116,
      9.033855146279581,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
    ],
    Freq: [
      42.0,
      23.0,
      12.0,
      9.0,
      13.0,
      13.0,
      20.0,
      15.0,
      8.0,
      15.0,
      8.998602719616139,
      4.999223733120076,
      1.999689493248031,
      0.9998447466240155,
      3.999378986496062,
      0.9998447466240155,
      0.9998447466240155,
      1.999689493248031,
      0.9998447466240155,
      9.998447466240153,
      13.997826452736215,
      13.997826452736215,
      6.998913226368107,
      5.999068479744092,
      5.999068479744092,
      5.999068479744092,
      3.999378986496062,
      3.999378986496062,
      14.99767119936023,
      17.997205439232278,
      13.997826452736215,
      17.997205439232278,
      10.998292212864168,
      6.998913226368107,
      1.0006383801872485,
      3.0019151405617457,
      1.0006383801872485,
      1.0006383801872485,
      1.0006383801872485,
      3.0019151405617457,
      1.0006383801872485,
      3.0019151405617457,
      1.0006383801872485,
      2.001276760374497,
      6.003830281123491,
      6.003830281123491,
      3.0019151405617457,
      3.0019151405617457,
      2.001276760374497,
      2.001276760374497,
      2.001276760374497,
      2.001276760374497,
      2.001276760374497,
      6.003830281123491,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      0.9994294794288937,
      7.99543583543115,
      3.997717917715575,
      2.9982884382866817,
      2.9982884382866817,
      2.9982884382866817,
      2.9982884382866817,
      1.9988589588577874,
      1.9988589588577874,
      1.9988589588577874,
      11.993153753146727,
      1.9988589588577874,
      1.9988589588577874,
      4.997147397144468,
      4.997147397144468,
      9.994294794288937,
      19.988589588577874,
      12.992583232575619,
      9.994294794288937,
      6.9960063560022565,
      1.0011479629408722,
      1.0011479629408722,
      1.0011479629408722,
      1.0011479629408722,
      2.0022959258817443,
      1.0011479629408722,
      2.0022959258817443,
      1.0011479629408722,
      2.0022959258817443,
      1.0011479629408722,
      4.004591851763489,
      4.004591851763489,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      4.004591851763489,
      3.0034438888226167,
      3.0034438888226167,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      1.0006635907692376,
      1.0006635907692376,
      1.0006635907692376,
      1.0006635907692376,
      1.0006635907692376,
      2.001327181538475,
      2.001327181538475,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      2.001327181538475,
      2.001327181538475,
      2.001327181538475,
      2.001327181538475,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      1.0012891865837978,
      4.005156746335191,
      5.0064459329189885,
      1.0012891865837978,
      2.0025783731675957,
      3.0038675597513933,
      2.0025783731675957,
      1.0012891865837978,
      2.0025783731675957,
      1.0012891865837978,
      9.01160267925418,
      6.007735119502787,
      5.0064459329189885,
      5.0064459329189885,
      5.0064459329189885,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      5.0064459329189885,
      4.005156746335191,
      0.9969504429287109,
      1.9939008858574219,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      3.9878017717148437,
      3.9878017717148437,
      2.9908513287861327,
      2.9908513287861327,
      1.9939008858574219,
      1.9939008858574219,
      1.9939008858574219,
      1.9939008858574219,
      0.9969504429287109,
      2.9908513287861327,
      1.9939008858574219,
    ],
    Term: [
      "farmer",
      "delhi",
      "cop",
      "biden",
      "republic",
      "flag",
      "tractor",
      "police",
      "gandhi",
      "day",
      "tractor_rally",
      "camera",
      "communal",
      "campaign",
      "dhoni",
      "hoisted_red",
      "samajwadi",
      "insult",
      "lalkella",
      "republic_day",
      "republic",
      "flag",
      "hoist",
      "cctv",
      "red",
      "fort",
      "part",
      "indian_flag",
      "day",
      "tractor",
      "rally",
      "farmer",
      "delhi",
      "protest",
      "entry",
      "mask",
      "army",
      "notes",
      "suggest",
      "rbi",
      "force",
      "college",
      "labourer",
      "madrasas",
      "rahul_gandhi",
      "rahul",
      "withdraw",
      "circular",
      "note",
      "boyfriend",
      "close",
      "anthem",
      "issue",
      "gandhi",
      "bareilly",
      "ghaziabad",
      "temple",
      "brutality",
      "villager",
      "misuse",
      "policemen",
      "related",
      "clash",
      "vandalize",
      "delhi_police",
      "thrash",
      "cry",
      "lucknow",
      "chase",
      "pakistani",
      "lathi",
      "rss",
      "peddle",
      "cop",
      "worker",
      "jai",
      "man",
      "linked_farmer",
      "police",
      "farmer",
      "delhi",
      "protest",
      "link",
      "event",
      "several",
      "parliament",
      "hill",
      "disrespect",
      "cong",
      "surface",
      "open",
      "pray",
      "priest",
      "tanker",
      "speech",
      "emotional",
      "rakesh",
      "account",
      "mamata",
      "twitter_account",
      "emotional_speech",
      "water_tanker",
      "rakesh_tikait",
      "water",
      "tikait",
      "woman",
      "farmer",
      "run",
      "arvind",
      "gdp",
      "growth",
      "fruit",
      "overlook",
      "rate",
      "kamala",
      "harris",
      "imf",
      "law",
      "kejriwal",
      "sambit",
      "sambit_patra",
      "arvind_kejriwal",
      "patra",
      "sign",
      "govt",
      "agricultural",
      "uttar",
      "support",
      "edit",
      "bjp",
      "nath",
      "chandigarh",
      "bhavan",
      "salute",
      "chatterjee",
      "priyanka",
      "daughter",
      "knee",
      "handle",
      "fun",
      "biden",
      "joe",
      "bose",
      "joe_biden",
      "chandra",
      "municipal",
      "chandigarh_municipal",
      "actor",
      "painting_rashtrapati",
      "president",
      "results_share",
      "rashtrapati",
      "paint",
      "modi",
      "result",
      "states",
      "message",
      "board",
      "cbse",
      "shah",
      "banana",
      "fast",
      "yojna",
      "mani",
      "announce",
      "vaccine",
      "train",
      "speed",
      "train_china",
      "high",
      "float",
      "simulation",
      "covid",
      "kieron",
      "china",
      "take",
    ],
    Total: [
      42.0,
      23.0,
      12.0,
      9.0,
      13.0,
      13.0,
      20.0,
      15.0,
      8.0,
      15.0,
      8.998602719616139,
      4.999223733120076,
      1.999689493248031,
      0.9998447466240155,
      3.999378986496062,
      0.9998447466240155,
      0.9998447466240155,
      1.999689493248031,
      0.9998447466240155,
      9.998447466240153,
      13.997826452736215,
      13.997826452736215,
      6.998913226368107,
      5.999068479744092,
      5.999068479744092,
      5.999068479744092,
      3.999378986496062,
      3.999378986496062,
      15.99830957954748,
      20.99549387751896,
      17.9992637847616,
      42.991817289800366,
      23.990875445439787,
      19.99693435676551,
      1.0006383801872485,
      3.0019151405617457,
      1.0006383801872485,
      1.0006383801872485,
      1.0006383801872485,
      3.0019151405617457,
      1.0006383801872485,
      3.0019151405617457,
      1.0006383801872485,
      2.001276760374497,
      6.003830281123491,
      6.003830281123491,
      3.0019151405617457,
      3.0019151405617457,
      2.001276760374497,
      2.001276760374497,
      2.001276760374497,
      2.001276760374497,
      2.001276760374497,
      8.006408654291088,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      1.9988589588577874,
      0.9994294794288937,
      0.9994294794288937,
      7.99543583543115,
      3.997717917715575,
      2.9982884382866817,
      2.9982884382866817,
      2.9982884382866817,
      2.9982884382866817,
      1.9988589588577874,
      1.9988589588577874,
      1.9988589588577874,
      12.992998499770742,
      1.9988589588577874,
      1.9988589588577874,
      5.994097840073179,
      5.996992143768484,
      15.99336327403303,
      42.991817289800366,
      23.990875445439787,
      19.99693435676551,
      14.99317324161593,
      1.0011479629408722,
      1.0011479629408722,
      1.0011479629408722,
      1.0011479629408722,
      2.0022959258817443,
      1.0011479629408722,
      2.0022959258817443,
      1.0011479629408722,
      2.0022959258817443,
      1.0011479629408722,
      4.004591851763489,
      4.004591851763489,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      3.0034438888226167,
      7.00288029005017,
      42.991817289800366,
      4.00287336825151,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      1.0006635907692376,
      1.0006635907692376,
      1.0006635907692376,
      1.0006635907692376,
      1.0006635907692376,
      2.001327181538475,
      2.001327181538475,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      3.0019907723077126,
      2.001327181538475,
      2.001327181538475,
      2.001327181538475,
      2.001327181538475,
      5.0008497311655,
      7.006073041317578,
      8.002517721744647,
      1.0012891865837978,
      4.005156746335191,
      5.0064459329189885,
      1.0012891865837978,
      2.0025783731675957,
      3.0038675597513933,
      2.0025783731675957,
      1.0012891865837978,
      2.0025783731675957,
      1.0012891865837978,
      9.01160267925418,
      6.007735119502787,
      5.0064459329189885,
      5.0064459329189885,
      5.0064459329189885,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      4.005156746335191,
      7.005304891776776,
      4.005156746335191,
      0.9969504429287109,
      1.9939008858574219,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      0.9969504429287109,
      3.9878017717148437,
      3.9878017717148437,
      2.9908513287861327,
      2.9908513287861327,
      1.9939008858574219,
      1.9939008858574219,
      1.9939008858574219,
      1.9939008858574219,
      0.9969504429287109,
      3.991489708973381,
      5.994724312313266,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1744,
      1.1098,
      1.0203,
      0.9229,
      0.3036,
      0.3944,
      0.1245,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.4556,
      2.1677,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.713,
      1.633,
      1.713,
      1.713,
      1.5311,
      1.5306,
      1.2429,
      0.9472,
      1.0997,
      1.0195,
      0.9508,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      2.2587,
      1.6998,
      -0.4026,
      1.9714,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.6646,
      2.1543,
      1.8171,
      1.6841,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.8301,
      1.4941,
      1.8301,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.4042,
      2.1156,
      1.3034,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -3.7482,
      -4.336,
      -5.2523,
      -5.9454,
      -4.5591,
      -5.9454,
      -5.9454,
      -5.2523,
      -5.9454,
      -3.6428,
      -3.3064,
      -3.3064,
      -3.9995,
      -4.1537,
      -4.1537,
      -4.1537,
      -4.5591,
      -4.5591,
      -3.2374,
      -3.055,
      -3.3064,
      -3.055,
      -3.5475,
      -3.9995,
      -4.6634,
      -3.5648,
      -4.6634,
      -4.6634,
      -4.6634,
      -3.5648,
      -4.6634,
      -3.5648,
      -4.6634,
      -3.9703,
      -2.8717,
      -2.8717,
      -3.5648,
      -3.5648,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -2.8717,
      -5.4072,
      -4.714,
      -5.4072,
      -4.714,
      -5.4072,
      -4.714,
      -5.4072,
      -4.714,
      -5.4072,
      -5.4072,
      -3.3277,
      -4.0209,
      -4.3086,
      -4.3086,
      -4.3086,
      -4.3086,
      -4.714,
      -4.714,
      -4.714,
      -2.9223,
      -4.714,
      -4.714,
      -3.7977,
      -3.7977,
      -3.1046,
      -2.4114,
      -2.8422,
      -3.1046,
      -3.4613,
      -4.8598,
      -4.8598,
      -4.8598,
      -4.8598,
      -4.1667,
      -4.8598,
      -4.1667,
      -4.8598,
      -4.1667,
      -4.8598,
      -3.4735,
      -3.4735,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.4735,
      -3.7612,
      -3.7612,
      -3.3557,
      -3.3557,
      -3.3557,
      -4.4543,
      -4.4543,
      -4.4543,
      -4.4543,
      -4.4543,
      -3.7612,
      -3.7612,
      -3.3557,
      -3.3557,
      -3.3557,
      -3.3557,
      -3.3557,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.7612,
      -3.3557,
      -3.3557,
      -3.3557,
      -5.2883,
      -3.902,
      -3.6788,
      -5.2883,
      -4.5951,
      -4.1897,
      -4.5951,
      -5.2883,
      -4.5951,
      -5.2883,
      -3.091,
      -3.4965,
      -3.6788,
      -3.6788,
      -3.6788,
      -3.902,
      -3.902,
      -3.902,
      -3.902,
      -3.902,
      -3.902,
      -3.902,
      -3.902,
      -3.6788,
      -3.902,
      -4.7185,
      -4.0254,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -4.7185,
      -3.3322,
      -3.3322,
      -3.6199,
      -3.6199,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.7185,
      -3.6199,
      -4.0254,
    ],
  },
  "token.table": {
    Topic: [
      4,
      6,
      5,
      7,
      2,
      2,
      5,
      5,
      7,
      3,
      6,
      6,
      1,
      4,
      5,
      7,
      6,
      2,
      3,
      1,
      1,
      7,
      1,
      6,
      6,
      6,
      3,
      6,
      2,
      7,
      2,
      3,
      2,
      2,
      1,
      4,
      1,
      3,
      7,
      3,
      6,
      1,
      2,
      1,
      3,
      3,
      1,
      4,
      2,
      4,
      5,
      4,
      4,
      2,
      4,
      1,
      3,
      4,
      6,
      7,
      1,
      7,
      2,
      1,
      5,
      6,
      2,
      6,
      5,
      3,
      5,
      5,
      6,
      5,
      7,
      4,
      1,
      1,
      5,
      1,
      1,
      2,
      3,
      6,
      6,
      5,
      5,
      7,
      6,
      2,
      1,
      3,
      5,
      1,
      3,
      4,
      7,
      1,
      3,
      3,
      2,
      4,
      3,
      7,
      7,
      2,
      7,
      3,
      3,
      6,
      6,
      6,
      2,
      2,
      4,
      5,
      6,
      6,
      3,
      4,
      1,
      5,
      3,
      1,
      3,
      3,
      4,
      6,
      4,
      6,
      1,
      3,
      4,
      6,
      2,
      2,
      4,
      4,
      1,
      3,
      6,
      6,
      5,
      2,
      1,
      3,
      1,
      1,
      6,
      6,
      3,
      3,
      4,
      6,
      1,
      5,
      5,
      4,
      7,
      5,
      7,
      4,
      7,
      7,
      2,
      3,
      5,
      4,
      1,
      6,
      7,
      4,
      3,
      3,
      4,
      1,
      3,
      1,
      7,
      7,
      4,
      5,
      7,
      3,
      3,
      4,
      4,
      2,
      3,
      4,
      3,
      7,
    ],
    Freq: [
      0.9988533533669688,
      0.998712473278378,
      0.9993368492914512,
      1.0030588853166367,
      0.9993620270820224,
      0.9993620270820224,
      0.9993368492914513,
      0.9993368492914513,
      1.0030588853166367,
      1.0005708462506353,
      0.9987124732783781,
      0.998712473278378,
      0.4998426918982131,
      0.12496067297455328,
      0.37488201892365985,
      1.0030588853166367,
      0.9987124732783781,
      0.9993620270820224,
      1.0005708462506353,
      1.0001552774833382,
      1.000155277483338,
      1.0030588853166367,
      1.0001552774833382,
      0.998712473278378,
      0.998712473278378,
      0.9987124732783781,
      1.000570846250635,
      0.998712473278378,
      0.2505330272434053,
      0.751599081730216,
      0.9993620270820223,
      1.0005708462506353,
      0.9993620270820224,
      0.9993620270820223,
      1.000155277483338,
      0.9988533533669688,
      0.07696452824324151,
      0.9235743389188983,
      1.0030588853166367,
      1.000570846250635,
      0.998712473278378,
      0.9375990585390511,
      0.06250660390260342,
      0.45850765325409965,
      0.5418726811184814,
      1.0005708462506353,
      1.000155277483338,
      0.9988533533669688,
      0.14273331067241313,
      0.4281999320172393,
      0.4281999320172393,
      0.9988533533669688,
      0.9988533533669688,
      0.9993620270820224,
      0.9988533533669688,
      0.41868432494177044,
      0.465204805490856,
      0.0697807208236284,
      0.0465204805490856,
      1.0030588853166367,
      1.0001552774833382,
      1.0030588853166367,
      0.9993620270820224,
      1.0001552774833382,
      0.9993368492914512,
      0.998712473278378,
      0.7493996695739806,
      0.24979988985799353,
      0.9993368492914513,
      1.0005708462506353,
      0.9993368492914512,
      0.9993368492914513,
      0.998712473278378,
      0.9993368492914512,
      1.0030588853166367,
      0.9988533533669688,
      1.0001552774833382,
      1.000155277483338,
      0.9993368492914512,
      1.000155277483338,
      1.000155277483338,
      0.9993620270820224,
      1.0005708462506353,
      0.998712473278378,
      0.9987124732783781,
      0.9993368492914512,
      0.9993368492914513,
      1.0030588853166367,
      0.998712473278378,
      0.9993620270820224,
      1.000155277483338,
      1.0005708462506353,
      0.9993368492914512,
      0.4001821297806423,
      0.4668791514107494,
      0.06669702163010706,
      0.06669702163010706,
      0.16675026013484226,
      0.8337513006742112,
      1.000570846250635,
      0.9993620270820224,
      0.9988533533669688,
      0.8341538849387479,
      0.1668307769877496,
      1.0030588853166367,
      0.9993620270820223,
      1.0030588853166367,
      1.0005708462506353,
      0.28549792348762915,
      0.7137448087190729,
      0.998712473278378,
      0.998712473278378,
      0.9993620270820224,
      0.9993620270820224,
      0.9988533533669688,
      0.9993368492914512,
      0.998712473278378,
      0.998712473278378,
      1.000570846250635,
      0.9988533533669688,
      1.000155277483338,
      0.9993368492914513,
      1.0005708462506353,
      0.3751556128123254,
      0.625259354687209,
      1.0005708462506353,
      0.9988533533669688,
      0.998712473278378,
      0.9988533533669688,
      0.998712473278378,
      0.3500536569812616,
      0.5000766528303737,
      0.05000766528303738,
      0.10001533056607476,
      0.9993620270820223,
      0.9993620270820223,
      0.9988533533669688,
      0.9988533533669688,
      0.7778095908485199,
      0.11111565583550284,
      0.11111565583550284,
      0.998712473278378,
      0.9993368492914512,
      0.9993620270820223,
      1.0001552774833382,
      1.0005708462506353,
      1.0001552774833382,
      1.0001552774833382,
      0.998712473278378,
      0.998712473278378,
      1.0005708462506353,
      0.24982054339550808,
      0.7494616301865242,
      0.998712473278378,
      1.000155277483338,
      0.9993368492914513,
      0.9993368492914513,
      0.9988533533669688,
      1.0030588853166367,
      0.9993368492914512,
      1.0030588853166367,
      0.9988533533669688,
      1.0030588853166367,
      1.0030588853166367,
      0.9993620270820224,
      0.3999320330574858,
      0.5998980495862287,
      0.9988533533669688,
      0.5004400275485478,
      0.1668133425161826,
      0.3336266850323652,
      0.9988533533669688,
      1.0005708462506353,
      1.0005708462506353,
      0.9988533533669688,
      0.8573268199836727,
      0.14288780333061213,
      1.000155277483338,
      1.0030588853166367,
      1.0030588853166367,
      0.9988533533669688,
      0.9993368492914512,
      1.0030588853166367,
      1.0005708462506353,
      1.0005708462506353,
      0.9988533533669688,
      0.9988533533669688,
      0.9993620270820223,
      0.4283951568131843,
      0.571193542417579,
      1.0005708462506353,
      1.0030588853166367,
    ],
    Term: [
      "account",
      "actor",
      "agricultural",
      "announce",
      "anthem",
      "army",
      "arvind",
      "arvind_kejriwal",
      "banana",
      "bareilly",
      "bhavan",
      "biden",
      "bjp",
      "bjp",
      "bjp",
      "board",
      "bose",
      "boyfriend",
      "brutality",
      "camera",
      "campaign",
      "cbse",
      "cctv",
      "chandigarh",
      "chandigarh_municipal",
      "chandra",
      "chase",
      "chatterjee",
      "china",
      "china",
      "circular",
      "clash",
      "close",
      "college",
      "communal",
      "cong",
      "cop",
      "cop",
      "covid",
      "cry",
      "daughter",
      "day",
      "day",
      "delhi",
      "delhi",
      "delhi_police",
      "dhoni",
      "disrespect",
      "edit",
      "edit",
      "edit",
      "emotional",
      "emotional_speech",
      "entry",
      "event",
      "farmer",
      "farmer",
      "farmer",
      "farmer",
      "fast",
      "flag",
      "float",
      "force",
      "fort",
      "fruit",
      "fun",
      "gandhi",
      "gandhi",
      "gdp",
      "ghaziabad",
      "govt",
      "growth",
      "handle",
      "harris",
      "high",
      "hill",
      "hoist",
      "hoisted_red",
      "imf",
      "indian_flag",
      "insult",
      "issue",
      "jai",
      "joe",
      "joe_biden",
      "kamala",
      "kejriwal",
      "kieron",
      "knee",
      "labourer",
      "lalkella",
      "lathi",
      "law",
      "link",
      "link",
      "link",
      "link",
      "linked_farmer",
      "linked_farmer",
      "lucknow",
      "madrasas",
      "mamata",
      "man",
      "man",
      "mani",
      "mask",
      "message",
      "misuse",
      "modi",
      "modi",
      "municipal",
      "nath",
      "note",
      "notes",
      "open",
      "overlook",
      "paint",
      "painting_rashtrapati",
      "pakistani",
      "parliament",
      "part",
      "patra",
      "peddle",
      "police",
      "police",
      "policemen",
      "pray",
      "president",
      "priest",
      "priyanka",
      "protest",
      "protest",
      "protest",
      "protest",
      "rahul",
      "rahul_gandhi",
      "rakesh",
      "rakesh_tikait",
      "rally",
      "rally",
      "rally",
      "rashtrapati",
      "rate",
      "rbi",
      "red",
      "related",
      "republic",
      "republic_day",
      "result",
      "results_share",
      "rss",
      "run",
      "run",
      "salute",
      "samajwadi",
      "sambit",
      "sambit_patra",
      "several",
      "shah",
      "sign",
      "simulation",
      "speech",
      "speed",
      "states",
      "suggest",
      "support",
      "support",
      "surface",
      "take",
      "take",
      "take",
      "tanker",
      "temple",
      "thrash",
      "tikait",
      "tractor",
      "tractor",
      "tractor_rally",
      "train",
      "train_china",
      "twitter_account",
      "uttar",
      "vaccine",
      "vandalize",
      "villager",
      "water",
      "water_tanker",
      "withdraw",
      "woman",
      "woman",
      "worker",
      "yojna",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6, 7],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/mahendra-singh-dhoni-kisan-andolan-tractor-parade-fake-news-old-photo-11734",
        headline:
          "\u091f\u094d\u0930\u0948\u0915\u094d\u091f\u0930 \u092a\u0930 \u0927\u094b\u0928\u0940 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u0939\u0948, \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u0938\u092e\u094d\u092c\u0902\u0927\u093f\u0924 \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/kisan-andolan-delhi-police-cctv-camera-rakesh-tikait-president-ram-nath-kovind-11765",
        headline:
          "\u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u093f\u092f\u094b\u0902 \u0915\u094b \u0938\u0940\u0938\u0940\u091f\u0940\u0935\u0940 \u0915\u0948\u092e\u0930\u093e \u0924\u094b\u095c\u0924\u0947 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948 \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u092c \u0915\u093e \u0939\u0948?",
      },
      {
        url:
          "https://factly.in/old-image-is-shared-as-farmers-entered-delhi-to-participate-in-tractor-rally/",
        headline:
          " Old image is shared as farmers in Delhi to participate in tractor rally",
      },
      {
        url:
          "https://factly.in/these-visuals-do-not-show-rehearsals-by-the-farmers-for-the-upcoming-r-day-tractor-parade/",
        headline:
          " No, these visuals do not show rehearsals by the farmers for the upcoming R-Day tractor parade.",
      },
      {
        url:
          "https://factly.in/2011-republic-day-parade-tableau-from-bihar-shared-as-that-displayed-during-akhilesh-yadav-rule-in-up/",
        headline:
          " 2011 RD parade tableau from Bihar shared as the one from UP during Akhilesh Yadav\u2019s reign as CM",
      },
      {
        url:
          "https://factly.in/telugu-indian-flag-on-red-fort-hasnt-been-removed-during-republic-day-tractor-rally-protests/",
        headline:
          " \u0c1f\u0c4d\u0c30\u0c3e\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c40 \u0c32\u0c4b \u0c2d\u0c3e\u0c17\u0c02\u0c17\u0c3e \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c39\u0c3f\u0c02\u0c38\u0c3e\u0c24\u0c4d\u0c2e\u0c15 \u0c18\u0c1f\u0c28\u0c32\u0c4d\u0c32\u0c4b \u0c0e\u0c30\u0c4d\u0c30 \u0c15\u0c4b\u0c1f \u0c2e\u0c40\u0c26 \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36 \u0c24\u0c4d\u0c30\u0c3f\u0c35\u0c30\u0c4d\u0c23 \u0c2a\u0c24\u0c3e\u0c15\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c15\u0c3e\u0c30\u0c41\u0c32\u0c41 \u0c24\u0c40\u0c38\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/video-of-police-destroying-cctv-camera-is-from-caa-protests-not-from-tractor-rally-protests-on-republic-day/",
        headline:
          " Video of police destroying CCTV camera is from CAA protests, not from tractor rally protests on Republic day",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-photos-are-shared-as-police-attack-on-farmers-at-tractor-rally/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c41 \u0c1f\u0c4d\u0c30\u0c3e\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c40\u0c32\u0c4b \u0c30\u0c48\u0c24\u0c41\u0c32\u0c2a\u0c48 \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32 \u0c26\u0c3e\u0c21\u0c3f \u0c05\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/no-this-video-does-not-show-farmers-in-india-standing-on-the-indian-flag-and-damaging-it/",
        headline:
          " This video does not show farmers in India standing on the Indian flag and damaging it",
      },
      {
        url:
          "https://factly.in/telugu-2011-rd-parade-tableau-from-bihar-shared-as-the-one-from-up-during-akhilesh-yadavs-reign-as-cm/",
        headline:
          " 2011 \u0c30\u0c3f\u0c2a\u0c2c\u0c4d\u0c32\u0c3f\u0c15\u0c4d \u0c21\u0c47 \u0c2a\u0c46\u0c30\u0c47\u0c21\u0c4d \u0c32\u0c4b \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c2a\u0c4d\u0c30\u0c26\u0c30\u0c4d\u0c36\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c36\u0c15\u0c1f\u0c3e\u0c28\u0c4d\u0c28\u0c3f \u0c05\u0c16\u0c3f\u0c32\u0c47\u0c37\u0c4d \u0c2f\u0c3e\u0c26\u0c35\u0c4d\u200c\u0c15\u0c3f \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-other-party-flags-were-also-hoisted-on-this-dome-along-with-bjp-flag/",
        headline:
          " \u0c1f\u0c4d\u0c2f\u0c3e\u0c02\u0c15\u0c4d \u0c2c\u0c02\u0c21\u0c4d \u0c2a\u0c48 \u0c09\u0c28\u0c4d\u0c28 \u0c08\u00a0\u0c21\u0c4b\u0c2e\u0c4d\u00a0\u0c2e\u0c40\u0c26 2011\u0c32\u0c4b\u00a0\u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c1c\u0c46\u0c02\u0c21\u0c3e\u0c24\u0c4b \u0c2a\u0c3e\u0c1f\u0c41 \u0c07\u0c24\u0c30 \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40 \u0c1c\u0c46\u0c02\u0c21\u0c3e\u0c32\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c0e\u0c17\u0c41\u0c30\u0c35\u0c47\u0c36\u0c3e\u0c30\u0c41\u00a0\u00a0 \u00a0\u00a0",
      },
      {
        url:
          "https://factly.in/telugu-this-video-does-not-show-farmers-in-india-standing-on-the-indian-flag-and-damaging-it/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24 \u0c1c\u0c3e\u0c24\u0c40\u0c2f \u0c1c\u0c46\u0c02\u0c21\u0c3e\u0c28\u0c41 \u0c05\u0c35\u0c2e\u0c3e\u0c28\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c15\u0c3e\u0c30\u0c41\u0c32\u0c41 \u0c2d\u0c3e\u0c30\u0c24\u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c30\u0c48\u0c24\u0c41\u0c32\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/unrelated-old-photos-are-shared-as-police-attack-on-farmers-at-tractor-rally-protests-on-republic-day/",
        headline:
          " Unrelated old photos are shared as police attack on farmers at tractor rally protests on Republic day",
      },
      {
        url:
          "https://factly.in/shivraj-singh-chouhan-hoisting-bjp-flag-at-panchayat-chalo-campaign-is-2018-linked-to-republic-day/",
        headline:
          " Shivraj Singh Chouhan hoisting BJP flag at Panchayat Chalo campaign is 2018 linked to Republic Day",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-photo-shared-as-bjp-activists-attacking-police-on-republic-day/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c30\u0c3f\u0c2a\u0c2c\u0c4d\u0c32\u0c3f\u0c15\u0c4d \u0c21\u0c47 \u0c30\u0c4b\u0c1c\u0c41 \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c2a\u0c48 BJP \u0c15\u0c3e\u0c30\u0c4d\u0c2f\u0c15\u0c30\u0c4d\u0c24\u0c32 \u0c26\u0c3e\u0c21\u0c3f \u0c05\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-photo-of-ms-dhoni-standing-on-his-new-tractor-falsely-linked-to-the-farmers-tractor-parade-held-in-delhi/",
        headline:
          " Old photo of MS Dhoni standing on his new tractor falsely linked to the farmers\u2019 tractor parade held in Delhi",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-truth-of-the-flag-hoisted-at-red-fort-by-protesting-farmers-1762991-2021-01-26",
        headline:
          "Fact Check: Truth of the flag hoisted at Red Fort by protesting farmers\u00a0",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/25/fact-check-cricketer-ms-dhoni-is-not-taking-part-in-tractor-march-heres-the-truth/",
        headline:
          "Fact Check: Cricketer MS Dhoni Is Not Taking Part In Tractor March; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-clipped-video-of-delhis-republic-day-tableau-shared-with-misleading-claim/",
        headline:
          "Fact Check: Clipped Video Of Delhi\u2019s Republic Day Tableau Shared With Misleading Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-old-picture-of-political-rally-shared-as-farmers-marching-towards-delhi/",
        headline:
          "Fact check: Old Picture Of Political Rally Shared As Farmers Marching Towards Delhi",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-old-picture-of-bihars-republic-day-tableau-falsely-shared-as-ups-during-samajwadi-partys-term/",
        headline:
          "Fact Check: Old Picture Of Bihar\u2019s Republic Day Tableau Falsely Shared As UP\u2019s During Samajwadi Party\u2019s Term",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-no-protestors-did-not-hoist-khalistani-flag-red-fort/",
        headline:
          "Fact Check: No! Protestors Did Not Hoist Khalistani Flag At Red Fort",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/28/fact-check-this-video-of-policeman-breaking-cctv-camera-is-not-from-recent-farmers-protest/",
        headline:
          "Fact Check: This Video Of Policeman Breaking CCTV Camera Is Not From Recent Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/28/fact-check-did-a-farmer-die-after-being-shot-at-during-the-tractor-rally-in-delhi-heres-the-truth/",
        headline:
          "Fact Check: Did A Farmer Die After Being Shot At During The Tractor Rally In Delhi? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-mp-cm-shivraj-singh-chouhan-did-not-hoist-bjp-flag-on-republic-day/",
        headline:
          "Fact Check: MP CM Shivraj Singh Chouhan Did Not Hoist BJP Flag On Republic Day",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/khalistan-flag-hoisted-at-red-fort-a-factcheck-11719",
        headline: "Khalistan Flag Hoisted At Red Fort? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-dr-kafeel-khan-tractor-rally-delhi-republic-day-farmers-protest-viral-image-11764",
        headline:
          "Dr Kafeel Khan Took Part In Farmers' Tractor Rally? Fact-Check",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/tarek-fatah-falsely-links-2016-pro-khalistan-clip-to-farmers-tractor-rally-fake-news-republic-day-khalistan-zindabad-delhi-11736",
        headline:
          "Tarek Fatah Falsely Links 2016 Pro-Khalistan Clip To Farmers' Tractor Rally",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/cropped-video-of-delhis-republic-day-tableau-viral-with-communal-spin-11717",
        headline:
          "Cropped Video Of Delhi's Republic Day Tableau Viral With Communal Spin",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/kisan-andolan-delhi-police-cctv-camera-rakesh-tikait-president-ram-nath-kovind-11765",
        headline:
          "\u092a\u0941\u0932\u093f\u0938\u0915\u0930\u094d\u092e\u093f\u092f\u094b\u0902 \u0915\u094b \u0938\u0940\u0938\u0940\u091f\u0940\u0935\u0940 \u0915\u0948\u092e\u0930\u093e \u0924\u094b\u095c\u0924\u0947 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948 \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u092c \u0915\u093e \u0939\u0948?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/mahendra-singh-dhoni-kisan-andolan-tractor-parade-fake-news-old-photo-11734",
        headline:
          "\u091f\u094d\u0930\u0948\u0915\u094d\u091f\u0930 \u092a\u0930 \u0927\u094b\u0928\u0940 \u0915\u0940 \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u092a\u0941\u0930\u093e\u0928\u0940 \u0939\u0948, \u0915\u093f\u0938\u093e\u0928 \u0906\u0902\u0926\u094b\u0932\u0928 \u0938\u0947 \u0938\u092e\u094d\u092c\u0902\u0927\u093f\u0924 \u0928\u0939\u0940\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fast-check/viral-image-sikh-man-insulting-national-flag-central-london-khalistan-farmers-rally-11750",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u093f\u0938\u093e\u0928 \u0930\u0948\u0932\u0940 \u0938\u0947 \u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0928\u0939\u0940\u0902 \u0939\u0948, \u0935\u093e\u092f\u0930\u0932 \u0926\u093e\u0935\u093e \u092d\u094d\u0930\u093e\u092e\u0915 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/red-fort-indian-tricolour-hoisted-khalistani-flag-kisan-andolan-fake-news-11727",
        headline:
          "\u0932\u093e\u0932 \u0958\u093f\u0932\u0947 \u092a\u0930 \u095e\u0939\u0930\u093e\u092f\u093e \u0917\u092f\u093e \u0959\u093e\u0932\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u091d\u0902\u0921\u093e?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/video-of-khalistan-supporters-disrespecting-the-tricolour-is-from-the-united-states-11772",
        headline:
          "Farmers Protest: \u09ad\u09be\u09b0\u09a4\u09c7\u09b0 \u09aa\u09a4\u09be\u0995\u09be \u0985\u09ac\u09ae\u09be\u09a8\u09a8\u09be\u09b0 Viral \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u0986\u09ae\u09c7\u09b0\u09bf\u0995\u09be\u09df \u09a4\u09cb\u09b2\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-dr-kafeel-khan-tractor-rally-delhi-republic-day-farmers-protest-viral-image-11766",
        headline:
          "Dr. Kafeel Khan \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf Tractor Rally'\u09a4\u09c7 \u09af\u09cb\u0997 \u09a6\u09bf\u09df\u09c7\u099b\u09c7\u09a8? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2011-republic-day-tableau-of-bihars-maner-sharif-shared-as-tipu-sultan-ram-madir-ayodhya-tableau-11718",
        headline:
          "\u09e8\u09e6\u09e7\u09e7 Republic Day \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 Maner Sharif \u099f\u09cd\u09af\u09be\u09ac\u09b2\u09cb\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 Tipu Sultan",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/khalistan-flag-hoisted-at-red-fort-a-factcheck-11735",
        headline:
          "\u09b2\u09be\u09b2 \u0995\u09c7\u09b2\u09cd\u09b2\u09be\u09df \u0995\u09bf Khalistan \u09aa\u09a4\u09be\u0995\u09be \u0993\u09dc\u09be\u09a8\u09cb \u09b9\u09df\u09c7\u099b\u09c7? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af\u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/cropped-video-of-delhis-republic-day-tableau-viral-with-communal-spin-11768",
        headline:
          "Republic Day \u09a6\u09bf\u09b2\u09cd\u09b2\u09bf\u09b0 \u099f\u09cd\u09af\u09be\u09ac\u09b2\u09cb\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u0995\u09c7\u099f\u09c7 \u09a7\u09b0\u09cd\u09ae\u09c0\u09df \u09b0\u0999 \u09ae\u09be\u0996\u09bf\u09df\u09c7 \u09aa\u09cd\u09b0\u099a\u09be\u09b0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-pulwama-attack-protest-pictures-shared-as-january-26-tractor-rally-violence-1763381-2021-01-27",
        headline:
          "Fact Check: Pulwama attack protest pictures shared as January 26 tractor rally violence",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-dr-kafeel-khan-was-not-part-of-tractor-rally-in-delhi-on-republic-day-1763724-2021-01-28",
        headline:
          "Fact Check: Dr Kafeel Khan was not part of tractor rally in Delhi on Republic Day\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-video-of-police-damaging-cctv-is-not-linked-to-january-26-tractor-rally-1763750-2021-01-29",
        headline:
          "Fact Check: This video of police damaging CCTV is not linked to January 26 tractor rally",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-unrelated-image-shared-as-police-action-against-protesting-farmers-1764295-2021-01-30",
        headline:
          "Fact Check: Unrelated image shared as police action against protesting farmers",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-pro-khalistan-rally-in-san-francisco-passed-off-as-farmers-agitation-in-india-1764565-2021-01-31",
        headline:
          "Fact Check: Pro-Khalistan rally in San Francisco passed off as farmers\u2019 agitation in India",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-of-cops-allegedly-breaking-cctv-cameras-falsely-linked-to-republic-day-violence-in-delhi-673467",
        headline:
          "Fact Check: Old video of cops allegedly breaking CCTV cameras falsely linked to Republic Day violence in Delhi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-delhi-tableau-at-republic-day-parade-shared-with-false-communal-spin-673377",
        headline:
          "Fact Check: Video of Delhi tableau at Republic Day parade shared with false communal spin",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-protesting-farmers-did-not-remove-indian-flag-at-red-fort-673336",
        headline:
          "Fact Check: Protesting farmers did not remove Indian Flag at Red Fort",
      },
    ],
    "2": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/26-january-republic-day-of-india-uttar-pradesh-yogi-adityanath-madarsa-11703",
        headline:
          "\u0928\u0939\u0940\u0902, 26 \u091c\u0928\u0935\u0930\u0940 \u092a\u0930 \u0930\u093e\u0937\u094d\u091f\u094d\u0930\u0917\u093e\u0928 \u0928 \u092c\u091c\u093e\u0928\u0947 \u092a\u0930 \u092c\u0902\u0926 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u0947 \u091c\u093e\u090f\u0902\u0917\u0947 \u092f\u0941.\u092a\u0940 \u0915\u0947 \u092e\u0926\u0930\u0938\u0947",
      },
      {
        url:
          "https://factly.in/telugu-rbi-did-not-take-any-decision-to-cancel-the-old-rs-100-notes-from-march-2021/",
        headline:
          " \u0c2e\u0c3e\u0c30\u0c4d\u0c1a\u0c3f 2021 \u0c28\u0c41\u0c02\u0c1a\u0c3f \u0c2a\u0c3e\u0c24 100 \u0c30\u0c42\u0c2a\u0c3e\u0c2f\u0c32 \u0c28\u0c4b\u0c1f\u0c4d\u0c32\u0c28\u0c41 \u0c30\u0c26\u0c4d\u0c26\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c07\u0c2a\u0c4d\u0c2a\u0c1f\u0c3f\u0c35\u0c30\u0c15\u0c48\u0c24\u0c47 RBI \u0c28\u0c3f\u0c30\u0c4d\u0c23\u0c2f\u0c02 \u0c24\u0c40\u0c38\u0c41\u0c15\u0c4b\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/video-of-rahul-gandhi-during-a-book-release-is-edited-and-shared-with-false-narrative/",
        headline:
          " Video of Rahul Gandhi during a book release is edited and shared with a false narrative",
      },
      {
        url:
          "https://factly.in/telugu-rahul-gandhi-wearing-mask-after-having-lunch-is-shared-with-false-narrative/",
        headline:
          " \u0c30\u0c3e\u0c39\u0c41\u0c32\u0c4d \u0c17\u0c3e\u0c02\u0c27\u0c40 \u0c24\u0c28 \u0c2d\u0c4b\u0c1c\u0c28\u0c02 \u0c2e\u0c41\u0c17\u0c3f\u0c38\u0c3f\u0c28 \u0c24\u0c30\u0c4d\u0c35\u0c3e\u0c24 \u0c2e\u0c3e\u0c38\u0c4d\u0c15\u0c4d \u0c27\u0c30\u0c3f\u0c02\u0c1a\u0c3f \u0c2e\u0c3e\u0c1f\u0c4d\u0c32\u0c3e\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/image-of-mask-clad-rahul-gandhi-is-being-shared-with-a-sarcastic-claim/",
        headline:
          " Image of mask clad Rahul Gandhi is being shared with a sarcastic claim",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-rahul-gandhi-did-not-suggest-replacing-indian-army-with-labourers-at-china-border-1762983-2021-01-26",
        headline:
          "Fact Check: Rahul Gandhi did not suggest replacing Indian Army with labourers at China border",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/25/fact-check-no-rbi-is-not-withdrawing-old-series-of-%e2%82%b9100-%e2%82%b910-heres-the-truth/",
        headline:
          "Fact Check: No, RBI Is NOT Withdrawing Old Series Of \u20b9100, \u20b910 & \u20b95 Banknotes; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-did-rahul-gandhi-ask-for-disbanding-of-armed-forces-heres-the-truth/",
        headline:
          "Fact Check: Did Rahul Gandhi Ask For Disbanding Of Armed Forces? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-agras-st-johns-college-hasnt-issued-this-bizarre-valentines-day-circular-heres-the-truth/",
        headline:
          "Fact Check: Agra\u2019s St John\u2019s College Hasn\u2019t Issued This Bizarre Valentine\u2019s Day Circular; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-no-chennais-srm-college-did-not-issue-circular-asking-girls-to-mandatorily-have-a-boyfriend-heres-the-truth/",
        headline:
          "Fact Check: No, Chennai\u2019s SRM College Did Not Issue Circular Asking Girls To Mandatorily Have A Boyfriend; Here\u2019s The Truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/rbi-denial-100-10-5-banknotes-demonetization-again-noteban-notebandi-11746",
        headline:
          "\u09e7\u09e6\u09e6, \u09e7\u09e6 \u0993 \u09eb \u099f\u09be\u0995\u09be\u09b0 \u09a8\u09cb\u099f \u09a4\u09c1\u09b2\u09c7 \u09a8\u09c7\u0993\u09df\u09be\u09b0 \u0995\u09a5\u09be \u0985\u09b8\u09cd\u09ac\u09c0\u0995\u09be\u09b0 \u0995\u09b0\u09c7\u099b\u09c7 Reserve Bank",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/rbi-denial-100-10-5-banknotes-demonetization-again-noteban-notebandi-11705",
        headline:
          "RBI Denies Media Reports On Withdrawal Of \u20b9100, \u20b910 And \u20b95 Banknotes",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/26-january-republic-day-of-india-uttar-pradesh-yogi-adityanath-madarsa-11703",
        headline:
          "\u0928\u0939\u0940\u0902, 26 \u091c\u0928\u0935\u0930\u0940 \u092a\u0930 \u0930\u093e\u0937\u094d\u091f\u094d\u0930\u0917\u093e\u0928 \u0928 \u092c\u091c\u093e\u0928\u0947 \u092a\u0930 \u092c\u0902\u0926 \u0928\u0939\u0940\u0902 \u0915\u093f\u092f\u0947 \u091c\u093e\u090f\u0902\u0917\u0947 \u092f\u0941.\u092a\u0940 \u0915\u0947 \u092e\u0926\u0930\u0938\u0947",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/did-rahul-gandhi-wear-mask-while-eating-food-in-tamil-nadu-visit-a-fact-check-11751",
        headline:
          "\u09ae\u09be\u09b8\u09cd\u0995 \u09aa\u09b0\u09c7 \u0996\u09be\u09ac\u09be\u09b0 \u0996\u09be\u099a\u09cd\u099b\u09c7\u09a8 Rahul Gandhi? \u098f\u0995\u099f\u09bf \u09a4\u09a5\u09cd\u09af \u09af\u09be\u099a\u09be\u0987",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-boyfriend-no-entry-these-college-circulars-are-too-filmy-to-be-true-1764478-2021-01-31",
        headline:
          "Fact Check: No boyfriend, no entry? These college circulars are too filmy to be true",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-old-currency-notes-of-rs-100-10-and-5-denominations-will-not-be-withdrawn-673376",
        headline:
          "Fact Check: No, old currency notes of Rs 100, 10, and 5 denominations will not be withdrawn",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fact-check/farmers-protest-pakistani-womans-pic-misused-to-spread-fake-news-11753",
        headline:
          "Farmers Protest: Pakistani Woman's Pic Misused To Spread Fake News",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/police-kicking-sikh-protester-viral-picture-farmers-protest-delhi-republic-day-tractor-rally-fake-news-11767",
        headline:
          "2013 Picture Of Sikh Protester Being Thrashed By Cops Peddled As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-delhi-police-crying-lathicharge-farmers-protest-delhi-viral-video-tractor-rally-republic-day-11781",
        headline:
          "Video Of Delhi Police Crying After Lathi-Charging Farmers? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/rss-member-delhi-police-farmers-protest-republic-day-11783",
        headline:
          "RSS Worker Disguised Himself As A Cop At Farmers Protest? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/delhi-police-brutality-farmers-protest-fake-news-fact-check-11794",
        headline:
          "2011 Picture Of Brutality By Lucknow Cop Viral As Delhi Police",
      },
      {
        url:
          "https://factly.in/2016-video-raked-up-and-falsely-linked-to-farmers-tractor-parade/",
        headline:
          " 2016 video reshared and falsely linked to farmers tractor parade",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-shows-the-terraces-of-pumatallis-in-the-town-of-ollantaytambo-in-peru/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2a\u0c46\u0c30\u0c42 \u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b\u0c28\u0c3f \u2018Ollantaytambo\u2019 \u0c1f\u0c4c\u0c28\u0c4d \u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u2018Terraces of Pumatallis\u2019",
      },
      {
        url:
          "https://factly.in/telugu-people-running-behind-a-faulty-tractor-falsely-shared-as-villagers-chasing-farmers/",
        headline:
          " \u0c05\u0c26\u0c41\u0c2a\u0c41\u0c24\u0c2a\u0c4d\u0c2a\u0c3f\u0c28 \u0c1f\u0c4d\u0c30\u0c3e\u0c15\u0c4d\u0c1f\u0c30\u0c4d \u0c35\u0c46\u0c28\u0c15 \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c41 \u0c2a\u0c30\u0c3f\u0c17\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c30\u0c48\u0c24\u0c41\u0c32\u0c28\u0c41 \u0c24\u0c30\u0c41\u0c2e\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c17\u0c4d\u0c30\u0c3e\u0c2e \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c02\u0c1f\u0c42 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-the-man-with-the-bruises-in-the-post-was-injured-in-an-old-accident-incident/",
        headline:
          " \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c4d \u0c32\u0c4b \u0c26\u0c46\u0c2c\u0c4d\u0c2c\u0c32\u0c24\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c2b\u0c4b\u0c1f\u0c4b \u0c2a\u0c3e\u0c24\u0c26\u0c3f; \u0c12\u0c15 \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c02\u0c32\u0c4b \u0c06 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f\u0c15\u0c3f \u0c17\u0c3e\u0c2f\u0c3e\u0c32\u0c2f\u0c4d\u0c2f\u0c3e\u0c2f\u0c3f",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/25/fact-check-old-incident-of-attack-on-policeman-from-ghaziabad-shared-with-misleading-claim/",
        headline:
          "Fact Check: Old Incident Of Attack On Policeman From Ghaziabad Shared With Misleading Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-2019-pictures-from-jammu-shared-as-latest-images-of-unrest-in-delhi/",
        headline:
          "Fact Check: 2019 Pictures From Jammu Shared As Latest Images Of Unrest In Delhi",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-picture-of-man-with-marks-of-assault-on-his-back-is-not-from-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: Picture Of Man With Marks Of Assault On His Back Is NOT From Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-this-picture-is-not-related-to-farmers-protest-heres-the-truth/",
        headline:
          "Fact Check: This Picture Is Not Related To Farmers\u2019 Protest; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-this-video-of-rally-in-support-of-pm-modi-heres-the-truth/",
        headline:
          "Fact Check: This Video Of Rally In Support Of PM Modi & Delhi Police Is From 2019; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-this-video-is-not-of-up-police-chasing-a-farmers-tractor-heres-the-truth/",
        headline:
          "Fact Check: This Video Is Not Of UP Police Chasing A Farmer\u2019s Tractor; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/delhi-police-jai-jawan-jai-kisan-farmers-protest-republic-day-tractor-parade-fake-news-fact-check-11795",
        headline:
          "Video Of Delhi Cops Chanting Jai Jawan, Jai Kisan Viral With Fake Claim",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/police-kicking-sikh-protester-viral-picture-farmers-protest-delhi-republic-day-tractor-rally-fake-news-11767",
        headline:
          "2013 Picture Of Sikh Protester Being Thrashed By Cops Peddled As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/delhi-police-brutality-farmers-protest-fake-news-fact-check-11794",
        headline:
          "2011 Picture Of Brutality By Lucknow Cop Viral As Delhi Police",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/2019-image-of-man-thrashed-by-delhi-police-linked-to-farmers-rally-11730",
        headline:
          "2019 Image Of Man Thrashed By Delhi Police Linked To Farmers' Rally",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-delhi-police-crying-lathicharge-farmers-protest-delhi-viral-video-tractor-rally-republic-day-11781",
        headline:
          "Video Of Delhi Police Crying After Lathi-Charging Farmers? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/rss-member-delhi-police-farmers-protest-republic-day-11783",
        headline:
          "RSS Worker Disguised Himself As A Cop At Farmers Protest? A FactCheck",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/farmers-protest-pakistani-womans-pic-misused-to-spread-fake-news-11753",
        headline:
          "Farmers Protest: Pakistani Woman's Pic Misused To Spread Fake News",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-image-pakistani-twitter-famers-parade-sikh-protest-jantar-mantar-delhi-2013-11763",
        headline:
          "\u092a\u093e\u0915\u093f\u0938\u094d\u0924\u093e\u0928\u0940 \u092a\u0924\u094d\u0930\u0915\u093e\u0930 \u0928\u0947 \u0915\u093f\u0938\u093e\u0928 \u092a\u0930\u0947\u0921 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 7 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0936\u0947\u092f\u0930 \u0915\u0940",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/viral-video-delhi-police-lath-bajao-26-januray-farmers-violence-pro-caa-nrc-rally-11745",
        headline:
          "'\u0926\u093f\u0932\u094d\u0932\u0940 \u092a\u0941\u0932\u093f\u0938 \u0932\u091f\u094d\u0920 \u092c\u091c\u093e\u0913' \u0915\u093e \u0928\u093e\u0930\u093e \u0932\u0917\u093e\u0924\u0947 \u0932\u094b\u0917\u094b\u0902 \u0915\u0940 \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u092a\u0941\u0930\u093e\u0928\u0940 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/old-image-of-man-thrashed-by-delhi-police-linked-to-farmers-rally-11773",
        headline:
          "Delhi Police-\u098f\u09b0 \u09b9\u09be\u09a4\u09c7 \u09a8\u09bf\u0997\u09cd\u09b0\u09b9\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf Viral \u09b9\u09b2 \u0995\u09c3\u09b7\u0995 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/30/fact-check-old-picture-of-clash-between-protestor-and-cop-in-lucknow-linked-to-farmers-protest/",
        headline:
          "Fact Check: Old Picture Of Clash Between Protestor and Cop In Lucknow Linked To Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/30/fact-check-old-video-of-cop-crying-over-lathicharge-falsely-linked-to-farmers-protest/",
        headline:
          "Fact Check: Old Video Of Cop Crying Over Lathicharge Falsely Linked To Farmers\u2019 Protest",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-seven-year-old-image-from-up-wrongly-linked-to-farmers-agitation-1764071-2021-01-29",
        headline:
          "Fact Check: Seven-year-old image from UP wrongly linked to farmers\u2019 agitation",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-cops-pursuing-sand-mafia-passed-off-as-up-police-chasing-farmer-1763360-2021-01-27",
        headline:
          "Fact Check: Video of cops pursuing sand mafia passed off as UP police chasing farmer",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-200-policemen-in-delhi-havent-resigned-in-support-of-farmers-1764456-2021-01-31",
        headline:
          "Fact Check: No, 200 policemen in Delhi haven't resigned in support of farmers",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-vandalized-hanuman-idol-from-2014-falsely-linked-to-recent-ap-temple-attacks-673287",
        headline:
          "Fact Check: Photo of vandalized Hanuman idol from 2014 falsely linked to recent AP temple attacks",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-video-of-agitating-policeman-in-ranchi-passed-off-as-farmers-protest-in-delhi-673517",
        headline:
          "Fact Check: Old video of agitating policeman in Ranchi passed off as farmers protest in Delhi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-jammu-violence-images-passed-off-as-farmers-unrest-in-delhi-673466",
        headline:
          "FACT CHECK: Old Jammu violence images passed off as farmers unrest in Delhi",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-woman-thrashing-cop-with-slippers-is-from-ghaziabad-not-bareilly-673363",
        headline:
          "FACT CHECK: Video of woman thrashing cop with slippers is from Ghaziabad, not Bareilly",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-man-in-modi-t-shirt-attacking-cops-not-related-to-farmers-protest-673543",
        headline:
          "Fact Check: Photo of man in Modi t-shirt attacking cops not related to farmers' protest",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-tractor-runs-over-old-women-parade-rally-alka-lamba-bjp-fake-claim-viral-news-fact-check-11762",
        headline:
          "Video Of Water Tanker Running Over Two Women Viral With Misleading Claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-rakesh-tikait-twitter-accounts-surface-after-emotional-speech-11784",
        headline:
          "Fake Rakesh Tikait Twitter Accounts Surface After Emotional Speech",
      },
      {
        url:
          "https://factly.in/lord-macaulay-did-not-deliver-this-speech-glorifying-india-in-british-parliament-in-1835/",
        headline:
          " Lord Macaulay did not deliver this speech in the British Parliament in 1835",
      },
      {
        url:
          "https://factly.in/an-old-photo-of-muslims-praying-in-front-of-the-us-capitol-is-being-shared-as-a-recent-one/",
        headline:
          " An old photo of Muslims praying in front of the US Capitol is being shared as a recent one",
      },
      {
        url:
          "https://factly.in/telugu-mamata-banerjee-offered-prayers-to-hindu-and-sikh-gods-as-well-in-this-speech/",
        headline:
          " \u0c08\u00a0\u0c38\u0c2d\u0c32\u0c4b\u00a0\u0c2e\u0c2e\u0c24\u0c3e\u00a0\u0c2c\u0c46\u0c28\u0c30\u0c4d\u0c1c\u0c40\u00a0\u0c07\u0c38\u0c4d\u0c32\u0c3e\u0c02 \u0c2a\u0c4d\u0c30\u0c3e\u0c30\u0c4d\u0c25\u0c28\u0c24\u0c4b\u00a0\u0c2a\u0c3e\u0c1f\u0c41 \u0c39\u0c3f\u0c02\u0c26\u0c42, \u0c38\u0c3f\u0c15\u0c4d\u0c15\u0c41\u00a0\u0c2e\u0c24 \u0c2a\u0c4d\u0c30\u0c3e\u0c30\u0c4d\u0c25\u0c28\u0c32\u0c41 \u0c15\u0c42\u0c21\u0c3e \u0c1a\u0c47\u0c38\u0c3e\u0c30\u0c41\u00a0\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/video/edited-clip-of-mamata-reciting-islamic-prayers-linked-to-netaji-event-controversy-1762653-2021-01-25",
        headline:
          "Edited clip of Mamata reciting Islamic prayers linked to Netaji event controversy",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/25/fact-check-old-edited-video-shared-as-raga-opposing-farmers-loan-waiver-heres-the-truth/",
        headline:
          "Fact Check: Old, Edited Video Shared As RaGa Opposing Farmers\u2019 Loan Waiver; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/25/fact-check-2009-picture-of-namaz-at-us-capitol-hill-passed-of-as-recent-one/",
        headline:
          "Fact Check: 2009 Picture Of Namaz At US Capitol Hill Passed Of As Recent One",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-this-picture-does-not-show-farmers-shot-during-congress-rule-in-1998-heres-the-truth/",
        headline:
          "Fact Check: This Picture Does Not Show Farmers Shot During Cong Rule In 1998; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-video-of-people-raising-pro-khalistani-slogans-disrespecting-tricolour-is-not-from-india/",
        headline:
          "Fact Check: Video Of People Raising Pro-Khalistani Slogans, Disrespecting Tricolour Is NOT From India",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/video-of-khalistan-supporters-disrespecting-the-tricolour-is-from-the-us-11749",
        headline:
          "Video Of Khalistan Supporters Disrespecting The Tricolour Is From The US",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-tractor-runs-over-old-women-parade-rally-alka-lamba-bjp-fake-claim-viral-news-fact-check-11762",
        headline:
          "Video Of Water Tanker Running Over Two Women Viral With Misleading Claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-rakesh-tikait-twitter-accounts-surface-after-emotional-speech-11784",
        headline:
          "Fake Rakesh Tikait Twitter Accounts Surface After Emotional Speech",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-rakesh-tikait-twitter-accounts-surface-after-emotional-speech-11793",
        headline:
          "Rakesh Tikait-\u098f\u09b0 \u0986\u09ac\u09c7\u0997\u09c0 \u09ad\u09be\u09b7\u09a3\u09c7\u09b0 \u09aa\u09b0 \u09a4\u09be\u0981\u09b0 \u09a8\u09be\u09ae\u09c7 \u098f\u0995\u09be\u09a7\u09bf\u0995 \u09ad\u09c1\u09df\u09cb \u099f\u09c1\u0987\u099f\u09be\u09b0 \u0985\u09cd\u09af\u09be\u0995\u09be\u0989\u09a8\u09cd\u099f \u0996\u09cb\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/video-of-man-cuddling-cheetahs-in-south-africa-shared-as-rajasthan-temple-revived-11699",
        headline:
          "\u09b0\u09be\u099c\u09b8\u09cd\u09a5\u09be\u09a8\u09c7 \u09aa\u09c1\u09b0\u09cb\u09b9\u09bf\u09a4\u09c7\u09b0 \u09b8\u09be\u09a5\u09c7 \u099a\u09bf\u09a4\u09be\u09b0 \u09b8\u0996\u09cd\u09af\u09a4\u09be \u09ac\u09b2\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u0986\u09ab\u09cd\u09b0\u09bf\u0995\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/secret-meeting-bjp-leader-suvendu-adhikari-with-indian-secular-front-leader-abbas-siddiqui-fake-image-bengal-assembly-election-11739",
        headline:
          "\u09b6\u09c1\u09ad\u09c7\u09a8\u09cd\u09a6\u09c1-\u0986\u09ac\u09cd\u09ac\u09be\u09b8 \u09b8\u09bf\u09a6\u09cd\u09a6\u09bf\u0995\u09bf\u09b0 \u0997\u09cb\u09aa\u09a8 \u0986\u0981\u09a4\u09be\u09a4? \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09b9\u09b2 \u09ad\u09c1\u09df\u09cb \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-water-tanker-hitting-women-en-route-farmers-protest-has-no-political-connection-1764205-2021-01-30",
        headline:
          "Fact Check: Video of water tanker hitting women en route farmers' protest has no political connection",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-accident-showing-women-run-over-by-tanker-in-amritsar-shared-with-misleading-claims-673544",
        headline:
          "Fact Check: Video of accident showing women run over by tanker in Amritsar shared with misleading claims",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bjp-shares-edited-video-clip-of-mamata-banerjee-reciting-islamic-verses-673271",
        headline:
          "FACT CHECK: BJP shares edited video clip of Mamata Banerjee reciting Islamic verses",
      },
    ],
    "5": [
      {
        url:
          "https://www.boomlive.in/fact-check/bjp-imf-twitter-gdp-growth-8-115-2020-estimates-11738",
        headline:
          "BJP Misses Minus Sign While Sharing India's IMF GDP Growth Data",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/72nd-republic-day-india-ram-mandir-uttar-pradesh-bihar-tableau-old-photo-11732",
        headline:
          "\u0926\u0938 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u092c\u093f\u0939\u093e\u0930 \u0915\u0940 \u091d\u093e\u0902\u0915\u0940 \u0909\u0924\u094d\u0924\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/sambit-patra-arvind-kejriwal-delhi-farm-laws-kisan-andolan-bjp-fake-11797",
        headline:
          "\u0938\u0902\u092c\u093f\u0924 \u092a\u093e\u0924\u094d\u0930\u093e \u0928\u0947 \u0936\u0947\u092f\u0930 \u0915\u0940 \u0905\u0930\u0935\u093f\u0928\u094d\u0926 \u0915\u0947\u091c\u0930\u0940\u0935\u093e\u0932 \u0915\u0940 \u0915\u0943\u0937\u093f \u0915\u093e\u0928\u0942\u0928\u094b\u0902 \u0915\u093e \u0938\u092e\u0930\u094d\u0925\u0928 \u0915\u0930\u0924\u0947 \u0926\u093f\u0916\u093e\u0924\u0940 \u090f\u0921\u093f\u091f\u0947\u0921 \u0915\u094d\u0932\u093f\u092a",
      },
      {
        url:
          "https://factly.in/a-supersonic-free-fall-video-shared-as-an-astronaut-jumping-from-his-spacecraft/",
        headline:
          " A Supersonic freefall video shared as an astronaut jumping from his Spacecraft",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-has-govt-increased-interest-rate-of-kisan-credit-card-loan-heres-the-truth/",
        headline:
          "Fact Check: Has Govt Increased Interest Rate Of Kisan Credit Card Loan; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-did-gujarat-govt-rename-dragon-fruit-after-kamala-harris-heres-the-truth/",
        headline:
          "Fact Check: Did Gujarat Govt Rename Dragon Fruit After Kamala Harris? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-sambit-patra-edited-clip-arvind-kejriwal-farm-bills-farmers-protests-11785",
        headline:
          "Sambit Patra Shares Edited Clip To Claim Arvind Kejriwal Supports Farm Bills",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/bjp-imf-twitter-gdp-growth-8-115-2020-estimates-11738",
        headline:
          "BJP Misses Minus Sign While Sharing India's IMF GDP Growth Data",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/sambit-patra-arvind-kejriwal-delhi-farm-laws-kisan-andolan-bjp-fake-11797",
        headline:
          "\u0938\u0902\u092c\u093f\u0924 \u092a\u093e\u0924\u094d\u0930\u093e \u0928\u0947 \u0936\u0947\u092f\u0930 \u0915\u0940 \u0905\u0930\u0935\u093f\u0928\u094d\u0926 \u0915\u0947\u091c\u0930\u0940\u0935\u093e\u0932 \u0915\u0940 \u0915\u0943\u0937\u093f \u0915\u093e\u0928\u0942\u0928\u094b\u0902 \u0915\u093e \u0938\u092e\u0930\u094d\u0925\u0928 \u0915\u0930\u0924\u0947 \u0926\u093f\u0916\u093e\u0924\u0940 \u090f\u0921\u093f\u091f\u0947\u0921 \u0915\u094d\u0932\u093f\u092a",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/72nd-republic-day-india-ram-mandir-uttar-pradesh-bihar-tableau-old-photo-11732",
        headline:
          "\u0926\u0938 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u092c\u093f\u0939\u093e\u0930 \u0915\u0940 \u091d\u093e\u0902\u0915\u0940 \u0909\u0924\u094d\u0924\u0930 \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0947 \u0928\u093e\u092e \u0938\u0947 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bjp-overlooks-indias-negative-gdp-estimates-for-2020-falsely-claims-8-growth-673516",
        headline:
          "Fact Check: BJP overlooks India's negative GDP estimates for 2020, falsely claims 8% growth",
      },
    ],
    "6": [
      {
        url:
          "https://www.boomlive.in/fact-check/netaji-subhas-chandra-bose-gumnaami-prasenjit-chatterjee-fake-news-fact-check-viral-presidnet-kovind-11704",
        headline:
          "Painting At Rashtrapati Bhavan Is Not Of An Actor Playing Subhas Chandra Bose",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news-fact-check-chandigarh-municipal-election-bjp-congress-bjp-20-11760",
        headline:
          "Chandigarh Municipal Election Results Of 2016 Falsely Shared As Recent",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/joe-biden-news-narendra-modi-donald-trump-priyanka-gandhi-fake-facebook-post-11700",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u094d\u0930\u093f\u092f\u0902\u0915\u093e \u0917\u093e\u0901\u0927\u0940 \u0928\u0947 \u091c\u094b \u092c\u093e\u0907\u0921\u0928 \u0915\u094b \u0936\u0941\u092d\u0915\u093e\u092e\u0928\u093e\u090f\u0902 \u0926\u0947\u0924\u0947 \u0939\u0941\u090f \u092e\u094b\u0926\u0940 \u0915\u0940 \u0906\u0932\u094b\u091a\u0928\u093e \u0915\u0940?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/netaji-subhash-chandra-bose-gumnami-film-rashtrapati-bhawan-ram-nath-kovind-false-claims-11710",
        headline:
          "\u0930\u093e\u0937\u094d\u091f\u094d\u0930\u092a\u0924\u093f \u092d\u0935\u0928 \u092e\u0947\u0902 \u092a\u0947\u0902\u091f\u093f\u0902\u0917 \u0938\u0941\u092d\u093e\u0937 \u091a\u0928\u094d\u0926\u094d\u0930 \u092c\u094b\u0938 \u092c\u0928\u0947 \u0915\u093f\u0938\u0940 \u0915\u0932\u093e\u0915\u093e\u0930 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/neha-joshi-fake-twitter-account-kisan-rally-farmers-attacked-journalists-tractor-parade-11756",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0930\u0948\u0932\u0940: \u092a\u0924\u094d\u0930\u0915\u093e\u0930\u094b\u0902 \u092a\u0930 \u0939\u092e\u0932\u0947 \u0915\u0940 \u090f\u0915 \u091c\u0948\u0938\u0940 \u0915\u0939\u093e\u0928\u0940 \u0915\u0939\u0924\u0947 \u091f\u094d\u0935\u093f\u091f\u0930 \u0939\u0948\u0902\u0921\u0932 \u0915\u093e \u0938\u091a",
      },
      {
        url:
          "https://factly.in/telugu-joe-biden-is-not-sitting-infront-of-george-floyd-daughter-in-this-picture/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c1c\u0c4b \u0c2c\u0c48\u0c21\u0c46\u0c28\u0c4d \u0c2e\u0c4b\u0c15\u0c3e\u0c33\u0c4d\u0c33\u0c2a\u0c48 \u0c15\u0c42\u0c30\u0c4d\u0c1a\u0c41\u0c02\u0c26\u0c3f \u0c1c\u0c3e\u0c30\u0c4d\u0c1c\u0c4d \u0c2b\u0c4d\u0c32\u0c3e\u0c2f\u0c3f\u0c21\u0c4d \u0c15\u0c42\u0c24\u0c41\u0c30\u0c41 \u0c2e\u0c41\u0c02\u0c26\u0c41 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/2016-chandigarh-municipal-elections-results-shared-as-those-achieved-amidst-the-ongoing-farmers-protests/",
        headline:
          " 2016 Chandigarh Municipal elections results shared as those during the ongoing farmers\u2019 protests",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/25/fact-check-no-former-pm-manmohan-singh-was-not-invited-to-bidens-oath-taking-ceremony-here-the-truth/",
        headline:
          "Fact Check: No, Former PM Manmohan Singh Was Not Invited to Biden\u2019s Oath-Taking Ceremony; Here The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/25/fact-check-did-us-president-joe-biden-call-pm-modi-world-leader-heres-the-truth/",
        headline:
          "Fact Check: Did US President Joe Biden Call PM Modi \u2018World Leader\u2019? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/26/fact-check-did-president-unveil-portrait-of-actor-who-played-netaji-heres-the-truth/",
        headline:
          "Fact Check: Did President Unveil Portrait Of Actor Who Played Netaji? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-no-400000-americans-did-not-die-within-9-hours-of-biden-becoming-us-president/",
        headline:
          "Fact Check: No! 400,000 Americans Did Not Die Within 9 Hours Of Biden Becoming US President",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-is-joe-biden-seen-apologising-to-the-daughter-of-an-african-american-policeman-in-this-pic-heres-the-truth/",
        headline:
          "Fact Check: Is Joe Biden Seen Apologising To The Daughter Of An African-American Policeman In This Pic? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/29/fact-check-this-video-is-not-of-captain-ankit-gupta-jumping-from-a-helicopter-heres-the-truth/",
        headline:
          "Fact Check: This Video Is Not Of Captain Ankit Gupta Jumping From A Helicopter; Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/netaji-subhas-chandra-bose-gumnaami-prasenjit-chatterjee-fake-news-fact-check-viral-presidnet-kovind-11704",
        headline:
          "Painting At Rashtrapati Bhavan Is Not Of An Actor Playing Subhas Chandra Bose",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-fact-check-chandigarh-municipal-election-bjp-congress-bjp-20-11760",
        headline:
          "Chandigarh Municipal Election Results Of 2016 Falsely Shared As Recent",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/neha-joshi-fake-twitter-account-kisan-rally-farmers-attacked-journalists-tractor-parade-11756",
        headline:
          "\u0915\u093f\u0938\u093e\u0928 \u0930\u0948\u0932\u0940: \u092a\u0924\u094d\u0930\u0915\u093e\u0930\u094b\u0902 \u092a\u0930 \u0939\u092e\u0932\u0947 \u0915\u0940 \u090f\u0915 \u091c\u0948\u0938\u0940 \u0915\u0939\u093e\u0928\u0940 \u0915\u0939\u0924\u0947 \u091f\u094d\u0935\u093f\u091f\u0930 \u0939\u0948\u0902\u0921\u0932 \u0915\u093e \u0938\u091a",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/delhi-tableau-on-republic-day-2021-azaan-kangana-ranaut-tweet-11729",
        headline: "              ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/netaji-subhash-chandra-bose-gumnami-film-rashtrapati-bhawan-ram-nath-kovind-false-claims-11710",
        headline:
          "\u0930\u093e\u0937\u094d\u091f\u094d\u0930\u092a\u0924\u093f \u092d\u0935\u0928 \u092e\u0947\u0902 \u092a\u0947\u0902\u091f\u093f\u0902\u0917 \u0938\u0941\u092d\u093e\u0937 \u091a\u0928\u094d\u0926\u094d\u0930 \u092c\u094b\u0938 \u092c\u0928\u0947 \u0915\u093f\u0938\u0940 \u0915\u0932\u093e\u0915\u093e\u0930 \u0915\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/joe-biden-news-narendra-modi-donald-trump-priyanka-gandhi-fake-facebook-post-11700",
        headline:
          "\u0915\u094d\u092f\u093e \u092a\u094d\u0930\u093f\u092f\u0902\u0915\u093e \u0917\u093e\u0901\u0927\u0940 \u0928\u0947 \u091c\u094b \u092c\u093e\u0907\u0921\u0928 \u0915\u094b \u0936\u0941\u092d\u0915\u093e\u092e\u0928\u093e\u090f\u0902 \u0926\u0947\u0924\u0947 \u0939\u0941\u090f \u092e\u094b\u0926\u0940 \u0915\u0940 \u0906\u0932\u094b\u091a\u0928\u093e \u0915\u0940?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/joe-biden-narendra-modi-world-leader-us-president-india-america-partnership-viral-fake-tweet-11714",
        headline:
          "Fact Check: Joe Biden \u0995\u09bf Narednra Modi \u0995\u09c7 \u099f\u09c1\u0987\u099f\u09c7 '\u09ac\u09bf\u09b6\u09cd\u09ac\u09a8\u09c7\u09a4\u09be' \u09ac\u09b2\u09c7\u099b\u09c7\u09a8?",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/netaji-subhas-chandra-bose-gumnaami-prasenjit-chatterjee-fake-news-fact-check-viral-president-kovind-11708",
        headline: "Rashrapati Bhavan-  ,  Prasenjit Chatterjee- ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-priyanka-gandhi-fan-page-post-narendra-modi-joe-biden-congress-priyanka-gandhi-vadra-kamala-harris-11722",
        headline:
          "Biden-\u0995\u09c7 \u09b6\u09c1\u09ad\u09c7\u099a\u09cd\u099b\u09be \u099c\u09be\u09a8\u09bf\u09df\u09c7 Priyanka \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 Modi-\u0995\u09c7 \u0995\u099f\u09be\u0995\u09cd\u09b7 \u0995\u09b0\u09c7\u099b\u09c7\u09a8?",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/30/fact-check-2016-chandigarh-municipal-poll-results-shared-as-recent-amid-farmers-protest/",
        headline:
          "Fact Check: 2016 Chandigarh Municipal Poll Results Shared As Recent Amid Farmers\u2019 Protest",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-president-ram-nath-kovind-unveiled-the-original-portrait-of-subhash-chandra-bose-not-actor-prosenjit-chatterjees-photo-673312",
        headline:
          "Fact Check: President Ram Nath Kovind unveiled the original portrait of Subhash Chandra Bose, not actor Prosenjit Chatterjee's photo",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/viral/fact-check-old-image-of-us-capitol-viral-with-misleading-claim/",
        headline:
          "Fact Check: \u0986\u09ae\u09c7\u09f0\u09bf\u0995\u09be \u09af\u09c1\u0995\u09cd\u09a4\u09f0\u09be\u09b7\u09cd\u099f\u09cd\u09f0\u09f0 \u09f0\u09be\u099c\u09a7\u09be\u09a8\u09c0\u09f0 \u09e8\u09e6\u09e6\u09ef \u099a\u09a8\u09f0 \u09aa\u09c1\u09f0\u09a3\u09bf \u099b\u09ac\u09bf\u0995 \u09ad\u09c1\u09f1\u09be \u0996\u09ac\u09f0\u09c7\u09f0\u09c7 \u0995\u09f0\u09be \u09b9\u09c8\u099b\u09c7 \u09ad\u09be\u0987\u09f0\u09c7\u09b2",
      },
    ],
    "7": [
      {
        url:
          "https://www.boomlive.in/fact-check/simulation-clip-shared-as-high-speed-floating-train-in-china-11759",
        headline:
          "Simulation Clip Shared As High-Speed Floating Train In China",
      },
      {
        url:
          "https://factly.in/there-is-no-pm-berojgaari-bhatta-yojna2021-scheme-providing-monthly-unemployment-allowance/",
        headline:
          " There is no \u2018PM Berojgaari Bhatta Yojna \u2013 2021\u2019 scheme providing a monthly unemployment allowance.",
      },
      {
        url:
          "https://factly.in/telugu-the-officers-in-the-video-clarified-that-they-had-taken-the-corona-vaccine/",
        headline:
          " \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c05\u0c27\u0c3f\u0c15\u0c3e\u0c30\u0c41\u0c32\u0c41 \u0c24\u0c3e\u0c2e\u0c41 \u0c15\u0c30\u0c4b\u0c28\u0c3e \u0c35\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d \u0c24\u0c40\u0c38\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c35\u0c3f\u0c35\u0c30\u0c23 \u0c07\u0c1a\u0c4d\u0c1a\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-satirical-news-article-is-misconstrued-as-good-morning-messages-will-attract-18-gst/",
        headline:
          " Old satirical news article is misconstrued as good morning messages will attract 18% GST",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-of-children-dressed-in-hindu-attire-and-dining-on-banana-leaves-was-not-taken-in-germany/",
        headline:
          " \u0c39\u0c3f\u0c02\u0c26\u0c42 \u0c2a\u0c3f\u0c32\u0c4d\u0c32\u0c32\u0c41 \u0c05\u0c30\u0c1f\u0c3f \u0c06\u0c15\u0c41\u0c32\u0c32\u0c4b \u0c2d\u0c4b\u0c1c\u0c28\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c1c\u0c30\u0c4d\u0c2e\u0c28\u0c40 \u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-did-a-man-from-the-minority-community-kill-an-elephant-in-tamil-nadu-heres-the-truth/",
        headline:
          "Fact Check: Did A Man From The Minority Community Kill An Elephant In Tamil Nadu; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/01/27/fact-check-has-cbse-announced-biometric-attendance-for-board-examinations-heres-the-truth/",
        headline:
          "Fact Check: Has CBSE Announced Biometric Attendance For Board Examinations? Here\u2019s The Truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/simulation-clip-shared-as-high-speed-floating-train-in-china-11759",
        headline:
          "Simulation Clip Shared As High-Speed Floating Train In China",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/clip-of-simulation-video-game-shared-as-superfast-floating-maglev-train-in-china-11723",
        headline:
          "\u099a\u09bf\u09a8\u09c7 \u09a6\u09cd\u09b0\u09c1\u09a4 \u0997\u09a4\u09bf\u09b0 \u09ad\u09be\u09b8\u09ae\u09be\u09a8 \u099f\u09cd\u09b0\u09c7\u09a8 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 Video Game-\u098f\u09b0 \u09a6\u09b6\u09cd\u09af",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/unrelated-images-revived-as-mythical-sunken-city-of-dwaraka-gujarat-11747",
        headline:
          "\u09b8\u09ae\u09cd\u09aa\u09b0\u09cd\u0995\u09b9\u09c0\u09a8 \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 Gujarat-\u098f \u0985\u09ac\u09bf\u09b7\u09cd\u0995\u09be\u09b0 \u09b9\u0993\u09df\u09be Dwaraka \u09b6\u09b9\u09b0\u09c7\u09b0 \u09ad\u0997\u09cd\u09a8\u09b0\u09c2\u09aa",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/coronavirus-vaccine-does-not-act-on-covid-19-false-message-impact-11726",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u09ac\u09be\u09b0\u09cd\u09a4\u09be\u09df \u09ac\u09b2\u09be \u09b9\u09df\u09c7\u099b\u09c7 COVID-19 \u09ad\u09cd\u09af\u09be\u0995\u09b8\u09bf\u09a8\u09c7 \u0995\u09be\u099c \u09b9\u09df \u09a8\u09be",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-of-speeding-train-smashing-motorbike-but-narrowly-missing-biker-is-not-from-rajahmundry-673420",
        headline:
          "Fact Check: Viral video of speeding train smashing motorbike but narrowly missing biker is not from Rajahmundry",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-spectacular-sun-halo-is-not-mani-darshan-but-phenomenon-called-parhelion-673480",
        headline:
          "Fact Check: Video of spectacular sun halo is not Mani Darshan but phenomenon called parhelion",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-amit-shah-greeting-asaduddin-owaisi-is-morphed-673309",
        headline:
          "Fact Check: Photo of Amit Shah greeting Asaduddin Owaisi is morphed",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-2018-news-clipping-on-measles-vaccine-side-effects-linked-to-covid-19-vaccine-673362",
        headline:
          "Fact Check: 2018 news clipping on measles vaccine side effects linked to COVID-19 vaccine",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-west-indies-cricketer-kieron-pollard-is-not-dead-673542",
        headline:
          "Fact Check: West Indies cricketer Kieron Pollard is not dead",
      },
      {
        url:
          "https://www.vishvasnews.com/assamese/health/this-photo-has-no-connection-with-covid-19-vaccination-viral-post-is-not-true/",
        headline:
          "Fact Check: \u0995\u2019\u09ad\u09bf\u09a1-\u09e7\u09ef \u09f0 \u09b8\u09c8\u09a4\u09c7 \u098f\u0987 \u09ab\u099f\u09cb\u0996\u09a8\u09f0 \u0995\u09cb\u09a8\u09cb \u09b8\u09ae\u09cd\u09aa\u09f0\u09cd\u0995 \u09a8\u09be\u0987, \u09ad\u09be\u0987\u09f0\u09c7\u09b2 \u09b9\u09cb\u09f1\u09be \u09a6\u09be\u09ac\u09c0 \u09ad\u09c1\u09f1\u09be",
      },
    ],
  },
  numpy_seed: 400,
  number_of_articles: 169,
}
