export const data = {
  mdsDat: {
    x: [
      0.18151133496182423,
      -0.27531046290162714,
      0.23404910844005247,
      0.14106517239184715,
      -0.30005848101537064,
      0.018743328123273617,
    ],
    y: [
      0.3305857935965839,
      0.16722876972619305,
      -0.17776465616091405,
      -0.14511085301207174,
      -0.161195597677753,
      -0.013743456472038524,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      14.599920002677912,
      15.477682404783637,
      15.916884491678157,
      18.990030291663253,
      12.184996950636439,
      22.83048585856061,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    Freq: [
      9.0,
      18.0,
      8.0,
      7.0,
      7.0,
      11.0,
      7.0,
      7.0,
      6.0,
      7.0,
      7.000277432862935,
      7.000277432862935,
      2.0000792665322673,
      1.0000396332661337,
      2.0000792665322673,
      4.000158533064535,
      1.0000396332661337,
      2.0000792665322673,
      1.0000396332661337,
      1.0000396332661337,
      6.000237799596802,
      4.000158533064535,
      4.000158533064535,
      4.000158533064535,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      4.000158533064535,
      4.000158533064535,
      4.000158533064535,
      1.000011962465099,
      2.000023924930198,
      1.000011962465099,
      2.000023924930198,
      4.000047849860396,
      3.000035887395297,
      1.000011962465099,
      2.000023924930198,
      2.000023924930198,
      1.000011962465099,
      9.00010766218589,
      8.000095699720791,
      6.000071774790594,
      5.000059812325495,
      4.000047849860396,
      3.000035887395297,
      3.000035887395297,
      3.000035887395297,
      3.000035887395297,
      3.000035887395297,
      2.0000388650922485,
      1.0000194325461242,
      1.0000194325461242,
      1.0000194325461242,
      2.0000388650922485,
      3.0000582976383727,
      1.0000194325461242,
      2.0000388650922485,
      2.0000388650922485,
      1.0000194325461242,
      7.00013602782287,
      7.00013602782287,
      5.000097162730621,
      5.000097162730621,
      5.000097162730621,
      3.0000582976383727,
      3.0000582976383727,
      2.0000388650922485,
      2.0000388650922485,
      10.000194325461242,
      7.00013602782287,
      6.0001165952767455,
      0.9999952367459667,
      3.9999809469838667,
      0.9999952367459667,
      0.9999952367459667,
      0.9999952367459667,
      1.9999904734919334,
      1.9999904734919334,
      0.9999952367459667,
      0.9999952367459667,
      3.9999809469838667,
      6.999966657221767,
      4.999976183729833,
      3.9999809469838667,
      3.9999809469838667,
      3.9999809469838667,
      2.9999857102378997,
      2.9999857102378997,
      1.9999904734919334,
      1.9999904734919334,
      4.999976183729833,
      6.999966657221767,
      4.999976183729833,
      4.999976183729833,
      3.9999809469838667,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      2.0000958958612243,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      2.0000958958612243,
      6.000287687583674,
      5.000239739653061,
      5.000239739653061,
      4.0001917917224485,
      4.0001917917224485,
      4.0001917917224485,
      3.000143843791837,
      2.0000958958612243,
      2.0000958958612243,
      6.000287687583674,
      2.0000958958612243,
      2.0000958958612243,
      5.000239739653061,
      5.000239739653061,
      2.999794127473373,
      0.9999313758244576,
      1.9998627516489151,
      1.9998627516489151,
      0.9999313758244576,
      0.9999313758244576,
      1.9998627516489151,
      2.999794127473373,
      0.9999313758244576,
      1.9998627516489151,
      5.999588254946746,
      4.999656879122288,
      4.999656879122288,
      4.999656879122288,
      4.999656879122288,
      2.999794127473373,
      2.999794127473373,
      2.999794127473373,
      2.999794127473373,
      4.999656879122288,
      10.999245134069032,
      4.999656879122288,
      3.9997255032978303,
      4.999656879122288,
      4.999656879122288,
    ],
    Term: [
      "rjd",
      "biden",
      "dump",
      "bsf",
      "bus",
      "singh",
      "manmohan",
      "manmohan_singh",
      "ballot",
      "show",
      "bsf",
      "bus",
      "stretcher",
      "place",
      "hospital",
      "advisor",
      "trail",
      "political",
      "bus_accident",
      "alter",
      "bsf_jawans",
      "pictures_bus",
      "appoint",
      "jawans",
      "khan",
      "refuse",
      "hand",
      "shake",
      "jawan",
      "ahmed",
      "accident",
      "bihar",
      "biden",
      "school",
      "item",
      "temple",
      "ammunition",
      "dumped_rjd",
      "rjd_worker",
      "gujarat",
      "blast",
      "madrasa",
      "accuse",
      "rjd",
      "dump",
      "sweet",
      "office",
      "patna",
      "arm",
      "unrelated",
      "food",
      "worker",
      "bihar",
      "name",
      "vetrivel",
      "yatra",
      "morph",
      "music",
      "harris",
      "account",
      "policeman",
      "islamic",
      "parody",
      "manmohan_singh",
      "manmohan",
      "swearing_ceremony",
      "invite",
      "ceremony",
      "prophet",
      "swearing",
      "inauguration",
      "muhammad",
      "biden",
      "singh",
      "joe",
      "spain",
      "denmark",
      "recently",
      "abdoullakh",
      "legislation",
      "samuel_paty",
      "blame",
      "tampering",
      "cuddle",
      "teacher",
      "muslim",
      "funeral",
      "paty",
      "samuel",
      "protest",
      "law",
      "killed_french",
      "right",
      "make",
      "vote",
      "pass",
      "man",
      "french",
      "biden",
      "view",
      "russia",
      "arizona",
      "dmk",
      "unnao",
      "tejashwi",
      "pan",
      "unopened",
      "crore",
      "show_tejashwi",
      "ballot",
      "stuff",
      "ballot_stuffing",
      "yadav",
      "award",
      "young",
      "win",
      "receive",
      "politician",
      "show",
      "launch",
      "ek",
      "us",
      "election",
      "kapil",
      "minor",
      "next",
      "victim",
      "mock",
      "shivle",
      "endorse",
      "dev",
      "social",
      "shah",
      "rape",
      "minister",
      "giriraj_singh",
      "letter",
      "giriraj",
      "donald",
      "pm_modi",
      "cricketer",
      "donald_trump",
      "girl",
      "bihar",
      "modi",
      "year",
      "singh",
      "election",
    ],
    Total: [
      9.0,
      18.0,
      8.0,
      7.0,
      7.0,
      11.0,
      7.0,
      7.0,
      6.0,
      7.0,
      7.000277432862935,
      7.000277432862935,
      2.0000792665322673,
      1.0000396332661337,
      2.0000792665322673,
      4.000158533064535,
      1.0000396332661337,
      2.0000792665322673,
      1.0000396332661337,
      1.0000396332661337,
      6.000237799596802,
      4.000158533064535,
      4.000158533064535,
      4.000158533064535,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      3.000118899798401,
      6.0000212847134495,
      17.999439554528863,
      18.000333805509644,
      1.000011962465099,
      2.000023924930198,
      1.000011962465099,
      2.000023924930198,
      4.000047849860396,
      3.000035887395297,
      1.000011962465099,
      2.000023924930198,
      2.000023924930198,
      1.000011962465099,
      9.00010766218589,
      8.000095699720791,
      6.000071774790594,
      5.000059812325495,
      4.000047849860396,
      3.000035887395297,
      3.000035887395297,
      3.000035887395297,
      3.000035887395297,
      17.999439554528863,
      2.0000388650922485,
      1.0000194325461242,
      1.0000194325461242,
      1.0000194325461242,
      2.0000388650922485,
      3.0000582976383727,
      1.0000194325461242,
      2.0000388650922485,
      2.0000388650922485,
      1.0000194325461242,
      7.00013602782287,
      7.00013602782287,
      5.000097162730621,
      5.000097162730621,
      5.000097162730621,
      3.0000582976383727,
      3.0000582976383727,
      2.0000388650922485,
      2.0000388650922485,
      18.000333805509644,
      11.999792906945157,
      11.000137175526746,
      0.9999952367459667,
      3.9999809469838667,
      0.9999952367459667,
      0.9999952367459667,
      0.9999952367459667,
      1.9999904734919334,
      1.9999904734919334,
      0.9999952367459667,
      0.9999952367459667,
      3.9999809469838667,
      6.999966657221767,
      4.999976183729833,
      3.9999809469838667,
      3.9999809469838667,
      3.9999809469838667,
      2.9999857102378997,
      2.9999857102378997,
      1.9999904734919334,
      1.9999904734919334,
      5.999907559554291,
      8.999829408870683,
      6.000015816995967,
      7.000000108660031,
      18.000333805509644,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      2.0000958958612243,
      1.0000479479306121,
      1.0000479479306121,
      1.0000479479306121,
      2.0000958958612243,
      6.000287687583674,
      5.000239739653061,
      5.000239739653061,
      4.0001917917224485,
      4.0001917917224485,
      4.0001917917224485,
      3.000143843791837,
      2.0000958958612243,
      2.0000958958612243,
      7.000299650048772,
      2.0000958958612243,
      2.0000958958612243,
      11.000043773164492,
      13.99991101719748,
      2.999794127473373,
      0.9999313758244576,
      1.9998627516489151,
      1.9998627516489151,
      0.9999313758244576,
      0.9999313758244576,
      1.9998627516489151,
      2.999794127473373,
      0.9999313758244576,
      1.9998627516489151,
      5.999588254946746,
      4.999656879122288,
      4.999656879122288,
      4.999656879122288,
      4.999656879122288,
      2.999794127473373,
      2.999794127473373,
      2.999794127473373,
      2.999794127473373,
      5.999676311668412,
      17.999439554528863,
      6.999647352614221,
      4.999737465762929,
      11.999792906945157,
      13.99991101719748,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.9242,
      1.5187,
      0.4201,
      0.4201,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      1.8658,
      0.0741,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.8378,
      1.25,
      1.2988,
      1.2317,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.6613,
      1.4789,
      1.41,
      1.4789,
      1.3248,
      0.1572,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      2.105,
      1.9508,
      2.105,
      2.105,
      1.3166,
      1.0754,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.4771,
      1.2947,
      0.9846,
      1.1406,
      1.2539,
      0.6016,
      0.4474,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -2.9444,
      -2.9444,
      -4.1972,
      -4.8903,
      -4.1972,
      -3.5041,
      -4.8903,
      -4.1972,
      -4.8903,
      -4.8903,
      -3.0986,
      -3.5041,
      -3.5041,
      -3.5041,
      -3.7917,
      -3.7917,
      -3.7917,
      -3.7917,
      -3.7917,
      -3.7917,
      -3.5041,
      -3.5041,
      -3.5041,
      -4.9488,
      -4.2556,
      -4.9488,
      -4.2556,
      -3.5625,
      -3.8501,
      -4.9488,
      -4.2556,
      -4.2556,
      -4.9488,
      -2.7515,
      -2.8693,
      -3.157,
      -3.3393,
      -3.5625,
      -3.8501,
      -3.8501,
      -3.8501,
      -3.8501,
      -3.8501,
      -4.2836,
      -4.9767,
      -4.9767,
      -4.9767,
      -4.2836,
      -3.8781,
      -4.9767,
      -4.2836,
      -4.2836,
      -4.9767,
      -3.0308,
      -3.0308,
      -3.3673,
      -3.3673,
      -3.3673,
      -3.8781,
      -3.8781,
      -4.2836,
      -4.2836,
      -2.6741,
      -3.0308,
      -3.185,
      -5.1533,
      -3.767,
      -5.1533,
      -5.1533,
      -5.1533,
      -4.4601,
      -4.4601,
      -5.1533,
      -5.1533,
      -3.767,
      -3.2074,
      -3.5439,
      -3.767,
      -3.767,
      -3.767,
      -4.0547,
      -4.0547,
      -4.4601,
      -4.4601,
      -3.5439,
      -3.2074,
      -3.5439,
      -3.5439,
      -3.767,
      -4.7095,
      -4.7095,
      -4.7095,
      -4.7095,
      -4.7095,
      -4.0164,
      -4.7095,
      -4.7095,
      -4.7095,
      -4.0164,
      -2.9178,
      -3.1001,
      -3.1001,
      -3.3232,
      -3.3232,
      -3.3232,
      -3.6109,
      -4.0164,
      -4.0164,
      -2.9178,
      -4.0164,
      -4.0164,
      -3.1001,
      -3.1001,
      -4.2389,
      -5.3375,
      -4.6444,
      -4.6444,
      -5.3375,
      -5.3375,
      -4.6444,
      -4.2389,
      -5.3375,
      -4.6444,
      -3.5458,
      -3.7281,
      -3.7281,
      -3.7281,
      -3.7281,
      -4.2389,
      -4.2389,
      -4.2389,
      -4.2389,
      -3.7281,
      -2.9396,
      -3.7281,
      -3.9512,
      -3.7281,
      -3.7281,
    ],
  },
  "token.table": {
    Topic: [
      4,
      1,
      6,
      3,
      2,
      1,
      1,
      1,
      2,
      1,
      5,
      2,
      5,
      5,
      5,
      1,
      3,
      4,
      1,
      2,
      6,
      4,
      2,
      1,
      1,
      1,
      1,
      3,
      6,
      5,
      4,
      4,
      6,
      5,
      6,
      6,
      2,
      2,
      5,
      2,
      4,
      5,
      6,
      6,
      2,
      2,
      4,
      4,
      6,
      6,
      3,
      6,
      2,
      1,
      3,
      1,
      3,
      3,
      3,
      2,
      1,
      1,
      1,
      3,
      4,
      6,
      1,
      4,
      5,
      4,
      4,
      6,
      2,
      4,
      1,
      4,
      3,
      3,
      6,
      6,
      6,
      4,
      6,
      3,
      3,
      3,
      4,
      3,
      6,
      2,
      5,
      3,
      4,
      6,
      2,
      4,
      1,
      1,
      6,
      3,
      1,
      5,
      3,
      4,
      6,
      5,
      4,
      1,
      4,
      2,
      2,
      5,
      4,
      4,
      2,
      6,
      1,
      6,
      2,
      5,
      5,
      3,
      6,
      6,
      4,
      1,
      5,
      3,
      3,
      2,
      4,
      4,
      5,
      2,
      1,
      5,
      5,
      2,
      3,
      4,
      5,
      6,
      3,
      6,
      5,
      4,
      6,
      5,
      2,
      5,
      3,
      2,
      6,
      5,
    ],
    Freq: [
      1.000004763276722,
      0.6666643017068952,
      0.3333321508534476,
      0.9999805678314923,
      0.9999880376779999,
      0.9999603683045999,
      0.9999603683045998,
      0.9999603683045999,
      0.9999880376779999,
      0.9999603683045999,
      0.9999520543682817,
      0.9999880376779999,
      0.9999520543682817,
      0.9999520543682815,
      0.9999520543682816,
      0.22221810124297015,
      0.5555452531074253,
      0.22221810124297015,
      0.22222914151755102,
      0.16667185613816327,
      0.6111301391732653,
      1.000004763276722,
      0.9999880376779999,
      0.9999603683045999,
      0.9999603683045998,
      0.9999603683045999,
      0.9999603683045999,
      0.9999805678314923,
      1.000068628885143,
      0.9999520543682817,
      1.000004763276722,
      1.000004763276722,
      1.000068628885143,
      0.9999520543682817,
      1.000068628885143,
      1.000068628885143,
      0.9999880376779999,
      0.9999880376779999,
      0.9999520543682817,
      0.1428580508506948,
      0.1428580508506948,
      0.357145127126737,
      0.357145127126737,
      1.000068628885143,
      0.9999880376779999,
      0.28571428127918247,
      0.7142857031979561,
      1.000004763276722,
      1.000068628885143,
      1.000068628885143,
      0.16667565849430238,
      0.833378292471512,
      0.9999880376779999,
      0.9999603683045998,
      0.9999805678314923,
      0.9999603683045999,
      0.9999805678314923,
      0.9999805678314923,
      0.9999805678314923,
      0.9999880376779999,
      0.9999603683045998,
      0.9999603683045999,
      0.0909079572411891,
      0.5454477434471345,
      0.3636318289647564,
      1.000068628885143,
      0.9999603683045998,
      1.0000047632767222,
      0.9999520543682817,
      1.0000047632767222,
      1.000004763276722,
      1.000068628885143,
      0.9999880376779999,
      1.000004763276722,
      0.16666622730682581,
      0.833331136534129,
      0.9999805678314923,
      0.9999805678314923,
      1.000068628885143,
      1.000068628885143,
      1.000068628885143,
      0.2857286802103026,
      0.7143217005257565,
      0.9999805678314923,
      0.9999805678314923,
      0.9999805678314923,
      1.000004763276722,
      0.9999805678314923,
      1.000068628885143,
      0.9999880376779998,
      0.9999520543682817,
      0.9999805678314923,
      0.7777925205004941,
      0.2222264344287126,
      0.9999880376779999,
      1.000004763276722,
      0.9999603683045999,
      0.9999603683045999,
      1.000068628885143,
      0.9999805678314923,
      0.9999603683045999,
      0.9999520543682817,
      0.9999805678314923,
      1.000004763276722,
      1.000068628885143,
      0.9999520543682817,
      1.000004763276722,
      0.9999603683045998,
      1.000004763276722,
      0.9999880376779999,
      0.9999880376779999,
      0.9999520543682817,
      1.000004763276722,
      1.000004763276722,
      0.9999880376779999,
      1.000068628885143,
      0.9999603683045998,
      1.000068628885143,
      0.1428510278117927,
      0.8571061668707564,
      0.9999520543682817,
      0.5833434005305699,
      0.4166738575218356,
      1.000068628885143,
      1.000004763276722,
      0.9999603683045999,
      0.9999520543682816,
      0.9999805678314923,
      0.9999805678314923,
      0.9999880376779999,
      1.000004763276722,
      1.000004763276722,
      0.9999520543682817,
      0.9999880376779999,
      0.9999603683045999,
      0.9999520543682817,
      0.9999520543682817,
      0.9999880376779999,
      0.09090872914884048,
      0.18181745829768095,
      0.45454364574420236,
      0.27272618744652144,
      0.9999805678314923,
      1.000068628885143,
      0.9999520543682817,
      0.8333461724819357,
      0.16666923449638713,
      0.9999520543682815,
      0.9999880376779999,
      0.9999520543682817,
      0.9999805678314923,
      0.20001050192090558,
      0.8000420076836223,
      0.9999520543682817,
    ],
    Term: [
      "abdoullakh",
      "accident",
      "accident",
      "account",
      "accuse",
      "advisor",
      "ahmed",
      "alter",
      "ammunition",
      "appoint",
      "arizona",
      "arm",
      "award",
      "ballot",
      "ballot_stuffing",
      "biden",
      "biden",
      "biden",
      "bihar",
      "bihar",
      "bihar",
      "blame",
      "blast",
      "bsf",
      "bsf_jawans",
      "bus",
      "bus_accident",
      "ceremony",
      "cricketer",
      "crore",
      "cuddle",
      "denmark",
      "dev",
      "dmk",
      "donald",
      "donald_trump",
      "dump",
      "dumped_rjd",
      "ek",
      "election",
      "election",
      "election",
      "election",
      "endorse",
      "food",
      "french",
      "french",
      "funeral",
      "giriraj",
      "giriraj_singh",
      "girl",
      "girl",
      "gujarat",
      "hand",
      "harris",
      "hospital",
      "inauguration",
      "invite",
      "islamic",
      "item",
      "jawan",
      "jawans",
      "joe",
      "joe",
      "joe",
      "kapil",
      "khan",
      "killed_french",
      "launch",
      "law",
      "legislation",
      "letter",
      "madrasa",
      "make",
      "man",
      "man",
      "manmohan",
      "manmohan_singh",
      "minister",
      "minor",
      "mock",
      "modi",
      "modi",
      "morph",
      "muhammad",
      "music",
      "muslim",
      "name",
      "next",
      "office",
      "pan",
      "parody",
      "pass",
      "pass",
      "patna",
      "paty",
      "pictures_bus",
      "place",
      "pm_modi",
      "policeman",
      "political",
      "politician",
      "prophet",
      "protest",
      "rape",
      "receive",
      "recently",
      "refuse",
      "right",
      "rjd",
      "rjd_worker",
      "russia",
      "samuel",
      "samuel_paty",
      "school",
      "shah",
      "shake",
      "shivle",
      "show",
      "show",
      "show_tejashwi",
      "singh",
      "singh",
      "social",
      "spain",
      "stretcher",
      "stuff",
      "swearing",
      "swearing_ceremony",
      "sweet",
      "tampering",
      "teacher",
      "tejashwi",
      "temple",
      "trail",
      "unnao",
      "unopened",
      "unrelated",
      "us",
      "us",
      "us",
      "us",
      "vetrivel",
      "victim",
      "view",
      "vote",
      "vote",
      "win",
      "worker",
      "yadav",
      "yatra",
      "year",
      "year",
      "young",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fake-news/did-recep-erdogan-refuse-to-shake-emmanuel-macrons-hand-a-fact-check-10609",
        headline:
          "Did Recep Erdogan Refuse To Shake Emmanuel Macron's Hand? A Fact Check",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/images-of-bsf-personnel-bus-accident-viral-with-fake-claim-10608",
        headline:
          "\u092c\u0940\u090f\u0938\u090f\u095e \u091c\u0935\u093e\u0928\u094b\u0902 \u0915\u0940 \u092c\u0938 \u092a\u0932\u091f\u0928\u0947 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://factly.in/no-bsf-jawan-died-in-the-bus-accident-at-darbhanga-city-in-bihar/",
        headline:
          " No BSF Jawan died in the bus accident at Darbhanga in Bihar",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/images-of-bus-accident-involving-bsf-personnel-viral-with-fake-claims-10592",
        headline:
          "\u09ac\u09bf\u098f\u09b8\u098f\u09ab \u099c\u0993\u09df\u09be\u09a8 \u09b8\u09ae\u09c7\u09a4 \u098f\u0995\u099f\u09bf \u09ac\u09be\u09b8 \u09a6\u09c1\u09b0\u09cd\u0998\u099f\u09a8\u09be\u09b0 \u099b\u09ac\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/11/fact-check-has-mukesh-ambani-been-diagnosed-with-pancreatic-cancer-heres-the-truth/",
        headline:
          "Fact Check: Has Mukesh Ambani been diagnosed with pancreatic cancer? Here\u2019s the truth",
      },
      {
        url:
          "https://www.altnews.in/hindi/congress-shared-old-video-of-boy-pushing-stretcher-in-up-hospital-as-bihar/",
        headline:
          "UP \u0915\u0947 \u0905\u0938\u094d\u092a\u0924\u093e\u0932 \u092e\u0947\u0902 \u0938\u094d\u091f\u094d\u0930\u0947\u091a\u0930 \u0915\u094b \u0927\u0915\u094d\u0915\u093e \u0926\u0947 \u0930\u0939\u0947 \u092c\u091a\u094d\u091a\u0947 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 \u092c\u093f\u0939\u093e\u0930 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/congress-shared-old-video-of-boy-pushing-stretcher-in-up-hospital-as-bihar/",
        headline:
          "Congress shares video of child pushing father\u2019s stretcher in UP hospital as Bihar",
      },
      {
        url:
          "https://www.altnews.in/the-bus-filled-with-bsf-jawans-was-definitely-overturned-in-bihar-but-the-news-of-martyrdom-of-9-soldiers-was-wrong/",
        headline:
          "Bus with BSF jawans overturned in Bihar but claims of 9 soldiers dying is false",
      },
      {
        url:
          "https://factly.in/2018-image-is-falsely-shared-as-turkey-president-refusing-to-shake-hands-with-macron/",
        headline:
          " 2018 image is falsely shared as Turkey President refusing to shake hands with Macron",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/posts-claiming-indian-ahmed-khan-appointed-advisor-to-biden-are-false-10662",
        headline:
          "Posts Claiming Indian Ahmed Khan Appointed Advisor To Biden Are False",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/post-claims-indian-ahmad-khan-has-been-appointed-as-joe-bidens-political-advisor-10667",
        headline:
          "\u0915\u094d\u092f\u093e \u092d\u093e\u0930\u0924 \u0915\u0947 \u0905\u0939\u092e\u0926 \u0916\u093e\u0928 \u0915\u094b \u092c\u093e\u0907\u0921\u0947\u0928 \u0915\u093e \u0930\u093e\u091c\u0928\u0940\u0924\u093f\u0915 \u0938\u0932\u093e\u0939\u0915\u093e\u0930 \u0928\u093f\u092f\u0941\u0915\u094d\u0924 \u0915\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948?",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/joe-biden-has-not-appointed-indian-origin-man-political-advisor-1740819-2020-11-13",
        headline:
          "Fact Check: No, Joe Biden has not appointed an Indian-origin man as political advisor\u00a0",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/11/fact-check-has-mukesh-ambani-been-diagnosed-with-pancreatic-cancer-heres-the-truth/",
        headline:
          "Fact Check: Has Mukesh Ambani been diagnosed with pancreatic cancer? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/posts-claiming-indian-ahmed-khan-appointed-advisor-to-biden-are-false-10662",
        headline:
          "Posts Claiming Indian Ahmed Khan Appointed Advisor To Biden Are False",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/covid-19-vaccine-to-alter-dna-5-false-claims-by-christiane-northrup-10651",
        headline:
          "COVID-19 Vaccine To Alter DNA? 5 False Claims By Christiane Northrup",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/did-recep-erdogan-refuse-to-shake-emmanuel-macrons-hand-a-fact-check-10609",
        headline:
          "Did Recep Erdogan Refuse To Shake Emmanuel Macron's Hand? A Fact Check",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/images-of-bus-accident-involving-bsf-personnel-viral-with-fake-claims-10592",
        headline:
          "\u09ac\u09bf\u098f\u09b8\u098f\u09ab \u099c\u0993\u09df\u09be\u09a8 \u09b8\u09ae\u09c7\u09a4 \u098f\u0995\u099f\u09bf \u09ac\u09be\u09b8 \u09a6\u09c1\u09b0\u09cd\u0998\u099f\u09a8\u09be\u09b0 \u099b\u09ac\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/post-claims-indian-ahmad-khan-has-been-appointed-as-joe-bidens-political-advisor-10667",
        headline: "             ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/images-of-bsf-personnel-bus-accident-viral-with-fake-claim-10608",
        headline:
          "\u092c\u0940\u090f\u0938\u090f\u095e \u091c\u0935\u093e\u0928\u094b\u0902 \u0915\u0940 \u092c\u0938 \u092a\u0932\u091f\u0928\u0947 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/yes-human-trails-of-sputnik-vaccine-will-take-place-in-india-670307",
        headline:
          "Yes, Human trails of Sputnik Vaccine will take place in India",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-9-bsf-jawans-were-not-killed-in-bus-accident-in-bihars-darbhanga-670264",
        headline:
          "Fact Check: 9 BSF jawans were not killed in bus accident in Bihar's Darbhanga",
      },
    ],
    "2": [
      {
        url:
          "http://newsmobile.in/articles/2020/11/09/fact-check-no-saudi-arabia-did-not-dump-french-products-in-a-desert-heres-the-truth/",
        headline:
          "Fact Check: No, Saudi Arabia did not dump French products in a desert; here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/this-video-of-youth-assaulting-a-temple-priest-is-from-haryana-not-from-gujarat/",
        headline:
          " This video of youngsters  assaulting a temple priest is from Haryana, not from Gujarat",
      },
      {
        url:
          "https://www.altnews.in/unrelated-photos-viral-with-image-of-accused-arrested-in-illegal-arms-racket-in-up-madrasa-last-year/",
        headline:
          "Unrelated photos viral with image of accused arrested in illegal arms racket in UP madrasa last year",
      },
      {
        url:
          "https://factly.in/mha-official-vishwajit-mukherjees-message-against-chinese-firecrackers-old-fake-message-revived/",
        headline:
          " MHA official Vishwajit Mukherjee\u2019s message against Chinese firecrackers? Old fake message revived",
      },
      {
        url:
          "https://factly.in/unrelated-image-from-haryana-shared-as-rjd-workers-dumping-sweets-after-the-election-loss-in-bihar/",
        headline:
          " Unrelated image from Haryana shared as RJD workers dumping sweets after the election loss in Bihar",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-image-from-haryana-shared-as-rjd-workers-dumping-sweets-after-the-election-loss-in-bihar/",
        headline:
          " \u0c39\u0c30\u0c4d\u0c2f\u0c3e\u0c28\u0c3e\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c2b\u0c41\u0c21\u0c4d \u0c38\u0c47\u0c2b\u0c4d\u0c1f\u0c40 \u0c24\u0c28\u0c3f\u0c16\u0c40\u0c32\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f RJD \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40\u0c15\u0c3f \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c42 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/11/fact-check-no-an-assam-cong-leader-has-not-been-detained-with-arms-and-ammunition-heres-the-truth/",
        headline:
          "Fact Check: No, an Assam Cong leader has not been detained with arms and ammunition; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/13/fact-check-pictures-from-sirsa-gwalior-falsely-shared-as-food-items-dumped-at-rjd-office-in-patna/",
        headline:
          "Fact Check: Pictures from Sirsa, Gwalior falsely shared as food items dumped at RJD office in Patna",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-cylinder-explosion-in-roorkee-goes-viral-with-misleading-claim-1740542-2020-11-12",
        headline:
          "Fact Check: Cylinder explosion in Roorkee goes viral with misleading claim",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-unrelated-photos-viral-linking-to-recent-school-blast-peshawar-1740214-2020-11-12",
        headline:
          "Fact Check: Unrelated photos go viral linking them to recent school blast in Peshawar",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-were-sweets-dumped-in-the-rjd-office-after-bihar-poll-verdict-1740522-2020-11-12",
        headline:
          "Fact Check: Were sweets dumped in the RJD office after Bihar poll verdict?",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/09/fact-check-no-saudi-arabia-did-not-dump-french-products-in-a-desert-heres-the-truth/",
        headline:
          "Fact Check: No, Saudi Arabia did not dump French products in a desert; here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/11/fact-check-no-an-assam-cong-leader-has-not-been-detained-with-arms-and-ammunition-heres-the-truth/",
        headline:
          "Fact Check: No, an Assam Cong leader has not been detained with arms and ammunition; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/13/fact-check-pictures-from-sirsa-gwalior-falsely-shared-as-food-items-dumped-at-rjd-office-in-patna/",
        headline:
          "Fact Check: Pictures from Sirsa, Gwalior falsely shared as food items dumped at RJD office in Patna",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-these-pics-dont-show-sweets-being-dumped-by-rjds-patna-office-10648",
        headline:
          "\u098f\u0997\u09c1\u09b2\u09bf \u0986\u09b0\u099c\u09c7\u09a1\u09bf'\u09b0 \u09aa\u09be\u099f\u09a8\u09be \u0995\u09be\u09b0\u09cd\u09af\u09be\u09b2\u09df\u09c7 \u09ae\u09bf\u09b7\u09cd\u099f\u09bf \u09ab\u09c7\u09b2\u09c7 \u09a6\u09c7\u0993\u09df\u09be\u09b0 \u099b\u09ac\u09bf \u09a8\u09df",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-these-pics-dont-show-sweets-being-dumped-by-rjds-patna-office-10626",
        headline:
          "No, These Pics Don't Show Sweets Being Dumped By RJD's Patna Office",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/no-these-pics-dont-show-sweets-being-dumped-by-rjds-patna-office-10636",
        headline:
          "\u091c\u0940 \u0928\u0939\u0940\u0902, \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u092e\u093f\u0920\u093e\u0908 \u092b\u0947\u0902\u0915\u0924\u0947 \u0926\u093f\u0916 \u0930\u0939\u0947 \u0932\u094b\u0917 \u0930\u093e\u091c\u0926 \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/unrelated-photos-viral-as-pencils-in-blood-and-injured-girl-in-recent-peshawar-madarsa-attack-in-pakistan-10630",
        headline:
          "\u092f\u0947 \u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u092a\u0947\u0936\u093e\u0935\u0930 \u0915\u0947 \u092e\u0926\u0930\u0938\u0947 \u092e\u0947\u0902 \u0939\u093e\u0932 \u092e\u0947\u0902 \u0939\u0941\u090f \u0935\u093f\u0938\u094d\u095e\u094b\u091f \u0938\u0947 \u0938\u092e\u094d\u092c\u0902\u0927\u093f\u0924 \u0928\u0939\u0940\u0902 \u0939\u0948\u0902",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/viral-video-claims-sadhus-preparing-to-travel-to-mumbai-10620",
        headline:
          "2019 \u0915\u0941\u0902\u092d \u092e\u0947\u0902 \u0936\u093e\u0939\u0940 \u0938\u094d\u0928\u093e\u0928 \u0915\u0947 \u0932\u093f\u090f \u091c\u093e\u0924\u0947 \u0938\u093e\u0927\u0941\u0913\u0902 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0941\u0902\u092c\u0908 \u0915\u0942\u091a \u092c\u0924\u093e\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-rjd-workers-did-not-dump-sweets-after-their-defeat-in-bihar-election-670419",
        headline:
          "Fact Check: No, RJD workers did not dump sweets after their defeat in Bihar election",
      },
    ],
    "3": [
      {
        url:
          "https://factly.in/the-tweet-about-indian-media-by-joe-biden-is-from-a-parody-account/",
        headline:
          " The tweet about Indian Media by Joe Biden is from a parody account",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/09/fact-check-video-of-policeman-assaulting-a-woman-is-not-from-france-heres-the-truth/",
        headline:
          "Fact Check: Video of policeman assaulting a woman is NOT from France; here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/sachin-tendulkar-hasnt-said-that-prophet-muhammed-is-the-greatest-person-ever/",
        headline:
          " Sachin Tendulkar hasn\u2019t said that Prophet Muhammed is the greatest person ever.",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/islamic-verses-on-prophet-muhammad-misleadingly-linked-to-kazi-nazrul-islam-10594",
        headline:
          "\u09a8\u09ac\u09c0 \u09ae\u09b9\u09ae\u09cd\u09ae\u09a6\u09c7\u09b0 \u09a8\u09be\u09ae\u09c7 \u09a4\u09c8\u09b0\u09bf \u0987\u09b8\u09b2\u09be\u09ae\u09bf \u09b8\u0999\u09cd\u0997\u09c0\u09a4 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09be\u099c\u09c0 \u09a8\u099c\u09b0\u09c1\u09b2 \u0987\u09b8\u09b2\u09be\u09ae\u09c7\u09b0 \u09b2\u09c7\u0996\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/claims-of-manmohan-singh-being-invited-for-biden-inauguration-are-false-10614",
        headline:
          "\u099c\u09cb \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u09b6\u09aa\u09a5 \u0985\u09a8\u09c1\u09b7\u09cd\u09a0\u09be\u09a8\u09c7 \u0986\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09bf\u09a4 \u09b9\u09df\u09c7\u099b\u09c7\u09a8 \u09ae\u09a8\u09ae\u09cb\u09b9\u09a8 \u09b8\u09bf\u0982\u09b9 \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/10/fact-check-pak-pms-urdu-tweet-congratulating-biden-and-harris-viral-with-misleading-translation/",
        headline:
          "Fact Check: Pak PM\u2019s Urdu tweet congratulating Biden and Harris viral with misleading translation",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/claims-of-manmohan-singh-being-invited-for-biden-inauguration-are-false-10613",
        headline:
          "Claims Of Manmohan Singh Being Invited For Biden Inauguration Are False",
      },
      {
        url:
          "https://www.altnews.in/fact-check-did-former-pm-manmohan-singh-get-invitation-of-joe-biden-oath-ceremony/",
        headline:
          "Fact-check: Has Dr Manmohan Singh been invited to Joe Biden\u2019s swearing-in ceremony?",
      },
      {
        url:
          "https://factly.in/telugu-this-image-is-not-from-the-vetri-vel-yatra-being-organized-by-bjp-in-tamil-nadu/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c24\u0c2e\u0c3f\u0c33\u0c28\u0c3e\u0c21\u0c41 \u0c32\u0c4b \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40 \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c46\u0c1f\u0c4d\u0c30\u0c3f\u0c35\u0c47\u0c32\u0c4d\u200c \u0c2f\u0c3e\u0c24\u0c4d\u0c30\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://digiteye.in/no-joe-biden-is-not-sniffing-small-girls-as-alleged-in-this-viral-video/",
        headline:
          "No, Joe Biden is not \u2018sniffing small girls\u2019 as alleged in this viral video",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-former-pm-manmohan-singh-is-not-a-chief-guest-at-joe-biden-swearing-in-ceremony-1739511-2020-11-09",
        headline:
          "Fact Check: No, former PM Manmohan Singh is not a chief guest at Joe Biden's swearing-in ceremony",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/09/fact-check-video-of-policeman-assaulting-a-woman-is-not-from-france-heres-the-truth/",
        headline:
          "Fact Check: Video of policeman assaulting a woman is NOT from France; here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/10/fact-check-pak-pms-urdu-tweet-congratulating-biden-and-harris-viral-with-misleading-translation/",
        headline:
          "Fact Check: Pak PM\u2019s Urdu tweet congratulating Biden and Harris viral with misleading translation",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/claims-of-manmohan-singh-being-invited-for-biden-inauguration-are-false-10613",
        headline:
          "Claims Of Manmohan Singh Being Invited For Biden Inauguration Are False",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/islamic-verses-on-prophet-muhammad-misleadingly-linked-to-kazi-nazrul-islam-10594",
        headline:
          "\u09a8\u09ac\u09c0 \u09ae\u09b9\u09ae\u09cd\u09ae\u09a6\u09c7\u09b0 \u09a8\u09be\u09ae\u09c7 \u09a4\u09c8\u09b0\u09bf \u0987\u09b8\u09b2\u09be\u09ae\u09bf \u09b8\u0999\u09cd\u0997\u09c0\u09a4 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09be\u099c\u09c0 \u09a8\u099c\u09b0\u09c1\u09b2 \u0987\u09b8\u09b2\u09be\u09ae\u09c7\u09b0 \u09b2\u09c7\u0996\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/claims-of-manmohan-singh-being-invited-for-biden-inauguration-are-false-10614",
        headline:
          "\u099c\u09cb \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u09b6\u09aa\u09a5 \u0985\u09a8\u09c1\u09b7\u09cd\u09a0\u09be\u09a8\u09c7 \u0986\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09bf\u09a4 \u09b9\u09df\u09c7\u099b\u09c7\u09a8 \u09ae\u09a8\u09ae\u09cb\u09b9\u09a8 \u09b8\u09bf\u0982\u09b9 \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/post-claims-manmohan-singh-to-be-chief-guest-at-us-president-joe-bidens-swearing-in-ceremony-10645",
        headline:
          "\u0915\u094d\u092f\u093e \u0905\u092e\u0930\u0940\u0915\u0940 \u0930\u093e\u0937\u094d\u091f\u094d\u0930\u092a\u0924\u093f \u0915\u0947 \u0936\u092a\u0925 \u0917\u094d\u0930\u0939\u0923 \u0938\u092e\u093e\u0930\u094b\u0939 \u092e\u0947\u0902 \u092e\u0928\u092e\u094b\u0939\u0928 \u0938\u093f\u0902\u0939 \u092e\u0941\u0916\u094d\u092f \u0905\u0924\u093f\u0925\u093f \u0939\u094b\u0902\u0917\u0947?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-saree-clad-kamala-harris-is-morphed-670377",
        headline:
          "Fact Check: Viral image of saree-clad Kamala Harris is morphed",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-driver-in-viral-video-was-not-shot-by-angry-passenger-in-us-670285",
        headline:
          "Fact Check: No. driver in viral video was not shot by angry passenger in US",
      },
    ],
    "4": [
      {
        url:
          "http://newsmobile.in/articles/2020/11/09/fact-check-old-picture-of-us-president-elect-joe-biden-without-mask-shared-as-recent-one/",
        headline:
          "Fact Check: Old picture of US President-elect Joe Biden without mask shared as recent one",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-video-shared-as-france-police-attack-on-islamic-extremists/",
        headline:
          " \u0c2c\u0c3e\u0c30\u0c4d\u0c38\u0c3f\u0c32\u0c4b\u0c28\u0c3e \u0c2a\u0c4d\u0c30\u0c1c\u0c32\u0c41 \u0c32\u0c3e\u0c15\u0c4d \u0c21\u0c4c\u0c28\u0c4d \u0c28\u0c3f \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c1a\u0c47\u0c38\u0c3f\u0c28 \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c28\u0c3f, \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c05\u0c24\u0c3f\u0c35\u0c3e\u0c26 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c41\u0c32\u0c2a\u0c48 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c26\u0c3e\u0c21\u0c41\u0c32\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/joe-bidens-tweet-accusing-narendra-modi-of-campaigning-for-donald-trump-is-fake-10610",
        headline:
          "\u099f\u09cd\u09b0\u09be\u09ae\u09cd\u09aa\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09aa\u09cd\u09b0\u099a\u09be\u09b0\u09c7 \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0\u0995\u09c7 \u09a6\u09be\u09df\u09c0 \u0995\u09b0\u09be \u099c\u09cb \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u099f\u09c1\u0987\u099f \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://factly.in/telugu-protest-video-from-bangladesh-shared-as-a-massive-protest-by-muslims-in-west-bengal/",
        headline:
          " \u0c2c\u0c02\u0c17\u0c4d\u0c32\u0c3e\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c15\u0c4b\u0c32\u0c4d\u200c\u0c15\u0c24\u0c3e\u0c32\u0c4b \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32 \u0c28\u0c3f\u0c30\u0c38\u0c28 \u0c2a\u0c4d\u0c30\u0c26\u0c30\u0c4d\u0c36\u0c28\u0c32\u0c41 \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/denmark-did-not-pass-legislation-barring-muslims-from-voting-in-elections/",
        headline:
          " Denmark did not pass legislation barring Muslims from voting in elections.",
      },
      {
        url:
          "https://factly.in/old-videos-of-muslim-prayers-in-streets-of-france-are-being-passed-off-as-recent-ones/",
        headline:
          " Old videos of Muslim Prayers in streets of France are being passed off as recent ones.",
      },
      {
        url:
          "https://factly.in/telugu-periodic-labour-force-survey-did-not-mention-that-unemployment-rate-is-33-9-in-telangana/",
        headline:
          " \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c41\u0c26\u0c4d\u0c2f\u0c4b\u0c17\u0c24 33.9% \u0c05\u0c28\u0c3f \u0c2a\u0c40\u0c30\u0c3f\u0c2f\u0c3e\u0c21\u0c3f\u0c15\u0c4d \u0c32\u0c47\u0c2c\u0c30\u0c4d \u0c2b\u0c4b\u0c30\u0c4d\u0c38\u0c41 \u0c38\u0c30\u0c4d\u0c35\u0c47 \u0c30\u0c3f\u0c2a\u0c4b\u0c30\u0c4d\u0c1f\u0c4d \u0c24\u0c46\u0c32\u0c41\u0c2a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://www.altnews.in/hindi/false-claim-of-denmark-passed-a-low-banning-voting-from-muslims-is-viral/",
        headline:
          "\u0921\u0947\u0928\u092e\u093e\u0930\u094d\u0915 \u0928\u0947 \u092e\u0941\u0938\u094d\u0932\u093f\u092e \u0938\u092e\u0941\u0926\u093e\u092f \u0938\u0947 \u0935\u094b\u091f \u0926\u0947\u0928\u0947 \u0915\u093e \u0905\u0927\u093f\u0915\u093e\u0930 \u091b\u0940\u0928\u0928\u0947 \u0915\u093e \u0915\u093e\u0928\u0942\u0928 \u0928\u0939\u0940\u0902 \u092c\u0928\u093e\u092f\u093e, \u095a\u0932\u0924 \u0926\u093e\u0935\u093e \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/false-claim-of-denmark-passed-a-low-banning-voting-from-muslims-is-viral/",
        headline:
          "No, Denmark hasn\u2019t passed law making its Muslim population ineligible to vote",
      },
      {
        url:
          "https://factly.in/telugu-2017-protest-video-against-muslims-street-prayers-shared-as-recent/",
        headline:
          " 2017 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c32\u0c4b \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02\u0c32 \u0c2a\u0c4d\u0c30\u0c3e\u0c30\u0c4d\u0c27\u0c28\u0c32\u0c15\u0c41 \u0c35\u0c4d\u0c2f\u0c24\u0c3f\u0c30\u0c47\u0c15\u0c02\u0c17\u0c3e \u0c28\u0c3f\u0c30\u0c38\u0c28\u0c32\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-2018-video-shared-as-the-last-rites-of-the-chechen-muslim-who-recently-beheaded-a-french-teacher/",
        headline:
          " 2018 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c2b\u0c4d\u0c30\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c1f\u0c40\u0c1a\u0c30\u0c4d \u0c28\u0c3f \u0c39\u0c24\u0c2e\u0c3e\u0c30\u0c4d\u0c1a\u0c3f\u0c28 \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c02 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c05\u0c02\u0c24\u0c4d\u0c2f\u0c15\u0c4d\u0c30\u0c3f\u0c2f\u0c32\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/less-vote-share-but-more-seats-won-cannot-be-considered-as-a-proof-of-evm-tampering/",
        headline:
          " \u2018Less vote share but more seats won\u2019 -cannot be considered as a proof of EVM tampering",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/13/fact-check-no-this-is-not-the-funeral-of-the-man-who-killed-french-teacher-samuel-paty-heres-the-truth/",
        headline:
          "Fact Check: No, this is NOT the funeral of the man who killed French teacher Samuel Paty; Here\u2019s the truth",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-the-funeral-procession-of-man-who-beheaded-french-teacher-samuel-paty-1739851-2020-11-10",
        headline:
          "Fact Check: This is not the funeral procession of man who beheaded French teacher Samuel Paty",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/09/fact-check-old-picture-of-us-president-elect-joe-biden-without-mask-shared-as-recent-one/",
        headline:
          "Fact Check: Old picture of US President-elect Joe Biden without mask shared as recent one",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/13/fact-check-no-this-is-not-the-funeral-of-the-man-who-killed-french-teacher-samuel-paty-heres-the-truth/",
        headline:
          "Fact Check: No, this is NOT the funeral of the man who killed French teacher Samuel Paty; Here\u2019s the truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/joe-bidens-tweet-accusing-narendra-modi-of-campaigning-for-donald-trump-is-fake-10610",
        headline:
          "\u099f\u09cd\u09b0\u09be\u09ae\u09cd\u09aa\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09aa\u09cd\u09b0\u099a\u09be\u09b0\u09c7 \u09a8\u09b0\u09c7\u09a8\u09cd\u09a6\u09cd\u09b0 \u09ae\u09cb\u09a6\u09c0\u0995\u09c7 \u09a6\u09be\u09df\u09c0 \u0995\u09b0\u09be \u099c\u09cb \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u099f\u09c1\u0987\u099f \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-is-not-the-funeral-of-samuel-patys-killer-abdoullakh-anzorov-10640",
        headline:
          "No, This Is Not The Funeral Of Samuel Paty's Killer Abdoullakh Anzorov",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-of-protests-in-spain-over-covid-19-restrictions-peddled-as-france-10607",
        headline:
          "Video Of Protests In Spain Over COVID-19 Restrictions Peddled As France",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-denmark-has-not-passed-a-law-banning-muslims-from-voting-10585",
        headline:
          "\u09a8\u09be, \u09ae\u09c1\u09b8\u09b2\u09ae\u09be\u09a8\u09a6\u09c7\u09b0 \u09ad\u09cb\u099f\u09be\u09a7\u09bf\u0995\u09be\u09b0 \u0995\u09c7\u09dc\u09c7 \u09a8\u09bf\u09a4\u09c7 \u0995\u09cb\u09a8\u0993 \u0986\u0987\u09a8 \u09aa\u09be\u09b6 \u0995\u09b0\u09c7\u09a8\u09bf \u09a1\u09c7\u09a8\u09ae\u09be\u09b0\u09cd\u0995",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/rohit-sharmas-picture-not-shown-on-burj-khalifa-after-winning-ipl-10663",
        headline: "             ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-brawl-in-brazil-passed-off-as-altercation-in-france-670366",
        headline:
          "Fact Check: Video of brawl in Brazil passed off as altercation in France",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/vote-rigging-in-2018-russian-elections-passed-off-as-poll-fraud-in-us-670194",
        headline:
          "FACT CHECK: Vote rigging in 2018 Russian elections passed off as poll fraud in US",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-several-clips-from-china-passed-off-as-video-from-kailash-mansarovar-670344",
        headline:
          "Fact Check: Several clips from China passed off as video from Kailash Mansarovar",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/video-of-leopards-cuddling-beside-a-man-is-from-south-africa-not-pipaleshvar-mandir-670200",
        headline:
          "Fact check: Video of leopards cuddling beside a man is from South Africa, not Pipaleshvar Mandir",
      },
    ],
    "5": [
      {
        url:
          "http://newsmobile.in/articles/2020/11/09/fact-check-this-video-of-ballot-stuffing-is-not-from-us-elections-2020-heres-the-truth/",
        headline:
          "Fact Check: This video of \u2018ballot stuffing\u2019 is NOT from US elections 2020; here\u2019s the truth",
      },
      {
        url:
          "https://factly.in/unnao-district-administration-in-up-has-launched-a-barter-scheme-of-trading-stubble-for-biofertilizer/",
        headline:
          " Unnao district administration in UP has launched a barter scheme of trading stubble for biofertilizer",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/bihar-polls-no-tejashwi-yadav-did-not-receive-yuva-neta-award-in-london-10619",
        headline:
          "\u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0924\u0947\u091c\u0936\u094d\u0935\u0940 \u092f\u093e\u0926\u0935 \u0915\u094b '\u0938\u092c\u0938\u0947 \u092f\u0941\u0935\u093e \u0928\u0947\u0924\u093e' \u092a\u0941\u0930\u0938\u094d\u0915\u093e\u0930 \u091c\u0940\u0924\u0924\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://factly.in/telugu-1000-crore-rupees-were-not-seized-during-the-recent-it-raids-conducted-in-dmk-leader-house/",
        headline:
          " DMK \u0c28\u0c47\u0c24 \u0c07\u0c02\u0c1f\u0c4d\u0c32\u0c4b \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 IT \u0c26\u0c3e\u0c21\u0c41\u0c32\u0c4d\u0c32\u0c4b 1000 \u0c15\u0c4b\u0c1f\u0c4d\u0c32\u0c41 \u0c1c\u0c2a\u0c4d\u0c24\u0c41 \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2018-video-from-russia-viral-as-stuffing-of-ballots-in-us-elections-10596",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u092f\u0942\u090f\u0938 \u0907\u0932\u0947\u0915\u094d\u0936\u0928 \u092e\u0947\u0902 '\u092c\u0948\u0932\u091f \u0938\u094d\u091f\u095e\u093f\u0902\u0917' \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948",
      },
      {
        url:
          "https://factly.in/there-is-no-such-scheme-called-ek-parivar-ek-naukari-providing-one-job-to-one-family-pan-india/",
        headline:
          " There is no such scheme called \u2018Ek Parivar Ek Naukri\u2019 providing one job to every family pan India",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-these-pics-dont-show-tejashwi-yadav-receiving-a-youngest-politician-award-10616",
        headline:
          "No, These Pics Don't Show Tejashwi Yadav Receiving A 'Youngest Politician' Award",
      },
      {
        url:
          "https://www.altnews.in/old-video-from-russsia-shared-with-false-claim-as-ballot-stuffing-in-us-presidential-election/",
        headline:
          "Old video from Russia falsely shared as ballot stuffing during 2020 US elections",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/09/fact-check-this-video-of-ballot-stuffing-is-not-from-us-elections-2020-heres-the-truth/",
        headline:
          "Fact Check: This video of \u2018ballot stuffing\u2019 is NOT from US elections 2020; here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-these-pics-dont-show-tejashwi-yadav-receiving-a-youngest-politician-award-10616",
        headline:
          "No, These Pics Don't Show Tejashwi Yadav Receiving A 'Youngest Politician' Award",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/rohit-sharmas-image-was-not-displayed-on-burj-khalifa-after-mis-ipl-win-10653",
        headline:
          "Rohit Sharma's Image Was Not Displayed On Burj Khalifa After MI's IPL Win",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/old-video-of-crowded-platform-resurfaces-after-trains-resumed-in-west-bengal-10638",
        headline:
          "\u09aa\u09b6\u09cd\u099a\u09bf\u09ae\u09ac\u0999\u09cd\u0997\u09c7 \u09b2\u09cb\u0995\u09be\u09b2 \u099f\u09cd\u09b0\u09c7\u09a8 \u099a\u09be\u09b2\u09c1 \u09b9\u0993\u09df\u09be\u09b0 \u09aa\u09b0 \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bc \u09b8\u09cd\u099f\u09c7\u09b6\u09a8\u09c7\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2018-video-from-russia-viral-as-stuffing-of-ballots-in-us-elections-10596",
        headline:
          "\u0928\u0939\u0940\u0902, \u092f\u0939 \u0935\u0940\u0921\u093f\u092f\u094b \u092f\u0942\u090f\u0938 \u0907\u0932\u0947\u0915\u094d\u0936\u0928 \u092e\u0947\u0902 '\u092c\u0948\u0932\u091f \u0938\u094d\u091f\u095e\u093f\u0902\u0917' \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u093e \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/bihar-polls-no-tejashwi-yadav-did-not-receive-yuva-neta-award-in-london-10619",
        headline:
          "\u0924\u0938\u094d\u0935\u0940\u0930\u0947\u0902 \u0924\u0947\u091c\u0936\u094d\u0935\u0940 \u092f\u093e\u0926\u0935 \u0915\u094b '\u0938\u092c\u0938\u0947 \u092f\u0941\u0935\u093e \u0928\u0947\u0924\u093e' \u092a\u0941\u0930\u0938\u094d\u0915\u093e\u0930 \u091c\u0940\u0924\u0924\u0947 \u0928\u0939\u0940\u0902 \u0926\u093f\u0916\u093e\u0924\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-image-of-unopened-mail-in-ballots-in-arizona-shared-with-false-claim-670338",
        headline:
          "Fact Check: Image of unopened mail-in ballots in Arizona shared with false claim",
      },
    ],
    "6": [
      {
        url:
          "https://www.boomlive.in/fake-news/fake-letter-claims-pm-modi-endorses-giriraj-singh-as-next-bihar-cm-10602",
        headline:
          "Fake Letter Claims PM Modi Endorses Giriraj Singh As Next Bihar CM",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-letter-claims-pm-modi-endorses-giriraj-singh-as-next-bihar-cm-10611",
        headline:
          "\u0997\u09bf\u09b0\u09bf\u09b0\u09be\u099c \u09b8\u09bf\u0982\u09b9 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7 \u09ae\u09c1\u0996\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09b9\u09cb\u0995, \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u099a\u09bf\u09a0\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://factly.in/death-of-a-3-year-old-in-road-accident-is-falsely-shared-as-rape-and-murder/",
        headline:
          " Death of a 3-year-old in a road accident is falsely shared as rape and murder",
      },
      {
        url:
          "https://factly.in/telugu-death-of-a-3-year-old-in-a-road-accident-is-falsely-shared-as-rape-and-murder/",
        headline:
          " \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c2a\u0c4d\u0c30\u0c2e\u0c3e\u0c26\u0c02\u0c32\u0c4b \u0c1a\u0c28\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c2e\u0c42\u0c21\u0c47\u0c33\u0c4d\u0c33 \u0c2c\u0c3e\u0c32\u0c3f\u0c15\u0c28\u0c3f \u0c09\u0c24\u0c4d\u0c24\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b \u0c05\u0c24\u0c4d\u0c2f\u0c3e\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c3f \u0c1a\u0c02\u0c2a\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.altnews.in/a-video-of-three-year-old-dead-girl-viral-with-false-rape-and-communal-angle/",
        headline:
          "Video of minor girl who died in UP viral with false rape and communal spin",
      },
      {
        url:
          "https://www.altnews.in/bihar-polls-old-unverified-video-falsely-viral-as-evm-directly-registering-vote-for-bjp/",
        headline:
          "Bihar polls: Old, unverified video falsely viral as EVM directly registering vote for BJP",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/11/fact-check-the-news-of-legendary-cricketer-kapil-dev-passing-away-is-fake-dont-believe-it/",
        headline:
          "Fact Check: The news of legendary cricketer Kapil Dev passing away is fake, don\u2019t believe it",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/11/fact-check-doctored-video-of-cnn-screen-with-pornhub-notification-during-election-coverage-goes-viral/",
        headline:
          "Fact Check: Doctored video of CNN screen with Pornhub notification during election coverage goes viral",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/11/fact-check-no-the-three-year-old-girl-in-the-video-is-not-a-not-a-rape-victim-heres-the-truth/",
        headline:
          "Fact Check: No, the three-year-old girl in the video is NOT a rape victim; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/11/fact-check-no-donald-trump-is-not-smashing-things-after-losing-us-elections-heres-the-truth/",
        headline:
          "Fact Check: No, Donald Trump is not smashing things after losing US elections; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2016-clip-of-kejriwal-alleging-owaisi-shah-secret-deal-for-bihar-polls-revived-10643",
        headline:
          "2016 Clip Of Kejriwal Alleging Owaisi-Shah 'Secret Deal' For Bihar Polls Revived",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2019-video-of-booth-capture-in-haryana-shared-as-bihar-10644",
        headline: "2019 Video Of 'Booth Capture' In Haryana Shared As Bihar",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-fake-letter-claims-pm-modi-wants-giriraj-singh-as-chief-minister-of-bihar-1739824-2020-11-10",
        headline:
          "Fact Check: Fake letter claims PM Modi wants Giriraj Singh as chief minister of Bihar",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-shivling-damaged-in-ranchi-shared-with-misleading-communal-claims-1740206-2020-11-11",
        headline:
          "Fact Check: Shivling damaged in Ranchi shared with misleading communal claims",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/11/fact-check-the-news-of-legendary-cricketer-kapil-dev-passing-away-is-fake-dont-believe-it/",
        headline:
          "Fact Check: The news of legendary cricketer Kapil Dev passing away is fake, don\u2019t believe it",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/11/fact-check-doctored-video-of-cnn-screen-with-pornhub-notification-during-election-coverage-goes-viral/",
        headline:
          "Fact Check: Doctored video of CNN screen with Pornhub notification during election coverage goes viral",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/11/fact-check-no-the-three-year-old-girl-in-the-video-is-not-a-not-a-rape-victim-heres-the-truth/",
        headline:
          "Fact Check: No, the three-year-old girl in the video is NOT a rape victim; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/11/fact-check-no-donald-trump-is-not-smashing-things-after-losing-us-elections-heres-the-truth/",
        headline:
          "Fact Check: No, Donald Trump is not smashing things after losing US elections; Here\u2019s the truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fake-letter-claims-pm-modi-endorses-giriraj-singh-as-next-bihar-cm-10611",
        headline:
          "\u0997\u09bf\u09b0\u09bf\u09b0\u09be\u099c \u09b8\u09bf\u0982\u09b9 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7 \u09ae\u09c1\u0996\u09cd\u09af\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09b9\u09cb\u0995, \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09ae\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0 \u09ae\u09cb\u09a6\u09c0\u09b0 \u099a\u09bf\u09a0\u09bf\u099f\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2019-video-of-booth-capture-in-haryana-shared-as-bihar-10644",
        headline: "2019 Video Of 'Booth Capture' In Haryana Shared As Bihar",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/fake-letter-claims-pm-modi-endorses-giriraj-singh-as-next-bihar-cm-10602",
        headline:
          "Fake Letter Claims PM Modi Endorses Giriraj Singh As Next Bihar CM",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2016-clip-of-kejriwal-alleging-owaisi-shah-secret-deal-for-bihar-polls-revived-10643",
        headline:
          "2016 Clip Of Kejriwal Alleging Owaisi-Shah 'Secret Deal' For Bihar Polls Revived",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-evms-were-not-stolen-during-bihar-elections-670447",
        headline: "Fact Check: No, EVMs were not stolen during Bihar elections",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-false-rumours-on-social-media-claim-former-indian-cricketer-kapil-dev-is-dead-670249",
        headline:
          "Fact Check: False rumours on social media claim former Indian cricketer Kapil Dev is dead",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-of-time-cover-mocking-us-president-donald-trump-is-fake-670332",
        headline:
          "Fact Check: Viral image of Time cover mocking US President Donald Trump is fake",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-deceased-girl-in-viral-video-was-not-raped-murdered-670284",
        headline:
          "Fact Check: No, deceased girl in viral video was not raped, murdered",
      },
    ],
  },
  numpy_seed: 0,
  number_of_articles: 126,
}
