export const data = {
  mdsDat: {
    x: [
      -0.17433099410722058,
      -0.1535522787912182,
      0.2815410532622721,
      0.248913582964827,
      -0.1046967635897442,
      0.1335470919944857,
      -0.2314216917334012,
    ],
    y: [
      0.23450350345375753,
      -0.36562974125352987,
      0.027239875842873292,
      -0.12346943500044608,
      -0.053466321235233774,
      0.18410507117909075,
      0.0967170470134878,
    ],
    topics: [1, 2, 3, 4, 5, 6, 7],
    cluster: [1, 1, 1, 1, 1, 1, 1],
    Freq: [
      17.364522747322795,
      10.852713541256206,
      11.471959127849983,
      9.380251262444666,
      17.752992725216696,
      12.86830318882365,
      20.309257407086008,
    ],
  },
  tinfo: {
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
      "Topic7",
    ],
    Freq: [
      35.0,
      14.0,
      13.0,
      12.0,
      9.0,
      9.0,
      14.0,
      15.0,
      8.0,
      9.0,
      2.0000209235755713,
      3.0000313853633567,
      1.0000104617877856,
      1.0000104617877856,
      2.0000209235755713,
      1.0000104617877856,
      2.0000209235755713,
      2.0000209235755713,
      3.0000313853633567,
      4.0000418471511425,
      5.000052308938929,
      5.000052308938929,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      3.0000313853633567,
      3.0000313853633567,
      11.000115079665642,
      19.000198773967927,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      2.000000066888643,
      2.000000066888643,
      2.000000066888643,
      1.0000000334443215,
      2.000000066888643,
      2.000000066888643,
      1.0000000334443215,
      2.000000066888643,
      2.000000066888643,
      4.000000133777286,
      9.000000300998893,
      9.000000300998893,
      5.000000167221608,
      5.000000167221608,
      5.000000167221608,
      5.000000167221608,
      3.000000100332965,
      3.000000100332965,
      3.000000100332965,
      3.000000100332965,
      10.000000334443216,
      5.000000167221608,
      4.999603809096782,
      4.999603809096782,
      1.999841523638713,
      0.9999207618193565,
      1.999841523638713,
      1.999841523638713,
      0.9999207618193565,
      0.9999207618193565,
      0.9999207618193565,
      0.9999207618193565,
      2.9997622854580697,
      2.9997622854580697,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      6.999445332735496,
      1.999841523638713,
      1.999841523638713,
      6.999445332735496,
      6.999445332735496,
      8.999286856374209,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      2.9997622854580697,
      2.9997622854580697,
      3.999683047277426,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      3.0001299492281697,
      2.0000866328187796,
      4.000173265637559,
      4.000173265637559,
      4.000173265637559,
      3.0001299492281697,
      2.0000866328187796,
      2.0000866328187796,
      1.0000433164093898,
      7.000303214865729,
      1.0000433164093898,
      10.000433164093899,
      7.000303214865729,
      8.000346531275119,
      2.0000866328187796,
      1.000059415525307,
      2.000118831050614,
      2.000118831050614,
      2.000118831050614,
      2.000118831050614,
      1.000059415525307,
      1.000059415525307,
      4.000237662101228,
      1.000059415525307,
      2.000118831050614,
      12.000712986303686,
      6.000356493151843,
      6.000356493151843,
      6.000356493151843,
      6.000356493151843,
      6.000356493151843,
      5.000297077626536,
      5.000297077626536,
      4.000237662101228,
      4.000237662101228,
      5.000297077626536,
      5.000033467946538,
      5.000033467946538,
      2.0000133871786154,
      2.0000133871786154,
      2.0000133871786154,
      2.0000133871786154,
      1.0000066935893077,
      1.0000066935893077,
      2.0000133871786154,
      3.0000200807679227,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      7.000046855125152,
      4.000026774357231,
      4.000026774357231,
      8.999636583827042,
      8.999636583827042,
      8.999636583827042,
      8.999636583827042,
      1.9999192408504538,
      0.9999596204252269,
      0.9999596204252269,
      0.9999596204252269,
      3.9998384817009076,
      4.999798102126135,
      5.999757722551362,
      4.999798102126135,
      3.9998384817009076,
      3.9998384817009076,
      3.9998384817009076,
      3.9998384817009076,
      3.9998384817009076,
      8.999636583827042,
      3.9998384817009076,
      6.999717342976589,
      14.999394306378404,
      6.999717342976589,
    ],
    Term: [
      "bihar",
      "diwali",
      "show",
      "biden",
      "adityanath",
      "yogi",
      "china",
      "indian",
      "light",
      "rajasthan",
      "lok",
      "talk",
      "anil",
      "dubaka",
      "ganpati",
      "ls",
      "sabha",
      "voter",
      "anti_pak",
      "polling",
      "bihar_election",
      "kejriwal",
      "haryana",
      "booth",
      "rig",
      "polling_officer",
      "officer",
      "congress",
      "slogans",
      "anti",
      "election",
      "bihar",
      "bjp",
      "poll",
      "shared_one",
      "night",
      "burst",
      "wrong",
      "suggest",
      "midnapore",
      "christian",
      "dr",
      "public",
      "change",
      "firecracker",
      "adityanath",
      "yogi",
      "cracker",
      "crackers_diwali",
      "cm",
      "yogi_adityanath",
      "gehlot",
      "vaccine",
      "ashok",
      "cm_yogi",
      "diwali",
      "burn",
      "loc",
      "strike",
      "cricketer",
      "unfounded",
      "sharma",
      "refutes",
      "tricolor",
      "andean",
      "speak",
      "coffin",
      "soldier",
      "handle",
      "coffins",
      "product",
      "refrain",
      "major",
      "burj",
      "border",
      "violation",
      "pak_handles",
      "buying",
      "indian_army",
      "rohit",
      "khalifa",
      "pak",
      "army",
      "indian",
      "display",
      "ask",
      "ministry",
      "report",
      "use",
      "china",
      "madrasa",
      "sabarimala",
      "course",
      "irctc",
      "desai",
      "fail",
      "riot",
      "intrigue",
      "china_jodhpur",
      "show_rajasthan",
      "jodhpur",
      "water",
      "light_show",
      "water_light",
      "airstrike",
      "refute",
      "beautiful",
      "light",
      "telangana",
      "show",
      "rajasthan",
      "china",
      "army",
      "pandemic",
      "welcome",
      "provide",
      "international",
      "scuffle",
      "northernmost",
      "apologising",
      "elect",
      "advisor",
      "fleet",
      "biden",
      "joe",
      "chant",
      "house",
      "white_house",
      "white",
      "railway",
      "station",
      "vedic",
      "president",
      "indian",
      "kerala",
      "romania",
      "kailash",
      "clash",
      "strikes",
      "arrest",
      "passenger",
      "hundred",
      "army_denied",
      "france",
      "clad",
      "muslims",
      "burqa_clad",
      "spit",
      "amit_shah",
      "banerjee",
      "amit",
      "burqa",
      "police",
      "link",
      "shah",
      "tejashwi",
      "tejashwi_yadav",
      "gulnaz",
      "yadav",
      "alive",
      "person",
      "maharashtra",
      "second",
      "khatun",
      "girl",
      "march",
      "murder",
      "case",
      "candle",
      "gulnaz_khatun",
      "protest",
      "body",
      "woman",
      "candle_march",
      "karnataka",
      "bihar",
      "burn",
    ],
    Total: [
      35.0,
      14.0,
      13.0,
      12.0,
      9.0,
      9.0,
      14.0,
      15.0,
      8.0,
      9.0,
      2.0000209235755713,
      3.0000313853633567,
      1.0000104617877856,
      1.0000104617877856,
      2.0000209235755713,
      1.0000104617877856,
      2.0000209235755713,
      2.0000209235755713,
      3.0000313853633567,
      4.0000418471511425,
      5.000052308938929,
      5.000052308938929,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      4.0000418471511425,
      3.0000313853633567,
      3.0000313853633567,
      16.0002069183201,
      35.99971191139694,
      5.000085163560533,
      8.000120332071305,
      5.999883370789855,
      2.000000066888643,
      2.000000066888643,
      2.000000066888643,
      1.0000000334443215,
      2.000000066888643,
      2.000000066888643,
      1.0000000334443215,
      2.000000066888643,
      2.000000066888643,
      4.000000133777286,
      9.000000300998893,
      9.000000300998893,
      5.000000167221608,
      5.000000167221608,
      5.000000167221608,
      5.000000167221608,
      3.000000100332965,
      3.000000100332965,
      3.000000100332965,
      3.000000100332965,
      14.000237996544445,
      11.999717510198197,
      4.999603809096782,
      4.999603809096782,
      1.999841523638713,
      0.9999207618193565,
      1.999841523638713,
      1.999841523638713,
      0.9999207618193565,
      0.9999207618193565,
      0.9999207618193565,
      0.9999207618193565,
      2.9997622854580697,
      2.9997622854580697,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      8.999531965554276,
      1.999841523638713,
      1.999841523638713,
      9.999476718098853,
      10.999545352732891,
      15.999670566819525,
      1.999841523638713,
      1.999841523638713,
      1.999841523638713,
      3.9998056018674593,
      4.999881116508684,
      14.00004296573116,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      1.0000433164093898,
      3.0001299492281697,
      2.0000866328187796,
      4.000173265637559,
      4.000173265637559,
      4.000173265637559,
      3.0001299492281697,
      2.0000866328187796,
      2.0000866328187796,
      1.0000433164093898,
      8.00030324831005,
      1.0000433164093898,
      13.000443692770327,
      9.000362663835357,
      14.00004296573116,
      10.999545352732891,
      1.000059415525307,
      2.000118831050614,
      2.000118831050614,
      2.000118831050614,
      2.000118831050614,
      1.000059415525307,
      1.000059415525307,
      4.000237662101228,
      1.000059415525307,
      2.000118831050614,
      12.000712986303686,
      6.000356493151843,
      6.000356493151843,
      6.000356493151843,
      6.000356493151843,
      6.000356493151843,
      5.000297077626536,
      5.000297077626536,
      4.000237662101228,
      4.000237662101228,
      15.999670566819525,
      5.000033467946538,
      5.000033467946538,
      2.0000133871786154,
      2.0000133871786154,
      2.0000133871786154,
      2.0000133871786154,
      1.0000066935893077,
      1.0000066935893077,
      2.0000133871786154,
      3.0000200807679227,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      4.000026774357231,
      8.000106270650459,
      8.999848841996743,
      8.000068621508373,
      8.999636583827042,
      8.999636583827042,
      8.999636583827042,
      8.999636583827042,
      1.9999192408504538,
      0.9999596204252269,
      0.9999596204252269,
      0.9999596204252269,
      3.9998384817009076,
      4.999798102126135,
      5.999757722551362,
      4.999798102126135,
      3.9998384817009076,
      3.9998384817009076,
      3.9998384817009076,
      3.9998384817009076,
      3.9998384817009076,
      10.999649971005658,
      3.9998384817009076,
      8.999717409865232,
      35.99971191139694,
      11.999717510198197,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.7507,
      1.376,
      1.1117,
      1.5276,
      1.0576,
      1.3453,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      2.2208,
      1.8843,
      1.3453,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      2.1653,
      1.9139,
      2.1653,
      2.1653,
      1.8086,
      1.7132,
      1.5898,
      2.1653,
      2.1653,
      2.1653,
      1.8776,
      1.6544,
      0.9124,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.3666,
      2.233,
      2.3666,
      2.1042,
      2.1153,
      1.807,
      0.6619,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      1.7286,
      0.5655,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      2.0504,
      1.9169,
      1.2395,
      1.3573,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.5941,
      1.3934,
      1.5941,
      1.3428,
      0.7186,
      1.0551,
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.7185,
      -4.313,
      -5.4116,
      -5.4116,
      -4.7185,
      -5.4116,
      -4.7185,
      -4.7185,
      -4.313,
      -4.0254,
      -3.8022,
      -3.8022,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.313,
      -4.313,
      -3.0138,
      -2.4672,
      -4.0254,
      -4.0254,
      -4.0254,
      -4.2485,
      -4.2485,
      -4.2485,
      -4.9416,
      -4.2485,
      -4.2485,
      -4.9416,
      -4.2485,
      -4.2485,
      -3.5553,
      -2.7444,
      -2.7444,
      -3.3322,
      -3.3322,
      -3.3322,
      -3.3322,
      -3.843,
      -3.843,
      -3.843,
      -3.843,
      -2.6391,
      -3.3322,
      -3.3878,
      -3.3878,
      -4.3041,
      -4.9972,
      -4.3041,
      -4.3041,
      -4.9972,
      -4.9972,
      -4.9972,
      -4.9972,
      -3.8986,
      -3.8986,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -4.3041,
      -3.0513,
      -4.3041,
      -4.3041,
      -3.0513,
      -3.0513,
      -2.8,
      -4.3041,
      -4.3041,
      -4.3041,
      -3.8986,
      -3.8986,
      -3.6109,
      -4.7958,
      -4.7958,
      -4.7958,
      -4.7958,
      -4.7958,
      -4.7958,
      -4.7958,
      -4.7958,
      -3.6972,
      -4.1026,
      -3.4095,
      -3.4095,
      -3.4095,
      -3.6972,
      -4.1026,
      -4.1026,
      -4.7958,
      -2.8499,
      -4.7958,
      -2.4932,
      -2.8499,
      -2.7163,
      -4.1026,
      -5.4337,
      -4.7406,
      -4.7406,
      -4.7406,
      -4.7406,
      -5.4337,
      -5.4337,
      -4.0474,
      -5.4337,
      -4.7406,
      -2.9488,
      -3.642,
      -3.642,
      -3.642,
      -3.642,
      -3.642,
      -3.8243,
      -3.8243,
      -4.0474,
      -4.0474,
      -3.8243,
      -3.5025,
      -3.5025,
      -4.4188,
      -4.4188,
      -4.4188,
      -4.4188,
      -5.112,
      -5.112,
      -4.4188,
      -4.0134,
      -3.7257,
      -3.7257,
      -3.7257,
      -3.7257,
      -3.7257,
      -3.7257,
      -3.7257,
      -3.7257,
      -3.1661,
      -3.7257,
      -3.7257,
      -3.3711,
      -3.3711,
      -3.3711,
      -3.3711,
      -4.8752,
      -5.5683,
      -5.5683,
      -5.5683,
      -4.1821,
      -3.9589,
      -3.7766,
      -3.9589,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -4.1821,
      -3.3711,
      -4.1821,
      -3.6224,
      -2.8603,
      -3.6224,
    ],
  },
  "token.table": {
    Topic: [
      2,
      5,
      4,
      7,
      6,
      6,
      3,
      1,
      1,
      1,
      5,
      3,
      4,
      6,
      6,
      6,
      2,
      3,
      6,
      4,
      5,
      1,
      5,
      7,
      1,
      1,
      4,
      7,
      1,
      3,
      3,
      2,
      7,
      6,
      6,
      2,
      3,
      7,
      7,
      7,
      2,
      5,
      3,
      4,
      6,
      4,
      2,
      6,
      6,
      2,
      2,
      3,
      3,
      1,
      4,
      2,
      2,
      3,
      4,
      3,
      2,
      5,
      2,
      1,
      5,
      1,
      5,
      6,
      7,
      4,
      2,
      5,
      6,
      1,
      2,
      7,
      7,
      7,
      3,
      1,
      5,
      6,
      3,
      4,
      5,
      3,
      4,
      5,
      4,
      4,
      4,
      5,
      6,
      2,
      7,
      1,
      6,
      3,
      7,
      2,
      4,
      4,
      1,
      3,
      6,
      7,
      3,
      1,
      1,
      4,
      7,
      3,
      7,
      2,
      3,
      7,
      6,
      2,
      5,
      1,
      1,
      3,
      3,
      5,
      6,
      7,
      5,
      6,
      1,
      2,
      5,
      7,
      1,
      1,
      5,
      3,
      7,
      5,
      2,
      5,
      2,
      4,
      5,
      3,
      4,
      3,
      3,
      4,
      1,
      4,
      3,
      6,
      4,
      1,
      5,
      7,
      1,
      6,
      1,
      3,
      3,
      1,
      2,
      4,
      4,
      1,
      3,
      3,
      6,
      5,
      3,
      6,
      2,
      1,
      7,
      7,
      4,
      3,
      3,
      3,
      5,
      2,
      5,
      3,
      1,
      4,
      4,
      5,
      5,
      5,
      6,
      7,
      2,
      7,
      2,
      2,
    ],
    Freq: [
      0.9999999665556797,
      0.9999405880046879,
      0.9999566854668402,
      1.000040381205349,
      0.9999933064554961,
      0.9999933064554961,
      1.0000792444598303,
      0.9999895383216623,
      0.9999895383216623,
      0.9999895383216623,
      0.9999405880046879,
      0.636389939358795,
      0.18182569695965572,
      0.18182569695965572,
      0.9999933064554961,
      0.9999933064554961,
      0.9999999665556795,
      1.0000792444598303,
      0.9999933064554961,
      0.9999566854668402,
      0.9999405880046878,
      0.5277820013327634,
      0.055556000140290884,
      0.41667000105218166,
      0.999989538321662,
      0.7999863740624015,
      0.19999659351560037,
      1.000040381205349,
      0.9999895383216623,
      1.0000792444598303,
      1.0000792444598303,
      0.41667647557125,
      0.58334706579975,
      0.9999933064554961,
      0.9999933064554961,
      0.9999999665556796,
      1.0000792444598303,
      1.000040381205349,
      1.000040381205349,
      1.000040381205349,
      0.9999999665556796,
      0.9999405880046878,
      0.28571340886532043,
      0.5714268177306409,
      0.14285670443266021,
      0.9999566854668401,
      0.9999999665556796,
      0.9999933064554961,
      0.9999933064554961,
      0.9999999665556795,
      0.9999999665556795,
      1.0000792444598303,
      1.0000792444598303,
      0.9999895383216623,
      0.9999566854668402,
      0.9999999665556795,
      0.9999999665556795,
      1.0000792444598303,
      0.9999566854668402,
      1.0000792444598303,
      0.7142735718112943,
      0.2857094287245177,
      0.9999999665556796,
      0.9999895383216623,
      0.9999405880046879,
      0.6874911090934139,
      0.1249983834715298,
      0.1249983834715298,
      0.0624991917357649,
      0.9999566854668402,
      0.9999999665556796,
      0.9999405880046879,
      0.9999933064554962,
      0.9999895383216623,
      0.9999999665556795,
      1.0000403812053489,
      1.0000403812053489,
      1.000040381205349,
      1.0000792444598303,
      0.9999895383216623,
      0.9999405880046878,
      0.9999933064554961,
      0.562511581873717,
      0.12500257374971488,
      0.31250643437428716,
      0.7778182273025434,
      0.22223377922929807,
      0.9999405880046879,
      0.9999566854668402,
      0.9999566854668402,
      0.9999566854668402,
      0.9999405880046878,
      0.9999933064554961,
      0.22222919997550783,
      0.7778021999142775,
      0.999989538321662,
      0.9999933064554963,
      1.0000792444598303,
      1.000040381205349,
      0.12499526192475713,
      0.8749668334732998,
      0.9999566854668402,
      0.22222595458128516,
      0.22222595458128516,
      0.4444519091625703,
      0.11111297729064258,
      1.0000792444598303,
      0.9999895383216623,
      0.9999895383216623,
      0.9999566854668402,
      1.000040381205349,
      1.0000792444598303,
      1.0000403812053489,
      0.9999999665556796,
      1.0000792444598303,
      1.0000403812053489,
      0.9999933064554961,
      0.9999999665556796,
      0.9999405880046879,
      0.9999895383216623,
      0.30001569927854926,
      0.7000366316499482,
      1.0000792444598303,
      0.9999405880046879,
      0.9999933064554961,
      1.000040381205349,
      0.12499833954314381,
      0.8749883768020066,
      0.49999247935866525,
      0.12499811983966631,
      0.24999623967933263,
      0.12499811983966631,
      0.9999895383216623,
      0.9999895383216623,
      0.9999405880046879,
      1.0000792444598303,
      1.000040381205349,
      0.9999405880046879,
      0.9999999665556796,
      0.9999405880046877,
      0.11110663396022159,
      0.7777464377215512,
      0.11110663396022159,
      1.0000792444598303,
      0.9999566854668402,
      1.0000792444598303,
      0.7500364514213734,
      0.25001215047379116,
      0.9999895383216623,
      0.9999566854668402,
      1.0000792444598303,
      0.9999933064554963,
      0.9999566854668402,
      0.9999895383216623,
      0.9999405880046879,
      1.000040381205349,
      0.4999957111925148,
      0.4999957111925148,
      0.6666796257196945,
      0.33333981285984726,
      1.0000792444598303,
      0.0769204516116715,
      0.153840903223343,
      0.769204516116715,
      0.9999566854668402,
      0.9999895383216623,
      1.0000792444598303,
      1.0000792444598303,
      0.9999933064554961,
      0.9999405880046877,
      1.0000792444598303,
      0.9999933064554961,
      0.9999999665556796,
      0.9999895383216623,
      1.0000403812053489,
      1.0000403812053489,
      0.9999566854668402,
      1.0000792444598303,
      1.0000792444598303,
      0.6000142663581648,
      0.40000951090544323,
      0.9999999665556795,
      0.9999405880046879,
      1.0000792444598303,
      0.9999895383216623,
      0.9999566854668402,
      0.9999566854668401,
      0.9999405880046879,
      0.9999405880046878,
      0.9999405880046878,
      0.18182396760550257,
      0.8182078542247616,
      0.9999999665556796,
      1.0000403812053489,
      0.9999999665556797,
      0.9999999665556795,
    ],
    Term: [
      "adityanath",
      "advisor",
      "airstrike",
      "alive",
      "amit",
      "amit_shah",
      "andean",
      "anil",
      "anti",
      "anti_pak",
      "apologising",
      "army",
      "army",
      "army",
      "army_denied",
      "arrest",
      "ashok",
      "ask",
      "banerjee",
      "beautiful",
      "biden",
      "bihar",
      "bihar",
      "bihar",
      "bihar_election",
      "bjp",
      "bjp",
      "body",
      "booth",
      "border",
      "burj",
      "burn",
      "burn",
      "burqa",
      "burqa_clad",
      "burst",
      "buying",
      "candle",
      "candle_march",
      "case",
      "change",
      "chant",
      "china",
      "china",
      "china",
      "china_jodhpur",
      "christian",
      "clad",
      "clash",
      "cm",
      "cm_yogi",
      "coffin",
      "coffins",
      "congress",
      "course",
      "cracker",
      "crackers_diwali",
      "cricketer",
      "desai",
      "display",
      "diwali",
      "diwali",
      "dr",
      "dubaka",
      "elect",
      "election",
      "election",
      "election",
      "election",
      "fail",
      "firecracker",
      "fleet",
      "france",
      "ganpati",
      "gehlot",
      "girl",
      "gulnaz",
      "gulnaz_khatun",
      "handle",
      "haryana",
      "house",
      "hundred",
      "indian",
      "indian",
      "indian",
      "indian_army",
      "indian_army",
      "international",
      "intrigue",
      "irctc",
      "jodhpur",
      "joe",
      "kailash",
      "karnataka",
      "karnataka",
      "kejriwal",
      "kerala",
      "khalifa",
      "khatun",
      "light",
      "light",
      "light_show",
      "link",
      "link",
      "link",
      "link",
      "loc",
      "lok",
      "ls",
      "madrasa",
      "maharashtra",
      "major",
      "march",
      "midnapore",
      "ministry",
      "murder",
      "muslims",
      "night",
      "northernmost",
      "officer",
      "pak",
      "pak",
      "pak_handles",
      "pandemic",
      "passenger",
      "person",
      "police",
      "police",
      "poll",
      "poll",
      "poll",
      "poll",
      "polling",
      "polling_officer",
      "president",
      "product",
      "protest",
      "provide",
      "public",
      "railway",
      "rajasthan",
      "rajasthan",
      "rajasthan",
      "refrain",
      "refute",
      "refutes",
      "report",
      "report",
      "rig",
      "riot",
      "rohit",
      "romania",
      "sabarimala",
      "sabha",
      "scuffle",
      "second",
      "shah",
      "shah",
      "shared_one",
      "shared_one",
      "sharma",
      "show",
      "show",
      "show",
      "show_rajasthan",
      "slogans",
      "soldier",
      "speak",
      "spit",
      "station",
      "strike",
      "strikes",
      "suggest",
      "talk",
      "tejashwi",
      "tejashwi_yadav",
      "telangana",
      "tricolor",
      "unfounded",
      "use",
      "use",
      "vaccine",
      "vedic",
      "violation",
      "voter",
      "water",
      "water_light",
      "welcome",
      "white",
      "white_house",
      "woman",
      "woman",
      "wrong",
      "yadav",
      "yogi",
      "yogi_adityanath",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6, 7],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.altnews.in/hindi/video-from-2019-lok-sabha-polls-viral-as-voter-fraud-in-recent-bihar-elections/",
        headline:
          "2019 \u0932\u094b\u0915\u0938\u092d\u093e \u091a\u0941\u0928\u093e\u0935 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935 \u092e\u0947\u0902 \u0927\u093e\u0902\u0927\u0932\u0940 \u0939\u094b\u0928\u0947 \u0915\u0947 \u0926\u093e\u0935\u0947 \u0938\u0947 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/video-from-2019-lok-sabha-polls-viral-as-voter-fraud-in-recent-bihar-elections/",
        headline:
          "Video from 2019 Lok Sabha polls viral as voter fraud in recent Bihar elections",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-old-video-is-being-shared-as-proof-of-evm-tampering-in-dubbaka/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02\u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c2a\u0c46\u0c1f\u0c4d\u0c1f\u0c3f, \u0c26\u0c41\u0c2c\u0c4d\u0c2c\u0c3e\u0c15 \u0c09\u0c2a\u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c4b \u0c08\u0c35\u0c40\u0c0e\u0c02 \u0c1f\u0c4d\u0c2f\u0c3e\u0c02\u0c2a\u0c30\u0c3f\u0c02\u0c17\u0c4d \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/2019-video-of-a-polling-agent-influencing-voters-in-haryana-is-falsely-linked-2020-bihar-elections/",
        headline:
          " 2019 video of a polling agent influencing voters in Haryana is falsely linked 2020 Bihar elections",
      },
      {
        url:
          "https://factly.in/telugu-old-fictional-character-mla-anil-upadhyay-revived-again-as-congress-mla-anil-upadhyay/",
        headline:
          " \u0c05\u0c28\u0c3f\u0c32\u0c4d \u0c09\u0c2a\u0c3e\u0c27\u0c4d\u0c2f\u0c3e\u0c2f \u0c2a\u0c47\u0c30\u0c41\u0c24\u0c4b \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c2a\u0c3e\u0c30\u0c4d\u0c1f\u0c40\u0c32\u0c4b \u0c05\u0c38\u0c32\u0c41 \u0c0e\u0c2e\u0c4d\u0c2e\u0c46\u0c32\u0c4d\u0c2f\u0c47 \u0c32\u0c47\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-shows-mp-congress-leaders-daughter-not-bihar-polling-officer/",
        headline:
          " This video shows MP Congress leader\u2019s daughter, not Bihar polling officer.",
      },
      {
        url:
          "https://factly.in/telugu-this-video-shows-mp-congress-leaders-daughter-not-bihar-polling-officer/",
        headline:
          " \u0c2e\u0c27\u0c4d\u0c2f\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c32\u0c40\u0c21\u0c30\u0c4d \u0c15\u0c42\u0c24\u0c41\u0c30\u0c41 \u0c05\u0c15\u0c4d\u0c15\u0c21\u0c3f \u0c09\u0c2a \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c17\u0c41\u0c30\u0c3f\u0c02\u0c1a\u0c3f \u0c2e\u0c3e\u0c1f\u0c4d\u0c32\u0c3e\u0c21\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f\u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/16/fact-check-no-this-video-of-booth-capturing-is-not-from-bihar-heres-the-truth/",
        headline:
          "Fact Check: No, this video of \u2018booth capturing\u2019 is NOT from Bihar; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/18/fact-check-old-video-of-kejriwal-talking-about-bjp-aimim-deal-shared-as-recent-one-in-context-of-bihar/",
        headline:
          "Fact Check: Old video of Kejriwal talking about BJP-AIMIM \u2018deal\u2019 shared as recent one in context of Bihar",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/18/fact-check-old-picture-of-shah-mamata-meeting-naveen-patnaik-in-odisha-falsely-shared-as-recent-one/",
        headline:
          "Fact Check: Old picture of Shah, Mamata meeting Naveen Patnaik in Odisha falsely shared as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/21/fact-check-did-a-polling-officer-admit-to-rigging-in-bihar-elections-heres-the-truth/",
        headline:
          "Fact Check: Did a polling officer admit to rigging in Bihar elections? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/21/fact-check-priyanka-gandhi-vadra-did-not-take-a-dig-at-jawaharlal-nehru-this-tweet-is-morphed/",
        headline:
          "Fact Check: Priyanka Gandhi Vadra did not take a dig at Jawaharlal Nehru; This tweet is morphed",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/old-video-of-anti-pak-slogans-during-ganpati-visarjan-passed-off-as-jnu-10698",
        headline:
          "Old Video Of Anti-Pak Slogans During Ganpati Visarjan Passed Off As JNU",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-of-anti-pak-slogans-during-ganpati-visarjan-passed-off-as-jnu-10755",
        headline:
          "\u0917\u0923\u092a\u0924\u093f \u0935\u093f\u0938\u0930\u094d\u091c\u0928 \u0915\u0947 \u0935\u0958\u094d\u0924 \u092a\u093e\u0915 \u0935\u093f\u0930\u094b\u0927\u0940 \u0928\u093e\u0930\u094b\u0902 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u091c\u0947.\u090f\u0928.\u092f\u0941 \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2016-clip-of-kejriwal-alleging-owaisi-shah-secret-deal-for-bihar-polls-revived-10677",
        headline:
          "\u09ac\u09bf\u09b9\u09be\u09b0\u09c7 \u0993\u09df\u09c7\u0987\u09b8\u09bf-\u09b6\u09be\u09b9 '\u0997\u09cb\u09aa\u09a8 \u0986\u09be\u0981\u09a4\u09be\u09a4'? \u0995\u09c7\u099c\u09b0\u09bf\u0993\u09df\u09be\u09b2\u09c7\u09b0 \u09e8\u09e6\u09e7\u09ec-\u09b0 \u0995\u09cd\u09b2\u09bf\u09aa \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/video-of-illegal-voting-not-from-bihar-fact-check-1741308-2020-11-16",
        headline:
          "Fact Check: This video of illegal voting is not from Bihar\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-video-of-kejriwal-alleging-bjp-aimim-deal-in-bihar-is-four-years-old-1741337-2020-11-16",
        headline:
          "Fact Check: Video of Kejriwal alleging BJP-AIMIM deal in Bihar is four years old",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/16/fact-check-no-this-video-of-booth-capturing-is-not-from-bihar-heres-the-truth/",
        headline:
          "Fact Check: No, this video of \u2018booth capturing\u2019 is NOT from Bihar; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/18/fact-check-old-video-of-kejriwal-talking-about-bjp-aimim-deal-shared-as-recent-one-in-context-of-bihar/",
        headline:
          "Fact Check: Old video of Kejriwal talking about BJP-AIMIM \u2018deal\u2019 shared as recent one in context of Bihar",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/18/fact-check-old-picture-of-shah-mamata-meeting-naveen-patnaik-in-odisha-falsely-shared-as-recent-one/",
        headline:
          "Fact Check: Old picture of Shah, Mamata meeting Naveen Patnaik in Odisha falsely shared as recent one",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/21/fact-check-did-a-polling-officer-admit-to-rigging-in-bihar-elections-heres-the-truth/",
        headline:
          "Fact Check: Did a polling officer admit to rigging in Bihar elections? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/21/fact-check-priyanka-gandhi-vadra-did-not-take-a-dig-at-jawaharlal-nehru-this-tweet-is-morphed/",
        headline:
          "Fact Check: Priyanka Gandhi Vadra did not take a dig at Jawaharlal Nehru; This tweet is morphed",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/old-video-of-anti-pak-slogans-during-ganpati-visarjan-passed-off-as-jnu-10698",
        headline:
          "Old Video Of Anti-Pak Slogans During Ganpati Visarjan Passed Off As JNU",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-is-not-a-polling-officer-alleging-rigging-during-bihar-elections-10714",
        headline:
          "No, This Is Not A Polling Officer Alleging Rigging During Bihar Elections",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2019-video-of-booth-capture-in-haryana-shared-as-bihar-10726",
        headline:
          "\u09e8\u09e6\u09e7\u09ef \u09b8\u09be\u09b2\u09c7\u09b0 \u09b9\u09b0\u09bf\u09df\u09be\u09a8\u09be\u09df '\u09ac\u09c1\u09a5 \u09a6\u0996\u09b2'\u098f\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09a8\u09cb \u09b9\u099a\u09cd\u099b\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2016-clip-of-kejriwal-alleging-owaisi-shah-secret-deal-for-bihar-polls-revived-10677",
        headline:
          "\u09ac\u09bf\u09b9\u09be\u09b0\u09c7 \u0993\u09df\u09c7\u0987\u09b8\u09bf-\u09b6\u09be\u09b9 '\u0997\u09cb\u09aa\u09a8 \u0986\u09be\u0981\u09a4\u09be\u09a4'? \u0995\u09c7\u099c\u09b0\u09bf\u0993\u09df\u09be\u09b2\u09c7\u09b0 \u09e8\u09e6\u09e7\u09ec-\u09b0 \u0995\u09cd\u09b2\u09bf\u09aa \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-of-anti-pak-slogans-during-ganpati-visarjan-passed-off-as-jnu-10755",
        headline: "         ..    ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-alleged-evm-hacking-in-haryana-by-poll-passed-off-as-electoral-fraud-in-bihar-670602",
        headline:
          "FACT CHECK: Alleged EVM hacking in Haryana by-poll passed off as electoral fraud in Bihar",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-booth-capturing-video-is-from-2019-haryana-ls-polls-not-bihar-elections-670576",
        headline:
          "Fact Check: Booth capturing video is from 2019 Haryana LS polls not Bihar elections",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-congress-leader-konda-vishweshwar-reddy-is-not-joining-bjp-670728",
        headline:
          "Fact check: No, Congress leader Konda Vishweshwar Reddy is not joining BJP",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/unrelated-videos-are-compared-and-shared-as-hypocrisy-of-up-government-on-firecrackers-ban/",
        headline:
          " Unrelated videos are compared and shared as hypocrisy of UP Government on firecrackers ban",
      },
      {
        url:
          "https://factly.in/the-claims-made-by-dr-christiane-northrup-regarding-covid-19-vaccines-in-this-video-are-false/",
        headline:
          " The claims made by Dr. Christiane Northrup regarding COVID-19 vaccines in this video are false",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/19/fact-check-old-picture-of-rajasthan-cm-ashok-gehlot-burning-crackers-shared-as-recent-one/",
        headline:
          "Was Rajashtan CM Ashok Gehlot bursting crackers this Diwali, here is the fact check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/21/fact-check-no-up-cm-yogi-adityanath-did-not-burn-crackers-this-diwali-heres-the-truth/",
        headline:
          "Fact Check: No, UP CM Yogi Adityanath did NOT burn crackers this Diwali; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-this-video-does-not-show-yogi-adityanath-bursting-firecrackers-on-diwali-10723",
        headline:
          "No, This Video Does Not Show Yogi Adityanath Bursting Firecrackers On Diwali",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/rajasthan-cm-ashok-gehlot-video-viral-as-praying-namaz-10717",
        headline:
          "\u0930\u093e\u091c\u0938\u094d\u0925\u093e\u0928 \u0915\u0947 \u092e\u0941\u0916\u094d\u092f\u092e\u0902\u0924\u094d\u0930\u0940 \u0905\u0936\u094b\u0915 \u0917\u0939\u0932\u094b\u0924 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0926\u093e\u0935\u0947 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/old-video-of-yogi-adityanath-burning-crackers-viral-with-misleading-claims-10725",
        headline:
          "\u092f\u094b\u0917\u0940 \u0906\u0926\u093f\u0924\u094d\u092f\u0928\u093e\u0925 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092d\u094d\u0930\u093e\u092e\u0915 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/covid-19-vaccine-to-alter-dna-5-false-claims-by-christiane-northrup-10681",
        headline:
          "\u0995\u09cb\u09ad\u09bf\u09a1-\u09e7\u09ef \u099f\u09bf\u0995\u09be \u09a1\u09bf\u098f\u09a8\u098f'\u09b0 \u0997\u09a0\u09a8 \u09ac\u09a6\u09b2\u09be\u09ac\u09c7? \u0995\u09cd\u09b0\u09bf\u09b8\u09cd\u099f\u09bf\u09df\u09be\u09a8 \u09a8\u09b0\u09cd\u09a5\u09b0\u09be\u09aa\u09c7\u09b0 \u09eb \u099f\u09bf \u09ad\u09c1\u09b2 \u09a6\u09be\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-video-does-not-show-yogi-adityanath-bursting-firecrackers-on-diwali-10738",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u09a6\u09c0\u09aa\u09be\u09ac\u09b2\u09bf\u09b0 \u09b0\u09be\u09a4\u09c7 \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u09ac\u09be\u099c\u09bf \u09aa\u09cb\u09a1\u09bc\u09be\u09a8\u09cb\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018-image-of-karnatakas-rally-by-cpim-shared-as-peoples-support-in-purba-medinipur-10740",
        headline:
          "\u0995\u09b0\u09cd\u09a8\u09be\u099f\u0995\u09c7 \u09ac\u09be\u09ae\u09c7\u09a6\u09c7\u09b0 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09aa\u09c2\u09b0\u09cd\u09ac \u09ae\u09c7\u09a6\u09bf\u09a8\u09c0\u09aa\u09c1\u09b0\u09c7 \u099c\u09a8\u09b8\u09ae\u09b0\u09cd\u09a5\u09a8",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-viral-video-of-yogi-adityanath-burning-crackers-is-not-from-diwali-1742418-2020-11-20",
        headline:
          " Fact Check: Viral video of Yogi Adityanath burning crackers is not from Diwali",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/19/fact-check-old-picture-of-rajasthan-cm-ashok-gehlot-burning-crackers-shared-as-recent-one/",
        headline:
          "Was Rajashtan CM Ashok Gehlot bursting crackers this Diwali, here is the fact check",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/21/fact-check-no-up-cm-yogi-adityanath-did-not-burn-crackers-this-diwali-heres-the-truth/",
        headline:
          "Fact Check: No, UP CM Yogi Adityanath did NOT burn crackers this Diwali; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-yogi-adityanath-bursting-firecrackers-on-diwali-10723",
        headline:
          "No, This Video Does Not Show Yogi Adityanath Bursting Firecrackers On Diwali",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2018-image-of-karnatakas-rally-by-cpim-shared-as-peoples-support-in-purba-medinipur-10740",
        headline:
          "\u0995\u09b0\u09cd\u09a8\u09be\u099f\u0995\u09c7 \u09ac\u09be\u09ae\u09c7\u09a6\u09c7\u09b0 \u09ae\u09bf\u099b\u09bf\u09b2\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u09aa\u09c2\u09b0\u09cd\u09ac \u09ae\u09c7\u09a6\u09bf\u09a8\u09c0\u09aa\u09c1\u09b0\u09c7 \u099c\u09a8\u09b8\u09ae\u09b0\u09cd\u09a5\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-video-does-not-show-yogi-adityanath-bursting-firecrackers-on-diwali-10738",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u09ad\u09bf\u09a1\u09bf\u0993\u099f\u09bf \u09a6\u09c0\u09aa\u09be\u09ac\u09b2\u09bf\u09b0 \u09b0\u09be\u09a4\u09c7 \u09af\u09cb\u0997\u09c0 \u0986\u09a6\u09bf\u09a4\u09cd\u09af\u09a8\u09be\u09a5\u09c7\u09b0 \u09ac\u09be\u099c\u09bf \u09aa\u09cb\u09a1\u09bc\u09be\u09a8\u09cb\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/covid-19-vaccine-to-alter-dna-5-false-claims-by-christiane-northrup-10681",
        headline:
          "\u0995\u09cb\u09ad\u09bf\u09a1-\u09e7\u09ef \u099f\u09bf\u0995\u09be \u09a1\u09bf\u098f\u09a8\u098f'\u09b0 \u0997\u09a0\u09a8 \u09ac\u09a6\u09b2\u09be\u09ac\u09c7? \u0995\u09cd\u09b0\u09bf\u09b8\u09cd\u099f\u09bf\u09df\u09be\u09a8 \u09a8\u09b0\u09cd\u09a5\u09b0\u09be\u09aa\u09c7\u09b0 \u09eb \u099f\u09bf \u09ad\u09c1\u09b2 \u09a6\u09be\u09ac\u09bf",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-up-cm-yogi-adityanath-did-not-light-firecrackers-during-diwali-670782",
        headline:
          "FACT CHECK: No, UP CM Yogi Adityanath did not light firecrackers during Diwali",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-political-heavyweights-didnt-discuss-wb-polls-8-months-before-announcement-as-viral-claim-suggests-670733",
        headline:
          "Fact Check: Political heavyweights didn't discuss WB polls 8 months before announcement as viral claim suggests",
      },
    ],
    "3": [
      {
        url:
          "https://www.altnews.in/hindi/ex-pak-major-shares-10-yo-photo-as-indian-army-men-who-died-in-loc-ceasefire-violation/",
        headline:
          "\u092a\u0942\u0930\u094d\u0935 \u092a\u093e\u0915 \u092e\u0947\u091c\u0930 \u0928\u0947 10 \u0938\u093e\u0932 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0915\u0947 \u0938\u093e\u0925 LoC \u092a\u0930 \u092d\u093e\u0930\u0924\u0940\u092f \u0938\u0948\u0928\u093f\u0915\u094b\u0902 \u0915\u0947 \u092e\u093e\u0930\u0947 \u091c\u093e\u0928\u0947 \u0915\u0940 \u092c\u093e\u0924 \u0915\u0939\u0940",
      },
      {
        url:
          "https://www.altnews.in/ex-pak-major-shares-10-yo-photo-as-indian-army-men-who-died-in-loc-ceasefire-violation/",
        headline:
          "Ex-Pak major shares 10-yo photo as Indian army men who died in LoC ceasefire violation",
      },
      {
        url:
          "https://www.altnews.in/old-unfounded-reports-of-removal-of-jk-from-un-list-of-disputes-viral-again/",
        headline:
          "Old unfounded reports of removal of JK from UN \u2018list of disputes\u2019 viral again",
      },
      {
        url:
          "https://factly.in/telugu-the-indian-army-did-not-carry-out-pin-point-strikes-on-pok/",
        headline:
          " \u0c07\u0c02\u0c21\u0c3f\u0c2f\u0c28\u0c4d \u0c06\u0c30\u0c4d\u0c2e\u0c40 \u0c2a\u0c3e\u0c15\u0c4d \u0c06\u0c15\u0c4d\u0c30\u0c2e\u0c3f\u0c24 \u0c15\u0c3e\u0c36\u0c4d\u0c2e\u0c40\u0c30\u0c4d \u0c2a\u0c48 \u0c2a\u0c3f\u0c28\u0c4d \u0c2a\u0c3e\u0c2f\u0c3f\u0c02\u0c1f\u0c4d \u0c38\u0c4d\u0c1f\u0c4d\u0c30\u0c48\u0c15\u0c4d\u0c38\u0c4d \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/16/fact-check-cricketer-rohit-sharmas-photo-was-not-displayed-on-burj-khalifa-heres-the-truth/",
        headline:
          "Fact Check: Cricketer Rohit Sharma\u2019s photo was not displayed on Burj Khalifa; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/16/fact-check-home-ministry-has-not-asked-people-to-refrain-from-buying-products-made-in-china/",
        headline:
          "Fact Check: Home Ministry has not asked people to refrain from buying products made in China",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/19/fact-check-old-picture-of-coffins-of-soldiers-shared-as-recent-one-with-misleading-claims/",
        headline:
          "Fact Check: Old picture of coffins of soldiers shared as recent one with misleading claims",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/indian-army-refutes-reports-of-microwave-weapons-used-by-china-at-border-10703",
        headline:
          "Indian Army Refutes Reports Of Microwave Weapons Used By China At Border",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/pak-handles-share-2010-photo-linking-it-to-recent-loc-strike-10708",
        headline:
          "Pak Handles Share 2010 Photo Linking It To Recent LoC Strike",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/media-outlets-celebrates-airstrike-in-pok-indian-army-refutes-claim-10745",
        headline:
          "\u092d\u093e\u0930\u0924\u0940\u092f \u0938\u0947\u0928\u093e \u0928\u0947 \u092a\u0940\u0913\u0915\u0947 \u092e\u0947\u0902 \u090f\u092f\u0930 \u0938\u094d\u091f\u094d\u0930\u093e\u0907\u0915 \u0915\u0940 \u0959\u092c\u0930 \u0915\u093e \u0915\u093f\u092f\u093e \u0916\u0902\u0921\u0928",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/16/fact-check-cricketer-rohit-sharmas-photo-was-not-displayed-on-burj-khalifa-heres-the-truth/",
        headline:
          "Fact Check: Cricketer Rohit Sharma\u2019s photo was not displayed on Burj Khalifa; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/16/fact-check-home-ministry-has-not-asked-people-to-refrain-from-buying-products-made-in-china/",
        headline:
          "Fact Check: Home Ministry has not asked people to refrain from buying products made in China",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/19/fact-check-old-picture-of-coffins-of-soldiers-shared-as-recent-one-with-misleading-claims/",
        headline:
          "Fact Check: Old picture of coffins of soldiers shared as recent one with misleading claims",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/indian-army-refutes-reports-of-microwave-weapons-used-by-china-at-border-10703",
        headline:
          "Indian Army Refutes Reports Of Microwave Weapons Used By China At Border",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/pak-handles-share-2010-photo-linking-it-to-recent-loc-strike-10708",
        headline:
          "Pak Handles Share 2010 Photo Linking It To Recent LoC Strike",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/pak-handles-use-fake-image-to-discredit-indian-armys-strike-across-loc-10687",
        headline:
          "Pak Handles Use Fake Image To Discredit Indian Army's Strike Across LoC",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/image-of-indian-temple-token-coins-revived-with-misleading-claim-10754",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09ae\u09a8\u09cd\u09a6\u09bf\u09b0\u09c7\u09b0 \u09b8\u09cd\u09ae\u09be\u09b0\u0995 \u09ae\u09c1\u09a6\u09cd\u09b0\u09be",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/india-today-aaj-tak-share-edited-video-with-dama-dam-mast-qalandar-music",
        headline: "            ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photos-of-coffins-wrapped-in-tricolor-not-related-to-pak-ceasefire-violation-in-jk-670807",
        headline:
          "Fact Check: Photos of coffins wrapped in Tricolor not related to Pak ceasefire violation in J&K",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-bird-in-viral-video-is-not-jatayu-from-ramayana-but-andean-condor-from-argentina-670470",
        headline:
          "Fact check: Bird in viral video is not `Jatayu' from Ramayana, but Andean Condor from Argentina",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-game-clip-false-shared-as-indian-army-attack-on-pok-670765",
        headline:
          "Fact Check: Video game clip false shared as Indian Army attack on PoK",
      },
    ],
    "4": [
      {
        url:
          "https://www.altnews.in/hindi/old-video-of-synchronized-water-light-show-in-china-viral-as-jodhpur-rajasthan/",
        headline:
          "\u091a\u0940\u0928 \u0915\u0947 \u0935\u0949\u091f\u0930 \u0936\u094b \u0915\u093e \u090f\u0915 \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u091c\u094b\u0927\u092a\u0941\u0930 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.altnews.in/hindi/unrelated-photos-viral-with-image-of-accused-arrested-in-illegal-arms-racket-in-up-madrasa-last-year/",
        headline:
          "\u095e\u0948\u0915\u094d\u091f-\u091a\u0947\u0915 : UP \u0915\u0947 \u092e\u0926\u0930\u0938\u0947 \u0938\u0947 \u092c\u0930\u093e\u092e\u0926 \u0939\u0941\u0906 \u0939\u0925\u093f\u092f\u093e\u0930\u094b\u0902 \u0915\u093e \u091c\u0916\u0940\u0930\u093e, \u0926\u0902\u0917\u0947 \u0915\u0940 \u0938\u093e\u095b\u093f\u0936 \u0939\u0941\u0908 \u0928\u093e\u0915\u093e\u092e?",
      },
      {
        url:
          "https://www.altnews.in/old-video-of-synchronized-water-light-show-in-china-viral-as-jodhpur-rajasthan/",
        headline:
          "Old video of synchronized water-light show in China viral as Jodhpur, Rajasthan",
      },
      {
        url:
          "https://factly.in/this-video-is-related-to-a-light-show-in-china-not-jodhpur-rajasthan/",
        headline:
          " This video is related to a light show in China, not Jodhpur (Rajasthan)",
      },
      {
        url:
          "https://factly.in/telugu-this-video-is-related-to-a-light-show-in-china-not-jodhpur-rajasthan/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c32\u0c48\u0c1f\u0c4d \u0c37\u0c4b \u0c28\u0c3f \u0c1a\u0c48\u0c28\u0c3e \u0c32\u0c4b \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3e\u0c30\u0c41; \u0c1c\u0c4b\u0c27\u0c4d\u200c\u0c2a\u0c42\u0c30\u0c4d (\u0c30\u0c3e\u0c1c\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d) \u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-old-video-from-china-being-shared-as-a-man-being-killed-by-tigers-in-telangana/",
        headline:
          " \u0c1a\u0c48\u0c28\u0c3e \u0c26\u0c47\u0c36\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c12\u0c15 \u0c2f\u0c41\u0c35\u0c15\u0c41\u0c21\u0c3f\u0c28\u0c3f \u0c2a\u0c41\u0c32\u0c41\u0c32\u0c41 \u0c1a\u0c02\u0c2a\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/an-edited-version-of-special-effect-videos-portrayed-as-the-beautiful-light-show-in-rajasthan/",
        headline:
          " An edited version of special effect videos portrayed as the beautiful light show in Rajasthan.",
      },
      {
        url:
          "https://digiteye.in/is-irctc-confusing-senior-citizens-to-give-up-their-concession-fact-check/",
        headline:
          "Is IRCTC confusing senior citizens to give up their concession? Fact Check",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/21/fact-check-viral-video-from-china-falsely-shared-as-water-and-light-show-from-rajasthan/",
        headline:
          "Fact Check: Viral video from China falsely shared as water and light show from Rajasthan",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/social-media-celebrates-airstrike-in-pok-indian-army-refutes-claim-10742",
        headline:
          "Social Media 'Celebrates' Airstrike In PoK, Indian Army Refutes Claim",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-reports-claiming-bjp-s-role-behind-trupti-desai-s-visit-to-sabarimala-is-fake-1742935-2020-11-22",
        headline:
          "Fact Check: \u201cReports\u201d claiming BJP's role behind Trupti Desai's visit to Sabarimala are fake",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/pakistan-mascot-chacha-cricket-death-rumours-1742746-2020-11-21",
        headline:
          "Fact Check: Pakistan's lovable mascot 'Chacha Cricket' hits out of boundary his death rumours\u00a0",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/21/fact-check-viral-video-from-china-falsely-shared-as-water-and-light-show-from-rajasthan/",
        headline:
          "Fact Check: Viral video from China falsely shared as water and light show from Rajasthan",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/social-media-celebrates-airstrike-in-pok-indian-army-refutes-claim-10742",
        headline:
          "Social Media 'Celebrates' Airstrike In PoK, Indian Army Refutes Claim",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/media-outlets-celebrates-airstrike-in-pok-indian-army-refutes-claim-10745",
        headline: "           ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/rajasthan-cm-ashok-gehlot-video-viral-as-praying-namaz-10717",
        headline: "            ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/video-of-booth-capture-in-faridabad-lok-sabha-election-2019-shared-as-bihar-10680",
        headline: "  2019  ' '      ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-video-does-not-show-pak-us-armed-forces-taking-part-in-obstacle-course-race-670605",
        headline:
          "Fact Check: Viral video does not show Pak, US armed forces taking part in obstacle course race",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-spectacular-light-show-in-the-viral-video-is-from-china-not-rajasthan-670532",
        headline:
          "Fact check: Spectacular light show in the viral video is from China, not Rajasthan",
      },
    ],
    "5": [
      {
        url:
          "https://www.altnews.in/hindi/2014-video-shared-as-white-house-holding-a-recital-of-hindu-shlokas-for-joe-biden/",
        headline:
          "\u0935\u094d\u0939\u093e\u0907\u091f \u0939\u093e\u0909\u0938 \u092e\u0947\u0902 \u0939\u094b \u0930\u0939\u0947 \u092e\u0902\u0924\u094d\u0930\u094b\u091a\u094d\u091a\u093e\u0930\u0923 \u0915\u093e \u092a\u0941\u0930\u093e\u0928\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u093e\u0907\u0921\u0928 \u0915\u0947 \u092a\u0939\u0932\u0947 \u0926\u093f\u0928 \u0915\u093e \u092c\u0924\u093e\u0915\u0930 \u0936\u0947\u092f\u0930 \u0915\u093f\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/video-from-2018-falsely-shared-as-overcrowded-railway-station-during-covid-19/",
        headline:
          "Video from 2018 falsely shared as overcrowded railway station during COVID-19",
      },
      {
        url:
          "https://factly.in/old-video-of-international-fleet-review-is-passed-off-as-diwali-celebrations-by-indian-navy/",
        headline:
          " Old video of International Fleet Review is passed off as Diwali celebrations by Indian Navy",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-international-fleet-review-is-passed-off-as-diwali-celebrations-by-indian-navy/",
        headline:
          " \u0c07\u0c02\u0c1f\u0c30\u0c4d\u0c28\u0c47\u0c37\u0c28\u0c32\u0c4d \u0c2b\u0c4d\u0c32\u0c40\u0c1f\u0c4d \u0c30\u0c3f\u0c35\u0c4d\u0c2f\u0c42\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c2d\u0c3e\u0c30\u0c24 \u0c28\u0c47\u0c35\u0c40 \u0c1c\u0c30\u0c41\u0c2a\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c26\u0c40\u0c2a\u0c3e\u0c35\u0c33\u0c3f \u0c35\u0c47\u0c21\u0c41\u0c15\u0c32 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/old-video-is-passed-off-as-vedic-chanting-in-white-house-on-first-day-of-newly-elected-american-president/",
        headline:
          " Old video passed off as Vedic chanting in White House on the first day of newly elected American President.",
      },
      {
        url:
          "https://factly.in/telugu-the-newly-elected-president-of-america-was-not-welcomed-with-vedic-mantras/",
        headline:
          " \u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e\u0c32\u0c4b \u0c15\u0c4a\u0c24\u0c4d\u0c24\u0c17\u0c3e \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c48\u0c28 \u0c05\u0c27\u0c4d\u0c2f\u0c15\u0c4d\u0c37\u0c41\u0c28\u0c3f\u0c15\u0c3f \u0c35\u0c47\u0c26\u0c2e\u0c02\u0c24\u0c4d\u0c30\u0c3e\u0c32\u0c24\u0c4b \u0c38\u0c4d\u0c35\u0c3e\u0c17\u0c24\u0c02 \u0c2a\u0c32\u0c15\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/16/fact-check-this-picture-of-us-president-elect-joe-biden-with-a-big-dog-is-edited/",
        headline:
          "Fact Check: This picture of US President-elect Joe Biden with a big dog is edited",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/16/fact-check-is-govt-providing-jobs-to-one-member-of-each-family-heres-the-truth/",
        headline:
          "Fact Check: Is Govt providing jobs to one member of each family? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/19/fact-check-old-video-of-scuffle-from-west-bengal-shared-incorrectly-in-context-of-bihar-polls/",
        headline:
          "Fact check: Old video of scuffle from West Bengal shared incorrectly in context of Bihar polls",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/19/fact-check-this-viral-video-of-crowded-railway-station-during-covid-19-is-not-from-kolkata-heres-the-truth/",
        headline:
          "Fact Check: This viral video of crowded railway station during COVID-19 is NOT from Kolkata; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2014-video-shared-as-hindu-chants-recited-at-white-house-for-joe-biden-10735",
        headline:
          "2014 Video Shared As Hindu Chants Recited At White House For Joe Biden",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/posts-claiming-indian-ahmed-khan-appointed-advisor-to-biden-are-false-10672",
        headline:
          "\u09ad\u09be\u09b0\u09a4\u09c0\u09df \u0986\u09b9\u09ae\u09c7\u09a6 \u0996\u09be\u09a8 \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u09aa\u09b0\u09be\u09ae\u09b0\u09cd\u09b6\u09a6\u09be\u09a4\u09be \u09a8\u09bf\u09af\u09c1\u0995\u09cd\u09a4 \u09b9\u09df\u09c7\u099b\u09c7\u09a8 \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/fake-old-quote-attributed-to-cec-ts-krishnamurthy-on-evm-hacking-viral-10756",
        headline:
          "\u0987\u09ad\u09bf\u098f\u09ae \u0995\u09be\u09b0\u099a\u09c1\u09aa\u09bf \u09a8\u09bf\u09df\u09c7 \u09aa\u09cd\u09b0\u09be\u0995\u09cd\u09a4\u09a8 \u09ae\u09c1\u0996\u09cd\u09af \u09a8\u09bf\u09b0\u09cd\u09ac\u09be\u099a\u09a8 \u0995\u09ae\u09bf\u09b6\u09a8\u09be\u09b0\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u0989\u0995\u09cd\u09a4\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-joe-biden-is-not-apologising-to-the-daughter-of-a-race-victim-here-1741959-2020-11-18",
        headline:
          "Fact Check: Joe Biden is not apologising to the daughter of a race victim here",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-a-video-of-white-house-welcoming-joe-biden-with-vedic-chants-1742419-2020-11-20",
        headline:
          "Fact Check: This is not a video of White House welcoming Joe Biden with Vedic chants",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/16/fact-check-this-picture-of-us-president-elect-joe-biden-with-a-big-dog-is-edited/",
        headline:
          "Fact Check: This picture of US President-elect Joe Biden with a big dog is edited",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/16/fact-check-is-govt-providing-jobs-to-one-member-of-each-family-heres-the-truth/",
        headline:
          "Fact Check: Is Govt providing jobs to one member of each family? Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/19/fact-check-old-video-of-scuffle-from-west-bengal-shared-incorrectly-in-context-of-bihar-polls/",
        headline:
          "Fact check: Old video of scuffle from West Bengal shared incorrectly in context of Bihar polls",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/19/fact-check-this-viral-video-of-crowded-railway-station-during-covid-19-is-not-from-kolkata-heres-the-truth/",
        headline:
          "Fact Check: This viral video of crowded railway station during COVID-19 is NOT from Kolkata; Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2016-video-viral-as-diwali-celebrations-by-the-indian-navy-10746",
        headline: "2016 Video Viral As Diwali Celebrations By The Indian Navy",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2014-video-shared-as-hindu-chants-recited-at-white-house-for-joe-biden-10735",
        headline:
          "2014 Video Shared As Hindu Chants Recited At White House For Joe Biden",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/old-video-revived-as-crowded-railway-station-during-covid-19-10695",
        headline:
          "Old Video Revived As Crowded Railway Station During COVID-19",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/fake-old-quote-attributed-to-cec-ts-krishnamurthy-on-evm-hacking-viral-10756",
        headline:
          "\u0987\u09ad\u09bf\u098f\u09ae \u0995\u09be\u09b0\u099a\u09c1\u09aa\u09bf \u09a8\u09bf\u09df\u09c7 \u09aa\u09cd\u09b0\u09be\u0995\u09cd\u09a4\u09a8 \u09ae\u09c1\u0996\u09cd\u09af \u09a8\u09bf\u09b0\u09cd\u09ac\u09be\u099a\u09a8 \u0995\u09ae\u09bf\u09b6\u09a8\u09be\u09b0\u09c7\u09b0 \u09ad\u09c1\u09df\u09cb \u0989\u0995\u09cd\u09a4\u09bf \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/posts-claiming-indian-ahmed-khan-appointed-advisor-to-biden-are-false-10672",
        headline:
          "\u09ad\u09be\u09b0\u09a4\u09c0\u09df \u0986\u09b9\u09ae\u09c7\u09a6 \u0996\u09be\u09a8 \u09ac\u09be\u0987\u09a1\u09c7\u09a8\u09c7\u09b0 \u09aa\u09b0\u09be\u09ae\u09b0\u09cd\u09b6\u09a6\u09be\u09a4\u09be \u09a8\u09bf\u09af\u09c1\u0995\u09cd\u09a4 \u09b9\u09df\u09c7\u099b\u09c7\u09a8 \u09a6\u09be\u09ac\u09bf\u099f\u09bf \u09ae\u09bf\u09a5\u09cd\u09af\u09c7",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-biden-did-not-apologise-to-the-daughter-of-african-american-man-killed-by-police-670656",
        headline:
          "Fact Check: No, Biden did not apologise to the daughter of African-American man killed by police",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-it-is-true-that-the-northernmost-city-in-america-will-see-darkness-for-67-days-670681",
        headline:
          "Fact Check: It is true that the northernmost city in America will see darkness for 67 days",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-false-claim-uses-video-of-overcrowded-railway-station-in-2018-as-rush-during-pandemic-670542",
        headline:
          "Fact Check: False claim uses video of overcrowded railway station in 2018 as rush during pandemic",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-biden-has-not-been-welcomed-into-white-house-amid-vedic-chants-670712",
        headline:
          "Fact Check: No, Biden has not been welcomed into White House amid Vedic chants",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-claims-that-ahmed-khan-from-hyderabad-has-been-appointed-bidens-political-advisor-is-false-670486",
        headline:
          "FACT CHECK: Viral claims that Ahmed Khan from Hyderabad has been appointed Biden's political advisor is false.",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-jamra-beej-from-rajasthan-passed-off-as-rajputs-celebrating-diwali-using-firearms-670786",
        headline:
          "Fact Check: Video of 'Jamra Beej' from Rajasthan passed off as Rajputs celebrating Diwali using firearms",
      },
    ],
    "6": [
      {
        url:
          "https://www.altnews.in/hindi/old-image-of-mamta-banerjee-amit-shah-having-breakfast-with-other-leaders-shared-as-a-meeting-for-upcoming-bengal-election/",
        headline:
          "\u0905\u092e\u093f\u0924 \u0936\u093e\u0939 \u0914\u0930 \u092e\u092e\u0924\u093e \u092c\u0928\u0930\u094d\u091c\u0940 \u0915\u0940 \u092a\u0941\u0930\u093e\u0928\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0936\u0947\u092f\u0930 \u0915\u0930\u0924\u0947 \u0939\u0941\u090f \u092c\u0902\u0917\u093e\u0932 \u091a\u0941\u0928\u093e\u0935 \u0938\u0947 \u091c\u094b\u095c \u0926\u093f\u092f\u093e \u0917\u092f\u093e",
      },
      {
        url:
          "https://www.altnews.in/old-image-of-mamta-banerjee-amit-shah-having-breakfast-with-other-leaders-shared-as-a-meeting-for-upcoming-bengal-election/",
        headline:
          "Old image of Amit Shah and Mamata Banerjee linked with West Bengal elections",
      },
      {
        url:
          "https://factly.in/telugu-video-from-romania-shared-as-france-police-attack-muslims-for-not-wearing-a-mask-in-a-metro-rail/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c30\u0c4a\u0c2e\u0c47\u0c28\u0c3f\u0c2f\u0c3e \u0c2a\u0c4b\u0c32\u0c40\u0c38\u0c41\u0c32\u0c41 \u0c15\u0c4a\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c26\u0c3f \u0c2e\u0c3e\u0c38\u0c4d\u0c15\u0c4d \u0c27\u0c30\u0c3f\u0c02\u0c1a\u0c15\u0c41\u0c02\u0c21\u0c3e \u0c07\u0c24\u0c30\u0c41\u0c32 \u0c2a\u0c48 \u0c09\u0c2e\u0c4d\u0c2e\u0c47\u0c38\u0c3f\u0c28\u0c02\u0c26\u0c41\u0c15\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-photo-from-kerala-is-falsely-linked-to-diwali-celebrations-in-ayodhya/",
        headline:
          " \u0c15\u0c47\u0c30\u0c33 \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c05\u0c2f\u0c4b\u0c27\u0c4d\u0c2f\u0c32\u0c4b \u0c26\u0c40\u0c2a\u0c3e\u0c32\u0c24\u0c4b \u0c28\u0c3f\u0c32\u0c3f\u0c1a\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c02\u0c26\u0c32\u0c3e\u0c26\u0c3f \u0c2d\u0c15\u0c4d\u0c24\u0c41\u0c32\u0c02\u0c1f\u0c42 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/video-from-romania-falsely-shared-as-french-police-action-on-muslims-for-not-wearing-a-mask/",
        headline:
          " Video from Romania falsely shared as French Police action on Muslims for not wearing a mask",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/19/fact-check-video-of-clash-from-romania-shared-with-false-paris-link/",
        headline:
          "Fact Check: Video of clash from Romania shared with false Paris link",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/20/fact-check-this-viral-video-is-not-of-kailash-mansarovar-but-a-montage-of-different-locations-in-china/",
        headline:
          "Fact Check: This viral video is NOT of Kailash Mansarovar, but a montage of different locations in China",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/20/fact-check-are-the-burqa-clad-women-in-this-viral-photograph-from-kerala-police-heres-the-truth/",
        headline:
          "Fact Check: Are the burqa clad women in this viral photo from Kerala Police? Here\u2019s the truth",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/unrelated-images-of-pm-modi-and-gurmeet-ram-rahim-riding-copter-shared-with-misleading-claim-10711",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ae\u09cb\u09a6\u09c0 \u0993 \u0997\u09c1\u09b0\u09ae\u09bf\u09a4\u09c7\u09b0 \u09b9\u09c7\u09b2\u09bf\u0995\u09aa\u09cd\u099f\u09be\u09b0 \u099a\u09a1\u09bc\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/social-media-celebrates-airstrike-in-pok-indian-army-refutes-claim-10752",
        headline:
          "\u09aa\u09be\u0995 \u0985\u09a7\u09bf\u0995\u09c3\u09a4 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u09c7 \u098f\u09df\u09be\u09b0 \u09b8\u09cd\u099f\u09cd\u09b0\u09be\u0987\u0995 \u09b8\u09cb\u09b6\u09be\u09b2 \u09ae\u09bf\u09a1\u09bf\u09df\u09be\u09b0 \u09a6\u09be\u09ac\u09bf \u09a8\u09be\u0995\u099a \u09b8\u09c7\u09a8\u09be\u09ac\u09be\u09b9\u09bf\u09a8\u09c0\u09b0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-picture-of-burqa-clad-students-passed-off-as-kerala-s-all-women-police-force-1742047-2020-11-18",
        headline:
          "Fact Check: Picture of burqa-clad students passed off as Kerala\u2019s all-women police force",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/19/fact-check-video-of-clash-from-romania-shared-with-false-paris-link/",
        headline:
          "Fact Check: Video of clash from Romania shared with false Paris link",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/20/fact-check-this-viral-video-is-not-of-kailash-mansarovar-but-a-montage-of-different-locations-in-china/",
        headline:
          "Fact Check: This viral video is NOT of Kailash Mansarovar, but a montage of different locations in China",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/20/fact-check-are-the-burqa-clad-women-in-this-viral-photograph-from-kerala-police-heres-the-truth/",
        headline:
          "Fact Check: Are the burqa clad women in this viral photo from Kerala Police? Here\u2019s the truth",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/video-from-romania-viral-as-muslims-arrested-in-france-for-spitting-in-train-10674",
        headline:
          "Video From Romania Viral As Muslims Arrested In France For Spitting In Train",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/image-of-amit-shah-dining-with-mamata-banerjee-revived-with-false-claims-10729",
        headline:
          "Image Of Amit Shah Dining With Mamata Banerjee Revived With False Claims",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/image-of-amit-shah-dining-with-mamata-banerjee-revived-with-false-claims-10730",
        headline:
          "\u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0985\u09ae\u09bf\u09a4 \u09b6\u09be\u09b9 \u0993 \u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09ae\u09a7\u09cd\u09af\u09be\u09a8\u09cd\u09a8\u09ad\u09cb\u099c\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/social-media-celebrates-airstrike-in-pok-indian-army-refutes-claim-10752",
        headline:
          "\u09aa\u09be\u0995 \u0985\u09a7\u09bf\u0995\u09c3\u09a4 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u09c7 \u098f\u09df\u09be\u09b0 \u09b8\u09cd\u099f\u09cd\u09b0\u09be\u0987\u0995 \u09b8\u09cb\u09b6\u09be\u09b2 \u09ae\u09bf\u09a1\u09bf\u09df\u09be\u09b0 \u09a6\u09be\u09ac\u09bf \u09a8\u09be\u0995\u099a \u09b8\u09c7\u09a8\u09be\u09ac\u09be\u09b9\u09bf\u09a8\u09c0\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/unrelated-images-of-pm-modi-and-gurmeet-ram-rahim-riding-copter-shared-with-misleading-claim-10711",
        headline:
          "\u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ae\u09cb\u09a6\u09c0 \u0993 \u0997\u09c1\u09b0\u09ae\u09bf\u09a4\u09c7\u09b0 \u09b9\u09c7\u09b2\u09bf\u0995\u09aa\u09cd\u099f\u09be\u09b0 \u099a\u09a1\u09bc\u09be\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/video-from-romania-viral-as-muslims-arrested-in-france-for-spitting-in-train-10689",
        headline:
          "\u099f\u09cd\u09b0\u09c7\u09a8\u09c7 \u09a5\u09c1\u09a4\u09c1 \u09ab\u09c7\u09b2\u09be\u09df \u09ab\u09cd\u09b0\u09be\u09a8\u09cd\u09b8\u09c7 \u09ae\u09c1\u09b8\u09b2\u09bf\u09ae\u09b0\u09be \u0997\u09cd\u09b0\u09c7\u09ab\u09a4\u09be\u09b0? \u099b\u09a1\u09bc\u09be\u09b2 \u09b0\u09cb\u09ae\u09be\u09a8\u09bf\u09df\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/video-of-rajputs-firing-linked-to-rajasthan-cracker-ban-in-diwali-2020-10734",
        headline: "            ?",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/video-from-romania-viral-as-muslims-arrested-in-france-for-spitting-in-train-10701",
        headline:
          "\u0930\u094b\u092e\u093e\u0928\u093f\u092f\u093e \u0915\u0940 \u0905\u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0918\u091f\u0928\u093e \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u094d\u0930\u093e\u0902\u0938 \u0938\u0947 \u091c\u094b\u095c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-police-did-not-seize-weapons-from-bijnor-mosque-670508",
        headline:
          "Fact Check: No, police did not seize weapons from Bijnor mosque",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-claim-that-muslims-were-spitting-on-passengers-inside-paris-metro-is-fake-news-670533",
        headline:
          "Fact Check: Viral claim that Muslims were spitting on passengers inside Paris metro is Fake News",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-burqa-clad-girls-in-viral-picture-are-not-keralas-women-police-but-students-670684",
        headline:
          "Fact check: Burqa-clad girls in viral picture are NOT Kerala's women police, but students",
      },
    ],
    "7": [
      {
        url:
          "https://www.altnews.in/hindi/video-of-a-woman-burnt-in-karnataka-shared-as-up-and-bihar/",
        headline:
          "\u0932\u095c\u0915\u0940 \u0915\u0947 \u091c\u0932\u0924\u0947 \u0939\u0941\u090f \u0936\u0935 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092c\u093f\u0939\u093e\u0930 \u092f\u093e UP \u0915\u093e \u0928\u0939\u0940\u0902 \u092c\u0932\u094d\u0915\u093f \u0915\u0930\u094d\u0928\u093e\u091f\u0915\u093e \u0915\u093e \u0939\u0948",
      },
      {
        url:
          "https://www.altnews.in/hindi/unrelated-old-photos-shared-with-recent-incident-of-vaishali-bihar-young-girl-burnt/",
        headline:
          "\u0935\u0948\u0936\u093e\u0932\u0940 \u092e\u0947\u0902 \u0932\u095c\u0915\u0940 \u0915\u094b \u092e\u093e\u0930\u0915\u0930 \u0915\u0941\u090f\u0902 \u092e\u0947\u0902 \u092b\u0947\u0902\u0915\u0928\u0947 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u092c\u093f\u0939\u093e\u0930 \u0915\u0947 \u0909\u0938\u0940 \u095b\u093f\u0932\u0947 \u092e\u0947\u0902 \u0939\u0941\u0908 \u0926\u0942\u0938\u0930\u0940 \u0939\u0924\u094d\u092f\u093e \u0915\u0940 \u092c\u0924\u093e\u0908",
      },
      {
        url:
          "https://www.altnews.in/hindi/old-image-of-tejashwi-yadav-shared-as-he-participated-in-a-candle-march-to-bring-justice-for-vaishalis-victim/",
        headline:
          "\u0924\u0947\u091c\u0938\u094d\u0935\u0940 \u092f\u093e\u0926\u0935 \u0915\u0940 2018 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u0939\u093f\u0902\u0938\u093e \u0915\u093e \u0936\u093f\u0915\u093e\u0930 \u0939\u0941\u0908 \u092f\u0941\u0935\u0924\u0940 \u0915\u0947 \u0932\u093f\u090f \u0915\u0948\u0902\u0921\u0932 \u092e\u093e\u0930\u094d\u091a \u0915\u0947 \u0928\u093e\u092e \u092a\u0930 \u0936\u0947\u092f\u0930",
      },
      {
        url:
          "https://www.altnews.in/video-of-a-woman-burnt-in-karnataka-shared-as-up-and-bihar/",
        headline:
          "Video of woman\u2019s charred dead body found in Karnataka falsely viral as UP and Bihar",
      },
      {
        url:
          "https://www.altnews.in/unrelated-old-photos-shared-with-recent-incident-of-vaishali-bihar-young-girl-burnt/",
        headline:
          "Photos of woman killed in Bihar\u2019s Vaishali in July linked with recent murder of Muslim woman",
      },
      {
        url:
          "https://www.altnews.in/old-image-of-tejashwi-yadav-shared-as-he-participated-in-a-candle-march-to-bring-justice-for-vaishalis-victim/",
        headline:
          "Old image falsely shared as Tejashwi Yadav leads protest march for woman burnt alive in Bihar",
      },
      {
        url:
          "https://factly.in/edited-video-shared-as-massive-fire-explosion-in-mangalore-city-of-karnataka/",
        headline:
          " Edited video shared as massive fire explosion in Mangalore city of Karnataka",
      },
      {
        url:
          "https://factly.in/unrelated-old-video-falsely-shared-as-people-demanding-the-recounting-of-votes-in-bihar/",
        headline:
          " Unrelated old video falsely shared as people demanding recounting of votes in Bihar",
      },
      {
        url:
          "https://factly.in/2018-photo-is-now-shared-as-tejashwi-yadavs-protest-march-for-the-20-year-old-girl-burnt-alive-in-bihar/",
        headline:
          " 2018 photo is now shared as Tejashwi Yadav\u2019s protest march for the 20-year-old girl burnt alive in Bihar",
      },
      {
        url:
          "https://factly.in/telugu-this-letter-purportedly-written-by-bandi-sanjay-is-fake/",
        headline:
          " GHMC \u0c32\u0c4b \u0c35\u0c30\u0c26 \u0c38\u0c39\u0c3e\u0c2f\u0c02 \u0c06\u0c2a\u0c2e\u0c28\u0c3f \u0c15\u0c47\u0c02\u0c26\u0c4d\u0c30 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c15\u0c2e\u0c3f\u0c37\u0c28\u0c4d \u0c15\u0c41 \u0c2c\u0c02\u0c21\u0c3f \u0c38\u0c02\u0c1c\u0c2f\u0c4d \u0c30\u0c3e\u0c38\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c09\u0c28\u0c4d\u0c28 \u0c08 \u0c32\u0c47\u0c16 \u0c2b\u0c47\u0c15\u0c4d.",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/16/fact-check-no-abp-news-did-not-tweet-about-re-imposition-of-lockdown-in-december-2020-heres-the-truth/",
        headline:
          "Fact Check: No, ABP News did not tweet about re-imposition of lockdown in December 2020; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/18/fact-check-no-this-picture-of-man-with-evm-is-not-from-bihar-heres-the-truth/",
        headline:
          "Fact Check: No, this picture of man with EVMs is NOT from Bihar; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/11/19/fact-check-2018-picture-of-tejashwi-yadavs-candle-march-falsely-shared-as-protest-against-gulnaz-murder-case/",
        headline:
          "Fact Check: 2018 picture of Tejashwi Yadav\u2019s candle march falsely shared as protest against Gulnaz murder case",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/disturbing-video-of-woman-burnt-in-karnataka-peddled-as-up-10700",
        headline: "Disturbing Video of Woman Burnt in Karnataka Peddled as UP",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/2018-photo-falsely-shared-as-tejashwi-yadav-marching-for-gulnaz-khatun-10707",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u095e\u094b\u091f\u094b \u092e\u0947\u0902 \u0917\u0941\u0932\u0928\u093e\u095b \u0959\u093e\u0924\u0942\u0928 \u0915\u0947 \u0932\u093f\u090f \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0928\u0939\u0940\u0902 \u0915\u0930 \u0930\u0939\u0947 \u0924\u0947\u091c\u0936\u094d\u0935\u0940 \u092f\u093e\u0926\u0935",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/disturbing-video-of-woman-burnt-in-karnataka-peddled-as-up-10702",
        headline:
          "\u0995\u09b0\u09cd\u09a8\u09be\u099f\u0995\u09c7 \u09ad\u09df\u09be\u09ac\u09b9 \u0985\u0997\u09cd\u09a8\u09bf\u09a6\u09cd\u09ac\u0997\u09cd\u09a7 \u098f\u0995 \u09af\u09c1\u09ac\u09a4\u09c0\u09b0 \u09a6\u09c7\u09b9 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/16/fact-check-no-abp-news-did-not-tweet-about-re-imposition-of-lockdown-in-december-2020-heres-the-truth/",
        headline:
          "Fact Check: No, ABP News did not tweet about re-imposition of lockdown in December 2020; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/18/fact-check-no-this-picture-of-man-with-evm-is-not-from-bihar-heres-the-truth/",
        headline:
          "Fact Check: No, this picture of man with EVMs is NOT from Bihar; Here\u2019s the truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2020/11/19/fact-check-2018-picture-of-tejashwi-yadavs-candle-march-falsely-shared-as-protest-against-gulnaz-murder-case/",
        headline:
          "Fact Check: 2018 picture of Tejashwi Yadav\u2019s candle march falsely shared as protest against Gulnaz murder case",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/disturbing-video-of-woman-burnt-in-karnataka-peddled-as-up-10700",
        headline: "Disturbing Video of Woman Burnt in Karnataka Peddled as UP",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/no-this-is-not-one-of-the-accused-in-bihars-gulnaz-khatun-case-10694",
        headline:
          "No, This Is Not One Of The Accused In Bihar's Gulnaz Khatun Case",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/india-today-aaj-tak-tweet-edited-video-of-indonesian-kid-dancing-10727",
        headline:
          "India Today, Aaj Tak Tweet Edited Video Of Indonesian Kid Dancing",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/photos-from-2019-maharashtra-polls-shared-as-evms-stolen-in-bihar-10673",
        headline:
          "Photos From 2019 Maharashtra Polls Shared As EVMs 'Stolen' In Bihar",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fake-news/2018-photo-falsely-shared-as-tejashwi-yadav-marching-for-gulnaz-khatun-10696",
        headline:
          "2018 Photo Falsely Shared As Tejashwi Yadav Marching For Gulnaz Khatun",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/disturbing-video-of-woman-burnt-in-karnataka-peddled-as-up-10702",
        headline:
          "\u0995\u09b0\u09cd\u09a8\u09be\u099f\u0995\u09c7 \u09ad\u09df\u09be\u09ac\u09b9 \u0985\u0997\u09cd\u09a8\u09bf\u09a6\u09cd\u09ac\u0997\u09cd\u09a7 \u098f\u0995 \u09af\u09c1\u09ac\u09a4\u09c0\u09b0 \u09a6\u09c7\u09b9 \u0989\u09a4\u09cd\u09a4\u09b0\u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/2018-photo-falsely-shared-as-tejashwi-yadav-marching-for-gulnaz-khatun-10712",
        headline:
          "\u0997\u09c1\u09b2\u09a8\u09be\u099c \u0996\u09be\u09a4\u09c1\u09a8\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u09aa\u09be \u09ae\u09c7\u09b2\u09be\u09b2\u09c7\u09a8 \u09a4\u09c7\u099c\u09b8\u09cd\u09ac\u09c0, \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09e8\u09e6\u09e7\u09ee'\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/no-this-is-not-one-of-the-accused-in-bihars-gulnaz-khatun-case-10706",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf \u09ac\u09bf\u09b9\u09be\u09b0\u09c7\u09b0 \u0997\u09c1\u09b2\u09a8\u09be\u099c \u0996\u09be\u09a4\u09c1\u09a8 \u09b9\u09a4\u09cd\u09af\u09be\u0995\u09be\u09a3\u09cd\u09a1\u09c7 \u0985\u09a8\u09cd\u09af\u09a4\u09ae \u0985\u09ad\u09bf\u09af\u09c1\u0995\u09cd\u09a4 \u09a8\u09a8",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fake-news/india-today-aaj-tak-tweet-edited-video-of-indonesian-kid-dancing-10741",
        headline:
          "\u0987\u09a8\u09cd\u09a6\u09cb\u09a8\u09c7\u09b6\u09bf\u09df\u09be\u09b0 \u09ac\u09be\u099a\u09cd\u099a\u09be\u09b0 \u09a8\u09be\u099a\u09c7\u09b0 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u09ad\u09bf\u09a1\u09bf\u0993 \u099f\u09c1\u0987\u099f \u0987\u09a8\u09cd\u09a1\u09bf\u09df\u09be \u099f\u09c1\u09a1\u09c7, \u0986\u099c \u09a4\u0995\u09c7\u09b0",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/2018-photo-falsely-shared-as-tejashwi-yadav-marching-for-gulnaz-khatun-10707",
        headline:
          "\u0935\u093e\u092f\u0930\u0932 \u095e\u094b\u091f\u094b \u092e\u0947\u0902 \u0917\u0941\u0932\u0928\u093e\u095b \u0959\u093e\u0924\u0942\u0928 \u0915\u0947 \u0932\u093f\u090f \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0928\u0939\u0940\u0902 \u0915\u0930 \u0930\u0939\u0947 \u0924\u0947\u091c\u0936\u094d\u0935\u0940 \u092f\u093e\u0926\u0935",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/old-video-of-yogi-adityanath-burning-crackers-viral-with-misleading-claims-10725",
        headline: "         ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fake-news/no-this-is-not-the-accused-in-bihars-gulnaz-khatun-case-10699",
        headline:
          "\u0928\u0939\u0940\u0902, \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u093e \u092f\u0941\u0935\u0915 \u092c\u093f\u0939\u093e\u0930 \u0915\u0947 \u0917\u0941\u0932\u0928\u093e\u095b \u0959\u093e\u0924\u0942\u0928 \u0915\u0947\u0938 \u0915\u093e \u0906\u0930\u094b\u092a\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-womans-charred-corpse-is-from-mysore-not-up-670657",
        headline:
          "Fact Check: Video of woman's charred corpse is from Mysore, not UP",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-unrelated-pictures-of-deceased-girl-passed-off-as-bihar-stalking-victim-670626",
        headline:
          "Fact Check: Old unrelated pictures of deceased girl passed off as Bihar stalking victim",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-tejashwi-yadav-did-not-lead-candle-march-for-bihar-stalking-victim-670625",
        headline:
          "FACT CHECK: No, Tejashwi Yadav did not lead candle march for Bihar stalking victim",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-filmmaker-vivek-agnihotri-shares-year-old-video-of-british-journalists-hackling-with-misleading-claim-670541",
        headline:
          "Fact check: Filmmaker Vivek Agnihotri shares year-old video of British journalist's hackling with misleading claim",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-bandi-sanjay-has-not-written-to-ec-for-stopping-relief-to-hyderabad-flood-victims-670623",
        headline:
          "Fact check: No, Bandi Sanjay has not written to EC for stopping relief to Hyderabad flood victims",
      },
    ],
  },
  numpy_seed: 20,
  number_of_articles: 180,
}
