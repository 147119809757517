export const data = {
  mdsDat: {
    x: [
      0.20945489357734537,
      -0.09346728556163025,
      0.24489099160062738,
      0.15099838727201667,
      -0.2229634571126606,
      -0.28891352977569806,
    ],
    y: [
      -0.1566361886496988,
      0.39769940903736156,
      -0.053088913591404635,
      0.07709034944177501,
      -0.07945687597133186,
      -0.18560778026670136,
    ],
    topics: [1, 2, 3, 4, 5, 6],
    cluster: [1, 1, 1, 1, 1, 1],
    Freq: [
      16.8802421262824,
      15.401508812276385,
      12.157484606247552,
      24.67088442603076,
      15.736236647952545,
      15.153643381210358,
    ],
  },
  tinfo: {
    Term: [
      "olympics",
      "tokyo",
      "gold",
      "traffic",
      "modi",
      "medal",
      "man",
      "opening_ceremony",
      "ship",
      "ceremony",
      "abdul",
      "phone",
      "surface",
      "donald",
      "asteroids",
      "artificial",
      "anniversary",
      "east_company",
      "gotheborg",
      "showers",
      "ceremony",
      "ship",
      "year",
      "opening_ceremony",
      "east",
      "company",
      "opening",
      "replica",
      "swedish",
      "tokyo",
      "olympics",
      "days_august",
      "bank",
      "heart",
      "august",
      "day",
      "right",
      "arrange",
      "shut",
      "ram",
      "model",
      "bjp",
      "chaturvedi",
      "pramod",
      "closed",
      "acharya",
      "name",
      "hoard",
      "gul",
      "remain",
      "dainik",
      "quarantine",
      "priya",
      "sona",
      "strike",
      "priya_malik",
      "announce",
      "skate",
      "zoyo",
      "camp",
      "wrestler",
      "gold",
      "malik",
      "mix",
      "postpone",
      "flag",
      "central",
      "hallmarking",
      "mandatory",
      "competition",
      "government",
      "olympics",
      "tokyo",
      "win",
      "indian",
      "medal",
      "border",
      "first",
      "aung",
      "pak",
      "pakistan_himachal",
      "poster",
      "kailash",
      "modi",
      "professional",
      "kyi",
      "traffic",
      "jam",
      "pradesh",
      "himachal",
      "traffic_jam",
      "pm",
      "china",
      "kishtwar",
      "uttarakhand",
      "credit",
      "cloudburst",
      "pakistan",
      "morphed",
      "medal",
      "nayar",
      "believe",
      "rs",
      "vote",
      "bangladesh_shared",
      "ge",
      "vio",
      "indians",
      "civil",
      "attacking",
      "vaccine",
      "mumbai",
      "doctor",
      "delhi",
      "expressway",
      "mask",
      "murder",
      "spread",
      "african",
      "south",
      "mountain",
      "people",
      "anti",
      "bangladesh",
      "kill",
      "street",
      "protest",
      "man",
      "taliban",
      "mock",
      "gujarat",
      "bull",
      "militant",
      "terrorist",
      "afghanistan",
      "attack",
      "r",
      "message",
      "capture",
      "fraud",
      "woman",
      "kashmir",
      "reservation",
      "state",
      "kill",
    ],
    Freq: [
      19.0,
      16.0,
      6.0,
      7.0,
      7.0,
      9.0,
      5.0,
      4.0,
      4.0,
      4.0,
      0.9992327235672916,
      1.9984654471345833,
      0.9992327235672916,
      0.9992327235672916,
      0.9992327235672916,
      1.9984654471345833,
      0.9992327235672916,
      2.997698170701875,
      1.9984654471345833,
      0.9992327235672916,
      4.996163617836459,
      4.996163617836459,
      4.996163617836459,
      4.996163617836459,
      3.9969308942691666,
      3.9969308942691666,
      3.9969308942691666,
      2.997698170701875,
      2.997698170701875,
      6.994629064971042,
      6.994629064971042,
      2.9931234106876743,
      2.9931234106876743,
      2.9931234106876743,
      2.9931234106876743,
      2.9931234106876743,
      0.9977078035625583,
      0.9977078035625583,
      0.9977078035625583,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.0017767315547983,
      4.007106926219193,
      1.0017767315547983,
      1.0017767315547983,
      3.005330194664395,
      1.0017767315547983,
      2.0035534631095966,
      1.0017767315547983,
      1.0017767315547983,
      4.007106926219193,
      6.01066038932879,
      4.007106926219193,
      3.005330194664395,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      9.015990583993183,
      8.014213852438386,
      3.005330194664395,
      3.005330194664395,
      3.005330194664395,
      3.0013005069462615,
      2.000867004630841,
      2.000867004630841,
      1.0004335023154205,
      3.0013005069462615,
      1.0004335023154205,
      1.0004335023154205,
      7.0030345162079435,
      1.0004335023154205,
      2.000867004630841,
      7.0030345162079435,
      6.002601013892523,
      6.002601013892523,
      6.002601013892523,
      5.0021675115771025,
      5.0021675115771025,
      5.0021675115771025,
      4.001734009261682,
      4.001734009261682,
      4.001734009261682,
      4.001734009261682,
      7.0030345162079435,
      6.002601013892523,
      6.002601013892523,
      1.000513811567353,
      1.000513811567353,
      1.000513811567353,
      2.001027623134706,
      1.000513811567353,
      1.000513811567353,
      1.000513811567353,
      2.001027623134706,
      1.000513811567353,
      1.000513811567353,
      4.002055246269412,
      3.0015414347020597,
      3.0015414347020597,
      3.0015414347020597,
      3.0015414347020597,
      3.0015414347020597,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      5.002569057836766,
      4.002055246269412,
      3.0015414347020597,
      2.001027623134706,
      3.0015414347020597,
      5.00264509059188,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      2.0010580362367523,
      2.0010580362367523,
      1.0005290181183761,
      2.0010580362367523,
      2.0010580362367523,
      2.0010580362367523,
      4.0021160724735045,
      2.0010580362367523,
      2.0010580362367523,
      3.0015870543551286,
      3.0015870543551286,
    ],
    Total: [
      19.0,
      16.0,
      6.0,
      7.0,
      7.0,
      9.0,
      5.0,
      4.0,
      4.0,
      4.0,
      0.9992327235672916,
      1.9984654471345833,
      0.9992327235672916,
      0.9992327235672916,
      0.9992327235672916,
      1.9984654471345833,
      0.9992327235672916,
      2.997698170701875,
      1.9984654471345833,
      0.9992327235672916,
      4.996163617836459,
      4.996163617836459,
      4.996163617836459,
      4.996163617836459,
      3.9969308942691666,
      3.9969308942691666,
      3.9969308942691666,
      2.997698170701875,
      2.997698170701875,
      16.00927641972485,
      19.011920155910484,
      2.9931234106876743,
      2.9931234106876743,
      2.9931234106876743,
      2.9931234106876743,
      2.9931234106876743,
      0.9977078035625583,
      0.9977078035625583,
      0.9977078035625583,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      1.9954156071251166,
      2.997192338679915,
      1.9954156071251166,
      1.0017767315547983,
      4.007106926219193,
      1.0017767315547983,
      1.0017767315547983,
      3.005330194664395,
      1.0017767315547983,
      2.0035534631095966,
      1.0017767315547983,
      1.0017767315547983,
      4.007106926219193,
      6.01066038932879,
      4.007106926219193,
      3.005330194664395,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      2.0035534631095966,
      19.011920155910484,
      16.00927641972485,
      5.0061971992952365,
      5.006357817799101,
      9.007931208556919,
      3.0013005069462615,
      2.000867004630841,
      2.000867004630841,
      1.0004335023154205,
      3.0013005069462615,
      1.0004335023154205,
      1.0004335023154205,
      7.0030345162079435,
      1.0004335023154205,
      2.000867004630841,
      7.0030345162079435,
      6.002601013892523,
      6.002601013892523,
      6.002601013892523,
      5.0021675115771025,
      5.0021675115771025,
      5.0021675115771025,
      4.001734009261682,
      4.001734009261682,
      4.001734009261682,
      4.001734009261682,
      8.004811247762742,
      7.000308817455081,
      9.007931208556919,
      1.000513811567353,
      1.000513811567353,
      1.000513811567353,
      2.001027623134706,
      1.000513811567353,
      1.000513811567353,
      1.000513811567353,
      2.001027623134706,
      1.000513811567353,
      1.000513811567353,
      4.002055246269412,
      3.0015414347020597,
      3.0015414347020597,
      3.0015414347020597,
      3.0015414347020597,
      3.0015414347020597,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      2.001027623134706,
      6.003002560152186,
      5.002488748584833,
      6.003128489057188,
      2.001027623134706,
      7.000549745210879,
      5.00264509059188,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      3.0015870543551286,
      2.0010580362367523,
      2.0010580362367523,
      1.0005290181183761,
      2.0010580362367523,
      2.0010580362367523,
      2.0010580362367523,
      6.003143695608211,
      2.0010580362367523,
      2.0010580362367523,
      4.003363785909927,
      6.003128489057188,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
      "Topic6",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -5.1591,
      -4.4659,
      -5.1591,
      -5.1591,
      -5.1591,
      -4.4659,
      -5.1591,
      -4.0604,
      -4.4659,
      -5.1591,
      -3.5496,
      -3.5496,
      -3.5496,
      -3.5496,
      -3.7728,
      -3.7728,
      -3.7728,
      -4.0604,
      -4.0604,
      -3.2131,
      -3.2131,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -3.9703,
      -5.0689,
      -5.0689,
      -5.0689,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.3758,
      -4.8283,
      -3.442,
      -4.8283,
      -4.8283,
      -3.7297,
      -4.8283,
      -4.1352,
      -4.8283,
      -4.8283,
      -3.442,
      -3.0366,
      -3.442,
      -3.7297,
      -4.1352,
      -4.1352,
      -4.1352,
      -4.1352,
      -4.1352,
      -4.1352,
      -4.1352,
      -2.6311,
      -2.7489,
      -3.7297,
      -3.7297,
      -3.7297,
      -4.4387,
      -4.8442,
      -4.8442,
      -5.5373,
      -4.4387,
      -5.5373,
      -5.5373,
      -3.5914,
      -5.5373,
      -4.8442,
      -3.5914,
      -3.7456,
      -3.7456,
      -3.7456,
      -3.9279,
      -3.9279,
      -3.9279,
      -4.151,
      -4.151,
      -4.151,
      -4.151,
      -3.5914,
      -3.7456,
      -3.7456,
      -5.0876,
      -5.0876,
      -5.0876,
      -4.3944,
      -5.0876,
      -5.0876,
      -5.0876,
      -4.3944,
      -5.0876,
      -5.0876,
      -3.7013,
      -3.989,
      -3.989,
      -3.989,
      -3.989,
      -3.989,
      -4.3944,
      -4.3944,
      -4.3944,
      -4.3944,
      -4.3944,
      -4.3944,
      -3.4782,
      -3.7013,
      -3.989,
      -4.3944,
      -3.989,
      -3.4404,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -3.9512,
      -4.3567,
      -4.3567,
      -5.0499,
      -4.3567,
      -4.3567,
      -4.3567,
      -3.6636,
      -4.3567,
      -4.3567,
      -3.9512,
      -3.9512,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      1.779,
      0.951,
      0.7791,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.8707,
      1.4639,
      1.8707,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      2.1072,
      1.3612,
      1.4153,
      1.5969,
      1.5969,
      1.0095,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.3995,
      1.2658,
      1.2458,
      0.9936,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.8492,
      1.6669,
      1.6261,
      1.156,
      1.8492,
      1.0023,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.8869,
      1.4815,
      1.8869,
      1.8869,
      1.5989,
      1.1938,
    ],
  },
  "token.table": {
    Topic: [
      1,
      2,
      6,
      5,
      1,
      3,
      4,
      5,
      2,
      1,
      1,
      6,
      5,
      2,
      4,
      4,
      5,
      5,
      2,
      5,
      2,
      4,
      6,
      3,
      6,
      3,
      1,
      2,
      4,
      5,
      2,
      4,
      1,
      3,
      4,
      2,
      2,
      2,
      5,
      5,
      1,
      1,
      1,
      5,
      4,
      3,
      6,
      5,
      3,
      1,
      3,
      6,
      2,
      3,
      2,
      4,
      2,
      3,
      5,
      5,
      4,
      4,
      6,
      5,
      6,
      4,
      4,
      3,
      6,
      3,
      5,
      3,
      4,
      6,
      6,
      3,
      6,
      2,
      4,
      2,
      4,
      5,
      5,
      5,
      2,
      5,
      1,
      3,
      4,
      1,
      1,
      4,
      3,
      4,
      4,
      5,
      1,
      4,
      4,
      3,
      4,
      2,
      3,
      3,
      4,
      2,
      4,
      5,
      3,
      6,
      2,
      2,
      3,
      1,
      6,
      2,
      5,
      1,
      1,
      2,
      3,
      3,
      5,
      5,
      3,
      6,
      5,
      3,
      1,
      1,
      6,
      6,
      1,
      3,
      4,
      4,
      4,
      4,
      5,
      5,
      5,
      3,
      4,
      5,
      6,
      3,
      1,
      3,
    ],
    Freq: [
      1.000767865597885,
      1.0022974626731964,
      0.9994712615938206,
      0.9994864522993959,
      1.000767865597885,
      0.9982264196214253,
      0.1665833039349309,
      0.8329165196746545,
      1.0022974626731964,
      1.000767865597885,
      1.000767865597885,
      0.9994712615938206,
      0.9994864522993959,
      1.0022974626731966,
      0.9995666855274067,
      0.19990049958291112,
      0.7996019983316445,
      0.9994864522993959,
      1.0022974626731966,
      0.9994864522993959,
      1.0022974626731964,
      0.9995666855274067,
      0.9994712615938205,
      0.9982264196214253,
      0.9994712615938206,
      0.9982264196214253,
      1.000767865597885,
      1.0022974626731964,
      0.9995666855274067,
      0.9994864522993959,
      1.0022974626731964,
      0.9995666855274067,
      1.000767865597885,
      0.9982264196214253,
      0.9995666855274067,
      1.0022974626731964,
      1.0022974626731966,
      1.0022974626731966,
      0.9994864522993957,
      0.9994864522993957,
      1.000767865597885,
      1.000767865597885,
      1.000767865597885,
      0.9994864522993957,
      0.9995666855274067,
      0.9982264196214253,
      0.9994712615938206,
      0.9994864522993959,
      0.9982264196214252,
      1.000767865597885,
      0.9982264196214253,
      0.9994712615938205,
      1.0022974626731964,
      0.9982264196214253,
      1.0022974626731966,
      0.9995666855274067,
      1.0022974626731964,
      0.5992380307564317,
      0.39949202050428784,
      0.9994864522993959,
      0.9995666855274067,
      0.9995666855274067,
      0.9994712615938206,
      0.49973942844444436,
      0.49973942844444436,
      0.9995666855274067,
      0.9995666855274067,
      0.9982264196214253,
      0.9994712615938207,
      0.9982264196214253,
      0.9994864522993957,
      0.3330398435048222,
      0.6660796870096444,
      0.9994712615938206,
      0.9994712615938205,
      0.9982264196214252,
      0.9994712615938205,
      1.0022974626731964,
      0.9995666855274067,
      0.1428508407381296,
      0.8571050444287776,
      0.9994864522993959,
      0.9994864522993957,
      0.9994864522993959,
      1.0022974626731964,
      0.9994864522993959,
      0.36819005879444633,
      0.47338721845000237,
      0.15779573948333414,
      1.000767865597885,
      1.000767865597885,
      0.9995666855274067,
      0.12492486943767589,
      0.8744740860637312,
      0.9995666855274067,
      0.9994864522993959,
      1.000767865597885,
      0.9995666855274067,
      0.9995666855274067,
      0.9982264196214253,
      0.9995666855274067,
      1.0022974626731964,
      0.9982264196214253,
      0.9982264196214252,
      0.9995666855274067,
      0.142845924448163,
      0.428537773344489,
      0.428537773344489,
      0.9982264196214253,
      0.9994712615938206,
      1.0022974626731964,
      0.6672911758745791,
      0.33364558793728954,
      1.000767865597885,
      0.9994712615938206,
      1.0022974626731964,
      0.9994864522993959,
      1.000767865597885,
      1.000767865597885,
      1.0022974626731964,
      0.9982264196214253,
      0.9982264196214253,
      0.9994864522993959,
      0.9994864522993959,
      0.24978994002982155,
      0.7493698200894647,
      0.9994864522993959,
      0.9982264196214253,
      1.000767865597885,
      1.000767865597885,
      0.9994712615938205,
      0.9994712615938205,
      0.4372464948743954,
      0.4997102798564519,
      0.062463784982056486,
      0.9995666855274067,
      0.9995666855274067,
      0.9995666855274067,
      0.9994864522993959,
      0.9994864522993959,
      0.9994864522993959,
      0.5992572566702595,
      0.39950483778017304,
      0.3331587750370132,
      0.6663175500740264,
      0.9982264196214253,
      1.000767865597885,
      0.9982264196214253,
    ],
    Term: [
      "abdul",
      "acharya",
      "afghanistan",
      "african",
      "anniversary",
      "announce",
      "anti",
      "anti",
      "arrange",
      "artificial",
      "asteroids",
      "attack",
      "attacking",
      "august",
      "aung",
      "bangladesh",
      "bangladesh",
      "bangladesh_shared",
      "bank",
      "believe",
      "bjp",
      "border",
      "bull",
      "camp",
      "capture",
      "central",
      "ceremony",
      "chaturvedi",
      "china",
      "civil",
      "closed",
      "cloudburst",
      "company",
      "competition",
      "credit",
      "dainik",
      "day",
      "days_august",
      "delhi",
      "doctor",
      "donald",
      "east",
      "east_company",
      "expressway",
      "first",
      "flag",
      "fraud",
      "ge",
      "gold",
      "gotheborg",
      "government",
      "gujarat",
      "gul",
      "hallmarking",
      "heart",
      "himachal",
      "hoard",
      "indian",
      "indian",
      "indians",
      "jam",
      "kailash",
      "kashmir",
      "kill",
      "kill",
      "kishtwar",
      "kyi",
      "malik",
      "man",
      "mandatory",
      "mask",
      "medal",
      "medal",
      "message",
      "militant",
      "mix",
      "mock",
      "model",
      "modi",
      "morphed",
      "morphed",
      "mountain",
      "mumbai",
      "murder",
      "name",
      "nayar",
      "olympics",
      "olympics",
      "olympics",
      "opening",
      "opening_ceremony",
      "pak",
      "pakistan",
      "pakistan",
      "pakistan_himachal",
      "people",
      "phone",
      "pm",
      "poster",
      "postpone",
      "pradesh",
      "pramod",
      "priya",
      "priya_malik",
      "professional",
      "protest",
      "protest",
      "protest",
      "quarantine",
      "r",
      "ram",
      "remain",
      "remain",
      "replica",
      "reservation",
      "right",
      "rs",
      "ship",
      "showers",
      "shut",
      "skate",
      "sona",
      "south",
      "spread",
      "state",
      "state",
      "street",
      "strike",
      "surface",
      "swedish",
      "taliban",
      "terrorist",
      "tokyo",
      "tokyo",
      "tokyo",
      "traffic",
      "traffic_jam",
      "uttarakhand",
      "vaccine",
      "vio",
      "vote",
      "win",
      "win",
      "woman",
      "woman",
      "wrestler",
      "year",
      "zoyo",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5, 6],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/5000-years-old-vishnu-idol-underwater-sea-temple-in-bali-indonesia-fake-news-14046",
        headline: "   5       ?  ",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/taiwans-new-year-celebration-passed-off-as-olympic-opening-ceremony-681261",
        headline:
          "Taiwan's New Year celebration passed off as Olympic opening ceremony",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/beat-the-heat-with-artificial-showers-in-pure-dubai-style-681217",
        headline: "Beat the heat with artificial showers in pure Dubai style",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-were-the-tokyo-2020-olympics-rings-made-of-trees-planted-in-1964-681293",
        headline:
          "Fact Check: Were the Tokyo 2020 Olympics rings made of trees planted in 1964?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-did-architect-of-iraq-war-donald-rumsfeld-commit-suicide-681216",
        headline:
          "Fact Check: Did architect of Iraq war Donald Rumsfeld commit suicide?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-is-this-the-photo-of-a-282-year-old-east-india-trading-company-ship-681315",
        headline:
          "Fact Check: Is this the photo of a 282-year-old East India Trading Company ship?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-is-clubhouse-hacking-subscribers-phone-data-681303",
        headline: "Fact Check: Is Clubhouse hacking subscribers' phone data?",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/rajinikanth-medical-college-of-engineering-for-commerce-and-arts-viral-image-fake-news-14127",
        headline:
          "Rajinikanth College? Netizens Share Edited Image Of Mgmt Institute",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/dainik-bhaskar-tweet-income-tax-raid-delhi-jaipur-bhopal-fake-news-14081",
        headline: "          ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fact-check-tokyo-olympics-surya-namaskar-viral-video-old-mongolia-yoga-event-video-14065",
        headline:
          "\u09e8\u09e6\u09e7\u09eb \u09b8\u09be\u09b2\u09c7 \u09ae\u09cb\u0999\u09cd\u0997\u09cb\u09b2\u09bf\u09df\u09be\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09ac\u09b2\u09be \u09b9\u09b2 \u099f\u09cb\u0995\u09bf\u0993 \u0985\u09b2\u09bf\u09ae\u09cd\u09aa\u09bf\u0995\u09c7 \u09b8\u09c2\u09b0\u09cd\u09af \u09a8\u09ae\u09b8\u09cd\u0995\u09be\u09b0",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/apj-abdul-kalam-death-anniversary-1997-image-of-former-president-of-india-missile-man-viral-with-misleading-claims-14054",
        headline:
          "\u09ae\u09c3\u09a4\u09cd\u09af\u09c1 \u09ac\u09be\u09b0\u09cd\u09b7\u09bf\u0995\u09c0\u09a4\u09c7 \u09ae\u09bf\u09a5\u09cd\u09af\u09c7 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09aa\u09cd\u09b0\u09be\u0995\u09cd\u09a4\u09a8 \u09b0\u09be\u09b7\u09cd\u099f\u09cd\u09b0\u09aa\u09a4\u09bf \u0986\u09ac\u09cd\u09a6\u09c1\u09b2 \u0995\u09be\u09b2\u09be\u09ae\u09c7\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/computer-generated-special-effects-video-falsely-shared-as-moon-hit-by-asteroid-14106",
        headline:
          "\u099a\u09be\u0981\u09a6\u09c7\u09b0 \u09ac\u09c1\u0995\u09c7 \u0986\u099b\u09a1\u09bc\u09c7 \u09aa\u09a1\u09bc\u09b2 \u0997\u09cd\u09b0\u09b9\u09be\u09a3\u09c1 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09c3\u09a4\u09cd\u09b0\u09bf\u09ae \u09ad\u09bf\u09a1\u09bf\u0993",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/viral-video-bull-attacks-kills-man-muslim-hindu-fake-news-14125",
        headline:
          "\u09b7\u09be\u0981\u09dc\u09c7\u09b0 \u0997\u09c1\u0981\u09a4\u09cb\u09df \u098f\u0995 \u09ac\u09cd\u09af\u0995\u09cd\u09a4\u09bf\u09b0 \u09ae\u09c3\u09a4\u09cd\u09af\u09c1\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/image-of-anukul-thakur-printed-on-soccer-player-leo-messis-vest-is-photoshopped-14124",
        headline:
          "\u09ab\u09c1\u099f\u09ac\u09b2\u09be\u09b0 \u09b2\u09bf\u0993\u09a8\u09c7\u09b2 \u09ae\u09c7\u09b8\u09bf\u09b0 \u0997\u09c7\u099e\u09cd\u099c\u09bf\u09a4\u09c7 \u0985\u09a8\u09c1\u0995\u09c2\u09b2 \u09a0\u09be\u0995\u09c1\u09b0? \u099b\u09ac\u09bf\u099f\u09bf \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4",
      },
      {
        url:
          "https://digiteye.in/old-video-of-mangolia-surfaces-as-that-of-opening-ceremony-of-tokyo-olympics-fact-check-old-video/",
        headline:
          "Old video of Mangolia surfaces as that of Opening Ceremony of Tokyo Olympics: Fact Check old video",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-was-surya-namaskar-performed-at-opening-ceremony-of-tokyo-olympics-2020-heres-the-truth/",
        headline:
          "Fact Check: Was Surya Namaskar Performed At Opening Ceremony Of Tokyo Olympics 2020? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-replica-of-swedish-east-india-companys-ship-gotheborg-peddled-as-real/",
        headline:
          "Fact Check: Replica Of Swedish East India Company\u2019s Ship \u2018Gotheborg\u2019 Peddled As Real",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-no-this-video-does-not-show-the-firework-display-at-tokyo-olympics-2020-heres-the-truth/",
        headline:
          "Fact Check: NO, This Video Does NOT Show The Firework Display At Tokyo Olympics 2020; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/30/fact-check-old-video-from-2018-viral-as-recent-earthquake-in-alaska/",
        headline:
          "Fact Check: Old Video From 2018 Viral As Recent Earthquake In Alaska",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-old-picture-of-west-bengals-sabooj-sathi-bicycle-covered-in-weed-shared-as-recent/",
        headline:
          "Fact Check: Old Picture Of West Bengal\u2019s Sabooj Sathi Bicycle Covered In Weed Shared As Recent",
      },
      {
        url:
          "https://factly.in/flipkart-is-not-offering-free-mi-11-lite-mobile-phones-on-its-15th-anniversary/",
        headline:
          " Flipkart is not offering free Mi-11 Lite mobile phones on its 15th anniversary",
      },
      {
        url:
          "https://factly.in/this-photo-is-of-a-swedish-replica-ship-started-in-2005-not-of-a-282-year-old-east-india-company-ship/",
        headline:
          " This photo is of a Swedish replica ship started in 2005, not of a 282-Year-Old East India Company Ship",
      },
      {
        url:
          "https://factly.in/these-videos-do-not-show-fireworks-from-the-2020-tokyo-olympics-opening-ceremony/",
        headline:
          " These videos do not show fireworks from the 2020 Tokyo Olympics opening ceremony",
      },
      {
        url:
          "https://factly.in/image-of-former-akali-minister-is-digitally-morphed-so-as-to-look-like-he-is-carrying-a-condom-packet/",
        headline:
          " Image of former Punjab minister is digitally morphed to make it look like he is carrying a condom packet",
      },
      {
        url:
          "https://factly.in/telugu-isro-moved-the-apple-satellite-on-a-bullock-cart-in-1981-to-test-the-antenna-in-the-open-field/",
        headline:
          " 1981\u0c32\u0c4b \u0c07\u0c38\u0c4d\u0c30\u0c4b \u0c38\u0c02\u0c38\u0c4d\u0c25 \u2018APPLE\u2019 \u0c36\u0c3e\u0c1f\u0c3f\u0c32\u0c48\u0c1f\u0c4d\u200c\u0c28\u0c3f \u0c0e\u0c21\u0c4d\u0c32\u0c2c\u0c02\u0c21\u0c3f \u0c2a\u0c48 \u0c24\u0c30\u0c32\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c13\u0c2a\u0c46\u0c28\u0c4d \u0c2b\u0c40\u0c32\u0c4d\u0c21\u0c4d\u200c\u0c32\u0c4b \u0c2f\u0c3e\u0c02\u0c1f\u0c46\u0c28\u0c4d\u0c28\u0c3e \u0c2a\u0c30\u0c40\u0c15\u0c4d\u0c37 \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c21\u0c3e\u0c28\u0c3f\u0c15\u0c3f, \u0c28\u0c3f\u0c27\u0c41\u0c32 \u0c15\u0c4a\u0c30\u0c24 \u0c35\u0c32\u0c28 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-new-year-celebration-in-taiwan-passed-off-as-opening-ceremony-of-tokyo-olympics-1833387-2021-07-27",
        headline:
          "Fact Check: New Year Celebration in Taiwan passed off as opening ceremony of Tokyo Olympics",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-it-s-not-a-282-year-old-ship-but-a-replica-of-swedish-east-india-company-s-gotheborg-1832913-2021-07-26",
        headline:
          "Fact Check: No, it's not a 282-year-old ship, but a replica of Swedish East India Company's Gotheborg",
      },
    ],
    "2": [
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/fake-news-fake-check-namo-again-priyanka-chaturvedi-photoshopped-shiv-sena-bjp-14035",
        headline:
          "Morphed Photo Of Priyanka Chaturvedi Sporting 'Namo Again' Revived",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/dainik-bhaskar-hoarding-uttar-pradesh-yogi-adityanath-it-raid-fake-news-14039",
        headline:
          "\u09ac\u09bf\u099c\u09c7\u09aa\u09bf\u0995\u09c7 \u09a8\u09bf\u09b6\u09be\u09a8\u09be \u0995\u09b0\u09be \u09a6\u09c8\u09a8\u09bf\u0995 \u09ad\u09be\u09b8\u09cd\u0995\u09b0\u09c7\u09b0 \u09b9\u09cb\u09b0\u09cd\u09a1\u09bf\u0982\u09af\u09bc\u09c7\u09b0 \u09ad\u09be\u0987\u09b0\u09be\u09b2 \u099b\u09ac\u09bf \u09ad\u09c1\u09df\u09cb",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/image-of-diorama-set-up-created-by-dilip-sarkar-revived-as-mortal-remains-of-indian-army-soldier-14038",
        headline:
          "\u09ae\u09c3\u09a4 \u09ad\u09be\u09b0\u09a4\u09c0\u09df \u09b8\u09c7\u09a8\u09be\u09b0 \u09a6\u09c7\u09b9\u09be\u09ac\u09b6\u09c7\u09b7 \u09ac\u09b2\u09c7 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ab\u09c7\u09b0 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u09a1\u09be\u09df\u09cb\u09b0\u09be\u09ae\u09be \u09ae\u09a1\u09c7\u09b2",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-hindi-daily-dainik-bhaskar-hoarding-is-photoshopped-681147",
        headline:
          "Fact Check: Viral Hindi Daily Dainik Bhaskar hoarding is photoshopped",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-shiv-sena-mp-priyanka-chaturvedi-did-not-wear-kurti-with-namo-again-slogan-681166",
        headline:
          "Fact Check: Shiv Sena MP Priyanka Chaturvedi did not wear kurti with `Namo Again' slogan",
      },
      {
        url:
          "https://newsmeter.in/fact-check/defence/eastern-naval-command-pays-homage-to-bravehearts-on-kargil-vijay-diwas-681159",
        headline:
          "Eastern Naval Command pays homage to bravehearts on 'Kargil Vijay Diwas'",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-girl-in-viral-image-is-not-afghan-ambassadors-daughter-but-pakistani-transgender-activist-681143",
        headline:
          "Fact Check: Girl in viral Image is not Afghan ambassador's daughter, but Pakistani transgender activist",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-not-all-banks-will-be-shut-for-15-days-in-august-681314",
        headline:
          "Fact Check: No, not all banks will be shut for 15 days in August",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/did-a-girl-hold-a-boycott-bollywood-placard-during-a-protest-demonstration-681354",
        headline:
          "Did a girl hold a 'Boycott Bollywood' placard during a protest demonstration?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photo-of-weightlifter-mirabai-chanu-ex-jnu-student-leader-shehla-rashid-shared-with-misleading-claims-681175",
        headline:
          "Fact Check: Photo of weightlifter Mirabai Chanu, ex-JNU student leader Shehla Rashid shared with misleading claims",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-laddoo-throwing-video-is-not-related-to-ram-temple-681177",
        headline:
          "Fact Check: Laddoo throwing video is not related to Ram temple",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-unvaccinated-americans-will-not-be-imprisoned-viral-claim-is-false-681225",
        headline:
          "Fact Check: Unvaccinated Americans will not be imprisoned, viral claim is false",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/ndtv-reporter-ravish-kumar-rolling-on-ground-viral-video-photo-fact-check-14089",
        headline: "            ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/ex-cji-ranjan-gogoi-tweet-lal-bahadur-shastri-death-indira-gandhi-viral-post-fake-news-14129",
        headline: "      CJI      ",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/27/fact-check-did-rss-members-create-rukus-while-seeking-donations-for-ram-mandir-heres-the-truth/",
        headline:
          "Fact Check: Did RSS Members Create Rukus While Seeking Donations For Ram Mandir? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-old-video-of-religious-cleric-referring-to-badrinath-temple-attributed-to-aap-for-upcoming-elections/",
        headline:
          "Fact Check: Old Video Of Religious Cleric Referring To Badrinath Temple Attributed To AAP For Upcoming Elections",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/29/fact-check-banks-across-the-country-will-not-remain-closed-for-15-days-in-august-post-is-misleading/",
        headline:
          "Fact Check: Banks Across The Country Will NOT Remain Closed For 15 Days In August; Post Is Misleading",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-congress-leader-acharya-pramod-krishnams-real-name-is-not-gul-shamad-khan-claim-is-fake/",
        headline:
          "Fact Check: Congress Leader Acharya Pramod Krishnam\u2019s Real Name Is NOT Gul Shamad Khan; Claim is FAKE",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-does-this-video-show-a-special-sunrise-on-the-himalayas-heres-the-truth/",
        headline:
          "Fact Check: Does This Video Show A Special Sunrise On The Himalayas? Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/this-photo-of-dainik-bhaskars-hoarding-against-the-bjp-was-morphed/",
        headline:
          " This Photo of Dainik Bhaskar\u2019s Hoarding criticizing BJP is morphed",
      },
      {
        url:
          "https://factly.in/telugu-cow-heart-cannot-be-transplanted-into-a-human-only-tissues-around-it-are-used-to-make-bioprosthetic-valves/",
        headline:
          " \u0c2e\u0c28\u0c3f\u0c37\u0c3f \u0c17\u0c41\u0c02\u0c21\u0c46 \u0c38\u0c4d\u0c25\u0c3e\u0c28\u0c02\u0c32\u0c4b \u0c06\u0c35\u0c41 \u0c17\u0c41\u0c02\u0c21\u0c46\u0c28\u0c3f \u0c05\u0c2e\u0c30\u0c4d\u0c1a\u0c32\u0c47\u0c2e\u0c41, \u0c15\u0c47\u0c35\u0c32\u0c02 \u0c06\u0c35\u0c41 \u0c17\u0c41\u0c02\u0c21\u0c46 \u0c1a\u0c41\u0c1f\u0c4d\u0c1f\u0c42 \u0c09\u0c02\u0c21\u0c47 \u0c1f\u0c3f\u0c37\u0c4d\u0c2f\u0c42\u0c38\u0c4d\u200c \u0c26\u0c4d\u0c35\u0c3e\u0c30\u0c3e \u0c2c\u0c2f\u0c4b \u0c2a\u0c4d\u0c30\u0c4b\u0c38\u0c4d\u0c24\u0c46\u0c1f\u0c3f\u0c15\u0c4d \u0c35\u0c3e\u0c32\u0c4d\u0c35\u0c4d \u0c24\u0c2f\u0c3e\u0c30\u0c41 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/private-and-public-banks-across-the-country-will-not-remain-closed-for-15-days-in-august-2021/",
        headline:
          " Private & Public banks across the country will not remain closed for 15 days in August 2021",
      },
      {
        url:
          "https://factly.in/no-acharya-pramod-krishnams-original-name-is-not-gul-shamad-khan-and-he-is-a-hindu-by-birth/",
        headline:
          " Acharya Pramod Krishnam\u2019s original name is not Gul Shamad Khan, and he is a Hindu by birth",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-no-meenakshi-lekhi-s-father-in-law-didn-t-defend-nathuram-godse-in-court-1832959-2021-07-26",
        headline:
          "Fact Check: No, Meenakashi Lekhi\u2019s father-in-law didn\u2019t defend Nathuram Godse in court",
      },
    ],
    "3": [
      {
        url:
          "https://digiteye.in/india-winning-gold-at-budapest-goes-viral-as-winning-gold-at-tokyo-olympics-fact-check/",
        headline:
          "India winning gold at Budapest goes viral as winning gold at Tokyo Olympics; Fact Check",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-wrestler-priya-malik-strikes-gold-but-not-in-olympics-681142",
        headline:
          "Fact Check: Wrestler Priya Malik strikes gold\u2026 but not in Olympics",
      },
      {
        url:
          "https://factly.in/central-government-will-retain-98-3-of-central-excise-duty-whereas-the-remaining-1-7-is-distributed-among-the-states/",
        headline:
          " Central government retains 98.3% of Central Excise duty, whereas the remaining 1.7% is distributed among the states",
      },
      {
        url:
          "https://factly.in/telugu-surya-namaskar-at-an-event-in-mongolia-is-falsely-linked-to-tokyo-olympics/",
        headline:
          " \u0c2d\u0c3e\u0c30\u0c24 \u0c1c\u0c3e\u0c24\u0c40\u0c2f \u0c1c\u0c46\u0c02\u0c21\u0c3e \u0c30\u0c02\u0c17\u0c41\u0c32\u0c24\u0c4b \u0c38\u0c42\u0c30\u0c4d\u0c2f \u0c28\u0c2e\u0c38\u0c4d\u0c15\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c15\u0c3f \u0c1f\u0c4b\u0c15\u0c4d\u0c2f\u0c4b \u0c12\u0c32\u0c3f\u0c02\u0c2a\u0c3f\u0c15\u0c4d\u0c38\u0c4d\u200c\u0c24\u0c4b \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-medal-mix-up-at-2019-figure-skating-competition-in-france-shared-as-2020-olympics-681389",
        headline:
          "Fact Check: Video of medal mix-up at 2019 figure skating competition in France shared as 2020 Olympics",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-centre-has-not-postponed-mandatory-hallmarking-of-gold-681182",
        headline:
          "Fact Check: Centre has not postponed mandatory hallmarking of gold",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-viral-image-claiming-tokyo-is-asias-most-populous-city-is-false-681274",
        headline:
          "Fact Check: Viral image claiming Tokyo is Asia's most populous city is false",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-tokyo-olympics-medal-mix-up-viral-video-figure-skating-gran-prix-france-old-video-14104",
        headline:
          "Video Of Medal Mix-Up In Skating Grand Prix Shared As Tokyo Olympics",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/weekly-wrap-top-5-fake-news-pakistans-video-shared-as-himachal-pradesh-priya-malik-wins-medal-in-tokyo-olympic-14110",
        headline:
          "\u09eb \u09ad\u09c1\u09df\u09cb \u09ab\u09cb\u09dc\u09a8: \u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09c7\u09b0 \u09af\u09be\u09a8\u09af\u099f \u09b9\u09b2 \u09ad\u09be\u09b0\u09a4\u09c7\u09b0, \u09aa\u09cd\u09b0\u09bf\u09df\u09be \u09b8\u09cb\u09a8\u09be \u099c\u09bf\u09a4\u09b2 \u0985\u09b2\u09bf\u09ae\u09cd\u09aa\u09bf\u0995\u09c7?",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/26/fact-check-no-the-government-has-not-postponed-the-mandatory-hallmarking-on-gold-jewellery-till-june-2022/",
        headline:
          "Fact Check: No, The Government Has NOT Postponed The Mandatory Hallmarking On Gold Jewellery Till June 2022",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/26/fact-check-old-video-from-2018-shared-as-mla-ramkesh-meena-beaten-for-tearing-down-saffron-flag/",
        headline:
          "Fact Check: Old Video From 2018 Shared As MLA Ramkesh Meena Beaten For Tearing Down Saffron Flag",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/27/fact-check-no-biden-did-not-announce-quarantine-camps-for-non-vaccinated-americans-its-a-satire/",
        headline:
          "Fact Check: No, Biden Did NOT Announce \u2018Quarantine Camps\u2019 For Non-Vaccinated Americans; It\u2019s A Satire",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/27/fact-check-no-indian-wrestler-priya-malik-did-not-win-gold-at-tokyo-olympics-2020-heres-the-truth/",
        headline:
          "Fact Check: No, Indian Wrestler Priya Malik Did NOT Win Gold At Tokyo Olympics 2020; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-no-this-video-does-not-show-an-rrs-durga-vahini-member-defeating-a-pakistani-wrestler/",
        headline:
          "Fact Check: No, This Video Does NOT Show An RRS Durga Vahini Member Defeating A Pakistani Wrestler",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-no-this-medal-mix-up-did-not-happen-at-tokyo-olympics-2020-heres-the-truth/",
        headline:
          "Fact Check: No, This Medal Mix-Up Did NOT Happen At Tokyo Olympics 2020; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/unrelated-video-shared-as-the-visuals-of-gymnastics-competition-at-the-tokyo-olympics/",
        headline:
          " Unrelated video shared as the visuals of gymnastics competition at the Tokyo Olympics",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-indian-athletes-tribal-dance-is-falsely-linked-to-tokyo-olympics/",
        headline:
          " \u0c1f\u0c4b\u0c15\u0c4d\u0c2f\u0c4b \u0c12\u0c32\u0c3f\u0c02\u0c2a\u0c3f\u0c15\u0c4d\u0c38\u0c4d\u200c\u0c32\u0c4b \u0c2d\u0c3e\u0c30\u0c24 \u0c15\u0c4d\u0c30\u0c40\u0c21\u0c3e\u0c15\u0c3e\u0c30\u0c41\u0c32 \u0c06\u0c26\u0c3f\u0c35\u0c3e\u0c38\u0c40 \u0c21\u0c3e\u0c28\u0c4d\u0c38\u0c4d \u0c05\u0c02\u0c1f\u0c42 \u0c12\u0c15 \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-netizens-congratulate-priya-malik-but-post-wrestler-sonam-malik-s-picture-1832890-2021-07-26",
        headline:
          "Fact Check: Netizens congratulate Priya Malik but post wrestler Sonam Malik\u2019s picture",
      },
    ],
    "4": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/viral-video-of-traffic-jam-towards-naran-kaghan-valley-pakistan-falsely-linked-with-himachal-pradesh-kinnaur-landslide-14040",
        headline:
          "Video Of Traffic Jam In Pak Valley Falsely Shared As Himachal Rockslide",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/viral-video-traffic-jam-khandala-video-from-pakistan-naran-kaghan-valley-eid-holiday-14044",
        headline: "Video Of Traffic Jam In Pakistan Peddled As Khandala",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-morphed-image-crediting-modi-for-indias-first-olympic-medal-goes-viral-681321",
        headline:
          "Fact Check: Morphed image crediting Modi for India's first Olympic medal goes viral",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-massive-traffic-jam-is-from-pakistan-not-himachal-pradesh-681317",
        headline:
          "Fact Check: Video of massive traffic jam is from Pakistan, not Himachal Pradesh",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/viral-image-of-heavily-garlanded-french-president-macron-is-morphed-681323",
        headline:
          "Viral image of heavily garlanded French President Macron is morphed",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-floods-in-bangladesh-passed-off-as-deluge-in-india-681223",
        headline:
          "Fact Check: Floods in Bangladesh passed off as deluge in India",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/old-image-of-uttarakhand-floods-passed-off-as-kishtwar-cloudburst-681289",
        headline:
          "Old image of Uttarakhand floods passed off as Kishtwar cloudburst",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-doctored-image-shows-banner-thanking-modi-for-mirabai-chanus-olympics-silver-medal-681396",
        headline:
          "Fact Check: Doctored image shows banner thanking Modi for Mirabai Chanu's Olympics silver medal",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-video-of-jawans-protesting-not-related-to-current-assam-mizoram-border-crisis-681350",
        headline:
          "Fact Check: Video of 'jawans' protesting not related to current Assam-Mizoram border crisis",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-chanu-mirabai-olympics-silver-medal-narendra-modi-thank-you-poster-felicitation-ceremony-anurag-thakur-factcheck-14114",
        headline:
          "Photo Of Poster Thanking PM Modi For Tokyo Olympics Medal Is Morphed",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-france-president-emmanuel-macron-covered-flower-garlands-photo-morphed-14088",
        headline:
          "Image of France President Macron Covered In Garlands Is Morphed",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-japan-tsunami-video-from-2011-shared-as-cars-airplanes-swept-away-by-flood-water-china-14055",
        headline:
          "2011 Video Of Tsunami In Japan Shared As Recent China Floods",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-aaj-tak-sweta-singh-narendra-modi-india-olympics-medal-win-mirabai-chanu-factcheck-14069",
        headline:
          "Aaj Tak Graphic Crediting PM Modi For India's Olympics Medal Win Is Fake",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/clip-of-protest-by-home-guards-during-assam-assembly-polls-2021-shared-as-recent-lailapur-cachar-assam-mizoram-border-dispute-14085",
        headline:
          "Video Of Protest By Home Guards During Assam Polls Shared As Recent",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fact-check-jammu-kishtwar-cloudburst-viral-image-uttarakhand-pithoragarh-cloudburst-old-photo-14066",
        headline:
          "2016 Image From Uttarakhand Shared As Cloudburst In Jammu's Kishtwar",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/narendra-modi-mirabai-chanu-wins-silver-medal-tokyo-olympics-2020-aaj-tak-14072",
        headline:
          "Olympic Medal Win: \u092a\u0940\u090f\u092e \u092e\u094b\u0926\u0940 \u0915\u094b \u0915\u094d\u0930\u0947\u0921\u093f\u091f \u0926\u0947\u0924\u0940 \u0906\u091c\u0924\u0915 \u0915\u0940 \u092f\u0947 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u0930\u094d\u095b\u0940 \u0939\u0948",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/brahman-dalit-caste-eurasian-dna-dalit-viral-image-14111",
        headline: "'   ,     '...   ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/fake-news-japan-tsunami-video-from-2011-shared-as-cars-airplanes-swept-away-by-flood-water-china-14091",
        headline:
          "\u09e8\u09e6\u09e7\u09e7 \u09b8\u09be\u09b2\u09c7\u09b0 \u099c\u09be\u09aa\u09be\u09a8\u09c7\u09b0 \u09b8\u09c1\u09a8\u09be\u09ae\u09bf\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099a\u09bf\u09a8\u09c7 \u09ac\u09a8\u09cd\u09af\u09be\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/viral-video-of-traffic-jam-towards-naran-kaghan-valley-pakistan-falsely-linked-with-himachal-pradesh-kinnaur-landslide-14070",
        headline:
          "\u09aa\u09be\u0995\u09bf\u09b8\u09cd\u09a4\u09be\u09a8\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099b\u09a1\u09bc\u09be\u09b2 \u09b9\u09bf\u09ae\u09be\u099a\u09b2 \u09aa\u09cd\u09b0\u09a6\u09c7\u09b6\u09c7 \u09ad\u09c2\u09ae\u09bf\u09a7\u09b8\u09c7\u09b0 \u099c\u09c7\u09b0\u09c7 \u09af\u09be\u09a8\u099c\u099f \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://digiteye.in/old-uttarakhand-image-of-2016-being-shared-as-that-of-kishtwar-cloudburst-now-fact-check/",
        headline:
          "Old Uttarakhand image of 2016 being shared as that of Kishtwar cloudburst now; Fact Check",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/27/fact-check-video-of-traffic-jam-in-pakistan-viral-as-that-from-himachal-pradesh/",
        headline:
          "Fact Check: Video Of Traffic Jam In Pakistan Viral As That From Himachal Pradesh",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-morphed-image-viral-to-show-pm-modi-carrying-an-umbrella-with-jio-logo/",
        headline:
          "Fact Check: Morphed Image Viral To Show PM Modi Carrying An Umbrella With Jio Logo",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-this-image-of-myanmar-leader-aung-san-suu-kyi-in-prison-is-morphed/",
        headline:
          "Fact Check: This Image Of Myanmar Leader Aung San Suu Kyi In Prison Is Morphed",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/29/fact-check-old-image-from-uttarakhand-falsely-shared-as-cloudburst-in-jammus-kishtwar-district/",
        headline:
          "Fact Check: Old Image From Uttarakhand Falsely Shared As Cloudburst In Jammu\u2019s Kishtwar District",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/29/fact-check-no-this-viral-video-is-not-from-mount-kailash-but-mount-kilimanjaro/",
        headline:
          "Fact Check: No, This Viral Video Is NOT From Mount Kailash But Mount Kilimanjaro",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/29/fact-check-months-old-video-of-protest-by-homeguards-viral-amid-assam-mizoram-border-crisis/",
        headline:
          "Fact Check: Months Old Video Of Protest By Assamese Home Guards Viral Amid Assam-Mizoram Border Crisis",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-morphed-image-viral-as-french-president-macron-covered-in-flower-garlands/",
        headline:
          "Fact Check: Morphed Image Viral As French President Macron Covered In Flower Garlands",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-old-image-of-philippine-coast-guard-rescuing-a-baby-shared-as-recent-myanmar-floods/",
        headline:
          "Fact Check: Old Image Of Philippine Coast Guard Rescuing A Baby Shared As Recent Myanmar Floods",
      },
      {
        url:
          "https://factly.in/telugu-jain-sculptures-in-gwalior-fort-falsely-shared-as-temple-found-after-demolishing-a-mosque/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4d\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28\u0c35\u0c3f \u0c2e\u0c27\u0c4d\u0c2f\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d\u200c\u0c32\u0c4b\u0c28\u0c3f \u0c17\u0c4d\u0c35\u0c3e\u0c32\u0c3f\u0c2f\u0c30\u0c4d \u0c15\u0c4b\u0c1f \u0c2a\u0c4d\u0c30\u0c3e\u0c02\u0c24\u0c02\u0c32\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c1c\u0c48\u0c28 \u0c36\u0c3f\u0c32\u0c4d\u0c2a\u0c3e\u0c32\u0c41; \u0c05\u0c35\u0c3f \u0c2e\u0c38\u0c40\u0c26\u0c41 \u0c15\u0c42\u0c32\u0c4d\u0c1a\u0c3f\u0c28\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c30\u0c3e\u0c2f\u0c1a\u0c42\u0c30\u0c4d\u200c\u0c32\u0c4b \u0c2c\u0c2f\u0c1f\u0c2a\u0c21\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-video-of-pakistan-being-shared-as-visuals-of-a-massive-traffic-jam-in-himachal-pradesh/",
        headline:
          " \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d\u200c\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c39\u0c3f\u0c2e\u0c3e\u0c1a\u0c32\u0c4d \u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d\u200c\u0c32\u0c4b \u0c2d\u0c3e\u0c30\u0c40 \u0c1f\u0c4d\u0c30\u0c3e\u0c2b\u0c3f\u0c15\u0c4d \u0c1c\u0c3e\u0c2e\u0c4d \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/morphed-image-is-shared-as-that-of-jailed-ex-pm-of-burma-aung-san-suu-kyi/",
        headline:
          " Morphed image is shared as that of jailed ex-PM of Burma, Aung San Suu Kyi",
      },
      {
        url:
          "https://factly.in/photo-of-aaj-tak-crediting-modi-for-the-first-olympic-medal-was-morphed/",
        headline:
          " Photo of Aaj Tak crediting Modi for the first Olympic medal is morphed",
      },
      {
        url:
          "https://factly.in/this-video-showing-heavy-traffic-jam-in-a-valley-is-from-pakistan-not-himachal-pradesh/",
        headline:
          " This video showing heavy traffic jam in a valley is from Pakistan, not Himachal Pradesh",
      },
      {
        url:
          "https://factly.in/telugu-performance-visuals-of-professional-figure-skaters-is-shared-as-those-of-chinese-robots/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c4a\u0c2b\u0c46\u0c37\u0c28\u0c32\u0c4d \u0c2b\u0c3f\u0c17\u0c30\u0c4d \u0c38\u0c4d\u0c15\u0c47\u0c1f\u0c30\u0c4d\u0c32 \u0c2a\u0c4d\u0c30\u0c26\u0c30\u0c4d\u0c36\u0c28 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c48\u0c28\u0c3e \u0c30\u0c4b\u0c2c\u0c4b\u0c32\u0c41 \u0c28\u0c43\u0c24\u0c4d\u0c2f\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-photos-of-chinese-carrying-ak-47-in-pakistan-shared-as-recent/",
        headline:
          " \u0c1a\u0c48\u0c28\u0c3e \u0c07\u0c02\u0c1c\u0c28\u0c40\u0c30\u0c4d\u0c32\u0c41 \u0c0e\u0c15\u0c46-47 \u0c2a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c28\u0c3f \u0c09\u0c28\u0c4d\u0c28 \u0c2a\u0c3e\u0c24 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c41 \u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d\u200c\u0c32\u0c4b \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c2a\u0c47\u0c32\u0c41\u0c21\u0c41 \u0c24\u0c30\u0c4d\u0c35\u0c3e\u0c24 \u0c24\u0c40\u0c38\u0c3f\u0c28 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/morphed-image-is-shared-as-anti-sharia-signboard-being-displayed-at-india-border/",
        headline:
          " Morphed image is shared as anti-Sharia signboard displayed at India border",
      },
    ],
    "5": [
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/smart-city-banaras-street-flood-heavy-rain-narendra-modi-uttar-pradesh-viral-picture-news-14037",
        headline: "              ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/tourists-returning-from-himachal-pradesh-kinnaur-rockslide-traffic-jam-video-naran-kaghan-valleys-14049",
        headline: "            ",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/tarun-kothari-din-dean-nayar-nair-hospital-mumbai-covid-anti-masker-vaccine-social-distancing-fact-check-14047",
        headline:
          "\u09a8\u09be, \u09ae\u09be\u09b8\u09cd\u0995-\u09ac\u09bf\u09b0\u09cb\u09a7\u09c0 \u09a1\u09be\u0983 \u09a4\u09b0\u09c1\u09a3 \u0995\u09cb\u09a0\u09be\u09b0\u09bf \u09ae\u09c1\u09ae\u09cd\u09ac\u0987\u09df\u09c7\u09b0 \u09a8\u09be\u09df\u09be\u09b0 \u09b9\u09be\u09b8\u09aa\u09be\u09a4\u09be\u09b2\u09c7\u09b0 \u09a1\u09bf\u09a8 \u09a8\u09a8",
      },
      {
        url:
          "https://newsmeter.in/fact-check/regional/andhra-pradesh/eluru-municipal-polls-ysrcp-vote-share-increased-by-11-claims-sajjala-681163",
        headline:
          "Eluru municipal polls: YSRCP vote share increased by 11%, claims Sajjala",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-people-in-plastic-bags-are-not-indonesian-covid-victims-681149",
        headline:
          "FACT CHECK: People in plastic bags are not Indonesian COVID victims",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-did-south-african-leader-ask-his-followers-to-kill-indians-and-whites-681219",
        headline:
          "Fact Check: Did South African leader ask his followers to kill Indians and whites?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-this-is-not-a-video-of-anti-vaccine-protest-in-greece-681174",
        headline:
          "Fact Check: No, this is not a video of anti-vaccine protest in Greece",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-does-this-doctor-charge-patients-only-rs-5-681391",
        headline: "Fact Check: Does this doctor charge patients only Rs. 5?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-horrific-video-of-teenager-being-killed-is-from-brazil-not-west-bengal-681395",
        headline:
          "Fact Check: Horrific video of teenager being killed is from Brazil, not West Bengal",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/bangkok-chennai-airport-civil-aviation-ministry-14126",
        headline:
          "Ministry Of Civil Aviation Shares Image Of Bangkok Airport As Chennai",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/bull-kills-old-man-haryana-panipat-viral-video-fake-claim-14073",
        headline: "      ,     ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/mirabai-chanu-tokyo-olympics-silver-medal-felicitation-ceremony-pm-modi-thank-you-poster-anurag-thakur-viral-image-14115",
        headline: "            ?  ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/people-offering-namaz-in-knee-deep-water-viral-video-fake-claim-14093",
        headline: "              ",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/delhi-london-kejriwal-flooded-street-kirari-vidhan-sabha-fake-news-viral-photo-14083",
        headline:
          "\u092a\u093e\u0928\u0940 \u092e\u0947\u0902 \u0921\u0942\u092c\u0940 \u0917\u0932\u0940 \u0915\u0940 \u092f\u0947 \u0924\u0938\u094d\u0935\u0940\u0930 \u0926\u093f\u0932\u094d\u0932\u0940 \u0915\u0940 \u0928\u0939\u0940\u0902 \u092c\u0932\u094d\u0915\u093f \u092a\u0902\u091c\u093e\u092c \u0938\u0947 \u0939\u0948",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fast-check/2013-stripped-protest-antonio-garca-conejo-image-in-mexico-congress-against-energy-bill-revived-with-misleading-context-14058",
        headline:
          "\u09ae\u09c7\u0995\u09cd\u09b8\u09bf\u0995\u09cb\u09b0 \u09b8\u0982\u09b8\u09a6\u09c7 \u09b0\u09be\u099c\u09a8\u09c0\u09a4\u09bf\u0995\u09c7\u09b0 \u09ac\u09c7\u0986\u09ac\u09cd\u09b0\u09c1 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u099b\u09ac\u09bf \u09ab\u09c7\u09b0 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2020-complete-west-bengal-lockdown-rules-mamata-bannerjee-nabanna-video-shared-as-recent-covid-19-lockdown-till-31-august-14102",
        headline:
          "\u09e8\u09e6\u09e8\u09e6 \u09ae\u09ae\u09a4\u09be \u09ac\u09a8\u09cd\u09a6\u09cd\u09af\u09cb\u09aa\u09be\u09a7\u09cd\u09af\u09be\u09df\u09c7\u09b0 \u09b2\u0995\u09a1\u09be\u0989\u09a8 \u09ac\u09bf\u09a7\u09bf\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09ad\u09c1\u09df\u09cb \u09a6\u09be\u09ac\u09bf\u09a4\u09c7 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/clip-of-protest-by-home-guards-during-assam-assembly-polls-2021-shared-as-recent-lailapur-cachar-assam-mizoram-border-dispute-14101",
        headline:
          "\u0985\u09b8\u09ae\u09c7 \u09ad\u09cb\u099f\u09c7\u09b0 \u09b8\u09ae\u09df \u09b9\u09cb\u09ae\u0997\u09be\u09b0\u09cd\u09a1\u09a6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/27/fact-check-anti-vaxxer-doctor-spreads-false-claims-about-coronavirus-vaccine-and-masks-dont-believe-this/",
        headline:
          "Fact Check: Anti Vaxxer \u2018Doctor\u2019 Spreads False Claims About Coronavirus, Vaccine and Masks; Don\u2019t Believe This",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/27/fact-check-did-south-african-leader-say-kill-indians-and-burn-their-houses-heres-the-truth/",
        headline:
          "Fact Check: Did South African Leader Say \u2018Kill Indians And Burn Their Houses\u2019? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/28/fact-check-old-picture-of-floods-in-bangladesh-falsely-shared-as-current-situation-in-india/",
        headline:
          "Fact Check: Old Picture Of Floods In Bangladesh Falsely Shared As Current Situation In India",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/29/fact-check-no-this-picture-is-not-of-delhi-mumbai-expressway-heres-the-truth/",
        headline:
          "Fact Check: No, This Picture Is NOT Of Delhi-Mumbai Expressway; Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/30/fact-check-old-video-of-people-offering-namaz-amidst-floods-from-bangladesh-shared-with-false-claim/",
        headline:
          "Fact Check: Old Video Of People Offering Namaz Amidst Floods From Bangladesh Shared With False Claim",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-no-akhilesh-yadav-did-not-tweet-about-babri-masjid-heres-the-truth/",
        headline:
          "Fact Check: No, Akhilesh Yadav Did NOT Tweet About Babri Masjid; Here\u2019s The Truth",
      },
      {
        url:
          "https://factly.in/doctor-spreads-anti-masking-and-anti-vaccine-misinformation/",
        headline:
          " \u2018Doctor\u2019 spreads anti-masking and anti-vaccine misinformation",
      },
      {
        url:
          "https://factly.in/telugu-old-kidnapping-incident-from-karnataka-is-given-a-false-communal-narrative/",
        headline:
          " \u0c15\u0c30\u0c4d\u0c23\u0c3e\u0c1f\u0c15\u0c32\u0c4b \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c2a\u0c3e\u0c24 \u0c15\u0c3f\u0c21\u0c4d\u0c28\u0c3e\u0c2a\u0c4d\u200c\u0c28\u0c3f \u0c2e\u0c41\u0c38\u0c4d\u0c32\u0c3f\u0c2e\u0c41\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-photo-from-bangladesh-is-falsely-shared-as-blood-flood-indian-streets-during-eid/",
        headline:
          " Old photo from Bangladesh is falsely shared as blood filled Indian streets during Eid",
      },
      {
        url:
          "https://factly.in/telugu-aerial-view-of-the-mount-kilimanjaro-is-shared-as-that-of-mount-kailash/",
        headline:
          " \u0c15\u0c3f\u0c32\u0c3f\u0c2e\u0c02\u0c1c\u0c3e\u0c30\u0c4b \u0c2a\u0c30\u0c4d\u0c35\u0c24 \u0c17\u0c17\u0c28 \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c28\u0c3f \u0c15\u0c48\u0c32\u0c3e\u0c38 \u0c2a\u0c30\u0c4d\u0c35\u0c24\u0c02 \u0c26\u0c43\u0c36\u0c4d\u0c2f\u0c3e\u0c32\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/photo-of-yamuna-expressway-is-falsely-shared-as-delhi-mumbai-expressway/",
        headline:
          " Photo of Yamuna Expressway is falsely shared as Delhi-Mumbai Expressway",
      },
      {
        url:
          "https://factly.in/telugu-bangladesh-judge-who-sentenced-16-convicts-to-death-in-murder-case-did-not-demean-indian-judiciary/",
        headline:
          " \u0c2e\u0c39\u0c3f\u0c33 \u0c39\u0c24\u0c4d\u0c2f \u0c15\u0c47\u0c38\u0c41\u0c32\u0c4b 16 \u0c2e\u0c02\u0c26\u0c3f \u0c26\u0c4b\u0c37\u0c41\u0c32\u0c15\u0c41 \u0c2e\u0c30\u0c23\u0c36\u0c3f\u0c15\u0c4d\u0c37 \u0c35\u0c3f\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c2c\u0c02\u0c17\u0c4d\u0c32\u0c3e\u0c26\u0c47\u0c36\u0c4d \u0c28\u0c4d\u0c2f\u0c3e\u0c2f\u0c2e\u0c42\u0c30\u0c4d\u0c24\u0c3f \u0c2d\u0c3e\u0c30\u0c24 \u0c28\u0c4d\u0c2f\u0c3e\u0c2f\u0c35\u0c4d\u0c2f\u0c35\u0c38\u0c4d\u0c25\u0c28\u0c41 \u0c39\u0c47\u0c33\u0c28 \u0c1a\u0c47\u0c2f\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-there-is-no-evidence-that-the-heads-of-these-countries-were-assassinated-for-refusing-covid-19-vaccines/",
        headline:
          " \u0c15\u0c4b\u0c35\u0c3f\u0c21\u0c4d \u0c35\u0c4d\u0c2f\u0c3e\u0c15\u0c4d\u0c38\u0c3f\u0c28\u0c4d\u0c32\u0c28\u0c41 \u0c28\u0c3f\u0c30\u0c3e\u0c15\u0c30\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c02\u0c26\u0c41\u0c15\u0c41 \u0c08 \u0c26\u0c47\u0c36\u0c3e\u0c32 \u0c05\u0c27\u0c3f\u0c28\u0c47\u0c24\u0c32\u0c41 \u0c39\u0c24\u0c4d\u0c2f\u0c32\u0c15\u0c41 \u0c17\u0c41\u0c30\u0c48\u0c28\u0c1f\u0c4d\u0c1f\u0c41 \u0c0e\u0c1f\u0c41\u0c35\u0c02\u0c1f\u0c3f \u0c06\u0c27\u0c3e\u0c30\u0c3e\u0c32\u0c41 \u0c32\u0c47\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-of-crocodile-attacking-a-woman-is-from-mexico-not-odisha/",
        headline:
          " This video of crocodile attacking a woman is from Mexico, not Odisha",
      },
      {
        url:
          "https://factly.in/drinking-warm-water-does-not-completely-cure-these-health-ailments/",
        headline:
          " Drinking warm water does not completely cure these health ailments",
      },
    ],
    "6": [
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/are-lpg-prices-high-due-to-55-state-taxes-viral-message-is-false-14018",
        headline:
          "Are LPG Prices High Due To 55% State Taxes? Viral Message Is False",
      },
      {
        url:
          "https://newsmeter.in/fact-check/entertainment/bheemla-nayak-back-in-action-pawan-kalyan-resumes-shooting-for-his-next-681156",
        headline:
          "Bheemla Nayak back in action; Pawan Kalyan resumes shooting for his next",
      },
      {
        url:
          "https://newsmeter.in/fact-check/hyderabad/2-up-men-arrested-for-duping-hyderabad-octogenarian-in-rs-15l-insurance-fraud-681162",
        headline:
          "2 UP men arrested for duping Hyderabad octogenarian in Rs. 15L insurance fraud",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-this-is-not-the-video-of-mahas-koyna-dam-overflowing-681383",
        headline:
          "Fact Check: No, this is not the video of Maha's Koyna dam overflowing",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-old-image-of-terrorist-dressed-as-woman-is-from-afghanistan-not-india-681352",
        headline:
          "Fact Check: Old image of terrorist dressed as woman is from Afghanistan, not India",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-is-urmila-matondkar-niece-of-rss-chief-mohan-bhagwat-681279",
        headline:
          "Fact Check: Is Urmila Matondkar niece of RSS chief Mohan Bhagwat?",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-hindu-man-who-died-in-bull-attack-mistaken-for-muslim-mocked-by-social-media-users-681290",
        headline:
          "Fact Check: Hindu man who died in bull attack mistaken for Muslim, mocked by social media users",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-photographer-michi-hoshino-did-not-capture-last-moments-on-camera-681176",
        headline:
          "Fact Check: Photographer Michi Hoshino did not capture last moments on camera",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/fact-check-no-gujarat-high-court-has-not-abolished-reservation-policy-in-state-681292",
        headline:
          "Fact Check: No, Gujarat High Court has not abolished reservation policy in state",
      },
      {
        url:
          "https://newsmeter.in/fact-check/fact-check/man-in-viral-image-is-not-famous-scholar-dandibhatla-vishwanatha-sastry-681276",
        headline:
          "Man in viral image is not famous scholar Dandibhatla Vishwanatha Sastry",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fast-check/china-xiaolangdi-dam-overflowing-water-shared-falsely-as-koyna-river-dam-in-maharashtra-india-14123",
        headline: "Video Of Overflowing Dam Is Not From Maharashtra",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/viral-video-bull-attacks-kills-man-muslim-hindu-fake-news-14071",
        headline:
          "Video Of Haryana Man Killed By Stray Bull Shared With False Communal Spin",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/2012-photo-of-taliban-militant-dressed-as-a-woman-viral-as-kashmir-india-14086",
        headline:
          "2012 Photo Of Taliban Militant Dressed As A Woman Viral As Kashmir, India",
      },
      {
        url:
          "https://www.boomlive.in/fact-check/fact-check/fake-news-mock-drill-video-police-terrorists-gujarat-patan-mock-drill-city-point-factcheck-14121",
        headline:
          "Mock Drill Video Falsely Viral As Terrorists Nabbed in Gujarat",
      },
      {
        url:
          "https://hindi.boomlive.in/fact-check/fact-check/round-up-of-five-fake-news-viral-on-social-media-last-week-brahman-dalit-jai-bhim-eurasian-ganga-delhi-banaras-rain-14128",
        headline:
          "\u092a\u093f\u091b\u0932\u0947 \u0939\u095e\u094d\u0924\u0947 \u0935\u093e\u092f\u0930\u0932 \u0939\u0941\u0908\u0902 5 \u092e\u0941\u0916\u094d\u092f \u095e\u0930\u094d\u095b\u0940 \u0959\u092c\u0930\u094b\u0902 \u0915\u0940 \u092a\u095c\u0924\u093e\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/2012-photo-of-taliban-militant-dressed-as-a-woman-viral-as-kashmir-india-14109",
        headline:
          "\u09e8\u09e6\u09e7\u09e8 \u09b8\u09be\u09b2\u09c7 \u09ae\u09b9\u09bf\u09b2\u09be\u09b0 \u09ac\u09c7\u09b6\u09c7 \u09a7\u09c3\u09a4 \u09a4\u09be\u09b2\u09bf\u09ac\u09be\u09a8 \u099c\u0999\u09cd\u0997\u09bf\u09b0 \u099b\u09ac\u09bf \u099b\u09a1\u09bc\u09be\u09b2 \u0995\u09be\u09b6\u09cd\u09ae\u09c0\u09b0\u09c7\u09b0 \u0998\u099f\u09a8\u09be \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/are-lpg-prices-high-due-to-55-state-taxes-viral-message-is-false-14057",
        headline:
          "\u09eb\u09eb% \u09b0\u09be\u099c\u09cd\u09af\u09c7\u09b0 \u09b6\u09c1\u09b2\u09cd\u0995\u09c7\u09b0 \u099c\u09a8\u09cd\u09af \u098f\u09b2\u09aa\u09bf\u099c\u09bf \u0997\u09cd\u09af\u09be\u09b8\u09c7\u09b0 \u09a6\u09be\u09ae \u0985\u0997\u09cd\u09a8\u09bf\u09ae\u09c2\u09b2\u09cd\u09af? \u09ad\u09c1\u09df\u09cb \u09ac\u09be\u09b0\u09cd\u09a4\u09be",
      },
      {
        url:
          "https://bangla.boomlive.in/fact-check/fact-check/ramkesh-meena-jaipur-rajasthan-saffron-flag-mob-viral-video-fake-news-14087",
        headline:
          "\u09b0\u09be\u099c\u09b8\u09cd\u09a5\u09be\u09a8\u09c7\u09b0 \u09ac\u09bf\u09a7\u09be\u09df\u0995 \u09b0\u09be\u09ae\u0995\u09c7\u09b6 \u09ae\u09bf\u09a8\u09be\u0995\u09c7 \u09b9\u09be\u09ae\u09b2\u09be\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u09ad\u09bf\u09a1\u09bf\u0993\u0995\u09c7 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09ac\u09b2\u09be \u09b9\u09b2",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/29/fact-check-did-a-bull-kill-a-man-from-the-minority-community-on-eid-heres-the-truth/",
        headline:
          "Fact Check: Did A Bull Kill A Man From The Minority Community On Eid? Here\u2019s The Truth",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/30/fact-check-old-picture-of-terrorist-captured-in-afghanistan-falsely-shared-as-that-of-india/",
        headline:
          "Fact Check: Old Picture Of Terrorist Captured In Afghanistan Falsely Shared As That Of India",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-reservation-has-not-been-abolished-in-gujarat-viral-claim-recirculated-yet-again/",
        headline:
          "Fact Check: Reservation Has Not Been Abolished In Gujarat; Viral Claim Recirculated Yet Again",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-viral-image-of-jk-journalist-shared-to-mock-journalist-ravish-kumar/",
        headline:
          "Fact Check: Viral Image Of J&K Journalist Shared To Mock Journalist Ravish Kumar",
      },
      {
        url:
          "https://newsmobile.in/articles/2021/07/31/fact-check-no-the-viral-list-does-not-show-when-covid-19-variants-will-be-released-its-fake/",
        headline:
          "Fact Check: No, The Viral List Does NOT Show When COVID-19 Variants Will Be Released; It\u2019s FAKE",
      },
      {
        url:
          "https://factly.in/telugu-if-a-kashmiri-woman-marries-a-man-from-another-state-she-will-not-lose-her-kashmiri-residence-status/",
        headline:
          " \u0c15\u0c36\u0c4d\u0c2e\u0c40\u0c30\u0c40 \u0c2e\u0c39\u0c3f\u0c33 \u0c35\u0c47\u0c30\u0c47 \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f\u0c28\u0c3f \u0c35\u0c3f\u0c35\u0c3e\u0c39\u0c02 \u0c1a\u0c47\u0c38\u0c41\u0c15\u0c41\u0c02\u0c1f\u0c47, \u0c06\u0c2e\u0c46 \u0c24\u0c28 \u0c15\u0c36\u0c4d\u0c2e\u0c40\u0c30\u0c40 \u0c36\u0c3e\u0c36\u0c4d\u0c35\u0c24 \u0c28\u0c3f\u0c35\u0c3e\u0c38 \u0c39\u0c4b\u0c26\u0c3e \u0c15\u0c4b\u0c32\u0c4d\u0c2a\u0c4b\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/video-of-ghmc-corporators-argument-with-police-is-being-shared-with-a-communal-narrative/",
        headline:
          " Video of GHMC corporator\u2019s argument with police is being shared with a communal narrative",
      },
    ],
  },
  numpy_seed: 0,
  number_of_articles: 162,
}
