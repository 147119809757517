export const data = {
  mdsDat: {
    x: [
      0.27271499760578427,
      -0.21048384823480784,
      0.19256995997614745,
      -0.33515849166884876,
      0.08035738232172521,
    ],
    y: [
      -0.13920487447439775,
      -0.098041865058923,
      -0.14975201415145564,
      -0.036259891546851726,
      0.4232586452316281,
    ],
    topics: [1, 2, 3, 4, 5],
    cluster: [1, 1, 1, 1, 1],
    Freq: [
      17.320263619307003,
      23.762020948403585,
      22.883723625624487,
      21.495935599525414,
      14.538056207139514,
    ],
  },
  tinfo: {
    Term: [
      "hyderabad",
      "flood",
      "rahul_gandhi",
      "rain",
      "gandhi",
      "rahul",
      "pradesh",
      "bjp",
      "congress",
      "bridge",
      "idiot",
      "trump",
      "name",
      "president",
      "confusion",
      "hospital",
      "clipped",
      "context",
      "vaccine",
      "lie",
      "rahul_gandhi",
      "covid",
      "educate",
      "list",
      "world",
      "forbes",
      "educated_leader",
      "donald",
      "rahul",
      "gandhi",
      "leader",
      "tweet",
      "pradesh",
      "pothole",
      "brandish",
      "irrelevant",
      "desi",
      "politician",
      "saraswati",
      "hurl",
      "indecent",
      "kanpur",
      "bjp",
      "andhra",
      "bengal",
      "andhra_pradesh",
      "worker",
      "bihar",
      "telangana",
      "attack",
      "state",
      "election",
      "road",
      "congress",
      "mirzapur",
      "priyanka",
      "uddhav",
      "part",
      "know",
      "accuse",
      "stab",
      "priyanka_gandhi",
      "war",
      "baton",
      "hathras",
      "bhabhi",
      "naxal",
      "protest",
      "goa",
      "tv",
      "social",
      "vadra",
      "watch",
      "thackeray",
      "bhabhi_hathras",
      "naxalite",
      "congress",
      "police",
      "rape",
      "gandhi",
      "away",
      "tell",
      "rajahmundry",
      "overflowing",
      "cheruvu",
      "mexico",
      "fun",
      "age",
      "present",
      "waterlogged",
      "flood",
      "crocodile",
      "bridge",
      "airport",
      "signal",
      "floodwater",
      "traffic_signal",
      "traffic",
      "wash",
      "vadodara",
      "hyderabad",
      "rain",
      "ramayana",
      "eyed",
      "murder",
      "kill",
      "failure",
      "assassination",
      "bread",
      "compulsory",
      "nirbhaya",
      "khashoggi",
      "rickshaw",
      "communal",
      "ecuador",
      "pictures",
      "shop",
      "colonel",
      "govt",
      "offer",
      "puller",
      "involve",
      "government",
      "smartphone",
      "russian",
      "woman",
      "message",
      "missile",
      "make",
    ],
    Freq: [
      22.0,
      9.0,
      7.0,
      10.0,
      12.0,
      8.0,
      6.0,
      5.0,
      9.0,
      5.0,
      1.0000239797716668,
      3.000071939315001,
      1.0000239797716668,
      2.0000479595433336,
      1.0000239797716668,
      3.000071939315001,
      1.0000239797716668,
      1.0000239797716668,
      1.0000239797716668,
      1.0000239797716668,
      7.000167858401668,
      4.000095919086667,
      4.000095919086667,
      4.000095919086667,
      4.000095919086667,
      3.000071939315001,
      3.000071939315001,
      3.000071939315001,
      7.000167858401668,
      7.000167858401668,
      4.000095919086667,
      3.000071939315001,
      6.99842074421865,
      1.9995487840624715,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      1.9995487840624715,
      5.998646352187414,
      4.998871960156179,
      4.998871960156179,
      4.998871960156179,
      4.998871960156179,
      3.999097568124943,
      2.999323176093707,
      2.999323176093707,
      2.999323176093707,
      2.999323176093707,
      4.998871960156179,
      2.999323176093707,
      1.0000566512634788,
      5.000283256317394,
      2.0001133025269575,
      1.0000566512634788,
      2.0001133025269575,
      1.0000566512634788,
      1.0000566512634788,
      5.000283256317394,
      1.0000566512634788,
      1.0000566512634788,
      5.000283256317394,
      5.000283256317394,
      5.000283256317394,
      5.000283256317394,
      3.0001699537904365,
      3.0001699537904365,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      7.0003965588443515,
      4.000226605053915,
      4.000226605053915,
      5.000283256317394,
      1.0001932387779175,
      1.0001932387779175,
      1.0001932387779175,
      1.0001932387779175,
      2.000386477555835,
      2.000386477555835,
      1.0001932387779175,
      1.0001932387779175,
      1.0001932387779175,
      2.000386477555835,
      9.001739149001258,
      5.000966193889588,
      5.000966193889588,
      5.000966193889588,
      4.00077295511167,
      4.00077295511167,
      4.00077295511167,
      4.00077295511167,
      3.0005797163337524,
      3.0005797163337524,
      21.00405801433627,
      9.001739149001258,
      0.9999654312910743,
      0.9999654312910743,
      2.9998962938732228,
      1.9999308625821486,
      0.9999654312910743,
      0.9999654312910743,
      1.9999308625821486,
      0.9999654312910743,
      0.9999654312910743,
      1.9999308625821486,
      2.9998962938732228,
      2.9998962938732228,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      2.9998962938732228,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
    ],
    Total: [
      22.0,
      9.0,
      7.0,
      10.0,
      12.0,
      8.0,
      6.0,
      5.0,
      9.0,
      5.0,
      1.0000239797716668,
      3.000071939315001,
      1.0000239797716668,
      2.0000479595433336,
      1.0000239797716668,
      3.000071939315001,
      1.0000239797716668,
      1.0000239797716668,
      1.0000239797716668,
      1.0000239797716668,
      7.000167858401668,
      4.000095919086667,
      4.000095919086667,
      4.000095919086667,
      4.000095919086667,
      3.000071939315001,
      3.000071939315001,
      3.000071939315001,
      8.000224509665147,
      12.000451114719063,
      8.999814656939575,
      4.999902982609716,
      6.99842074421865,
      1.9995487840624715,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      0.9997743920312357,
      1.9995487840624715,
      5.998646352187414,
      4.998871960156179,
      4.998871960156179,
      4.998871960156179,
      4.998871960156179,
      3.999097568124943,
      2.999323176093707,
      2.999323176093707,
      2.999323176093707,
      2.999323176093707,
      5.999065198934097,
      9.999719734938058,
      1.0000566512634788,
      5.000283256317394,
      2.0001133025269575,
      1.0000566512634788,
      2.0001133025269575,
      1.0000566512634788,
      1.0000566512634788,
      5.000283256317394,
      1.0000566512634788,
      1.0000566512634788,
      5.000283256317394,
      5.000283256317394,
      5.000283256317394,
      5.000283256317394,
      3.0001699537904365,
      3.0001699537904365,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      2.0001133025269575,
      9.999719734938058,
      5.0000009970851504,
      5.000192036344989,
      12.000451114719063,
      1.0001932387779175,
      1.0001932387779175,
      1.0001932387779175,
      1.0001932387779175,
      2.000386477555835,
      2.000386477555835,
      1.0001932387779175,
      1.0001932387779175,
      1.0001932387779175,
      2.000386477555835,
      9.001739149001258,
      5.000966193889588,
      5.000966193889588,
      5.000966193889588,
      4.00077295511167,
      4.00077295511167,
      4.00077295511167,
      4.00077295511167,
      3.0005797163337524,
      3.0005797163337524,
      22.003832406367504,
      10.001513541032494,
      0.9999654312910743,
      0.9999654312910743,
      2.9998962938732228,
      1.9999308625821486,
      0.9999654312910743,
      0.9999654312910743,
      1.9999308625821486,
      0.9999654312910743,
      0.9999654312910743,
      1.9999308625821486,
      2.9998962938732228,
      2.9998962938732228,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      1.9999308625821486,
      5.00000959640018,
      2.9999548423538154,
      1.9999308625821486,
      2.999705254613384,
    ],
    Category: [
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Default",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic1",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic2",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic3",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic4",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
      "Topic5",
    ],
    logprob: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      -4.92,
      -3.8214,
      -4.92,
      -4.2268,
      -4.92,
      -3.8214,
      -4.92,
      -4.92,
      -4.92,
      -4.92,
      -2.9741,
      -3.5337,
      -3.5337,
      -3.5337,
      -3.5337,
      -3.8214,
      -3.8214,
      -3.8214,
      -2.9741,
      -2.9741,
      -3.5337,
      -3.8214,
      -3.2905,
      -4.5433,
      -5.2364,
      -5.2364,
      -5.2364,
      -5.2364,
      -5.2364,
      -5.2364,
      -5.2364,
      -4.5433,
      -3.4447,
      -3.627,
      -3.627,
      -3.627,
      -3.627,
      -3.8501,
      -4.1378,
      -4.1378,
      -4.1378,
      -4.1378,
      -3.627,
      -4.1378,
      -5.1985,
      -3.5891,
      -4.5053,
      -5.1985,
      -4.5053,
      -5.1985,
      -5.1985,
      -3.5891,
      -5.1985,
      -5.1985,
      -3.5891,
      -3.5891,
      -3.5891,
      -3.5891,
      -4.0999,
      -4.0999,
      -4.5053,
      -4.5053,
      -4.5053,
      -4.5053,
      -4.5053,
      -4.5053,
      -3.2526,
      -3.8122,
      -3.8122,
      -3.5891,
      -5.1358,
      -5.1358,
      -5.1358,
      -5.1358,
      -4.4427,
      -4.4427,
      -5.1358,
      -5.1358,
      -5.1358,
      -4.4427,
      -2.9386,
      -3.5264,
      -3.5264,
      -3.5264,
      -3.7495,
      -3.7495,
      -3.7495,
      -3.7495,
      -4.0372,
      -4.0372,
      -2.0913,
      -2.9386,
      -4.7449,
      -4.7449,
      -3.6463,
      -4.0518,
      -4.7449,
      -4.7449,
      -4.0518,
      -4.7449,
      -4.7449,
      -4.0518,
      -3.6463,
      -3.6463,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -4.0518,
      -3.6463,
      -4.0518,
      -4.0518,
      -4.0518,
    ],
    loglift: [
      10.0,
      9.0,
      8.0,
      7.0,
      6.0,
      5.0,
      4.0,
      3.0,
      2.0,
      1.0,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.7533,
      1.6198,
      1.2143,
      0.9424,
      1.2425,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.4371,
      1.2547,
      0.2329,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.4747,
      1.1182,
      1.2517,
      1.2516,
      0.5993,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.5373,
      1.4908,
      1.432,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.9284,
      1.4175,
      1.5229,
      1.9284,
      1.523,
    ],
  },
  "token.table": {
    Topic: [
      3,
      4,
      4,
      2,
      2,
      5,
      2,
      4,
      3,
      2,
      3,
      3,
      2,
      2,
      2,
      5,
      4,
      4,
      1,
      5,
      5,
      5,
      1,
      2,
      3,
      1,
      1,
      4,
      2,
      1,
      5,
      1,
      1,
      2,
      5,
      5,
      4,
      4,
      1,
      4,
      1,
      3,
      3,
      5,
      5,
      3,
      1,
      2,
      2,
      4,
      1,
      2,
      5,
      2,
      2,
      5,
      5,
      3,
      1,
      2,
      3,
      1,
      1,
      2,
      5,
      1,
      5,
      4,
      3,
      5,
      5,
      1,
      3,
      3,
      5,
      5,
      4,
      3,
      5,
      2,
      3,
      2,
      2,
      2,
      4,
      1,
      3,
      3,
      3,
      5,
      1,
      3,
      1,
      2,
      4,
      4,
      5,
      3,
      5,
      5,
      2,
      4,
      5,
      2,
      5,
      4,
      5,
      3,
      3,
      2,
      2,
      4,
      3,
      4,
      4,
      1,
      3,
      1,
      2,
      3,
      3,
      1,
      4,
      3,
      3,
      4,
      3,
      4,
      3,
      5,
      2,
      1,
    ],
    Freq: [
      0.999943351945705,
      0.9998067985560933,
      0.9998067985560933,
      1.0002256588792056,
      1.0002256588792056,
      1.0000345699039626,
      1.0002256588792056,
      0.9998067985560933,
      0.999943351945705,
      1.0002256588792056,
      0.9999433519457052,
      0.999943351945705,
      1.0002256588792056,
      1.0002256588792056,
      1.0002256588792056,
      1.0000345699039626,
      0.9998067985560933,
      0.9998067985560933,
      0.9999760208033489,
      1.0000345699039626,
      1.0000345699039628,
      1.0000345699039626,
      0.9999760208033489,
      0.3000084081875104,
      0.7000196191041909,
      0.9999760208033489,
      0.9999760208033489,
      0.9998067985560933,
      1.0002256588792056,
      0.9999760208033487,
      1.0000345699039626,
      0.9999760208033489,
      0.9999760208033487,
      1.0002256588792056,
      1.0000345699039626,
      1.0000345699039626,
      0.9998067985560933,
      0.9998067985560933,
      0.9999760208033487,
      0.9998067985560933,
      0.5833114049699517,
      0.41665100354996554,
      0.999943351945705,
      1.0000345699039626,
      1.0000345699039626,
      0.9999433519457052,
      0.9999760208033487,
      1.0002256588792056,
      0.04544662863868289,
      0.9543792014123407,
      0.9999760208033489,
      1.0002256588792056,
      1.0000345699039626,
      1.0002256588792056,
      1.0002256588792056,
      1.0000345699039626,
      1.0000345699039626,
      0.999943351945705,
      0.4444535973766616,
      0.2222267986883308,
      0.3333401980324962,
      0.9999760208033489,
      0.9999760208033489,
      0.33336608603863804,
      0.6667321720772761,
      0.3333383509251036,
      0.6666767018502072,
      0.9998067985560933,
      0.999943351945705,
      1.0000345699039626,
      1.0000345699039628,
      0.9999760208033489,
      0.9999433519457052,
      0.999943351945705,
      1.0000345699039626,
      1.0000345699039626,
      0.9998067985560933,
      0.999943351945705,
      1.0000345699039626,
      0.19999996011660193,
      0.7999998404664077,
      1.0002256588792056,
      1.0002256588792056,
      1.0002256588792058,
      0.9998067985560933,
      0.9999760208033489,
      0.9999433519457052,
      0.9999433519457052,
      0.9999433519457052,
      1.0000345699039626,
      0.8749754449444805,
      0.12499649213492578,
      0.9999760208033488,
      0.09998486688013485,
      0.8998638019212136,
      0.9998067985560933,
      1.0000345699039626,
      0.799969275364851,
      0.19999231884121274,
      1.0000345699039628,
      0.8334631870459402,
      0.16669263740918805,
      1.0000345699039626,
      1.0002256588792056,
      1.0000345699039626,
      0.9998067985560933,
      1.0000345699039626,
      0.999943351945705,
      0.999943351945705,
      1.0002256588792056,
      1.0002256588792056,
      0.9998067985560933,
      0.999943351945705,
      0.9998067985560933,
      0.9998067985560933,
      0.9999760208033487,
      0.999943351945705,
      0.6000116423127354,
      0.20000388077091183,
      0.20000388077091183,
      0.999943351945705,
      0.9999760208033489,
      0.9998067985560934,
      0.999943351945705,
      0.999943351945705,
      0.9998067985560934,
      0.999943351945705,
      0.9998067985560933,
      0.39999923228945905,
      0.5999988484341885,
      1.0002256588792056,
      0.9999760208033489,
    ],
    Term: [
      "accuse",
      "age",
      "airport",
      "andhra",
      "andhra_pradesh",
      "assassination",
      "attack",
      "away",
      "baton",
      "bengal",
      "bhabhi",
      "bhabhi_hathras",
      "bihar",
      "bjp",
      "brandish",
      "bread",
      "bridge",
      "cheruvu",
      "clipped",
      "colonel",
      "communal",
      "compulsory",
      "confusion",
      "congress",
      "congress",
      "context",
      "covid",
      "crocodile",
      "desi",
      "donald",
      "ecuador",
      "educate",
      "educated_leader",
      "election",
      "eyed",
      "failure",
      "flood",
      "floodwater",
      "forbes",
      "fun",
      "gandhi",
      "gandhi",
      "goa",
      "government",
      "govt",
      "hathras",
      "hospital",
      "hurl",
      "hyderabad",
      "hyderabad",
      "idiot",
      "indecent",
      "involve",
      "irrelevant",
      "kanpur",
      "khashoggi",
      "kill",
      "know",
      "leader",
      "leader",
      "leader",
      "lie",
      "list",
      "make",
      "make",
      "message",
      "message",
      "mexico",
      "mirzapur",
      "missile",
      "murder",
      "name",
      "naxal",
      "naxalite",
      "nirbhaya",
      "offer",
      "overflowing",
      "part",
      "pictures",
      "police",
      "police",
      "politician",
      "pothole",
      "pradesh",
      "present",
      "president",
      "priyanka",
      "priyanka_gandhi",
      "protest",
      "puller",
      "rahul",
      "rahul",
      "rahul_gandhi",
      "rain",
      "rain",
      "rajahmundry",
      "ramayana",
      "rape",
      "rape",
      "rickshaw",
      "road",
      "road",
      "russian",
      "saraswati",
      "shop",
      "signal",
      "smartphone",
      "social",
      "stab",
      "state",
      "telangana",
      "tell",
      "thackeray",
      "traffic",
      "traffic_signal",
      "trump",
      "tv",
      "tweet",
      "tweet",
      "tweet",
      "uddhav",
      "vaccine",
      "vadodara",
      "vadra",
      "war",
      "wash",
      "watch",
      "waterlogged",
      "woman",
      "woman",
      "worker",
      "world",
    ],
  },
  R: 10,
  "lambda.step": 0.01,
  "plot.opts": { xlab: "PC1", ylab: "PC2" },
  "topic.order": [1, 2, 3, 4, 5],
  per_cluster_headlines: {
    "1": [
      {
        url:
          "https://www.boomlive.in/fake-news/no-forbes-did-not-name-rahul-gandhi-on-a-most-educated-leaders-list-10189",
        headline:
          "No, Forbes Did Not Name Rahul Gandhi On A 'Most Educated Leaders' List",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/no-rahul-gandhi-did-not-call-himself-indias-biggest-fool-in-speech-10222",
        headline:
          "No, Rahul Gandhi Did Not Call Himself India's 'Biggest Fool' In Speech",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/did-avoiding-the-future-plague-psa-predict-covid-19-pandemic-10237",
        headline:
          "Did 'Avoiding The Future Plague' PSA Predict COVID-19 Pandemic?",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/2018-image-of-dilip-ghosh-on-hospital-bed-revived-with-covid-19-spin-10240",
        headline:
          "2018 Image Of Dilip Ghosh On Hospital Bed Revived With Covid-19 Spin",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-forbes-did-not-name-rahul-gandhi-on-a-most-educated-leaders-list-10190",
        headline:
          "\u09ad\u09c1\u09df\u09cb \u09ac\u09be\u09b0\u09cd\u09a4\u09be: \u09ab\u09cb\u09b0\u09cd\u09ac\u09b8\u09c7\u09b0 \u09a4\u09be\u09b2\u09bf\u0995\u09be\u09df \u09ac\u09bf\u09b6\u09cd\u09ac\u09c7\u09b0 \u09b8\u09ac\u099a\u09c7\u09df\u09c7 \u09b6\u09bf\u0995\u09cd\u09b7\u09bf\u09a4 \u09a8\u09c7\u09a4\u09be \u09b0\u09be\u09b9\u09c1\u09b2 \u0997\u09be\u0981\u09a7\u09c0",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/hoax-of-abdur-razzak-mollahs-death-surfaces-as-namesake-passes-away-10196",
        headline:
          "\u09aa\u09cd\u09b0\u09be\u0995\u09cd\u09a4\u09a8 \u09b8\u09bf\u09aa\u09bf\u0986\u0987\u098f\u09ae \u09ac\u09bf\u09a7\u09be\u09df\u0995 \u09a1\u0983 \u0986\u09ac\u09cd\u09a6\u09c1\u09b0 \u09b0\u09c7\u099c\u09cd\u099c\u09be\u0995\u09c7\u09b0 \u09ae\u09c3\u09a4\u09cd\u09af\u09c1\u09a4\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/2018-image-of-dilip-ghosh-on-hospital-bed-revived-with-covid-19-spin-10242",
        headline:
          "\u0995\u09b0\u09cb\u09a8\u09be \u0986\u09ac\u09b9\u09c7 \u09e8\u09e6\u09e7\u09ee \u09b8\u09be\u09b2\u09c7 \u09b9\u09be\u09b8\u09aa\u09be\u09a4\u09be\u09b2\u09c7 \u09b6\u09af\u09cd\u09af\u09be\u09b6\u09be\u09df\u09c0 \u09a6\u09bf\u09b2\u09c0\u09aa \u0998\u09cb\u09b7\u09c7\u09b0 \u099b\u09ac\u09bf \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2",
      },
      {
        url:
          "https://factly.in/telugu-forbes-did-not-conduct-any-survey-that-reported-rahul-gandhi-to-be-the-7th-most-educated-politician/",
        headline:
          " \u0c2a\u0c4d\u0c30\u0c2a\u0c02\u0c1a\u0c02\u0c32\u0c4b \u0c05\u0c24\u0c4d\u0c2f\u0c27\u0c3f\u0c15\u0c2e\u0c41\u0c17\u0c3e \u0c1a\u0c26\u0c41\u0c35\u0c41\u0c15\u0c41\u0c28\u0c4d\u0c28 \u0c28\u0c3e\u0c2f\u0c15\u0c41\u0c32\u0c32\u0c4b \u0c30\u0c3e\u0c39\u0c41\u0c32\u0c4d \u0c17\u0c3e\u0c02\u0c27\u0c40 7\u0c35 \u0c38\u0c4d\u0c25\u0c3e\u0c28\u0c02\u0c32\u0c4b \u0c28\u0c3f\u0c32\u0c3f\u0c1a\u0c3e\u0c30\u0c28\u0c3f  \u0c2b\u0c4b\u0c30\u0c4d\u0c2c\u0c4d\u0c38\u0c4d \u0c38\u0c02\u0c38\u0c4d\u0c25 \u0c24\u0c46\u0c32\u0c41\u0c2a\u0c32\u0c47\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/forbes-hasnt-listed-rahul-gandhi-as-the-seventh-most-educated-leader-in-the-world/",
        headline:
          " Forbes hasn\u2019t listed Rahul Gandhi as the seventh most educated leader in the world",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-no-who-didnt-list-out-7-brain-damaging-habits-viral-post-falsely-attributed-to-world-health-organization/",
        headline:
          "Fact Check: No, WHO Didn\u2019t List Out 7 Brain Damaging Habits; Viral Post Falsely Attributed To World Health Organization",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-viral-tweet-attributed-to-columnist-shefali-vaidya-is-fake/",
        headline:
          "Fact-Check: The viral tweet attributed to Shefali Vaidya is false",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-edited-photo-of-rahul-gandhi-is-viral-with-fake-claim/",
        headline:
          "Fact Check: Edited photo of Rahul Gandhi Is Viral with fake claim\ufeff",
      },
      {
        url:
          "https://digiteye.in/fake-image-of-donald-trump-in-hospital-with-covid-19-goes-viral-fact-check/",
        headline:
          "Fake Image of Donald Trump in hospital with Covid-19 goes viral; Fact Check",
      },
      {
        url:
          "https://digiteye.in/principia-claims-the-coronavirus-pandemic-was-pre-planned-and-with-purpose-fact-check/",
        headline:
          "Principia claims the Coronavirus pandemic was pre-planned and with purpose; Fact Check",
      },
      {
        url:
          "https://www.factcrescendo.com/clipped-video-rahul-gandhi-did-not-say-he-is-a-fool/",
        headline:
          "CLIPPED VIDEO: \u0930\u093e\u0939\u0941\u0932 \u0917\u093e\u0901\u0927\u0940 \u0915\u093e \u0916\u0941\u0926 \u0915\u094b \u092c\u0947\u0935\u0915\u0942\u092b \u0915\u0939\u0928\u0947 \u0935\u093e\u0932\u093e \u0935\u093e\u092f\u0930\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u092e\u0942\u0932 \u0935\u0940\u0921\u093f\u092f\u094b \u0938\u0947 \u0915\u094d\u0932\u093f\u092a \u0915\u0930 \u0917\u0932\u0924 \u0938\u0928\u094d\u0926\u0930\u094d\u092d \u0915\u0947 \u0938\u093e\u0925 \u092b\u0948\u0932\u093e\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 |",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/12/fact-check-dont-fall-for-this-morphed-tweet-of-president-donald-trump-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed tweet of President Donald Trump; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/13/fact-check-did-us-vp-candidate-kamala-harris-say-that-joe-biden-is-trash-to-her-heres-the-truth/",
        headline:
          "Fact Check: Did US VP candidate Kamala Harris say that Joe Biden is \u2018trash\u2019 to her? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/15/fact-check-no-us-president-donald-trump-did-not-tweet-about-getting-covid-19-vaccine-shot/",
        headline:
          "Fact Check: No, US President Donald Trump did NOT tweet about getting COVID-19 vaccine shot",
      },
    ],
    "2": [
      {
        url:
          "https://factly.in/telugu-2018-photo-of-trs-leader-campaign-is-falsely-linked-to-dubbaka-assembly-bypoll/",
        headline:
          " \u0c12\u0c15 \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c3f \u0c38\u0c4d\u0c28\u0c3e\u0c28\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c02\u0c1f\u0c47 \u0c1f\u0c40\u0c06\u0c30\u0c4d\u0c0e\u0c38\u0c4d \u0c28\u0c3e\u0c2f\u0c15\u0c41\u0c21\u0c41 \u0c28\u0c40\u0c33\u0c4d\u0c33\u0c41 \u0c2a\u0c4b\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c26\u0c41\u0c2c\u0c4d\u0c2c\u0c3e\u0c15 \u0c09\u0c2a\u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c15\u0c41 \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-unrelated-photos-falsely-linked-to-condition-of-roads-in-andhra-pradesh/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b \u0c17\u0c41\u0c02\u0c24\u0c32\u0c24\u0c4b \u0c09\u0c28\u0c4d\u0c28 \u0c30\u0c4b\u0c21\u0c4d\u0c32\u0c41 \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c35\u0c3f \u0c15\u0c3e\u0c35\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-lorry-overturning-on-a-potholed-road-is-not-from-andhra-pradesh/",
        headline:
          " \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c15\u0c28\u0c3f\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c32\u0c3e\u0c30\u0c40 \u0c2c\u0c4b\u0c32\u0c4d\u0c24\u0c3e\u0c2a\u0c21\u0c3f\u0c02\u0c26\u0c3f \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b\u0c28\u0c3f \u0c2e\u0c32\u0c4d\u0c15\u0c3e\u0c2a\u0c42\u0c30\u0c4d \u0c2c\u0c48\u0c2a\u0c3e\u0c38\u0c4d \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c2a\u0c48, \u0c05\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c2a\u0c48 \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/bihar-minister-shares-photo-from-hyderabad-to-show-development-in-state-10231",
        headline:
          "Bihar Minister Shares Photo From Hyderabad To Show Development In State",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/madhya-pradesh-congress-farzi-tweet-police-shooting-citizens-mock-drill-10215",
        headline:
          "\u092e\u0927\u094d\u092f \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947 \u0936\u093f\u0935\u0930\u093e\u091c \u0938\u093f\u0902\u0939 \u091a\u094c\u0939\u093e\u0928 \u092a\u0930 \u0928\u093f\u0936\u093e\u0928\u093e \u0938\u093e\u0927\u0924\u0947 \u0939\u0941\u090f \u095e\u0930\u094d\u095b\u0940 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u093f\u092f\u093e \u091f\u094d\u0935\u0940\u091f",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/old-news-of-anil-kumble-and-rahul-dravid-denied-to-join-politics-revived-10177",
        headline:
          "\u0995\u09c1\u09ae\u09cd\u09ac\u09b2\u09c7 \u0993 \u09a6\u09cd\u09b0\u09be\u09ac\u09bf\u09dc\u09c7\u09b0 \u09b0\u09be\u099c\u09a8\u09c0\u09a4\u09bf\u09a4\u09c7 \u09af\u09cb\u0997 \u09a8\u09be \u09a6\u09c7\u0993\u09df\u09be\u09b0 \u0996\u09ac\u09b0\u099f\u09bf \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a4\u09bf\u0995 \u09a8\u09df",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/7-year-old-photo-of-a-mexican-politician-viral-with-misleading-context-10225",
        headline:
          "\u09ae\u09c7\u0995\u09cd\u09b8\u09bf\u0995\u09cb\u09b0 \u09b0\u09be\u099c\u09a8\u09c0\u09a4\u09bf\u0995\u09c7\u09b0 \u09ed \u09ac\u099b\u09b0\u09c7\u09b0 \u09aa\u09c1\u09b0\u09a8\u09cb \u099b\u09ac\u09bf \u09ac\u09bf\u09ad\u09cd\u09b0\u09be\u09a8\u09cd\u09a4\u09bf\u0995\u09b0 \u09a6\u09be\u09ac\u09bf \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://factly.in/these-roads-with-full-of-pits-are-not-from-the-aurangabad-city-of-maharashtra/",
        headline:
          " These roads full of potholes are not from Aurangabad, Maharashtra",
      },
      {
        url:
          "https://factly.in/this-photo-shows-the-nidadavole-railway-station-ap-tracks-filled-with-rainwater/",
        headline:
          " This photo shows the Nidadavole Railway Station (AP) tracks filled with rainwater.",
      },
      {
        url:
          "https://factly.in/telugu-this-photo-shows-the-nidadavole-railway-station-ap-tracks-filled-with-rainwater-not-kacheguda/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d \u0c32\u0c4b\u0c28\u0c3f \u0c28\u0c3f\u0c21\u0c26\u0c35\u0c4b\u0c32\u0c41 \u0c30\u0c48\u0c32\u0c4d\u0c35\u0c47 \u0c38\u0c4d\u0c1f\u0c47\u0c37\u0c28\u0c4d \u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c15\u0c3e\u0c1a\u0c3f\u0c17\u0c42\u0c21\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-old-and-unrelated-video-shared-as-congress-leader-beaten-for-making-untoward-comments-on-hindus/",
        headline:
          " \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c02 \u0c32\u0c47\u0c28\u0c3f \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c39\u0c3f\u0c02\u0c26\u0c42\u0c35\u0c41\u0c32\u0c2a\u0c48 \u0c05\u0c28\u0c41\u0c1a\u0c3f\u0c24 \u0c35\u0c4d\u0c2f\u0c3e\u0c16\u0c4d\u0c2f\u0c32\u0c41 \u0c1a\u0c47\u0c38\u0c3f\u0c28\u0c02\u0c26\u0c41\u0c15\u0c41 \u0c15\u0c3e\u0c02\u0c17\u0c4d\u0c30\u0c46\u0c38\u0c4d \u0c28\u0c47\u0c24\u0c2a\u0c48 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c28 \u0c26\u0c3e\u0c21\u0c3f \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-photo-of-houses-submerged-in-rain-water-in-telangana-is-shared-as-that-from-andhra-pradesh/",
        headline:
          " \u0c08 \u0c2e\u0c41\u0c28\u0c3f\u0c17\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c07\u0c33\u0c4d\u0c32 \u0c2b\u0c4b\u0c1f\u0c4b\u0c28\u0c3f \u0c24\u0c46\u0c32\u0c02\u0c17\u0c3e\u0c23\u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3e\u0c30\u0c41, \u0c05\u0c2e\u0c30\u0c3e\u0c35\u0c24\u0c3f (\u0c06\u0c02\u0c27\u0c4d\u0c30\u0c2a\u0c4d\u0c30\u0c26\u0c47\u0c36\u0c4d) \u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-video-of-fish-crossing-flooded-street-in-washington-shared-as-gandipet/",
        headline:
          " \u0c1a\u0c47\u0c2a\u0c32\u0c41 \u0c30\u0c4b\u0c21\u0c4d\u0c21\u0c41 \u0c26\u0c3e\u0c1f\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c35\u0c3e\u0c37\u0c3f\u0c02\u0c17\u0c4d\u0c1f\u0c28\u0c4d \u0c15\u0c3f (\u0c05\u0c2e\u0c46\u0c30\u0c3f\u0c15\u0c3e) \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c17\u0c02\u0c21\u0c3f\u0c2a\u0c47\u0c1f\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-2018-bjp-rally-photo-from-tamil-nadu-is-falsely-linked-to-bihar-election-campaign/",
        headline:
          " \u0c08 \u0c2b\u0c4b\u0c1f\u0c4b \u0c2c\u0c40\u0c39\u0c3e\u0c30\u0c4d \u0c30\u0c3e\u0c37\u0c4d\u0c1f\u0c4d\u0c30\u0c02\u0c32\u0c4b \u0c2c\u0c40\u0c1c\u0c47\u0c2a\u0c40   \u0c28\u0c3f\u0c30\u0c4d\u0c35\u0c39\u0c3f\u0c02\u0c1a\u0c3f\u0c28 \u0c0e\u0c28\u0c4d\u0c28\u0c3f\u0c15\u0c32 \u0c30\u0c4d\u0c2f\u0c3e\u0c32\u0c40\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c28\u0c26\u0c3f \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/bjp-worker-in-this-video-was-attacked-by-congress-party-workers-in-indore-not-bihar/",
        headline:
          " BJP worker in this video was attacked by Congress party workers in Indore, not Bihar.",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/old-videos-go-viral-visuals-telangana-rains-lash-state-1731843-2020-10-15",
        headline:
          "Fact Check: Old videos go viral as visuals from Telangana as rains lash state",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-unrelated-images-linked-to-nagorno-karabakh-conflict-1731491-2020-10-14",
        headline:
          "Fact Check: Old, unrelated images linked to Nagorno-Karabakh conflict",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-video-of-khushbu-sundar-slapping-a-man-resurfaces-after-she-joins-bjp-1732691-2020-10-18",
        headline:
          "Fact Check: Old video of Khushbu Sundar slapping a man resurfaces after she joins BJP",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-old-picture-of-attack-on-policeman-in-kanpur-passed-off-as-incident-from-bengal/",
        headline:
          "Fact Check: Old Picture of Attack on Policeman in Kanpur Passed off As Incident from Bengal",
      },
      {
        url:
          "https://www.factcrescendo.com/video-of-bjp-worker-kicking-tear-gas-cannister-in-bengal-is-fake/",
        headline:
          "\u092c\u0902\u0917\u093e\u0932 \u092e\u0947\u0902 \u0906\u0902\u0938\u0942 \u0917\u0948\u0938 \u0915\u0947 \u0921\u093f\u092c\u094d\u092c\u0947 \u0915\u094b \u092d\u093e\u091c\u092a\u093e \u0915\u093e\u0930\u094d\u092f\u0915\u0930\u094d\u0924\u093e \u0926\u094d\u0935\u093e\u0930\u093e \u0932\u093e\u0924 \u092e\u093e\u0930\u0928\u0947 \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u092b\u0930\u094d\u091c\u0940 \u0939\u0948 |",
      },
      {
        url:
          "https://www.factcrescendo.com/footwear-garland-put-on-bjp-leader-by-locals-in-dhar/",
        headline:
          "\u092e\u0927\u094d\u092f \u092a\u094d\u0930\u0926\u0947\u0936 \u0915\u0947 \u0927\u093e\u0930 \u092e\u0947\u0902 \u0939\u0941\u0908 \u0968\u0966\u0967\u096e \u0915\u0940 \u0918\u091f\u0928\u093e \u0915\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u092c\u093f\u0939\u093e\u0930 \u091a\u0941\u0928\u093e\u0935 \u092a\u094d\u0930\u091a\u093e\u0930 \u0938\u0947 \u091c\u094b\u0921\u093c\u0915\u0930 \u0935\u093e\u092f\u0930\u0932 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948\u0964",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/12/fact-check-idol-of-goddess-saraswati-in-andhra-pradesh-was-not-vandalized-recently-heres-the-truth/",
        headline:
          "Fact Check: Idol of Goddess Saraswati in Andhra Pradesh was NOT vandalized recently; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/13/fact-check-old-picture-of-bjp-workers-body-hanging-from-a-tree-in-bengal-circulated-as-recent/",
        headline:
          "Fact Check: Old picture of BJP worker\u2019s body hanging from a tree in Bengal circulated as recent",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/13/fact-check-old-newspaper-clipping-with-false-claim-about-swiss-player-ambre-allinckxs-refusal-to-come-to-india-viral-again/",
        headline:
          "Fact Check: Old newspaper clipping with false claim about Swiss player Ambre Allinckx\u2019s refusal to come to India viral again",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/14/fact-check-did-west-bengal-police-hurl-desi-bombs-at-bjp-workers-heres-the-truth/",
        headline:
          "Fact Check: Did West Bengal Police hurl \u2018desi\u2019 bombs at BJP workers? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/14/fact-check-old-video-of-man-brandishing-sword-over-electricity-bill-being-circulated-as-recent-one/",
        headline:
          "Fact Check: Old video of man brandishing sword over electricity bill being circulated as recent one",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/15/fact-check-old-picture-of-policeman-being-beaten-up-in-kanpur-falsely-shared-as-recent-clashes-in-bengal/",
        headline:
          "Fact Check: Old picture of policeman being beaten up in Kanpur falsely shared as recent clashes in Bengal",
      },
    ],
    "3": [
      {
        url:
          "https://www.boomlive.in/fake-news/morphed-photo-showing-police-protest-against-lathi-charging-revived-10146",
        headline:
          "Morphed Photo Showing Police Protest Against Lathi-Charging Revived",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photo-of-police-pradarshan-with-masoomo-pe-lathi-charge-placard-is-edited-10154",
        headline:
          "\u0926\u093f\u0932\u094d\u0932\u0940 \u092a\u0941\u0932\u093f\u0938 \u0915\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928 \u0915\u0940 \u0924\u0938\u094d\u0935\u0940\u0930 \u095e\u094b\u091f\u094b\u0936\u0949\u092a \u0915\u0930 \u0915\u0940 \u091c\u093e \u0930\u0939\u0940 \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/photo-of-priyanka-gandhi-hugging-hathras-victims-mother-viral-as-naxali-bhabhi-10156",
        headline:
          "\u0928\u0939\u0940\u0902, \u0924\u0938\u094d\u0935\u0940\u0930 \u092e\u0947\u0902 \u092a\u094d\u0930\u093f\u092f\u0902\u0915\u093e \u0917\u093e\u0902\u0927\u0940 '\u0928\u0915\u094d\u0938\u0932 \u092d\u093e\u092d\u0940' \u0915\u094b \u0917\u0932\u0947 \u0928\u0939\u0940\u0902 \u0932\u0917\u093e \u0930\u0939\u0940 \u0939\u0948\u0902",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/tv9-bharatvarsh-runs-arma-3-clip-as-armenia-azerbaijan-war-footage-10152",
        headline:
          "\u0986\u09b0\u09ae\u09c7\u09a8\u09bf\u09df\u09be-\u0986\u099c\u09be\u09b0\u09ac\u09be\u0987\u099c\u09be\u09a8 \u09af\u09c1\u09a6\u09cd\u09a7 \u09ac\u09b2\u09c7 \u099f\u09bf\u09ad\u09bf\u09ef \u09ad\u09be\u09b0\u09a4\u09ac\u09b0\u09cd\u09b7 \u09a6\u09c7\u0996\u09be\u09b2 \u09ad\u09bf\u09a1\u09bf\u0993 \u0997\u09c7\u09ae\u09c7\u09b0 \u09a6\u09c3\u09b6\u09cd\u09af",
      },
      {
        url:
          "https://factly.in/morphed-photo-shared-as-maharashtra-cm-uddhav-thackeray-watching-republic-tv/",
        headline:
          " Morphed photo shared as Maharashtra CM Uddhav Thackeray watching \u2018Republic TV\u2019",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/photo-of-goa-congress-leader-falsely-shared-as-naxal-bhabhi-in-hathras-10161",
        headline:
          "Photo of Goa Congress Leader Falsely Shared As 'Naxal Bhabhi' In Hathras",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/mp-congress-includes-mock-drill-video-to-target-cm-shivraj-singh-chouhan-10214",
        headline:
          "MP Congress Includes Mock Drill Video To Target CM Shivraj Singh Chouhan",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/dated-photo-of-rahul-and-priyanka-gandhi-shared-with-misleading-claim-10223",
        headline:
          "Dated Photo Of Rahul And Priyanka Gandhi Shared With Misleading Claim",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/morphed-photo-showing-police-protest-against-lathi-charging-revived-10165",
        headline:
          "\u09b2\u09be\u09a0\u09bf\u099a\u09be\u09b0\u09cd\u099c\u09c7\u09b0 \u09ac\u09bf\u09b0\u09c1\u09a6\u09cd\u09a7\u09c7 \u09aa\u09c1\u09b2\u09bf\u09b6\u09c7\u09b0 \u09aa\u09cd\u09b0\u09a4\u09bf\u09ac\u09be\u09a6 \u09ac\u09b2\u09c7 \u099c\u09bf\u0987\u09df\u09c7 \u0989\u09a0\u09b2 \u09b8\u09ae\u09cd\u09aa\u09be\u09a6\u09bf\u09a4 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-priyanka-gandhi-vadra-is-not-hugging-naxal-bhabhi-in-this-photo-10170",
        headline:
          "\u09a8\u09be, \u098f\u0987 \u099b\u09ac\u09bf\u09a4\u09c7 \u09aa\u09cd\u09b0\u09bf\u09df\u0999\u09cd\u0995\u09be \u0997\u09be\u0981\u09a7\u09c0 '\u09a8\u0995\u09b6\u09be\u09b2 \u09ad\u09be\u09ac\u09c0'\u0995\u09c7 \u0986\u09b2\u09bf\u0999\u09cd\u0997\u09a8 \u0995\u09b0\u09a8\u09a8\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/photo-of-goa-congress-leader-falsely-shared-as-naxal-bhabhi-in-hathras-10185",
        headline:
          "\u09b9\u09be\u09a5\u09b0\u09b8\u09c7 '\u09a8\u0995\u09b6\u09be\u09b2 \u09ad\u09be\u09ac\u09bf' \u09ac\u09b2\u09c7 \u099b\u09a1\u09bc\u09be\u09b2 \u0997\u09cb\u09df\u09be \u0995\u0982\u0997\u09cd\u09b0\u09c7\u09b8 \u09a8\u09c7\u09a4\u09cd\u09b0\u09c0\u09b0 \u099b\u09ac\u09bf",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/no-this-is-not-naxal-bhabhi-photographed-with-bhim-army-chief-10230",
        headline:
          "\u09a8\u09be, \u09ad\u09c0\u09ae \u09b8\u09c7\u09a8\u09be \u09aa\u09cd\u09b0\u09a7\u09be\u09a8\u09c7\u09b0 \u09b8\u0999\u09cd\u0997\u09c7 '\u09a8\u0995\u09b6\u09be\u09b2 \u09ad\u09be\u09ac\u09bf' \u0995\u09c7 \u09a6\u09c7\u0996\u09be \u09af\u09be\u099a\u09cd\u099b\u09c7 \u09a8\u09be \u098f\u0987 \u099b\u09ac\u09bf\u09a4\u09c7",
      },
      {
        url:
          "https://factly.in/telugu-marriage-of-a-couple-from-same-community-falsely-shared-as-love-jihad/",
        headline:
          " \u0c12\u0c15\u0c47 \u0c38\u0c3e\u0c2e\u0c3e\u0c1c\u0c3f\u0c15 \u0c35\u0c30\u0c4d\u0c17\u0c3e\u0c28\u0c3f\u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c07\u0c26\u0c4d\u0c26\u0c30\u0c3f \u0c35\u0c4d\u0c2f\u0c15\u0c4d\u0c24\u0c41\u0c32 \u0c2a\u0c46\u0c33\u0c4d\u0c32\u0c3f\u0c28\u0c3f \u2018\u0c32\u0c35\u0c4d \u0c1c\u0c3f\u0c39\u0c3e\u0c26\u0c4d\u2019 \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/a-collage-with-the-photos-of-priyanka-gandhi-hugging-a-woman-and-the-photo-highlighting-a-congress-spokesperson-is-being-shared-on-social-media-claiming-that-priyanka-gandhi-consoling-the-hathras-rape/",
        headline:
          " The woman whom Priyanka Gandhi Vadra hugged in the photo is the mother of Hathras rape victim",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-know-about-the-man-smiling-in-the-face-of-death-literally-1732859-2020-10-18",
        headline:
          "Fact Check: Know about the man smiling in the face of death, literally",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-kirron-kher-didn-t-say-rapes-cannot-be-stopped-as-it-s-part-of-our-culture-1731299-2020-10-13",
        headline:
          "Fact Check: Kirron Kher didn't say rapes cannot be stopped as it's part of our culture",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-post-claiming-nigerian-nationals-protested-demanding-justice-for-bollywood-actor-sushant-singh-is-fake/",
        headline:
          "Fact Check: Post claiming Nigerian nationals protested demanding justice for Bollywood actor Sushant Singh is fake",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-goa-congress-social-media-chiefs-picture-viral-with-fake-claim/",
        headline:
          "Fact Check: Goa Congress Social Media Chief\u2019s picture Viral With Fake Claim",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-this-image-of-protest-by-delhi-police-is-edited-viral-post-is-fake/",
        headline:
          "Fact Check: This Image of Protest by Delhi Police is Edited; Viral Post is Fake",
      },
      {
        url:
          "https://www.factcrescendo.com/man-is-the-image-is-not-congress-leader-and-accused-of-hathras-case/",
        headline:
          "FACT CHECK- \u0915\u094d\u092f\u093e \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0915\u0947 \u092a\u094b\u0938\u094d\u091f\u0930 \u092e\u0947\u0902 \u0926\u093f\u0916 \u0930\u0939\u093e \u092f\u0939 \u0906\u0926\u092e\u0940 \u0939\u093e\u0925\u0930\u0938 \u0926\u0941\u0937\u094d\u0915\u0930\u094d\u092e \u0915\u093e \u0905\u092d\u093f\u092f\u0941\u0915\u094d\u0924 \u0914\u0930 \u0915\u093e\u0902\u0917\u094d\u0930\u0947\u0938 \u0928\u0947\u0924\u093e \u0939\u0948? \u091c\u093e\u0928\u093f\u092f\u0947 \u0938\u091a..",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/12/fact-check-old-tweet-of-actor-ali-fazal-about-protests-falsely-shared-as-recent-with-boycott-mirzapur-2-plea/",
        headline:
          "Fact Check: Old tweet of actor Ali Fazal about protests, falsely shared as recent with \u2018Boycott Mirzapur 2\u2019 plea",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/12/fact-check-dont-fall-for-this-morphed-picture-of-uddhav-thackeray-watching-republic-tv-heres-the-truth/",
        headline:
          "Fact Check: Don\u2019t fall for this morphed picture of Uddhav Thackeray watching Republic TV; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/12/fact-check-rss-activists-have-not-stabbed-ias-officer-raped-a-girl-in-a-car-heres-the-truth/",
        headline:
          "Fact Check: RSS activists have NOT stabbed IAS officer, raped a girl in a car; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/13/fact-check-dont-fall-for-false-claims-that-priyanka-gandhi-vadra-hugged-naxalite-bhabhi-in-hathras/",
        headline:
          "Fact Check: Don\u2019t fall for false claims that Priyanka Gandhi Vadra hugged \u2018Naxalite Bhabhi\u2019 in Hathras",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/15/fact-check-the-woman-in-this-congress-meeting-is-not-naxalite-bhabhi-from-hathras/",
        headline:
          "Fact Check: The woman in this Congress meeting is NOT \u2018Naxalite Bhabhi\u2019 from Hathras",
      },
    ],
    "4": [
      {
        url:
          "https://factly.in/old-video-of-vehicles-getting-washed-away-is-shared-as-the-recent-situation-in-rain-hit-hyderabad/",
        headline:
          " Old video of vehicles getting washed away is shared as the recent situation in rain-hit Hyderabad",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-crocodile-in-flood-waters-is-from-vadodara-not-hyderabad/",
        headline:
          " \u0c35\u0c30\u0c26 \u0c28\u0c40\u0c1f\u0c3f\u0c32\u0c4b \u0c2e\u0c4a\u0c38\u0c32\u0c3f \u0c2a\u0c4d\u0c30\u0c24\u0c4d\u0c2f\u0c15\u0c4d\u0c37\u0c2e\u0c48\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c35\u0c21\u0c4b\u0c26\u0c30\u0c15\u0c3f \u0c38\u0c02\u0c2c\u0c02\u0c27\u0c3f\u0c02\u0c1a\u0c3f\u0c02\u0c26\u0c3f, \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c15\u0c3f \u0c15\u0c3e\u0c26\u0c41.",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-vehicles-getting-washed-away-is-shared-as-the-recent-situation-in-rain-hit-hyderabad/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c32\u0c4b \u0c15\u0c41\u0c30\u0c3f\u0c38\u0c3f\u0c28 \u0c35\u0c30\u0c4d\u0c37\u0c3e\u0c32\u0c15\u0c3f \u0c28\u0c3f\u0c02\u0c21\u0c3f\u0c2a\u0c4b\u0c2f\u0c3f\u0c28 \u0c09\u0c38\u0c4d\u0c2e\u0c3e\u0c28\u0c4d \u0c17\u0c02\u0c1c\u0c4d \u0c05\u0c02\u0c1f\u0c42 \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/video-of-crocodile-spotted-in-waterlogged-streets-is-from-vadodara-not-hyderabad/",
        headline:
          " Video of a Crocodile spotted in waterlogged streets is from Vadodara, not Hyderabad",
      },
      {
        url:
          "https://factly.in/no-this-video-of-airport-flooded-with-rainwater-is-not-related-to-hyderabad-airport-rgia/",
        headline:
          " This video of airport flooded with rainwater is not related to Hyderabad Airport (RGIA).",
      },
      {
        url:
          "https://factly.in/old-video-is-being-shared-as-of-crocodile-spotted-in-himayatsagar-during-ongoing-rains-in-hyderabad/",
        headline:
          " Old video is being shared as  Crocodile spotted in Himayatsagar during ongoing rains in Hyderabad",
      },
      {
        url:
          "https://factly.in/this-video-of-traffic-signal-post-being-swept-away-in-floodwaters-is-from-china-not-hyderabad/",
        headline:
          " This video of traffic signal post being swept away in floodwaters is from China, not Hyderabad",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-airport-flooded-with-rainwater-is-not-related-to-hyderabad-airport-rgia/",
        headline:
          " \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c32\u0c4b \u0c35\u0c30\u0c4d\u0c37\u0c02 \u0c28\u0c40\u0c33\u0c4d\u0c33\u0c24\u0c4b \u0c28\u0c3f\u0c02\u0c21\u0c3f \u0c09\u0c28\u0c4d\u0c28\u0c26\u0c3f  \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c36\u0c02\u0c37\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c35\u0c3f\u0c2e\u0c3e\u0c28\u0c3e\u0c36\u0c4d\u0c30\u0c2f\u0c02 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-video-of-fishes-entering-the-house-is-being-shared-as-that-during-the-ongoing-hyderabad-rains/",
        headline:
          " \u0c17\u0c24\u0c02\u0c32\u0c4b \u0c35\u0c30\u0c4d\u0c37\u0c02 \u0c28\u0c40\u0c1f\u0c3f\u0c15\u0c3f \u0c07\u0c02\u0c1f\u0c4d\u0c32\u0c4b\u0c15\u0c3f \u0c15\u0c4a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c35\u0c1a\u0c4d\u0c1a\u0c3f\u0c28 \u0c1a\u0c47\u0c2a\u0c32 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c32\u0c4b \u0c15\u0c41\u0c30\u0c41\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c35\u0c30\u0c4d\u0c37\u0c3e\u0c32\u0c15\u0c41 \u0c2e\u0c41\u0c21\u0c3f \u0c2a\u0c46\u0c21\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-this-video-of-traffic-signal-post-being-swept-away-in-floodwaters-is-from-china-not-hyderabad/",
        headline:
          " \u0c1f\u0c4d\u0c30\u0c3e\u0c2b\u0c3f\u0c15\u0c4d \u0c38\u0c3f\u0c17\u0c4d\u0c28\u0c32\u0c4d \u0c2a\u0c4b\u0c38\u0c4d\u0c1f\u0c4d \u0c35\u0c30\u0c26 \u0c28\u0c40\u0c1f\u0c3f\u0c32\u0c4b \u0c15\u0c4a\u0c1f\u0c4d\u0c1f\u0c41\u0c15\u0c41\u0c2a\u0c4b\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c18\u0c1f\u0c28 \u0c1c\u0c30\u0c3f\u0c17\u0c3f\u0c02\u0c26\u0c3f \u0c1a\u0c48\u0c28\u0c3e\u0c32\u0c4b, \u0c39\u0c48\u0c26\u0c30\u0c3e\u0c2c\u0c3e\u0c26\u0c4d \u0c32\u0c4b \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-old-video-is-being-shared-as-crocodile-spotted-in-himayatsagar-during-ongoing-rains-in-hyderabad/",
        headline:
          " \u0c2a\u0c3e\u0c24 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c1f\u0c40\u0c35\u0c32 \u0c35\u0c30\u0c4d\u0c37\u0c3e\u0c32\u0c15\u0c41 \u0c39\u0c3f\u0c2e\u0c3e\u0c2f\u0c24\u0c4d \u0c38\u0c3e\u0c17\u0c30\u0c4d \u0c32\u0c4b \u0c2a\u0c4d\u0c30\u0c24\u0c4d\u0c2f\u0c15\u0c4d\u0c37\u0c2e\u0c48\u0c28  \u0c2e\u0c4a\u0c38\u0c32\u0c3f\u0c26\u0c3f \u0c05\u0c28\u0c3f \u0c24\u0c2a\u0c4d\u0c2a\u0c41\u0c17\u0c3e \u0c2a\u0c4d\u0c30\u0c1a\u0c3e\u0c30\u0c02 \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/old-video-of-fishes-entering-a-house-is-being-shared-as-the-one-from-current-hyderabad-rains/",
        headline:
          " Old video of fishes entering a house is being shared as the one from current Hyderabad rains",
      },
      {
        url:
          "https://factly.in/telugu-people-swimming-in-rain-water-in-karachi-is-shared-as-that-from-hyderabad/",
        headline:
          " \u0c35\u0c30\u0c4d\u0c37\u0c02 \u0c28\u0c40\u0c33\u0c4d\u0c33\u0c32\u0c4b \u0c15\u0c4a\u0c02\u0c26\u0c30\u0c41 \u0c38\u0c30\u0c26\u0c3e\u0c17\u0c3e \u0c1c\u0c3e\u0c30\u0c41\u0c15\u0c41\u0c02\u0c1f\u0c42 \u0c35\u0c46\u0c33\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c08 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b \u0c15\u0c30\u0c3e\u0c1a\u0c40 (\u0c2a\u0c3e\u0c15\u0c3f\u0c38\u0c4d\u0c24\u0c3e\u0c28\u0c4d) \u0c32\u0c4b \u0c24\u0c40\u0c38\u0c3f\u0c28\u0c26\u0c3f",
      },
      {
        url:
          "https://factly.in/telugu-2012-video-shared-as-rajahmundry-rail-bridge-flooded-with-water/",
        headline:
          " 2012 \u0c35\u0c40\u0c21\u0c3f\u0c2f\u0c4b\u0c28\u0c3f \u0c07\u0c2a\u0c4d\u0c2a\u0c41\u0c21\u0c41 \u0c30\u0c3e\u0c1c\u0c2e\u0c02\u0c21\u0c4d\u0c30\u0c3f\u0c32\u0c4b\u0c28\u0c3f \u0c30\u0c48\u0c32\u0c4d\u0c35\u0c47 \u0c2c\u0c4d\u0c30\u0c3f\u0c21\u0c4d\u0c1c\u0c3f\u0c2a\u0c48 \u0c2a\u0c4a\u0c02\u0c17\u0c41\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c17\u0c4b\u0c26\u0c3e\u0c35\u0c30\u0c3f \u0c35\u0c30\u0c26 \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://factly.in/this-video-of-people-enjoying-while-swimming-in-the-floodwaters-is-from-pakistan-not-from-hyderabad/",
        headline:
          " This video of people swimming in floodwaters is from Pakistan, not Hyderabad (India)",
      },
      {
        url:
          "https://factly.in/old-video-of-tandava-bridge-passed-off-as-solapur-bijapur-railway-bridge-submerged-in-bheema-river/",
        headline:
          " Old video of Tandava bridge passed off as Solapur-Bijapur railway bridge submerged in Bheema river",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/dad-spoofs-maternity-shoot-pics-go-viral-misleading-claim-1731271-2020-10-13",
        headline:
          "Fact Check: Dad spoofs maternity shoot, pics go viral with misleading claim\u00a0",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-video-of-fishes-inside-a-flooded-house-falsely-linked-to-recent-hyderabad-deluge-1732617-2020-10-17",
        headline:
          "Fact Check: Old video of fishes inside a flooded house falsely linked to recent Hyderabad deluge",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-viral-clip-of-traffic-signal-floating-in-floodwater-is-not-from-hyderabad-1731985-2020-10-15",
        headline:
          "Fact Check: Viral clip of traffic signal floating in floodwater is not from Hyderabad",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-old-video-from-mexico-shared-as-flooded-hyderabad-airport-1731958-2020-10-15",
        headline:
          "Fact Check: Old video from Mexico shared as flooded Hyderabad airport",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-this-video-of-road-accident-is-not-of-cheruvu-bridge-in-hyderabad/",
        headline:
          "Fact Check: This Video Of Road Accident Is Not Of Cheruvu Bridge In Hyderabad",
      },
      {
        url:
          "https://www.vishvasnews.com/english/politics/fact-check-upsc-has-not-reduced-maximum-age-limit-from-32-to-26-years-for-civil-services-examination/",
        headline:
          "Fact Check: UPSC has not reduced maximum age limit from 32 to 26 years for civil services examination",
      },
      {
        url:
          "https://www.factcrescendo.com/people-and-vehicles-drain-away-in-usmanganj-in-hyderabad-floods/",
        headline:
          "FactCheck: \u090f\u0915 \u092a\u0941\u0930\u093e\u0928\u0947 \u092c\u093e\u0922\u093c \u0915\u0947 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u094b \u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0939\u0948\u0926\u0930\u093e\u092c\u093e\u0926 \u0915\u093e \u092c\u0924\u093e \u0935\u093e\u092f\u0930\u0932 \u0915\u093f\u092f\u093e \u091c\u093e \u0930\u0939\u093e \u0939\u0948 \u0964",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/13/fact-check-no-this-accident-video-is-not-from-durgam-cheruvu-bridge-in-hyderabad/",
        headline:
          "Fact Check: No, this accident video is NOT from Durgam Cheruvu Bridge in Hyderabad",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/15/fact-check-old-video-of-crocodile-in-a-waterlogged-street-of-vadodara-shared-as-that-of-hyderabad/",
        headline:
          "Fact Check: Old video of crocodile in a waterlogged street of Vadodara shared as that of Hyderabad",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/17/fact-check-is-this-video-of-traffic-signal-floating-in-floodwaters-from-hyderabad-heres-the-truth/",
        headline:
          "Fact Check: Is this video of traffic signal floating in floodwaters from Hyderabad? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/17/fact-check-video-of-flooded-airport-in-mexico-falsely-shared-as-that-of-hyderabad/",
        headline:
          "Fact Check: Video of flooded airport in Mexico falsely shared as that of Hyderabad",
      },
    ],
    "5": [
      {
        url:
          "https://bangla.boomlive.in/fake-news/are-these-photos-of-a-hybrid-human-pig-creature-10155",
        headline:
          "\u098f\u0997\u09c1\u09b2\u09bf \u0995\u09bf \u09ae\u09be\u09a8\u09ac-\u09b6\u09c2\u0995\u09b0 \u09b8\u0982\u0995\u09b0 \u09b6\u09be\u09ac\u0995\u09c7\u09b0 \u099b\u09ac\u09bf?",
      },
      {
        url:
          "https://factly.in/telugu-this-boy-was-arrested-for-killing-his-brother-not-for-stealing-bread/",
        headline:
          " \u0c2b\u0c4b\u0c1f\u0c4b\u0c32\u0c4b\u0c28\u0c3f \u0c15\u0c41\u0c30\u0c4d\u0c30\u0c3e\u0c21\u0c41 \u0c24\u0c28 \u0c24\u0c2e\u0c4d\u0c2e\u0c41\u0c21\u0c3f\u0c28\u0c3f \u0c1a\u0c02\u0c2a\u0c3f\u0c28\u0c02\u0c26\u0c41\u0c15\u0c41 \u0c05\u0c30\u0c46\u0c38\u0c4d\u0c1f\u0c4d \u0c05\u0c2f\u0c4d\u0c2f\u0c3e\u0c21\u0c41, \u0c2c\u0c4d\u0c30\u0c46\u0c21\u0c4d \u0c26\u0c4a\u0c02\u0c17\u0c24\u0c28\u0c02 \u0c1a\u0c47\u0c38\u0c3f\u0c28\u0c02\u0c26\u0c41\u0c15\u0c41 \u0c15\u0c3e\u0c26\u0c41",
      },
      {
        url:
          "https://factly.in/telugu-photo-from-bangladesh-shared-as-indian-government-apathy-towards-the-poor/",
        headline:
          " \u0c2c\u0c02\u0c17\u0c4d\u0c32\u0c3e\u0c26\u0c47\u0c36\u0c4d \u0c15\u0c3f \u0c1a\u0c46\u0c02\u0c26\u0c3f\u0c28 \u0c30\u0c3f\u0c15\u0c4d\u0c37\u0c3e \u0c21\u0c4d\u0c30\u0c48\u0c35\u0c30\u0c4d \u0c2a\u0c30\u0c3f\u0c38\u0c4d\u0c25\u0c3f\u0c24\u0c3f\u0c28\u0c3f \u0c1a\u0c42\u0c2a\u0c3f\u0c38\u0c4d\u0c24\u0c42 \u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c36\u0c02\u0c32\u0c4b \u0c2a\u0c47\u0c26\u0c32\u0c15\u0c3f \u0c2a\u0c4d\u0c30\u0c2d\u0c41\u0c24\u0c4d\u0c35\u0c02 \u0c07\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28 \u0c17\u0c4c\u0c30\u0c35\u0c02 \u0c07\u0c26\u0c3e \u0c05\u0c28\u0c3f \u0c37\u0c47\u0c30\u0c4d \u0c1a\u0c47\u0c38\u0c4d\u0c24\u0c41\u0c28\u0c4d\u0c28\u0c3e\u0c30\u0c41",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/ecuador-video-falsely-shared-as-saudi-colonel-killed-for-khashoggi-murder-10178",
        headline:
          "Ecuador Video Falsely Shared As Saudi Colonel Killed For Khashoggi Murder",
      },
      {
        url:
          "https://www.boomlive.in/fake-news/video-of-woman-vandalising-shop-viral-with-false-communal-spin-10235",
        headline:
          "Video of Woman Vandalising Shop Viral With False Communal Spin",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/edited-photo-of-woman-holding-a-placard-with-communal-claims-revived-10162",
        headline:
          "\u092e\u0939\u093f\u0932\u093e \u0915\u0940 \u092a\u094d\u0932\u0947\u0915\u093e\u0930\u094d\u0921 \u0932\u093f\u090f \u095e\u094b\u091f\u094b\u0936\u0949\u092a\u094d\u0921 \u0924\u0938\u094d\u0935\u0940\u0930 \u092b\u093f\u0930 \u0939\u094b \u0930\u0939\u0940 \u0939\u0948 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://hindi.boomlive.in/fake-news/indore-woman-vandalising-shop-viral-with-false-hindu-muslim-angle-10238",
        headline:
          "\u0926\u0941\u0915\u093e\u0928 \u092e\u0947\u0902 \u0924\u094b\u095c\u092b\u094b\u095c \u0915\u0930\u0924\u0940 \u092e\u0939\u093f\u0932\u093e \u0915\u093e \u0935\u0940\u0921\u093f\u092f\u094b \u095e\u0930\u094d\u095b\u0940 \u0938\u093e\u092e\u094d\u092a\u094d\u0930\u0926\u093e\u092f\u093f\u0915 \u0926\u093e\u0935\u094b\u0902 \u0915\u0947 \u0938\u093e\u0925 \u0935\u093e\u092f\u0930\u0932",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/video-of-father-asking-daughter-to-not-marry-viral-with-communal-claim-10171",
        headline:
          "\u0985\u09ae\u09a4\u09c7 \u09ac\u09bf\u09df\u09c7 \u09a8\u09be \u0995\u09b0\u09a4\u09c7 \u09ac\u09be\u09ac\u09be\u09b0 \u0985\u09a8\u09c1\u09b0\u09cb\u09a7\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u09b8\u09be\u09ae\u09cd\u09aa\u09cd\u09b0\u09a6\u09be\u09df\u09bf\u0995 \u09ac\u09be\u09b0\u09cd\u09a4\u09be \u09b8\u09b9 \u09ad\u09be\u0987\u09b0\u09be\u09b2",
      },
      {
        url:
          "https://bangla.boomlive.in/fake-news/ecuador-video-falsely-shared-as-saudi-colonel-killed-for-khashoggi-murder-10206",
        headline:
          "\u0987\u0995\u09c1\u09af\u09bc\u09c7\u09a1\u09b0\u09c7\u09b0 \u09ad\u09bf\u09a1\u09bf\u0993 \u099b\u09a1\u09bc\u09be\u09b2 \u099c\u09be\u09ae\u09be\u09b2 \u0996\u09be\u09b6\u09cb\u0997\u09bf \u09b9\u09a4\u09cd\u09af\u09be\u09df \u099c\u09dc\u09bf\u09a4 \u09b8\u09cc\u09a6\u09bf \u0995\u09b0\u09cd\u09a8\u09c7\u09b2\u09c7\u09b0 \u0996\u09c1\u09a8 \u09ac\u09b2\u09c7",
      },
      {
        url:
          "https://factly.in/fake-messages-and-websites-now-offer-non-existent-smartphone-employment-from-the-government/",
        headline:
          " Fake messages and websites now offer non-existent smartphone employment from the Government",
      },
      {
        url:
          "https://factly.in/this-video-of-a-rikshaw-puller-breaking-down-in-tears-is-from-bangladesh-not-india/",
        headline:
          " This video of a rickshaw-puller breaking down in tears is from Bangladesh, not India",
      },
      {
        url:
          "https://factly.in/2013-russian-proto-m-rocket-explosion-video-shared-as-a-failure-of-an-indian-test-missile/",
        headline:
          " 2013 Russian Proto-M rocket explosion video shared as a failure of an Indian test missile",
      },
      {
        url:
          "https://www.indiatoday.in/fact-check/story/fact-check-this-is-not-the-juvenile-involved-in-nirbhaya-s-gang-rape-and-murder-1731808-2020-10-15",
        headline:
          " Fact Check: This is not the juvenile involved in Nirbhaya\u2019s gang rape and murder",
      },
      {
        url:
          "https://www.vishvasnews.com/english/viral/fact-check-eating-millet-bread-cannot-provide-protection-from-coronavirus-viral-claim-is-false/",
        headline:
          "Fact-check: Eating millet bread cannot provide protection from coronavirus, viral claim is false",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/13/fact-check-this-viral-video-of-a-teary-eyed-rickshaw-puller-is-not-from-india-heres-the-truth/",
        headline:
          "Fact Check: This viral video of a teary eyed rickshaw puller is NOT from India; Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/13/fact-check-rajasthan-has-not-issued-directive-to-make-br-ambedkars-portrait-a-must-in-govt-offices/",
        headline:
          "Fact Check: Rajasthan has NOT issued directive to make BR Ambedkar\u2019s portrait a must in govt offices",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/14/fact-check-is-the-govt-offering-jobs-to-people-with-smartphones-this-navratri-heres-the-truth/",
        headline:
          "Fact Check: Is the govt offering jobs to people with smartphones this Navratri? Here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/14/fact-check-ramayana-has-not-been-made-compulsory-in-japanese-schools-heres-the-truth/",
        headline:
          "Fact Check: Ramayana has NOT been made compulsory in Japanese schools; here\u2019s the truth",
      },
      {
        url:
          "http://newsmobile.in/articles/2020/10/15/fact-check-old-pictures-of-russian-missile-resurfaces-as-recent-ones-with-false-claims/",
        headline:
          "Fact Check: Old pictures of Russian missile resurfaces as recent ones with false claims",
      },
    ],
  },
  numpy_seed: 3,
  number_of_articles: 116,
}
